import React, {useState} from 'react';

import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';
import Slider from 'react-slick';

import {Button} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import {ShareablesBlackIcon, rewardStar} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';

const Property = props => {


  const [tabType, setTabType] = useState('playCenter');
  const [threeDots, setThreeDots] = useState(false);
  const [currentComponent, setCurrentComponent] = useState('');

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showComponent, setShowComponent] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);

  const [analycticsSlider, setAnalycticsSlider] = useState(false)

  const imageUrl =
    'https://s3.amazonaws.com/build-dev/profile_img/1696311960976_thumb.jpg';
  const title = 'Property Name';
  const date = 'Washington, DC (Petworth)'; // Replace with your actual date format

  const cardStyle = {
    display: 'flex',
    width: '376px',
    height: '122px',
    

    borderRadius: '5.015px',
    border: ' 1.003px solid #F6F5F5',
    background: '#FFF',
    marginTop: '14px',
  };

  const imageStyle = {
    width: '94.276px',
    height: '94.276px',
    borderRadius: '5.015px',
    margin: '12px',
    objectFit: 'cover',
  };

  const contentStyle = {
    flex: 1,
    margin: ' 1px',
    marginTop: '14px',
  };


  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  const rewardSliderData = [
    {
      name: 'Revenue',
      money: '1',
      time: 'versus last month',
      svgIcon: '',
    },

    {
      name: 'Potential  ',
      money: '2',
      time: 'versus last month',
      svgIcon: '',
      icon: 'fa-solid fa-star',
    },

    {
      name: 'Profit ',
      money: '2',
      time: 'versus last month',
      svgIcon: '',
    },
    {
      name: 'Referrals ',
      money: '42',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">

        <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span style={{fontWeight: 700, fontSize: '13px'}}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <span style={{fontSize: '17px'}}>{item.money}</span>
                            {item.name === 'Ratings' && (
                              <img src={item.svgIcon} alt="" />
                            )}
                            {item.name === 'Conversion' ? (
                              <i
                                className="fa-solid fa-arrow-down"
                                style={{color: '#BD4141'}}></i>
                            ) : (
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            )}
                          </span>
                          <div class="message-date-info">
                            <span style={{color: '#333'}} class="message-date">
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div>
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
                <div className="play_center_page primary_panel_content">
                  <div className="messages-header mb-2">
                    <div className="event-tabs tabs-style-2 mb-0">
                      {' '}
                      <ul>
                        <li>
                          <Button
                            className={tabType === 'playCenter' ? 'active' : ''}
                            onClick={() => {
                              setTabType('playCenter');
                            }}>
                            Available
                          </Button>
                        </li>

                        <li>
                          <Button
                            className={tabType === 'quizzes' ? 'active' : ''}
                            onClick={() => {
                              setTabType('quizzes');
                            }}>
                            Pending
                          </Button>
                        </li>
                        <li>
                          <Button
                            className={tabType === 'saved' ? 'active' : ''}
                            onClick={() => {
                              setTabType('saved');
                            }}>
                            Occupied
                          </Button>
                        </li>
                      </ul>
                    </div>

                    <div className="search-tabs-icons">
                      <span
                        className={
                          currentComponent === 'create-form'
                            ? 'icon active'
                            : 'icon'
                        }>
                        <i
                          className="icon-plus"
                          onClick={() => setCurrentComponent('create-form')}
                        />
                      </span>
                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={
                          threeDots ? 'icon active threeDots' : 'icon threeDots'
                        }>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>
                  <div className="refferal-list-wrapper">
                      <Scrollbars autoHide>
                  <div style={cardStyle}>
                    <img src={imageUrl} alt="Card" style={imageStyle} />
                    <div style={contentStyle}>
                      <h3
                        style={{
                          color: '#000',
                          //   textAlign: 'right',
                          fontFamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: 'normal',
                          paddingBottom: '2px',
                        }}>
                        {title}
                      </h3>
                      <p
                        style={{
                          color: '#000',
                          //   textAlign: 'right',
                          fontFamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: 'normal',
                          paddingBottom: '2px',
                        }}>
                        {date}
                      </p>
                      <p
                        style={{
                          color: '#7E7B70',
                          //   textAlign: 'right',
                          fontFamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: 'normal',
                          paddingBottom: '1px',
                        }}>
                        22 Units
                      </p>
                      <div>
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            marginTop: '10px',
                            marginRight: '18px',
                          }}>
                          <span
                            style={{
                              color: '#000',
                              //   textAlign: 'right',
                              fontFamily: 'Inter',
                              fontSize: '15px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: 'normal',
                            }}>
                            {' '}
                            $225K
                          </span>
                          <span>
                            <img
                              src={ShareablesBlackIcon}
                              alt=""
                              style={{filter: 'brightness(4)'}}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                  </Scrollbars>
                    </div>
                </div>
              </>
            }
            secondary={<></>}
          />
           {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
            </div>
        </div>
      </div>
    </>
  );
};
export default Property;
