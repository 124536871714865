import React, {useState, useEffect, useImperativeHandle} from 'react';

import {Button, Form, Dropdown, Accordion} from 'react-bootstrap';
import {errorMessage} from '../../../Utils/ToastMessages';
import commonApi from '../../../Services/CommonService';
import LoaderSpinner from '../../Modals/LoaderSpinner';

import EditPencilSvg from '../../../Svg/EditPencilSvg';
import {successMessage} from '../../../Utils/ToastMessages';
import {
  blockInvalidChar,
  capitalizeFirst,
  validateCatalogCost,
  validateCatalogItem,
} from '../../../Utils/commonUtils';
import MemberShip from './MemberShip';
import {
  durationArray,
  generatePaymentFrequency,
  isWithinBudget,
  getNameFromDurationValue,
  isDateMatched,
  compareDates,
  isCostEqualToSum,
} from '../../../Utils/commonUtils';
import CustomModal from '../../CustomModal';
import {
  monthDays,
  getNameOfDuration,
  getMaxDateFromToday,
} from '../../../Utils/commonUtils';
import {fixName, isPaymentMilestoneDuplicate} from '../../../Utils/commonUtils';
import moment from 'moment';
import MultiSelectionDropdown from '../../MultiSelectionDropdown';
import {labels} from '../../../Constants/Translations';
import {selected_lang} from '../../../Utils/env';
import Calender from '../../FeedBack/Calender';
const Catalog = props => {
  const [selectedServiceId, setSelectedServiceId] = useState(['1']);
  const [showModal, setShowModal] = useState(false);

  const [showModel, setShowModel] = useState('');
  const [membershipModal, setMembershipModal] = useState(false);
  const [catalogName, setCatalogName] = useState('Product');
  // const [titleValidation, setTitleValidation] = useState(false);
  // const [catalogItems, setCatalogItems] = useState([]);

  // const [catalogTitleList, setCatalogTitleList] = useState([]);
  const [type, setType] = useState('1');
  const [typeForAudience, setTypeForAudience] = useState('1');
  const [typeForAliasRole, setTypeForAliasRole] = useState(
    props.aliasRoles[0]?.id,
  );

  const [typeForAliasRoleM, setTypeForAliasRoleM] = useState(
    props.aliasRoles[0]?.id,
  );
  const [typeForDelievery, setTypeForDelievery] = useState('virtual');

  const [pointsValue, setPointsValue] = useState('');
  const [moneyValue, setMoneyValue] = useState('');

  const [catalogImage, setCatalogImage] = useState('');
  const [showLoader, setShowLoader] = useState('');
  // const [showModel, props.setShowModel] = useState('');
  const [companySuppliers, setCompanySuppliers] = useState([]);
  const [suppliersIds, setSuppliersIds] = useState([]);
  // const RadioButtonName = ['Monthly', 'Annually', 'Custom'];

  const RadioButtonName = [
    {id: '1', label: 'Monthly'},
    {id: '3', label: 'Quarterly'},
    {id: '12', label: 'Annually'},
  ];

  const RadioIncentives = ['Points', 'Money'];
  const [subcribtionIdvalue, setSubcribtionIdvalue] = useState(
    RadioButtonName[0].id,
  );
  const [incentiveIdvalue, setIncentiveIdvalue] = useState(RadioIncentives[0]);
  const [incentiveValues, setIncentiveValues] = useState([]);

  const [rolesPointsMoney, setRolesPointsMoney] = useState([]);
  const [rolesAssignMoney, setRolesAssignMoney] = useState([]);
  const [selectedAliasforPoints, setSelectedAliasforPoints] = useState([]);
  const [selectedAliasforMoney, setSelectedAliasforMoney] = useState([]);
  //  const [pointIncentive, setPointincentive] = useState('');
  // const [moneyIncentive, setMoneyincentive] = useState('');

  const [paymentTypeId, setPaymentTypeId] = useState(['one_time']);

  const [catalogData, setCatalogData] = useState({
    type: '',
    title: '',
    // id: '',
    description: '',
    condition: '',
    rentalFee: '',
    cost: '',
    replacement_cost: '',
    recipient_cost: '',
    documents: '',
    audience: '',
    responsible_party: '',
    delivery_method: '',
    quantity_available: '',
    duration: '',
    variation: '',
    restock: '',
    address: '',
    bedrooms: '',
    bathrooms: '',
    amenities: '',
    retail_value: '',
    media_type: '',
    subscription: subcribtionIdvalue,
    incentive: incentiveIdvalue,
    role_id: '',
    catalog_type: catalogName.toLowerCase(),
    image: '',
    group_ids: [],
    incentive_points: [],
    incentive_money: [],
    taxes_fee: '',
    discount: '',
    grace_period: '',
    late_fee: '',
    shipping_handling: '',
    billing_checked: false,
    billing_day: '',
    levels: [],
    purchase_order: 'no',
    supplier_ids: [],
    payment_milestones: [],
    payment_type: paymentTypeId,
    // group_type: 'single',
  });

  const [paymentMilestone, setPaymentMilestone] = useState({
    date: '',
    amount: '',
  });
  const todayDate = moment().format('YYYY-MM-DD');
  const [catalogEditItem, setCatalogEditItem] = useState({});
  const [validated, setValidated] = useState(false);

  const groupSingleArray = [
    {
      name: 'Single',
      id: 'single',
    },
    {
      name: 'Group',
      id: 'group',
    },
  ];

  const [groupSingelId, setGroupSingelId] = useState(groupSingleArray[0].id);
  const [showGroupFields, setShowGroupFields] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [groupsDeatil, setGroupsDeatil] = useState([]);

  const [deleteItem, setDeleteItem] = useState({});
  const [deleteItemIndex, setDeleteItemIndex] = useState(0);
  const [deleteGroupId, setDeleteGroupId] = useState(0);

  //Membership

  const [gracePeriodType, setGracePeriodType] = useState('1');
  const [levelData, setlevelData] = useState({
    level_name: '',
    frequencies: [],
  });

  const [frequencyData, setFrequencyData] = useState({
    duration: '1',
    payment_frequency: '1',
    payment_amount: '',
    renewal: false,
  });

  const [durationType, setDurationType] = useState('1');
  const [frequencyType, setFrequencyType] = useState('1');

  const [durationItem, setDurationItem] = useState({});
  const [frequencyItem, setFrequencyItem] = useState({});

  const [levelItemIndex, setLevelItemIndex] = useState('');
  const [levelError, setLevelError] = useState(false);

  const [paymentError, setPaymentError] = useState(false);
  const [frequencyValues, setFrequencyValues] = useState([
    {id: '1', name: '1 month', value: '1'},
  ]);

  let plusIconStyle = {
    height: '25px',
    padding: '6px',
  };

  const handlePaymentFrequency = selectedDuartion => {
    console.log(selectedDuartion, 'selectedDuartionselectedDuartion');
    const frequencyArray = generatePaymentFrequency(
      selectedDuartion,
      durationArray,
    );
    console.log(frequencyArray, 'frequencyArray');
    setFrequencyValues(frequencyArray);
  };

  // const handleFrequency = () => {
  //   // if (frequencyData.payment_amount === '') {
  //   //   setPaymentError(true);
  //   //   return;
  //   // } else {
  //   setlevelData({
  //     ...levelData,
  //     frequencies: [...levelData.frequencies, frequencyData],
  //   });
  //   // }
  //   setPaymentTypeId(['one_time']);
  //   setFrequencyData({
  //     duration: '1',
  //     payment_frequency: '1',
  //     payment_amount: '',
  //     renewal: false,
  //     payment_type: ['one_time'],
  //     subscription: RadioButtonName[0].id,
  //     payment_milestones: [],
  //   });
  //   // setFrequencyType('1');
  //   setDurationType('1');

  //   setFrequencyType('1');

  //   setPaymentError(false);
  // };

  const handleFrequency = () => {
    if (paymentTypeId.includes('milestone')) {
      if (frequencyData.payment_amount === '') {
        errorMessage('Please add amount');
      } else if (frequencyData.payment_milestones.length === 0) {
        errorMessage('Please add milestones');
      } else {
        const valid = isCostEqualToSum(
          frequencyData.payment_milestones,
          frequencyData.payment_amount,
        );
        console.log(valid, '==========>validvalid');
        if (valid) {
          addFrequency();
        } else {
          errorMessage(labels.paymentMilestoneError[selected_lang]);
        }
      }
    } else {
      addFrequency();
    }
  };

  const addFrequency = () => {
    setlevelData({
      ...levelData,
      frequencies: [...levelData.frequencies, frequencyData],
    });

    setPaymentTypeId(['one_time']);
    setFrequencyData({
      duration: '1',
      payment_frequency: '1',
      payment_amount: '',
      renewal: false,
      payment_type: ['one_time'],
      subscription: RadioButtonName[0].id,
      payment_milestones: [],
    });
    setDurationType('1');
    setFrequencyType('1');

    setPaymentError(false);
  };

  const handleResetlevel = () => {
    setLevelItemIndex('');
    setFrequencyData({
      duration: '1',
      payment_frequency: '1',
      payment_amount: '',
      renewal: false,
      payment_type: ['one_time'],
      subscription: RadioButtonName[0].id,
      payment_milestones: [],
    });
    setlevelData({
      level_name: '',
      frequencies: [],
    });
    setPaymentMilestone({
      date: '',
      amount: '',
    });
  };

  const removePaymentFrequency = indexToRemove => {
    setlevelData({
      ...levelData,
      frequencies: levelData.frequencies.filter(
        (_, index) => index !== indexToRemove,
      ),
    });
  };

  // {  title,
  // description,
  // cost,
  // audience       ('standard','customer_role','individuals'),
  // delivery_method   ('virtual','in-person','delivery','pickup','deliver'),
  // duration,
  // quantity_available,
  // type  (int),
  // condition,
  // rental_fee,
  // replacement_cost,
  // documents,
  // quantity,
  // responsible_party,
  // variations,
  // available_triggers,
  // restock_frequency,
  // address,
  // bedrooms,
  // bathrooms
  // amenities
  // recipient_cost,
  // retail_value,
  // media_type,
  // subscription,
  // incentive ('points','money'),
  // catalog_type ('service','product','rental','equipment','material','fleet','re_listing','gift','training'),
  // company_id (int),
  // template_id (int)
  // }

  const button = [
    {
      id: '1',
      name: 'Product',
      content:
        'Choose if you offer retail and e-commerce options (virtual or physical)',
    },
    {
      id: '2',
      name: 'Service',
      content: 'Choose if you do consulting or exchange time for reward',
    },

    {
      id: '3',
      name: 'Membership',
      content: 'Choose if you need to simply stay organized or rent to others',
    },
    {
      id: '4',
      name: 'Equipment',
      content: 'Choose if you need to manage material needed for projects',
    },
    {
      id: '5',
      name: 'Material',
      content: 'Choose if you need to manage material needed for projects',
    },

    {
      id: '6',
      name: 'Fleet',
      content: 'Choose if you need to manage service or rentals vehicles',
    },
    {
      id: '7',
      name: 'RE Listing',
      content: 'Choose if you manage real estate and rentals',
    },
    {
      id: '8',
      name: 'Rewards',
      content: 'Choose if you reward customers and team for achievements',
    },
    {
      id: '9',
      name: 'Play Center',
      content: 'Choose if you reward customers and team for achievements',
    },
  ];

  const AudienceTypes = [
    {id: '1', name: 'Standard', value: 'standard'},
    {
      id: '2',
      name: 'Customer Role',
      value: 'customer_role',
    },

    {
      id: '4',
      name: 'Individuals',
      value: 'individuals',
    },
  ];

  const DelieveryTypes = [
    {id: '1', name: 'Virtual', value: 'virtual'},
    {id: '2', name: ' In Person', value: ' in-person'},
    {
      id: '3',
      name: 'Delivery',
      value: 'delivery',
    },

    {
      id: '5',
      name: 'Delivered',
      value: 'delivered',
    },

    {
      id: '4',
      name: 'Deliver',
      value: 'deliver',
    },
    {id: '6', name: 'Pickup', value: 'pickup'},
  ];

  const DelieveryTypes2 = [
    {id: '1', name: 'Virtual', value: 'virtual'},
    {id: '2', name: ' In Person', value: ' in-person'},

    {
      id: '5',
      name: 'Delivered',
      value: 'delivered',
    },
  ];

  console.log(catalogData.group_type, 'group_type');

  useEffect(() => {
    if (catalogName === 'Fleet') {
      setCatalogData({
        ...catalogData,
        audience: '4',
      });
      setTypeForAudience('4');
    } else {
      setCatalogData({
        ...catalogData,
        audience: typeForAudience,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogName]);
  useEffect(() => {}, [props.hitApi]);

  const handlereset = () => {
    setCatalogData({
      type: '',
      title: '',
      // id: '',
      description: '',
      condition: '',
      rentalFee: '',
      cost: '',
      replacement_cost: '',
      recipient_cost: '',
      documents: '',
      audience: '',
      responsible_party: '',
      delivery_method: '',
      quantity_available: '',
      duration: '',
      variation: '',
      restock: '',
      address: '',
      bedrooms: '',
      bathrooms: '',
      amenities: '',
      retail_value: '',
      media_type: '',
      subscription: RadioButtonName[0].id,
      // incentive: '',
      role_id: '',
      catalog_type: catalogName.toLowerCase(),
      image: '',
      group_ids: [],
      incentive_points: [],
      incentive_money: [],
      taxes_fee: '',
      discount: '',
      grace_period: '',
      late_fee: '',
      shipping_handling: '',
      billing_checked: false,
      billing_day: '',
      levels: [],
      purchase_order: 'no',
      supplier_ids: [],
      payment_milestones: [],
      payment_type: ['one_time'],
      // group_type: 'single',
    });

    setValidated(false);
    setPaymentMilestone({
      date: '',
      amount: '',
    });
    setPointsValue('');
    setMoneyValue('');
    setRolesAssignMoney([]);
    setRolesPointsMoney([]);
    setIncentiveValues([]);
    setCatalogEditItem({});
    setGracePeriodType('1');
    setPaymentTypeId(['one_time']);
    setPaymentMilestone({
      date: '',
      amount: '',
    });
    setShowGroupFields(false);
    setSelectedGroupIds([]);
  };
  const addCatalog = (id, name) => {
    // setTitleValidation(false);
    setCatalogName(name);
    props.setCatalogName(name);
    handlereset();
    // setCatalogItems([]);
    setCatalogImage('');
    props.setTicketImg('');
    setSubcribtionIdvalue(RadioButtonName[0].id);
    setIncentiveIdvalue(RadioIncentives[0]);
    setGroupSingelId(groupSingleArray[0].id);
    // props.setCatalogItemsList([]);
    setSelectedServiceId([id]);
    setShowModal(false);
    setMembershipModal(false);
    setFrequencyData({
      duration: '1',
      payment_frequency: '1',
      payment_amount: '',
      renewal: false,
      payment_type: ['one_time'],
      subscription: RadioButtonName[0].id,
      payment_milestones: [],
    });

    setFrequencyType('1');
    setDurationType('1');
    setlevelData({
      level_name: '',
      frequencies: [],
    });
    setPaymentError(false);
    setLevelError(false);
    // setCatalogTitleList([]);

    // if (!selectedServiceId.includes(id)) {
    //   setSelectedServiceId([id]);
    // } else {
    //   setSelectedServiceId(previous =>
    //     previous.filter(miniId => miniId !== id),
    //   );
    // }
  };

  console.log(catalogData, 'catalogData');
  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];
    // let fileExtension = file.name.split('.').pop().toLowerCase();

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else {
      const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      let reader = new FileReader();
      reader.readAsDataURL(filePath);
      reader.onload = e => {
        //

        uploadCatalogImages(e.target.result);
      };

      // setTicketImg(objectUrl);
      //
      //
    }
    // const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  };

  const uploadCatalogImages = img => {
    setShowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_catalog_images(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          setCatalogImage(res.image);
          setCatalogData({
            ...catalogData,
            image: res.image,
          });
        }
        setShowLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const listingOfData =
    catalogName === 'Product'
      ? props.catalogItemsList?.product
      : catalogName === 'Service'
      ? props.catalogItemsList?.service
      : catalogName === 'Rental'
      ? props.catalogItemsList?.rental
      : catalogName === 'Equipment'
      ? props.catalogItemsList?.equipment
      : catalogName === 'Material'
      ? props.catalogItemsList?.material
      : catalogName === 'Fleet'
      ? props.catalogItemsList?.fleet
      : catalogName === 'RE Listing'
      ? props.catalogItemsList?.re_listing
      : catalogName === 'Rewards'
      ? props.catalogItemsList?.gift
      : catalogName === 'Play Center'
      ? props.catalogItemsList?.training
      : catalogName === 'Membership'
      ? props.catalogItemsList?.membership
      : [];

  const generateRandomId = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  };
  const addItems = item => {
    // if (!catalogItems.includes(item.title)) {

    if (item.image === '') {
      item.image = 'https://s3.amazonaws.com/build-dev/no_image_2.jpg';
    }
    const newItem = {
      ...item,
      id: generateRandomId(), // Generate a random UUID and assign it to the 'id' property
      catalog_type:
        catalogName === 'RE Listing'
          ? 're_listing'
          : catalogName === 'Play Center'
          ? 'play_center'
          : catalogName.toLowerCase(),
    };
    // setCatalogItaems(previous => [...previous, newItem.title]);
    // if (!catalogTitleList.includes) {
    // setCatalogTitleList(previous => [...previous, newItem.title]);
    props.setCatalogListSaveByUser(previous => [...previous, newItem]);

    // props.setCatalogItemsList(previous => [...previous, item]);

    // const updatedCatalogListTemp = {
    //   ...props.catalogListSaveByUser,
    //   [catalogName.toLowerCase()]: [
    //     ...props.catalogListSaveByUser[catalogName.toLowerCase()],
    //     item,
    //   ],
    // };
    // props.setCatalogListSaveByUser(updatedCatalogListTemp);
    const updatedCatalogList = {
      ...props.catalogItemsList,
      [catalogName === 'RE Listing'
        ? 're_listing'
        : catalogName === 'Play Center'
        ? 'play_center'
        : catalogName.toLowerCase()]: [
        ...props.catalogItemsList[
          catalogName === 'RE Listing'
            ? 're_listing'
            : catalogName === 'Play Center'
            ? 'play_center'
            : catalogName.toLowerCase()
        ],
        newItem,
      ],
    };
    props.setCatalogItemsList(updatedCatalogList);

    setShowModal(false);
    handlereset();
    // setSubcribtionIdvalue('');
    // setIncentiveIdvalue('');
    // setSubcribtionIdvalue(RadioButtonName[0].id);
    setIncentiveIdvalue(RadioIncentives[0]);
    setGroupSingelId(groupSingleArray[0].id);
    setCatalogImage('');
    props.setTicketImg('');
    // } else {
    //   errorMessage(`you have already added this ${catalogName}`);
    // }
  };

  const removeCatalogItems = (title, userId) => {
    const updatedCatalogList = {
      ...props.catalogItemsList,
      [catalogName === 'RE Listing'
        ? 're_listing'
        : catalogName === 'Play Center'
        ? 'play_center'
        : catalogName.toLowerCase()]: props.catalogItemsList[
        catalogName === 'RE Listing'
          ? 're_listing'
          : catalogName === 'Play Center'
          ? 'play_center'
          : catalogName.toLowerCase()
      ].filter((item, index) => item.title !== title && index !== userId),
    };

    props.setCatalogItemsList(updatedCatalogList);

    props.setCatalogListSaveByUser(previous =>
      previous.filter(
        (item, index) => item.title !== title && index !== userId,
      ),
    );
  };

  const handleChange = e => {
    // const {id, checked} = e.target;
    // setOpportunity([...opportunity, id]);
    setSubcribtionIdvalue(e.target.value);
    // setCatalogData({
    //   ...catalogData,
    //   subscription: e.target.value.toLowerCase(),
    // });

    if (catalogName !== 'Membership') {
      // setSubcribtionIdvalue(e.target.value);
      setCatalogData({
        ...catalogData,
        subscription: e.target.value,
      });
    }

    if (catalogName === 'Membership') {
      // setSubcribtionIdvalue(e.target.value);
      setFrequencyData({
        ...frequencyData,
        subscription: e.target.value,
      });
    }
  };

  const delete_company_catalog = id => {
    props.setCatalogListSaveByUser(previous =>
      previous.filter(miniId => miniId.id !== id),
    );

    const updatedCatalogList = {
      ...props.catalogItemsList,
      [catalogName === 'RE Listing'
        ? 're_listing'
        : catalogName === 'Play Center'
        ? 'play_center'
        : catalogName.toLowerCase()]: props.catalogItemsList[
        catalogName === 'RE Listing'
          ? 're_listing'
          : catalogName === 'Play Center'
          ? 'play_center'
          : catalogName.toLowerCase()
      ].filter(item => item.id !== id),
    };
    props.setCatalogItemsList(updatedCatalogList);
    props.setCatalogDeleteids(previous => [...previous, id]);
  };
  // const handleIncentives = e => {
  //   // const {id, checked} = e.target;
  //   // setOpportunity([...opportunity, id]);
  //   setIncentiveIdvalue(e.target.value);
  //   setCatalogData({
  //     ...catalogData,
  //     incentive: e.target.value.toLowerCase(),
  //   });
  // };

  const handleIncentivesValues = e => {
    if (!incentiveValues.includes(e.target.value)) {
      setIncentiveValues(previous => [...previous, e.target.value]);
    } else {
      setIncentiveValues(previous =>
        previous.filter(item => item !== e.target.value),
      );

      if (e.target.value === 'Points') {
        setRolesPointsMoney([]);
        setCatalogData({
          ...catalogData,
          incentive_points: [],
        });
      } else if (e.target.value === 'Money') {
        setRolesAssignMoney([]);
        setCatalogData({
          ...catalogData,
          incentive_money: [],
        });
      }
    }

    // setCatalogData({
    //   ...catalogData,
    //   incentive: e.target.value.toLowerCase(),
    // });
  };

  // const handleSubmitOfForm = e => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const form = e.currentTarget;
  //   if(form.checkValidity() === false){
  //     setValidated(true);
  //     return
  //   }

  //   const valid = isCostEqualToSum(
  //     catalogData.payment_milestones,
  //     catalogData.cost,
  //   );

  //   if (
  //     form.checkValidity() === false ||
  //     (catalogName === 'Membership' && catalogData.levels.length === 0) ||
  //     (catalogName !== 'Membership' &&
  //       paymentTypeId.includes('milestone') &&
  //       catalogData.payment_milestones.length === 0) ||
  //     !valid
  //   ) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     // if (!catalogData.image || catalogData.image === '') {
  //     //   errorMessage('Please add image');
  //     // }

  //     if (catalogName === 'Membership' && catalogData.levels?.length === 0) {
  //       errorMessage('Please Add Level');
  //     } else {
  //       if (paymentTypeId.includes('milestone')) {
  //         if (catalogData.payment_milestones.length > 0) {
  //           if (!valid) {
  //             errorMessage('Sum of payment milestones must be equal to cost');
  //           }
  //         } else {
  //           errorMessage('Please add milestones');
  //         }
  //       }
  //     }

  //     setValidated(true);
  //   } else {
  //     e.preventDefault();

  //     const catalogItem = validateCatalogItem(catalogData);

  //     addItems(catalogItem);
  //     setValidated(false);
  //   }
  // };

  const handleSubmitOfForm = e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    if (
      catalogData.cost <= catalogData.discount &&
      catalogName !== 'Membership'
    ) {
      errorMessage(labels.discount_Error[selected_lang]);
      return;
    }
    let valid = true;

    if (paymentTypeId.includes('milestone')) {
      valid = validateCatalogCost(
        catalogData.payment_milestones,
        catalogData.cost,
        catalogData.discount,
      );
    }

    if (
      (catalogName === 'Membership' && catalogData.levels?.length === 0) ||
      (catalogName !== 'Membership' &&
        paymentTypeId.includes('milestone') &&
        catalogData.payment_milestones.length === 0) ||
      !valid
    ) {
      setValidated(true);
      if (catalogName === 'Membership' && catalogData.levels?.length === 0) {
        errorMessage('Please Add Level');
      } else if (paymentTypeId.includes('milestone')) {
        if (catalogData.payment_milestones.length === 0) {
          errorMessage('Please add milestones');
        } else if (!valid) {
          errorMessage('Sum of payment milestones must be equal to cost');
        }
      }
      return;
    }

    const catalogItem = validateCatalogItem(catalogData);

    addItems(catalogItem);
    setValidated(false);
  };

  const getGroupDetail = () => {
    const paylaod = {
      search_query: '',
    };

    commonApi
      .get_all_groups(paylaod)
      .then(res => {
        if (res.status === 200) {
          const groupArray = [...res.all_groups];
          groupArray.unshift({group_name: 'All', id: 'all'});
          const newArray = groupArray;
          setGroupsDeatil(
            res.all_groups.length > 0 ? newArray : res.all_groups,
          );
          console.log(catalogEditItem, 'catalogEditItemcatalogEditItem');

          if (
            Object.keys(catalogEditItem).length === 0 &&
            res.all_groups.length > 0
          ) {
            setSelectedGroupIds([res.all_groups[0]?.id]);
          }

          if (
            Object.keys(catalogEditItem).length > 0 &&
            catalogEditItem.hasOwnProperty('group_ids') &&
            catalogEditItem.group_ids.length > 0 &&
            catalogEditItem.group_ids.length === res.all_groups.length
          ) {
            const newArray = [...catalogEditItem.group_ids];
            newArray.unshift('all');
            // const groupIds = newArray
            setSelectedGroupIds(newArray);
          }

          // if (res.all_groups.length === 0) {
          //   setAddNewGroup(true);
          // }
          setShowGroupFields(true);
        }

        // setGroupsDeatil(res.all_groups);
        // // setSelctedGroupId(res.all_groups[0]?.id)
        // const newData = {
        //   ...catalogData,
        //   group_ids: [res.all_groups[0]?.id],
        // };
        // setCatalogData(newData);
        // setShowGroupFields(true);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    if (showGroupFields) {
      getGroupDetail();
    }
  }, [props.showModel]);

  useEffect(() => {
    setCatalogData({
      ...catalogData,
      image: props.ticketImg,
    });
  }, [props.ticketImg]);

  useEffect(() => {
    if (catalogName !== 'Membership') {
      setCatalogData({
        ...catalogData,
        payment_type: catalogName === 'Membership' ? [] : paymentTypeId,
        payment_milestones: !paymentTypeId.includes('milestone')
          ? []
          : catalogData.payment_milestones,
        subscription: !paymentTypeId.includes('subscription')
          ? RadioButtonName[0].id
          : catalogData.subscription,
      });
    }

    if (catalogName === 'Membership') {
      setFrequencyData({
        ...frequencyData,
        payment_type: paymentTypeId,
        payment_milestones: !paymentTypeId.includes('milestone')
          ? []
          : frequencyData.payment_milestones,
        subscription: !paymentTypeId.includes('subscription')
          ? RadioButtonName[0].id
          : frequencyData.subscription,
      });
    }

    if (!paymentTypeId.includes('subscription')) {
      setSubcribtionIdvalue(RadioButtonName[0].id);
    }
  }, [paymentTypeId]);

  useEffect(() => {
    if (
      props.deleteCatalog &&
      Object.keys(deleteItem).length > 0 &&
      props.deleteItemName === ''
    ) {
      if (deleteItem.id) {
        delete_company_catalog(deleteItem.id);
      } else {
        removeCatalogItems(deleteItem.title, deleteItemIndex);
      }
    }

    if (props.deleteCatalog && deleteGroupId !== '') {
      delete_group_catalog(deleteGroupId);
    }

    if (props.deleteCatalog && props.deleteItemName === 'Duration') {
      removePaymentFrequency(deleteItemIndex);
    }

    if (props.deleteCatalog && props.deleteItemName === 'Level') {
      removeMembershipLevels(deleteItemIndex);
    }

    if (props.deleteCatalog && props.deleteItemName === 'Milestone Payment') {
      handlePaymentMileStone('remove', deleteItemIndex);
    }

    if (
      props.deleteCatalog &&
      props.deleteItemName === 'deleteDuarationMilestone'
    ) {
      handleDurationMileStone('remove', deleteItemIndex);
    }

    if (
      props.deleteCatalog &&
      props.deleteItemName === 'edit_level' &&
      Object.keys(deleteItem).length > 0
    ) {
      editMembershipLevel(deleteItem);
    }
    // else{
    //   if (deleteItem.id) {
    //     delete_company_catalog(deleteItem.id);
    //   } else {
    //     removeCatalogItems(deleteItem.title, deleteItemIndex);
    //   }
    // }

    props.setDeleteGroup(false);
    setDeleteItem({});
    setDeleteItemIndex(0);
    props.setDeleteCatalog(false);
    props.setDeleteItemName('');
    setDeleteGroupId('');
  }, [props.deleteCatalog]);

  useEffect(() => {
    getCompanySupplier();
  }, []);

  const delete_group_catalog = id => {
    var data = {
      delete_group_id: id,
    };

    commonApi
      .create_group_catalog(data)
      .then(res => {
        if (res.success === true) {
          props.setAllCatalogDataGroup(prev =>
            prev.filter(item => item.id !== id),
          );
          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const isAliasIdExists = (id, stateArray) => {
    return stateArray.some(item => item.alias_id === id);
  };

  const handlePointsMoney = (aliasId, value) => {
    if (!isAliasIdExists(aliasId, rolesPointsMoney)) {
      const filteredarray = props.aliasRoles.filter(
        item => item.id === aliasId,
      );
      let item = filteredarray[0];
      setRolesPointsMoney(previous => [
        ...previous,
        {alias_id: aliasId, alias_name: item.role_alias, points: value},
      ]);

      setPointsValue('');
    } else {
      errorMessage('You have already assign Points');
    }
  };

  const handleMoney = (aliasId, value) => {
    if (!isAliasIdExists(aliasId, rolesAssignMoney)) {
      const filteredarray = props.aliasRoles.filter(
        item => item.id === aliasId,
      );
      let item = filteredarray[0];
      setRolesAssignMoney(previous => [
        ...previous,
        {alias_id: aliasId, alias_name: item.role_alias, money: value},
      ]);

      setMoneyValue('');
    } else {
      errorMessage('You have already assign Money');
    }
  };

  const removeAssignPointsRoles = id => {
    setRolesPointsMoney(prev => prev.filter(item => item.alias_id !== id));
  };

  const removeAssignMoneyRoles = id => {
    setRolesAssignMoney(prev => prev.filter(item => item.alias_id !== id));
  };

  useEffect(() => {
    if (rolesPointsMoney.length > 0) {
      setCatalogData({
        ...catalogData,
        incentive_points: rolesPointsMoney,
      });
    }
  }, [rolesPointsMoney]);

  useEffect(() => {
    if (rolesAssignMoney.length > 0) {
      setCatalogData({
        ...catalogData,
        incentive_money: rolesAssignMoney,
      });
    }
  }, [rolesAssignMoney]);

  useEffect(() => {
    const newData = {
      ...catalogData,
      group_ids: selectedGroupIds.filter(item => item !== 'all'),
    };
    setCatalogData(newData);
  }, [selectedGroupIds]);

  const handleEditCatalogItem = data => {
    // setCatalogEditItem(data);
    const catalogData = {
      type: data.type || '',
      title: data.title || '',
      // id: data.id || '', // Uncomment if id needs to be included
      description: data.description || '',
      condition: data.condition || '',
      rentalFee: data.rentalFee || '',
      cost: data.cost || '',
      replacement_cost: data.replacement_cost || '',
      recipient_cost: data.recipient_cost || '',
      documents: data.documents || '',
      audience: data.audience || '',
      responsible_party: data.responsible_party || '',
      delivery_method: data.delivery_method || '',
      quantity_available: data.quantity_available || '',
      duration: data.duration || '',
      variation: data.variation || '',
      restock: data.restock || '',
      address: data.address || '',
      bedrooms: data.bedrooms || '',
      bathrooms: data.bathrooms || '',
      amenities: data.amenities || '',
      retail_value: data.retail_value || '',
      media_type: data.media_type || '',
      subscription: data.subscription || RadioButtonName[0].id,
      role_id: data.role_id || '',
      catalog_type: data.catalog_type ? data.catalog_type.toLowerCase() : '',
      image:
        data.image &&
        data.image !== 'https://s3.amazonaws.com/build-dev/no_image_2.jpg'
          ? data.image
          : '',
      group_ids: data.group_ids || [],
      incentive_points: data.incentive_points || [],
      incentive_money: data.incentive_money || [],
      taxes_fee: data.taxes_fee || '',
      discount: data.discount || '',
      grace_period: data.grace_period || '',
      late_fee: data.late_fee || '',
      shipping_handling: data.shipping_handling || '',
      levels:
        catalogName === 'Membership' && data.membership_levels.length > 0
          ? data.membership_levels
          : [],
      purchase_order:
        data.catalog_type === 'product' && data.purchase_order === 'yes'
          ? 'yes'
          : 'no',
      supplier_ids:
        data.catalog_type === 'product' && data.supplier_ids.length > 0
          ? data.supplier_ids
          : [],
      payment_milestones:
        data.payment_milestones && data.payment_milestones.length > 0
          ? data.payment_milestones
          : [],
      payment_type:
        data.payment_type && data.payment_type.length > 0
          ? data.payment_type
          : ['one_time'],
    };

    setGracePeriodType(
      data.grace_period && data.grace_period !== '' ? data.grace_period : '1',
    );

    setRolesAssignMoney(
      data.incentive_money.length > 0 ? data.incentive_money : [],
    );
    setRolesPointsMoney(
      data.incentive_points.length > 0 ? data.incentive_points : [],
    );
    setCatalogData(catalogData);

    setPaymentTypeId(
      !data.payment_type.includes('') &&
        data.payment_type &&
        data.payment_type.length > 0
        ? data.payment_type
        : ['one_time'],
    );
    props.setTicketImg(
      data.image &&
        data.image !== 'https://s3.amazonaws.com/build-dev/no_image_2.jpg'
        ? data.image
        : '',
    );

    setSubcribtionIdvalue(data.subscription || RadioButtonName[0].id);
    setIncentiveValues(previous =>
      [
        ...previous,
        ...(data.incentive_money.length > 0 ? ['Money'] : []),
        ...(data.incentive_points.length > 0 ? ['Points'] : []),
      ].filter(Boolean),
    );

    if (data.group_ids.length > 0) {
      setGroupSingelId(groupSingleArray[1].id);
      setShowGroupFields(true);
      setSelectedGroupIds(data.group_ids);
    }

    setShowModal(true);
  };

  useEffect(() => {
    if (
      Object.keys(catalogEditItem).length > 0 &&
      catalogEditItem.hasOwnProperty('group_ids')
    ) {
      getGroupDetail();
    }
  }, [catalogEditItem]);

  const updateCatalogItem = () => {
    const catalogItem = validateCatalogItem(catalogData);

    var data = {
      catalog_details: [catalogItem],
      catalog_id: 'delete' in catalogEditItem ? catalogEditItem.id : '',
    };

    console.log(data, 'data');
    commonApi
      .create_offering(data)
      .then(res => {
        if (res.success === true) {
          props.getCompanyCatalog();
          setShowModal(false);
          handlereset();
          setCatalogImage('');
          props.setTicketImg('');
          // setSubcribtionIdvalue('');
          setIncentiveIdvalue('');

          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (
      catalogName !== 'RE Listing' &&
      catalogName !== 'Rewards' &&
      catalogName !== 'Play Center' &&
      catalogName !== 'Product'
    ) {
      setSubcribtionIdvalue(RadioButtonName[0].id);
    }
    //  else {
    //   setSubcribtionIdvalue('');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogName]);

  const isLevelsExists = (name, stateArray, levelItemIndex) => {
    return stateArray.some((item, index) => {
      if (typeof levelItemIndex === 'number') {
        return index !== levelItemIndex && item.level_name === name;
      } else {
        return item.level_name === name;
      }
    });
  };

  const handleLevels = () => {
    if (levelData.level_name === '' || levelData.frequencies.length === 0) {
      if (levelData.level_name === '') {
        setLevelError(true);
      } else if (levelData.frequencies.length === 0) {
        errorMessage('Please Add Duration');
      }

      return;
    } else {
      if (!isLevelsExists(levelData.level_name, catalogData.levels)) {
        if (levelItemIndex !== '') {
          setCatalogData({
            ...catalogData,
            levels: catalogData.levels.map((item, index) =>
              index === levelItemIndex ? levelData : item,
            ),
          });

          setLevelItemIndex('');
        } else {
          setCatalogData({
            ...catalogData,
            levels: [...catalogData.levels, levelData],
          });
        }

        setFrequencyData({
          duration: '1',
          payment_frequency: '1',
          payment_amount: '',
          renewal: false,
          payment_type: ['one_time'],
          subscription: RadioButtonName[0].id,
          payment_milestones: [],
        });
        setPaymentTypeId(['one_time']);
        setFrequencyType('1');
        setDurationType('1');
        setlevelData({
          level_name: '',
          frequencies: [],
        });

        setLevelError(false);
      } else {
        errorMessage('You have already added this Level name');
      }
    }
  };

  const removeMembershipLevels = indexToRemove => {
    setCatalogData({
      ...catalogData,
      levels: catalogData.levels.filter((_, index) => index !== indexToRemove),
    });
    handleResetlevel();
  };

  const editMembershipLevel = item => {
    setlevelData(item);
  };

  const getCompanySupplier = type => {
    var payload = {};

    commonApi
      .get_company_supplier(payload)
      .then(res => {
        if (res.status === 200) {
          if (
            type &&
            type === 'addSupplier' &&
            res.supplier_details.length > 0
          ) {
            setCatalogData({
              ...catalogData,
              supplier_ids: [res.supplier_details[0]?.id],
            });
          }
          setCompanySuppliers(res.supplier_details);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const handleDurationMileStone = (type, index) => {
    console.log(
      paymentMilestone.amount,
      frequencyData.payment_milestones,
      frequencyData.payment_amount,
      'dddddddddddd',
    );
    if (frequencyData.payment_amount === '') {
      errorMessage('Please add amount');
      return;
    } else if (
      (paymentMilestone.date === '' || paymentMilestone.amount === '') &&
      type === 'add'
    ) {
      errorMessage(
        paymentMilestone.date === ''
          ? 'Please select date'
          : 'Please add amount',
      );
      return;
    } else if (
      !isWithinBudget(
        paymentMilestone.amount,
        frequencyData.payment_milestones,
        frequencyData.payment_amount,
      ) &&
      type !== 'remove'
    ) {
      errorMessage(
        `please select equal and less than $${frequencyData.payment_amount} `,
      );

      return;
    }

    const isValid = isPaymentMilestoneDuplicate(
      frequencyData.payment_milestones,
      paymentMilestone,
    );
    const validateDate = compareDates(
      getNameFromDurationValue(frequencyData.duration),
      paymentMilestone.date,
    );
    const dateIsValid = isDateMatched(
      frequencyData.payment_milestones,
      frequencyData.date,
    );
    console.log(validateDate, '----------date validation');
    // console.log(isValid, '-------->isValid');
    if (type === 'add' && isValid && validateDate && !dateIsValid) {
      setFrequencyData({
        ...frequencyData,
        payment_milestones: [
          ...frequencyData.payment_milestones,
          paymentMilestone,
        ],
      });
    } else if (type !== 'remove' && !isValid) {
      errorMessage('You have already added this date');
      return;
    } else if (type !== 'remove' && !validateDate) {
      errorMessage('Invalid date');
      return;
    } else if (type !== 'remove' && dateIsValid) {
      errorMessage('Date is repeated');
      return;
    }

    if (type === 'remove') {
      const newPaymentMilestones = frequencyData.payment_milestones.filter(
        (_, i) => i !== index,
      );

      setFrequencyData({
        ...frequencyData,
        payment_milestones: newPaymentMilestones,
      });
    }

    setPaymentMilestone({
      date: '',
      amount: '',
    });
    props.setShowModel('');
  };

  const handlePaymentMileStone = (type, index) => {
    if (catalogData.cost === '') {
      errorMessage('Please add first cost');
      return;
    } else if (
      (paymentMilestone.date === '' || paymentMilestone.amount === '') &&
      type === 'add'
    ) {
      errorMessage(
        paymentMilestone.date === ''
          ? 'Please select date'
          : 'Please add amount',
      );
      return;
    } else if (
      !isWithinBudget(
        paymentMilestone.amount,
        catalogData.payment_milestones,
        catalogData.cost,
      ) &&
      type !== 'remove'
    ) {
      errorMessage(
        `Sum of payment milestone must be equal to $${catalogData.cost} `,
      );

      return;
    }

    const isValid = isPaymentMilestoneDuplicate(
      catalogData.payment_milestones,
      paymentMilestone,
    );

    console.log(isValid, '-------->isValid');
    if (type === 'add' && isValid) {
      setCatalogData({
        ...catalogData,
        payment_milestones: [
          ...catalogData.payment_milestones,
          paymentMilestone,
        ],
      });
    } else if (type !== 'remove') {
      errorMessage('You have already added this date');
      return;
    }
    if (type === 'remove') {
      const newPaymentMilestones = catalogData.payment_milestones.filter(
        (_, i) => i !== index,
      );

      setCatalogData({
        ...catalogData,
        payment_milestones: newPaymentMilestones,
      });
    }

    props.setShowModel('');
    setPaymentMilestone({
      date: '',
      amount: '',
    });
  };

  useImperativeHandle(props.catalogRef, () => ({
    getSupplier(type) {
      getCompanySupplier(type);
    },
  }));
  // console.log(props.aliasRoles, 'props.systemRoles');
  // console.log(rolesPointsMoney, 'rolesPointsMoney');
  // console.log(rolesAssignMoney, 'rolesAssignMoney');

  // console.log(listingOfData, 'listingOfData');

  // console.log(groupsDeatil, 'groupsDeatilgroupsDeatil');
  // console.log(catalogData.group_ids, 'length');

  console.log(levelData, 'levelDatalevelDatalevelData');

  console.log(catalogData.payment_milestones, 'catalogData.payment_milestones');
  console.log(catalogEditItem, 'catalogEditItem,,,,,,,,');
  return (
    <>
      <div className="company-info-screen  catalog_screen">
        <div>
          {props.showLoader && <LoaderSpinner />}
          <div className="slider3-container">
            <div className="mb-5">
              <h1 className="mb-2">
                Select catalog options you need to run your business
              </h1>

              <span> You will be able to add your inventory later</span>
            </div>
            <div style={{display: 'flex'}}>
              <div style={{flex: 1}}>
                <div className="sticky" style={{top: 0}}>
                  {Object.entries(button).length > 0 &&
                    button.map((item, index) => (
                      <div
                        style={{display: 'flex'}}
                        className="mb-3"
                        key={`cat-item-${index}`}>
                        <div
                          // className="catalog-button-option"
                          style={{flex: 1}}>
                          <div
                            className={`catalog-button-option as-link ${
                              selectedServiceId.includes(item.id)
                                ? 'selected-catalog'
                                : ''
                            }`}
                            onClick={() => {
                              addCatalog(item.id, item.name);
                            }}>
                            {item.name}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {!showModal && (
                <div
                  style={{
                    flex: 1,
                    // flex:1,
                    width: '32%',
                  }}>
                  <div
                    className="catalog-content "
                    style={{
                      // marginLeft: '20%',
                      marginLeft: '20%',
                    }}>
                    <span
                      className=" as-link "
                      style={{color: '#52BD41', fontWeight: '600'}}
                      onClick={() => {
                        setShowModal(true);
                      }}>
                      Add {catalogName}
                    </span>

                    <div className="mt-3">
                      <ul>
                        {listingOfData.length > 0 &&
                          !showModal &&
                          listingOfData.map((item, index) => (
                            <li className={`mb-2 `} key={index}>
                              <div className="catalog_product_item">
                                <div className="text-container">
                                  {item.title}
                                </div>
                                <div className="d-flex gap-2">
                                  <span
                                    className=" as-link d-inline"
                                    onClick={() => {
                                      setCatalogEditItem(item);
                                      handleEditCatalogItem(item);
                                      // props.handleEditGroupCatalog(item);
                                    }}>
                                    <EditPencilSvg
                                      fill={'#52bd41'}
                                      h={15}
                                      w={15}
                                    />
                                  </span>
                                  <span
                                    className="as-link poc_delete"
                                    onClick={() => {
                                      if (
                                        item.delete === true ||
                                        !('delete' in item)
                                      ) {
                                        props.setShowModel('deleteItem');
                                        setDeleteItem(item);
                                        setDeleteItemIndex(index);
                                      } else {
                                        errorMessage(
                                          'This catalog is include in Invoice',
                                        );
                                      }
                                      // if (item.id) {
                                      //   // alert('hehe');
                                      //   delete_company_catalog(item.id);
                                      // } else {
                                      //   // alert('no hehe');
                                      //   removeCatalogItems(item.title, index);
                                      // }
                                    }}>
                                    <i className="fa-solid fa-trash-can" />
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {!showModal && (
                <div
                  style={{
                    flex: 1,
                    width: '32%',
                  }}>
                  <div className="catalog-content" style={{marginLeft: '20%'}}>
                    {props.allCatalogData?.service?.length +
                      props.allCatalogData?.product?.length >=
                      2 && (
                      <span
                        className=" as-link "
                        style={{color: '#52BD41', fontWeight: '600'}}
                        onClick={() => {
                          props.setShowModel('add_group');
                        }}>
                        Add Group
                      </span>
                    )}

                    {props.allCatalogDataGroup?.length > 0 && (
                      <div className="mt-3">
                        <ul>
                          {props.allCatalogDataGroup.map((item, index) => (
                            <li className="mb-2" key={index}>
                              <div className="catalog_product_item">
                                <div className="text-container">
                                  {item.group_name}
                                </div>

                                <div className="d-flex gap-2">
                                  <span
                                    className=" as-link d-inline"
                                    onClick={() => {
                                      props.handleEditGroupCatalog(item);
                                    }}>
                                    <EditPencilSvg
                                      fill={'#52bd41'}
                                      h={15}
                                      w={15}
                                    />
                                  </span>

                                  <span
                                    className="poc_delete as-link "
                                    onClick={() => {
                                      props.setShowModel('deleteItem');
                                      props.setDeleteGroup(true);
                                      setDeleteGroupId(item.id);
                                      // delete_group_catalog(item.id)
                                    }}>
                                    <i className="fa-solid fa-trash-can" />
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {showModal && (
                <div style={{flex: 2}}>
                  <span
                    className="close as-link"
                    style={{float: 'right', marginRight: '30px'}}
                    onClick={() => {
                      setShowModal(false);
                      handlereset();
                      setCatalogImage('');
                      props.setTicketImg('');
                      // setSubcribtionIdvalue('');
                      setIncentiveIdvalue('');
                    }}>
                    <i className="fas fa-times" />
                  </span>

                  <div className="catalog-content">
                    {showModal && (
                      <>
                        <div className="" style={{marginRight: 30}}>
                          <div className="ticket-img-wrapper mb-3 m-0 ">
                            {props.ticketImg && (
                              <p
                                onClick={() => {
                                  props.setShowModel('image_pre');
                                }}>
                                Onclick
                              </p>
                            )}
                            {/* <p onClick={()=>{props.setShowModel('modal')}}>Onclick</p> */}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={e => props.uploadCatalogImg(e)}
                            />
                            {props.ticketImg && (
                              <img src={props.ticketImg} alt="" />
                            )}
                            <i className="icon-camera" />
                          </div>

                          {/* {sliderImage.length > 0 && (
                          <span
                            className="as-link info-text mb-3 mt-0"
                            onClick={() => {
                              setImgModal(true);
                            }}>
                            Preview
                          </span>
                        )} */}
                        </div>
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handleSubmitOfForm}>
                          <div className="mt-3">
                            {catalogName !== 'Product' &&
                              catalogName !== 'Service' &&
                              catalogName !== 'Rewards' &&
                              catalogName !== 'Play Center' &&
                              catalogName !== 'Membership' && (
                                <div className="input-group">
                                  <Form.Label controlid="validationCustom01">
                                    Type
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    value={catalogData.type}
                                    pattern="^\S.*$"
                                    required
                                    placeholder=""
                                    onChange={e => {
                                      setCatalogData({
                                        ...catalogData,
                                        type: e.target.value,
                                      });
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    type is required.
                                  </Form.Control.Feedback>
                                </div>
                              )}

                            <div className="input-group">
                              <Form.Label controlid="validationCustom01">
                                {catalogName === 'Membership'
                                  ? 'Membership Title'
                                  : 'Title'}
                              </Form.Label>

                              <Form.Control
                                type="text"
                                value={catalogData.title}
                                pattern="^\S.*$"
                                required
                                placeholder=""
                                onChange={e => {
                                  setCatalogData({
                                    ...catalogData,
                                    title: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Title is required.
                              </Form.Control.Feedback>
                            </div>

                            {/* {catalogName !== 'Product' &&
                        catalogName !== 'Service' &&
                        catalogName !== 'Gift' &&
                        catalogName !== 'Training' && (
                          <div className="input-group">
                            <Form.Label controlid="validationCustom01">
                              Id
                            </Form.Label>

                            <Form.Control
                              type="text"
                              value={catalogData.id}
                              pattern="^\S.*$"
                              required
                              placeholder=""
                              onChange={e => {
                                setCatalogData({
                                  ...catalogData,
                                  id: e.target.value,
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Id is required.
                            </Form.Control.Feedback>
                          </div>
                        )} */}

                            {catalogName === 'RE Listing' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Address
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={catalogData.address}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      address: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Address is required.
                                </Form.Control.Feedback>
                              </div>
                            )}

                            {catalogName === 'RE Listing' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Bedrooms
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={catalogData.bedrooms}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      bedrooms: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Bedrooms is required.
                                </Form.Control.Feedback>
                              </div>
                            )}

                            {catalogName === 'RE Listing' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Bathrooms
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={catalogData.bathrooms}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      bathrooms: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Bathrooms is required.
                                </Form.Control.Feedback>
                              </div>
                            )}

                            {catalogName === 'Membership' && (
                              <div className="membership_level mb-3">
                                <h1 className="mb-3 ">Membership Levels</h1>

                                <div className="d-flex gap-4 ">
                                  <div className="flex-1">
                                    <div className="input-group">
                                      <Form.Label controlid="validationCustom01">
                                        Level Name
                                      </Form.Label>

                                      <Form.Control
                                        type="text"
                                        value={levelData.level_name}
                                        pattern="^\S.*$"
                                        // required={membershipData.levels.length === 0 ? true : false}
                                        placeholder=""
                                        onChange={e => {
                                          setlevelData({
                                            ...levelData,
                                            level_name: e.target.value,
                                          });
                                        }}
                                      />

                                      {levelError &&
                                        levelData.level_name === '' && (
                                          <div className="text-danger textforerror">
                                            Name is required.
                                          </div>
                                        )}

                                      {/* <Form.Control.Feedback type="invalid">
                             Name is required.
                           </Form.Control.Feedback> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="mb-1 mr-1 d-flex justify-content-end">
                                  <div className="triger_plus_main">
                                    <div
                                      className="triger_plus"
                                      onClick={() => {
                                        handleFrequency();
                                      }}
                                      style={plusIconStyle}>
                                      <i className="fa-solid fa-plus"></i>
                                    </div>
                                  </div>
                                </div>

                                <div className="membership_level_fields mb-3">
                                  <div className="d-flex gap-4 mb-3">
                                    <div className="flex-1">
                                      <div className="payment-select-frequency-fields">
                                        <Form.Label controlid="validationCustom01">
                                          Duration
                                        </Form.Label>
                                        <Dropdown
                                          className="select-wrapper"
                                          onSelect={(eventKey, event) => {
                                            var item = JSON.parse(eventKey);
                                            console.log(item, '====>');
                                            handlePaymentFrequency(item.name);
                                            setDurationType(item.id);
                                            setDurationItem(item);
                                            setFrequencyData({
                                              ...frequencyData,
                                              duration: item.value,
                                            });
                                            setFrequencyType('1');
                                          }}
                                          drop="down">
                                          <Dropdown.Toggle variant="primary">
                                            {durationType
                                              ? durationArray.find(
                                                  item =>
                                                    item.id === durationType,
                                                ).name
                                              : 'Select Role'}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {durationArray.map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  key={`st-${index}`}
                                                  eventKey={JSON.stringify(
                                                    item,
                                                  )}
                                                  active={
                                                    item.id === durationType
                                                  }>
                                                  {item.name}
                                                </Dropdown.Item>
                                              ),
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>

                                    <div className="flex-1">
                                      <div className="payment-select-frequency-fields">
                                        <Form.Label controlid="validationCustom01">
                                          Payment Frequency
                                        </Form.Label>
                                        <Dropdown
                                          className="select-wrapper"
                                          onSelect={(eventKey, event) => {
                                            var item = JSON.parse(eventKey);
                                            setFrequencyType(item.id);
                                            setFrequencyItem(item);
                                            setFrequencyData({
                                              ...frequencyData,
                                              payment_frequency: item.value,
                                            });
                                          }}
                                          drop="down">
                                          <Dropdown.Toggle variant="primary">
                                            {frequencyType
                                              ? frequencyValues.find(
                                                  item =>
                                                    item.id === frequencyType,
                                                ).name
                                              : 'Select Role'}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {frequencyValues.map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  key={`st-${index}`}
                                                  eventKey={JSON.stringify(
                                                    item,
                                                  )}
                                                  active={
                                                    item.id === frequencyType
                                                  }>
                                                  {item.name}
                                                </Dropdown.Item>
                                              ),
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex gap-4">
                                    <div className="flex-1">
                                      <div className="input-group">
                                        <Form.Label controlid="validationCustom01">
                                          Payment Amount
                                        </Form.Label>

                                        <Form.Control
                                          type="number"
                                          value={frequencyData.payment_amount}
                                          pattern="^\S.*$"
                                          // required={membershipData.levels.length === 0 ? true : false}
                                          placeholder=""
                                          onKeyDown={blockInvalidChar}
                                          onChange={e => {
                                            const {name, value} = e.target;
                                            const regex =
                                              /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                            if (
                                              value === '' ||
                                              regex.test(value) ||
                                              value === '1'
                                            ) {
                                              setFrequencyData({
                                                ...frequencyData,
                                                payment_amount: e.target.value,
                                                payment_milestones: [],
                                              });
                                            }
                                          }}
                                        />

                                        {paymentError &&
                                          frequencyData.payment_amount ===
                                            '' && (
                                            <div className="text-danger textforerror">
                                              Payment is required.
                                            </div>
                                          )}

                                        {/* <Form.Control.Feedback type="invalid">
                               Payment is required.
                             </Form.Control.Feedback> */}
                                      </div>
                                    </div>

                                    <div className="catalog-radio-btns flex-1 d-flex  align-items-center">
                                      <Form.Group className="fieldset payment-checkbox m-0 pt-4">
                                        <Form.Check
                                          // type="radio"
                                          type={'checkbox'}
                                          // name="radioGroup"
                                          label={'Allow Renewal'}
                                          id={'Points'}
                                          value={frequencyData.renewal}
                                          checked={frequencyData.renewal}
                                          onChange={() => {
                                            setFrequencyData({
                                              ...frequencyData,
                                              renewal: !frequencyData.renewal,
                                            });
                                          }}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                  <div className="mb_12">
                                    <Form.Label controlid="validationCustom01">
                                      Payment Type
                                    </Form.Label>
                                    <MultiSelectionDropdown
                                      setDropdownIds={setPaymentTypeId}
                                      dropdownIds={paymentTypeId}
                                      DropDownValues={[
                                        {title: 'All', id: 'all'},
                                        {title: 'One-time', id: 'one_time'},
                                        {
                                          title: 'Subscription',
                                          id: 'subscription',
                                        },
                                        {title: 'Milestone', id: 'milestone'},
                                      ]}
                                      itemName={['title']}
                                      ErrorMessage={
                                        labels.paymentTypeError[selected_lang]
                                      }
                                      DropdownType={'all'}
                                      // className={'mb-3 recruitment_dropdown'}
                                    />
                                  </div>

                                  {paymentTypeId.includes('subscription') && (
                                    <div className="input-group customized-catalog-content-holder">
                                      <Form.Label controlid="validationCustom01">
                                        Subscription
                                      </Form.Label>

                                      <div className="catalog-radio-btns customized-catalog-radio-btns">
                                        {Object.entries(RadioButtonName)
                                          .length > 0 &&
                                          RadioButtonName.map((item, index) => (
                                            <Form.Check
                                              type="radio"
                                              key={item.id + index}
                                              // name="radioGroup"
                                              label={item.label}
                                              id={item.id}
                                              value={item.id}
                                              checked={
                                                subcribtionIdvalue === item.id
                                              }
                                              onChange={handleChange}
                                            />
                                          ))}
                                      </div>
                                    </div>
                                  )}

                                  {paymentTypeId.includes('milestone') && (
                                    <Accordion
                                      className="create-catalogue-accord-holder milestone_accodrdion"
                                      defaultActiveKey={null}>
                                      <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                          {' '}
                                          Payment Milestone
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div className="mb-1 mr-1 d-flex justify-content-end">
                                            <div className="triger_plus_main">
                                              <div
                                                className="triger_plus"
                                                onClick={() => {
                                                  handleDurationMileStone(
                                                    'add',
                                                  );
                                                }}
                                                style={plusIconStyle}>
                                                <i className="fa-solid fa-plus"></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex gap-4">
                                            <div className="flex-1 payment_milestone_label">
                                              <div className="input-group">
                                                <Form.Label controlid="validationCustom01">
                                                  Date
                                                </Form.Label>

                                                {/* <Form.Control
                                                  type="date"
                                                  value={paymentMilestone.date}
                                                  pattern="^\S.*$"
                                                  min={todayDate}
                                                  placeholder=""
                                                  onChange={e => {
                                                    setPaymentMilestone({
                                                      ...paymentMilestone,
                                                      date: e.target.value,
                                                    });
                                                  }}
                                                /> */}

                                                <Calender
                                                  minDate={todayDate}
                                                  maxDate={getMaxDateFromToday}
                                                  setSelectedDate={selectedDate => {
                                                    if (selectedDate) {
                                                      setPaymentMilestone({
                                                        ...paymentMilestone,
                                                        date: selectedDate,
                                                      });
                                                    } else {
                                                      setPaymentMilestone({
                                                        ...paymentMilestone,
                                                        date: '',
                                                      });
                                                    }
                                                  }}
                                                  selectedDate={
                                                    paymentMilestone.date
                                                      ? new Date(
                                                          paymentMilestone.date,
                                                        )
                                                      : null
                                                  }
                                                  showDate={true}
                                                  showTime={false}
                                                />

                                                {/* {levelError && levelData.level_name === '' && (
                                  <div className="text-danger textforerror">
                                    Name is required.
                                  </div>
                                )} */}
                                              </div>
                                            </div>
                                            <div className="flex-1">
                                              <div className="input-group payment_milestone_label">
                                                <Form.Label controlid="validationCustom01">
                                                  Amount
                                                </Form.Label>

                                                <Form.Control
                                                  type="number"
                                                  value={
                                                    paymentMilestone.amount
                                                  }
                                                  pattern="^\S.*$"
                                                  placeholder=""
                                                  onKeyDown={blockInvalidChar}
                                                  onChange={e => {
                                                    const {name, value} =
                                                      e.target;
                                                    const regex =
                                                      /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                                    if (
                                                      value === '' ||
                                                      regex.test(value) ||
                                                      value === '1'
                                                    ) {
                                                      setPaymentMilestone({
                                                        ...paymentMilestone,
                                                        amount: e.target.value,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          {frequencyData.payment_milestones
                                            .length > 0 && (
                                            <div className="mt-3 mb-3">
                                              {Object.entries(
                                                frequencyData.payment_milestones,
                                              ).length > 0 &&
                                                frequencyData.payment_milestones.map(
                                                  (item, index) => {
                                                    return (
                                                      <div
                                                        className="d-flex catalog_product_item payment_milestone_item mb-2"
                                                        key={`level${index}`}>
                                                        <p className="input-label m-0">
                                                          {moment(
                                                            item.date,
                                                          ).format(
                                                            'MM/DD/YYYY',
                                                          )}{' '}
                                                          {'  '}
                                                          (${item.amount})
                                                        </p>
                                                        <div className="text-right mb-2">
                                                          <div className="d-flex gap-2 ">
                                                            <span
                                                              className="poc_delete as-link"
                                                              onClick={() => {
                                                                setDeleteItemIndex(
                                                                  index,
                                                                );
                                                                props.setShowModel(
                                                                  'deleteDuarationMilestone',
                                                                );
                                                                props.setDeleteItemName(
                                                                  'deleteDuarationMilestone',
                                                                );
                                                                //
                                                              }}>
                                                              <i className="fa-solid fa-trash-can" />
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  },
                                                )}
                                            </div>
                                          )}
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  )}
                                </div>

                                <div className="membership_pading mb-3">
                                  {Object.entries(levelData.frequencies)
                                    .length > 0 &&
                                    levelData.frequencies.map((item, index) => {
                                      return (
                                        <div className="d-flex align-items-center justify-content-between">
                                          <p className="input-label m-0">
                                            {getNameOfDuration(item.duration)}{' '}
                                            {'  '}
                                            {item.payment_amount !== ''
                                              ? '$' + item.payment_amount
                                              : 'Free'}
                                          </p>
                                          <div className="text-right mb-2">
                                            <span
                                              className="poc_delete as-link"
                                              onClick={() => {
                                                props.setShowModel(
                                                  'deleteMembershipItem',
                                                );

                                                setDeleteItemIndex(index);
                                                props.setDeleteItemName(
                                                  'Duration',
                                                );

                                                // removePaymentFrequency(index);
                                              }}>
                                              <i className="fa-solid fa-trash-can" />
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                  <Button
                                    className="new-btn btn-green"
                                    onClick={() => {
                                      handleLevels();
                                    }}>
                                    {levelItemIndex !== ''
                                      ? 'Update Level'
                                      : 'Add Level'}
                                  </Button>
                                </div>

                                {catalogData.levels.length > 0 && (
                                  <div className="mt-3 mb-3">
                                    {Object.entries(catalogData.levels).length >
                                      0 &&
                                      catalogData.levels.map((item, index) => {
                                        return (
                                          <div
                                            className="d-flex catalog_product_item mb-2 align-items-start"
                                            key={`level${index}`}>
                                            <p className="input-label m-0 text-limit-paragraph">
                                              {item.level_name}
                                            </p>
                                            <div className="text-right mb-0">
                                              <div className="d-flex gap-2 ">
                                                <span
                                                  className=" as-link"
                                                  onClick={() => {
                                                    setLevelItemIndex(index);
                                                    setDeleteItem(item);
                                                    props.setDeleteItemName(
                                                      'edit_level',
                                                    );
                                                    props.setShowModel(
                                                      'deleteMembershipItem',
                                                    );
                                                    // editMembershipLevel(item);
                                                  }}>
                                                  <EditPencilSvg
                                                    fill={'#52bd41'}
                                                  />
                                                </span>

                                                <span
                                                  className="poc_delete as-link"
                                                  onClick={() => {
                                                    props.setShowModel(
                                                      'deleteMembershipItem',
                                                    );
                                                    setDeleteItemIndex(index);
                                                    props.setDeleteItemName(
                                                      'Level',
                                                    );
                                                    // removeMembershipLevels(
                                                    //   index,
                                                    // );
                                                  }}>
                                                  <i className="fa-solid fa-trash-can" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="input-catalog-text-area-group">
                              <Form.Label controlid="validationCustom01">
                                Description
                              </Form.Label>

                              <Form.Control
                                type="text"
                                as="textarea"
                                rows={6}
                                value={catalogData.description}
                                pattern="^\S.*$"
                                required
                                placeholder=""
                                onChange={e => {
                                  setCatalogData({
                                    ...catalogData,
                                    description: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Description is required.
                              </Form.Control.Feedback>
                            </div>

                            {catalogName === 'Product' && (
                              <>
                                {/* <div className="input-group mb-2">
                                  <div className="d-flex ">
                                    <div className="me-2">
                                      <input
                                        className="form-check-input rounded-circle as-link"
                                        type="checkbox"
                                        onChange={e => {
                                          const newValue = e.target.checked
                                            ? 'yes'
                                            : 'no';
                                          setCatalogData({
                                            ...catalogData,
                                            purchase_order: newValue,
                                            supplier_ids:
                                              newValue === 'yes'
                                                ? [companySuppliers[0]?.id]
                                                : [],
                                          });

                                         
                                        }}
                                        checked={
                                          catalogData.purchase_order === 'yes'
                                            ? true
                                            : false
                                        }
                                        id={`flexCheck`}
                                      />
                                    </div>

                                    <span className="purchase_order_span">
                                      Purchase Orders
                                    </span>
                                    
                                  </div>
                                </div> */}

                                {catalogData.purchase_order === 'yes' &&
                                  (companySuppliers.length > 0 ? (
                                    <div className="mb_12">
                                      <Form.Label controlId="validationCustom01">
                                        Suppliers
                                      </Form.Label>

                                      <Dropdown
                                        className="select-wrapper customized-select-wrapper"
                                        onSelect={(eventKey, event) => {
                                          if (
                                            catalogData.supplier_ids.length ===
                                              1 &&
                                            catalogData.supplier_ids.includes(
                                              eventKey,
                                            )
                                          ) {
                                            errorMessage(
                                              'Atleast one supplier is required',
                                            );
                                          } else {
                                            const groupIds = [
                                              ...catalogData.supplier_ids,
                                            ]; // Copy the existing group ids
                                            if (!groupIds.includes(eventKey)) {
                                              // Add the selected group id if it's not already selected
                                              groupIds.push(eventKey);
                                            } else {
                                              // Remove the selected group id if it's already selected
                                              const index =
                                                groupIds.indexOf(eventKey);
                                              groupIds.splice(index, 1);
                                            }
                                            const newData = {
                                              ...catalogData,
                                              supplier_ids: groupIds,
                                            };
                                            setCatalogData(newData);
                                          }
                                        }}
                                        drop="down">
                                        <Dropdown.Toggle variant="primary">
                                          {catalogData.supplier_ids.length > 0
                                            ? catalogData.supplier_ids
                                                .map(groupId =>
                                                  fixName(
                                                    companySuppliers.find(
                                                      item =>
                                                        item.id === groupId,
                                                    ).first_name,
                                                    companySuppliers.find(
                                                      item =>
                                                        item.id === groupId,
                                                    ).last_name,
                                                  ),
                                                )
                                                .join(', ')
                                            : ' '}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {companySuppliers.map(
                                            (item, index) => (
                                              <Dropdown.Item
                                                key={`sessionCategories-${index}`}
                                                eventKey={item.id}
                                                active={catalogData.supplier_ids.includes(
                                                  item.id,
                                                )}>
                                                {fixName(
                                                  item.first_name,
                                                  item.last_name,
                                                )}
                                                {/* {item.group_name} */}
                                              </Dropdown.Item>
                                            ),
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  ) : (
                                    <div className="filter-btn-wrapper fix-width-forms ">
                                      <div
                                        className="invite-btn text-center  pb-2"
                                        style={{bottom: 0}}>
                                        <Button
                                          style={{padding: '13px 35px'}}
                                          className="btn-dark mb-0 mt-2"
                                          onClick={() => {
                                            props.setShowModel('addSupplier');
                                          }}>
                                          Add Supplier
                                        </Button>
                                      </div>
                                    </div>
                                  ))}
                              </>
                            )}

                            {/* {catalogName !== 'Product' &&
                          catalogName !== 'Service' &&
                          catalogName !== 'Rewards' &&
                          catalogName !== 'Play Center' && (
                            <div className="input-group">
                              <Form.Label controlid="validationCustom01">
                                Condition
                              </Form.Label>

                              <Form.Control
                                type="text"
                                value={catalogData.condition}
                                pattern="^\S.*$"
                                required
                                placeholder=""
                                onChange={e => {
                                  setCatalogData({
                                    ...catalogData,
                                    condition: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Condition is required.
                              </Form.Control.Feedback>
                            </div>
                          )} */}

                            {catalogName === 'RE Listing' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Amenities
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={catalogData.amenities}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      amenities: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Amenities is required.
                                </Form.Control.Feedback>
                              </div>
                            )}

                            {catalogName === 'Rewards' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Retail Value
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={catalogData.retail_value}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      retail_value: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Retail is required.
                                </Form.Control.Feedback>
                              </div>
                            )}
                            {catalogName === 'Rental' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Documents
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={catalogData.documents}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      documents: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Documents is required.
                                </Form.Control.Feedback>
                              </div>
                            )}

                            {catalogName !== 'Membership' && (
                              <div className="mb_12">
                                <Form.Label controlid="validationCustom01">
                                  Audience
                                </Form.Label>
                                {catalogName === 'RE Listing' &&
                                  Object.entries(AudienceTypes).length > 0 && (
                                    <Dropdown
                                      className="select-wrapper"
                                      onSelect={(eventKey, event) => {
                                        setTypeForAudience(eventKey);
                                        setCatalogData({
                                          ...catalogData,
                                          audience: eventKey,
                                        });
                                      }}
                                      drop="down">
                                      <Dropdown.Toggle variant="primary">
                                        {typeForAudience
                                          ? AudienceTypes.find(
                                              item =>
                                                item.value === typeForAudience,
                                            )?.name
                                          : 'Select Role'}
                                      </Dropdown.Toggle>
                                      {/* {catalogName !== 'RE Listing' && (
     <Dropdown.Menu>
       {AudienceTypes.slice(0, 2).map(
         (item, index) => (
           <Dropdown.Item
             key={`sessionCategories-${index}`}
             eventKey={item.value}
             active={
               item.value === typeForAudience
             }>
             {item.name}
           </Dropdown.Item>
         ),
       )}
     </Dropdown.Menu>
   )} */}
                                      {catalogName === 'RE Listing' && (
                                        <Dropdown.Menu>
                                          {AudienceTypes.map((item, index) => (
                                            <Dropdown.Item
                                              key={`sessionCategories-${index}`}
                                              eventKey={item.value}
                                              active={
                                                item.value === typeForAudience
                                              }>
                                              {item.name}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      )}
                                    </Dropdown>
                                  )}

                                {catalogName !== 'RE Listing' &&
                                  Object.entries(props.rolesTypes).length >
                                    0 && (
                                    <Dropdown
                                      className="select-wrapper"
                                      onSelect={(eventKey, event) => {
                                        setTypeForAudience(eventKey);
                                        setCatalogData({
                                          ...catalogData,
                                          audience: eventKey,
                                        });
                                      }}
                                      drop="down">
                                      <Dropdown.Toggle
                                        variant="primary"
                                        id="dropdown-basic">
                                        {typeForAudience
                                          ? props.rolesTypes.find(
                                              item =>
                                                item.id === typeForAudience,
                                            ).role_name
                                          : 'Select Role'}
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {props.rolesTypes.map((item, index) => (
                                          <Dropdown.Item
                                            key={`sessionCategories-${index}`}
                                            eventKey={item.id}
                                            active={
                                              item.id === typeForAudience
                                            }>
                                            {item.role_name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                              </div>
                            )}

                            {/* <div className="input-group">
                        <Form.Label controlid="validationCustom01">
                          Audience
                        </Form.Label>

                        <Form.Control
                          type="number"
                          value={catalogData.audience}
                          // pattern="^\S.*$"
                          required
                          placeholder=""
                          onChange={e => {
                            setCatalogData({
                              ...catalogData,
                              audience: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Audience is required.
                        </Form.Control.Feedback>
                      </div> */}
                            <div className="mb_12">
                              <Form.Label controlid="validationCustom01">
                                Group/Single
                              </Form.Label>

                              <Dropdown
                                className="select-wrapper"
                                onSelect={(eventKey, event) => {
                                  if (eventKey === 'group') {
                                    // if (
                                    //   props.allCatalogData?.service?.length +
                                    //     props.allCatalogData?.product?.length >=
                                    //   2
                                    // ) {
                                    setGroupSingelId(eventKey);
                                    getGroupDetail();
                                    const newData = {
                                      ...catalogData,
                                      // group_ids: [],
                                      group_type: eventKey,
                                    };
                                    setCatalogData(newData);
                                    // } else {
                                    //   errorMessage(
                                    //     'Please add some product to add in group',
                                    //   );
                                    // }
                                  } else {
                                    setGroupSingelId(eventKey);
                                    setShowGroupFields(false);
                                    // setSelctedGroupId(res.all_groups[0]?.id);
                                    const newData = {
                                      ...catalogData,
                                      group_ids: [],
                                      group_type: eventKey,
                                    };
                                    setCatalogData(newData);
                                    setSelectedGroupIds([]);
                                  }
                                }}
                                drop="down">
                                <Dropdown.Toggle
                                  variant="primary"
                                  className="text-container">
                                  {groupSingelId
                                    ? groupSingleArray.find(
                                        item => item.id === groupSingelId,
                                      ).name
                                    : 'Select Role'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {groupSingleArray.map((item, index) => (
                                    <Dropdown.Item
                                      key={`sessionCategories-${index}`}
                                      eventKey={item.id}
                                      active={item.id === groupSingelId}>
                                      {item.name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            {showGroupFields && groupsDeatil.length > 0 && (
                              <div className="mb_12">
                                <Form.Label controlId="validationCustom01">
                                  Groups Name
                                </Form.Label>

                                {/* <Dropdown
                                  className="select-wrapper"
                                  onSelect={(eventKey, event) => {
                                    if (
                                      catalogData.group_ids.length === 1 &&
                                      catalogData.group_ids.includes(eventKey)
                                    ) {
                                      errorMessage(
                                        'Atleast one Group is required',
                                      );
                                    } else {
                                      const groupIds = [
                                        ...catalogData.group_ids,
                                      ]; // Copy the existing group ids
                                      if (!groupIds.includes(eventKey)) {
                                        // Add the selected group id if it's not already selected
                                        groupIds.push(eventKey);
                                      } else {
                                        // Remove the selected group id if it's already selected
                                        const index =
                                          groupIds.indexOf(eventKey);
                                        groupIds.splice(index, 1);
                                      }
                                      const newData = {
                                        ...catalogData,
                                        group_ids: groupIds,
                                      };
                                      setCatalogData(newData);
                                    }
                                    // else{
                                    //   errorMessage('Atleast one Group is required')
                                    // }
                                  }}
                                  drop="down">
                                  <Dropdown.Toggle variant="primary">
                                    {catalogData.group_ids.length > 0
                                      ? catalogData.group_ids
                                          .map(
                                            groupId =>
                                              groupsDeatil.find(
                                                item => item.id === groupId,
                                              ).group_name,
                                          )
                                          .join(', ')
                                      : 'Select Groups'}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {groupsDeatil.map((item, index) => (
                                      <Dropdown.Item
                                        key={`sessionCategories-${index}`}
                                        eventKey={item.id}
                                        active={catalogData.group_ids.includes(
                                          item.id,
                                        )}>
                                        {item.group_name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown> */}
                                <MultiSelectionDropdown
                                  setDropdownIds={setSelectedGroupIds}
                                  dropdownIds={selectedGroupIds}
                                  DropDownValues={groupsDeatil}
                                  itemName={['group_name']}
                                  ErrorMessage={
                                    labels.groupRequired[selected_lang]
                                  }
                                  DropdownType={'all'}
                                />
                              </div>
                            )}
                            {showGroupFields && groupsDeatil.length === 0 && (
                              <div className="add_group_button">
                                <Button
                                  onClick={() => {
                                    props.setShowModel('add_group');
                                  }}>
                                  Add Group
                                </Button>
                              </div>
                            )}
                            {catalogName === 'Equipment' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Responsible Party
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={catalogData.responsible_party}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      responsible_party: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Field required.
                                </Form.Control.Feedback>
                              </div>
                            )}
                            {catalogName !== 'Equipment' &&
                              catalogName !== 'RE Listing' &&
                              catalogName !== 'Membership' && (
                                <div className="mb_12">
                                  <Form.Label controlid="validationCustom01">
                                    Delievery Method (virtual,in-person)
                                  </Form.Label>

                                  {catalogName !== 'Play Center' &&
                                    catalogName !== 'Rewards' &&
                                    Object.entries(DelieveryTypes).length >
                                      0 && (
                                      <Dropdown
                                        className="select-wrapper"
                                        onSelect={(eventKey, event) => {
                                          setTypeForDelievery(eventKey);
                                          setCatalogData({
                                            ...catalogData,
                                            delievery: eventKey,
                                          });
                                        }}
                                        drop="down">
                                        <Dropdown.Toggle variant="primary">
                                          {typeForDelievery
                                            ? DelieveryTypes.find(
                                                item =>
                                                  item.value ===
                                                  typeForDelievery,
                                              )?.name
                                            : 'Select Role'}
                                        </Dropdown.Toggle>

                                        {catalogName === 'Service' && (
                                          <Dropdown.Menu>
                                            {DelieveryTypes.slice(0, 2).map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  key={`sessionCategories-${index}`}
                                                  eventKey={item.value}
                                                  active={
                                                    item.value ===
                                                    typeForDelievery
                                                  }>
                                                  {item.name}
                                                </Dropdown.Item>
                                              ),
                                            )}
                                          </Dropdown.Menu>
                                        )}

                                        {catalogName === 'Product' && (
                                          <Dropdown.Menu>
                                            {DelieveryTypes.slice(0, 3).map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  key={`sessionCategories-${index}`}
                                                  eventKey={item.value}
                                                  active={
                                                    item.value ===
                                                    typeForDelievery
                                                  }>
                                                  {item.name}
                                                </Dropdown.Item>
                                              ),
                                            )}
                                          </Dropdown.Menu>
                                        )}

                                        {(catalogName === 'Rental' ||
                                          catalogName === 'Material' ||
                                          catalogName === 'Fleet') && (
                                          <Dropdown.Menu>
                                            {DelieveryTypes.slice(4, 6).map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  key={`sessionCategories-${index}`}
                                                  eventKey={item.value}
                                                  active={
                                                    item.value ===
                                                    typeForDelievery
                                                  }>
                                                  {item.name}
                                                </Dropdown.Item>
                                              ),
                                            )}
                                          </Dropdown.Menu>
                                        )}

                                        {catalogName === 'Rewards' && (
                                          <Dropdown.Menu>
                                            {DelieveryTypes.slice(0, 4).map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  key={`sessionCategories-${index}`}
                                                  eventKey={item.value}
                                                  active={
                                                    item.value ===
                                                    typeForDelievery
                                                  }>
                                                  {item.name}
                                                </Dropdown.Item>
                                              ),
                                            )}
                                          </Dropdown.Menu>
                                        )}

                                        {catalogName === 'Play Center' && (
                                          <Dropdown.Menu>
                                            {DelieveryTypes.slice(0, 4).map(
                                              (item, index) => (
                                                <Dropdown.Item
                                                  key={`sessionCategories-${index}`}
                                                  eventKey={item.value}
                                                  active={
                                                    item.value ===
                                                    typeForDelievery
                                                  }>
                                                  {item.name}
                                                </Dropdown.Item>
                                              ),
                                            )}
                                          </Dropdown.Menu>
                                        )}

                                        {/* {catalogName == 'RE Listing' && (
                              <Dropdown.Menu>
                                {DelieveryTypes.slice(0, 1).map((item, index) => (
                                  <Dropdown.Item
                                    key={`sessionCategories-${index}`}
                                    eventKey={item.value}
                                    active={item.value == typeForDelievery}>
                                    {item.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            )} */}
                                      </Dropdown>
                                    )}

                                  {(catalogName === 'Play Center' ||
                                    catalogName === 'Rewards') &&
                                    Object.entries(DelieveryTypes2).length >
                                      0 && (
                                      <Dropdown
                                        className="select-wrapper"
                                        onSelect={(eventKey, event) => {
                                          setTypeForDelievery(eventKey);
                                          setCatalogData({
                                            ...catalogData,
                                            delievery: eventKey,
                                          });
                                        }}
                                        drop="down">
                                        <Dropdown.Toggle variant="primary">
                                          {typeForDelievery
                                            ? DelieveryTypes2.find(
                                                item =>
                                                  item.value ===
                                                  typeForDelievery,
                                              )?.name
                                            : 'Select Role'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {DelieveryTypes2.map(
                                            (item, index) => (
                                              <Dropdown.Item
                                                key={`sessionCategories-${index}`}
                                                eventKey={item.value}
                                                active={
                                                  item.value ===
                                                  typeForDelievery
                                                }>
                                                {item.name}
                                              </Dropdown.Item>
                                            ),
                                          )}
                                        </Dropdown.Menu>

                                        {/* {catalogName == 'RE Listing' && (
                              <Dropdown.Menu>
                                {DelieveryTypes.slice(0, 1).map((item, index) => (
                                  <Dropdown.Item
                                    key={`sessionCategories-${index}`}
                                    eventKey={item.value}
                                    active={item.value == typeForDelievery}>
                                    {item.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            )} */}
                                      </Dropdown>
                                    )}

                                  {/* <Form.Control
                          type="text"
                          value={catalogData.delievery}
                          pattern="^\S.*$"
                          required
                          placeholder=""
                          onChange={e => {
                            setCatalogData({
                              ...catalogData,
                              delievery: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Delievery Method is required.
                        </Form.Control.Feedback> */}
                                </div>
                              )}

                            <div className="input-group customized-input-group mb_12">
                              <Form.Label controlid="validationCustom01">
                                {' '}
                                Taxes{' '}
                              </Form.Label>

                              <Form.Control
                                type="number"
                                value={catalogData.taxes_fee}
                                pattern="^\S.*$"
                                placeholder=""
                                onChange={e => {
                                  setCatalogData({
                                    ...catalogData,
                                    taxes_fee: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Taxes is required.
                              </Form.Control.Feedback>
                            </div>
                            <div className="input-group customized-input-group mb_12">
                              <Form.Label controlid="validationCustom01">
                                {' '}
                                Discount{' '}
                              </Form.Label>

                              <Form.Control
                                type="number"
                                value={catalogData.discount}
                                pattern="^\S.*$"
                                placeholder=""
                                onChange={e => {
                                  setCatalogData({
                                    ...catalogData,
                                    discount: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Discount is required.
                              </Form.Control.Feedback>
                            </div>
                            {catalogName === 'Product' && (
                              <div className="input-group customized-input-group mb_12">
                                <Form.Label controlid="validationCustom01">
                                  {' '}
                                  Shipment{' '}
                                </Form.Label>

                                <Form.Control
                                  type="number"
                                  value={catalogData.shipping_handling}
                                  pattern="^\S.*$"
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      shipping_handling: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Shipment is required.
                                </Form.Control.Feedback>
                              </div>
                            )}

                            {catalogName !== 'Product' && (
                              <div className="input-group customized-input-group mb_12">
                                <Form.Label controlid="validationCustom01">
                                  {' '}
                                  Late Fee{' '}
                                </Form.Label>

                                <Form.Control
                                  type="number"
                                  value={catalogData.late_fee}
                                  pattern="^\S.*$"
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      late_fee: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Late Fee is required.
                                </Form.Control.Feedback>
                              </div>
                            )}

                            {catalogName !== 'Product' && (
                              // <div className="input-group customized-input-group mb_12">
                              //   <Form.Label controlid="validationCustom01">
                              //     {' '}
                              //     Grace Period{' '}
                              //   </Form.Label>

                              //   <Form.Control
                              //     type="date"
                              //     value={catalogData.grace_period}
                              //     pattern="^\S.*$"
                              //     placeholder=""
                              //     onChange={e => {
                              //       setCatalogData({
                              //         ...catalogData,
                              //         grace_period: e.target.value,
                              //       });
                              //     }}
                              //   />
                              //   <Form.Control.Feedback type="invalid">
                              //     Grace Period is required.
                              //   </Form.Control.Feedback>
                              // </div>
                              <div className="mb-3">
                                <Form.Label controlid="validationCustom01">
                                  Grace Period{' '}
                                </Form.Label>
                                <Dropdown
                                  className="select-wrapper"
                                  onSelect={(eventKey, event) => {
                                    setCatalogData({
                                      ...catalogData,
                                      grace_period: eventKey,
                                    });
                                    setGracePeriodType(eventKey);
                                  }}
                                  drop="down">
                                  <Dropdown.Toggle variant="primary">
                                    {monthDays
                                      ? monthDays.find(
                                          item => item === gracePeriodType,
                                        )
                                      : 'Select Role'}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {monthDays.map((item, index) => (
                                      <Dropdown.Item
                                        key={`st-${index}`}
                                        eventKey={item}
                                        active={item === gracePeriodType}>
                                        {item}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )}

                            {catalogName === 'Play Center' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Media Type
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={catalogData.media_type}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      media_type: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Media is required.
                                </Form.Control.Feedback>
                              </div>
                            )}
                            {catalogName !== 'Service' &&
                              catalogName !== 'RE Listing' &&
                              catalogName !== 'Rewards' &&
                              catalogName !== 'Play Center' &&
                              catalogName !== 'Membership' && (
                                <div className="input-group">
                                  <Form.Label controlid="validationCustom01">
                                    {catalogName === 'Equipment'
                                      ? 'Quantity'
                                      : 'Quantity Available'}
                                  </Form.Label>

                                  <Form.Control
                                    // type="text"
                                    type="number"
                                    onWheel={event =>
                                      event.currentTarget.blur()
                                    }
                                    value={catalogData.quantity_available}
                                    pattern="^\S.*$"
                                    required
                                    placeholder=""
                                    onChange={e => {
                                      setCatalogData({
                                        ...catalogData,
                                        quantity_available: e.target.value,
                                      });
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Quantity is required.
                                  </Form.Control.Feedback>
                                </div>
                              )}

                            {catalogName === 'Service' && (
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Duration (mins)
                                </Form.Label>

                                <Form.Control
                                  type="number"
                                  onWheel={event => event.currentTarget.blur()}
                                  value={catalogData.duration}
                                  pattern="^\S.*$"
                                  required
                                  placeholder=""
                                  onChange={e => {
                                    setCatalogData({
                                      ...catalogData,
                                      duration: e.target.value,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Duration is required.
                                </Form.Control.Feedback>
                              </div>
                            )}

                            {catalogName !== 'Play Center' &&
                              catalogName !== 'Membership' && (
                                <div className="input-group">
                                  <Form.Label controlid="validationCustom01">
                                    {catalogName === 'Rewards'
                                      ? 'Recipient Cost'
                                      : catalogName !== 'Rental' &&
                                        catalogName !== 'Material' &&
                                        catalogName !== 'Equipment' &&
                                        catalogName !== 'Fleet'
                                      ? 'Cost'
                                      : 'Replacement Cost'}
                                  </Form.Label>

                                  <Form.Control
                                    // type="text"
                                    value={catalogData.cost}
                                    // pattern="^\S.*$"
                                    type="number"
                                    onWheel={event =>
                                      event.currentTarget.blur()
                                    }
                                    required
                                    placeholder=""
                                    onKeyDown={blockInvalidChar}
                                    onChange={e => {
                                      const {name, value} = e.target;
                                      const regex =
                                        /^(?!0(?!\.))\d*(\.\d{0,2})?$/;
                                      // const regex = /^(?!.*--)(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                      if (
                                        value === '' ||
                                        regex.test(value) ||
                                        value === '1'
                                      ) {
                                        catalogName === 'Rewards'
                                          ? setCatalogData({
                                              ...catalogData,
                                              recipient_cost: e.target.value,
                                            })
                                          : catalogName !== 'Rental' &&
                                            catalogName !== 'Material' &&
                                            catalogName !== 'Equipment'
                                          ? setCatalogData({
                                              ...catalogData,
                                              cost: e.target.value,
                                            })
                                          : setCatalogData({
                                              ...catalogData,
                                              replacement_cost: e.target.value,
                                            });
                                      }
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Cost is required.
                                  </Form.Control.Feedback>
                                </div>
                              )}

                            {catalogName !== 'Membership' && (
                              <div className="input-group customized-input-group mb_12">
                                <Form.Label controlid="validationCustom01">
                                  Payment Type
                                </Form.Label>
                                <MultiSelectionDropdown
                                  setDropdownIds={setPaymentTypeId}
                                  dropdownIds={paymentTypeId}
                                  DropDownValues={[
                                    {title: 'All', id: 'all'},
                                    {title: 'One-time', id: 'one_time'},
                                    {title: 'Subscription', id: 'subscription'},
                                    {title: 'Milestone', id: 'milestone'},
                                  ]}
                                  itemName={['title']}
                                  ErrorMessage={
                                    labels.paymentTypeError[selected_lang]
                                  }
                                  DropdownType={'all'}
                                  // className={'mb-3 recruitment_dropdown'}
                                />
                              </div>
                            )}

                            {catalogName !== 'Membership' &&
                              paymentTypeId.includes('milestone') && (
                                <Accordion
                                  className="create-catalogue-accord-holder milestone_accodrdion"
                                  defaultActiveKey={null}>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      {' '}
                                      Payment Milestone
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="mb-1 mr-1 d-flex justify-content-end">
                                        <div className="triger_plus_main">
                                          <div
                                            className="triger_plus"
                                            onClick={() => {
                                              handlePaymentMileStone('add');
                                            }}
                                            style={plusIconStyle}>
                                            <i className="fa-solid fa-plus"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex gap-4">
                                        <div className="flex-1 payment_milestone_label">
                                          <div className="input-group">
                                            <Form.Label controlid="validationCustom01">
                                              Date
                                            </Form.Label>

                                            {/* <Form.Control
                                              type="date"
                                              min={todayDate}
                                              value={paymentMilestone.date}
                                              pattern="^\S.*$"
                                              placeholder=""
                                              onChange={e => {
                                                setPaymentMilestone({
                                                  ...paymentMilestone,
                                                  date: e.target.value,
                                                });
                                              }}
                                            /> */}

                                            <Calender
                                              minDate={todayDate}
                                              maxDate={getMaxDateFromToday}
                                              setSelectedDate={selectedDate => {
                                                if (selectedDate) {
                                                  setPaymentMilestone({
                                                    ...paymentMilestone,
                                                    date: selectedDate,
                                                  });
                                                  // setDate(selectedDate);
                                                } else {
                                                  setPaymentMilestone({
                                                    ...paymentMilestone,
                                                    date: '',
                                                  });
                                                }
                                              }}
                                              selectedDate={
                                                paymentMilestone.date
                                                  ? new Date(
                                                      paymentMilestone.date,
                                                    )
                                                  : null
                                              }
                                              showDate={true}
                                              showTime={false}
                                            />

                                            {/* {levelError && levelData.level_name === '' && (
                                  <div className="text-danger textforerror">
                                    Name is required.
                                  </div>
                                )} */}
                                          </div>
                                        </div>
                                        <div className="flex-1">
                                          <div className="input-group payment_milestone_label">
                                            <Form.Label controlid="validationCustom01">
                                              Amount
                                            </Form.Label>

                                            <Form.Control
                                              type="number"
                                              value={paymentMilestone.amount}
                                              pattern="^\S.*$"
                                              placeholder=""
                                              onKeyDown={blockInvalidChar}
                                              onChange={e => {
                                                const {name, value} = e.target;
                                                const regex =
                                                  /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                                if (
                                                  value === '' ||
                                                  regex.test(value) ||
                                                  value === '1'
                                                ) {
                                                  setPaymentMilestone({
                                                    ...paymentMilestone,
                                                    amount: e.target.value,
                                                  });
                                                }
                                              }}
                                            />

                                            {levelError &&
                                              levelData.level_name === '' && (
                                                <div className="text-danger textforerror">
                                                  Amount is required.
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>

                                      {catalogData.payment_milestones.length >
                                        0 && (
                                        <div className="mt-3 mb-3">
                                          {Object.entries(
                                            catalogData.payment_milestones,
                                          ).length > 0 &&
                                            catalogData.payment_milestones.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    className="d-flex catalog_product_item mb-2"
                                                    key={`level${index}`}>
                                                    <p className="input-label m-0">
                                                      {moment(item.date).format(
                                                        'MM/DD/YYYY',
                                                      )}{' '}
                                                      {'  '}
                                                      (${item.amount})
                                                    </p>
                                                    <div className="text-right mb-2">
                                                      <div className="d-flex gap-2 ">
                                                        <span
                                                          className="poc_delete as-link"
                                                          onClick={() => {
                                                            setDeleteItemIndex(
                                                              index,
                                                            );
                                                            props.setShowModel(
                                                              'deleteMilestonePayment',
                                                            );
                                                            props.setDeleteItemName(
                                                              'Milestone Payment',
                                                            );
                                                          }}>
                                                          <i className="fa-solid fa-trash-can" />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              },
                                            )}
                                        </div>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              )}

                            {/* {catalogName !== 'Rewards' && (
                          <Accordion alwaysOpen defaultActiveKey={['0']}>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header> Rental</Accordion.Header>
                              <Accordion.Body>
                                <div className="input-group">
                                  <Form.Label controlid="validationCustom01">
                                    Condition
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    value={catalogData.condition}
                                    pattern="^\S.*$"
                                    required
                                    placeholder=""
                                    onChange={e => {
                                      setCatalogData({
                                        ...catalogData,
                                        condition: e.target.value,
                                      });
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Condition is required.
                                  </Form.Control.Feedback>
                                </div>

                                <div className="input-group">
                                  <Form.Label controlid="validationCustom01">
                                    Rental Fee
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    value={catalogData.rentalFee}
                                    pattern="^\S.*$"
                                    required
                                    placeholder=""
                                    onChange={e => {
                                      setCatalogData({
                                        ...catalogData,
                                        rentalFee: e.target.value,
                                      });
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Rental is required.
                                  </Form.Control.Feedback>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )} */}

                            <Accordion
                              defaultActiveKey={null}
                              className="create-catalogue-accord-holder">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header> Advanced</Accordion.Header>
                                <Accordion.Body>
                                  {catalogName === 'Material' ||
                                    (catalogName === 'Fleet' && (
                                      <div className="input-group">
                                        <Form.Label controlid="validationCustom01">
                                          Restock Frequency
                                        </Form.Label>

                                        <Form.Control
                                          type="text"
                                          value={catalogData.restock}
                                          pattern="^\S.*$"
                                          required
                                          placeholder=""
                                          onChange={e => {
                                            setCatalogData({
                                              ...catalogData,
                                              restock: e.target.value,
                                            });
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Restock is required.
                                        </Form.Control.Feedback>
                                      </div>
                                    ))}
                                  {paymentTypeId.includes('subscription') &&
                                    catalogName !== 'RE Listing' &&
                                    catalogName !== 'Rewards' &&
                                    catalogName !== 'Play Center' &&
                                    // catalogName !== 'Product' &&
                                    catalogName !== 'Membership' && (
                                      <div className="input-group customized-catalog-content-holder">
                                        <Form.Label controlid="validationCustom01">
                                          Subscription
                                        </Form.Label>

                                        <div className="catalog-radio-btns customized-catalog-radio-btns">
                                          {Object.entries(RadioButtonName)
                                            .length > 0 &&
                                            RadioButtonName.map(
                                              (item, index) => (
                                                <Form.Check
                                                  key={index + item.id}
                                                  type="radio"
                                                  // name="radioGroup"
                                                  label={item.label}
                                                  id={item.id}
                                                  value={item.id}
                                                  checked={
                                                    subcribtionIdvalue ===
                                                    item.id
                                                  }
                                                  onChange={handleChange}
                                                />
                                              ),
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  <div className="input-group incentives-group-holder">
                                    <Form.Label controlid="validationCustom01">
                                      {catalogName !== 'Play Center'
                                        ? 'Incentives'
                                        : 'Completion Incentives'}
                                    </Form.Label>

                                    <div className="input-group">
                                      <div className="catalog-radio-btns customized-catalog-radio-btns customized-catalog-radio-boxes">
                                        <Form.Group className="fieldset payment-checkbox">
                                          <Form.Check
                                            // type="radio"
                                            type={'checkbox'}
                                            // name="radioGroup"
                                            label={'Points'}
                                            id={'Points'}
                                            value={'Points'}
                                            checked={incentiveValues.includes(
                                              'Points',
                                            )}
                                            onChange={handleIncentivesValues}
                                          />
                                        </Form.Group>

                                        <Form.Group className="fieldset payment-checkbox">
                                          <Form.Check
                                            // type="radio"
                                            type={'checkbox'}
                                            // name="radioGroup"
                                            label={'Money'}
                                            id={'Money'}
                                            value={'Money'}
                                            checked={incentiveValues.includes(
                                              'Money',
                                            )}
                                            onChange={handleIncentivesValues}
                                          />
                                        </Form.Group>

                                        <div className="catalog-incentive-dropdown"></div>
                                      </div>
                                    </div>
                                  </div>

                                  {incentiveValues.includes('Points') && (
                                    <>
                                      <div className="d-flex gap-2">
                                        <div className="input-group">
                                          <Form.Label controlid="validationCustom01">
                                            Roles
                                          </Form.Label>
                                          {Object.entries(props.aliasRoles)
                                            .length > 0 && (
                                            <Dropdown
                                              className="select-wrapper"
                                              onSelect={(eventKey, event) => {
                                                setTypeForAliasRole(eventKey);
                                              }}
                                              drop="down"
                                              //  style={{width:'50%'}}
                                            >
                                              <Dropdown.Toggle variant="primary">
                                                {typeForAliasRole
                                                  ? capitalizeFirst(
                                                      props.aliasRoles.find(
                                                        item =>
                                                          item.id ===
                                                          typeForAliasRole,
                                                      )?.role_alias,
                                                    )
                                                  : 'Select Role'}
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                {props.aliasRoles.map(
                                                  (item, index) => (
                                                    <Dropdown.Item
                                                      key={`sessionCategories-${index}`}
                                                      eventKey={item.id}
                                                      active={
                                                        item.id ===
                                                        typeForAliasRole
                                                      }>
                                                      {capitalizeFirst(
                                                        item.role_alias,
                                                      )}
                                                    </Dropdown.Item>
                                                  ),
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          )}
                                        </div>

                                        <div className="input-group">
                                          <Form.Label controlid="validationCustom01">
                                            Points
                                          </Form.Label>

                                          <Form.Control
                                            // type="text"
                                            value={pointsValue}
                                            // pattern="^\S.*$"
                                            novalidate
                                            type="number"
                                            onWheel={event =>
                                              event.currentTarget.blur()
                                            }
                                            placeholder=""
                                            onKeyDown={blockInvalidChar}
                                            onChange={e => {
                                              const {name, value} = e.target;
                                              const regex =
                                                /^(?!0(?!\.))\d*(\.\d{0,2})?$/;
                                              // const regex = /^(?!.*--)(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                              if (
                                                value === '' ||
                                                regex.test(value) ||
                                                value === '1'
                                              ) {
                                                setPointsValue(value);
                                              }
                                            }}
                                          />
                                          {/* <Form.Control.Feedback type="invalid">
                                  Cost is required.
                                </Form.Control.Feedback> */}
                                        </div>

                                        <div className="triger_plus_main triger_plus_setting">
                                          <div
                                            className="triger_plus"
                                            onClick={() => {
                                              if (pointsValue !== '') {
                                                handlePointsMoney(
                                                  typeForAliasRole,
                                                  pointsValue,
                                                );
                                              } else {
                                                errorMessage(
                                                  'Please Add Points',
                                                );
                                              }
                                            }}>
                                            <i className="fa-solid fa-plus"></i>
                                          </div>
                                        </div>
                                      </div>

                                      {rolesPointsMoney.length > 0 && (
                                        <table className="location-table">
                                          <thead>
                                            <tr>
                                              <th>Roles</th>
                                              <th>Points</th>
                                              <th>Delete</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Object.entries(rolesPointsMoney)
                                              .length > 0 &&
                                              rolesPointsMoney.map(
                                                (item, index) => (
                                                  <tr
                                                    key={`label-${index}`}
                                                    className="location-item">
                                                    <td
                                                      onClick={() => {
                                                        // removeLocation(item.latitude);
                                                        // setShowModel(true);
                                                      }}>
                                                      {item.alias_name}
                                                    </td>
                                                    <td
                                                      onClick={() => {
                                                        // removeLocation(item.latitude);
                                                        // setShowModel(true);
                                                      }}>
                                                      {item.points}
                                                    </td>
                                                    <td>
                                                      <i
                                                        className="fas fa-times as-link"
                                                        onClick={() => {
                                                          removeAssignPointsRoles(
                                                            item.alias_id,
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                  </tr>
                                                ),
                                              )}
                                          </tbody>
                                        </table>
                                      )}
                                    </>
                                  )}

                                  {incentiveValues.includes('Money') && (
                                    <div className="mt-3">
                                      <div className="d-flex gap-2">
                                        <div className="input-group">
                                          <Form.Label controlid="validationCustom01">
                                            Roles
                                          </Form.Label>
                                          {Object.entries(props.aliasRoles)
                                            .length > 0 && (
                                            <Dropdown
                                              className="select-wrapper"
                                              onSelect={(eventKey, event) => {
                                                setTypeForAliasRoleM(eventKey);
                                              }}
                                              drop="down"
                                              //  style={{width:'50%'}}
                                            >
                                              <Dropdown.Toggle variant="primary">
                                                {typeForAliasRoleM
                                                  ? capitalizeFirst(
                                                      props.aliasRoles.find(
                                                        item =>
                                                          item.id ===
                                                          typeForAliasRoleM,
                                                      )?.role_alias,
                                                    )
                                                  : 'Select Role'}
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                {props.aliasRoles.map(
                                                  (item, index) => (
                                                    <Dropdown.Item
                                                      key={`sessionCategories-${index}`}
                                                      eventKey={item.id}
                                                      active={
                                                        item.id ===
                                                        typeForAliasRoleM
                                                      }>
                                                      {capitalizeFirst(
                                                        item.role_alias,
                                                      )}
                                                    </Dropdown.Item>
                                                  ),
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          )}
                                        </div>

                                        <div className="input-group">
                                          <Form.Label controlid="validationCustom01">
                                            Money
                                          </Form.Label>

                                          <Form.Control
                                            // type="text"
                                            value={moneyValue}
                                            // pattern="^\S.*$"
                                            novalidate
                                            type="number"
                                            onWheel={event =>
                                              event.currentTarget.blur()
                                            }
                                            placeholder=""
                                            onKeyDown={blockInvalidChar}
                                            onChange={e => {
                                              const {name, value} = e.target;
                                              const regex =
                                                /^(?!0(?!\.))\d*(\.\d{0,2})?$/;
                                              // const regex = /^(?!.*--)(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                              if (
                                                value === '' ||
                                                regex.test(value) ||
                                                value === '1'
                                              ) {
                                                setMoneyValue(value);
                                              }
                                            }}
                                          />
                                          {/* <Form.Control.Feedback type="invalid">
                                  Cost is required.
                                </Form.Control.Feedback> */}
                                        </div>

                                        <div className="triger_plus_main triger_plus_setting">
                                          <div
                                            className="triger_plus"
                                            onClick={() => {
                                              if (moneyValue !== '') {
                                                handleMoney(
                                                  typeForAliasRoleM,
                                                  moneyValue,
                                                );
                                              } else {
                                                errorMessage(
                                                  'Please Add Money',
                                                );
                                              }
                                            }}>
                                            <i className="fa-solid fa-plus"></i>
                                          </div>
                                        </div>
                                      </div>

                                      {rolesAssignMoney.length > 0 && (
                                        <table className="location-table">
                                          <thead>
                                            <tr>
                                              <th>Roles</th>
                                              <th>Money</th>
                                              <th>Delete</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Object.entries(rolesAssignMoney)
                                              .length > 0 &&
                                              rolesAssignMoney.map(
                                                (item, index) => (
                                                  <tr
                                                    key={`label-${index}`}
                                                    className="location-item">
                                                    <td onClick={() => {}}>
                                                      {item.alias_name}
                                                    </td>
                                                    <td onClick={() => {}}>
                                                      {item.money}
                                                    </td>
                                                    <td>
                                                      <i
                                                        className="fas fa-times as-link"
                                                        onClick={() => {
                                                          removeAssignMoneyRoles(
                                                            item.alias_id,
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                  </tr>
                                                ),
                                              )}
                                          </tbody>
                                        </table>
                                      )}
                                    </div>
                                  )}

                                  {/* {catalogName === 'Product' && (
                                    <div className="input-group">
                                      <Form.Label controlid="validationCustom01">
                                        Variations
                                      </Form.Label>

                                      <Form.Control
                                        type="text"
                                        value={catalogData.variation}
                                        pattern="^\S.*$"
                                        // required
                                        placeholder=""
                                        onChange={e => {
                                          setCatalogData({
                                            ...catalogData,
                                            variation: e.target.value,
                                          });
                                        }}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Variations is required.
                                      </Form.Control.Feedback>
                                    </div>
                                  )} */}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>

                            {Object.keys(catalogEditItem).length === 0 && (
                              <Button
                                className="new-btn btn-green"
                                type="sumbit">
                                Add
                              </Button>
                            )}
                          </div>
                        </Form>

                        {Object.keys(catalogEditItem).length > 0 && (
                          <div
                            className="filter-btn-wrapper create-session-btn  "
                            style={{bottom: '0px'}}>
                            <div className="invite-btns">
                              <Button
                                className="mb-0"
                                onClick={() => {
                                  updateCatalogItem();
                                }}>
                                Update {catalogName}
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {/* {membershipModal && (
                <div style={{flex: 2}}>
                  <span
                    className="close as-link"
                    style={{float: 'right', marginRight: '30px'}}
                    onClick={() => {
                      setMembershipModal(false);
                    }}>
                    <i className="fas fa-times" />
                  </span>
                  <div style={{marginLeft: '8%', width: '100%'}}>
                    <MemberShip />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {/* {showModal ==='levels' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModal('');
            
          }}
          removeheight={true}>
          <div className="new-session-modal-wrapper text-center createSessionModal eventBrite-Modal">
            <div className="new-session-modal">
            {Object.entries(catalogData.levels)
                                    .length > 0 &&
                                    catalogData.levels.map((item, index) => {
                                      return (
                                        <div className="d-flex align-items-center justify-content-between">
                                          <p className="input-label m-0">
                                            {item.level_name}
                                            
                                          </p>
                                          <div className="text-right mb-2">
                                            <span className="circle-link-icon">
                                              <span
                                                className="as-link remove-selected-actions"
                                                onClick={() => {
                                                  
                                                }}>
                                                <i class="fa-solid fa-circle-xmark"></i>
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
            </div>
          </div>
        </CustomModal>
      )} */}
    </>
  );
};

export default Catalog;
