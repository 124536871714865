import React, {useEffect, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';

import {errorMessage} from '../../Utils/ToastMessages';

const FocusListItems = ({
  items,
  active,
  showTooltip,
  updateActive,
  maxItem,
  titleLable,
}) => {
  const [activeItems, setactiveItems] = useState([]);

  const [numToShow, setNumToShow] = useState(5); // default value to show 5 items

  const handleClick = () => {
    setNumToShow(numToShow === 5 ? items.length : 5); // toggle between showing 5 or all items
  };

  const updateSelected = id => {
    let tempList = [...activeItems];

    if (tempList.includes(id)) {
      tempList = tempList.filter(item => item !== id);
    } else if (maxItem && tempList.length >= maxItem) {
      errorMessage(`You can only select ${maxItem} items.`);
    } else {
      tempList.push(id);
    }

    updateActive(tempList);
  };

  useEffect(() => {
    setactiveItems(active);
  }, [active]);

  return (
    <div>
      <ul>
        {items.slice(0, numToShow).map((item, index) => {
          const isActive = activeItems.includes(item.id) ? 'active' : '';
          let itemTitle = titleLable
            ? item[titleLable]
            : item.title
            ? item.title
            : item.appointment_type;
          return (
            <li
              className={isActive}
              key={`boarding-item-${item.id}-${index}`}
              onClick={() => updateSelected(item.id)}>
              {showTooltip && item.description !== '' && (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{item.description}</Tooltip>}>
                  <span>{itemTitle}</span>
                </OverlayTrigger>
              )}
              {!showTooltip && itemTitle}

              {activeItems.includes(item.id) && (
                <span className="check-icon">
                  <i className="fas fa-check" />
                </span>
              )}
            </li>
          );
        })}
      </ul>
      <div className="read-more-read-less">
        <button className="btn" onClick={handleClick} style={{margin: 'auto'}}>
          {' '}
          {numToShow === 5
            ? labels.show_more[selected_lang]
            : labels.show_less[selected_lang]}
        </button>
      </div>
      {/* {items.length > numToShow && (
      <button onClick={handleClick}>
        {numToShow === 5 ? 'Show More' : 'Show Less'}
      </button>
      )} */}
    </div>
  );
};

export default FocusListItems;
