import React from 'react';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import {errorMessage} from '../../Utils/ToastMessages';
import SlidingPanel from '../SlidingPanel';

const ReferNotes = props => {
  const submit = e => {
    if (props.notes === '') {
      errorMessage('Notes are required.');
    } else {
      props.onClose();
    }
  };
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
        <div className="serach-filter-wrapper">
          <div className="sidebar-heading-wrapper">
            <div className="heading">
              <h2>Notes</h2>
            </div>
          </div>
          <Scrollbars>
            {/* <span
        className="close as-link"
        onClick={() => {
          props.onClose();
        }}>
        <i className="fas fa-times" />
      </span> */}
            <div className="fix-width-forms">
              <div className="form-outline">
                <Form>
                  <textarea
                    disabled={props.disabled}
                    className="form-control"
                    id="textAreaExample1"
                    rows="12"
                    placeholder="Write a note..."
                    value={!props.notesF ? props.notes : props.notesF}
                    onChange={e => {
                      props.setNotes(e.target.value);
                    }}></textarea>
                </Form>
              </div>
              {!props.disabled && (
                <div className="filter-btn-wrapper">
                  <div className="invite-btns text-left">
                    <Button type="submit" onClick={submit}>
                      Submit
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>
    </>
  );
};
export default ReferNotes;
