import React, {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {DEFAULT_IMAGE} from '../../Utils/env';

import {fixName} from '../../Utils/commonUtils';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import {AddContact, select_icon} from '../../Constants/Images';
import {getAllBuilderList} from '../../Store/Actions/SessionAction';

import {getItem} from '../../Utils/LocalStorage';
import SwiperCore from 'swiper';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const DiscoverBuilders = props => {
  const dispatch = useDispatch();
  // var builders

  // if(props.type=='appointment'){

  //    builders = useSelector(state => state.AllBuilderList);
  // }
  // else{
  //    builders = useSelector(state => state.builderList)
  // }

  // const [builders,setBuilders] = useState([]);
  // const [showLoader, setshowLoader] = useState(false);

  // const builders = useSelector(state =>
  //   props.type === 'appointment' ? state.AllBuilderList : state.builderList,
  // );


  // SwiperCore.use([Lazy]);
  let builders = [];

  const allBuilderList = useSelector(state => state.AllBuilderList);


if (props.type === 'poll') {
  builders = props.allChatUsers;
  // props.buildersHandle('Add_all_attendees', props.allChatUsers);
} else {
  builders = allBuilderList;
}

  const getBuilders = () => {
    var data = {
      user_id: getItem('id'),
      search_query: props.searchqueryAssighne ? props.searchqueryAssighne : '',
    };
    dispatch(getAllBuilderList(data));

    // if(response){
    //   props.setshowLoader(false);
    // }

    // commonApi
    //   .get_builders(data)
    //   .then(response => {
    //     if (response) {
    //       setbuilders(response.discover_builder);
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  useEffect(() => {
    // props.setshowLoader(true);
    if (!props.searchqueryAssighne) {
      setTimeout(() => {
        getBuilders();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contact, props.apiHit,props.checkUserHitApi && props.checkUserHitApi]);
  useEffect(() => {
    // props.setshowLoader(true);
    // if (props.type == 'Create_Action') {
    //   if (builders.length == 0) {
    //     props.setshowLoader(true);
    //   }
    //   setTimeout(() => {
    //     props.setshowLoader(false);
    //   }, 2000);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contact, props.apiHit]);
  useEffect(() => {
    // props.setshowLoader(true);
    if (props.searchqueryAssighne || props.searchqueryAssighne === '') {
      getBuilders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchqueryAssighne]);


  SwiperCore.use([]);
 
  const renderDiscoverBuilder = index => {
    // Function to render DiscoverBuilder component
    return (
      <SwiperSlide key={`discover-builder-${index}`}>
        <div
          className="discover-item search as-link"
          onClick={() => {
            props.buildersHandle('AllBuilders');
          }}>
          <div className="img-wrapper img-magnify-wrapper">
            <i className="fas fa-magnifying-glass" />
          </div>

          <h3>Search</h3>
          <p>Network</p>
        </div>
      </SwiperSlide>
    );
  };
  const ShowText =
    props.type === 'poll' && builders.length === props.builders.length
      ? 'Unselect All'
      : 'Select All';

  return (
    <>
      <div className="discover-builders-wrapper home-users-listing customer_user_row">
        <div className="discover-builders">
          <div className="discover-builders-slider discover-custom-builders-slider">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={20}
              navigation={true}
              // centeredSlides={false}
              modules={[Navigation]}
              // className="mySwiper"
              lazy={true} 
              className={`mySwiper ${
                builders.length > 10
                  ? 'addwidthDiscoverBuilders'
                  : 'onlyBuildersSlider'
              }`}>
              {!props.type && (
                <SwiperSlide>
                  <div
                    className="discover-item search as-link"
                    onClick={() => {
                      props.buildersHandle('inviteOutSideTheBuild');
                    }}>
                    <div className="img-wrapper">
                      <img src={AddContact} alt="" />
                    </div>

                    <h3>Add</h3>
                    <p>Contact</p>
                  </div>
                </SwiperSlide>
              )}

              {props.type &&
                props.type !== 'Create_Action' &&
                (builders.length === 0 || props.type !== 'poll') && (
                  <React.Fragment>
                    <SwiperSlide>
                      <div
                        className="discover-item search as-link"
                        onClick={() => {
                          props.buildersHandle('AllBuilders');
                        }}>
                        <div className="img-wrapper">
                          <i className="fas fa-magnifying-glass" />
                        </div>

                        <h3>Search</h3>
                        <p>Network</p>
                      </div>
                    </SwiperSlide>
                  </React.Fragment>
                )}
              {builders.length === 0 && !props.type && (
                <React.Fragment>
                  <SwiperSlide>
                    <div
                      className="discover-item search as-link"
                      onClick={() => {
                        props.buildersHandle('AllBuilders');
                      }}>
                      <div className="img-wrapper">
                        <i className="fas fa-magnifying-glass" />
                      </div>

                      <h3>Search</h3>
                      <p>Network</p>
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              )}
              {props.type === 'poll' && (
                <React.Fragment>
                  <SwiperSlide>
                    <div
                      className="discover-item search as-link"
                      onClick={() => {
                        props.buildersHandle('Add_all_attendees', builders);
                      }}>
                        <div className="img-circle-wrap">
                      <div className="img-wrapper">
                      <img src={select_icon} alt='' />
                      </div>
                      </div>

                      <h3>{ShowText}</h3>
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              )}
              {!props.showMessagesUsers && props.searchqueryAssighneCheck ? (
                Object.entries(props.allChatUsers).length > 0 &&
                props.allChatUsers.map((item, index) => {
                  return (
                    <React.Fragment key={`discover-${index}`}>
                      <SwiperSlide key={`discover-item-${index}`}>
                        <div
                          className="discover-item as-link"
                          onClick={() => {
                            if (
                              props.type === 'appointment' ||
                              props.type === 'poll' ||
                              props.type === 'Create_Action'
                            ) {
                              props.buildersHandle('profileComponent', item);
                            } else {
                              props.buildersHandle('profileComponent', item.id);
                            }
                          }}>
                          <div className="img-wrapper">
                            <img
                              className={
                                (props.type === 'appointment' &&
                                  props.builders.includes(item.id)) ||
                                (props.type === 'poll' &&
                                  props.builders.includes(item.id)) ||
                                (props.type === 'Create_Action' &&
                                  props.builders.includes(item.id))
                                  ? 'selercted-user'
                                  : ''
                              }
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>
                          <h3>{fixName(item.first_name, item.last_name)}</h3>
                          {/* <p>
                            {item.title ? item.title : ''}
                            {parseInt(item.total) ? ' +' + item.total : ''}
                          </p> */}
                        </div>
                      </SwiperSlide>
                      {!props.type &&
                        (index === builders.length - 1 && builders.length <= 10
                          ? renderDiscoverBuilder()
                          : index > 0 &&
                            index % 10 === 0 &&
                            renderDiscoverBuilder(index))}
                    </React.Fragment>
                  );
                })
              ) : Object.entries(builders).length > 0 ? (
                builders.map((item, index) => {
                  return (
                    <React.Fragment key={`discover-${index + item.id}`}>
                      <SwiperSlide key={`discover-item-${index + item.id}`}>
                        <div
                          className="discover-item as-link"
                          onClick={() => {
                            if (
                              props.type === 'appointment' ||
                              props.type === 'poll' ||
                              props.type === 'Create_Action'
                            ) {
                              props.buildersHandle('profileComponent', item);
                            } else {
                              props.buildersHandle('profileComponent', item.id, item);
                            }
                          }}>
                          {/* 'img-wrapper business' */}
                          <div
                            className={
                              item?.linked_user_detail?.user_type === 'business'
                                ? 'img-wrapper business'
                                : 'img-wrapper'
                            }>
                            {/* <img
                              className={
                                (props.type === 'appointment' &&
                                  props.builders.includes(item.id)) ||
                                (props.type === 'poll' &&
                                  props.builders.includes(item.id)) ||
                                (props.type === 'Create_Action' &&
                                  props.builders.includes(item.id))
                                  ? 'selercted-user swiper-lazy'
                                  : 'swiper-lazy'
                              }
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                        
                              alt=""
                            /> */}
                            
                            <LazyLoadImage
                              height={70}
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                              style={{border : (props.type === 'appointment' &&
                                props.builders.includes(item.id)) ||
                              (props.type === 'poll' &&
                                props.builders.includes(item.id)) ||
                              (props.type === 'Create_Action' &&
                                props.builders.includes(item.id))
                                ? '5px solid #52bd41' :'' }}
                              width={70}
                              effect="opacity"
                              
                            />
                          </div>
                          <h3>{fixName(item.first_name, item.last_name)}</h3>
                          {/* <p>
                            {item.title ? item.title : ''}
                            {parseInt(item.total) ? ' +' + item.total : ''}
                          </p> */}

{/* <div className="swiper-lazy-preloader"></div>  */}
                        </div>
                      </SwiperSlide>
                      {!props.type &&
                        (index === builders.length - 1 && builders.length <= 10
                          ? renderDiscoverBuilder()
                          : index > 0 &&
                            index % 10 === 0 &&
                            renderDiscoverBuilder(index))}
                    </React.Fragment>
                  );
                })
              ) : (
                <></>
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverBuilders;
