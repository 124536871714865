import {FormattedText} from '../../Utils/Helpers';
import React, {useState, useEffect} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import CreatePipeLine from './CreatePipeLine';
import { getUpdateDataOfPipeline } from '../../Store/Actions/ProfileAction';
import {useSelector, useDispatch} from 'react-redux';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import { RoiLogo } from '../../Constants/Images';
const PipelineDetail = props => {
  const [showComponent, setShowComponent] = useState('');
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const showMoreClassDescription = showMoreDescription ? 'show-all' : '';
  const dispatch = useDispatch();

  
  const lines =
    props.itemOfPipeline.description !== ''
      ? props.itemOfPipeline.description.split('\n')
      : '';


      const renderHeader = () => {
        
        return (
          <>
        
        {props.pipeLinePermissions?.associate_pipeline ? (  <span
                      className="as-link"
                      onClick={() => {
                        setShowComponent('edit_pipeLine');
                      }}>
                      <EditPencilSvg fill={'white'} h={20} w={20} />
                    </span>
        ) :(
          <img src={RoiLogo} alt="" />
        )}
            
          
          </>
        );
      }; 
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
          dispatch(getUpdateDataOfPipeline(false));
        }}
        renderData={renderHeader}
       showBlackHeader={true}
       lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
       firstDivStyle={{
         padding: 0,
         background:
           'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
       }}
        >
        <div className="serach-filter-wrapper edit_Action">
          {/* {props.pipeLinePermissions?.associate_pipeline && (
            <div className=''>
             
            <h1
              className="mb-2"
              onClick={() => {
                setShowComponent('edit_pipeLine');
              }}>
              <i class="fa-solid fa-pen as-link"></i> {''} 
              <span className='as-link'>Edit</span>
            </h1>
            
                
                </div>
          )} */}

          <Scrollbars autoHide="scroll">
            <div className='fixed-width-inputs black-head-form-pad-left fix-width-forms'>
              <div className="mt-3">
                <div className="refferals-list action-listing  p-0 bg-transparent border-0">
                  <h2 className="actions-title mb-3 truncate_2_lines">
                    {props.itemOfPipeline.title}

                    {/* <FormattedText
                      className={`profile-description ${showMoreClassTitle}`}
                      content={props.dataOfAction.title}
                    />
                    {(props.dataOfAction.title.length > 250 ||
                      titleLines.length > 3) && (
                      <>
                        {' '}
                        <span
                          className="show-more-link"
                          onClick={() => setShowMoreTitle(!showMoreTitle)}>
                          {!showMoreTitle
                            ? labels.read_more[selected_lang]
                            : labels.read_less[selected_lang]}
                        </span>
                      </>
                    )} */}

                    {/* order for Supplies */}
                  </h2>
                  {/* <div className="d-flex align-items-center session-cam mb-2">
                    <img src={VideoGreyIcon} alt="video-cam" />
                    <p className="p-reg m-0 single-line-text">
                      {props.dataOfAction.association_name}
                    </p>
                  </div> */}

                  {/* <div
                    className="d-flex align-items-center justify-content-between"
                    style={{alignItems: 'center'}}>
                    <div className="d-flex align-items-center">
                      {assigneDetail(props.dataOfAction)}
                    </div>
                  </div> */}
                </div>
              </div>

              <FormattedText
                className={`profile-description ${showMoreClassDescription}`}
                content={props.itemOfPipeline.description}
              />

              {(props.itemOfPipeline.description.length > 250 ||
                lines.length > 3) && (
                <>
                  {' '}
                  <span
                    className="show-more-link"
                    onClick={() =>
                      setShowMoreDescription(!showMoreDescription)
                    }>
                    {!showMoreDescription
                      ? labels.read_more[selected_lang]
                      : labels.read_less[selected_lang]}
                    {/* {profile.data.bio.length} */}
                  </span>
                </>
              )}
              <div className='mt-3'>

              <span className="">
                <b>Phases</b>
              </span>
              </div>
              <div className="mt-2">
                {props.itemOfPipeline.phases.length > 0 &&
                  props.itemOfPipeline.phases.map((item, index) => (
                    <div className="pipeline_item">
                      <div>
                        <b> {item.phase}</b>
                      </div>

                      <div>
                         
                        {item.deals.length === 1   ? item.deals.length + ' Deal' : item.deals.length > 1 ?  item.deals.length + ' Deals' : '    No Deals'}




                      </div>
                    </div>
                  ))}
              </div>

              <div className="filter-btn-wrapper pt-2">
                <div className="invite-btn">
                  <div className="fieldset"></div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showComponent === 'edit_pipeLine' && (
        <CreatePipeLine
          editPipeline={props.itemOfPipeline}
          onClose={() => {
            setShowComponent('');
          }}
          closeActionDeatil={props.onClose}
          type={true}
        />
      )}
    </>
  );
};

export default PipelineDetail;
