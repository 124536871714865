import React, {useState, useEffect, useRef} from 'react';
import CustomSlidingButtons from '../CustomSlidingButtons';
import {
  follow_up_List,
  getFutureDateByCategory,
  getTomorrowDate,
} from '../../Utils/commonUtils';
import SlidingPanel from '../SlidingPanel';
import {RoiLogo} from '../../Constants/Images';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';
import CustomDropdown from '../CustomDropdown';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {successMessage} from '../../Utils/ToastMessages';

const ContactsEdit = props => {
  const [actionType, setActionType] = useState(follow_up_List[0].id);
  const [followUpDate, setFollowUpDate] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [contactRelations, setContactRelations] = useState([]);
  const [contactRelationsId, setContactRelationsId] = useState('');
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [emilOrphone, setEmilOrphone] = useState('');
  const [eventname, setEventname] = useState('');
  const [note, setNote] = useState('');
  const buttonRef = useRef(null);

  const handleGetData = e => {
    const {value} = e.target;
    setActionType(value);
    const date = getFutureDateByCategory(value);
    setFollowUpDate(date);
  };

  useEffect(() => {
    getContactRelationTypes();
  }, []);

  useEffect(() => {
    setFollowUpDate(getTomorrowDate());
  }, []);

  const getContactRelationTypes = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelations(res.data);
          setContactRelationsId(res.data[0]?.id);
          setTimeout(() => {
            setShowLoader(false);
          }, 1200);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const rendercontacts = () => {
    // Return the JSX element conditionally based on props.permission
    return <img src={RoiLogo} alt="" />;
  };
  const submitData = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      sendnvitationOutIside();
      setValidated(false);
    }
  };

  const sendnvitationOutIside = () => {
    let data = {
      contact: emilOrphone,
      fname: name,
      relationship_id: contactRelationsId,
      linkedin_id: props.selectedItem?.id,
      note: note,
      event_name: eventname,
      follow_up: followUpDate,
    };
    let Payload = {contacts: [data]};

    commonApi
      .send_invitation_outside(Payload)
      .then(res => {
        if (res.status === 200) {
          props.onClose();
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <SlidingPanel
      hidePanel={() => {
        props.onClose();
      }}
      renderData={rendercontacts}
      showBlackHeader={true}
      lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
      firstDivStyle={{
        padding: 0,
        background: 'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
      }}>
      <div className="serach-filter-wrapper">
        <h2 className="mt-3 ml-5">Follow-up</h2>
        <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-4">
          <CustomSlidingButtons
            items={follow_up_List}
            activeButtons={actionType}
            setActionType={setActionType}
            onItemClick={e => {
              handleGetData(e);
            }}
            showItems={3}
            typ
          />
        </div>
        <Scrollbars className="custom-scroll">
          <div className="fix-width-forms pl-23">
            <div className="messages-users-list">
              <div className="create-ticket-form ">
                <Form noValidate validated={validated} onSubmit={submitData}>
                  <>
                    <div className="fieldset">
                      <Form.Label controlid="validationCustom01">
                        {labels.Name[selected_lang]}
                      </Form.Label>
                      <Form.Control
                        name="Name"
                        value={name}
                        pattern="^\S.*$"
                        required
                        placeholder="Enter name"
                        onChange={e => {
                          setName(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </div>
                    <div className="fieldset">
                      <Form.Label controlid="validationCustom01">
                        {labels.RelationShip[selected_lang]}
                      </Form.Label>
                      <CustomDropdown
                        // type={'credential'}
                        setDropdownId={setContactRelationsId}
                        dropdownId={contactRelationsId}
                        DropDownValues={contactRelations}
                        itemName={['relations']}
                      />
                    </div>
                    <div className="fieldset">
                      <Form.Label controlid="validationCustom01">
                        {labels.EmailPhone[selected_lang]}
                      </Form.Label>
                      <Form.Control
                        name="Enter a phone or email"
                        value={emilOrphone}
                        pattern="^\S.*$"
                        required
                        placeholder="Enter a phone or email"
                        onChange={e => {
                          setEmilOrphone(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Phone or email is required.
                      </Form.Control.Feedback>
                    </div>
                    <div className="fieldset">
                      <Form.Label controlid="validationCustom01">
                        {labels.Event[selected_lang]} (Optional)
                      </Form.Label>
                      <Form.Control
                        name="Event name"
                        value={eventname}
                        pattern="^\S.*$"
                        placeholder="Event name"
                        onChange={e => {
                          setEventname(e.target.value);
                        }}
                      />
                    </div>
                    <div className="fieldset">
                      <Form.Label controlid="validationCustom01">
                        {labels.Note[selected_lang]} (Optional)
                      </Form.Label>
                      <Form.Control
                        name="leave note"
                        value={note}
                        as="textarea"
                        rows={6}
                        pattern="^\S.*$"
                        placeholder="leave note"
                        onChange={e => {
                          setNote(e.target.value);
                        }}
                      />
                    </div>

                    <Button
                      className="btn-dark"
                      type="submit"
                      style={{display: 'none'}}
                      ref={buttonRef}>
                      {/* {props.editTag?.id ? 'Update Tag' : 'Create Tag'} */}
                    </Button>
                  </>
                  <h2>location_captured</h2>
                  <p className="mt-2">
                    {props.selectedItem?.location_captured}
                  </p>
                  <h2 className="mt-2 mb-1">Link</h2>
                  {/* <p className="mt-2 mb-5"> */}

                  <a
                
                  className='pb-4 pt-1 linked_tag'
                    href={props.selectedItem.scanned_link}
                    target="_blank"
                    rel="noopener noreferrer">
                    {props.selectedItem.scanned_link}
                  </a>
                  {/* <a
                    href={props.selectedItem.scanned_link}
                    target="_blank"
                    rel="noopener noreferrer" >
                    {props.selectedItem.scanned_link}
                  </a> */}
                  {/* {props.selectedItem?.scanned_link} */}
                  {/* </p> */}
                </Form>
              </div>
            </div>
          </div>
        </Scrollbars>

        <div className="filter-btn-wrapper fix-width-forms mb-2">
          <div className="invite-btn text-center ">
            <Button
              className="btn-dark"
              type="submit"
              onClick={() => {
                buttonRef.current.click();
              }}>
              {'Add Contact'}
            </Button>
          </div>
        </div>
      </div>
    </SlidingPanel>
  );
};

export default ContactsEdit;
