import React, {useState} from 'react';

import {addUser} from '../../Constants/Images';
import {Col, Row} from 'react-bootstrap';

import {CustomModal} from '../../Components';

import CompanyInvite from '../../Components/SingleComponent/CompanyInvite';

import {registerCompany} from '../../Constants/Images';

import CircleListBuilders from '../Front/StaticPages/CircleListBuilders';
import SystemRolesInvite from '../../Components/SingleComponent/SystemRolesInvite';
import SlidingPanel from '../../Components/SlidingPanel';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import SendInvitation from '../Front/StaticPages/SendInvitation';
import {RoiLogo} from '../../Constants/Images';
const SliderComponent3 = props => {
  const [showModal, setShowModal] = useState(false);
  // const [rolesTypes, setRolesTypes] = useState(props.systemRoles);

  const [builders, setBuilders] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [allBuilders, setAllBuilders] = useState([]);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [orginalSelectedBuilders, setOrginalSelectedBuilders] = useState([]);
  // const [showComponent, setShowComponent] = useState(props.showComponent);

  const [followers, setFollowers] = useState([]);
  const [selectedInviteTitle, setSelectedInviteTitle] = useState('');

  const handleInvite = title => {
    if (selectedBuilders.length !== orginalSelectedBuilders.length) {
      setOrginalSelectedBuilders(selectedBuilders);
    }

    props.systemRoles.forEach(role => {
      if (role.role_name === title) {
        const existingMenusArray = role.invites ? role.invites.split(',') : [];
        if (existingMenusArray.length > 0) {
          const filteredData = followers.filter(item =>
            existingMenusArray.includes(item.id),
          );
          const selectedFilterData = selectedBuilders.filter(
            item => !filteredData.some(miniItem => miniItem.id === item),
          );

          setAllBuilders(filteredData);
          setOriginalDataOfMod(filteredData);
          setSelectedBuilders(selectedFilterData);
        } else {
          setAllBuilders([]);
          setOriginalDataOfMod([]);
        }
        setBuilders(existingMenusArray);

        setSelectedInviteTitle(title);
        setShowModal(true);
        // const existingMenusSet = new Set(existingMenusArray);
        // existingMenusSet.add(menuId);
        // const updatedMenus = Array.from(builders).join(',');
      }
    });
  };

  const handleSelectedInvite = () => {
    const updatedRolesTypes = props.systemRoles.map(role => {
      if (role.role_name === selectedInviteTitle) {
        // const existingMenusArray = role.invites ? role.invites.split(',') : [];
        // const existingMenusSet = new Set(existingMenusArray);
        // existingMenusSet.add(menuId);
        // const updatedMenus = Array.from(builders).join(',');
        setSelectedBuilders(previous => {
          const uniqueBuilders = new Set([...previous, ...builders]);
          return Array.from(uniqueBuilders);
        });
        setOrginalSelectedBuilders(previous => {
          const uniqueBuilders = new Set([...previous, ...builders]);
          return Array.from(uniqueBuilders);
        });

        // setSelectedBuilders((previous => [...previous, ...builders]))
        // setOrginalSelectedBuilders((previous => [...previous, ...builders]))
        return {...role, invites: builders.join(',')};
      }
      return role;
    });

    props.setSystemRoles(updatedRolesTypes);
  };

  const handleInviteLength = invites => {
    const invite = invites.split(',');
    return invite.length;
  };

  const handleCircle = () => {
    props.setShowComponent('Builders');
  };

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (
          !builders.includes(miniItem.id) &&
          !selectedBuilders.includes(miniItem.id)
        ) {
          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };
  //

  //

  let firstDivStyle = {
    padding: '25px 25px 0px 25px !important',
  };

  const dotsArray = [1, 2, 3];

  return (
    <>
      <div className="slider-component create_company_form roles_page">
        {/* <div className="slider-component-img"> */}
        {/* {!props.hide && ( */}
        <div
          className="slider-component-img"
          style={{
            backgroundImage: `url(${registerCompany})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}>
          {/* <img src={registerCompany} alt="" /> */}

          <div className="slider-component-img-text">
            Welcome to the ROI Family!
          </div>
        </div>
        {/* )} */}

        <div
          className="slider-component-content refferal-list-wrapper"
          style={{width: '50%', position: 'relative'}}>
          {props.showLoader && <LoaderSpinner />}
          <div className="slider-content">
            <h1 className="mb-2 main-slide-head">Who’s who in your company?</h1>

            <div className="d-flex gap-3 mb-5">
              {dotsArray.map((dot, index) => (
                <span
                  key={index}
                  className={` company_dots ${
                    props.currentStep === 3 && index === 2 ? 'bg_green' : ''
                  } ${props.currentStep === 3 ? 'as-link' : ''}`}
                  onClick={() => {
                    if (props.currentStep === 3) {
                      if (index === 0) {
                        props.setStep(1);
                      } else if (index === 1) {
                        props.setStep(2);
                      }
                    }
                  }}></span>
              ))}
            </div>
            <div className=" system-roles mt-5 ">
              {props.systemRoles &&
                Object.entries(props.systemRoles).length > 0 &&
                props.systemRoles.map((item, index) => (
                  <Row key={`r-item-${index}`}>
                    <Col xs={9} md={9} className="role-row">
                      {' '}
                      {item.role_name}
                    </Col>

                    <Col
                      xs={props.hide ? 3 : 2}
                      md={props.hide ? 3 : 2}
                      className="role-row system_invite_users">
                      {' '}
                      {item.invites === '' ? (
                        <div
                          onClick={() => {
                            handleInvite(item.role_name);
                            // setSelectedInviteTitle(item.alias)
                            // setShowModal(true);
                          }}>
                          <img className="as-link" src={addUser} alt="" />{' '}
                        </div>
                      ) : (
                        <span
                          className="invite-length as-link"
                          onClick={() => {
                            handleInvite(item.role_name);
                          }}>
                          {' '}
                          {handleInviteLength(item.invites)} added
                        </span>
                      )}
                    </Col>
                  </Row>
                ))}
            </div>
          </div>

          {showModal && (
            <SlidingPanel
              hidePanel={() => {
                setShowModal(false);
                if (
                  orginalSelectedBuilders.length !== selectedBuilders.length
                ) {
                  setSelectedBuilders(orginalSelectedBuilders);
                }
              }}
              // firstDivStyle={firstDivStyle}

              renderData={renderHeader}
              showBlackHeader={true}
              // headerPadding={'24px 20px'}
              lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
              firstDivStyle={{
                padding: 0,
                background:
                  'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
               
              }}>
              <SystemRolesInvite
                type={'refer'}
                component={'system-role'}
                createCompany={true}
                followers={followers}
                setFollowers={setFollowers}
                moderator={builders}
                setModerator={setBuilders}
                selectedModData={allBuilders}
                setSelectedModData={setAllBuilders}
                originalDataOfMod={originalDataOfMod}
                setOriginalDataOfMod={setOriginalDataOfMod}
                selectedBuilders={selectedBuilders}
                contact={props.contact}
                setContact={props.setContact}
                setShowComponent={props.setShowComponent}
                forApiHit={props.showComponent}
                // prevShowComponent={prevShowComponent}
                onClick={() => props.setShowComponent('inviteUser')}
                handleCircle={handleCircle}
                handleInvite={() => {
                  handleSelectedInvite();
                  setShowModal(false);
                }}
                title={selectedInviteTitle}
                hideCircle={props.type==='create-account' ? true :false}
              />
            </SlidingPanel>
          )}

          {props.showComponent === 'Builders' && (
            <CircleListBuilders
              onClose={() => {
                props.setShowComponent('');
              }}
              hide={true}
              // padding={true}
              CircleUserListData={CircleUserListData}
              // marginTop={true}
            />
          )}
          {props.showComponent === 'inviteUser' && (
            <SendInvitation
              setContact={props.setContact}
              onClose={() => {
                props.setShowComponent('');
              }}
            />
          )}
        </div>
      </div>

      {/* <div className="company-info-screen">
      <div className="company-top-q">
        <h1 className="mb-2">Tell us about your team</h1>
        <div className="mb-5">
          How many employees, contractors and partners do you have?
        </div>
      </div>
      <div>
        <div className="slider3-container">
          <div className="slider3-Top-container">
            <label>Which roles exist in your company?</label>

            <div className="svg-team">
              <img src={download} alt="" />
              <span>Download Template</span>
              <span
                className="btn-roi-template as-link mt-3-bold"
                onClick={() => {}}>
                Import Team
              </span>
            </div>
          </div>

          <div>
            <label className="role-label">
              If you don’t want to change the name of the provided core roles,
              you can leave the right field blank.
            </label>
            <div className="slider2-inner-container">
              <div className="scheduler-dropdown">
                

              {Object.entries(rolesTypes).length > 0 &&  <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setType(eventKey);
                   
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type
                      ? rolesTypes.find(item => item.id === type).role_name
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {rolesTypes.map((item, index) => (
                      <Dropdown.Item
                        key={`sessionCategories-${index}`}
                        eventKey={item.id}
                        active={item.id == type}>
                        {item.role_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>}
              </div>

              <div className="slider3-called-title">
                <span> We use this title</span>

                <input
                  type="text"
                  id="companyName"
                  value={title}
                  placeholder={placeholderTitle(type)}
                  onChange={(e)=>{
                    setTitle(e.target.value)
                  }}
                />
              </div>
              <div className="btn-add-type">
                <Button className="new-btn btn-green " onClick={() => {addSystemRole(type)}}>
                  Add Role
                </Button>
              </div>
            </div>
          </div>

          <div className="slider-3-roles-conatiner">
            <label>11 System Users</label>

            <label>Your alias column is how these roles will show in ROI</label>

            <div className="mt-3">
              <div className="system-role-list">
                <Container className="mb-3">
                  <Row>
                    <Col md={4}> System Role</Col>

                    <Col md={3}>Alias </Col>
                  </Row>
                </Container>

                <Container>
                  {Object.entries(selectedRoleItems).length > 0 &&
                    selectedRoleItems.map((item, index) => (
                      <Row key={`r-item-${index}`}>
                        <Col md={4} className="role-row">
                          {' '}
                          {item.role}
                        </Col>

                        <Col md={3} className="role-row">
                          {item.alias}{' '}
                        </Col>
                        <Col md={1} className="role-row">
                          {' '}
                          <div onClick={()=>{
                            setShowModal(true)
                          }}>

                         
                          <img className='as-link' src={addUser} alt="" />{' '}
                          </div>
                        </Col>
                        <Col md={1} className="role-row">
                          {' '}
                          <span className="as-link" onClick={() => {removeSystemRole(item.alias)}}>
                            <i
                              class="fa-solid fa-xmark"
                              style={{
                                color: '#d71616',
                                fontWeight: 'bold',
                                fontSize: '22px',
                              }}></i>
                          </span>{' '}
                        </Col>
                      </Row>
                    ))}
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div> */}

      {/* {showModal && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModal(false);
            if (orginalSelectedBuilders.length !== selectedBuilders.length) {
              setSelectedBuilders(orginalSelectedBuilders);
            }
          }}
          className={'company_invite_modal'}
          removeheight={true}>
          <div className="new-session-modal-wrapper text-center createSessionModal eventBrite-Modal">
            <div className="new-session-modal">
              <CompanyInvite
                type={'refer'}
                component={'system-role'}
                followers={followers}
                setFollowers={setFollowers}
                moderator={builders}
                setModerator={setBuilders}
                selectedModData={allBuilders}
                setSelectedModData={setAllBuilders}
                originalDataOfMod={originalDataOfMod}
                setOriginalDataOfMod={setOriginalDataOfMod}
                selectedBuilders={selectedBuilders}
                contact={props.contact}
                setContact={props.setContact}
                forApiHit={props.showComponent}
                onClick={() => props.setShowComponent('inviteUser')}
                handleCircle={handleCircle}
                handleInvite={() => {
                  handleSelectedInvite();
                  setShowModal(false);
                }}
              />
            </div>
          </div>
        </CustomModal>
      )} */}

      {/* {showModal && (
        <SlidingPanel
          hidePanel={() => {
            setShowModal(false);
            if (orginalSelectedBuilders.length !== selectedBuilders.length) {
              setSelectedBuilders(orginalSelectedBuilders);
            }
          }}
          firstDivStyle={firstDivStyle}>
          <SystemRolesInvite
            type={'refer'}
            component={'system-role'}
            followers={followers}
            setFollowers={setFollowers}
            moderator={builders}
            setModerator={setBuilders}
            selectedModData={allBuilders}
            setSelectedModData={setAllBuilders}
            originalDataOfMod={originalDataOfMod}
            setOriginalDataOfMod={setOriginalDataOfMod}
            selectedBuilders={selectedBuilders}
            contact={props.contact}
            setContact={props.setContact}
            setShowComponent={props.setShowComponent}
            forApiHit={props.showComponent}
            // prevShowComponent={prevShowComponent}
            onClick={() => props.setShowComponent('inviteUser')}
            handleCircle={handleCircle}
            handleInvite={() => {
              handleSelectedInvite();
              setShowModal(false);
            }}
          />
        </SlidingPanel>
      )} */}

      {/* {props.showComponent === 'Builders' && (
        <CircleListBuilders
          onClose={() => {
            props.setShowComponent('');
          }}
          hide={true}
          // padding={true}
          CircleUserListData={CircleUserListData}
        />
      )} */}
    </>
  );
};

export default SliderComponent3;
