import commonApi from "../../Services/CommonService";

export const getDispatch = (type, data) => ({
    type: type,
    payload: data,
  });
export const getUserMenuPermesionList = () => {
  return async dispatch => {
    const response = await commonApi.get_active_menu_permission();
    dispatch(getDispatch('GET_USER_Menu_Permesions_LIST', response));
    return response;
  };
}
export const getActiveCompanyUserMenus = () => {
  return async dispatch => {
    const response = await commonApi.get_active_company_user_menus();
    dispatch(getDispatch('GET_USER_Active_Menu_LIST', response));
    return response;
  };
}
