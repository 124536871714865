import React, {useState, useEffect} from 'react';
import { capitalizeFirst, fixName } from '../../../Utils/commonUtils';

const SuggestionsListing = props => {

    const {listing, type, style, handleSuggestions} = props;

    console.log(listing, 'listinglistinglisting')

  return (
    <>

    {listing && listing.length > 0 && (
        <ul className="custom-suggestions invoices_suggestion" style={{width: style , position: 'absolute', zIndex: '999'}}>
            {listing.map(suggestion => {

            const cleanedPhoneNumber = suggestion?.phone?.replace(/"/g, '');
            const onlyNumbers = /^[\d+#*]{0,15}$/.test(cleanedPhoneNumber);
                
                return onlyNumbers ? (
                <li className="as-link" key={'suggestion' + suggestion.id}
                    onClick={() => {
                        handleSuggestions(suggestion, cleanedPhoneNumber)
                    }}>
                    {type === 'messages' ? fixName(suggestion.first_name, suggestion.last_name) + " " + capitalizeFirst(cleanedPhoneNumber) : capitalizeFirst(cleanedPhoneNumber)}
                </li>
                )
            : null            
        })}
        </ul>
    )}
    </>
  );
};

export default SuggestionsListing;
