import commonApi from '../../Services/CommonService';

export const getDispatch = (type, data) => ({
    type: type,
    payload: data,
  });

export const getCompanyOfferings = data => {
    return async dispatch => {
      const response = await  commonApi
      .get_company_catalogs(data);
      dispatch(getDispatch('GET_COMPANY_OFFERINGS', response.data ));
      return response;
    };
  };