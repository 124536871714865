import React, {useState, useEffect} from 'react';
import commonApi from '../../Services/CommonService';
import DoubleCam from '../../Svg/DoubleCam';
import CustomSlidingButtons from '../CustomSlidingButtons';
import {Button} from 'react-bootstrap';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {useSelector} from 'react-redux';
import {checkPermissions} from '../../Utils/commonUtils';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

const ProfileTransformation = props => {
  const [transformation_Filter, setTransformation_Filter] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState([]);
  const activeCompany = localStorage.getItem('activeWorkspaceItem')
    ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
    : [];

  const [showLoader, setshowLoader] = useState(true);

  const menuPermesions = useSelector(state => state.menuPermesionList);
  const BusinnesProfilePermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Business Profile');

    
    const location = useLocation();
    const { pathname, search, hash } = location;

    
  const get_company_transformation = () => {
    const filteredArray =
      transformation_Filter.length > 0 &&
      transformation_Filter.filter(item => item !== 'All');
    var payload = {};
    if (props.userId == undefined) {
      payload = {
        user_id: localStorage.getItem('id'),
        category_ids: transformation_Filter.length > 0 ? filteredArray : [],
        company_id: activeCompany[0].id,
      };
    } else {
      payload = {
        user_id:
          props.companyData || props.Profile === 'business'
            ? ''
            : props.userId
            ? props.userId
            : '',
        category_ids: transformation_Filter.length > 0 ? filteredArray : [],
        company_id: props.companyData
          ? props.companyData.id
          : activeCompany[0].id,
      };
    }

    commonApi
      .get_company_transformation(payload)
      .then(res => {
        if (res.status === 200) {
          props.setTransformationList(res.transformation);
          if (res) {
            setTimeout(() => {
              setshowLoader(false);
            }, 500);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_transformation_category = () => {
    var payload = {
      user_id: props.userId ? props.userId : localStorage.getItem('id'),
      company_name: activeCompany[0].name,
    };
    commonApi
      .get_transformation_category(payload)
      .then(res => {
        const categories = res.transformation_categories;

        // Create a new category object
        const newCategory = {
          id: 'All',
          category_title: 'All',
        };

        // Add the new category object to the beginning of the array
        const updatedCategories = [newCategory, ...categories];

        // Now you can set the state with the updated categories
        setCategoryTypes(updatedCategories);
        // setCategoryTypes(res.transformation_categories);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    get_transformation_category();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.companyData]);

  useEffect(() => {
    get_company_transformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformation_Filter, props.showComponent, props.companyData]);

  const handleClick = e => {
    const {id} = e.target;

    if (id === 'All') {
      if (
        transformation_Filter &&
        transformation_Filter.length > 0 &&
        transformation_Filter.includes(id)
      ) {
        setTransformation_Filter([]);
      } else {
        const idsArray = categoryTypes.map(category => category.id);
        const newCategory = 'All';

        const updatedCategories = [newCategory, ...idsArray];
        setTransformation_Filter(updatedCategories);
      }
    } else {
      if (
        transformation_Filter &&
        transformation_Filter.length > 0 &&
        transformation_Filter.includes(id)
      ) {
        setTransformation_Filter(
          transformation_Filter.filter(item => item !== id),
        );
      } else {
        setTransformation_Filter([...transformation_Filter, id]);
      }
    }
  };

  const businessProfilePermission =
    BusinnesProfilePermessions &&
    (BusinnesProfilePermessions === 'allow' ||
      BusinnesProfilePermessions?.edit_company === true)
      ? true
      : false;



  return (
    <div className="profile-transformation-wrapper">
      {showLoader && (
        <div className="loader_hieght">{showLoader && <LoaderSpinner />}</div>
      )}
      {!showLoader && props.threeDots && (
        <div className="tranformation_filter category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
          <CustomSlidingButtons
            items={categoryTypes}
            activeButtons={transformation_Filter}
            onItemClick={e => {
              handleClick(e);
            }}
            showItems={2}
          />
        </div>
      )}

      {!showLoader &&
      props.transformationList &&
      props.transformationList.length > 0
        ? props.transformationList.map((item, index) => (
            <div
              className="business_profile_content mt-3"
              key={index}
              onClick={() => {
                props.handleTransFormItem(item, 'transformationDetail');
              }}>
              <div
                className="before_after_content mr-3 "
                style={{position: 'relative'}}>
                {item.before_images.length > 1 && (
                  <div
                    className="as-link"
                    style={{
                      position: 'absolute',
                      bottom: '12px',
                      right: '18px',
                    }}>
                    <DoubleCam fill={'#52BD41'} />
                  </div>
                )}
                {index === 0 && <div className="mb-2">Before</div>}
                <div className="before_after_img as-link">
                  <img src={item.before_images[0]} alt="" />
                </div>
              </div>
              <div
                className="before_after_content"
                style={{position: 'relative'}}>
                {item.after_images.length > 1 && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '12px',
                      right: '18px',
                    }}
                    className="as-link">
                    <DoubleCam fill={'#52BD41'} />
                  </div>
                )}
                {index === 0 && <div className="mb-2">After</div>}
                <div className="before_after_img  as-link">
                  <img src={item.after_images[0]} alt="" />
                </div>
              </div>
            </div>
          ))
        : !showLoader && (
            <>
              <div className="no-results-wrapper no-appointments my-5">
                <div className="no-results-content">
                  <p className={'noRecord-desc'}>No record found</p>
                  {(localStorage.getItem('id') === props.userId &&
                    (businessProfilePermission || pathname ==='/profile')) && (
                      <div className=" filter-btn-wrapper btn-wrapper mt-3">
                        <Button
                          className="btn btn-dark"
                          onClick={() => {
                            props.handleTransFormItem('', 'createTransform');
                          }}>
                          Add Transformation
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
    </div>
  );
};

export default ProfileTransformation;
