import React from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Dropdown,
} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {
  Logo,
  ShareYellowIcon,
  ShareablesYellowIcon,
  NoForm,
  Dollar,
} from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';
import {listForDropDown} from '../../Utils/commonUtils';
import {labels} from '../../Constants/Translations';
import { selected_lang} from '../../Utils/env';
import {fixName} from '../../Utils/commonUtils';
import {formatString} from '../../Utils/commonUtils';
import {Link} from 'react-router-dom';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

import CustomModal from '../CustomModal';



const ShareAblesList = props => {
  const [showLoader, setShowLoader] = useState(false);
  const [dropdownType, setDropdownType] = useState('1');

  const [searchQry, setSearchQry] = useState('');
  const [tabType, setTabType] = useState('leads');

  const [allData, setAllData] = useState([]);

  const [dropdownValue, setdropdownValue] = useState(listForDropDown[0].title);

  // const [areas_Filter, setAreas_Filter] = useState(listForDropDown[0].id);

  const [model, setModel] = useState('');
  const [shareUrl, setShareUrl] = useState('');

  // const name = localStorage.getItem('userName');


 

  const [filterbtns, setFilterbtns] = useState(['2']);

  const filterButtons = [
    {id: '1', name: 'All'},

    {id: '2', name: 'Social'},

    {id: '3', name: 'Networking'},
  ];

  useEffect(() => {
    // getUserData();
    // getAccomplishment();
    get_user_all_data(searchQry);

    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dropdownType]);

  useEffect(() => {
    get_user_all_data(searchQry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQry]);

  // const dropDownSelection = e => {
    
  //   const {id, innerText} = e.target;

  //   setAreas_Filter(id);
  //   // setType(id)
  //   // setDropdownType(id)

  //   // setdropdownValue([innerText]);
  // };

  const get_user_all_data = qry => {
    const payload = {
     
      search_query: qry,
      type:
        tabType === labels.PAGE[selected_lang] &&
        props.dropdownValue === 'Workspace'
          ? ['workspace']
          : tabType === labels.ELEMENT[selected_lang] &&
            props.dropdownValue === 'Workspace'
          ? [
              'sessions',
              'events',
              'opportunities',
              'snapshots',
              'appointments',
              'group_chats',
            ]
          : dropdownValue.includes('Virtual')
          ? ['sessions']
          : dropdownValue.includes('In-Person')
          ? ['events']
          : dropdownValue.includes('Recruitments') 
          ? ['opportunities']
          : dropdownValue.includes('Appointments')
          ? ['appointments']
          : dropdownValue.includes('Group Chats')
          ? ['group_chats']
          : // :
          // dropdownValue.includes('Workspace')
          // ? ['workspace']

          dropdownValue.includes('Credentials')
          ? ['snapshots']
          : dropdownValue.includes('Network')
          ? ['networks']
          : dropdownValue.includes('Links')
          ? ['links']
          : dropdownValue.includes('Forms')
          ? ['Forms']
          :[],
          // : [
          //     'sessions',
          //     'events',
          //     'opportunities',
          //     'snapshots',
          //     'appointments',
          //     'group_chats',
          //   ],
      category_id: props.sessionCatgory ? props.sessionCatgory : '',
      events: dropdownValue.includes('Virtual')
        ? false
        : dropdownValue.includes('In-Person')
        ? true
        : '',
      sessions: dropdownValue.includes('Virtual')
        ? true
        : dropdownValue.includes('In-Person')
        ? false
        : '',
      // categories: props.snapshotFilter,
      //opportunity filter

      amount:
        props.opportunityFilter && props.opportunityFilter.amount
          ? props.opportunityFilter.amount
          : '',
      frequency:
        props.opportunityFilter && props.opportunityFilter.frequency
          ? props.opportunityFilter.frequency
          : '',
      latitude:
        props.opportunityFilter && props.opportunityFilter.latitude
          ? props.opportunityFilter.latitude
          : '',
      like: false,
      location:
        props.opportunityFilter && props.opportunityFilter.location
          ? props.opportunityFilter.location
          : '',
      longitude:
        props.opportunityFilter && props.opportunityFilter.longitude
          ? props.opportunityFilter.longitude
          : '',

      opportunity_types:
        props.opportunityFilter && props.opportunityFilter.opportunity_types
          ? props.opportunityFilter.opportunity_types
          : '',
      page: 1,

      vaccinated: props.opportunityFilter.vaccinated ? 'yes' : 'no',
      virtual: props.opportunityFilter.virtual ? 'yes' : 'no',

      //network filter

      // gender: props.gender.toString(),
      gender: '',
      otherIdentifires:
        props.networkFilter && props.networkFilter.otherIdentifires
          ? props.networkFilter.otherIdentifires
          : '',
     
      // ethnicities: props.ethnicityId.toString(),
      ethnicities: '',
      city_states:
        props.networkFilter && props.networkFilter.city_states
          ? props.networkFilter.city_states
          : '',

      on_boarding_areas:
        props.networkFilter && props.networkFilter.on_boarding_areas
          ? props.networkFilter.on_boarding_areas
          : '',
      appointment_types:
        props.networkFilter && props.networkFilter.appointment_types
          ? props.networkFilter.appointment_types
          : '',
      on_boarding_categories:
        props.networkFilter && props.networkFilter.on_boarding_categories
          ? props.networkFilter.on_boarding_categories
          : '',

      on_boarding_areas_filter:
        props.networkFilter && props.networkFilter.on_boarding_areas_filter
          ? props.networkFilter.on_boarding_areas_filter
          : '',
      other_identifier_filter:
        props.networkFilter && props.networkFilter.other_identifier_filter
          ? props.networkFilter.other_identifier_filter
          : '',

      appointment_type_ids:
        props.networkFilter && props.networkFilter.appointment_type_ids
          ? props.networkFilter.appointment_type_ids
          : '',
      areas:
        props.networkFilter && props.networkFilter.areas
          ? props.networkFilter.areas
          : '',
      categories:
        props.networkFilter && props.networkFilter.categories
          ? props.networkFilter.categories
          : '',
      hometown_location:
        props.networkFilter && props.networkFilter.hometown_location
          ? props.networkFilter.hometown_location
          : '',
      // search_query: '',
      travel_location:
        props.networkFilter && props.networkFilter.travel_location
          ? props.networkFilter.travel_location
          : '',
      // latitude:
      //   props.networkFilter && props.networkFilter.latitude
      //     ? props.networkFilter.latitude
      //     : '',
      // longitude:
      //   props.networkFilter && props.networkFilter.longitude
      //     ? props.networkFilter.longitude
      //     : '',
      //workspace filter
      workspace_ids: props.workSpaceDropDownIds.toString(','),
    };

    commonApi
      .get_user_all_data(payload)
      .then(res => {
        if (res) {
          setAllData(res.all_record ? res.all_record :[]);
          
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handlePreview = item => {
    props.setShareAbleItem(item);
    props.setShowModel('preview');
    let type = '';
    if (item.data_type === 'session') {
      type = 'conference';
    } else if (item.data_type === 'event') {
      type = 'event';
    } else if (item.data_type === 'opportunity') {
      type = 'opportunity';
    } else if (item.data_type === 'group_chat') {
      type = 'message';
    } else if (item.data_type === 'network') {
      type = 'profile';
    } else if (item.data_type === 'accomplishment') {
      type = 'snapshot';
    } else if (item.data_type === 'appointment') {
      type = 'appointment';
    }
    props.setType(type);
  };

  const handleShare = item => {
    // alert('');

    let type = '';
    if (item.data_type === 'session') {
      type = 'conference';
    } else if (item.data_type === 'event') {
      type = 'event';
    } else if (item.data_type === 'opportunity') {
      type = 'opportunity';
    } else if (item.data_type === 'group_chat') {
      type = 'group_chat';
    } else if (item.data_type === 'network') {
      type = 'profile';
    } else if (item.data_type === 'accomplishment') {
      type = 'snapshot';
    } else if (item.data_type === 'appointment') {
      type = 'appointment';
    }
    if (type !== '') {
      props.ShowModelLinks('link_model', type, item);
    }

   
  };

  const handleFilter = id => {
    if (!filterbtns.includes(id)) {
      setFilterbtns(previous => [...previous, id]);
    } else {
      setFilterbtns(previous => previous.filter(item => item !== id));
    }
  };


    

  const showNoreordFound = allData && allData.length > 0 ? true : false;

  console.log(dropdownValue,'dropdownValue')


  return (
    <>
      {/* <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
        /> */}
      {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}
<div style={{display: 'flex', flex: 1, padding:'25px 20px 15px'}}>
<div className="cutsomers-page-listing shareable_list primary_panel_content">
        <div className="company_logo">
          <img src={Logo} alt="" />
        </div>

        <div className="mt-4" style={{display: 'flex'}}>
          <div className="customer_list_dropdown">
            <div className="roi-template-dropdown">
              <div className="select-wrapper">
                <Dropdown
                  onSelect={(eventKey, event) => {
                    // setAreas_Filter(eventKey);
                    // setdropdownValue([eventKey]);
                    setDropdownType(eventKey);
                    setSearchQry('');
                    const innerValue = listForDropDown.find(
                      item => item.id === eventKey,
                    )?.title;
                    setdropdownValue([innerValue]);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {dropdownType
                      ? listForDropDown.find(item => item.id === dropdownType)
                          ?.title
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{
                    width: "-webkit-fill-available",
                    width: "-moz-fill-available"
                    }}
                    className="dropdown-custom-full-width-menu"
                    >
                    {listForDropDown.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item.id.toString()}
                        active={item.id === dropdownType}>
                        {item.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="input-group">
              <Form.Control
                type="text"
                value={searchQry}
                pattern="^\S.*$"
                required
                placeholder="Search"
                onChange={e => {
                  setSearchQry(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="search-tabs-icons">
            {/* <span
              className={
                // secondComponent === 'group-component'
                //   ? 'icon active'
                //   :
                'icon'
              }>
              <i
                className="icon-plus"
                // onClick={() =>
                //   setSecondComponent('group-component')
                // }
              />
            </span>

            <span
              //   onClick={() => {
              //     setThreeDots(!threeDots);
              //   }}
              className={
                // threeDots
                //   ? 'icon active threeDots'
                //   :
                'icon threeDots'
              }>
              <i className="fas fa-ellipsis-vertical" />
            </span> */}
          </div>
        </div>
        {/* <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
          <CustomSlidingButtons
            items={listForDropDown}
            activeButtons={areas_Filter}
            onItemClick={e => {
              dropDownSelection(e);
            }}
            showItems={5}
          />
        </div> */}

        <div className="filter_btns_wraper mt-4 ">
          {Object.entries(filterButtons).length > 0 &&
            filterButtons.map((item, index) => (
              <div
                className={`shareable_filter_btn ${
                  filterbtns.includes(item.id) ? 'filter_active' : ''
                }`}
                onClick={() => {
                  handleFilter(item.id);
                }}>
                {item.name}
              </div>
            ))}
        </div>
        <div className="mt-4 customers-list refferal-list-wrapper">
        {showLoader && <LoaderSpinner />}
          <Scrollbars

          >
            <div>
              
              {allData.length > 0 && (
                <Row className="mb-3 mt-2">
                  <Col md={6} className="role-head">
                    {dropdownValue} Name
                  </Col>

                  <Col md={2} className="role-head">
                    Category
                  </Col>
                </Row>
              )}
              {Object.entries(allData).length > 0 &&
                allData.map((item, index) => (
                  <Row key={`r-item-${index}`}>
                    <Col md={6} className="role-row icons-row">
                      <>
                        {item.data_type === 'session' && (
                          <>
                             <div className='shareable_title'>
                             {item.session_title}
                            </div>
                              
                            {/* </span> */}
                          </>
                        )}
                        {item.data_type === 'event' && (
                          <>
                           
                            <div className='shareable_title'>
                            {item.session_title}
                            </div>
                          </>
                        )}
                        {item.data_type === 'opportunity' && (
                          <>
                            
                            <div className='shareable_title'>
                            {item.title}
                            </div>
                          </>
                        )}
                        {item.data_type === 'appointment' && (
                          <>
                           <div className='shareable_title'>
                              {item.title}
                            </div>
                          </>
                        )}
                        {item.data_type === 'group_chat' && (
                          <>
                            <div
                              className="user-img as-link"
                              // onClick={() => {
                              //   amplitudeEvent('OPEN_CHAT_ROOM');
                              //   handleOpenChatRoom(item);
                              // }}
                            >
                              {item.chat_type === 'question' && (
                                <span>
                                  <i className="fa-solid fa-q"></i>
                                </span>
                              )}
                              {item.chat_type === 'opportunity' && (
                                <span className="icon icon-posts"></span>
                              )}
                              {item.chat_type === 'event' && (
                                <span className="icon-tickets"></span>
                              )}
                              {item.chat_type === 'session' && (
                                <span className="icon-video-camera-2"></span>
                              )}
                              {/* <span className="fa-solid fa-shop"></span> */}
                            </div>
                            <div
                              className="user-content as-link"
                              onClick={() => {
                                // handleOpenChatRoom(item);
                              }}>
                              <h2 className="single-line-text">
                                {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                {item.group_name !== ''
                                  ? item.group_name
                                  : fixName(
                                      item[
                                        parseInt(item.creator_id) ===
                                        parseInt(localStorage.getItem('user'))
                                          ? 'user_detail'
                                          : item.hasOwnProperty(
                                              'creator_details',
                                            )
                                          ? 'creator_details'
                                          : 'creator_detail'
                                      ]['first_name'],
                                      item[
                                        parseInt(item.creator_id) ===
                                        parseInt(localStorage.getItem('user'))
                                          ? 'user_detail'
                                          : item.hasOwnProperty(
                                              'creator_details',
                                            )
                                          ? 'creator_details'
                                          : 'creator_detail'
                                      ]['last_name'],
                                    )}
                              </h2>

                              {formatString(item.last_message)}
                            </div>
                          </>
                        )}

                        {item.data_type === 'network' && (
                          <>
                        <div className='shareable_title'>
                        {item && item.first_name}
                            {item && item.last_name}
                        </div>
                           
                          </>
                        )}

                        {item.data_type === 'accomplishment' && (
                          <>
                           <div className='shareable_title'>
                           {item.title}
                            </div>
                            {/* <h2 className="single-line-text">{item.title}</h2> */}
                          </>
                        )}
                      </>
                    </Col>
                    <Col
                      md={2}
                      className="role-row"
                      style={{
                        color: '#000',
                        fontFamily: 'Inter',
                        fontSize: '19.05px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}>
                      Social
                    </Col>
                    <Col md={2} className="role-row icons-row-2">
                      <p
                        style={{
                          color: '#272727',
                          fontFamily: 'Inter',
                          fontSize: '19.05px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: 'normal',
                          textDecoration: 'underline',
                        }}
                        className="as-link"
                        onClick={() => {
                          handlePreview(item);
                        }}>
                        Preview
                      </p>
                    </Col>
                    <Col md={1} className="role-row icons-row-1">
                      <div className="d-flex align-items-center">
                        <img
                          className="ScanQr as-link"
                          src={ShareYellowIcon}
                          alt="scanQr"
                          onClick={() => {
                            let type = '';
                            if (item.data_type === 'session') {
                              type = 'virtual';
                            } else if (item.data_type === 'event') {
                              type = 'in-person';
                            } else if (item.data_type === 'opportunity') {
                              type = 'recruitment';
                            } else if (item.data_type === 'group_chat') {
                              type = 'group_chat';
                            } else if (item.data_type === 'network') {
                              type = 'profile';
                            } else if (item.data_type === 'accomplishment') {
                              type = 'snapshot';
                            } else if (item.data_type === 'appointment') {
                              type = 'appointment';
                            }
                            props.ShareAbleRefer(type, item);
                          }}
                        />
                        <img
                          className="sharableYellow as-link"
                          src={ShareablesYellowIcon}
                          alt="shareable"
                          onClick={() => {
                            handleShare(item);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}

              {allData && allData.length === 0 && (
                <>
                  {!showNoreordFound && dropdownValue.includes('Virtual') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/session.png')}
                          alt="no-session"
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>Manage Sessions</h2>
                        <p>Meetup with your network virtually</p>

                        <div className="btn-wrapper mt-3">
                          <Button className="btn btn-dark">
                            <Link
                              className="text-light"
                              style={{textDecoration: 'none'}}
                              to={{
                                pathname: '/',
                                state: {
                                  typeIs: 'sessionss',
                                  data: 'createSession',
                                },
                              }}>
                              Create Session
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showNoreordFound && dropdownValue.includes('In-Person') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/event.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>{labels.MANAGE_EVENTS[selected_lang]}</h2>
                        <p>{labels.Connect_Network[selected_lang]}</p>

                        <div className="btn-wrapper mt-3">
                          <Button className="btn btn-dark">
                            <Link
                              className="text-light"
                              style={{textDecoration: 'none'}}
                              to={{
                                pathname: '/',
                                state: {
                                  typeIs: 'events',
                                  data: 'show_create_event',
                                },
                              }}>
                              Create Event
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showNoreordFound &&
                    dropdownValue.includes('Recruitments') && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/opportunity.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>{labels.Share_Opportunity[selected_lang]}</h2>
                          <p>{labels.Someone_Waiting[selected_lang]}</p>

                          <div className="btn-wrapper mt-3">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                props.setProperComponent('inviteUser');
                              }}>
                              {labels.Create_Opportunity[selected_lang]}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  {!showNoreordFound &&
                    dropdownValue.includes('Appointments') && (
                      <div className="no-results-wrapper no-appointments mt-5">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/no-appointments.png')}
                            alt=""
                          />
                        </div>
                        <div className="no-results-content">
                          <p className="noRecord-title">No Upcoming 1-on-1s</p>
                          <p className="noRecord-desc">
                            You don't have any upcoming appointments
                          </p>

                          <div className="btn-wrapper mt-3">
                            <Button
                              className={
                                'btn btn-dark'
                                // getItem('userRole') === 'guest'
                                //   ? 'grey-class-btn'
                                //   : 'btn btn-dark'
                              }>
                              <Link
                                // onClick={e => {
                                //   loginUserRole(e);
                                // }}
                                className="text-light"
                                style={{
                                  textDecoration: 'none',
                                }}
                                to={{
                                  pathname: '/circle',
                                  state: {
                                    typeIs: 'search',
                                  },
                                }}>
                                Explore Builders
                              </Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  {!showNoreordFound && dropdownValue.includes('Credentials') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/no-snapshot.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>Manage Credentials</h2>
                        <p>
                          Create shareable one-pagers for your business(es),
                          affiliations and more
                        </p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              props.setProperComponent('create-snapshot');
                            }}>
                            Add Credentials
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showNoreordFound && dropdownValue.includes('Links') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/svg-icons/link.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>No Links Available</h2>
                        <p>
                          Add links to quickly promote things that matter most
                        </p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Link
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showNoreordFound &&
                    dropdownValue.includes('Group Chats') && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/svg-icons/noGroup.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No Active Group Chats</h2>
                          <p>Create a group chat to collaborate with others</p>

                          <div className="btn-wrapper mt-3">
                            <Button className="btn btn-dark">
                              <Link
                                // onClick={e => {
                                //   loginUserRole(e);
                                // }}
                                className="text-light"
                                style={{
                                  textDecoration: 'none',
                                }}
                                to={{
                                  pathname: '/messages',
                                  state: {
                                    typeIs: 'create-group',
                                  },
                                }}>
                                {' '}
                                Create Group Chat
                              </Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  {!showNoreordFound && dropdownValue.includes('Forms') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img src={NoForm} alt="" />
                      </div>

                      <div className="no-results-content">
                        <h2>No Forms Added</h2>
                        <p>
                          Create forms to learn more and add to your network
                        </p>

                        <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Form
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showNoreordFound && dropdownValue.includes('Network') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/svg-icons/network.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>No Contacts Added</h2>
                        <p>
                          Add contacts to become more efficient in the way you
                          interact
                        </p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Contact
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  {!showNoreordFound && dropdownValue.includes('Gifts')  && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/gifts2.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>No Gifts Available</h2>
                        <p>
                          Keep people excited and motivated while connecting
                          with your network
                        </p>

                        {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                      </div>
                    </div>
                  )}
                  {!showNoreordFound && dropdownValue.includes('Offerings')  && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/offerings.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>No Offerings Available</h2>
                        <p>
                          Quickly share what your business offers to increase
                          sales
                        </p>

                        {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                      </div>
                    </div>
                  )}
                  {!showNoreordFound && dropdownValue.includes('Payments')  && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img src={Dollar} alt="" />
                      </div>

                      <div className="no-results-content">
                        <h2>No Payment Options Available</h2>
                        <p>
                          Provide payment options to quickly collect funds from
                          your network
                        </p>

                        {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                      </div>
                    </div>
                  )}
                  {!showNoreordFound && dropdownValue.includes('Polls')  && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/polls.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>No Polls Available</h2>
                        <p>Ask quick questions to gain impactful insight</p>

                        {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                      </div>
                    </div>
                  )}

                  {!showNoreordFound &&
                    tabType === labels.ELEMENT[selected_lang] &&
                    (dropdownValue.includes('Workspace') ||
                      dropdownValue.includes('All')) && (
                      <div className="no-results-wrapper mb-3">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/shareable.png')}
                            alt="no-session"
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No ShareAbles Available</h2>
                          <p>
                            Aadd ShareAbles to increase engagement, revenue and
                            measure what matters
                          </p>
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
</div>
      

      {model === 'link_model' && (
        <CustomModal
          position="bottom"
          className={'share-links-modal'}
          onClose={() => setModel('')}
          setTabType={setTabType}
          >
          <div className="camera-options-list w-100 social-links-modal">
            <ul>
              <li className="as-link">
                <FacebookShareButton url={shareUrl} title={false}>
                  <i className="fa-brands fa-facebook"></i>
                </FacebookShareButton>
              </li>
              <li className="as-link">
                <WhatsappShareButton url={shareUrl}>
                  <i className="fa-brands fa-whatsapp"></i>
                </WhatsappShareButton>
              </li>
              <li className="as-link">
                <EmailShareButton url={shareUrl}>
                  <i className="fa-solid fa-envelope"></i>
                </EmailShareButton>
              </li>
              <li className="as-link">
                <TwitterShareButton url={shareUrl}>
                  <i className="fa-brands fa-twitter"></i>
                </TwitterShareButton>
              </li>
              <li className="as-link">
                <LinkedinShareButton url={shareUrl} setShareUrl={setShareUrl}>
                  <i className="fa-brands fa-linkedin"></i>
                </LinkedinShareButton>
              </li>
            </ul>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default ShareAblesList;
