import {
    ADD_ADMIN_SUBSCRIPTIONS,
    GET_ADMIN_SUBSCRIPTIONS,
    UPDATE_ADMIN_SUBSCRIPTION,
    REMOVE_ADMIN_SUBSCRIPTION,
    GET_ADMIN_PAYMENTS,
    REMOVE_ADMIN_PAYMENT,
    GET_EXPENSES,
    ADD_EXPENSE,
    UPDATE_EXPENSE,
    REMOVE_EXPENSE,
    GET_TESTIMONIALS,
    ADD_TESTIMONIAL,
    UPDATE_TESTIMONIAL,
    REMOVE_TESTIMONIAL,
    SAVE_TERM_PAGES,
} from "../../Constants/Types";

export const adminSubscriptions = (state = [], action = {}) => {
    let subscriptions;
    switch (action.type) {
        case GET_ADMIN_SUBSCRIPTIONS:
            return action.subscriptions;
        case ADD_ADMIN_SUBSCRIPTIONS:
            subscriptions = state;
            if (Object.entries(subscriptions).length > 0) {
                subscriptions.unshift(action.subscription);
            } else {
                subscriptions = subscriptions.push(action.subscription);
            }
            return subscriptions;
        case UPDATE_ADMIN_SUBSCRIPTION:
            subscriptions = state.map((subscription) => {
                if (subscription.id === action.subscription.id) return { ...subscription, ...action.subscription };
                return subscription;
            });
            return subscriptions;
        case REMOVE_ADMIN_SUBSCRIPTION:
            subscriptions = state.filter((subscription) => {
                return subscription.id !== action.subscription.id;
            });
            return subscriptions;
        default:
            return state;
    }
};

export const termPages = (state = [], action = {}) => {
    let pages;
    switch (action.type) {
        case SAVE_TERM_PAGES:
            return action.pages;
        case REMOVE_ADMIN_PAYMENT:
            pages = state.filter((payment) => {
                return payment.id !== action.payment.id;
            });
            return pages;
        default:
            return state;
    }
};
export const adminPayments = (state = [], action = {}) => {
    let payments;
    switch (action.type) {
        case GET_ADMIN_PAYMENTS:
            return action.payments;
        case REMOVE_ADMIN_PAYMENT:
            payments = state.filter((payment) => {
                return payment.id !== action.payment.id;
            });
            return payments;
        default:
            return state;
    }
};

export const expensesList = (state = [], action = {}) => {
    let expenses;
    switch (action.type) {
        case GET_EXPENSES:
            return action.expenses;
        case ADD_EXPENSE:
            expenses = state;
            if (Object.entries(expenses).length > 0) {
                expenses.unshift(action.expense);
            } else {
                expenses = expenses.push(action.expense);
            }
            return expenses;
        case UPDATE_EXPENSE:
            expenses = state.map((expense) => {
                if (expense.id === action.expense.id) return { ...expense, ...action.expense };
                return expense;
            });
            return expenses;
        case REMOVE_EXPENSE:
            expenses = state.filter((expense) => {
                return expense.id !== action.expense.id;
            });
            return expenses;
        default:
            return state;
    }
};

export const testimonialList = (state = [], action = {}) => {
    let testimonials;
    switch (action.type) {
        case GET_TESTIMONIALS:
            return action.testimonials;
        case ADD_TESTIMONIAL:
            testimonials = state;
            if (Object.entries(testimonials).length > 0) {
                testimonials.unshift(action.testimonial);
            } else {
                testimonials = testimonials.push(action.testimonial);
            }
            return testimonials;
        case UPDATE_TESTIMONIAL:
            testimonials = state.map((testimonial) => {
                if (testimonial.id === action.testimonial.id) return { ...testimonial, ...action.testimonial };
                return testimonial;
            });
            return testimonials;
        case REMOVE_TESTIMONIAL:
            testimonials = state.filter((testimonial) => {
                return testimonial.id !== action.testimonial.id;
            });
            return testimonials;
        default:
            return state;
    }
};
