import React, {useState, useEffect} from 'react';

import Sidebar from '../Sidebar';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import {
  SesssionItem,
  EventsItem,
  OnBoardingModal,
  SessionSearchFilter,
  DiscoverBuilders,
  Pannels,
  CustomModal,
} from '../../Components';
import OpportunityScreen from '../../Components/FrontScreens/OpportunityScreen';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';

import {useHistory, useLocation, useParams} from 'react-router-dom';
import {rewardStar} from '../../Constants/Images';

// import { eventItems } from "../../Constants/DummyContent";
import API from '../../Services/SessionServices';

import ShowEventsDetails from '../../Components/EventDetails/ShowEventsDetails.js';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';

import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import CreateSession from '../../Components/SessionDetails/CreateSession';

import Map from '../../Components/GoogleMap/Map';
import {Scrollbars} from 'react-custom-scrollbars';

import {useSelector, useDispatch, connect} from 'react-redux';
import EventDetail from '../../Components/Referral/EventDetail';
import PurchaseTicket from '../../Components/Referral/PurchaseTicket';

import {
  getSessionListing,
  getSessionCategory,
  getAllBuilderList,
} from '../../Store/Actions/SessionAction';

import ReferOpportunity from '../../Components/Referral/ReferOpportunity';
import {toggleSecondaryPannel} from '../../Store/Actions/CommanAction';
import SessionDetail from '../../Components/SessionDetails/SessionDetail';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import AllBuilders from './StaticPages/AllBuilders';

import {amplitudeEvent} from '../../Services/AmplitudeServices';
import PraiseCoution from '../../Components/SessionDetails/PraiseCoution';
import SendInvitation from './StaticPages/SendInvitation';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';

import SessionPopup from '../../Components/EventDetails/SessionPopup';
import Slider from 'react-slick';
import {getItem} from '../../Utils/LocalStorage.js';
import moment from 'moment';
import {checkPermissions, goToZoom} from '../../Utils/commonUtils.js';
import Calender from '../../Components/FeedBack/Calender.js';

const SessionEvents = props => {
  // localStorage.setItem('newSessionsCount',  JSON.stringify(sessionPopup))

  //   const database = ref(db, 'new_session/');

  // get(database)
  //       .then((snapshot) => {
  //       if (snapshot.val()) {

  //         var sessionPopup = {
  //           count : 0,
  //           checked_at : snapshot.val().time

  //         }

  //         localStorage.setItem('newSessionsCount',  JSON.stringify(sessionPopup))

  //
  //         // localStorage.setItem('new_session_count', '0');
  //         // localStorage.setItem('new_session_creator_id',snapshot.val().creator_id)

  //       }
  //     });

  const [pageNo, setPageNo] = useState(1);
  const [pageLoad, setPageLoad] = useState(true);
  const [currentComponent, setCurrentComponent] = useState('sessions');
  const [typeIs, setTypeIs] = useState(true);
  const [sessionType, setGalleryType] = useState('happening');
  const [searchQry, setSearchQry] = useState('');

  const [sessionList, setSessionList] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  // const [showComponent, setShowComponent] = useState('');
  const [mapButton, setMapButton] = useState(false);
  const [active, setActive] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);
  const [showPreSpinner, setShowPreSpinner] = useState(true);
  const [onBoardingModal, setonBoardingModal] = useState(false);
  const [inviteSnaphot, setInviteSnapshot] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [chooseCircle, setChooseCircle] = useState(false);

  const [userData, setUserData] = useState([]);
  const [plusButton, setPlusButton] = useState(true);

  const [button, setButton] = useState();

  const [showDetails, setShowDetails] = useState({});

  const [createdSessionId, setcreatedSessionId] = useState('');

  const [bullseyeButton, setBullseyeButton] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(true);
  const [isCheck, setIsCheck] = useState(['5', '4', '2', '1', '3']);
  const [list, setList] = useState([]);
  const [sessionLink, setSessionLink] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [buildersUserId, setBuildersUserId] = useState('');

  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [gender, setGender] = useState([]);
  const [focusLocations, setFocusLocations] = useState([]);
  const [serverTime, setServerTime] = useState('');

  const [appointmentId, setAppointmentId] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [persnalTypesId, setPersnalTypesId] = useState([]);
  const [idForPraiseCaution, setIdForPraiseCaution] = useState('');
  const [surveyData, setSurveyData] = useState([]);
  const [singleSurveyData, setSingleSurveyData] = useState({});
  const [selectedSurveyItem, setSelectedSurveyItem] = useState([]);

  const [openSurveyModals, setOpenSurveyModals] = useState('');
  const [showFilterModel, setShowFilterModel] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [filterFrom, setFilterFrom] = useState('');
  const [iconTrue, setIconTrue] = useState('');
  const [checkUserHitApi, setCheckUserHitApi] = useState(false);
  const [fromCalendar, setFromCalendar] = useState(false);
  const [toCalendar, setToCalendar] = useState(false);

  const [refresh, setRefresh] = useState([]);
  const {id: backgroundId} = useParams();
  const currentURL = window.location.href;

  const survey = localStorage.getItem('survey');

  // Create a URL object from the current URL
  const url = new URL(currentURL);

  // Get the 'id' parameter value
  const id = url.searchParams.get('id');

  const [popup, setPopup] = useState(false);
  const sessionData = useSelector(state => state.sessionListing);
  const menuPermesions = useSelector(state => state.menuPermesionList);
  const menuItems = useSelector(state => state.menuActiveList);

  const dispatch = useDispatch();

  const {pathname} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);

  // const id = searchParams.get('id');

  const history = useHistory();
  const locationWeb = useLocation();

  const modelCloseIconStyle = {
    right: '-10px',
    top: '-64px',
  };
  // const [topIcons, setTopIcons] = useState({
  //   focus: false,
  //   location: false,
  //   vaccinate: false,
  //   dollar: false,
  //   store: true,
  //   bullseye: false,
  // });

  useEffect(() => {
    // alert('survey')
    setTimeout(() => {
      getSurveyData();
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSurveyData = async id => {
    const payload = {
      survey_id: id,
      status: 'answered',
      option_id: selectedSurveyItem.toString(),
    };
    await commonApi
      .survey_response(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          handleNextSurveyModal(id);
        }
      })
      .catch(err => {
        // setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleNextSurveyModal = id => {
    const updatedSurveyData = surveyData.filter(item => item.id !== id);

    if (updatedSurveyData.length > 0) {
      setSelectedSurveyItem([]);
      setSingleSurveyData(updatedSurveyData[0]);
      setOpenSurveyModals('survey-modal');
      setSurveyData(updatedSurveyData);
    } else {
      setOpenSurveyModals('');
      setSurveyData();
      setSingleSurveyData();
      setSelectedSurveyItem();
    }
  };
  const getSurveyData = async () => {
    if (survey === 'yes') {
      // alert('')
      const response = await commonApi.user_surveys({});

      if (response.data.length > 0) {
        setSurveyData(response.data);
        setSingleSurveyData(response.data[0]);
        setOpenSurveyModals('survey-modal');
        // setShowComponent('survey-modal');
      }
    }
  };

  const selectSurveyOption = item => {
    if (!selectedSurveyItem.includes(item.id)) {
      setSelectedSurveyItem(previous => [...previous, item.id]);
      // props.setEvenBriteEvents(previous => [...previous, item]);
    } else {
      setSelectedSurveyItem(previous =>
        previous.filter(itemId => itemId !== item.id),
      );
      props.setEvenBriteEvents(previous =>
        previous.filter(miniItem => miniItem.id !== item.id),
      );
    }
  };

  // }
  // if(survey=='yes'){

  //   getSurveyData()
  // }

  const fun = h => {
    setSessionList(h);
  };

  useEffect(() => {
    var data = {
      count: 0,
      checked_at: 0,
    };
    localStorage.setItem('newSessionsCount', JSON.stringify(data));
  }, []);

  const getApiSessionList = async (sesType, qry, eventIs, page) => {
    setGalleryType(sesType);
    // const filterids = refresh.map(item => item.id);
    var data = {
      category_id: isCheck,
      events: eventIs,
      focus: active ? 'yes' : 'no',
      // gender: gender.toString(),
      gender: '',
      otherIdentifires: identiferId.toString(),
      // ethnicities: ethnicityId.toString(),
      ethnicities: '',
      city_states: focusLocations,
      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId,
      on_boarding_categories: categoriesId,
      page: page,
      search_query: qry,
      sessions: !eventIs,
      type: sesType,
      from: filterFrom,
      to: filterTo,
      // page: '',
      // event: eventIs,

      // workspace_ids: filterids.length > 0 ? filterids.toString(',') : '',
    };

    const response = await dispatch(getSessionListing(data));

    if (response) {
      if (page === 1) {
        // setSessionList(response);
        setPageNo(2);
        setPageLoad(true);
      } else if (page > 1) {
        if (response?.meetup?.length > 0) {
          // setSessionList(current => {
          //   if (
          //     response.session.find(
          //       objs =>
          //         objs.id == current.session[current.session.length - 1].id,
          //     )
          //   ) {
          //     return current;
          //   } else {
          //     return {
          //       ...current,
          //       session: [...current.session, ...response.session],
          //     };
          //   }
          // });
          // setSessionList(res);
          setPageNo(page + 1);
          setPageLoad(true);
        } else {
          setPageLoad(false);
        }
      }

      setShowSpinner(false);
    }

    // API.sessions_list(data)
    //   .then(res => {

    //     if (res) {
    //       if (page === 1) {
    //         setSessionList(res);
    //         setPageNo(2);
    //         setPageLoad(true);
    //       } else if (page > 1) {
    //         if (res.session.length > 0) {
    //           setSessionList(current => {
    //             if (
    //               res.session.find(
    //                 objs =>
    //                   objs.id == current.session[current.session.length - 1].id,
    //               )
    //             ) {
    //               return current;
    //             } else {
    //               return {
    //                 ...current,
    //                 session: [...current.session, ...res.session],
    //               };
    //             }
    //           });
    //           // setSessionList(res);
    //           setPageNo(page + 1);
    //           setPageLoad(true);
    //         } else {
    //           setPageLoad(false);
    //         }
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };
  const serverTimeCheck = () => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .check_server_time()
      .then(res => {
        setServerTime(res.server_time);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const eventDetails = index => {
    setShowDetails(sessionList.session[index]);
  };

  // const getfolowers = qry => {
  //   var payLoad = {
  //     search_query: qry,
  //     user_id: localStorage.getItem('user'),
  //   };
  //   commonApi
  //     .followers_list(payLoad)
  //     .then(res => {

  //       if (res.status === 200) {
  //         // setFolowers(res.data);
  //         setFolowers(
  //           res.data.filter(
  //             item =>
  //               !audience.includes(item.id) && !modrator.includes(item.id),
  //           ),
  //         );
  //         setnNewFolowers(
  //           res.data.filter(
  //             item =>
  //               !audience.includes(item.id) && !modrator.includes(item.id),
  //           ),
  //         );
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const handleNotificationDirection = () => {
    const {pathname, state} = locationWeb;

    if (state && state.typeIs === 'events') {
      getApiSessionList('happening', '', true, 1);
      if (state.event) {
        setTypeIs(false);
        setCurrentComponent('events');
        componentDecesion(state.event, 'event');
      } else if (
        state.typeIs === 'events' &&
        state.data === 'show_create_event'
      ) {
        setCurrentComponent('events');
        setPlusButton(false);
      } else {
        setTypeIs(false);
        setCurrentComponent('events');
      }
      history.replace({pathname: pathname, key: Date.now()});
    }

    if (state && state.typeIs === 'session') {
      componentDecesion(state.session, 'session');

      history.replace({pathname: pathname, key: Date.now()});
    }

    if (
      state &&
      state.typeIs === 'sessionss' &&
      state.data === 'createSession'
    ) {
      setPlusButton(false);
    }
    if (state && state.typeIs === 'virtual' && state.meetup_item) {
      // alert('hehe')
      setCurrentComponent('sessions');
      componentDecesion(state.meetup_item, 'session');
    }
    if (state && state.typeIs === 'in-person' && state.meetup_item) {
      // alert('hehe22')
      setCurrentComponent('events');
      componentDecesion(state.meetup_item, 'event');
    }

    // if (state && state.typeIs === 'subInvites') {
    //   setGalleryType('subInvites');
    //   history.replace({pathname: pathname});
    // }

    if (state && state.typeIs) {
    } else {
      //commented for double api hit
      // getApiSessionList(sessionType, '', !typeIs, pageNo);
    }
  };
  const eventDetail = id => {
    var payLoad = {
      event_id: id,
    };
    commonApi
      .event_detail(payLoad)
      .then(response => {
        if (response.status === 200 && response.success === true) {
          setTypeIs(false);
          setCurrentComponent('events');
          setUserData(response.event_detail);
          setShowComponent('event');
          // setEventData(response.audience_details);
          // setEventAud(response.pending_audience);
          // setSearchEventAud(response.pending_audience);
          // setSerachEventData(response.audience_details);
          // setSearchModDetail(response.moderators_details);
          // setRsvp(response.rsvp);
          // setSearchQry(response.rsvp);
          // setPenMod(response.pending_moderator);
          // setSearchPenMod(response.pending_moderator);
          // setSessionData(response.event_detail);
          // setModAud(response);
          // setModDetail(response.moderators_details);
          // setMediaImg(response.event_moment_media);
          // setShowLoader(false);
        } else {
          errorMessage(labels.Meetup_Not_found[selected_lang]);
        }
      })
      .catch(err => {
        // setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const joinTheSession = async id => {
    let payload = {conference_id: id};
    const response = await API.is_session_started(payload);

    if (response.started) {
      if (response.session_id) {
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', id);
        // history.push('/session-details');
        goToZoom(response.session_id);
      } else {
      }
    } else {
      errorMessage(response.message);
      // history.push('/');
      // window.location.reload();
    }
  };
  const sessionDetail = async id => {
    let payload = {session_id: id};
    const response = await commonApi.session_detail(payload);

    if (response.success === true) {
      // alert('');

      if (
        response.session_detail.status === 'pending' &&
        response.session_detail.session_start_time
      ) {
        componentDecesion(response.session_detail, 'session');
      } else {
        joinTheSession(id);
      }
    } else {
      errorMessage(labels.Meetup_Not_found[selected_lang]);
    }
  };

  // useEffect(() => {
  //   if (builderSearchQry === '') {
  //     getfolowers();
  //   } else {
  //     getfolowers(builderSearchQry);
  //   }
  // }, [builderSearchQry]);

  useEffect(() => {
    serverTimeCheck();
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // getTabstatus();

    // var urlUser = window.location.pathname;
    // urlUser = urlUser.slice(1);
    // var url = urlUser.split(/[.\-/]/);

    // if (url && url[1] == 'event_invite') {
    //   //  joinTheSession(url[2])
    //   getDataEvent(url[2]);
    // }
    // if (url && url[1] == 'profile_invite') {
    //   //  joinTheSession(url[2])
    //   setTimeout(() => {
    //     getProfile(url[2]);
    //   }, 2000);
    // }

    if (urlParts[2] === 'in-person') {
      getDataEvent(id);
    }

    if (urlParts[2] === 'virtual') {
      sessionDetail(id);
      // setTimeout(() => {
      //   joinTheSession(id);
      // }, 2000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      urlParts.length > 0 &&
      (urlParts.includes('profile') || urlParts.includes('snapshot'))
    ) {
      setTimeout(() => {
        const companyExist = localStorage.getItem('companyExist');

        console.log(companyExist, 'companyExistcompanyExist');
        if (
          urlParts[2] === 'profile' &&
          /^\d+$/.test(urlParts[1]) &&
          companyExist &&
          companyExist === 'true'
        ) {
          buildersHandle('profileComponent', id);
        }

        if (
          urlParts[2] === 'snapshot' &&
          /^\d+$/.test(urlParts[1]) &&
          companyExist &&
          companyExist === 'true'
        ) {
          setInviteSnapshot(id);
          buildersHandle('profileComponent', urlParts[1]);
        }

        localStorage.setItem('companyExist', false);
      }, 2000);
    }
  }, [menuItems]);

  const getDataEvent = id => {
    if (id) {
      eventDetail(id);
    }
  };

  useEffect(() => {
    if (
      locationWeb &&
      locationWeb.state &&
      locationWeb.state.creator === 'yes'
    ) {
      successMessage('Session Successfully ended');
    }

    if (
      locationWeb &&
      locationWeb.state &&
      locationWeb.state.close_session === 'yes'
    ) {
      // const historyIs = createHistory();
      if (
        history.location &&
        history.location.state &&
        history.location.state.close_session
      ) {
        const state = {...history.location.state};
        delete state.close_session;
        history.replace({...history.location, state});
      }
      if (
        history.location &&
        history.location.state &&
        history.location.state.creator_id?.id !== localStorage.getItem('id')
      ) {
        const state = {...history.location.state};
        setIdForPraiseCaution(state.creator_id);
        delete state.creator_id;
        history.replace({...history.location, state});

        setShowComponent('praiseCaution');
      }
      localStorage.setItem('session_started', '');
      successMessage('Session is closed by moderator.');
    }

    if (
      locationWeb &&
      locationWeb.state &&
      locationWeb.state.banu_user === 'yes'
    ) {
      errorMessage('Youre banned for this session.');
    }
    // if (
    //   locationWeb &&
    //   locationWeb.state &&
    //   locationWeb.state.typeIs === 'events'
    // ) {
    //   if (
    //     history.location &&
    //     history.location.state &&
    //     history.location.state.typeIs
    //   ) {
    //     const state = {...history.location.state};
    //     delete state.typeIs;
    //     history.replace({...history.location, state});
    //   }
    //   // locationWeb.state = undefined;
    //   setTypeIs(false);
    //   setCurrentComponent('events');
    //   // getApiSessionList(sessionType, "", true, pageNo);
    // } else {
    //   getApiSessionList(sessionType, '', !typeIs, pageNo);
    // }

    handleNotificationDirection();

    dispatch(getSessionCategory());
    // getDiscoverBuilders();
    setTimeout(() => {
      setShowPreSpinner(false);
    }, 1000);
    amplitudeEvent('VISIT_MEETUPS');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (backgroundId) {
      setTimeout(() => {
        getBackgroundData();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackgroundData = () => {
    const DBOpenRequest = window.indexedDB.open('Build', 4);

    DBOpenRequest.onsuccess = event => {
      const db = DBOpenRequest.result;

      getData(db);
    };

    function getData(db) {
      const transaction = db.transaction(['Table1'], 'readwrite');

      transaction.oncomplete = event => {};

      transaction.onerror = event => {
        console.log(transaction.error, 'transaction error');
      };

      const objectStore = transaction.objectStore('Table1');

      const objectStoreRequest = objectStore.get('1');

      objectStoreRequest.onsuccess = event => {
        const myRecord = objectStoreRequest.result;

        if (
          ['create_session', 'session_started', 'session'].includes(
            myRecord.value.type,
          )
        ) {
          if (myRecord.value.conference_id) {
            joinTheSessionNotification(myRecord.value.conference_id);
          } else {
            joinTheSessionNotification(myRecord.value.session.id);
          }
        }

        // setBackgroundData(myRecord.value)
        let type;

        myRecord.value.data.event === 'no'
          ? (type = 'session')
          : (type = 'event');
        if (type === 'event') {
          getApiSessionList('happening', '', true, 1);
          setCurrentComponent('events');
        }
        componentDecesion(myRecord.value.data, type);

        setTimeout(() => {
          deleteDataFromIndexDb(db);
        }, 3000);
      };
    }
  };

  const joinTheSessionNotification = async id => {
    let payload = {conference_id: id};
    const response = await API.is_session_started(payload);
    if (response.started) {
      if (response.session_id) {
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', id);
        // history.push('/session-details');
        // window.location.reload();
        goToZoom(response.session_id);
      } else {
      }
    } else {
      errorMessage(response.message);
    }
  };

  const deleteDataFromIndexDb = db => {
    let transaction = db.transaction(['Table1'], 'readwrite');
    transaction.objectStore('Table1').delete('1');

    transaction.oncomplete = () => {};
  };

  useEffect(() => {
    setTimeout(() => {
      setShowSpinner(true);
      getApiSessionList(sessionType, '', !typeIs, 1);
      serverTimeCheck();
    }, 1500);
    // setShowComponent('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionType, typeIs, refresh]);
  // useEffect(() => {

  //   getApiSessionList(sessionType, '', !typeIs, pageNo);
  // }, [showComponent]);

  const buttonPlus = () => {
    plusButton ? setPlusButton(false) : setPlusButton(true);
    setShowComponent('');
  };

  const openModal = () => {
    setShowModal(true);
  };

  const enterSession = async () => {
    // initializeVoxeet();
    var CheckLastSession = '';
    if (CheckLastSession && CheckLastSession === createdSessionId) {
      let payload = {conference_id: createdSessionId};
      const response = await API.is_session_started(payload);

      if (response.session_id) {
        // localStorage.setItem('conferenceAlias', response.conferenceAlias);
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', createdSessionId);
        // history.push('/session-details');
        goToZoom(response.session_id);
      }
    }
    if (!CheckLastSession) {
      let payload = {conference_id: createdSessionId};
      const response = await API.is_session_started(payload);

      if (response.session_id) {
        // localStorage.setItem('conferenceAlias', response.conferenceAlias);
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', createdSessionId);
        // history.push('/session-details');
        goToZoom(response.session_id);
      }
    }
    if (CheckLastSession && CheckLastSession !== createdSessionId) {
      errorMessage(
        'Your are already in a session ' +
          localStorage.getItem('session_started_name') +
          '.',
      );
    }
  };

  useEffect(() => {
    if (pageNo === 1) {
      setPageLoad(true);
    }
  }, [pageNo]);

  // useEffect(() => {
  //   if (props.eventButton) {
  //     setTypeIs(!typeIs);
  //     setCurrentComponent("events");
  //   }
  // }, []);

  const componentDecesion = (item, type) => {
    setUserData(item);
    setShowComponent(type);
  };

  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const buildersHandle = (t, id) => {
    setBuildersUserId(id);
    setShowComponent(t);
    setPlusButton(true);
    setMapButton(false);
  };

  const getTabstatus = () => {
    // setShowSpinner(true);
    commonApi
      .get_tab_status()
      .then(res => {
        // setShowSpinner(false);

        if (res.status === 200) {
          // setShowComponent('firstVist');
          const item = res.tab_status;
          if (item.length === 0) {
            setShowComponent('firstVist');
          } else {
            const newArray = [];
            if (Object.entries(item).length > 0) {
              item.forEach(item => {
                newArray.push(item.tab);

                // if (item.tab === "session_event" && item.tab ===  ){
                //   setShowComponent("");
                // }else{
                //   setShowComponent("firstVist");

                // }
              });
            }
            const found = newArray.find(element => element === 'session_event');
            if (found !== 'session_event') {
              setShowComponent('firstVist');
            }

            // if (newArray === ) {

            // }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    if ((showComponent !== '' && showComponent !== 'firstVist') || mapButton) {
      toggleHiddenPanel(true);
      // alert('hehe')
    } else {
      toggleHiddenPanel(false);
      // alert('hehe2')
    }
  }, [showComponent, mapButton]);
  useEffect(() => {
    if (!plusButton) {
      toggleHiddenPanel(true);
      // alert('hehe')
    } else {
      toggleHiddenPanel(false);
      // alert('hehe2')
    }
  }, [plusButton]);

  //commented because of Double Api hit

  // useEffect(() => {
  //   if (refresh.length > 0) {

  //     getApiSessionList(sessionType, '', !typeIs, 1);

  //     setTimeout(() => {
  //       setShowPreSpinner(false);
  //     }, 1000);

  //     // amplitudeEvent('VISIT_MEETUPS');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [refresh]);

  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);

      // console.log(urlPart,'========>urlParturlPart')
      // console.log(refresh,'========>refreshrefresh')
      // console.log(showComponent,'========>showComponent')

      if (
        Object.keys(refresh).length > 0 &&
        urlPart.length === 1 &&
        (showComponent !== '' || !plusButton)
      ) {
        setShowComponent('');
        setPlusButton(true);
      }
    }, 1000);
  }, [refresh]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     getBuilders();
  //   }, 1000);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showComponent, refresh, checkUserHitApi]);

  // useEffect(() => {
  //   // setNewNotification();
  //   // newSessionNotification();
  //   }, []);

  // useEffect(() => {
  //   setPopup(true)

  //   setTimeout(() => {
  //     setPopup(false)
  //   }, 2000);
  //  }, [newSessionCount]);

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'Attendance',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'On-Time',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'RSVP Conversion',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 0px',
    // Add other styles as needed
  };

  const getBuilders = () => {
    var data = {
      user_id: getItem('id'),
      search_query: '',
    };

    dispatch(getAllBuilderList(data));
  };

  const SessionPermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Meetups');

  console.log(refresh, '=====>refresh');

  const mdelStylr = {
    zIndex: '0',
  };
  return (
    <>
      {/* <Modal className="medium" show={showModal} onHide={() => closeModal()}>
        <Modal.Header>
          <Modal.Title>
            <h2>Request</h2>
          </Modal.Title>
          <div className="modal-header-btns">
            <span className="close as-link" onClick={() => closeModal()}>
              <i className="fas fa-times" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h3>You have successfully created {title}</h3>
            <p>
              If you don't join when the push notification is sent, you can
              simply go to the heart tab to quickly tap in. Psst... this is also
              where you can copy the URL to share on other platforms or tap to
              stop the reminder from being sent.{' '}
            </p>
          </div>
          <div className="session-profile-wrapper profile-modal">
            <Button onClick={() => enterSession()}>Enter Session</Button>
            <div>GENERATE GUEST PASS</div>
            <span>{sessionLink}</span>
            <br />
            <span
              className="builder-text"
              onClick={() => {
                navigator.clipboard.writeText(sessionLink);
                successMessage(labels.copy_link[selected_lang]);
              }}>
              COPY LINK
            </span>
          </div>
        </Modal.Body>
      </Modal> */}

      <div className="page-wrapper">
        {!props.eventButton && (
          <Sidebar
            // setWorkSpaceItem={setWorkSpaceItem}
            setWorkSpaceItem={setRefresh}
            location={props.location}
            setShowComponent={setShowComponent}
            showComponent={showComponent}
          />
        )}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            {SessionPermessions &&
              (SessionPermessions === 'allow' ||
                SessionPermessions?.analytics_row === true) && (
                <>
                  <div className="Reward_main analytics_slider_bg">
                    <span
                      className="analytics_slider_heading"
                      style={{color: analycticsSlider ? '' : '#999999'}}
                      onClick={() => {
                        setAnalycticsSlider(!analycticsSlider);
                      }}>
                      Analytics Row
                    </span>

                    {analycticsSlider && (
                      <div className="reward-top  mt-3 mb-2">
                        <Slider {...rewardSlider}>
                          {rewardSliderData.length > 0 &&
                            rewardSliderData.map((item, index) => (
                              <div className="reward-main">
                                <div className="reward-card">
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      fontSize: '13px',
                                    }}>
                                    {item.name}
                                  </span>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}>
                                    <span style={{fontSize: '17px'}}>
                                      {item.money}
                                    </span>
                                    {item.name === 'Ratings' && (
                                      <img src={item.svgIcon} alt="" />
                                    )}
                                    {item.name === 'Conversion' ? (
                                      <i
                                        className="fa-solid fa-arrow-down"
                                        style={{color: '#BD4141'}}></i>
                                    ) : (
                                      <i
                                        class="fa-solid fa-arrow-up"
                                        style={{color: '#52bd41'}}></i>
                                    )}
                                  </span>
                                  <div class="message-date-info">
                                    <span
                                      style={{color: '#333'}}
                                      class="message-date">
                                      {item.time}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    )}
                  </div>
                  <div className="add_border"></div>
                </>
              )}
            {showPreSpinner && <LoaderSpinner />}
            {!showPreSpinner && (
              <>
                <Pannels
                  isFloated={false}
                  showHiddenPanel={showHiddenPanel}
                  updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
                  customCss={dynamicStyle}
                  primary={
                    <>
                      {showComponent !== 'firstVist' &&
                        showComponent !== 'WorkSpace' && (
                          <>
                            {SessionPermessions &&
                              (SessionPermessions === 'allow' ||
                                SessionPermessions?.customer_row === true) && (
                                <DiscoverBuilders
                                  buildersHandle={buildersHandle}
                                  apiHit={refresh}
                                  checkUserHitApi={checkUserHitApi}
                                />
                              )}
                          </>
                        )}
                      {showComponent === 'firstVist' && (
                        <OpportunityScreen
                          onClose={() => {
                            setShowComponent('');
                          }}
                          type={'session_event'}
                          setButton={setButton}
                          setPlusButton={setPlusButton}
                        />
                      )}
                      {showComponent !== 'firstVist' && (
                        <>
                          {showDetails && showDetails.id ? (
                            <>
                              <span
                                className="close as-link"
                                onClick={() => setShowDetails({})}>
                                <i className="fas fa-times" />
                              </span>
                              {<ShowEventsDetails showDetails={showDetails} />}
                            </>
                          ) : (
                            <div className="primary_panel_wraper primary_panel_content">
                              <div className="serach-filter-wrapper ">
                                {/* Search Wrapper */}
                                <div className="search-tabs-wrapper">
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="event-tabs tabs-style-2 mb-0">
                                      <ul>
                                        <li>
                                          <Button
                                            className={
                                              currentComponent === 'sessions'
                                                ? 'active'
                                                : ''
                                            }
                                            onClick={() => {
                                              // setGalleryType("happening");
                                              // resetFilterSession();
                                              if (sessionType === 'upcoming') {
                                                setGalleryType('happening');
                                                amplitudeEvent(
                                                  'VISIT_SESSION_HAPPENING',
                                                );
                                              }
                                              setTypeIs(true);
                                              setSearchQry('');
                                              setMapButton(false);
                                              setCurrentComponent('sessions');
                                              if (
                                                currentComponent === 'events'
                                              ) {
                                                setShowSpinner(true);
                                                setPageNo(1);
                                                amplitudeEvent(
                                                  'VISIT_SESSION_HAPPENING',
                                                );
                                              }
                                            }}>
                                            {/* {
                                              labels.sessions_tab_text[
                                                selected_lang
                                              ]
                                            } */}
                                            Virtual
                                          </Button>
                                        </li>
                                        <li>
                                          <Button
                                            className={
                                              currentComponent === 'events'
                                                ? 'active'
                                                : ''
                                            }
                                            onClick={() => {
                                              // setGalleryType("upcoming");
                                              // resetFilterSession();
                                              if (sessionType === 'upcoming') {
                                                setGalleryType('happening');
                                                amplitudeEvent(
                                                  'VISIT_EVENT_HAPPENING',
                                                );
                                              }
                                              setTypeIs(false);
                                              setSearchQry('');
                                              setCurrentComponent('events');
                                              if (
                                                currentComponent === 'sessions'
                                              ) {
                                                setShowSpinner(true);
                                                setPageNo(1);
                                                amplitudeEvent(
                                                  'VISIT_EVENT_HAPPENING',
                                                );
                                              }
                                            }}>
                                            {/* {
                                              labels.events_tab_text[
                                                selected_lang
                                              ]
                                            } */}
                                            In-person
                                          </Button>
                                        </li>
                                      </ul>
                                      {/* <ul>
                                      <li>
                                        <Button
                                          className={
                                            sessionType === "happening"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            setGalleryType("happening");
                                            setSearchQry("");
                                          }}
                                        >
                                          {
                                            labels.sessions_type_happening[
                                              selected_lang
                                            ]
                                          }
                                        </Button>
                                      </li>
                                      <li>
                                        <Button
                                          className={
                                            sessionType === "upcoming" ? "active" : ""
                                          }
                                          onClick={() => {
                                            setGalleryType("upcoming");
                                            setSearchQry("");
                                          }}
                                        >
                                          {
                                            labels.sessions_type_upcoming[
                                              selected_lang
                                            ]
                                          }
                                        </Button>
                                      </li>
                                    </ul> */}
                                    </div>

                                    <div className="search-tabs-icons">
                                      {SessionPermessions &&
                                        (SessionPermessions === 'allow' ||
                                          SessionPermessions?.create ===
                                            true) && (
                                          <span
                                            onClick={() => {
                                              setButton('sessions');
                                              setPageNo(1);
                                              buttonPlus();
                                              setMapButton(false);
                                              toggleHiddenPanel(
                                                !showHiddenPanel,
                                              );
                                              // getSessionCatData();
                                              // getfolowers();
                                            }}
                                            className={
                                              button === 'sessions' &&
                                              plusButton === false
                                                ? 'icon active'
                                                : 'icon'
                                            }>
                                            <i className="icon-plus"></i>
                                          </span>
                                        )}

                                      {/* <span
                                onClick={() => {
                                  setTypeIs(!typeIs);
                                }}
                                className={typeIs ? "icon active" : "icon"}
                              >
                                <i className="icon-video-camera-2" />
                              </span>
                              <span
                                onClick={() => {
                                  setTypeIs(!typeIs);
                                  setPlusButton(true);
                                  handleResetForm();
                                }}
                                className={!typeIs ? "icon active" : "icon"}
                              >
                                <i className="icon-tickets" />
                              </span> */}

                                      <span
                                        onClick={() => {
                                          setShowFilterModel('open_model');
                                          // resetFilterSession();
                                          // if (sessionType === 'happening') {
                                          //   setGalleryType('upcoming');
                                          //   if (
                                          //     currentComponent === 'sessions'
                                          //   ) {
                                          //     amplitudeEvent(
                                          //       'VISIT_SESSION_UPCOMING',
                                          //     );
                                          //   } else {
                                          //     amplitudeEvent(
                                          //       'VISIT_EVENT_UPCOMING',
                                          //     );
                                          //   }
                                          // } else {
                                          //   setGalleryType('happening');
                                          //   if (
                                          //     currentComponent === 'sessions'
                                          //   ) {
                                          //     amplitudeEvent(
                                          //       'VISIT_SESSION_HAPPENING',
                                          //     );
                                          //   } else {
                                          //     amplitudeEvent(
                                          //       'VISIT_EVENT_HAPPENING',
                                          //     );
                                          //   }
                                          // }
                                          setPageNo(1);

                                          // setPlusButton(true);
                                          // handleResetForm();
                                        }}
                                        className={
                                          iconTrue === 'open_model'
                                            ? 'icon active'
                                            : 'icon'
                                        }>
                                        <i className="fa-solid fa-calendar-days"></i>
                                      </span>

                                      <span
                                        onClick={() => {
                                          setThreeDots(!threeDots);
                                        }}
                                        className={
                                          threeDots ? 'icon active' : 'icon'
                                        }>
                                        <i className="fas fa-ellipsis-vertical" />
                                      </span>
                                    </div>
                                  </div>

                                  {threeDots && (
                                    <div className="search-form d-flex">
                                      <Form.Group className="search-input">
                                        <Form.Control
                                          onChange={e => {
                                            getApiSessionList(
                                              sessionType,
                                              e.target.value,
                                              !typeIs,
                                              1,
                                            );
                                            setSearchQry(e.target.value);
                                          }}
                                          value={searchQry}
                                          type="text"
                                          placeholder={
                                            typeIs === true
                                              ? 'Search for a session'
                                              : 'Search for an event'
                                          }
                                        />
                                      </Form.Group>
                                      {currentComponent === 'events' && (
                                        <Button
                                          className={mapButton ? 'active' : ''}
                                          onClick={() => {
                                            // handClick();
                                            // getLocation(lug,latitute);
                                            setPlusButton(true);
                                            setMapButton(!mapButton);
                                            setShowComponent('');
                                          }}>
                                          <i className="icon-map-pin" />
                                        </Button>
                                      )}
                                      <Button>
                                        <i
                                          className="icon-filter-icon"
                                          onClick={() =>
                                            setSearchFilterModal(true)
                                          }
                                        />
                                      </Button>
                                    </div>
                                  )}
                                </div>

                                <div className={` ${popup ? '' : ''}`}>
                                  {/* <span> {newSessionCount} session Added</span> */}
                                  <SessionPopup
                                    getApiSessionList={getApiSessionList}
                                    setShowSpinner={setShowSpinner}
                                    setPopup={setPopup}
                                    popup={popup}
                                  />
                                </div>

                                <div className="refferal-list-wrapper">
                                  <Scrollbars
                                    className="custom-refferal-vertical-scrollbar"
                                    onScrollFrame={data => {
                                      if (data.top > 0.99 && pageLoad) {
                                        setPageLoad(false);
                                        getApiSessionList(
                                          sessionType,
                                          searchQry,
                                          !typeIs,
                                          pageNo,
                                        );
                                      }
                                    }}
                                    autoHide>
                                    <div className="session-wrapper session-happening-upcoming-listing">
                                      {/* {Object.entries(sessionList).length > 0 &&
                                      Object.entries(sessionList.session).length > 0 ? (
                                        sessionList.session.map((item, index) => (
                                          <>
                                            {typeIs && !item.image && <SesssionItem
                                              key={`session-item-${index}`}
                                              item={item}
                                            />}
                                            {!typeIs && item.image && <EventsItem
                                              key={`event-item-${index}`}
                                              item={item}
                                              index={index}
                                              eventDetails={eventDetails}
                                            />}
                                          </>
                                        ))
                                      ) : !showLoader ? (
                                        <p className="text-center">No record found</p>
                                      ) : (
                                        <></>
                                      )} */}

                                      {showSpinner && <LoaderSpinner />}

                                      {!showSpinner &&
                                      sessionData.hasOwnProperty('session') &&
                                      Object.entries(sessionData.session)
                                        .length > 0
                                        ? Object.entries(sessionData.session)
                                            .length > 0 &&
                                          sessionData.session.map(
                                            (item, index) =>
                                              typeIs && !item.image ? (
                                                <SesssionItem
                                                  key={`session-item-${index}`}
                                                  item={item}
                                                  heart={sessionType}
                                                  componentDecesion={
                                                    componentDecesion
                                                  }
                                                  serverTime={serverTime}
                                                />
                                              ) : (
                                                !typeIs && (
                                                  <EventsItem
                                                    key={`event-item-${index}`}
                                                    item={item}
                                                    index={index}
                                                    eventDetails={eventDetails}
                                                    componentDecesion={
                                                      componentDecesion
                                                    }
                                                  />
                                                )
                                              ),
                                          )
                                        : !showSpinner && (
                                            <>
                                              {typeIs && (
                                                <div className="no-results-wrapper">
                                                  <div className="img-bg-wrapper">
                                                    <img
                                                      src={require('../../Assets/images/norecord/session.png')}
                                                      alt="no-session"
                                                    />
                                                  </div>

                                                  <div className="no-results-content">
                                                    <h2>Manage Meetups</h2>
                                                    <p>
                                                      Meetup with your network
                                                      virtually
                                                    </p>

                                                    {SessionPermessions &&
                                                      (SessionPermessions ===
                                                        'allow' ||
                                                        SessionPermessions?.create ===
                                                          true) && (
                                                        <div className="btn-wrapper mt-3">
                                                          <Button
                                                            className="btn btn-dark"
                                                            onClick={() => {
                                                              setPlusButton(
                                                                false,
                                                              );
                                                            }}>
                                                            Create Meetups
                                                          </Button>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              )}
                                              {!typeIs && (
                                                <div className="no-results-wrapper">
                                                  <div className="img-bg-wrapper">
                                                    <img
                                                      src={require('../../Assets/images/norecord/event.png')}
                                                      alt=""
                                                    />
                                                  </div>

                                                  <div className="no-results-content">
                                                    <h2>
                                                      {
                                                        labels.MANAGE_EVENTS[
                                                          selected_lang
                                                        ]
                                                      }
                                                    </h2>
                                                    <p>
                                                      {
                                                        labels.Connect_Network[
                                                          selected_lang
                                                        ]
                                                      }
                                                    </p>

                                                    {SessionPermessions &&
                                                      (SessionPermessions ===
                                                        'allow' ||
                                                        SessionPermessions?.create ===
                                                          true) && (
                                                        <div className="btn-wrapper mt-3">
                                                          <Button
                                                            className="btn btn-dark"
                                                            onClick={() => {
                                                              setPlusButton(
                                                                false,
                                                              );
                                                            }}>
                                                            Create Meetups
                                                          </Button>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}

                                      {/* {!showSpinner &&
                                      !showLoader &&
                                      sessionType === 'happening' && (
                                        <div className="upcoming-content-text">
                                          <span>
                                            <i className="fa-solid fa-calendar-days"></i>
                                          </span>
                                          <span>
                                            <>{`Upcoming ${'Meetups'}`}</>
                                          </span>
                                        </div>
                                      )} */}

                                      {/* {!showSpinner &&
                                      sessionType === 'happening' &&
                                      sessionData.hasOwnProperty('upcoming') &&
                                      Object.entries(sessionData.upcoming)
                                        .length > 0 &&
                                      sessionData.upcoming.map((item, index) =>
                                        typeIs && !item.image ? (
                                          <SesssionItem
                                            key={`session-upcoming-${index}`}
                                            item={item}
                                            heart={'upcoming'}
                                            componentDecesion={
                                              componentDecesion
                                            }
                                            serverTime={serverTime}
                                          />
                                        ) : (
                                          !typeIs && (
                                            <EventsItem
                                              key={`event-upcoming-${index}`}
                                              item={item}
                                              index={index}
                                              eventDetails={eventDetails}
                                              componentDecesion={
                                                componentDecesion
                                              }
                                            />
                                          )
                                        ),
                                      )} */}

                                      {/* {!showSpinner && !showLoader && (
                                      <div
                                        className={`dont-forget-events as-link ${
                                          currentComponent === 'events'
                                            ? 'events-bg-img'
                                            : 'session-bg-img'
                                        }`}
                                        onClick={() => {
                                          if (currentComponent === 'events') {
                                            setPageNo(1);
                                            setTypeIs(true);
                                            setSearchQry('');
                                            setCurrentComponent('sessions');
                                          } else {
                                            setPageNo(1);
                                            setTypeIs(false);
                                            setSearchQry('');
                                            setCurrentComponent('events');
                                          }
                                        }}>
                                        <div>
                                          Don't forget you can toggle above for{' '}
                                          {currentComponent === 'sessions'
                                            ? 'Meetups'
                                            : 'Meetups'}
                                        </div>
                                        <div className="icons-wrapper">
                                          <span className="d-block">
                                            {currentComponent === 'Meetups'
                                              ? 'Explore Meetups'
                                              : 'Explore Meetups'}
                                          </span>
                                          {currentComponent === 'events' ? (
                                            <span className="icon active">
                                              <i className="icon-video-camera-2" />
                                            </span>
                                          ) : (
                                            <span className="icon active">
                                              <i className="icon-tickets" />
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    )} */}
                                    </div>
                                  </Scrollbars>
                                </div>
                              </div>

                              {/* if(topIcons.location === false){setTopIcons({ ...topIcons, ['focus']: false });} */}
                              {/* <button onClick={() => postSessionDetails()}>Send Data</button> */}
                            </div>
                          )}
                        </>
                      )}

                      {showFilterModel === 'open_model' && (
                        <CustomModal
                          size="medium"
                          iconStyle={mdelStylr}
                          onClose={() => {
                            setShowFilterModel('');
                          }}>
                          <div style={{overflowY: (fromCalendar || toCalendar) ? 'unset' : 'auto'}} className="filter-wrapper">
                            <div className="headings m-0">
                              <h2 className="m-0 text-center">Select Date</h2>
                            </div>
                            <div className="filter-category-list">
                              <ul style={{listStyleType: 'none', padding: 0}}>
                               
                                <li style={{marginBottom: '5px'}}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div style={{paddingRight: '15px'}}>
                                      <Form.Label>From</Form.Label>
                                    </div>
                                    
                                    <div className="parent-container w-100">
                                      <Calender
                                        minDate={moment(serverTime).format(
                                          'YYYY-MM-DD',
                                        )}
                                        setSelectedDate={selectedDate => {
                                          if (selectedDate) {
                                            setFilterFrom(selectedDate);
                                          } else {
                                            setFilterFrom('');
                                          }
                                        }}
                                        selectedDate={
                                          filterFrom &&
                                          moment(
                                            filterFrom,
                                            'YYYY-MM-DD',
                                            true,
                                          ).isValid()
                                            ? new Date(filterFrom)
                                            : ''
                                        }
                                        showDate={true}
                                        showTime={false}
                                        typeOfModelShow='something'
                                        setFromCalendar={setFromCalendar}
                                        fromCalendar={fromCalendar}
                                        setToCalendar={setToCalendar}
                                        showCalendar={'fromCalendar'}                                      />
                                    </div>
                                  </div>
                                </li>
                                <li style={{marginBottom: '5px'}}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div style={{paddingRight: '31px'}}>
                                      <Form.Label>To</Form.Label>
                                    </div>
                                    <div className="parent-container w-100">
                                      <Calender
                                        minDate={moment(serverTime).format(
                                          'YYYY-MM-DD',
                                        )}
                                        showDate={true}
                                        showTime={false}
                                        setSelectedDate={selectedDate => {
                                          if (selectedDate) {
                                            setFilterTo(selectedDate);
                                          } else {
                                            setFilterTo('');
                                          }
                                        }}
                                        selectedDate={
                                          filterTo &&
                                          moment(
                                            filterTo,
                                            'YYYY-MM-DD',
                                            true,
                                          ).isValid()
                                            ? new Date(filterTo)
                                            : ''
                                        }
                                        typeOfModelShow='something'
                                        setToCalendar={setToCalendar}
                                        toCalendar={toCalendar}
                                        setFromCalendar={setFromCalendar}
                                        showCalendar={'toCalendar'}                                      />
                                    </div>
                                  </div>
                                </li>
                              </ul>

                              <div className="filter-btn-wrapper text-center">
                                <Button
                                  className="btn-dark"
                                  onClick={() => {
                                    const validRange = moment(filterFrom).isSameOrBefore(filterTo);


                                    if (
                                      (filterTo !== '' &&
                                        filterFrom !== '' &&
                                        validRange) ||
                                      (filterTo === '' && filterFrom === '')
                                    ) {
                                      // Both dates are valid and in range OR both are empty -> Call the API
                                      getApiSessionList(
                                        '',
                                        '',
                                        currentComponent === 'sessions'
                                          ? false
                                          : true,
                                        1,
                                      );
                                      setIconTrue('open_model');
                                      setShowFilterModel('');
                                      setShowSpinner(true);
                                    } else if (
                                      filterTo !== '' &&
                                      filterFrom !== '' &&
                                      validRange === false
                                    ) {
                                      // Both dates are present but not valid -> Show error message
                                      errorMessage(
                                        'Please select a valid range',
                                      );
                                    } else if (
                                      filterTo === '' ||
                                      filterFrom === ''
                                    ) {
                                      // One of the dates is missing -> Show error message
                                      errorMessage('Please select both dates');
                                    }
                                  }}>
                                  Filter date
                                </Button>
                              </div>
                            </div>
                          </div>
                        </CustomModal>
                      )}

                      {searchFilter && (
                        <SessionSearchFilter
                          show={searchFilter}
                          onClose={() => setSearchFilterModal(false)}
                          sessionType={sessionType}
                          typeIs={typeIs}
                          fun={fun}
                          isCheckAll={isCheckAll}
                          setIsCheckAll={setIsCheckAll}
                          isCheck={isCheck}
                          setIsCheck={setIsCheck}
                          list={list}
                          setList={setList}
                          active={active}
                          setActive={setActive}
                          pageNo={pageNo}
                          setFocusLocations={setFocusLocations}
                          setIdentiferId={setIdentiferId}
                          setEthnicityId={setEthnicityId}
                          setGender={setGender}
                          gender={gender}
                          focusLocations={focusLocations}
                          ethnicityId={ethnicityId}
                          identiferId={identiferId}
                          setPersnalTypesId={setPersnalTypesId}
                          setCategoriesId={setCategoriesId}
                          setAppointmentId={setAppointmentId}
                          persnalTypesId={persnalTypesId}
                          appointmentId={appointmentId}
                          categoriesId={categoriesId}
                        />
                      )}
                    </>
                  }
                  secondary={
                    <>
                      {showComponent === 'praiseCaution' &&
                        idForPraiseCaution && (
                          <PraiseCoution
                            setShowComponent={setShowComponent}
                            userData={idForPraiseCaution}
                            type={'session'}
                          />
                        )}

                      {showComponent === 'inviteOutSideTheBuild' && (
                        <SendInvitation
                          onClose={() => {
                            setShowComponent('');
                          }}
                          setCheckUserHitApi={setCheckUserHitApi}
                          checkUserHitApi={checkUserHitApi}
                        />
                      )}
                      {showComponent === 'event' &&
                        (userData.creator_id === localStorage.getItem('id') ||
                          userData.moderator_ids.includes(
                            localStorage.getItem('id'),
                          )) && (
                          <EventDetail
                            onClose={() => {
                              setShowComponent('');
                            }}
                            getApiSessionList={getApiSessionList}
                            // getApiSessionList(sessionType, "", !typeIs, pageNo);
                            sessionType={sessionType}
                            typeIs={typeIs}
                            item={userData}
                          />
                        )}

                      {showComponent === 'event' &&
                        userData.creator_id !== localStorage.getItem('id') &&
                        !userData.moderator_ids.includes(
                          localStorage.getItem('id'),
                        ) && (
                          <PurchaseTicket
                            onClose={() => {
                              setShowComponent('');
                            }}
                            item={userData}
                            // type={"refer"}
                            getApiSessionList={getApiSessionList}
                            sessionType={sessionType}
                            typeIs={typeIs}
                            // item={userData}
                          />
                        )}

                      {showComponent === 'Refer' && userData && (
                        // <SlidingPanel
                        //   hidePanel={() => {
                        //     setShowComponent('');
                        //   }}>
                        // <Scrollbars>
                        <ReferOpportunity
                          onClose={() => {
                            setShowComponent('');
                          }}
                          type={
                            currentComponent === 'events'
                              ? 'in-person'
                              : 'virtual'
                          }
                          userData={userData}
                        />
                        //   </Scrollbars>
                        // </SlidingPanel>
                      )}

                      {showComponent === 'session' &&
                        userData.creator_id !== localStorage.getItem('id') && (
                          // <SlidingPanel
                          //   hidePanel={() => {
                          //     setShowComponent('');
                          //   }}>
                          //   <Scrollbars>

                          //   <PurchaseTicket
                          //   onClose={() => {
                          //     setShowComponent('');
                          //   }}
                          //   item={userData}
                          //   // type={"refer"}
                          //   getApiSessionList={getApiSessionList}
                          //   sessionType={sessionType}
                          //   typeIs={typeIs}
                          //   // item={userData}
                          // />
                          <SessionDetail
                            item={userData}
                            onClose={() => setShowComponent('')}
                          />
                          //   </Scrollbars>
                          // </SlidingPanel>
                        )}

                      {showComponent === 'session' &&
                        userData.creator_id === localStorage.getItem('id') && (
                          // <SlidingPanel
                          //   hidePanel={() => {
                          //     setShowComponent('');
                          //   }}>
                          //   <div className="content-sidebar">
                          <CreateSession
                            onClose={() => {
                              setShowComponent('');
                              toggleHiddenPanel(!showHiddenPanel);
                            }}
                            editSession={userData}
                            getApiSessionList={getApiSessionList}
                            // getApiSessionList(sessionType, "", !typeIs, pageNo);
                            sessionType={sessionType}
                            typeIs={typeIs}
                            item={userData}
                          />
                          //   </div>
                          // </SlidingPanel>
                        )}

                      {plusButton === false && (
                        // currentComponent === 'sessions' &&
                        <CreateSession
                          setBullseyeButton={setBullseyeButton}
                          bullseyeButton={bullseyeButton}
                          setPlusButton={setPlusButton}
                          setSessionLink={setSessionLink}
                          sessionLink={sessionLink}
                          getApiSessionList={getApiSessionList}
                          // getApiSessionList(sessionType, "", !typeIs, pageNo);
                          sessionType={sessionType}
                          typeIs={typeIs}
                          showModal={showModal}
                          openModal={openModal}
                          enterSession={enterSession}
                          setcreatedSessionId={setcreatedSessionId}
                          setChooseCircle={setChooseCircle}
                          chooseCircle={chooseCircle}
                          onClose={() => {
                            setPlusButton(true);
                            toggleHiddenPanel(!showHiddenPanel);
                          }}
                        />
                      )}

                      {/* {plusButton === false &&
                        currentComponent === 'events' && (
                          <CreateSession
                            setSelectedMod={setSelectedMod}
                            setBullseyeButton={setBullseyeButton}
                            setSelectedModData={setSelectedModData}
                            setPlusButton={setPlusButton}
                            setSessionLink={setSessionLink}
                            getApiSessionList={getApiSessionList}
                            // getApiSessionList(sessionType, "", !typeIs, pageNo);
                            sessionType={sessionType}
                            typeIs={typeIs}
                            openModal={openModal}
                            enterSession={enterSession}
                            setcreatedSessionId={setcreatedSessionId}
                            setChooseCircle={setChooseCircle}
                            showEvent={true}
                            onClose={() => {
                              setPlusButton(true);
                            }}
                          />
                        )} */}

                      {showComponent === 'AllBuilders' && (
                        <AllBuilders
                          onClose={() => {
                            setShowComponent('');
                            toggleHiddenPanel(!showHiddenPanel);
                          }}
                        />
                      )}

                      {buildersUserId &&
                        showComponent === 'profileComponent' && (
                          <div className="pb-4 fix-width-forms">
                            <ProfileComponent
                              userId={buildersUserId}
                              show={false}
                              onClose={() => {
                                setShowComponent('');
                                setInviteSnapshot('');
                              }}
                              snaphotId={inviteSnaphot}
                              type={inviteSnaphot !== '' ? 'snapshotRefer' : ''}
                              // hideCloseButton={true}
                              button={button}
                              userData={[]}
                              moderator={false}
                              // setParticipantType={setParticipantType}
                              VoxeetSDK={[]}
                              conferenceInfo={[]}
                              jdSpecialM={[]}
                              jdSpecialA={[]}
                            />
                          </div>
                        )}

                      {/* {mapButton === true && (
                        <Map
                          onClose={() => {
                            setMapButton(!mapButton);
                            toggleHiddenPanel(!showHiddenPanel);
                          }}
                          mapButton={mapButton}
                          sessionType={sessionType}
                          typeIs={typeIs}
                          fun={fun}
                          getApiSessionList={getApiSessionList}
                          // sessionType={sessionType}
                          // typeIs={typeIs}
                        />
                      )} */}

                      {mapButton === true && (
                        <Map
                          onClose={() => {
                            setMapButton(false);
                            toggleHiddenPanel(false);
                          }}
                          mapButton={mapButton}
                          sessionType={sessionType}
                          typeIs={typeIs}
                          fun={fun}
                          getApiSessionList={getApiSessionList}
                        />
                      )}

                      {openSurveyModals === 'survey-modal' && (
                        <CustomModal
                          size="medium"
                          onClose={() => {
                            setOpenSurveyModals('');
                            setTimeout(() => {
                              handleNextSurveyModal(singleSurveyData.id);
                            }, 500);
                          }}>
                          {/* <Scrollbars className=""> */}
                          <div className="new-session-modal-wrapper text-center ">
                            <div className="new-session-modal">
                              <h3>Quick Question</h3>
                              <p>
                                To relevant platform and add your handles. Only
                                One is required to help us learn more about the
                                community.{' '}
                              </p>
                              {singleSurveyData && (
                                <h2>{singleSurveyData.question_title} </h2>
                              )}

                              <ul>
                                {Object.entries(singleSurveyData.options)
                                  .length > 0 &&
                                  singleSurveyData.options.map(
                                    (item, index) => (
                                      <div
                                        className={`survey-data survey-option as-link ${
                                          selectedSurveyItem.includes(item.id)
                                            ? 'selected-survey-option'
                                            : ''
                                        }`}
                                        onClick={() => {
                                          selectSurveyOption(item);
                                        }}>
                                        <li
                                          className="as-link "
                                          key={`sessionC-${index}`}>
                                          <Form.Label>
                                            {item.option_title}
                                            <span />
                                          </Form.Label>
                                        </li>
                                      </div>
                                    ),
                                  )}
                              </ul>

                              <div className="new-session-btn-wrapper">
                                <Button
                                  className="btn-dark"
                                  onClick={() => {
                                    if (selectedSurveyItem.length === 0) {
                                      errorMessage('Select Option');
                                    } else {
                                      submitSurveyData(singleSurveyData.id);
                                    }
                                  }}>
                                  Submit
                                </Button>
                              </div>

                              <span
                                //  variant="primary"
                                className="d-block text-yellow as-link mt-3-bold"
                                onClick={() => {
                                  setOpenSurveyModals('');
                                  setTimeout(() => {
                                    handleNextSurveyModal(singleSurveyData.id);
                                  }, 500);
                                }}>
                                No Thanks!
                              </span>
                            </div>
                          </div>
                          {/* </Scrollbars> */}
                        </CustomModal>
                      )}

                      {/* {showModal && (
                        <CustomModal
                          size="medium"
                          onClose={() => closeModal(false)}>
                          <div className="new-session-modal-wrapper text-center">
                            <div className="new-session-modal">
                              <h3>You have successfully created {title}</h3>
                              <p>
                                If you don't join when the push notification is
                                sent, you can simply go to the heart tab to
                                quickly tap in. Psst... this is also where you
                                can copy the URL to share on other platforms or
                                tap to stop the reminder from being sent.{' '}
                              </p>

                              <h3>Generate Guest Pass</h3>
                              <span>{sessionLink}</span>

                              <div className="new-session-btn-wrapper">
                                <Button
                                  className="btn-dark"
                                  onClick={() => enterSession()}>
                                  Enter Session
                                </Button>

                                <span
                                  className=" copy-link-text"
                                  onClick={() => {
                                    navigator.clipboard.writeText(sessionLink);
                                    successMessage(
                                      labels.copy_link[selected_lang],
                                    );
                                  }}>
                                  <i className="icon-link" />
                                  Copy Link
                                </span>
                              </div>
                            </div>
                          </div>
                        </CustomModal>
                      )} */}
                    </>
                  }
                />
                {showComponent === 'WorkSpace' && (
                  <CreateWorkSpace
                    onClose={() => {
                      setShowComponent('');
                    }}
                  />
                )}

                {onBoardingModal && (
                  <OnBoardingModal
                    show={onBoardingModal}
                    onClose={() => setonBoardingModal(false)}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

// const SessionEvents = props => {
//   const [selectedAud, setSelectedAud] = useState(false);
//   const [topic, setTopic] = useState([]);
//   const [topicId, setTopicId] = useState('');

//   const [feedBack, setFeedBack] = useState('');

//   const [isFloated, toggleFloatedPanel] = useState(false);
//   const [showHiddenPanel, toggleHiddenPanel] = useState(false);
//   const [validated, setValidated] = useState(false);
//   const [showComponent, setShowComponent] = useState(false);
//   const [workspaceData, setWorkspaceData] = useState([]);

//   return (
//     <>
//       <div className="page-wrapper">
//         <Sidebar
//           location={props.location}
//           setShowComponent={setShowComponent}
//           setWorkSpaceItem={setWorkspaceData}
//           showComponent={showComponent}
//         />
//         <div className="page-content-wrapper">
//           <Pannels
//             isFloated={isFloated}
//             showHiddenPanel={showHiddenPanel}
//             updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
//             primary={
//               <div className="flex items-center justify-center h-screen bg-white">
//                 <div className="text-center">
//                   <svg
//                     className="w-16 h-16 mx-auto mb-4 text-green-500"
//                     fill="none"
//                     stroke="currentColor"
//                     viewBox="0 0 24 24"
//                     xmlns="http://www.w3.org/2000/svg">
//                     <path
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth="2"
//                       d="M5 13l4 4L19 7"></path>
//                   </svg>
//                   <h1 className="text-2xl font-semibold text-gray-800">
//                     No Records Found
//                   </h1>
//                   <p className="text-gray-600">
//                     Sorry, we couldn't find any records against this page.
//                   </p>
//                 </div>
//               </div>
//             }
//             secondary={<></>}
//           />
//         </div>
//       </div>
//     </>
//   );
// }
const mapStateProps = state => ({
  secondaryPannelState: state.secondaryPannelState,
});

export default connect(mapStateProps, {toggleSecondaryPannel})(SessionEvents);
