"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTCCandidatePairStats = exports.RTCStreamDescription = exports.RTCMediaStreamVideoTrack = exports.RTCMediaStreamAudioTrack = exports.RTCRemoteInboundRTPStreamStats = exports.RTCOutboundRtpVideoStreamStats = exports.RTCOutboundRtpAudioStreamStats = exports.RTCInboundRTPVideoStreamStats = exports.RTCInboundRTPAudioStreamStats = exports.RTCStats = void 0;
/**
 * @ignore
 */
class RTCStats {
    constructor() {
        this.type = '';
    }
}
exports.RTCStats = RTCStats;
/**
 * @ignore
 */
class RTCInboundRTPAudioStreamStats extends RTCStats {
    constructor() {
        super(...arguments);
        this.ssrc = 0;
        this.trackId = '';
        this.mediaType = 'audio';
        this.packetsReceived = 0;
        this.bytesReceived = 0;
        this.bitrateReceived = 0;
        this.packetsLost = 0;
        this.jitter = 0;
    }
}
exports.RTCInboundRTPAudioStreamStats = RTCInboundRTPAudioStreamStats;
/**
 * @ignore
 */
class RTCInboundRTPVideoStreamStats extends RTCStats {
    constructor() {
        super(...arguments);
        this.ssrc = 0;
        this.trackId = '';
        this.mediaType = 'video';
        this.packetsReceived = 0;
        this.bytesReceived = 0;
        this.bitrateReceived = 0;
        this.packetsLost = 0;
        this.jitter = 0;
    }
}
exports.RTCInboundRTPVideoStreamStats = RTCInboundRTPVideoStreamStats;
/**
 * @ignore
 */
class RTCOutboundRtpAudioStreamStats extends RTCStats {
    constructor() {
        super(...arguments);
        this.ssrc = 0;
        this.trackId = '';
        this.mediaType = 'audio';
        this.packetsSent = 0;
        this.bytesSent = 0;
        this.bitrateSent = 0;
        this.retransmittedPacketsSent = 0;
        this.retransmittedBytesSent = 0;
    }
}
exports.RTCOutboundRtpAudioStreamStats = RTCOutboundRtpAudioStreamStats;
/**
 * @ignore
 */
class RTCOutboundRtpVideoStreamStats extends RTCStats {
    constructor() {
        super(...arguments);
        this.ssrc = 0;
        this.trackId = '';
        this.mediaType = 'video';
        this.packetsSent = 0;
        this.bytesSent = 0;
        this.bitrateSent = 0;
        this.retransmittedPacketsSent = 0;
        this.retransmittedBytesSent = 0;
        this.contentType = '';
    }
}
exports.RTCOutboundRtpVideoStreamStats = RTCOutboundRtpVideoStreamStats;
/**
 * @ignore
 */
class RTCRemoteInboundRTPStreamStats extends RTCStats {
    constructor() {
        super(...arguments);
        this.ssrc = 0;
        this.kind = ''; // "audio" / "video"
        this.packetLost = 0;
        this.jitter = 0;
        this.roundTripTime = 0;
    }
}
exports.RTCRemoteInboundRTPStreamStats = RTCRemoteInboundRTPStreamStats;
/**
 * @ignore
 */
class RTCMediaStreamAudioTrack extends RTCStats {
    constructor() {
        super(...arguments);
        this.id = '';
        this.kind = 'audio';
        this.jitterBufferDelay = 0;
        this.jitterBufferEmittedCount = 0;
        this.audioLevel = 0;
        this.totalAudioEnergy = 0;
    }
}
exports.RTCMediaStreamAudioTrack = RTCMediaStreamAudioTrack;
class RTCMediaStreamVideoTrack extends RTCStats {
    constructor() {
        super(...arguments);
        this.id = '';
        this.kind = 'video';
        this.jitterBufferDelay = 0;
        this.jitterBufferEmittedCount = 0;
        this.frameWidth = 0;
        this.frameHeight = 0;
        this.framesReceived = 0;
        this.framesDecoded = 0;
    }
}
exports.RTCMediaStreamVideoTrack = RTCMediaStreamVideoTrack;
/**
 * @ignore
 */
class RTCStreamDescription extends RTCStats {
    constructor() {
        super(...arguments);
        this.streamIdentifier = '';
        this.trackIds = [];
    }
}
exports.RTCStreamDescription = RTCStreamDescription;
/**
 * @ignore
 */
class RTCCandidatePairStats extends RTCStats {
    constructor() {
        super(...arguments);
        this.id = '';
        this.availableOutgoingBitrate = 0;
        this.availableIncomingBitrate = 0;
        this.bytesSent = 0;
        this.bytesReceived = 0;
        this.bitrateSent = 0;
        this.bitrateReceived = 0;
        this.totalRoundTripTime = 0;
        this.currentRoundTripTime = 0;
        this.requestsReceived = 0;
        this.requestsSent = 0;
        this.responsesReceived = 0;
        this.responsesSent = 0;
        this.consentRequestsSent = 0;
        //nominated?: boolean = false;
        this.selected = false;
    }
}
exports.RTCCandidatePairStats = RTCCandidatePairStats;
