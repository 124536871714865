import commonApi from "../../Services/CommonService";

const getDispatch = (type, data) => ({
  type: type,
  payload: data,
});



export const getPipelineData = (data) =>{
    return async dispatch => {
      const response = await commonApi.get_pipeline_details(data);
      dispatch(getDispatch('PIPELINE/LIST', response.data));
      return response;
    };
  }


  export const getDealsData = (data) =>{
    return async dispatch => {
      const response = await commonApi.get_deals_list(data);
      dispatch(getDispatch('DEALS/LIST', response.data));
      return response;
    };
  }

  export const getUpdateDataOfDeals = (value) =>{
    return async dispatch => {
      
      dispatch(getDispatch('DEALS/UPDATE', value));
     
    };
  }


  export const getUpdateDataOfPipeline = (value) =>{
    return async dispatch => {
      
      dispatch(getDispatch('PIPELINE/UPDATE', value));
     
    };
  }