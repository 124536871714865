import React from 'react'

import {
    ArrowIcon,
    FireIcon,
    MachineIcon,
    WhitePenIcon,
    timeIcon,
    bagIcon,
    heartIcon,
    Bandage
  } from '../../Constants/Images';
  import moment from 'moment';

  import { Button } from 'react-bootstrap';

  import { DEFAULT_IMAGE } from '../../Utils/env';

const InvoiceItem = ({item,handleCommand}) => {
  return (
    <div className="payment-card-main">
    <div
      className="as-link"
      onClick={() => {
        handleCommand && handleCommand(
          item,
          'invoiceDetail',
        );
      }}>
      <div className="payment-content-card">
        <div className="task-profile-img">
          {/* <img
              src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
              alt=""
            /> */}
          <img
            src={
              item.profile_img
                ? item.profile_img
                : DEFAULT_IMAGE
            }
            alt=""
          />
        </div>

        <div className="task-content">
          <h1
            style={{
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
            }}>
            {item.first_name + ' ' + item.last_name}
            {/* {fixName(item.first_name, item.last_name)} */}
            {/* Samantha Ellington */}
          </h1>

          <span
            style={{
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            }}>
            {item.invoice_title
              ? item.invoice_title
              : ''}
          </span>
          <span
            className="project-title"
            style={{
              color: '#707070',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
            }}>
            Invoice
          </span>
        </div>
      </div>

      <div className="payment-service-icons">
        <div className="roi-service-icons">
          <div className="service-icon">
            <img src={WhitePenIcon} alt="" />
          </div>

          <div className="service-icon-time">
            <img className="" src={timeIcon} alt="" />
            <span>5D</span>
          </div>

          <div className="service-icon w-25">
            <img src={FireIcon} alt="" />
          </div>

          <div className="service-icon w-29">
            <img src={MachineIcon} alt="" />
          </div>

          <div className="service-icon w-29">
            <img src={ArrowIcon} alt="" />
          </div>

          <div className="service-icon w-29">
            <img src={Bandage} alt="" />
          </div>

          <div className="service-icon w-28">
            <img src={heartIcon} alt="" />
          </div>

          <div className="service-icon w-29">
            <img src={bagIcon} alt="" />
          </div>
          
        </div>
      </div>
    </div>

    <div className="payment-card-bottom">
      <div
        className="payment-card-icons"
        style={{
          color: '#262626',

          fontFamily: 'Inter',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
          paddingTop: '11px',
        }}>
        {moment(item?.due_date)
          .format('MM/DD/YY')
          .toString()}
      </div>
      <div className="pay-btn">
        <span
          style={{
            color: '#262626',
            fontFamily: 'Inter',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
          }}>
          {' '}
          ${item.total}
        </span>

    
            <Button
              className="new-btn btn-green ml-3 "
              onClick={() => {
                handleCommand(
                  {
                    price: item.total,
                    paymentId: item.payment_id,
                  },
                  'stripeModal',
                );
                
              }}
              
              >
              Pay
            </Button>
        
      </div>
    </div>
  </div>
  )
}

export default InvoiceItem