"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subscribe = exports.Register = exports.ConferenceNotNull = exports.BaseConferenceService = void 0;
const events_1 = require("events");
const index_1 = __importStar(require("../events/index"));
const Exceptions_1 = require("../lib/Exceptions");
/**
 * @ignore
 */
class Service extends events_1.EventEmitter {
    constructor(sdk) {
        super();
        this._sdk = sdk;
    }
}
exports.default = Service;
/**
 * @ignore
 */
class BaseConferenceService extends Service {
    constructor(sdk) {
        super(sdk);
        index_1.default.register(this, [index_1.EventType.ConferenceJoined, index_1.EventType.ConferenceLeft]);
    }
    /* istanbul ignore next */
    onConferenceJoined(e) {
        this._conferenceId = e.conferenceId;
    }
    /* istanbul ignore next */
    onConferenceLeft(e) {
        this._conferenceId = null;
    }
    checkConference() {
        if (!this._conferenceId) {
            throw Exceptions_1.ConferenceUninitializedError();
        }
    }
}
exports.BaseConferenceService = BaseConferenceService;
/**
 * @ignore
 */
function ConferenceNotNull() {
    return function (target, propertyKey, descriptor) {
        let method = descriptor.value;
        descriptor.value = function (...args) {
            target.checkConference.call(this);
            return method.apply(this, args);
        };
        return descriptor;
    };
}
exports.ConferenceNotNull = ConferenceNotNull;
/**
 * @ignore
 * @param type
 */
function Register(type) {
    return function (target, propertyKey, descriptor) {
        index_1.default.register(target, [type]);
        return descriptor;
    };
}
exports.Register = Register;
/**
 * @ignore
 * @param events
 */
function Subscribe(events) {
    return function (constructorFunction) {
        //new constructor function
        let newConstructorFunction = function (...args) {
            let func = function () {
                return new constructorFunction(...args);
            };
            func.prototype = constructorFunction.prototype;
            let result = new func();
            index_1.default.register(result, events);
            return result;
        };
        newConstructorFunction.prototype = constructorFunction.prototype;
        return newConstructorFunction;
    };
}
exports.Subscribe = Subscribe;
