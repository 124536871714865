import React, {useState, useEffect} from 'react';
import {Play, rewardStar} from '../../Constants/Images';
import {Button, Form, Dropdown} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';

const TemplateExchange = props => {
  const [type, setType] = useState('all');
  const [threeDots, setThreeDots] = useState('');
  const [templateSearchQry, setTemplateSearchQry] = useState('');
  const [showTrmplates, setShowTemplates] = useState([]);
  const [showLoader, setshowLoader] = useState(true);
  const [favourite, setFavourite] = useState(false);

  const getAllGlobalTemplates = (qry, type) => {
    // setshowLoader(true)
    var Payload = {
      company_type_id: type === 'all' ? '' : type,
      search: qry,
      favourite: favourite,
    };

    commonApi
      .get_all_global_templates(Payload)
      .then(res => {
        if (res.status === 200) {
          if (res.all_templates) {
            const apiRes = res.all_templates;
            setShowTemplates(apiRes);
            setTimeout(() => {
              setshowLoader(false);
            }, 1500);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const get_workspace_types = () => {
  //   commonApi
  //     .get_workspace_types()
  //     .then(res => {
  //       if (res.status === 200) {
  //         console.log(res, 'response workspace');
  //         // setIndustryList(res.workspace_types);
  //         const newArray = [
  //           ...res.workspace_types,
  //           {
  //             id: 'all',
  //             name: 'All',
  //             description: '',
  //             created_at: '',
  //             updated_at: '',
  //           },
  //         ];
  //         setIndustryList(newArray);
  //         setTimeout(() => {
  //           setshowLoader(false);
  //         }, 1000);

  //         // setTimeout(() => {
  //         //   if (companyData.industry == '') {
  //         //     setCompanyData({
  //         //       ...companyData,
  //         //       industry: res.workspace_types[0].id,
  //         //     });
  //         //   }
  //         // }, 5000);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    getAllGlobalTemplates(templateSearchQry, type);
    // get_workspace_types();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSearchQry, type, favourite]);

  useEffect(() => {
    setshowLoader(true);
  }, [type]);

  // console.log(industryList, 'industryList');

  const handleimport = item => {
    // setshowLoader(true)
    var Payload = {
      id: item.id,
      company_name: item.company_name,
      company_email: item.company_email,
      company_type_id: item.company_type_id,
      no_of_members: item.no_of_members,
      cc_user_ids: item.cc_user_ids,
      poc_name: item.poc_name,
      poc_phone: item.poc_phone,
      email: item.email,
      poc_role: item.poc_role,
      s_poc_name: item.s_poc_name,
      s_email: item.s_email,
      s_poc_phone: item.s_poc_phone,
      s_poc_role: item.s_poc_role,
      customer_title: item.customer_title,
      customer_types: item.customer_types,
      schedule_types: item.schedule_types,
      deliverable_title: item.deliverable_title,
      deliverable_type: item.deliverable_type,
      rewards: item.rewards,
      status: item.status,
      image: item.image,
      is_tempelate: item.is_tempelate,
      is_company: item.is_company,
      imported: item.imported,
      is_private: item.is_private,
      shared: item.shared,
      created_by: item.created_by,
      created_at: item.created_at,
      updated_at: item.updated_at,
      roles: item.roles,
      menus: item.menus,
      catalog: item.catalog,
      emails: item.emails,
      task: item.task,
      projects: item.projects,
      sms: item.sms,
      messages: item.messages,
      goals: item.goals,
      analytics: item.analytics,
      leaderboard: item.leaderboard,
    };

    commonApi
      .import_template(Payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            successMessage(res.message);
            props.setHitApi(!props.hitApi);
          } else {
            errorMessage(res.message);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
    console.log('kkkkkk', Payload);
  };

  const handleFavouriteTemplates = id => {
    var Payload = {
      template_id: id,
    };

    commonApi
      .update_templat_favourite(Payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          getAllGlobalTemplates(templateSearchQry, type);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <>
      <div className="messages-header">
        <div className="event-tabs tabs-style-2 mb-0">
          {' '}
          <ul className="LabelTabs">
            <li className="ml-20 d-flex align-items-center">
              <Button
                className={props.tabType === 'myTemplate' ? 'active' : ''}
                onClick={() => {
                  props.setTabType('myTemplate');
                }}>
                {labels.my_template[selected_lang]}
              </Button>
            </li>

            <li className="ml-20 d-flex align-items-center">
              <Button
                className={props.tabType === 'exchange' ? 'active' : ''}
                onClick={() => {
                  props.setTabType('exchange');
                }}>
                {labels.exchange[selected_lang]}
              </Button>
            </li>

            {/* <li className="ml-20 d-flex align-items-center">
              <Button
                className={tabType === 'training' ? 'active' : ''}
                onClick={() => {
                  setTabType('training');
                }}>
                {labels.training[selected_lang]}
              </Button>
            </li> */}
          </ul>
        </div>

        <div className="search-tabs-icons">
          <>
            <span
              onClick={() => {
                setFavourite(!favourite);
              }}>
              <i
                style={{fontSize: '18px', color: '#363636' , marginRight:'20px'}}
                className={
                  favourite
                    ? 'fa-solid fa-heart as-link'
                    : 'far fa-heart as-link'
                }
              />
            </span>

            {/* <Button
                className="new-btn btn-green button-padding ml-5 "
                onClick={() => {}}>
                Add Template
              </Button> */}
          </>
        </div>
      </div>
      <div className="fix-width-template">
        <div className="exhange-dropdown-header mt-3">
          <div className="exhange-dropdown ">
            <div className="exch_dropown">
              <div className="select-wrapper">
                <Dropdown
                  onSelect={(eventKey, event) => {
                    setType(eventKey);
                    setTemplateSearchQry('');
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type
                      ? props.industryList.find(item => item.id === type)?.name
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {props.industryList.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item.id.toString()}
                        active={item.id === type}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="search-tabs-icons ">
              <span
                onClick={() => {
                  setThreeDots(!threeDots);
                }}
                className={
                  threeDots ? 'icon active threeDots' : 'icon threeDots'
                }>
                <i className="fas fa-ellipsis-vertical" />
              </span>
            </div>
          </div>
          {threeDots && (
            <div className=" roi-search-form ">
              <div className="input-group">
                <Form.Control
                  type="text"
                  value={templateSearchQry}
                  pattern="^\S.*$"
                  required
                  placeholder="Search"
                  onChange={e => {
                    setTemplateSearchQry(e.target.value);
                  }}
                />
              </div>

              <div className="filter-icon-wrapper">
                <i
                  className="icon-filter-icon"
                  // onClick={() => setSearchFilterModal(true)}
                />
              </div>
            </div>
          )}
        </div>

        <div className="template-exchange-tab" >
          <div className="template-name-btns ">
            <span>Growth</span>
            <span className="template-name-btn-white">Nurturing</span>
            <span>Growth</span>
            <span>Solopreneur</span>
            <span className="template-name-btn-white">Nurturing</span>
            <span className="template-name-btn-white">Nurturing</span>
            <span className="template-name-btn-white">Nurturing</span>
            <span className="template-name-btn-white">Nurturing</span>
          </div>
          <div className="roi-template-general refferal-list-wrapper mt-3">
            {showLoader && <LoaderSpinner />}
            {Object.entries(showTrmplates).length > 0 ? (
              showTrmplates.map((item, index) => (
                <div className="general-setting mt-2" key={index}>
                  <h1> {item.company_name}</h1>

                  <div className="general-video">
                    <img src={Play} alt="" />
                  </div>

                  <div className="template-name-btns">
                    <span>Growth</span>
                    <span>Solopreneur</span>
                  </div>
                  <div className="cost-rating">
                    <span className="img-span">
                      4.8
                      <img src={rewardStar} alt="" />
                    </span>

                    <span> $250</span>
                  </div>
                  <div>Description</div>

                  <div className="view-more">
                    <span>View more</span>

                    <span
                      className="template_heart"
                      onClick={() => {
                        handleFavouriteTemplates(item.id);
                      }}>
                      <i
                        className={
                          item.is_favourite
                            ? 'fas fa-heart as-link'
                            : 'far fa-heart  as-link'
                        }
                      />
                    </span>
                  </div>

                  <div className="img-consulting">
                    <div>
                      <img
                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                        alt=""
                      />
                      <i
                        class="icon-appointments"
                        style={{color: '#949494'}}></i>{' '}
                      $125 /Hr
                    </div>
                    <div>
                      <Button
                        className="new-btn btn-green ml-5 "
                        onClick={() => {
                          handleimport(item);
                        }}>
                        Import
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no_record_main">
                <div className="no_record_found">No Record Found</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateExchange;
