import React, {useState, useEffect} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';

import Scrollbars from 'react-custom-scrollbars';
import Sidebar from '../../Pages/Sidebar';
import Pannels from '../Pannels';
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import {rewardStar, upload_file} from '../../Constants/Images';
import Slider from 'react-slick';

import CustomSlidingButtons from '../CustomSlidingButtons';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {allowedFileTypes} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import moment from 'moment/moment';
import UploadFileFilter from './UploadFileFilter';
import ReferOpportunity from '../Referral/ReferOpportunity';
import ViewFile from './ViewFile';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';

const FeedBack = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showComponent, setShowComponent] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [type, setType] = useState('1');
  const [threeDots, setThreeDots] = useState(false);

  const [transformation_Filter, setTransformation_Filter] = useState(['1']);
  const [filterOption, setFilterOption] = useState([]);

  const [tabType, setTabType] = useState('files');
  const [searchQry, setSearchQry] = useState('');
  const [documentInfo, setDocumentInfo] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [fileNameToShowUser, setFileNameToShowUser] = useState('');
  const [allDocumentsDetail, setAllDocumentsDetail] = useState([]);
  const [showModel, setshowModel] = useState('');
  const [page, setPage] = useState(1);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [refredDocument, setRefredDocument] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [seconadaryComponent, setShowComponent] = useState('');

  const currentUrl = window.location.href;

  // Check if the URL contains "customer_services"
  const dynamicStyle = {
    padding: 0,
    // Add other styles as needed
  };
  const dropdownMenu = [
    {
      id: '1',
      name: currentUrl.includes('customer_services')
        ? 'Select Request Type'
        : 'Select File Type',
    },
    {
      id: '2',
      name: 'pdf',
    },
    {
      id: '3',
      name: 'docx',
    },
    {
      id: '4',
      name: 'ppit',
    },
  ];


  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  useEffect(() => {
    setShowLoader(true);
    get_document_types();
    get_document_files(1);
  }, []);
  useEffect(() => {
    get_document_files(1);
  }, [tabType, transformation_Filter, searchQry]);

  const makeLoaderTrue = () => {
    setShowLoader(true);
  };
  useEffect(() => {
    setSearchQry('');
    // makeLoaderTrue();
  }, [tabType]);

  const get_document_types = () => {
    commonApi
      .get_document_types()
      .then(response => {
        if (response.status === 200) {
          const data = response.document_file_types;

          // Add_All object
          const Add_All = {id: 'all', title: 'All'};

          // Add Add_All object to the beginning of the array
          data.unshift(Add_All);

          // Set the updated array
          setFilterOption(data);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const validateFileExtension = filename => {
    console.log(filename, 'filename');
    const allowedExtensions = [
      'doc',
      'docx',
      'xls',
      'xlsx',
      'pdf',
      'ppt',
      'pptx',
    ];
    const fileExtension = filename.split('.').pop().toLowerCase();
    console.log(fileExtension, 'fileExtension');
    return allowedExtensions.includes(fileExtension);
  };
  const handleFileUpload = event => {
    if (event.target.files.length === 0) return false;
    const file = event.target.files[0];

    if (file) {
      const isValid = validateFileExtension(file.name);
      console.log(isValid, 'isValid');
      if (isValid) {
        // Replace this with your actual upload function
        // const filePath = e.target.files[0];
        setFileNameToShowUser(file);
        const objectUrl = URL.createObjectURL(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          upload_files_to_s3(e.target.result);
        };

        // setTicketImg(objectUrl);
      } else {
        errorMessage(
          'Invalid file type. Please upload a Word, Excel, PDF, or PowerPoint file.',
        );
      }
    }
  };
  const upload_files_to_s3 = file => {
    setShowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'file',
      file.replace(/^data:image\/[a-z]+;base64,/, ''),
    );
    commonApi
      .upload_files_to_s3(bodyFormData)
      .then(response => {
        if (response.status === 200 && response.success) {
          console.log('response', response);
          const fileInfo = [
            {
              file: response.file,
              extension: response.file_extension,
              title: response.file_title,
            },
          ];
          console.log(fileInfo, 'fileInfo');
          setDocumentInfo(fileInfo);
          console.log('chala');
          setShowLoader(false);
        } else {
          if (response.message) {
            errorMessage(response.message);
            setShowLoader(false);
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };
  const upload_files = () => {
    const payload = {
      title: documentInfo[0].title,
      file_type: type,
      extension: documentInfo[0].extension,
      file_url: documentInfo[0].file,
    };
    commonApi
      .upload_files(payload)
      .then(response => {
        if (response.status === 200) {
          setDocumentInfo(null);
          successMessage(response.message);
          get_document_files(1);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };
  const get_document_files = pageNo => {
    const payload = {
      page: pageNo ? pageNo : page,
      type: tabType,
      // '(received,upload,files)',
      search_query: searchQry,
      file_types: transformation_Filter.includes('all')
        ? transformation_Filter.filter(item => item !== 'all')
        : transformation_Filter,
      extensions: isCheck,
    };
    commonApi
      .get_document_files(payload)
      .then(response => {
        if (response.status === 200) {
          let catdata = [];

          if (pageNo > 1) {
            catdata = [...allDocumentsDetail, ...response.document_files];
            setAllDocumentsDetail(catdata);
          } else {
            catdata = response.document_files;
            setAllDocumentsDetail(catdata);
          }

          setPage(pageNo ? pageNo : 1);
          if (response.document_files.length > 0) {
            setIsFetching(false);
          }

          setShowLoader(false);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };
  const delete_document_files = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', documentInfo[0].file);
    commonApi
      .delete_document_files(bodyFormData)
      .then(response => {
        if (response.status === 200) {
          setDocumentInfo(null);
          setshowModel('');
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,

    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Issues',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Resolution Time ',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: ' Response Time',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];
  const rewardSliderDataUpload = [
    {
      name: 'On-Time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Response Time',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Customer Review',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  useEffect(() => {
    if (transformation_Filter.length === 3) {
      setTransformation_Filter(['all', ...transformation_Filter]);
    }
  }, [transformation_Filter]);

  const handleClick = e => {
    const {id} = e.target;

    // Handle the "All" option
    if (id === 'all') {
      if (transformation_Filter.includes('all')) {
        // If "All" is already selected, remove it and clear all selections
        setTransformation_Filter([]);
      } else {
        // If "All" is not selected, select all options
        const allIds = filterOption.map(option => option.id);
        setTransformation_Filter([...allIds]);
      }
    } else {
      // Handle individual options
      setTransformation_Filter(prevFilter => {
        if (prevFilter.includes(id)) {
          // If the ID is already in the array, remove it
          const newFilter = prevFilter.filter(item => item !== id);
          // Remove "all" if any individual item is deselected
          return newFilter.includes('all')
            ? newFilter.filter(item => item !== 'all')
            : newFilter;
        } else {
          // If the ID is not in the array, add it
          const newFilter = [...prevFilter, id];
          // Automatically add "all" if all other options are selected
          const allIds = filterOption.map(option => option.id);
          if (allIds.every(optId => newFilter.includes(optId))) {
            return ['all', ...allIds];
          }
          return newFilter;
        }
      });
    }
  };

  const showDataOfSlider = currentUrl.includes('customer_services')
    ? rewardSliderData
    : rewardSliderDataUpload;

  const handleSubmit = () => {
    if (documentInfo?.length > 0) {
      upload_files();
    } else {
      errorMessage('Please upload document first ');
    }
  };

  const DropdownTypeOfFile = filterOption.filter(
    fileType => fileType.id !== 'all',
  );

  const formatDate = dateString => {
    return moment(dateString).format('MM/DD/YY h:mm A');
  };

  const filterCatalogData = () => {
    get_document_files(1);
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + 2 + scrollTop >= scrollHeight && !isFetching) {
      // setShowBottomSpinner(true);
      // Increment the page number and call the API
      get_document_files(page + 1);
      setIsFetching(true);
    }
  };

  const handleRefred = (item, type) => {
    setRefredDocument(item);
    setShowComponent(type);
  };
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {showDataOfSlider &&
                      showDataOfSlider.length > 0 &&
                      showDataOfSlider.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="upload-file-page primary_panel_wraper referal_page_content">
                    <div className="messages-header  mb-3">
                      <div className="event-tabs tabs-style-2">
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'files' ? 'active' : ''}
                              onClick={() => {
                                makeLoaderTrue();
                                setTabType('files');
                              }}>
                              Files
                            </Button>
                          </li>

                          <li>
                            <Button
                              className={tabType === 'received' ? 'active' : ''}
                              onClick={() => {
                                makeLoaderTrue();
                                setTabType('received');
                              }}>
                              Received
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={tabType === 'upload' ? 'active' : ''}
                              onClick={() => {
                                setTabType('upload');
                              }}>
                              Upload
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        {/* <span
                          className={
                            // currentComponent === 'createInvoice'
                            //   ? 'icon active'
                            //   :

                            'icon'
                          }>
                          <i className="icon-plus" onClick={() => {}} />
                        </span> */}

                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots mt-0'
                              : 'icon threeDots mt-0'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    {threeDots && (
                      <>
                        <div className="person-search-wrapper mb-3 mt-0 messages-users-wrapper">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQry}
                                onChange={e => {
                                  setSearchQry(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                setshowModel('filter');
                              }}>
                              <i className="icon-filter-icon" />
                            </Button>
                          </div>
                          <div className="multiselection_sliding_btns tranformation_filter category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                            <CustomSlidingButtons
                              items={filterOption}
                              activeButtons={transformation_Filter}
                              onItemClick={e => {
                                handleClick(e);
                              }}
                              showItems={3}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="refferal-list-wrapper">
                      {showLoader && <LoaderSpinner />}

                      {tabType === 'files' && (
                        <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide onScroll={handleScroll}
                        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                        // Hide the vertical scrollbar
                        renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
                          <div>
                            <div className="upload-file-content">
                              {allDocumentsDetail?.length > 0 ? (
                                allDocumentsDetail.map((doc, index) => (
                                  <div
                                    className="uploaded-files-wrapper"
                                    key={index}>
                                    <div className="uploaded-file">
                                      <div
                                        className="file-detail as-link"
                                        onClick={() => {
                                          setSelectedFile(doc);
                                          setShowComponent('viewDocument');
                                        }}>
                                        <h1>{doc.title}</h1>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}>
                                          <span>
                                            {doc.document_file_type_title}
                                          </span>
                                          <span>{doc.extension}</span>
                                        </div>
                                      </div>
                                      <div className="upload_file_bottom d-flex justify-content-between">
                                        <div className="file_upload_time">
                                          {formatDate(doc.created_at)}
                                        </div>

                                        <span
                                          className="share_icon as-link "
                                          onClick={() => {
                                            handleRefred(doc, 'refer_document');
                                          }}>
                                          <i className="fas fa-share"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="no-results-wrapper mt-5">
                                  <div className="no-results-content mt-5">
                                    <span>No record found</span>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        style={{padding: '11px 25px'}}
                                        className="btn btn-dark"
                                        onClick={() => {
                                          setShowLoader(true);
                                          setTabType('upload');
                                        }}>
                                        Upload File
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Scrollbars>
                      )}

                      {tabType === 'received' && (
                        <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide onScroll={handleScroll}
                        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                        // Hide the vertical scrollbar
                        renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
                          <div>
                            <div className="upload-file-content">
                              {allDocumentsDetail?.length > 0 ? (
                                allDocumentsDetail.map((doc, index) => (
                                  <div
                                    className="uploaded-files-wrapper"
                                    key={index}>
                                    <div className="uploaded-file">
                                      <div
                                        className="file-detail as-link"
                                        onClick={() => {
                                          setSelectedFile(doc);
                                          setShowComponent('viewDocument');
                                        }}>
                                        <h1>{doc.title}</h1>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}>
                                          <span>
                                            {doc.document_file_type_title}
                                          </span>
                                          <span>{doc.extension}</span>
                                        </div>
                                      </div>
                                      <div className="upload_file_bottom d-flex justify-content-between">
                                        <div
                                          className="file_upload_time"
                                          onClick={() => {
                                            setSelectedFile(doc);
                                            setShowComponent('viewDocument');
                                          }}>
                                          {formatDate(doc.created_at)}
                                        </div>

                                        <span
                                          className="share_icon as-link"
                                          onClick={() => {
                                            handleRefred(doc, 'refer_document');
                                          }}>
                                          <i className="fas fa-share"></i>
                                        </span>
                                      </div>
                                    </div>

                                    {/* <div
                              style={{
                                height: '42px',
                                borderRadius: '3px',
                                background: '#F1EFE8',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}>
                              <div>
                                <span>10/05/24 5:42 PM</span>
                                
                                  <i className="fas fa-share"></i>
                               
                              </div>
                            </div> */}
                                  </div>
                                ))
                              ) : (
                                <div className="no-results-wrapper mt-5">
                                  <div className="no-results-content mt-5">
                                    <span>No record found</span>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        style={{padding: '11px 25px'}}
                                        className="btn btn-dark"
                                        onClick={() => {
                                          setShowLoader(true);
                                          setTabType('upload');
                                        }}>
                                        Upload File
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Scrollbars>
                      )}
                      {tabType === 'upload' && (
                        <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide onScroll={handleScroll}
                        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                        // Hide the vertical scrollbar
                        renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
                          <div>
                            <div className="upload-file-top mb-2">
                              <div className="mt-2">
                                What would you like to upload?
                              </div>
                            </div>
                            <div className="upload-file-content upload">
                              <div
                                style={{
                                  marginBottom: '15px',
                                }}>
                                <div className="select-wrapper upload-custom-slct-wrapp">
                                  <Dropdown
                                    onSelect={(eventKey, event) => {
                                      setType(eventKey);
                                    }}
                                    drop="down">
                                    <Dropdown.Toggle variant="primary">
                                      {type
                                        ? DropdownTypeOfFile.find(
                                            item => item.id === type,
                                          ).title
                                        : 'Select Role'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {DropdownTypeOfFile.map((item, index) => (
                                        <Dropdown.Item
                                          key={`st-${index}`}
                                          eventKey={item.id.toString()}
                                          active={item.id === type}>
                                          {item.title}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div
                                className="file-upload-wrapper as-link"
                                style={{
                                  marginBottom: '15px',
                                }}>
                                {!documentInfo && (
                                  <input
                                    type="file"
                                    className="as-link"
                                    accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx"
                                    onChange={e => {
                                      handleFileUpload(e);
                                    }}
                                  />
                                )}
                                {documentInfo && documentInfo?.length > 0 ? (
                                  <>
                                    <span
                                      onClick={() => {
                                        setshowModel('confirmation_Model');
                                      }}
                                      style={{
                                        width: '100%',
                                        textAlign: 'end',
                                        paddingRight: '11px',
                                        position: 'absolute',
                                        top: '7px',
                                      }}>
                                      {' '}
                                      <i className="fa-solid fa-xmark"></i>
                                    </span>

                                    <span style={{fontSize: '40px'}}>
                                      <i className="fa-solid fa-file"></i>
                                    </span>
                                  </>
                                ) : (
                                  <img src={upload_file} alt="" />
                                )}
                                {/* <img src={upload_file} alt="" /> */}

                                <span>
                                  {currentUrl &&
                                  currentUrl.includes('customer_services')
                                    ? 'Share a pic or screenshot'
                                    : documentInfo === null
                                    ? 'Tap to upload'
                                    : fileNameToShowUser?.name}
                                </span>
                              </div>
                              {allDocumentsDetail?.length > 0 && (
                                <div>
                                  <h2
                                    style={{
                                      fontWeight: '600',
                                      fontSize: '17.05px',
                                      paddingBottom: '15px',
                                    }}>
                                    Uploaded
                                  </h2>
                                </div>
                              )}{' '}
                              <div className="uploaded-files-wrapper">
                                {allDocumentsDetail?.length > 0 ? (
                                  allDocumentsDetail.map(doc => (
                                    <div
                                      key={doc.id}
                                      className="uploaded-file mb-3 ">
                                      <div
                                        className="file-detail as-link"
                                        onClick={() => {
                                          setSelectedFile(doc);
                                          setShowComponent('viewDocument');
                                        }}>
                                        <h1>{doc.title}</h1>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}>
                                          <span>
                                            {doc.document_file_type_title}
                                          </span>
                                          <span>{doc.extension}</span>
                                        </div>
                                      </div>
                                      <div className="upload_file_bottom d-flex justify-content-between">
                                        <div
                                          className="file_upload_time"
                                          onClick={() => {
                                            setSelectedFile(doc);
                                            setShowComponent('viewDocument');
                                          }}>
                                          {formatDate(doc.created_at)}
                                        </div>
                                        <span
                                          className="share_icon as-link"
                                          onClick={() => {
                                            handleRefred(doc, 'refer_document');
                                          }}>
                                          <i className="fas fa-share"></i>
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </Scrollbars>
                      )}
                    </div>
                    {tabType === 'upload' &&
                      showLoader === false &&
                      documentInfo &&
                      documentInfo?.length > 0 && (
                        <div
                          className={
                            currentUrl.includes('customer_services')
                              ? 'text-center mt-3 bg_transparent '
                              : 'upload-file-bottom bg_transparent'
                          }>
                          <Button
                            className="new-btn btn-green"
                            style={{
                              width: '200px',
                              height: '44px',
                              fontSize: '15px',
                              // Green color with 50% opacity
                              // 80% opacity for the entire button
                              border: 'none', // Optional: remove border if needed
                            }}
                            // onClick={() => {}}
                            // style={{
                            //   width: '200px',
                            //   height: '44px',
                            //   fontSize: '15px',
                            // }}
                            onClick={handleSubmit}>
                            Send File(s)
                          </Button>
                        </div>
                      )}
                  </div>

                  {showModel === 'filter' && (
                    <UploadFileFilter
                      // show={searchFilter}
                      onClose={() => setshowModel('')}
                      //  sessionType={sessionType}
                      // isEvent={isEvent}
                      hitFuctionAfterFilter={filterCatalogData}
                      // filterlisting={catalogType}
                      isCheckAll={isCheckAll}
                      setIsCheckAll={setIsCheckAll}
                      isCheck={isCheck}
                      setIsCheck={setIsCheck}
                      favouriteCatalogs={''}
                      cartItems={''}
                      orginalCartItem={''}
                      catalogItems={''}
                      heartColor={''}
                    />
                  )}
                  {showModel === 'confirmation_Model' && (
                    <ConfirmationModel
                      closeModel={() => {
                        setshowModel('');
                      }}
                      discription={
                        'Are you Sure you want to delete this File ?'
                      }
                      buttonOne={'Yes'}
                      buttonTwo={'No Thanks'}
                      ButtonOneAction={() => {
                        delete_document_files();
                      }}
                    />
                  )}
                </>
              }
              secondary={
                <>
                  {showComponent === 'refer_document' && refredDocument && (
                    // <SlidingPanel
                    //   hidePanel={() => {
                    //     setShowComponent('');
                    //   }}>
                    // <Scrollbars>
                    <ReferOpportunity
                      onClose={() => {
                        setShowComponent('');
                      }}
                      type={'document'}
                      userData={refredDocument}
                    />
                    //   </Scrollbars>
                    // </SlidingPanel>
                  )}

                  {showComponent === 'viewDocument' && (
                    <ViewFile
                      selectedFile={selectedFile}
                      onClose={() => {
                        setShowComponent('');
                        setSelectedFile(null);
                      }}
                    />
                  )}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedBack;
