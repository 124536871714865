
export const pipelineList = (state = [], action) => {
    switch (action.type) {
      case 'PIPELINE/LIST':
        return action.payload;
      default:
        return state;
    }
  };


  export const dealList = (state = [], action) => {
    switch (action.type) {
      case 'DEALS/LIST':
        return action.payload;
      default:
        return state;
    }
  };


  export const updateDealData = (state = false, action) => {
    switch (action.type) {
      case 'DEALS/UPDATE':
        return action.payload;
      default:
        return state;
    }
  };

  export const updatePipelineData = (state = false, action) => {
    switch (action.type) {
      case 'PIPELINE/UPDATE':
        return action.payload;
      default:
        return state;
    }
  };