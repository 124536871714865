import React, {useState,  useEffect} from 'react';
import {Button, Form, } from 'react-bootstrap';

import commonApi from '../../Services/CommonService';
import Sidebar from '../../Pages/Sidebar';
import {successMessage} from '../../Utils/ToastMessages';
import Pannels from '../Pannels';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import OutlookCalendar from './OutlookCalendar';
import Calender from './Calender';
import moment from 'moment';

const FeedBack = props => {
  const [topic, setTopic] = useState([]);
  const [topicId, setTopicId] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [feedBack, setFeedBack] = useState('');
  const todayDate =new Date();

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const handle = e => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();

      setValidated(true);
    } else {
      e.preventDefault();

      submitFeedback();
      setValidated(false);
    }
  };
  const handleFeedbackChange = e => {
    const value = e.target.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      setFeedBack(value.trimStart());
    } else {
      setFeedBack(value);
    }
  };

  const submitFeedback = () => {
    setshowLoader(true);
    var payLoad = {
      feedback_description: feedBack,
      feedback_type: topicId,
    };
    commonApi
      .submit_feedback(payLoad)
      .then(res => {
        setshowLoader(false);
        if (res.status === 200) {
          successMessage(res.message);
          setFeedBack('');
          // setTopicId('3');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const feedbacktypes = () => {
    setshowLoader(true);
    var payLoad = {};
    commonApi
      .feedback_types(payLoad)
      .then(res => {
        setshowLoader(false);
        if (res.status === 200) {
          setshowLoader(false);
          setTopic(res.data);
          setTopicId(res.data[2].id);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    feedbacktypes();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
          workspaceData={workspaceData}
        />
        <div className="page-content-wrapper">
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
                <div className="custom-scroll-content pt-0 h-100 add-feedback">
                  {showLoader && <LoaderSpinner />}
                  <div className="send-refferal-form-wrapper mt-4 h-100">
                    <Form
                      className="h-100"
                      noValidate
                      validated={validated}
                      onSubmit={handle}>
                      <div className="form-fields">
                        <Form.Group className="fieldset">
                          <Form.Label>
                            We value your feedback. Please let us know how your
                            experience has been with us.
                          </Form.Label>
                        </Form.Group>

                        <Form.Group className="fieldset">
                          <Form.Label>Topic</Form.Label>
                          <div className="select-wrapper">
                            <select
                              className="form-control"
                              value={topicId}
                              onChange={e => {
                                setTopicId(e.target.value);
                              }}>
                              {Object.entries(topic).length > 0 &&
                                topic.map((item, index) => (
                                  <option
                                    id={item.id}
                                    value={item.id}
                                    key={`sessionCategories-${index}`}>
                                    {item.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Form.Group>

                        <Form.Group className="fieldset">
                          <Form.Label>Feedback</Form.Label>
                          <Form.Control
                            as="textarea"
                            required
                            rows={8}
                            // pattern="^\S.*$"
                            placeholder="Leave Comment Here..."
                            value={feedBack}
                            onChange={e => {
                              handleFeedbackChange(e);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Feedback is required.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="invite-btns">
                        <Button type="submit"> Send</Button>
                      </div>
                    </Form>
                  </div>
                </div>
                {showComponent === 'WorkSpace' && (
                  <CreateWorkSpace
                    onClose={() => {
                      setShowComponent('');
                    }}
                  />
                )}
              </>
            }
            secondary={<>
            {/* <OutlookCalendar/> */}
            <Calender  
            setSelectedDate={setDate}
            selectedDate={date ? new Date(date) : ''}
            minDate={todayDate} 
            maxDate={new Date(2024, 11, 20)} 
            showDate={true} 
            showTime={true}
            />
            <Calender 
            type="time"
            setStartDaysTime={e => {
              if (e) {
                setTime(
                  moment(e, 'HH:mm A').format(
                    'HH:mm A',
                  ),
                );
              } else {
                setTime('');
              }
            }}
            startDaysTime={
              time === ''
                ? ''
                : moment(time, 'HH:mm:ss')
            } 
            showDate={false} 
            showTime={true}
            />
            <Calender
            setSelectedDate={setDate}
            selectedDate={date ? new Date(date) : ''}  
            minDate={todayDate} 
            maxDate={new Date(2024, 11, 31)} 
            showDate={true} 
            showTime={false}/>
            </>}
          />
        </div>
      </div>
    </>
  );
};
export default FeedBack;
