import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {generateId} from '../Utils/Helpers';

const NewCustomTabs = ({tabs, active, onSelect, titleLabel, darkmode = false, check,showTraction}) => {
  const [activeTab, setActive] = useState('');
  const [tabId, setTabId] = useState('');
  const [handleWidth, setHandleWidth] = useState(0);
  const [handlePos, setHandlePos] = useState(0);

  const Newdata = (check === '1' && showTraction ) ? tabs : tabs.slice(0, 2);

  const calculateHandlePos = index => {
    let leftPos = 0;
    const button = document.getElementById(`${tabId}-${index}`);
    const buttonWidth = button ? button.offsetWidth : 0;
    setHandleWidth(buttonWidth);

    if (index !== 0) {
      for (let i = 0; i < index; i++) {
        const otherItem = document.getElementById(`${tabId}-${i}`);
        leftPos += otherItem.offsetWidth;
      }
    }

    setHandlePos(leftPos);
  };

  const setActiveTab = (tab, index) => {
    calculateHandlePos(index);
    setActive(tab);
    onSelect(tab);
  };

  const getActiveTabIndex = tab => {
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i] === tab) {
        return i;
      }
    }
  };

  useEffect(() => {
    const tabId = generateId();
    setTabId(tabId);

    setTimeout(() => {
      const index = getActiveTabIndex(activeTab);
      setActiveTab(activeTab, index);
    }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.entries(tabs).length > 0 && active !== '') {
      const tabIndex = getActiveTabIndex(active);
      setActiveTab(active, tabIndex);
    } else if (Object.entries(tabs).length > 0 && !active) {
      setActiveTab(tabs[0], 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);


  return (
    <>
      {Object.entries(Newdata).length > 0 && (
        <div
          id={tabId}
          className={`custom-tabs snaptshot-tab event-tabs ${darkmode ? 'dark-tabs' : ''}`}>
          <span
            className="activeHandle"
            style={{width: handleWidth, left: handlePos}}
          />
          <ul>
            {Newdata.map((item, index) => {
              const isActive =
                titleLabel && item[titleLabel] === activeTab
                  ? 'active'
                  : !titleLabel && item === activeTab
                  ? 'active'
                  : '';
              return (
                <li key={`tab-item-${index}`}>
                  <Button
                    id={`${tabId}-${index}`}
                    className={isActive}
                    onClick={e => {
                      const currentItem = titleLabel ? item[titleLabel] : item;
                      setActiveTab(currentItem, index);
                    }}>
                    {titleLabel && item[titleLabel]}
                    {!titleLabel && item}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default NewCustomTabs;
