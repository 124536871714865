"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The Recording model includes the recording timestamp and the ID of a participant who started the recording.
 */
class Recording {
    /**
     * @ignore
     */
    constructor(participantId, startTimestamp) {
        this.participantId = participantId;
        this.startTimestamp = startTimestamp;
    }
}
exports.default = Recording;
