import React, {useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import CustomModal from '../CustomModal';
import SlidingPanel from '../SlidingPanel';
import {fixName, capitalizeFirst} from '../../Utils/commonUtils';
import {DEFAULT_IMAGE} from '../../Utils/env';
import Scrollbars from 'react-custom-scrollbars';
import {errorMessage} from '../../Utils/ToastMessages';
// import { fixName } from '../../../Utils/commonUtils';

const SelectedReferUser = props => {
  const [showModaL, setShowModel] = useState(false);
  const [clearAllModal, setclearAllModal] = useState(false);
  const [popId, setPopId] = useState('');

  const mergedArray = [...props.selectedAllUser, ...props.referOutSide];
  const handleSelectedAud = id => {
    // 
    if (popId.id) {
      props.setUsers(props.users.filter(item => item !== popId.id));
      props.setSelectedAllUser(
        props.selectedAllUser.filter(item => item.id !== popId.id),
      );
    }

    if (props.referOutSide) {
      props.setReferOutSide(
        props.referOutSide.filter(
          item => item.contact !== popId.contact && item.fname !== popId.fname,
        ),
      );
    }

    if (props.selectedAllUser.length + props.referOutSide.length === 1) {
      props.onClose();
    }
  };

  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper pt-0">
          <div className="sidebar-heading-wrapper mt-0 with-panel">
            <div className="invite-persons-heading">
              {props.selectedAllUser.length + props.referOutSide.length > 0 && (
                <span
                  className="builder-text as-link"
                  onClick={() => {
                    setclearAllModal(true);
                  }}>
                  Clear all (
                  {props.selectedAllUser.length + props.referOutSide.length})
                </span>
              )}
            </div>
          </div>

          <Scrollbars>
            <span className="builder-text"> </span>
            <div className="invite-persons-list fix-width-forms">
              <Row>
                {Object.entries(mergedArray).length > 0 ? (
                  Object.entries(mergedArray).length > 0 &&
                  mergedArray.map((item, index) => (
                    <Col md={4} xl={3} key={`f-item-${index}`}>
                      <div
                        className="stage-list-item"
                        onClick={() => {
                          setPopId(item);
                          setShowModel(true);
                        }}>
                        <div className="stage-person-img as-link">
                          <img
                            src={
                              !item.profile_img || item.profile_img === ''
                                ? DEFAULT_IMAGE
                                : item.profile_img
                            }
                            alt=""
                            id={item.id}
                          />
                        </div>
                        <div className="stage-person-name as-link">
                          {item.first_name && (
                            <h3>{fixName(item.first_name, item.last_name)}</h3>
                          )}
                          {item.fname && item.lname && (
                            <h3>{fixName(item.fname, item.lname)}</h3>
                          )}
                          {item.fname && !item.lname && (
                            <h3>{capitalizeFirst(item.fname)}</h3>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <p className="text-center"> No user found.</p>
                )}
              </Row>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showModaL && (
        <CustomModal className="RemoveContacts-Modal" size="small" onClose={() => setShowModel(false)}>
          <div className="permission-modal-content text-center">
            {/* <h2>Alert</h2> */}
            <p>
              Are you sure you want to remove{' '}
              <b>{popId.first_name && fixName(popId.first_name, popId.last_name)}
              {popId.fname && popId.lname && fixName(popId.fname, popId.lname)}
              {popId.fname && !popId.lname && capitalizeFirst(popId.fname)}</b>?
            </p>
            <div className="event-btn-wrapper permission-modal-btns">
              <Button
                variant="secondary"
                onClick={() => {
                  if (props.alreadyAddedUser.includes(popId.id)) {
                    errorMessage('This user is  already associated');
                    setShowModel(false);
                  } else {
                    handleSelectedAud();
                    setShowModel(false);
                  }
                }}>
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowModel(false);
                }}>
                No Thanks!
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {clearAllModal && (
        <CustomModal size="small" onClose={() => setclearAllModal(false)}>
          <div className="permission-modal-content text-center">
            <p style={{ fontSize: 18, fontFamily: 'HelveticaBold', }}>Alert</p>
            <p>Are you sure you want to clear all?</p>
            <div className="event-btn-wrapper permission-modal-btns">
              <Button
                variant="secondary"
                onClick={() => {
                  //   const usersIds = props.users.filter(id =>
                  //     props.alreadyAddedUser.includes(id),
                  //   );
                  //   if (
                  //     props.alreadyAddedUser.length > 0 &&
                  //     usersIds.length > 0
                  //   ) {
                  //     const userDetails = props.selectedAllUser.filter(item =>
                  //       props.alreadyAddedUser.includes(item.id),
                  //     );

                  //     props.setUsers(usersIds);
                  //     props.setSelectedAllUser(userDetails);

                  //     setclearAllModal(false);
                  //     errorMessage('These users  are already associated');
                  //   } else {
                  setclearAllModal(false);
                  if (props.users.length > 0) {
                    props.setUsers([]);
                  }
                  if (props.selectedAllUser.length > 0) {
                    props.setSelectedAllUser([]);
                  }
                  if (props.referOutSide.length > 0) {
                    props.setReferOutSide([]);
                  }

                  // if (props.removeObject) {
                  //   props.setRemoveObject([]);
                  // }

                  props.onClose();
                  //   }
                }}>
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setclearAllModal(false);
                }}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};
export default SelectedReferUser;
