const generalArray = [
  {
    id: '1',
    // name: 'gen_AddCaution',
    name: 'gen_PeopleFollowTravel',
    description: 'People I follow travel somewhere new. ',
  },
  {
    id: '2',
    // name: 'gen_AddPriase',
    name: 'gen_FollowingAchivement',
    description: 'Someone I follow adds to their achievements. ',
  },
  {
    id: '3',
    // name: 'gen_CallReminder',
    name: 'gen_SendMeRefferal',
    description: 'Someone sends me a referral. ',
  },
  {
    id: '4',
    name: 'gen_AddCaution',
    // name: 'gen_FollowingAchivement',
    description: 'Someone leaves caution message.',
  },
  {
    id: '5',
    name: 'gen_AddPriase',
    // name: 'gen_PeopleFollowTravel',
    description: 'Someone leaves praise message. ',
  },
  {
    id: '6',
    name: 'gen_ScheduleCall',
    description: 'Someone schedules on my calendar. ',
  },
  {
    id: '7',
    // name: 'gen_SendMeRefferal',
    name: 'gen_CallReminder',
    description: '5 minute reminder before consult starts (set reminder time).',
  },

  {id: '8', name: 's_FollowingStartSession', description: 'People I follow start a session.'},
  {
    id: '9',
    name: 's_FavouriteTopicStart',
    description: 'A session starts under a topic or activity I like.',
  },
  {
    id: '10',
    name: 's_Invite',
    description: 'Someone I follow invites me to a session.',
  },
  {
    id: '11',
    name: 's_Referral',
    description: 'Someone sends me a referral to a session.',
  },
  {
    id: '12',
    name: 's_Change',
    description: 'Changes are made to a session I rsvp’d to.',
  },
  {id: '13', name: 's_RSVP', description: 'When anyone rsvp.'},
  {id: '14', name: 's_Reminder', description: 'Upcoming sessions reminder.'},

  {
    id: '15',
    name: 'e_FavouriteTopicStart',
    description: 'An event starts under a topic or activity I like.',
  },
  {
    id: '16',
    name: 'e_Referral',
    description: 'Someone sends me a referral to an event.',
  },
  {
    id: '17',
    name: 'e_MomentAdd',
    description: ' Someone i follow invites me to an  event.',
  },
  {
    id: '18',
    name: 'e_MomentAchievement',
    description: 'If someone adds your moment to their achievements wall.',
  },
  {id: '19', name: 'e_FollowingRSVP', description: 'When people I follow rsvp.'},
  {
    id: '20',
    name: 'e_Change',
    description: 'Changes are made to an event I rsvp’d to.',
  },

  {id: '21', name: 'e_UserAddedRSVP', description: 'When anyone rsvp.'},
  {id: '22', name: 'e_Reminder' , description: 'Upcoming event reminder.'},

  {
    id: '23',
    name: 'o_BestMatch',
    description: 'An opportunity is posted that matches my criteria.',
  },
  {id: '24', name: 'o_Referral', description: 'Someone sends me a referral.'},
  {id: '25', name: 'o_Message', description: 'I receive a new message.'},
  {
    id: '26',
    name: 'o_Call',
    description: 'Someone schedules on my calendar.',
  },
  {id: '27', name: 'o_Save', description: 'Someone saved my opportunity.'},
  {id: '28', name: 'o_Decision', description: 'Once the decision is made.'},
];

export {generalArray};
