import React, {useState, useEffect, useMemo} from 'react';
import {Button} from 'react-bootstrap';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {Cart, ShareablesBlackIcon} from '../../Constants/Images';
import Slider from 'react-slick';
import CreateCatalog from '../Catalog/CreateCatalog';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {FormattedText} from '../../Utils/Helpers';
import CartSvg from '../../Svg/CartSvg';
import { RoiLogo } from '../../Constants/Images';
const CatalogDetail = ({
  catalogDetail_id,
  onClose,
  AddToFavourite,
  catalogItems,
  catalogItem,
  OfferingsPermissions,
  handleCart,
  removeCatalogtem,
  setCatalogItems,
  searchQry,
}) => {
  const [prosuctSize, setProsuctSize] = useState('');
  const [changeHeartStatus, setChangeHeartStatus] = useState();
  const [showComponent, setShowComponent] = useState('');
  const sizeButtons = ['Small', 'Large', 'XL'];
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showDataForDetail, setShowDataForDetail] = useState(null);
  const showMoreClassDescription = showMore ? 'show-all' : '';

  let catalogDetail;

  useEffect(() => {
    if (!searchQry && catalogItems && catalogItems.length > 0) {
      const filteredData = catalogItems.find(
        item => item.catalog_id === catalogDetail_id,
      );
      setShowDataForDetail(filteredData);
    }
  }, [searchQry, catalogDetail_id, catalogItems]);

  catalogDetail = useMemo(() => {
    if (!searchQry) {
      return catalogItems && catalogItems.length > 0 && catalogDetail_id
        ? catalogItems.find(item => item.catalog_id === catalogDetail_id)
        : catalogItem;
    } else {
      return showDataForDetail;
    }
  }, [searchQry, catalogDetail_id, catalogItems, showDataForDetail]);

  const productSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (catalogDetail?.image) {
      setShowDataForDetail(catalogDetail);
      setShowDetails(false)
    }
  }, [catalogDetail]);

  console.log(catalogDetail, 'catalogDetail');
  console.log(showDataForDetail, 'showDataForDetail');

  const productSliderData = catalogDetail?.image
    ? catalogDetail.image.split()
    : [];

  const handleProductSize = size => {
    setProsuctSize(size);
  };
  let heartColor = '#52bd41';

  const lines =
    catalogDetail?.description && catalogDetail.description !== ''
      ? catalogDetail.description.split('\n')
      : '';
  console.log(catalogDetail, 'catalogDetail');

  const updateTheEditData = data => {
    if (data) {
      setCatalogItems(previousItems =>
        previousItems.map(item =>
          item.catalog_id === data.catalog_id ? {...item, ...data} : item,
        ),
      );
    }
  };

  const renderHeader = () => {
        
    return (
      <>
    
    {localStorage.getItem('id') === catalogDetail?.created_by  ?  (  <span
                  className="as-link"
                  onClick={() => {
                    setShowComponent('editCatalog');
                  }}>
                  <EditPencilSvg fill={'white'} h={20} w={20} />
                </span>
    ) : (
      <img src={RoiLogo} alt="" />
    )}
        
      
      </>
    );
  }; 
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          onClose();
        }}
        renderData={renderHeader}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        <div className="serach-filter-wrapper create-sessionEvents">
          {/* <div>
            {
              // OfferingsPermissions &&
              //   (OfferingsPermissions === 'allow' ||
              //     OfferingsPermissions?.create === true)
              localStorage.getItem('id') === catalogDetail?.created_by && (
                <h1
                  className="as-link mb-2"
                  onClick={() => {
                    setShowComponent('editCatalog');
                  }}>
                  <i class="fa-solid fa-pen"></i> {''}
                </h1>
              )
            }
          </div> */}
          <Scrollbars>
            <div className="create-ticket-form fix-width-forms fixed-width-inputs black-head-form-pad-left pb-3">
              <div className="catalog_detail_screen">
                <div className="catalog_detail_slider pt-3">
                  <Slider {...productSlider}>
                    {productSliderData.length > 0 &&
                      productSliderData.map((item, index) => (
                        <div>
                          <div className="catalog_slider_main">
                            {/* <div className="catalog_slider_img"></div> */}
                            <img
                              className="cart-img"
                              src={item}
                              alt=""
                              height={'50%'}
                              width={'50%'}
                            />
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>

                <div className="catalog_detail_content mt-5">
                  <div className="cataolog_catalogDetail_title p-reg-truncate" style={{fontWeight:'600'}}>
                    {catalogDetail?.title}
                  </div>

                  <div className="cataog_category d-flex  justify-content-between">
                    <div className="cataog_category">
                      {catalogDetail &&
                        catalogDetail?.catalog_type.charAt(0).toUpperCase() +
                          catalogDetail?.catalog_type.slice(1)}
                    </div>

                    {/* <span
                      className=" as-link d-inline"
                      onClick={() => {
                        setShowComponent('editCatalog');
                      }}>
                      <EditPencilSvg fill={'#52bd41'} h={15} w={15} />
                    </span> */}
                  </div>

                  {/* <div className="catalog_size_btns">
                    <div className="size_title">Select Size</div>
                    <div className="size_btns_flex mt-3">
                      {sizeButtons.length > 0 &&
                        sizeButtons.map((item, index) => (
                          <span
                            key={index}
                            className={`size_btn ${
                              prosuctSize === item ? 'border_size_btn' : ''
                            }`}
                            onClick={() => {
                              handleProductSize(item);
                            }}>
                            {item}
                          </span>
                        ))}
                    </div>
                  </div> */}

                  <div className="catalog_cart_card bg_cart">
                    <h1>
                      {catalogDetail?.cost ? `$${catalogDetail.cost}` : 'Free'}
                    </h1>

                    <span>
                      {catalogDetail?.is_cart ? (
                        <span
                          className="as-link"
                          style={{}}
                          onClick={() => {
                            removeCatalogtem(catalogDetail);
                          }}>
                          <i
                            className="fa-solid fa-xmark"
                            style={{
                              color: '#D71616',
                              fontSize: '21px',
                            }}></i>
                        </span>
                      ) : (
                        <span
                          className="as-link"
                          onClick={() => {
                            handleCart(catalogDetail);
                          }}
                          style={{}}>
                          <CartSvg fill={'#52BD41'} />
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="catalog_cart_card">
                    <span
                      className={`as-link font_color`}
                      onClick={() => {
                        setShowDetails(!showDetails);
                      }}>
                      Details
                    </span>

                    <div className="catalog_detail_icons">
                      <span className="heart_icon">
                        <span
                          onClick={() => {
                            AddToFavourite(catalogDetail?.catalog_id);
                            if (catalogItem) {
                              if (!catalogDetail?.is_favourite) {
                                catalogDetail.is_favourite = true;
                              } else {
                                catalogDetail.is_favourite = false;
                              }
                            }
                          }}
                          className="as-link">
                          <i
                            className={`${
                              catalogDetail?.is_favourite == true
                                ? 'fas'
                                : 'far'
                            } fa-heart`}
                            style={{
                              fontSize: '21px',
                              color:
                                catalogDetail?.is_favourite == true
                                  ? heartColor
                                  : '',
                            }}></i>
                        </span>
                      </span>
                      <span style={{}}>
                        <img
                          className="sharableYellow as-link"
                          src={ShareablesBlackIcon}
                          alt="shareable"
                        />
                      </span>
                    </div>
                  </div>

                  {showDetails && (
                    <>
                      {catalogDetail &&
                        catalogDetail?.description &&
                        catalogDetail?.description !== '' && (
                          <div className="d-flex gap-1">
                            <h1>Description:</h1>

                            <div>
                              <FormattedText
                                className={`profile-description mt-0 ${showMoreClassDescription}`}
                                content={catalogDetail?.description}
                              />

                              {(catalogDetail?.description?.length > 250 ||
                                lines?.length > 3) && (
                                <>
                                  {' '}
                                  <span
                                    className="show-more-link"
                                    onClick={() => setShowMore(!showMore)}>
                                    {!showMore
                                      ? labels.show_more[selected_lang]
                                      : labels.show_less[selected_lang]}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                      {catalogDetail?.left_item ? (
                        <div className="d-flex gap-1">
                          <h1>Available quantity:</h1>
                          <div>{catalogDetail?.left_item}</div>
                        </div>
                      ) : null}

                      {catalogDetail?.taxes_fee ? (
                        <div className="d-flex gap-1">
                          <h1>Tax:</h1>
                          <div>${catalogDetail?.taxes_fee}</div>
                        </div>
                      ) : null}

                      {catalogDetail?.discount ? (
                        <div className="d-flex gap-1">
                          <h1>Discount:</h1>
                          <div>${catalogDetail?.discount}</div>
                        </div>
                      ) : null}

                      {catalogDetail?.late_fee ? (
                        <div className="d-flex gap-1">
                          <h1>Late fee:</h1>
                          <div>${catalogDetail?.late_fee}</div>
                        </div>
                      ) : null}

                      {catalogDetail?.shipping_handling ? (
                        <div className="d-flex gap-1">
                          <h1>Shipment:</h1>
                          <div>${catalogDetail?.shipping_handling}</div>
                        </div>
                      ) : null}

                      {catalogDetail?.delivery_method ? (
                        <div className="d-flex gap-1">
                          <h1>Delivery method:</h1>
                          <div>{catalogDetail?.delivery_method}</div>
                        </div>
                      ) : null}

                      {catalogDetail?.grace_period ? (
                        <div className="d-flex gap-1">
                          <h1>Grace Period:</h1>
                          <div>{catalogDetail?.grace_period}</div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>

                <div className="product_buy_btn mt-6">
                  <Button className="new-btn btn-green " onClick={() => {}}>
                    Buy Now
                  </Button>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showComponent === 'editCatalog' && (
        <CreateCatalog
          CatalogItem={catalogDetail}
          onClose={data => {
            setShowComponent('');

            updateTheEditData(data);
          }}
        />
      )}
    </>
  );
};

export default CatalogDetail;
