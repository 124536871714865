import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import LoaderModal from './LoaderModal';
import FocusModel from './FocusModel';
import {useSelector, useDispatch} from 'react-redux';
import {getSessionListing} from '../../Store/Actions/SessionAction';
import CustomModal from '../CustomModal';


const SessionSearchFilter = props => {
  // const [show, setShow] = useState(props.show);
  const closeModal = () => {
    // setShow(false);
    props.onClose();
  };
  const [showLoader, setShowLoader] = useState(false);
  const [searchFilter, setSearchFilter] = useState(false);

  const categoryData = useSelector(state => state.sessionCategory);
  const dispatch = useDispatch();

  useEffect(() => {
    sessionCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sessionCategories = () => {
    // setShowLoader(true);
    commonApi
      .session_categories()
      .then(res => {
        setShowLoader(false);
        if (res.status === 200) {
          // setSessionC(res.data);
          props.setList(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const handleSelectAll = e => {
    props.setIsCheckAll(!props.isCheckAll);
    props.setIsCheck(props.list.map(item => item.id));
    if (props.isCheckAll) {
      props.setIsCheck([]);
    }
  };
  // const works = props.isCheck.toString();

  const getReferrals = () => {
    setShowLoader(true);
    var payload = {
      builder_filter_data: {
        allies: '',
        appointmentTypes: '',
        backgrounds: '',
        characteristics: [],
        homeLatitude: '',
        homeLongitude: '',
        homeTown: '',
        travelLatitude: '',
        travelLocation: '',
        travelLongitude: '',
      },
      // allies:  props.active ? 'yes' : 'no',
      // gender: props.gender.toString(),
      // otherIdentifires: props.identiferId.toString(),
      // ethnicities: props.ethnicityId.toString(),
      // city_states : props.focusLocations,
      category_ids: props.isCheck.toString(),
      opportunity_filter_data: {
        address: '',
        allies: '',
        amount: '',
        frequencyIds: [],
        latitude: '',
        longitude: '',
        opportunityIds: [],
        vaccinated: '',
        virtual: '',
      },
      page: 1,
      referred_by_me: props.arrowButton,
      search_query: '',
      type: props.subTabType,
    };
    commonApi

      .get_referrals(payload)
      .then(res => {
        if (res.status === 200) {
          props.data(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const sessionsList = () => {
    var payload = {
      category_id: props.isCheck,
      events: !props.typeIs,
      focus: props.active ? 'yes' : 'no',
      // gender: props.gender.toString(),
      gender: '',
      otherIdentifires: props.identiferId.toString(),
      // ethnicities: props.ethnicityId.toString(),
      ethnicities: '',
      city_states: props.focusLocations,
      on_boarding_areas: props.persnalTypesId,
      appointment_types: props.appointmentId,
      on_boarding_categories: props.categoriesId,
      page: 1,
      search_query: '',
      sessions: props.typeIs,
      type: props.sessionType,
    };

    dispatch(getSessionListing(payload));
    // API.sessions_list(payload)
    //   .then((res) => {
    //     // setshowLoader(false);
    //     if (res.status === 200) {
    //       // props.fun(res); // jd-experiment
    //       //  
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Err", err);
    //     return {type: "error", message: err.message};
    //   });
  };

  const handleClick = e => {
    const {id, checked} = e.target;
    props.setIsCheck([...props.isCheck, id]);
    if (!checked) {
      props.setIsCheck(props.isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    if (props.list.length > 0) {
      if (props.isCheck.length === props.list.length) {
        props.setIsCheckAll(true);
      } else {
        props.setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCheck]);
  const eventListLocation = () => {
    var work = {
      category_id: props.isCheck,
      events: true,
      focus: props.active ? 'yes' : 'no',
      // gender: props.gender.toString(),
      gender: '',
      otherIdentifires: props.identiferId.toString(),
      // ethnicities: props.ethnicityId.toString(),
      ethnicities: '',
      city_states: props.focusLocations,

      on_boarding_areas: props.persnalTypesId,
      appointment_types: props.appointmentId,
      on_boarding_categories: props.categoriesId,

      latitude: props.latitute,
      longitude: props.lug,
      page: 1,
      radius: 100,
      search_query: '',
    };
    // 

    commonApi
      .event_list_location(work)
      .then(res => {
        // 
        if (res.status === 200) {
          props.fun(res.events);

          // // 
          // setlug(parseFloat(res.data[0].longitude));
          // // 
          // // 

          // setlat(parseFloat(res.data[0].latitude));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handlevalue = () => {
    props.setSessionFilter(props.isCheck)
    props.onClose();
  };

  return (
    <>
      <CustomModal size="medium" className={'sessioneventFilter'} style={{height: '100%'}} onClose={() => closeModal()}>
        {showLoader && <LoaderModal show={showLoader} />}

        <div className="serach-filter-wrapper">
          <div className="align-items-center justify-content-between mb-3">
            <div className="headings m-0">
              <h2 className="m-0 text-center">Categories</h2>
            </div>

            <div className="sidebar-heading-wrapper mt-0 text-right">
              {/* <div className="ticket-icons-options">
              <ul>
                <li
                  className={props.active ? 'active' : 'target-icon'}
                  onClick={() => {
                    setSearchFilter(true);
                  }}>
                  <i className="icon-bullseye" />
                </li>
              </ul>
            </div> */}
            </div>
          </div>

          {/* <Scrollbars> */}
          <div className="filter-wrapper filter-pad-bottom-wrap">
            <div className="filter-category-list">
              <ul>
                <li className={props.isCheckAll ? 'active' : ''}>
                  <Form.Label>
                    All{' '}
                    <input
                      type="checkbox"
                      id="ALL"
                      name="ALL"
                      value="ALL"
                      onChange={handleSelectAll}
                      checked={props.isCheckAll}
                    />{' '}
                    <span />
                  </Form.Label>
                </li>
                {Object.entries(categoryData).length > 0 &&
                  categoryData.map((item, index) => (
                    <li
                      key={`sessionC-${index}`}
                      className={
                        props.isCheck.includes(item.id) ? 'active' : ''
                      }>
                      <Form.Label>
                        {item.ses_category_name}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          onChange={handleClick}
                          checked={props.isCheck.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {/* </Scrollbars> */}
          <div className="filter-btn-wrapper pt-3 text-center bg_transparent bottom-0  border-radius-25">
            <Button
              className="btn-dark"
              type="submit"
              onClick={() => {
                
                  props.shareAbleType === 'stop'
                    ? handlevalue()
                    : props.subTabType
                    ? getReferrals()
                    : props.type === 'event'
                    ? eventListLocation()
                    : sessionsList();
                

                props.onClose();
              }}>
              Filter Categories
            </Button>
          </div>
        </div>
      </CustomModal>
      {searchFilter && (
        <FocusModel
          show={searchFilter}
          setActive={props.setActive}
          active={props.active}
          setFocusLocations={props.setFocusLocations}
          setIdentiferId={props.setIdentiferId}
          setEthnicityId={props.setEthnicityId}
          setGender={props.setGender}
          setPersnalTypesId={props.setPersnalTypesId}
          setCategoriesId={props.setCategoriesId}
          setAppointmentId={props.setAppointmentId}
          onClose={() => {
            setSearchFilter(false);
          }}
        />
      )}
    </>
  );
};

export default SessionSearchFilter;
