import React, {useState,  useRef, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {errorMessage} from '../../Utils/ToastMessages';
import CropImage from '../Cropper/CropImage';
import CustomModal from '../CustomModal';
import LoaderSpinner from '../Modals/LoaderSpinner';
import { DEFAULT_IMAGE_COMPANYLOGO } from '../../Constants/Images';
import commonApi from '../../Services/CommonService';
import { RoiLogo } from '../../Constants/Images';

const EditBusinessProfile = ({onClose, updateCompanyDetails,companyProfileData}) => {
  const [file, setFile] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [validated, setValidated] = useState(false);

  const [companyLogo, setCompanyLogo] = useState(companyProfileData.company_logo);
  const [companyName, setCompanyName] = useState(companyProfileData.company_name);
  const [bio, setBio] = useState(companyProfileData.bio);
  const [companyNameExist, setCompanyNameExist] = useState(false);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const buttonRef = useRef(null);

  const changeTicketImg = e => {
    // If no file selected, return
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('modal');

      e.target.value = null;
      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {
      //   //

      //   uploadProfilePicture(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };

  const handle = e => {
    // alert("ASDasd")
    const form = e.currentTarget;

    if (form.checkValidity() === false || companyNameExist) {
      e.preventDefault();
      e.stopPropagation();

      setValidated(true);
      // alert('1')
    } else {
      e.preventDefault();

      updateCompanyDetails(companyLogo,companyName,bio,'');
      companyProfileData.company_logo = companyLogo;
      companyProfileData.company_name = companyName;
      companyProfileData.bio = bio

      onClose()

      setValidated(false);
    }
  };


  useEffect(() => {
    check_company_name();
    // eslint-disable-next-line 
  }, [companyName]);

  const check_company_name = () => {
    const payload = {
      company_name: companyName,
      company_id: companyProfileData.id,
    };

    commonApi
      .check_company_name(payload)
      .then(res => {
        if (res.status === 200) {
          setCompanyNameExist(res.company_exist);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };


  return (
    <SlidingPanel hidePanel={() => onClose()}
    renderData={renderHeader}
    showBlackHeader={true}
    headerPadding={'24px 20px'}
    lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
    firstDivStyle={{
      padding: 0,
      background:
        'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
    }}
    >
      {showLoader && <LoaderSpinner />}

      <div className="serach-filter-wrapper editProfile">
        <div className="fixed-width-inputs black-head-form-pad-left pt-2">
          <div className="sidebar-heading-wrapper mt-0">
            <div className="heading">
              <h2>Edit Company Profile</h2>
            </div>
          </div>
        </div>

        <Scrollbars autoHide>
        <div className='fixed-width-inputs black-head-form-pad-left'>
          <div className="card profile-block-wrapper edit-business-card-wrapper">
            <div className="profile-img-wrapper">
              <div className="profile-img upload-user-img removeCursor upload-edit-business-profile-img">
                <div style={{position: 'relative'}}>
                  <div className="">
                    <img src={companyLogo ? companyLogo : DEFAULT_IMAGE_COMPANYLOGO} alt="" />
                  </div>
                  <div className="">
                  <span className="icon icon-camera"></span>
                  <input
                    type="file"
                    name="my-image"
                    id="image"
                    accept="image/*"
                    onChange={e => {
                      changeTicketImg(e);
                    }}
                  />
                  </div>
                </div>
                <div style={{position: 'relative'}}>
                  {/* <Form.Label>Update Profile Picture</Form.Label> */}
                  <input
                    type="file"
                    name="my-image"
                    id="image"
                    accept="image/*"
                    onChange={e => {
                      changeTicketImg(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <Form noValidate validated={validated} onSubmit={handle}>
              <div className="fieldset">
                <Form.Label className="w-auto" controlid="validationCustom01">
                  Company Name
                </Form.Label>

                <Form.Control
                  type="text"
                  value={companyName}
                  pattern="^\S.*$"
                  required
                  placeholder="Company Name"
                  onChange={e => {
                    setCompanyName(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Name is required.
                </Form.Control.Feedback>

                {companyNameExist === true && (
                    <div className="text-danger textforerror">
                      Company name already exist
                    </div>
                  )}
              </div>
              <div className="send-refferal-form-wrapper mt-2">
                <Form.Group className="fieldset send-refferal-fieldset">
                  <Form.Label>Bio</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={8}
                    required
                    maxLength={320}
                    placeholder="Tell other contacts a little about Company"
                    value={bio}
                    onChange={e => {
                      setBio(e.target.value);
                    }}
                  />
                  {bio?.length > 0 && ( <span className="info-text">
                    {bio && bio.length > 0 && 320 - bio.length} Maximum
                    characters limit
                  </span>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Bio is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <Button
                type="submit"
                style={{display: 'none'}}
                ref={buttonRef}></Button>
            </Form>
          </div>
          </div>
        </Scrollbars>

        <div className="filter-btn-wrapper text-center pt-3">
          <Button
          style={{marginBottom:"1rem", marginRight:"0px"}}
            className="btn-dark"
            onClick={() => buttonRef.current.click()}>
            Update Company Profile
          </Button>
        </div>
      </div>

      {showModel === 'modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="company"
            ratio={25 / 24}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            setImgUrl={setCompanyLogo}
            setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}
    </SlidingPanel>
  );
};

export default EditBusinessProfile;
