import React, {useState, useRef, useEffect} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import CustomModal from '../CustomModal';

import DoubleCam from '../../Svg/DoubleCam';

import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import CustomTabs from '../CustomTabs';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import CropImage from '../Cropper/CropImage';
import MultipleImages from '../Common/MultipleImages';
import LoaderSpinner from '../Modals/LoaderSpinner';
import { RoiLogo } from '../../Constants/Images';

const CreateTransform = props => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [tabType, setTabType] = useState('Before');
  const [validated, setValidated] = useState(false);
  const [categoryTypeId, setCategoryTypeId] = useState('');
  const [CategoryTypes, setCategoryTypes] = useState([]);
  const [beforeImages, setBeforeImages] = useState([]);
  const [afterImages, setAfterImages] = useState([]);
  const buttonRef = useRef(null);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const [file, setFile] = useState(null);
  const [AddNewCatgory, setAddNewCatgory] = useState('');
  const [currentModel, setCurrentModel] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);
  const ticketTabs = [
    labels.before[selected_lang],
    labels.after[selected_lang],
  ];

  const handle = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      event.preventDefault();
      if (beforeImages.length > 0 && afterImages.length > 0) {
        CreateTransformation();
      } else {
        errorMessage('Please add before && after images');
      }
    }
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setCurrentModel('UploadImage');

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };

  useEffect(() => {
    get_transformation_category();
  }, []);

  const CreateTransformation = () => {
    const payload = {
      category_id: categoryTypeId,
      title: title,
      description: description,
      before_images: beforeImages.length > 0 ? beforeImages.toString(',') : '',
      after_images: afterImages.length > 0 ? afterImages.toString(',') : '',
    };
    commonApi
      .create_company_transformation(payload)
      .then(res => {
        successMessage(res.message);
        props.onClose();
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const create_transformation_category = () => {
    const payload = {
      category_title: AddNewCatgory,
    };
    commonApi
      .create_transformation_category(payload)
      .then(res => {
        get_transformation_category();
        setCurrentModel('');
        setAddNewCatgory('');
        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_transformation_category = () => {
    var payload = {}
    commonApi
      .get_transformation_category(payload)
      .then(res => {
        setCategoryTypes(res.transformation_categories);
        setCategoryTypeId(res.transformation_categories[0].id);

        setTimeout(() => {
          setShowSpinner(false)
        }, 300);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderHeader = () => {
    
    return (
      <>
      
          <img src={RoiLogo} alt="" />
    
      </>
    );
  };


  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        >
          {showSpinner && <LoaderSpinner className={'curved-spinner-bg'} />}
        <div className="referal_page_content serach-filter-wrapper create_tranformation">
          <div className=" add-contacts referal_page_content">
            <div className="sidebar-heading-wrapper fixed-width-inputs  black-head-form-pad-left pt-2">
              <h2>Add Transformation</h2>
            </div>

            <Scrollbars>
              <div className="send-refferal-form-wrapper  fixed-width-inputs  black-head-form-pad-left mt-2">
                <Form noValidate validated={validated} onSubmit={handle}>
                  <div className="roi-form-input">
                    <Form.Label controlid="validationCustom01">
                      Title
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={title}
                      pattern="^\S.*$"
                      required
                      placeholder="Enter title"
                      onChange={e => {
                        setTitle(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Title is required.
                    </Form.Control.Feedback>
                  </div>

                  <div className="roi-form-dropdown mb-3">
                    <Form.Label
                      controlid="validationCustom01"
                      className="d-flex justify-content-between align-items-center">
                      Category
                      <span
                        className="icon as-link"
                        onClick={() => {
                          setCurrentModel('Add_Catgory');
                        }}>
                        <i className="icon-plus"></i>
                      </span>
                    </Form.Label>

                    <div className="select-wrapper">
                      <Dropdown
                        onSelect={(eventKey, event) => {
                          setCategoryTypeId(eventKey);
                        }}
                        drop="down">
                        <Dropdown.Toggle variant="primary">
                          {categoryTypeId &&
                            CategoryTypes.length > 0 &&
                            CategoryTypes.find(
                              item => item.id === categoryTypeId,
                            )?.category_title}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {CategoryTypes.length > 0 &&
                            CategoryTypes.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id}
                                active={item.id === categoryTypeId}>
                                {item.category_title}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="roi-form-input">
                    <Form.Label controlid="validationCustom01">
                      Description
                    </Form.Label>
                    <Form.Control
                      // required
                      value={description}
                      as="textarea"
                      rows={6}
                      type="text"
                      placeholder="Write description... "
                      onChange={e => {
                        setDescription(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Description is required.
                    </Form.Control.Feedback>
                  </div>

                  <Button
                    type="submit"
                    style={{display: 'none'}}
                    ref={buttonRef}></Button>
                </Form>
              </div>

              <div className="d-flex align-items-center justify-content-center fixed-width-inputs  black-head-form-pad-left w-100">
                <div className="create-ticket-tabs w-100">
                  <CustomTabs
                    darkmode
                    tabs={ticketTabs}
                    active={tabType}
                    onSelect={item => setTabType(item)}
                  />
                </div>
              </div>

              <div className="Business_profile_page fixed-width-inputs black-head-form-pad-left w-100" style={{paddingBottom:'65px'}}>
                <div className="business_profile_content mt-3">
                  <div className="before_after_content ">
                    <div className="before_after_img">
                      <div
                        className="ticket-img-wrapper"
                        style={{
                          width: '174px',
                          height: '174px',
                          borderRadius: '3.918px',
                          objectFit: 'cover',
                        }}>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={e => changeTicketImg(e)}
                        />

                        <i className="icon-camera" />
                      </div>
                      {/* <img src={cupcake} alt="" /> */}
                    </div>
                  </div>
                  <div
                    className="before_after_content"
                    style={{position: 'relative'}}>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '12px',
                        right: '18px',
                      }}
                      onClick={() => {
                        // startWebCam();
                        setCurrentModel('showMultipleImages');
                      }}>
                      {tabType === 'Before' && beforeImages.length > 1 && (
                        <DoubleCam fill={'#52BD41'} />
                      )}
                      {tabType === 'After' && afterImages.length > 1 && (
                        <DoubleCam fill={'#52BD41'} />
                      )}
                    </div>

                    <div className="before_after_img">
                      {tabType === 'Before' && beforeImages.length > 0 && (
                        <img src={beforeImages[0]} alt="" />
                      )}
                      {tabType === 'After' && afterImages.length > 0 && (
                        <img src={afterImages[0]} alt="" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>

            <div className="filter-btn-wrapper pt-2 pb-2 fixed-width-inputs  bg_transparent bottom-0">
              <div className="invite-btns  p-0  text-center">
                <Button
                  type="submit"
                  className='m-0'
                  onClick={() => {
                    buttonRef.current.click();
                  }}>
                  Add Transformation
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SlidingPanel>
      {/* Modal for clicking a pic from webcam */}

      {currentModel === 'Add_Catgory' && (
        <CustomModal
          size="small"
          onClose={() => {
            setCurrentModel('');
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal">
              <div className="fieldset">
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Label className="w-auto" controlid="validationCustom01">
                    Add Category
                  </Form.Label>
                </div>

                <Form.Control
                  value={AddNewCatgory}
                  type="text"
                  placeholder="Add Category... "
                  onChange={e => {
                    setAddNewCatgory(e.target.value);
                  }}
                />
              </div>

              

              <div className="invite-btns  p-0 pt-3 text-center">
                <Button
                  
                  onClick={() => {
                    if (AddNewCatgory === '') {
                      errorMessage('Please enter catgory');
                    } else {
                      create_transformation_category();
                    }
                  }}>
                  Add Category
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
      {currentModel === 'UploadImage' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setCurrentModel('');
            setFile(null);
          }}>
          <CropImage
            type="transformImages"
            ratio={100 / 50}
            file={file}
            tabType={tabType}
            setFile={setFile}
            setCropImg={setCurrentModel}
            afterImages={afterImages}
            setAfterImages={setAfterImages}
            beforeImages={beforeImages}
            setBeforeImages={setBeforeImages}
            // setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}
      {currentModel === 'showMultipleImages' && (
        <MultipleImages
          onClose={() => {
            setCurrentModel('');
          }}
          imagesArray={tabType === 'Before' ? beforeImages : afterImages}
          setImageArray={
            tabType === 'Before' ? setBeforeImages : setAfterImages
          }
        />
      )}
    </>
  );
};

export default CreateTransform;
