import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row, Dropdown} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import {
  CustomSlidingButtons,
  RefferalSearchFilter,
  SlidingPanel,
} from '../../../Components';
import {labels} from '../../../Constants/Translations';
import {selected_lang} from '../../../Utils/env';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import ProfileComponent from '../../../Components/ProfilePage/ProfileComponent';
import {errorMessage, successMessage} from '../../../Utils/ToastMessages';
import SendInvitation from '../StaticPages/SendInvitation';
import {DEFAULT_IMAGE} from '../../../Utils/env';
import {Link} from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import {fixName} from '../../../Utils/commonUtils';
import {amplitudeEvent} from '../../../Services/AmplitudeServices';
import {connect} from 'react-redux';
import CircleListBuilders from './CircleListBuilders';
import Invite from '../../../Components/SingleComponent/Invite';
import {CustomTabs} from '../../../Components';
import { RoiLogo } from '../../../Constants/Images';

const AllBuilders = props => {
  const [builders, setbuilders] = useState([]);
  const [searchQry, setSearchQry] = useState('');
  const [areas, setAreas] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckSF, setIsCheckSF] = useState([]);
  const [button, setButton] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [newUserId, setNewUserId] = useState('');
  const [filterButton, setFilterButton] = useState(false);
  const [appointmentId, setAppointmentId] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [locationS, setLocationS] = useState('');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [list, setList] = useState([]);
  const [subTabType, setSubTabsType] = useState('search');
  const [active, setActive] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [gender, setGender] = useState([]);
  const [focusLocations, setFocusLocations] = useState([]);
  const [categoriesId_Focus, setCategoriesId_Focus] = useState([]);
  const [persnalTypesId, setPersnalTypesId] = useState([]);
  const [appointmentId_Focus, setAppointmentId_Focus] = useState([]);
  const [idenntifier_Filter, setIdenntifier_Filter] = useState([]);
  const [areas_Filter, setAreas_Filter] = useState([]);
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [PhoneNo, setPhonenNo] = useState('');
  const [showSlectedUser, setShowSlectedUser] = useState('');
  const [contact, setContact] = useState([]);
  const [tabType, setTabType] = useState('business');
  const ticketTabs = [
    labels.qr_type_business[selected_lang],
    labels.type_individual[selected_lang],
  ];
  const [relationType, setRelationType] = useState('1');
  const [contactRelations, setContactRelations] = useState([]);
  const [pocName, setPocName] = useState('');
  const buttonRef = useRef(null);
  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const getAreas = () => {
    // setshowLoader(true);
    setShowSpinner(true);
    commonApi
      .get_contact_relation_types()
      .then(response => {
        if (response) {
          setAreas(response.data);
          setAreas_Filter(response.data.map(item => item.id));
          // setAreas_Filter(
          //   selectedValues(response.all_boarding_areas, 'id', 'selected'),
          // );
          // setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const selectedValues = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };
  const getContactRelationTypes = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelations(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const builderData = h => {
    setbuilders(h);
    // setSaerchData(h);
    // setshowLoader(false);
    setShowSpinner(false);
  };

  const getBuilders = (sr_qry, area_ids) => {
    var adress =
      typeof locationS === 'object'
        ? locationS.label
        : typeof locationS === 'string'
        ? locationS
        : '';
    var data = {
      allies: active ? 'yes' : 'no',
      // gender: gender.toString(),
      gender: '',
      otherIdentifires: identiferId.toString(),
      // ethnicities: ethnicityId.toString(),
      ethnicities: '',
      city_states: focusLocations,
      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId_Focus,
      on_boarding_categories: categoriesId_Focus,
      on_boarding_areas_filter: areas_Filter.toString(),
      other_identifier_filter: idenntifier_Filter.toString(),

      // appointment_type_ids: appointmentId,
      areas: area_ids,
      categories: categoriesId,
      hometown_location: adress,
      // hometown_location: locationS,
      search_query: sr_qry,
      travel_location: adress,
      latitude: longitude,
      longitude: longitude,
      user_type: props.type === 'appointment' ? 'all' : '',
      relation_ids:areas_Filter,
    };
    // allies: "no",
    // appointment_type_ids: ["1", "2"],
    // areas: [],
    // categories: ["15"],
    // hometown_location: "",
    // search_query    : "",
    // travel_location: "",
    commonApi
      .get_builders(data)
      .then(response => {
        if (response) {
          setbuilders(response.discover_builder);
          setShowSpinner(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getBuilders(searchQry, areas_Filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQry, areas_Filter]);


  useEffect(() => {
    
    if(contact.length > 0){
      setInviteLoader(true)
      setInviteQry('')
    }
  }, [contact])


  
  useEffect(() => {
    // getCircles();
    getAreas();
    amplitudeEvent('VISIT_BUILDERS');
    getContactRelationTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //createCircle

  const getProfileData = userId => {
    setNewUserId(userId);
    setButton(true);
    setShowComponent('profileComponent');
  };

  useEffect(() => {
    setFirstName('');
    setPhonenNo('');
    setPocName('');
  }, [tabType]);

  // const handle = event => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();

  //     setValidated(true);
  //   } else {
  //     event.preventDefault();

  //     let Payload = {
  //       contact: PhoneNo,
  //       fname: firstName,
  //       lname: '',
  //     };
  //     setFirstName('');
  //     setPhonenNo('');
  //     setValidated(false);
  //     setContact(previous => [...previous, Payload]);
  //     if (props.type === 'appointment') {
  //       props.setContact(previous => [...previous, Payload]);
  //     }

  //     const UpdatedArray = [...contact, Payload];
  //     sendnvitationOutIside(UpdatedArray);

  //     //
  //   }
  // };
  // const sendnvitationOutIside = participantArray => {
  //   var Payload = {
  //     contacts: participantArray,
  //   };
  //   commonApi
  //     .send_invitation_outside(Payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         successMessage(res.message);
  //       }
  //       if (res.status === false) {
  //         errorMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const handle = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      event.preventDefault();

      // referOutside();
      // alert('d')
      referOutside();

      //
    }
  };

  const referOutside = type => {
    let Payload = {
      poc_name: pocName,
      contact: PhoneNo,
      fname: firstName,
      relationship_id: relationType,
      user_type: tabType === 'Business' ? 'business' : 'individual',
    };

    if (type === 'number') {
      if (PhoneNo.includes('+')) {
        // setParticipantArraytPhonenNo(previous => [...previous, Payload]);
        sendnvitationOutIside(Payload);
      } else {
        // alert('')
        let PayloadData = {
          poc_name: pocName,
          contact: '+' + PhoneNo,
          fname: firstName,
          relationship_id: relationType,
          user_type: tabType === 'Business' ? 'business' : 'individual',
        };
        // setParticipantArraytPhonenNo(previous => [...previous, PayloadData]);

        sendnvitationOutIside(PayloadData);
      }
    } else {
      sendnvitationOutIside(Payload);
    }
    // alert('2')
    if (props.contact) {
      props.setContact(previous => [...previous, Payload]);
    }

    setFirstName('');
    setPhonenNo('');

    setPocName('');
    setValidated(false);
  };

  const sendnvitationOutIside = Updated => {
    var Payload;
    // props.setContact(previous => [...previous, Updated]);
    if (props.type === 'schedule') {
      Payload = {
        contacts: [Updated],
      };
    } else {
      Payload = {
        contacts: [Updated],
      };
    }

    commonApi
      .send_invitation_outside(Payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          if (props.setContact) {
            // participantArray.map((item, index) => {
            props.setContact(previous => [...previous, Updated]);
            // });
          }
          if (props.setCheckUserHitApi) {
            props.setCheckUserHitApi(!props.checkUserHitApi);
          }
          // props.onClose();
        }
        if (res.status === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleClick = e => {
    const {id} = e.target;
    if (areas_Filter && areas_Filter.length > 0 && areas_Filter.includes(id)) {
      setAreas_Filter(areas_Filter.filter(item => item !== id));
    } else {
      setAreas_Filter([...areas_Filter, id]);
    }
  };

  const CircleUserListData = item => {
    // console.log('????',item)

    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (!props.builders.includes(miniItem.id)) {
          // alert('why me')
          // count=count + 1

          props.setBuilders(previous => [...previous, miniItem.id]);
          props.setAllBuilders(previous => [...previous, miniItem]);
          props.setOriginalData(previous => [...previous, miniItem])
        }
      });
    }
  };
  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
       
          <img src={RoiLogo} alt="" />
      
      </>
    );
  };

  return (
    <>
      <SlidingPanel
      renderData={renderProfileTagsButtons}
      showBlackHeader={true}
      lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
      firstDivStyle={{
        padding: 0,
        background:
          'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
      }}
      hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper customized-search-filter-wrapper pt-3 black-head-form-pad-left black-head-form-pad-right">
          <div
            className={`messages-profile-heading no-border fix-width-forms m-0 pb-0 ${
              props.windowWidth < 1300 && 'with-panel'
            }`}>
            <div className="person-search-wrapper m-0 fix-width-forms w-100 mb-2">
              <div className="event-tabs tabs-style-2 mb-2">
                <ul>
                  <li>
                    <Button
                      className={subTabType === 'search' ? 'active' : ''}
                      onClick={() => {
                        setSubTabsType('search');
                        // getMyAppointmentsByType('from_me');
                      }}>
                      Search
                    </Button>
                  </li>

                  <li>
                    <Button
                      className={subTabType === 'contact' ? 'active' : ''}
                      onClick={() => {
                        setSubTabsType('contact');
                        // getMyAppointmentsByType('to_me');
                      }}>
                      Add Contact
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {subTabType === 'search' && (
            <>
              {props.type === 'appointment' && (
                <>
                {inviteLoader && (<div className="invite_loader_hieght">
                <LoaderSpinner />
                </div>
               )}
                <Scrollbars>
                  <Invite
                    type={'refer'}
                    moderator={props.builders}
                    setModerator={props.setBuilders}
                    selectedModData={props.allBuilders}
                    setSelectedModData={props.setAllBuilders}
                    contact={props.contact}
                    setContact={props.setContact}
                    originalDataOfMod={props.originalData}
                    setOriginalDataOfMod={props.setOriginalData}
                    forApiHit={subTabType}
                    handleCircle={handleCircle}
                    onClick={() => {
                      setShowComponent('inviteUser')
                      setTimeout(() => {
                        if(contact.length ===0 && inviteQry !==''){
                          setInviteFollowers(followersList)
                                setInviteQry('')
                        } 
                      }, 1000);
                     
                    }}
                    setShowSlectedUser={setShowSlectedUser}
                    showLoader={inviteLoader}
                    setshowLoader={setInviteLoader}
                    followers={inviteFollowers}
                    setFollowers={setInviteFollowers}
                    setFollowersList={setFollowersList}
                    followersList={followersList}
                    setSearchQry={setInviteQry}
                    searchQry={inviteQry}
                  />
                </Scrollbars>
                </>
              )}
              {props.type !== 'appointment' && (
                <div className="search-form d-flex">
                  <Form.Group className="search-input w-100">
                    <Form.Control
                      onChange={e => {
                        setSearchQry(e.target.value);
                      }}
                      type="text"
                      placeholder="Search for contacts"
                    />
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-end ml-5">
                    {/* <Button
                    onClick={() => {
                      setShowComponent('inviteUser');
                    }}>
                    <i className="icon-plus"></i>
                  </Button> */}
                    {props.type !== 'appointment' && (
                      <Button
                        onClick={() => {
                          setFilterButton(true);
                        }}>
                        <i className="icon-filter-icon" />
                      </Button>
                    )}
                  </div>
                </div>
              )}
              <div className="refferal-list-wrapper all_builders-wrapp-listing">
                {props.type !== 'appointment' && showSpinner &&  <LoaderSpinner />}
                {props.type !== 'appointment' && (
                  <div className="person-search-wrapper m-0">
                    <div className="category-btns-wrapper category-btns-wrapper-slider">
                      <CustomSlidingButtons
                        items={areas}
                        activeButtons={areas_Filter}
                        onItemClick={e => {
                          handleClick(e);
                        }}
                        showItems={3}
                        label={'relations'}
                      />
                    </div>
                  </div>
                )}

{/* showComponent === '' && */}
                { props.type !== 'appointment' && (
                  <>
                    <Scrollbars>
                      <div className="all_builders all_builders-wrapp">
                        {/* <span className="icon">
                      <i
                        className="fa fa-times"
                        onClick={() => {
                          props.onClose();
                        }}></i>
                    </span> */}

                        <div className="persons-imgs-wrapper search-network pt-3">
                          <div className="stage-list-wrapper">
                            {/* <Scrollbars style={{position: 'absolute', width: '100%'}}> */}
                            {showSlectedUser === '' && (
                              <Row>
                                {Object.entries(builders).length > 0 ? (
                                  Object.entries(builders).length > 0 &&
                                  builders.map((item, index) => (
                                    <Col md={3} key={`stage-person-${index}`}>
                                      <div
                                        className="stage-list-item"
                                        onClick={() => {
                                          getProfileData(item.id);
                                        }}>
                                        <div
                                          className={
                                            item?.linked_user_detail
                                              ?.user_type === 'business'
                                              ? 'stage-person-img business as-link'
                                              : 'stage-person-img as-link'
                                          }>
                                          <img
                                            src={
                                              item.profile_img
                                                ? item.profile_img
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="stage-person-name as-link">
                                          <h3>
                                            {fixName(
                                              item.first_name,
                                              item.last_name,
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  ))
                                ) : (
                                  <div className="no-results-wrapper no-appointments mt-5">
                                    <div className="img-bg-wrapper">
                                      <img
                                        src={require('../../../Assets/images/norecord/no-circles.png')}
                                        alt=""
                                      />
                                    </div>
                                    <div className="no-results-content">
                                      {/* {searchQry && ( */}

                                      <p className="noRecord-title">
                                        We didn't find the {searchQry} match for
                                        the name
                                      </p>
                                      {/* ) 
                                    : (
                                      <h2>
                                        {
                                          labels.DIDNOT_FIND_NETWORK[
                                            selected_lang
                                          ]
                                        }
                                      </h2>
                                    )
                                    } */}

                                      {!showSpinner && (
                                        <div className="btn-wrapper mt-3">
                                          <Button
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setSubTabsType('contact');
                                            }}>
                                            {/* <Link
                                              className="text-light"
                                              style={{textDecoration: 'none'}}> */}
                                              Add Contacts
                                            {/* </Link> */}
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Row>
                            )}
                            {/* </Scrollbars> */}
                          </div>
                        </div>
                      </div>
                    </Scrollbars>
                    {props.type === 'appointment' && (
                      <div className="filter-btn-wrapper">
                        <div className="invite-btn text-left">
                          <Button className="btn-dark" type="submit">
                            Add To Appointment
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {/* {subTabType === 'contact' && (
            <>
              <div className="fix-width-forms">
                <div className="send-refferal-form-wrapper mt-2">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    <Form.Group className="fieldset">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        pattern="[^\s ]+"
                        placeholder="First name"
                        value={firstName}
                        onChange={e => {
                          setFirstName(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {firstName
                          ? 'First name is invalid.'
                          : 'First name is required.'}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="fieldset">
                      <Form.Label>Phone Number or Email</Form.Label>
                      <Form.Control
                        required
                        pattern="[^\s ]+"
                        type="text"
                        placeholder="Phone number or email"
                        value={PhoneNo}
                        onChange={e => {
                          setPhonenNo(e.target.value);
                        }}
                      />
                      <PhoneInput
                  onlyCountries={ALLOWED_COUNTRY}
                  country={'us'}
                  value={PhoneNo}
                  // onChange={}
                  onChange={phone => setPhonenNo(phone)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handle(e);
                    }
                  }}
                  placeholder="xxx-xxx-xxxx"
                />
                      <Form.Control.Feedback type="invalid">
                  {PhoneNo === ''
                    ? ' Phone no  is required.'
                    : 'Phone no  is invalid.'}
                </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {PhoneNo
                          ? ' Phone number or email is invalid.'
                          : ' Phone number or email is required.'}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="filter-btn-wrapper pt-3">
                      <div className="invite-btns text-center p-0">
                        <Button type="submit">{'Add Contact'}</Button>
                      </div>
                    </div>

                    <input type="submit" value="Submit" onKeyDown={(e) => {if (e.key === 'Enter') handle(e)}} /> 
                  </Form>
                </div>
              </div>
            </>
          )} */}

          {subTabType === 'contact' && (
            <>
              <div className=" primary_panel_content">
                <div className="d-flex align-items-center justify-content-center w-100 ">
                  <div className="create-ticket-tabs w-100">
                    <CustomTabs
                      darkmode
                      tabs={ticketTabs}
                      active={tabType}
                      onSelect={item => setTabType(item)}
                    />
                  </div>
                </div>
                <Scrollbars autoHide>
                <div className="refferal-list-wrapper">
                  
                    {tabType === labels.qr_type_business[selected_lang] && (
                      <div className="send-refferal-form-wrapper  fixed-width-inputs pt-3 mt-2">
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handle}>
                          <div className="roi-form-input">
                            <Form.Label controlid="validationCustom01">
                              Company Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={firstName}
                              pattern="^\S.*$"
                              required
                              placeholder="Enter Company Name"
                              onChange={e => {
                                setFirstName(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Company Name is required.
                            </Form.Control.Feedback>
                          </div>

                          <div className="roi-form-dropdown mb-3">
                            <Form.Label controlid="validationCustom01">
                              Relationship
                            </Form.Label>
                            <div className="select-wrapper">
                              <Dropdown
                                onSelect={(eventKey, event) => {
                                  setRelationType(eventKey);
                                }}
                                drop="down">
                                <Dropdown.Toggle variant="primary">
                                  {relationType
                                    ? contactRelations.find(
                                        item => item.id === relationType,
                                      )?.relations
                                    : 'Select Role'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {contactRelations.map((item, index) => (
                                    <Dropdown.Item
                                      key={`st-${index}`}
                                      eventKey={item.id.toString()}
                                      active={item.id === relationType}>
                                      {item.relations}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <div className="roi-form-input">
                            <Form.Label controlid="validationCustom01">
                              POC Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={pocName}
                              pattern="^\S.*$"
                              // required
                              placeholder="Enter Name"
                              onChange={e => {
                                setPocName(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Name is required.
                            </Form.Control.Feedback>
                          </div>

                          <div className="roi-form-input">
                            <Form.Label controlid="validationCustom01">
                              POC Phone or Email
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={PhoneNo}
                              pattern="^\S.*$"
                              // required
                              placeholder="Enter a phone or email "
                              onChange={e => {
                                setPhonenNo(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              POC No is required.
                            </Form.Control.Feedback>
                          </div>

                          {/* <Form.Group className="fieldset">
                  <span className="use-my-contacts">Use My Contacts</span>
                </Form.Group> */}

                          {/* <div className="invite-persons-heading">
                    <span
                      className={
                        participantArray.length > 0
                          ? 'builder-text as-link'
                          : 'builder-text'
                      }
                      onClick={() => {
                        if (participantArray.length > 0) {
                          setShowComponent('SelectedUsers');
                        }
                      }}>
                      {participantArray.length} Selected
                    </span>
                  </div> */}

                          <Button
                            type="submit"
                            style={{display: 'none'}}
                            ref={buttonRef}>
                            {props.snapshotIds
                              ? 'Send Refferral'
                              : 'Add Contact'}
                          </Button>

                          {/* <input type="submit" value="Submit" onKeyDown={(e) => {if (e.key === 'Enter') handle(e)}} />  */}
                        </Form>
                      </div>
                    )}

                    {tabType === labels.type_individual[selected_lang] && (
                      <div className="send-refferal-form-wrapper fixed-width-inputs pt-3 mt-2">
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handle}>
                          <div className="roi-form-input">
                            <Form.Label controlid="validationCustom01">
                              Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={firstName}
                              pattern="^\S.*$"
                              required
                              placeholder="Enter Name"
                              onChange={e => {
                                setFirstName(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Name is required.
                            </Form.Control.Feedback>
                          </div>

                          <div className="roi-form-dropdown mb-3">
                            <Form.Label controlid="validationCustom01">
                              Relationship
                            </Form.Label>
                            <div className="select-wrapper">
                              <Dropdown
                                onSelect={(eventKey, event) => {
                                  setRelationType(eventKey);
                                }}
                                drop="down">
                                <Dropdown.Toggle variant="primary">
                                  {relationType
                                    ? contactRelations.find(
                                        item => item.id === relationType,
                                      )?.relations
                                    : 'Select Role'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {contactRelations.map((item, index) => (
                                    <Dropdown.Item
                                      key={`st-${index}`}
                                      eventKey={item.id.toString()}
                                      active={item.id === relationType}>
                                      {item.relations}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <div className="roi-form-input">
                            <Form.Label controlid="validationCustom01">
                              Phone or Email
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={PhoneNo}
                              pattern="^\S.*$"
                              // required
                              placeholder="Enter a phone or email "
                              onChange={e => {
                                setPhonenNo(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Phone or Email is required.
                            </Form.Control.Feedback>
                          </div>

                          {/* <Form.Group className="fieldset">
                  <span className="use-my-contacts">Use My Contacts</span>
                </Form.Group> */}

                          {/* <div className="invite-persons-heading">
                    <span
                      className={
                        participantArray.length > 0
                          ? 'builder-text as-link'
                          : 'builder-text'
                      }
                      onClick={() => {
                        if (participantArray.length > 0) {
                          setShowComponent('SelectedUsers');
                        }
                      }}>
                      {participantArray.length} Selected
                    </span>
                  </div> */}

                          <Button
                            type="submit"
                            style={{display: 'none'}}
                            ref={buttonRef}>
                            {props.snapshotIds
                              ? 'Send Refferral'
                              : 'Add Contact'}
                          </Button>

                          {/* <input type="submit" value="Submit" onKeyDown={(e) => {if (e.key === 'Enter') handle(e)}} />  */}
                        </Form>
                      </div>
                    )}
                 
                </div>
                </Scrollbars>

                {!showSpinner && (
                  <div className="filter-btn-wrapper">
                    <div className="invite-btns  p-0 pt-3 text-center">
                      <Button
                        type="submit"
                        onClick={() => {
                          buttonRef.current.click();
                        }}>
                        {props.snapshotIds ? 'Send Refferral' : 'Add Contact'}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </SlidingPanel>

      {newUserId && showComponent === 'profileComponent' && (
        // <SlidingPanel
        //   hidePanel={() => {
        //     setShowComponent('');
        //   }}>
        <ProfileComponent
          userId={newUserId}
          show={false}
          onClose={() => {
            setShowComponent('');
          }}
          closeModal={() => {
            setShowComponent('');
            newUserId('');
          }}
          // hideCloseButton={true}
          button={button}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
        // </SlidingPanel>
      )}

      {showComponent === 'inviteUser' && (
        <SendInvitation
          contact={contact}
          setContact={setContact}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {filterButton && (
        <RefferalSearchFilter
          show={filterButton}
          onClose={() => setFilterButton(false)}
          subTabType={''}
          builderData={builderData}
          arrowButton={''}
          isCheckAll={isCheckAll}
          setIsCheckAll={setIsCheckAll}
          isCheck={isCheckSF}
          isCheckArea={isCheck}
          setIsCheckArea={setIsCheck}
          setIsCheck={setIsCheckSF}
          list={list}
          setList={setList}
          locationS={locationS}
          setLocationS={setLocationS}
          appointmentId={appointmentId}
          setAppointmentId={setAppointmentId}
          categoriesId={categoriesId}
          setCategoriesId={setCategoriesId}
          setActive={setActive}
          active={active}
          setFocusLocations={setFocusLocations}
          setIdentiferId={setIdentiferId}
          setEthnicityId={setEthnicityId}
          setGender={setGender}
          gender={gender}
          focusLocations={focusLocations}
          ethnicityId={ethnicityId}
          identiferId={identiferId}
          setLongitude={setLongitude}
          longitude={longitude}
          setLatitude={setLatitude}
          latitude={latitude}
          setPersnalTypesId={setPersnalTypesId}
          setCategoriesId_Focus={setCategoriesId_Focus}
          setAppointmentId_Focus={setAppointmentId_Focus}
          setIdenntifier_Filter={setIdenntifier_Filter}
          setAreas_Filter={setAreas_Filter}
          idenntifier_Filter={idenntifier_Filter}
          areas_Filter={areas_Filter}
          persnalTypesId={persnalTypesId}
          appointmentId_Focus={appointmentId_Focus}
          categoriesId_Focus={categoriesId_Focus}
        />
      )}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(AllBuilders);
