import React, {useState, useEffect, useRef} from 'react';
import {Button, Form} from 'react-bootstrap';

import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import Sidebar from '../Sidebar';
import commonApi from '../../Services/CommonService';
import Moment from 'react-moment';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import MessageSearchFilter from '../../Components/Modals/MessagesSearchFilter';
import {Scrollbars} from 'react-custom-scrollbars';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import CreateSession from '../../Components/SessionDetails/CreateSession';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import CreateGroup from '../../Components/Messages/CreateGroup';
import OtherUserImages from '../../Components/Common/OtherUserImages';
import {CustomSlidingButtons, DiscoverBuilders, Pannels} from '../../Components';
import OpportunityScreen from '../../Components/FrontScreens/OpportunityScreen';
import {useSelector} from 'react-redux';
import {
  chatroomInfo,
  filteringUsers,
  arrayColumn,
  formatString,
  checkPermissions,
} from '../../Utils/commonUtils';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
// import {NoConvos, NoMessages} from '../../Constants/Images';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {fixName} from '../../Utils/commonUtils';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import ScrollLoader from '../../Components/Modals/ScrollLoader';
import Slider from 'react-slick';
import {rewardStar} from '../../Constants/Images';
import AppointmentTabs from '../../Components/AppointmentPage/AppointmentTabs';
import SuggestionsListing from './StaticPages/SuggestionsListing';
import CallScreenModal from '../../Components/Modals/CallScreenModal';
import SendInvitation from './StaticPages/SendInvitation';
import AllBuilders from './StaticPages/AllBuilders';
const Messages = props => {
  const [secondComponent, setSecondComponent] = useState('');
  const [chatDetails, setChatDetails] = useState([]);
  const [chatDetailItem, setChatDetailItem] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [chatPropType, setChatPropType] = useState('');

  const [tabType, setTabType] = useState('chats');
  const [liveChatTab, setLiveChatTab] = useState('active');
  //   const [invites, setInvites] = useState([]);
  const [page, setPage] = useState(1);
  const [chatList, setChatList] = useState([]);
  const [convosData, setConvosData] = useState([]);
  const [liveChatData, setLiveChatData] = useState([]);
  const [queueliveChatData, setQueueLiveChatData] = useState([]);
  const [closedChatData, setClosedChatData] = useState([]);
  // const [userInfo, setUserInfo] = useState([]);

  const [showSpinner, setShowSpinner] = useState(true);
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [groupChat, setGroupChat] = useState('');
  const [chatSearchQry, setChatSearchQry] = useState('');
  const [liveChatQry, setLiveChatQry] = useState('');
  // const [showComponent, setShowComponent] = useState('');
  const [filter, setFilter] = useState({
    event: true,
    opportunity: true,
    personal: true,
    session: true,
    question: true,
  });
  // const [show, setShow] = useState(false);
  const [threeDots, setThreeDots] = useState(false);
  const [liveChatDots, setLiveChatDots] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [allDataOfItemSession, setAllDataOfItem] = useState([]);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [liveChatToggle, setLiveChatToggle] = useState(false);
  const [smsToggle, setSmsToggle] = useState(false);
  const [liveChatType, setLiveChatType] = useState('accepted');
  const [buildersUserId, setBuildersUserId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [callModal, setCallModal] = useState(false);
  const [showComponent, setShowComponent] = useState('Recent');
  const [showBuildersComponent, setShowBuildersComponent] = useState('');



  const [subTabType, setSubTabType] = useState('Convos');

  console.log(subTabType, 'subTabTypesubTabType')

  const [isLoading, setIsLoading] = useState(false);
  const isFetching = useRef(false);
  const hasMoreData = useRef(false);

  const locationWeb = useLocation();
  const history = useHistory();
  const {id} = useParams();

  const {pathname} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const currentURL = window.location.href;

  // Create a URL object from the current URL
  const url = new URL(currentURL);

  // Get the 'id' parameter value
  const groupId = url.searchParams.get('id');

  const hitApi = useSelector(state => state.chatListing);
  const menuPermesions = useSelector(state => state.menuPermesionList);
  const menuItems = useSelector(state => state.menuActiveList);

  const ticketTabs = [
    labels.SMS[selected_lang],
    labels.business_type_messages[selected_lang],
    labels.business_type_LiveChat[selected_lang],
  
  ];


  
  const check_users_in_company = searchQuery => {
    const payload = {search_query: searchQuery};

    commonApi
      .check_users_in_company(payload)
      .then(res => {
        setSuggestions(res?.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };


  const handleSuggestionsClick = (item, cleanedPhoneNumber)=>{
    setPhoneNumber(cleanedPhoneNumber);
    setSuggestions([])
  }

  const TabList = [
    {id: 'Recent', title: 'Recent'},
    {id: 'All', title: 'All'},
    {id: 'Customers', title: 'Customers'},
    {id: 'Recordings', title: 'Recordings'},
  ];


  
  const handleType = e => {
    const {id, value} = e.target;
    setShowComponent(value);
  };

  const handleChange = e => {
    const value = e.target.value;
    // Check if the input value is a number and has at most 15 digits
    // if (/^[\d+#*]{0,15}$/.test(value)) {
      setPhoneNumber(value);
      check_users_in_company(value);
    // }
  };

  // const handleClose = () => setShow(false);

  // const closeChatRoom = () => {
  //   var payLoad = {
  //     chat_room_id: groupChat.id,
  //   };
  //   commonApi
  //     .close_chat_room(payLoad)
  //     .then(res => {
  //       if (res.status === 200) {
  //         successMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const setGalleryType = type => {
  //   if (tabType !== type) {
  //     setShowSpinner(true);
  //   }
  //   setTabType(type);
  //   //  getInvites(tabType);
  //
  //   // setShowSpinner(true);
  //   // getConservation(tabType);
  // };

  const checkNewUsersInGroup = id => {
    var data = {group_id: id};
    commonApi
      .check_new_users_in_group(data)
      .then(res => {
        if (res.group_chat_detail) {
          setChatDetails(res.group_chat_detail);
          setAllChatUsers(res.group_chat_detail.all_users);
          setChatRoomName(res.group_chat_detail.chat_room_name);
          setChatRoomId(res.group_chat_detail.id);
          // const chatItem = res.group_chat_detail;
          // chatItem.id = res.group_chat_detail.type_id;
          // setChatDetailItem(chatItem);
          setSecondComponent('chat-component');
          // setShowUserInfo(true);
        } else {
          errorMessage(labels.group_chat_Not_found[selected_lang]);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const getUserData = id => {
  //   var data = {user_id: id};
  //   commonApi
  //     .user_info(data)
  //     .then(res => {
  //       if (res) {
  //         setUserInfo(res.data);
  //         // setShowUserInfo(true);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const getConservation = (msgType, sQry, types, pageN) => {
    // setShowSpinner(true);

    setTimeout(() => {
      setShowSpinner(false);
    }, 1000);
    if (hasMoreData.current || isFetching.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }
    // setIsFetching(true);
    isFetching.current = !isFetching.current;

    var data = {
      filter: types,
      page: pageN,
      search_query: sQry,
      status: msgType,
    };
    commonApi
      .get_conversation(data)
      .then(res => {
        if (res.status === 200) {
          if (pageN > 1) {
            if (res.all_data.length > 0) {
              setPage(pageN);
              setTimeout(() => {
                setConvosData([...convosData, ...res.all_data]);
              }, 1000);
            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            setConvosData(res.all_data);
          }
        }

        // setShowSpinner(false);
        // setIsFetching(false);
        // setIsLoading(true);

        setTimeout(() => {
          isFetching.current = !isFetching.current;
          // setIsFetching(false);
          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getInvites = (msgType, sQry, types, pageN) => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 1000);
    if (hasMoreData.current || isFetching.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }
    // setIsFetching(true);
    isFetching.current = !isFetching.current;

    var data = {
      filter: types,
      page: pageN,
      search_query: sQry,
      status: msgType,
    };
    commonApi
      .get_conversation(data)
      .then(res => {
        if (res.status === 200) {
          if (pageN > 1) {
            if (res.all_data.length > 0) {
              setPage(pageN);
              setTimeout(() => {
                setChatList([...chatList, ...res.all_data]);
              }, 1000);
            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            setChatList(res.all_data);
          }
        }

        // setIsFetching(false);
        // setIsLoading(true);

        setTimeout(() => {
          isFetching.current = !isFetching.current;
          // setIsFetching(false);
          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const manageOppoChatInvitation = (id, o_id, status) => {
    if (status === 'rejected') {
      setChatList(prev => prev.filter(item => item.id !== id));
    }

    let payload = {
      // chat_room_id: id,
      // chat_status: type,
      // status: status,
      opportunity_id: o_id,
      chat_id: id,
      status: status,
    };
    commonApi
      .accept_opportunity_invitation(payload)
      .then(response => {
        if (response) {
          successMessage(response.message);
          // getConservation(tabType, chatSearchQry, filter, 1);
          if (status === 'accepted') {
            amplitudeEvent('ACCEPT_CHAT_INVITE');
          } else {
            amplitudeEvent('REJECT_CHAT_INVITE');
          }
        }

        setShowSpinner(false);
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };
  const manageChatInvitation = (id, type, status) => {
    setChatList(prev => prev.filter(item => item.id !== id));
    let payload = {
      chat_room_id: id,
      chat_status: type,
      status: status,
    };
    commonApi
      .manage_chat_invitation(payload)
      .then(response => {
        if (response) {
          successMessage(response.message);

          if (liveChatToggle) {
            setLiveChatTab('active');
          }

          // getConservation(tabType, chatSearchQry, filter, 1);
          if (status === 'accepted') {
            amplitudeEvent('ACCEPT_CHAT_INVITE');
          } else {
            amplitudeEvent('REJECT_CHAT_INVITE');
          }
        }

        setShowSpinner(false);
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const collectingUsers = item => {
    const creator = item.hasOwnProperty('creator_details')
      ? 'creator_details'
      : 'creator_detail';
    const moderator =
      item.chat_type === 'opportunity'
        ? 'team_member_details'
        : 'moderator_detail';
    const audience =
      item.chat_type === 'opportunity'
        ? 'other_persons_opportunity_chat_room'
        : 'audience_detail';

    const chatUsers = [
      {
        id: item[creator]['id'],
        first_name: item[creator]['first_name'],
        last_name: item[creator]['last_name'],
        profile_img: item[creator]['profile_img'],
        type: 'creator',
      },
    ];
    if (item[audience].length > 0) {
      item[audience].forEach(obj => {
        if (
          !item.hasOwnProperty('group_detail_ids') ||
          item.group_detail_ids.includes(obj.id)
        ) {
          obj.type = 'audience';
          chatUsers.push(obj);
        }
      });
    }
    if (item[moderator].length > 0) {
      item[moderator].forEach(obj => {
        if (
          !item.hasOwnProperty('group_detail_ids') ||
          item.group_detail_ids.includes(obj.id)
        ) {
          obj.type = 'moderator';
          chatUsers.push(obj);
        }
      });
    }
    return chatUsers;
  };

  const handleOpenChatRoom = chatDetail => {
    if (liveChatToggle && liveChatTab === 'queue') {
      setQueueLiveChatData(prev =>
        prev.filter(item => item.id !== chatDetail.id),
      );
      setLiveChatType('accepted');
    } else {
      setChatList(prev => prev.filter(item => item.id !== chatDetail.id));
    }

    if (
      (tabType === 'chats' && !liveChatToggle) ||
      (liveChatTab === 'active' && liveChatToggle)
    ) {
      setChatDetails(chatDetail);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_room_name);
      setChatRoomId(chatDetail.chat_id);

      if (chatDetail.chat_type === 'opportunity') {
        if (chatDetail.creator_id === localStorage.getItem('id')) {
          setChatPropType('mine-opportunity');
        } else if (chatDetail.team_member_ids) {
          const myArray = chatDetail.team_member_ids.split(',');
          if (myArray.includes(localStorage.getItem('id'))) {
            setChatPropType('mine-opportunity');
          } else {
            setChatPropType('other-opportunity');
          }
        } else {
          setChatPropType('other-opportunity');
        }
      } else {
        if (
          chatDetail.chat_type === 'poc_team' ||
          chatDetail.chat_type === 'poc_customer'
        ) {
          setChatPropType('Poc_chat');
        } else {
          if (chatDetail.chat_room_type === 'group') {
            setChatPropType('personal-group');
          } else {
            setChatPropType('personal-single');
          }
        }
      }

      const chatItem = chatDetail;
      chatItem.id = chatDetail.type_id;
      setChatDetailItem(chatItem);

      setSecondComponent('chat-component');
    } else if (tabType === 'subInvites' || liveChatTab === 'queue') {
      if (chatDetail.chat_type === 'opportunity') {
        manageOppoChatInvitation(
          chatDetail.id,
          chatDetail.opportunity_id,
          'accepted',
        );
      } else {
        manageChatInvitation(
          chatDetail.id,
          chatDetail.invitation_type,
          'accepted',
        );
      }

      setChatDetails(chatDetail);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_room_name);
      setChatRoomId(chatDetail.chat_id);

      // const chatItem = chatDetail;
      // chatItem.id = chatDetail.type_id;
      // setChatDetailItem(chatItem);

      if (chatDetail.chat_type === 'opportunity') {
        setChatPropType('other-opportunity');
      } else {
        if (chatDetail.chat_room_type === 'group') {
          setChatPropType('personal-group');
        } else {
          setChatPropType('personal-single');
        }
      }

      setTimeout(() => {
        setSecondComponent('chat-component');
      }, 100);
    } else if (liveChatTab === 'closed') {
      setChatDetails(chatDetail);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_room_name);
      setChatRoomId(chatDetail.chat_id);

      if (chatDetail.chat_type === 'opportunity') {
        setChatPropType('other-opportunity');
      } else {
        if (chatDetail.chat_room_type === 'group') {
          setChatPropType('personal-group');
        } else {
          setChatPropType('personal-single');
        }
      }

      setTimeout(() => {
        setSecondComponent('chat-component');
      }, 100);
    }
  };

  const handleCommonChat = (data, type) => {
    if (type === 'edit-group-component') {
      setGroupChat(data);
      if (data.chat_room_type === 'single') {
        setSecondComponent('group-component');
      } else {
        setSecondComponent(type);
      }
    } else if (type === 'create-session-component') {
      setAllDataOfItem(data);
      setSecondComponent(type);
    }
  };

  const checkNewUsersInOpportunity = id => {
    let data = {chat_id: id};
    commonApi
      .check_opportunity_new_user(data)
      .then(res => {
        if (res) {
          // const {pathname} = locationWeb;
          res.group_detail.chat_type = 'opportunity';
          // res.group_detail.opportunity_id = res.group_detail.opportunity_id;
          res.chat_type = 'opportunity';
          res.status = res.group_detail.status;
          res.id = res.group_detail.opportunity_id;
          let data = res;

          if (res.group_detail.creator_id === localStorage.getItem('id')) {
            setChatPropType('mine-opportunity');
          } else if (res.team_member_ids) {
            const myArray = res.team_member_ids.split(',');
            if (myArray.includes(localStorage.getItem('id'))) {
              setChatPropType('mine-opportunity');
            } else {
              setChatPropType('other-opportunity');
            }
          } else {
            setChatPropType('other-opportunity');
          }

          setChatDetails(data);
          setAllChatUsers(collectingUsers(res));
          setChatRoomName(res.group_detail.chat_room_name);
          setChatRoomId(res.group_detail.id);
          setSecondComponent('chat-component');

          // history.replace({pathname: pathname, key: Date.now()});
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleNotificationDirection = () => {
    const {pathname, state} = locationWeb;

    if (state && state.typeIs === 'chats') {
      setChatDetails(state.chatDetail);
      setAllChatUsers(collectingUsers(state.chatDetail));
      setChatRoomName(state.chatDetail.chat_room_name);
      setChatRoomId(state.chatDetail.id);
      setSecondComponent('chat-component');

      if (state.chatDetail.type === 'question') {
        manageChatInvitation(state.chatDetail.id, 'group_chat', 'accepted');
      }

      history.replace({pathname: pathname, key: Date.now()});
    }
    if (state && state.typeIs === 'opportunityMessages') {
      state.chatDetail.chat_type = 'opportunity';
      state.chatDetail.opportunity_id = state.chatDetail.id;
      state.chatDetail.chat_detail.chat_type = 'opportunity';
      for (let x in state.chatDetail.chat_detail) {
        if (!state.chatDetail[x]) {
          state.chatDetail[x] = state.chatDetail.chat_detail[x];
        }
      }
      let data = state.chatDetail;
      data.id = state.chatDetail.chat_detail.id;

      if (state.chatDetail.created_by === localStorage.getItem('id')) {
        setChatPropType('mine-opportunity');
      } else if (state.chatDetail.team_member_ids) {
        const myArray = state.chatDetail.team_member_ids.split(',');
        if (myArray.includes(localStorage.getItem('id'))) {
          setChatPropType('mine-opportunity');
        } else {
          setChatPropType('other-opportunity');
        }
      } else {
        setChatPropType('other-opportunity');
      }

      setChatDetails(data);
      setAllChatUsers(collectingUsers(state.chatDetail));
      setChatRoomName(state.chatDetail.chat_detail.chat_room_name);
      setChatRoomId(state.chatDetail.chat_detail.id);
      setSecondComponent('chat-component');

      history.replace({pathname: pathname, key: Date.now()});
    }

    if (state && state.typeIs === 'subInvites') {
      // setGalleryType('subInvites');
      setTabType('subInvites');
      amplitudeEvent('CHECK_CHAT_INVITES');
      history.replace({pathname: pathname});
    }
    if (state && state.typeIs === 'opportunity_call') {
      checkNewUsersInOpportunity(state.chat_room_id);
    }
    if (state && state.typeIs === 'opportunity_emoji') {
      checkNewUsersInOpportunity(state.id);
    }
    if (state && state.typeIs === 'emoji') {
      checkNewUsersInGroup(state.id);
    }
    if (state && state.typeIs === 'create-group') {
      setSecondComponent('group-component');
    }
  };

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        getBackgroundData();
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackgroundData = () => {
    const DBOpenRequest = window.indexedDB.open('Build', 4);

    DBOpenRequest.onsuccess = event => {
      const db = DBOpenRequest.result;

      getData(db);
    };

    function getData(db) {
      const transaction = db.transaction(['Table1'], 'readwrite');

      transaction.oncomplete = event => {};

      transaction.onerror = event => {
        console.log(transaction.error, 'transaction error');
      };

      const objectStore = transaction.objectStore('Table1');

      const objectStoreRequest = objectStore.get('1');

      objectStoreRequest.onsuccess = event => {
        const myRecord = objectStoreRequest.result;

        var Payload = myRecord.value;
        let type;

        if (
          [
            'personalMessage',
            'sessionChatInvitation',
            'personalChatAccept',
          ].includes(Payload.type)
        ) {
          type = 'chats';
        } else if (
          ['personalChatInvitation', 'opportunityChatInvitation'].includes(
            Payload.type,
          )
        ) {
          type = 'subInvites';
        } else if (['opportunityMessage'].includes(Payload.type)) {
          type = 'opportunityMessages';
        } else if (['opportunity_call'].includes(Payload.type)) {
          type = Payload.type;
        } else if (['emoji', 'opportunity_emoji'].includes(Payload.type)) {
          type = Payload.type;
        }

        handleBackgroundNotification(type, Payload);

        setTimeout(() => {
          deleteDataFromIndexDb(db);
        }, 3000);
      };
    }
  };

  const deleteDataFromIndexDb = db => {
    let transaction = db.transaction(['Table1'], 'readwrite');
    transaction.objectStore('Table1').delete('1');

    transaction.oncomplete = () => {};
  };
  const handleBackgroundNotification = (type, Payload) => {
    if (type === 'chats') {
      const chatDetail = Payload.chat_detail
        ? Payload.chat_detail
        : Payload.group_chat_detail;

      setChatDetails(chatDetail);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_room_name);
      setChatRoomId(chatDetail.id);
      setSecondComponent('chat-component');

      if (type === 'question') {
        manageChatInvitation(Payload.chatDetail.id, 'group_chat', 'accepted');
      }
    }
    if (type === 'opportunityMessages') {
      const chatDetail = Payload.opportunity_detail;

      chatDetail.chat_type = 'opportunity';
      chatDetail.opportunity_id = chatDetail.id;
      chatDetail.chat_detail.chat_type = 'opportunity';
      for (let x in chatDetail.chat_detail) {
        if (!chatDetail[x]) {
          chatDetail[x] = chatDetail.chat_detail[x];
        }
      }
      let data = chatDetail;
      data.id = chatDetail.chat_detail.id;

      if (chatDetail.created_by === localStorage.getItem('id')) {
        setChatPropType('mine-opportunity');
      } else if (chatDetail.team_member_ids) {
        const myArray = chatDetail.team_member_ids.split(',');
        if (myArray.includes(localStorage.getItem('id'))) {
          setChatPropType('mine-opportunity');
        } else {
          setChatPropType('other-opportunity');
        }
      } else {
        setChatPropType('other-opportunity');
      }

      setChatDetails(data);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_detail.chat_room_name);
      setChatRoomId(chatDetail.chat_detail.id);
      setSecondComponent('chat-component');
    }

    if (type === 'subInvites') {
      // setGalleryType('subInvites');
      setTabType('subInvites');
      amplitudeEvent('CHECK_CHAT_INVITES');
      // history.replace({pathname: pathname});
    }
    if (type === 'opportunity_call') {
      checkNewUsersInOpportunity(Payload.chat_room_id);
    }
    if (type === 'opportunity_emoji') {
      checkNewUsersInOpportunity(Payload.type_id);
    }
    if (type === 'emoji') {
      checkNewUsersInGroup(Payload.type_id);
    }
    if (type === 'create-group') {
      setSecondComponent('group-component');
    }
  };

  useEffect(() => {
    amplitudeEvent('VISIT_MESSAGES');
    // getTabstatus();

    handleNotificationDirection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groupId && urlParts[2] === 'group-chat') {
      checkNewUsersInGroup(groupId);
    }
  }, []);

  useEffect(() => {
    // alert('daniyal')
    if (tabType === 'chats' && !liveChatToggle) {
      getConservation(tabType, chatSearchQry, filter, 1);
    } else if (!liveChatToggle) {
      getInvites(tabType, chatSearchQry, filter, 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabType, chatSearchQry, filter, secondComponent, hitApi, liveChatToggle]);

  useEffect(() => {
    if (secondComponent !== '' && secondComponent !== 'firstVist') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [secondComponent]);

  useEffect(() => {
    if (liveChatToggle) {
      getLiveChatData();
    }
  }, [liveChatToggle, liveChatTab, liveChatQry, hitApi]);

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + scrollTop >= scrollHeight) {
      // Increment the page number and call the API

      getConservation(tabType, chatSearchQry, filter, page + 1);
    }
  };

  useEffect(() => {
    if (workspaceData.length > 0) {
      setTimeout(() => {
        getConservation(tabType, chatSearchQry, filter, 1);
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  const getTabstatus = () => {
    commonApi
      .get_tab_status()
      .then(res => {
        if (res.status === 200) {
          // setSecondComponent('firstVist');
          const tabStatus = res.tab_status;
          if (tabStatus && tabStatus.length === 0) {
            setSecondComponent('firstVist');
          } else {
            const found = tabStatus.find(element => element.tab === 'chats');
            if (typeof found === 'object') {
            } else {
              setSecondComponent('firstVist');
            }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getLiveChatData = () => {
    var payload = {
      status: liveChatType,
      search_query: liveChatQry,
    };
    commonApi
      .get_live_chatrooms(payload)
      .then(res => {
        if (res) {
          if (liveChatType === 'accepted') {
            setLiveChatData(res.conversations);
          } else if (liveChatType === 'pending') {
            setQueueLiveChatData(res.conversations);
          } else if (liveChatType === 'closed') {
            setClosedChatData(res.conversations);
          }
          if (liveChatToggle) {
            setTimeout(() => {
              setShowSpinner(false);
            }, 300);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'Response time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Messages',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Frequency',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 15px',
    // Add other styles as needed
  };

  const MessagesPermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Messages');

  console.log(menuPermesions, 'LiveChatPermessions');

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuPermesions]);

  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);

      if (
        Object.keys(workspaceData).length > 0 &&
        urlPart.length === 1 &&
        secondComponent !== ''
      ) {
       
        setSecondComponent('');
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 1000);
  }, [workspaceData]);


  const buildersHandle = (t, id, item) => {
    if (t === 'profileComponent') {
      if (item?.phone) {
        const cleanedPhoneNumber = item?.phone?.replace(/"/g, '');
        const onlyNumnbers = /^[\d+#*]{0,15}$/.test(cleanedPhoneNumber);
  
        if (cleanedPhoneNumber && onlyNumnbers) {
          setPhoneNumber(cleanedPhoneNumber);
          // check_users_in_company(cleanedPhoneNumber)
          setShowComponent('Dialler');
        } else {
          setPhoneNumber('');
          errorMessage('User not contain number ');
        }
      } else {
        setPhoneNumber('');
        errorMessage('User not contain number ');
      }
    } else {
      setBuildersUserId(id);
      setShowBuildersComponent(t);
    }  
  };


  
  const handleCall = () => {
    // Add logic for making a call with the phone number
    if (phoneNumber && phoneNumber !== '') {
      setCallModal(true);
    }
  };



  
  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(null);

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.offsetWidth);
    }
  }, [inputRef.current]);



  console.log(id, '======> id');
  return (
    //{/* {showLoader && <LoaderModal show={showLoader} />} */}
    <div className="page-wrapper">
      <Sidebar
        setWorkSpaceItem={setWorkspaceData}
        location={props.location}
        setShowComponent={setSecondComponent}
        showComponent={secondComponent}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          {/* <DiscoverBuilders   buildersHandle={buildersHandle} /> */}
          {MessagesPermessions &&
            (MessagesPermessions === 'allow' ||
              MessagesPermessions?.analytics_row === true) && (
              <>
                <div className="Reward_main analytics_slider_bg">
                  <span
                    className="analytics_slider_heading "
                    style={{color: analycticsSlider ? '' : '#999999'}}
                    onClick={() => {
                      setAnalycticsSlider(!analycticsSlider);
                    }}>
                    Analytics Row
                  </span>

                  {analycticsSlider && (
                    <div className="reward-top  mt-3 mb-2">
                      <Slider {...rewardSlider}>
                        {rewardSliderData.length > 0 &&
                          rewardSliderData.map((item, index) => (
                            <div className="reward-main">
                              <div className="reward-card">
                                <span
                                  style={{
                                    fontWeight: 700,
                                    fontSize: '13px',
                                  }}>
                                  {item.name}
                                </span>
                                <span
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <span style={{fontSize: '17px'}}>
                                    {item.money}
                                  </span>
                                  {item.name === 'Ratings' && (
                                    <img src={item.svgIcon} alt="" />
                                  )}
                                  {item.name === 'Conversion' ? (
                                    <i
                                      className="fa-solid fa-arrow-down"
                                      style={{color: '#BD4141'}}></i>
                                  ) : (
                                    <i
                                      class="fa-solid fa-arrow-up"
                                      style={{color: '#52bd41'}}></i>
                                  )}
                                </span>
                                <div class="message-date-info">
                                  <span
                                    style={{color: '#333'}}
                                    class="message-date">
                                    {item.time}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>
                  )}
                </div>
                <div className="add_border"></div>
              </>
            )}
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            customCss={dynamicStyle}
            primary={
              <>
                {/* <Scrollbars style={{ width: "100%", flex: 1 }}> */}{' '}
                {/* Merging */}
                {secondComponent === 'firstVist' ? (
                  <OpportunityScreen
                    onClose={() => {
                      setSecondComponent('');
                    }}
                    type={'chats'}
                    setSecondComponent={setSecondComponent}
                  />
                ) : (
                  <>
                    {/* <div className="Reward_main analytics_slider_bg">
                      <span
                        className="analytics_slider_heading "
                        style={{color: analycticsSlider ? '' : '#999999'}}
                        onClick={() => {
                          setAnalycticsSlider(!analycticsSlider);
                        }}>
                        Analytics Row
                      </span>

                      {analycticsSlider && (
                        <div className="reward-top  mt-3 mb-2">
                          <Slider {...rewardSlider}>
                            {rewardSliderData.length > 0 &&
                              rewardSliderData.map((item, index) => (
                                <div className="reward-main">
                                  <div className="reward-card">
                                    <span
                                      style={{
                                        fontWeight: 700,
                                        fontSize: '13px',
                                      }}>
                                      {item.name}
                                    </span>
                                    <span
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}>
                                      <span style={{fontSize: '17px'}}>
                                        {item.money}
                                      </span>
                                      {item.name === 'Ratings' && (
                                        <img src={item.svgIcon} alt="" />
                                      )}
                                      {item.name === 'Conversion' ? (
                                        <i
                                          className="fa-solid fa-arrow-down"
                                          style={{color: '#BD4141'}}></i>
                                      ) : (
                                        <i
                                          class="fa-solid fa-arrow-up"
                                          style={{color: '#52bd41'}}></i>
                                      )}
                                    </span>
                                    <div class="message-date-info">
                                      <span
                                        style={{color: '#333'}}
                                        class="message-date">
                                        {item.time}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>
                      )}
                    </div> */}

                    <div className="primary_panel_wraper h-100">
                      <div className="sessions-content-wrapper">
                        {MessagesPermessions &&
                          (MessagesPermessions === 'allow' ||
                            MessagesPermessions?.live_chat === true) && (
                            <div className="  show-on-public ">
                              <div className="d-flex align-items-center justify-content-center ">
                                {/* <p className="p-reg m-0">Show on Market</p> */}

                                {/* <div className="d-flex  align-items-center justify-content-center live_chat_toogle_d">
                                  <span>Live Chat</span>

                                  <div className="live_chat_toogle">
                                    <Form.Check
                                      type="switch"
                                      className="switchBlock"
                                      id="custom-switch"
                                      value={liveChatToggle}
                                      checked={liveChatToggle}
                                      onClick={() => {

                                        setLiveChatQry('')
                                        liveChatToggle &&
                                          setLiveChatTab('active');
                                          
                                        setLiveChatToggle(!liveChatToggle);
                                        setChatSearchQry('')

                                        setShowSpinner(true);
                                      }}
                                    />
                                  </div>
                                </div> */}

                                <div className="d-flex align-items-center justify-content-center w-100 three-buttons">
                                  <div className="create-ticket-tabs w-100">
                                    <AppointmentTabs
                                      darkmode
                                      tabs={ticketTabs}
                                      active={subTabType}
                                      onSelect={item => {
                                        setSubTabType(item);

                                        console.log(item, 'itemitemitemitem')
                                        if (item === 'Convos') {
                                          setLiveChatToggle(false);
                                          setSmsToggle(false);
                                        } else if(item === 'SMS'){
                                          setSmsToggle(true)
                                        } else {
                                          setLiveChatToggle(true);
                                          setSmsToggle(false);
                                        }

                                        setLiveChatQry('');
                                        liveChatToggle &&
                                          setLiveChatTab('active');

                                        setChatSearchQry('');

                                        setShowSpinner(true);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        {!liveChatToggle && !smsToggle && (
                          <div className="messages-header">
                            {/* <div className="event-tabs dark-tabs"> */}
                            <div className="event-tabs tabs-style-2 mb-0">
                              {' '}
                              {/* Merging */}
                              <ul>
                                <li>
                                  <Button
                                    className={
                                      tabType === 'chats' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      // setGalleryType('chats');
                                      setTabType('chats');
                                      setChatList([]);
                                      setShowSpinner(true);
                                    }}>
                                    {labels.message_type_convos[selected_lang]}
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    className={
                                      tabType === 'subInvites' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      // setGalleryType('subInvites');

                                      setShowSpinner(true);
                                      setTabType('subInvites');
                                      setConvosData();
                                      amplitudeEvent('CHECK_CHAT_INVITES');
                                    }}>
                                    {
                                      labels.sessions_type_invites[
                                        selected_lang
                                      ]
                                    }
                                  </Button>
                                </li>
                              </ul>
                            </div>

                            {/* <span className="add-icon">
                          <i
                            className="icon-plus"
                            onClick={() => setSecondComponent("group-component")}
                          />
                        </span>
                        <span className="add-icon">
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </span> */}

                            <div className="search-tabs-icons">
                              <span
                                className={
                                  secondComponent === 'group-component'
                                    ? 'icon active'
                                    : 'icon'
                                }>
                                {MessagesPermessions &&
                                  (MessagesPermessions === 'allow' ||
                                    MessagesPermessions?.create === true) && (
                                    <i
                                      className="icon-plus"
                                      onClick={() =>
                                        setSecondComponent('group-component')
                                      }
                                    />
                                  )}
                              </span>

                              <span
                                onClick={() => {
                                  setThreeDots(!threeDots);
                                }}
                                className={
                                  threeDots
                                    ? 'icon active threeDots'
                                    : 'icon threeDots'
                                }>
                                <i className="fas fa-ellipsis-vertical" />
                              </span>
                            </div>
                          </div>
                        )}

                        {liveChatToggle && (
                          <div className="messages-header">
                            <div className="event-tabs tabs-style-2 mb-0">
                              {' '}
                              <ul>
                                <li>
                                  <Button
                                    className={
                                      liveChatTab === 'active' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      setShowSpinner(true);
                                      setLiveChatQry('');
                                      setLiveChatTab('active');
                                      setLiveChatType('accepted');
                                    }}>
                                    {labels.livechat_type_active[selected_lang]}
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    className={
                                      liveChatTab === 'queue' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      setLiveChatQry('');
                                      setLiveChatType('pending');
                                      setLiveChatTab('queue');
                                      setShowSpinner(true);
                                    }}>
                                    {labels.livechat_type_queue[selected_lang]}
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    className={
                                      liveChatTab === 'closed' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      setLiveChatQry('');
                                      setShowSpinner(true);
                                      setLiveChatTab('closed');
                                      setLiveChatType('closed');
                                    }}>
                                    {labels.livechat_type_closed[selected_lang]}
                                  </Button>
                                </li>
                              </ul>
                            </div>

                            <div className="search-tabs-icons">
                              {/* <span
                                className={
                                  

                                  'icon'
                                }>
                                
                                <i
                                  className="icon-plus"
                                  
                                />
                             
                              </span> */}

                              <span
                                onClick={() => {
                                  setLiveChatDots(!liveChatDots);
                                }}
                                className={
                                  liveChatDots
                                    ? 'icon active threeDots'
                                    : 'icon threeDots'
                                }>
                                <i className="fas fa-ellipsis-vertical" />
                              </span>
                            </div>
                          </div>
                        )}

                        {threeDots && !liveChatToggle && (
                          <div className="person-search-wrapper mb-0 messages-users-wrapper">
                            <div className="search-form d-flex">
                              <Form.Group className="search-input">
                                <Form.Control
                                  type="text"
                                  placeholder="Who are you looking for?"
                                  value={chatSearchQry}
                                  onChange={e => {
                                    setChatSearchQry(e.target.value);
                                  }}
                                />
                              </Form.Group>

                              <Button>
                                <i
                                  className="icon-filter-icon"
                                  onClick={() => setSearchFilterModal(true)}
                                />
                              </Button>
                            </div>
                          </div>
                        )}

                        {liveChatDots && liveChatToggle && (
                          <div className="person-search-wrapper mb-0 messages-users-wrapper">
                            <div className="search-form d-flex">
                              <Form.Group className="search-input">
                                <Form.Control
                                  type="text"
                                  placeholder="Who are you looking for?"
                                  value={liveChatQry}
                                  onChange={e => {
                                    setLiveChatQry(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        )}

                        {smsToggle &&
                       <>
                        <div className="messages-header">
                        <DiscoverBuilders
                        buildersHandle={buildersHandle}
                        buildersUserId={buildersUserId}
                        apiHit={workspaceData}
                        />
                        </div>
                        <div>
                        <div className="call-center-page">
                            <div className="messages-header">
                              <div className="tabs-style-2" style={{margin: '0px', width: '100%'}}>
                                <div className="phone-input" style={{justifyContent: 'space-between', width: '100%'}}>
                                  <div>
                                    <div className="search-tabs-wrapper" style={{margin: '0px'}}>
                                      <div className="search-form d-flex mt-0">
                                        <Form.Group className="search-input-location">
                                          <div className="form-control-with-icon phone_placeholder">
                                            <Form.Control
                                              ref={inputRef}
                                             style={{
                                              color:
                                                phoneNumber !== ''
                                                  ? ''
                                                  : '#AEAEAE',
                                              fontFamily: 'Inter',
                                              fontStyle: 'normal',
                                              fontWeight: '300',
                                              lineHeight: 'normal',
                                              fontSize: '15px',
                                              background: 'white',
                                              width: '310px',
                                              borderRadius: '5px',
                                              borderColor: '#eaeaea'
                                            }}
                                              value={phoneNumber}
                                              onChange={handleChange}
                                              maxLength={15}
                                              type="text"
                                              placeholder="Enter Phone Number"
                                            />
                                            {phoneNumber !== '' && (
                                              <span
                                                className="input-icon"
                                                style={{left: '90%'}}
                                                onClick={() => {
                                                  setPhoneNumber('');
                                                  setSuggestions([])
                                                }}>
                                                <i className="fas fa-close" />
                                              </span>
                                            )}
                                          </div>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div
                                      className="phone-call-icon"
                                      onClick={handleCall}>
                                      <i className="fa-solid icon-plane" style={{color: 'white', fontSize: '26px'}}></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>

                            <SuggestionsListing
                            listing={suggestions}
                            type='messages'
                            handleSuggestions={handleSuggestionsClick}
                            style={inputWidth} 
                            />
                            </div>

                    <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                    <CustomSlidingButtons
                      items={TabList}
                      activeButtons={showComponent}
                      onItemClick={e => {
                        handleType(e);
                      }}
                      showItems={3}
                      value={showComponent}
                    />
                  </div>

                            </>
                         }

                        {/* </div> */}
                        <div className="person-search-wrapper m-0 messages-users-wrapper h-100">
                          {/* <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Who are you looking for?"
                              value={chatSearchQry}
                              onChange={(e) => {
                                setChatSearchQry(e.target.value);
                              }}
                            />
                          </Form.Group>
                        </div> */}
                          <div className="refferal-list-wrapper">
                            <Scrollbars
                              
                              renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                              // Hide the vertical scrollbar
                              renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}
                              onScroll={handleScroll}
                              className="custom-scroll"
                              style={{
                                position: 'absolute',
                                width: '100%',
                                height: '-webkit-fill-available',
                              }}>
                              {!liveChatToggle && !smsToggle && (
                                <>
                                  {tabType === 'chats' && (
                                    <>
                                      {showSpinner && <LoaderSpinner />}
                                      {!showSpinner && (
                                        <>
                                          <div className="messages-users-list h-auto custom-messages-users-list-holder">
                                            {convosData?.length > 0 &&
                                            Object.entries(convosData).length >
                                              0 ? (
                                              Object.entries(convosData)
                                                .length > 0 &&
                                              convosData.map((item, index) => {
                                                // const newMessage = item.read ? '' : 'new-message';
                                                const newMessage =
                                                  chatRoomId === item.id ||
                                                  item.read
                                                    ? ''
                                                    : 'new-message';
                                                const isActive =
                                                  chatRoomId === item.id
                                                    ? 'active'
                                                    : '';
                                                const userIs =
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail';
                                                return (
                                                  <div
                                                    // onClick={() => {
                                                    //   // getUserData(item.user_id);
                                                    //   // checkNewUsersInGroup(item.id);
                                                    //   handleOpenChatRoom(item);
                                                    // }}
                                                    key={`msg-${tabType}-${index}`}
                                                    className={`message-user-item no-link likes-dislikes-msgs ${isActive} ${newMessage}`}>
                                                    <div
                                                      className="user-img as-link"
                                                      onClick={() => {
                                                        amplitudeEvent(
                                                          'OPEN_CHAT_ROOM',
                                                        );
                                                        if (
                                                          chatRoomId !==
                                                            item.id &&
                                                          !isActive
                                                        ) {
                                                          handleOpenChatRoom(
                                                            item,
                                                          );
                                                        }
                                                      }}>
                                                      <img
                                                        src={
                                                          tabType ===
                                                          'subInvites'
                                                            ? // !item.hasOwnProperty('read')
                                                              item[userIs][
                                                                'profile_img'
                                                              ]
                                                              ? item[userIs][
                                                                  'profile_img'
                                                                ]
                                                              : DEFAULT_IMAGE
                                                            : item.group_image !==
                                                              ''
                                                            ? item.group_image
                                                            : item[userIs][
                                                                'profile_img'
                                                              ]
                                                            ? item[userIs][
                                                                'profile_img'
                                                              ]
                                                            : DEFAULT_IMAGE
                                                        }
                                                        alt=""
                                                      />
                                                      {item.chat_type ===
                                                        'question' && (
                                                        <span>
                                                          <i className="fa-solid fa-q"></i>
                                                        </span>
                                                      )}
                                                      {item.chat_type ===
                                                        'opportunity' && (
                                                        <span className="icon icon-posts"></span>
                                                      )}
                                                      {item.chat_type ===
                                                        'event' && (
                                                        <span className="icon-tickets"></span>
                                                      )}
                                                      {item.chat_type ===
                                                        'session' && (
                                                        <span className="icon-video-camera-2"></span>
                                                      )}
                                                      {/* <span className="fa-solid fa-shop"></span> */}
                                                    </div>
                                                    <div
                                                      className="user-content as-link"
                                                      onClick={() => {
                                                        if (
                                                          chatRoomId !==
                                                            item.id &&
                                                          !isActive
                                                        ) {
                                                          handleOpenChatRoom(
                                                            item,
                                                          );
                                                        }
                                                      }}>
                                                      <h2 className="single-line-text">
                                                        {/* {tabType === 'subInvites' &&
            !item.hasOwnProperty('read') */}
                                                        {tabType ===
                                                        'subInvites'
                                                          ? fixName(
                                                              item[userIs][
                                                                'first_name'
                                                              ],
                                                              item[userIs][
                                                                'last_name'
                                                              ],
                                                            )
                                                          : item.group_name !==
                                                            ''
                                                          ? item.group_name
                                                          : fixName(
                                                              item[userIs][
                                                                'first_name'
                                                              ],
                                                              item[userIs][
                                                                'last_name'
                                                              ],
                                                            )}
                                                      </h2>

                                                      {/* className={
              tabType === 'subInvites'
                ? ''
                : 'single-line-text'
            } */}
                                                      {/* {tabType === 'subInvites' &&
            !item.hasOwnProperty('read') */}
                                                      {tabType === 'subInvites'
                                                        ? item.group_name !== ''
                                                          ? 'Just invited you to chat.'
                                                          : 'Just invited you to a group chat.'
                                                        : formatString(
                                                            item.last_message,
                                                          )}

                                                      <div className="message-date-info">
                                                        <span className="message-date">
                                                          <Moment format="MM/DD/YY HH:mm A">
                                                            {item.last_message_time
                                                              ? item.last_message_time
                                                              : item.created_at}
                                                          </Moment>
                                                        </span>
                                                        {/* {renderMessageUsers(item.moreUsers)} */}
                                                      </div>
                                                    </div>
                                                    {item.hasOwnProperty(
                                                      'opportunity_invitaion_status',
                                                    ) &&
                                                      tabType ===
                                                        'subInvites' && (
                                                        <div className="stage-likes-icons">
                                                          <span
                                                            className="as-link"
                                                            onClick={e => {
                                                              e.stopPropagation();
                                                              if (
                                                                chatRoomId !==
                                                                  item.id &&
                                                                !isActive
                                                              ) {
                                                                handleOpenChatRoom(
                                                                  item,
                                                                );
                                                              }
                                                            }}>
                                                            <i className="icon-like-icon" />
                                                          </span>
                                                          <span
                                                            className="as-link"
                                                            onClick={e => {
                                                              e.stopPropagation();
                                                              manageOppoChatInvitation(
                                                                item.id,
                                                                item.opportunity_id,
                                                                'rejected',
                                                              );
                                                            }}>
                                                            <i className="icon-unlike" />
                                                          </span>
                                                        </div>
                                                      )}
                                                    {item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) && (
                                                      <div className="stage-likes-icons">
                                                        <span
                                                          className="as-link"
                                                          onClick={e => {
                                                            e.stopPropagation();

                                                            // setShowSpinner(true);
                                                            handleOpenChatRoom(
                                                              item,
                                                            );
                                                            // manageChatInvitation(
                                                            //   item.id,
                                                            //   item.invitation_type,
                                                            //   'accepted',
                                                            // );
                                                          }}>
                                                          <i className="icon-like-icon" />
                                                        </span>
                                                        <span
                                                          className="as-link"
                                                          onClick={e => {
                                                            e.stopPropagation();

                                                            // setShowSpinner(true);
                                                            manageChatInvitation(
                                                              item.id,
                                                              item.invitation_type,
                                                              'rejected',
                                                            );
                                                          }}>
                                                          <i className="icon-unlike" />
                                                        </span>
                                                      </div>
                                                    )}
                                                    {!item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) &&
                                                      item.hasOwnProperty(
                                                        'group_detail',
                                                      ) && (
                                                        <OtherUserImages
                                                          otherUsers={filteringUsers(
                                                            item.audience_detail.concat(
                                                              item.moderator_detail,
                                                              item.creator_detail
                                                                ? [
                                                                    item.creator_detail,
                                                                  ]
                                                                : [
                                                                    item.creator_details,
                                                                  ],
                                                            ),
                                                            arrayColumn(
                                                              item.group_detail,
                                                              'id',
                                                            ),
                                                          )}
                                                        />
                                                      )}
                                                    {!item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) &&
                                                      !item.hasOwnProperty(
                                                        'opportunity_invitaion_status',
                                                      ) &&
                                                      item.hasOwnProperty(
                                                        'opportunity_id',
                                                      ) &&
                                                      item.chat_type ===
                                                        'opportunity' && (
                                                        <OtherUserImages
                                                          otherUsers={filteringUsers(
                                                            item.other_persons_opportunity_chat_room.concat(
                                                              item.team_member_details,
                                                              item.creator_detail
                                                                ? [
                                                                    item.creator_detail,
                                                                  ]
                                                                : [
                                                                    item.creator_details,
                                                                  ],
                                                            ),
                                                            arrayColumn(
                                                              [
                                                                ...item.other_persons_opportunity_chat_room,
                                                                ...item.team_member_details,
                                                                item.creator_details,
                                                              ],
                                                              'id',
                                                            ),
                                                          )}
                                                        />
                                                      )}
                                                  </div>
                                                );
                                              })
                                            ) : (
                                              <>
                                                <div className="no-results-wrapper mt-5">
                                                  <div className="img-bg-wrapper">
                                                    <img
                                                      src={require('../../Assets/images/norecord/no-convos.webp')}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="no-results-content">
                                                    <h2>
                                                      {
                                                        labels
                                                          .MANAGE_GROUP_CHAT[
                                                          selected_lang
                                                        ]
                                                      }
                                                    </h2>
                                                    <p>
                                                      {
                                                        labels.Create_to_Invite[
                                                          selected_lang
                                                        ]
                                                      }
                                                    </p>

                                                    <div className="btn-wrapper mt-3">
                                                      <Button
                                                        className="btn btn-dark"
                                                        onClick={() => {
                                                          setSecondComponent(
                                                            'group-component',
                                                          );
                                                        }}>
                                                        {
                                                          labels.Create_Table[
                                                            selected_lang
                                                          ]
                                                        }
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}

                                  {tabType === 'subInvites' && (
                                    <>
                                      {showSpinner && <LoaderSpinner />}
                                      {!showSpinner && (
                                        <>
                                          <div className="messages-users-list h-auto pr-1">
                                            {chatList?.length > 0 &&
                                            Object.entries(chatList).length >
                                              0 ? (
                                              Object.entries(chatList).length >
                                                0 &&
                                              chatList.map((item, index) => {
                                                // const newMessage = item.read ? '' : 'new-message';
                                                const newMessage =
                                                  chatRoomId === item.id ||
                                                  item.read
                                                    ? ''
                                                    : 'new-message';
                                                const isActive =
                                                  chatRoomId === item.id
                                                    ? 'active'
                                                    : '';
                                                const userIs =
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail';
                                                return (
                                                  <div
                                                    // onClick={() => {
                                                    //   // getUserData(item.user_id);
                                                    //   // checkNewUsersInGroup(item.id);
                                                    //   handleOpenChatRoom(item);
                                                    // }}
                                                    key={`msg-${tabType}-${index}`}
                                                    className={`message-user-item no-link ${isActive} ${newMessage}`}>
                                                    <div
                                                      className="user-img as-link"
                                                      onClick={() => {
                                                        amplitudeEvent(
                                                          'OPEN_CHAT_ROOM',
                                                        );
                                                        handleOpenChatRoom(
                                                          item,
                                                        );
                                                      }}>
                                                      <img
                                                        src={
                                                          tabType ===
                                                          'subInvites'
                                                            ? // !item.hasOwnProperty('read')
                                                              item[userIs][
                                                                'profile_img'
                                                              ]
                                                              ? item[userIs][
                                                                  'profile_img'
                                                                ]
                                                              : DEFAULT_IMAGE
                                                            : item.group_image !==
                                                              ''
                                                            ? item.group_image
                                                            : item[userIs][
                                                                'profile_img'
                                                              ]
                                                            ? item[userIs][
                                                                'profile_img'
                                                              ]
                                                            : DEFAULT_IMAGE
                                                        }
                                                        alt=""
                                                      />
                                                      {item.chat_type ===
                                                        'question' && (
                                                        <span>
                                                          <i className="fa-solid fa-q"></i>
                                                        </span>
                                                      )}
                                                      {item.chat_type ===
                                                        'opportunity' && (
                                                        <span className="icon icon-posts"></span>
                                                      )}
                                                      {item.chat_type ===
                                                        'event' && (
                                                        <span className="icon-tickets"></span>
                                                      )}
                                                      {item.chat_type ===
                                                        'session' && (
                                                        <span className="icon-video-camera-2"></span>
                                                      )}
                                                      {/* <span className="fa-solid fa-shop"></span> */}
                                                    </div>
                                                    <div
                                                      className="user-content as-link"
                                                      onClick={() => {
                                                        handleOpenChatRoom(
                                                          item,
                                                        );
                                                      }}>
                                                      <h2 className="single-line-text">
                                                        {/* {tabType === 'subInvites' &&
!item.hasOwnProperty('read') */}
                                                        {tabType ===
                                                        'subInvites'
                                                          ? fixName(
                                                              item[userIs][
                                                                'first_name'
                                                              ],
                                                              item[userIs][
                                                                'last_name'
                                                              ],
                                                            )
                                                          : item.group_name !==
                                                            ''
                                                          ? item.group_name
                                                          : fixName(
                                                              item[userIs][
                                                                'first_name'
                                                              ],
                                                              item[userIs][
                                                                'last_name'
                                                              ],
                                                            )}
                                                      </h2>

                                                      {/* className={
tabType === 'subInvites'
? ''
: 'single-line-text'
} */}
                                                      {/* {tabType === 'subInvites' &&
!item.hasOwnProperty('read') */}
                                                      {tabType === 'subInvites'
                                                        ? item.group_name !== ''
                                                          ? 'Just invited you to chat.'
                                                          : 'Just invited you to a group chat.'
                                                        : item.last_message ===
                                                          formatString(
                                                            item.last_message,
                                                          )}

                                                      <div className="message-date-info">
                                                        <span className="message-date">
                                                          <Moment format="MM/DD/YY HH:mm A">
                                                            {item.last_message_time
                                                              ? item.last_message_time
                                                              : item.created_at}
                                                          </Moment>
                                                        </span>
                                                        {/* {renderMessageUsers(item.moreUsers)} */}
                                                      </div>
                                                    </div>
                                                    {item.hasOwnProperty(
                                                      'opportunity_invitaion_status',
                                                    ) &&
                                                      tabType ===
                                                        'subInvites' && (
                                                        <div className="stage-likes-icons">
                                                          <span
                                                            className="as-link"
                                                            onClick={e => {
                                                              e.stopPropagation();
                                                              handleOpenChatRoom(
                                                                item,
                                                              );
                                                            }}>
                                                            <i className="icon-like-icon" />
                                                          </span>
                                                          <span
                                                            className="as-link"
                                                            onClick={e => {
                                                              e.stopPropagation();
                                                              manageOppoChatInvitation(
                                                                item.id,
                                                                item.opportunity_id,
                                                                'rejected',
                                                              );
                                                            }}>
                                                            <i className="icon-unlike" />
                                                          </span>
                                                        </div>
                                                      )}
                                                    {item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) && (
                                                      <div className="stage-likes-icons">
                                                        <span
                                                          className="as-link"
                                                          onClick={e => {
                                                            e.stopPropagation();

                                                            // setShowSpinner(true);
                                                            handleOpenChatRoom(
                                                              item,
                                                            );
                                                            // manageChatInvitation(
                                                            //   item.id,
                                                            //   item.invitation_type,
                                                            //   'accepted',
                                                            // );
                                                          }}>
                                                          <i className="icon-like-icon" />
                                                        </span>
                                                        <span
                                                          className="as-link"
                                                          onClick={e => {
                                                            e.stopPropagation();

                                                            // setShowSpinner(true);
                                                            manageChatInvitation(
                                                              item.id,
                                                              item.invitation_type,
                                                              'rejected',
                                                            );
                                                          }}>
                                                          <i className="icon-unlike" />
                                                        </span>
                                                      </div>
                                                    )}
                                                    {!item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) &&
                                                      item.hasOwnProperty(
                                                        'group_detail',
                                                      ) && (
                                                        <OtherUserImages
                                                          otherUsers={filteringUsers(
                                                            item.audience_detail.concat(
                                                              item.moderator_detail,
                                                              item.creator_detail
                                                                ? [
                                                                    item.creator_detail,
                                                                  ]
                                                                : [
                                                                    item.creator_details,
                                                                  ],
                                                            ),
                                                            arrayColumn(
                                                              item.group_detail,
                                                              'id',
                                                            ),
                                                          )}
                                                        />
                                                      )}
                                                    {!item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) &&
                                                      !item.hasOwnProperty(
                                                        'opportunity_invitaion_status',
                                                      ) &&
                                                      item.hasOwnProperty(
                                                        'opportunity_id',
                                                      ) &&
                                                      item.chat_type ===
                                                        'opportunity' && (
                                                        <OtherUserImages
                                                          otherUsers={filteringUsers(
                                                            item.other_persons_opportunity_chat_room.concat(
                                                              item.team_member_details,
                                                              item.creator_detail
                                                                ? [
                                                                    item.creator_detail,
                                                                  ]
                                                                : [
                                                                    item.creator_details,
                                                                  ],
                                                            ),
                                                            arrayColumn(
                                                              [
                                                                ...item.other_persons_opportunity_chat_room,
                                                                ...item.team_member_details,
                                                                item.creator_details,
                                                              ],
                                                              'id',
                                                            ),
                                                          )}
                                                        />
                                                      )}
                                                  </div>
                                                );
                                              })
                                            ) : (
                                              <>
                                                <div className="no-results-wrapper mt-5">
                                                  <div className="img-bg-wrapper">
                                                    <img
                                                      src={require('../../Assets/images/norecord/no-convos.webp')}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="no-results-content">
                                                    <h2>
                                                      {
                                                        labels
                                                          .NO_INVITES_PENDING[
                                                          selected_lang
                                                        ]
                                                      }
                                                    </h2>
                                                    <p>
                                                      {
                                                        labels
                                                          .NO_INVITES_PENDING_INFO[
                                                          selected_lang
                                                        ]
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {liveChatToggle && (
                                <>
                                  {showSpinner && <LoaderSpinner />}
                                  {!showSpinner && (
                                    <>
                                      {liveChatTab === 'active' && (
                                        <div className="messages-users-list h-auto">
                                          {Object.entries(liveChatData).length >
                                          0 ? (
                                            Object.entries(liveChatData)
                                              .length > 0 &&
                                            liveChatData.map((item, index) => {
                                              // const newMessage = item.read ? '' : 'new-message';
                                              const newMessage =
                                                chatRoomId === item.id ||
                                                item.read
                                                  ? ''
                                                  : 'new-message';
                                              const isActive =
                                                chatRoomId === item.id
                                                  ? 'active'
                                                  : '';
                                              const userIs =
                                                parseInt(item.creator_id) ===
                                                parseInt(
                                                  localStorage.getItem('user'),
                                                )
                                                  ? 'user_detail'
                                                  : item.hasOwnProperty(
                                                      'creator_details',
                                                    )
                                                  ? 'creator_details'
                                                  : 'creator_detail';
                                              return (
                                                <div
                                                  // onClick={() => {
                                                  //   // getUserData(item.user_id);
                                                  //   // checkNewUsersInGroup(item.id);
                                                  //   handleOpenChatRoom(item);
                                                  // }}
                                                  key={`msg-${tabType}-${index}`}
                                                  className={`message-user-item no-link ${isActive} ${newMessage}`}>
                                                  <div
                                                    className="user-img as-link"
                                                    onClick={() => {
                                                      amplitudeEvent(
                                                        'OPEN_CHAT_ROOM',
                                                      );
                                                      if (
                                                        chatRoomId !==
                                                          item.id &&
                                                        !isActive
                                                      ) {
                                                        handleOpenChatRoom(
                                                          item,
                                                        );
                                                      }
                                                    }}>
                                                    <img
                                                      src={
                                                        liveChatTab === 'queue'
                                                          ? // !item.hasOwnProperty('read')
                                                            item[userIs][
                                                              'profile_img'
                                                            ]
                                                            ? item[userIs][
                                                                'profile_img'
                                                              ]
                                                            : DEFAULT_IMAGE
                                                          : item.group_image !==
                                                            ''
                                                          ? item.group_image
                                                          : item[userIs][
                                                              'profile_img'
                                                            ]
                                                          ? item[userIs][
                                                              'profile_img'
                                                            ]
                                                          : DEFAULT_IMAGE
                                                      }
                                                      alt=""
                                                    />
                                                    {item.chat_type ===
                                                      'question' && (
                                                      <span>
                                                        <i className="fa-solid fa-q"></i>
                                                      </span>
                                                    )}
                                                    {item.chat_type ===
                                                      'opportunity' && (
                                                      <span className="icon icon-posts"></span>
                                                    )}
                                                    {item.chat_type ===
                                                      'event' && (
                                                      <span className="icon-tickets"></span>
                                                    )}
                                                    {item.chat_type ===
                                                      'session' && (
                                                      <span className="icon-video-camera-2"></span>
                                                    )}
                                                    {/* <span className="fa-solid fa-shop"></span> */}
                                                  </div>
                                                  <div
                                                    className="user-content as-link"
                                                    onClick={() => {
                                                      if (
                                                        chatRoomId !==
                                                          item.id &&
                                                        !isActive
                                                      ) {
                                                        handleOpenChatRoom(
                                                          item,
                                                        );
                                                      }
                                                    }}>
                                                    <h2 className="single-line-text">
                                                      {/* {tabType === 'subInvites' &&
      !item.hasOwnProperty('read') */}
                                                      {liveChatTab === 'queue'
                                                        ? fixName(
                                                            item[userIs][
                                                              'first_name'
                                                            ],
                                                            item[userIs][
                                                              'last_name'
                                                            ],
                                                          )
                                                        : item.group_name !== ''
                                                        ? item.group_name
                                                        : fixName(
                                                            item[userIs][
                                                              'first_name'
                                                            ],
                                                            item[userIs][
                                                              'last_name'
                                                            ],
                                                          )}
                                                    </h2>

                                                    {/* className={
        tabType === 'subInvites'
          ? ''
          : 'single-line-text'
      } */}
                                                    {/* {tabType === 'subInvites' &&
      !item.hasOwnProperty('read') */}
                                                    {liveChatTab === 'queue'
                                                      ? item.group_name !== ''
                                                        ? 'Just invited you to chat.'
                                                        : 'Just invited you to a group chat.'
                                                      : formatString(
                                                          item.last_message,
                                                        )}

                                                    <div className="message-date-info">
                                                      <span className="message-date">
                                                        <Moment format="MM/DD/YY HH:mm A">
                                                          {item.last_message_time
                                                            ? item.last_message_time
                                                            : item.created_at}
                                                        </Moment>
                                                      </span>
                                                      {/* {renderMessageUsers(item.moreUsers)} */}
                                                    </div>
                                                  </div>
                                                  {item.hasOwnProperty(
                                                    'opportunity_invitaion_status',
                                                  ) &&
                                                    liveChatTab === 'queue' && (
                                                      <div className="stage-likes-icons">
                                                        <span
                                                          className="as-link"
                                                          onClick={e => {
                                                            e.stopPropagation();
                                                            if (
                                                              chatRoomId !==
                                                                item.id &&
                                                              !isActive
                                                            ) {
                                                              handleOpenChatRoom(
                                                                item,
                                                              );
                                                            }
                                                          }}>
                                                          <i className="icon-like-icon" />
                                                        </span>
                                                        <span
                                                          className="as-link"
                                                          onClick={e => {
                                                            e.stopPropagation();
                                                            manageOppoChatInvitation(
                                                              item.id,
                                                              item.opportunity_id,
                                                              'rejected',
                                                            );
                                                          }}>
                                                          <i className="icon-unlike" />
                                                        </span>
                                                      </div>
                                                    )}
                                                  {item.hasOwnProperty(
                                                    'invitation_type',
                                                  ) && (
                                                    <div className="stage-likes-icons">
                                                      <span
                                                        className="as-link"
                                                        onClick={e => {
                                                          e.stopPropagation();

                                                          // setShowSpinner(true);
                                                          handleOpenChatRoom(
                                                            item,
                                                          );
                                                          // manageChatInvitation(
                                                          //   item.id,
                                                          //   item.invitation_type,
                                                          //   'accepted',
                                                          // );
                                                        }}>
                                                        <i className="icon-like-icon" />
                                                      </span>
                                                      <span
                                                        className="as-link"
                                                        onClick={e => {
                                                          e.stopPropagation();

                                                          // setShowSpinner(true);
                                                          manageChatInvitation(
                                                            item.id,
                                                            item.invitation_type,
                                                            'rejected',
                                                          );
                                                        }}>
                                                        <i className="icon-unlike" />
                                                      </span>
                                                    </div>
                                                  )}
                                                  {!item.hasOwnProperty(
                                                    'invitation_type',
                                                  ) &&
                                                    item.hasOwnProperty(
                                                      'group_detail',
                                                    ) && (
                                                      <OtherUserImages
                                                        otherUsers={filteringUsers(
                                                          item.audience_detail.concat(
                                                            item.moderator_detail,
                                                            item.creator_detail
                                                              ? [
                                                                  item.creator_detail,
                                                                ]
                                                              : [
                                                                  item.creator_details,
                                                                ],
                                                          ),
                                                          arrayColumn(
                                                            item.group_detail,
                                                            'id',
                                                          ),
                                                        )}
                                                      />
                                                    )}
                                                  {!item.hasOwnProperty(
                                                    'invitation_type',
                                                  ) &&
                                                    !item.hasOwnProperty(
                                                      'opportunity_invitaion_status',
                                                    ) &&
                                                    item.hasOwnProperty(
                                                      'opportunity_id',
                                                    ) &&
                                                    item.chat_type ===
                                                      'opportunity' && (
                                                      <OtherUserImages
                                                        otherUsers={filteringUsers(
                                                          item.other_persons_opportunity_chat_room.concat(
                                                            item.team_member_details,
                                                            item.creator_detail
                                                              ? [
                                                                  item.creator_detail,
                                                                ]
                                                              : [
                                                                  item.creator_details,
                                                                ],
                                                          ),
                                                          arrayColumn(
                                                            [
                                                              ...item.other_persons_opportunity_chat_room,
                                                              ...item.team_member_details,
                                                              item.creator_details,
                                                            ],
                                                            'id',
                                                          ),
                                                        )}
                                                      />
                                                    )}
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <>
                                              <div className="no-results-wrapper mt-5">
                                                <div className="img-bg-wrapper">
                                                  <img
                                                    src={require('../../Assets/images/norecord/no-convos.webp')}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="no-results-content">
                                                  {/* <h2>
                                               No Record Found
                                              </h2> */}
                                                  <h2>No Active Chats</h2>

                                                  <p>
                                                    {liveChatQry
                                                      ? 'Active Chats not found'
                                                      : "Seems you don't have any active conservation going on."}
                                                  </p>

                                                  {/* <div className="btn-wrapper mt-3">
                                                <Button
                                                  className="btn btn-dark"
                                                  onClick={() => {
                                                    setSecondComponent(
                                                      'group-component',
                                                    );
                                                  }}>
                                                  {
                                                    labels.Create_Table[
                                                      selected_lang
                                                    ]
                                                  }
                                                </Button>
                                              </div> */}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      )}

                                      {liveChatTab === 'queue' && (
                                        <div className="messages-users-list h-auto">
                                          {Object.entries(queueliveChatData)
                                            .length > 0 ? (
                                            Object.entries(queueliveChatData)
                                              .length > 0 &&
                                            queueliveChatData.map(
                                              (item, index) => {
                                                // const newMessage = item.read ? '' : 'new-message';
                                                const newMessage =
                                                  chatRoomId === item.id ||
                                                  item.read
                                                    ? ''
                                                    : 'new-message';
                                                const isActive =
                                                  chatRoomId === item.id
                                                    ? 'active'
                                                    : '';
                                                const userIs =
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail';
                                                return (
                                                  <div
                                                    // onClick={() => {
                                                    //   // getUserData(item.user_id);
                                                    //   // checkNewUsersInGroup(item.id);
                                                    //   handleOpenChatRoom(item);
                                                    // }}
                                                    key={`msg-${tabType}-${index}`}
                                                    className={`message-user-item no-link ${isActive} ${newMessage}`}>
                                                    <div
                                                      className="user-img as-link"
                                                      onClick={() => {
                                                        amplitudeEvent(
                                                          'OPEN_CHAT_ROOM',
                                                        );
                                                        handleOpenChatRoom(
                                                          item,
                                                        );
                                                      }}>
                                                      <img
                                                        src={
                                                          liveChatTab ===
                                                          'queue'
                                                            ? // !item.hasOwnProperty('read')
                                                              item[userIs][
                                                                'profile_img'
                                                              ]
                                                              ? item[userIs][
                                                                  'profile_img'
                                                                ]
                                                              : DEFAULT_IMAGE
                                                            : item.group_image !==
                                                              ''
                                                            ? item.group_image
                                                            : item[userIs][
                                                                'profile_img'
                                                              ]
                                                            ? item[userIs][
                                                                'profile_img'
                                                              ]
                                                            : DEFAULT_IMAGE
                                                        }
                                                        alt=""
                                                      />
                                                      {item.chat_type ===
                                                        'question' && (
                                                        <span>
                                                          <i className="fa-solid fa-q"></i>
                                                        </span>
                                                      )}
                                                      {item.chat_type ===
                                                        'opportunity' && (
                                                        <span className="icon icon-posts"></span>
                                                      )}
                                                      {item.chat_type ===
                                                        'event' && (
                                                        <span className="icon-tickets"></span>
                                                      )}
                                                      {item.chat_type ===
                                                        'session' && (
                                                        <span className="icon-video-camera-2"></span>
                                                      )}
                                                      {/* <span className="fa-solid fa-shop"></span> */}
                                                    </div>
                                                    <div
                                                      className="user-content as-link"
                                                      onClick={() => {
                                                        handleOpenChatRoom(
                                                          item,
                                                        );
                                                      }}>
                                                      <h2 className="single-line-text">
                                                        {/* {tabType === 'subInvites' &&
!item.hasOwnProperty('read') */}
                                                        {liveChatTab === 'queue'
                                                          ? fixName(
                                                              item[userIs][
                                                                'first_name'
                                                              ],
                                                              item[userIs][
                                                                'last_name'
                                                              ],
                                                            )
                                                          : item.group_name !==
                                                            ''
                                                          ? item.group_name
                                                          : fixName(
                                                              item[userIs][
                                                                'first_name'
                                                              ],
                                                              item[userIs][
                                                                'last_name'
                                                              ],
                                                            )}
                                                      </h2>

                                                      {/* className={
tabType === 'subInvites'
? ''
: 'single-line-text'
} */}
                                                      {/* {tabType === 'subInvites' &&
!item.hasOwnProperty('read') */}
                                                      {liveChatTab === 'queue'
                                                        ? item.group_name !== ''
                                                          ? 'Just requested to you for a live chat.'
                                                          : 'Just requested to you for a live chat.'
                                                        : item.last_message ===
                                                          formatString(
                                                            item.last_message,
                                                          )}

                                                      <div className="message-date-info">
                                                        <span className="message-date">
                                                          <Moment format="MM/DD/YY HH:mm A">
                                                            {item.last_message_time
                                                              ? item.last_message_time
                                                              : item.created_at}
                                                          </Moment>
                                                        </span>
                                                        {/* {renderMessageUsers(item.moreUsers)} */}
                                                      </div>
                                                    </div>
                                                    {/* {item.hasOwnProperty(
                                                  'opportunity_invitaion_status',
                                                ) &&
                                                  tabType ===
                                                    'subInvites' && (
                                                    <div className="stage-likes-icons">
                                                      <span
                                                        className="as-link"
                                                        onClick={e => {
                                                          e.stopPropagation();
                                                          handleOpenChatRoom(
                                                            item,
                                                          );
                                                        }}>
                                                        <i className="icon-like-icon" />
                                                      </span>
                                                      <span
                                                        className="as-link"
                                                        onClick={e => {
                                                          e.stopPropagation();
                                                          manageOppoChatInvitation(
                                                            item.id,
                                                            item.opportunity_id,
                                                            'rejected',
                                                          );
                                                        }}>
                                                        <i className="icon-unlike" />
                                                      </span>
                                                    </div>
                                                  )}
                                                {item.hasOwnProperty(
                                                  'invitation_type',
                                                ) && (
                                                  <div className="stage-likes-icons">
                                                    <span
                                                      className="as-link"
                                                      onClick={e => {
                                                        e.stopPropagation();

                                                        // setShowSpinner(true);
                                                        handleOpenChatRoom(
                                                          item,
                                                        );
                                                        // manageChatInvitation(
                                                        //   item.id,
                                                        //   item.invitation_type,
                                                        //   'accepted',
                                                        // );
                                                      }}>
                                                      <i className="icon-like-icon" />
                                                    </span>
                                                    <span
                                                      className="as-link"
                                                      onClick={e => {
                                                        e.stopPropagation();

                                                        // setShowSpinner(true);
                                                        manageChatInvitation(
                                                          item.id,
                                                          item.invitation_type,
                                                          'rejected',
                                                        );
                                                      }}>
                                                      <i className="icon-unlike" />
                                                    </span>
                                                  </div>
                                                )} */}
                                                    {!item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) &&
                                                      item.hasOwnProperty(
                                                        'group_detail',
                                                      ) && (
                                                        <OtherUserImages
                                                          otherUsers={filteringUsers(
                                                            item.audience_detail.concat(
                                                              item.moderator_detail,
                                                              item.creator_detail
                                                                ? [
                                                                    item.creator_detail,
                                                                  ]
                                                                : [
                                                                    item.creator_details,
                                                                  ],
                                                            ),
                                                            arrayColumn(
                                                              item.group_detail,
                                                              'id',
                                                            ),
                                                          )}
                                                        />
                                                      )}
                                                    {!item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) &&
                                                      !item.hasOwnProperty(
                                                        'opportunity_invitaion_status',
                                                      ) &&
                                                      item.hasOwnProperty(
                                                        'opportunity_id',
                                                      ) &&
                                                      item.chat_type ===
                                                        'opportunity' && (
                                                        <OtherUserImages
                                                          otherUsers={filteringUsers(
                                                            item.other_persons_opportunity_chat_room.concat(
                                                              item.team_member_details,
                                                              item.creator_detail
                                                                ? [
                                                                    item.creator_detail,
                                                                  ]
                                                                : [
                                                                    item.creator_details,
                                                                  ],
                                                            ),
                                                            arrayColumn(
                                                              [
                                                                ...item.other_persons_opportunity_chat_room,
                                                                ...item.team_member_details,
                                                                item.creator_details,
                                                              ],
                                                              'id',
                                                            ),
                                                          )}
                                                        />
                                                      )}
                                                  </div>
                                                );
                                              },
                                            )
                                          ) : (
                                            <>
                                              <div className="no-results-wrapper mt-5">
                                                <div className="img-bg-wrapper">
                                                  <img
                                                    src={require('../../Assets/images/norecord/no-convos.webp')}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="no-results-content">
                                                  <h2>
                                                    {
                                                      labels.NO_REQUEST_PENDING[
                                                        selected_lang
                                                      ]
                                                    }
                                                  </h2>
                                                  <p>
                                                    There are no pending
                                                    requests of Live Chats
                                                  </p>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      )}

                                      {liveChatTab === 'closed' && (
                                        <div className="messages-users-list h-auto">
                                          {Object.entries(closedChatData)
                                            .length > 0 ? (
                                            Object.entries(closedChatData)
                                              .length > 0 &&
                                            closedChatData.map(
                                              (item, index) => {
                                                // const newMessage = item.read ? '' : 'new-message';
                                                const newMessage =
                                                  chatRoomId === item.id ||
                                                  item.read
                                                    ? ''
                                                    : 'new-message';
                                                const isActive =
                                                  chatRoomId === item.id
                                                    ? 'active'
                                                    : '';
                                                const userIs =
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail';
                                                return (
                                                  <div
                                                    // onClick={() => {
                                                    //   // getUserData(item.user_id);
                                                    //   // checkNewUsersInGroup(item.id);
                                                    //   handleOpenChatRoom(item);
                                                    // }}
                                                    key={`msg-${tabType}-${index}`}
                                                    className={`message-user-item no-link `}>
                                                    <div
                                                      className="user-img as-link"
                                                      onClick={() => {
                                                        amplitudeEvent(
                                                          'OPEN_CHAT_ROOM',
                                                        );
                                                        if (
                                                          chatRoomId !==
                                                            item.id &&
                                                          !isActive
                                                        ) {
                                                          handleOpenChatRoom(
                                                            item,
                                                          );
                                                        }
                                                      }}>
                                                      <img
                                                        src={
                                                          liveChatTab ===
                                                          'queue'
                                                            ? // !item.hasOwnProperty('read')
                                                              item[userIs][
                                                                'profile_img'
                                                              ]
                                                              ? item[userIs][
                                                                  'profile_img'
                                                                ]
                                                              : DEFAULT_IMAGE
                                                            : item.group_image !==
                                                              ''
                                                            ? item.group_image
                                                            : item[userIs][
                                                                'profile_img'
                                                              ]
                                                            ? item[userIs][
                                                                'profile_img'
                                                              ]
                                                            : DEFAULT_IMAGE
                                                        }
                                                        alt=""
                                                      />
                                                      {item.chat_type ===
                                                        'question' && (
                                                        <span>
                                                          <i className="fa-solid fa-q"></i>
                                                        </span>
                                                      )}
                                                      {item.chat_type ===
                                                        'opportunity' && (
                                                        <span className="icon icon-posts"></span>
                                                      )}
                                                      {item.chat_type ===
                                                        'event' && (
                                                        <span className="icon-tickets"></span>
                                                      )}
                                                      {item.chat_type ===
                                                        'session' && (
                                                        <span className="icon-video-camera-2"></span>
                                                      )}
                                                      {/* <span className="fa-solid fa-shop"></span> */}
                                                    </div>
                                                    <div
                                                      className="user-content as-link"
                                                      onClick={() => {
                                                        if (
                                                          chatRoomId !==
                                                            item.id &&
                                                          !isActive
                                                        ) {
                                                          handleOpenChatRoom(
                                                            item,
                                                          );
                                                        }
                                                      }}>
                                                      <h2 className="single-line-text">
                                                        {/* {tabType === 'subInvites' &&
      !item.hasOwnProperty('read') */}
                                                        {liveChatTab === 'queue'
                                                          ? fixName(
                                                              item[userIs][
                                                                'first_name'
                                                              ],
                                                              item[userIs][
                                                                'last_name'
                                                              ],
                                                            )
                                                          : item.group_name !==
                                                            ''
                                                          ? item.group_name
                                                          : fixName(
                                                              item[userIs][
                                                                'first_name'
                                                              ],
                                                              item[userIs][
                                                                'last_name'
                                                              ],
                                                            )}
                                                      </h2>

                                                      {/* className={
        tabType === 'subInvites'
          ? ''
          : 'single-line-text'
      } */}
                                                      {/* {tabType === 'subInvites' &&
      !item.hasOwnProperty('read') */}
                                                      {liveChatTab === 'queue'
                                                        ? item.group_name !== ''
                                                          ? 'Just invited you to chat.'
                                                          : 'Just invited you to a group chat.'
                                                        : formatString(
                                                            item.last_message,
                                                          )}

                                                      <div className="message-date-info">
                                                        <span className="message-date">
                                                          <Moment format="MM/DD/YY HH:mm A">
                                                            {item.last_message_time
                                                              ? item.last_message_time
                                                              : item.created_at}
                                                          </Moment>
                                                        </span>
                                                        {/* {renderMessageUsers(item.moreUsers)} */}
                                                      </div>
                                                    </div>
                                                    {item.hasOwnProperty(
                                                      'opportunity_invitaion_status',
                                                    ) &&
                                                      liveChatTab ===
                                                        'queue' && (
                                                        <div className="stage-likes-icons">
                                                          <span
                                                            className="as-link"
                                                            onClick={e => {
                                                              e.stopPropagation();
                                                              if (
                                                                chatRoomId !==
                                                                  item.id &&
                                                                !isActive
                                                              ) {
                                                                handleOpenChatRoom(
                                                                  item,
                                                                );
                                                              }
                                                            }}>
                                                            <i className="icon-like-icon" />
                                                          </span>
                                                          <span
                                                            className="as-link"
                                                            onClick={e => {
                                                              e.stopPropagation();
                                                              manageOppoChatInvitation(
                                                                item.id,
                                                                item.opportunity_id,
                                                                'rejected',
                                                              );
                                                            }}>
                                                            <i className="icon-unlike" />
                                                          </span>
                                                        </div>
                                                      )}
                                                    {item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) && (
                                                      <div className="stage-likes-icons">
                                                        <span
                                                          className="as-link"
                                                          onClick={e => {
                                                            e.stopPropagation();

                                                            // setShowSpinner(true);
                                                            handleOpenChatRoom(
                                                              item,
                                                            );
                                                            // manageChatInvitation(
                                                            //   item.id,
                                                            //   item.invitation_type,
                                                            //   'accepted',
                                                            // );
                                                          }}>
                                                          <i className="icon-like-icon" />
                                                        </span>
                                                        <span
                                                          className="as-link"
                                                          onClick={e => {
                                                            e.stopPropagation();

                                                            // setShowSpinner(true);
                                                            manageChatInvitation(
                                                              item.id,
                                                              item.invitation_type,
                                                              'rejected',
                                                            );
                                                          }}>
                                                          <i className="icon-unlike" />
                                                        </span>
                                                      </div>
                                                    )}
                                                    {!item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) &&
                                                      item.hasOwnProperty(
                                                        'group_detail',
                                                      ) && (
                                                        <OtherUserImages
                                                          otherUsers={filteringUsers(
                                                            item.audience_detail.concat(
                                                              item.moderator_detail,
                                                              item.creator_detail
                                                                ? [
                                                                    item.creator_detail,
                                                                  ]
                                                                : [
                                                                    item.creator_details,
                                                                  ],
                                                            ),
                                                            arrayColumn(
                                                              item.group_detail,
                                                              'id',
                                                            ),
                                                          )}
                                                        />
                                                      )}
                                                    {!item.hasOwnProperty(
                                                      'invitation_type',
                                                    ) &&
                                                      !item.hasOwnProperty(
                                                        'opportunity_invitaion_status',
                                                      ) &&
                                                      item.hasOwnProperty(
                                                        'opportunity_id',
                                                      ) &&
                                                      item.chat_type ===
                                                        'opportunity' && (
                                                        <OtherUserImages
                                                          otherUsers={filteringUsers(
                                                            item.other_persons_opportunity_chat_room.concat(
                                                              item.team_member_details,
                                                              item.creator_detail
                                                                ? [
                                                                    item.creator_detail,
                                                                  ]
                                                                : [
                                                                    item.creator_details,
                                                                  ],
                                                            ),
                                                            arrayColumn(
                                                              [
                                                                ...item.other_persons_opportunity_chat_room,
                                                                ...item.team_member_details,
                                                                item.creator_details,
                                                              ],
                                                              'id',
                                                            ),
                                                          )}
                                                        />
                                                      )}
                                                  </div>
                                                );
                                              },
                                            )
                                          ) : (
                                            <>
                                              <div className="no-results-wrapper mt-5">
                                                <div className="img-bg-wrapper">
                                                  <img
                                                    src={require('../../Assets/images/norecord/no-convos.webp')}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="no-results-content">
                                                  {/* <h2>
                                                {
                                                  labels
                                                    .MANAGE_GROUP_CHAT[
                                                    selected_lang
                                                  ]
                                                }
                                              </h2> */}

                                                  <h2>No Closed Chats</h2>

                                                  <p>
                                                    {liveChatQry
                                                      ? 'Closed Chats not found'
                                                      : "Seems you don't have any closed conservation."}
                                                  </p>

                                                  {/* <div className="btn-wrapper mt-3">
                                                <Button
                                                  className="btn btn-dark"
                                                  onClick={() => {
                                                    setSecondComponent(
                                                      'group-component',
                                                    );
                                                  }}>
                                                  {
                                                    labels.Create_Table[
                                                      selected_lang
                                                    ]
                                                  }
                                                </Button>
                                              </div> */}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                      {isLoading && <ScrollLoader />}
                    </div>
                  </>
                )}
                {/* </Scrollbars> */} {/* Merging */}
                {searchFilter && (
                  <MessageSearchFilter
                    show={searchFilter}
                    onClose={() => setSearchFilterModal(false)}
                    setFilter={setFilter}
                    filter={filter}
                    setshowLoader={setShowSpinner}
                  />
                )}
              </>
            } 
            secondary={
              <>
                {/* Temporary showing component */}
                {secondComponent === 'profile-component-x' && (
                  <ProfileComponent
                    userId={'4'}
                    show={false}
                    closeModal={() => {}}
                    userData={[]}
                    moderator={false}
                    // setParticipantType={setParticipantType}
                    VoxeetSDK={[]}
                    conferenceInfo={[]}
                    jdSpecialM={[]}
                    jdSpecialA={[]}
                  />
                )}

                {/* Chat Component */}
                {secondComponent === 'chat-component' && (
                  <SmartChatPage
                    allmsg={true}
                    type={chatPropType}
                    item={chatDetailItem}
                    chatroomInfo={chatroomInfo(chatDetails)}
                    oppoDetails={chatDetails}
                    chatDetails={chatDetails}
                    allChatUsers={allChatUsers}
                    chatRoomName={chatRoomName}
                    chatRoomId={chatRoomId}
                    workSpaceItem={workspaceData}
                    // chatRoomStatus={chatDetails.status}
                    onClose={() => {
                      setSecondComponent('');
                      setChatRoomId('');
                    }}
                    handleCommonChat={(data, type) => {
                      handleCommonChat(data, type);
                    }}
                    paddingBottom={true}
                  />
                )}

                {secondComponent === 'group-component' && (
                  <CreateGroup
                    onClose={() => {
                      setSecondComponent('');
                    }}
                    chatGroupType={'create'}
                  />
                )}

                {secondComponent === 'edit-group-component' &&
                  Object.entries(groupChat).length > 0 && (
                    // <CustomModal
                    //   position="bottom"
                    //   onClose={() => {
                    //     setSecondComponent('chat-component');
                    //   }}>
                    <CreateGroup
                      groupChat={groupChat}
                      onClose={() => {
                        getConservation(tabType, chatSearchQry, filter, 1);

                        setSecondComponent('chat-component');
                      }}
                      // createGroup={()=>{

                      // }}

                      chatGroupType={'edit'}
                    />
                    // {/* </CustomModal> */}
                  )}

                {secondComponent === 'create-session-component' && (
                  // <CustomModal
                  //   position="bottom"
                  //   onClose={() => {
                  //     setSecondComponent('chat-component');
                  //   }}>
                  <CreateSession
                    onClose={() => {
                      setSecondComponent('chat-component');
                    }}
                    qaSession={allDataOfItemSession}
                  />
                  // </CustomModal>
                )}

                  {showBuildersComponent === 'inviteOutSideTheBuild' && (
                    <SendInvitation
                      onClose={() => {
                        setShowBuildersComponent('');
                      }}
                    />
                  )}

                {showBuildersComponent === 'AllBuilders' && (
                    <div className="pb-4">
                      <AllBuilders
                        onClose={() => {
                          setShowBuildersComponent('');
                        }}
                      />
                    </div>
                  )}

                {/* {buildersUserId && showComponent === 'profileComponent' && (
                <div className="pb-4 fix-width-forms">
                  <ProfileComponent
                    userId={buildersUserId}
                    show={false}
                    onClose={() => {
                      setShowComponent('');
                    }}
                    // hideCloseButton={true}
                    button={button}
                    userData={[]}
                    moderator={false}
                    // setParticipantType={setParticipantType}
                    VoxeetSDK={[]}
                    conferenceInfo={[]}
                    jdSpecialM={[]}
                    jdSpecialA={[]}
                  />
                </div>
              )} */}
              </>
            }
          />
          {showBuildersComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowBuildersComponent('');
              }}
            />
          )}

         
        </div>
      </div>
      {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>End Chat</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to end the chat ?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                closeChatRoom();
                handleClose();
              }}>
              yes
            </Button>
            <Button variant="primary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal> */}
    </div>
  );
};

export default Messages;
