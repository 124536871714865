"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefreshTokenError = exports.ParticipantNotFoundError = exports.BrowserNotSupportedError = exports.PeerDisconnectedError = exports.PeerConnectionFailedError = exports.CreateAnswerError = exports.RemoteDescriptionError = exports.PeerNotFoundError = exports.InsufficientPermissionsError = exports.ChromeExtensionNotInstalled = exports.ConferenceAtMaxCapacityError = exports.ConferenceUninitializedError = exports.SessionError = exports.VideoForwardingError = exports.UnsupportedError = exports.WebSocketError = exports.ServerError = exports.ParticipantError = exports.BrowserError = exports.MediaError = exports.MediaStreamError = exports.PeerError = exports.ConferenceError = void 0;
/**
 * The ConferenceError exception is responsible for conference-specific errors. The error includes a name property, which provides error details. The ConferenceError exception includes the following names:
 * - `ConferenceUninitializedError`: Occurs when a client tries to interact with a conference (using methods such as mute, leave, or startScreenShare) before a conference begins.
 * - `ConferenceAtMaxCapacityError`: Occurs when a client tries to join a conference that reached a capacity limit and no longer accepts new participants. For more information about the capacity limit, see the [Conference Capacity Limitations](/developers/interactivity-apis/guides/conference-capacity-limitations) article.
 * - `InsufficientPermissionsError`: Indicates that a client does not have permission to execute a specific operation.
 *
 * @noInheritDoc
 */
class ConferenceError extends Error {
    /**
     * @ignore
     * @param message - The error message
     * @param name - The name of the specific error
     */
    constructor(name, message) {
        super(message);
        this.message = message;
        this.name = name;
    }
}
exports.ConferenceError = ConferenceError;
/**
 * The PeerError exception informs about problems that occur during the call negotiation phase. These errors cause the call setup failure. The PeerError exception includes a name parameter, which provides more information about the error. PeerError includes the following names:
 * - `RemoteDescriptionError`: Occurs when there is a problem with providing the session description to the application during the call negotiation phase. The session description specifies the properties of the Dolby Interactivity API platform, including the media format. The specific errors for the RemoteDescriptionError exception can be retrieved by examining the `e` parameter of the exception. The `e` parameter represents underlyingError, which is the original error from WebRTC.
 * - `CreateAnswerError`: Occurs when the client SDK creates an answer to the remote description from the Dolby Interactivity API platform and configures its local description. The answer includes information about media attached to the session, codecs, options supported by the browser, and gathered [ICE](https://developer.mozilla.org/en-US/docs/Glossary/ICE) candidates. The local description specifies the properties of the local end of the connection, including the media format. The specific errors for the CreateAnswerError exception can be retrieved by examining the `e` parameter of the exception. The `e` parameter represents underlyingError, which is the original error from WebRTC.
 * - `ConnectionFailedError`: Usually caused by a network failure. It occurs when the peer connection state has one of the following values:
 *    - `failed`
 *    - `connecting` for more than 40 seconds
 *
 * The valid PeerConnectionState values are defined in the [MDN Web](https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState) documentation.
 *
 * **Note**: The Chrome browser does not support software H264 encoding and decoding on Android devices. Therefore, the Chrome usage may result in the `LocalDescriptionError` (present under the `CreateAnswerError` name) and `RemoteDescriptionError` exceptions on Android devices that do not support the H264 codec in their hardware. Due to [a known Chrome issue](https://bugs.chromium.org/p/chromium/issues/detail?id=1074421), this problem may also appear on other Android devices when a participant joins a conference as a listener.
 *
 * @noInheritDoc
 */
class PeerError extends Error {
    /**
     * @ignore
     * @param message - The error message
     * @param name - The name of the specific error, either `RemoteDescriptionError`, `CreateAnswerError`, or `ConnectionFailedError`
     * @param peerId - The peer ID
     * @param e - The original WebRTC error
     */
    constructor(name, message, peerId, e) {
        super(message);
        this.message = message;
        this.name = name;
        this.peerId = peerId;
        this.underlyingError = e;
    }
}
exports.PeerError = PeerError;
/**
 * The MediaStreamError exception is a collection of exceptions related to problems with accessing the local media (microphone and camera). The MediaStreamError exception includes a name property, which provides more information about the error. MediaStreamError includes the following names:
 *
 *  - `NotAllowedError`: Occurs when the user has not granted access to media devices (microphone, camera, and screen share).
 *    To resolve this problem, refer to the browser-specific instructions for enabling access to the media devices when the application is loaded into the browser. You should see a one-time pop-up window requesting the user to grant access to media devices. Users can use the settings menu to change the settings later.
 *
 *  - `OverconstrainedError`: Occurs when none of the user's media devices match the media constraints specified by the application. For example, the application requires 1920x1080 as a minimum resolution for the video capture device but the user only has a camera that supports 720p.
 *    For more details, refer to the exception name and messages related to this error.
 *    To resolve this problem, do not make the media constraints too restrictive when calling getUserMedia. If the application requires strict media constraints, report this error to the users notifying them that the media devices are not compatible with the application.
 *
 *  - `NotFoundError`: Occurs when there are no media tracks of the type specified for getUserMedia that satisfy the given constraints. For example, this error occurs when an application tries to call getUserMedia with the “video" media type on a computer that does not have a camera.
 *
 *  - `AbortError`: Occurs when the device is not working even though the user and the operating system have access to the hardware device, and there are no hardware issues causing NotReadableError.
 *
 *  - `NotReadableError`: Occurs when a device is not working even though the user has granted permission to use the matching device. This exception may be caused by a hardware error at the level of the operating system, browser, or web page.
 *
 *  - `SecurityError`: Occurs when the user media support is disabled on the [document](https://developer.mozilla.org/en-US/docs/Web/API/Document) on which getUserMedia() was called. The mechanism by which user media support is enabled and disabled is left up to the individual user agent.
 *
 *  - `TypeError`: Occurs when a list of specified constraints is empty or when all constraints are set to false. This exception can also happen if you try to call getUserMedia() in an insecure context.
 *
 * @noInheritDoc
 */
class MediaStreamError extends Error {
    /**
     * @ignore
     * @param e - The original WebRTC error
     */
    constructor(e) {
        super(e.message);
        this.message = e.message;
        this.name = e.name;
    }
}
exports.MediaStreamError = MediaStreamError;
/**
 * The MediaError exception occurs when the requested action related to audio or video streams is not allowed. For example, when a user who transmits a video stream tries to start a video stream.
 *
 * @noInheritDoc
 * */
class MediaError extends Error {
    /**
     * @ignore
     * @param msg - The error message
     */
    constructor(msg) {
        super(msg);
        this.message = msg;
        this.name = 'MediaError';
    }
}
exports.MediaError = MediaError;
/**
 * The BrowserError exception collects the browser specific errors. The BrowserError exception includes a name property, which provides more information about the error. The `NotSupportedError` name occurs when a browser does not support WebRTC, or more specifically, does not support the getUserMedia API. For more information about the supported browser platforms, see the [supported client platforms](/developers/interactivity-apis/client-sdk/articles/supported-client-platforms) article.
 *
 * @noInheritDoc
 */
class BrowserError extends Error {
    /**
     * @ignore
     * @param message - The error message
     * @param name - The name of the specific error
     */
    constructor(name, message) {
        super(message);
        this.message = message;
        this.name = name;
    }
}
exports.BrowserError = BrowserError;
/**
 * @ignore
 */
class ParticipantError extends Error {
    /**
     * @ignore
     */
    constructor(name, message) {
        super(message);
        this.message = message;
        this.name = name;
    }
}
exports.ParticipantError = ParticipantError;
/**
 * The ServerError exceptions occur when there is a communication issue between the Client SDK and the Dolby Interactivity APIs platform over the HTTP protocol. These errors correspond to the HTTP response codes defined in RFC2616. The most common are `4xx` and `5xx` errors:
 *
 * - `401` Unauthorized: occurs when the application tries to use an invalid token for the token authentication. Check if you have provided a valid token to the initializeToken API call and the refreshToken callback.
 *
 * - `404` Not Found: occurs when the client tries to access a conference that has already been destroyed in the platform.
 *
 * - `500` Internal Server Error: occurs when the platform encounters an error. Escalate this problem to the Dolby Interactivity API platform support team.
 *
 * Each error includes a specific HTTP code and a possible reason for the error.
 *
 * @noInheritDoc
 */
class ServerError extends Error {
    /**
     * @ignore
     * @param error - The original HTTP response codes defined in RFC2616
     */
    constructor(error) {
        super('ServerError');
        this.name = 'ServerError';
        this.data = error.response ? error.response.data : {};
        this.status = error.response ? error.response.status : '';
        this.statusText = error.response ? error.response.statusText : '';
        this.code = error.response ? error.response.data.error_code : '';
        this.reason = error.response ? error.response.data.error_reason : '';
        this.description = error.response ? error.response.data.error_description : '';
        this.message = this.description;
    }
}
exports.ServerError = ServerError;
/**
 * The Client SDK maintains the WebSocket connection with the Dolby Interactivity APIs platform after opening the session. When this connection is broken, the SDK raises the WebSocketError exception. This error usually occurs when the client disconnects from the network.
 *
 * @noInheritDoc
 */
class WebSocketError extends Error {
    /**
     * @ignore
     * @param message - The error message
     */
    constructor(message) {
        super();
        this.name = 'WebSocketError';
        this.message = message;
    }
}
exports.WebSocketError = WebSocketError;
/**
 * The UnsupportedError exception indicates that the client does not support the called functionality. To check unsupported functions in SDK 3.0, see the [Dolby Voice](/developers/interactivity-apis/client-sdk/articles/dolby-voice) article.
 *
 * @noInheritDoc
 */
class UnsupportedError extends Error {
    /**
     * @ignore
     * @param message - The error message
     */
    constructor(message = 'Your client does not support this functionality.') {
        super();
        this.name = 'UnsupportedError';
        this.message = message;
    }
}
exports.UnsupportedError = UnsupportedError;
/**
 * The VideoForwardingError exception occurs when a client who uses the 7.0 or earlier version of the MCU  tries to use the [VideoForwarding](/developers/interactivity-apis/guides/video-forwarding) feature. Video forwarding is available only for clients who use the 7.1 or later version of the MCU.
 * The VideoForwardingError exception also occurs when a client provides an incorrect value of the [max](/developers/interactivity-apis/reference/client-sdk/reference-javascript/conferenceservice#videoforwarding) parameter. The valid parameter's values are between 0 and 25 for desktop browsers and between 0 and 4 for mobile browsers. In the case of providing a value smaller than 0 or greater than the valid values, SDK triggers the error.
 *
 * @noInheritDoc
 */
class VideoForwardingError extends Error {
    /**
     * @ignore
     */
    constructor(message) {
        super();
        this.name = 'VideoForwardingError';
        this.message = message;
    }
}
exports.VideoForwardingError = VideoForwardingError;
/**
 * @ignore
 */
class SessionError extends Error {
    /**
     * @ignore
     */
    constructor(message) {
        super();
        this.message = message;
        this.name = 'SessionError';
    }
}
exports.SessionError = SessionError;
/**
 * @ignore
 */
const PeerNotFoundError = (peerId) => new PeerError('NotFoundError', 'Unable to find peer.', peerId);
exports.PeerNotFoundError = PeerNotFoundError;
/**
 * @ignore
 */
const InsufficientPermissionsError = () => new ConferenceError('InsufficientPermissionsError', 'Insufficient permissions for this operation');
exports.InsufficientPermissionsError = InsufficientPermissionsError;
/**
 * @ignore
 */
const RemoteDescriptionError = (e, peerId) => new PeerError('RemoteDescriptionError', e.message, peerId, e);
exports.RemoteDescriptionError = RemoteDescriptionError;
/**
 * @ignore
 */
const CreateAnswerError = (e, peerId) => new PeerError('CreateAnswerError', e.message, peerId, e);
exports.CreateAnswerError = CreateAnswerError;
/**
 * @ignore
 */
const PeerConnectionFailedError = (peerId) => new PeerError('PeerConnectionFailedError', 'Peer connection has failed to connect.', peerId);
exports.PeerConnectionFailedError = PeerConnectionFailedError;
/**
 * @ignore
 */
const PeerDisconnectedError = (peerId) => new PeerError('PeerConnectionDisconnectedError', 'Peer connection has been disconnected', peerId);
exports.PeerDisconnectedError = PeerDisconnectedError;
/**
 * @ignore
 */
const ConferenceUninitializedError = () => new ConferenceError('UninitializedError', 'Conference is not initialized.');
exports.ConferenceUninitializedError = ConferenceUninitializedError;
/**
 * @ignore
 */
const ConferenceAtMaxCapacityError = () => new ConferenceError('MaxCapacityError', 'Conference is at maximum capacity.');
exports.ConferenceAtMaxCapacityError = ConferenceAtMaxCapacityError;
/**
 * @ignore
 */
const ChromeExtensionNotInstalled = () => new ConferenceError('ChromeExtensionError', 'Chrome Web Extension is not installed');
exports.ChromeExtensionNotInstalled = ChromeExtensionNotInstalled;
/**
 * @ignore
 */
const BrowserNotSupportedError = () => new BrowserError('NotSupportedError', 'This operation is not supported on this browser');
exports.BrowserNotSupportedError = BrowserNotSupportedError;
/**
 * @ignore
 */
const ParticipantNotFoundError = (peerId) => new ParticipantError('NotFoundError', 'Unable to find participant: ' + peerId);
exports.ParticipantNotFoundError = ParticipantNotFoundError;
/**
 * @ignore
 */
const RefreshTokenError = () => new SessionError('Refresh token is null or undefined, please check your callback.');
exports.RefreshTokenError = RefreshTokenError;
