import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import CustomSlidingButtons from '../CustomSlidingButtons';
import CustomTabs from '../CustomTabs';
import {labels} from '../../Constants/Translations';
import {DEFAULT_IMAGE, selected_lang} from '../../Utils/env';
import commonApi from '../../Services/CommonService';
import {
  ShareablesYellowIcon,
  ShareYellowIcon,
  NoForm,
  coffeecup,
  Dollar,
} from '../../Constants/Images';
import {Button, Col, Form, Row} from 'react-bootstrap';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {
  arrayColumn,
  filteringUsers,
  fixName,
  formatString,
} from '../../Utils/commonUtils';
import OtherUserImages from '../Common/OtherUserImages';
import Moment from 'react-moment';
import ShareAblesSessions from './ShareAblesSessions';
import ShreAblesEvent from './ShreAblesEvent';
import ShareAableOpportunity from './ShareAableOpportunity';
import CustomModal from '../CustomModal';
import {listForDropDown} from '../../Utils/commonUtils';

import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import {errorMessage} from '../../Utils/ToastMessages';
import Scrollbars from 'react-custom-scrollbars';

const MessagesShareAbles = props => {
  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);
  const [userData, setUserData] = useState([]);
  // const [apiResponse, setApiResponse] = useState([]);
  const [mineAreas, setMineAreas] = useState([]);
  const [mineCat, setMineCat] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [model, setModel] = useState('');
  const [shareAbleItem, setShareAbleItem] = useState('');
  const [type, setType] = useState('');

  // const [activeTab, setActiveTab] = useState('App');

  const [snapShopData, setSnapShopData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [unknownData, setUnknownData] = useState('');
  const [showModel, setShowModel] = useState('');
  const [tabType, setTabType] = useState([labels.ELEMENT[selected_lang]]);
  // const [searchQry, setSearchQry] = useState('');
  const [shareUrl, setShareUrl] = useState('');

  const [dropdownValue, setdropdownValue] = useState([
    listForDropDown[0].title,
  ]);
  const [isCheckWorkSpace, setIsCheckWorkSpace] = useState([]);
  const [isCheckValueWorkSpace, setIsCheckValueWorkSpace] = useState([]);
  let mineAreaUser = [];
  let CategoryOfUser = [];

  // const handleTabClick = tab => {
  //   setActiveTab(tab);
  // };

  let workSpaceItem = localStorage.getItem('activeWorkspaceItem')
    ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
    : [];

  const dropDownSelection = e => {
    console.log(e, '////////////////////');
    const {id, innerText} = e.target;

    setAreas_Filter(id);
    setdropdownValue([innerText]);
  };

  // const ShareAbleRefer = (type, data) => {
  //   // alert('Hehe');
  //   // if (type == 'snapShot') {
  //   //   setProperComponent('shareAble_snapShot');
  //   //   setItem(data);
  //   // } else if (type == 'profile') {
  //   //   // setTypeOfShareAbles(type);
  //   //   setItem(data);
  //   //   setProperComponent('shareAble_Profile');
  //   // } else {
  //   //   setTypeOfShareAbles(type);
  //   //   setItem(data);
  //   //   setProperComponent('shareAble_refer');
  //   // }
  // };

  useEffect(() => {
    if (Object.entries(workSpaceItem).length > 0) {
      const newIsCheck = [];
      const newIsCheckValue = [];

      workSpaceItem.forEach((item, index) => {
        newIsCheck.push(item.id); // Assuming you want to add 'id' to isCheck
        newIsCheckValue.push(item.name); // Assuming you want to add 'value' to isCheckValue
      });

      // Update the state arrays after iterating through workSpaceItem
      setIsCheckWorkSpace(newIsCheck);
      setIsCheckValueWorkSpace(newIsCheckValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const showData = (val, item, type) => {
  //   console.log(val, 'valueeeeeeeeeeee');
  //   console.log(item, 'insied function item');

  //   if (val === 'title') {
  //     if (type === 'event') {
  //       return item.session_title;
  //     } else if (type === 'session') {
  //       return item.session_title;
  //     } else if (type === 'snapShot') {
  //       return item.title;
  //     } else if (type === 'opportunity') {
  //       return item.title;
  //     } else if (type === 'appointment') {
  //       return item.title;
  //     } else if (type === 'message') {
  //       return item.group_name;
  //     } else if (type === 'conference') {
  //       return item.session_title;
  //     }
  //   }

  //   if (val === 'description') {
  //     if (type === 'event') {
  //       return item.session_description;
  //     } else if (type === 'session') {
  //       return item.session_description;
  //     } else if (type === 'snapShot') {
  //       return item.description;
  //     } else if (type === 'opportunity') {
  //       return item.description;
  //     } else if (type === 'appointment') {
  //       return item.description;
  //     } else if (type === 'conference') {
  //       return item.session_description;
  //     }
  //   }

  //   if (val === 'date') {
  //     if (type === 'event') {
  //       return moment(item.session_start_time).format('ddd, MMMD @ h:mm A');
  //     } else if (type === 'session') {
  //       return moment(item.session_start_time).format('ddd, MMMD @ h:mm A');
  //     } else if (type === 'snapShot') {
  //       if (item.date) {
  //         return (
  //           item.date != '0000-00-00' &&
  //           moment(item.date).format('ddd, MMMD @ h:mm A')
  //         );
  //       }
  //     } else if (type === 'opportunity') {
  //       return moment(item.created_at).format('ddd, MMMD @ h:mm A');
  //     } else if (type === 'appointment') {
  //       return moment(item.date).format('ddd, MMMD @ h:mm A');
  //     } else if (type === 'message') {
  //       return moment(item.created_at).format('ddd, MMMD @ h:mm A');
  //     } else if (type === 'conference') {
  //       return moment(item.session_start_time).format('ddd, MMMD @ h:mm A');
  //     }
  //   }

  //   if (val === 'image') {
  //     if (type === 'event') {
  //       return item.image.slider_images;
  //     } else if (type === 'session') {
  //       return item.image;
  //     } else if (type === 'snapShot') {
  //       return item.images[0];
  //     } else if (type === 'opportunity') {
  //       return DEFAULT_IMAGE;
  //     } else if (type === 'appointment') {
  //       return DEFAULT_IMAGE;
  //     } else if (type === 'message') {
  //       return item.group_image;
  //     } else if (type === 'conference') {
  //       return DEFAULT_IMAGE;
  //     }
  //   }

  //   if (val === 'location') {
  //     if (type === 'event') {
  //       return item.address;
  //     } else if (type === 'session') {
  //       return item.address;
  //     } else if (type === 'snapShot') {
  //       return;
  //     } else if (type === 'opportunity') {
  //       return;
  //     } else if (type === 'appointment') {
  //       return;
  //     } else if (type === 'message') {
  //       return;
  //     } else if (type === 'conference') {
  //       return;
  //     }
  //   }
  // };
  useEffect(() => {
    getUserData();
    getAccomplishment();
    get_user_all_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dropdownValue.includes('Network')) {
      setTabType(labels.ELEMENT[selected_lang]);
    }
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    get_user_all_data('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValue, tabType, props.workSpaceDropDownIds]);

  // const WorkspaceSliding = [
  //   {title: 'personal'},
  //   {title: 'Work 1'},
  //   {title: 'Woek 2'},
  //   {title: 'Work 3'},
  //   {title: 'Work 4'},
  // ];
  // const WorkspaceSlidingPage = [
  //   {title: 'All'},
  //   {title: 'Profile'},
  //   {title: 'Scheduler'},
  //   {title: 'Offerings'},
  //   {title: 'Opportunities'},
  //   {title: 'Gifts'},
  //   {title: 'Forms'},
  // ];

  // const RecentSliding = [{title: 'All'}, {title: 'Types'}];
  // const SessionsSliding = [
  //   {title: 'Sessions'},
  //   {title: 'Sponsorship'},
  //   {title: 'Demographics'},
  //   {title: 'Partners'},
  // ];
  // const CircleSliding = [
  //   {title: 'All'},
  //   {title: 'Circle 1'},
  //   {title: 'Circle 2'},
  //   {title: 'Circle 3'},
  //   {title: 'Circle 4'},
  // ];
  // const NetworkSliding = [
  //   {title: 'All'},
  //   {title: 'Circle 1'},
  //   {title: 'Circle 2'},
  //   {title: 'Circle 3'},
  //   {title: 'Circle 4'},
  // ];
  // const EventsSliding = [
  //   {title: 'Events'},
  //   {title: 'Sponsorship'},
  //   {title: 'Demographics'},
  //   {title: 'Partners'},
  //   {title: 'Gallery'},
  // ];
  // const OpportunitiesSliding = [
  //   {title: 'Opportunity '},
  //   {title: 'Types'},
  //   {title: 'Partners'},
  // ];
  // const AppointmentsSliding = [
  //   {title: 'All '},
  //   {title: 'Appointment '},
  //   {title: 'Types'},
  // ];
  // const GroupChatsSliding = [
  //   {title: 'Chats '},
  //   {title: 'Demographics'},
  //   {title: 'Sponsorships'},
  // ];
  // const SnapshotsSliding = [{title: 'Profile '}, {title: 'Demographics'}];
  // const LinksSliding = [
  //   {title: 'Media '},
  //   {title: 'Social'},
  //   {title: 'Profile'},
  //   {title: 'All other types'},
  // ];
  // const PaymentsSliding = [
  //   {title: 'Tips '},
  //   {title: 'Offerings'},
  //   {title: 'Adhoc'},
  // ];

  // const name = localStorage.getItem('userName');
  // const lowerName = name.toLowerCase();

  // const typeOfLink = '';

  // const Element = [{title: 'Invest in Ghana'}];
  // const Page = [{title: 'Invest in Ghana'}];

  const getAccomplishment = () => {
    var payLoad = {
      categories: [],
      user_id: localStorage.getItem('user'),
    };
    commonApi
      .get_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          setSnapShopData(res.accomplishment);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  console.log('snapShopData', snapShopData);
  const getUserData = () => {
    var data = {user_id: localStorage.getItem('id')};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserData(res);
          setMineAreas(res.mine_areas);
          setMineCat(res.mine_areas);

          // {
          //   res.mine_areas.length > 0 &&
          //     res.mine_areas.map(item => {
          //       mineAreaUser.push(item.title);
          //     });
          // }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_user_all_data = qry => {
    // console.log(props.workSpaceItem,'props.workSpaceItemprops.workSpaceItem')
    const payload = {
      // page: '',
      search_query: qry,
      type:
        tabType === labels.PAGE[selected_lang] && dropdownValue === 'Workspace'
          ? ['workspace']
          : tabType === labels.ELEMENT[selected_lang] &&
            dropdownValue === 'Workspace'
          ? [
              'sessions',
              'events',
              'opportunities',
              'snapshots',
              'appointments',
              'group_chats',
            ]
          : dropdownValue.includes('Sessions')
          ? ['sessions']
          : dropdownValue.includes('Events')
          ? ['events']
          : dropdownValue === 'Opportunities'
          ? ['opportunities']
          : dropdownValue.includes('Appointments')
          ? ['appointments']
          : dropdownValue.includes('Group Chats')
          ? ['group_chats']
          : // :
          // dropdownValue.includes('Workspace')
          // ? ['workspace']

          dropdownValue === 'Snapshots'
          ? ['snapshots']
          : dropdownValue === 'Network'
          ? ['networks']
          : dropdownValue === 'Links'
          ? ['links']
          : dropdownValue === 'Forms'
          ? ['Forms']
          : [
              'sessions',
              'events',
              'opportunities',
              'snapshots',
              'appointments',
              'group_chats',
            ],
      category_id: props.sessionCatgory ? props.sessionCatgory : '',
      events: dropdownValue.includes('Sessions')
        ? false
        : dropdownValue.includes('Events')
        ? true
        : '',
      sessions: dropdownValue.includes('Sessions')
        ? true
        : dropdownValue.includes('Events')
        ? false
        : '',
      categories: props.snapshotFilter,
      //opportunity filter

      amount:
        props.opportunityFilter && props.opportunityFilter.amount
          ? props.opportunityFilter.amount
          : '',
      frequency:
        props.opportunityFilter && props.opportunityFilter.frequency
          ? props.opportunityFilter.frequency
          : '',
      latitude:
        props.opportunityFilter && props.opportunityFilter.latitude
          ? props.opportunityFilter.latitude
          : '',
      like: false,
      location:
        props.opportunityFilter && props.opportunityFilter.location
          ? props.opportunityFilter.location
          : '',
      longitude:
        props.opportunityFilter && props.opportunityFilter.longitude
          ? props.opportunityFilter.longitude
          : '',

      opportunity_types:
        props.opportunityFilter && props.opportunityFilter.opportunity_types
          ? props.opportunityFilter.opportunity_types
          : '',
      page: 1,

      //   vaccinated: props.opportunityFilter.vaccinated ? 'yes' : 'no',
      //   virtual: props.opportunityFilter.virtual ? 'yes' : 'no',
      vaccinated: '',
      //network filter

      // gender: props.gender.toString(),
      gender: '',
      // otherIdentifires: '',
      otherIdentifires: '',
      // ethnicities: props.ethnicityId.toString(),
      ethnicities: '',
      city_states:
        props.networkFilter && props.networkFilter.city_states
          ? props.networkFilter.city_states
          : '',

      on_boarding_areas:
        props.networkFilter && props.networkFilter.on_boarding_areas
          ? props.networkFilter.on_boarding_areas
          : '',
      appointment_types:
        props.networkFilter && props.networkFilter.appointment_types
          ? props.networkFilter.appointment_types
          : '',
      on_boarding_categories:
        props.networkFilter && props.networkFilter.on_boarding_categories
          ? props.networkFilter.on_boarding_categories
          : '',

      on_boarding_areas_filter:
        props.networkFilter && props.networkFilter.on_boarding_areas_filter
          ? props.networkFilter.on_boarding_areas_filter
          : '',
      other_identifier_filter:
        props.networkFilter && props.networkFilter.other_identifier_filter
          ? props.networkFilter.other_identifier_filter
          : '',

      appointment_type_ids:
        props.networkFilter && props.networkFilter.appointment_type_ids
          ? props.networkFilter.appointment_type_ids
          : '',
      areas:
        props.networkFilter && props.networkFilter.areas
          ? props.networkFilter.areas
          : '',
      // categories:
      //   props.networkFilter && props.networkFilter.categories
      //     ? props.networkFilter.categories
      //     : '',
      hometown_location:
        props.networkFilter && props.networkFilter.hometown_location
          ? props.networkFilter.hometown_location
          : '',
      // search_query: '',
      travel_location:
        props.networkFilter && props.networkFilter.travel_location
          ? props.networkFilter.travel_location
          : '',
      // latitude:
      //   props.networkFilter && props.networkFilter.latitude
      //     ? props.networkFilter.latitude
      //     : '',
      // longitude:
      //   props.networkFilter && props.networkFilter.longitude
      //     ? props.networkFilter.longitude
      //     : '',
      //workspace filter
      workspace_ids:
        isCheckWorkSpace && isCheckWorkSpace.length > 0
          ? isCheckWorkSpace.toString(',')
          : '',
    };

    commonApi
      .get_user_all_data(payload)
      .then(res => {
        if (res) {
          setAllData(res.all_record);
          console.log(res, '@@@@@@||||||||||');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const showNoreordFound = allData && allData.length > 0 ? true : false;
  const Messages = [{title: 'Invest in Ghana'}];

  if (mineCat.length > 0) {
    CategoryOfUser = userData.mine_categories.map(item => item.title);
  }
  if (mineAreas.length > 0) {
    mineAreaUser = userData.mine_areas.map(item => item.title);
  }
  const ElementDesign = (type, data) => {
    return (
      <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
        <p
          className="p-reg m-0 preview-message as-link"
          onClick={() => {
            console.log(type, 'typeeeeeeeeeeeeeee');
            setShareAbleItem(data);
            setType(type);
            setShowModel('preview');
          }}>
          Preview
        </p>
        <div className="d-flex align-items-center">
          {/* <img
            className="ScanQr as-link"
            src={ShareYellowIcon}
            alt="scanQr"
            onClick={() => {
              ShareAbleRefer(type, data);
            }}
          />
          <img
            className="sharableYellow as-link"
            src={ShareablesYellowIcon}
            alt="shareable"
            onClick={() => {
              ShowModelLinks('link_model', type, data);
            }}
          /> */}

          <span
            onClick={() => {
              props.sendAnyThink(type, data);
              props.onClose();
            }}
            className="sharableYellow as-link send-message form-icon msg_send_btn send-btn">
            <i className="icon-plane" style={{fontSize: '23px'}} />
          </span>
          {/* <img
            className="sharableYellow as-link send-message"
            src={require('../../Assets/images/send-message.png')}
            alt="send-message"
            onClick={() => {
              
            }}
          /> */}
        </div>
      </div>
    );
  };
  const PageDesign = () => {
    return (
      <div className="mt-2 p20-horizental show-on-public">
        <div className="d-flex align-items-center justify-content-center">
          <p className="p-reg m-0">Show on Public Page</p>
          <div>
            <Form.Check
              type="switch"
              className="switchBlock"
              id="custom-switch"
            />
          </div>
        </div>
      </div>
    );
  };

  const componentDecesion = () => {};

  // const ShowModelLinks = (model, type, data) => {
  //   const typeSelecttion =
  //     type === 'conference'
  //       ? 'session'
  //       : type === 'snapShot'
  //       ? 'snapshot'
  //       : type === 'message'
  //       ? 'group_chat'
  //       : type;
  //   if (type === 'profile' || type === 'referperson') {
  //     setShareUrl(
  //       'www.' +
  //         ENV_HOST +
  //         'roiforpros.com/' +
  //         data.unique_user_name +
  //         '/profile?id=' +
  //         data.id,
  //     );
  //     setModel(model);
  //   } else {
  //     setShareUrl(
  //       'www.' +
  //         ENV_HOST +
  //         'roiforpros.com/' +
  //         lowerName +
  //         '/' +
  //         typeSelecttion +
  //         '/invite?id=' +
  //         data.id,
  //     );
  //     setModel(model);
  //     setShowModel('');
  //   }
  // };
  //   console.log(dropdownVadropdowdropdowdropdownValue');

  const handleClickList = e => {
    const {id, value} = e.target;
    if (!isCheckWorkSpace.includes(id)) {
      setIsCheckWorkSpace([...isCheckWorkSpace, id]);
      setIsCheckValueWorkSpace([...isCheckValueWorkSpace, value]);
    } else {
      if (isCheckWorkSpace.length === 1) {
        errorMessage('One workspace should be selected. ');
      } else {
        setIsCheckWorkSpace(isCheckWorkSpace.filter(item => item !== id));
        setIsCheckValueWorkSpace(
          isCheckValueWorkSpace.filter(item => item !== value),
        );
      }
    }
  };

  return (
    <CustomModal
      position="bottom"
      className={'share-links-modal unset'}
      onClose={() => props.onClose()}
      style={{marginTop: '15%', maxHeight: '100%'}}>
      <div className="new-session-modal-wrapper text-left shareablesPreview_Modal m-0 w-100 primary_panel_content">
        {/* <div className="primary_panel_content"> */}

        {/* {workSpaceItem.length > 0 && (
          <div
            className="select-wrapper custom-dropdown as-link"
            onClick={() => {
              if (workSpaceItem.length > 1) {
                setShowModel('work-place-list');
              } else {
              }
            }}>
            <p className="p-reg m-0 single-line-text">
              {isCheckValueWorkSpace.length > 0
                ? isCheckValueWorkSpace.join(', ')
                : ''}
            </p>
          </div>
        )} */}
        <>
          <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3 w-100">
            <CustomSlidingButtons
              items={listForDropDown}
              activeButtons={areas_Filter}
              onItemClick={e => {
                dropDownSelection(e);
              }}
              showItems={3}
            />
          </div>
        </>
        <div className="refferal-list-wrapper">
          <Scrollbars autoHide>
            <div className="create-ticket-tabs d-flex justify-content-center mt-0">
              <CustomTabs
                darkmode
                active={tabType}
                onSelect={item => setTabType(item)}
                tabs={
                  dropdownValue.includes('Recent') ||
                  dropdownValue.includes('Network')
                    ? [
                        labels.ELEMENT[selected_lang],
                        labels.MESSAGES[selected_lang],
                      ]
                    : [
                        labels.ELEMENT[selected_lang],
                        labels.PAGE[selected_lang],
                        labels.MESSAGES[selected_lang],
                      ]
                }
              />
            </div>

            <div style={{position: 'relative', flex: 1}}>
              {tabType === labels.ELEMENT[selected_lang] && (
                <div>
                  {showLoader && <LoaderSpinner />}
                  {showNoreordFound && (
                    <>
                      <div className="shareables-items">
                        {Object.entries(allData).length > 0 &&
                          allData.map((item, index) => (
                            <>
                              {item.data_type === 'session' && (
                                <>
                                  <ShareAblesSessions
                                    key={`session-item-${index}`}
                                    item={item}
                                    heart={''}
                                    componentDecesion={componentDecesion}
                                    ElementDesign={ElementDesign}
                                  />
                                  {/* {ElementDesign()} */}
                                </>
                              )}
                              {item.data_type === 'event' && (
                                <>
                                  <ShreAblesEvent
                                    key={`event-item-${index}`}
                                    item={item}
                                    index={index}
                                    // eventDetails={eventDetails}
                                    componentDecesion={componentDecesion}
                                    ElementDesign={ElementDesign}
                                  />
                                </>
                              )}
                              {item.data_type === 'opportunity' && (
                                <>
                                  <ShareAableOpportunity
                                    key={`opportunity-item-${index}`}
                                    setShowButton={setUnknownData}
                                    unknownData={unknownData}
                                    onShare={() => {
                                      // setProperComponent('refer-opportunity');
                                    }}
                                    setChatData={setUnknownData}
                                    handleItem={componentDecesion}
                                    item={item}
                                    ElementDesign={ElementDesign}
                                  />
                                </>
                              )}
                              {item.data_type === 'appointment' && (
                                <>
                                  <div
                                    className="refferals-list as-link card-container w-100"
                                    key={'www' + index}>
                                    <div className="padd-with-borderContaier">
                                      <div className="refferals-list-item new-refferal-list mb-0">
                                        <div className="refferal-content">
                                          <h2>
                                            {item.creator_details &&
                                              fixName(
                                                item.creator_details.first_name,
                                                item.creator_details.last_name,
                                              )}
                                          </h2>
                                          <span className="refferal-auther">
                                            {item.appointment_type_title}
                                          </span>
                                          {/* <p>Think there may be some synergy</p> */}
                                          <span className="refferal-date">
                                            {/* {moment(item.start_date).format(
                                        'MM/DD/YYYY',
                                      )}{' '} */}
                                            {item.duration + 'min'}
                                          </span>
                                        </div>
                                        <div className="refferal-img">
                                          <img
                                            src={
                                              item.creator_details &&
                                              item.creator_details.profile_img
                                                ? item.creator_details
                                                    .profile_img
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />
                                          <p className="refferal-info-que text-end">
                                            {item.cost
                                              ? '$' + item.cost
                                              : 'Free'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {ElementDesign('appointment', item)}
                                  </div>
                                </>
                              )}
                              {item.data_type === 'group_chat' && (
                                <>
                                  <div className="messages-users-list card-container">
                                    <div
                                      key={`msg-${index}`}
                                      className={
                                        'message-user-item no-link padd-with-borderContaier m-0'
                                      }>
                                      <div
                                        className="user-img as-link"
                                        // onClick={() => {
                                        //   amplitudeEvent('OPEN_CHAT_ROOM');
                                        //   handleOpenChatRoom(item);
                                        // }}
                                      >
                                        <img
                                          src={
                                            item.group_image !== ''
                                              ? item.group_image
                                              : item[
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail'
                                                ]['profile_img']
                                              ? item[
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail'
                                                ]['profile_img']
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                        {item.chat_type === 'question' && (
                                          <span>
                                            <i className="fa-solid fa-q"></i>
                                          </span>
                                        )}
                                        {item.chat_type === 'opportunity' && (
                                          <span className="icon icon-posts"></span>
                                        )}
                                        {item.chat_type === 'event' && (
                                          <span className="icon-tickets"></span>
                                        )}
                                        {item.chat_type === 'session' && (
                                          <span className="icon-video-camera-2"></span>
                                        )}
                                        {/* <span className="fa-solid fa-shop"></span> */}
                                      </div>
                                      <div
                                        className="user-content as-link"
                                        onClick={() => {
                                          // handleOpenChatRoom(item);
                                        }}>
                                        <h2 className="single-line-text">
                                          {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                          {item.group_name !== ''
                                            ? item.group_name
                                            : fixName(
                                                item[
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail'
                                                ]['first_name'],
                                                item[
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail'
                                                ]['last_name'],
                                              )}
                                        </h2>

                                        {formatString(item.last_message)}

                                        <div className="message-date-info">
                                          <span className="message-date">
                                            <Moment format="MM/DD/YY HH:mm A">
                                              {item.last_message_time
                                                ? item.last_message_time
                                                : item.created_at}
                                            </Moment>
                                          </span>
                                          {/* {renderMessageUsers(item.moreUsers)} */}
                                        </div>
                                      </div>

                                      {!item.hasOwnProperty(
                                        'invitation_type',
                                      ) &&
                                        item.hasOwnProperty('group_detail') && (
                                          <OtherUserImages
                                            otherUsers={filteringUsers(
                                              item.audience_detail.concat(
                                                item.moderator_detail,
                                                item.creator_detail
                                                  ? [item.creator_detail]
                                                  : [item.creator_details],
                                              ),
                                              arrayColumn(
                                                item.group_detail,
                                                'id',
                                              ),
                                            )}
                                          />
                                        )}
                                      {!item.hasOwnProperty(
                                        'invitation_type',
                                      ) &&
                                        !item.hasOwnProperty(
                                          'opportunity_invitaion_status',
                                        ) &&
                                        item.hasOwnProperty('opportunity_id') &&
                                        item.chat_type === 'opportunity' && (
                                          <OtherUserImages
                                            otherUsers={filteringUsers(
                                              item.other_persons_opportunity_chat_room.concat(
                                                item.team_member_details,
                                                item.creator_detail
                                                  ? [item.creator_detail]
                                                  : [item.creator_details],
                                              ),
                                              arrayColumn(
                                                [
                                                  ...item.other_persons_opportunity_chat_room,
                                                  ...item.team_member_details,
                                                  item.creator_details,
                                                ],
                                                'id',
                                              ),
                                            )}
                                          />
                                        )}
                                    </div>
                                    {ElementDesign('message', item)}
                                  </div>
                                </>
                              )}

                              {item.data_type === 'network' && (
                                <>
                                  <div className="as-link card-container w-100">
                                    <div className="padd-with-borderContaier">
                                      <div className="profile-img-wrapper align-items-center justify-content-start">
                                        <div className="profile-img">
                                          <img
                                            src={
                                              item.profile_img
                                                ? item.profile_img
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />
                                        </div>

                                        <div className="profile-name flex-0">
                                          <div className="profile-name-headings">
                                            <div className="">
                                              <h2 className="m-0 mb-1 p-bold">
                                                {item && item.first_name}
                                                {item && item.last_name}
                                              </h2>
                                              {item.on_boarding_areas.length >
                                                0 && (
                                                <h3 className="p-reg m-0">
                                                  {item.on_boarding_areas
                                                    .map(area => area.title)
                                                    .join(', ')}
                                                </h3>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="profile-content-infos">
                            {CategoryOfUser.join(', ')}

                            <div className="other-infos">
                              <div className="profile-address as-link profile-address">
                                <i className="fas fa-home" />
                                {userData &&
                                userData.data &&
                                userData.data.home_location
                                  ? userData.data.home_location
                                  : 'Add Location'}
                              </div>
                              <div className="profile-address as-link profile-address">
                                <i className="icon-aroplane" />
                                {userData &&
                                userData.data &&
                                userData.data.current_location
                                  ? userData.data.current_location
                                  : 'Add Location'}
                              </div>
                            </div>
                            <p className="p-reg m-0">
                              {userData && userData.data && userData.data.bio}
                            </p>

                           
                          </div> */}
                                    </div>
                                    {ElementDesign('profile', item)}
                                  </div>
                                </>
                              )}

                              {item.data_type === 'accomplishment' && (
                                <>
                                  <div
                                    className="card-container shareables-snapshots"
                                    key={index + 'userListing'}>
                                    <div className="padd-with-borderContaier as-link">
                                      <div className=" d-flex align-items-center mb-3">
                                        <div className="snapshotsImage as-link">
                                          <img src={item.images[0]} alt="" />
                                        </div>
                                        <div
                                          className="snapshots-content as-link"
                                          onClick={() => {
                                            // setSnapShotId(item);
                                            // setShowComponent('SnapShotDetail');
                                          }}>
                                          <h2 className="single-line-text">
                                            {item.title}
                                          </h2>
                                          {item.category_name && (
                                            <p className="p-reg single-line-text category-name">
                                              {item.category_name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      {item.description && (
                                        <p className="p-reg truncate_2_lines">
                                          {item.description}
                                        </p>
                                      )}
                                    </div>
                                    {ElementDesign('snapShot', item)}
                                  </div>
                                </>
                              )}
                            </>
                          ))}
                        {/* <div
                      className="session-item as-link card"
                      style={{padding: '0px 20px'}}>
                      <div style={{padding: '20px 0px'}}>
                        <div className="session-heading">
                          <h2>Invest in Ghana</h2>
                          <div className="events-place-like align-items-start">
                            <div className="w-100">
                              <span className="users-text usercount-type">
                                <img
                                  src={UserGroupMini}
                                  height={16}
                                  width={16}
                                  alt=""
                                />
                                <span className="userCount">3/1</span>
                                <span className="category-text">
                                  Investigator
                                </span>
                              </span>
                            </div>
                            <div className="session-share-favs">
                              <span className="event-heart as-link">
                                <i className="fas fa-share" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="persons-img-list">
                          <div className="img-item">
                            <img src={DEFAULT_IMAGE} alt="" />
                          </div>
                          <div className="img-item">
                            <img src={DEFAULT_IMAGE} alt="" />
                          </div>
                          <div className="img-item">
                            <img src={DEFAULT_IMAGE} alt="" />
                          </div>
                          <div className="img-item">
                            <img src={DEFAULT_IMAGE} alt="" />
                          </div>
                        </div>

                        <div className="persons-name-list">
                          <ul>
                            <li>
                              <span className="owner-icon">
                                <i className="fas fa-crown" />
                              </span>
                              Muhammad Ali
                            </li>
                            <li>
                              Muhammad Ali
                              <span className="speaker-icon">
                                <i className="fas fa-microphone" />
                              </span>
                            </li>
                            <li>Muhammad Ali</li>
                          </ul>
                        </div>

                        <div className="events-place-like session-address-wrapper mt-2">
                          <span className="address as-link">
                            <i className="fas fa-location-dot"></i> Linford
                            London.
                          </span>
                          <span>$10</span>
                          <div className="session-share-favs">
                            <span className="event-heart as-link">
                              <img
                                src={ShareablesYellowIcon}
                                alt="shareable-yellow"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="as-link card-container w-100">
                      <div className="padd-with-borderContaier">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="">
                            <p className="p-bold mb-1">Jasmine Patterson</p>
                            <p className="p-reg m-0">Business Consulting +2</p>
                          </div>
                          <img
                            src={require('../../Assets/images/default-img.jpg')}
                            className="mini-image"
                            alt="img"
                          />
                        </div>
                      </div>
                      <div className="events-place-like session-address-wrapper mt-2 p20-horizental">
                        <span className="address as-link">
                          <i className="fas fa-location-dot"></i> Linford
                          London.
                        </span>
                        <span>$10</span>
                        <div className="session-share-favs">
                          <span className="event-heart as-link">
                            <img
                              src={ShareablesYellowIcon}
                              alt="shareable-yellow"
                            />
                          </span>
                        </div>
                      </div>
                    </div>{' '} */}
                      </div>
                    </>
                  )}
                </div>
              )}

              {tabType === labels.PAGE[selected_lang] && (
                <div className="">
                  {showLoader && <LoaderSpinner />}
                  {showNoreordFound ? (
                    <>
                      <div className="shareables-items">
                        {Object.entries(allData).length > 0 &&
                          allData.map((item, index) => (
                            <>
                              {item.data_type === 'session' && (
                                <>
                                  <ShareAblesSessions
                                    key={`session-item-${index}`}
                                    item={item}
                                    heart={''}
                                    componentDecesion={componentDecesion}
                                    ElementDesign={PageDesign}
                                  />
                                  {/* {ElementDesign()} */}
                                </>
                              )}
                              {item.data_type === 'event' && (
                                <>
                                  <ShreAblesEvent
                                    key={`event-item-${index}`}
                                    item={item}
                                    index={index}
                                    // eventDetails={eventDetails}
                                    componentDecesion={componentDecesion}
                                    ElementDesign={PageDesign}
                                  />
                                </>
                              )}
                              {item.data_type === 'opportunity' && (
                                <>
                                  <ShareAableOpportunity
                                    key={`opportunity-item-${index}`}
                                    setShowButton={setUnknownData}
                                    onShare={() => {
                                      // setProperComponent('refer-opportunity');
                                    }}
                                    setChatData={setUnknownData}
                                    handleItem={componentDecesion}
                                    item={item}
                                    ElementDesign={PageDesign}
                                  />
                                </>
                              )}
                              {item.data_type === 'appointment' && (
                                <>
                                  <div
                                    className="refferals-list card-container as-link"
                                    key={'www' + index}>
                                    <div className="padd-with-borderContaier">
                                      <div className="refferals-list-item new-refferal-list mb-0">
                                        <div className="refferal-content">
                                          <h2>
                                            {item.creator_details &&
                                              fixName(
                                                item.creator_details.first_name,
                                                item.creator_details.last_name,
                                              )}
                                          </h2>
                                          <span className="refferal-auther">
                                            {item.appointment_type_title}
                                          </span>
                                          {/* <p>Think there may be some synergy</p> */}
                                          <span className="refferal-date">
                                            {/* {moment(item.start_date).format(
                                        'MM/DD/YYYY',
                                      )}{' '} */}
                                            {item.duration + 'min'}
                                          </span>
                                        </div>
                                        <div className="refferal-img">
                                          <img
                                            src={
                                              item.creator_details &&
                                              item.creator_details.profile_img
                                                ? item.creator_details
                                                    .profile_img
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />
                                          <p className="refferal-info-que text-end">
                                            {item.cost
                                              ? '$' + item.cost
                                              : 'Free'}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {PageDesign()}
                                  </div>
                                </>
                              )}

                              {item.data_type === 'group_chat' && (
                                <>
                                  <div className="messages-users-list card-container">
                                    <div
                                      key={`msg-${index}`}
                                      className={
                                        'message-user-item no-link padd-with-borderContaier m-0'
                                      }>
                                      <div
                                        className="user-img as-link"
                                        // onClick={() => {
                                        //   amplitudeEvent('OPEN_CHAT_ROOM');
                                        //   handleOpenChatRoom(item);
                                        // }}
                                      >
                                        <img
                                          src={
                                            item.group_image !== ''
                                              ? item.group_image
                                              : item[
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail'
                                                ]['profile_img']
                                              ? item[
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail'
                                                ]['profile_img']
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                        {item.chat_type === 'question' && (
                                          <span>
                                            <i className="fa-solid fa-q"></i>
                                          </span>
                                        )}
                                        {item.chat_type === 'opportunity' && (
                                          <span className="icon icon-posts"></span>
                                        )}
                                        {item.chat_type === 'event' && (
                                          <span className="icon-tickets"></span>
                                        )}
                                        {item.chat_type === 'session' && (
                                          <span className="icon-video-camera-2"></span>
                                        )}
                                        {/* <span className="fa-solid fa-shop"></span> */}
                                      </div>
                                      <div
                                        className="user-content as-link"
                                        onClick={() => {
                                          // handleOpenChatRoom(item);
                                        }}>
                                        <h2 className="single-line-text">
                                          {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                          {item.group_name !== ''
                                            ? item.group_name
                                            : fixName(
                                                item[
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail'
                                                ]['first_name'],
                                                item[
                                                  parseInt(item.creator_id) ===
                                                  parseInt(
                                                    localStorage.getItem(
                                                      'user',
                                                    ),
                                                  )
                                                    ? 'user_detail'
                                                    : item.hasOwnProperty(
                                                        'creator_details',
                                                      )
                                                    ? 'creator_details'
                                                    : 'creator_detail'
                                                ]['last_name'],
                                              )}
                                        </h2>

                                        {formatString(item.last_message)}

                                        <div className="message-date-info">
                                          <span className="message-date">
                                            <Moment format="MM/DD/YY HH:mm A">
                                              {item.last_message_time
                                                ? item.last_message_time
                                                : item.created_at}
                                            </Moment>
                                          </span>
                                          {/* {renderMessageUsers(item.moreUsers)} */}
                                        </div>
                                      </div>

                                      {!item.hasOwnProperty(
                                        'invitation_type',
                                      ) &&
                                        item.hasOwnProperty('group_detail') && (
                                          <OtherUserImages
                                            otherUsers={filteringUsers(
                                              item.audience_detail.concat(
                                                item.moderator_detail,
                                                item.creator_detail
                                                  ? [item.creator_detail]
                                                  : [item.creator_details],
                                              ),
                                              arrayColumn(
                                                item.group_detail,
                                                'id',
                                              ),
                                            )}
                                          />
                                        )}
                                      {!item.hasOwnProperty(
                                        'invitation_type',
                                      ) &&
                                        !item.hasOwnProperty(
                                          'opportunity_invitaion_status',
                                        ) &&
                                        item.hasOwnProperty('opportunity_id') &&
                                        item.chat_type === 'opportunity' && (
                                          <OtherUserImages
                                            otherUsers={filteringUsers(
                                              item.other_persons_opportunity_chat_room.concat(
                                                item.team_member_details,
                                                item.creator_detail
                                                  ? [item.creator_detail]
                                                  : [item.creator_details],
                                              ),
                                              arrayColumn(
                                                [
                                                  ...item.other_persons_opportunity_chat_room,
                                                  ...item.team_member_details,
                                                  item.creator_details,
                                                ],
                                                'id',
                                              ),
                                            )}
                                          />
                                        )}
                                    </div>
                                    {PageDesign()}
                                  </div>
                                </>
                              )}
                              {item.data_type === 'accomplishment' && (
                                <>
                                  <div
                                    className="card-container shareables-snapshots"
                                    key={index + 'userListing'}>
                                    <div className="padd-with-borderContaier as-link">
                                      <div className=" d-flex align-items-center mb-3">
                                        <div className="snapshotsImage as-link">
                                          <img src={item.images[0]} alt="" />
                                        </div>
                                        <div
                                          className="snapshots-content as-link"
                                          onClick={() => {
                                            // setSnapShotId(item);
                                            // setShowComponent('SnapShotDetail');
                                          }}>
                                          <h2 className="single-line-text">
                                            {item.title}
                                          </h2>
                                          {item.category_name && (
                                            <p className="p-reg single-line-text category-name">
                                              {item.category_name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      {item.description && (
                                        <p className="p-reg truncate_2_lines">
                                          {item.description}
                                        </p>
                                      )}
                                    </div>
                                    {PageDesign()}
                                  </div>
                                </>
                              )}
                            </>
                          ))}
                      </div>
                    </>
                  ) : (
                    dropdownValue.includes('Workspace') && (
                      <div className="shareables-items">
                        <div className="as-link card-container w-100">
                          <div className="padd-with-borderContaier">
                            <div className="profile-img-wrapper">
                              <div className="profile-img">
                                <img
                                  src={
                                    userData &&
                                    userData.data &&
                                    userData.data.profile_img
                                      ? userData.data.profile_img
                                      : DEFAULT_IMAGE
                                  }
                                  alt=""
                                />
                              </div>

                              <div className="profile-name">
                                <div className="profile-name-headings">
                                  <div className="">
                                    <h2 className="m-0 mb-1 p-bold">
                                      {userData &&
                                        userData.data &&
                                        userData.data.first_name}
                                      {userData &&
                                        userData.data &&
                                        userData.data.last_name}
                                    </h2>
                                    {mineAreaUser.length > 0 && (
                                      <h3 className="p-reg m-0">
                                        {mineAreaUser.join(', ')}
                                      </h3>
                                    )}
                                  </div>
                                </div>
                                <div className="events-icon dark d-flex align-items-center justify-content-between">
                                  <ul>
                                    <li>
                                      <span className="as-link">
                                        <i className="icon-applaud" />
                                      </span>
                                      <span className="event-icon-text">
                                        10%
                                      </span>
                                    </li>

                                    <li>
                                      <span className="as-link icon">
                                        <i className="icon-circles" />
                                      </span>
                                    </li>
                                  </ul>

                                  <div className="d-flex align-items-center as-link">
                                    <ul>
                                      <li>
                                        <span className="as-link icon">
                                          <i className="fas fa-share" />
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="profile-content-infos">
                              <div className="skills">
                                {CategoryOfUser.join(', ')}
                              </div>

                              <div className="other-infos">
                                <div className="profile-address as-link profile-address">
                                  <i className="fas fa-home" />
                                  {userData &&
                                  userData.data &&
                                  userData.data.home_location
                                    ? userData.data.home_location
                                    : 'Add Location'}
                                </div>
                                <div className="profile-address as-link profile-address">
                                  <i className="icon-aroplane" />
                                  {userData &&
                                  userData.data &&
                                  userData.data.current_location
                                    ? userData.data.current_location
                                    : 'Add Location'}
                                </div>
                              </div>
                              <p className="p-reg m-0">
                                {userData && userData.data && userData.data.bio}
                              </p>

                              <div className="social-icons">
                                <ul>
                                  {userData.data &&
                                    userData.data.instagram !== '' && (
                                      <li>
                                        <a
                                          href={userData.data.instagram}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          <i className="fab fa-instagram" />
                                        </a>
                                      </li>
                                    )}
                                  {userData.data &&
                                    userData.data.twitter !== '' && (
                                      <li>
                                        <a
                                          href={userData.data.twitter}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          <i className="fab fa-twitter" />
                                        </a>
                                      </li>
                                    )}
                                  {userData.data &&
                                    userData.data.youtube !== '' && (
                                      <li>
                                        <a
                                          href={userData.data.youtube}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          <i className="fab fa-youtube" />
                                        </a>
                                      </li>
                                    )}
                                  {userData.data &&
                                    userData.data.web !== '' && (
                                      <li>
                                        <a
                                          href={userData.data.web}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          <i className="icon-link" />
                                        </a>
                                      </li>
                                    )}
                                  {userData.data &&
                                    userData.data.linkedin !== '' && (
                                      <li>
                                        <a
                                          href={userData.data.linkedin}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          <i className="fa-brands fa-linkedin" />
                                        </a>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 p20-horizental show-on-public">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Show on Public Page</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Personality Type</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Feedback</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Industry</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Home and Travel</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Bio</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Social Media</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Sessions</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Events</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="p-reg m-0">Opportunities</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="as-link card-container w-100 donation-block-wrapper">
                          <div className="padd-with-borderContaier">
                            <p>
                              If you had a great experience with [name], show
                              your appreciation through a cup of coffee.{' '}
                            </p>
                            <div className="donation-form">
                              <Form>
                                <div className="coffee-field">
                                  <div className="numbers-field">
                                    <span className="coffee-cup">
                                      <img src={coffeecup} alt="" />
                                    </span>
                                    <span className="multiply">X</span>
                                    <div className="numbers">
                                      <span className="active">1</span>
                                      <span>2</span>
                                      <span className="">3</span>
                                    </div>
                                    <div className="">
                                      <input
                                        className="form-control"
                                        type="number"
                                        min={1}
                                      />
                                    </div>
                                  </div>
                                  <span className="donation-amount">$5</span>
                                </div>
                                <div className="payment-btns">
                                  <Button className="apple-pay">
                                    <i className="fab fa-apple" />
                                    {labels.pay_text[selected_lang]}
                                  </Button>
                                  <Button className="pay-with-card">
                                    {labels.pay_with_card[selected_lang]}
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </div>
                          <div className="mt-2 p20-horizental show-on-public">
                            <div className="d-flex align-items-center justify-content-between">
                              <p className="p-reg m-0">Show on Public Page</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="as-link card-container w-100 donation-block-wrapper">
                          <div className="padd-with-borderContaier">
                            <div className="gallery-imgs">
                              <Row>
                                {Object.entries(snapShopData).length > 0 &&
                                  snapShopData.map((item, index) => (
                                    <Col md={4} sm={4} key={index}>
                                      <div className="gallery-item">
                                        <img src={item.images[0]} alt="" />
                                      </div>
                                    </Col>
                                  ))}
                              </Row>
                            </div>
                          </div>
                          <div className="mt-2 p20-horizental show-on-public">
                            <div className="d-flex align-items-center justify-content-between">
                              <p className="p-reg m-0">Show on Public Page</p>
                              <div>
                                <Form.Check
                                  type="switch"
                                  className="switchBlock"
                                  id="custom-switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}

              {tabType === labels.MESSAGES[selected_lang] && (
                <>
                  {Messages.length > 0 && (
                    <div className="shareables-items">
                      <div className="filter-btn-wrapper d-flex justify-content-center mb-3">
                        <Button className="btn-dark" type="submit">
                          Add Message
                        </Button>
                      </div>
                      <div className="card-container">
                        <div className="padd-with-borderContaier as-link">
                          <div className="tab-select">
                            <div className="select-wrapper as-link">
                              <select className="form-control text-align-left">
                                <option>Session Name</option>
                                <option>Custom Link</option>
                                <option>sdgdf</option>
                              </select>
                            </div>
                          </div>
                          <div className="d-flex align-items-center page-snapshots mt-3">
                            <p className="p-reg m-0">
                              Hey [first_name]! I’m so excited about you joining
                              [session_name]. See you soon!!
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
                          <p className="p-reg m-0 preview-message as-link">
                            Preview Message
                          </p>
                          <div className="d-flex align-items-center">
                            <img
                              className="ScanQr as-link"
                              src={ShareYellowIcon}
                              alt="scanQr"
                            />
                            <img
                              className="sharableYellow as-link"
                              src={ShareablesYellowIcon}
                              alt="shareable"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {Messages.length === 0 && (
                    <div className="no-results-wrapper mt-5">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/no-convos.webp')}
                          alt=""
                        />
                      </div>
                      <div className="no-results-content">
                        <h2>{labels.NO_INVITES_PENDING[selected_lang]}</h2>
                        <p>{labels.NO_INVITES_PENDING_INFO[selected_lang]}</p>
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* no-records-below */}
              {(tabType === labels.PAGE[selected_lang] ||
                tabType === labels.ELEMENT[selected_lang]) &&
                allData &&
                allData.length === 0 && (
                  <>
                    {!showNoreordFound &&
                      dropdownValue.includes('Sessions') && (
                        <div className="no-results-wrapper">
                          <div className="img-bg-wrapper">
                            <img
                              src={require('../../Assets/images/norecord/session.png')}
                              alt="no-session"
                            />
                          </div>

                          <div className="no-results-content">
                            <h2>Manage Sessions</h2>
                            <p>Meetup with your network virtually</p>

                            <div className="btn-wrapper mt-3">
                              <Button className="btn btn-dark">
                                <Link
                                  className="text-light"
                                  style={{textDecoration: 'none'}}
                                  to={{
                                    pathname: '/',
                                    state: {
                                      typeIs: 'sessionss',
                                      data: 'createSession',
                                    },
                                  }}>
                                  Create Session
                                </Link>
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    {!showNoreordFound && dropdownValue.includes('Events') && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/event.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>{labels.MANAGE_EVENTS[selected_lang]}</h2>
                          <p>{labels.Connect_Network[selected_lang]}</p>

                          <div className="btn-wrapper mt-3">
                            <Button className="btn btn-dark">
                              <Link
                                className="text-light"
                                style={{textDecoration: 'none'}}
                                to={{
                                  pathname: '/',
                                  state: {
                                    typeIs: 'events',
                                    data: 'show_create_event',
                                  },
                                }}>
                                Create Event
                              </Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!showNoreordFound &&
                      dropdownValue.includes('Opportunities') && (
                        <div className="no-results-wrapper">
                          <div className="img-bg-wrapper">
                            <img
                              src={require('../../Assets/images/norecord/opportunity.png')}
                              alt=""
                            />
                          </div>

                          <div className="no-results-content">
                            <h2>{labels.Share_Opportunity[selected_lang]}</h2>
                            <p>{labels.Someone_Waiting[selected_lang]}</p>

                            <div className="btn-wrapper mt-3">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  // props.setProperComponent('inviteUser');
                                }}>
                                {labels.Create_Opportunity[selected_lang]}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    {!showNoreordFound &&
                      dropdownValue.includes('Appointments') && (
                        <div className="no-results-wrapper no-appointments mt-5">
                          <div className="img-bg-wrapper">
                            <img
                              src={require('../../Assets/images/norecord/no-appointments.png')}
                              alt=""
                            />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-title">
                              No Upcoming 1-on-1s
                            </p>
                            <p className="noRecord-desc">
                              You don't have any upcoming appointments
                            </p>

                            <div className="btn-wrapper mt-3">
                              <Button
                                className={
                                  'btn btn-dark'
                                  // getItem('userRole') === 'guest'
                                  //   ? 'grey-class-btn'
                                  //   : 'btn btn-dark'
                                }>
                                <Link
                                  // onClick={e => {
                                  //   loginUserRole(e);
                                  // }}
                                  className="text-light"
                                  style={{
                                    textDecoration: 'none',
                                  }}
                                  to={{
                                    pathname: '/builders',
                                    state: {
                                      typeIs: 'search',
                                    },
                                  }}>
                                  Explore Builders
                                </Link>
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    {!showNoreordFound &&
                      dropdownValue.includes('Snapshots') && (
                        <div className="no-results-wrapper">
                          <div className="img-bg-wrapper">
                            <img
                              src={require('../../Assets/images/norecord/no-snapshot.png')}
                              alt=""
                            />
                          </div>

                          <div className="no-results-content">
                            <h2>Manage Credentials</h2>
                            <p>
                              Create shareable one-pagers for your business(es),
                              affiliations and more
                            </p>

                            <div className="btn-wrapper mt-3">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  // props.setProperComponent('create-snapshot');
                                }}>
                                Add Credentials
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    {!showNoreordFound && dropdownValue.includes('Links') && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/svg-icons/link.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No Links Available</h2>
                          <p>
                            Add links to quickly promote things that matter most
                          </p>

                          <div className="btn-wrapper mt-3">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                //  setAddNewSnapshot(true);
                              }}>
                              Add Link
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!showNoreordFound &&
                      dropdownValue.includes('Group Chats') && (
                        <div className="no-results-wrapper">
                          <div className="img-bg-wrapper">
                            <img
                              src={require('../../Assets/images/svg-icons/noGroup.png')}
                              alt=""
                            />
                          </div>

                          <div className="no-results-content">
                            <h2>No Active Group Chats</h2>
                            <p>
                              Create a group chat to collaborate with others
                            </p>

                            <div className="btn-wrapper mt-3">
                              <Button className="btn btn-dark">
                                <Link
                                  // onClick={e => {
                                  //   loginUserRole(e);
                                  // }}
                                  className="text-light"
                                  style={{
                                    textDecoration: 'none',
                                  }}
                                  to={{
                                    pathname: '/messages',
                                    state: {
                                      typeIs: 'create-group',
                                    },
                                  }}>
                                  {' '}
                                  Create Group Chat
                                </Link>
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    {!showNoreordFound && dropdownValue.includes('Forms') && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img src={NoForm} alt="" />
                        </div>

                        <div className="no-results-content">
                          <h2>No Forms Added</h2>
                          <p>
                            Create forms to learn more and add to your network
                          </p>

                          <div className="btn-wrapper">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                //  setAddNewSnapshot(true);
                              }}>
                              Add Form
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!showNoreordFound && dropdownValue.includes('Network') && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/svg-icons/network.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No Contacts Added</h2>
                          <p>
                            Add contacts to become more efficient in the way you
                            interact
                          </p>

                          <div className="btn-wrapper mt-3">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                //  setAddNewSnapshot(true);
                              }}>
                              Add Contact
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {!showNoreordFound && dropdownValue === 'Gifts' && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/gifts2.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No Gifts Available</h2>
                          <p>
                            Keep people excited and motivated while connecting
                            with your network
                          </p>

                          {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                        </div>
                      </div>
                    )}
                    {!showNoreordFound && dropdownValue === 'Offerings' && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/offerings.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No Offerings Available</h2>
                          <p>
                            Quickly share what your business offers to increase
                            sales
                          </p>

                          {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                        </div>
                      </div>
                    )}
                    {!showNoreordFound && dropdownValue === 'Payments' && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img src={Dollar} alt="" />
                        </div>

                        <div className="no-results-content">
                          <h2>No Payment Options Available</h2>
                          <p>
                            Provide payment options to quickly collect funds
                            from your network
                          </p>

                          {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                        </div>
                      </div>
                    )}
                    {!showNoreordFound && dropdownValue === 'Polls' && (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/polls.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>No Polls Available</h2>
                          <p>Ask quick questions to gain impactful insight</p>

                          {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                        </div>
                      </div>
                    )}

                    {!showNoreordFound &&
                      tabType === labels.ELEMENT[selected_lang] &&
                      (dropdownValue.includes('Workspace') ||
                        dropdownValue.includes('All')) && (
                        <div className="no-results-wrapper mb-3">
                          <div className="img-bg-wrapper">
                            <img
                              src={require('../../Assets/images/norecord/shareable.png')}
                              alt="no-session"
                            />
                          </div>

                          <div className="no-results-content">
                            <h2>No ShareAbles Available</h2>
                            <p>
                              Aadd ShareAbles to increase engagement, revenue
                              and measure what matters
                            </p>
                          </div>
                        </div>
                      )}
                  </>
                )}

              {/* no-records-end */}
            </div>
          </Scrollbars>
        </div>
        {/* </div> */}
      </div>
      {showModel === 'work-place-list' && (
        <CustomModal
          className="shareables-workspace-modal"
          size="small"
          onClose={() => setShowModel('')}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(workSpaceItem).length > 0 &&
                  workSpaceItem.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.name}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.name}
                          onChange={e => {
                            handleClickList(e);
                          }}
                          checked={
                            isCheckWorkSpace.includes(item.id) ||
                            isCheckValueWorkSpace.includes(item.title)
                          }
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <Button
                type="submit"
                className="btn-dark"
                onClick={() => {
                  get_user_all_data();
                  setShowModel('');
                }}>
                Update
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {showModel === 'preview' && shareAbleItem && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModel('');
          }}
          className="Preview-Modal">
          {type === 'conference' && (
            <>
              <ShareAblesSessions
                // key={`session-item-${index}`}
                type="preview"
                item={shareAbleItem}
                heart={''}
                componentDecesion={componentDecesion}
                ElementDesign={PageDesign}
                // showModel={showModel}
              />
              {/* {ElementDesign()} */}
            </>
          )}
          {type === 'event' && (
            <div style={{}} className="custom-scrollbar w-100">
              <ShreAblesEvent
                // key={`event-item-${index}`}
                type="preview"
                item={shareAbleItem}
                // index={index}
                // eventDetails={eventDetails}
                componentDecesion={componentDecesion}
                ElementDesign={PageDesign}
              />
            </div>
          )}
          {type === 'opportunity' && (
            <>
              <ShareAableOpportunity
                type="preview"
                // key={`opportunity-item-${index}`}
                setShowButton={setUnknownData}
                onShare={() => {
                  // setProperComponent('refer-opportunity');
                }}
                setChatData={setUnknownData}
                handleItem={componentDecesion}
                item={shareAbleItem}
                ElementDesign={PageDesign}
              />
            </>
          )}

          {type === 'appointment' && (
            <>
              <div
                className="refferals-list card-container as-link"
                // key={'www' + index}
              >
                <div className="padd-with-borderContaier">
                  <div className="refferals-list-item new-refferal-list mb-0">
                    <div className="refferal-content">
                      <h2>
                        {shareAbleItem.creator_details &&
                          fixName(
                            shareAbleItem.creator_details.first_name,
                            shareAbleItem.creator_details.last_name,
                          )}
                      </h2>
                      <span className="refferal-auther">
                        {shareAbleItem.appointment_type_title}
                      </span>
                      {/* <p>Think there may be some synergy</p> */}
                      <span className="refferal-date">
                        {/* {moment(item.start_date).format(
                                        'MM/DD/YYYY',
                                      )}{' '} */}
                        {shareAbleItem.duration + 'min'}
                      </span>
                    </div>
                    <div className="refferal-img">
                      <img
                        src={
                          shareAbleItem.creator_details &&
                          shareAbleItem.creator_details.profile_img
                            ? shareAbleItem.creator_details.profile_img
                            : DEFAULT_IMAGE
                        }
                        alt=""
                      />
                      <p className="refferal-info-que text-end">
                        {shareAbleItem.cost ? '$' + shareAbleItem.cost : 'Free'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {type === 'message' && (
            <>
              <div className="messages-users-list card-container">
                <div
                  // key={`msg-${index}`}
                  className={
                    'message-user-item no-link padd-with-borderContaier m-0'
                  }>
                  <div
                    className="user-img as-link"
                    // onClick={() => {
                    //   amplitudeEvent('OPEN_CHAT_ROOM');
                    //   handleOpenChatRoom(item);
                    // }}
                  >
                    <img
                      src={
                        shareAbleItem.group_image !== ''
                          ? shareAbleItem.group_image
                          : shareAbleItem[
                              parseInt(shareAbleItem.creator_id) ===
                              parseInt(localStorage.getItem('user'))
                                ? 'user_detail'
                                : shareAbleItem.hasOwnProperty(
                                    'creator_details',
                                  )
                                ? 'creator_details'
                                : 'creator_detail'
                            ]['profile_img']
                          ? shareAbleItem[
                              parseInt(shareAbleItem.creator_id) ===
                              parseInt(localStorage.getItem('user'))
                                ? 'user_detail'
                                : shareAbleItem.hasOwnProperty(
                                    'creator_details',
                                  )
                                ? 'creator_details'
                                : 'creator_detail'
                            ]['profile_img']
                          : DEFAULT_IMAGE
                      }
                      alt=""
                    />
                    {shareAbleItem.chat_type === 'question' && (
                      <span>
                        <i className="fa-solid fa-q"></i>
                      </span>
                    )}
                    {shareAbleItem.chat_type === 'opportunity' && (
                      <span className="icon icon-posts"></span>
                    )}
                    {shareAbleItem.chat_type === 'event' && (
                      <span className="icon-tickets"></span>
                    )}
                    {shareAbleItem.chat_type === 'session' && (
                      <span className="icon-video-camera-2"></span>
                    )}
                    {/* <span className="fa-solid fa-shop"></span> */}
                  </div>
                  <div
                    className="user-content as-link"
                    onClick={() => {
                      // handleOpenChatRoom(item);
                    }}>
                    <h2 className="single-line-text">
                      {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                      {shareAbleItem.group_name !== ''
                        ? shareAbleItem.group_name
                        : fixName(
                            shareAbleItem[
                              parseInt(shareAbleItem.creator_id) ===
                              parseInt(localStorage.getItem('user'))
                                ? 'user_detail'
                                : shareAbleItem.hasOwnProperty(
                                    'creator_details',
                                  )
                                ? 'creator_details'
                                : 'creator_detail'
                            ]['first_name'],
                            shareAbleItem[
                              parseInt(shareAbleItem.creator_id) ===
                              parseInt(localStorage.getItem('user'))
                                ? 'user_detail'
                                : shareAbleItem.hasOwnProperty(
                                    'creator_details',
                                  )
                                ? 'creator_details'
                                : 'creator_detail'
                            ]['last_name'],
                          )}
                    </h2>

                    {formatString(shareAbleItem.last_message)}

                    <div className="message-date-info">
                      <span className="message-date">
                        <Moment format="MM/DD/YY HH:mm A">
                          {shareAbleItem.last_message_time
                            ? shareAbleItem.last_message_time
                            : shareAbleItem.created_at}
                        </Moment>
                      </span>
                      {/* {renderMessageUsers(item.moreUsers)} */}
                    </div>
                  </div>

                  {!shareAbleItem.hasOwnProperty('invitation_type') &&
                    shareAbleItem.hasOwnProperty('group_detail') && (
                      <OtherUserImages
                        otherUsers={filteringUsers(
                          shareAbleItem.audience_detail.concat(
                            shareAbleItem.moderator_detail,
                            shareAbleItem.creator_detail
                              ? [shareAbleItem.creator_detail]
                              : [shareAbleItem.creator_details],
                          ),
                          arrayColumn(shareAbleItem.group_detail, 'id'),
                        )}
                      />
                    )}
                  {!shareAbleItem.hasOwnProperty('invitation_type') &&
                    !shareAbleItem.hasOwnProperty(
                      'opportunity_invitaion_status',
                    ) &&
                    shareAbleItem.hasOwnProperty('opportunity_id') &&
                    shareAbleItem.chat_type === 'opportunity' && (
                      <OtherUserImages
                        otherUsers={filteringUsers(
                          shareAbleItem.other_persons_opportunity_chat_room.concat(
                            shareAbleItem.team_member_details,
                            shareAbleItem.creator_detail
                              ? [shareAbleItem.creator_detail]
                              : [shareAbleItem.creator_details],
                          ),
                          arrayColumn(
                            [
                              ...shareAbleItem.other_persons_opportunity_chat_room,
                              ...shareAbleItem.team_member_details,
                              shareAbleItem.creator_details,
                            ],
                            'id',
                          ),
                        )}
                      />
                    )}
                </div>
              </div>
            </>
          )}
          {type === 'snapShot' && (
            <>
              <div
                className="card-container shareables-snapshots"
                // key={index + 'userListing'}
              >
                <div className="padd-with-borderContaier as-link">
                  <div className=" d-flex align-items-center mb-3">
                    <div className="snapshotsImage as-link">
                      <img src={shareAbleItem.images[0]} alt="" />
                    </div>
                    <div
                      className="snapshots-content as-link"
                      onClick={() => {
                        // setSnapShotId(item);
                        // setShowComponent('SnapShotDetail');
                      }}>
                      <h2 className="single-line-text">
                        {shareAbleItem.title}
                      </h2>
                      {shareAbleItem.category_name && (
                        <p className="p-reg single-line-text category-name">
                          {shareAbleItem.category_name}
                        </p>
                      )}
                    </div>
                  </div>
                  {shareAbleItem.description && (
                    <p className="p-reg truncate_2_lines">
                      {shareAbleItem.description}
                    </p>
                  )}
                </div>
              </div>
            </>
          )}

          {/* <div className="new-session-modal-wrapper text-left shareablesPreview_Modal m-0 w-100">
            <div className="tabbed-modal-wrapper">
              
              <div className="tab-content">
            

               
                <div className="shareables-preview-footer">
                  <span>
                    <img
                      className="ScanQr as-link"
                      src={ShareYellowIcon}
                      alt="scanQr"
                      onClick={() => {
                        ShareAbleRefer(type, shareAbleItem);
                      }}
                    />
                  </span>
                  <span>
                    <img
                      className="sharableYellow as-link"
                      src={ShareablesYellowIcon}
                      alt="shareable"
                      onClick={() => {
                        ShowModelLinks('link_model', type, shareAbleItem);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            
          </div> */}

          <div className="d-flex align-items-center my-3">
            {/* <img
            className="ScanQr as-link"
            src={ShareYellowIcon}
            alt="scanQr"
            onClick={() => {
              ShareAbleRefer(type, data);
            }}
          />
          <img
            className="sharableYellow as-link"
            src={ShareablesYellowIcon}
            alt="shareable"
            onClick={() => {
              ShowModelLinks('link_model', type, data);
            }}
          /> */}

            <span
              onClick={() => {
                props.sendAnyThink(type, shareAbleItem);
                props.onClose();
              }}
              className="sharableYellow as-link send-message form-icon msg_send_btn send-btn">
              <i className="icon-plane" style={{fontSize: '23px'}} />
            </span>
            {/* <img
              className="sharableYellow as-link send-message"
              src={require('../../Assets/images/send-message.png')}
              alt="send-message"
              onClick={() => {
                props.sendAnyThink(type, shareAbleItem);
                props.onClose();
              }}
            /> */}
          </div>
        </CustomModal>
      )}
      {model === 'link_model' && (
        <CustomModal
          position="bottom"
          className={'share-links-modal'}
          onClose={() => setModel('')}>
          <div className="camera-options-list w-100 social-links-modal">
            <ul>
              <li className="as-link">
                <FacebookShareButton
                  url={shareUrl}
                  title={false}
                  setShareUrl={setShareUrl}>
                  <i className="fa-brands fa-facebook"></i>
                </FacebookShareButton>
              </li>
              <li className="as-link">
                <WhatsappShareButton url={shareUrl}>
                  <i className="fa-brands fa-whatsapp"></i>
                </WhatsappShareButton>
              </li>
              <li className="as-link">
                <EmailShareButton url={shareUrl}>
                  <i className="fa-solid fa-envelope"></i>
                </EmailShareButton>
              </li>
              <li className="as-link">
                <TwitterShareButton url={shareUrl}>
                  <i className="fa-brands fa-twitter"></i>
                </TwitterShareButton>
              </li>
              <li className="as-link">
                <LinkedinShareButton url={shareUrl}>
                  <i className="fa-brands fa-linkedin"></i>
                </LinkedinShareButton>
              </li>
            </ul>
          </div>
        </CustomModal>
      )}
    </CustomModal>
  );
};

export default MessagesShareAbles;
