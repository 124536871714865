import React, {useState,  useEffect} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import moment from 'moment/moment';
import SendTicket from './SendTicket';
import {DEFAULT_IMAGE} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {capitalizeFirst, fixName} from '../../Utils/commonUtils';

const MineTickets = props => {
  const [tickets, setTickets] = useState([]);
  const [inviteUser, setInviteUser] = useState(false);
  const [allData, setAllData] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const getMineTickets = () => {
    setShowSpinner(true);
    var data = {
      event_id: props.eventData,
      recursion_id: props.idOfRecursion,
    };
    commonApi
      .get_mine_tickets(data)
      .then(res => {
        if (res.status === 200) {
          setTickets(res.mine_tickets);
        }
        setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getMineTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteUser]);

  return (
    <>
   
        <SlidingPanel hidePanel={() => props.onClose()}>
          {showSpinner && tickets.length === 0 && <LoaderSpinner />}
          <div className="serach-filter-wrapper">
            <div className="headings fix-width-forms">
              <h2>My Tickets</h2>
            </div>
            <Scrollbars>
              <div className="fix-width-forms">
                <div className="no-results-content w-100 m-0">
                  <p> Choose your ticket before inviting others</p>
                </div>
                <div className="text-right">
                  {/* <span className="close as-link" onClick={() => props.onClose()}>
                  <i className="fas fa-times" />
                </span> */}
                </div>
                <div className="event-tabs tabs-style-2 mt-3 mb-0" />
                {Object.entries(tickets).length !== 0 ? (
                  Object.entries(tickets).length > 0 &&
                  tickets.map((item, index) => (
                    <div
                      key={`boaasdasrding-${index}`}
                      className="opportunity-item card"
                      onClick={() => {
                        setAllData(item.id);
                      }}>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="w-100">
                          {item.title !== null && item.title !== '' && (
                            <h2 className="no-pointer">{item.title}</h2>
                          )}

                          <h2 className="no-pointer">
                            {item.cost === null || item.cost === ''
                              ? 'Free'
                              : '$' + item.cost}
                          </h2>
                        </div>

                        <div
                          className={
                            props.userData.status !== 'finished'
                              ? 'search-tabs-icons my-ticke-icon text-right'
                              : ' search-tabs-icons disabled-Class text-right '
                          }>
                          {item.share === 'no' &&
                            item.user_status !== 'joined' && (
                              <span className="icon">
                                <i
                                  className="icon-user-plus"
                                  onClick={() => {
                                    setInviteUser(true);
                                    setAllData(item.id);
                                  }}></i>
                              </span>
                            )}
                        </div>
                      </div>

                      {item.description !== null && item.description !== '' && (
                        <div className="oppo-share-wrapper">
                          <span className="category">
                            <h2>{item.description}</h2>
                          </span>
                        </div>
                      )}
                      <div className="oppo-share-wrapper">
                        <span className="category">
                          {moment(item.start_time).format(
                            'MMMM Do YYYY, h:mmA',
                          )}
                        </span>
                        {item.share === 'no' &&
                          item.user_status === 'joined' &&
                          item.user_details.length === 0 && (
                            <div className="oppo-share-wrapper">
                              <span className="" style={{ color: '#9b9787' }}>Mine</span>
                            </div>
                          )}
                        <div className="text-right">
                          {item.event_user_status === 'shared' &&
                          item.share === 'yes' ? (
                            <span className="" style={{ color: '#9b9787' }}>Pending</span>
                          ) : (
                            item.event_user_status === 'volunteer' &&
                            item.share === 'yes' && (
                              <span className="" style={{ color: '#9b9787' }}>Claimed</span>
                            )
                          )}
                        </div>
                      </div>
                      {item.share === 'yes' &&
                        item.user_details.length === 0 && (
                          <div className="invited-by-wrapper mt-0">
                            <div className="user-img">
                              <img src={DEFAULT_IMAGE} alt="" />
                            </div>
                            <div className="oppo-desc m-0">
                              <div className="oppo-desc-content">
                                {item.first_name &&
                                  item.last_name &&
                                  fixName(item.first_name, item.last_name)}
                                {item.first_name &&
                                  !item.last_name &&
                                  capitalizeFirst(item.first_name)}
                              </div>
                            </div>
                          </div>
                        )}
                      {item.share === 'yes' &&
                        item.user_details.length !== 0 && (
                          <div className="invited-by-wrapper mt-0">
                            <div className="user-img">
                              <img
                                src={
                                  item.user_details.profile_img
                                    ? item.user_details.profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>
                            <div className="oppo-desc m-0">
                              <div className="oppo-desc-content">
                                {fixName(
                                  item.user_details.first_name,
                                  item.user_details.last_name,
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  ))
                ) : (
                  <div className="no-results-content w-100 mt-4 text-center">
                    <p className="text-center">No Record Found</p>
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </SlidingPanel>

        {inviteUser && (
        <SendTicket
          userData={props.userData}
          tickets={tickets}
          allData={allData}
          eventId={props.eventData}
          onClose={() => {
            setInviteUser(false);
          }}
          creatorId={props.creatorId}
        />
      
      )}
    </>
  );
};
export default MineTickets;
