import React from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import CustomModal from '../CustomModal';
const SocialLinkModel = props => {
  return (
    <CustomModal
      position="bottom"
      className={'share-links-modal'}
      onClose={() => props.onClose()}
      // setTabType={setTabType}
    >
      <div className="camera-options-list w-100 social-links-modal">
        <ul>
          <li className="as-link">
            <FacebookShareButton url={props.link} title={false}>
              <i className="fa-brands fa-facebook"></i>
            </FacebookShareButton>
          </li>
          <li className="as-link">
            <WhatsappShareButton url={props.link}>
              <i className="fa-brands fa-whatsapp"></i>
            </WhatsappShareButton>
          </li>
          <li className="as-link">
            <EmailShareButton url={props.link}>
              <i className="fa-solid fa-envelope"></i>
            </EmailShareButton>
          </li>
          <li className="as-link">
            <TwitterShareButton url={props.link}>
              <i className="fa-brands fa-twitter"></i>
            </TwitterShareButton>
          </li>
          <li className="as-link">
            <LinkedinShareButton url={props.link}>
              <i className="fa-brands fa-linkedin"></i>
            </LinkedinShareButton>
          </li>
        </ul>
      </div>
    </CustomModal>
  );
};

export default SocialLinkModel;
