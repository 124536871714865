import React from 'react';

const TermsOfServices = () => {
  return (
    <div className="container mt-4 mb-4">
      <center>
        <img
          src="https://build-dev.s3.us-east-1.amazonaws.com/roi.png"
          alt="logo"
          style={{width: '90px', height: 'auto'}}
        />

        <b>Terms of Service</b>
      </center>
      <p>
        On www.roilevelup.com ("Site") and the Build mobile application, we
        provide an online safe space for the workforce to interact and share
        ideas on resolving workplace concerns. Additionally, we provide employee
        advocacy services via our expert Advocates for a fee ("Service").{' '}
      </p>

      <p>
        The terms "we," "us," and "our" refer to Employee of Choice, Inc.
        ("Company").{' '}
      </p>

      <p>
        The terms "user," "you," and "your" refer to visitors, customers, and
        users of the Site and application. Use of the Site, including all
        content, materials, products, and/or services, is subject to these Terms
        of Use ("Terms").{' '}
      </p>

      <p>
        These Terms apply to all visitors, customers, and users of the Site. By
        using the Site or Service, you agree to and acknowledge these Terms.
      </p>

      <h3>USE OF THE SITE AND SERVICE</h3>

      <p>
        To access or use the Site and/or Service, you must be 18 years or older
        and have the requisite power and authority to enter into these Terms.
        Children under the age of 18 are prohibited from using the Site or
        Service and minor employees should consult with their parents.
      </p>

      <p>
        We make no representation or warranty that the information provided on
        the Site ("Content"), regardless of its source, is accurate, complete,
        reliable, current, or error-free. We disclaim all liability for any
        inaccuracy, error, or incompleteness in the Content. We do not guarantee
        any particular result will be achieved from the Content.{' '}
      </p>

      <h3>ACCOUNT CREATION</h3>

      <p>
        To access or use the Site and/or Service, you may be required to provide
        information about yourself including your name, email address, username,
        password, and other personal information. You agree that any
        registration information you give to Company will always be accurate,
        correct, and up to date. You must not impersonate someone else or
        provide account information or an email address other than your own.
        Your account must not be used for any illegal or unauthorized purpose.
        In the use of the Site and/or Service, you must not violate any laws in
        your jurisdiction.
      </p>

      <h3>LAWFUL PURPOSES</h3>

      <p>
        You may use the Site and Service for lawful purposes only. You agree to
        be financially responsible for all purchases made by you or someone
        acting on your behalf through the Site. You agree to use the Site and to
        purchase products or services through the Site for legitimate,
        non-commercial purposes only. You will not post or transmit through the
        Site or Service any material which violates or infringes the rights of
        others, or which is threatening, abusive, defamatory, libelous, invasive
        of privacy or publicity rights, vulgar, obscene, profane or otherwise
        objectionable, contains injurious formulas, recipes, or instructions,
        which encourages conduct that would constitute a criminal offense, give
        rise to civil liability, or otherwise violate any law.
      </p>

      <h3>LEGAL DISCLAIMER</h3>

      <p>
        The information on the myzolve.com website and the mobile application,
        as well as any information provided via subsequent phone and/or
        in-person sessions, does not constitute legal advice and is for
        informational purposes only. For situations requiring legal advice,
        please contact your attorney or initiate a chat with an Advisor for a
        referral. If in a crisis, call 911.
      </p>

      <h3>REFUSAL OF SERVICE</h3>

      <p>
        The Service is offered subject to our acceptance of your order or
        requests. We reserve the right to refuse service to any order, person,
        or entity at our discretion without the obligation to assign reason for
        doing so. No order is deemed accepted until your payment has been
        processed. We may change or discontinue any aspect or feature of the
        Site or Service at any time, subject to our fulfillment of our prior
        responsibilities to you based on our acceptance of your payment. If we
        choose to refuse your order after payment has been processed, we will
        refund your money.
      </p>

      <h3>ORDER CONFIRMATION</h3>

      <p>
        We will email you to confirm the placement and details of your order. If
        there is an error in this email confirmation, it is your responsibility
        to inform us as soon as possible.
      </p>

      <h3>ANCELLATIONS, REFUNDS, AND RETURNS</h3>

      <p>
        All sales are final. We do not offer refunds or returns. However, your
        satisfaction is our #1 priority, email us at info@bleedingbulb.com to
        let us know why you are unsatisfied and we will try our best to meet
        your needs.{' '}
      </p>

      <h3>PRODUCT DESCRIPTION</h3>

      <p>
        We endeavor to describe and display the Site and Service as accurately
        as possible. While our intentions are to be as clear as possible in
        explaining the Service, please do not accept that the Site is entirely
        accurate, current, or error-free. From time to time, we may correct
        errors in pricing and descriptions. We reserve the right to refuse or
        cancel any order with an incorrect price listing.
      </p>

      <h3>MATERIAL YOU SUBMIT TO THE SITE</h3>

      <p>
        You will not upload, post, or otherwise make available on the Site any
        artwork, photos, or other materials (collectively, "Materials")
        protected by copyright, trademark or other proprietary right without the
        owner's express written permission, and the burden of determining that
        any Materials are not protected rests entirely with you. You will be
        liable for any damage resulting from any infringement of copyrights,
        trademarks, or other proprietary rights, or any other harm resulting
        from such a submission. For all Materials submitted by you to the Site,
        you automatically represent or warrant that you have the authority to
        use and distribute the Materials, and that the use or display of the
        Materials will not violate any laws, rules, regulations, or third party
        rights.
      </p>

      <h3>INTELLECTUAL PROPERTY RIGHTS TO YOUR MATERIALS</h3>

      <p>
        We claim no intellectual property rights over the material you submit to
        Company. You retain copyright and any other rights you may rightfully
        hold in any content, which you submit through the Site or Service. Your
        submitted content remains yours to the extent that you have any legal
        claims to it. You agree to hold Company harmless from and against all
        claims, liabilities, and expenses arising out of any potential or actual
        copyright or trademark misappropriation or infringement claimed against
        you. By posting Material on the Site or sending us testimonials and
        other positive feedback to info@bleedingbulb.com, you grant us a
        worldwide, nonexclusive license to use the material for promotional,
        business development, advertising, and/or marketing purposes.
      </p>

      <h3>OUR INTELLECTUAL PROPERTY</h3>

      <p>
        The Site and Service contain intellectual property owned by Company,
        including but not limited to trademarks, copyrights, patents, and
        proprietary information. You may not modify, publish, transmit, or
        participate in the transfer or sale of, create derivative works from,
        distribute, display, reproduce, perform, or in any way exploit in any
        format whatsoever any of the Site, Service, Content, or intellectual
        property, in whole or in part, without our prior written consent. We
        reserve the right to immediately remove you from the Service, without
        refund, if you are caught violating this intellectual property policy.
      </p>

      <p>Build is a trademark owned by Employee of Choice, Inc. </p>

      <h3>UPDATED TERMS</h3>

      <p>
        We may amend or update these Terms, along with any portion of our Site
        and Service, at any time. We will post the most recent versions to the
        Site and list the effective dates on the pages of our Terms. By posting
        the new Terms on this Site, the amendments are effective immediately
        upon notice to you. Your use of the Site or Service after being notified
        means that you accept the amended terms.
      </p>

      <h3>THIRD PARTY RESOURCES</h3>

      <p>
        The Site and the Service contain links to third party websites and
        resources. You acknowledge and agree that Company is not responsible or
        liable for the availability, accuracy, content, or policies of third
        party websites or resources. Links to such websites or resources do not
        imply any endorsement by or affiliation with Company. You acknowledge
        sole responsibility for and assume all risk arising from your use of any
        such websites or resources.
      </p>

      <h3>INDEMNIFICATION</h3>

      <p>
        You agree to indemnify and hold us harmless from and against any and all
        losses, damages, settlements, liabilities, costs, charges, assessments
        and expenses, as well as third party claims and causes of action,
        including but not limited to attorneys' fees, arising out of any breach
        by you of any of these Terms, or your use of the Site or Service. You
        will provide us with such assistance, without charge, as we may request
        in connection with any such defense, including but not limited to
        providing us with any accessible information, documents, or records as
        necessary. You will not settle any third party claim or waive any
        defense without our prior written consent.
      </p>

      <h3>ENTIRE AGREEMENT AND WAIVER</h3>

      <p>
        This Agreement constitutes the entire agreement between you and Company
        pertaining to the Site and Service and supersedes all prior and
        contemporaneous agreements, representations, and understandings between
        the parties. No waiver of any provisions of this Agreement by Company
        will constitute a waiver or continuing waiver of any other provision. No
        waiver will be binding unless executed in writing by Company.{' '}
      </p>

      <h3>NOTICES</h3>

      <p>
        All notices, requests, demands, and other communications under these
        Terms must be in writing and properly addressed to Tameika Scott via
        e-mail at info@bleedingbulb.com.
      </p>

      <h3>GOVERNING LAW</h3>

      <p>
        These Terms will be construed in accordance with the laws of the State
        of Maryland. The exclusive venue for any mediation, arbitration, or
        court proceeding based on or arising out of this Agreement will be Anne
        Arundel County. The parties agree to attempt to resolve any dispute,
        claim, or controversy arising out of or relating to these Terms by
        mediation. The parties further agree that their respective good faith
        participation in mediation is a condition precedent to pursuing any
        other available legal or equitable remedy, including litigation,
        arbitration, or other dispute resolution procedures.
      </p>

      <h3>RECOVERY OF LITIGATION EXPENSES</h3>

      <p>
        If any legal action, arbitration, or other proceeding is brought for the
        enforcement of these Terms, or because of an alleged dispute, breach,
        default, or misrepresentation in connection with any provisions of these
        Terms, the prevailing party will be entitled to recover reasonable
        attorneys' fees and other costs incurred in that action or proceeding,
        in addition to any other relief to which they may be entitled.
      </p>

      <h3>SEVERABILITY</h3>

      <p>
        If any term, provision, covenant, or condition of these Terms is held by
        an arbitrator or court of competent jurisdiction to be invalid, void, or
        unenforceable, the rest of the terms, provisions, covenants, or
        conditions will remain in full force and effect and will in no way be
        affected, impaired, or invalidated.
      </p>

      <h3>ASSIGNMENT</h3>

      <p>
        These Terms bind and inure to the benefit of the parties' successors and
        assigns. These Terms are not assignable, delegable, sub licensable, or
        otherwise transferable. Any transfer, assignment, delegation or
        sublicense is invalid.
      </p>

      <p>
        If you have questions about these Terms, email us at
        info@bleedingbulb.com.{' '}
      </p>

      <p>Updated: 1/31/2020 </p>
    </div>
  );
};

export default TermsOfServices;
