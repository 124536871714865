export const referralListing = (state = [], action) => {
	switch(action.type) {
		case "REFER/GET_DATA":
			return action.payload.data;
        case "REFER/GET_DATA_PAGE":
            return [...state, ...action.payload.data];
		default:
			return state;
	}
};

export const referralApi = (state = 1, action) => {
	switch (action.type) {
	  case 'REFER/API':
		return state + 1;
	  default:
		return state;
	}
  };