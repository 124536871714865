import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {Logo} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {useHistory} from 'react-router-dom';
import commonApi from '../../Services/CommonService';
import {setItem, getItem} from '../../Utils/LocalStorage';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {errorMessage} from '../../Utils/ToastMessages';
import API from '../../Services/SessionServices';
import {useDispatch} from 'react-redux';
// import {callChatApi} from '../../Store/Actions/ChatAction';
import {callDeepLink} from '../../Store/Actions/DeepLinkAction';
import { goToZoom } from '../../Utils/commonUtils';
const OnBoardingStep1 = props => {
  const dispatch = useDispatch();
  const [allData, setALLData] = useState(props.data);
  const [show, setShow] = useState(props.show);
  const [showTypes, setShowTypes] = useState(false);
  const [showDescription, setshowDescription] = useState(false);
  const [data, setData] = useState({
    fname: '',
    lname: '',
  });
  const buttonRef = useRef(null);
  const [profileImg, setProfileImg] = useState('');
  const [description, setdescription] = useState('');
  const [validated, setValidated] = useState(false);
  // const [type, setType] = useState('');

  const [currentStep, setStep] = useState('name');

  const [opportunityTypeIds, setOpportunityTypeIds] = useState([]);
  const [mineAreas, setMineAreas] = useState([]);
  const [mineCategories, setMineCategories] = useState([]);
  const [otherAreas, setOtherAreas] = useState([]);
  const [otherCategories, setOtherCategories] = useState([]);
  const [sessionCategories, setSessionCategories] = useState([]);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [showUp, setshowUp] = useState(false);
  const [idModel, setIdModel] = useState('');
  const [OpportunityTypeSub, setOpportunityTypeSub] = useState([]);
  const [array, setArray] = useState([]);
  const [subOppId, setSubOppId] = useState([]);
  const history = useHistory();

  const updateImage = e => {
    // setProfileImg();
    const imgData = e.target.files[0];
    if (!/^image\//.test(imgData.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else {
      const objectUrl = URL.createObjectURL(imgData);

      let reader = new FileReader();
      reader.readAsDataURL(imgData);
      reader.onload = e => {
        const tempData = {
          ...data,
          profile_img: e.target.result,
        };
        setData(tempData);
      };
      //
      setProfileImg(objectUrl);
    }
  };

  const onChangeData = e => {
    const updatedValue = e.target.value.replace(/\s/g, '');
    const tempData = {
      ...data,
      [e.target.name]: updatedValue,
    };

    setData(tempData);
  };

  const handleClick = (e, t, item) => {
    const {value, checked} = e.target;
    if (t === 'mineAreas') {
      if (!checked) {
        setMineAreas(mineAreas.filter(item => item !== value));
      } else if (mineAreas.length < 2) {
        setMineAreas([...mineAreas, value]);
      } else {
        errorMessage('Maximum two can be selected');
      }
    } else if (t === 'mineCategories') {
      if (!checked) {
        setMineCategories(mineCategories.filter(item => item !== value));
      } else if (mineCategories.length < 5) {
        setMineCategories([...mineCategories, value]);
      } else {
        errorMessage('Maximum five can be selected');
      }
    } else if (t === 'otherAreas') {
      // if (otherAreas.length < 2) {
      setOtherAreas([...otherAreas, value]);
      // }
      if (!checked) {
        setOtherAreas(otherAreas.filter(item => item !== value));
      }
    } else if (t === 'otherCategories') {
      setOtherCategories([...otherCategories, value]);
      if (!checked) {
        setOtherCategories(otherCategories.filter(item => item !== value));
      }
    } else if (t === 'sessionCategories') {
      setSessionCategories([...sessionCategories, value]);
      if (!checked) {
        setSessionCategories(sessionCategories.filter(item => item !== value));
      }
    } else if (t === 'opportunityTypeIds') {
      setOpportunityTypeIds([...opportunityTypeIds, value]);
      setIdModel(item.id);
      setOpportunityTypeSub(item.sub_oppo_type);
      setArray([...array, {opportunity_id: value, sub_opportunity_ids: ''}]);
      // if (!checked) {
      //     setOpportunityTypeIds(opportunityTypeIds.filter((item) => item !== id));
      // }
      if (!checked) {
        const found = array.find(objs => objs.opportunity_id === value);
        if (typeof found === 'object') {
          const idToRemove = found.sub_opportunity_ids;
          setSubOppId(subOppId.filter(item => item !== idToRemove));
        }
        setOpportunityTypeIds(
          opportunityTypeIds.filter(item => item !== value),
        );
        setArray(array.filter(item => item.opportunity_id !== value));
      }
      if (['1', '2', '5', 1, 2, 5].includes(value) && checked) {
        handleShow();
      }
    }
  };

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setSessionCategories(allData.session_categories.map(item => item.id));
    if (isCheckAll) {
      setSessionCategories([]);
    }
  };

  useEffect(() => {
    if (allData.session_categories.length > 0) {
      if (sessionCategories.length === allData.session_categories.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionCategories]);

  const createUser = con => {
    const isSkipped = getItem('onBoardingSkip');
    if (data.fname.length > 0 && data.lname.length > 0) {
      const dataTwo = {
        fcmToken: '',
        firstName: data.fname,
        instagram: '',
        lastName: data.lname,
        linkedin: '',
        phoneNumber: getItem('loginNum'),
        twitter: '',
        web: '',
        youtube: '',
      };
      commonApi
        .create_profile(dataTwo)
        .then(response => {
          if (response.status === 200) {
            if (response.user) {
              setItem('user', response.user.id);
              setItem('id', response.user.id);
              setItem(
                'profilePic',
                response.user.profile_img
                  ? response.user.profile_img
                  : DEFAULT_IMAGE,
              );
              setItem('userName', response.user.unique_user_name);
              setItem('bio', response.user.bio);
              setItem('currentLocation', response.user.current_location);
              setItem(
                'currentLocationLatitude',
                response.user.current_location_latitude,
              );
              setItem(
                'currentLocationLongitude',
                response.user.current_location_longitude,
              );
              setItem('email', response.user.email);
              setItem('fcmToken', response.user.firebase_token);
              setItem('firstName', response.user.first_name);
              setItem('homeLocation', response.user.home_location);
              setItem(
                'homeLocationLatitude',
                response.user.home_location_latitude,
              );
              setItem(
                'homeLocationLongitude',
                response.user.home_location_longitude,
              );
              setItem('instagram', response.user.instagram);
              // setItem("id", response.user.invitation_id);
              setItem('lastName', response.user.last_name);
              setItem('linkedin', response.user.linkedin);
              setItem('number', response.user.phone);
              setItem('qrCode', response.user.qr_code);
              // setItem("id", response.user.role_id);
              // setItem("id", response.user.unique_user_name);
              // setItem("id", response.user.verification_status);
              setItem('web', response.user.web);
              setItem('youtube', response.user.youtube);
              setItem('twitter', response.user.twitter);
              setItem('otherProfile', response.user.other_profile);
            }
            setItem('bf_auth_token', response.token);
            setItem('token', response.token);
            // setHeaders(authToken);
            if (data.profile_img) {
              var bodyFormData = new FormData();
              bodyFormData.append('picture', data.profile_img);
              commonApi
                .upload_profile_picture(bodyFormData)
                .then(response => {
                  if (response.status === 200) {
                    setItem(
                      'profilePic',
                      response.user.profile_img
                        ? response.user.profile_img
                        : DEFAULT_IMAGE,
                    );
                    if (isSkipped === 'skip') {
                      history.push('/opportunities');
                    } else {
                      setStep(1);
                    }
                  }
                })
                .catch(err => {
                  console.log('Err', err);
                  return {type: 'error', message: err.message};
                });
            } else {
              setItem('profilePic', DEFAULT_IMAGE);
              if (isSkipped === 'skip') {
                history.push('/opportunities');
              } else {
                setStep(1);
              }
            }
          }
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
    }
  };

  const createOnBoardingDetails = () => {
    const dataTwo = {
      accredited: 'no',
      creation_category_tag_ids: '',
      latitude: '',
      location: '',
      longitude: '',
      mine_areas: mineAreas,
      mine_categories: mineCategories,
      opportunity_amount_type_value: '',
      opportunity_frequency_ids: '',
      opportunity_type_ids: array,
      option_id: [],
      other_areas: otherAreas,
      other_categories: otherCategories,
      session_categories: sessionCategories,
      shop_category_tag_ids: '',
      tone_id: '',
      vaccination: '',
    };
    commonApi
      .create_on_boarding_details(dataTwo)
      .then(response => {
        if (response.status === 200) {
          // setStep(4);
          dispatch(callDeepLink());
          var urlUser = window.location.pathname;
          urlUser = urlUser.slice(1);
          var url = urlUser.split(/[.\-/]/);

          if (url && url[1] === 'session_invite') {
            joinTheSession(url[2]);
          }
          if (url && url[1] === 'event_invite') {
            history.push('/events/event_invite/' + url[2]);
            window.location.reload();
          }
          if (url && url[1] === 'opportunity_invite') {
            history.push('/opportunities/opportunity_invite/' + url[2]);
            window.location.reload();
          }
          if (url && url[1] === 'profile_invite') {
            history.push('/events/profile_invite/' + url[2]);
            window.location.reload();
          }

          if (
            url &&
            url[1] !== 'opportunity_invite' &&
            url[1] !== 'session_invite' &&
            url[1] !== 'event_invite' &&
            url[1] !== 'profile_invite'
          ) {
            history.push('/opportunities');
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleValidation = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      // setType('');
      setItem('onBoardingSkip', '');

      //
      setValidated(true);
    } else {
      event.preventDefault();

      //
      setValidated(false);

      createUser();
    }
  };
  const joinTheSession = async id => {
    let payload = {conference_id: id};
    const response = await API.is_session_started(payload);

    if (response.started) {
      if (response.session_id) {
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', id);
        // history.push('/session-details');
        // window.location.reload();
        goToZoom(response.session_id);
      } else {
      }
    } else {
      errorMessage(response.message);
    }
  };
  const onSkipOnboarding = () => {
    dispatch(callDeepLink());
    var urlUser = window.location.pathname;
    urlUser = urlUser.slice(1);
    var url = urlUser.split(/[.\-/]/);

    // setType('skip');
    setItem('onBoardingSkip', 'skip');
    buttonRef.current.click();
    if (url && url[1] === 'session_invite') {
      joinTheSession(url[2]);
    }
    if (url && url[1] === 'event_invite') {
      history.push('/events/event_invite/' + url[2]);
      window.location.reload();
    }
    if (url && url[1] === 'opportunity_invite') {
      history.push('/opportunities/opportunity_invite/' + url[2]);
      window.location.reload();
    }
  };
  const stepName = () => {
    return (
      <div className="onboarding-profile">
        <div className="profile-img">
          <input
            type="file"
            accept="image/*"
            name="profile_img"
            onChange={e => updateImage(e)}
          />
          {profileImg && <img src={profileImg} alt="" />}
          {!profileImg && <i className="fas fa-camera" />}
        </div>
        <h3>What’s your name?</h3>
        <p>Yes, we use real names.</p>

        <Form.Group className="fieldset mt-3">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            name="fname"
            pattern="[^\s ]+"
            value={data.fname ? data.fname : ''}
            onChange={e => onChangeData(e)}
            placeholder="First Name"
            required={true}
            style={{width: '50%'}}
          />
          <Form.Control.Feedback type="invalid">
            {data.fname === ''
              ? 'First name is required.'
              : 'Space not allowed.'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="fieldset">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            pattern="[^\s ]+"
            name="lname"
            value={data.lname ? data.lname : ''}
            onChange={e => onChangeData(e)}
            placeholder="Last Name"
            required
            style={{width: '50%'}}
          />
          <Form.Control.Feedback type="invalid">
            {data.lname === ''
              ? 'Last name is required.'
              : 'Space not allowed.'}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="info-text">
          <p>
            To create the best experience, we have a couple questions for you.
          </p>
          <span
            className="skip-link"
            // onClick={(e)=>{handleValidation('skip')}}
            onClick={() => {
              onSkipOnboarding();
            }}>
            Skip Onboarding
          </span>
        </div>
        <Button className="make-btn-small" type="submit" onClick={() => {}}>
          Next
        </Button>
        <Button
          type="submit"
          style={{display: 'none'}}
          ref={buttonRef}></Button>
      </div>
    );
  };

  const stepZero = () => {
    return (
      <>
        <div className="onboarding-questions-wrapper">
          <div className="questions-heading">
            <h4>Step 1 of 4</h4>
            <p>To build efficiently, you must be intentional.</p>
            <p>If a question makes you uncomfortable, leave it blank.</p>
            <h2>
              What types of <b>opportunities</b> interest you?
            </h2>
          </div>

          <Form.Group className="fieldset">
            {Object.entries(allData.opportunity_types).length > 0 &&
              allData.opportunity_types.map((item, index) => {
                return (
                  <div key={`opportunity_types${item.id}`}>
                    <Form.Check
                      value={item.id}
                      type="checkbox"
                      label={item.type_name}
                      key={`opportunity_types${item.id}`}
                      onChange={e => {
                        handleClick(e, 'opportunityTypeIds', item);
                      }}
                      checked={opportunityTypeIds.includes(item.id)}
                    />
                    {item.sub_oppo_type.length > 0 && (
                      <div className="sub-oppo-type-list">
                        {item.sub_oppo_type.forEach((miniItem, index) => {
                          if (subOppId.includes(miniItem.id)) {
                            return <span key={index}> - {miniItem.title}</span>;
                          }
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
          </Form.Group>

          <Button className="make-btn-small" onClick={() => setStep(2)}>
            Next
          </Button>
        </div>
      </>
    );
  };

  const stepOne = () => {
    return (
      <div className="onboarding-questions-wrapper">
        <div className="questions-heading">
          <div className="previous-steps">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => {
                setStep(1);
              }}>
              <i className="fas fa-angle-left" /> View Previous Step
            </a>
          </div>
          <h4>Step 2 of 4</h4>
          <h2>
            Tell us a little about <b>yourself</b>
          </h2>
          <p>
            Choose up to 2 personality types that are most natural to you. Tap
            for descriptions.
          </p>
        </div>

        <Form.Group className="fieldset">
          {Object.entries(allData.boarding_areas).length > 0 &&
            allData.boarding_areas.map((item, index) => {
              return (
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Check
                    value={item.id}
                    type="checkbox"
                    label={item.title}
                    key={`boarding_areas_${item.id}`}
                    onChange={e => {
                      handleClick(e, 'mineAreas');
                    }}
                    checked={mineAreas.includes(item.id)}
                  />
                  <span
                    className="icon-info"
                    onClick={() => {
                      setdescription(item.description);
                      setshowDescription(true);
                    }}>
                    {' '}
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </span>
                </div>
              );
            })}
        </Form.Group>

        <Form.Group className="fieldset">
          <Form.Label>
            Which of the following best represents your background?
          </Form.Label>
          {Object.entries(allData.boarding_catgories).length > 0 &&
            allData.boarding_catgories.map((item, index) => {
              return (
                <Form.Check
                  value={item.id}
                  type="checkbox"
                  label={item.title}
                  key={`boarding_catgories_${item.id}`}
                  onChange={e => {
                    handleClick(e, 'mineCategories');
                  }}
                  checked={mineCategories.includes(item.id)}
                />
              );
            })}
        </Form.Group>

        <Button className="make-btn-small" onClick={() => setStep(3)}>
          Next
        </Button>
      </div>
    );
  };
  const stepTwo = () => {
    return (
      <>
        <div className="onboarding-questions-wrapper">
          <div className="questions-heading">
            <div className="previous-steps">
              <div className="d-flex justify-content-between w-100">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="as-link">View Previous Steps</a>
                <span
                  onClick={() => {
                    setshowUp(!showUp);
                  }}>
                  {showUp ? (
                    <i className="fas fa-angle-up"></i>
                  ) : (
                    <i className="fas fa-angle-down"></i>
                  )}
                </span>
              </div>

              {showUp && (
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    className="as-link"
                    onClick={() => {
                      setStep(1);
                    }}>
                    <i className="fas fa-angle-left" /> Opportunities
                  </a>
                  <br />
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    className="as-link"
                    onClick={() => {
                      setStep(2);
                    }}>
                    <i className="fas fa-angle-left" /> What do you bring to the
                    table?
                  </a>
                </>
              )}
            </div>

            <h4>Step 3 of 4</h4>
            <h2>
              Who would you like to <b>build</b> with?
            </h2>
            <p>Choose as many as you want. Tap for descriptions.</p>
          </div>

          <Form.Group className="fieldset">
            {Object.entries(allData.boarding_areas).length > 0 &&
              allData.boarding_areas.map((item, index) => {
                return (
                  <Form.Check
                    value={item.id}
                    type="checkbox"
                    label={item.title}
                    key={`boarding_areas_${item.id}`}
                    onChange={e => {
                      handleClick(e, 'otherAreas');
                    }}
                    checked={otherAreas.includes(item.id)}
                  />
                );
              })}
          </Form.Group>

          <Form.Group className="fieldset">
            <Form.Label>
              Which of the following best represents your background?
            </Form.Label>
            {Object.entries(allData.boarding_catgories).length > 0 &&
              allData.boarding_catgories.map((item, index) => {
                return (
                  <Form.Check
                    value={item.id}
                    type="checkbox"
                    label={item.title}
                    key={`boarding_catgories_${item.id}`}
                    onChange={e => {
                      handleClick(e, 'otherCategories');
                    }}
                    checked={otherCategories.includes(item.id)}
                  />
                );
              })}
          </Form.Group>

          <Button onClick={() => setStep(4)} className="make-btn-small">
            Next
          </Button>
        </div>
      </>
    );
  };
  const stepThree = () => {
    return (
      <>
        <div className="onboarding-questions-wrapper">
          <div className="questions-heading">
            <div className="previous-steps">
              <div className="d-flex justify-content-between">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="as-link">View Previous Steps</a>
                <span
                  onClick={() => {
                    setshowUp(!showUp);
                  }}>
                  {showUp ? (
                    <i className="fas fa-angle-up"></i>
                  ) : (
                    <i className="fas fa-angle-down"></i>
                  )}
                </span>
              </div>
              {showUp ? (
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => {
                      setStep(1);
                    }}>
                    <i className="fas fa-angle-left" /> Opportunities
                  </a>
                  <br />
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => {
                      setStep(2);
                    }}>
                    <i className="fas fa-angle-left" /> What do you bring to the
                    table?
                  </a>
                  <br />
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() => {
                      setStep(3);
                    }}>
                    <i className="fas fa-angle-left" /> What would you like
                    brought to the table?
                  </a>
                </>
              ) : (
                <></>
              )}
            </div>
            <h4>Step 4 of 4</h4>
            <h2>
              Which <b>topics</b> do you vibe with the most?
            </h2>
          </div>

          <Form.Group className="fieldset">
            <Form.Check
              type="checkbox"
              id="ALL"
              label="All"
              onChange={handleSelectAll}
              checked={isCheckAll}
            />
            {Object.entries(allData.session_categories).length > 0 &&
              allData.session_categories.map((item, index) => {
                return (
                  <Form.Check
                    value={item.id}
                    type="checkbox"
                    label={item.ses_category_name}
                    key={`session_categories${item.id}`}
                    onChange={e => {
                      handleClick(e, 'sessionCategories');
                    }}
                    checked={sessionCategories.includes(item.id)}
                  />
                );
              })}
          </Form.Group>

          {/* <Button onClick={() => setStep(4)}>Next</Button> */}
          <Button
            className="make-btn-small"
            onClick={() => {
              createOnBoardingDetails();
            }}>
            Next
          </Button>
        </div>
      </>
    );
  };
  // const stepFour = () => {
  //   return (
  //     <>
  //       <div className="onboarding-questions-wrapper">
  //         <div className="questions-heading">
  //           <h2>
  //             Follow <b>people you know</b> that are already building
  //           </h2>
  //         </div>

  //         <div className="recommend-list">
  //           <div className="recommend-item">
  //             <div className="recommend-img">
  //               <img src={sessionUser14} alt="" />
  //             </div>
  //             <div className="recommend-name">
  //               <h2>Ronnie Brown</h2>
  //               <p>202.123.1234</p>
  //             </div>
  //             <div className="recommend-btns">
  //               <Button>Follow</Button>
  //             </div>
  //           </div>
  //           <div className="recommend-item">
  //             <div className="recommend-img">
  //               <img src={sessionUser14} alt="" />
  //             </div>
  //             <div className="recommend-name">
  //               <h2>Ronnie Brown</h2>
  //               <p>202.123.1234</p>
  //             </div>
  //             <div className="recommend-btns">
  //               <Button>Follow</Button>
  //             </div>
  //           </div>
  //           <div className="recommend-item">
  //             <div className="recommend-img">
  //               <img src={sessionUser14} alt="" />
  //             </div>
  //             <div className="recommend-name">
  //               <h2>Ronnie Brown</h2>
  //               <p>202.123.1234</p>
  //             </div>
  //             <div className="recommend-btns">
  //               <Button>Follow</Button>
  //             </div>
  //           </div>
  //           <div className="recommend-item">
  //             <div className="recommend-img">
  //               <img src={sessionUser14} alt="" />
  //             </div>
  //             <div className="recommend-name">
  //               <h2>Ronnie Brown</h2>
  //               <p>202.123.1234</p>
  //             </div>
  //             <div className="recommend-btns">
  //               <Button>Follow</Button>
  //             </div>
  //           </div>
  //         </div>

  //         <Button className="make-btn-small" onClick={() => setStep(5)}>
  //           Next
  //         </Button>
  //       </div>
  //     </>
  //   );
  // };
  // const stepFive = () => {
  //   return (
  //     <>
  //       <div className="onboarding-questions-wrapper">
  //         <div className="questions-heading">
  //           <h2>
  //             Who would you like to <b>Invite </b>?
  //           </h2>
  //         </div>

  //         <div className="recommend-list">
  //           <div className="recommend-item">
  //             <div className="recommend-img">
  //               <img src={sessionUser14} alt="" />
  //             </div>
  //             <div className="recommend-name">
  //               <h2>Ronnie Brown</h2>
  //               <p>202.123.1234</p>
  //             </div>
  //             <div className="recommend-btns">
  //               <Button>Invite</Button>
  //             </div>
  //           </div>
  //           <div className="recommend-item">
  //             <div className="recommend-img">
  //               <img src={sessionUser14} alt="" />
  //             </div>
  //             <div className="recommend-name">
  //               <h2>Ronnie Brown</h2>
  //               <p>202.123.1234</p>
  //             </div>
  //             <div className="recommend-btns">
  //               <Button>Invite</Button>
  //             </div>
  //           </div>
  //         </div>

  //         <Button onClick={() => {}}>Done</Button>
  //       </div>
  //     </>
  //   );
  // };

  // const closeModal = () => {
  //   setShow(false);
  //   // props.closeModal();
  //   // props.onClose();
  // };

  const handleClose = subId => {
    setShowTypes(false);
    if (subId) {
      const xyzArr = array;
      const lastIndex = array.length - 1;
      xyzArr[lastIndex]['sub_opportunity_ids'] = subId;

      setArray(xyzArr);
    }
  };
  const handleShow = () => setShowTypes(true);

  return (
    <>
      <Modal
        className={`medium ${showDescription || showTypes ? 'opacity-0' : ''}`}
        show={show}
        // onHide={() => closeModal()}
      >
        <Modal.Header>
          <Modal.Title>
            {currentStep === 'name' && (
              <div className="modal-logo">
                <img src={Logo} alt="" />
              </div>
            )}
            {currentStep !== 'name' && (
              <div className="modal-header-icons">
                {/* {currentStep !== 1 && (
                                    <span onClick={() => setStep(currentStep !== 1 ? currentStep - 1 : "name")}>
                                        <i className="fas fa-angle-left" /> Back
                                    </span>
                                )} */}
              </div>
            )}
          </Modal.Title>
          {currentStep !== 'name' && currentStep !== 5 && (
            <div className="modal-header-btns">
              {/* <span className="skip-btn close as-link" onClick={() => closeModal()}> */}
              <span
                className="skip-btn close as-link"
                onClick={() => {
                  currentStep < 4
                    ? setStep(currentStep + 1)
                    : createOnBoardingDetails();
                }}>
                Skip
              </span>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="onboarding-step-wrapper">
            <Form noValidate validated={validated} onSubmit={handleValidation}>
              {currentStep === 'name' && stepName()}
              {currentStep === 1 && stepZero()}
              {currentStep === 2 && stepOne()}
              {currentStep === 3 && stepTwo()}
              {currentStep === 4 && stepThree()}
              {/* {currentStep === 4 && stepFour()} */}
              {/* {currentStep === 5 && stepFive()} */}
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {OpportunityTypeSub.length > 0 && (
        <Modal
          show={showTypes}
          // Temporary state for remove warning
          setALLData={setALLData}>
          <Modal.Header>
            <Modal.Title>&nbsp;</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="onboarding-questions-wrapper">
              <div className="questions-heading">
                {idModel === '1' && (
                  <h2>
                    Are you an accredited Investor? We ask this question to keep
                    BUILD community safe misrepresenting youself may lead to
                    temporary or permanent suspension from our community
                  </h2>
                )}
                {idModel === '2' && <h2>Do you have a security clearance</h2>}
                {idModel === '5' && <h2>Which best describe you</h2>}

                <div className="ques-options-list">
                  <ul>
                    {Object.entries(OpportunityTypeSub).length > 0 ? (
                      Object.entries(OpportunityTypeSub).length > 0 &&
                      OpportunityTypeSub.map((item, index) => {
                        return (
                          <li
                            key={`opportunityTypeSub-${index}`}
                            onClick={() => {
                              setSubOppId(current => [...current, item.id]);
                              handleClose(item.id);
                            }}
                            value={item.id}>
                            <span>
                              <i className="far fa-circle" />
                            </span>
                            {item.title}
                          </li>
                        );
                      })
                    ) : (
                      <li>{labels.no_record_found[selected_lang]}</li>
                    )}

                    {idModel === '2' && (
                      <li
                        onClick={() => {
                          handleClose();
                        }}>
                        <span>
                          <i className="far fa-circle" />
                        </span>
                        NO CLEARANCE
                      </li>
                    )}
                    {idModel === '5' && (
                      <li
                        onClick={() => {
                          handleClose();
                        }}>
                        <span>
                          <i className="far fa-circle" />
                        </span>
                        Not yet
                      </li>
                    )}
                    {idModel === '1' && (
                      <li
                        onClick={() => {
                          handleClose();
                        }}>
                        <span>
                          <i className="far fa-circle" />
                        </span>
                        Not yet
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Modal show={showDescription} setShow={setShow}>
        <Modal.Header>
          <Modal.Title>Description</Modal.Title>
          <i
            style={{color: 'white'}}
            className="fa-solid fa-xmark"
            onClick={() => {
              setshowDescription(!showDescription);
            }}></i>
        </Modal.Header>
        <Modal.Body>
          <div className="onboarding-questions-wrapper">
            <div className="questions-heading">
              <p>{description}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OnBoardingStep1;
