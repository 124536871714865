import React, {useState, useEffect} from 'react';
import {Button, Form, Col} from 'react-bootstrap';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {formatHoursMinutesTime} from '../../Utils/commonUtils';
import { RoiLogo } from '../../Constants/Images';

import LoaderSpinner from '../Modals/LoaderSpinner';
const CreateCatalogGroup = props => {
  const [circleName, setCircleName] = useState(
    props.case === 'editCatalog' ? props.catalogItem.group_name : '',
  );
  const [member, setMember] = useState(
    props.case === 'editCatalog'
      ? props.catalogItem.catalog_ids.split(',')
      : [],
  );

  const [selectedFollowers, setSelectedFollowers] = useState([]);
  const [circleFollowers, setCircleFollowers] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [searchQry, setSearchQry] = useState('');
  const [showLoader, setshowLoader] = useState(true);

  const create_group_catalog = () => {
    var data = {
      group_name: circleName,
      catalog_ids: member.toString(','),
      // group_id: workSpaceIds.toString(','),
    };
    commonApi
      .create_group_catalog(data)
      .then(res => {
        if (res.success === true) {
          props.onClose(true);
          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const edit_group_catalog = () => {
    var data = {
      group_id: props.catalogItem.id ? props.catalogItem.id : '',
      catalog_ids: member.toString(','),
      group_name: circleName,
      // group_id: workSpaceIds.toString(','),
    };
    commonApi
      .create_group_catalog(data)
      .then(res => {
        if (res.success === true) {
          props.onClose(true);
          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handle = () => {
    if (circleName === '') {
      errorMessage(
        props.type === 'catalog'
          ? 'Group name is required'
          : 'Circle name is required',
      );
    } else if (member.length < 2) {
      errorMessage(
        props.type === 'catalog'
          ? 'Minmum two catalogs are required'
          : 'Minmum one member required',
      );
    } else {
      if (props.type === 'catalog') {
        if (props.case === 'editCatalog') {
          edit_group_catalog();
        } else {
          create_group_catalog();
        }
      }
    }
  };

  useEffect(() => {
    setSearchQry('');

    list_of_catalogs_ps('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const list_of_catalogs_ps = qry => {
    // alert('@@@@@')

    var payLoad = {
      search_query: qry,
    };
    commonApi
      .list_of_catalogs_ps(payLoad)
      .then(res => {
        setshowLoader(false);
        if (res.status === 200) {
          setFollowers(res.catalog_data);
          setCircleFollowers(res.catalog_data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const ModData = mod => {
    if (!member.includes(mod.id)) {
      if (props.pipeline) {
        props.setModerator([mod.id]);
        props.setSelectedModData([mod]);
        props.setOriginalDataOfMod([mod]);
      } else {
        setMember(previous => [...previous, mod.id]);
        // setModerator(previous => [...previous, mod.id]);
        setSelectedFollowers(previous => [...previous, mod]);
        setOriginalData(previous => [...previous, mod]);
      }
    } else if (
      props.alreadyAddedMod &&
      props.alreadyAddedMod.includes(mod.id)
    ) {
      errorMessage('This user is  already associated');
    } else {
      setMember(previous => previous.filter(id => id !== mod.id));

      // setModerator(previous => previous.filter(id => id !== mod.id));
      setSelectedFollowers(previous =>
        previous.filter(item => item.id !== mod.id),
      );
      setOriginalData(previous => previous.filter(item => item.id !== mod.id));
    }

    //
  };

  const handleSearchQry = q => {
    list_of_catalogs_ps(q);
  };

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };

  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()} renderData={props.component ==='offering' ? renderHeader : () => null}
    showBlackHeader={props.component ==='offering' && true}
    headerPadding={props.component ==='offering' ? '24px 20px' :''}
    lastDivStyle={props.component ==='offering' ? {borderRadius: '35px', background: '#f7f6f2'} :{marginTop : '20px',}}
    firstDivStyle={ {
      padding: 0,
      background: props.component ==='offering' ? 
        'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' :'',
      
    }}
    >
        <div className="serach-filter-wrapper">
          <div className=" create_catalog_group pt-3 primary_panel_content  ">
            <div className="search-form black-head-form-pad-left fixed-width-inputs fix-width-forms">
              <Form.Group className="search-input">
                <Form.Label className="f_600_h">
                  {props.type === 'catalog' ? 'Group Name' : ' Circle Name'}
                </Form.Label>
                <Form.Control
                  onChange={e => {
                    setCircleName(e.target.value);
                  }}
                  value={circleName}
                  pattern="[a-zA-Z][a-zA-Z ]+"
                  type="text"
                  placeholder={
                    props.type === 'catalog' ? 'Group Name' : 'Circle name'
                  }
                />
              </Form.Group>
            </div>

            <div className="ScheduleScreen_Style  primary_panel_content">
              <div
                className="invite-page-search black-head-form-pad-left fixed-width-inputs fix-width-forms "
                style={{paddingRight: '15px', margin: 0}}>
                <div className="search-form">
                  <Form.Group className="search-input">
                    <Form.Control
                      onChange={e => {
                        setSearchQry(e.target.value);
                        handleSearchQry(e.target.value);
                      }}
                      value={searchQry}
                      type="text"
                      placeholder={'Search for catalog'}
                    />
                  </Form.Group>
                </div>
              </div>

              {followers && followers.length > 0 && (
                <div className="d-flex black-head-form-pad-left fix-width-forms  mb-2">
                  <p className="p-bold f_600_h">Select Catalogs</p>
                </div>
              )}

              {props.type === 'catalog' && (
                <div className="refferal-list-wrapper fixed-width-inputs fix-width-forms ">
                  {showLoader && <LoaderSpinner />}
                  <Scrollbars>
                    <div
                      className="ld_list_card mt-2 mb-4 black-head-form-pad-left "
                      style={{paddingBottom: '40px'}}>
                      <ul>
                        {Object.entries(followers).length > 0 ? (
                          Object.entries(followers).length > 0 &&
                          followers.map((item, index) => (
                            <div
                              className={`deal_item as-link ${
                                member.includes(item.id) ? 'selercted-user' : ''
                              }`}
                              key={index}
                              onClick={() => {
                                ModData(item);
                              }}>
                              <div className="f_600_h text_overflow_container">
                                {item.title}
                              </div>

                              <p className="deal_item_desc">
                                {item.description ? item.description : ''}
                              </p>

                              <div className="d-flex justify-content-between">
                                <span className="deal_time">
                                  {item.duration
                                    ? formatHoursMinutesTime(item.duration)
                                    : ''}
                                </span>
                                <span className="deal_cost f_600_h">
                                  {item.cost ? `$${item.cost}` : 'Free'}
                                </span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Col md={12} xl={12} className="text-center">
                            {props.invoice && 'No catalog found'}
                          </Col>
                        )}
                      </ul>
                    </div>
                  </Scrollbars>
                </div>
              )}
            </div>
          </div>

          {props.type === 'catalog' && circleFollowers.length > 0 && (
            <div
              className="filter-btn-wrapper create-session-btn fixed-width-inputs  fix-width-forms mx-0 bg_transparent"
              style={{bottom: '0px'}}>
              <div className="invite-btns">
                <Button
                  className="mb-0"
                  onClick={() => {
                    handle();
                  }}>
                  {props.case === 'editCatalog'
                    ? 'Update Group'
                    : 'Create Group'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>
    </>
  );
};

export default CreateCatalogGroup;
