import React, { useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import CustomModal from '../CustomModal';
import Scrollbars from 'react-custom-scrollbars';

const SessionSearchFilter = props => {
  // const [show, setShow] = useState(props.show);
  const closeModal = () => {
    // setShow(false);
    props.onClose();
  };

  const categoryData = useSelector(state => state.sessionCategory);

  useEffect(() => {}, []);

  const handleSelectAll = e => {
    props.setIsCheckAll(!props.isCheckAll);
    props.setIsCheck(categoryData.map(item => item.id));
    if (props.isCheckAll) {
      props.setIsCheck([]);
    }
  };

  const handleClick = e => {
    const {id, checked} = e.target;
    props.setIsCheck([...props.isCheck, id]);
    if (!checked) {
      props.setIsCheck(props.isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    if (categoryData.length > 0) {
      if (props.isCheck.length === categoryData.length) {
        props.setIsCheckAll(true);
      } else {
        props.setIsCheckAll(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCheck]);

  return (
    <CustomModal style={{height: '100%'}} onClose={() => closeModal()}>
      <div className="serach-filter-wrapper">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="headings m-0">
            <h2 className="m-0">Categories</h2>
          </div>
        </div>

        <Scrollbars>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                <li className={props.isCheckAll ? 'active' : ''}>
                  <Form.Label>
                    All{' '}
                    <input
                      type="checkbox"
                      id="ALL"
                      name="ALL"
                      value="ALL"
                      onChange={handleSelectAll}
                      checked={props.isCheckAll}
                    />{' '}
                    <span />
                  </Form.Label>
                </li>
                {Object.entries(categoryData).length > 0 &&
                  categoryData.map(item => (
                    <li
                      key={`sessionC-${item.id}`}
                      className={
                        props.isCheck.includes(item.id) ? 'active' : ''
                      }>
                      <Form.Label>
                        {item.ses_category_name}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          onChange={handleClick}
                          checked={props.isCheck.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Scrollbars>

        <div className="filter-btn-wrapper text-center pt-3">
          <Button
            className="btn-dark"
            type="submit"
            onClick={() => {
              props.onClose();
            }}>
            Filter Categories
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SessionSearchFilter;
