import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
// import moment from 'moment/moment';
// import Scrollbars from 'react-custom-scrollbars';

import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {list} from '../../Utils/commonUtils';
import Invite from '../SingleComponent/Invite';
import PersonalCreateModel from '../Modals/PersonalCreateModel';
import {useDispatch, useSelector} from 'react-redux';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import LoaderSpinner from '../Modals/LoaderSpinner';
import { RoiLogo } from '../../Constants/Images';
const CreatCircleList = props => {
  // const [showSpinner, setShowSpinner] = useState(true);

  const [circleName, setCircleName] = useState(props.case ==='editCatalog' ? props.catalogItem.group_name : '');
  const [member, setMember] = useState(props.case ==='editCatalog' ? props.catalogItem.catalog_ids.split(',') : []);
  // const [showSelectedUser, setShowSelectedUser] = useState('');
  const [selectedFollowers, setSelectedFollowers] = useState([]);
  const [circleFollowers, setCircleFollowers] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [topIcons, setTopIcons] = useState({
    // focus: false,
    // location: false,
    // vaccinate: false,
    // dollar: false,
    // store: true,
    // bullseye: false,
    personal: false,
  });

  const [contact, setContact] = useState([]);

  const [showComponent, setShowComponent] = useState([]);

  const [workSpaceIds, setWorkSpaceIds] = useState('');

    // Invite States

    const [inviteLoader, setInviteLoader] = useState(true);
    const [inviteFollowers, setInviteFollowers] = useState([]);
    const [followersList, setFollowersList] = useState([]);
    const [inviteQry, setInviteQry] = useState('');


  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);

  const create_group_catalog = () => {
    var data = {
      group_name: circleName,
      catalog_ids: member.toString(','),
      // group_id: workSpaceIds.toString(','),
    };
    commonApi
      .create_group_catalog(data)
      .then(res => {
        if (res.success === true) {
          props.onClose(true);
          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const edit_group_catalog = () => {
    var data = {
      group_id: props.catalogItem.id ?  props.catalogItem.id : '',
      catalog_ids: member.toString(','),
      group_name: circleName,
      // group_id: workSpaceIds.toString(','),
    };
    commonApi
      .create_group_catalog(data)
      .then(res => {
        if (res.success === true) {
          props.onClose(true);
          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    if (workSpaceIds.length > 0) {
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      // setActiveWorkspacesId(updatedIds);
      setWorkSpaceIds(updatedIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  useEffect(() => {
    
    if(contact.length > 0){
      setInviteLoader(true)
      setInviteQry('')
    }
  }, [contact])

  
  const addUserInCircle = () => {
    var data = {
      circle_name: circleName,
      user_ids: member,
      workspace_ids: workSpaceIds.toString(','),
    };
    commonApi
      .create_circle(data)
      .then(res => {
        if (res.success === true) {
          props.onClose(true);
          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handle = () => {
    if (circleName === '') {
      errorMessage(
        props.type === 'catalog'
          ? 'Group name is required'
          : 'Circle name is required',
      );
    } else if (member.length < 2) {
      errorMessage(
        props.type === 'catalog'
          ? 'Minmum two catalogs are required'
          : 'Minmum one member required',
      );
    }
    //  else if (workSpaceIds.length == 0) {
    //   errorMessage('Please select workspace');
    // }
    else {
      if (props.type === 'catalog') {
        if(props.case ==='editCatalog'){
          edit_group_catalog();
        }else{
          create_group_catalog();
        }
        
      } else {
        addUserInCircle();
      }
    }
  };

  const renderHeader = () => {
    
    return (
      <>
        
          <img src={RoiLogo} alt="" />
       
      </>
    );
  };

  console.log(props.catalogItem,'props.catalogItem')
  console.log(circleName,'circleName')

  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()} renderData={renderHeader}
        showBlackHeader={true}
        // headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        {/* {showLoader && <LoaderModal show={showLoader} />} */}
        <div className="serach-filter-wrapper">
          <div className="create-circle-panel primary_panel_content ">
            {/* <div className="ticket-icons-options">
              <ul>
                <li
                className={topIcons.bullseye ? 'active' : ''}
                onClick={() => {
                  setShowComponent('SessionFocus');
                }}>
                <i className="icon-bullseye" />
              </li>
                <li
                  
                  className="active"
                  onClick={() => {
                    setTopIcons({
                      ...topIcons,
                      ['personal']: !topIcons.personal,
                    });
                    setShowComponent('persnal-model');
                  }}>
              
                  <PersnalSvg fill={'white'} />
                  
                </li>
              </ul>
            </div> */}
            <div className="search-form fixed_width_page fixed_padding padding_top">
              <Form.Group className="search-input">
                <Form.Label className='f_600_h'>
                  {props.type === 'catalog' ? 'Group Name' : ' Circle Name'}
                </Form.Label>
                <Form.Control
                  onChange={e => {
                    setCircleName(e.target.value);
                  }}
                  value={circleName}
                  pattern="[a-zA-Z][a-zA-Z ]+"
                  type="text"
                  placeholder={
                    props.type === 'catalog' ? 'Group Name' : 'Circle name'
                  }
                />
              </Form.Group>
            </div>

            
            <div className="refferal-list-wrapper">
            {inviteLoader && (<div className="invite_loader_hieght">
                  <LoaderSpinner />
                  </div>
                 )}
              <Scrollbars>
                <div className="fixed_width_page " style={{padding:'0px 20px 45px 20px'}}>
                  <Invite
                    type={'refer'}
                    invoice={props.type==='catalog' ? true : false}
                    moderator={member}
                    setModerator={setMember}
                    selectedModData={selectedFollowers}
                    setSelectedModData={setSelectedFollowers}
                    originalDataOfMod={originalData}
                    setOriginalDataOfMod={setOriginalData}
                    // hideCircle={true}
                    hideCircleIcon={true}
                    onClick={() => {
                      setShowComponent('inviteUser')
                      setTimeout(() => {
                        if(contact.length ===0 && inviteQry !==''){
                          setInviteFollowers(followersList)
                                setInviteQry('')
                        } 
                      }, 1000);
                     
                    }}
                    contact={contact}
                    setContact={setContact}
                    forApiHit={showComponent}
                    setCircleFollowers={setCircleFollowers}
                    circleFollowers={circleFollowers}
                    component={'groupCatalog'}
                    showLoader={inviteLoader}
                    setshowLoader={setInviteLoader}
                    followers={inviteFollowers}
                    setFollowers={setInviteFollowers}
                    setFollowersList={setFollowersList}
                    followersList={followersList}
                    setSearchQry={setInviteQry}
                    searchQry={inviteQry}
                  />
                </div>
              </Scrollbars>
            </div>
          </div>

          {circleFollowers.length > 0 && (
            <div className="filter-btn-wrapper create-session-btn  fixed_width_page mx-0 bg_transparent" style={{bottom:'0px'}}>
              <div className="invite-btns">
                <Button className='mb-0'
                  onClick={() => {
                    handle();
                  }}>
                  Create Circle
                </Button>
              </div>
            </div>
          )}
          { props.type === 'catalog' && circleFollowers.length > 0 && (
            <div className="filter-btn-wrapper create-session-btn  fix-width-forms mx-0 bg_transparent" style={{bottom:'0px'}}>
              <div className="invite-btns">
                <Button
                className='mb-0'
                  onClick={() => {
                    handle();
                  }}>
                  {props.case ==='editCatalog' ? 'Update Group' : 'Create Group'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>
      {showComponent === 'inviteUser' && (
        <SendInvitation
          onClose={() => {
            
            setShowComponent('');
          }}
          contact={contact}
          setContact={setContact}
        />
      )}

      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          onClose={() => {
            setShowComponent('');
            setTopIcons({
              ...topIcons,
              personal: !topIcons.personal,
            });
          }}
        />
      )}

      {/* {member.length > 0 && selectedAud === true && (
        <SelectedUsers
          onClose={() => setSelectedAud(false)}
          selectedAllUser={selectedAudData}
          setSelectedAllUser={setSelectedAudData}
          setUsers={setMember}
          users={member}
          alreadyAddedUser={[]}
        />
      )} */}
    </>
  );
};
export default CreatCircleList;
