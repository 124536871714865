import React from 'react';
import {TailSpin} from 'react-loader-spinner';

const ScrollLoader = () => {
  return (
    <>
      <div className="custom-spinner">
        <TailSpin color="#272727" />
      </div>
    </>
  );
};

export default ScrollLoader;
