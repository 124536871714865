import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {fixName} from '../../Utils/commonUtils';

import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import moment from 'moment/moment';

const AppoinmentHistoryByType = props => {

  const [appointmentList, setAppointmentList] = useState([]);
  const [showLoader, setshowLoader] = useState(false);

  useEffect(() => {
    if (props.Tab === 'history') {
      getConsultHistoryByType(props.subTabType, '');
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.Tab === 'history') {
    getConsultHistoryByType(props.subTabType, props.search ? props.search  : '' );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search, props.subTabType]);

  const getConsultHistoryByType = (type, srch) => {
    setshowLoader(true);
    const data = {type: type, search_query: srch ? srch : ''};
    commonApi
      .get_consult_history_by_type(data)
      .then(res => {
       

        setTimeout(() => {
          setshowLoader(false);
          setAppointmentList(res.history);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <>
      {showLoader && <LoaderSpinner />}
      {props.subTabType === 'to_me' && (
        <>
          {Object.entries(appointmentList).length > 0 ? (
            appointmentList.map((item, index) => (
              <div className="refferals-list as-link" key={'www' + index}>
                <div className="refferals-list-item card new-refferal-list">
                  <div className="refferal-content">
                    <h2>
                      { item.user_details &&
                        typeof item.user_details === 'object' &&
                        item.user_details !== [] &&
                        fixName(
                          item.user_details.first_name,
                          item.user_details.last_name,
                        )}
                    </h2>
                    <span className="refferal-auther">
                      {item.appointment_type_title}
                    </span>
                    {/* <p>Think there may be some synergy</p> */}
                    <span className="refferal-date">
                      {moment(item.start_date).format('MM/DD/YYYY')}{' '}
                      {item.duration + 'min'}
                    </span>
                  </div>
                  <div className="refferal-img">
                    <img
                      src={
                        item.user_details && item.user_details.profile_img
                          ? item.user_details.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt=""
                    />
                    <p className="refferal-info-que text-end">
                      {item.cost ? '$' + item.cost : 'Free'}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-results-wrapper no-appointments mt-5">
              <div className="img-bg-wrapper">
                <img
                  src={require('../../Assets/images/norecord/no-appointments.png')}
                  alt="appointment"
                />
              </div>
              <div className="no-results-content">
                <p className="noRecord-title">No Previous Appointments</p>
                <p className="noRecord-desc">
                  You don't have any Past appointments
                </p>

                <div className="btn-wrapper mt-3">
                  <Button
                    className={'btn btn-dark'}
                    onClick={() => {
                      props.setShowComponent('create-appointment');
                    }}>
                    Setup Appointment
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {props.subTabType === 'from_me' && (
        <>
          {Object.entries(appointmentList).length > 0 ? (
            appointmentList.map((item, index) => (
              <div className="refferals-list as-link" key={'www' + index}>
                <div className="refferals-list-item card new-refferal-list">
                  <div className="refferal-content">
                    <h2>
                      {item.creator_details &&
                        fixName(
                          item.creator_details.first_name,
                          item.creator_details.last_name,
                        )}
                    </h2>
                    <span className="refferal-auther">
                      {item.appointment_type_title}
                    </span>
                    {/* <p>Think there may be some synergy</p> */}
                    <span className="refferal-date">
                      {moment(item.start_date).format('MM/DD/YYYY')}{' '}
                      {item.duration + 'min'}
                    </span>
                  </div>
                  <div className="refferal-img">
                    <img
                      src={
                        item.creator_details && item.creator_details.profile_img
                          ? item.creator_details.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt=""
                    />
                    <p className="refferal-info-que text-end">
                      {item.cost ? '$' + item.cost : 'Free'}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-results-wrapper no-appointments mt-5">
              <div className="img-bg-wrapper">
                <img
                  src={require('../../Assets/images/norecord/no-appointments.png')}
                  alt="appointment"
                />
              </div>
              <div className="no-results-content">
                <p className="noRecord-title">No Previous Appointments</p>
                <p className="noRecord-desc">
                  You don't have any Past appointments
                </p>

                <div className="btn-wrapper mt-3">
                  <Button
                    className={'btn btn-dark'}
                    onClick={() => {
                      props.setShowComponent('create-appointment');
                    }}>
                    Setup Appointment
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default AppoinmentHistoryByType;
