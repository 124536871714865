import {combineReducers} from 'redux';
import {user} from './AuthReducer';

import {
  menuState,
  // openBottomMenu,
  secondaryPannelState,
  settings,
  notifications,
  pageLoaded,
  usStates,
  myCards,
  subMenuState,
  windowWidth,
  newNotifications,
  updateCompanyName,
  companyLoader,
  companyMenuState,
  registerRouteReducer
} from './CommanReducers';
import {causeCategory, causeRegions, adminCauses} from './CausesReduces-Admin';
import {
  allCauseCategories,
  allCauseRegions,
  myCauses,
  subscriptions,
  myPayments,
  homeCauses,
  categoryCauses,
} from './FrontReducers';
import {
  adminSubscriptions,
  adminPayments,
  expensesList,
  testimonialList,
  termPages,
} from './AdminReducer';

import {users} from './UserReducer';
import { companyTransformationList } from './TransformtionReducer';


import { companyOferringsList } from './BusinessProfileReducer';

import {
  sessionListing,
  sessionCategory,
  builderList,
  AllBuilderList,
  AllWatchesList,
  AllActionsList,
  userSessionListing,
  userEventListing,
  companyMeetupListing
} from './SessionReducer';

import {referralListing, referralApi} from './ReferralReducer';

import {chatListing, personalChatUpdate} from './ChatReducer';

import {
  opportunityListing,
  questionListing,
  opportunityApi,
  onBoardingApi,
  opportunityChatDetail,
  userOpportunityListing,
  userCompanyRecruitment,
  opportunityListingProfile,
  // CompanytyListing
} from './OpportunityReducer';
import {
  menuPermesionList,
  menuActiveList
} from './MenusReducer';

import {
  accomplishmentCategories,
  appointmentTypes,
  opportunityTypes,
} from './FilterReducer';

import {
  deepLinking,
  notification,
  serverTime,
  currentUserData,
  userCompanyData,
  companyUrl,
  newFavourite,
  favouriteSession,
  favouriteRecruitment,
  newFavouriteRecruitment
} from './DeepLinkReducer';
import { hitWorkspace,hitAssociateWorkSpace } from './SessionReducer';
import {apointSlots, apointCurrentDate,appointmentList,userProfileAppointmentList} from './AppointmentReducer';
import { pipelineList ,dealList,updateDealData,updatePipelineData} from './ProfileReducer';

export default combineReducers({
  pageLoaded,
  menuState,
  // openBottomMenu,
  secondaryPannelState,
  usStates,
  settings,
  notifications,
  user,
  users,
  causeCategory,
  causeRegions,
  adminCauses,
  expensesList,
  testimonialList,
  allCauseCategories,
  allCauseRegions,
  homeCauses,
  categoryCauses,
  myCauses,
  myCards,
  subscriptions,
  myPayments,
  adminSubscriptions,
  adminPayments,
  termPages,
  sessionListing,
  sessionCategory,
  referralListing,
  opportunityListing,
  questionListing,
  opportunityApi,
  builderList,
  AllBuilderList,
  AllWatchesList,
  AllActionsList,
  chatListing,
  accomplishmentCategories,
  appointmentTypes,
  onBoardingApi,
  referralApi,
  deepLinking,
  notification,
  subMenuState,
  windowWidth,
  opportunityChatDetail,
  apointSlots,
  pipelineList,
  dealList,
  updateDealData,
  updatePipelineData,
  apointCurrentDate,
  appointmentList,
  userProfileAppointmentList,
  serverTime,
  userSessionListing,
  userEventListing,
  companyMeetupListing,
  userOpportunityListing,
  userCompanyRecruitment,
  opportunityTypes,
  currentUserData,
  userCompanyData,
  companyUrl,
  newNotifications,
  updateCompanyName,
  companyLoader,
  companyMenuState,
  registerRouteReducer,
  personalChatUpdate,
  newFavourite,
  favouriteSession,
  favouriteRecruitment,
  newFavouriteRecruitment,
  hitWorkspace,
  hitAssociateWorkSpace,
  opportunityListingProfile,
  // CompanytyListing,
  menuPermesionList,
  menuActiveList,
  companyTransformationList,
  companyOferringsList
});
