export const opportunityListing = (
  state = {listing: [], payload: {}},
  action,
) => {
  switch (action.type) {
    case 'OPPORTUNITY/GET_DATA':
      // return action.payload.opportunity;
      return {listing: action.payload.opportunity, payload: action.prevPayload};
    case 'OPPORTUNITY/GET_DATA_PAGE':
      // return [...state, ...action.payload.opportunity];
      return {
        listing: [...state.listing, ...action.payload.opportunity],
        payload: state.payload,
      };
    default:
      return state;
  }
};
export const CompanytyListing = (
  state = {listing: [], payload: {}},
  action,
) => {
  switch (action.type) {
    case 'COMPANY/GET_DATA':
      // return action.payload.opportunity;
      return {listing: action.payload.data, payload: action.prevPayload};
    case 'COMPANY/GET_DATA_PAGE':
      // return [...state, ...action.payload.opportunity];
      return {
        listing: [...state.listing, ...action.payload.data],
        payload: state.payload,
      };
    default:
      return state;
  }
};

export const opportunityListingProfile = (
  state = {listing: [], payload: {}},
  action,
) => {
 
  switch (action.type) {
    case 'OPPORTUNITY/GET_DATA2':
      // return action.payload.opportunity;
      return {listing: action.payload?.user_available_opportunity, payload: action.prevPayload};
    // case 'OPPORTUNITY/GET_DATA_PAGE':
    //   // return [...state, ...action.payload.opportunity];
    //   return {
    //     listing: [...state.listing, ...action.payload.user_available_opportunity],
    //     payload: state.payload,
    //   };
    default:
      return state;
  }
};

export const questionListing = (state = {listing: [], payload: {}}, action) => {
  switch (action.type) {
    case 'QUESTION/GET_DATA':
      return {
        listing: action.payload.get_questions_list,
        payload: action.prevPayload,
      };
    case 'QUESTION/GET_DATA_PAGE':
      return {
        listing: [...state.listing, ...action.payload.get_questions_list],
        payload: state.payload,
      };
    default:
      return state;
  }
};

export const opportunityChatDetail = (state = {}, action) => {
  switch (action.type) {
    case 'OPPORTUNITY/CHAT':
      return action.payload;
    default:
      return state;
  }
};

export const opportunityApi = (state = 1, action) => {
  switch (action.type) {
    case 'OPPORTUNITY/API':
      return state + 1;
    default:
      return state;
  }
};

export const onBoardingApi = (state = {}, action) => {
  switch (action.type) {
    case 'ONBOARDING/API':
      return action.payload;
    default:
      return state;
  }
};

export const userOpportunityListing = (state = [], action) => {
  switch (action.type) {
    case 'GET_USER_OPPORTUNITY_LIST':
      return action.payload.opportunity ?  action.payload.opportunity : [];
    default:
      return state;
  }
};

export const userCompanyRecruitment = (state = [], action) => {
  switch (action.type) {
    case 'GET_COMPANY_RECRUITMENT_LIST':
      return action.payload ? action.payload :[];
    default:
      return state;
  }
};
