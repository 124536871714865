import React from 'react'

const CheckMarkSvg = ({fill,w,h}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
    <path d="M15.579 31.158C24.183 31.158 31.158 24.183 31.158 15.579C31.158 6.97496 24.183 0 15.579 0C6.97496 0 0 6.97496 0 15.579C0 24.183 6.97496 31.158 15.579 31.158Z" fill={fill}/>
    <path d="M7 16.7931C7.08343 16.4943 7.18241 16.205 7.37657 15.9491C7.69399 15.5314 8.43765 15.2205 8.99398 15.3151C9.48053 15.398 9.84345 15.6246 10.1893 15.9377C10.804 16.4943 11.44 17.0293 12.0642 17.5759C12.3979 17.8682 12.7278 18.1644 13.0563 18.4625C13.2447 18.6338 13.4749 18.6481 13.646 18.4666C14.0407 18.0478 14.4241 17.6192 14.814 17.1961C15.0464 16.9438 15.2842 16.696 15.5155 16.4426C15.9649 15.9505 16.4105 15.4552 16.8595 14.9628C17.0825 14.7182 17.3112 14.4791 17.5346 14.2349C17.9923 13.7337 18.4474 13.2306 18.9047 12.7291C19.1193 12.4937 19.3385 12.2623 19.5532 12.0273C20.0196 11.5172 20.4827 11.0043 20.9506 10.4954C21.2904 10.1254 21.6386 9.76276 21.9776 9.39243C22.3162 9.02247 22.6522 8.65068 22.9814 8.27302C23.2309 7.98665 23.4342 7.93459 23.7641 8.07465C23.9845 8.16815 24.0667 8.49705 23.9397 8.69504C23.127 9.95893 22.3121 11.2217 21.5005 12.486C20.4982 14.0476 19.4993 15.611 18.4967 17.1723C17.8467 18.185 17.1917 19.1948 16.5414 20.2071C15.93 21.159 15.324 22.1134 14.7116 23.0645C14.4078 23.5368 14.0142 23.895 13.4124 23.9716C13.4051 23.9724 13.4002 23.99 13.3945 23.9999H12.8647C12.4772 23.8855 12.1374 23.6985 11.8769 23.3916C11.7119 23.1972 11.5485 23.0022 11.385 22.8067C11.1321 22.5046 10.8814 22.201 10.6273 21.8993C10.3262 21.5414 10.0236 21.1843 9.71982 20.8282C9.40809 20.4627 9.09258 20.1004 8.78237 19.7341C8.46875 19.3642 8.15702 18.9931 7.84947 18.6187C7.64241 18.3668 7.42322 18.1208 7.24877 17.8487C7.13045 17.6643 7.0804 17.4388 7 17.232C7 17.0857 7 16.9394 7 16.7931Z" fill="white"/>
  </svg>
  )
}

export default CheckMarkSvg