import React, {useRef, useState, useEffect} from 'react';

import SlidingPanel from '../SlidingPanel';
import {interval} from 'rxjs';
import {take} from 'rxjs/operators';
import LoaderSpinner from '../Modals/LoaderSpinner';
import { RoiLogo } from '../../Constants/Images';
import $ from 'jquery';
const ViewFile = ({selectedFile, onClose}) => {
  const [showLoader, setshowLoader] = useState(true);
  const iframeRef = useRef(null);
  const checkIFrameSubscription = useRef(null);

  useEffect(() => {
    const checkIFrame = iframe => {
      const addStylesToIframe = () => {
        // alert('')
        // const iframe = $(iframeRef.current); // Use jQuery to access the iframe
        
        var head = $("#doc_iframe").contents().find("head");
        console.log(head,'----------->head')
var css = '<style type="text/css">' +
          '.ndfHFb-c4YZDc-Wrql6b{display:none}; ' +
          '</style>';
$(head).append(css);
      };


      if (iframe) {
        iframe.onload = () => {
          if (checkIFrameSubscription.current) {
            checkIFrameSubscription.current.unsubscribe();
            addStylesToIframe()
            setTimeout(() => {
              setshowLoader(false);
            }, 1000);
          }
        };
      }
    };

    const reloadIFrame = iframe => {
      if (iframe) {
        if (selectedFile.extension === 'docx' || selectedFile.extension === 'pdf' || selectedFile.extension === 'msword' || selectedFile.extension === 'doc') {
          iframe.src = `https://docs.google.com/a/umd.edu/viewer?url=${selectedFile.file_url}&embedded=true`; // Google document viewer URL
        }
        //  else if (selectedFile.extension === 'pdf') {
        //   iframe.src = `${selectedFile.file_url}#toolbar=0`;
        // } 
        else {
          iframe.src = `https://view.officeapps.live.com/op/embed.aspx?src=${selectedFile.file_url}`;
        }
      }
    };

    let iframe = iframeRef.current;

    checkIFrame(iframe);

    checkIFrameSubscription.current = interval(3000)
      .pipe(take(Math.round(20000 / 3000))) // 20 seconds total
      .subscribe(() => {
        if (iframe === null) {
          iframe = iframeRef.current; // Reassign in case it's updated
          checkIFrame(iframe);
        }
        reloadIFrame(iframe);
      });

    return () => {
      if (checkIFrameSubscription.current) {
        checkIFrameSubscription.current.unsubscribe();
      }
    };
  }, [selectedFile]);

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          onClose();
        }}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        >
        {showLoader && <LoaderSpinner />}
        <div className="serach-filter-wrapper create-sessionEvents pt-3 black-head-form-pad-left ">
          {
          
          selectedFile.extension === 'pdf' ? (
            <iframe
              ref={iframeRef}
              src={`${selectedFile.file_url}#toolbar=0`}
              frameborder="0"
              style={{
                height: '-webkit-fill-available',
                width: '-webkit-fill-available',
              }}></iframe>
          ) : 
          // (selectedFile.extension === 'docx' || selectedFile.extension === 'pdf' || selectedFile.extension === 'doc' || selectedFile.extension === 'msword') ? (
          //   <iframe
          //     ref={iframeRef}
          //     id="doc_iframe"
          //     src={`https://docs.google.com/a/umd.edu/viewer?url=${selectedFile.file_url}&embedded=true`}
          //     frameborder="0"
          //     style={{
          //       height: '-webkit-fill-available',
          //       width: '-webkit-fill-available',
          //     }}
          //     sandbox="allow-scripts allow-same-origin"></iframe>
          // ) :
           (
            <iframe
              ref={iframeRef}
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedFile.file_url}`}
              frameborder="0"
              style={{
                height: '-webkit-fill-available',
                width: '-webkit-fill-available',
              }}></iframe>
          )}

          {/* <embed src={selectedFile} width="100%" height="600px" /> */}
        </div>
      </SlidingPanel>
    </>
  );
};

export default ViewFile;
