import React from 'react'

const StarsSvg = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8611_323)">
<path d="M6.86441 -0.000190999C6.89685 0.111017 6.95503 0.221195 6.95811 0.332918C7.04358 3.46682 9.33524 6.06116 12.4332 6.51784C12.6947 6.55645 12.9614 6.56624 13.2261 6.57911C13.3707 6.58631 13.5242 6.59043 13.5283 6.77321C13.5329 6.97606 13.3682 6.97503 13.2158 6.98224C10.8144 7.09344 8.9944 8.18287 7.77572 10.2521C7.24026 11.1608 6.99107 12.1596 6.96017 13.213C6.9576 13.2959 6.95657 13.3921 6.91538 13.457C6.88346 13.5075 6.75217 13.56 6.73054 13.5399C6.65846 13.474 6.58278 13.3808 6.56836 13.2892C6.49062 12.7918 6.4762 12.2811 6.35727 11.7951C5.68022 9.02671 3.18519 7.04402 0.332846 6.98069C0.264884 6.97915 0.176842 6.99665 0.133078 6.96164C0.0697501 6.91119 -0.016747 6.78093 0.00281784 6.75467C0.0584231 6.67847 0.156762 6.60073 0.247378 6.58734C0.677804 6.52402 1.11904 6.51629 1.54329 6.4262C4.40902 5.8161 6.49628 3.28096 6.56527 0.357116C6.56785 0.237156 6.62654 0.118225 6.65949 -0.0012207C6.72797 -0.0012207 6.79645 -0.0012207 6.86492 -0.0012207L6.86441 -0.000190999Z" fill="black"/>
<path d="M22.9992 12.6381C22.8798 12.6705 22.7608 12.7287 22.6403 12.7318C19.9435 12.8033 17.7157 15.0301 17.6498 17.7253C17.6487 17.7593 17.6621 17.8036 17.6456 17.8262C17.5839 17.9122 17.5128 17.991 17.4454 18.0729C17.3805 17.9884 17.2744 17.9122 17.2574 17.819C17.1761 17.3752 17.1637 16.9144 17.0453 16.4815C16.4537 14.3175 14.4875 12.7941 12.2499 12.7354C12.2241 12.7349 12.1984 12.7338 12.1726 12.7344C12.0264 12.7369 11.8915 12.6844 11.9224 12.5258C11.9374 12.4491 12.0769 12.3601 12.1721 12.3426C12.6082 12.2633 13.0603 12.2514 13.485 12.1356C15.6681 11.5394 17.1859 9.58449 17.2482 7.32789C17.2523 7.17807 17.2446 7.00766 17.4454 7.00302C17.6482 6.99839 17.6462 7.16726 17.6503 7.31863C17.6714 8.08215 17.8361 8.81427 18.196 9.48821C19.149 11.2742 20.6447 12.2195 22.6666 12.3379C22.7788 12.3446 22.8885 12.3997 22.9992 12.4321V12.6376V12.6381Z" fill="black"/>
<path d="M13.9561 18.6201C13.9294 18.8719 13.8068 18.8976 13.6554 18.9017C12.9563 18.9192 12.3019 19.103 11.7123 19.4789C10.5395 20.2259 9.91702 21.301 9.84185 22.6895C9.83361 22.8424 9.83361 23.0061 9.63075 23C9.44798 22.9943 9.44437 22.8398 9.43922 22.6957C9.36045 20.6054 7.73657 18.9872 5.63232 18.9017C5.48507 18.8956 5.3373 18.8822 5.33988 18.6984C5.34297 18.5161 5.49176 18.5053 5.63798 18.4996C7.73708 18.4173 9.35273 16.8022 9.43922 14.6995C9.4454 14.5533 9.4557 14.4045 9.63796 14.4014C9.82228 14.3983 9.83567 14.5461 9.84133 14.6933C9.92834 16.8099 11.5224 18.3951 13.6621 18.5058C13.7672 18.5115 13.8691 18.5851 13.9556 18.6206L13.9561 18.6201Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_8611_323">
<rect width="23" height="23" fill="black"/>
</clipPath>
</defs>
</svg>

  )
}

export default StarsSvg