"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMobile = exports.makeAudioProcessingOptions = exports.makeSimulcastOption = exports.makeMixingOptions = exports.makeUserOptions = exports.makeConferenceOptions = void 0;
const Bowser = __importStar(require("bowser"));
/**
 * @ignore
 */
const browser = Bowser.getParser(window.navigator.userAgent);
/**
 * @ignore
 * @param data
 */
function makeConferenceOptions(data) {
    data = data || {};
    data.params = data.params || {};
    return {
        alias: data.alias || '',
        type: data.type || 'sdk_conf',
        preferredRegions: data.preferredRegions || [],
        pinCode: data.pinCode,
        params: {
            ttl: data.params.ttl || 0,
            multiSession: data.params.multiSession || false,
            mode: data.params.mode || 'standard',
            rtcpMode: data.params.rtcpMode || 'average',
            stats: data.params.stats ? true : false,
            currentLanguage: data.params.currentLanguage || 'english',
            videoCodec: data.params.videoCodec || 'H264',
            liveRecording: data.params.liveRecording || 'false',
            dolbyVoice: data.params.dolbyVoice || 'false',
        },
    };
}
exports.makeConferenceOptions = makeConferenceOptions;
/**
 * @ignore
 * @param data
 * type: data.type || "user", // User type, none / user / listener / mixer,
 */
function makeUserOptions(data) {
    data = data || {};
    data.params = data.params || {};
    return {
        name: data.name || null,
        externalId: data.externalId || null,
        avatarUrl: data.avatarUrl || null,
        ownerId: data.ownerId || null,
        params: Object.assign({}, data.params),
    };
}
exports.makeUserOptions = makeUserOptions;
/**
 * @ignore
 * @param conferenceId
 * @param data
 */
function makeMixingOptions(conferenceId, data) {
    data = data || {};
    return {
        enabled: data.enabled || false,
        uri: data.uri || conferenceId,
    };
}
exports.makeMixingOptions = makeMixingOptions;
/**
 * @ignore
 * @param activated
 */
function makeSimulcastOption(activated) {
    let res = activated || false;
    const platform = browser.getPlatform();
    if (res && ((platform && platform.type === 'mobile') || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || !browser.is('chrome'))) {
        // Forcing mobile browser to disable simulcast
        res = false;
    }
    return res;
}
exports.makeSimulcastOption = makeSimulcastOption;
/**
 * @ignore
 * @param activated
 */
function makeAudioProcessingOptions(data) {
    data = data || {};
    return {
        send: {
            audioProcessing: data.send.audioProcessing ? true : false,
        },
    };
}
exports.makeAudioProcessingOptions = makeAudioProcessingOptions;
/**
 * @ignore
 */
function isMobile() {
    const platform = browser.getPlatform();
    return (platform && platform.type === 'mobile') || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}
exports.isMobile = isMobile;
