import React, {useState} from 'react';

import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';
import Slider from 'react-slick';
import {rewardStar, cupcake, RewardImg} from '../../Constants/Images';
import {Button} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';

const PlayCenter = props => {
  const [tabType, setTabType] = useState('playCenter');
  const [threeDots, setThreeDots] = useState(false);
  const [currentComponent, setCurrentComponent] = useState('');

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showComponent, setShowComponent] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Potential',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'On-Time',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Conversion',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];
  const videoSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.4,
    slidesToScroll: 1,
  };

  const videoSliderData = [
    {
      name: 'Completion',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Quiz Score',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Average Duration',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 15px',
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading"
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top  mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="play_center_page primary_panel_wraper primary_panel_content">
                    <div className="messages-header mb-2">
                      <div className="event-tabs tabs-style-2 mb-0">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={
                                tabType === 'playCenter' ? 'active' : ''
                              }
                              onClick={() => {
                                setTabType('playCenter');
                              }}>
                              Play Center
                            </Button>
                          </li>

                          <li>
                            <Button
                              className={tabType === 'quizzes' ? 'active' : ''}
                              onClick={() => {
                                setTabType('quizzes');
                              }}>
                              Quizzes
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={tabType === 'saved' ? 'active' : ''}
                              onClick={() => {
                                setTabType('saved');
                              }}>
                              Saved
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        <span
                          className={
                            currentComponent === 'create-form'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => setCurrentComponent('create-form')}
                          />
                        </span>
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    <div className="refferal-list-wrapper">
                      <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide>
                        <div className="play_center_content">
                          <div className="short_videos mt-3">
                            <Slider {...videoSlider}>
                              {videoSliderData.length > 0 &&
                                videoSliderData.map((item, index) => (
                                  <div>
                                    <div className="video_box">
                                      <img src={cupcake} alt="" />
                                    </div>
                                  </div>
                                ))}
                            </Slider>

                            <div className="view_all_btn mt-3">
                              View All Shorts
                            </div>
                          </div>

                          <div className="play_center_audios mt-4">
                            <div className="audio_card">
                              <div className="audio_title_flex">
                                <span>This is the title to this track</span>
                                <span className="like_icon">
                                  <i className="far fa-heart" />
                                </span>
                              </div>

                              <div className="audio_line_flex mt-2">
                                <span className="audio_music_btn"></span>
                                <span className="play_icon">
                                  <i className="fa-solid fa-play" />
                                </span>

                                <span className="audio_line_bar"></span>

                                <span className="audio_time">14:14</span>
                              </div>
                            </div>

                            <div className="view_all_btn mt-3">
                              View All Audios
                            </div>

                            <div
                              style={{
                                // width: '385px',
                                // height: '360px',
                                borderRadius: '5.015px',
                                border: '1.003px solid #F6F5F5',
                                background: '#FFF',
                                marginTop: '21px',
                              }}>
                              <div>
                                <div
                                  style={{
                                    margin: '18px',
                                    // width: '348px',
                                    height: '182px',
                                    borderRadius: '5.015px',
                                    backgroundImage: `url(${RewardImg})`,
                                    backgroundPosition: '50%',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundColor: '#D9D9D9',
                                  }}></div>
                                <div
                                  style={{
                                    // marginLeft: '22px',
                                    width: '315px',
                                    // marginBottom: '11px',
                                  }}>
                                  {/* <span
                              style={{
                                color: '#000',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                marginBottom: '3px',
                                marginLeft: '26px',
                              }}>
                              20% off ANY Air Duct Cleaning
                            </span>

                            <p
                              style={{
                                color: '#726F6F',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                marginBottom: '3px',
                                marginLeft: '26px',
                                marginTop: '6px',
                              }}>
                              Your home is due for an annual cleaning but don’t
                              worry, we have a special...
                            </p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PlayCenter;
