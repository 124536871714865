import React, {useState} from 'react';
import {
  Button,
  Dropdown,
} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {Pannels} from '../../Components';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  bagIcon,
  heartIcon,
  timeIcon,
  Bandage,
} from '../../Constants/Images';
import {
  ProfileNotes,
  UserProfileImg,
  cameraicon,
} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import CustomSlidingButtons from '../../Components/CustomSlidingButtons';
import BadgeCheck from '../../Svg/BadgeCheck';
import Scrollbars from 'react-custom-scrollbars';
const UserProfile = props => {
  const [tabType, setTabType] = useState('project');
  const [showComponent, setShowComponent] = useState('');
  const [threeDots, setThreeDots] = useState(false);
  const [type, setType] = useState('1');

  const [workSpaceItem, setWorkSpaceItem] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  // const buildersHandle = (t, id) => {
  //   // setBuildersUserId(id);
  //   // setShowComponent(t);
  //   // setPlusButton(true);
  //   // setMapButton(false);
  // };

  const listForDropDown = [
    {title: 'All', id: '2'},
    {title: 'Basement', id: '3'},
    {title: 'Master Bedroom', id: '4'},
    {title: 'Bath', id: '5'},
  ];

  const [dropdownValue, setdropdownValue] = useState([
    listForDropDown[0].title,
  ]);
  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);
  const dropDownSelection = e => {
    
    const {id, innerText} = e.target;

    setAreas_Filter(id);
    setdropdownValue([innerText]);
  };

  const dropdownMenu = [
    {id: '1', name: 'Residential'},
    {
      id: '2',
      name: 'Commercial',
    },
    {
      id: '3',
      name: 'Transportation',
    },
    {
      id: '4',
      name: 'Standalone Equipment',
    },
  ];
  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          {/* {showLoader && <LoaderSpinner />} */}
          <div className="sessions-list-wrapper">
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="profile-page referal_page_content">
                    <div className="profile-top-card">
                      <div className="profile_img_wrapper">
                        <div className="profile_img">
                          <img src={UserProfileImg} alt="" />

                          <div className="badge-check">
                            {/* <img src={<BadgeCheckC fill={'#43C8DA'}/>} alt="" /> */}
                            <BadgeCheck fill={'#43C8DA'} />
                          </div>
                        </div>

                        <div className="profile_detail">
                          <div className="profile_name">
                            <div>
                              <h1>Kathryn Chapman</h1>
                            </div>

                            <img src={ProfileNotes} alt="" />
                          </div>

                          <div className="service-type-title">Service Type</div>
                          <div className="location">
                            <div className="location_name">Washington, DC</div>

                            <div className="profile_icons">
                              <div className="profile-phone-message-icon">
                                <i class="fa-solid fa-phone"></i>
                              </div>

                              <div className="profile-phone-message-icon">
                                <i className="fa-solid icon-plane"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="notes-card mt-3">
                        <div
                          className="notes-card-top"
                          onClick={() => {
                            setShowNotes(!showNotes);
                          }}>
                          <div className="notes-title">Notes</div>

                          {!showNotes && <div className="notes-count">2</div>}
                        </div>
                        {showNotes && (
                          <div className="note_detail_main">
                            <div className="text-note">
                              This is a note someone left
                            </div>
                            <div className="notes-detail">
                              <span>11/05/23 5:23 PM</span>

                              <span>Fred Harper</span>
                            </div>
                          </div>
                        )}
                      </div>

                    {threeDots && tabType === 'property' && (
                        <div className="roi-service-icons">
                          <div className="service-icon">
                            <img src={WhitePenIcon} alt="" />
                          </div>

                          <div className="service-icon-time">
                            <img className="" src={timeIcon} alt="" />
                            <span>5D</span>
                          </div>

                          <div className="service-icon w-25">
                            <img src={FireIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={MachineIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={ArrowIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={Bandage} alt="" />
                          </div>

                          <div className="service-icon w-28">
                            <img src={heartIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={bagIcon} alt="" />
                          </div>
                          <div className="w-29">
                            <i
                              className="fa-solid fa-ellipsis"
                              style={{
                                color: '#0a0a0a',
                                paddingTop: '15px',
                              }}></i>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="search-tabs-wrapper mb-2 mt-3">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="event-tabs tabs-style-2 mb-0">
                          <ul>
                            <li>
                              <Button
                                className={
                                  tabType === 'general' ? 'active' : ''
                                }
                                onClick={() => {
                                  setTabType('general');
                                }}>
                                General
                              </Button>
                            </li>
                            <li>
                              <Button
                                className={
                                  tabType === 'project' ? 'active' : ''
                                }
                                onClick={() => {
                                  setTabType('project');
                                }}>
                                Projects
                              </Button>
                            </li>

                            <li>
                              <Button
                                className={
                                  tabType === 'property' ? 'active' : ''
                                }
                                onClick={() => {
                                  setTabType('property');
                                }}>
                                Property
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <div className="search-tabs-icons">
                          <span
                            className={
                              // secondComponent === 'group-component'
                              //   ? 'icon active'
                              //   :
                              'icon'
                            }>
                            <i
                              className="icon-plus"
                              // onClick={() =>
                              //   setSecondComponent('group-component')
                              // }
                            />
                          </span>

                          <span
                            onClick={() => {
                              setThreeDots(!threeDots);
                            }}
                            className={
                              threeDots
                                ? 'icon active threeDots'
                                : 'icon threeDots'
                            }>
                            <i className="fas fa-ellipsis-vertical" />
                          </span>
                        </div>
                      </div>
                    </div>

                    {threeDots && tabType === 'property' && (
                      <>
                        <div className="dropdown-search-icon d-flex  mb-2">
                          <div className="select-wrapper">
                            <Dropdown
                              onSelect={(eventKey, event) => {
                                setType(eventKey);
                              }}
                              drop="down">
                              <Dropdown.Toggle variant="primary">
                                {type
                                  ? dropdownMenu.find(item => item.id === type)
                                      .name
                                  : 'Select Role'}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {dropdownMenu.map((item, index) => (
                                  <Dropdown.Item
                                    key={`st-${index}`}
                                    eventKey={item.id.toString()}
                                    active={item.id === type}>
                                    {item.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="filter-icon">
                            <Button>
                              <i
                                className="icon-filter-icon"
                                // onClick={() => setSearchFilterModal(true)}
                              />
                            </Button>
                          </div>
                        </div>

                        <div className="category-btns-wrapper category-btns-wrapper-slider mt-3">
                          <CustomSlidingButtons
                            items={listForDropDown}
                            activeButtons={areas_Filter}
                            onItemClick={e => {
                              dropDownSelection(e);
                            }}
                            showItems={2}
                            dropdownValue={dropdownValue}
                          />
                        </div>
                      </>
                    )}

                    <div className="refferal-list-wrapper mt-3">
                      <Scrollbars autoHide>
                        {tabType === 'project' && (
                          <div className="profile-project-tab-screen">
                            <div className="create-project-card">
                              <div className="">Kickoff a new project</div>

                              <Button
                                className="new-btn btn-green profile-button-padding "
                                onClick={() => {}}>
                                Create Project
                              </Button>
                            </div>
                          </div>
                        )}

                        {tabType === 'property' && (
                          <div className="profile-property-tab-screen">
                            <div className="property-item-card">
                              <div className="property-card-content-main">
                                <div className="property-card-content">
                                  <div className="property-item-img"></div>

                                  <div className="property-item-content">
                                    <div className="p-item-title">
                                      Hot Water Heater
                                    </div>

                                    <div>services</div>
                                  </div>
                                </div>

                                <div className="roi-service-icons">
                                  <div className="service-icon w-29">
                                    <img src={ArrowIcon} alt="" />
                                  </div>
                                  <div className="service-icon-time">
                                    <img className="" src={timeIcon} alt="" />
                                    <span>3 Y</span>
                                  </div>
                                </div>
                              </div>

                              <div className="property-item-card-bottom">
                                <span>Estimate</span>

                                <div className="property-card-icons">
                                  <div className="p-bottom-icon">
                                    <i
                                      class="fa-solid fa-clock"
                                      style={{color: '#262626'}}></i>
                                  </div>
                                  <div className="p-bottom-icon p-camera-icon">
                                    <img src={cameraicon} alt="" />
                                  </div>
                                  <div className="p-bottom-icon">
                                    <img src={ProfileNotes} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="add-property-card">
                            <div className="">
                              Add homes and other equipment that will be
                              serviced
                            </div>

                            <Button
                              className="new-btn btn-green profile-button-padding "
                              onClick={() => {}}>
                              Add Property
                            </Button>
                          </div> */}
                          </div>
                        )}
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
