import React from 'react';
import {getTimeFormatForChat} from '../../Services/AppServices';

const SmartChatReplyBox = ({reply, user}) => {
  return (
    <div className={`quoted-msg ${reply.isPrivate ? 'private-msg' : ''}`}>
      <div className="msg-container">
        <div className="msg-content-text">
          <p>{reply.type !== 'poll' && reply.message}</p>

          <div className="snapshot-imgs-wrapper">
            {reply.type === 'snapshot' && (
              <div className="d-flex align-items-center chatSnapshots">
                {Object.entries(reply.picture_url).length &&
                  reply.picture_url.slice(0, 4).map((item, index) => {
                    return (
                      <div className="snapshot-imgs-item" key={`pic-${index}`}>
                        <img src={item.images[0]} alt="" />
                      </div>
                    );
                  })}
              </div>
            )}

            {reply.type === 'image' && (
              <div className="snapshot-imgs-item">
                <img src={reply.picture_url} alt="" />
              </div>
            )}

            {reply.type === 'video' && reply.video_url && (
              <div className="snapshot-imgs-item">
                <video src={reply.video_url} playsInline autoPlay controls />
              </div>
            )}
          </div>
        </div>
        <span className="msg-date">
          {getTimeFormatForChat(reply.send_date)}
        </span>
      </div>
    </div>
  );
};

export default SmartChatReplyBox;
