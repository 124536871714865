import React,{useState} from 'react';
// import {Button} from 'react-bootstrap';
import ShowMore from '../Common/ShowMore';
// import {consults} from '../../Constants/Images';

const AppointmentBusinessProfile = ({item,key,PageDesign} )=> {
  const [marketToggle, setMarketToggle] = useState(item.show_in_market_b);

  return (
    <>
      <div className="opportunities-list-wrapper appointment-list-wrapper oppertunities-appointment-profile-wrapper">
       
            <div className="opportunity-item card" key={`shedule-${key}`}>
              <h2>{item.title}</h2>
              <div className="oppo-desc">
                <ShowMore limit={200}>{item.description}</ShowMore>
              </div>

              <div className='d-flex justify-content-between'>
                <span className="address as-link">
                  {item.duration} Minutes
                  <b className='ml-3'>${item.cost === '' ? '0' : item.cost}</b>
                </span>

                <span className="hearticon as-link">
                  {item.session_type === 'other' ? (
                    <>
                      <i className="fa-solid fa-phone"></i>

                      <i className="fa-solid fa-video"></i>
                    </>
                  ) : item.session_type === 'audio' ? (
                    <i className="fa-solid fa-phone"></i>
                  ) : item.session_type === 'video' ? (
                    <i className="fa-solid fa-video"></i>
                  ) : (
                    ''
                  )}
                </span>
              </div>
              {PageDesign('consult',item,marketToggle,setMarketToggle)}
            </div>
          
          {/* <div className="no-results-wrapper">
            <div className="img-bg-wrapper">
              <img src={consults} alt="" />
            </div>

            <div className="no-results-content">
              <h2>Leverage your Experiences to Help Others</h2>
              <p>
                No matter if you want to mentor for free or charge a consulting
                fee. you can start hosting 1-on-1 video meetings based on your
                availability.
              </p>

              <div className="btn-wrapper">
                <Button className="btn btn-dark">
                  Create Appointment Type
                </Button>
              </div>
            </div>
          </div> */}
      
        
      </div>
    </>
  );
};
export default AppointmentBusinessProfile;
