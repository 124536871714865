import React from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import DiscoverBuilders from '../SessionList/DiscoverBuilders';
import {Button, Form} from 'react-bootstrap';

const RideDetail = (props) => {
  return (
    <SlidingPanel
      hidePanel={() => {
        props.onClose();
      }}>
     

      <div className="serach-filter-wrapper create-sessionEvents">
        <>
          {props.type !== 'pick&drop' && (
            <div>
              <DiscoverBuilders />
            </div>
          )}
          <Scrollbars className="custom-scroll">
            <div
            className="map-road-trip-container"
              style={{
                position: 'relative',
                color: '#726F6F',

                fontFamily: 'Inter',
                fontSize: '16.047px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
              }}>
              <Form.Label className="map-road-trip-container">
                <div>
                <span className="span-locator">Start</span>
                <span>
                  <i
                    class="fa-solid fa-location-dot"
                    style={{
                      color: '#52BD41',
                    }}></i>
                </span>
                </div>
                <span
                  className="text"
                  style={{
                    color: '#52BD41',

                    fontFamily: 'Inter',
                    fontSize: '15.044px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                  }}>
                  Round Trip
                </span>
              </Form.Label>
            </div>

            <div className="home_map_listing_holder">
              <div className="search-tabs-wrapper  mt-0">
                <div className="search-form d-flex mt-0">
                  <Form.Group className="search-input-location">
                    <Form.Control
                      style={{
                        boxShadow:
                          ' 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25)',
                        borderRadius: '8.023px',
                      }}
                      onChange={e => {}}
                      type="text"
                      placeholder="Pickup location"
                    />
                  </Form.Group>
                  {/* <Button> */}
                  <div
                    style={{
                      // paddingLeft: '66px',
                      // paddingTop: '10px',
                      borderRadius: '29.067px',
                      border: '1.003px solid #E2E2E2',
                      width: '96.282px',
                      height: '39.114px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '6px',
                      marginTop: '14px',

                      marginLeft: '52px',
                    }}>
                    <i className="fa-solid fa-calendar-days" /> Now
                  </div>

                  {/* </Button> */}
                </div>
              </div>
              <div className="search-tabs-wrapper  mt-0">
                <div className="search-form d-flex mt-0">
                  <Form.Group className="search-input-location">
                    <Form.Label
                      style={{
                        color: '#726F6F',

                        fontFamily: 'Inter',
                        fontSize: '16.047px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}>
                      Destination
                    </Form.Label>
                    <Form.Control
                      style={{
                        boxShadow:
                          ' 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25)',
                        borderRadius: '8.023px',
                      }}
                      onChange={e => {}}
                      type="text"
                      placeholder="Drop-off location"
                    />
                  </Form.Group>
                  {/* <Button> */}

                  {/* </Button> */}
                </div>
              </div>
            </div>
            <div style={{position: 'relative'}} className="mt-">
              <span style={{marginLeft: "0px"}} className="clear-btn">Add Additional Destination</span>
            </div>
            <div className="quick_destination_holder">
              <div className="mt-3">
                <h1>Quick Destinations</h1>
              </div>
              <div className="mt-3">
                <span>
                  <i className="fa-solid fa-house"></i>
                </span>
                <span
                  className="pl-2 quick_icon_txt"
                  style={{
                    color: '#494949',

                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}>
                  Home
                </span>
              </div>
              <div className="mt-3">
                <span>
                  <i className="icon-store"></i>
                </span>
                <span
                  className="pl-2 quick_icon_txt"
                  style={{
                    color: '#494949',

                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}>
                  Work
                </span>
              </div>
              <div className="mt-3">
                <span>
                  <i className="fa-solid fa-location-dot"></i>
                </span>
                <span
                  className="pl-2 quick_icon_txt"
                  style={{
                    color: '#494949',

                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}>
                  Fairmont Austin
                </span>
                <div>
                  <span
                    className="pl-4 quick_icon_txt"
                    style={{
                      color: '#494949',
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: 'normal',
                    }}>
                    101 Red River St, Austin
                  </span>
                </div>
              </div>
            </div>
            <div>
              <Button
                type="button"
                className="new-btn btn-green  btn btn-primary  text-center mt-4">
                Submit Request
              </Button>
            </div>
          </Scrollbars>
        </>
      </div>
    </SlidingPanel>
  );
};

export default RideDetail;
