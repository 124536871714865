import commonApi from "../../Services/CommonService";

export const getDispatch = (type, data) => ({
    type: type,
    payload: data,
});

export const getReferralListing = (data) => {

    return async (dispatch) => {
        const response = await commonApi.get_referrals(data);
        if (data.page > 1) {
            dispatch(getDispatch('REFER/GET_DATA_PAGE', response));
        } else {
            dispatch(getDispatch('REFER/GET_DATA', response));
        }
        return response;
    }
}

export const callReferralApi = () => {
    return {
      type: 'REFER/API',
    };
  };
  