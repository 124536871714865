import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {GoogleMap} from '@react-google-maps/api';
import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';
import {JoeSanchez} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import {SliderFleet} from '../../Constants/AnalyticsConstants';
import Scrollbars from 'react-custom-scrollbars';

const Fleet = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showComponent, setShowComponent] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [tabType, setTabType] = useState('map');
  const [threeDots, setThreeDots] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  const fleetSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  const fleetSliderData = [
    {
      service: 'Catalog Service',
      time: '05/12/24  6AM - 8AM',
      location: 'McLean, VA',
    },

    {
      service: 'Catalog Service',
      time: '05/12/24  6AM - 8AM',
      location: 'McLean, VA',
    },

    {
      service: 'Catalog Service',
      time: '05/12/24  6AM - 8AM',
      location: 'McLean, VA',
    },
  ];

  const dynamicStyle = {
    padding: '0px',
    // Add other styles as needed
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: false,
  };
  const mapContainerStyle = {
    width: '100%',
    height: '79.5vh',
    position: 'absolute',
    top: 0,
  };
  const defaultCenter = {
    lat: 31.5497,
    lng: 74.3436,
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {SliderFleet.length > 0 &&
                      SliderFleet.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <div className="fleet_Screen referal_page_content ">
                  <div className="messages-header mb-2 primary_panel_wraper">
                    <div className="event-tabs tabs-style-2 mb-0">
                      {' '}
                      <ul>
                        <li>
                          <Button
                            className={tabType === 'map' ? 'active' : ''}
                            onClick={() => {
                              setTabType('map');
                            }}>
                            Map
                          </Button>
                        </li>

                        <li>
                          <Button
                            className={tabType === 'list' ? 'active' : ''}
                            onClick={() => {
                              setTabType('list');
                            }}>
                            List
                          </Button>
                        </li>
                      </ul>
                    </div>

                    <div className="search-tabs-icons">
                      <span
                        className={
                          // currentComponent === 'createInvoice'
                          //   ? 'icon active'
                          //   :
                          'icon'
                        }>
                        <i className="icon-plus" onClick={() => {}} />
                      </span>
                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={
                          threeDots ? 'icon active threeDots' : 'icon threeDots'
                        }>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>

                  <div className="refferal-list-wrapper">
                    <Scrollbars autoHide>
                      {tabType === 'map' && (
                        <div style={{position: 'relative'}}>
                          <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={10}
                            center={defaultCenter}
                            options={options}
                            // onLoad={onMapLoad}
                            // onUnmount={() => setMap(null)}
                          ></GoogleMap>

                          <div className="flee_item_main">
                            <Slider {...fleetSlider}>
                              {fleetSliderData.length > 0 &&
                                fleetSliderData.map((item, index) => (
                                  <div className="fleet_schedule_item_main">
                                    <div className="fleet_schedule_item">
                                      <div className="schedule_item_img">
                                        <img src={JoeSanchez} alt="" />
                                      </div>

                                      <div className="schedule_item_content">
                                        <div style={{marginBottom: '4px'}}>
                                          Joe Sanchez
                                        </div>

                                        <span style={{marginBottom: '4px'}}>
                                          Appointment
                                        </span>
                                        <p>Vehicle type</p>

                                        <div className="call_msg_flex">
                                          <div>22 miles away</div>
                                          <div className="fleet_item_icons">
                                            <div className="profile-phone-message-icon">
                                              <i class="fa-solid fa-phone"></i>
                                            </div>

                                            <div className="profile-phone-message-icon">
                                              <i className="fa-solid icon-plane"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </Slider>
                          </div>
                        </div>
                      )}

                      {tabType === 'list' && (
                        <div className="fleet_list_screen mt-2">
                          <div className="fleet_schedule_item mb-3">
                            <div className="schedule_item_img">
                              <img src={JoeSanchez} alt="" />
                            </div>

                            <div className="schedule_item_content">
                              <div style={{marginBottom: '4px'}}>
                                Joe Sanchez
                              </div>

                              <span style={{marginBottom: '4px'}}>
                                Appointment
                              </span>
                              <p>Vehicle type</p>

                              <div className="call_msg_flex">
                                <div>22 miles away</div>
                                <div className="fleet_item_icons">
                                  <div className="profile-phone-message-icon">
                                    <i class="fa-solid fa-phone"></i>
                                  </div>

                                  <div className="profile-phone-message-icon">
                                    <i className="fa-solid icon-plane"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Scrollbars>
                  </div>
                </div>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Fleet;
