"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The ConferenceParameters model allows the application to:
 *
 * - Turn the [live recording](#liverecording) on and off
 * - Set the [transmission bitrate adaptation mode](#rtcpmode)
 * - Customize the [wait time](#ttl) before terminating an empty conference
 * - Set the desired [codec](#videocodec) for video conferences
 */
class ConferenceParameters {
    constructor() { }
    /**
     * @ignore
     * @param data
     */
    static fromJoinData(data) {
        const parameters = new ConferenceParameters();
        parameters.currentLanguage = data.currentLanguage;
        parameters.dolbyVoice = data.dolbyVoice === 'true';
        parameters.liveRecording = data.liveRecording;
        parameters.mode = data.mode;
        parameters.multiSession = data.multiSession;
        parameters.rtcpMode = data.rtcpMode;
        parameters.stats = data.stats;
        parameters.ttl = data.ttl;
        parameters.videoCodec = data.videoCodec;
        return parameters;
    }
}
exports.default = ConferenceParameters;
