import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import {generalArray} from '../../Constants/PushNotification';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import CustomDropdown from '../CustomDropdown';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';

const CircleNotifications = props => {
  const [notificationButtuns, setnotificationButtuns] = useState('general');
  const [circle, setCircle] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [apiResponse, setApiResponse] = useState({});
  const [notiArray, setNotiArray] = useState(generalArray);
  const [sessionNoti, setSessionNoti] = useState(generalArray.slice(7, 14));
  const [eventNoti, setEventNoti] = useState(generalArray.slice(14, 22));
  const [oppNoti, setOppNoti] = useState(generalArray.slice(22, 28));

  const [circleId, setCircleId] = useState('');

  const generalNotifications = id => {
    // const {id, checked} = e.target;
    // if (!checked) {
    //   setApiResponse({
    //     ...apiResponse,
    //     [id]: false,
    //   });
    // } else {
    //   setApiResponse({
    //     ...apiResponse,
    //     [id]: true,
    //   });
    // }

    let tempRes = {...apiResponse};

    if (tempRes[id] === true || tempRes[id] === 'true') {
      tempRes[id] = false;
    } else {
      tempRes[id] = true;
    }

    setApiResponse(tempRes);
  };

  const onsubmit = () => {
    manageNotification();
  };
  const manageNotification = () => {
    setshowLoader(true);
    var notificationsPayload = {
      circle_id: circleId,
      e_Change: apiResponse.e_Change,
      e_FavouriteTopicStart: apiResponse.e_FavouriteTopicStart,
      e_FollowingRSVP: apiResponse.e_FollowingRSVP,
      e_MomentAchievement: apiResponse.e_MomentAchievement,
      e_MomentAdd: apiResponse.e_MomentAdd,
      e_Referral: apiResponse.e_Referral,
      e_Reminder: apiResponse.e_Reminder,
      e_UserAdded: apiResponse.e_UserAdded,
      e_UserAddedRSVP: apiResponse.e_UserAddedRSVP,

      gen_AddCaution: apiResponse.gen_AddCaution,
      gen_AddPriase: apiResponse.gen_AddPriase,
      gen_CallReminder: apiResponse.gen_CallReminder,
      gen_FollowingAchivement: apiResponse.gen_FollowingAchivement,
      gen_PeopleFollowTravel: apiResponse.gen_PeopleFollowTravel,
      gen_ScheduleCall: apiResponse.gen_ScheduleCall,
      gen_SendMeRefferal: apiResponse.gen_SendMeRefferal,

      o_BestMatch: apiResponse.o_BestMatch,
      o_Call: apiResponse.o_Call,
      o_Decision: apiResponse.o_Decision,
      o_Message: apiResponse.o_Message,
      o_Referral: apiResponse.o_Referral,
      o_Save: apiResponse.o_Save,
      o_Session: apiResponse.o_Session,

      s_Change: apiResponse.s_Change,
      s_FavouriteTopicStart: apiResponse.s_FavouriteTopicStart,
      s_FollowingStartSession: apiResponse.s_FollowingStartSession,
      s_Invite: apiResponse.s_Invite,
      s_Link: apiResponse.s_Link,
      s_RSVP: apiResponse.s_RSVP,
      s_Referral: apiResponse.s_Referral,
      s_Reminder: apiResponse.s_Reminder,
    };
    commonApi
      .manage_circle_push_notification(notificationsPayload)
      .then(res => {
        if (res.status === 200) {
          successMessage('Settins update successfully');
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getCircles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (circleId && circle.length > 0) {
      getCirclePushNotification(circleId);
    }
  }, [circleId]);

  const getCirclePushNotification = id => {
    setshowLoader(true);
    var payload = {
      circle_id: !id ? circleId : id,
    };
    commonApi
      .get_circle_push_notification(payload)
      .then(res => {
        if (res.status === 200) {
          setApiResponse(res.data);
          setshowLoader(false);
          setShowSpinner(false)
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const removeCirclePushNotification = () => {
    setshowLoader(true);
    var payload = {
      circle_id: circleId,
    };
    commonApi
      .remove_circle_push_notification(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage('Circle settings reset successfully');
          setApiResponse('');
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getCircles = () => {
    setshowLoader(true);

    commonApi
      .get_circles()
      .then(res => {
        if (res.status === 200) {
          // setCircle(res.data);

          setCircle(
            res.data.length > 0
              ? res.data.map(circle => ({
                  id: circle.circle_id,
                  circle_name: circle.circle_name,
                }))
              : [],
          );
          setCircleId(res.data.length > 0 ? res.data[0].circle_id : '');

          if(res.data.length ===0){
            setShowSpinner(false)
          }
          // getCirclePushNotification(res.data[0].circle_id)
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const resetSearch = () => {
    setNotiArray(generalArray);
    setSessionNoti(generalArray.slice(7, 14));
    setEventNoti(generalArray.slice(14, 22));
    setOppNoti(generalArray.slice(22, 28));
  };

  const handleGeneralSearch = (array, query) => {
    setNotiArray(
      array.slice(0, 7).filter(el => {
        return (
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handlesessionsSearch = (array, query) => {
    setSessionNoti(
      array.filter(el => {
        return (
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleEventSearch = (array, query) => {
    setEventNoti(
      array.filter(el => {
        return (
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleOppSearch = (array, query) => {
    setOppNoti(
      array.filter(el => {
        return (
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  return (
    <>
      {showSpinner  && <LoaderSpinner />}
      <SlidingPanel hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper">
          <div className="sidebar-heading-wrapper fix-width-forms mt-0 mb-3">
            <div className="headings mb-0">
              <h2 className="mb-0">
                <i className="icon-circles" /> Circle Notifications
              </h2>
            </div>
            <div className="search-tabs-icons">
              <span
                className="icon active"
                onClick={removeCirclePushNotification}>
                <i className="fas fa-rotate-right" />
              </span>
            </div>
          </div>

          {/* <div className="fix-width-forms"> */}
          {/* <div className="prefs-content-wrapper"> */}

          <div className="fix-width-forms">
            <div className="prefs-inner-content-wrapper mt-3 ">
              <h2>
                These settings will override your existing global settings
              </h2>
            </div>

            {Object.entries(circle).length > 0 && (
              <CustomDropdown
                setDropdownId={setCircleId}
                dropdownId={circleId}
                DropDownValues={circle}
                itemName={['circle_name']}
                className={'dropdown_font mb-3'}
              />
            )}
            <div className="search-form">
              {/* {circle.length > 0 && (
                    <div className="fieldset">
                      <div className="select-wrapper">
                        <select
                          className="form-control"
                          disabled={circle.length > 0 ? false : true}
                          onChange={e => {
                            setCircleId(e.target.value);
                            
                            getCirclePushNotification(e.target.value);
                          }}>

                          {Object.entries(circle).length > 0 &&
                            circle.map((item, index) => (
                              <option
                                id={item.circle_id}
                                value={item.circle_id}
                                key={`appointmentType-${index}`}>
                                {item.circle_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )} */}

              {/* <p>Who would you like to refer event / session / opportunity to?</p> */}

              {notificationButtuns === 'general' && (
                <Form.Group className="search-input">
                  <Form.Control
                    onChange={e => {
                      handleGeneralSearch(generalArray, e.target.value);
                    }}
                    type="text"
                    placeholder="Search for notification"
                  />
                </Form.Group>
              )}
              {notificationButtuns === 'sessions' && (
                <Form.Group className="search-input">
                  <Form.Control
                    onChange={e => {
                      handlesessionsSearch(
                        generalArray.slice(7, 14),
                        e.target.value,
                      );
                    }}
                    type="text"
                    placeholder="Search for notification"
                  />
                </Form.Group>
              )}
              {notificationButtuns === 'events' && (
                <Form.Group className="search-input">
                  <Form.Control
                    onChange={e => {
                      handleEventSearch(
                        generalArray.slice(14, 22),
                        e.target.value,
                      );
                    }}
                    type="text"
                    placeholder="Search for notification"
                  />
                </Form.Group>
              )}
              {notificationButtuns === 'oppor' && (
                <Form.Group className="search-input">
                  <Form.Control
                    onChange={e => {
                      handleOppSearch(
                        generalArray.slice(22, 28),
                        e.target.value,
                      );
                    }}
                    type="text"
                    placeholder="Search for notification"
                  />
                </Form.Group>
              )}
            </div>

            <div className="event-tabs tabs-style-2 mt-4 mb-4">
              <ul>
                <li>
                  <Button
                    className={
                      notificationButtuns === 'general' ? 'active' : ''
                    }
                    onClick={() => {
                      setnotificationButtuns('general');
                      resetSearch();
                    }}>
                    {labels.sessions_type_general[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={
                      notificationButtuns === 'sessions' ? 'active' : ''
                    }
                    onClick={() => {
                      setnotificationButtuns('sessions');
                      resetSearch();
                    }}>
                    {labels.meetup_virtual[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={notificationButtuns === 'events' ? 'active' : ''}
                    onClick={() => {
                      setnotificationButtuns('events');
                      resetSearch();
                    }}>
                    {labels.in_person[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={notificationButtuns === 'oppor' ? 'active' : ''}
                    onClick={() => {
                      setnotificationButtuns('oppor');
                      resetSearch();
                    }}>
                    {labels.Recruitment[selected_lang]}
                  </Button>
                </li>
              </ul>
            </div>
          </div>

          <div className="refferal-list-wrapper">
            <div className="circle_loader">
              {showLoader && <LoaderSpinner />}
            </div>

            <Scrollbars>
              <div className="fix-width-forms pb-5">
                {notificationButtuns === 'general' && (
                  <>
                    <div className="prefs-inner-content-wrapper">
                      <h2>Which topics or activities interest you the most?</h2>
                    </div>

                    <div className="checkbox-list">
                      <ul>
                        <li>Notify me when...</li>

                        {Object.entries(notiArray).length > 0 ? (
                          Object.entries(notiArray).length > 0 &&
                          notiArray.slice(0, 7).map((item, index) => (
                            <li
                              key={'abcd' + item.id}
                              className={
                                apiResponse[item.name] === true ||
                                apiResponse[item.name] === 'true'
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => generalNotifications(item.name)}>
                              {item.description}

                              {(apiResponse[item.name] === true ||
                                apiResponse[item.name] === 'true') && (
                                <span class="check-icon">
                                  <i class="fas fa-check"></i>
                                </span>
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No records found</p>
                        )}
                      </ul>
                    </div>
                  </>
                )}

                {notificationButtuns === 'sessions' && (
                  <>
                    <div className="checkbox-list">
                      <ul>
                        <li>Notify me when...</li>

                        {Object.entries(sessionNoti).length > 0 ? (
                          Object.entries(sessionNoti).length > 0 &&
                          sessionNoti.map((item, index) => (
                            <>
                              <li
                                key={'sessionsArray' + item.id}
                                className={
                                  apiResponse[item.name] === true ||
                                  apiResponse[item.name] === 'true'
                                    ? 'active'
                                    : ''
                                }
                                onClick={() => generalNotifications(item.name)}>
                                {item.description}

                                {(apiResponse[item.name] === true ||
                                  apiResponse[item.name] === 'true') && (
                                  <span class="check-icon">
                                    <i class="fas fa-check"></i>
                                  </span>
                                )}
                              </li>

                              {/* <li key={'sessionsArray' + item.id}>
                                <Form.Label>
                                  {item.description}
                                  <input
                                    id={item.name}
                                    type="checkbox"
                                    onChange={generalNotifications}
                                    checked={
                                      apiResponse[item.name] === true ||
                                      apiResponse[item.name] === 'true'
                                        ? true
                                        : false
                                    }
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li> */}
                            </>
                          ))
                        ) : (
                          <p className="text-center">No records found</p>
                        )}
                      </ul>
                    </div>
                  </>
                )}

                {notificationButtuns === 'events' && (
                  <>
                    <div className="checkbox-list">
                      <ul>
                        <li>Notify me when...</li>

                        {Object.entries(eventNoti).length > 0 ? (
                          Object.entries(eventNoti).length > 0 &&
                          eventNoti.map((item, index) => (
                            <li
                              key={'eventArray' + item.id}
                              className={
                                apiResponse[item.name] === true ||
                                apiResponse[item.name] === 'true'
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => generalNotifications(item.name)}>
                              {item.description}

                              {(apiResponse[item.name] === true ||
                                apiResponse[item.name] === 'true') && (
                                <span class="check-icon">
                                  <i class="fas fa-check"></i>
                                </span>
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No records found</p>
                        )}
                      </ul>
                    </div>
                  </>
                )}

                {notificationButtuns === 'oppor' && (
                  <>
                    <div className="checkbox-list">
                      <ul>
                        <li>Notify me when...</li>

                        {Object.entries(oppNoti).length > 0 ? (
                          Object.entries(oppNoti).length > 0 &&
                          oppNoti.map((item, index) => (
                            <li
                              key={'eventArray' + item.id}
                              className={
                                apiResponse[item.name] === true ||
                                apiResponse[item.name] === 'true'
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => generalNotifications(item.name)}>
                              {item.description}

                              {(apiResponse[item.name] === true ||
                                apiResponse[item.name] === 'true') && (
                                <span class="check-icon">
                                  <i class="fas fa-check"></i>
                                </span>
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No records found</p>
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </Scrollbars>
          </div>
          {/* </div> */}
          {/* </div> */}

          <div className="filter-btn-wrapper pb-0">
            <div className="invite-btns p-0 text-center bg_transparent fix-width-forms bottom-0">
              <Button className="btn btn-dark" onClick={onsubmit}>
                Save Settings
              </Button>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </>
  );
};
export default CircleNotifications;
