import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import EditAppointment from '../../Components/AppointmentPage/EditAppointment';
import ShowMore from '../../Components/Common/ShowMore';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import {consults} from '../../Constants/Images';
import {SlidingPanel} from '../../Components';
// import {CustomTabs} from '../../Components';
import NewCustomTabs from '../../Components/NewCustomTabs';
import AppointmentTabs from '../../Components/AppointmentPage/AppointmentTabs';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import EyeSvg from '../../Svg/EyeSvg';
import {successMessage} from '../../Utils/ToastMessages';
import { RoiLogo } from '../../Constants/Images';
const Appointments = props => {
  const [shedule, setShedule] = useState('');
  // const [showLoader, setshowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [createrId, setCreaterId] = useState('');
  const [scheduleId, setScheduleId] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [page, setPage] = useState(1);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [onBoardingModalData, setonBoardingModalData] = useState([]);
  const [tabType, setTabType] = useState('mine');
  const [subTabType, setSubTabType] = useState('Market');
  const [selectedType, setSelectedType] = useState('mine');
  const [subType, setSubType] = useState('show_on_the_market');
  

  const ticketTabs = [
    labels.business_type_market[selected_lang],
    labels.business_type_internal[selected_lang],
  ];

  useEffect(() => {
    // setshowLoader(true);
  
    
    getMySchedules(1);
  setTimeout(() => {
    setShowSpinner(false);
  }, 1500);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, subType]);

  const getMySchedules = pageN => {
    commonApi.get_on_boarding_details().then(res => {
      if (res) {
        setonBoardingModalData(res);
      }
    });
    var Payload = {
      type: selectedType,
      sub_type: subType,
      page: pageN,
    };
    commonApi
      .get_my_schedules(Payload)
      .then(res => {
        if (res.success === true) {
          if (pageN > 1) {
            if (res.data > 0) {
              setPage(pageN);
              setShedule([...shedule, ...res.data]);
            }
          } else {
            setPage(1);
            setShedule(res.data);
          }

          // setshowLoader(false);
        }
       
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const handleScroll = values => {
  //   const {clientHeight, scrollHeight, scrollTop} = values.target;
  //   // // Check if the user has reached the end of the content
  //   if (clientHeight + scrollTop >= scrollHeight) {
  //     // Increment the page number and call the API

  //     getMySchedules(page + 1);
  //   }
  // };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const updateMarketToggleValue = (MiniItem, value) => {
    const index = shedule.findIndex(item => item.id === MiniItem.id);

    if (index !== -1) {
      shedule[index].show_in_market_b = value;
      return shedule[index];
    }
  };

  const updatePageView = item => {
    setShedule(prevShedule => {
      const index = prevShedule.findIndex(MiniItem => MiniItem.id === item.id);
      if (index !== -1) {
        const updatedShedule = [...prevShedule];
        updatedShedule[index].show_on_page =
          item.show_on_page === 'hide' ? 'show' : 'hide';
        return updatedShedule;
      }
      return prevShedule;
    });
  };

  const manageMarket = (itemId, itemType, value) => {
    var payLoad = {
      id: itemId,
      type: itemType,
      show_in_market: value,
    };
    commonApi
      .manage_market(payLoad)
      .then(response => {
        if (response.status === 200) {
          successMessage(response.message);
          setShedule(previous =>
            previous.filter(miniitem => miniitem.id !== itemId),
          );
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };

  const manageSchedule = id => {
    var payLoad = {
      schedule_id: id,
    };
    commonApi
      .manage_schedules(payLoad)
      .then(response => {
        if (response.success === true) {
          successMessage(response.message);
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };


  const ToggleDesign = ({item}) => {
    const [marketToggle, setMarketToggle] = useState(item.show_in_market_b);
    return (
      <div className="mt-2 p20-horizental show-on-public">
        <div className="d-flex align-items-center justify-content-center">
          <p className="p-reg m-0">Show on Market</p>
          <div>
            <Form.Check
              type="switch"
              className="switchBlock"
              id="custom-switch"
              value={marketToggle}
              checked={marketToggle}
              onClick={() => {
                setMarketToggle(!marketToggle);
                manageMarket(item.id, 'consult', !marketToggle);
                updateMarketToggleValue(item, !marketToggle);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  // useEffect(() => {

    

  //   if(initialRender.current){
  //     initialRender.current = false
      
  //   }else{
  //     lastRender.current = false
  //   }


  //   if (!initialRender.current &&  !lastRender.current) {
  //     if (subTabType === 'Market') {
  //       setSubType('show_on_the_market');
  //     } else {
  //       setSubType('internal');
  //     }
  //   }
  // }, [subTabType]);

  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     isInitialRender.current = false;
  //     lastSubTabType.current = subTabType;
  //     return;
  //   }

  //   // Check if the tab has actually changed
  //   if (subTabType !== lastSubTabType.current) {
  //     lastSubTabType.current = subTabType;

  //     // Set subType based on the new subTabType
  //     if (subTabType === 'Market') {
      
  //       setSubType('show_on_the_market');
  //     } else {
  //       setSubType('internal');
  //     }
  //   }
  // }, [subTabType]);


  const renderHeader = () => {
   
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      {/* <div className="page-wrapper">
        <Sidebar location={props.location} /> */}
      {/* {showComponent === '' && ( */}
      <div className="page-wrapper">
        <SlidingPanel hidePanel={() => props.onClose()}
           renderData={renderHeader}
           showBlackHeader={true}
           headerPadding={'24px 20px'}
           lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
           firstDivStyle={{
             padding: 0,
             background:
               'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
           }}
          >
          <div className="sessions-content-wrapper appointment mt-4">
            <div className="d-flex justify-content-between align-items-center mb-2 black-head-form-pad-left">
              <div className="event-tabs tabs-style-2 mb-2">
                <ul>
                  <li>
                    <Button
                      className={tabType === 'mine' ? 'active' : ''}
                      onClick={() => {
                        setShowSpinner(true);
                        setTabType('mine');
                        setSelectedType('mine');
                        setSubTabType('Market')
                        
                        setSubType('show_on_the_market')
                      }}>
                      Mine
                    </Button>
                  </li>

                  <li>
                    <Button
                      className={tabType === 'team' ? 'active' : ''}
                      onClick={() => {
                        setShowSpinner(true);
                        setTabType('team');

                        setSelectedType('team');
                        setSubType('');
                      }}>
                      Team
                    </Button>
                  </li>

                  <li className='appointment_add_icon'>
                  <span
                  className={'icon'}
                  onClick={() => setShowComponent('AddAppointment')}>
                  <i className="icon-plus" />
                </span>
                  </li>
                </ul>
              </div>
              <div className="search-tabs-icons">
                
              </div>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center mb-2">
                <div />
                <div className="search-tabs-icons d-block w-100">
                  <span
                    className={'icon'}
                    onClick={() => setShowComponent('AddAppointment')}>
                    <i className="icon-plus" />
                  </span>
                </div>
              </div> */}

            {tabType === 'mine' && (
              <div className="d-flex align-items-center justify-content-center w-100 fixed-width-inputs black-head-form-pad-left">
                <div className="create-ticket-tabs w-100">
                  <AppointmentTabs
                    darkmode
                    tabs={ticketTabs}
                    active={subTabType}
                    onSelect={item => {
                      setSubTabType(item);


                     
                      if (item === 'Market') {
                        setSubType('show_on_the_market');
                      } else {
                        setSubType('internal');
                      }
                      

                     
                    }}
                  />
                </div>
              </div>
            )}

            <Scrollbars>
              <>
                {showSpinner && <LoaderSpinner />}
                {!showSpinner && (
                  <div className="opportunities-list-wrapper appointment-list-wrapper oppertunities-appointment-profile-wrapper fixed-width-inputs black-head-form-pad-left pb-3">
                    {Object.entries(shedule).length > 0 ? (
                      shedule.map((item, index) => (
                        <div
                          className="opportunity-item card"
                          key={`shedule-${index}`}
                          onClick={() => {
                            // setShowComponent('EditAppointment');
                            // setCreaterId(item.created_by);
                            // setScheduleId(item.id);
                          }}>
                          <div className="d-flex justify-content-between">
                            <h2
                              onClick={() => {
                                setShowComponent('EditAppointment');
                                setCreaterId(item.created_by);
                                setScheduleId(item.id);
                              }}>
                              {item.title}
                            </h2>

                            <span
                              className="as-link"
                              onClick={() => {
                                manageSchedule(item.id);
                                updatePageView(item);
                              }}>
                              <EyeSvg
                                fill={
                                  item.show_on_page === 'hide'
                                    ? '#262626'
                                    : '#52BD41'
                                }
                              />
                            </span>
                          </div>

                          <div className="oppo-desc">
                            {/* <p> */}
                            <ShowMore limit={200}>{item.description}</ShowMore>
                            {/* </p> */}
                          </div>

                          <div
                            className="oppo-address"
                            // onClick={() => {
                            //   setShowComponent('EditAppointment');
                            //   setCreaterId(item.created_by);
                            //   setScheduleId(item.id);
                            // }}
                          >
                            <span className="address as-link">
                              {item.duration} Minutes
                              <b>{item.cost !== '' ? '$' + item.cost : 'Free'}</b>
                            </span>
                            {/* <span className="injeaction-text as-link">                                    
                                    <b>${item.cost === '' ? '0' : item.cost}</b>
                                  </span> */}
                            <span className="hearticon as-link">
                              {item.session_type === 'other' ? (
                                <>
                                  <i className="fa-solid fa-phone"></i>

                                  <i className="fa-solid fa-video"></i>
                                </>
                              ) : item.session_type === 'audio' ? (
                                <i className="fa-solid fa-phone"></i>
                              ) : item.session_type === 'video' ? (
                                <i className="fa-solid fa-video"></i>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>

{tabType !== 'team' && (
  <ToggleDesign key={item.id} item={item} />
)}
                          
                        </div>
                      ))
                    ) : (
                      <div className="no-results-wrapper">
                        <div className="img-bg-wrapper">
                          <img src={consults} alt="" />
                        </div>

                        <div className="no-results-content">
                          <h2>Leverage your Experiences to Help Others</h2>
                          <p>
                            No matter if you want to mentor for free or charge a
                            consulting fee. you can start hosting 1-on-1 video
                            meetings based on your availability.
                          </p>

                          <div className="btn-wrapper mt-3">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                setShowComponent('AddAppointment');
                                // setonBoardingModal(true);
                              }}>
                              Create Appointment Type
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            </Scrollbars>
          </div>
        </SlidingPanel>
      </div>
      {/* )} */}

      {showComponent === 'AddAppointment' && (
        <EditAppointment
          onClose={() => {
            // setShowSpinner(true);
            setShowComponent('');
          }}
          getMySchedules={getMySchedules}
        />
      )}
      {showComponent === 'EditAppointment' && (
        // <SlidingPanel
        //   hidePanel={() => {
        //     setShowComponent('');
        //   }}>
        //   <Scrollbars>
        <EditAppointment
          onClose={() => {
            // setShowSpinner(true);
            setShowComponent('');
          }}
          createrId={createrId}
          scheduleId={scheduleId}
          showHiddenPanel={showHiddenPanel}
          onBoardingModalData={onBoardingModalData}
          page={page}
          getMySchedules={getMySchedules}
        />
        //   </Scrollbars>
        // </SlidingPanel>
      )}
    </>
  );
};
export default Appointments;
