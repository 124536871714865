import React, {useState, useEffect} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';

import Scrollbars from 'react-custom-scrollbars';
import AddPraiseCaution from './AddPraiseCaution';
import ShowMore from '../Common/ShowMore';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import CustomModal from '../CustomModal';
import {fixName} from '../../Utils/commonUtils';
import moment from 'moment/moment';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import CustomDropdown from '../CustomDropdown';

const ReviewListing = props => {
  const [subTabs, setSubTabs] = useState('praise');
  // const [searchQry, setSearchQry] = useState("event");
  const [feedBack, setFeedBack] = useState('');
  const [countCoution, setCountCoution] = useState('');

  const [allImages, setAllImages] = useState([]);
  const [countPraise, setCountPraise] = useState('');
  const [category, setCategory] = useState(props.type ? props.type : 'event');
  // const [image, setImage] = useState('');
  const [imageButton, setImageButton] = useState(false);
  const [plusButton, setPlusButton] = useState(null);
  const [showLoader, setshowLoader] = useState(false);
  const [responseAvrg, setResponseAvrg] = useState('');
  const [error, setError] = useState('');
  // const [response, setResponse] = useState('');
  // const [imageData, setImageData] = useState('');
  const [show, setShow] = useState(false);
  const [pathVideo, setPathVideo] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [modalVideo, setModalVideo] = useState('');
  const [plusiconHide, setPlusiconHide] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [profileId, setProfileId] = useState('');
  // const [addFeedback, setAddFeedback] = useState({
  //   can_consult_add_feedback: false,
  //   can_even_add_feedback: false,
  //   can_oppor_add_feedback: false,
  //   can_sess_add_feedback: false,
  // });

  const handleCloseVideo = () => setShowVideo(false);
  // const handleShowVideo = () => setShowVideo(true);

  const handleCloseAudio = () => setShowAudio(false);
  // const handleShowAudio = () => setShowAudio(true);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const [showMore, setShowMore] = useState(false);
  // const showMoreClass = showMore ? 'show-all' : '';

  const handle = () => {
    plusButton ? setPlusButton(false) : setPlusButton(true);
  };
  // const onFeedbackClick = () => {
  //   if (addFeedback.can_consult_add_feedback) {
  //     setSelectedType('consult');
  //     setSelectedId(addFeedback.can_consult_add_feedback);
  //     setComponentToShow('addFeedback');
  //   } else if (addFeedback.can_even_add_feedback) {
  //     setSelectedType('event');
  //     setSelectedId(addFeedback.can_even_add_feedback);
  //     setComponentToShow('addFeedback');
  //   } else if (addFeedback.can_oppor_add_feedback) {
  //     setSelectedType('opportunity');
  //     setSelectedId(addFeedback.can_oppor_add_feedback);
  //     setComponentToShow('addFeedback');
  //   } else if (addFeedback.can_sess_add_feedback) {
  //     setSelectedType('session');
  //     setSelectedId(addFeedback.can_sess_add_feedback);
  //     setComponentToShow('addFeedback');
  //   }
  // }

  const reviewTypes = [
    {id: 'event', value: 'event', title: 'In-person'},
    {id: 'consult', value: 'consult', title: 'Consult'},
    {id: 'session', value: 'session', title: 'Virtual'},
    {id: 'opportunity', value: 'opportunity', title: 'Recruitment'},
  ];

  const handleProfileComponent = userId => {
    setProfileId(userId);
    setShowComponent('profileComponent');
  };
  const getFeedback = (type, qry) => {
    // alert('inside praise coution',  props.userData.id)
    setshowLoader(true);
    var payloadFeedback = {
      search_query: qry,
      type: type,
      user_id: props.userData.id,
    };
    commonApi
      .get_feedback(payloadFeedback)
      .then(res => {
        if (res.status === 200) {
          if (
            (res.can_consult_add_feedback !== '' ||
              res.can_even_add_feedback !== '' ||
              res.can_oppor_add_feedback !== '' ||
              res.can_sess_add_feedback !== '') &&
            props.userData.id !== localStorage.getItem('id')
          ) {
            // alert('set State true')
            setPlusButton(true);
          } else {
            setPlusButton(false);
          }
          // setAddFeedback(res);
          setFeedBack(res.feedbacks);
          setError(res.feedbacks);
          setPlusiconHide(res.can_add_feedback);
          // setImage(res.feedbacks.reply_detail);
          setCountPraise(res.total_praise);
          // setResponse(res.feedbacks.reply_detail);
          setCountCoution(res.total_caution);
          setResponseAvrg(res.average);

          setTimeout(() => {
            setshowLoader(false);
          }, 2000);

          // setImageData(res.feedbacks.images);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    getFeedback(subTabs, category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTabs, category]);
  useEffect(() => {}, [feedBack]);

  // const decision = () => {
  //   imageButton === true ? setImageButton(false) : setImageButton(true);
  // };

  console.log(props.userData, 'props.userData');

  console.log(plusButton, 'plusButton');
  return (
    <>
      {plusButton === true && (
        <AddPraiseCaution
          subTabs={subTabs}
          setPlusButton={setPlusButton}
          userData={props.userData}
          getFeedback={getFeedback}
          category={category}
          setCategory={setCategory}
          onClose={() => {
            setPlusButton(false);
            props.setShowComponent(oldArr => [{...oldArr, praise: false}]);
          }}
        />
      )}
      {plusButton === false && (
        //   <SlidingPanel
        //     hidePanel={() => {
        //       props.setShowComponent(oldArr => [{...oldArr, praise: false}]);
        //     }}
        //     marginTop={props.marginTop ? props.marginTop : false}>
        // <Scrollbars>
        <div className="fix-width-forms" style={{padding: '0 0 0 4px '}}>
          {/* <div className="sidebar-heading-wrapper">
                  <div className="heading">
                    <h2>
                      {fixName(
                        props.userData.first_name,
                        props.userData.last_name,
                      )}{' '}
                    </h2>
                  </div>
                  <div className="with-user-img">
                    <div
                      className="user-profile-img as-link"
                      onClick={() => {
                        handleProfileComponent(props.userData.id);
                      }}>
                      <img
                        src={
                          props.userData.profile_img === ''
                            ? DEFAULT_IMAGE
                            : props.userData.profile_img
                        }
                        alt=""
                      />
                    </div>
                    <span
                            className="close as-link"
                            onClick={() =>
                              props.setShowComponent(oldArr => [
                                {...oldArr, ['praise']: false},
                              ])
                            }>
                            <i className="fas fa-times" />
                          </span>
                  </div>
                </div> */}

          {/* <div className="search-form" style={{width: '310px'}}>
            <div className="select-wrapper">
              <select
                value={category}
                className="form-control"
                onChange={e => {
                  setCategory(e.target.value);
                }}>
                {category}
                <option value="event">Meetup (In-person)</option>
                <option value="consult">Consult</option>
                <option value="session">Meetup (Virtual)</option>
                <option value="opportunity">Recruitment</option>
              </select>
            </div>
          </div> */}

          <CustomDropdown
            setDropdownId={setCategory}
            dropdownId={category}
            DropDownValues={reviewTypes}
            itemName={['title']}
            // className={'mb-3 recruitment_dropdown'}
          />

          <div className="search-tabs-wrapper mt-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="event-tabs tabs-style-2 mb-0">
                <ul>
                  <li>
                    <Button
                      className={subTabs === 'praise' ? 'active' : ''}
                      onClick={() => {
                        setSubTabs('praise');
                      }}>
                      <i className="icon-like-icon" /> Positive ({countPraise})
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={subTabs === 'caution' ? 'active' : ''}
                      onClick={() => {
                        setSubTabs('caution');
                      }}>
                      <i className="icon-unlike" /> Caution ({countCoution})
                    </Button>
                  </li>
                </ul>
              </div>
              {props.userData.id !== localStorage.getItem('id') &&
                plusiconHide && (
                  <div className="search-tabs-icons">
                    <span
                      className="icon"
                      onClick={() => {
                        handle();
                      }}>
                      <i className="fa fa-plus"></i>
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="loader-container ">
            {showLoader && <LoaderSpinner />}
            <div className="praise-list-wrapper">
              <span className="feedback-count">
                {responseAvrg}% Positive Feedback
              </span>

              {subTabs === 'praise' ? (
                <>
                  {Object.entries(error).length > 0 ? (
                    Object.entries(error).length > 0 &&
                    error?.map((item, index) => (
                      <div key={`feedBacksss-${index}`}>
                        <div
                          className="praise-list-item"
                          // key={`feedBackssssss-${index[0]}`}
                        >
                          {item.feedback_description && (
                            <ShowMore limit={150}>
                              {item.feedback_description}
                            </ShowMore>
                          )}

                          <div className="praise-user-info">
                            <div className="user-img">
                              <img
                                src={
                                  item.creator_details.profile_img === ''
                                    ? DEFAULT_IMAGE
                                    : item.creator_details.profile_img
                                }
                                alt=""
                              />
                            </div>
                            <div className="user-img">
                              <h4>
                                {' '}
                                {fixName(
                                  item.creator_details.first_name,
                                  item.creator_details.last_name,
                                )}
                              </h4>
                              {item.created_at !== '0000-00-00' &&
                                item.created_at && (
                                  <div className="events-headings">
                                    <span className="category-name">
                                      {moment(item.created_at).format(
                                        'MM/DD/YYYY',
                                      )}
                                    </span>
                                  </div>
                                )}
                            </div>
                            {/* <div className="user-icons">
                                      {item.feedback_category === "audio" && (
                                        <span
                                          className="icon icon-mic"
                                          onClick={() => {
                                            setPathVideo(item.file_url);
                                         handleShowAudio();
                                          }}
                                         
                                        />
                                      )}
                                      {item.feedback_category === "video" && (
                                        <span className="icon icon-video-camera"
                                        onClick={() => {
                                          setModalVideo(item.file_url);
                                          handleShowVideo();
                                        }}></span>
                                      )}
                                      {item.images === "" ||
                                      item.images === null ? (
                                        <></>
                                      ) : (
                                        <span
                                          className="icon icon-gallery-img"
                                          onClick={() => {
                                            setAllImages(item.images.split(","));
                                         handleShow();
                                          }}
                                        />
                                      )}
                                    </div> */}
                          </div>
                          {item.reply_detail ? (
                            <>
                              <div className="praise-response">
                                <div className="praise-list-item">
                                  <h3>Response</h3>
                                  <p>
                                    {item.reply_detail.feedback_description}
                                  </p>
                                  <div className="praise-user-info">
                                    <div className="user-img">
                                      <img
                                        src={
                                          item.user_details.profile_img === ''
                                            ? DEFAULT_IMAGE
                                            : item.user_details.profile_img
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-img">
                                      <h4>
                                        {fixName(
                                          item.user_details.first_name,
                                          item.user_details.last_name,
                                        )}
                                      </h4>
                                    </div>
                                    {/* <div className="user-icons">
                                              {item.reply_detail
                                                .feedback_category ===
                                                "audio" && (
                                                <span className="icon icon-mic" 
                                                onClick={() => {
                                                  setPathVideo(item.reply_detail.file_url);
                                               handleShowAudio();
                                                }}/>
                                              )}
                                              {item.reply_detail
                                                .feedback_category ===
                                                "video" && (
                                                <span className="icon icon-video-camera"
                                                onClick={() => {
                                                  setModalVideo(item.reply_detail.file_url);
                                                  handleShowVideo();
                                                }} />
                                              )}
                                              {item.images === "" ||
                                              item.images === null ? (
                                                <></>
                                              ) : (
                                                <span
                                                  className="icon icon-gallery-img"
                                                  onClick={() => {
                                                    setAllImages(
                                                      item.reply_detail.images.split(
                                                        ","
                                                      )
                                                    );
  
                                                 handleShow();
                                                  }}
                                                />
                                              )}
                                            </div> */}
                                  </div>
                                </div>
                                {/* <div className="praise-list-item">
                                <h3>Response</h3>
                                <p>Thank you much!! It was a pleasure!!</p>
                                <div className="praise-user-info">
                                    <div className="user-img">
                                        <img src={eventUser5} alt="" />
                                    </div>
                                    <div className="user-img">
                                        <h4>Ronnie Brown</h4>
                                        <span className="date">1/5/2022</span>
                                    </div>
                                    <div className="user-icons">
                                        <span className="icon icon-mic" />
                                    </div>
                                </div>
                            </div> */}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ))
                  ) : !showLoader ? (
                    <div className="praise-list-item no-border">
                      <p className="text-center">
                        No positive feedback available.
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              {subTabs === 'caution' ? (
                <>
                  {Object.entries(error).length > 0 ? (
                    Object.entries(error).length > 0 &&
                    error?.map((item, index) => (
                      <div key={`feedBackzzz-${index}`}>
                        <div
                          className="praise-list-item"
                          // key={`feedBackssssss-${index[0]}`}
                        >
                          <p>{item.feedback_description}</p>

                          <div className="praise-user-info">
                            <div className="user-img">
                              <img
                                src={
                                  item.creator_details.profile_img === ''
                                    ? DEFAULT_IMAGE
                                    : item.creator_details.profile_img
                                }
                                alt=""
                              />
                            </div>
                            <div className="user-img">
                              <h4>
                                {' '}
                                {fixName(
                                  item.creator_details.first_name,
                                  item.creator_details.last_name,
                                )}
                              </h4>
                              {/* <span className="date">1/5/2022</span> */}
                              {item.created_at !== '0000-00-00' &&
                                item.created_at && (
                                  <div className="events-headings">
                                    <span className="category-name">
                                      {moment(item.created_at).format(
                                        'MM/DD/YYYY',
                                      )}
                                    </span>
                                  </div>
                                )}
                            </div>
                            {/* <div className="user-icons">
                                    {item.feedback_category === 'audio' && (
                                      <span
                                        className="icon icon-mic"
                                        onClick={() => {
                                          setPathVideo(item.file_url);
                                          handleShowAudio();
                                        }}
                                      />
                                    )}
                                    {item.feedback_category === 'video' && (
                                      <span
                                        className="icon icon-video-camera"
                                        onClick={() => {
                                          setModalVideo(item.file_url);
                                          handleShowVideo();
                                        }}
                                      />
                                    )}
                                    {item.images === '' ||
                                    item.images === null ? (
                                      <></>
                                    ) : (
                                      <span
                                        className="icon icon-gallery-img"
                                        onClick={() => {
                                          setAllImages(item.images.split(','));
  
                                          handleShow();
                                        }}
                                      />
                                    )}
                                  </div> */}
                          </div>
                          {item.reply_detail ? (
                            <>
                              <div className="praise-response">
                                <div className="praise-list-item">
                                  <h3>Response</h3>
                                  <p>
                                    {item.reply_detail.feedback_description}
                                  </p>
                                  <div className="praise-user-info">
                                    <div className="user-img">
                                      <img
                                        src={
                                          item.user_details.profile_img === ''
                                            ? DEFAULT_IMAGE
                                            : item.user_details.profile_img
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-img">
                                      <h4>
                                        {fixName(
                                          item.user_details.first_name,
                                          item.user_details.last_name,
                                        )}
                                      </h4>
                                      <span className="date">1/5/2022</span>
                                    </div>
                                    {/* <div className="user-icons">
                                            {item.reply_detail
                                              .feedback_category === 'audio' && (
                                              <span
                                                className="icon icon-mic"
                                                onClick={() => {
                                                  setPathVideo(
                                                    item.reply_detail.file_url,
                                                  );
                                                  handleShowAudio();
                                                }}
                                              />
                                            )}
                                            {item.reply_detail
                                              .feedback_category === 'video' && (
                                              <span
                                                className="icon icon-video-camera"
                                                onClick={() => {
                                                  setModalVideo(
                                                    item.reply_detail.file_url,
                                                  );
                                                  handleShowVideo();
                                                }}
                                              />
                                            )}
                                            {item.images === '' ||
                                            item.images === null ? (
                                              <></>
                                            ) : (
                                              <span
                                                className="icon icon-gallery-img"
                                                onClick={() => {
                                                  setAllImages(
                                                    item.reply_detail.images.split(
                                                      ',',
                                                    ),
                                                  );
                                                  handleShow();
                                                }}
                                              />
                                            )}
                                          </div> */}
                                  </div>
                                </div>
                                {/* <div className="praise-list-item">
                      <h3>Response</h3>
                      <p>Thank you much!! It was a pleasure!!</p>
                      <div className="praise-user-info">
                          <div className="user-img">
                              <img src={eventUser5} alt="" />
                          </div>
                          <div className="user-img">
                              <h4>Ronnie Brown</h4>
                              <span className="date">1/5/2022</span>
                          </div>
                          <div className="user-icons">
                              <span className="icon icon-mic" />
                          </div>
                      </div>
                  </div> */}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="praise-list-item no-border">
                      <p className="text-center">
                        No nagative feedback available.
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {imageButton === true ? (
              <>
                <div className="gallery-imgs">
                  <Row>
                    {Object.entries(allImages).length > 0 &&
                      allImages.map((item, index) => {
                        return (
                          <Col md={4} sm={4} key={`image-${index}`}>
                            <div className="gallery-item">
                              <img src={item} alt="" />
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        // </Scrollbars>
        //   </SlidingPanel>
      )}

      {showComponent === 'profileComponent' && profileId && (
        <ProfileComponent
          userId={profileId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
          setAllImages={setAllImages}
          setModalVideo={setModalVideo}
          setImageButton={setImageButton}
          setPathVideo={setPathVideo}
        />
      )}

      {show && (
        <CustomModal onClose={() => handleClose()}>
          <div className="gallery-imgs">
            <Row>
              {Object.entries(allImages).length > 0 &&
                allImages.map((item, index) => {
                  return (
                    <Col md={4} sm={4} key={`image-${index}`}>
                      <div className="gallery-item">
                        <img src={item} alt="" />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </CustomModal>
      )}

      {showAudio && (
        <CustomModal onClose={() => handleCloseAudio()}>
          <div className="gallery-imgs">
            <Row>
              <audio controls>
                <source src={pathVideo} type="audio/mpeg" />
              </audio>
            </Row>
          </div>
        </CustomModal>
      )}

      {showVideo && (
        <CustomModal onClose={() => handleCloseVideo()}>
          <div className="gallery-imgs">
            <Row>
              <video controls>
                <source src={modalVideo} type="video/mp4" />
              </video>
            </Row>
          </div>
        </CustomModal>
      )}

      {/* <Modal show={showAudio} onHide={handleCloseAudio}>
                <Modal.Header closeButton>
                  <Modal.Title>Audio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <>
                    <div className="gallery-imgs">
                      <Row>
                        <audio controls>
                          <source src={pathVideo} type="audio/mpeg" />
                        </audio>
                      </Row>
                    </div>
                  </>
                </Modal.Body>
              </Modal> */}
    </>
  );
};

export default ReviewListing;
