import React, {useState, useEffect} from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Dropdown,
} from 'react-bootstrap';
import {errorMessage} from '../../../Utils/ToastMessages';
import commonApi from '../../../Services/CommonService';



const Scheduler = props => {
  const [type, setType] = useState('1');
  const [SchedulerTypes, setSchedulerTypes] = useState([]);
  const [customertype, setCustomertype] = useState('');
  const [deleteValue, setDeleteValue] = useState('');


  useEffect(() => {
    getSchedulerTypes();

  }, [props.hitApiData]);

  const getSchedulerTypes = async id => {
      await commonApi
      .get_scheduler_types()
      .then(res => {
        if (res.status === 200) {
          setSchedulerTypes(res.schedulers);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const addSchedulerType = id => {
    const filteredarray = SchedulerTypes.filter(item => item.id === id);
    const item = filteredarray[0];

    if (!props.selectedSchedulerTypeId.includes(item.id)) {
      props.setSelectedSchedulerTypeId(previous => [...previous, item.id]);
      props.setSelectedSchedulerTypeItems(previous => [...previous, item]);
    } else {
      errorMessage('You have already added this Scheduler');
      // props.setSelectedSchedulerTypeId(previous => previous.filter(id => id !== item.id));
      // props.setSelectedSchedulerTypeItems(previous => previous.filter(MiniItem => MiniItem.id !== item.id));
    }
  };

  const removeSchedulerType = item => {
    props.setSelectedSchedulerTypeId(previous =>
      previous.filter(id => id !== item.id),
    );
    props.setSelectedSchedulerTypeItems(previous =>
      previous.filter(MiniItem => MiniItem.id !== item.id),
    );
    handleReset()
   
  };

  // const addCustomerTypes = title => {
  //   if (!state.customers.includes(title)) {
  //     dispatch({type: 'added_todo'});
  //   } else {
  //     errorMessage('You have already added  this title');
  //   }
  // };

  const addCustomerTypes = title => {
    // if (!state.customers.includes(title)) {
    //   dispatch({type: 'added_todo'});
    // } else {
    //   errorMessage('You have already added  this title');
    // }
    if (!props.customerTypesArray.includes(title)) {
      props.setCustomerTypesArray(previous => [...previous, title]);
      setCustomertype('');
      // props.setCustomerTypesArray(previous => previous.filter(item => item !== title))
    } else {
      errorMessage('you have already added this type');
    }
  };

  const removeCustomerType = title => {
    props.setCustomerTypesArray(previous => previous.filter(item => item !== title));
    
    handleReset()
  };

 

  useEffect(() => {

    
    
   
    if(props.deleteItem && typeof deleteValue ==='string'){
      console.log(deleteValue,'-=------->deleteitem')
      removeCustomerType(deleteValue)
    } else if(props.deleteItem && Object.keys(deleteValue).length > 0 ){
      removeSchedulerType(deleteValue);
    } 
  
   

  }, [props.deleteItem])

  const handleReset = () =>{
    props.setShowModel('');
    props.setDeleteItem(false)
    setDeleteValue('');
    props.setDeleteItemName('')
  }

  // useEffect(() => {
  //   saveDataInState();
  // }, [
  //   props.customerName,
  //   props.customerTypesArray,
  //   props.selectedSchedulerTypeId,
  //   props.deliverableTitle,
  //   props.manageDeliverables,
  //   props.reward,
  // ]);

  // const saveDataInState = () => {
  //   const payload = {
  //     customer_title: props.customerName,
  //     customer_types: props.customerTypesArray.toString(','),
  //     scheduler_type_ids: props.selectedSchedulerTypeId.toLocaleString(','),
  //     deliverable_title: props.deliverableTitle,
  //     manage_deliverables: props.manageDeliverables,
  //     // ('status_changes','project_management'),
  //     reward: props.reward,
  //     // ('yes','no)
  //   };
  //   console.log('payload,@@@@@@', payload);
  //   props.setPayloadForApi(payload);
  // };

  

  console.log(typeof deleteValue, 'typetype');
  console.log(deleteValue, '======>deleteItem in c ');
  // console.log(state, 'state');
  // console.log(props.selectedSchedulerTypeItems, 'props.selectedSchedulerTypeItems');
  console.log(props.deleteItem, '======>props.deleteItem');
  return (
    <>
      <div className="company-info-screen template_schheduler">
        <div className="container1">
          <div>
            <label>What do you call customers?</label>
            <div className="slider2-inner-container">
              <div className="title">Customers</div>

              <div className="called-title">
                <span> We use this title</span>

                {/* <Form.Label controlid="validationCustom01">We use this title</Form.Label> */}

                <div className="customer-title-input">
                  <Form.Control
                    type="text"
                    value={props.customerName}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      props.setCustomerName(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Name is required.
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="btn-add-type"></div>
            </div>
          </div>

          <div>
            <label>
              Does your offerings require customers to have different
              experiences? If so, add customer types.
            </label>
            <div className="slider2-inner-container">
              <div className="title">Customers</div>

              <div className="called-title">
                <span> We use this title</span>

                {/* <Form.Label controlid="validationCustom01">Number of employees</Form.Label> */}

                <div className="customer-title-input">
                  <Form.Control
                    type="text"
                    value={customertype}
                    pattern="^\S.*$"
                    // disabled={customerTitle == '' ? true : false}
                    placeholder=""
                    onChange={e => {
                      // props.setTitleValidation(false);

                      setCustomertype(e.target.value);
                      // dispatch({
                      //   type: 'changed_draft',
                      //   nextDraft: e.target.value,
                      // });
                    }}

                    // isInvalid={props.titleValidation}
                  />
                  {props.titleValidation === true && customertype === '' ? (
                    <div className="text-danger textforerror">
                      Type is required.
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* <Form.Control.Feedback type="invalid"> */}

                  {/* </Form.Control.Feedback>
                   */}
                </div>
              </div>
              <div className="btn-add-type">
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    if (customertype !== '') {
                      addCustomerTypes(customertype);
                    }
                  }}>
                  Add Type
                </Button>
              </div>
            </div>
          </div>

          <div className="customer-types mb-3 ml-2">
            <Container>
              <ul>
                {Object.entries(props.customerTypesArray).length > 0 &&
                  props.customerTypesArray.map((item, index) => (
                    <li key={item}>
                      {item}{' '}
                      <span
                        className="as-link"
                        onClick={() => {
                          // removeCustomerType(item);
                          // removeCustomerType(item);
                          props.setDeleteItemName('title')
                          props.setShowModel('deleteItem');
                        
                          setDeleteValue(item)
                        }}>
                        <i
                          class="fa-solid fa-xmark"
                          style={{
                            color: '#d71616',
                            fontWeight: 'bold',
                            fontSize: '15px',
                            marginLeft: '20px',
                          }}></i>
                      </span>{' '}
                    </li>
                  ))}
              </ul>
            </Container>
          </div>

          <div>
            <label>How do customers schedule time for your services?</label>

            <div className="slider2-inner-container">
              <div className="scheduler-dropdown custom-scheduler-dropdown">
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setType(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type && SchedulerTypes?.length > 0
                      ? SchedulerTypes.find(item => item.id === type).title
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {SchedulerTypes?.length > 0 &&
                      SchedulerTypes.map((item, index) => (
                        <Dropdown.Item
                          key={`st-${index}`}
                          eventKey={item.id.toString()}
                          active={item.id === type}>
                          {item.title}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="btn-add-type">
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    addSchedulerType(type);
                  }}>
                  Add Scheduler Type
                </Button>
              </div>
            </div>
          </div>

          {/* <div>
         <label>
           Traditional Scheduler - Only one customer can book for an available
           time slot (dispatch available)
         </label>

         <div className="slider2-inner-container">
           <div className="scheduler-items">
             <span>Traditional Scheduler</span>

             <span
               
               className=" text-green as-link mt-3-bold"
               onClick={() => {
                 
               }}>
              Setup
             </span>

             <span className="input-icon" onClick={() => {}}>
               <i class="fa-solid fa-xmark" style={{color: '#d71616',fontWeight:'bold'}}></i>
             </span>
           </div>
         </div>
       </div> */}

          <div className="slider-3-roles-conatiner">
            <label>
              Traditional Scheduler - Only one customer can book for an
              available time slot (dispatch available)
            </label>

            <div className="mt-3">
              <div className="system-role-list">
                <Container>
                  {Object.entries(props.selectedSchedulerTypeItems).length > 0 &&
                    props.selectedSchedulerTypeItems.map((item, index) => (
                      <Row className="mb-1" key={`s-item-${index}`}>
                        <Col md={6} className="role-row">
                          {' '}
                          {item.title}
                        </Col>

                        <Col md={2} className="role-row">
                          {' '}
                          <span
                            className=" text-green as-link mt-3-bold"
                            onClick={() => {}}>
                            Setup
                          </span>
                        </Col>
                        <Col md={1} className="role-row">
                          {' '}
                          <span
                            className="as-link"
                            onClick={() => {
                              // removeSchedulerType(item);
                              props.setDeleteItemName('type')
                              props.setShowModel('deleteItem');
                              
                              setDeleteValue(item)
                            }}>
                            <i
                              class="fa-solid fa-xmark"
                              style={{
                                color: '#d71616',
                                fontWeight: 'bold',
                                fontSize: '22px',
                              }}></i>
                          </span>{' '}
                        </Col>
                      </Row>
                    ))}
                </Container>
              </div>
            </div>
          </div>

          <div>
            <label>What do you call your deliverables?</label>
            <div className="slider2-inner-container">
              <div className="title">Deliverable</div>

              <div className="called-title">
                <span> We use this title</span>

                {/* <Form.Label controlid="validationCustom01">Number of employees</Form.Label> */}

                <div className="customer-title-input">
                  <Form.Control
                    type="text"
                    value={props.deliverableTitle}
                    pattern="^\S.*$"
                    required
                    placeholder=""
                    onChange={e => {
                      props.setDeliverableTitle(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Title is required.
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="btn-add-type"></div>
            </div>
          </div>

          <div>
            <label>How do you manage your deliverables?</label>
            <div className="slider2-inner-container">
              <div className="deliverables-options">
                <div>
                  <span
                    className={
                      props.manageDeliverables === 'status_changes' ? 'showActive' : ''
                    }
                    onClick={() => {
                      if (props.manageDeliverables === 'status_changes') {
                        props.setManageDeliverables('');
                      } else {
                        props.setManageDeliverables('status_changes');
                      }
                    }}>
                    Status Changes
                  </span>{' '}
                </div>
                <div>
                  <span
                    className={
                      props.manageDeliverables === 'project_management'
                        ? 'showActive'
                        : ''
                    }
                    onClick={() => {
                      if (props.manageDeliverables === 'project_management') {
                        props.setManageDeliverables('');
                      } else {
                        props.setManageDeliverables('project_management');
                      }
                    }}>
                    Project Management
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label>
              Would you like to offer points as props.rewards for team and customer
              contributions?
            </label>
            <div className="slider2-inner-container">
              <div className="deliverables-options">
                <div>
                  <span
                    className={props.reward === 'yes' ? 'showActive' : ''}
                    onClick={() => {
                      if (props.manageDeliverables === 'yes') {
                        props.setReward('');
                      } else {
                        props.setReward('yes');
                      }
                    }}>
                    Yes
                  </span>
                </div>
                <div>
                  <span
                    className={props.reward === 'no' ? 'showActive' : ''}
                    onClick={() => {
                      if (props.manageDeliverables === 'no') {
                        props.setReward('');
                      } else {
                        props.setReward('no');
                      }
                    }}>
                    No
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scheduler;
