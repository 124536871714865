import React from 'react';

const DoubleCam = ({fill}) => {
  return (
    <svg
      width="42"
      height="37"
      viewBox="0 0 42 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3731_121)">
        <path
          d="M30.7136 12.1452C30.3092 10.9443 29.2345 10.1227 28.0054 10.0761C26.891 10.0661 25.7766 10.0761 24.6622 10.0761C24.3019 10.0761 24.2922 10.0706 24.2965 9.70134C24.3276 9.18907 24.2729 8.67458 24.1356 8.18115C23.8063 7.12778 22.8636 6.41037 21.7942 6.39707H12.9982C12.6217 6.38265 12.2496 6.4769 11.9224 6.66873C11.236 7.07012 10.7523 7.75869 10.5957 8.55704C10.5367 8.93071 10.5163 9.30882 10.5345 9.68693C10.5292 10.0551 10.5185 10.0706 10.1688 10.0706C9.1059 10.0706 8.04192 10.0828 6.97902 10.0706C6.52318 10.0562 6.07164 10.1604 5.66621 10.3744C4.64621 10.8334 3.99088 11.8791 4.00268 13.0278V26.0231C3.98552 26.4012 4.05094 26.7793 4.19574 27.1275C4.68804 28.3272 5.8582 29.0746 7.11631 28.9925C13.985 28.9848 20.8525 28.9815 27.7201 28.9848C28.1126 29.0125 28.5063 28.9615 28.8795 28.834C29.9532 28.4703 30.7114 27.4779 30.8058 26.3147V12.7506C30.7511 12.5521 30.7801 12.3392 30.7125 12.1441M17.4128 26.277C13.8101 26.2693 10.8949 23.2444 10.9024 19.5199C10.9099 15.7954 13.8359 12.7816 17.4386 12.7894C21.0413 12.7972 23.9565 15.822 23.949 19.5465C23.949 19.5487 23.949 19.551 23.949 19.5532C23.935 23.2733 21.0113 26.2804 17.4128 26.277Z"
          fill={fill}
        />
        <path
          d="M17.4216 23.9973C15.0383 23.9929 13.1099 21.9915 13.1152 19.5288C13.1206 17.0661 15.0555 15.0714 17.4376 15.0769C19.8198 15.0825 21.7493 17.0828 21.744 19.5454C21.7311 22.0048 19.8005 23.9929 17.4216 23.9973Z"
          fill={fill}
        />
        <path
          d="M37.906 5.75065H37.9082C37.5038 4.54981 36.4291 3.72817 35.2 3.6816C34.0856 3.67162 32.9712 3.6816 31.8579 3.6816C31.4975 3.6816 31.4879 3.67606 31.4921 3.30682C31.5232 2.79455 31.4685 2.28006 31.3313 1.78663C31.002 0.732148 30.0592 0.0147432 28.9888 0.00143736H20.1928C19.8163 -0.0129773 19.4441 0.0812722 19.117 0.273098C18.4306 0.67338 17.9468 1.36306 17.7903 2.16141C17.7313 2.53508 17.7109 2.91319 17.7291 3.2913C17.7291 3.29462 17.7291 3.29795 17.7291 3.30128H23.2195C24.9603 3.32234 26.4887 4.4866 27.0228 6.19862L27.0378 6.24851C27.1011 6.47582 27.1504 6.70645 27.188 6.93931C27.2159 6.9515 27.2416 6.96481 27.2695 6.97701C27.9763 6.97479 28.7046 6.97368 29.4264 6.97922H29.4714C31.3549 7.0524 32.9712 8.28874 33.5901 10.1283H33.5879C33.6898 10.4299 33.7102 10.6949 33.722 10.8545C33.722 10.8634 33.7231 10.8734 33.7241 10.8823L33.767 11.0364V22.5903C34.1489 22.5903 34.5318 22.5903 34.9136 22.5903C35.3062 22.618 35.6998 22.567 36.073 22.4395C37.1467 22.0758 37.905 21.0834 37.9993 19.9202V6.35607C37.9446 6.15759 37.9736 5.9447 37.906 5.74954V5.75065Z"
          fill={fill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3731_121"
          x="0"
          y="0"
          width="42"
          height="36.9987"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3731_121"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3731_121"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default DoubleCam;
