import React from 'react'; // For React components
import CustomModal from '../CustomModal';

const ImagePreview = props => {
  // Component logic and rendering here

  return (
    <CustomModal
      size="medium"
      className={'image-preview-modal'}
      onClose={() => {
        props.onClose();
      }}>
      <div className="chat-img-preview">
        <img src={props.modelImg} alt="" />
      </div>
    </CustomModal>
  );
};
export default ImagePreview;
