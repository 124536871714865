import React from 'react';

const BellSvg = ({ fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 14.584 18.241">
  <path id="Path_2243" data-name="Path 2243" fill={fill} d="M7.283,18.241a2.149,2.149,0,0,0,2.193-2.416H5.085A2.15,2.15,0,0,0,7.283,18.241ZM14.4,13.509c-.7-.925-2.083-1.468-2.083-5.612,0-4.254-1.878-5.963-3.629-6.374-.164-.041-.283-.1-.283-.269V1.122a1.112,1.112,0,1,0-2.225,0v.132c0,.169-.119.228-.283.269C4.142,1.938,2.268,3.643,2.268,7.9c0,4.144-1.381,4.682-2.083,5.612a.9.9,0,0,0,.725,1.45h12.77A.905.905,0,0,0,14.4,13.509Z"/>
</svg>

  );
};

export default BellSvg;