import React, {useState} from 'react';
import SlidingPanel from '../SlidingPanel';
import {Col, Row} from 'react-bootstrap';
import ImagePreview from '../Modals/ImagePreview';
import AlertModel from '../Modals/AlertModel';
import Scrollbars from 'react-custom-scrollbars';

export default function MultipleImages(props) {
  const [indexOfImage, setIndexOfImage] = useState('');
  const [showModelCompoenet, setShowModelCompoenet] = useState('');
  const [modelImg, setModelImg] = useState('');

  const deleteImage = index => {
    const newImages = props.imagesArray.filter((image, i) => i !== index);

    props.setImageArray(newImages);
  };
  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper">
          {/* <CustomModal size="medium" onClose={() => handleClose()}> */}
          <div className="permission-modal-content text-left">
            <h3>Images</h3>
          </div>

          <Scrollbars>
            <div className="snapshot-preview-wrapper fix-width-forms">
              <Row>
                {Object.entries(props.imagesArray).length > 0 ? (
                  Object.entries(props.imagesArray).length > 0 &&
                  props.imagesArray.map((item, index) => {
                    return (
                      <Col md={4} sm={4} key={`imagem-${index}`}>
                        <div className="snapshot-item">
                          <span
                            className="remove-icon"
                            onClick={() => {
                              // setImageArr(prevImgs => prevImgs.slice(1));
                              setIndexOfImage(index);
                              setShowModelCompoenet('image_remove_alert');
                            }}>
                            <i className="fas fa-times" />
                          </span>

                          <img
                            className="as-link"
                            src={item}
                            alt=""
                            onClick={() => {
                              setModelImg(item);
                              setShowModelCompoenet('image_pre');
                            }}
                          />
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <p className="text-center">No Images Found</p>
                )}
              </Row>
            </div>
          </Scrollbars>
        </div>
        {/* </CustomModal> */}
      </SlidingPanel>

      {showModelCompoenet === 'image_pre' && modelImg && (
        <ImagePreview
          modelImg={modelImg}
          onClose={() => {
            setShowModelCompoenet('');
          }}
        />
      )}

      {showModelCompoenet === 'image_remove_alert' && (
        <AlertModel
          // modelImg={modelImg}
          onClose={() => {
            setShowModelCompoenet('');
          }}
          removeImage={() => {
            deleteImage(indexOfImage);
          }}
        />
      )}
    </>
  );
}
