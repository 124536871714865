import React, {useState} from 'react';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';

import {Button, Form} from 'react-bootstrap';

import {Google, rewardStar} from '../../Constants/Images';
import Slider from 'react-slick';

export default function Reputation(props) {
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [tabType, setTabType] = useState('reviews');

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Reviews',
      money: '23',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Ratings',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
      icon: 'fa-solid fa-star',
    },

    {
      name: 'Repurchase',
      money: '65 Days',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 15px',
  };
  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
        showComponent={showComponent}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper  slider-screen-main">
          <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span style={{fontWeight: 700, fontSize: '13px'}}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <span style={{fontSize: '17px'}}>{item.money}</span>
                            {item.name === 'Ratings' && (
                              <img src={item.svgIcon} alt="" />
                            )}
                            {item.name === 'Conversion' ? (
                              <i
                                className="fa-solid fa-arrow-down"
                                style={{color: '#BD4141'}}></i>
                            ) : (
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            )}
                          </span>
                          <div class="message-date-info">
                            <span style={{color: '#333'}} class="message-date">
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div>
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            customCss={dynamicStyle}
            primary={
              <>
                <div className="agreements-proposals-page primary_panel_wraper primary_panel_content">
                  <div className="messages-header mb-2">
                    <div className="event-tabs tabs-style-2 mb-2">
                      {' '}
                      <ul>
                        <li>
                          <Button
                            className={tabType === 'reviews' ? 'active' : ''}
                            onClick={() => {
                              setTabType('reviews');
                            }}>
                            Reviews
                          </Button>
                        </li>

                        <li>
                          <Button
                            className={tabType === 'responses' ? 'active' : ''}
                            onClick={() => {
                              setTabType('responses');
                            }}>
                            Responses
                          </Button>
                        </li>
                      </ul>
                    </div>

                    <div className="search-tabs-icons">
                      {/* <span
                            className={
                              // currentComponent === 'create-form'
                              //   ? 'icon active'
                              // :
                              'icon'
                            }>
                            <i
                              className="icon-plus"
                              // onClick={() => setCurrentComponent('create-form')}
                            />
                          </span> */}
                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={
                          threeDots ? 'icon active threeDots' : 'icon threeDots'
                        }>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>

                  {threeDots && (
                    <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                      <div className="search-form d-flex">
                        <Form.Group className="search-input">
                          <Form.Control
                            type="text"
                            placeholder="Who are you looking for?"
                            // value={chatSearchQry}
                            // onChange={e => {
                            //   setChatSearchQry(e.target.value);
                            // }}
                          />
                        </Form.Group>
                        <Button>
                          <i
                            className="icon-filter-icon"
                            // onClick={() => setSearchFilterModal(true)}
                          />
                        </Button>
                      </div>
                    </div>
                  )}

                  <div className="refferal-list-wrapper">
                    <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide>
                      <div className="payment-card-main">
                        <div className="payment-content-card">
                          <div className="task-profile-img">
                            <img
                              src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                              alt=""
                            />
                          </div>

                          <div className="task-content">
                            <h1
                              style={{
                                color: '#000',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                marginBottom: '4px',
                              }}>
                              Joshua Austin
                            </h1>
                            <span>
                              <i
                                className="fa-solid fa-star"
                                style={{color: '#FFD43B'}}
                              />
                              <i
                                className="fa-solid fa-star"
                                style={{color: '#FFD43B'}}
                              />
                              <i
                                className="fa-solid fa-star"
                                style={{color: '#FFD43B'}}
                              />
                              <i
                                className="fa-solid fa-star"
                                style={{color: '#FFD43B'}}
                              />
                              <i
                                className="fa-solid fa-star"
                                style={{color: '#FFD43B'}}
                              />
                            </span>
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              marginLeft: '7px',
                              color: '#000',
                              fontFamily: 'Inter',
                              fontSize: '15px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: 'normal',
                            }}>
                            Jeff was amazing!! Davis Ford came to the house and
                            went through everything...
                          </div>
                          <div
                            className="d-flex justify-content-between"
                            style={{
                              marginLeft: '7px',
                              marginRight: '7px',
                              marginBottom: '10px',
                              marginTop: '10px',
                            }}>
                            <span>
                              {' '}
                              <img src={Google} alt="" />{' '}
                            </span>
                            <span
                              style={{
                                color: '#7E7B70',
                                textAlign: 'right',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                              }}>
                              11/02/23 5:43 PM
                            </span>
                          </div>
                        </div>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </>
            }
            secondary={<></>}
          />
          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
