export const PAGE_LOADED = 'PAGE_LOADED';
export const UPADATE_WINDOW_WIDTH = 'UPADATE_WINDOW_WIDTH';
export const GET_STATES = 'GET_STATES';

// Auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Admin
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SIDEBAR_MENU_STATE = 'SIDEBAR_MENU_STATE';
export const SECONDARY_PANEL_STATE = 'SECONDARY_PANEL_STATE';
export const SUB_MENU_STATE = 'SUB_MENU_STATE';

export const GET_USERS = 'GET_USERS';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';

export const GET_ADMIN_CAUSES = 'GET_ADMIN_CAUSES';
export const ADD_ADMIN_CAUSE = 'ADD_ADMIN_CAUSE';
export const UPDATE_ADMIN_CAUSE = 'UPDATE_ADMIN_CAUSE';
export const REMOVE_ADMIN_CAUSE = 'REMOVE_ADMIN_CAUSE';

export const GET_REGIONS = 'GET_REGIONS';
export const ADD_REGION = 'ADD_REGION';
export const UPDATE_REGION = 'UPDATE_REGION';
export const REMOVE_REGION = 'REMOVE_REGION';

export const GET_EXPENSES = 'GET_EXPENSES';
export const ADD_EXPENSE = 'ADD_EXPENSE';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export const REMOVE_EXPENSE = 'REMOVE_EXPENSE';

export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const ADD_TESTIMONIAL = 'ADD_TESTIMONIAL';
export const UPDATE_TESTIMONIAL = 'UPDATE_TESTIMONIAL';
export const REMOVE_TESTIMONIAL = 'REMOVE_TESTIMONIAL';

export const GET_ADMIN_SUBSCRIPTIONS = 'GET_ADMIN_SUBSCRIPTIONS';
export const ADD_ADMIN_SUBSCRIPTIONS = 'ADD_ADMIN_SUBSCRIPTIONS';
export const UPDATE_ADMIN_SUBSCRIPTION = 'UPDATE_ADMIN_SUBSCRIPTION';
export const REMOVE_ADMIN_SUBSCRIPTION = 'REMOVE_ADMIN_SUBSCRIPTION';
export const GET_ADMIN_PAYMENTS = 'GET_ADMIN_PAYMENTS';
export const REMOVE_ADMIN_PAYMENT = 'REMOVE_ADMIN_PAYMENT';

export const SAVE_TERM_PAGES = 'SAVE_TERM_PAGES';
export const GET_TERM_PAGES = 'GET_TERM_PAGES';

// Front
export const GET_HOME_CAUSES = 'GET_HOME_CAUSES';
export const GET_CAUSES = 'GET_CAUSES';
export const UPDATE_CAUSE_VOTE = 'UPDATE_CAUSE_VOTE';
export const UPDATE_MY_CAUSE_VOTE = 'UPDATE_MY_CAUSE_VOTE';
export const GET_CATEGORY_CAUSES = 'GET_CATEGORY_CAUSES';
export const ADDMORE_CATEGORY_CAUSES = 'ADDMORE_CATEGORY_CAUSES';

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_ALL_REGIONS = 'GET_ALL_REGIONS';
export const GET_MY_CAUSES = 'GET_MY_CAUSES';
export const UPDATE_MY_CAUSES = 'UPDATE_MY_CAUSES';
export const REMOVE_MY_CAUSES = 'REMOVE_MY_CAUSES';

export const GET_MY_SUBSCRIPTIONS = 'GET_MY_SUBSCRIPTIONS';
export const GET_MY_PAYMENTS = 'GET_MY_PAYMENTS';
export const UPDATE_MY_SUBSCRIPTION = 'UPDATE_MY_SUBSCRIPTION';

export const GET_MY_CARD = 'GET_MY_CARD';
export const ADD_MY_CARD = 'ADD_MY_CARD';
export const UPDATE_MY_CARD = 'UPDATE_MY_CARD';
export const REMOVE_MY_CARD = 'REMOVE_MY_CARD';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
