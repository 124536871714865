import moment from 'moment';
export const getTimeFormatForChat = time => {
  const sourceTime = new Date(time);
  const options = {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const newYorkTime = sourceTime.toLocaleString('en-US', options);
  return newYorkTime;
};

export const getTimeFormatForReply = time => {
  const sourceTime = new Date(time);

  const dateOptions = {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const timeOptions = {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };


  const formattedDate = sourceTime.toLocaleDateString('en-US', dateOptions);
  const formattedTime = sourceTime.toLocaleTimeString('en-US', timeOptions);

  return `${formattedDate} @ ${formattedTime}`;
};

export const getDateFormatForChat = date => {
  return moment(date).format('YYYY-MM-DD').toString();
};


