"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Participant = exports.toParticipantStatus = exports.ParticipantStatus = exports.toParticipantType = exports.ParticipantType = void 0;
const events_1 = require("events");
const Options_1 = require("./Options");
/**
 * The ParticipantType model represents the types of conference participants.
 */
var ParticipantType;
(function (ParticipantType) {
    /**
     * The Participant is a user.
     */
    ParticipantType["USER"] = "user";
    /**
     * The Participant is a speaker.
     */
    ParticipantType["SPEAKER"] = "speaker";
    /**
     *The Participant is a listener.
     */
    ParticipantType["LISTENER"] = "listener";
    /**
     * @ignore
     */
    ParticipantType["DOLBYVOICE"] = "dolbyVoice";
    /**
     * @ignore
     */
    ParticipantType["PSTN"] = "pstn";
    /**
     * @ignore
     */
    ParticipantType["MIXER"] = "mixer";
    /**
     * @ignore
     */
    ParticipantType["NONE"] = "none";
    /**
     * @ignore
     */
    ParticipantType["ROBOT"] = "robot";
})(ParticipantType = exports.ParticipantType || (exports.ParticipantType = {}));
/**
 * @ignore
 * @param participantType
 */
exports.toParticipantType = (participantType) => {
    switch (participantType) {
        case 'USER':
            return ParticipantType.USER;
        case 'SPEAKER':
            return ParticipantType.SPEAKER;
        case 'LISTENER':
            return ParticipantType.LISTENER;
        case 'MIXER':
            return ParticipantType.MIXER;
        case 'NONE':
            return ParticipantType.NONE;
        case 'ROBOT':
            return ParticipantType.ROBOT;
        case 'DOLBYVOICE':
            return ParticipantType.DOLBYVOICE;
        default:
            return ParticipantType.USER;
    }
};
/**
 * The ParticipantStatus model represents the statuses of conference participants. The following graphic shows possible status changes during a conference:
 *
 * <img src="../../../../../../images/participant_statuses_all_2.png" alt="screen-share" title="Screen share" width="1000"/>
 *
 */
var ParticipantStatus;
(function (ParticipantStatus) {
    /**
     * A participant is invited to a conference but has not received an invitation yet.
     */
    ParticipantStatus["RESERVED"] = "Reserved";
    /**
     * A participant did not enable audio, video, or screen-share and, therefore, is not connected to any stream.
     */
    ParticipantStatus["INACTIVE"] = "Inactive";
    /**
     * A participant declined the invitation.
     */
    ParticipantStatus["DECLINE"] = "Decline";
    /**
     * A participant received the conference invitation and is connecting to a conference.
     */
    ParticipantStatus["CONNECTING"] = "Connecting";
    /**
     * A participant successfully connected to a conference.
     */
    ParticipantStatus["CONNECTED"] = "Connected";
    /**
     * A participant left the conference.
     */
    ParticipantStatus["LEFT"] = "Left";
    /**
     * A participant experiences a peer connection problem, which may result in the `Error` or `Connected` status.
     */
    ParticipantStatus["WARNING"] = "Warning";
    /**
     * A peer connection failed, and the participant cannot connect to a conference.
     */
    ParticipantStatus["ERROR"] = "Error";
    /**
     * A participant has been kicked from the conference.
     */
    ParticipantStatus["KICKED"] = "Kicked";
})(ParticipantStatus = exports.ParticipantStatus || (exports.ParticipantStatus = {}));
/**
 * @ignore
 * @param status
 */
exports.toParticipantStatus = (status) => {
    switch (status) {
        case 'RESERVED':
            return ParticipantStatus.RESERVED;
        case 'INACTIVE':
            return ParticipantStatus.INACTIVE;
        case 'DECLINE':
            return ParticipantStatus.DECLINE;
        case 'CONNECTING':
            return ParticipantStatus.CONNECTING;
        case 'ON_AIR':
            return ParticipantStatus.CONNECTED;
        case 'LEFT':
            return ParticipantStatus.LEFT;
        case 'WARNING':
            return ParticipantStatus.WARNING;
        case 'ERROR':
            return ParticipantStatus.ERROR;
        case 'KICKED':
            return ParticipantStatus.KICKED;
        default:
            return ParticipantStatus.CONNECTING;
    }
};
/**
 * @ignore
 */
var ParticipantEmit;
(function (ParticipantEmit) {
    ParticipantEmit["Status"] = "status";
})(ParticipantEmit || (ParticipantEmit = {}));
/**
 * The Participant model includes information about the conference participant, such as the [ID](#id),
 * [status](#status-1), [information](#info), and [streams](/#streams). It informs about participant's
 * status change through the [status](#status) event.
 *
 * @noInheritDoc
 */
class Participant extends events_1.EventEmitter {
    /**
     * @ignore
     * @param id
     */
    constructor(id, status = 'CONNECTING', type = 'USER') {
        super();
        /**
         * The participant's informations.
         */
        this.info = new Options_1.ParticipantInfo();
        /**
         * The participant's streams.
         */
        this.streams = new Array();
        /**
         * The participant's audio quality indicator.
         * @ignore
         */
        this.audioQuality = -1;
        /**
         * The participant's audio status.
         */
        this.audio = false;
        this.id = id;
        this.status = exports.toParticipantStatus(status);
        this.type = exports.toParticipantType(type);
    }
    /**
     * @ignore
     * @param data
     */
    init(data) {
        this.updateStatus(data.status);
        this.info = new Options_1.ParticipantInfo();
        this.info.name = data.name;
        this.info.externalId = data.externalId;
        this.info.avatarUrl = data.avatarUrl;
        this.audio = data.audio;
        this.metadata = data.metadata;
        this.level = 0;
        this.active = false;
        this.type = exports.toParticipantType(data.participantType);
    }
    /**
     * @ignore
     * @param data
     */
    update(data) {
        this.updateStatus(data.status);
        this.updateType(data.participantType);
        this.updateAudio(data.audio || false);
        this.updateName(data.name);
    }
    /**
     * @ignore
     * @param audio
     */
    updateAudio(audio) {
        this.audio = audio || false;
    }
    /**
     * @ignore
     * @param type
     */
    updateType(type) {
        this.type = exports.toParticipantType(type);
    }
    /**
     * @ignore
     * @param status
     */
    updateStatus(status) {
        const oldStataus = this.status;
        this.status = exports.toParticipantStatus(status);
        if (oldStataus !== this.status) {
            this.emit(ParticipantEmit.Status);
        }
    }
    /**
     * @ignore
     * @param name
     */
    updateName(name) {
        this.info.name = name;
    }
}
exports.Participant = Participant;
