import React, {useState} from 'react';
import {
  Button,

} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {Pannels} from '../../Components';
import {
  scan_Qr,
} from '../../Constants/Images';
import {
  rewardStar,
} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import {getItem} from '../../Utils/LocalStorage';


const Attendence = props => {
  const [tabType, setTabType] = useState('checkIn');
  const [showComponent, setShowComponent] = useState('');
  const [threeDots, setThreeDots] = useState(false);

  const [qrImage, setQrImage] = useState(getItem('qrCode'));

  const [workSpaceItem, setWorkSpaceItem] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);








  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Check-Ins',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'On-Time',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Reviews',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];



  const dynamicStyle = {
    padding: 0,

  };
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          {/* {showLoader && <LoaderSpinner />} */}
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="primary_panel_wraper" style={{display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
                    <div className="attendence-page referal_page_content">
                      <div className="messages-header mb-2">
                        <div className="event-tabs tabs-style-2 mb-2">
                          {' '}
                          <ul>
                            <li>
                              <Button
                                className={
                                  tabType === 'checkIn' ? 'active' : ''
                                }
                                onClick={() => {
                                  setTabType('checkIn');
                                }}>
                                Check In
                              </Button>
                            </li>
                            <li>
                              <Button
                                className={
                                  tabType === 'attendance' ? 'active' : ''
                                }
                                onClick={() => {
                                  setTabType('attendance');
                                }}>
                                Attendance
                              </Button>
                            </li>
                          </ul>
                        </div>

                        <div className="search-tabs-icons">
                          <span
                            className={
                              // secondComponent === 'group-component'
                              //   ? 'icon active'
                              //   :
                              'icon'
                            }>
                            <i
                              className="icon-plus"
                              // onClick={() =>
                              //   setSecondComponent('group-component')
                              // }
                            />
                          </span>
                          <span
                            onClick={() => {
                              setThreeDots(!threeDots);
                            }}
                            className={
                              threeDots
                                ? 'icon active threeDots'
                                : 'icon threeDots'
                            }>
                            <i className="fas fa-ellipsis-vertical" />
                          </span>
                        </div>
                      </div>

                      {tabType === 'checkIn' && (
                        <div className="checkIn-tab-screen mt-5">
                          <div
                            className="checkIn-content "
                            // style={{height: '71vh'}}
                          >
                            <p>Scan QR or hit the Check In button below</p>
                            <div className="checkIn-qr-img">
                              <img src={qrImage} alt="" />
                            </div>
                          </div>
                        </div>
                      )}

                      {tabType === 'attendance' && (
                        <div className="attendence-tab-screen">
                          <div className="attendence-content">
                            <div className="attendence-detail-card-main">
                              <div className="attendence-detail-card">
                                <h1>Workspace Rooms</h1>
                                <span>Last check in: 5:43 PM</span>
                              </div>

                              <div className="detail-card-bottom">
                                <span>50 Checked In</span>

                                <div className="a-qr-icon">
                                  <img src={scan_Qr} alt="" />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3">
                              <div className="attendence-item-list">
                                <div className="user_profile_img">
                                  <img
                                    src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                    alt=""
                                  />
                                </div>

                                <div className="checkIn_user_detail">
                                  <div className="user_name">
                                    <h1>Samantha Ellington</h1>
                                    <span>Info sent</span>
                                  </div>

                                  <div className="check_in_time">5:43 PM</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {tabType === 'checkIn' && (
                        <div className="filter-btn-wrapper">
                          <div className="invite-btn text-center">
                            <Button
                              className="new-btn btn-green profile-button-padding"
                              onClick={() => {}}>
                              Check In
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
                setQrImage={setQrImage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendence;
