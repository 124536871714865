import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import Moment from 'react-moment';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import ReferNotes from './ReferNotes';
import {DEFAULT_IMAGE} from '../../Utils/env';
// import CommonChatPage from '../Common/CommonChatPage';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SmartChatPage from '../Common/SmartChatPage';
import {
  chatroomInfo,
  fixName,
  newSingleChatroom,
} from '../../Utils/commonUtils';
import {getItem} from '../../Utils/LocalStorage';

const SendFeedBack = props => {
  const [refer, setRefer] = useState('');
  const [referId, setReferId] = useState([]);
  const [bio, setBio] = useState(props.userData.note);
  const [likeButton, setLikeButton] = useState(props.userData.feedback);
  const [referedUser, setReferedUser] = useState('');
  const [newShow, setNewShow] = useState(false);
  const [newShowScnd, setNewShowScnd] = useState(false);
  const [feedBackId, setFeedBackId] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [notes, setNotes] = useState('');
  const [document, setDocument] = useState(false);
  const [chatButton, setChatButton] = useState(false);
  const [chatType, setChatType] = useState('new-personal-single');
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [convertName, setConvertName] = useState(props.userData);
  const [selectedStatus, setSelectedStatus] = useState(props.userData && props.userData !== '0' ? props.userData :'1');

  const submit = () => {
    if (likeButton === null || bio === null || feedBackId.length < 0) {
      errorMessage('Give feedback before sending');
    } else {
      addReferralFeedback();
    }
  };

  const dealStatus = [
    {
      status: '1',
      color: '#3597C2',
    },
    {
      status: '2',
      color: '#FEBE13',
    },

    {
      status: '3',
      color: '#49B038',
    },
  ]

  const handleClick = e => {
    const {id} = e.target;
    if (referId && referId.length > 0 && referId.includes(id)) {
      setReferId(referId.filter(item => item !== id));
    } else {
      setReferId([...referId, id]);
    }
  };

  const touchFunc = userInfo => {
    const userInfoObj = userInfo;
    userInfoObj.id = userInfo.user_id;
    return userInfoObj;
  };

  useEffect(() => {
    getRefer();

    const referUserData = props.userData.referred_detail;
    // referUserData.id = referUserData.user_id;
    // delete referUserData.user_id;
    setConvertName(referUserData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userData]);

  const getRefer = () => {
    setshowLoader(true);
    var payloadRefer = {
      type: 'feedback',
    };
    commonApi
      .get_refer_purpose(payloadRefer)
      .then(res => {
        if (res.status === 200) {
          setRefer(res.purpose);
          setReferId(props.userData.feedback_purpose_ids);
          setNotes(props.userData.refer_notes);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);
          } else {
            setAllChatUsers([
              {
                id: getItem('id'),
                first_name: getItem('firstName'),
                last_name: getItem('lastName'),
                profile_img: getItem('profilePic')
                  ? getItem('profilePic')
                  : DEFAULT_IMAGE,
              },
              touchFunc(convertName),
            ]);
            setChatType('new-personal-single');
            setChatDetails([]);
            // setAllChatUsers([]);
            setChatRoomName('');
            setChatRoomId('');
          }
          setChatButton(true);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const addReferralFeedback = () => {
    var payloadRefer = {
      feedback: likeButton,
      notes: bio,
      purpose_ids: referId,
      referral_id: props.userData.id,
      status: selectedStatus,
    };
    commonApi
      .add_referral_feedback(payloadRefer)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  //

  const renderReferProfileButtons = profile => (
    <>
     
        <div className="ganjaaaa sendfeedback-holder">
         
            <div className="heading sendfeed-head">
              <div className="persons-img-list m-0">
                <div
                  className="img-item as-link m-0"
                  onClick={() => {
                    setReferedUser(props.userData.referred_detail.user_id);
                    setNewShow(true);
                  }}>
                  <img
                    src={
                      props.userData.referred_detail.profile_img === ''
                        ? DEFAULT_IMAGE
                        : props.userData.referred_detail.profile_img
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="search-tabs-icons text-left search-feedback-tab-icons">
              <spn className="icon as-link">
                <i
                  className="fa-solid fa-paper-plane"
                  onClick={() => {
                    checkChatRoom(props.userData.referred_detail.user_id);
                  }}></i>
              </spn>
            </div>

            {/* <span className="close as-link" onClick={()=>{ setDocument(true)}}><i className="fa-sharp fa-solid fa-book"></i></span> */}
          
        </div>
      
    </>
  );

  return (
    <>
   
      {chatButton === true ? (
        <SmartChatPage
          allmsg={true}
          type={chatType}
          item={[]}
          // chatroomInfo={chatroomInfo(chatDetails)}
          chatroomInfo={
            chatDetails.length > 0
              ? chatroomInfo(chatDetails)
              : newSingleChatroom(touchFunc(props.userData.referred_detail))
          }
          chatDetails={chatDetails}
          oppoDetails={chatDetails}
          allChatUsers={allChatUsers}
          chatRoomName={chatRoomName}
          chatRoomId={chatRoomId}
          // userData={props.userData}
          userData={convertName}
          onClose={() => {
            setChatButton(false);
          }}
          handleCommonChat={(data, type) => {
            // handleCommonChat(data, type);
          }}
        />
      ) : (
        <>
          {document === true ? (
            <>
              <ReferNotes
                disabled={true}
                setNotesF={setNotes}
                notesF={notes}
                onClose={() => {
                  setDocument(false);
                }}
              />
            </>
          ) : (
            <>
              {referedUser && newShow === true ? (
                <ProfileComponent
                  userId={referedUser}
                  show={false}
                  closeModal={() => {
                    setNewShow(false);
                  }}
                  onClose={() => {
                    setNewShow(false);
                  }}
                  userData={[]}
                  moderator={false}
                  // setParticipantType={setParticipantType}
                  VoxeetSDK={[]}
                  conferenceInfo={[]}
                  jdSpecialM={[]}
                  jdSpecialA={[]}
                />
              ) : feedBackId && newShowScnd === true ? (
                <ProfileComponent
                  userId={feedBackId}
                  show={false}
                  closeModal={() => {
                    setNewShowScnd(false);
                  }}
                  onClose={() => {
                    setNewShowScnd(false);
                  }}
                  userData={[]}
                  moderator={false}
                  // setParticipantType={setParticipantType}
                  VoxeetSDK={[]}
                  conferenceInfo={[]}
                  jdSpecialM={[]}
                  jdSpecialA={[]}
                />
              ) : (
                <SlidingPanel
                showBlackHeader={true}
                lastDivStyle={{
                  borderRadius: '35px',
                  background: '#f7f6f2',
                  // marginBottom: '20px',
                }}
                renderData={renderReferProfileButtons}
                firstDivStyle={{
                  padding: 0,
                  background: 'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
                }}
                
                
                hidePanel={() => props.onClose()}>
                  <div className="serach-filter-wrapper">
                       {showLoader && <LoaderSpinner   className={'curved-spinner-bg'}/>}
                    <Scrollbars>
                      <div className="fix-width-inputs black-head-form-pad-left send-feedback-new-wrap">
                        {props.arrowButton === false ? (
                          <>
                            <div className="send-referrals-wrapper send-feed-refferals-wrapper">
                              <h2>
                                {fixName(
                                  props.userData.referred_detail.first_name,
                                  props.userData.referred_detail.last_name,
                                )}
                              </h2>
                              <h3>{props.userData.purpose_title}</h3>

                              <div className="date-share-wrapper cursor-default">
                                <span className="refferal-date cursor-default">
                                  <Moment format="MM/DD/YY HH:mmA">
                                    {props.userData.created_at}
                                  </Moment>
                                </span>
                                <span className="cursor-default">
                                  Leave feedback for{' '}
                                  {fixName(
                                    props.userData.referred_detail.first_name,
                                    props.userData.referred_detail.last_name,
                                  )}
                                </span>
                                <span className="cursor-default">
                                  Share feeedback with{' '}
                                  {fixName(
                                    props.userData.user_first_name,
                                    props.userData.users_last_name,
                                  )}
                                </span>
                              </div>
                              <h2> Being Referred</h2>

                              <div className="praise-user-info">
                                <div
                                  className="user-img"
                                  onClick={() => {
                                    setReferedUser(props.userData.user_id);
                                    setNewShow(true);
                                  }}>
                                  <img
                                    src={
                                      props.userData.user_profile_img === ''
                                        ? DEFAULT_IMAGE
                                        : props.userData.user_profile_img
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="user-img">
                                  <h4>
                                    {fixName(
                                      props.userData.user_first_name,
                                      props.userData.user_last_name,
                                    )}
                                  </h4>
                                </div>
                                <div className="user-icons">
                                  {props.userData.refer_notes !== '' && (
                                    <span
                                      className="icon as-link"
                                      onClick={() => {
                                        setDocument(true);
                                      }}>
                                      <i className="fa-sharp fa-solid fa-book"></i>
                                    </span>
                                  )}

                                  <span
                                    className={
                                      props.userData.feedback_purpose_ids
                                        .length > 0 &&
                                      (likeButton === 'like' ||
                                        props.userData.feedback === 'like')
                                        ? 'icon active cursor-default'
                                        : props.userData.feedback_purpose_ids
                                            .length > 0 &&
                                          (likeButton !== 'like' ||
                                            props.userData.feedback !== 'like')
                                        ? 'icon cursor-default'
                                        : likeButton === 'like' ||
                                          props.userData.feedback === 'like'
                                        ? 'icon active'
                                        : 'icon'
                                    }
                                    onClick={() => {
                                      !props.userData.feedback &&
                                        setLikeButton('like');
                                    }}>
                                    <i className="icon-like-icon" />
                                  </span>
                                  <span
                                    className={
                                      props.userData.feedback_purpose_ids
                                        .length > 0 &&
                                      (likeButton === 'dislike' ||
                                        props.userData.feedback === 'dislike')
                                        ? 'icon active cursor-default'
                                        : props.userData.feedback_purpose_ids
                                            .length > 0 &&
                                          (likeButton !== 'dislike' ||
                                            props.userData.feedback !==
                                              'dislike')
                                        ? 'icon cursor-default'
                                        : likeButton === 'dislike' ||
                                          props.userData.feedback === 'dislike'
                                        ? 'icon active'
                                        : 'icon'
                                    }
                                    onClick={() => {
                                      !props.userData.feedback &&
                                        setLikeButton('dislike');
                                    }}>
                                    <i className="icon-unlike" />
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="intrested-for-wrapper interested-feed-for-wrapper">
                            <h2> Reason</h2>
                              <div className="d-flex flex-column">
                                {Object.entries(refer).length > 0 &&
                                  refer.map((item, index) => (
                                    <Button
                                      key={`hiuash-item-${index}`}
                                      value={referId}
                                      className={
                                        referId.includes(item.id)
                                          ? 'active'
                                          : ''
                                      }
                                      id={item.id}
                                      onClick={handleClick}
                                      checked={referId.includes(item.id)}
                                      disabled={
                                        props.userData.feedback_purpose_ids
                                          .length > 0
                                          ? true
                                          : false
                                      }>
                                      {item.title}
                                    </Button>
                                  ))}
                              </div>
                              <div>
                    <h2>Likelihood</h2>
                  </div>

                  <div className="likelihood_color_flex mb-2">
                    {dealStatus.map((status, index) => (
                      <div
                        style={{
                          border:
                            selectedStatus === status.status
                              ? '3px solid #424242'
                              : '',
                        }}
                        className={`deal_status_border ${
                          selectedStatus === status.status ? 'white' : ''
                        }  `}>
                        <div
                          key={index}
                          className="deal_status"
                          style={{
                            backgroundColor: status.color,
                          }}
                          onClick={() => {
                            if ( props.userData.feedback_purpose_ids
                              .length > 0) {
                              
                            } else {
                              setSelectedStatus(status.status);
                            }
                           
                          }}></div>
                      </div>
                    ))}
                  </div>

                              <div className="send-refferal-form-wrapper mt-2 send-refferal-feedback-holder">
                                <Form.Group className="fieldset">
                                  <Form.Control
                                    as="textarea"
                                    rows={8}
                                    placeholder="Tell other contacts a little about yourself "
                                    value={bio}
                                    disabled={
                                      props.userData.note === null
                                        ? false
                                        : true
                                    }
                                    onChange={e => {
                                      setBio(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            
                          </>
                        ) : (
                          <>
                            <div className="intrested-for-wrapper pt-4 interested-send-feedback-holder">
                              <Form.Label>
                                {props.userData.purpose_title}
                              </Form.Label>
                              {props.userData.refer_notes !== '' && (
                                <span
                                  className="close as-link as-link notes-icon"
                                  onClick={() => {
                                    setDocument(true);
                                  }}>
                                  <i className="fa-sharp fa-solid fa-book"></i>
                                </span>
                              )}
                              <div className="event-tabs tabs-style-2 mt-1 mb-0" />
                              <span className="refferal-date refferal-new-date">
                                <Moment format="MM/DD/YY H:mmA">
                                  {props.userData.created_at}
                                </Moment>
                              </span>
                              <div className="event-tabs event-new-send-tabs tabs-style-2 mt-3 mb-0" />
                              <Form.Label className="txt-talks">
                                {fixName(
                                  props.userData.referred_detail.first_name,
                                  props.userData.referred_detail.last_name,
                                )}{' '}
                                feeedback on this referral?{' '}
                              </Form.Label>
                              <div className="event-tabs tabs-style-2 mb-0" />
                              {props.userData.view_status === 'yes' &&
                                props.userData.note === null && (
                                  <span>No feedback received</span>
                                )}
                              {props.userData.view_status === 'no' &&
                                !props.userData.feedback && (
                                  <span className="txt-talks">Not yet seen.</span>
                                )}

                              <div className="persons-img-list mt-2">
                                <div
                                  className="img-item as-link mr-0"
                                  onClick={() => {
                                    setNewShowScnd(true);
                                    setFeedBackId(props.userData.user_id);
                                  }}>
                                  <img
                                    src={
                                      props.userData.user_profile_img === ''
                                        ? DEFAULT_IMAGE
                                        : props.userData.user_profile_img
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              {props.userData.feedback_purpose_ids.length >
                                0 && (
                                <>
                                  <div className="search-tabs-icons">
                                    <span
                                      className={
                                        likeButton === 'like' ||
                                        props.userData.feedback === 'like'
                                          ? 'icon active'
                                          : 'icon'
                                      }
                                      onClick={() => {
                                        !props.userData.feedback &&
                                          setLikeButton('like');
                                      }}>
                                      <i className="icon-like-icon" />
                                    </span>
                                    <span
                                      className={
                                        likeButton === 'dislike' ||
                                        props.userData.feedback === 'dislike'
                                          ? 'icon active'
                                          : 'icon'
                                      }
                                      onClick={() => {
                                        !props.userData.feedback &&
                                          setLikeButton('dislike');
                                      }}>
                                      <i className="icon-unlike" />
                                    </span>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <Form.Label> which area(s)?</Form.Label>
                                    {Object.entries(refer).length > 0 &&
                                      refer.map((item, index) => (
                                        <Button
                                          key={`hiuash-item-${index}`}
                                          value={referId}
                                          className={
                                            referId.includes(item.id)
                                              ? 'active'
                                              : ''
                                          }
                                          id={item.id}
                                          onClick={handleClick}
                                          checked={referId.includes(item.id)}
                                          disabled={
                                            props.userData.feedback_purpose_ids
                                              .length > 0
                                              ? true
                                              : false
                                          }>
                                          {item.title}
                                        </Button>
                                      ))}
                                  </div>
                                  {props.userData.note !== null && (
                                    <>
                                      <span>
                                        Comment from{' '}
                                        {fixName(
                                          props.userData.referred_detail
                                            .first_name,
                                          props.userData.referred_detail
                                            .last_name,
                                        )}
                                      </span>
                                      <div className="event-tabs tabs-style-2 mt-1 mb-0" />
                                      <span>{props.userData.note}</span>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                        
                      </div>
                    </Scrollbars>
                    {props.arrowButton === false &&

<div className="filter-btn-wrapper pt-2 bg_transparent" style={{bottom: "10px"}}>
                              <div className="invite-btns p-0 text-center">
                                <Button
                                  style={{marginRight: "0px", marginBottom: "0px"}}
                                  type="submit"
                                  onClick={submit}
                                  disabled={
                                    props.userData.note === null ? false : true
                                  }>
                                  Send Feedback
                                </Button>
                              </div>
                            </div>}
                  </div>
                </SlidingPanel>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default SendFeedBack;
