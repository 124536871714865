import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import Scrollbars from 'react-custom-scrollbars';
import ReferSnapshot from '../Referral/ReferSnapshot';
import {DEFAULT_IMAGE} from '../../Utils/env';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import {Button} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import AddNewSnapShot from '../Messages/AddNewSnapShot';
import SlidingPanel from '../SlidingPanel';
import CustomModal from '../CustomModal';
import {useHistory} from 'react-router-dom';
import SessionDetail from '../SessionDetails/SessionDetail';
import CreateSession from '../SessionDetails/CreateSession';
import PurchaseTicket from '../Referral/PurchaseTicket';
import EventDetail from '../Referral/EventDetail';
import OpportunityList from '../OpportunitiesList/OpportunityList';
import ReferOpportunity from '../Referral/ReferOpportunity';
import UserListOfSnapshot from './UserListOfSnapshot';
import OpportunityDetail from '../OpportunitiesList/OpportunityDetail';
import OpportunityMessageList from '../OpportunitiesList/OpportunityMessageList';
import SmartChatPage from '../Common/SmartChatPage';
// import { labels } from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {
  fixName,
  chatroomInfo,
  chatroomInfoOppo,
  collectingOppoUsers,
  capitalizeFirst,
} from '../../Utils/commonUtils';
import {newSingleChatroom} from '../../Utils/commonUtils';
import {FormattedText} from '../../Utils/Helpers';
import {labels} from '../../Constants/Translations';
import moment from 'moment/moment';
import EditPencilSvg from '../../Svg/EditPencilSvg';
const SnapshotDetail = props => {
  const [profileId, setProfileId] = useState(props.userData.created_by);
  const [modalDeleteChat, setModalDeleteChat] = useState(false);
  // const [addNewSnapShot, setAddNewSnapShot] = useState(false);
  const [snapshotData, setSnapshotData] = useState([]);
  // const [acceptedUserImg, setAcceptedUserImg] = useState([]);
  const [subTabs, setSubTabs] = useState('Overview');
  // const [upcommingListing, setUpcommingListing] = useState([]);
  // const [oppotunityListing, setOppotunityListing] = useState([]);
  const [editSnapShotData, setEditSnapShotData] = useState(null);
  const [showComponent, setshowComponent] = useState('');
  const history = useHistory();
  const [userData, setUserData] = useState([]);
  const [status, setStatus] = useState();

  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [userInfo, setUserInfo] = useState([]);

  const [chatRoomStatus, setChatRoomStatus] = useState('pending');
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [oppoDetails, setOppoDetails] = useState([]);
  const [indexOfSnapshotId, setIndex] = useState(0);

  const [showMore, setShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';
  // let numbersArray =
  //   props.multipleSnapShotId && props.multipleSnapShotId.split();
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const imgSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: indexOfSnapshotId,
  };

  const snapshotSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };
  const userProfileSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  //  const date = new Date(props.snapshotId.date )

  //
  //
  useEffect(() => {
    getUserData();
    // alert('00 showFromMessages')
    if (props.showFromMessages) {
      // alert('0 showFromMessages')

      if (
        props.multipleSnapShotId &&
        props.multipleSnapShotId.split(',').length > 1
      ) {
        // alert('1 showFromMessages')

        getSpecificAccomplishment(props.multipleSnapShotId.split(','));
      } else {
        // alert('2 showFromMessages')
        getSpecificAccomplishment(
          props.snapshotId.id
            ? props.snapshotId.id.split(',')
            : props.snapshotId.split(','),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // getSessionsEventAgainstAccomplishment();
    // getOpportunityAgainstAccomplishment();
    if (!props.showFromMessages) {
      // alert('0 ')

      if (
        props.multipleSnapShotId &&
        props.multipleSnapShotId.split(',').length > 1
      ) {
        // alert('1 ')
        getSpecificAccomplishment(props.multipleSnapShotId.split(','));
      } else {
        // alert('2 ')
        getSpecificAccomplishment(
          props.snapshotId.id
            ? props.snapshotId.id.split(',')
            : props.snapshotId.split(','),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);

  const getSpecificAccomplishment = id => {
    var data = {
      accomplishment_ids: id,
    };
    commonApi
      .get_specific_accomplishment(data)
      .then(response => {
        if (response.success) {
          setSnapshotData(response.accomplishment_detail);

          setStatus(response.accomplishment_detail[0].delete_status);
          if (props.showCurentClickSnapshotId) {
            const specificIdIndex = response.accomplishment_detail.findIndex(
              item => item.id === props.showCurentClickSnapshotId,
            );
            setIndex(specificIdIndex);
          } else {
            setIndex(0);
          }

          // setAcceptedUserImg(
          //   response.accomplishment_detail &&
          //     response.accomplishment_detail.invited_users.filter(
          //       item => item.status == 'accepted',
          //     ),
          // );
        } else {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const showSnapshotDetail = () => {
  //   // if (props.multipleSnapShotId.includes(',')) {
  //   //   numbersArray = props.multipleSnapShotId.split(',');
  //   // } else {
  //   //   numbersArray = props.multipleSnapShotId;
  //   // }
  //   var payLoad = {
  //     accomplishment_id: props.snapshotId.id,
  //   };
  //   commonApi
  //     .detail_accomplishment(payLoad)
  //     .then(response => {
  //       if (response.status === 200) {
  //         setSnapshotData(response.accomplishment_detail);
  //         // setAcceptedUserImg(
  //         //   snapshotData &&
  //         //   snapshotData.invited_users.filter(
  //         //       item => item.status == 'accepted',
  //         //     ),
  //         // );
  //       }
  //     })
  //     .catch(error => {
  //       console.log('Err', error);
  //       return {type: 'error', message: error.message};
  //     });
  // };
  const handleProfileComponent = userId => {
    setProfileId(userId);
    setshowComponent('profile');
  };

  const deleteAccomplishment = () => {
    var data = {
      accomplishment_id: props.snapshotId.id,
    };
    commonApi
      .delete_accomplishment(data)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);

          props.onClose(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const getSessionsEventAgainstAccomplishment = () => {
  //   var data = {
  //     snap_shot_id: props.snapshotId.id,
  //   };
  //   commonApi
  //     .get_sessions_event_against_accomplishment(data)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setUpcommingListing(res.data);
  //       }
  //     })
  //     .catch(err => {
  //
  //       return {type: 'error', message: err.message};
  //     });
  // };
  // const getOpportunityAgainstAccomplishment = () => {
  //   var data = {
  //     snapshot_id: props.snapshotId.id,
  //   };
  //   commonApi
  //     .get_opportunity_against_accomplishment(data)
  //     .then(res => {
  //       if (res.status === 200) {
  //         // setUpcommingListing(res.data);
  //         setOppotunityListing(res.opportunity);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const handleCommand = (item, type) => {
    setUserData(item);

    if (type === 'referOpportunity') {
      setshowComponent('referOpportunity');
    } else {
      if (parseInt(item.created_by) === parseInt(localStorage.getItem('id'))) {
        setshowComponent('mine-opportunity');
      } else if (item.team_member_details.length > 0) {
        const found = item.team_member_details.find(
          objs => parseInt(objs.id) === parseInt(localStorage.getItem('id')),
        );
        if (typeof found === 'object') {
          setshowComponent('mine-opportunity');
        } else if (
          item.hasOwnProperty('chat_detail') &&
          Object.entries(item.chat_detail).length === 0
        ) {
          setshowComponent('respond-opportunity');
        } else if (Object.entries(item.chat_detail).length > 0) {
          setshowComponent('other-opportunity'); // new issue
        }
      } else if (item.chat_detail.length === 0) {
        setshowComponent('respond-opportunity');
      } else if (Object.entries(item.chat_detail).length > 0) {
        setshowComponent('other-opportunity');
      }
    }
  };

  const newDecision = () => {
    if (userData.login_user && userData.login_user.status) {
      return userData.login_user.status;
    } else if (userData.chat_detail.hasOwnProperty('status')) {
      return userData.chat_detail.status;
    } else {
      return 'pending';
    }
  };

  const _renderCreatorImg = person => {
    return (
      <div className="img-item">
        {
          <span className="owner-icon">
            <i className="fas fa-crown" />
          </span>
        }
        <img
          src={person.creator_img === '' ? DEFAULT_IMAGE : person.creator_img}
          alt=""
        />
      </div>
    );
  };
  const _renderAudiImg = person => {
    return (
      <div key={`person-id-${person.id}`} className="img-item">
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };
  const handleShowComponent = (item, type) => {
    setUserData(item);
    setshowComponent(type);
  };

  const SessionItem = item => {
    var totalMem = item.total_members + 1;
    var totalMode = item.total_moderators + 1;
    return (
      <div
        onClick={() => {
          handleShowComponent(item, 'session');
        }}
        className="session-item card as-link">
        <div className="session-heading">
          <h2>{item.session_title}</h2>
          <span className="users-text">
            <i className="fas fa-circle-user" /> {`${totalMem}/${totalMode}`}
          </span>
          <span className="category-text">{item.category_name}</span>
        </div>

        <div className="persons-img-list">
          {item && _renderCreatorImg(item)}
          {item.audience_ids &&
            item.audience_list &&
            Object.entries(item.audience_list).length > 0 &&
            item.audience_list.map((person, index) => _renderAudiImg(person))}
        </div>
        <div className="persons-name-list">
          <ul>
            {
              <li key={`person-name-creator`}>
                {
                  <span className="owner-icon">
                    <i className="fas fa-crown" />
                  </span>
                }
                {fixName(item.creator_first_name, item.creator_last_name)}
              </li>
            }
            {item.audience_ids &&
              item.audience_list &&
              Object.entries(item.audience_list).length > 0 &&
              item.audience_list.map((person, index) => (
                <li key={`person-name-${index}`}>
                  {fixName(person.first_name, person.last_name)}
                </li>
              ))}
          </ul>
        </div>
        {item.address && (
          <div className="person-address-place">
            <i className="fas fa-location-dot" /> {item.address}
          </div>
        )}
      </div>
    );
  };

  const handleClickLink = link => {
    // perform some action

    // navigate to a specific page using history object
    history.push(link);
  };

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);
            setshowComponent('chat');
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([userInfo.data]);
            setChatRoomName('');
            setChatRoomId('');
            setshowComponent('chat');
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const getOtherUserSetting = id => {
    let payLoad = {
      type: 'message',
      user_id: id,
    };
    commonApi
      .get_other_user_setting(payLoad)
      .then(response => {
        if (response.success) {
          checkChatRoom(id);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const getUserData = () => {
    var data = {user_id: props.userData.id};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserInfo(res);
          // setShowUserInfo(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderHeader = profile => (
    
    <>

    <div className='d-flex gap-2 snapshotDetail-leave-icon-holder'>


   
      <div
        className="event_detail user-profile-img as-link"
        onClick={() => {
          setshowComponent('profile');
        }}>
        <img
          src={ props.userData.profile_img
            ? props.userData.profile_img
            : DEFAULT_IMAGE}
          alt=""
        />
      </div>

      

     

      {props.userData.id === localStorage.getItem('user')  && (
          <span
            className=" as-link"
            onClick={() => {
              setEditSnapShotData(snapshotData[0]);
                                setshowComponent('addNewSnapShot');
            }}>
            <EditPencilSvg fill={'white'} h={20} w={20} />
          </span>
        )}


{props.userData.id !== localStorage.getItem('id') &&
                ((props.referdDetail &&
                  props.referdDetail.user_id !== localStorage.getItem('id')) ||
                  (props.creator_detail &&
                    props.creator_detail.id !==
                      localStorage.getItem('id'))) && (
                  <div className="events-icons-wrapper events-snapshot-icon-wrapper">
                    <div className="left-icons">
                      <span
                        className={''}
                        onClick={() => {
                          getOtherUserSetting(
                            props.referdDetail
                              ? props.referdDetail.user_id
                              : props.creator_detail.id,
                          );
                        }}>
                        <i className="fa-solid fa-paper-plane as-link"></i>
                      </span>
                    </div>
                  </div>
                )}

</div>
      
    </>
  );


  console.log(snapshotData,'snapshotData------->')
  return (
    <>
      {snapshotData && snapshotData.length > 0 && (
        <SlidingPanel
          hidePanel={() => props.onClose()}
          transparentBg={props.transparentBg ? true : false}
          renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2',
          // marginTop : props.marginTop ? '12px' : ''
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        >
          <div className="serach-filter-wrapper pt-0">
            <div
              className={`sidebar-heading-wrapper m-0 p-0 mt-0 fix-width-forms ${
                props.withHeadingSpace ? 'with-panel' : ''
              }`}>
              {/* <div className="with-user-img with-user-snapshot-img" 
              >
                <div
                  className="user-profile-img as-link"
                  onClick={() => {
                    setshowComponent('profile');
                  }}>
                  <img
                    src={
                      props.userData.profile_img
                        ? props.userData.profile_img
                        : DEFAULT_IMAGE
                    }
                    alt=""
                  />
                </div>
              </div> */}
              {/* {!props.refersnapShotId && (
                <div className="heading">
                  <h2>
                    {fixName(
                      props.userData.first_name,
                      props.userData.last_name,
                    )}
                  </h2>
                </div>
              )} */}
              {/* {props.userData.id !== localStorage.getItem('id') &&
                ((props.referdDetail &&
                  props.referdDetail.user_id !== localStorage.getItem('id')) ||
                  (props.creator_detail &&
                    props.creator_detail.id !==
                      localStorage.getItem('id'))) && (
                  <div className="events-icons-wrapper events-snapshot-icon-wrapper">
                    <div className="left-icons">
                      <span
                        className={''}
                        onClick={() => {
                          getOtherUserSetting(
                            props.referdDetail
                              ? props.referdDetail.user_id
                              : props.creator_detail.id,
                          );
                        }}>
                        <i className="fa-solid fa-paper-plane as-link"></i>
                      </span>
                    </div>
                  </div>
                )} */}
            </div>
            <Scrollbars>
              <Slider {...imgSliderSettings} className="snapshot-slider">
                {Object.entries(snapshotData).length > 0 &&
                  snapshotData.map((item, index) => (
                    <div
                      className="events-wrapper mt-3 fixed-width-inputs black-head-form-pad-left"
                      key={'hehe-Boy' + index}>
                      <div className="events-img-slider">
                        <Slider {...snapshotSlider}>
                          {item.images &&
                            item.images.length > 0 &&
                            Object.entries(item.images).length > 0 &&
                            item.images.map((miniitem, index) => (
                              <img src={miniitem} alt="" key={index} />
                            ))}
                        </Slider>
                      </div>

                      <div className="events-icons-wrapper snapshot-icons">
                        <div className="left-icons as-link">
                          {item.website_url && (
                            <span
                              onClick={() => openInNewTab(item.website_url)}
                              className="icon-globe"></span>
                          )}
                        </div>
                        <div className="right-icons">
                          {/* {props.userData.id ===
                            localStorage.getItem('user') && (
                            <span
                              className="as-link"
                              onClick={() => {
                                
                                setEditSnapShotData(item);
                                setshowComponent('addNewSnapShot');
                              }}>
                              <i className="fas fa-edit" />
                            </span>
                          )} */}
                          {props.userData.id === localStorage.getItem('user') &&
                            props.editProfile && (
                              <span
                                onClick={() => {
                                  if (!status) {
                                    errorMessage(
                                      'Your snapshot is associated with an active workspace. Please unsubscribe the workspace first.',
                                    );
                                  } else {
                                    setModalDeleteChat(true);
                                  }
                                }}
                                className="fa-solid fa-trash-can as-link"></span>
                            )}

                          {props.showRefer && (
                            <span
                              onClick={() => {
                                setshowComponent('reffer');
                              }}
                              className="icon-share-option as-link"></span>
                          )}
                        </div>
                      </div>

                      <div className="events-headings">
                        <h2>{item.title}</h2>
                        <div className="d-flex align-items-center justify-content-between">
                          <span
                            className="category-name"
                            style={{color: '#000'}}>
                            {item.category_name}
                          </span>

                          {item.date !== '0000-00-00' && (
                            <span
                              className="category-name"
                              style={{color: '#000'}}>
                              {moment(item.date).format('MM/DD/YY')}
                            </span>
                          )}
                        </div>
                        <span className="category-name">
                          {item.industry_title}
                        </span>
                      </div>
                      <div className="event-tabs tabs-style-2 mb-0">
                        <ul>
                          {item.description && (
                            <li>
                              <Button
                                className={
                                  subTabs === 'Overview' ? 'active' : ''
                                }
                                onClick={() => {
                                  setSubTabs('Overview');
                                }}>
                                Overview
                              </Button>
                            </li>
                          )}
                          {item.opportunities.length > 0 && (
                            <li>
                              <Button
                                className={
                                  subTabs === 'Opportunities' ? 'active' : ''
                                }
                                onClick={() => {
                                  setSubTabs('Opportunities');
                                }}>
                                Opportunities
                              </Button>
                            </li>
                          )}
                          {item.sessions_events.length > 0 && (
                            <li>
                              <Button
                                className={
                                  subTabs === 'Upcoming' ? 'active' : ''
                                }
                                onClick={() => {
                                  setSubTabs('Upcoming');
                                }}>
                                Upcoming
                              </Button>
                            </li>
                          )}
                          {/* <li>
                            <Button
                              className={subTabs === 'Pending' ? 'active' : ''}
                              onClick={() => {
                                setSubTabs('Pending');
                              }}>
                              Pending
                            </Button>
                          </li> */}
                        </ul>
                      </div>
                      {subTabs === 'Overview' && (
                        <div className="events-desc-wrapper events-desc-snapshot-wrapper">
                          <FormattedText
                            className={`profile-description ${showMoreClass}`}
                            content={item.description}
                          />
                          {item.description.length > 250 && (
                            <>
                              {' '}
                              <span
                                className="show-more-link"
                                onClick={() => setShowMore(!showMore)}>
                                {!showMore
                                  ? labels.read_more[selected_lang]
                                  : labels.read_less[selected_lang]}
                                {/* {profile.data.bio.length} */}
                              </span>
                            </>
                          )}

                          {item.location && (
                            <span className="event-address">
                              <i className="icon-map-pin" /> {item.location}
                            </span>
                          )}
                          {props.showReferDetails && (
                            <div className="text-right">
                              <span className="event-address justify-content-end">
                                Referred {props.arrowButton ? 'to ' : 'by '}
                                {props.referdDetail.user_first_name &&
                                  fixName(
                                    props.referdDetail.user_first_name,
                                    props.referdDetail.users_last_name,
                                  )}
                                {props.referdDetail.first_name &&
                                  props.referdDetail.last_name &&
                                  fixName(
                                    props.referdDetail.first_name,
                                    props.referdDetail.last_name,
                                  )}
                                {props.referdDetail.first_name &&
                                  !props.referdDetail.last_name &&
                                  capitalizeFirst(
                                    props.referdDetail.first_name,
                                  )}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {subTabs === 'Upcoming' && (
                        <div className="img-gallery-wrapper">
                          <>
                            {Object.entries(item.sessions_events).length > 0 ? (
                              Object.entries(item.sessions_events).length > 0 &&
                              item.sessions_events.map((item, index) => (
                                <div key={`ses-item-${index}`}>
                                  {item && SessionItem(item)}
                                </div>
                              ))
                            ) : (
                              <div className="no-results-wrapper">
                                <div className="no-results-content">
                                  <h2>
                                    You don't have any upcoming activities.
                                  </h2>
                                  <p>
                                    Grow your personal and professional circles
                                    by creating and attending group sessions and
                                    events.
                                  </p>

                                  <div className="btn-wrapper">
                                    <Button
                                      className="btn btn-dark"
                                      onClick={() => {
                                        handleClickLink('/');
                                      }}>
                                      Explore Sessions and Events
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        </div>
                      )}

                      {subTabs === 'Opportunities' && (
                        <>
                          <div className="opportunities-list-wrapper">
                            {Object.entries(item.opportunities).length > 0 ? (
                              Object.entries(item.opportunities).length > 0 &&
                              item.opportunities.map((item, index) => (
                                <OpportunityList
                                  key={`opportunity-item-${index}`}
                                  item={item}
                                  handleCommand={handleCommand}
                                />
                              ))
                            ) : (
                              <div className="no-results-wrapper">
                                {/* <div className="img-bg-wrapper">
                              <img src={QA} alt="" />
                            </div> */}

                                <div className="no-results-content">
                                  <h2>
                                    You are not participating in any
                                    opportunities.
                                  </h2>
                                  <p>
                                    Create or explore opportunities to
                                    accelerate your growth. Find partners, seek
                                    funding, invest and so much more.
                                  </p>

                                  <div className="btn-wrapper">
                                    <Button
                                      className="btn btn-dark"
                                      onClick={() => {
                                        handleClickLink('/opportunities');
                                      }}>
                                      Explore Opportunities
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      <div className="persons-img-gallery mt-4">
                        <Slider {...userProfileSlider}>
                          {Object.entries(item.invited_users).length > 0 &&
                            item.invited_users.map((miniItem, index) => (
                              <div
                                className="persons-img-list persons-img-snapshot-holder m-0"
                                key={index + 'eee'}>
                                <div
                                  className="img-item as-link"
                                  onClick={() => {
                                    handleProfileComponent(miniItem.user_id);
                                  }}>
                                  <img
                                    src={
                                      miniItem.profile_img
                                        ? miniItem.profile_img
                                        : DEFAULT_IMAGE
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            ))}
                        </Slider>
                        {item.invited_users &&
                          item.invited_users.length > 0 && (
                            <div className="text-right vertical-ellipsis-icon all-pending-users all-snapshot-detail-users">
                              <span
                                className="text-right as-link infoIcon"
                                onClick={() => {
                                  setEditSnapShotData(item);
                                  setshowComponent('userList');
                                }}>
                                <i className="fas fa-ellipsis-vertical"></i>
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
              </Slider>
            </Scrollbars>
          </div>
        </SlidingPanel>
      )}

      {showComponent === 'chat' && (
        <SmartChatPage
          allmsg={true}
          type={chatType}
          item={[]}
          chatroomInfo={
            chatDetails.length > 0
              ? chatroomInfo(chatDetails)
              : newSingleChatroom(userInfo.data)
          }
          oppoDetails={chatDetails}
          chatDetails={chatDetails}
          allChatUsers={allChatUsers}
          chatRoomName={chatRoomName}
          chatRoomId={chatRoomId}
          onClose={() => {
            setshowComponent('');
          }}
          userData={userInfo.data}
          // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
        />
      )}
      {showComponent === 'addNewSnapShot' && (
        <AddNewSnapShot
          id={props.snapshotId.id}
          snapShotData={editSnapShotData}
          onClose={() => {
            setshowComponent('');
          }}
        />
      )}
      {showComponent === 'reffer' && (
        <ReferSnapshot
          onClose={() => {
            setshowComponent('');
          }}
          userData={props.userData}
          snapshotData={props.snapshotId}
        />
      )}
      {showComponent === 'profile' && profileId && (
        <ProfileComponent
          userId={profileId}
          show={false}
          closeModal={() => {
            setshowComponent('');
          }}
          onClose={() => {
            setshowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
      {showComponent === 'respond-opportunity' && (
        <OpportunityDetail
          item={userData}
          userData={userData.creator_details}
          onClose={() => {
            setshowComponent('');
          }}
        />
      )}
      {showComponent === 'session' &&
        userData.creator_id !== localStorage.getItem('id') &&
        userData.event === 'no' && (
          <SessionDetail item={userData} onClose={() => setshowComponent('')} />
        )}
      {showComponent === 'session' &&
        userData.creator_id === localStorage.getItem('id') &&
        userData.event === 'no' && (
          <CreateSession
            onClose={() => {
              setshowComponent('');
            }}
            editSession={userData}
            getApiSessionList={[]}
            sessionType={''}
            typeIs={''}
            item={userData}
          />
          //   </div>
          // </SlidingPanel>
        )}
      {showComponent === 'session' &&
        userData.creator_id !== localStorage.getItem('id') &&
        !userData.moderator_ids.includes(localStorage.getItem('id')) &&
        userData.event === 'yes' && (
          <PurchaseTicket
            onClose={() => {
              setshowComponent('');
            }}
            item={userData}
            // type={"refer"}
            getApiSessionList={[]}
            sessionType={''}
            typeIs={''}
            // item={userData}
          />
        )}
      {showComponent === 'session' &&
        (userData.creator_id === localStorage.getItem('id') ||
          userData.moderator_ids.includes(localStorage.getItem('id'))) &&
        userData.event === 'yes' && (
          <EventDetail
            onClose={() => {
              setshowComponent('');
            }}
            getApiSessionList={[]}
            // getApiSessionList(sessionType, "", !typeIs, pageNo);
            sessionType={''}
            typeIs={''}
            item={userData}
            type={'type'}
          />
        )}
      {showComponent === 'mine-opportunity' && (
        <OpportunityMessageList
          item={userData}
          setOppoDetails={setOppoDetails}
          oppoDetails={oppoDetails}
          setAllChatUsers={setAllChatUsers}
          setChatRoomId={setChatRoomId}
          setChatRoomName={setChatRoomName}
          setChatRoomStatus={setChatRoomStatus}
          chatRoomStatus={chatRoomStatus}
          onClose={() => {
            // dispatch(
            //   getOpportunityListing(opportunityData.payload),
            // );
            // setShowButton(false);
            setshowComponent('');
          }}
          onEditOppo={() => {
            setshowComponent('fromOppoMsg'); // temparary majbori
            // setInviteUser(true);
            // setCreateSessionEvent(false);
          }}
          onCreateSessionEvent={() => {
            // setCreateSessionEvent(true);
            // setInviteUser(false);
            setshowComponent('');
          }}
          onShowChat={() => {
            setshowComponent('show-chats');
          }}
        />
      )}
      {/* {chatRoomName !== '' &&
        allChatUsers.length > 0 &&
        showComponent === 'show-chats' && (
          <SmartChatPage
            allmsg={true}
            type={'mine-opportunity'}
            item={userData}
            oppoDetails={oppoDetails}
            chatroomInfo={chatroomInfoOppoMsgList(oppoDetails, 'opportunity')}
            allChatUsers={allChatUsers}
            chatDetails={oppoDetails.chat_detail}
            chatRoomName={chatRoomName}
            chatRoomId={chatRoomId}
            chatRoomStatus={chatRoomStatus}
            onClose={() => {
              setChatRoomName('');
              // setShowButton(true);
              setshowComponent('mine-opportunity');
            }}
          />
        )} */}
      {showComponent === 'other-opportunity' && (
        <SmartChatPage
          type={'other-opportunity'}
          allmsg={true}
          chatroomInfo={chatroomInfoOppo(userData, 'opportunity')}
          item={[]}
          oppoDetails={userData}
          chatDetails={userData.chat_detail}
          allChatUsers={collectingOppoUsers(userData)}
          chatRoomName={
            userData.chat_detail.hasOwnProperty('chat_room_name')
              ? userData.chat_detail.chat_room_name
              : ''
          }
          chatRoomId={
            userData.chat_detail.hasOwnProperty('id')
              ? userData.chat_detail.id
              : ''
          }
          onClose={() => {
            // dispatch(getOpportunityListing(opportunityData.payload));

            setshowComponent('');
          }}
          userData={
            userData.hasOwnProperty('creator_details')
              ? userData.creator_details
              : userData.creator_detail
          }
          chatRoomStatus={newDecision()}
          // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
        />
        // </SlidingPanel>
      )}

      {showComponent === 'referOpportunity' && (
        <ReferOpportunity
          userData={userData}
          onClose={() => {
            setshowComponent('');
          }}
          type={'opportunity'}
        />
      )}
      {showComponent === 'userList' && editSnapShotData && (
        <UserListOfSnapshot
          invitedUser={editSnapShotData}
          onClose={() => {
            setshowComponent('');
          }}
        />
      )}
      {modalDeleteChat && (
        <CustomModal size="small" onClose={() => setModalDeleteChat(false)}>
          <div className="leave-session-content w-100">
            <h3>Remove Snapshots!</h3>
            <p>Are you sure you want to delete this snapshot?</p>

            <div className="d-flex align-items-center justify-content-center">
              <Button
                className="btn-dark"
                onClick={() => {
                  deleteAccomplishment();
                  setModalDeleteChat(false);
                }}>
                Yes
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  setModalDeleteChat(false);
                }}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default SnapshotDetail;
