import React, {useState} from 'react';

import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';
import {Button} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {SliderAnnouncements} from '../../Constants/AnalyticsConstants';
import Slider from 'react-slick';

const Announcement = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showComponent, setShowComponent] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [tabType, setTabType] = useState('announcements');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const announcementData = [
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
          workspaceData={workspaceData}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {SliderAnnouncements.length > 0 &&
                      SliderAnnouncements.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <div className="non-profit-page primary_panel_content">
                  <div className="nonprofit-top ">
                    <h1>Hey, Jeff</h1>
                    {/* <div>Check Announcements !</div> */}
                    {/* <h1 className="">Recent Announcements</h1> */}
                  </div>
                  <div className="messages-header mb-2">
                    {/* <div className="event-tabs dark-tabs"> */}
                    <div className="event-tabs tabs-style-2 mb-2">
                      {' '}
                      <ul>
                        <li>
                          <Button
                            className={
                              tabType === 'announcements' ? 'active' : ''
                            }
                            style={{color: '#000'}}
                            onClick={() => {
                              // setGalleryType('chats');
                              setTabType('announcements');
                              // setShowSpinner(true);
                            }}>
                            Announcements
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="refferal-list-wrapper">
                    <Scrollbars autoHide className="custom-refferal-vertical-scrollbar">
                      {Object.entries(announcementData).length > 0 &&
                        announcementData.map((item, index) => (
                          <div className="news-card mb-4">
                            <div className="news-img"></div>

                            <span className="news-title">
                              We just painted a school
                            </span>

                            <p className="news-description">
                              Thanks to our donors we are now able to help
                              Bethel Middle School.
                            </p>

                            <span className="news-read-more-btn">
                              Read more
                            </span>
                          </div>
                        ))}
                    </Scrollbars>
                  </div>
                </div>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Announcement;
