import {
  PAGE_LOADED,
  UPADATE_WINDOW_WIDTH,
  SIDEBAR_MENU_STATE,
  GET_SETTINGS,
  GET_STATES,
  GET_MY_CARD,
  ADD_MY_CARD,
  UPDATE_MY_CARD,
  REMOVE_MY_CARD,
  GET_NOTIFICATIONS,
  SECONDARY_PANEL_STATE,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../../Constants/Types';

export const pageLoaded = (state = {}, action = {}) => {
  switch (action.type) {
    // Projects API
    case PAGE_LOADED:
      return action.status;
    default:
      return state;
  }
};
export const windowWidth = (state = 1200, action = {}) => {
  switch (action.type) {
    // Projects API
    case UPADATE_WINDOW_WIDTH:
      return action.width;
    default:
      const windowWidth = window.innerWidth;
      return windowWidth;
  }
};

export const usStates = (state = {}, action = {}) => {
  switch (action.type) {
    // Projects API
    case GET_STATES:
      return action.states;
    default:
      return state;
  }
};

export const menuState = (state = true, action = {}) => {
  switch (action.type) {
    case SIDEBAR_MENU_STATE:
      return action.status;
    default:
      return state;
  }
};

export const subMenuState = (state = false, action = {}) => {
  switch (action.type) {
    case SIDEBAR_MENU_STATE:
      return action.status;
    default:
      return state;
  }
};

export const secondaryPannelState = (state = false, action = {}) => {
  switch (action.type) {
    case SECONDARY_PANEL_STATE:
      return action.status;
    default:
      return state;
  }
};

export const settings = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_SETTINGS:
      return action.settings;
    default:
      return state;
  }
};

export const notifications = (state = {}, action = {}) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return action.notifications;
    default:
      return state;
  }
};

export const myCards = (state = [], action = {}) => {
  let cards;
  switch (action.type) {
    case GET_MY_CARD:
      return action.cards;
    case ADD_MY_CARD:
      cards = state;
      if (Object.entries(cards).length > 0) {
        cards.unshift(action.card);
      } else {
        cards = cards.push(action.card);
      }
      
      return cards;
    case UPDATE_MY_CARD:
      cards = state.map(card => {
        if (card.id === action.card.id) return {...card, ...action.card};
        return card;
      });
      return cards;
    case REMOVE_MY_CARD:
      cards = state.filter(card => {
        return card.id !== action.card.id;
      });
      return cards;
    default:
      return state;
  }
};

export const newNotifications = (state = [], action = {}) => {
  let items;
  switch (action.type) {
    case ADD_NOTIFICATION:
      state.push(action.notification);
      return state;
    case REMOVE_NOTIFICATION:
      items = state.filter(notification => {
    
        return notification.id !== action.notification.id;
      });
 
      return items;
    default:
      return state;
  }
};



export const updateCompanyName = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE/COMPANYNAME':
      return action.payload;
    default:
      return state;
  }
};


export const companyLoader = (state = false, action) => {
  switch (action.type) {
    case 'COMPANY/CHANGE':
      return action.payload;
    default:
      return state;
  }
};

export const companyMenuState = (state = false, action) => {
  switch (action.type) {
    case 'MENU/OPEN':
      return action.payload;
    default:
      return state;
  }
};


export const registerRouteReducer = (state = false, action) => {
  switch (action.type) {
    case 'ROUTE/AVAILABLE':
      return action.payload;
    default:
      return state;
  }
};