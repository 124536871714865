// import { useState} from 'react';
import {
  Button,
} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';

const OpportunityScreen = props => {
  // const [showLoader, setshowLoader] = useState(false);

  const addTabStatus = type => {
    // setshowLoader(true);
    var payload = {
      tab: props.type,
    };
    commonApi
      .add_tab_status(payload)
      .then(res => {
        // setshowLoader(false);
        if (res.status === 200) {
          props.onClose();
          if (type === 'show-opportuntiy') {
            props.setProperComponent('inviteUser');
          }
          if (type === 'show-createGroup') {
            props.setSecondComponent('group-component');
          }
          if (type === 'show_invites') {
            props.setShowComponent('inviteuser');
          }
          if (type === 'snapshot') {
            // props.setAddNewSnapshot(true);
            props.handleSnapshot()
          }
          if (type === 'appointment') {
            props.setShowComponent('addAppointment');
          }
          if (type === 'circle') {
            props.setShowComponent('createCircle');
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <div className="first-visits-block">
      {props.type === 'session_event' && (
        <div className="first-visits-block-inner startTabs">
          <div className="modal-header">
            <img src={require('../../Assets/images/start-session.png')} alt="" />
          </div>
          <div className="visits-list">
            <p className="modalTitle">Start your first Group Session</p>
            <p className="modalCaution">
              Invite an audience to have a group video or audio session
            </p>
          </div>
          <div className="visit-btn-wrapper startTabs_btns">
            <Button
              className="btn-dark"
              onClick={() => {
                props.setButton('sessions');
                props.setPlusButton(false);
                addTabStatus();
              }}>
              Create Session
            </Button>
            <p
              className="btn-link"
              onClick={() => {
                addTabStatus();
              }}>
              Not Now
            </p>
          </div>
        </div>
      )}

      {props.type === 'opportunity' && (
        <div className="first-visits-block-inner startTabs">
          <div className="modal-header">
            <img src={require('../../Assets/images/manage-shareables.png')} alt="" />
          </div>
          <div className="visits-list">
            <p className="modalTitle">Manage Shareables</p>
            <p className="modalCaution pt-3">
              Add element that drive your business to quick share and track
            </p>
          </div>
          <div className="visit-btn-wrapper startTabs_btns">
            <Button
              className="btn-dark"
              onClick={() => {
                addTabStatus();
              }}>
              I Understand
            </Button>
            <p
              className="btn-link"
              onClick={() => {
                addTabStatus();
              }}>
              Not Now
            </p>
          </div>
        </div>
      )}

      {props.type === 'chats' && (
        <div className="first-visits-block-inner startTabs">
          <div className="modal-header">
            <img src={require('../../Assets/images/manage-messages.png')} alt="" />
          </div>
          <div className="visits-list">
            <p className="modalTitle">Manage Messages</p>
            <p className="modalCaution">
              Create group chats, which we call “Tables,” or engage 1-on-1
            </p>
          </div>
          <div className="visit-btn-wrapper startTabs_btns">
            <Button
              className="btn-dark"
              onClick={() => {
                addTabStatus('show-createGroup');
              }}>
              Create Group Chat
            </Button>
            <p
              className="btn-link"
              onClick={() => {
                addTabStatus();
              }}>
              Not Now
            </p>
          </div>
        </div>
      )}

      {props.type === 'refer' && (
        <div className="first-visits-block-inner startTabs">
          <div className="modal-header">
            <img src={require('../../Assets/images/manage-referrals.png')} alt="" />
          </div>
          <div className="visits-list">
            <p className="modalTitle">Manage Referrals</p>
            <p className="modalCaution">
              Leverage your network to quickly connect people you meet daily
            </p>
          </div>
          <div className="visit-btn-wrapper startTabs_btns">
            <Button
              className="btn-dark"
              onClick={() => {
                addTabStatus('show_invites');
              }}>
              Invite Colleagues
            </Button>
            <p
              className="btn-link"
              onClick={() => {
                addTabStatus();
              }}>
              Not Now
            </p>
          </div>
        </div>
      )}
      {props.type === 'circle' && (
        <div className="first-visits-block-inner startTabs">
          <div className="modal-header">
            <img src={require('../../Assets/images/manage-circles.png')} alt="" />
          </div>
          <div className="visits-list">
            <p className="modalTitle">Manage Circles</p>
            <p className="modalCaution">
              Organize your relationships to quickly reference later
            </p>
          </div>
          <div className="visit-btn-wrapper startTabs_btns">
            <Button
              className="btn-dark"
              onClick={() => {
                addTabStatus('circle');
              }}>
              Setup Circles
            </Button>
            <p
              className="btn-link"
              onClick={() => {
                addTabStatus();
              }}>
              Not Now
            </p>
          </div>
        </div>
      )}
      {props.type === 'appointment' && (
        <div className="first-visits-block-inner startTabs">
          <div className="modal-header">
            <img src={require('../../Assets/images/manage-appointments.png')} alt="" />
          </div>
          <div className="visits-list">
            <p className="modalTitle">Manage Appointments</p>
            <p className="modalCaution">
              Setup consults or any other 1-on-1 video calls you deem necessary
            </p>
          </div>
          <div className="visit-btn-wrapper startTabs_btns">
            <Button
              className="btn-dark"
              onClick={() => {
                addTabStatus('appointment');
              }}>
              Setup Appointments
            </Button>
            <p
              className="btn-link"
              onClick={() => {
                addTabStatus();
              }}>
              Not Now
            </p>
          </div>
        </div>
      )}
      {props.type === 'snapshot' && (
        <div className="first-visits-block-inner startTabs">
          <div className="modal-header">
            <img src={require('../../Assets/images/manage-snapshots.png')} alt="" />
          </div>
          <div className="visits-list">
            <p className="modalTitle">manage-snapshots</p>
            <p className="modalCaution">
              Create shareable one-pagers for your business(es), affiliations
              and more
            </p>
          </div>
          <div className="visit-btn-wrapper startTabs_btns">
            <Button
              className="btn-dark"
              onClick={() => {
                addTabStatus('snapshot');
              }}>
              Add Credentials
            </Button>
            <p
              className="btn-link"
              onClick={() => {
                addTabStatus();
              }}>
              Not Now
            </p>
          </div>
        </div>
      )}
      {props.type === 'profile' && (
        <div className="first-visits-block-inner startTabs">
          <div className="modal-header">
            <img src={require('../../Assets/images/manage-referrals.png')}  alt="" />
          </div>
          <div className="visits-list">
            <p className="modalTitle">Manage Referrals</p>
            <p className="modalCaution">
              Leverage your network to quickly connect people you meet daily
            </p>
          </div>
          <div className="visit-btn-wrapper startTabs_btns">
            <Button
              className="btn-dark"
              onClick={() => {
                addTabStatus('profile');
              }}>
              Invite Colleagues
            </Button>
            <p
              className="btn-link"
              onClick={() => {
                addTabStatus();
              }}>
              Not Now
            </p>
          </div>
        </div>
      )}

      {/* <div className="first-visits-block-inner">
        <div className="visits-header">
          <h2>
            {props.type === 'session_event' &&
              'Connect Virtually and in-Person'}
            {props.type === 'opportunity' && 'Explore Opportunities and Q&As'}
            {props.type === 'chats' && 'Create Solo and Group Chats'}
            {props.type === 'refer' && 'Expand your Referral Network'}
            {props.type === 'profile' && 'Build Relationships Quicker'}
          </h2>
        </div>
        <div className="visits-list">
          {props.type === 'session_event' && (
            <ul>
              <li>
                <span>
                  <i className="icon-video-camera-2" /> Group virtual
                  conversation
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-share" /> Refer sessions and event
                </span>
              </li>
              <li>
                <span>
                  <i className="far fa-heart" /> Save and receive reminder
                </span>
              </li>
              <li>
                <span>
                  <i className="icon-tickets" /> In-person events
                </span>
              </li>
              <li>
                <span>
                  <i className="icon-circles" /> Share with social circles
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-ellipsis-vertical" /> More options
                  available
                </span>
              </li>
            </ul>
          )}
          {props.type === 'opportunity' && (
            <ul>
              <li>
                <span>
                  <i className="fa-solid fa-dollar-sign" /> Opportunities
                  related to money
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-share" /> Refer Opportunities
                </span>
              </li>
              <li>
                <span>
                  <i className="far fa-heart" /> Save and receive reminder
                </span>
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-syringe" /> COVID vaccination
                  required
                </span>
              </li>
              <li>
                <span>
                  <i className="icon-circles" /> Share with social circles
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-ellipsis-vertical" /> More options
                  available
                </span>
              </li>
            </ul>
          )}
          {props.type === 'chats' && (
            <ul>
              <li>
                <span>
                  <i className="icon-video-camera-2" /> Sessions conversation
                </span>
              </li>
              <li>
                <span>
                  <i className="icon-tickets" /> Event conversation
                </span>
              </li>
              <li>
                <span>
                  <i className="icon-circles" /> Invite social circles
                </span>
              </li>
            </ul>
          )}
          {props.type === 'refer' && (
            <ul>
              <li>
                <span>
                  <i className="fas fa-share" /> Refer to others
                </span>
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-thumbs-up"></i> Referral feedback
                </span>
              </li>
            </ul>
          )}
          {props.type === 'profile' && (
            <ul>
              <li>
                <span>
                  <i className="fa-solid fa-calendar-days" /> 1-on-1 video call
                </span>
              </li>
              <li>
                <span>
                  <i className="icon-user-group-m" /> Followers and following
                </span>
              </li>
              <li>
                <span>
                  <i className="icon-applaud" /> Community feedback
                </span>
              </li>
              <li>
                <span>
                  <i className="fas fa-share" /> Refer yourself and others
                </span>
              </li>
              <li>
                <span>
                  <i className="icon-circles" /> Social circles
                </span>
              </li>
            </ul>
          )}
        </div>
        <div className="visit-btn-wrapper">
          <Button
            className="btn-dark"
            onClick={() => {
              addTabStatus();
            }}>
            Got It
          </Button>
        </div>
      </div> */}
    </div>
  );
};
export default OpportunityScreen;
