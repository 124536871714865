import CustomModal from '../CustomModal';
import {Button, Form} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import {errorMessage} from '../../Utils/ToastMessages';

const PersonalCreateModel = props => {
  const [showComponent, setShowComponent] = useState('');

  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);
  const dispatch = useDispatch();

  const [activeWorkspaces, setActiveWorkspacesId] = useState([]);

  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);
  useEffect(() => {
    if (props.workSpaceIds.length > 0) {
      // Use the filter method to keep only the workspaces with matching IDs
      // const userExist = listWorspacerator.filter(workspace =>
      //   props.workSpaceIds.includes(workspace.id),
      // );

      // Extract the 'id' values from 'userExist'
      // const updatedIds = userExist.map(workspace => workspace.id);

      // if (props.workSpaceIds.includes('personal')) {
      //   updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      // }

      setActiveWorkspacesId(props.workSpaceIds);
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      setActiveWorkspacesId(updatedIds);
      props.setWorkSpaceIds(updatedIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  const handleCheckboxChange = event => {
    const workspaceId = event.target.id;
    if (!activeWorkspaces.includes(workspaceId)) {
      // Workspace is currently active, remove it from the selection

      setActiveWorkspacesId(prevActiveWorkspaces => [
        ...prevActiveWorkspaces,
        workspaceId,
      ]);
    } else {
      if (activeWorkspaces.length === 1) {
        errorMessage('One workspace should be selected.');
      } else {
        setActiveWorkspacesId(prevActiveWorkspaces =>
          prevActiveWorkspaces.filter(id => id !== workspaceId),
        );
      }
      // Workspace is not active, add it to the selection
    }
  };

  const handleUpdateButtonClick = () => {
    props.setWorkSpaceIds(activeWorkspaces);

    props.onClose();
  };

  return (
    <>
      {' '}
      <CustomModal
        size="small"
        onClose={() => {
          props.onClose();
          if (props.type === 'eventbrite') {
            props.setToggleEventBrite(!props.toggleEventBrite);
            props.setCheckEventBritelogin(false);
          }
        }}>
        <div className="filter-wrapper">
          <div className="filter-category-list">
            <div className="filter-headings">
              <h2>Associated Workspace</h2>
            </div>
            <ul>
              <li>
                <Form.Label>
                  Personal
                  <input
                    type="checkbox"
                    id={'personal'}
                    value={'personal'}
                    onChange={handleCheckboxChange}
                    checked={activeWorkspaces.includes('personal')}
                  />
                  <span />
                </Form.Label>
              </li>
              {Object.entries(listWorspacerator).length > 0 &&
                listWorspacerator.map((item, index) => (
                  <li key={`sessionC-${index}`}>
                    <Form.Label>
                      {item.name}
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        onChange={handleCheckboxChange}
                        checked={activeWorkspaces.includes(item.id)}
                      />
                      <span />
                    </Form.Label>
                  </li>
                ))}
            </ul>
            {/* {!props.type && (
              <div className="invite-persons-heading mt-3">
                <span
                  className="builder-text as-link"
                  onClick={() => {
                    setShowComponent('WorkSpace');
                  }}>
                  Manage Workspace
                </span>
              </div>
            )} */}
            <Button
              type="submit"
              className="btn-dark"
              // disabled={true}
              // onClick={handleUpdateButtonClick}
              onClick={() => {
                handleUpdateButtonClick();
                if (props.type === 'eventbrite') {
                  props.eventBriteLogin();
                }
              }}>
              {/* {props.type ? 'Select' : 'Update'} */}
              Select
            </Button>
          </div>
        </div>
      </CustomModal>
      {showComponent === 'WorkSpace' && (
        <CreateWorkSpace
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
    </>
  );
};

export default PersonalCreateModel;
