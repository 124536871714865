import { getItem } from "../../Utils/LocalStorage";

export const menuPermesionList = (state = [], action) => {
    switch (action.type) {
      case 'GET_USER_Menu_Permesions_LIST':
        return action.payload;
      default:
        return state;
    }
  };
export const menuActiveList = (state = [], action) => {
    switch (action.type) {
      case 'GET_USER_Active_Menu_LIST':


      const newItem = {
        id: '2',
        menu_name: 'Home',
        link: '/customer-mall',
        status: 'active',
        created_at: '2024-01-22 17:48:11',
        updated_at: '2024-01-22 07:48:11',
      };
   

      if (getItem('marketId') === '2' && action.payload.menus?.menu_details[0].id !=='2') {
        action.payload.menus?.menu_details.unshift(newItem);
      }
        return action.payload ? action.payload : [];
      default:
        return state;
    }
  };