import React, {useState, useEffect} from 'react';
import commonApi from '../../Services/CommonService';
import {errorMessage} from '../../Utils/ToastMessages';
import {db, storage, storageRef} from '../../Firebase/firebase';
import {callChatApi} from '../../Store/Actions/ChatAction';
import {ref, push, onValue, update, set, off} from 'firebase/database';
import {useDispatch} from 'react-redux';
import {getItem} from '../../Utils/LocalStorage';
import SmartLiveChatBox from './SmartLiveChatBox';
import Scrollbars from 'react-custom-scrollbars';
import {chatroomInfo, newSingleChatroom} from '../../Utils/commonUtils';
import {Form} from 'react-bootstrap';

import SmartLiveChatPage from './SmartLiveChatPage';

import {DEFAULT_IMAGE} from '../../Utils/env';
import $ from 'jquery';
import ChatIcon from '../../Svg/ChatIcon';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {RoiLogo} from '../../Constants/Images';

import {RoiArrowLogo} from '../../Constants/Images';
import {DEFAULT_IMAGE_COMPANYLOGO} from '../../Constants/Images';

const LandingPageChat = props => {
  const [tabtype, setTabtype] = useState('');
  const [showChat, setShowChat] = useState('');
  const [hideTabs, setHideTabs] = useState(false);
  const [showQuestion, setShowQuestion] = useState(true);
  const [userInfo, setUserInfo] = useState([]);
  const [loginUserId, setLoginId] = useState('');

  const dispatch = useDispatch();

  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [currentStep, setStep] = useState('phone');
  const [CompanyDetail, setCompanyDetail] = useState({});
  const [chatCount, setChatCount] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [pocList, setPocList] = useState({});
  const [notAvailbale, setNotAvailbale] = useState(false);
  const [fakeMsgArray, setFakeMsgArray] = useState([]);
  const [writtenMsg, setwrittenMsg] = useState('');

  const [responsiveForModal, setResponsiveForModal] = useState(
    window.innerWidth <= 768,
  );

  // console.log(chatCount,'chatCountchatCount')

  useEffect(() => {
    // if (localStorage.getItem('id') || localStorage.getItem('token')) {
    //   setTabtype('chat');
    // } else {
    setTabtype('form');
    // }
    company_info();
  }, []);

  const handleSubmit = () => {
    // e.preventDefault();

    const errors = {};

    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    }

    let isValidEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      formData.phoneNumber,
    );
    let isValidNumber =
      /^\+?1?\d{10}$/.test(formData.phoneNumber) || // US phone number with country code
      /^\+?92?\d{10}$/.test(formData.phoneNumber) || // Pakistan phone number with country code
      /^923\d{9}$|^03\d{9}$/.test(formData.phoneNumber) || // Pakistan phone number without country code
      /^\d{10}$/.test(formData.phoneNumber);

    if (isValidNumber || isValidEmail) {
      setFakeMsgArray(prev => [...prev, formData.phoneNumber]);
      setFormData({
        ...formData,
        phoneNumber: '',
      });
      setTimeout(() => {
        sendnvitationOutIside();
      }, 1500);
    } else {
      if (!isValidNumber) {
        errorMessage('Invalid phone number and email');
      } else {
        errorMessage('Invalid phone number and email');
      }
    }

    // if (Object.keys(errors).length > 0) {
    //   setFormErrors(errors);
    // } else {
    //   if (currentStep === 'phone') {
    //     setStep('name');
    //   } else if (currentStep === 'name') {
    //     sendnvitationOutIside();
    //   }
    // }

    // Perform form validation
  };

  const sendnvitationOutIside = () => {
    let contactName;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(formData.phoneNumber)) {
      // If it's an email, extract name part before '@'
      const match = formData.phoneNumber.match(/(.*)@/);
      if (match && match.length > 1) {
        contactName = match[1];
      }
    } else {
      // If it's not an email, assume it's a phone number
      contactName = formData.phoneNumber;
    }

    let PayloadData = {
      contact: formData.phoneNumber,
      fname: formData.name ? formData.name : contactName,
      relationship_id: '5',
      user_type: 'individual',
      company_name: props.companyName,
      email: formData.email,
      // appointment_id:'1573'
    };

    console.log(PayloadData, 'PayloadData');
    // Update PayloadData with the extracted contactName

    // setShowDateModel(true);
    commonApi
      .send_invitation_from_landingpage(PayloadData)
      .then(res => {
        if (res.success === true) {
          localStorage.setItem('token', res.user_token);
          if (res.added_contacts.length > 0) {
            setLoginId(res.added_contacts[0].id);
            localStorage.setItem('chatUserId', res.added_contacts[0].id);
            localStorage.setItem('user', res.added_contacts[0].id);
            localStorage.setItem('outsiderforlivechat', 'remove');
            localStorage.setItem('skinToneId', 1);
          }

          // successMessage(res.message)
          list_of_all_pocs_of_company();
        }
        if (res.success === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const company_info = () => {
    const PayloadData = {
      company_name: props.companyName,
    };

    commonApi
      .company_info(PayloadData)
      .then(res => {
        if (res.status === 200) {
          setCompanyDetail(res.company_detail);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (localStorage.getItem('id')) {
      list_of_all_pocs_of_company();
    }
    list_of_all_pocs_of_company();
  }, []);

  useEffect(() => {
    if (fakeMsgArray?.length > 0 && pocList?.length === 0) {
      setNotAvailbale(true);
    }
  }, [pocList]);

  const list_of_all_pocs_of_company = () => {
    let PayloadData = {
      company_name: props.companyName,
      // appointment_id:'1573'
    };
    // setShowDateModel(true);
    commonApi
      .list_of_all_pocs_of_company(PayloadData)
      .then(res => {
        if (res.success === true) {
          setPocList(res.data);
          // setTabtype('chat_list');
          setHideTabs(true);
          setFormData({
            name: '',
            phoneNumber: '',
            email: '',
          });
          if (res.data?.length === 1) {
            // getOtherUserSetting(res.data[0]?.user_id    );
            setUserInfo(res.data[0]?.user_detail);
          }

          setStep('list');
        }
        if (res.success === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleInputChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
    setFormErrors({...formErrors, [name]: ''}); // Clear validation error on input change
  };

  const styleOfHeading = {
    paddingBottom: '12px',
    textAlign: 'center',
  };

  const getOtherUserSetting = id => {
    let payLoad = {
      type: 'message',
      user_id: id,
    };
    commonApi
      .get_other_user_setting(payLoad)
      .then(response => {
        if (response.success) {
          checkChatRoom(id);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
      company_name: props.companyName,
      chat_type: 'live_chat',
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([userInfo]);
            setChatRoomName('');
            setChatRoomId('');
          }

          setTabtype('chat');
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  console.log(CompanyDetail, 'CompanyDetailCompanyDetail');

  return (
    <>
      {showQuestion && (
        <div
          className="help-text"
          style={{
            zIndex: '1004',
          }}>
          <div
            className="close-icon"
            onClick={() => {
              setShowQuestion(false);
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x">
              <line x1={18} y1={6} x2={6} y2={18} />
              <line x1={6} y1={6} x2={18} y2={18} />
            </svg>
          </div>
          <span className="material-symbols-outlined">smart_toy</span>
          <h3>👋 Do you have any Questions? we would love to help </h3>
        </div>
      )}

      <button
        className="chatbot-toggler"
        style={{
          zIndex: '999',
        }}
        onClick={() => {
          setShowQuestion(false);
          props.setShowChat(true);
        }}>
        <div className="landing_page_icon">
          <ChatIcon />
        </div>
      </button>
      <div className={props.showChat ? 'show-chatbot' : ''}>
        <div className="chatbot" style={{zIndex: '999'}}>
          <div
            className="chatbot-header"
            style={{alignItems: !userInfo?.id ? 'flex-start' : ''}}>
            {CompanyDetail?.image && (
              <>
              <span
              style={{top:'25px'}}
                className="close-btn material-symbols-outlined"
                onClick={() => {
                  props.setShowChat(false);
                }}>
                close
              </span>
                {!userInfo?.id ? (
                  <div className="new_data">
                    <div style={{paddingBottom: '11px'}}>
                      {CompanyDetail?.company_name}

                      
                    </div>
                    <div className="imges_round">
                      {pocList?.length > 0 &&
                        pocList.slice(0, 3).map(
                          (item, index) =>
                            localStorage.getItem('chatUserId') !==
                              item.user_id && (
                              <div className="avtar">
                                <img
                                  src={
                                    item?.user_detail?.profile_img
                                      ? item?.user_detail?.profile_img
                                      : DEFAULT_IMAGE
                                  }
                                  alt=""
                                />
                              </div>
                            ),
                        )}
                    </div>
                    <div class="bot-message">
                      <p>
                        We are here to take the weight off your shoulders. Tell
                        us about your challenges.
                      </p>
                    </div>
                    {userInfo?.id && tabtype === 'chat' && (
                      <span
                        className="text-right as-link"
                        style={{
                          paddingTop: '142px',
                        }}
                        onClick={() => {
                          setTabtype('form');
                        }}>
                        Reset
                      </span>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'center',
                    }}>
                    <div style={{width: '16px'}}>
                      {userInfo?.id && tabtype === 'chat' && (
                        <div>
                          <div
                            className="text-right as-link"
                            onClick={() => {
                              setTabtype('form');
                            }}>
                            <i className="fa-solid fa-arrow-left"></i>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="imges_round">
                      {pocList?.length > 0 &&
                        pocList.slice(0, 3).map(
                          (item, index) =>
                            localStorage.getItem('chatUserId') !==
                              item.user_id && (
                              <div className="avtar">
                                <img
                                  src={
                                    item?.user_detail?.profile_img
                                      ? item?.user_detail?.profile_img
                                      : DEFAULT_IMAGE
                                  }
                                  alt=""
                                />
                              </div>
                            ),
                        )}
                    </div>
                    <div>{CompanyDetail?.company_name}</div>
                  </div>
                )}
                {/* <div class="chat-container">
   
    <div class="bot-message">
        <p>We are here to help your home service business quote, schedule, invoice, and get paid—faster.</p>
    </div>
    <div class="bot-message">
        <p>Hi there! 👋 How can I help you today?</p>
    </div>
    <button class="chat-button">Speak to an expert</button>
    <button class="chat-button">Book a demo</button>
    <button class="chat-button">I want to learn how Jobber can help run my business</button>
    <button class="chat-button">Learn about Jobber Marketing Tools</button>
    <button class="chat-button">I'm a customer and need support</button>
    <div class="powered-by">
        <p>Powered by Intercom</p>
    </div>
</div> */}
              </>
            )}
          </div>
          <div className="chat-option landing-chat-option-holder">
            {tabtype === 'chat' && userInfo?.id && (
              <div
                className={
                  tabtype === 'chat'
                    ? 'chat-column new-chat active'
                    : 'chat-column new-chat'
                }>
                {/* <ul className="chatbox">
                  <li className="chat incoming">
                    <span className="material-symbols-outlined">smart_toy</span>
                    <p>
                      Hi there 👋
                      <br />
                      How can I help you today?
                    </p>
                  </li>
                  <li className="chat outgoing">
                    <p>Hi there 👋</p>
                  </li>
                </ul> */}

                <SmartLiveChatPage
                  onClose={() => {
                    setUserInfo([]);
                    setTabtype('chat_list');
                  }}
                  allmsg={true}
                  type={chatType}
                  item={[]}
                  chatroomInfo={
                    chatDetails.length > 0
                      ? chatroomInfo(chatDetails)
                      : newSingleChatroom(userInfo)
                  }
                  oppoDetails={chatDetails}
                  chatDetails={chatDetails}
                  allChatUsers={allChatUsers}
                  setAllChatUsers={setAllChatUsers}
                  chatRoomName={chatRoomName}
                  setChatRoomName={setChatRoomName}
                  chatRoomId={chatRoomId}
                  loginUserId={loginUserId}
                  setChatCount={setChatCount}
                  // onClose={() => {
                  //   setShowComponent({...showComponent, chat: false});
                  // }}
                  userData={userInfo}
                  companyName={props.companyName}
                  // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                />

                {/* <div className="chat-input">
                  <textarea
                    placeholder="Enter a message..."
                    spellCheck="false"
                    required=""
                    defaultValue={''}
                  />
                  <span id="send-btn" className="material-symbols-rounded">
                    send
                  </span>
                </div> */}
              </div>
            )}
            {tabtype === 'chat_list' && (
              <div
                className={
                  tabtype === 'chat_list'
                    ? 'chatlist-outer chat-column active'
                    : 'chatlist-outer chat-column '
                }>
                <ul className="chatlist " style={{minHeight: '90%'}}>
                  {pocList?.length > 0 ? (
                    pocList.map(
                      (item, index) =>
                        localStorage.getItem('chatUserId') !== item.user_id && (
                          <li
                            className="notify as-link"
                            key={index}
                            onClick={() => {
                              getOtherUserSetting(item.user_id);
                              setUserInfo(item.user_detail);
                            }}>
                            <span className="user-profile">
                              <img
                                src={
                                  item?.user_detail?.profile_img
                                    ? item?.user_detail?.profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </span>
                            <div className="user-details">
                              <h4>{item.poc_name}</h4>
                              <p>Hi there 👋 How can I help you today?</p>
                            </div>
                          </li>
                        ),
                    )
                  ) : (
                    <p className="text-center">
                      POCs are not available right now{' '}
                    </p>
                  )}
                </ul>
              </div>
            )}
            {tabtype === 'form' && (
              <div
                className={
                  tabtype === 'form'
                    ? 'chatlist-outer chat-column active'
                    : 'chatlist-outer chat-column '
                }>
                <ul className="chatlist messages-wrapper fix-width-forms ">
                  <div className="primary_panel_content">
                    <div className="refferal-list-wrapper">
                      <Scrollbars autoHide>
                        <div class="message-item new-msg-item ">
                          <div
                            class="msg-container"
                            style={{width: '90%', backgroundColor: '#f7f7f7'}}>
                            <div class="msg-content">
                              <div class="msg-content-left">
                                <div class="msg-usr-img roi_msg_logo">
                                  {/* <img src={CompanyDetail?.image} alt="" /> */}
                                  <img
                                    src={
                                      props.companyName === 'ROI'
                                        ? 'https://build-dev.s3.us-east-1.amazonaws.com/logo_roi.png'
                                        : CompanyDetail?.company_logo
                                        ? CompanyDetail?.company_logo
                                        : DEFAULT_IMAGE_COMPANYLOGO
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div class="msg-content-right">
                                <div class="msg-content-text">
                                  <p>
                                    {localStorage.getItem('token')
                                      ? 'Hi there! 👋 How can I help you today?'
                                      : ' Hi how can we help you? Get started please share you phone number or email.'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {fakeMsgArray.map((item, index) => (
                          <div className="message-item new-msg-item reply-message">
                            <div
                              className="msg-container"
                              style={{
                                width: '90%',
                                backgroundColor: '#E0F7E0;',
                              }}>
                              <div
                                className="msg-content-right"
                                style={{
                                  justifyContent: 'flex-start',
                                }}>
                                <div className="msg-content-text">
                                  <p>{item}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="ek-div">
                          {(fakeMsgArray?.length > 0 ||
                            localStorage.getItem('token')) &&
                          pocList?.length > 0
                            ? pocList.map(
                                (item, index) =>
                                  localStorage.getItem('chatUserId') !==
                                    item.user_id && (
                                    <div className="message-item as-link">
                                      <div
                                        className="msg-container-23"
                                        onClick={() => {
                                          getOtherUserSetting(item.user_id);
                                          setUserInfo(item.user_detail);
                                        }}
                                        style={{
                                          backgroundColor: '#E0F7E0',
                                        }}>
                                        <div className="msg-content-text">
                                          <p>{item.alias}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                              )
                            : fakeMsgArray?.length > 0 &&
                              pocList?.length === 0 &&
                              notAvailbale && (
                                <div class="message-item new-msg-item ">
                                  <div
                                    class="msg-container"
                                    style={{
                                      width: '90%',
                                      backgroundColor: '#f7f7f7',
                                    }}>
                                    <div class="msg-content">
                                      <div class="msg-content-left">
                                        <div class="msg-usr-img roi_msg_logo">
                                          {/* <img src={CompanyDetail?.image} alt="" /> */}
                                          <img
                                            src={
                                              props.companyName === 'ROI'
                                                ? 'https://build-dev.s3.us-east-1.amazonaws.com/logo_roi.png'
                                                : CompanyDetail?.company_logo
                                                ? CompanyDetail?.company_logo
                                                : DEFAULT_IMAGE_COMPANYLOGO
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div class="msg-content-right">
                                        <div class="msg-content-text">
                                          <p>
                                            POCs are not available right now
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>

                  {/* <form onSubmit={handleSubmit}>
                    <div style={{padding: '6px'}}>
                   
                      <h1 style={styleOfHeading}>Contact Info</h1>

                      <p>We will not sell or share your details</p>

                      {currentStep === 'name' && (
                        <div className="form-group mt-2">
                          <Form.Label
                            style={{
                              color: '#272727',
                              fontSize: '15px',
                              fontWeight: '400',
                            }}>
                            Do you want to use your number/email as your name or
                            add your name
                          </Form.Label>
                          <input
                            type="text"
                            name="name"
                           
                            placeholder="Name (Optional)"
                            value={formData.name}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}

                      {currentStep === 'phone' && (
                        <div className="form-group">
                       
                          <input
                            type="text"
                            name="phoneNumber"
                            className={`form-control ${
                              formErrors.phoneNumber && 'is-invalid'
                            }`}
                            placeholder="Phone Number or Email"
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.phoneNumber}
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <button
                        className="text-white px-6 py-3 rounded-md"
                        style={{
                          borderRadius: '5px',
                          background: '#78B054',
                        }}>
                        {currentStep === 'phone' ? 'Next' : 'Submit'}
                      </button>
                    </div>
                  </form> */}
                  {fakeMsgArray.length === 0 &&
                    !localStorage.getItem('token') && (
                      <div
                        className="message-form message-chat-landing-form"
                        style={{
                          background: 'white',
                          margin: '0 0 68px 0px',
                          padding: '9px 0 0 0',
                        }}>
                        <input
                          style={{display: 'none'}}
                          // ref={inputRef}
                          type="file"
                          name="myImage"
                        />
                        <Form.Group className="form-input">
                          <ReactTextareaAutosize
                            autoFocus
                            // focusTextArea
                            maxRows={4}
                            minRows={1}
                            name="message"
                            value={formData.phoneNumber}
                            className="form-control"
                            onChange={e => {
                              // setwrittenMsg(e.target.value);

                              // e.preventDefault();
                              setFormData({
                                ...formData,
                                phoneNumber: e.target.value,
                              });
                            }}
                            placeholder="Type your message here"
                            // ref={focusTextArea}
                          />
                        </Form.Group>
                        <span
                          onClick={() => {
                            if (
                              formData.phoneNumber !== '' &&
                              fakeMsgArray.length === 0
                            ) {
                              handleSubmit();
                            } else {
                              if (fakeMsgArray.length > 0) {
                              } else {
                                errorMessage(
                                  'please write some thing for chat',
                                );
                              }
                            }
                          }}
                          className="form-icon send-btn">
                          <i className="icon-plane" />
                        </span>
                      </div>
                    )}
                </ul>
              </div>
            )}
            {tabtype === 'help' && (
              <div
                className={
                  tabtype === 'help'
                    ? 'chatgroup-outer chat-column active'
                    : 'chatgroup-outer chat-column'
                }>
                <h3>7 collections</h3>
                <ul className="chatgroup">
                  <li className="notify">
                    <div className="notification">
                      <h4>Getting Started</h4>
                      <p>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet
                      </p>
                    </div>
                    <div className="arrow-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none">
                        <path
                          d="M5.42773 4.70898C5.46387 4.85254 5.53809 4.98828 5.65039 5.10059L8.54932 8L5.64893 10.9004C5.31689 11.2324 5.31689 11.7705 5.64893 12.1025C5.98096 12.4336 6.51904 12.4336 6.85107 12.1025L10.3516 8.60059C10.5591 8.39355 10.6367 8.10449 10.585 7.83691C10.5537 7.67578 10.4761 7.52246 10.3516 7.39844L6.85254 3.89941C6.52051 3.56738 5.98242 3.56738 5.65039 3.89941C5.43066 4.11816 5.35645 4.42871 5.42773 4.70898Z"
                          fill="#000000"
                        />
                      </svg>
                    </div>
                  </li>
                  <li className="notify">
                    <div className="notification">
                      <h4>Managing Subscription and Billing</h4>
                      <p>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet
                      </p>
                    </div>
                    <div className="arrow-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none">
                        <path
                          d="M5.42773 4.70898C5.46387 4.85254 5.53809 4.98828 5.65039 5.10059L8.54932 8L5.64893 10.9004C5.31689 11.2324 5.31689 11.7705 5.64893 12.1025C5.98096 12.4336 6.51904 12.4336 6.85107 12.1025L10.3516 8.60059C10.5591 8.39355 10.6367 8.10449 10.585 7.83691C10.5537 7.67578 10.4761 7.52246 10.3516 7.39844L6.85254 3.89941C6.52051 3.56738 5.98242 3.56738 5.65039 3.89941C5.43066 4.11816 5.35645 4.42871 5.42773 4.70898Z"
                          fill="#000000"
                        />
                      </svg>
                    </div>
                  </li>
                  <li className="notify">
                    <div className="notification">
                      <h4>Managing Account</h4>
                      <p>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet
                      </p>
                    </div>
                    <div className="arrow-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none">
                        <path
                          d="M5.42773 4.70898C5.46387 4.85254 5.53809 4.98828 5.65039 5.10059L8.54932 8L5.64893 10.9004C5.31689 11.2324 5.31689 11.7705 5.64893 12.1025C5.98096 12.4336 6.51904 12.4336 6.85107 12.1025L10.3516 8.60059C10.5591 8.39355 10.6367 8.10449 10.585 7.83691C10.5537 7.67578 10.4761 7.52246 10.3516 7.39844L6.85254 3.89941C6.52051 3.56738 5.98242 3.56738 5.65039 3.89941C5.43066 4.11816 5.35645 4.42871 5.42773 4.70898Z"
                          fill="#000000"
                        />
                      </svg>
                    </div>
                  </li>
                  <li className="notify">
                    <div className="notification">
                      <h4>Administering Server and Website</h4>
                      <p>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet
                      </p>
                    </div>
                    <div className="arrow-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none">
                        <path
                          d="M5.42773 4.70898C5.46387 4.85254 5.53809 4.98828 5.65039 5.10059L8.54932 8L5.64893 10.9004C5.31689 11.2324 5.31689 11.7705 5.64893 12.1025C5.98096 12.4336 6.51904 12.4336 6.85107 12.1025L10.3516 8.60059C10.5591 8.39355 10.6367 8.10449 10.585 7.83691C10.5537 7.67578 10.4761 7.52246 10.3516 7.39844L6.85254 3.89941C6.52051 3.56738 5.98242 3.56738 5.65039 3.89941C5.43066 4.11816 5.35645 4.42871 5.42773 4.70898Z"
                          fill="#000000"
                        />
                      </svg>
                    </div>
                  </li>
                  <li className="notify">
                    <div className="notification">
                      <h4>Troubleshooting Server and Application</h4>
                      <p>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet
                      </p>
                    </div>
                    <div className="arrow-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none">
                        <path
                          d="M5.42773 4.70898C5.46387 4.85254 5.53809 4.98828 5.65039 5.10059L8.54932 8L5.64893 10.9004C5.31689 11.2324 5.31689 11.7705 5.64893 12.1025C5.98096 12.4336 6.51904 12.4336 6.85107 12.1025L10.3516 8.60059C10.5591 8.39355 10.6367 8.10449 10.585 7.83691C10.5537 7.67578 10.4761 7.52246 10.3516 7.39844L6.85254 3.89941C6.52051 3.56738 5.98242 3.56738 5.65039 3.89941C5.43066 4.11816 5.35645 4.42871 5.42773 4.70898Z"
                          fill="currentcolor"
                        />
                      </svg>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {/* {tabtype !== 'form' &&  !hideTabs &&( */}
          <div className="chatbot-footer">
            {/* {currentStep ==='list' && ( */}
            <ul>
              {/* <li
                className={tabtype === 'chat_list' ? 'active' : ''}
                onClick={() => {
                  if (localStorage.getItem('token')) {
                    setTabtype('chat_list');
                  } else {
                    errorMessage('Please fill the form first');
                  }
                }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Bold"
                  fill="currentcolor"
                  viewBox="0 0 24 24"
                  width={16}
                  height={16}>
                  <path d="M8,7H22.5a1.5,1.5,0,0,0,0-3H8A1.5,1.5,0,0,0,8,7Z" />
                  <path d="M22.5,11H8a1.5,1.5,0,0,0,0,3H22.5a1.5,1.5,0,0,0,0-3Z" />
                  <path d="M22.5,18H8a1.5,1.5,0,0,0,0,3H22.5a1.5,1.5,0,0,0,0-3Z" />
                  <circle cx="2.5" cy="5.5" r="2.5" />
                  <circle cx="2.5" cy={12} r="2.5" />
                  <circle cx="2.5" cy={19} r="2.5" />
                </svg>
                <span>Home</span>
              </li> */}
              {/* {userInfo?.id && */}
              <li
                className={
                  tabtype === 'chat' || tabtype === 'form' ? 'active' : ''
                }
                onClick={() => {
                  if (localStorage.getItem('token') && userInfo?.id) {
                    setTabtype('chat');
                  } else {
                    if (localStorage.getItem('token') && !userInfo?.id) {
                      errorMessage('No user exist for chat');
                    } else {
                      setTabtype('form');
                    }
                  }
                }}>
                <svg
                  style={{fontSize: '20px'}}
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24">
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 9h8m-8 4h6m4-9a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-5l-5 3v-3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3z"
                  />
                </svg>
                <span>Messages</span>
              </li>

              {/* } */}

              {/* {userInfo?.id &&  */}
              <li
                className={tabtype === 'help' ? 'active' : ''}
                onClick={() => {
                  setTabtype('help');
                }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-help-circle">
                  <circle cx={12} cy={12} r={10} />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <line x1={12} y1={17} x2="12.01" y2={17} />
                </svg>
                <span>News</span>
              </li>

              {/* } */}
            </ul>
            {/* )} */}
          </div>
          {/* )} */}
        </div>
      </div>

      <style
        dangerouslySetInnerHTML={{
          __html: `
      //chat css

::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); background-color: va  r(--green);}
::-webkit-scrollbar{width: 6px; background: transparent; }
::-webkit-scrollbar-thumb{background-color:transparent;}

.help-text {position: fixed; bottom: 110px; width: 221px; text-align: left; right: 50px; background: #fff; padding: 15px; border-radius: 0; padding-top: 30px; box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); border-top: 1px solid rgba(0, 0, 0, 0.1); }
.help-text:after {content: ' '; position: absolute; width: 0; height: 0; left: auto; right: 30px; bottom: -19px; border: 10px solid; border-color: #fff #fff transparent transparent; filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, .1)); }
.help-text span {background: var(--green); border: 2px solid #52bd41; color: white; position: absolute; top: -25px; left: 0; right: 0; width: 48px; height: 48px; display: flex; align-items: center; justify-content: center; margin: 0 auto; border-radius: 50px; }
.help-text h3 {font-size: 12px; font-weight: normal; color: #5a5a5a; }
.close-icon {position: absolute; top: 10px; right: 10px; color: #8b98a7; cursor: pointer; }
.close-icon svg {display: block; }


.show-chatbot .chatbot-toggler h3{opacity: 0;visibility: hidden;}
.chat-column{display: none;}
.chat-column.active{    display: flex;
  flex-direction: column;
  
  flex: 1;}
.chatbot-toggler {position: fixed; bottom: 30px; right: 50px; outline: none; border: none; height: 75px; width: 75px; display: flex; cursor: pointer; align-items: center; justify-content: center; border-radius: 50%; background: #272727; transition: all 0.2s ease; }
/*.show-chatbot .chatbot-toggler {transform: rotate(90deg);}*/
.chatbot-toggler span {color: white; position: absolute;}
.chatbot-toggler span:last-child,
.show-chatbot .chatbot-toggler span:first-child  {opacity: 0;}
.show-chatbot .chatbot-toggler span:last-child {opacity: 1; }
.chatbot {position: fixed; right: 35px; bottom: 30px; max-width: 375px; width:100%; background: #fff; border-radius: 15px; overflow: hidden; height:540px; opacity: 0; pointer-events: none; transform: scale(0.5); transform-origin: bottom right; box-shadow: 0 0 128px 0 rgba(0,0,0,0.1), 0 32px 64px -48px rgba(0,0,0,0.5); transition: all 0.1s ease; display: flex;
  flex-direction: column; }
.show-chatbot .chatbot {opacity: 1; pointer-events: auto; transform: scale(1);}
.chatbot .chatbot-header {padding: 12px; position: relative; text-align: center; color: #fff;   background-color: #0A1B23; box-shadow: 0 2px 10px rgba(0,0,0,0.1); display: flex; align-items: center; gap: 0px;
  justify-content:space-between;
}
.online {width: 10px; height: 10px; background: #00d700; display: block; position: absolute; z-index: 9; right: 0; bottom: 0; border-radius: 50px;}
.avtar { position: relative; }
.avtar img{width: 43px; height: 43px;  border-radius: 50px; object-fit: cover;}
.chatbot .chatbot-header span {position: absolute; right: 15px; top: 50%; /*display: none;*/ cursor: pointer; transform: translateY(-50%);}
.chatbot-header h2 {font-size: 1.3rem; font-weight: 600; }
.chatlist,
.chatbot .chatbox {overflow-y: auto; height: 422px;padding:20px;list-style: none;}
.chatgroup{overflow-y: auto; height: 360px;list-style: none;}

.chatbot .chatbox { padding-bottom: 80px;}
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar {width: 6px; }
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-track {background: #fff; border-radius: 25px; }
.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-thumb {background: #ccc; border-radius: 25px;}
.chatbox .chat {display: flex; list-style: none; }
.chatbox .outgoing {margin: 15px 0; justify-content: flex-end; }
.chatbox .incoming span {width: 32px; height: 32px; color: #fff; cursor: default; text-align: center; line-height: 32px; align-self: flex-end; background: var(--green); border-radius: 4px; margin: 0 10px 7px 0; }
.chatbox .chat p {white-space: pre-wrap; padding: 6px 8px; border-radius: 10px 10px 0 10px; max-width: 75%; color: #fff; font-size: 0.80rem; background: var(--green);}
.chatbox .incoming p {border-radius: 10px 10px 10px 0; }
.chatbox .chat p.error {color: #721c24; background: #f8d7da; }
.chatbox .incoming p {color: #000; background: #f2f2f2; }
.new-chat {position: relative;}
.chatbot .chat-input {display: flex; bottom: 0; position: absolute; width: 100%; background: #fff; padding: 3px 20px; border-top: 1px solid #ddd; }
.chat-input textarea {height: 55px;width: 100%;     max-height: 180px;border: none; outline: none; resize: none; padding: 8px 10px 8px 0; font-size: 0.80rem;}
.chat-input span {color: var(--green); cursor: pointer; display: flex; align-items: center; visibility: hidden; font-size: 1.35rem;}
.chat-input textarea:valid ~ span {visibility: visible; }
.chatbot-footer{background: #f0ebff; padding: 0 15px; border-top: 1px solid#ddd; position: absolute; left: 0; right: 0; bottom: 0;}
.chatbot-footer ul {display: flex; align-items: center; justify-content: space-evenly; list-style: none; padding: 0; margin: 0;}
.chatbot-footer li {font-size: 0.95em; display: flex; padding: 15px 0; align-items: center; gap: 6px; position: relative; cursor: pointer;
  flex-direction: column}

.chatbot-footer li:hover,
.chatbot-footer li.active{color: var(--green);}
.chatbot-footer li:before {content: ""; position: absolute; bottom: 0px; left: 0; right: 0; transform: scale(0); height: 2px; opacity: 0; visibility: hidden; transition: all 0.4s ease-in-out; background: var(--green); }
.chatbot-footer li.active:before,
.chatbot-footer li:hover:before {opacity: 1; visibility:visible; transform: scale(1);}

/*  ueser list  */
.user-profile img{max-width: 40px; display: block; height: 40px; border-radius: 50px; overflow: hidden; border: 2px solid #fff;}
.chatgroup .notify,
.chatlist .notify {display: flex; align-items: center; gap: 15px; padding: 10px; background: #2196f31c; border-radius: 8px; margin-bottom: 12px;}
.user-details h4 {font-size: 14px;}
.user-details p {font-size: 13px;}

.chatgroup .notify {position: relative; background: transparent; border-bottom: 1px solid rgba(0, 0, 0, 0.06); margin: 0; padding: 16px 20px; cursor: pointer; border-radius: 0; transition: all 0.4s ease-in-out;}
.chatgroup .notify:hover {background: rgba(47, 57, 191, 0.1);}
.chatgroup-outer > h3 {padding: 16px 20px; border-bottom: 1px solid rgba(0, 0, 0, 0.06); line-height: 24px; color: rgb(26, 26, 26); font-weight: 500; font-size: 16px; }
.chatgroup .notify h4 {font-size: 14px; line-height: 21px; margin-bottom: 4px; font-weight: 600;}
.chatgroup .notify p{font-size: 14px; line-height: 21px; margin-bottom: 4px; word-break: break-word; color: rgb(26, 26, 26);}
.chat-option{
  flex: 1;
  display: flex;
}
.chatlist {        display: flex;
  flex-direction: column;
 }


@media (max-width: 490px) {
  .help-text {right: 20px;bottom: 160px;}
  .chatbot-toggler {right: 20px; bottom: 80px;}
  .chatbot {right: 0; bottom: 0; height: 100%; border-radius: 0; max-width: 100%;}
  .chatbot .chatbox {height:82vh; padding: 25px 15px 100px;}
  .chatgroup, .chatlist, .chatbot .chatbox {height: calc(100vh - 234px)!important;}
  .chatlist {        display: flex;
    flex-direction: column;
   }
  .chatgroup{height: calc(100vh - 180px)}
  .chatbot .chat-input {padding: 5px 15px;}
  .chatbot .chatbot-header span {display: block;}

}


.chat-container {
  background-color: #002E3B; /* Dark background color */
  color: #FFFFFF; /* White text color for contrast */
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  font-family: Arial, sans-serif;
}

/* Header with the app name and user images */
.chat-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.chat-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-header h1 {
  font-size: 20px;
  margin: 0;
}




/* Chat message from the bot */
.bot-message {

  color: #FFFFFF;
  // padding: 15px;

  padding: 15px 3px 15px;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 14px;
  
}

/* Buttons with light green background */
.chat-button {
  background-color: #E0F7E0; /* Light green background */
  color: #000; /* Black text color */
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-button:hover {
  background-color: #D0E7D0; /* Slightly darker green on hover */
}

.new_data {
  display: flex;
  flex-direction: column;
}
.imges_round {
  display: flex;
    align-items: center;
    justify-content: center;
}
.chatbot .chatbot-header .imges_round .avtar:nth-child(2) {
  mask: radial-gradient(circle at -17px center, transparent 21px, white 22px);
  margin-left: -2px;
}
.chatbot .chatbot-header .imges_round .avtar:nth-child(3) {
  mask: radial-gradient(circle at -17px center, transparent 21px, white 22px);
  margin-left: -2px;
}




// .chat-column.active{

//   height: calc(100vh - 297px) ;

// }
.ek-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 66px;
}
.ek-div .message-item {
  margin-bottom: 0px;
}
.ek-div .message-item .msg-container-23 {
  margin-bottom: 0px !important;
}

.message-item .msg-container-23 {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
  padding: 15px 15px;
  border-radius: 20px;
  font-size: 15px;
  color: var(--dark);

  margin: 0 0 10px;
}


`,
        }}
      />
    </>
  );
};

export default LandingPageChat;
