import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {generateId} from '../Utils/Helpers';
import {getItem} from '../Utils/LocalStorage';
import {errorMessageGuest} from '../Utils/ToastMessages';
import {selected_lang} from '../Utils/env';
import {labels} from '../Constants/Translations';
// import {Segmented, SegmentedButton, Block} from 'konsta/react';

const CustomTabs = ({
  tabs,
  active,
  onSelect,
  titleLabel,
  windowWidth,
  darkmode = false,
  link,
}) => {
  const [activeTab, setActive] = useState('');
  const [tabId, setTabId] = useState('');
  const [handleWidth, setHandleWidth] = useState(0);
  const [handlePos, setHandlePos] = useState(0);

  const calculateHandlePos = index => {
    let leftPos = 0;
    const button = document.getElementById(`${tabId}-${index}`);
    const buttonWidth = button ? button.offsetWidth : 0;
    setHandleWidth(buttonWidth);

    if (index !== 0) {
      for (let i = 0; i < index; i++) {
        const otherItem = document.getElementById(`${tabId}-${i}`);
        leftPos += otherItem.offsetWidth;
      }
    }

    setHandlePos(leftPos);
  };

  const setActiveTab = (tab, index) => {
    if (windowWidth > 767) {
      calculateHandlePos(index);
    }

    setActive(tab);
    onSelect(tab);
  };

  const getActiveTabIndex = tab => {
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i] === tab) {
        return i;
      }
    }
  };

  useEffect(() => {
    const tabId = generateId();
    setTabId(tabId);

    setTimeout(() => {
      const index = getActiveTabIndex(activeTab);
      setActiveTab(activeTab, index);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.entries(tabs).length > 0 && active !== '') {
      const tabIndex = getActiveTabIndex(active);
      setActiveTab(active, tabIndex);
    } else if (Object.entries(tabs).length > 0 && !active) {
      setActiveTab(tabs[0], 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <>
      {Object.entries(tabs).length > 0 && (
        <>
          {windowWidth < 768 && (
            <div className="tab-select">
              <div className="select-wrapper">
                <select
                  className="form-control"
                  defaultValue={activeTab}
                  onChange={e => {
                    const thisVal = e.target.value;
                    let curIndex = '';
                    for (let i = 0; i < tabs.length; i++) {
                      const currentItem = titleLabel
                        ? tabs[i][titleLabel]
                        : tabs[i];
                      if (thisVal === currentItem) {
                        curIndex = i;
                      }
                    }

                    setActiveTab(e.target.value, curIndex);
                  }}>
                  {tabs.map((item, index) => {
                    const currentItem = titleLabel ? item[titleLabel] : item;

                    return (
                      <option key={`tab-item-${index}`} value={currentItem}>
                        {currentItem}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          {windowWidth > 768 && link ? (
            <div
              id={tabId}
              className={`custom-tabs event-tabs ${
                darkmode ? 'dark-tabs' : ''
              }`}>
              <span
                className="activeHandle"
                style={{width: handleWidth, left: handlePos}}
              />
              <ul>
                {tabs.map((item, index) => {
                  const isActive =
                    titleLabel && item[titleLabel] === activeTab
                      ? 'active'
                      : !titleLabel && item === activeTab
                      ? 'active'
                      : '';
                  return (
                    <li key={`tab-item-${index}`}>
                      <Button
                        id={`${tabId}-${index}`}
                        className={isActive}
                        onClick={e => {
                          const currentItem = titleLabel
                            ? item[titleLabel]
                            : item;
                          if (
                            getItem('userRole') === 'guest' &&
                            currentItem !== 'Snapshots'
                          ) {
                            errorMessageGuest(
                              labels.errorMessage_for_uninvite_User[
                                selected_lang
                              ],
                            );
                          } else {
                            setActiveTab(currentItem, index);
                          }
                        }}>
                        {titleLabel && item[titleLabel]}
                        {!titleLabel && item}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            windowWidth > 768 && (
              <div className="custom_animated_tabs d-flex align-items-center justify-content-center">
                {Object.entries(tabs).length > 0 && (
                  <div
                    id={tabId}
                    className={`custom-tabs event-tabs ${
                      darkmode ? 'dark-tabs' : ''
                    }`}>
                    <span
                      className="activeHandle"
                      style={{width: handleWidth, left: handlePos}}
                    />
                    <ul>
                      {tabs.map((item, index) => {
                        const isActive =
                          titleLabel && item[titleLabel] === activeTab
                            ? 'active'
                            : !titleLabel && item === activeTab
                            ? 'active'
                            : '';
                        return (
                          <li key={`tab-item-${index}`}>
                            <Button
                              id={`${tabId}-${index}`}
                              className={isActive}
                              onClick={e => {
                                const currentItem = titleLabel
                                  ? item[titleLabel]
                                  : item;
                                setActiveTab(currentItem, index);
                              }}>
                              {titleLabel && item[titleLabel]}
                              {!titleLabel && item}
                            </Button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(CustomTabs);
