import React from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import {useState} from 'react';
import {Pannels} from '../../Components';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import CustomSlidingButtons from '../../Components/CustomSlidingButtons';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Scrollbars from 'react-custom-scrollbars';
import Slider from 'react-slick';
import {rewardStar} from '../../Constants/Images';

const OKRs = props => {
  const [threeDots, setThreeDots] = useState(false);
  const [chatSearchQry, setChatSearchQry] = useState('');
  const [tabType, setTabType] = useState('leads');
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);

  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const listForDropDown = [
    // {title: 'All', id: '1'},
    {title: 'Overall', id: '1'},
    {title: 'Sales', id: '2'},
    {title: 'Customer Service', id: '3'},
    {title: 'Anything', id: '4'},
    {title: 'Anything', id: '5'},
    {title: 'Anything', id: '6'},
    {title: 'Anything', id: '7'},
  ];
  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);
  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);
  };

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Completion ',
      money: '44%',
      time: 'versus last month',
      svgIcon: '',
    },

    {
      name: 'Change Requests',
      money: '22',
      time: 'versus last month',
      svgIcon: '',
      icon: 'fa-solid fa-star',
    },

    {
      name: 'Rollover',
      money: '22%',
      time: 'versus last month',
      svgIcon: '',
    },
    {
      name: 'Referrals ',
      money: '42',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
        />

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="okrs-page primary_panel_content">
                    <div className="messages-header mb-2">
                      {/* <div className="event-tabs dark-tabs"> */}
                      <div className="event-tabs tabs-style-2 mb-2">
                        {' '}
                        {/* Merging */}
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'leads' ? 'active' : ''}
                              onClick={() => {
                                // setGalleryType('chats');
                                setTabType('leads');
                                // setShowSpinner(true);
                              }}>
                              OKRs
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        <span
                          className={
                            // secondComponent === 'group-component'
                            //   ? 'icon active'
                            //   :
                            'icon'
                          }>
                          <i
                            className="icon-plus"
                            // onClick={() =>
                            //   setSecondComponent('group-component')
                            // }
                          />
                        </span>
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    {threeDots && (
                      <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                        <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Who are you looking for?"
                              value={chatSearchQry}
                              onChange={e => {
                                setChatSearchQry(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Button>
                            <i
                              className="icon-filter-icon"
                              // onClick={() => setSearchFilterModal(true)}
                            />
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                      <CustomSlidingButtons
                        items={listForDropDown}
                        activeButtons={areas_Filter}
                        onItemClick={e => {
                          dropDownSelection(e);
                        }}
                        showItems={2}
                      />
                    </div>

                    <div className="refferal-list-wrapper">
                      <Scrollbars
                        className="custom-scroll referral-cards custom-refferal-vertical-scrollbar"
                        autoHide>
                        <div className="okrs-content">
                          <div className="okrs-card-main mb-2">
                            <div className="okrs-card">
                              <h1>Make $50K / month</h1>

                              <div className="okrs-progress-bar warning-bar">
                                <div>Consider partnering with</div>
                                <ProgressBar
                                  className="okr-bar"
                                  variant="warning"
                                  now={40}
                                />
                              </div>

                              <div className="okrs-progress-bar success-bar">
                                <div>Consider partnering with</div>
                                <ProgressBar
                                  className="okr-bar"
                                  variant="warning"
                                  now={60}
                                />
                              </div>

                              <div className="okrs-progress-bar success-bar">
                                <div>Consider partnering with</div>
                                <ProgressBar
                                  className="okr-bar"
                                  variant="warning"
                                  now={75}
                                />
                              </div>
                            </div>

                            <div className="card-bottom">
                              <span>View More</span>

                              <span className="okr-percentage">65%</span>
                            </div>
                          </div>

                          <div className="okrs-card-main mb-2">
                            <div className="okrs-card">
                              <h1>Make $50K / month</h1>

                              <div className="okrs-progress-bar warning-bar">
                                <div>Consider partnering with</div>
                                <ProgressBar
                                  className="okr-bar"
                                  variant="warning"
                                  now={40}
                                />
                              </div>

                              <div className="okrs-progress-bar success-bar">
                                <div>Consider partnering with</div>
                                <ProgressBar
                                  className="okr-bar"
                                  variant="warning"
                                  now={60}
                                />
                              </div>

                              <div className="okrs-progress-bar success-bar">
                                <div>Consider partnering with</div>
                                <ProgressBar
                                  className="okr-bar"
                                  variant="warning"
                                  now={75}
                                />
                              </div>
                            </div>

                            <div className="card-bottom">
                              <span>View More</span>

                              <span className="okr-percentage">65%</span>
                            </div>
                          </div>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OKRs;
