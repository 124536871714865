import amplitude from 'amplitude-js';
// import { appendZerosToUserId } from '../Utils/commonUtils';
// initialize the client

// var instance1 = amplitude
//   .getInstance()
//   .init('289c0a12b81fb925979540a5bd5362a1', appendZerosToUserId(localStorage.getItem('user')));

export const amplitudeEvent = type => {
  amplitude.getInstance().logEvent(type);
};

export const amplitudeEventProps = (type, props) => {
  amplitude.getInstance().logEvent(type, props);
};
