"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PromisedCall {
    constructor(resolve, reject, fn) {
        this.resolve = resolve;
        this.reject = reject;
        this.fn = fn;
    }
}
/** @ignore */
class AsyncCallQueue {
    constructor(barrier) {
        this._barrier = null;
        this._queue = new Array();
        this._running = false;
        this._barrier = barrier;
    }
    start() {
        this._interval = setInterval(this.run.bind(this), 100);
    }
    stop() {
        if (this._interval) {
            clearInterval(this._interval);
        }
    }
    run() {
        if (this._queue.length > 0 && this._barrier() && !this._running) {
            this._running = true;
            const promiseCall = this._queue.shift();
            promiseCall
                .fn()
                .then((r) => {
                promiseCall.resolve(r);
                this._running = false;
            })
                .catch((e) => {
                promiseCall.reject(e);
                this._running = false;
            });
        }
    }
    push(object, fn, ...args) {
        return new Promise((resolve, reject) => {
            const p = new PromisedCall(resolve, reject, fn.bind(object, ...args));
            this._queue.push(p);
        });
    }
}
exports.default = AsyncCallQueue;
