import commonApi from '../../Services/CommonService';

export const getDispatch = (type, data) => ({
  type: type,
  payload: data,
});

export const getDispatchPayload = (type, data, payload) => ({
  type: type,
  payload: data,
  prevPayload: payload,
});

export const getOpportunityListing = data => {
  return async dispatch => {
    const response = await commonApi.get_opportunity(data);
    if (data.page > 1) {
      dispatch(getDispatch('OPPORTUNITY/GET_DATA_PAGE', response));
    } else {
      dispatch(getDispatchPayload('OPPORTUNITY/GET_DATA', response, data));
    }
    return response;
  };
};

export const getDispatchPayloadprofile = (type, data, payload) => ({
  type: type,
  payload: data,
  prevPayload: payload,
})
export const getOpportunityListingProfile = data => {
  return async dispatch => {
    const response = await commonApi.get_user_available_opportunities(data);
    // if (data.page > 1) {
    //   dispatch(getDispatch('OPPORTUNITY/GET_DATA_PAGE', response));
    // } else {
      dispatch(getDispatchPayloadprofile('OPPORTUNITY/GET_DATA2', response, data));
    // }
    return response;
  };
};

export const getQuestionListing = data => {
  return async dispatch => {
    const response = await commonApi.get_questions_list(data);
    if (data.page > 1) {
      dispatch(getDispatch('QUESTION/GET_DATA_PAGE', response));
    } else {
      dispatch(getDispatchPayload('QUESTION/GET_DATA', response, data));
    }
    return response;
  };
};

export const getOpportunityChatDetails = data => {
  return getDispatch('OPPORTUNITY/CHAT', data);
};

export const callOpportunityApi = () => {
  return {
    type: 'OPPORTUNITY/API',
  };
};

export const callOnBoardingApi = data => {
  return {
    type: 'ONBOARDING/API',
    payload: data,
  };
};

export const getUserOpportunityList = data => {
  return async dispatch => {
    const response = await commonApi.get_opportunity_by_user_id(data);
    dispatch(getDispatch('GET_USER_OPPORTUNITY_LIST', response));
    return response;
  };
};

export const getUserCompanyRecruitment = data => {
  return async dispatch => {
    const response = await commonApi.get_companyuser_opportunity(data);
    dispatch(getDispatch('GET_COMPANY_RECRUITMENT_LIST', response.company_detail.opportunities));
    return response;
  };
};
