
export const companyTransformationList = (
    state = [],
    action,
  ) => {
    switch (action.type) {
      case 'GET_COMPANY_TRANSFORMATION_LIST':
        return action.payload;
      default:
        return state;
    }
  };