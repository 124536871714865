import React, { useEffect } from "react";
import { LightBox } from "react-lightbox-pack";
import "react-lightbox-pack/dist/index.css";

const ImageLightbox = ({ images, show, current, onClose }) => {
    const [toggle, setToggle] = React.useState(show);
    const [sIndex, setSIndex] = React.useState(0);

 

    useEffect(() => {
        setToggle(show);
    }, [show]);

    useEffect(() => {
        setSIndex(current);
    }, [current]);

    // Handler
    const lightBoxHandler = (state, sIndex) => {
        if (!state) {
            onClose();
        }
        setSIndex(sIndex);
        setToggle(state);
    };

    return (
        <LightBox
            state={toggle}
            event={lightBoxHandler}
            data={images}
            imageWidth="60vw"
            imageHeight="70vh"
            thumbnailHeight={50}
            thumbnailWidth={50}
            setImageIndex={setSIndex}
            imageIndex={sIndex}
        />
    );
};

export default ImageLightbox;
