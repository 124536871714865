// src/components/ChatInterface.js

import React, {useState, useRef} from 'react';
import {Container, Row, Col, Button, Form, InputGroup} from 'react-bootstrap';
import StarsSvg from '../../Svg/StarsSvg';
import {SlidingPanel} from '../../Components';
import DoubleCam from '../../Svg/DoubleCam';
import {ShareablesBlackIcon} from '../../Constants/Images';
import CopySvg from '../../Svg/CopySvg';

const DetailBuildGpt = ({onClose}) => {
  const [title, setTitle] = useState('');

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          onClose();
        }}>
        <div className="serach-filter-wrapper create-sessionEvents gpt_detail_screen">
          <div className="create-ticket-form fix-width-forms">
            <div className="gpt_detail_header"></div>

            <div className="chat_title_input mb-3 ">
              <div className="roi-form-input">
                <Form.Control
                  type="text"
                  value={title}
                  pattern="^\S.*$"
                  required
                  placeholder="Create a title for this chat"
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Title is required.
                </Form.Control.Feedback>
              </div>

              <div className="input_icon">
                <i class="fa-solid fa-bookmark"></i>
              </div>
            </div>

            <div className="mb-3">
              <h1>Chat</h1>
            </div>

            <div className="initial_request mb-3">
              <div className="initial_request_img">
                <img
                  src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                  alt=""
                />
              </div>

              <span>This is your initial request</span>
            </div>

            <div className="responding_request">
              <div className="stars_flex">
                <div>
                  <StarsSvg />
                </div>

                <span className="">This is me responding to your request</span>
              </div>

              <div className="stars_flex p-0">
                <div className="empty_div"></div>

                <span className="stars_text"></span>
              </div>

              <div className="stars_flex pb-0 pt-3 ">
                <div className="empty_div"></div>

                <div className="gpt_detail_icons">
                <div>
                  <CopySvg/>
                </div>
                <div className="input_icon">
                  <i class="fa-solid fa-bookmark"></i>
                </div>
                <div className='share_able_icon'>
                  <img
                    src={ShareablesBlackIcon}
                    alt=""
                    className="as-link"
                    onClick={() => {}}
                  />
                </div>
              </div>
              </div>

              
            </div>
          </div>
        </div>
      </SlidingPanel>
    </>
  );
};

export default DetailBuildGpt;
