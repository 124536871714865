import React, {useState, useEffect} from 'react';
import {Button, Col, Row, Form, Dropdown} from 'react-bootstrap';
import {fixName} from '../../Utils/commonUtils';
import {useHistory} from 'react-router-dom';
import commonApi from '../../Services/CommonService';
import {DEFAULT_IMAGE} from '../../Utils/env';
import OpportunityList from './OpportunityList';
import {getOpportunityListingProfile} from '../../Store/Actions/OpportunityAction';
import {useSelector, useDispatch} from 'react-redux';
const ProfileRecruitment = props => {
  const [searchQry, setSearchQry] = useState('');
  const dispatch = useDispatch();
  const opportunityData = useSelector(
    state => state.opportunityListingProfile?.listing,
  );

  const getOpportunityData = async (qry, type) => {
    var data = {
      user_id: props.userId,
      search_query: qry,
      type: type,
    };
    dispatch(getOpportunityListingProfile(data));

    // setTimeout(() => {
    //   setshowLoader(false);
    // }, 500);

    // setOpportunityData()
  };

  useEffect(() => {
    getOpportunityData(searchQry, props.sessionFilterValue);
  }, [searchQry, props.sessionFilterValue]);

  return (
    <div className="opportunities-list-wrapper">
      {props.threeDots && (
        <div className="person-search-wrapper mb-3 mt-3 messages-users-wrapper">
          <div className="search-form d-flex">
            <Form.Group className="search-input">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchQry}
                onChange={e => {
                  setSearchQry(e.target.value);
                }}
              />
            </Form.Group>
            <Button>
              <i
                className="icon-filter-icon"
                onClick={() => {
                  props.handleCommand('', 'session_filter');
                }}
              />
            </Button>
          </div>
        </div>
      )}

      {Object.entries(opportunityData).length > 0 ? (
        Object.entries(opportunityData).length > 0 &&
        opportunityData.map((item, index) => (
          <OpportunityList
            key={`opportunity-item-${index}`}
            item={item}
            handleCommand={props.handleCommand}
          />
        ))
      ) : props.userId === localStorage.getItem('user') ? (
        <div className="no-results-wrapper">
          <div className="no-results-content">
            <h2>You are not participating in any opportunities.</h2>
            <p>
              Create or explore opportunities to accelerate your growth. Find
              partners, seek funding, invest and so much more.
            </p>

            <div className="btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                    props.handleCommand &&
                    props.handleCommand('', 'create_recruitment');
                  // handleClickLink('/recruitment');
                }}>
                Create Recruitment
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProfileRecruitment;
