import React, {useState, useEffect} from 'react';

const MineSlidingPannel = ({children, hidePanel, slideFrom,showCloseIcon,renderData,
  showBlackHeader,
  lastDivStyle,
  firstDivStyle,
  headerPadding}) => {
  const [animateClass, setClass] = useState('');

  const [inOutClass, setInOut] = useState({
    inClass: 'animate__fadeInUp',
    outClass: 'animate__fadeOutDown',
  });

  const togglePanel = () => {
    if (!animateClass) {
      setClass(inOutClass.inClass);
    } else {
    //   setClass(inOutClass.outClass);
      setTimeout(() => {
        hidePanel();
      }, 320);
    }
  };

  const assignInOut = () => {
    const temp = {
      inClass: 'animate__fadeInUp',
      outClass: 'animate__fadeOutDown',
    };

    if (slideFrom && slideFrom === 'left') {
      temp.inClass = 'animate__fadeInLeft';
      temp.outClass = 'animate__fadeOutLeft';
    }
    if (slideFrom && slideFrom === 'right') {
      temp.inClass = 'animate__fadeInRight';
      temp.outClass = 'animate__fadeOutRight';
    }
    if (slideFrom && slideFrom === 'top') {
      temp.inClass = 'animate__fadeInDown';
      temp.outClass = 'animate__fadeOutUp';
    }
    setInOut(temp);
    togglePanel();
  };

  useEffect(() => {
    assignInOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`sliding-panel-wrapper animate__animated ${animateClass}`} style={firstDivStyle}>
      {showCloseIcon === "yes" && 
      <span className="close as-link" onClick={() => togglePanel()}>
        <i className="fas fa-times" />
      </span>}

      {showBlackHeader && (
        <div>
          <div
            className="black-header"
            style={{
              backgroundColor: '#424242',
              // paddingTop:'60px'
            }}>
                <div
        className="adjust_icon "
        style={{
          fontSize: '22px',
          padding: headerPadding ? headerPadding : '24px',
          color: ' white',
          display: 'flex',
          justifyContent: 'left',
          gap: '20px',
          alignItems:'center'
        }}>
            {
            renderData &&
             renderData()}
             </div>
            <span
              className="close as-link"
              style={{top: '25px', background: '#424242',color:'white',right:'15px'}}
              onClick={() => togglePanel()}>
              <i className="fas fa-times" />
            </span>
          </div>
        </div>
      )}

      <div className="sliding-panel-content" style={lastDivStyle}>{children}</div>
    </div>
  );
};

export default MineSlidingPannel;
