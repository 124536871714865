import React, {useState, useEffect, useRef} from 'react';
import Moment from 'react-moment';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {fixName, splitStringToArray} from '../../Utils/commonUtils';

const CallContacts = props => {

  const {contactListing, handleContact} = props;

  return (
    <>
      <div className="session-wrapper history-sessions">
        {Object.entries(contactListing).length > 0 ? (
          Object.entries(contactListing).length > 0 &&
          contactListing.map((item, index) => (
            <div
              key={index + 'ASDSAD' + item.id}
              className="events-item as-link"
              style={{padding: '11px', marginBottom: '10px'}}
              onClick={()=>{handleContact(item)}}
              >
              <div className="session-heading" style={{alignItems: 'unset'}}>
                <div className="persons-img-list mb-0 d-flex align-items-center">
                  <div className="img-item mb-0">
                    <img
                      src={
                        item?.profile_img
                          ? item?.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt=""
                      style={{width: '60px', height: '60px'}}
                    />
                  </div>
                  <div className="row">
                    <span className="m-1">
                      {fixName(item?.first_name,item?.last_name)}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="session-heading payment-card-bottom">
              <div
                onClick={() => {
                //   buildersHandle('profileComponent', profile.user_id);
                }}
                className="event-titles payment-card-icons"
                style={{
                  color: '#262626',
                  fontFamily: 'Inter',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  paddingTop: '11px',
                }}>
                Top 10%
              </div>
              <div className="contacts-item-icon no-link">
                <div
                  onClick={() => {
                    // buildersHandle('profileComponent', profile.user_id);
                  }}
                  className="contacts-icon as-link">
                  <i className="fa-solid fa-phone"></i>
                </div>
                <div
                  className="contacts-icon as-link"
                  onClick={() => {
                //     setCustomerData({
                //       id: profile.user_id,

                //       profile_img: profile.profile_img,
                //       first_name: profile.first_name,
                //       last_name: profile.last_name,
                //     });
                //     checkChatRoom(profile.user_id);
                }}
                  >
                  <i className="fa-solid icon-plane"></i>
                </div>
              </div>
            </div> */}
            </div>
          ))
        ) : (
          <div className="no-results-wrapper">
            <div className="img-bg-wrapper">
            </div>

            <div className="no-results-content">
              <h2>No recorde found.</h2>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CallContacts;
