import {

    Form,
    Dropdown,
  } from 'react-bootstrap';
  import Sidebar from '../../Pages/Sidebar';

  import {Car, HomeServices} from '../../Constants/Images';
  import Scrollbars from 'react-custom-scrollbars';
  import React, {useState, useEffect} from 'react';
  import {Logo} from '../../Constants/Images';
  import { CustomSlidingTabs, Pannels, } from '../../Components';
  import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
  import Slider from 'react-slick';
  import SvgIconComponent from '../../Constants/SvgIconComponent';

const CustomerMall = (props) => {

    const [subTabType, setSubTabsType] = useState('home services');
    const [type, setType] = useState('1');
    const [showComponent, setShowComponent] = useState('');
    const [isFloated, toggleFloatedPanel] = useState(false);
    const [showHiddenPanel, toggleHiddenPanel] = useState(false);
    const [workspaceData, setWorkspaceData] = useState([]);

   

  
 
  
    const dropdownMenu = [
      {id: '1', name: 'Select Service'},
      {
        id: '2',
        name: 'Scheduler',
      },
      {
        id: '3',
        name: 'Menus',
      },
      {
        id: '4',
        name: 'Roles',
      },
      {
        id: '5',
        name: 'Catalog',
      },
      {
        id: '6',
        name: 'Triggers',
      },
      {
        id: '7',
        name: 'Analystics',
      },
      {
        id: '8',
        name: 'LeaderBoard',
      },
      {
        id: '9',
        name: 'Goals',
      },
    ];
  

  

 
  
    useEffect(() => {
      if (showComponent !== '') {
        toggleHiddenPanel(true);
      } else {
        toggleHiddenPanel(false);
      }
    }, [showComponent]);
  
    
  
 
    const userProfileSlider = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      // adaptiveHeight: true,
    };
    const images_array = [Logo, Logo, Logo, Logo, Logo];
  

  
    return (
      <>
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}
  
        <div className="page-content-wrapper">
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
                <div className="customer-search-page">
                  <div className="event-tabs sliding-tabs mb-3 customer-product-page">
                    <CustomSlidingTabs
                      items={[
                        {
                          key: 'home services',
                          label: 'Home Services',
                          icon: '',
                          img: (
                            <SvgIconComponent
                              icon={'home_services'}
                              fill={
                                subTabType === 'home services'
                                  ? 'white'
                                  : '#262626'
                              }
                              h={'25'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'retail',
                          label: 'Retail',
                          icon: 'fa-solid fa-cart-shopping',
                          img: '',
                        },
                        {
                          key: 'consulting',
                          label: 'Consulting',
                          img: (
                            <SvgIconComponent
                              icon={'consulting'}
                              fill={
                                subTabType === 'consulting' ? 'white' : '#262626'
                              }
                              h={'23'}
                              w={'30'}
                            />
                          ),
                        },
                        // {
                        //   key: 'consults',
                        //   label: 'Consults',
                        // },
                        {
                          key: 'rideshare',
                          label: 'Rideshare',
                          img: (
                            <SvgIconComponent
                              icon={'rideshare'}
                              fill={
                                subTabType === 'rideshare' ? 'white' : '#262626'
                              }
                              h={'25'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'pickup drop-off',
                          label: 'Pickup Drop-off',
                          img: (
                            <SvgIconComponent
                              icon={'pickup_drop_off'}
                              fill={
                                subTabType === 'pickup drop-off'
                                  ? 'white'
                                  : '#262626'
                              }
                              h={'25'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'nonprofit',
                          label: 'Nonprofit',
                          img: (
                            <SvgIconComponent
                              icon={'nonprofit'}
                              fill={
                                subTabType === 'nonprofit' ? 'white' : '#262626'
                              }
                              h={'23'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'dining',
                          label: 'Dining',
                          img: (
                            <SvgIconComponent
                              icon={'dining'}
                              fill={subTabType === 'dining' ? 'white' : '#262626'}
                              h={'23'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'event management',
                          label: 'Event Management',
                          img: (
                            <SvgIconComponent
                              icon={'event_management'}
                              fill={
                                subTabType === 'event management'
                                  ? 'white'
                                  : '#262626'
                              }
                              h={'25'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'rentals',
                          label: 'Rentals',
                          img: (
                            <SvgIconComponent
                              icon={'rentals'}
                              fill={subTabType === 'rentals' ? 'white' : '#262626'}
                              h={'25'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'real_estate',
                          label: 'Real Estate',
                          img: (
                            <SvgIconComponent
                              icon={'real_estate'}
                              fill={
                                subTabType === 'real_estate' ? 'white' : '#262626'
                              }
                              h={'25'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'co-working space',
                          label: 'Co-Working Space',
                          img: (
                            <SvgIconComponent
                              icon={'cp_worker'}
                              fill={
                                subTabType === 'co-working space'
                                  ? 'white'
                                  : '#262626'
                              }
                              h={'25'}
                              w={'30'}
                            />
                          ),
                        },
                        {
                          key: 'trade shops',
                          label: 'Trade Shops',
                          img: (
                            <SvgIconComponent
                              icon={'trade_shops'}
                              fill={
                                subTabType === 'trade shops' ? 'white' : '#262626'
                              }
                              h={'23'}
                              w={'30'}
                            />
                          ),
                        },
                      ]}
                      showItems={5}
                      activeTab={subTabType}
                      onItemClick={item => {
                        // setMustApiHit(false)
                        // setHasMoreData(true)
                        setSubTabsType(item);
                        // setshowLoader(true);
                        // handleApiSelector(item);
                      }}
                      component={'productComponent'}
                    />
                  </div>
  
                  
                  <div className="refferal-list-wrapper">
                  <Scrollbars>
                <div className="serach-filter-wrapper">
                  <div className="home_services">
                    {subTabType === 'home services' && (
                      <div>
                        <h1 className="">
                          <img src={HomeServices} alt="" />
                          Home Services
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="roi-template-dropdown">
                          <div className="select-wrapper">
                            <Dropdown
                              onSelect={(eventKey, event) => {
                                setType(eventKey);
                              }}
                              drop="down">
                              <Dropdown.Toggle variant="primary">
                                {type
                                  ? dropdownMenu.find(item => item.id === type)
                                      .name
                                  : 'Select Role'}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {dropdownMenu.map((item, index) => (
                                  <Dropdown.Item
                                    key={`st-${index}`}
                                    eventKey={item.id.toString()}
                                    active={item.id === type}>
                                    {item.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    )}
                    {(subTabType === 'home services' ||
                      subTabType === 'rideshare') && (
                      <div className="mt-5">
                        <h1 className="">
                          <img src={Car} alt="" />
                          Rideshare
                          <div>10</div>
                        </h1>

                        <span> Closest is approx 15 min</span>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="search-tabs-wrapper  mt-0">
                          <div className="search-form d-flex mt-0">
                            <Form.Group className="search-input-location">
                              <Form.Control
                                onChange={e => {}}
                                type="text"
                                placeholder="Enter location"
                              />
                            </Form.Group>

                            <div
                              style={{
                                paddingLeft: '66px',
                                paddingTop: '10px',
                              }}>
                              <i className="fa-solid fa-calendar-days" /> Now
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {(subTabType === 'home services' ||
                      subTabType === 'retail') && (
                      <div className="mt-5">
                        <h1 className="">
                          <i
                            style={{marginRight: '5px'}}
                            class="fa-solid fa-cart-shopping"></i>
                          Retail
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    {(subTabType === 'home services' ||
                      subTabType === 'pickup drop-off') && (
                      <div className="mt-5">
                        <h1 className="">
                          <div>
                            <SvgIconComponent
                              icon={'pickup_drop_off'}
                              fill={'black'}
                              h={'20'}
                              w={'30'}
                            />
                          </div>
                          Pickup Drop-off
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    {(subTabType === 'home services' ||
                      subTabType === 'consulting') && (
                      <div className="mt-5">
                        <h1 className="">
                          <i
                            style={{marginRight: '5px'}}
                            class="icon icon-appointments"></i>
                          Consulting
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}

                    {(subTabType === 'home services' ||
                      subTabType === 'nonprofit') && (
                      <div className="mt-5">
                        <h1 className="">
                          <div>
                            <SvgIconComponent
                              icon={'nonprofit'}
                              fill={'black'}
                              h={'20'}
                              w={'30'}
                            />
                          </div>
                          Nonprofit
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    {(subTabType === 'home services' ||
                      subTabType === 'dining') && (
                      <div className="mt-5">
                        <h1 className="">
                          <div>
                            <SvgIconComponent
                              icon={'dining'}
                              fill={'black'}
                              h={'25'}
                              w={'30'}
                            />
                          </div>
                          Dining
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    {(subTabType === 'home services' ||
                      subTabType === 'event management') && (
                      <div className="mt-5">
                        <h1 className="">
                          <div>
                            <SvgIconComponent
                              icon={'event_management'}
                              fill={'black'}
                              h={'25'}
                              w={'30'}
                            />
                          </div>
                          Event Management
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}

                    {(subTabType === 'home services' ||
                      subTabType === 'rentals') && (
                      <div className="mt-5">
                        <h1 className="">
                          <div>
                            <SvgIconComponent
                              icon={'rentals'}
                              fill={'black'}
                              h={'25'}
                              w={'30'}
                            />
                          </div>
                          Rentals
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    {(subTabType === 'home services' ||
                      subTabType === 'real_estate') && (
                      <div className="mt-5">
                        <h1 className="">
                          <div>
                            <SvgIconComponent
                              icon={'real_estate'}
                              fill={'black'}
                              h={'25'}
                              w={'30'}
                            />
                          </div>
                          Real Estate
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}

                    {(subTabType === 'home services' ||
                      subTabType === 'co-working space') && (
                      <div className="mt-5">
                        <h1 className="">
                          <div>
                            <SvgIconComponent
                              icon={'cp_worker'}
                              fill={'black'}
                              h={'30'}
                              w={'30'}
                            />
                          </div>
                          Co-Working Space
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    {(subTabType === 'home services' ||
                      subTabType === 'trade shops') && (
                      <div className="mt-5">
                        <h1 className="">
                          {/* <i
                            style={{marginRight: '5px'}}
                            class="fa-solid fa-cart-shopping"></i> */}
                          <div>
                            <SvgIconComponent
                              icon={'trade_shops'}
                              fill={'black'}
                              h={'20'}
                              w={'20'}
                            />
                          </div>
                          Trade Shops
                          <div>10</div>
                        </h1>
                        <div className="persons-img-gallery mt-4">
                          <Slider {...userProfileSlider}>
                            {images_array.length > 0 &&
                              images_array.map((miniItem, index) => (
                                <div className="mb-2" key={index + 'eee'}>
                                  <div className="img-item as-link  mr-5">
                                    <img src={miniItem} alt="" className="" />
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </div>

                        <div className="mt-4">
                          <div
                            className="refferal-list-wrapper"
                            onClick={() => {
                              // setShowComponent('retailDetail')
                            }}>
                            <div className="payment-card qa-list-item">
                              <div class="payment-user-item no-link">
                                <div className="cart-img as-link"></div>

                                <div class="message-other-users"></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Scrollbars>
                  </div>
  
                  
                  {showComponent === 'WorkSpace' && (
                    <CreateWorkSpace
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}
  
                  {/* <>
                     
                      <div className="mt-3">
                        <h3 className="text-2xl">Hi, Jeff</h3>
                      </div>
                      <div className="mt-2 ">
                        <span className="">Have fun shopping!</span>
                      </div>
                      <div>
                        <div className="search-tabs-wrapper  mt-0">
                          <div className="search-form d-flex mt-0">
                            <Form.Group className="search-input-location">
                              <Form.Control
                                onChange={e => {}}
                                type="text"
                                placeholder="Enter amount"
                              />
                            </Form.Group>
                    
                            <div
                              style={{
                                paddingLeft: '66px',
                                paddingTop: '10px',
                              }}>
                              <i className="fa-solid fa-calendar-days" /> Now
                            </div>
    
                         
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <button className="btn-success ">Donate</button>
                      </div>
                      <div
                        style={{position: 'relative'}}
                        className="mt-4 text-center font-bold">
                        <span className="clear-btn">Schedule Round Trip</span>
                      </div>
                      <div className="pt-3">
                        <h1 className="text-2xl">Recent News</h1>
                        <div className="pt-3">
                          <img
                            style={{width: '280px'}}
                            src="https://s3.amazonaws.com/build-dev/profile_img/1696311960976_thumb.jpg"
                            alt=""
                          />
                          <div className="pt-3">
                            <h1>We just painted a school</h1>
                          </div>
                          <div className="pt-3">
                            <span>
                              Thanks to our donors we are now able to help Bethel
                              Middle School.
                            </span>
                          </div>
                        </div>
                      </div>
              
              </> */}
                </div>
              </>
            }
            secondary={
              <>
             
              </>
            }
          />
        </div>
      </>
    );
  
  
}

export default CustomerMall