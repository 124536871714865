import React, {useState, useEffect} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import AddNewSnapShot from './AddNewSnapShot';
import commonApi from '../../Services/CommonService';
import SnapShotDetails from './SnapShotDetails';

import Scrollbars from 'react-custom-scrollbars';
import MineSlidingPannel from '../MineSlidingPannel';

const CreateSnapShot = props => {
  const [button, setAButton] = useState(false);
  const [images, setImages] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const [id, setId] = useState('');
  const [selectedImg, setSelectedImg] = useState('');

  useEffect(() => {
    getAccomplishment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [button, showDetails]);

  const getAccomplishment = () => {
    var payLoad = {
      categories: [],
      user_id: localStorage.getItem('user'),
    };
    commonApi
      .get_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          setImages(res.accomplishment);
          if (!props.type) {
            props.setImages(res.accomplishment);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <>
      <MineSlidingPannel
        hidePanel={() => props.onClose()}
        showCloseIcon={props.type ? 'yes' : 'no'}>
        <div className="serach-filter-wrapper">
          {props.type !== 'work-spaceee' && (
            <div className="headings fix-width-forms">
              <h2> Chose Host</h2>
            </div>
          )}

          <Scrollbars>
            <div className="fix-width-forms">
              {props.type !== 'work-spaceee' && (
                <>
                  <p>
                    Choose the bussiness you would like associated with this
                    group
                  </p>
                </>
              )}
              {/* <div className="images-list mb-3">
                <div
                  className="images-list-item"
                  onClick={() => {
                    setAButton(true);
                  }}>
                  <div className="camera-icon">
                    <i className="fas fa-plus" />
                  </div>
                </div>
              </div> */}

              <div className="gallery-imgs">
                <Row>
                  {Object.entries(images).length > 0 ? (
                    Object.entries(images).length > 0 &&
                    images.map((item, index) => {
                      return (
                        <Col md={4} sm={4} key={`gallery-img-${index}`}>
                          <div
                            className={
                              selectedImg === item.id ||
                              (props.snapShotId &&
                                props.snapShotId.includes(item.id))
                                ? 'gallery-item active'
                                : 'gallery-item'
                            }
                            onClick={e => {
                              if (props.type) {
                                // addSnapShot(item);
                                props.setSnapShotId(item.id);
                                props.setTitle(item.title);
                                props.setSnapShotListAraay(item.images[0]);
                              } else {
                                setId(item.id);
                              }

                              // setShowDetails(true);
                              setSelectedImg(item.id);
                            }}>
                            <span
                              onClick={e => {
                                setId(item.id);
                                setShowDetails(true);
                                setSelectedImg(item.id);
                              }}
                              className="zoom-icon">
                              <i className="fas fa-magnifying-glass" />
                            </span>
                            <div>
                              <img src={item.images[0]} alt="" />
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  <Col md={4} sm={4}>
                    <div
                      className="images-list mb-3 h-100 w-100"
                      onClick={() => {
                        setAButton(true);
                      }}>
                      <div
                        className="camera-icon h-100 w-100"
                        style={{fontSize: 22}}>
                        <i className="fas fa-plus" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Scrollbars>
          <div className="filter-btn-wrapper pt-3">
            <div className="invite-btns p-0 text-left">
              <Button
                className="btn-dark"
                type="submit"
                disabled={selectedImg === '' ? true : false}
                onClick={() => {
                  if (props.type) {
                    props.onClose();
                  } else {
                    props.setHostId(selectedImg);
                    props.onClose();
                  }
                }}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </MineSlidingPannel>

      {button === true && (
        <AddNewSnapShot
          onClose={() => {
            setAButton(false);
          }}
        />
      )}
      {showDetails === true && (
        <SnapShotDetails
          onClose={() => {
            setShowDetails(false);
          }}
          id={id}
        />
      )}
    </>
  );
};
export default CreateSnapShot;
