import commonApi from '../../Services/CommonService';

const getDispatch = (type, data) => ({
  type: type,
  payload: data,
});

export const callDeepLink = () => {
  return {
    type: 'DEEPLINK',
  };
};

export const getNotification = data => {
  return {
    type: 'NOTIFICATION',
    payload: data,
  };
};

export const setServerTime = () => {
  return async dispatch => {
    const response = await commonApi.check_server_time();
    dispatch(getDispatch('SERVER_TIME', new Date(response.server_time)));
    return response;
  };
};

export const getCurrentUserData = data => {
  return async dispatch => {
    const response = await commonApi.user_info(data);
    dispatch(getDispatch('GET_CURRENT_USER', response.data));
    return response;
  };
};

export const getUserData = data => {
  return async dispatch => {
    const response = await commonApi.company_info(data);
    dispatch(getDispatch('GET_USER_DATA', response.user_detail));
    return response;
  };
};

export const getUserCompanyData = data => {
  return async dispatch => {
  
    if(data.company_name ===''){
 
      const response = await commonApi.company_info(data);
      dispatch(getDispatch('GET_USER_COMPANY_DATA', response));
      return response;
    }
    else{
      const response = await commonApi.company_info(data);
      dispatch(getDispatch('GET_USER_COMPANY_DATA', response));
      return response;
    }
    
  };
};


export const getUrlData = data => {
  return async dispatch => {
  

      dispatch(getDispatch('GET_URL_DATA', data));
    
  };
};



export const manageFavourite = data => {
  return getDispatch('FAVOURITE_CHANGE', data);
};

export const managFavourite = data => {
  return getDispatch('FAVOURITE_CHANGE', data);
};

export const manageFavRecruitment = data => {
  return getDispatch('FAVOURITE_RECRUITMENT_CHANGE', data);
};

export const manageFavRecruitmentItem = data => {
  return getDispatch('FAVOURITE_CHANGE', data);
};

