import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useSwipeable} from 'react-swipeable';

import {setMenuState} from '../Store/Actions/CommanAction';

const Pannels = ({
  menuState,
  setMenuState,
  primary,
  secondary,
  isFloated,
  showHiddenPanel,
  updateHiddenPanelState,
  customCss,
  secondaryBgCss = {},
}) => {
  const [showPanel, toggleHiddenPannel] = useState(showHiddenPanel);

  useEffect(() => {
    toggleHiddenPannel(showHiddenPanel);
  }, [showHiddenPanel]);

  const config = {};
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!menuState) {
        setMenuState(!menuState);
      } else if (!showHiddenPanel) {
        updateHiddenPanelState(!showHiddenPanel);
      }
    },
    onSwipedRight: () => {
      if (menuState && !showHiddenPanel) {
        setMenuState(!menuState);
      }

      if (showHiddenPanel) {
        updateHiddenPanelState(!showHiddenPanel);
      }
    },
    ...config,
  });

  return (
    <div
      {...handlers}
      className={`panel-wrapper ${isFloated ? 'floated-panel' : ''} ${
        showPanel ? 'open' : ''
      }`}>
      <div className="panel-primary" style={customCss}>
        {primary}
      </div>
      <div className="panel-secondary" style={secondaryBgCss}>
        {secondary}
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
});

export default connect(mapStateProps, {setMenuState})(Pannels);
