import React, {useState, useEffect} from 'react';
import {Button, } from 'react-bootstrap';

import commonApi from '../../Services/CommonService';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import {successMessage} from '../../Utils/ToastMessages';
import LoaderModal from './LoaderModal';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';

import CustomModal from '../CustomModal';

import PrefListItems from '../Preferences/PrefListItems';
import FocusListItems from '../Preferences/FocusListItems';

const FocusModel = props => {
  // const [ethnicity, setEthnicity] = useState('');
  // const [gender, setGender] = useState('');
  // const [identifier, setIdentifier] = useState('');
  const [isCheckButton, setIsCheckButton] = useState([]);
  // const [isButton, setisButton] = useState([]);
  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [location, setLocation] = useState('');

  const [showLoader, setshowLoader] = useState(false);
  const [locationDel, setLocationDel] = useState('');
  const [showModaL, setShowModel] = useState(false);
  // const [show, setShow] = useState(props.show);
  const [locationArray, setLocationArray] = useState([]);
  const closeModal = () => {
    // setShow(false);
    props.onClose();
  };
  const [appointmentId, setAppointmentId] = useState([]);
  const [appointmentType, setAppointmentType] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [persnalTypes, setPersnalTypes] = useState([]);
  const [categoriesValue, setCategoriesValue] = useState([]);

  const [persnalTypesId, setPersnalTypesId] = useState([]);

  useEffect(() => {
    getAppointmentType();
    categories();
    getUserFocus();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppointmentType = () => {
    // setshowLoader(true);

    commonApi
      .get_appointment_types()
      .then(res => {
        if (res.status === 200) {
          setAppointmentType(res.appointment_type);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const categories = () => {
    setshowLoader(true);
    
    commonApi
      .get_areas_categories()
      .then(res => {
        // setshowLoader(false);
        if (res.status === 200) {
          setCategoriesValue(res.categories);
          setPersnalTypes(res.areas);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const makeReact = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };
  const Othersidn = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };

  useEffect(() => {}, [isCheckButton]);
  
  const handle = () => {
    createFocus();
  };
  const createFocus = () => {
    var payLoad = {
      city_states: locationArray,
      ethnicity_ids: ethnicityId,
      // gender_ids:
      //   typeof isCheckButton === 'string'
      //     ? isCheckButton.split()
      //     : isCheckButton,
      gender: '',
      other_identifier_ids: identiferId,
      on_boarding_areas: persnalTypesId,

      appointment_types: appointmentId,

      on_boarding_categories: categoriesId,
    };
    commonApi
      .create_focus(payLoad)
      .then(res => {
        // setshowLoader(false);
        if (res.status === 200) {
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUserFocus = () => {
    setshowLoader(true);

    commonApi
      .get_user_focus()
      .then(res => {
        // setshowLoader(false);
        if (res.status === 200) {
          
          // setEthnicity(res.data.ethnicity);
          // setGender(res.data.gender);
          // setIdentifier(res.data.other_identifier);

          setCategoriesId(
            makeReact(res.data.on_boarding_categories, 'id', 'selected'),
          );
          setAppointmentId(
            makeReact(res.data.appointment_type, 'id', 'selected'),
          );
          setPersnalTypesId(
            makeReact(res.data.on_boarding_areas, 'id', 'selected'),
          );
          setLocationArray(res.data.locations);
          setIsCheckButton(makeReact(res.data.gender, 'id', 'selected'));
          setIdentiferId(
            Othersidn(res.data.other_identifier, 'id', 'selected'),
          );
          setEthnicityId(makeReact(res.data.ethnicity, 'id', 'selected'));
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const locationData = () => {
    var place_id = !location ? '' : location.value.place_id;
    // 
    var perfectAddress = !location.label ? '' : location.label;
    if (place_id && perfectAddress) {
      geocodeByPlaceId(place_id)
        .then(results => getLatLng(results[0]))
        .then(({lat, lng}) => {
          // 
          var adress = {address: perfectAddress, latitude: lat, longitude: lng};

          setLocationArray(curr => [...curr, adress]);
          setLocation('');
        });
    }
  };
  const removeLocation = () => {
    setLocationArray(
      locationArray.filter(item => item.latitude !== locationDel),
    );
  };
  useEffect(() => {
   
      location.hasOwnProperty('label') && locationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const addValues = () => {
    props.setFocusLocations(locationArray);
    props.setIdentiferId(identiferId);
    props.setEthnicityId(ethnicityId);
    props.setGender(isCheckButton);
    props.setPersnalTypesId(persnalTypesId);
    props.setCategoriesId(categoriesId);
    props.setAppointmentId(appointmentId);
  };
  const removeValues = () => {
    props.setPersnalTypesId([]);
    props.setCategoriesId([]);
    props.setAppointmentId([]);
    props.setFocusLocations([]);
    props.setIdentiferId([]);
    props.setEthnicityId([]);
    props.setGender([]);
  };

  return (
    <>
      <SlidingPanel hidePanel={() => closeModal()}>
        {showLoader && (
          <LoaderModal closeModal={setshowLoader} show={showLoader} />
        )}

        <div className="serach-filter-wrapper">
          <div className="headings">
            <h2> Focus</h2>
          </div>
          <Scrollbars>
            <div className="focus-modal-content">
              {/* <div className="category-btns-wrapper category-btns-wrapper-slider">
                  <CustomSlidingTabs                  
                    items={gender}
                    showItems={2}
                    activeTab={isCheckButton}
                    onItemClick={handleButton}
                  />
                </div> */}
              {/* 
              <div className="checkbox-list">
                <h3>Gender</h3>
                <GenderListItems
                  items={gender}
                  active={isCheckButton}
                  updateActive={newActive => setIsCheckButton(newActive)}
                />
              </div> */}
              <div className="checkbox-list">
                <h3>Industries</h3>

                <FocusListItems
                  items={categoriesValue}
                  active={categoriesId}
                  updateActive={newItems => setCategoriesId(newItems)}
                />
              </div>
              <div className="checkbox-list">
                <h3>Personality Types</h3>

                <PrefListItems
                  items={persnalTypes}
                  active={persnalTypesId}
                  updateActive={newItems => setPersnalTypesId(newItems)}
                />
              </div>
              <div className="checkbox-list">
                <h3>1 on 1</h3>

                <PrefListItems
                  items={appointmentType}
                  active={appointmentId}
                  updateActive={newItems => setAppointmentId(newItems)}
                />
              </div>

              {/* <div className="checkbox-list">
                <h3>Self Identifiers</h3>

                <PrefListItems
                  items={identifier}
                  active={identiferId}
                  updateActive={newItems => setIdentiferId(newItems)}
                />
              </div> */}

              {/* <div className="checkbox-list">
                <h3>Ethnicity</h3>

                <PrefListItems
                  items={ethnicity}
                  active={ethnicityId}
                  updateActive={newItems => setEthnicityId(newItems)}
                />
              </div> */}

              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3>Locations</h3>
                {location && (
                  <span className="clear-btn" onClick={() => {}}>
                    Clear
                  </span>
                )}
              </div>

              <div className="location-field form-control">
                <GooglePlacesAutocomplete
                  location
                  selectProps={{
                    location,
                    onChange: setLocation,
                    name: 'city',
                    placeholder: 'Type city and state',
                  }}
                />
              </div>

              <div className="checkbox-list location-list mt-3">
                <ul>
                  {Object.entries(locationArray).length > 0 &&
                    locationArray.map((item, index) => (
                      <li className="py-0" key={`label-${index}`}>
                        {item.address}
                        <span
                          className="remove-icon"
                          onClick={() => {
                            setLocationDel(item.latitude);
                            setShowModel(true);
                          }}>
                          <i className="fas fa-times" />
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </Scrollbars>

          <div className="filter-btn-wrapper pt-3">
            <div className="d-flex align-items-center justify-content-between">
              {!props.active && (
                <Button
                  className="btn-dark"
                  onClick={() => {
                    // setButtonEnable(true);
                    props.setActive(true);
                    addValues();
                    props.onClose();
                  }}>
                  Enable Focus
                </Button>
              )}

              {props.active && (
                <Button
                  className="btn-dark"
                  onClick={() => {
                    // setButtonEnable(true);
                    props.setActive(false);
                    removeValues();
                    props.onClose();
                  }}>
                  Disable Focus
                </Button>
              )}
              <Button className="btn-dark" onClick={handle}>
                Update Preferences
              </Button>

              {/* <Form.Check
                type="switch"
                id="custom-switch"
                label="Update Preferences"
                onch
              />  */}
            </div>

            {/* <Button className="btn-dark" onClick={handle}>
              Update Preferences
            </Button> */}
          </div>
        </div>

        {/* <SessionEvents setButtonEnable={setButtonEnable} buttonEnable={buttonEnable} 
                onClick={() => props.parent(DataIs)} /> */}
      </SlidingPanel>

      {showModaL && (
        <CustomModal
          onClose={() => {
            setShowModel(false);
          }}>
          <div className="remove-user-text">
            <h2>REMOVE LOCATION!</h2>
            <p>Are you sure you want to remove this location?</p>
            <Button
              className="btn-dark"
              onClick={() => {
                removeLocation();
                setShowModel(false);
              }}>
              Yes
            </Button>
            <Button
              className="btn-dark"
              onClick={() => {
                setShowModel(false);
              }}>
              No
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};
export default FocusModel;
