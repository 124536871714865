import React, {useState, useEffect,useImperativeHandle} from 'react';
import commonApi from '../../Services/CommonService';
import moment from 'moment';
import CustomSlidingButtons from '../CustomSlidingButtons';
import { Button } from 'react-bootstrap';
import LoaderSpinner from '../Modals/LoaderSpinner';
import { getFutureDateByCategory } from '../../Utils/commonUtils';

const ProfileFollowUp = props => {
  const [followUp, setFollowUp] = useState([]);
  const [showLoader, setshowLoader] = useState(true);
  const [actionType, setActionType] = useState('');
  const [relationid, setRelationid] = useState('');
  const [stageid, setStageid] = useState('');
  

  const [futureDate, setFutureDate] = useState('');

  const [followUpType, setFollowUpType] = useState('');

  const ActionCategories = [
    {title: 'Custom'},
    {title: 'Tomorrow'},
    {title: '7 days'},
    {title: '15 days'},
    {title: '21 days'},
    {title: '3 month'},
    // {title: 'Circle 4'},
  ];

  const handleGetData = e => {
    const {value} = e.target;
    const date = getFutureDateByCategory(value);

    if (date) {
      setFutureDate(date);
    }
    setFollowUpType('follow_up');

    // setShowComponent('addAction');
    props.handleCommand(
      {followUpType: 'follow_up', date: date},
      'create_followUp',
    );
  };

  useImperativeHandle(props.hitApiRef, () => ({
   

    getFollowUps(){
      followUpData()
    },
   
  }));

  

  
  useEffect(() => {
    followUpData();
  }, [props.userData]);

  const followUpData = () => {
    // setshowLoader(true);
    var payLoad = {
      user_id: props.userData
        ? props.userData.data.id
        : localStorage.getItem('id'),
      search_query: '',
    };

    commonApi
      .get_follow_ups(payLoad)
      .then(res => {
        if (res.status === 200) {
          setFollowUp(res.all_actions);
          setTimeout(() => {
            setshowLoader(false);
          }, 500);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <div className="refferal-list-wrapper">
      {}


      {localStorage.getItem('id') !== props.userData.data.id && (
        <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
          <CustomSlidingButtons
            items={ActionCategories}
            activeButtons={actionType}
            setActionType={setActionType}
            stageid={stageid}
            relationid={relationid}
            onItemClick={e => {
              handleGetData(e);
            }}
            showItems={3}
          />
        </div>
      )}

{showLoader && (
      <div className="loader_hieght">{showLoader && <LoaderSpinner />}</div>
    )}

      {!showLoader && followUp.length > 0 ? (
        followUp.map((item, index) => (
          <div
            className="action-listing followed-up as-link"
            key={index}
            style={{border: '1px solid #f1eded'}}
            onClick={() => {
              props.handleCommand(item, 'followUp');
              //   setItemOfActionData(item);
              //   setShowComponent('actionDetails');
            }}>
            <p className="p-bold mb-2 single-line-text">{item.title}</p>
            <div className="d-flex align-items-center justify-content-between followed-date">
              <p className="p-reg m-0">
                {moment(item.due_date, 'YYYY-MM-DD HH:mm').format(
                  'DD/MM/YY hh:mmA',
                )}
              </p>
              <span className="form-icon send-btn">
                <i className="icon-plane"></i>
              </span>
            </div>
          </div>
        ))
      ) : !showLoader && (
        <div className="text-center mt-5">

          <div className='mb-2'>
          No Record Found
          </div>
          <div className="btn-wrapper mt-3">
            <Button
              className="btn btn-dark"
              onClick={() => {
                props.handleCommand(
                  {followUpType: 'follow_up', date: ''},
                  'create_followUp',
                );
              }}>
              Create Follow Up
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileFollowUp;
