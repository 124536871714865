import {
    GET_ALL_CATEGORIES,
    GET_ALL_REGIONS,
    GET_MY_CAUSES,
    GET_MY_SUBSCRIPTIONS,
    GET_MY_PAYMENTS,
    UPDATE_MY_SUBSCRIPTION,
    GET_HOME_CAUSES,
    GET_CATEGORY_CAUSES,
    ADDMORE_CATEGORY_CAUSES,
    UPDATE_MY_CAUSES,
    REMOVE_MY_CAUSES,
} from "../../Constants/Types";

export const allCauseCategories = (state = {}, action = {}) => {
    switch (action.type) {
        case GET_ALL_CATEGORIES:
            return action.categories;

        default:
            return state;
    }
};

export const allCauseRegions = (state = {}, action = {}) => {
    switch (action.type) {
        case GET_ALL_REGIONS:
            return action.regions;

        default:
            return state;
    }
};

export const myCauses = (state = [], action = {}) => {
    let causes;
    switch (action.type) {
        case GET_MY_CAUSES:
            causes = action.causes;

            return causes;
        case UPDATE_MY_CAUSES:
            causes = state.map((cause) => {
                if (cause.id === action.cause.id) return { ...cause, ...action.cause };
                return cause;
            });
            return causes;
        case REMOVE_MY_CAUSES:
            causes = state.filter((cause) => {
                return cause.id !== action.cause.id;
            });
            return causes;
        default:
            return state;
    }
};

export const homeCauses = (state = [], action = {}) => {
    let causes;
    switch (action.type) {
        case GET_HOME_CAUSES:
            causes = [...state];
            const newCauses = action.causes;

            if (Object.entries(causes).length > 0) {
                if (Object.entries(newCauses).length > 0) {
                    for (let i = 0; i < Object.entries(newCauses).length; i++) {
                        let monthName = newCauses[i].month;
                        let isMonthIn = false;

                        for (let j = 0; j < Object.entries(causes).length; j++) {
                            if (monthName === causes[j].month) {
                                isMonthIn = true;
                                // Category Check

                                const newCateCount = Object.entries(newCauses[i].categories).length;
                                const oldCateCount = Object.entries(causes[j].categories).length;

                                if (newCateCount > 0) {
                                    for (let nc = 0; nc < newCateCount; nc++) {
                                        const newCategoryId = newCauses[i].categories[nc].id;
                                        let isCateExist = false;

                                        for (let oc = 0; oc < oldCateCount; oc++) {
                                            const oldCategoryId = causes[j].categories[oc].id;

                                            if (newCategoryId === oldCategoryId) {
                                                causes[j].categories[oc].causes = newCauses[i].categories[nc].causes;
                                                isCateExist = true;
                                            }
                                        }

                                        if (!isCateExist) {
                                            causes[j].categories.push(newCauses[i].categories[nc]);
                                        }
                                    }
                                }
                            }
                        }

                        if (!isMonthIn) {
                            causes.unshift(newCauses[i]);
                        }
                    }
                }

                // causes = [...newCauses, ...causes];
            } else {
                causes = action.causes;
            }
            return causes;
        default:
            return state;
    }
};

export const categoryCauses = (state = [], action = {}) => {
    let causes;
    switch (action.type) {
        case GET_CATEGORY_CAUSES:
            return action.causes;
        case ADDMORE_CATEGORY_CAUSES:
            causes = [...state];
            const newCauses = action.causes;

            if (causes && Object.entries(causes).length > 0) {
                if (Object.entries(newCauses).length > 0) {
                    for (let i = 0; i < Object.entries(newCauses).length; i++) {
                        let monthName = newCauses[i].month;
                        let isMonthIn = false;

                        for (let j = 0; j < Object.entries(causes).length; j++) {
                            if (monthName === causes[j].month) {
                                isMonthIn = true;
                                // Category Check

                                causes[j].causes = newCauses[i].causes;
                            }
                        }

                        if (!isMonthIn) {
                            causes.unshift(newCauses[i]);
                        }
                    }
                }
            }
            return causes;
        default:
            return state;
    }
};

export const myPayments = (state = [], action = {}) => {
    switch (action.type) {
        case GET_MY_PAYMENTS:
            return action.payments;
        default:
            return state;
    }
};

export const subscriptions = (state = {}, action = {}) => {
    let subscriptions;
    switch (action.type) {
        case GET_MY_SUBSCRIPTIONS:
            subscriptions = action.subscriptions;
            return subscriptions;
        case UPDATE_MY_SUBSCRIPTION:
            subscriptions = state.map((subscription) => {
                if (subscription.id === action.subscription.id) return { ...subscription, ...action.subscription };
                return subscription;
            });
            return subscriptions;

        default:
            return state;
    }
};
