import React, {useState,useEffect,useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import moment from 'moment/moment';
import {errorMessage} from '../../Utils/ToastMessages';
import CustomModal from '../CustomModal';
import AlertModel from './AlertModel';
import CustomDropdown from '../CustomDropdown';
import TimePicker from 'rc-time-picker';
import Calender from '../FeedBack/Calender';
import { getMaxDateFromToday } from '../../Utils/commonUtils';
import {parseISO} from 'date-fns';

const weekDays = [
  {label: 'S', value: 'SU'},
  {label: 'M', value: 'MO'},
  {label: 'T', value: 'TU'},
  {label: 'W', value: 'WE'},
  {label: 'R', value: 'TH'},
  {label: 'F', value: 'FR'},
  {label: 'S', value: 'SA'},
  // { id: 'SU', label: 'S', title: 'Sunday',  },
  // { id: 'MO', label: 'M', title: 'Monday',  },
  // { id: 'TU', label: 'T', title: 'Tuesday',  },
  // { id: 'WE', label: 'W', title: 'Wednesday',  },
  // { id: 'TH', label: 'T', title: 'Thursday', },
  // { id: 'FR', label: 'F', title: 'Friday', },
  // { id: 'SA', label: 'S', title: 'Saturday',}
];

const TicketDateOption = props => {
  const [show, setShow] = useState(props.show);
  const [exDate, setExDate] = useState('');
  const [showDates, setshowDates] = useState(false);
  const [showModel, setShowModel] = useState('');
  const [itemOfDates, setItemOfDates] = useState('');
  const [startPickerOpen, setStartPickerOpen] = useState(false);
  const [endPickerOpen, setEndPickerOpen] = useState(false);
  const ref = useRef(null);
  const weekDaysDropdown = [
    {id: '1', name: '1'},
    {id: '2', name: '2'},
    {id: '3', name: '3'},
    {id: '4', name: '4'},
  ];

  const weekMonth = [
    {id: 'week', name: 'Week'},
    {id: 'month', name: 'Month'},
  ];

  const repeatValuesDropdown = [
    {id: '1', name: '1st'},
    {id: '2', name: '2nd'},
    {id: '3', name: '3rd'},
    {id: '4', name: '4th'},
    {id: '5', name: '5th'},
    {id: '6', name: '6th'},
  ];

  const occurenceDropdown = [
    {id: '1', name: '1'},
    {id: '2', name: '2'},
    {id: '3', name: '3'},
    {id: '4', name: '4'},
  ];

  const updateDays = value => {
    if (!props.selectedDays.includes(value)) {
      // selectedDays.push(value);
      props.selectWeekDay(current => [...current, value]);
      // selectWeekDay(selectedDays);
    } else {
      props.selectWeekDay(props.selectedDays.filter(item => item !== value));
    }
  };

  const onClose = () => {
    setShow(false);
    props.onClose();
  };
  let today = moment().format('YYYY-MM-DD');
  const handle = value => {
    if (!props.exculdedDates.includes(value)) {
      props.setExculdedDates(current => [...current, value]);
      setExDate('');
    } else {
      // props.setExculdedDates( props.exculdedDates.filter(item => item !== value));
      errorMessage('Already selected');
    }
  };
  const resetValues = () => {
    setExDate('');
    props.setExculdedDates([]);
    props.selectWeekDay([]);
    props.setStartAgainAfter('1');
    props.setWeekMonth('week');
    props.setOccurrences('1');
  };
  const modelHandle = () => {
    if (!props.selectedDays.length > 0) {
      errorMessage('select a day first');
    } else {
      props.onClose();
    }
  };


  // const dt = moment.utc(props.startDateTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ');

  let maxDate = moment().format('2099-12-30');

  return (
    <>
      {show && (
        <CustomModal
          size="small"
          className={'recursion-modal'}
          onClose={() => {
            onClose();
            resetValues();
          }}>
          <div className="ticket-dates-wrapper overflow-hidden meetup_date_repeat" ref={ref}>
            <Form className="h-100">
              <div className="ticket-dates-wrapper" onScroll={()=>{
                setStartPickerOpen(false);
                setEndPickerOpen(false);
              }}>
                <div className="fieldset">
                  <Form.Label>Date</Form.Label>
                  {/* <Form.Control
                    name="date"
                    placeholder="Start Date and Time"
                    readOnly={true}
                    value={props.startDateTime}
                  /> */}

                  {/* <Form.Control
                    value={props.date}
                    min={moment(props.serverTimeS).format('YYYY-MM-DD')}
                    max={maxDate}
                    name="startDate"
                    type="date"
                    placeholder="Select date "
                    onChange={e => {
                      props.setDate(e.target.value);
                    }}
                  /> */}

                    <Calender 
                        minDate={props.serverTimeS}
                        maxDate={getMaxDateFromToday}
                        setSelectedDate={(selectedDate) => {
                          if(selectedDate){
                          props.setDate(selectedDate); // Set the formatted date
                          }else{
                            props.setDate('')
                          }
                      }}
                        selectedDate={props.date ? new Date(props.date) : null} // Use parseISO to convert to Date
                        showDate={true} 
                        showTime={false}
                        type='meetups'
                        />

                  
                </div>
                <div className="fieldset">
                  <Form.Label>Start Time</Form.Label>
                  {/* <Form.Control
                    required={props.topIcons.focus ? true : false}
                    value={props.timeStart}
                    min={props.startTimeOfeventServer}
                    name="startTime"
                    type="time"
                    placeholder="Start Time"
                    onChange={e => {
                      props.setTimeStart(e.target.value);
                    }}
                  /> */}

                  {/* <TimePicker
                    showSecond={false}
                    value={props.timeStart ==='' ? '': moment(props.timeStart,'HH:mm:ss')}
                    onChange={e => {


                      props.setTimeStart(moment(e, 'HH:mm').format('HH:mm'));
                    }}
                    min={props.startTimeOfeventServer}
                    format="h:mm a"
                    use12Hours
                    placeholder="--:-- --"
                    allowEmpty={false}
                    placement="bottomLeft"
                    open={startPickerOpen}
                    onOpen={() => {
                      setStartPickerOpen(true);
                    }}
                    onClose={() => {
                      setStartPickerOpen(false);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.timeStart === ''
                      ? 'Time is required.'
                      : 'Invalid time.'}
                  </Form.Control.Feedback> */}

                    <Calender 
                        type='singleTime' 
                        setStartDaysTime={e => {
                          if(e){
                            props.setTimeStart(moment(e, 'HH:mm A').format('HH:mm A'));
                          }else{
                            props.setTimeStart('')
                          }
                        }} 
                        startDaysTime={props.timeStart === ''
                          ? ''
                          : moment(props.timeStart, 'hh:mm A')} // Ensure the moment object is valid before passing
                        showDate={false} 
                        showTime={true} 
                        setSelectedDate={props.setDate} 
                        selectedDate={props.date} 
                        serverTimeS={props.serverTimeS}
                        closeDaysTime={props.timeEnd}
                      />
                </div>

                <div className="fieldset">
                  <Form.Label>End Time</Form.Label>
                  {/* <Form.Control
                    required={props.topIcons.focus ? true : false}
                    min={moment(props.timeStart).format('hh:mm')}
                    value={props.timeEnd}
                    name="endTime"
                    type="time"
                    placeholder="End Time"
                    onChange={e => {
                      props.setTimeEnd(e.target.value);
                      // EventData();
                    }}
                  /> */}
                  {/* <TimePicker
                    showSecond={false}
                    value={props.timeEnd === '' ? '' : moment(props.timeEnd,'HH:mm:ss')}
                    onChange={e => {

                      props.setTimeEnd(moment(e, 'HH:mm').format('HH:mm'));
                      // props.setTimeEnd(e);
                    }}
                    min={moment(props.timeStart,'HH:mm:ss')}
                    format="h:mm a"
                    use12Hours
                    placeholder="--:-- --"
                    allowEmpty={false}
                    placement="bottomLeft"
                    open={endPickerOpen}
                    onOpen={() => {
                      setEndPickerOpen(true);
                    }}
                    onClose={() => {
                      setEndPickerOpen(false);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.timeEnd === ''
                      ? 'Time is required.'
                      : 'Invalid Time.'}
                  </Form.Control.Feedback> */}

              <Calender
                    type='singleTime' 
                    setCloseDaysTime={e => {
                      if(e){
                        props.setTimeEnd(moment(e, 'HH:mm A').format('HH:mm A'));
                      }else{
                        props.setTimeEnd('')
                      }
                    }} 
                    minTime={props.startTimeOfeventServer} 
                    closeDaysTime={props.timeEnd === ''
                      ? ''
                      : moment(props.timeEnd, 'hh:mm A')} // Ensure the moment object is valid before passing
                    showDate={false} 
                    showTime={true} 
                    checkTime='checkTime'
                    startDaysTime={props.timeStart ? props.timeStart : ''}
                    selectedDate={props.date} 
                  />

                </div>
                <div className="fieldset">
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label>Repeat every</Form.Label>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="By day"
                      value={props.isRepeat ? 'yes' : 'no'}
                      onClick={() => props.setRepeat(!props.isRepeat)}
                    />
                  </div>

                  {!props.isRepeat && (
                    <Row>
                      <Col md={4}>
                        {/* <div className="select-wrapper">
                          <select
                            onChange={e => {
                              props.setStartAgainAfter(e.target.value);
                            }}
                            value={props.startAgainAfter}>
                            <option value={'1'}>1</option>
                            <option value={'2'}>2</option>
                            <option value={'3'}>3</option>
                            <option value={'4'}>4</option>
                          </select>
                        </div> */}

                        <CustomDropdown
                          setDropdownId={props.setStartAgainAfter}
                          dropdownId={props.startAgainAfter}
                          DropDownValues={weekDaysDropdown}
                          itemName={['name']}
                          className={'repeat_date_dropdown'}
                        />
                      </Col>
                      <Col md={4}>
                        {/* <div className="select-wrapper">
                          <select
                            onChange={e => {
                              props.setWeekMonth(e.target.value);
                            }}
                            value={props.weekMonth}>
                            <option value={'week'}>Week</option>
                            <option value={'month'}>Month</option>
                          </select>
                        </div> */}

                        <CustomDropdown
                          setDropdownId={props.setWeekMonth}
                          dropdownId={props.weekMonth}
                          DropDownValues={weekMonth}
                          itemName={['name']}
                          className={'repeat_date_dropdown'}
                        />
                      </Col>
                      <Col md={4}></Col>
                    </Row>
                  )}
                  {props.isRepeat && (
                    <Row>
                      <Col md={12}>
                        {/* <div className="select-wrapper mt-2">
                          <select
                            onChange={e => {
                              props.setStartAgainAfter(e.target.value);
                            }}
                            value={props.startAgainAfter}>
                            <option value={'1'}>1st</option>
                            <option value={'2'}>2nd</option>
                            <option value={'3'}>3rd</option>
                            <option value={'4'}>4th</option>
                            <option value={'5'}>5th</option>
                            <option value={'6'}>6th</option>
                          </select>
                        </div> */}

                        <CustomDropdown
                          setDropdownId={props.setStartAgainAfter}
                          dropdownId={props.startAgainAfter}
                          DropDownValues={repeatValuesDropdown}
                          itemName={['name']}
                          className={'mt-2 repeat_date_dropdown'}
                        />
                      </Col>

                      <Col md={5}></Col>
                    </Row>
                  )}
                </div>
                <div className="fieldset">
                  <Form.Label>On</Form.Label>
                  <div className="week-days">
                    {weekDays.map((item, index) => {
                      const isActive = props.selectedDays.includes(item.value)
                        ? 'active'
                        : '';
                      return (
                        <span
                          key={`week-day-${index}`}
                          className={`day ${isActive}`}
                          onClick={() => updateDays(item.value)}>
                          {item.label}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <div className="fieldset">
                  <Form.Label>And end after</Form.Label>
                  <Row>
                    <Col md={12}>
                      {/* <div className="select-wrapper">
                        <select
                          onChange={e => {
                            props.setOccurrences(e.target.value);
                          }}
                          value={props.occurrences}>
                          <option value={'1'}>1</option>
                          <option value={'2'}>2</option>
                          <option value={'3'}>3</option>
                          <option value={'4'}>4</option>
                        </select>
                      </div> */}

                      <CustomDropdown
                        setDropdownId={props.setOccurrences}
                        dropdownId={props.occurrences}
                        DropDownValues={occurenceDropdown}
                        itemName={['name']}
                        className={'repeat_date_dropdown'}
                      />
                    </Col>
                    {/* <Col md={5}><Form.Label>Occurrence(s)</Form.Label></Col> */}
                  </Row>
                </div>
                <div className="fieldset">
                  <Form.Label>Occurence(s)</Form.Label>
                </div>
                <div className="fieldset">
                  <Form.Label>
                    Repeats:{' '}
                    {props.isRepeat
                      ? props.startAgainAfter === '1'
                        ? 'Every 1st of each month,'
                        : props.startAgainAfter === '2'
                        ? 'Every 2nd of each month,'
                        : props.startAgainAfter === '3'
                        ? 'Every 3rd of each month,'
                        : props.startAgainAfter === '4'
                        ? 'Every 4th of each month,'
                        : props.startAgainAfter === '5'
                        ? 'Every 5th of each month,'
                        : props.startAgainAfter === '6'
                        ? 'Every 6th of each month,'
                        : ''
                      : props.weekMonth.charAt(0).toUpperCase() +
                        props.weekMonth.slice(1) +
                        'ly'}{' '}
                    {props.selectedDays.length > 0 &&
                      !props.isRepeat &&
                      `on ${props.selectedDays.join(', ')}, `}
                    starting from{' '}
                    {moment(props.startDateTime).format('MMM DD, YYYY')}, for{' '}
                    {props.occurrences} occurence.
                  </Form.Label>
                </div>
                <div className="fieldset mb-0">
                  <Form.Label
                    onClick={() => {
                      setshowDates(!showDates);
                    }}>
                    Exlude dates
                  </Form.Label>

                  <Row>
                    <Col md={8}>
                      <Form.Control
                        name="date"
                        type="date"
                        min={today}
                        placeholder="Start Date and Time"
                        value={exDate}
                        onChange={e => {
                          setExDate(e.target.value);
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <Button
                        className="exclude-button"
                        disabled={exDate ? false : true}
                        onClick={() => {
                          handle(exDate);
                        }}>
                        Exclude
                      </Button>
                    </Col>
                  </Row>
                  {props.exculdedDates.length > 0 && (
                    <div className="excluded-dates-wrapper">
                      {props.exculdedDates?.map((item, index) => {
                        return (
                          <div key={item + 'asdwe'} className="excluded-item">
                            {moment(item).format('MM/DD/YY')}
                            <span className="remove-item">
                              <i
                                className="fas fa-times"
                                onClick={() => {
                                  setItemOfDates(item);
                                  setShowModel('image_remove_alert');
                                }}></i>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="fieldset mt-4 mb-0 text-center">
                <Button
                  className="btn btn-dark "
                  onClick={modelHandle}
                  disabled={
                    props.date === '' ||
                    props.timeStart === '' ||
                    props.timeEnd === ''
                  }>
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </CustomModal>
      )}
      {showModel === 'image_remove_alert' && (
        <AlertModel
          // modelImg={modelImg}
          onClose={() => {
            setShowModel('');
          }}
          type={'recursion'}
          removeImage={() => {
            props.setExculdedDates(
              props.exculdedDates.filter(mini => mini !== itemOfDates),
            );
          }}
        />
      )}
    </>
  );
};

export default TicketDateOption;
