import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const LoaderSpinner = props => {
  return (
    <div
      className={
        props.hasOwnProperty('className') ? props.className : 'spinner'
      }
      style={props.firstDivStyle}>
      <Spinner animation="border" />
    </div>
  );
};

export default LoaderSpinner;
