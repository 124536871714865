import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {successMessage} from '../../Utils/ToastMessages';

import {useEffect} from 'react';
import Invite from '../SingleComponent/Invite';
import LoaderSpinner from '../Modals/LoaderSpinner';

const Sponsors = props => {
  const [validated, setValidated] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  // const [ticketTitle, setTicketTitle] = useState('');
  const [ticketCost, setTicketCost] = useState('');
  const [saleEnd, setSaleEnd] = useState('');
  const [fee, setFee] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [tabType, setTabType] = useState('option');
  const [maximumAllowedperUser, setMaximumAllowedperUser] = useState('');
  const [limitPerUser, setLimitPerUser] = useState('');
  const [feeRange, setFeeRange] = useState(false);
  const [builders, setBuilders] = useState('');
  const [allBuilders, setAllBuilders] = useState([]);
  const [contact, setContact] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [showComponent, setShowComponent] = useState('');

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const handleEdit = index => {
    setEditIndex(index);
    // setTicketTitle(props.array[index].title);
    setTicketDescription(props.array[index].description);
    setTicketCost(props.array[index].cost);
    setFee(props.array[index].maximum_tickets);
    setSaleEnd(props.array[index].sale_end);
  };

  const handle = e => {
    const form = e.currentTarget;
    //
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      //
    } else {
      e.preventDefault();
      if (editIndex === -1) {
        // Adding a new ticket
        // const totalMaxTickets = props.array.reduce(
        //   (total, ticket) => total + ticket.maximum_tickets,
        //   0,
        // );
        // totalMaxTickets
        // if (totalMaxTickets + parseInt(maxTickets) <= props.ticket) {
        props.setArray([
          ...props.array,
          {
            no_of_allowed_sponsors: maximumAllowedperUser,
            fee: fee,
            require_approval: saleEnd,
            user_ids: builders.toString(','),
            description: ticketDescription,
          },
        ]);
        successMessage('Add ticket succesfully');
        setTimeout(() => {
          // e.target.reset();
          handleResetForm();
        }, 100);
        setValidated(false);
        // } else {
        //   errorMessage(
        //     'Not enough tickets available. Update your tickets options ',
        //   );
        // }
      } else {
        // Editing an existing ticket
        const newTickets = [...props.array];
        newTickets.reduce((total, ticket, index) => {
          if (index === editIndex) {
            return total + parseInt(fee);
          } else {
            return total + ticket.maximum_tickets;
          }
        }, 0);
        // if (totalMaxTickets <= props.ticket) {
        newTickets[editIndex] = {
          no_of_allowed_sponsors: maximumAllowedperUser,
          fee: ticketCost,
          require_approval: saleEnd,
          user_ids: builders.toString(','),
          description: ticketDescription,
        };
        props.setArray(newTickets);
        setEditIndex(-1);
        successMessage('Edit ticket succesfully');
        setTimeout(() => {
          // e.target.reset();
          handleResetForm();
        }, 100);
        setValidated(false);
        // } else {
        //   errorMessage(
        //     'Not enough tickets available. Update your tickets options ',
        //   );
        // }
      }
    }
  };

  useEffect(() => {
    handleResetForm();
  }, [tabType]);

  const handleResetForm = () => {
    // setTicketTitle('');
    setSaleEnd('');
    setFee('');
    setTicketDescription('');
    setTicketCost('');
    setUpdateId(0);
    setMaximumAllowedperUser('');
  };

  const handleDelete = index => {
    const newTickets = [...props.array];
    newTickets.splice(index, 1);
    props.setArray(newTickets);
  };

  const copy = item => {
    // setTicketTitle(item.title);
    setSaleEnd(item.sale_end);
    setFee(item.maximum_tickets);
    setTicketDescription(item.description);
    setTicketCost(item.cost);
  };

  // let today = moment(props.serverTimeS).format('YYYY-MM-DD hh:mm');

  // const handleOnClose = () => {
  //   props.onClose();
  //   // const totalTickets = props.array;
  //   // let sum = 0;

  //   // totalTickets.forEach(element => {
  //   //   sum += parseInt(element.maximum_tickets);
  //   // });

  //   // if (sum === parseInt(props.ticket)) {
  //   //   props.onClose();
  //   // } else {
  //   //   let subtract;
  //   //   subtract = props.ticket - sum;
  //   //   errorMessage(
  //   //     'You still have ' +
  //   //       subtract +
  //   //       ' tickets available. Update your ticket option or create an additional ticket. ',
  //   //   );
  //   // }
  // };

  // const handleInputChange = event => {
  //   const userInput = event.target.value;
  //   const sanitizedValue = userInput.replace(/^0+/, '');
  //   setTicketCost(sanitizedValue);
  // };
  const handleInputChangeMaxtickets = event => {
    const userInput = event.target.value;
    const sanitizedValue = userInput.replace(/^0+/, '');
    setFee(sanitizedValue);
  };

  const handleCircle = () => {
    setShowComponent('CircleList');
  };
  useEffect(() => {
    setMaximumAllowedperUser('');
  }, [limitPerUser]);

  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  return (
    <div className="serach-filter-wrapper">
      <div className="event-tabs tabs-style-2 mt-2 mb-3">
        <ul>
          <li>
            <Button
              className={tabType === 'option' ? 'active' : ''}
              onClick={() => {
                setTabType('option');
              }}>
              Option
            </Button>
          </li>
          <li>
            <Button
              className={tabType === 'invites' ? 'active' : ''}
              onClick={() => {
                setTabType('invites');
              }}>
              Invites
            </Button>
          </li>
        </ul>
      </div>

      <div className="create-ticket-wrapper fix-width-forms">
        {/* <div
          className="text center"
          onClick={() => {
            handleOnClose();
          }}>
          <i class="fa fa-times" aria-hidden="true"></i>
        </div> */}
        <>
          {tabType === 'option' && (
            <div className="create-ticket-form">
              <Form noValidate validated={validated} onSubmit={handle}>
                <div className="fieldset">
                  <div className="d-flex justify-content-between align-items-center">
                    <Form.Label>Description </Form.Label>
                    <div className="">
                      <Form.Check
                        className=""
                        type="switch"
                        id="custom-switch"
                        label={'Require Approval'}
                        value={limitPerUser ? true : false}
                        checked={limitPerUser ? true : false}
                        onClick={() => {
                          setLimitPerUser(!limitPerUser);
                        }}
                      />
                    </div>
                  </div>
                  <Form.Control
                    required
                    as="textarea"
                    rows={4}
                    // id="description"
                    // name="description"
                    value={ticketDescription}
                    type="text"
                    placeholder="Sum up the benefits for sponsoring "
                    onChange={e => {
                      setTicketDescription(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Discription is required.
                  </Form.Control.Feedback>
                </div>
                <div className="fieldset">
                  <Form.Label>Total Sponsors Allowed</Form.Label>

                  <Form.Control
                    required
                    value={maximumAllowedperUser}
                    type="number"
                    min={1}
                    placeholder="Enter max number"
                    onChange={e => {
                      setMaximumAllowedperUser(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Invalid total sponsors allowed
                  </Form.Control.Feedback>
                </div>

                <div className="fieldset">
                  <div className="d-flex justify-content-between align-items-center">
                    <Form.Label>Fee</Form.Label>
                    <div className="">
                      <Form.Check
                        className=""
                        type="switch"
                        id="custom-switch"
                        label={'Use Range'}
                        value={feeRange ? true : false}
                        checked={feeRange ? true : false}
                        onClick={() => {
                          setFeeRange(!feeRange);
                        }}
                      />
                    </div>
                  </div>
                  <Form.Control
                    required
                    value={fee}
                    type="number"
                    min={1}
                    placeholder="Enter amount in US Dollars"
                    onChange={handleInputChangeMaxtickets}
                  />
                  <Form.Control.Feedback type="invalid">
                    Invalid amount
                  </Form.Control.Feedback>
                </div>

                {updateId === 0 ? (
                  <div className="fieldset event-btn-wrapper">
                    <Button className="" type="submit">
                      Add Sponsor Option
                    </Button>
                  </div>
                ) : (
                  <div className="fieldset event-btn-wrapper">
                    <Button className="" type="submit">
                      Update Sponsor Option
                    </Button>
                  </div>
                )}
              </Form>

              <div className="session-wrapper mt-3">
                {props.array.length > 0 &&
                  Object.entries(props.array).length > 0 &&
                  props.array.map((item, index) => (
                    <div className="events-item" key={`shedule-${index}`}>
                      <div className="session-heading">
                        <div className="event-titles">
                          <h2>{item.title}</h2>
                          <div className="event-date">
                            {item.fee === '' || item.fee === 0
                              ? 'Free'
                              : '$' + item.fee}
                          </div>
                          <div className="event-date">{item.description}</div>
                        </div>
                      </div>
                      <div className="events-place-like">
                        <span className="address as-link">
                          {item.no_of_allowed_sponsors} {item.require_approval}
                        </span>
                        <div className="search-tabs-icons">
                          <span
                            className="icon active"
                            onClick={() => {
                              handleDelete();
                            }}>
                            <i className="fa-solid fa-trash-can"></i>
                          </span>
                          <span
                            className="icon active"
                            onClick={() => {
                              handleEdit(index);
                              setUpdateId(item.id);
                            }}>
                            <i className="fa-solid fa-pen-to-square"></i>
                          </span>
                          <span
                            className="icon active"
                            onClick={() => {
                              copy(item);
                              setUpdateId(0);
                            }}>
                            <i className="fa-solid fa-copy"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {tabType === 'invites' && (
            <>
              <div className="refferal-list-wrapper">
                {inviteLoader && (
                  <div className="invite_loader_hieght">
                    <LoaderSpinner />
                  </div>
                )}
                <Invite
                  type={'refer'}
                  moderator={builders}
                  setModerator={setBuilders}
                  selectedModData={allBuilders}
                  setSelectedModData={setAllBuilders}
                  contact={contact}
                  setContact={setContact}
                  originalDataOfMod={originalDataOfMod}
                  setOriginalDataOfMod={setOriginalDataOfMod}
                  handleCircle={handleCircle}
                  forApiHit={showComponent}
                  onClick={() => {
                    setShowComponent('inviteUser');
                    setTimeout(() => {
                      if (contact.length === 0 && inviteQry !== '') {
                        setInviteFollowers(followersList);
                        setInviteQry('');
                      }
                    }, 1000);
                  }}
                  showLoader={inviteLoader}
                  setshowLoader={setInviteLoader}
                  followers={inviteFollowers}
                  setFollowers={setInviteFollowers}
                  setFollowersList={setFollowersList}
                  followersList={followersList}
                  setSearchQry={setInviteQry}
                  searchQry={inviteQry}
                />
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};
export default Sponsors;
