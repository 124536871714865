import React from 'react';
import {Col, Form, Row, Dropdown} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import {useEffect, useState} from 'react';
import {RandomImg, rewardStar} from '../../Constants/Images';
import Moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {FireIcon, MachineIcon} from '../../Constants/Images';
import Slider from 'react-slick';
import CustomSlidingButtons from '../../Components/CustomSlidingButtons';

const DispatchScreen = props => {
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [type, setType] = useState('1');
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const listForDropDown = [
    // {title: 'All', id: '1'},
    {title: 'Pending', id: '1'},
    {title: 'Assigned', id: '2'},
    {title: 'Rescheduled', id: '3'},
    {title: 'Cancelled', id: '4'},
  ];

  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[1].id]);
  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);
  };

  const dispatchSliderData = [
    {
      service: 'Catalog Service',
      time: '05/12/24  6AM - 8AM',
      location: 'McLean, VA',
    },

    {
      service: 'Catalog Service',
      time: '05/12/24  6AM - 8AM',
      location: 'McLean, VA',
    },

    {
      service: 'Catalog Service',
      time: '05/12/24  6AM - 8AM',
      location: 'McLean, VA',
    },

    {
      service: 'Catalog Service',
      time: '05/12/24  6AM - 8AM',
      location: 'McLean, VA',
    },
    {
      service: 'Catalog Service',
      time: '05/12/24  6AM - 8AM',
      location: 'McLean, VA',
    },
  ];

  const timeSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  const dropdownMenu = [
    {id: '1', name: 'Department'},
    {
      id: '2',
      name: 'Department',
    },
    {
      id: '3',
      name: 'Department',
    },
    {
      id: '4',
      name: 'Department',
    },
  ];

  const timeSliderData = [
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    // '5 PM',
    // '6 PM',
    // '7 PM',
    // '8 PM',
    // '9 PM',
    // '10 PM',
    // '11 PM',
  ];

  let divStyle = {
    color: '#1A1818',
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Total Sales',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Opportunity',
      money: '$35k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Conversion Rate',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />

        <div className="page-content-wrapper ">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div>
              <div className="dispatch-screen">
                <div className="mt-3 mb-4" style={{display: 'flex'}}>
                  <div className="customer_list_dropdown">
                    <div className="roi-template-dropdown">
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setType(eventKey);
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {type
                              ? dropdownMenu.find(item => item.id === type).name
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {dropdownMenu.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === type}>
                                {item.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="input-group">
                      <Form.Control
                        type="text"
                        value={''}
                        pattern="^\S.*$"
                        required
                        placeholder="Search"
                        onChange={e => {}}
                      />
                    </div>
                  </div>

                  <div className="search-tabs-icons">
                    <i className="icon-filter-icon" onClick={() => {}} />
                  </div>
                </div>

                <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                  <CustomSlidingButtons
                    items={listForDropDown}
                    activeButtons={areas_Filter}
                    onItemClick={e => {
                      dropDownSelection(e);
                    }}
                    showItems={8}
                  />
                </div>

                <Scrollbars
                renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                // Hide the vertical scrollbar
                renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}
                  style={{
                    height: analycticsSlider
                      ? 'calc(100vh - 343px)'
                      : 'calc(100vh - 255px)',
                  }}
                  autoHide>
                  <div className="dispatch-inner-content mt-2">
                    <div className="catalog_flex  mr-5">
                      <div className="messages-header mb-2">
                        {/* <div className="event-tabs dark-tabs"> */}
                        <div></div>
                        <div className="event-tabs tabs-style-2">
                          {' '}
                          {/* Merging */}
                          <div className="appointment-date-wrapper ">
                            <div className="date-wrapper">
                              <span
                                onClick={() => {
                                  setStartDate(
                                    new Date(
                                      Moment(startDate).add(-1, 'months'),
                                    ),
                                  );
                                }}
                                className="navs fas fa-chevron-left"
                                style={divStyle}
                              />
                              <div className="selected-date">
                                <span
                                  className=" as-link"
                                  onClick={()=>{}}>
                                  {Moment(startDate)
                                    .format(' MMMM, YYYY')
                                    .toString()}
                                </span>{' '}
                              </div>

                              <span
                                className="navs fas fa-chevron-right"
                                onClick={() => {
                                  setStartDate(
                                    new Date(
                                      Moment(startDate).add(1, 'months'),
                                    ),
                                  );
                                }}
                                style={divStyle}
                              />
                            </div>
                          </div>
                        </div>

                        <div></div>
                      </div>

                      {dispatchSliderData.length > 0 &&
                        dispatchSliderData.map((item, index) => (
                          <div className="dispacth_catalog_card">
                            <div className="catalog_card_cotent">
                              <h2>{item.service}</h2>

                              <p>{item.time}</p>
                              <p>{item.location}</p>
                              <div className="roi-service-icons">
                                <div className="service-icon w-25">
                                  <img src={FireIcon} alt="" />
                                </div>

                                <div className="service-icon w-29">
                                  <img src={MachineIcon} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>

                    <div className="dispatch_slider">
                      <div className="messages-header mb-2">
                        <div className="event-tabs tabs-style-2">
                          {' '}
                          {/* Merging */}
                          <div className="appointment-date-wrapper ">
                            <div className="date-wrapper">
                              {/* <span
                              onClick={() => {
                                setStartDate(
                                  new Date(Moment(startDate).add(-1, 'days')),
                                );
                              }}
                              className="navs fas fa-chevron-left"
                              style={divStyle}
                            /> */}
                              <div className="selected-date">
                                <span
                                  className=" as-link"
                                  onClick={()=>{}}>
                                  {Moment(startDate).isSame(Moment(), 'day')
                                    ? 'Today ' +
                                      Moment(startDate)
                                        .format(', MMMM D, YYYY')
                                        .toString()
                                    : Moment(startDate)
                                        .format('dddd, MMMM D, YYYY')
                                        .toString()}
                                </span>{' '}
                              </div>

                              {/* <span
                              className="navs fas fa-chevron-right"
                              onClick={() => {
                                setStartDate(
                                  new Date(Moment(startDate).add(1, 'days')),
                                );
                              }}
                              style={divStyle}
                            /> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <Row>
                        <Col md={2}>
                          <div className="dispacth-time">
                            <span className="percentage">67%</span>
                          </div>

                          <div className="dispatch-list mt-5">
                            {Array.from({length: 5}, (_, index) => (
                              <div className="dispatch_user_img">
                                <img src={RandomImg} alt="" />
                              </div>
                            ))}
                          </div>
                        </Col>

                        <Col md={10}>
                          <div className="mt-3">
                            <Slider {...timeSlider}>
                              {timeSliderData.length > 0 &&
                                timeSliderData.map((item, index) => (
                                  <div className="card-item">
                                    <span className="time-slot">{item}</span>

                                    <div
                                      class="user-content as-link mt-5"
                                      style={{height: 72, width: 133}}>
                                      {item !== '8 AM' &&
                                        item !== '12 PM' &&
                                        item !== '4 PM' && (
                                          <div className="payment-card qa-list-item">
                                            {(item === '5 AM' ||
                                              item === '9 AM' ||
                                              item === '1 PM') && (
                                              <div>
                                                <div class="message-user-item no-link">
                                                  <div class="user-content as-link">
                                                    <h2 class="single-line-text">
                                                      Arlington, Va
                                                    </h2>
                                                  </div>
                                                  <div class="message-other-users"></div>
                                                </div>
                                                <div className="roi-service-icons">
                                                  <div className="service-icon w-25">
                                                    <img
                                                      src={FireIcon}
                                                      alt=""
                                                    />
                                                  </div>

                                                  <div className="service-icon w-29">
                                                    <img
                                                      src={MachineIcon}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </div>

                                    <div
                                      class="user-content as-link mt-3"
                                      style={{height: 72, width: 173}}>
                                      {item !== '6 AM' &&
                                        item !== '8 AM' &&
                                        item !== '10 AM' &&
                                        item !== '12 PM' &&
                                        item !== '2 PM' &&
                                        item !== '4 PM' && (
                                          <div
                                            className="payment-card  qa-list-item"
                                            style={{width: '173px'}}>
                                            <div>
                                              <div class="message-user-item no-link">
                                                <div class="user-content as-link">
                                                  {/* <div className='card-title'>
                                         Arlington, Va
                                         </div> */}

                                                  <h2 class="single-line-text">
                                                    Arlington, Va
                                                  </h2>
                                                </div>
                                              </div>
                                              <div className="roi-service-icons">
                                                <div className="service-icon w-25">
                                                  <img src={FireIcon} alt="" />
                                                </div>

                                                <div className="service-icon w-29">
                                                  <img
                                                    src={MachineIcon}
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </div>

                                    <div
                                      class="user-content as-link mt-3"
                                      style={{height: 72, width: 133}}>
                                      {item !== '8 AM' &&
                                        item !== '12 PM' &&
                                        item !== '4 PM' &&
                                        item !== '7 AM' &&
                                        item !== '11 AM' &&
                                        item !== '3 PM' && (
                                          <div className="payment-card qa-list-item">
                                            {(item === '5 AM' ||
                                              item === '9 AM' ||
                                              item === '1 PM') && (
                                              <div>
                                                <div class="message-user-item no-link">
                                                  <div class="user-content as-link">
                                                    <h2 class="single-line-text">
                                                      Arlington, Va
                                                    </h2>
                                                  </div>
                                                  <div class="message-other-users"></div>
                                                </div>
                                                <div className="roi-service-icons">
                                                  <div className="service-icon w-25">
                                                    <img
                                                      src={FireIcon}
                                                      alt=""
                                                    />
                                                  </div>

                                                  <div className="service-icon w-29">
                                                    <img
                                                      src={MachineIcon}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </div>

                                    <div
                                      class="user-content as-link mt-3"
                                      style={{height: 72, width: 133}}>
                                      {item !== '8 AM' &&
                                        item !== '12 PM' &&
                                        item !== '4 PM' &&
                                        item !== '7 AM' &&
                                        item !== '11 AM' &&
                                        item !== '3 PM' && (
                                          <div className="payment-card qa-list-item">
                                            {(item === '5 AM' ||
                                              item === '9 AM' ||
                                              item === '1 PM') && (
                                              <div>
                                                <div class="message-user-item no-link">
                                                  <div class="user-content as-link">
                                                    <h2 class="single-line-text">
                                                      Arlington, Va
                                                    </h2>
                                                  </div>
                                                  <div class="message-other-users"></div>
                                                </div>
                                                <div className="roi-service-icons">
                                                  <div className="service-icon w-25">
                                                    <img
                                                      src={FireIcon}
                                                      alt=""
                                                    />
                                                  </div>

                                                  <div className="service-icon w-29">
                                                    <img
                                                      src={MachineIcon}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </div>

                                    <div
                                      class="user-content as-link mt-3"
                                      style={{height: 72, width: 133}}>
                                      {item !== '8 AM' &&
                                        item !== '12 PM' &&
                                        item !== '4 PM' &&
                                        item !== '7 AM' &&
                                        item !== '11 AM' &&
                                        item !== '3 PM' && (
                                          <div className="payment-card qa-list-item">
                                            {(item === '5 AM' ||
                                              item === '9 AM' ||
                                              item === '1 PM') && (
                                              <div>
                                                <div class="message-user-item no-link">
                                                  <div class="user-content as-link">
                                                    <h2 class="single-line-text">
                                                      Arlington, Va
                                                    </h2>
                                                  </div>
                                                  <div class="message-other-users"></div>
                                                </div>
                                                <div className="roi-service-icons">
                                                  <div className="service-icon w-25">
                                                    <img
                                                      src={FireIcon}
                                                      alt=""
                                                    />
                                                  </div>

                                                  <div className="service-icon w-29">
                                                    <img
                                                      src={MachineIcon}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                ))}
                            </Slider>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
                showHiddenPanel={showHiddenPanel}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DispatchScreen;
