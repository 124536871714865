"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticipantPermissions = exports.ParticipantInvited = exports.ParticipantInfo = void 0;
/**
 * The ParticipantInfo model contains information about conference participants, such as the
 * [external ID](#optional-externalid), [name](#optional-name), and [URL of the participant's avatar](#optional-avatarurl).
 */
class ParticipantInfo {
}
exports.ParticipantInfo = ParticipantInfo;
/**
 * The ParticipantInvited model includes information about the invited participant, such as participant's [information](#info) and [permissions](#permissions).
 */
class ParticipantInvited {
}
exports.ParticipantInvited = ParticipantInvited;
/**
 * The ParticipantPermissions model includes information about the participant's permissions. The permissions allow the invited participant to perform a specific set of actions within a protected conference.
 */
class ParticipantPermissions {
}
exports.ParticipantPermissions = ParticipantPermissions;
