"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @ignore
 */
class Event {
    constructor(type) {
        this.type = type;
    }
}
exports.default = Event;
