import {
  DEFAULT_IMAGE_COMPANYBANNER,
  DEFAULT_IMAGE_COMPANYLOGO,
  rewardStar,
} from '../../Constants/Images';

import {EditPencilBlack} from '../../Constants/Images';

const Branding = ({
  companyProfileData,

  permission,

  updateLogo,
  updateBanner,
}) => {
  //   const updateLogo = e => {
  //     if (e.target.files.length === 0) return false;
  //     const file = e.target.files[0];

  //     // If no image selected, return
  //     // || fileExtension === "gif"
  //     if (!/^image\//.test(file.type)) {
  //       errorMessage(`File is not an image.`);
  //       return false;
  //     } else if (allowedImageTypes.includes(file.type)) {
  //       setFile(file);

  //       setShowModel('updatelogo');

  //       e.target.value = null;
  //     }
  //   };

  return (
    <>
      <div className="serach-filter-wrapper editProfile branding_component">
        <div className="Business_profile_page mt-5">
          <div className="business_profile_top">
            <div className="company_profile_card mb-4">
              <div className="company_logo">
                <img
                  src={
                    companyProfileData.company_logo
                      ? companyProfileData.company_logo
                      : DEFAULT_IMAGE_COMPANYLOGO
                  }
                  alt=""
                />
              </div>

              <div className="company_content">
                <div className="company_name_flex">
                  <span className="company_title">
                    {companyProfileData.company_name
                      ? companyProfileData.company_name
                      : ''}
                  </span>
                  {permission &&
                    (permission === 'allow' ||
                      permission?.edit_company === true) && (
                      <span
                        className="edit_company_logo as-link"
                        onClick={() => {}}>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={e => updateLogo(e)}
                        />

                        <img height={16} src={EditPencilBlack} alt="edit" />
                      </span>
                    )}
                </div>

                <div className="company_rating_content">
                  <span className="rating">
                    4.8 <img src={rewardStar} alt="" />
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="view_more_btn">View More</div> */}
          </div>

          <div className="update_banner_card mb-3 mt-4">
            <div className="banner_img_card">
              <img
                src={
                  companyProfileData.image
                    ? companyProfileData.image
                    : DEFAULT_IMAGE_COMPANYBANNER
                }
                alt=""
              />
            </div>

            <span className="announmentt">
              <div style={{position: 'relative'}}>
                {permission &&
                  (permission === 'allow' ||
                    permission?.edit_company === true) && (
                    <>
                      <p>Update Banner</p>
                      <div className="banner_input">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={e => updateBanner(e)}
                        />
                      </div>{' '}
                    </>
                  )}
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Branding;
