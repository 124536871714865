/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { frankImg } from "../../Constants/Images";
import AppointmentCalender from "./AppointmentCalender";
import AppointmentProfileField from "./AppointmentProfileField";

class Appointment extends Component {
    state = {
        data: {
            time: "",
            name: "",
            email: "",
            notes: "",
            date: new Date(),
            eventType: "1-on-1",
            dropdownValue: "",
            appointmentDisable:[],
        },
        showProfileFields: false,
    };

    updateData = (data) => {
        const tempData = { ...this.state.data, ...data };

        this.setState({
            data: tempData,
        });
    };

    updateEvents = (value) => {
        this.setState({
            data: {
                ...this.state.data,
                eventType: value,
            },
        });
    };
   
    render() {
        
        
        const { data, showProfileFields} = this.state;
        
        return (
            <div className="appointment-content-wrapper">
                <Container>
                    <Row>
                        <Col md={4} sm={4} xs={12}>
                            <div className="appointment-block">
                                <div className="appointment-img">
                                    <img src={frankImg} alt="" />
                                    <span className="profile-share">
                                        <i className="icon-share-option" />
                                    </span>
                                </div>

                                <div className="appointment-content">
                                    <h2>Frank Goodman</h2>
                                </div>

                                <div className="appointment-extra-content">
                                    <div className="event-dropdown">
                                        <div className="select-wrapper">
                                            <select className="form-control">
                                                <option>Dropdown</option>
                                                <option>Dropdown 1</option>
                                                <option>Dropdown 2</option>
                                                <option>Dropdown 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h3>The Appointment Name</h3>
                                    <h4>Appointment Type</h4>
                                    <p>This is a description about whatever was selected.</p>

                                    <div className="event-info">
                                        <ul>
                                            <li>
                                                <i className="fas fa-calendar-days" />
                                                30 Minutes
                                            </li>
                                            <li>
                                                <i className="fas fa-dollar-sign" />
                                                $50
                                            </li>
                                        </ul>
                                    </div>
                                    <h4>Did you know…</h4>
                                    <p>Frank is a visionary and connector with a background in [background]</p>
                                    {/* <div className="event-btn">
                                            <Button>Contact</Button>
                                        </div> */}
                                </div>
                            </div>
                        </Col>

                        <Col md={8} sm={8} xs={12}>
                            {!showProfileFields && (
                                <AppointmentCalender
                                    data={data}
                                    updateData={(data) => this.updateData(data)}
                                    appointmentDisable={[]}
                                    showProfileField={() => this.setState({ showProfileFields: true })}
                                />
                            )}
                            {showProfileFields && (
                                <AppointmentProfileField
                                    data={data}
                                    updateData={(data) => this.updateData(data)}
                                    hideProfileField={() => this.setState({ showProfileFields: false })}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Appointment;
