import React, {useState} from 'react';
import {Button, Dropdown} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import Sidebar from '../../Pages/Sidebar';
import Pannels from '../Pannels';
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';

import {SliderPickupDrop} from '../../Constants/AnalyticsConstants';
import Slider from 'react-slick';

const PickDrop = props => {
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [type, setType] = useState('1');
  const dropdownMenu = [
    {id: '1', name: 'Select Service'},
    {
      id: '2',
      name: 'Service',
    },
    {
      id: '3',
      name: 'Service',
    },
    {
      id: '4',
      name: 'Service',
    },
  ];

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {SliderPickupDrop.length > 0 &&
                      SliderPickupDrop.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <Scrollbars autoHide>
                    <div className="pickDrop-page">
                      <div className="pickdrop-top mb-3">
                        <h1>Hey, Jeff</h1>
                        <div>What would you like us to pickup?</div>
                      </div>

                      <div className="pick-drop-content">
                        <div className="mb-3">
                          <div className="select-wrapper">
                            <Dropdown
                              onSelect={(eventKey, event) => {
                                setType(eventKey);
                              }}
                              drop="down">
                              <Dropdown.Toggle variant="primary">
                                {type
                                  ? dropdownMenu.find(item => item.id === type)
                                      .name
                                  : 'Select Role'}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {dropdownMenu.map((item, index) => (
                                  <Dropdown.Item
                                    key={`st-${index}`}
                                    eventKey={item.id.toString()}
                                    active={item.id === type}>
                                    {item.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        <div className="show-details mb-4">show details</div>

                        <div className="pick-up-location mb-4">
                          <h1 className=" mb-3"> Pick Up Location</h1>

                          <div className="pickup-location-input ">
                            Friday, May 16, 2023
                          </div>
                        </div>

                        <div className="pick-up-location mb-4">
                          <h1 className=" mb-3"> Pick Up Time</h1>

                          <div className="pickup-location-input">
                            Friday, May 16, 2023
                          </div>
                        </div>

                        <div className="location-time-btns">
                          <span className="time-btn">9 AM - 12 PM</span>
                          <span className="time-btn selected-time">
                            9 AM - 12 PM
                          </span>
                        </div>
                      </div>

                      <div className="schedule-btn mt-4">
                        <Button
                          className="new-btn btn-green "
                          onClick={() => {}}>
                          Schedule
                        </Button>
                      </div>
                    </div>
                  </Scrollbars>
                </>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PickDrop;
