import React from 'react';

const PrivacyPolicy = () => {

  const privacyStatement = [
    {
      heading: "Data Access",
      description: "We may access your data for the purpose of providing and improving our services. Access to personal data is limited to authorized personnel only."
    },
    {
      heading: "Data Collection",
      description: "We collect personal information such as your name and email address. This helps us understand user behavior and enhance user experience."
    },
    {
      heading: "Data Use",
      description: "Your data may be used to personalize content, provide customer support, and communicate updates about our services. We do not use your data for any purposes outside of those explicitly stated."
    },
    {
      heading: "Data Maintenance",
      description: "We implement reasonable security measures to protect your personal information from unauthorized access and maintain its accuracy. Data retention periods are established based on the necessity of its use."
    },
    {
      heading: "Data Sharing",
      description: "We do not sell or rent your personal information to third parties. We may share your data with trusted service providers who assist us in delivering our services, under strict confidentiality agreements."
    },
    {
      heading: "User Rights",
      description: "You have the right to access, modify, or delete your personal information at any time. Please contact us if you wish to exercise any of these rights."
    },
    {
      heading: "Commitment to Privacy",
      description: "Our commitment to privacy is at the core of our operations, ensuring that all personal information is handled with the utmost care and respect. By entrusting us with your data, you agree to the terms outlined in this Privacy Statement and help us in maintaining a secure and trustworthy environment for all users."
    }
  ];
  
  return (
    <div className="container mt-4 mb-4">
      <center>
        <img
          src="https://build-dev.s3.us-east-1.amazonaws.com/roi.png"
          alt="logo"
          style={{ width: '100px', height: 'auto' }}
        />
        <br />
        <b>Privacy Policy</b>
      </center>
      <p>
        Employee of Choice, Inc. is committed to protecting your privacy online. This Privacy Policy describes the personal information collected through our website at www.tryingtobuild.com ("Site") and our mobile application, and how we collect and use that information.
      </p>
      <p>
        On this Site and mobile application, we provide an online safe space for the workforce to interact and share ideas on resolving workplace concerns. Additionally, we provide employee advocacy services via our expert Advocates for a fee (collectively, "Service").
      </p>
      <p>
        The terms "we," "us," and "our" refers to Employee of Choice, Inc. The term "user," "you," and "your" refers to visitors, customers, and other users of the Site. The term "personal information" is defined as information that you voluntarily provide to us which personally identifies you and/or your contact information, including your name, phone number, and email address.
      </p>
      <p>
        Use of the Site, including all content and online services is subject to this Privacy Policy. This Privacy Policy applies to all site visitors, customers, and users of the Site. By using the Site and/or Service, you consent to this Privacy Policy.
      </p>

      <h3>INFORMATION COLLECTED</h3>
      <p>This Site only collects the personal information you voluntarily provide to us which may include:</p>
      <ul>
        <li>
          <b>Product Orders.</b> We collect a variety of personal information with each product order. This includes:
          <ul>
            <li>
              Your name, address, email address, and phone number. This information is shared with our e-commerce software providers to ensure the delivery of your order. All personal information collected for an order is used to communicate with you about your order and to manage our customer relationship with you. When you place an order, you may be added to our mailing list from which you can unsubscribe at any time using the unsubscribe link in the email or by contacting us at info@bleedingbulb.com.
            </li>
            <li>
              Your payment information. We collect payment information for each order, but we do not store information on our servers. Your payment information is securely communicated to and processed via our e-commerce software providers.
            </li>
          </ul>
        </li>
        <li>
          <b>Interaction on the Website.</b> We collect the personal information you voluntarily provide to us. This may include:
          <ul>
            <li>Your email address to subscribe to our mailing list;</li>
            <li>Your name, email address, and website link to post a comment on the Site;</li>
            <li>Your full name, email address, and message to contact us via our contact form;</li>
            <li>General non-identifying information regarding specific workplace issues you may be facing</li>
          </ul>
        </li>
        <li>
          <b>Activity.</b> We may record information relating to your use of the Site, such as the searches you undertake, the pages you view, your browser type, IP address, requested URL, referring URL, and timestamp information. We use this type of information to administer the Site and provide the highest possible level of service. We also use this information to perform statistical analyses of user behavior and characteristics to measure interest in and use of the various areas of the Site.
        </li>
        <li>
          <b>Cookies.</b> We may send cookies to your computer to uniquely identify your browser and improve the quality of our service. The term "cookies" refers to small pieces of information that a website sends to your computer's hard drive while you are viewing the Site. We may use both session cookies (which expire upon closing your browser) and persistent cookies (which remain on your computer until you delete them). You have the ability to accept or decline cookies using your web browser settings. If you choose to disable cookies, some areas of the Site may not work properly or at all.
        </li>
      </ul>
      <p>
        Your information is used to process transactions, send periodic emails, and improve our overall service. We do share your information with trusted third parties who assist us in operating our website, conducting our business, and servicing clients and visitors. These trusted third parties agree to keep this information confidential. Your personal information will never be shared with unrelated third parties.
      </p>

      <h3>Mobile Carrier</h3>
      <p>
        We use mobile carrier because when multiple users exist in session and some users are busy on a call during the session, we use a mobile icon on these users' profile images.
      </p>

      <h3>THIRD PARTY LINKS</h3>
      <p>
        The Site may contain links to third party websites. Except as otherwise discussed in this Privacy Policy, these terms only address the use and disclosure of information collected on our Site. Other sites accessible through our Site via links or otherwise have their own privacy policies. We are not responsible for the privacy policies or practices of third parties including any referrals to outside experts.
      </p>


    <h3 className='mt-4 mb-4'>Privacy Statement Excerpts
    </h3>

      {privacyStatement.map((section, index) => (
      <ul key={index}>
        <li><b>{section.heading}.</b> {section.description}</li>
    
      </ul>
    ))}




      <h3>SECURITY</h3>
      <p>
        We maintain security measures to protect your personal information from unauthorized access, misuse, or disclosure. However, no exchange of data over the Internet can be 100% guaranteed secure. While we make every effort to protect your personal information shared with us through our Site, you acknowledge that the personal information you voluntarily share with us through this Site could be accessed or tampered with by a third party. You agree that we are not responsible for any intercepted information shared through the Site without our knowledge or permission. Additionally, you release us from any and all claims arising out of or related to the use of such intercepted information in any unauthorized manner.
      </p>

      <h3>CHILDREN</h3>
      <p>
        To access or use this Site, you must be 18 years or older and have the requisite power and authority to enter into this Privacy Policy. Children under the age of 18 are prohibited from using the Site.
      </p>

      <h3>UPDATING YOUR INFORMATION</h3>
      <p>You may access and correct your personal information and privacy preferences by contacting us via email at info@bleedingbulb.com.</p>

      <h3>CHANGES TO THIS POLICY</h3>
      <p>
        You acknowledge and agree that it is your responsibility to review this Site and this Privacy Policy periodically. We will notify you of any updates to this Privacy Policy by posting those changes on this page.
      </p>

      <h3>CONTACT</h3>
      <p>If you have questions about our privacy policy, please email us at info@bleedingbulb.com.</p>

      <p>Updated: 1/31/2020</p>
    </div>
    
  );
};

export default PrivacyPolicy;
