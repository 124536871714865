import React from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import {useEffect, useState} from 'react';
import Moment from 'moment';
import moment from 'moment';
import {Pannels} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  Bandage,
} from '../../Constants/Images';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';

import Slider from 'react-slick';

const CALENDER_HEIGHT = 2400;
const offset = CALENDER_HEIGHT / 24;
const LEFT_MARGIN = 70;

const ScheduleActivities = props => {
  const [startDate, setStartDate] = useState(new Date());
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);

  const [tabType, setTabType] = useState('list');

  const [packedEvents, setPackedEvents] = useState([]);

  const events = [
    {
      start: '2023-12-13 03:00:00',
      end: '2023-12-13 03:30:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },

    {
      start: '2023-12-13 03:31:00',
      end: '2023-12-13 04:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },

    {
      start: '2023-12-13 04:00:00',
      end: '2023-12-13 04:15:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      start: '2023-12-13 04:16:00',
      end: '2023-12-13 04:30:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },

    {
      start: '2023-12-13 04:31:00',
      end: '2023-12-13 04:45:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      start: '2023-12-13 04:46:00',
      end: '2023-12-13 05:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      start: '2023-12-13 06:00:00',
      end: '2023-12-13 07:30:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },

    {
      start: '2023-12-13 14:00:00',
      end: '2023-12-13 16:30:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },

    {
      start: '2023-12-13 16:00:00',
      end: '2023-12-13 16:30:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },

    {
      start: '2023-12-13 14:00:00',
      end: '2023-12-13 15:30:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },

    {
      start: '2023-12-13 16:00:00',
      end: '2023-12-13 17:30:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },

    {
      start: '2023-12-13 23:00:00',
      end: '2023-12-13 24:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
  ];

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Sales',
      money: '$2.4k',
      time: 'versus last month',
    },

    {
      name: 'Ratings Volume',
      money: '25',
      time: 'versus last month',
    },

    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
    },
  ];

  let divStyle = {
    color: '#1A1818',
  };

  // useEffect(() => {
  //   getPaymentDetail();
  // }, [startDate, endDate]);

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour <= 23; hour++) {
      const time = `${hour % 12 === 0 ? 12 : hour % 12} ${
        hour < 12 ? 'AM' : 'PM'
      }`;

      const timeStyle = {
        top: offset * hour - 8 ,
        position:'absolute',
        
        
      };
      const lineStyle = {
        
        top: offset * hour ,
        position:'absolute',
        marginLeft:60
        
        
      };
      timeSlots.push(
        <div key={hour} className="activities-listing"  >
          <div className="time" style={timeStyle}>{time}</div>
          <div className="activity-line" style={lineStyle} >
            {/* Add your activity content or styling here */}
          </div>
        </div>,
      );
    }
    return timeSlots;
  };

  const populateEvents = (events, screenWidth) => {
    let lastEnd;
    let columns;
    let calculatedEvents = [];

    events = events
      .map((ev, index) => ({...ev, index: index}))
      .sort((a, b) => {
        if (a.start < b.start) return -1;
        if (a.start > b.start) return 1;
        if (a.end < b.end) return -1;
        if (a.end > b.end) return 1;
        return 0;
      });

    columns = [];
    lastEnd = null;

    events.forEach((ev, index) => {
      if (lastEnd !== null && ev.start >= lastEnd) {
        pack(columns, screenWidth, calculatedEvents);
        columns = [];
        lastEnd = null;
      }

      let placed = false;
      for (let i = 0; i < columns.length; i++) {
        let col = columns[i];
        if (!collision(col[col.length - 1], ev)) {
          col.push(ev);
          placed = true;
          break;
        }
      }

      if (!placed) {
        columns.push([ev]);
      }

      if (lastEnd === null || ev.end > lastEnd) {
        lastEnd = ev.end;
      }
    });

    if (columns.length > 0) {
      pack(columns, screenWidth, calculatedEvents);
    }
    return calculatedEvents;
  };

  const pack = (columns, width, calculatedEvents) => {
    const colLength = columns.length;

    for (let i = 0; i < colLength; i++) {
      let col = columns[i];
      for (let j = 0; j < col.length; j++) {
        let colSpan = expand(col[j], i, columns);
        // let L = i / colLength * width;
        let L = i * width;
        let W = (width * colSpan) / colLength - 10;

        calculatedEvents.push(buildEvent(col[j], L, W));
      }
    }
  };

  const collision = (a, b) => {
    return a.end > b.start && a.start < b.end;
  };

  const expand = (ev, column, columns) => {
    let colSpan = 1;

    for (let i = column + 1; i < columns.length; i++) {
      const col = columns[i];

      for (let j = 0; j < col.length; j++) {
        let ev1 = col[j];
        if (collision(ev, ev1)) {
          return colSpan;
        }
      }
      colSpan++;
    }

    return colSpan;
  };

  const buildEvent = (column, left, width) => {
    const startTime = moment(column.start);
    const endTime = column.end
      ? moment(column.end)
      : startTime.clone().add(1, 'hour');
    const diffHours = startTime.diff(
      startTime.clone().startOf('day'),
      'hours',
      true,
    );

    column.top = diffHours * offset;
    column.height = endTime.diff(startTime, 'hours', true) * offset;
    column.left = left;
    return column;
  };

  useEffect(() => {
    const packedEvents = populateEvents(events, 300);

    setPackedEvents(packedEvents);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEvents = () => {
    const events = packedEvents.map((event, i) => {
      const style = {
        height: event.height,
        top: event.top,
        left: event.left,
        // marginTop: '',
        marginLeft: LEFT_MARGIN,
      };

      return (
        <div className="activity-item" style={style}>
          {/* <h2 class="single-line-text">Samantha Ellington</h2>
          <p class="single-line-text">Service</p>
          <p class="single-line-text">McLean, VA</p> */}
        </div>
      );
    });

    return <>{events}</>;
  };

  const dynamicStyle = {
    padding: 0,
    // Add other styles as needed
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
          workspaceData={workspaceData}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main schedule_activity">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            {tabType === 'list' && (
              <Pannels
                isFloated={isFloated}
                showHiddenPanel={showHiddenPanel}
                updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
                customCss={dynamicStyle}
                toggleFloatedPanel={toggleFloatedPanel}
                primary={
                  <>
                    <div className="primary_panel_wraper primary_panel_content">
                      <div className="messages-header">
                        <div className="event-tabs tabs-style-2 mb-0">
                          {' '}
                          {/* Merging */}
                          <ul className="LabelTabs">
                            <li className="ml-20 d-flex align-items-center">
                              <Button
                                className={tabType === 'list' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('list');
                                }}>
                                List
                              </Button>
                            </li>

                            <li className="ml-20 d-flex align-items-center">
                              <Button
                                className={
                                  tabType === 'calendar' ? 'active' : ''
                                }
                                onClick={() => {
                                  setTabType('calendar');
                                }}>
                                {labels.BOARD[selected_lang]}
                              </Button>
                            </li>
                          </ul>
                        </div>

                        {/* <span className="add-icon">
                         <i
                           className="icon-plus"
                           onClick={() => setSecondComponent("group-component")}
                         />
                       </span>
                       <span className="add-icon">
                         <i
                           className="icon-filter-icon"
                           onClick={() => setSearchFilterModal(true)}
                         />
                       </span> */}

                        <div className="search-tabs-icons">
                          <span
                            className={
                              showComponent === 'addAction'
                                ? 'icon active'
                                : 'icon'
                            }>
                            <i
                              className="icon-plus"
                              onClick={() => {
                                if (showComponent === 'addAction') {
                                  setShowComponent('');
                                } else {
                                  setShowComponent('addAction');
                                }
                              }}
                            />
                          </span>

                          <span
                            onClick={() => {
                              setThreeDots(!threeDots);
                            }}
                            className={threeDots ? 'icon active' : 'icon'}>
                            <i className="fas fa-ellipsis-vertical" />
                          </span>
                        </div>
                      </div>
                      <div className="refferal-list-wrapper mt-4">
                        <Scrollbars autoHide>
                          <div className="schedule_list_tab">
                            <div className="calendar-page-content">
                              <div className="payment-card-main">
                                <div className="payment-content-card">
                                  <div className="task-profile-img">
                                    <img
                                      src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                      alt=""
                                    />
                                  </div>

                                  <div className="task-content">
                                    <h1
                                      style={{
                                        color: '#000',
                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                        marginBottom: '4px',
                                      }}>
                                      Samantha Ellington
                                    </h1>

                                    <span
                                      style={{
                                        color: '#000',
                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        marginBottom: '4px',
                                      }}>
                                      McLean, VA
                                    </span>
                                    <div className="payment-card-3rd-text">
                                      <span
                                        className="project-title"
                                        style={{
                                          color: '#707070',
                                          fontFamily: 'Inter',
                                          fontSize: '13px',
                                          fontStyle: 'normal',
                                          fontWeight: '400',
                                          lineHeight: 'normal',
                                          marginBottom: '4px',
                                        }}>
                                        ETA 45 Min
                                      </span>

                                      <div>5:00 PM</div>
                                    </div>
                                  </div>
                                </div>

                                <div className="payment-service-icons">
                                  <div className="roi-service-icons">
                                    <div className="service-icon">
                                      <img src={WhitePenIcon} alt="" />
                                    </div>

                                    <div className="service-icon-time">
                                      <img className="" src={timeIcon} alt="" />
                                      <span>5D</span>
                                    </div>

                                    <div className="service-icon w-25">
                                      <img src={FireIcon} alt="" />
                                    </div>

                                    <div className="service-icon w-29">
                                      <img src={MachineIcon} alt="" />
                                    </div>

                                    <div className="service-icon w-29">
                                      <img src={ArrowIcon} alt="" />
                                    </div>

                                    <div className="service-icon w-29">
                                      <img src={Bandage} alt="" />
                                    </div>

                                    <div className="service-icon w-28">
                                      <img src={heartIcon} alt="" />
                                    </div>

                                    <div className="service-icon w-29">
                                      <img src={bagIcon} alt="" />
                                    </div>
                                    <div className="w-29">
                                      <i
                                        className="fa-solid fa-ellipsis"
                                        style={{
                                          color: '#0a0a0a',
                                          paddingTop: '15px',
                                        }}></i>
                                    </div>
                                  </div>
                                </div>

                                <div className="payment-card-bottom">
                                  <div className="payment-card-icons">
                                    <div className="payment-icon">
                                      <i class="fa-solid fa-phone"></i>
                                    </div>

                                    <div className="payment-icon">
                                      <i className="fa-solid icon-plane"></i>
                                    </div>
                                  </div>

                                  <div className="pay-btn calendar-btn">
                                    <Button
                                      className="new-btn btn-green ml-3 "
                                      onClick={() => {}}>
                                      OMW
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Scrollbars>
                      </div>
                    </div>
                  </>
                }
                secondary={<></>}
              />
            )}

            {tabType === 'calendar' && (
              <div style={{display: 'flex', flex: 1}}>
                <div className="schedule-activities-page primary_panel_content">
                  <div className="messages-header" style={{maxWidth: '379px'}}>
                    <div className="event-tabs tabs-style-2 mb-0">
                      {' '}
                      {/* Merging */}
                      <ul className="LabelTabs">
                        <li className="ml-20 d-flex align-items-center">
                          <Button
                            className={tabType === 'list' ? 'active' : ''}
                            onClick={() => {
                              setTabType('list');
                            }}>
                            {labels.LIST[selected_lang]}
                          </Button>
                        </li>

                        <li className="ml-20 d-flex align-items-center">
                          <Button
                            className={tabType === 'calendar' ? 'active' : ''}
                            onClick={() => {
                              setTabType('calendar');
                            }}>
                            {labels.BOARD[selected_lang]}
                          </Button>
                        </li>
                      </ul>
                    </div>

                    {/* <span className="add-icon">
                          <i
                            className="icon-plus"
                            onClick={() => setSecondComponent("group-component")}
                          />
                        </span>
                        <span className="add-icon">
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </span> */}

                    <div className="search-tabs-icons">
                      <span
                        className={
                          showComponent === 'addAction' ? 'icon active' : 'icon'
                        }>
                        <i
                          className="icon-plus"
                          onClick={() => {
                            if (showComponent === 'addAction') {
                              setShowComponent('');
                            } else {
                              setShowComponent('addAction');
                            }
                          }}
                        />
                      </span>

                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={threeDots ? 'icon active' : 'icon'}>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>
                  {threeDots && (
                    <div
                      className=" activity-search-wrapper payment-search-wrapper mb-0 messages-users-wrapper mt-4 mb-0"
                      style={{maxWidth: '379px'}}>
                      <div className="search-form d-flex">
                        <div className="input-group">
                          <Form.Control
                            type="text"
                            value={''}
                            pattern="^\S.*$"
                            required
                            placeholder="Search for activity"
                            onChange={e => {}}
                          />
                        </div>
                        <Button>
                          <i
                            className="icon-filter-icon"
                            // onClick={() => setSearchFilterModal(true)}
                          />
                        </Button>
                      </div>

                      <div className="search-form d-flex">
                        <div className="appointment-date-wrapper ">
                          <div className="date-wrapper">
                            <span style={{width: '30px'}} className=" as-link">
                              {Moment(startDate).format('MMM').toString()}
                            </span>{' '}
                            <span
                              onClick={() => {
                                setStartDate(
                                  new Date(Moment(startDate).add(-1, 'months')),
                                );
                              }}
                              className="navs fas fa-chevron-left"
                              style={divStyle}
                            />
                            <span
                              className="navs fas fa-chevron-up"
                              onClick={() => {
                                setStartDate(
                                  new Date(Moment(startDate).add(1, 'days')),
                                );
                              }}
                              style={divStyle}
                            />
                            <div className="selected-date">
                              <span className=" as-link">
                                {Moment(startDate).format('D ddd').toString()}
                              </span>{' '}
                            </div>
                            <span
                              className="navs fas fa-chevron-down"
                              onClick={() => {
                                setStartDate(
                                  new Date(Moment(startDate).add(1, 'days')),
                                );
                              }}
                              style={divStyle}
                            />
                            <span
                              className="navs fas fa-chevron-right"
                              onClick={() => {
                                setStartDate(
                                  new Date(Moment(startDate).add(1, 'months')),
                                );
                              }}
                              style={divStyle}
                            />
                          </div>
                          {/* <div className="calender-wrapper">
                        {isOpen && (
                          <DatePicker
                            selected={startDate}
                            onChange={handleChange}
                            onClickOutside={handleOnClickOutside}
                            inline
                          />
                        )}
                        {endIsOpen && (
                          <DatePicker
                            selected={endDate}
                            onChange={handleChange}
                            onClickOutside={handleOnClickOutside}
                            inline
                          />
                        )}
                      </div> */}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="refferal-list-wrapper mt-4">
                    <Scrollbars className="" autoHide>
                      <div className="activities-main mt-3">
                        {generateTimeSlots()}
                        {renderEvents()}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            )}

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleActivities;
