import React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import API from '../Services/SessionServices';
import {errorMessage} from '../Utils/ToastMessages';
import {DEFAULT_IMAGE} from '../Utils/env';
import commonApi from '../Services/CommonService';
import {successMessage} from '../Utils/ToastMessages';
import {getItem} from '../Utils/LocalStorage';
import {amplitudeEvent} from '../Services/AmplitudeServices';
import {fixName, formatAmount, goToZoom} from '../Utils/commonUtils';
import {useDispatch, useSelector} from 'react-redux';
import {manageFavourite} from '../Store/Actions/DeepLinkAction';
import {UserGroupMini} from '../Constants/Images';

const SesssionItem = props => {
  const {item} = props;

  const {pathname, search} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const searchParams = new URLSearchParams(search);
  
  const sessionUrl = `/${
    urlParts[0] + '/' + urlParts[1]
  }/meetup/invite?${searchParams.toString()}`;

  let totalMem = item.total_members + 1;
  let totalMode = item.total_moderators + 1;
  const history = useHistory();
  const [favourite, setFavourite] = useState(props.item.favourite);
  const newFavourite = useSelector(state => state.favouriteSession);
  const heartClass = favourite === true ? 'fas' : 'far';
  const dispatch = useDispatch();

  const joinTheSession = async () => {
    var CheckLastSession = ''
    // localStorage.getItem('session_started');
    // var hideCase = localStorage.setItem('hide_case', 'yes');
    if (CheckLastSession && CheckLastSession === item.id) {
      let payload = {conference_id: item.id};
      // const response = await API.is_session_started(payload);
      const response = await API.is_session_joined(payload);
      // if (response.started) {
      if (response.session_id) {
        // localStorage.setItem('conferenceAlias', response.conferenceAlias);
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', item.id);
        // history.push('/session-details');
        goToZoom(response.session_id);
      } else {
      }
      if (item.creator_id === getItem('id')) {
        amplitudeEvent('START_SESSION');
      } else {
        amplitudeEvent('JOIN_SESSION');
      }
      // } else {
      //   errorMessage(response.message);
      // }
    }
    if (!CheckLastSession) {
      let payload = {conference_id: item.id};
      const response = await API.is_session_started(payload);

      if (response.started) {
        if (response.session_id) {
          // localStorage.setItem('conferenceAlias', response.conferenceAlias);
          // localStorage.setItem('conferenceId', response.session_id);
          // localStorage.setItem('conf_id', item.id);
          // history.push('/session-details');
          goToZoom(response.session_id);
        } else {
        }
        if (item.creator_id === getItem('id')) {
          amplitudeEvent('START_SESSION');
        } else {
          amplitudeEvent('JOIN_SESSION');
        }
      } else {
        errorMessage(response.message);
      }
    }
    if (CheckLastSession && CheckLastSession !== item.id) {
      errorMessage(
        'Your are already in a session ' +
          getItem('session_started_name') +
          '.',
      );
    }
  };

  // useEffect(() => {
  //   item.favourite = favourite;
  // }, [favourite]);

  const favouriteOpportunity = () => {
    // dispatch(getSessionListing(item.id));
    dispatch(manageFavourite(item.id));
    let payload = {
      conference_id: item.id,
    };
    commonApi
      .manage_favourite(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            props.item.favourite = true;
            setFavourite(true);
          } else {
            props.item.favourite = false;
            setFavourite(false);
          }
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (item.id === newFavourite.id) {
      setFavourite(prev => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFavourite]);

  const _renderCreatorImg = person => {
    return (
      <div className="img-item">
        {
          <span className="owner-icon">
            <i className="fas fa-crown" />
          </span>
        }
        {/* {!person.owner && person.isOnline && <span className="online-icon"></span>} */}
        {/* {person.speaker && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        <img
          src={person.creator_img === '' ? DEFAULT_IMAGE : person.creator_img}
          alt=""
        />
      </div>
    );
  };
  const _renderAudiImg = (person, speaking) => {
    return (
      <div key={`p-id-${person.id}`} className="img-item">
        {/* {!person.owner && person.isOnline && <span className="online-icon"></span>} */}
        {/* {person.speaker && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        {/* {speaking && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };
  // const _renderUserImg = (person) => {
  //     return (
  //         <div className="img-item">
  //             {person.owner && (
  //                 <span className="owner-icon">
  //                     <i className="fas fa-crown" />
  //                 </span>
  //             )}
  //             {!person.owner && person.isOnline && <span className="online-icon"></span>}
  //             {person.speaker && (
  //                 <span className="speaker-icon">
  //                     <i className="fas fa-microphone" />
  //                 </span>
  //             )}
  //             <img src={person.image} alt="" />
  //         </div>
  //     );
  // };

  return (
    <div className="session-item as-link card" key={props.key} onClick={()=>{
      if (props.type === 'link_Profile') {
        // props.AddUserInCompany(item, sessionUrl, 'session');
        props.handleLoginModal( item, sessionUrl, 'session')
      }
    }}>
      <div
        className="session-heading"
        onClick={() => {
          if (props.type === 'link_Profile') {
            // props.AddUserInCompany(item, sessionUrl, 'session');
            props.handleLoginModal( item, sessionUrl, 'session')
          } else {
            if (
              props.serverTime &&
              new Date(props.serverTime) < new Date(item.start_time)
            ) {
              props.componentDecesion(item, 'session');
              //
            } else {
              joinTheSession();
              //
            }
          }
        }}>
        <h2>{item.session_title}</h2>
        <div className="events-place-like align-items-start">
          <div className="w-100">
            <span className="users-text usercount-type">
              <img src={UserGroupMini} height={16} width={16} alt="" />
              <span className="userCount">{`${totalMem}/${totalMode}`}</span>
              <span className="category-text">{item.category_name}</span>
            </span>
          </div>
          {props.heart === 'upcoming' && (
            <div className="session-share-favs">
              <span
                className="event-heart as-link"
                onClick={e => {
                  e.stopPropagation();
                  if (props.type === 'link_Profile') {
                    // props.AddUserInCompany(item, sessionUrl, 'session');
                  } else {
                    props.componentDecesion(item, 'Refer');
                  }
                }}>
                <i className="fas fa-share" />
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className="persons-img-list"
        onClick={() => {
          if (props.type === 'link_Profile') {
            // props.AddUserInCompany(item, sessionUrl, 'session');
          } else {
            if (
              props.serverTime &&
              new Date(props.serverTime) < new Date(item.start_time)
            ) {
              props.componentDecesion(item, 'session');
              //
            } else {
              joinTheSession();
              //
            }
          }
        }}>
        {/* {Object.entries(item.persons).length > 0 && item.persons.map((person, index) => _renderUserImg(person))} */}
        {_renderCreatorImg(item)}
        {!(item.moderator_ids === '') &&
          item.moderators_list &&
          item.moderators_list.length > 0 &&
          Object.entries(item.moderators_list).length > 0 &&
          item.moderators_list.slice(0, 4).map(person => {
            // if (index < 7) {
            return _renderAudiImg(person, true);
            // }
          })}
        {!(item.audience_ids === '') &&
          item.audience_list &&
          item.audience_list.length > 0 &&
          Object.entries(item.audience_list).length > 0 &&
          item.audience_list.slice(0, 3).map(person => {
            // if (index < 7) {
            return _renderAudiImg(person);
            // }
          })}
      </div>

      <div
        className="persons-name-list"
        onClick={() => {
          if (props.type === 'link_Profile') {
            // props.AddUserInCompany(item, sessionUrl, 'session');
          } else {
            if (
              props.serverTime &&
              new Date(props.serverTime) < new Date(item.start_time)
            ) {
              props.componentDecesion(item, 'session');
              //
            } else {
              joinTheSession();
              //
            }
          }
        }}>
        <ul>
          <li key={`person-name-creator`}>
            <span className="owner-icon">
              <i className="fas fa-crown" />
            </span>
            {fixName(item.creator_first_name, item.creator_last_name)}
          </li>
          {!(item.moderator_ids === '') &&
            item.moderators_list &&
            item.moderators_list.length > 0 &&
            Object.entries(item.moderators_list).length > 0 &&
            item.moderators_list.slice(0, 4).map(person => (
              <li key={`person-mod-${person.id}`}>
                {fixName(person.first_name, person.last_name)}
                <span className="speaker-icon">
                  <i className="fas fa-microphone" />
                </span>
              </li>
            ))}
          {!(item.audience_ids === '') &&
            item.audience_list &&
            item.audience_list.length > 0 &&
            Object.entries(item.audience_list).length > 0 &&
            item.audience_list.slice(0, 3).map(person => (
              // index < 5 &&
              <li key={`person-aud-${person.id}`}>
                {/* {person.owner && (
                                <span className="owner-icon">
                                    <i className="fas fa-crown" />
                                </span>
                            )}
                            {!person.owner && person.speaker && (
                                <span className="speaker-icon">
                                    <i className="fas fa-microphone" />
                                </span>
                            )} */}
                {fixName(person.first_name, person.last_name)}
              </li>
            ))}
        </ul>
      </div>

      <div className="events-place-like session-address-wrapper mt-2">
        {item.address && (
          <span
            className="address as-link"
            onClick={() => {
              if (props.type === 'link_Profile') {
                // props.AddUserInCompany(item, sessionUrl, 'session');
              } else {
                if (
                  props.serverTime &&
                  new Date(props.serverTime) < new Date(item.start_time)
                ) {
                  props.componentDecesion(item, 'session');
                  //
                } else {
                  joinTheSession();
                  //
                }
              }
            }}>
            <i className="fas fa-location-dot"></i> {item.address}
          </span>
        )}

        {item.price && (
          <span>{formatAmount(item.price ? item.price : '')}</span>
        )}

        {item.upcoming && (
          <div className="session-share-favs">
            <span
              className="event-heart as-link"
              onClick={() => {
                if (props.type === 'link_Profile') {
                  // props.AddUserInCompany(item, sessionUrl, 'session');
                } else {
                  props.componentDecesion(item, 'Refer');
                }
              }}>
              <i className="fas fa-share" />
            </span>
            <span
              className="event-heart as-link"
              onClick={() => {
                if (props.type === 'link_Profile') {
                  // props.AddUserInCompany(item, sessionUrl, 'session');
                } else {
                  favouriteOpportunity();
                }
              }}>
              <i className={`${heartClass} fa-heart`} />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SesssionItem;
