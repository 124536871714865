import React, {useState} from 'react';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import {
  ArrowIcon,
  Bandage,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  bagIcon,
  heartIcon,
  rewardStar,
  timeIcon,
} from '../../Constants/Images';
import Slider from 'react-slick';

export default function TimeSheet(props) {
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('shifts');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const dynamicStyle = {
    padding: '0px',

  };

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

  };
  const rewardSliderData = [
    {
      name: 'Task Completion',
      money: '2.4%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Ratings',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Close Time',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
        showComponent={showComponent}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span
                            style={{
                              color: '#000',

                              fontFamily: 'Inter',
                              // fontSize: '10.833px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                            }}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              color: '#000',

                              fontFamily: 'Inter',
                              fontSize: '17px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: 'normal',
                            }}>
                            {item.money}
                            {item.name === 'Rating' && (
                              <img src={rewardStar} alt="" />
                            )}
                            <i
                              class="fa-solid fa-arrow-up"
                              style={{color: '#52bd41'}}></i>
                          </span>
                          <div
                            class="message-date-info"
                            style={
                              {
                                // marginLeft: '13px',
                              }
                            }>
                            <span
                              class="message-date"
                              style={{
                                color: '#333',
                                fontFamily: 'Helvetica',
                                // fontSize: '9px',
                                fontStyle: 'normal',
                                fontWeight: '300',
                                lineHeight: 'normal',
                              }}>
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div>
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            customCss={dynamicStyle}
            primary={
              <>
                <div className="agreements-proposals-page primary_panel_wraper primary_panel_content">
                  <div className="messages-header mb-2">
                    <div className="event-tabs tabs-style-2 mb-2">
                      {' '}
                      <ul>
                        <li>
                          <Button
                            className={tabType === 'shifts' ? 'active' : ''}
                            onClick={() => {
                              setTabType('shifts');
                            }}>
                            Shifts
                          </Button>
                        </li>

                        <li>
                          <Button
                            className={tabType === 'breaks' ? 'active' : ''}
                            onClick={() => {
                              setTabType('breaks');
                            }}>
                            Breaks
                          </Button>
                        </li>
                      </ul>
                    </div>

                    <div className="search-tabs-icons">
                      {/* <span
                            className={
                              // currentComponent === 'create-form'
                              //   ? 'icon active'
                              // :
                              'icon'
                            }>
                            <i
                              className="icon-plus"
                              // onClick={() => setCurrentComponent('create-form')}
                            />
                          </span> */}
                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={
                          threeDots ? 'icon active threeDots' : 'icon threeDots'
                        }>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>

                  {threeDots && (
                    <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                      <div className="search-form d-flex">
                        <Form.Group className="search-input">
                          <Form.Control
                            type="text"
                            placeholder="Who are you looking for?"
                            // value={chatSearchQry}
                            // onChange={e => {
                            //   setChatSearchQry(e.target.value);
                            // }}
                          />
                        </Form.Group>
                        <Button>
                          <i
                            className="icon-filter-icon"
                            // onClick={() => setSearchFilterModal(true)}
                          />
                        </Button>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      borderRadius: '5px',
                      background: '#F5F9F0',
                      width: '380px',
                      height: '66px',
                      marginBottom: '14px',
                    }}>
                    <h1
                      style={{
                        color: '#262626',
                        fontfamily: 'Inter',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        paddingTop: '8px',
                        marginLeft: '13px',
                      }}>
                      {' '}
                      Next Payment
                    </h1>
                    <div className="d-flex justify-content-between mt-1">
                      <span
                        style={{
                          marginLeft: '13px',
                          color: '#262626',
                          fontfamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '24px',
                        }}>
                        Friday, March 3, 2024
                      </span>
                      <span
                        style={{
                          marginRight: '10px',
                          color: '#262626',
                          fontfamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '24px',
                        }}>
                        $2,450
                      </span>
                    </div>
                  </div>

                  <div className="refferal-list-wrapper">
                    <Scrollbars autoHide>
                      <div className="agreements-proposals-content">
                        <div className="agreement-card-main">
                          <div className="agrement-card">
                            <h1>Thursday, March 2, 2023</h1>
                            <span
                              style={{
                                color: '#262626',
                                fontfamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                              }}>
                              High productivity
                            </span>
                            <div className="service-title mt-1">
                              5 customers
                            </div>

                            <div className="roi-service-icons">
                              <div className="service-icon">
                                <img src={WhitePenIcon} alt="" />
                              </div>

                              <div className="service-icon-time">
                                <img className="" src={timeIcon} alt="" />
                                <span>5D</span>
                              </div>

                              <div className="service-icon w-25">
                                <img src={FireIcon} alt="" />
                              </div>

                              <div className="service-icon w-29">
                                <img src={MachineIcon} alt="" />
                              </div>

                              <div className="service-icon w-29">
                                <img src={ArrowIcon} alt="" />
                              </div>

                              <div className="service-icon w-29">
                                <img src={Bandage} alt="" />
                              </div>

                              <div className="service-icon w-28">
                                <img src={heartIcon} alt="" />
                              </div>

                              <div className="service-icon w-29">
                                <img src={bagIcon} alt="" />
                              </div>
                              <div className="w-29">
                                <i
                                  className="fa-solid fa-ellipsis"
                                  style={{
                                    color: '#0a0a0a',
                                    paddingTop: '15px',
                                  }}></i>
                              </div>
                            </div>
                          </div>

                          <div
                            className="agreement-card-bottom"
                            style={{height: '55px'}}>
                            <span
                              style={{
                                color: '#262626',

                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                              }}>
                              {' '}
                              5:05AM - 2:15PM
                            </span>

                            <div>
                              <span
                                style={{
                                  color: '#262626',

                                  fontFamily: 'Inter',
                                  fontSize: '15px',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  lineHeight: 'normal',
                                }}>
                                $575
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </>
            }
            secondary={<></>}
          />
          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
