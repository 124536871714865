import React, {useEffect, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {errorMessage} from '../../Utils/ToastMessages';

const PrefListItems = ({
  items,
  active,
  showTooltip,
  updateActive,
  maxItem,
  titleLable,
}) => {
  const [activeItems, setactiveItems] = useState([]);

  const updateSelected = id => {
    let tempList = [...activeItems];

    if (tempList.includes(id)) {
      tempList = tempList.filter(item => item !== id);
    } else if (maxItem && tempList.length >= maxItem) {
      errorMessage(`You can only select ${maxItem} items.`);
    } else {
      tempList.push(id);
    }

    updateActive(tempList);
  };

  useEffect(() => {
    setactiveItems(active);
  }, [active]);

  return (
    <ul>
      {items.length > 0 &&
        items.map((item, index) => {
          const isActive = activeItems.includes(item.id) ? 'active' : '';
          let itemTitle = titleLable ? item[titleLable] : item.title ? item.title  : item.appointment_type;
          return (
            <li
              className={isActive}
              key={`boarding-item-${item.id}-${index}`}
              onClick={() => updateSelected(item.id)}>
              {showTooltip && item.description !== '' && (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{item.description}</Tooltip>}>
                  <span>{itemTitle}</span>
                </OverlayTrigger>
              )}
              {!showTooltip && itemTitle}

              {activeItems.includes(item.id) && (
                <span className="check-icon">
                  <i className="fas fa-check" />
                </span>
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default PrefListItems;
