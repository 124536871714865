import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import {ref, push, onValue, update, set, off} from 'firebase/database';
import {db, storage, storageRef} from '../../Firebase/firebase';
import {uploadBytes, getDownloadURL} from 'firebase/storage';
import {getDateFormatForChat} from '../../Services/AppServices';

import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';

import {DEFAULT_IMAGE, S3_EMOJI_DEV, selected_lang} from '../../Utils/env';

import {EmojiModal} from '../../Components';

import {emojisArr} from '../../Constants/IonicEmojis';

import {successMessage, errorMessage} from '../../Utils/ToastMessages';

import SmartChatBox from '../../Components/Common/SmartChatBox';
import SmartReplyList from '../../Components/Common/SmartReplyList';

import {
  getDateWithTimezone,
  arrayColumnMerge,
  filteringUsers,
  fixName,
  collectingUsers,
  chatroomInfo,
  countUsers,
  removeLeftUsers,
  getEmojisAndSnapshots,
  chatroomInfoOppo,
  collectingOppoUsers,
} from '../../Utils/commonUtils';
import {CustomModal} from '../../Components';
import {connect, useDispatch, useSelector} from 'react-redux';
import {callChatApi} from '../../Store/Actions/ChatAction';
import ChatReplyBox from '../../Components/Common/ChatReplyBox';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {getItem, setItem} from '../../Utils/LocalStorage';
import UsersReactionModal from '../../Components/Modals/UsersReactionModal';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import {useHistory, useLocation} from 'react-router-dom';
import {closeSession, leaveConference} from '../../Utils/voxeetUtils';
import {
  AddGroupGray,
  AddShareableIcon,
  PollGrey,
  PrivateReplyBlack,
} from '../../Constants/Images';
import NoteSvg from '../../Svg/NoteSvg';
import ActionSvg from '../../Svg/ActionSvg';
import MessagesNotes from '../../Components/Common/MessagesNotes';

import MessagesAction from '../../Components/Common/MessagesAction';

import {labels} from '../../Constants/Translations';
import SmartLiveChatBox from './SmartLiveChatBox';
import SmartLiveChatReply from '../../Components/Common/SmartLiveChatReply';

// import amplitude from 'amplitude-js';
// // initialize the client
// var instance1 = amplitude
// .getInstance()
// .init('c2369055033a45dcadf4d095cae6b794', localStorage.getItem('user'));

// // send an event
// const event = 'Button Clicked';
// amplitude.getInstance().logEvent(event);

const SmartChatPage = props => {
  const [userData, setUserData] = useState({});
  const [chatroomObj, setChatroomObj] = useState(props.chatroomInfo);

  const [otherMsg, setOtherMsg] = useState('');
  const [msgChildKey, setMsgChildKey] = useState('');
  const [msgParentKey, setMsgParentKey] = useState('');
  const [emojiToMsg, setEmojiToMsg] = useState({});

  const [msgForReply, setMsgForReply] = useState({});
  const [privateMessageUsers, setPrivateMessageUsers] = useState([]);

  const [showEmojis, setShowEmojis] = useState(false);
  const [emojiList, setEmojiList] = useState([]);

  const [showLoader, setshowLoader] = useState(false);
  const [addSnapshot, setAddSnapshot] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState('');
  const [snapshotIds, setSnapshotIds] = useState([]);
  const [snapshotImages, setSnapshotImages] = useState([]);
  const [showSnapshotDetail, setShowSnapshotDetail] = useState(false);
  const [snapshotData, setSnapshotData] = useState({});
  const [snapshotMultiIds, setSnapshotMultiIds] = useState('');
  const [snapshotSingleId, setSnapshotSingleId] = useState('');
  const [showEmojiModal, setshowEmojiModal] = useState(false);
  const [sessionEvent, setSessionEvent] = useState(false);

  const [allChat, setallChat] = useState([]);
  const [chatId, setchatId] = useState(props.chatRoomId);
  const [isNewChatroom, setNewChatroom] = useState(props.chatRoomName);
  const [chatRoomStatus, setChatroomStatus] = useState(
    props.hasOwnProperty('chatRoomStatus')
      ? props.chatRoomStatus
      : props.chatDetails.hasOwnProperty('chat_room_status')
      ? props.chatDetails.chat_room_status
      : props.chatDetails.hasOwnProperty('status')
      ? props.chatDetails.status
      : 'open',
  );
  const [threeDots, setThreeDots] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [dataForComponent, setDataForComponent] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [showUsers, setShowUsers] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [pollParticipants, setPollParticipants] = useState([]);

  const [imageSrc, setImageSrc] = useState(null);
  const [webcamStream, setWebcamStream] = useState(null);

  const [createPoll, setCreatePoll] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [oppoCall, setOppoCall] = useState(false);
  const [oppoGroupChat, setOppoGroupChat] = useState(false);
  const [modalEndChat, setModalEndChat] = useState(false);
  const [modalLeavePolietly, setModalLeavePolietly] = useState(false);
  const [writtenMsg, setWrittenMsg] = useState('');
  const [isReply, setIsReply] = useState(false);
  const [isReplies, setIsReplies] = useState(false);
  const [isPrivateReply, setIsPrivateReply] = useState(false);
  const [showPrivate, setPrivateUser] = useState(false);
  const [messageBodyNew, setMessageBodyNew] = useState({});

  const [isScrollable, setScrollable] = useState(true);
  const [marksBest, setMarksBest] = useState(false);
  const [replyOptionObj, setReplyOptionObj] = useState(null);
  const [popupImages, setPopupImages] = useState([]);
  const [show, setShow] = useState(false);
  const [lastMsgTime, setLastMsgTime] = useState('');

  const [chatIsEnable, setChatEnable] = useState(true);
  const [chatEnableStatus, setChatEnableStatus] = useState(
    props.chatRoomStatus,
  );
  const [allChatUsers, setAllChatUsers] = useState(props.allChatUsers);
  // const [userCount, setUserCount] = useState(props.chatroomInfo.count);
  // const [userCount, setUserCount] = useState(0);
  // const [isQuestionChat, setQuestionChat] = useState(false);
  // const [isOpportunityChat, setOpportunityChat] = useState(false);
  const [showEndChat, setShowEndChat] = useState('');

  const [isExtraClose, setExtraClose] = useState(false);
  const [cameraOptions, toggleCameraOptions] = useState(false);
  const [replyOptions, toggleReplyOptions] = useState(false);
  const [showComponentAction, setShowComponentAction] = useState('');
  const [snapShotImagesLast3, setSnapShotImagesLast3] = useState([]);

  const [handleData, setHandleData] = useState({
    key: '',
    item: {},
    cKey: '',
    pKey: '',
  });
  const [autoScroll, setAutoScroll] = useState(true);

  // open messgaes from messages

  const [chatDetailsFromMessages, setChatDetailsFromMessages] = useState([]);
  const [chatDetailItemFromMessages, setChatDetailItemFromMessages] = useState(
    [],
  );
  const [allChatUsersFromMessages, setAllChatUsersFromMessages] = useState([]);
  const [chatRoomNameFromMessages, setChatRoomNameFromMessages] = useState('');
  const [chatRoomIdFromMessages, setChatRoomIdFromMessages] = useState('');
  const [chatPropTypeFromMessages, setChatPropTypeFromMessages] = useState('');

  // const messagesEndRef = useRef(null);
  const webcamRef = useRef(null);
  const scrollRef = useRef(null);
  const focusTextArea = useRef(null);
  const dispatch = useDispatch();
  const locationWeb = useLocation();
  const history = useHistory();

  const [messageBody, setMessageBody] = useState({
    message: '',
    emoji: '',
    accomplishment_data: '',
    appointment_data: '',
    group_chat_data: '',
    event_data: '',
    session_data: '',
    opportunity_data: '',
    network_data: '',
    picture_url: '',
    document_url: '',
    video_url: '',
    type: 'message',
    isPrivate: false,
    privateChatData: {
      first_name: '',
      last_name: '',
      profile_img: '',
      sent_to: '',
    },
    sent_by: localStorage.getItem('chatUserId')
      ? localStorage.getItem('chatUserId')
      : props.loginUserId,
    send_date: '',
  });

  const currentUrl = window.location.href;
  const lastSlashIndex = currentUrl.lastIndexOf('/');
  const newSubstring = currentUrl.substring(lastSlashIndex + 1);

  // const hitApi = useSelector(state => state.chatListing);
  const callCount = useSelector(state => state.opportunityApi);
  const personalChatCount = useSelector(state => state.personalChatUpdate);

  const [xfbFolder, setxFbFolder] = useState('chats');
  const [xchatDetail, setxChatDetail] = useState(props.chatDetails);
  const [xotherUser, setxOtherUser] = useState(
    props.chatDetails.creator_id === localStorage.getItem('chatUserId')
      ? 'user_detail'
      : props.chatDetails.hasOwnProperty('creator_details')
      ? 'creator_details'
      : 'creator_detail',
  );
  const [xchatType, setxChatType] = useState(props.type);
  const [chatRoomName, setChatRoomName] = useState(
    props.chatRoomName
      ? props.chatRoomName
      : 'chatroom_' + (Math.random() + 1).toString(36).substring(3),
  );

  const inputRef = useRef(null);

  const allowedImageTypes = ['image/jpeg', 'image/png'];
  const allowedImagestypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const allowedVideoTypes = [
    'video/mp4',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-msvideo',
    'video/x-matroska',
    'video/webm',
    'video/x-m4v',
  ];

  // const lastMessageTime = getItem('lastMessageTime');
  const chatStatusIn = ['accepted', 'open', 'join', 'pending', 'started'];
  // const creatorDetail = props.chatDetails.hasOwnProperty('creator_details')
  //   ? 'creator_details'
  //   : 'creator_detail';
  const opportunityId = props.oppoDetails.hasOwnProperty('opportunity_id')
    ? props.oppoDetails.opportunity_id
    : props.oppoDetails.id;
  const oppoCreatorDetail = props.oppoDetails.hasOwnProperty('creator_details')
    ? 'creator_details'
    : 'creator_detail';

  const updateNewUsers = chatId => {
    offUpdateNewUsers(chatId);
    const database = ref(
      db,
      'check_new_users_in_group/sessionEventGeneralChat/' + chatId,
    );
    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        const unixTimestamp = snapshot.val().time; // example Unix timestamp
        const currentTime = new Date().getTime(); // get current time in milliseconds
        const differenceInMs = currentTime - unixTimestamp * 1000; // convert timestamp to milliseconds and subtract from current time

        // if (differenceInMs < 5000) {
        checkNewUsersInGroup(snapshot.val().id, differenceInMs);
        // }
      }
    });
  };

  const offUpdateNewUsers = chatId => {
    if (chatId) {
      const database = ref(
        db,
        'check_new_users_in_group/sessionEventGeneralChat/' + chatId,
      );
      off(database);
    }
  };

  const offUpdateOppoChatStatus = chatId => {
    if (chatId) {
      const database = ref(db, 'chatroom_status/opportunityChat/' + chatId);
      off(database);
    }
  };

  const offCheckOppoChatNewUser = chatId => {
    if (chatId) {
      const database = ref(db, 'new_user_status/opportunityChat/' + chatId);
      off(database);
    }
  };

  const detachesChatroom = () => {
    const chatPath = getItem('chatPath');
    if (chatPath) {
      const database = ref(db, chatPath);
      off(database);
    }
  };

  const handleOpenChatRoom = chatDetail => {
    // alert()
    // if (tabType === 'chats') {
    setChatDetailsFromMessages(chatDetail);
    setAllChatUsersFromMessages(collectingUsers(chatDetail));
    setChatRoomNameFromMessages(chatDetail.chat_room_name);
    setChatRoomIdFromMessages(chatDetail.chat_id);

    if (chatDetail.chat_type === 'opportunity') {
      if (chatDetail.creator_id === localStorage.getItem('chatUserId')) {
        setChatPropTypeFromMessages('mine-opportunity');
      } else if (chatDetail.team_member_ids) {
        const myArray = chatDetail.team_member_ids.split(',');
        if (myArray.includes(localStorage.getItem('chatUserId'))) {
          setChatPropTypeFromMessages('mine-opportunity');
        } else {
          setChatPropTypeFromMessages('other-opportunity');
        }
      } else {
        setChatPropTypeFromMessages('other-opportunity');
      }
    } else {
      if (chatDetail.chat_room_type === 'group') {
        setChatPropTypeFromMessages('personal-group');
      } else {
        setChatPropTypeFromMessages('personal-single');
      }
    }

    const chatItem = chatDetail;
    chatItem.id = chatDetail.type_id;
    setChatDetailItemFromMessages(chatItem);

    setShowComponent('chat-component');
    // }
    //  else if (tabType === 'subInvites') {
    //   if (chatDetail.chat_type === 'opportunity') {
    //     manageOppoChatInvitation(
    //       chatDetail.id,
    //       chatDetail.opportunity_id,
    //       'accepted',
    //     );
    //   } else {
    //     manageChatInvitation(
    //       chatDetail.id,
    //       chatDetail.invitation_type,
    //       'accepted',
    //     );
    //   }

    //   setChatDetails(chatDetail);
    //   setAllChatUsers(collectingUsers(chatDetail));
    //   setChatRoomName(chatDetail.chat_room_name);
    //   setChatRoomId(chatDetail.chat_id);

    //   // const chatItem = chatDetail;
    //   // chatItem.id = chatDetail.type_id;
    //   // setChatDetailItem(chatItem);

    //   if (chatDetail.chat_type === 'opportunity') {
    //     setChatPropType('other-opportunity');
    //   } else {
    //     if (chatDetail.chat_room_type === 'group') {
    //       setChatPropType('personal-group');
    //     } else {
    //       setChatPropType('personal-single');
    //     }
    //   }

    //   setTimeout(() => {
    //     setSecondComponent('chat-component');
    //   }, 100);
    // }
  };

  const checkOpportunityChatStatus = chatId => {
    // alert('chala');
    const database = ref(db, 'chatroom_status/opportunityChat/' + chatId);
    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        // alert('chala222222222222');

        // id : 72,
        // left : true,
        // time : 1676356099

        if (snapshot.val().left) {
          // const currentUrl = window.location.href;
          // const lastSlashIndex = currentUrl.lastIndexOf('/');
          // const newSubstring = currentUrl.substring(lastSlashIndex + 1);
          if (newSubstring === 'history') {
            setChatEnable(false);
            setChatEnableStatus('left');
            // setChatEnableStatus('end');
          } else if (
            snapshot.val().time + 60 >
            Math.floor(new Date().getTime() / 1000)
          ) {
            setChatEnable(false);
            if (['match', 'decline'].includes(chatEnableStatus)) {
            } else {
              setChatEnableStatus('left');
            }
            // setChatEnableStatus('end');
          }
        } else {
          setChatEnable(true);
          setChatEnableStatus('pending');
        }
      } else {
        //jugar laya hai opportunity k match ecline k liye
        setChatEnableStatus('pending');
      }
    });
  };

  const checkSessionEventChatStatus = chatId => {
    const database = ref(
      db,
      'chatroom_status/sessionEventGeneralChat/' + chatId,
    );
    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        // id : 72,
        // left : true,
        // time : 1676356099
        if (snapshot.val().closed) {
          setChatEnable(false);
          setChatEnableStatus('left');
          // setChatEnableStatus('end');
        } else {
          setChatEnable(true);
          setChatEnableStatus('pending');
        }
      }
    });
  };

  const newUserStatusOpportunityChat = chatId => {
    const database = ref(db, 'new_user_status/opportunityChat/' + chatId);
    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        if (
          snapshot.val().time + 60 >
          Math.floor(new Date().getTime() / 1000)
        ) {
          checkNewUsersInOpportunity(chatId);
        }
      }
    });
  };

  const triggerEventOpportunityChatStatus = (chatId, status) => {
    const databaseRef = ref(db, 'chatroom_status/opportunityChat/' + chatId);
    set(databaseRef, {
      id: chatId,
      left: status,
      time: Math.floor(new Date().getTime() / 1000),
    });
  };

  const checkNewUsersInOpportunity = id => {
    let data = {chat_id: id};
    commonApi
      .check_opportunity_new_user(data)
      .then(res => {
        if (res) {
          res.creator_detail.type = 'creator';
          let allUsersInGroup = [res.creator_detail];
          let audienceArr = res.other_persons_opportunity_chat_room;
          let moderatorArr = res.team_member_details
            ? res.team_member_details
            : [];
          // let count = 1;

          for (let i = 0; i < audienceArr.length; i++) {
            if (audienceArr[i].status === 'accepted') {
              // count++;
            }
            audienceArr[i].type = 'audience';
          }

          for (let i = 0; i < moderatorArr.length; i++) {
            // count++;
            moderatorArr[i].type = 'moderator';
          }

          allUsersInGroup = [
            ...allUsersInGroup,
            ...moderatorArr,
            ...audienceArr,
          ];

          setAllChatUsers(allUsersInGroup);

          if (res.group_detail && res.group_detail.group_name) {
            setChatroomObj(prev => ({
              ...prev,
              image: res.group_detail.group_image,
              name: res.group_detail.group_name,
              chat_room_type: 'group',
            }));
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const checkNewUsersInGroup = (id, time) => {
    // const currentUrl = window.location.href;
    // const lastSlashIndex = currentUrl.lastIndexOf('/');
    // const newSubstring = currentUrl.substring(lastSlashIndex + 1);

    let data = {group_id: id};
    commonApi
      .check_new_users_in_group(data)
      .then(res => {
        if (res) {
          // const activeUsers = arrayColumn(
          //   res.group_chat_detail.group_detail,
          //   'id',
          // );

          dispatch(callChatApi());
          setChatroomObj(chatroomInfo(res.group_chat_detail));
          // setAllChatUsers(
          //   collectingUsers(res.group_chat_detail).filter(obj =>
          //     activeUsers.includes(obj.id),
          //   ),
          // );
          setAllChatUsers(res.group_chat_detail.all_users);
          setxChatDetail(res.group_chat_detail);

          const found = res.group_chat_detail.all_users.find(
            obj => obj.id === getItem('chatUserId') && obj.status !== 'left',
          );
          if (typeof found === 'object') {
          } else {
            if (newSubstring === 'history') {
            } else if (time < 5000) {
              props.onClose();
            }
          }

          // props.allChatUsers = collectingUsers(res.group_chat_detail);
          // props.chatroomInfo.count = res.group_chat_detail.count;
          // setUserCount(res.group_chat_detail.count);
          // setTimeout(() => {
          //   setChatroomObj(chatroomInfo(res.group_chat_detail));

          // }, 1000);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const scrollToBottom = status => {
    if (status) {
      if (isScrollable) {
        // messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
        if (scrollRef.current) {
          scrollRef.current.scrollToBottom();
        }
      }
    } else {
      // messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
      if (scrollRef.current) {
        scrollRef.current.scrollToBottom();
      }
    }
  };

  const startWebCam = () => {
    // request access to the user's webcam
    navigator.mediaDevices.getUserMedia({video: true}).then(stream => {
      // save the webcam stream to state
      setWebcamStream(stream);
      // navigator.attachMediaStream(webcamRef.current, stream);
      setTimeout(() => {
        webcamRef.current.srcObject = stream;
      }, 1000);
    });
  };

  const captureImage = () => {
    // create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // set the canvas size to the size of the video
    canvas.width = webcamStream.getTracks()[0].getSettings().width;
    canvas.height = webcamStream.getTracks()[0].getSettings().height;

    // draw the video on the canvas
    context.drawImage(webcamRef.current, 0, 0);

    // convert the canvas to an image
    const img = new Image();
    img.src = canvas.toDataURL();
    setImageSrc(img.src);

    canvas.toBlob(blob => {
      makeFile(blob);
    });
  };

  const ShowEmojModel = () => {
    setshowEmojiModal('add-emoji');
  };

  const handleReplyOptions = (option, signalData, childKey, parentKey) => {
    toggleReplyOptions(false);
    // handleFocus();

    if (signalData.isPrivate) {
      setPrivateMessageUsers([
        signalData.sent_by,
        signalData.privateChatData.sent_to,
      ]);
    }
    if (option === 'add-replies') {
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setMsgForReply(signalData);
      setIsReplies(true);
    } else if (option === 'add-emoji') {
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setEmojiToMsg(signalData);
      setshowEmojiModal(true);
    } else if (option === 'best-answer') {
      markAsBest(parentKey, childKey, {isCorrect: true}, signalData);
    } else if (option === 'end-poll') {
      endPoll(parentKey, childKey, '', signalData);
    } else if (option === 'private-reply') {
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setMsgForReply(signalData);
      setIsReplies(true);
      setIsPrivateReply(true);
    }
  };

  const handleChatBoxSignal = (
    signalType,
    signalData,
    childKey,
    parentKey,
    snapShotId,
  ) => {
    if (signalType === 'replyOptions') {
      setMarksBest(!signalData.isCorrect);
      setReplyOptionObj({
        signalType: signalType,
        signalData: signalData,
        childKey: childKey,
        parentKey: parentKey,
      });
      toggleReplyOptions(!replyOptions);
    } else if (signalType === 'update-poll') {
      handlePoll(signalData, childKey, parentKey);
    } else if (signalType === 'add-replies') {
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setMsgForReply(signalData);
      setIsReplies(true);
    } else if (signalType === 'add-reply') {
      setMessageBody({...messageBody, reply: signalData});
      setIsReply(true);
    } else if (signalType === 'show-snapshot-detail') {
      getSpecificAccomplishment(signalData, snapShotId);
    } else if (signalType === 'add-emoji') {
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setEmojiToMsg(signalData);
      setshowEmojiModal(true);
    } else if (signalType === 'show-emojis') {
      setEmojiList(signalData);
      setShowEmojis(true);
    } else if (signalType === 'mention-user') {
      if (chatroomObj.chat_room_type === 'group') {
        handleMentionUsers(signalData);
      }
    } else if (signalType === 'remove-reply') {
      setMsgChildKey('');
      setMsgParentKey('');
      setMsgForReply({});
      setPrivateMessageUsers([]);
      setIsReplies(false);
      setIsPrivateReply(false); // need to confirm
    } else if (signalType === 'show-replies') {
      setHandleData({
        key: '',
        item: signalData,
        cKey: childKey,
        pKey: parentKey,
      });
      setShowReplies(true);
    } else if (signalType === 'best-answer') {
      markAsBest(parentKey, childKey, {isCorrect: true}, signalData);
    } else if (signalType === 'show-participants') {
      const chatData = signalData.message.options;
      const participantIds = arrayColumnMerge(chatData, 'userIds');
      setPollParticipants(filteringUsers(allChatUsers, participantIds));
      setShowParticipants(true);
    } else if (signalType === 'show-profile') {
      setShowUserProfile(signalData.id);
    } else if (signalType === 'show-image') {
      const tempImgs = [{id: 0, image: signalData}];
      setShow(true);
      setPopupImages(tempImgs);
    } else if (signalType === 'show-reply-users') {
      const chatData = signalData.replies;
      const sentBySet = new Set(chatData.map(message => message.sent_by));
      const sentByValues = Array.from(sentBySet);
      setPollParticipants(filteringUsers(allChatUsers, sentByValues));
      setShowParticipants(true);
    }
  };

  const handleCreatePoll = msg => {
    setMessageBody({...messageBody, message: msg, type: 'poll'});
    setMessageBodyNew({...messageBody, message: msg, type: 'poll'});
  };

  const showLightbox = () => {
    const tempImgs = [{id: 0, image: URL.createObjectURL(selectedImage)}];
    setShow(true);
    setPopupImages(tempImgs);
  };

  const handleSelect = e => {
    if (e === '2') {
      inputRef.current.click();
    } else if (e === '3') {
      setOtherMsg('Snapshot');
      setAddSnapshot(true);
    } else if (e === '1') {
      startWebCam();
    }
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // let fileExtension = file.name.split('.').pop().toLowerCase();

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImagestypes.includes(file.type)) {
      makeFile(file);
      toggleCameraOptions(false);
      // setSelectedImage(file);

      e.target.value = null;

      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {

      //   uploadMedia(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
    // const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  };

  const makeFile = data => {
    const type = data.type.toString();
    if (type.search('image') === 0) {
      if (data) {
        setSelectedImage(data);
        setOtherMsg('Image');
      }
    }
    if (type.search('video') === 0) {
      if (data) {
        setSelectedImage(data);
        setVideoPreviewUrl(URL.createObjectURL(data));
        setOtherMsg('Video');
      }
    }
    if (
      type.search('document') === 0 ||
      type.search('pdf') === 0 ||
      type.search('application') === 0
    ) {
      if (data) {
        setSelectedImage(data);
        setOtherMsg('Document');
      }
    }
  };

  const clearData = () => {
    setSelectedImage(null);
    setVideoPreviewUrl(null);
    setMessageBody({
      message: '',
      emoji: '',
      accomplishment_data: '',
      appointment_data: '',
      group_chat_data: '',
      event_data: '',
      session_data: '',
      opportunity_data: '',
      network_data: '',
      picture_url: '',
      document_url: '',
      video_url: '',
      type: 'message',
      isPrivate: false,
      privateChatData: {
        first_name: '',
        last_name: '',
        profile_img: '',
        sent_to: '',
      },
      sent_by: localStorage.getItem('chatUserId')
        ? localStorage.getItem('chatUserId')
        : props.loginUserId,
      send_date: '',
    });
    setSnapshotIds([]);
    setSnapshotImages([]);
    setPrivateUser(false);
  };

  const uploadFile = () => {
    var type;
    if (selectedImage) {
      type = selectedImage.type.toString();
    }
    if (type.search('video') === 0) {
      if (selectedImage) {
        const fileName =
          'video_' +
          Math.floor(Date.now() / 1000).toString() +
          selectedImage.name;
        const fileRef = storageRef(storage, 'videos/' + fileName);
        uploadBytes(fileRef, selectedImage).then(res => {
          getDownloadURL(res.ref).then(downloadURL => {
            setMessageBody({
              ...messageBody,
              type: 'video',
              video_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'video',
              video_url: downloadURL,
            });
          });
        });
      }
    }
    if (type.search('image') === 0) {
      if (selectedImage) {
        const fileName =
          'image_' + Math.floor(Date.now() / 1000).toString() + '.png';
        const fileRef = storageRef(storage, 'images/' + fileName);
        uploadBytes(fileRef, selectedImage).then(res => {
          getDownloadURL(res.ref).then(downloadURL => {
            setMessageBody({
              ...messageBody,
              type: 'image',
              picture_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'image',
              picture_url: downloadURL,
            });
          });
        });
      }
    }
    if (
      type.search('document') === 0 ||
      type.search('pdf') === 0 ||
      type.search('application') === 0
    ) {
      if (selectedImage) {
        const fileName = 'document_' + Math.floor(Date.now() / 1000).toString();
        const fileRef = storageRef(storage, 'documents/' + fileName);

        uploadBytes(fileRef, selectedImage).then(res => {
          getDownloadURL(res.ref).then(downloadURL => {
            setMessageBody({
              ...messageBody,
              type: 'document',
              document_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'document',
              document_url: downloadURL,
            });
          });
        });
      }
    }
    setSelectedImage(null);
  };

  const sendSnapshot = () => {
    let images = [];
    snapshotIds.forEach((item, index) => {
      images.push({
        id: item,
        images: [snapshotImages[index]],
      });
    });
    setMessageBody({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
    setMessageBodyNew({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
  };
  const sendSnapshotFromModel = item => {
    let images = [
      {
        id: item.id,
        images: [item.images[0]],
      },
    ];
    // snapshotIds.map((item, index) => {
    //   images.push({
    //     id: item,
    //     images: [snapshotImages[index]],
    //   });
    // });
    setMessageBody({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
    setMessageBodyNew({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
  };
  const sendAnyThink = (type, data) => {
    // snapshotIds.map((item, index) => {
    //   images.push({
    //     id: item,
    //     images: [snapshotImages[index]],
    //   });
    // });

    if (type === 'snapShot') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        accomplishment_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        accomplishment_data: data,
      });
    } else if (type === 'profile') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        network_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        network_data: data,
      });
    } else if (type === 'event') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        event_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        event_data: data,
      });
    } else if (type === 'conference') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        session_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        session_data: data,
      });
    } else if (type === 'opportunity') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        opportunity_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        opportunity_data: data,
      });
    } else if (type === 'appointment') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        appointment_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        appointment_data: data,
      });
    } else if (type === 'message') {
      setMessageBody({
        ...messageBody,
        type: data.data_type,
        group_chat_data: data,
      });
      setMessageBodyNew({
        ...messageBody,
        type: data.data_type,
        group_chat_data: data,
      });
    }
  };

  const saveMessage = () => {
    if (!isReplies) {
      setAutoScroll(true);
    }
    if (selectedImage) {
      setshowLoader(true);
      uploadFile();
      setWrittenMsg('');
      setIsReply(false);
    } else if (snapshotIds.length > 0) {
      sendSnapshot();
      setWrittenMsg('');
      setIsReply(false);
    } else if (writtenMsg.trim().length !== 0) {
      sendMessage();
      setWrittenMsg('');
      setIsReply(false);
    } else if (selectedEmoji) {
      // sendMessagesEmoji()
      setWrittenMsg('');
      setIsReply(false);
    } else {
      setAutoScroll(false);

      setWrittenMsg('');
      setMessageBody({...messageBody, message: ''});
      errorMessage("Can't send empty messsage.");
    }
  };

  const sendEmoji = emojiMessage => {
    const type = xfbFolder === 'opportunities' ? 'opportunity' : '';
    sendEmojiNotification(chatId, type);
    // let msgBody = emojiToMsg;
    // msgBody.emoji = "ja raha ha";
    const databaseRef = ref(
      db,
      'chatrooms/' +
        xfbFolder +
        '/' +
        chatRoomName +
        '/' +
        msgParentKey +
        '/' +
        msgChildKey,
    );
    update(databaseRef, emojiMessage)
      .then(() => {
        // openChatroom(chatRoomName);
        setMsgChildKey('');
        setMsgParentKey('');
        setEmojiToMsg({});
        setshowEmojiModal(false);
        // setshowLoader(false);
      })
      .then(() => {
        // clearData();
      });
  };

  const markAsBest = (pKey, cKey, msg, markMsg) => {
    let payload = {
      best_ans: markMsg.type,
      best_ans_user: markMsg.sent_by,
      question_id: props.item.id,
    };
    if (markMsg) {
      if (markMsg.type === 'message') {
        payload.best_ans = markMsg.message;
      }

      commonApi.mark_as_best(payload);
    }

    // remove old mark
    if (localStorage.getItem('isCorrectPath')) {
      let alterData = {isCorrect: false};
      let isCorrectMessage = localStorage.getItem('isCorrectMessage');

      if (isCorrectMessage) {
        isCorrectMessage = JSON.parse(isCorrectMessage);
        if (isCorrectMessage.replies) {
          let replies = isCorrectMessage.replies;
          for (let x in replies) {
            if (replies[x].isCorrect) {
              replies[x].isCorrect = false;
            }
          }
          alterData.replies = replies;
        }
      }

      const databaseRefOld = ref(
        db,
        'chatrooms/' +
          xfbFolder +
          '/' +
          chatRoomName +
          '/' +
          localStorage.getItem('isCorrectPath'),
      );
      update(databaseRefOld, alterData);
      // update(databaseRefOld, {isCorrect: false});
    }

    // add new mark
    const databaseRef = ref(
      db,
      'chatrooms/' + xfbFolder + '/' + chatRoomName + '/' + pKey + '/' + cKey,
    );
    update(databaseRef, msg).then(() => {
      // openChatroom(chatRoomName);
    });
  };

  const endPoll = (pKey, cKey, msg, markMsg) => {
    let newMsg = markMsg.message;
    let payload = {
      poll_id: markMsg.message.pollId,
    };
    commonApi.close_poll(payload);
    newMsg.status = 'close';

    // end poll
    const databaseRef = ref(
      db,
      'chatrooms/' + xfbFolder + '/' + chatRoomName + '/' + pKey + '/' + cKey,
    );
    update(databaseRef, {message: newMsg}).then(() => {
      // openChatroom(chatRoomName);
    });
  };

  const handleAddEmoji = icon => {
    const myArray = icon.split('/').slice(-2);
    const skinTone = myArray[0];
    const emojiName = myArray[1].split('.', 1).toString();
    const found = emojisArr.find(objs =>
      objs.name ? objs.name === emojiName : objs.emojiName === emojiName,
    );
    const data = {
      emojiID: found.id,
      emojiName: emojiName,
      send_date: new Date().getTime(),
      sent_by: localStorage.getItem('chatUserId')
        ? localStorage.getItem('chatUserId')
        : props.loginUserId,
      skinTone: skinTone,
      type: 'emoji',
    };

    if (emojiToMsg.hasOwnProperty('emojis') && emojiToMsg.emojis.length > 0) {
      let prevEmojis = emojiToMsg.emojis;
      prevEmojis.push(data);
      sendEmoji({emojis: prevEmojis});
      // alert('hehe1');
    } else {
      // alert('hehe');
      sendEmoji({emojis: [data]});
    }
  };
  const sendMessagesEmoji = icon => {
    const myArray = icon.split('/').slice(-2);
    const skinTone = myArray[0];
    const emojiName = myArray[1].split('.', 1).toString();
    const found = emojisArr.find(objs =>
      objs.name ? objs.name === emojiName : objs.emojiName === emojiName,
    );
    const data = {
      emojiID: found.id,
      emojiName: emojiName,
      // send_date: new Date().getTime(),

      skinTone: skinTone,
    };

    setMessageBody({
      ...messageBody,
      type: 'emoji',
      emoji: data,
    });
    setMessageBodyNew({
      ...messageBody,
      type: 'emoji',
      emoji: data,
    });
    setshowEmojiModal(false);
  };

  const handlePoll = (msg, cKey, pKey) => {
    const newOpt = localStorage.getItem('optionNo');
    const localUser = localStorage.getItem('chatUserId').toString();
    const chating = msg.message.options;

    const data = {
      dateAndTime: msg.message.dateAndTime,
      options: msg.message.options,
      pollId: msg.message.pollId,
      question: msg.message.question,
      status: msg.message.status,
    };

    for (let x in chating) {
      if (x === newOpt) {
        if (chating[x].hasOwnProperty('userIds')) {
          if (chating[x]['userIds'].includes(localUser)) {
            chating[x]['userIds'] = chating[x]['userIds'].filter(
              item => item !== localUser,
            );
          } else {
            chating[x]['userIds'].push(localUser);
          }
        } else {
          chating[x]['userIds'] = [localUser];
        }
      } else {
        if (chating[x].hasOwnProperty('userIds')) {
          chating[x]['userIds'] = chating[x]['userIds'].filter(
            item => item !== localUser,
          );
        }
      }
    }

    data.options = chating;

    const databaseRef = ref(
      db,
      'chatrooms/' + xfbFolder + '/' + chatRoomName + '/' + pKey + '/' + cKey,
    );
    update(databaseRef, {message: data})
      .then(() => {})
      .then(() => {
        // clearData();
      });
  };

  const createOpportunityChatroom = () => {
    var data = {
      chat_room_name: chatRoomName,
      creator_id: props.oppoDetails.created_by,
      opportunity_id: opportunityId,
      user_id: '',
    };
    return commonApi
      .create_opportunity_chat_room(data)
      .then(res => {
        amplitudeEvent('CREATE_OPPORTUNITY_CHAT');
        if (res) {
          setExtraClose(true);
          setxChatDetail(res.chat_detail);
          setchatId(res.opportunity_chat_room_id);
          setNewChatroom(res.opportunity_chat_room_name);
          checkOpportunityChatStatus(res.opportunity_chat_room_id);
          newUserStatusOpportunityChat(res.opportunity_chat_room_id);
          return res.opportunity_chat_room_id;
        } else {
          return false;
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const createOpportunityChatroomCreator = () => {
    var data = {
      chat_id: '',
      chat_room_name: chatRoomName,
      creator_id: '',
      opportunity_id: opportunityId,
      user_id: chatroomObj.profile_id,
    };
    return commonApi
      .create_opportunity_chat_room(data)
      .then(res => {
        amplitudeEvent('CREATE_OPPORTUNITY_CHAT');
        if (res) {
          setExtraClose(true);
          setxChatDetail(res.chat_detail);
          setchatId(res.opportunity_chat_room_id);
          setNewChatroom(res.opportunity_chat_room_name);
          return res.opportunity_chat_room_id;
        } else {
          return false;
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const createChatroom = async () => {
    var data = {
      chat_room_name: chatRoomName,
      chat_room_type: 'single',
      type: 'live_chat',
      user_id: props.userData.hasOwnProperty('user_id')
        ? props.userData.user_id
        : props.userData.id,
      company_name: props.companyName,
    };

    return commonApi
      .create_chat_room(data)
      .then(res => {
        amplitudeEvent('CREATE_SINGLE_CHAT');
        if (res) {
          let ChatData = res.chat_detail;
          ChatData.chat_room_id = res.chat_room_id;
          ChatData.chat_room_name = res.chat_room_name;
          setxChatDetail(ChatData);
          setchatId(res.chat_room_id);
          setNewChatroom(res.chat_room_name);
          props.setChatRoomName(res.chat_room_name);
          const allChatUsers = [];
          allChatUsers.push(res.chat_detail.creator_detail);
          allChatUsers.push(res.chat_detail.user_detail);
          setAllChatUsers(allChatUsers);
          props.setAllChatUsers(allChatUsers);
          return res.chat_room_id;
        } else {
          return false;
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const checkChatRoom = async id => {
    let payLoad = {
      user_id: id,
      company_name: props.companyName,
      chat_type : 'live_chat'
    };
    return commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            setChatroomObj(chatroomInfo(response.chat_detail));
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setAllChatUsers(allChatUsers);
            setxChatDetail(response.chat_detail);
            setChatRoomName(response.chat_room_name); // props.chatRoomName
            setchatId(response.chat_room_id);
            setNewChatroom(response.chat_room_name);
            // setChatType('personal-single');
            // setShowComponent({...showComponent, ['chat']: true});

            // let ChatData = response.chat_detail;
            // ChatData.chat_room_id = response.chat_room_id;
            // ChatData.chat_room_name = response.chat_room_name;
            // setxChatDetail(ChatData);
            return {
              chatId: response.chat_room_id,
              chatName: response.chat_room_name,
            };
          } else {
            // const newChatId = await createChatroom();
            // return newChatId;
            return false;
          }
        } else {
          return false;
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const leavePolitely = () => {
    var data = {
      chat_room_id: chatId,
      opportunity_id: opportunityId,
    };
    commonApi
      .politely_leave_opportunity(data)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          if (isExtraClose) {
            props.onClose(isExtraClose);
          } else {
            props.onClose();
          }
        }
        setshowLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const manageOpportunityStatus = status => {
    var data = {
      chat_id: chatId,
      opportunity_id: opportunityId,
      status: status,
      user_id: xchatDetail.user_id,
    };
    commonApi
      .manage_opportunity_status(data)
      .then(res => {
        if (res.status === 200) {
          triggerEventOpportunityChatStatus(chatId, true);
          if (res.success) {
            successMessage(res.message);
          } else {
            errorMessage(res.message);
          }
          setChatEnable(false);
          setChatEnableStatus(status);
          setChatroomStatus(status);
          // if (isSessionOpen()) {
          leaveConference();
          closeSession();
          // }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const changeOpportunityStatus = () => {
    var data = {
      chat_room_id: chatId,
      opportunity_id: opportunityId,
      user_id: xchatDetail.user_id,
    };
    commonApi
      .change_opportunity_status(data)
      .then(res => {
        if (res.status === 200) {
          triggerEventOpportunityChatStatus(chatId, false);
          if (res.success) {
            successMessage(res.message);
          } else {
            errorMessage(res.message);
          }
          setChatEnable(true);
          setChatEnableStatus('pending');
          setChatroomStatus('pending');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUserData = id => {
    var data = {user_id: id};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserData(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getSpecificAccomplishment = (data, idOfSnapshot) => {
    let id = data.map(item => item.id);

    var payload = {
      accomplishment_ids: id,
    };
    commonApi
      .get_specific_accomplishment(payload)
      .then(res => {
        if (res.success) {
          // alert('success')
          getUserData(res.accomplishment_detail[0].created_by);
          if (id.length > 1) {
            // alert('id')
            setSnapshotSingleId(idOfSnapshot);
            setSnapshotMultiIds(id.toString(','));
            setShowSnapshotDetail(true);
          } else {
            // alert('else')
            setSnapshotData(res.accomplishment_detail[0]);
            setShowSnapshotDetail(true);
          }
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const saveOppotunityLastMessage = (chat, message) => {
    var data = {opportunity_chat_room_id: chat, last_message: message};
    commonApi
      .add_opportunity_last_message(data)
      .then(res => {
        if (res) {
          clearData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const saveLastMessage = (chat, message) => {
    var data = {chat_room_id: chat, last_message: message};
    commonApi
      .save_last_message(data)
      .then(res => {
        if (res) {
          clearData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const saveQuestionLastMessage = (chat, message, id) => {
    var data = {chat_id: chat, last_message: message, question_id: id};
    commonApi
      .add_last_message_in_question(data)
      .then(res => {
        if (res) {
          clearData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const sendMessage = async () => {
    if (
      messageBody.message !== '' ||
      messageBody.picture_url !== '' ||
      messageBody.document_url !== '' ||
      messageBody.video_url !== '' ||
      messageBody.emoji !== '' ||
      messageBody.appointment_data !== '' ||
      messageBody.group_chat_data !== '' ||
      messageBody.event_data !== '' ||
      messageBody.session_data !== '' ||
      messageBody.opportunity_data !== '' ||
      messageBody.network_data !== '' ||
      messageBody.accomplishment_data !== ''
    ) {
      // alert('eeeeeeeeeeeeeeeeee')
      let otherUserId = null;
      if (props.userData) {
        otherUserId = props.userData.hasOwnProperty('user_id')
          ? props.userData.user_id
          : props.userData.id;
      }
      let foundChat = false;
      let chatIdNew = '';
      let currentChatRoomName = chatRoomName;
      if (isNewChatroom) {
      } else {
        if (xchatType === 'new-opportunity') {
          chatIdNew = await createOpportunityChatroom();
        } else if (xchatType === 'new-personal-single') {
          if (otherUserId) {
          } else {
            console.error('uer data not found');
            return false;
          }
          foundChat = await checkChatRoom(otherUserId);
          if (foundChat) {
            chatIdNew = foundChat.chatId;
            currentChatRoomName = foundChat.chatName;
          } else {
            chatIdNew = await createChatroom();
          }
        }
        if (props.allmsg && xchatType === 'mine-opportunity') {
          if (chatroomObj.type === 'opportunity') {
            // chatIdNew = await createOpportunityChatroom();
            chatIdNew = await createOpportunityChatroomCreator();
          } else {
            if (otherUserId) {
            } else {
              console.error('uer data not found');
              return false;
            }
            foundChat = await checkChatRoom(otherUserId);
            if (foundChat) {
              chatIdNew = foundChat.chatId;
              currentChatRoomName = foundChat.chatName;
            } else {
              chatIdNew = await createChatroom();
            }
          }
        }
      }

      if (isReplies) {
        let replyIs = messageBody;
        replyIs.send_date = new Date().getTime();
        // replyIs.uniqueKey = generateName(20);
        let updatedMsg = {};

        replyIs.first_name = localStorage.getItem('firstName');
        replyIs.last_name = localStorage.getItem('lastName');
        replyIs.profile_img = localStorage.getItem('profilePic');
        if (isPrivateReply) {
          const found = allChatUsers.find(
            objs => parseInt(objs.id) === parseInt(msgForReply.sent_by),
          );
          replyIs.isPrivate = true;
          replyIs.privateChatData.first_name = found.first_name;
          replyIs.privateChatData.last_name = found.last_name;
          replyIs.privateChatData.profile_img = found.profile_img;
          replyIs.privateChatData.sent_to = found.id
            ? found.id
            : msgForReply.sent_by;
        }

        if (
          msgForReply.hasOwnProperty('replies') &&
          msgForReply.replies.length > 0
        ) {
          let prevReplies = msgForReply.replies;
          prevReplies.push(replyIs);
          updatedMsg = {replies: prevReplies};
        } else {
          updatedMsg = {replies: [replyIs]};
        }
        setPrivateMessageUsers([]);
        setIsReplies(false);
        setIsPrivateReply(false);
        const databaseRef = ref(
          db,
          'chatrooms/' +
            xfbFolder +
            '/' +
            chatRoomName +
            '/' +
            msgParentKey +
            '/' +
            msgChildKey,
        );
        update(databaseRef, updatedMsg)
          .then(() => {
            setMsgChildKey('');
            setMsgParentKey('');
            let lastMsg = messageBody.message ? messageBody.message : otherMsg;
            // openChatroom(chatRoomName);
            if (messageBody.isPrivate || msgForReply.isPrivate) {
              clearData();
            } else {
              if (xchatType === 'qa-chats') {
                lastMsg = messageBody.type === 'poll' ? 'Poll' : lastMsg;
                saveQuestionLastMessage(chatId, lastMsg, props.item.id);
              } else {
                if (xfbFolder === 'chats') {
                  saveLastMessage(chatId, lastMsg);
                } else {
                  saveOppotunityLastMessage(chatId, lastMsg);
                }
              }
            }
            setshowLoader(false);
          })
          .then(() => {
            // clearData();
          });
      } else {
        let msgObj = messageBody;
        msgObj.send_date = new Date().getTime();

        const databaseRef = ref(
          db,
          'chatrooms/' +
            xfbFolder +
            '/' +
            currentChatRoomName + // chatRoomName
            '/' +
            getDateFormatForChat(Date.now()),
        );
        push(databaseRef, msgObj)
          .then(() => {
            let lastMsg = messageBody.message ? messageBody.message : otherMsg;
            if (chatIdNew && !foundChat) {
              openChatroom(currentChatRoomName); // chatRoomName
            }
            // openChatroom(chatRoomName);
            if (messageBody.isPrivate) {
              clearData();
            } else {
              lastMsg =
                messageBody.type === 'poll'
                  ? messageBody.message.question
                  : lastMsg;
              if (xchatType === 'qa-chats') {
                saveQuestionLastMessage(chatId, lastMsg, props.item.id);
              } else {
                if (xfbFolder === 'chats') {
                  if (chatIdNew) {
                    saveLastMessage(chatIdNew, lastMsg);
                    checkSessionEventChatStatus(chatIdNew);
                  } else {
                    saveLastMessage(chatId, lastMsg);
                  }
                } else {
                  if (chatIdNew) {
                    saveOppotunityLastMessage(chatIdNew, lastMsg);
                  } else {
                    saveOppotunityLastMessage(chatId, lastMsg);
                  }
                }
              }
            }
            setshowLoader(false);
            scrollToBottom();
          })
          .then(() => {
            // clearData();
          });
      }
    }
  };

  const closeChatRoom = () => {
    var payLoad = {
      chat_room_id: chatId,
    };
    commonApi
      .close_chat_room(payLoad)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const leftChatRoom = () => {
    var payLoad = {
      chat_room_id: chatId,
    };
    commonApi
      .left_chat_room(payLoad)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const sendEmojiNotification = (id, type) => {
    let payLoad = {
      chat_id: id,
      type: type,
    };
    commonApi.send_emoji_notification(payLoad);
  };

  const openChatroom = chatRoomName => {
    if (props.type !== 'session-group') {
      detachesChatroom();
    }
    localStorage.removeItem('isCorrectPath');
    localStorage.removeItem('isCorrectMessage');

    localStorage.setItem(
      'chatPath',
      'chatrooms/' + xfbFolder + '/' + chatRoomName,
    );
    const database = ref(db, 'chatrooms/' + xfbFolder + '/' + chatRoomName);
    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        setallChat(snapshot.val());
        props.setChatCount(snapshot.val())

        setTimeout(() => {
          dispatch(callChatApi());
        }, 1000);
      } else if (snapshot.val() === null) {
        setallChat([]);
        props.setChatCount(snapshot.val())
      }
    });
  };

  const handleRemoveFile = () => {
    setOtherMsg(null);
    setSelectedImage(null);
    setVideoPreviewUrl(null);
    inputRef.current.value = null;
  };

  const handlePrivate = () => {
    setMessageBody({...messageBody, isPrivate: true});
    setPrivateUser(false);
  };

  const handleMentionUsers = item => {
    const privateUserObj = {
      sent_to: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      profile_img: item.profile_img,
    };
    setMessageBody({
      ...messageBody,
      privateChatData: privateUserObj,
      message: '@' + fixName(item.first_name, item.last_name),
    });
    setWrittenMsg('@' + fixName(item.first_name, item.last_name));
    setPrivateUser(true);
    setTimeout(() => {
      setPrivateUser(false);
    }, 5000);
  };

  const allMembers = () => {
    let memberNames = '';

    allChatUsers.slice(0, 2).forEach((item, index) => {
      memberNames += item.first_name + ' ' + item.last_name;
      memberNames += index < 1 ? ', ' : ' ';
    });

    return memberNames;
  };

  const createOpportunityCall = async () => {
    let payLoad = {
      audience_ids: [],
      opportunity_id: '',
      chat_room_id: '',
    };
    if (
      props.chatDetails &&
      Object.entries(props.chatDetails).length > 0 &&
      !props.chatDetails.group_detail
    ) {
      payLoad.audience_ids = [props.chatDetails.user_id];
      payLoad.opportunity_id = props.chatDetails.opportunity_id;
      payLoad.chat_room_id = props.chatDetails.id
        ? props.chatDetails.id
        : props.chatDetails.chat_id;
    } else {
      payLoad.audience_ids = [getItem('chatUserId')];
      payLoad.opportunity_id = props.oppoDetails.id;
      payLoad.chat_room_id = chatId;
    }

    const response = await commonApi.create_opportunity_call_dolby(payLoad);

    if (response.session_id) {
      setOppoCall(response);
      if (response.started_by === getItem('chatUserId')) {
        amplitudeEvent('START_OPPORTUNITY_CALL');
      } else {
        amplitudeEvent('JOIN_OPPORTUNITY_CALL');
      }
    } else {
      errorMessage(response.message);
    }
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // setScrollPos(scrollTop);
    // setItem('scrollPosition', scrollTop);
    setItem('scrollTop', scrollTop);
    // Check if the user has reached the end of the content
    if (clientHeight + 5 + scrollTop >= scrollHeight - clientHeight / 3) {
      // Increment the page number and call the API
      // setAutoScroll(true);
      setScrollable(true);
    } else {
      // setAutoScroll(false);
      setScrollable(false);
    }
    if (clientHeight + 2 + scrollTop >= scrollHeight) {
      setAutoScroll(true);
    } else {
      setAutoScroll(false);
    }
  };

  const userMessageStatus = id => {
    const payload = {chat_id: id};
    commonApi.user_message_status(payload);
  };
  const ShowSelectedComponent = (data, type) => {
    setDataForComponent(data);
    setShowComponent(type);
  };

  const opportunityMessageStatus = id => {
    const payload = {chat_id: id};
    commonApi.opportunity_message_status(payload);
  };

  const messageRead = (id, type) => {
    if (type === 'opportunities') {
      opportunityMessageStatus(id);
    } else {
      userMessageStatus(id);
    }
  };

  function handleFileUpload(event) {
    // handleFocus();
    const file = event.target.files[0];
    const fileType = file.type;

    if (file && file.size > 10485760) {
      // 10MB in bytes
      errorMessage('File size should not exceed 10MB');
      event.target.value = null; // reset the file input
    } else if (
      allowedImageTypes.includes(fileType) ||
      allowedVideoTypes.includes(fileType)
    ) {
      makeFile(file);
      event.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      event.target.value = null; // reset the file input
    }
  }

  const newDecision = () => {
    if (dataForComponent.login_user && dataForComponent.login_user.status) {
      return dataForComponent.login_user.status;
    } else if (dataForComponent.chat_detail.hasOwnProperty('status')) {
      return dataForComponent.chat_detail.status;
    } else {
      return 'pending';
    }
  };

  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
      <Popover.Body>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Body>
    </Popover>
  );

  // useEffect(() => {

  // }, [chatEnableStatus]);

  useEffect(() => {
    if (callCount > 1 && chatId) {
      // checkNewUsersInGroup(chatId);
      checkNewUsersInOpportunity(chatId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callCount]);

  useEffect(() => {
    if (personalChatCount > 1 && chatId) {
      checkNewUsersInGroup(chatId, 60000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalChatCount]);

  useEffect(() => {
    if (chatRoomName && isNewChatroom) {
      openChatroom(chatRoomName);
    }
    setTimeout(() => {
      scrollToBottom();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomName]);

  useEffect(() => {
    if (props.chatRoomName) {
      setItem('currentChatRoom', props.chatRoomName);
      setChatRoomName(props.chatRoomName);
    } else {
      setChatRoomName(
        'chatroom_' + (Math.random() + 1).toString(36).substring(3),
      );
    }
    setNewChatroom(props.chatRoomName);
    clearData();
    setWrittenMsg('');
    toggleCameraOptions(false);
    toggleReplyOptions(false);

    return () => {
      setItem('currentChatRoom', '');
    };
  }, [props.chatRoomName]);

  useEffect(() => {
    // const currentUrl = window.location.href;
    // const lastSlashIndex = currentUrl.lastIndexOf('/');
    // const newSubstring = currentUrl.substring(lastSlashIndex + 1);
    setItem('scrollPosition', '');
    setChatEnable(true);
    setChatroomStatus(
      props.hasOwnProperty('chatRoomStatus')
        ? props.chatRoomStatus
        : props.chatDetails.hasOwnProperty('chat_room_status')
        ? props.chatDetails.chat_room_status
        : props.chatDetails.hasOwnProperty('status')
        ? props.chatDetails.status
        : 'open',
    );
    // offUpdateOppoChatStatus
    if (
      props.chatDetails.hasOwnProperty('invitaion_status') &&
      props.chatDetails.invitaion_status &&
      props.chatDetails.invitaion_status.status === 'left'
    ) {
      let leftDateTime = '';
      const date = props.chatDetails.invitaion_status.updated_at.substring(
        0,
        10,
      );
      const time = props.chatDetails.invitaion_status.updated_at.substring(11);
      leftDateTime = `${date} ${time}`;
      setLastMsgTime(getDateWithTimezone(leftDateTime));
    } else if (newSubstring === 'history') {
      let leftDateTime = '';
      const date = props.chatDetails.invitaion_status
        ? props.chatDetails.invitaion_status.updated_at.substring(0, 10)
        : props.chatDetails.last_message_time
        ? props.chatDetails.last_message_time.substring(0, 10)
        : props.chatDetails.updated_at.substring(0, 10);
      const time = props.chatDetails.invitaion_status
        ? props.chatDetails.invitaion_status.updated_at.substring(11)
        : props.chatDetails.last_message_time
        ? props.chatDetails.last_message_time.substring(11)
        : props.chatDetails.updated_at.substring(11);
      leftDateTime = `${date} ${time}`;
      setLastMsgTime(getDateWithTimezone(leftDateTime));
    } else {
      setLastMsgTime('');
    }

    if (chatroomObj) {
      if (chatroomObj.type) {
        if (chatroomObj.type.indexOf('oppo') !== -1) {
          offUpdateOppoChatStatus(chatId);
          offCheckOppoChatNewUser(chatId);
        } else {
        }
      } else {
        if (props.type.toLowerCase().indexOf('oppo') !== -1) {
          offUpdateOppoChatStatus(chatId);
          offCheckOppoChatNewUser(chatId);
        } else {
        }
      }
    }

    if (props.chatroomInfo) {
      if (props.chatroomInfo.type) {
        if (props.chatroomInfo.type.indexOf('oppo') !== -1) {
          amplitudeEvent('OPEN_OPPORTUNITY_CHAT');
          setShowEndChat('opportunity');
        } else if (props.chatroomInfo.type.indexOf('q') !== -1) {
          amplitudeEvent('OPEN_QUESTION_CHAT');
          setShowEndChat('question');
        } else if (props.chatroomInfo.type.indexOf('even') !== -1) {
          amplitudeEvent('OPEN_EVENT_CHAT');
          setShowEndChat('event');
        } else if (props.chatroomInfo.type.indexOf('ses') !== -1) {
          amplitudeEvent('OPEN_SESSION_CHAT');
          setShowEndChat('session');
        } else if (props.chatroomInfo.chat_room_type === 'single') {
          amplitudeEvent('OPEN_SINGLE_CHAT');
          setShowEndChat('');
        } else if (props.chatroomInfo.chat_room_type === 'group') {
          amplitudeEvent('OPEN_GROUP_CHAT');
          setShowEndChat('');
        } else {
          setShowEndChat('');
        }
        if (props.chatRoomId) {
          if (props.chatroomInfo.type.indexOf('oppo') !== -1) {
            checkOpportunityChatStatus(props.chatRoomId);
            newUserStatusOpportunityChat(props.chatRoomId);
          } else {
            checkSessionEventChatStatus(props.chatRoomId);
            updateNewUsers(props.chatRoomId);
            checkNewUsersInGroup(props.chatRoomId, 60000); // 60000 this is temp
          }
        }
      } else {
        if (props.type.toLowerCase().indexOf('oppo') !== -1) {
          amplitudeEvent('OPEN_OPPORTUNITY_CHAT');
          setShowEndChat('opportunity');
        } else if (props.type.toLowerCase().indexOf('qa') !== -1) {
          amplitudeEvent('OPEN_QUESTION_CHAT');
          setShowEndChat('question');
        } else if (props.type.toLowerCase().indexOf('sin') !== -1) {
          amplitudeEvent('OPEN_SINGLE_CHAT');
          setShowEndChat('');
        } else if (props.type.toLowerCase().indexOf('gro') !== -1) {
          amplitudeEvent('OPEN_GROUP_CHAT');
          setShowEndChat('');
        } else {
          setShowEndChat('');
        }
        if (props.chatRoomId) {
          if (props.type.toLowerCase().indexOf('oppo') !== -1) {
            checkOpportunityChatStatus(props.chatRoomId);
            newUserStatusOpportunityChat(props.chatRoomId);
          } else {
            checkSessionEventChatStatus(props.chatRoomId);
            updateNewUsers(props.chatRoomId);
            checkNewUsersInGroup(props.chatRoomId, 60000); // 60000 this is temp
          }
        }
      }
    }
    messageRead(chatId, xfbFolder);
    setchatId(props.chatRoomId);

    setAllChatUsers(props.allChatUsers);
    // if (props.chatroomInfo) {
    //   setUserCount(props.chatroomInfo.count);
    // }

    if (chatId === props.chatRoomId) {
    } else {
      setChatroomObj(props.chatroomInfo);
      setAddSnapshot(false);
      setShowUsers(false);
      setShowParticipants(false);
      setShowSnapshotDetail(false);
      setShowUserProfile(false);
      setshowEmojiModal(false);
      setOppoGroupChat(false);
      setOppoCall(false);
      setShowReplies(false);
      setCreatePoll(false);
      setSessionEvent(false);
    }

    if (newSubstring === 'history') {
      setChatEnable(false);
    }

    // return offUpdateNewUsers(chatId);

    // return messageRead(chatId, xfbFolder);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatRoomId]);

  const handleFocus = () => {
    if (focusTextArea.current) {
      focusTextArea.current.focus();
    }
  };

  useEffect(() => {
    setxChatType(props.type);
    setxFbFolder('chats');
  }, [props.type]);

  useEffect(() => {
    setxChatDetail(props.chatDetails);
    setxOtherUser(
      props.chatDetails.creator_id === localStorage.getItem('chatUserId')
        ? 'user_detail'
        : props.chatDetails.hasOwnProperty('creator_details')
        ? 'creator_details'
        : 'creator_detail',
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatDetails]);

  // useEffect(() => {
  //   setChatroomObj(props.chatroomInfo);
  // }, [props.chatroomInfo]);

  useEffect(() => {
    sendMessage();
    // alert('|||||||||')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageBodyNew]);
  useEffect(() => {
    getAccomplishment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAccomplishment = () => {
    var payLoad = {
      categories: [],
      user_id: localStorage.getItem('chatUserId'),
    };
    commonApi
      .get_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          setSnapShotImagesLast3(res.accomplishment);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    scrollToBottom(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChat]);

  useEffect(() => {
    if (
      messageBody.isPrivate &&
      (writtenMsg.length === 0 ||
        (writtenMsg.length > 0 && writtenMsg[0] !== '@'))
    ) {
      setMessageBody({
        ...messageBody,
        isPrivate: true,
        privateChatData: {
          first_name: '',
          last_name: '',
          profile_img: '',
          sent_to: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writtenMsg]);

  // useEffect(() => {
  //   if (scrollRef.current) {
  //     scrollRef.current.view.scrollTop = scrollPos;
  //   }
  // });

  useEffect(() => {
    if (scrollRef.current) {
      if (autoScroll) {
        // scrollRef.current.view.scrollTop = scrollRef.current.view.scrollHeight;
        // scrollRef.current.scrollToBottom();
      } else {
        // scrollRef.current.view.scrollTop = scrollPos;
      }
    }
    // handleFocus();
  });

  useEffect(() => {
    // updateNewUsers();
    const {pathname, state} = locationWeb;
    if (state && state.typeIs === 'opportunity_call') {
      setTimeout(() => {
        createOpportunityCall();
        history.replace({pathname: pathname, key: Date.now()});
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      addSnapshot ||
      showUsers ||
      showParticipants ||
      showSnapshotDetail ||
      showUserProfile ||
      showEmojiModal ||
      oppoGroupChat ||
      oppoCall ||
      showReplies ||
      createPoll ||
      sessionEvent
    ) {
      setItem('scrollPosition', getItem('scrollTop'));
    } else {
      const storedScrollPosition = getItem('scrollPosition');
      if (storedScrollPosition && scrollRef.current) {
        scrollRef.current.view.scrollTop = storedScrollPosition;
      }
      handleFocus();
    }
  }, [
    addSnapshot,
    showUsers,
    showParticipants,
    showSnapshotDetail,
    showUserProfile,
    showEmojiModal,
    oppoGroupChat,
    oppoCall,
    showReplies,
    createPoll,
    sessionEvent,
  ]);

  const emojisAndSnapshots = getEmojisAndSnapshots(
    allChat,
    localStorage.getItem('chatUserId')
      ? localStorage.getItem('chatUserId')
      : props.loginUserId,
  );
  console.log(allChatUsers, 'allChatUsersallChatUsersallChatUsers');

  console.log(chatRoomName, 'chatRoomName3123');

  console.log(lastMsgTime, 'lastMsgTime');
  console.log(allChat, 'allChatallChat');
  console.log(isReplies, 'isReplies');
  console.log(showComponent, 'showComponent');
  return (
    <>
      {/* Default view of chat */}

      {/* <div style={{display: 'flex'}}> */}
      <div className="messages-wrapper fix-width-forms">
        {showLoader && <LoaderSpinner />}

        <div
          className={`messages-header-icons ${
            chatStatusIn.includes(chatRoomStatus) && chatIsEnable && chatId
              ? 'as-link'
              : 'disable-button'
          }`}
          style={{marginTop: '0px', marginBottom: '10px'}}>
          {/* {!isQuestionChat && !isOpportunityChat && ( */}
          {!showEndChat && (
            <span
              onClick={() => {
                if (
                  chatStatusIn.includes(chatRoomStatus) &&
                  chatIsEnable &&
                  chatId
                ) {
                  setModalEndChat(true);
                }
              }}>
              {/* <i className="icon-logout"></i> */}
            </span>
          )}
        </div>

        <>
          <div className="primary_panel_content live_chat_box">
            <div className="refferal-list-wrapper">
              {Object.entries(allChat).length > 0 && (
                <Scrollbars
                  className="scrollbaar"
                  ref={scrollRef}
                  onScroll={handleScroll}
                  style={{width: '100%', flex: 1}}>
                  <SmartLiveChatBox
                    lastMsgTime={lastMsgTime}
                    allChatUsers={allChatUsers}
                    allChat={Object.entries(allChat)}
                    handleChatBoxSignal={handleChatBoxSignal}
                    chatStatus={
                      chatStatusIn.includes(chatRoomStatus) && chatIsEnable
                    }
                    chatModule={xchatType}
                    chatEnableStatus={chatEnableStatus}
                    ShowSelectedComponent={ShowSelectedComponent}
                    handleOpenChatRoom={handleOpenChatRoom}
                    loginUserId={props.loginUserId}
                  />
                </Scrollbars>
              )}
            </div>

            {chatStatusIn.includes(chatRoomStatus) &&
              chatIsEnable &&
              props.userData?.id && (
                <div className="message-form" style={{padding:'9px 0 9px 0'}}>
                  <input
                    style={{display: 'none'}}
                    ref={inputRef}
                    type="file"
                    name="myImage"
                    onChange={event => {
                      handleFileUpload(event);
                    }}
                  />
                  {/* Show user list make private */}
                  {writtenMsg === '@' &&
                    chatroomObj.chat_room_type === 'group' &&
                    allChatUsers.length > 1 && (
                      // writtenMsg
                      <div className="reply-wrapper user-name-list">
                        {allChatUsers.map((item, index) => {
                          return (
                            item.id !== localStorage.getItem('chatUserId') &&
                            item.type !== 'left' &&
                            (!item.status || item.status === 'accepted') &&
                            (privateMessageUsers.length === 0 ||
                              privateMessageUsers.includes(item.id)) && (
                              <div
                                className="user-item as-link"
                                onClick={() => {
                                  handleMentionUsers(item);
                                }}
                                key={`${item.id}-${index}`}>
                                {/* {`${item.first_name} ${item.last_name}`} */}
                                @{fixName(item.first_name, item.last_name)}
                              </div>
                            )
                          );
                        })}
                      </div>
                    )}
                  {/* Show message reply container (old structure) */}
                  {isReply && messageBody.hasOwnProperty('reply') && (
                    <div className="reply-wrapper bottom-reply">
                      <p>{messageBody.reply.message}</p>
                      {messageBody.reply.type === 'image' && (
                        <div className="msg-img as-link">
                          <img
                            src={messageBody.reply.picture_url}
                            style={{width: '50px'}}
                            alt=""
                          />
                        </div>
                      )}
                      {messageBody.reply.type === 'snapshot' &&
                        Object.entries(messageBody.reply.picture_url).length &&
                        messageBody.reply.picture_url.map((item, index) => {
                          return (
                            <div
                              // onClick={() => {
                              //     getSpecificAccomplishment(item.id);
                              // }}
                              key={index}
                              className="msg-img">
                              <img
                                src={item.images[0]}
                                style={{width: '50px'}}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      {messageBody.reply.video_url && (
                        <div className="msg-img">
                          <video
                            src={messageBody.reply.video_url}
                            style={{width: '50px'}}
                            playsInline
                            autoPlay
                            controls
                          />
                        </div>
                      )}
                      <span
                        className="close as-link"
                        onClick={() => {
                          setIsPrivateReply(false); // need to confirm
                          setPrivateMessageUsers([]);
                          setIsReplies(false);
                          setIsReply(false);
                          setMessageBody(current => {
                            const {reply, ...rest} = current;
                            return rest;
                          });
                        }}>
                        <i className="fas fa-times" />
                      </span>
                      {/* <span>{getTimeFormatForChat(messageBody.reply.send_date)}</span> */}
                    </div>
                  )}
                  {/* Show message reply container (new structure) */}
                  {isReplies && msgForReply.hasOwnProperty('message') && (
                    // typeof msgForReply.message !== 'object' && (
                    <div className="reply-wrapper bottom-reply">
                      {/* <p>{msgForReply.message}</p> */}
                      <ChatReplyBox reply={msgForReply} />

                      <span
                        className="close as-link"
                        onClick={() => {
                          setPrivateMessageUsers([]);
                          setIsReplies(false);
                          setIsPrivateReply(false); // need to confirm
                        }}>
                        <i className="fas fa-times" />
                      </span>
                      {/* <span>{getTimeFormatForChat(messageBody.reply.send_date)}</span> */}
                    </div>
                  )}
                  {otherMsg === 'Image' && selectedImage ? (
                    <span className="msg-img">
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt=""
                        onClick={() => {
                          showLightbox();
                        }}
                      />
                      <i className="fas fa-times" onClick={handleRemoveFile} />
                    </span>
                  ) : otherMsg === 'Video' && videoPreviewUrl ? (
                    <span className="msg-img">
                      <video style={{width: '50px'}} src={videoPreviewUrl} />
                      <i className="fas fa-times" onClick={handleRemoveFile} />
                    </span>
                  ) : (
                    <>
                      <span
                        onClick={() => toggleCameraOptions(!cameraOptions)}
                        className="form-icon text-yellow smartlivechat-text-yellow-holder">
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </>
                  )}
                  <Form.Group className="form-input">
                    <TextareaAutosize
                      autoFocus
                      maxRows={4}
                      minRows={1}
                      name="message"
                      value={writtenMsg}
                      className="form-control"
                      onKeyDown={e => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          saveMessage();
                        }
                      }}
                      onChange={e => {
                        setMessageBody({
                          ...messageBody,
                          message: e.target.value,
                        });
                        setWrittenMsg(e.target.value);
                      }}
                      placeholder="Type your message here"
                      ref={focusTextArea}
                    />
                  </Form.Group>
                  <span
                    onClick={() => {
                      saveMessage();
                    }}
                    className="form-icon send-btn">
                    <i className="icon-plane" />
                  </span>
                </div>
              )}
          </div>
        </>
      </div>
      {/* </div> */}

      {/* When user start a call in opportunity chat */}

      {/* When user convert single opporunity chat into group */}

      {/* Modal Component for All Emojis */}
      {showEmojiModal && (
        <EmojiModal
          onClose={() => setshowEmojiModal(false)}
          sendEmoji={icon => {
            handleAddEmoji(icon);
          }}
        />
      )}
      {showEmojiModal === 'add-emoji' && (
        <EmojiModal
          onClose={() => setshowEmojiModal(false)}
          sendEmoji={icon => {
            sendMessagesEmoji(icon);
          }}
        />
      )}

      {/* Replies Component */}
      {showReplies && (
        <SmartLiveChatReply
          onClose={() => {
            setShowReplies(false);
          }}
          type={'liveChat'}
          chatx={handleData.item}
          allChatUsers={allChatUsers}
          folderName={xfbFolder}
          messagePath={
            'chatrooms/' +
            xfbFolder +
            '/' +
            chatRoomName +
            '/' +
            handleData.pKey +
            '/' +
            handleData.cKey
          }
          xfbFolder={xfbFolder}
          chatRoomName={chatRoomName}
          chatId={chatId}
          item={props.item}
          chatDetails={props.chatDetails}
          withHeadingSpace={
            props.withHeadingSpace || props.windowWidth <= 1295 ? true : false
          }
          chatEnableStatus={chatEnableStatus}
          snapShotImagesLast3={snapShotImagesLast3}
          // workSpaceItem={props.workSpaceItem}
        />
      )}

      {/* Custom Modal open when someone clicks on camera icon */}
      {cameraOptions && (
        <CustomModal
          position="bottom"
          onClose={() => toggleCameraOptions(!cameraOptions)}>
          <div className="camera-options-list w-100 chatActionSheet">
            <ul>
              <li className="as-link emojies-list">
                {emojisAndSnapshots &&
                  emojisAndSnapshots.emojis &&
                  emojisAndSnapshots.emojis.map((element, index) => (
                    <span className="add-emoji as-link" key={index}>
                      <img
                        onClick={() => {
                          sendMessagesEmoji(
                            S3_EMOJI_DEV +
                              element.skinTone +
                              '/' +
                              element.emojiName,
                          );
                          toggleCameraOptions(!cameraOptions);
                        }}
                        src={
                          S3_EMOJI_DEV +
                          element.skinTone +
                          '/' +
                          element.emojiName +
                          '.svg'
                        }
                        className=""
                        alt=""
                      />
                    </span>
                  ))}
                <span
                  className="add-emoji as-link " style={{paddingLeft:emojisAndSnapshots.emojis.length > 0 ? '' : 0}}
                  onClick={() => {
                    toggleCameraOptions(!cameraOptions);
                    // alert('hehehe')
                    ShowEmojModel(
                      'send-emoji',
                      // replyOptionObj.signalData,
                      // replyOptionObj.childKey,
                      // replyOptionObj.parentKey,
                    );
                  }}>
                  {/* <i className="fas fa-smile" />{' '} */}
                  <img
                    src={require('../../Assets/images/like-emojie.png')}
                    className=""
                    alt=""
                  />
                </span>
              </li>

              <li
                className="as-link image-list image_input"
                onClick={() => {
                  // toggleCameraOptions(false);
                  // handleSelect('1');
                }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={e => changeTicketImg(e)}
                />
                <i className="icon-camera" /> {labels.ADD_IMAGE[selected_lang]}
              </li>
            </ul>
          </div>
        </CustomModal>
      )}

      {/* Custom Modal open when someone open a chat reply and click on threedots */}
      {replyOptions && replyOptionObj && (
        <CustomModal
          position="bottom"
          onClose={() => toggleReplyOptions(!replyOptions)}>
          <div className="camera-options-list w-100 chat-camera-sheet">
            <div className="emoji-list d-flex align-items-center mb-3">
              {emojisAndSnapshots &&
                emojisAndSnapshots.emojis &&
                emojisAndSnapshots.emojis.length > 0 &&
                emojisAndSnapshots.emojis.map((element, index) => (
                  <span className="add-emoji as-link" key={index}>
                    <img
                      onClick={() => {
                        toggleReplyOptions(!replyOptions);
                        handleAddEmoji(
                          S3_EMOJI_DEV +
                            element.skinTone +
                            '/' +
                            element.emojiName,
                        );
                      }}
                      src={
                        S3_EMOJI_DEV +
                        element.skinTone +
                        '/' +
                        element.emojiName +
                        '.svg'
                      }
                      className=""
                      alt=""
                    />
                  </span>
                ))}

              <span
                className="add-emoji as-link like-emojie"
                onClick={() => {
                  handleReplyOptions(
                    'add-emoji',
                    replyOptionObj.signalData,
                    replyOptionObj.childKey,
                    replyOptionObj.parentKey,
                  );
                }}>
                <img
                  src={require('../../Assets/images/like-emojie.png')}
                  className=""
                  alt=""
                />
              </span>
            </div>
            <ul>
              <li
                className="as-link"
                onClick={() => {
                  handleReplyOptions(
                    'add-replies',
                    replyOptionObj.signalData,
                    replyOptionObj.childKey,
                    replyOptionObj.parentKey,
                  );
                }}>
                <i className="fas fa-comment" />{' '}
                <p className="p-reg mb-0">Reply</p>
              </li>
            </ul>
          </div>
        </CustomModal>
      )}

      {/* Modal to end chat */}
      {modalEndChat && (
        <CustomModal size="small" onClose={() => setModalEndChat(false)}>
          <div className="leave-session-content">
            <h3>{chatroomObj.creator ? 'End Chat' : 'Leave Chat'}</h3>
            <p>
              Do you want to {chatroomObj.creator ? 'end' : 'leave'} the chat?
            </p>

            <div className="text-center">
              <Button
                className="btn-dark"
                onClick={() => {
                  if (chatroomObj.creator) {
                    closeChatRoom();
                  } else {
                    leftChatRoom();
                  }
                  setModalEndChat(false);
                }}>
                Yes
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  setModalEndChat(false);
                }}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {/* Modal to end chat */}
      {modalLeavePolietly && (
        <CustomModal size="small" onClose={() => setModalLeavePolietly(false)}>
          <div className="leave-session-content">
            <h3>Leave Opportunity</h3>
            <p>Do you want to leave the chat?</p>

            <div className="text-center">
              <Button
                className="btn-dark"
                onClick={() => {
                  setModalLeavePolietly(false);
                  setshowLoader(true);
                  leavePolitely();
                }}>
                Yes
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  setModalLeavePolietly(false);
                }}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {/* Modal to show all users with reaction on a message */}
      {showEmojis && (
        <UsersReactionModal
          emojiList={emojiList}
          allUsers={allChatUsers}
          setUserId={id => {
            setShowUserProfile(id);
          }}
          onClose={() => {
            setShowEmojis(false);
          }}
        />
      )}

      {show && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShow(false);
          }}>
          <div className="chat-img-preview">
            <img src={popupImages[0].image} alt="" />
          </div>
        </CustomModal>
      )}
    </>
  );
};

const mapStatProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStatProps, {})(SmartChatPage);
