import {labels} from '../../Constants/Translations';

import {selected_lang} from '../../Utils/env';
// import {RenderHTMLBlock} from '../../Utils/Helpers';

import {RenderHTMLBlock} from '../../Utils/Helpers';
import React, {useState, useEffect} from 'react';
// import OpportunityDetail from './OpportunityDetail';
import commonApi from '../../Services/CommonService';
import { successMessage} from '../../Utils/ToastMessages';
import {formatAmount} from '../../Utils/commonUtils';

const ShareAableOpportunity = ({
  item,
  setShowButton,
  handleItem,
  onShare,
  setChatData,
  hideRefer,
  filterFavroiuteList,
  ElementDesign,
  typeMesages,
  typeForNpClick,
  type
}) => {
  // const [showButton, setShowButton] = useState(false);
  // const [newUserId, setNewUserId] = useState('');
  const [favourite, setFavourite] = useState(item.like);
  const heartClass = item.like ? 'fas' : 'far';

  const [showMore, toggleShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';

  useEffect(() => {
    item.like = favourite;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourite]);

  const favouriteOpportunity = () => {
    let payload = {
      opportunity_id: item.id,
    };
    commonApi
      .manage_favourite_opportunity(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            item.like = true;
            setFavourite(true);
          } else {
            item.like = false;
            setFavourite(false);
          }
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getOpportunityData = (userData, id) => {
    var data = {
      user_id: userData,
      type: 'mine',
    };
    commonApi
      .get_user_available_opportunities(data)
      .then(res => {
        if (res) {
        

          res.user_available_opportunity.forEach(item => {
            
            if (item.id === id) {
              setChatData(item.chat_detail);
              // setChatData(item.chat_detail.id);
            
            }
          });

          // setChatData(res.user_available_opportunity.chat_detail);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const functionId = userData => {
  //   setNewUserId(userData);
  // };
  return (
    <>
      <div
        className={
          !typeMesages
            ? 'as-link opportunity-item card-container'
            : 'as-link container-fromChat-Shareables'
        }>
        <div className={!typeMesages ? 'padd-with-borderContaier' : ''}>
          <h2
            onClick={() => {
              if (!typeForNpClick) {
                if (typeMesages) {
                  handleItem(item, 'opportunity');
                  getOpportunityData(item.created_by, item.id);
                } else {
                  setShowButton(true);
                  handleItem(item, 'opportunity');
                  getOpportunityData(item.created_by, item.id);
                }
              }
              
            }}>
            {item.title}
          </h2>
          <div className="oppo-share-wrapper as-link ">
            <span
              className="category as-link "
              onClick={() => {
                if (!typeForNpClick) {
                  if (typeMesages) {
                    handleItem(item, 'opportunity');
                    getOpportunityData(item.created_by, item.id);
                  } else {
                    setShowButton(true);
                    handleItem(item, 'opportunity');
                    getOpportunityData(item.created_by, item.id);
                  }
                }
              }}>
              {item.business_name}
            </span>
            {/* {!hideRefer && !typeMesages && (
              <span className="oppo-share-icon">
                <i
                  className="fas fa-share"
                  onClick={() => {
                    if (!typeForNpClick) {
                      onShare();
                      handleItem(item, 'opportunity', 'refer-opportunity');
                    }
                   
                  }}
                />
              </span>
            )} */}
          </div>

          <div
            className="oppo-desc as-link mb-1"
            onClick={() => {
              if (!typeForNpClick) {
                if (typeMesages) {
                  handleItem(item, 'opportunity');
                  getOpportunityData(item.created_by, item.id);
                } else {
                  setShowButton(true);
                  handleItem(item, 'opportunity');
                  getOpportunityData(item.created_by, item.id);
                }
              }
            }}>
            <RenderHTMLBlock
              className={`oppo-desc-content ${showMoreClass}`}
              content={item.description}
            />
          </div>

          {item.description.length > 100 && (
            <div className="oppo-desc as-link ">
              <span
                className="read-more"
                onClick={() => toggleShowMore(!showMore)}>
                {!showMore
                  ? labels.read_more[selected_lang]
                  : labels.read_less[selected_lang]}
              </span>
            </div>
          )}

          <div className="oppo-address as-link ">
            {item.address && (
              <span
                className="address"
                onClick={() => {
                  if (!typeForNpClick) {
                    if (typeMesages) {
                      handleItem(item, 'opportunity');
                      getOpportunityData(item.created_by, item.id);
                    } else {
                      setShowButton(true);
                      handleItem(item, 'opportunity');
                      getOpportunityData(item.created_by, item.id);
                    }
                  }
                }}>
                <i className="icon-map-pin" /> {item.address}
              </span>
            )}
            <span
              className="injeaction-text"
              onClick={() => {
                if (!typeForNpClick) {
                  if (typeMesages) {
                    handleItem(item, 'opportunity');
                    getOpportunityData(item.created_by, item.id);
                  } else {
                    setShowButton(true);
                    handleItem(item, 'opportunity');
                    getOpportunityData(item.created_by, item.id);
                  }
                }
              }}>
              {item.vaccinate === 'yes' && <i className="icon-injection" />}{' '}
              {item.opportunity_type_name}
              <b>{formatAmount(item.amount ? item.amount : '')}</b>
            </span>
            {!hideRefer && !typeMesages && (
              <span
                className="hearticon"
                onClick={() => {
                  if (filterFavroiuteList) {
                    filterFavroiuteList(item.id);
                  }
                  favouriteOpportunity();
                }}>
                <i className={`${heartClass} fa-heart as-link`} />
              </span>
            )}
          </div>
        </div>
        {!typeMesages && !type && ElementDesign('opportunity', item)}
      </div>
      {/* {showButton === true && <OpportunityDetail  item={item} userData={item.creator_details
} onClose={()=>{setShowButton(false);} }/>} */}
    </>
  );
};

export default ShareAableOpportunity;
