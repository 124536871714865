import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import {fixName} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SlidingPanel from '../SlidingPanel';

const FollowersList = props => {
  const [subTabs, setSubTabs] = useState('Followers');
  const [followers, setFollowers] = useState('');
  const [following, setFollowing] = useState('');
  // const [userName, setUserName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryFollowing, setSearchQueryFollowing] = useState('');
  const [countFollowers, setCountFollowers] = useState('');
  const [countFollowing, setCountFollowing] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [newShow, setNewShow] = useState(false);
  const [newUserId, setNewUserId] = useState('');

  const userInfo = () => {
    var payloadUser = {
      user_id: props.userId.id,
    };
    commonApi
      .user_info(payloadUser)
      .then(res => {
        if (res.status === 200) {
          setshowLoader(false);
          
          // setUserName(res.data.first_name + '' + res.data.last_name);
          setCountFollowers(res.data.followers);
          setCountFollowing(res.data.following);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const folowerList = qry => {
    var payloadFolow = {
      allies: '',
      areas: [],
      categories: [],
      search_query: qry,
      user_id: props.userId.id,
    };
    commonApi
      .followers_list(payloadFolow)
      .then(res => {
        if (res.status === 200) {
          setshowLoader(false);
          
          setFollowers(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const followingList = Qry => {
    var payloadFolowing = {
      allies: '',
      areas: [],
      categories: [],
      search_query: Qry,
      user_id: props.userId.id,
    };
    commonApi
      .following_list(payloadFolowing)
      .then(res => {
        setshowLoader(false);
        if (res.status === 200) {
          
          setFollowing(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const openProfile = id => {
    setNewUserId(id);
    setNewShow(true);
  };
  useEffect(() => {
    
    if (searchQuery === '') {
      folowerList();
      userInfo();
    } else {
      setshowLoader(false);
      folowerList();
    }
    if (searchQueryFollowing === '') {
      setshowLoader(true);
      followingList();
      userInfo();
    } else {
      setshowLoader(false);
      followingList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newShow]);

  return (
    <>
      {showLoader && <LoaderSpinner />}

      {!newShow ? (
        <SlidingPanel
          hidePanel={() => {
            props.setShowComponent(oldArr => [
              {...oldArr, followers: false},
            ]);
          }}>
          <div className="serach-filter-wrapper">
            <div className="fix-width-forms">
              <div className="sidebar-heading-wrapper with-panel mt-0 mb-4">
                <div className="heading">
                  <h2>
                    {fixName(props.userId.first_name, props.userId.last_name)}
                  </h2>
                </div>
                <div className="with-user-img">
                  <div
                    className="user-profile-img as-link"
                    onClick={() => {
                      openProfile(props.userId.id);
                    }}>
                    <img
                      src={
                        props.userId.profile_img === ''
                          ? DEFAULT_IMAGE
                          : props.userId.profile_img
                      }
                      alt=""
                    />
                  </div>
                  {/* <span
                      className="close as-link"
                      onClick={() =>
                        props.setShowComponent(oldArr => [
                          {...oldArr, ['followers']: false},
                        ])
                      }>
                      <i className="fas fa-times" />
                    </span> */}
                </div>
              </div>
            </div>
            <Scrollbars>
              <div className="fix-width-forms">
                <div className="search-tabs-wrapper">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="event-tabs tabs-style-2 mb-0">
                      <ul>
                        <li>
                          <Button
                            className={subTabs === 'Followers' ? 'active' : ''}
                            onClick={() => setSubTabs('Followers')}>
                            Followers {countFollowers}
                          </Button>
                        </li>
                        <li>
                          <Button
                            className={subTabs === 'Following' ? 'active' : ''}
                            onClick={() => setSubTabs('Following')}>
                            Following {countFollowing}
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {subTabs === 'Following' ? (
                  <>
                    <div className="search-form">
                      {props.userId.id === localStorage.getItem('id') && (
                        <Form.Group className="">
                          <Form.Control
                            type="text"
                            placeholder="Search for contacts"
                            onChange={e => {
                              setSearchQueryFollowing(e.target.value);
                              followingList(e.target.value);
                            }}
                            value={searchQueryFollowing}
                          />
                        </Form.Group>
                      )}
                      <span className="info-text">
                        You can follow by tapping into their profile
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {subTabs === 'Followers' ? (
                  <>
                    <div className="search-form">
                      {props.userId.id === localStorage.getItem('id') && (
                        <Form.Group className="">
                          <Form.Control
                            type="text"
                            placeholder="Search for contacts"
                            onChange={e => {
                              folowerList(e.target.value);
                              setSearchQuery(e.target.value);
                            }}
                            value={searchQuery}
                          />
                        </Form.Group>
                      )}
                      <span className="info-text">
                        You can follow by tapping into their profile
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="stage-list-wrapper mt-2">
                  {subTabs === 'Following' ? (
                    <>
                      <Row>
                        {Object.entries(following).length > 0 ? (
                          Object.entries(following).length > 0 &&
                          following.map((item, index) => (
                            <Col
                              md={3}
                              key={`following -${index}`}
                              onClick={() => {
                                openProfile(item.id);
                              }}>
                              <div className="stage-list-item">
                                <div className="stage-person-img as-link">
                                  <img
                                    src={
                                      item.profile_img === ''
                                        ? DEFAULT_IMAGE
                                        : item.profile_img
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="stage-person-name as-link">
                                  <h3>
                                    {fixName(item.first_name, item.last_name)}
                                  </h3>
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : !showLoader ? (
                          <div className="text-center">{labels.no_record_found[selected_lang]}</div>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {subTabs === 'Followers' ? (
                    <>
                      <Row>
                        {Object.entries(followers).length > 0 ? (
                          Object.entries(followers).length > 0 &&
                          followers.map((item, index) => (
                            <Col
                              md={3}
                              key={`followers -${index}`}
                              onClick={() => {
                                openProfile(item.id);
                              }}>
                              <div className="stage-list-item">
                                <div className="stage-person-img as-link">
                                  <img
                                    src={
                                      item.profile_img === ''
                                        ? DEFAULT_IMAGE
                                        : item.profile_img
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="stage-person-name as-link">
                                  <h3>
                                    {fixName(item.first_name, item.last_name)}
                                  </h3>
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : !showLoader ? (
                          <div className="text-center">{labels.no_record_found[selected_lang]}</div>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Scrollbars>
          </div>
        </SlidingPanel>
      ) : (
        <ProfileComponent
          userId={newUserId}
          show={false}
          closeModal={() => {
            setNewShow(false);
          }}
          userData={[]}
          moderator={false}
          onClose={() => {
            setNewShow(false);
          }}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
    </>
  );
};
export default FollowersList;
