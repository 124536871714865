import {rewardStar} from './Images';

export const SliderRideShare = [
  {
    name: 'Total Rides',
    money: '85',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },
  {
    name: 'Total Revenue',
    money: '$2.4k',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Average Trips',
    money: '68 mins',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Average Trips',
    money: '$2.4k',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Average Distance',
    money: '90 miles',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },
  {
    name: 'Ratings',
    money: '4.8',
    time: 'versus last month',
    svgIcon: rewardStar,
    icon: 'fa-solid fa-star',
  },

  {
    name: 'Cancellations',
    money: '23',
    time: 'versus last month',
    svgIcon: rewardStar,
  },
];

export const SliderCircles = [
  {
    name: 'Engagement',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Retention',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
  {
    name: 'Average LTV',
    money: '$2.4k',
    time: 'versus last month',
    svgIcon: rewardStar,
  },
];

export const SliderPickupDrop = [
  {
    name: 'Total Revenue',
    money: '$2.4k',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Profit',
    money: '$4.5k',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Ratings',
    money: '4.8',
    time: 'versus last month',
    svgIcon: rewardStar,
    icon: 'fa-solid fa-star',
  },

  {
    name: 'On-Time',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Cancellations',
    money: '23',
    time: 'versus last month',
    svgIcon: rewardStar,
  },

  {
    name: 'Average Trips',
    money: '$2.4k',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Average Distance',
    money: '90 miles',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },
];

export const SliderNonProfit = [
  {
    name: 'Donations',
    money: '$2.4k',
    time: 'versus last month',
    svgIcon: rewardStar,
  },

  {
    name: 'Subscribers',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Average Donation',
    money: '$2.4k',
    time: 'versus last month',
    svgIcon: rewardStar,
  },

  {
    name: 'Members',
    money: '45',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Average LTV',
    money: '$2.4k',
    time: 'versus last month',
    svgIcon: rewardStar,
  },

  {
    name: 'Engagement',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
];

export const SliderFleet = [
  {
    name: 'Revenue',
    money: '$2.4k',
    time: 'versus last month',
    svgIcon: rewardStar,
  },

  {
    name: 'Average Trip',
    money: '95 miles',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Average Time',
    money: '85%',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Utilization',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
];

export const SliderTeam = [
  {
    name: 'Average Revenue',
    money: '$2.4k',
    time: 'versus last month',
    svgIcon: rewardStar,
  },

  {
    name: 'Engagement',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Retention',
    money: '5y 2m 12d',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Ratings',
    money: '4.8',
    time: 'versus last month',
    svgIcon: rewardStar,
    icon: 'fa-solid fa-star',
  },

  {
    name: 'OKRs',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Referrals',
    money: '145',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
  {
    name: 'Margin',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
];

export const SliderShare = [
  {
    name: 'Shares',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Average Shares',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
  {
    name: 'Engagement',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Conversion',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
  {
    name: 'Average LTV',
    money: '$2.4k',
    time: 'versus last month',
    svgIcon: rewardStar,
  },

  {
    name: 'Revenue',
    money: '$2.4k',
    time: 'versus last month',
    svgIcon: rewardStar,
  },
];


export const SliderAnnouncements = [
    {
      name: 'Engagement',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  
    {
      name: ' Initial Engagement',
      money: '85',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
    {
        name: 'Call to Action',
        money: '85%',
        time: 'versus last month',
        svgIcon: rewardStar,
        // icon: 'fa-solid fa-star',
      },
  ];

export const SliderRetail = [
  {
    name: 'Revenue',
    money: '$2.4k',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Returns',
    money: '$4.5k',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },
  {
    name: 'Margins',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Abandoned',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
  {
    name: 'Abandoned',
    money: '$4.5k',
    time: 'versus last month',
    // svgIcon: rewardStar,
  },

  {
    name: 'Upsells',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
  {
    name: 'Ratings',
    money: '4.8',
    time: 'versus last month',
    svgIcon: rewardStar,
    icon: 'fa-solid fa-star',
  },
];

export const SliderOKRs = [
  {
    name: 'Completion',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },

  {
    name: 'Change Requests',
    money: '45',
    time: 'versus last month',
    svgIcon: rewardStar,
  },

  {
    name: 'Rollover',
    money: '85%',
    time: 'versus last month',
    svgIcon: rewardStar,
    // icon: 'fa-solid fa-star',
  },
];
