import commonApi from '../../Services/CommonService';

export const getDispatch = (type, data) => ({
  type: type,
  payload: data,
});

// export const getDispatchPayload = (type, data, payload) => ({
//   type: type,
//   payload: data,
//   prevPayload: payload,
// });

export const getAccomplishmentCategory = () => {
  return async dispatch => {
    const response = await commonApi.get_accomplishment_category();
    dispatch(
      getDispatch(
        'FILTER/ACCOMPLISHMENT_CATEGORY',
        response.accomplishment_categories,
      ),
    );
    return response;
  };
};

export const getAppointmentType = () => {
  return async dispatch => {
    const response = await commonApi.get_appointment_types();
    dispatch(getDispatch('FILTER/APPOINTMENT_TYPE', response.appointment_type));
    return response;
  };
};

export const getOpportunityType = () => {
  return async dispatch => {
    const response = await commonApi.get_opportunity_types();
    dispatch(
      getDispatch('FILTER/OPPORTUNITY_TYPE', response.opportunity_types),
    );
    return response;
  };
};

// export const getAccomplishmentCategory = () => {
//   return {
//     type: 'FILTER/API',
//   };
// };
