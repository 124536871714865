import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {Pannels} from '../../Components';
import {RewardImg} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import {Scrollbars} from 'react-custom-scrollbars';

const Reward = props => {
  const [currentComponent, setCurrentComponent] = useState('news');
  const [showComponent, setShowComponent] = useState('');

  const [workSpaceItem, setWorkSpaceItem] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Redeemed',
      money: '$2.4k',
      time: 'versus last month',
    },

    {
      name: 'Close Rate',
      money: '25',
      time: 'versus last month',
    },

    {
      name: 'Redeem Duration',
      money: '4.8',
      time: 'versus last month',
    },
    {
      name: 'Sales',
      money: '4.8',
      time: 'versus last month',
    },
  ];
 

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading"
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span
                              style={{
                                fontWeight: 700,
                                fontSize: '13px',
                              }}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="Reward_Screen referal_page_content">
                    <div className="reward_balance">
                      <div
                        style={{
                          color: '#FFF',
                          fontFamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: 'normal',
                          textAlign: 'center',
                          paddingTop: '20px',
                        }}>
                        Your Balance
                      </div>
                      <div
                        style={{
                          color: '#FFF',
                          fontFamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: 'normal',
                          textAlign: 'center',
                          paddingTop: '10px',
                          paddingBottom: '15px',
                        }}>
                        250 Points
                      </div>
                      <div
                        style={{
                          color: '#FFF',
                          fontFamily: 'Inter',
                          fontSize: '13px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: 'normal',
                          textAlign: 'center',
                        }}>
                        30 points away from your next milesotne
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="event-tabs tabs-style-2 mb-0">
                        <ul>
                          <li>
                            <Button
                              className={
                                currentComponent === 'news' ? 'active' : ''
                              }>
                              Available
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={
                                currentComponent === '1122' ? 'active' : ''
                              }>
                              Redeemed
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="search-tabs-icons">
                        <span
                          //   onClick={() => {
                          //     setThreeDots(!threeDots);
                          //   }}
                          className={
                            // threeDots
                            //   ? 'icon active threeDots'
                            //   :
                            'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    <div className="refferal-list-wrapper">
                      <Scrollbars autoHide
                      renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                      // Hide the vertical scrollbar
                      renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
                        <div
                          style={{
                            // width: '385px',
                            height: '360px',
                            borderRadius: '5.015px',
                            border: '1.003px solid #F6F5F5',
                            background: '#FFF',
                            marginTop: '21px',
                          }}>
                          <div>
                            <div
                              style={{
                                margin: '18px',
                                width: '348px',
                                height: '182px',
                                borderRadius: '5.015px',
                                backgroundImage: `url(${RewardImg})`,
                                backgroundPosition: '50%',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: '#D9D9D9',
                              }}></div>
                            <div
                              style={{
                                // marginLeft: '22px',
                                width: '315px',
                                // marginBottom: '11px',
                              }}>
                              <span
                                style={{
                                  color: '#000',
                                  fontFamily: 'Inter',
                                  fontSize: '16px',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  lineHeight: 'normal',
                                  marginBottom: '3px',
                                  marginLeft: '26px',
                                }}>
                                20% off ANY Air Duct Cleaning
                              </span>

                              <p
                                style={{
                                  color: '#726F6F',
                                  fontFamily: 'Inter',
                                  fontSize: '15px',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: 'normal',
                                  marginBottom: '3px',
                                  marginLeft: '26px',
                                  marginTop: '6px',
                                }}>
                                Your home is due for an annual cleaning but
                                don’t worry, we have a special...
                              </p>
                            </div>

                            <div
                              style={{
                                // width: '337px',
                                margin: '9px',
                                height: '58px',
                                borderRdius: '3px',
                                background: '#F1EFE8',
                              }}>
                              <div
                                className="d-flex justify-content-between status_action_section"
                                style={{marginTop: '11px'}}>
                                <span
                                  style={{
                                    color: '#262626',
                                    fontFamily: 'Inter',
                                    fontSize: '16px',
                                    fontstyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    paddingTop: '19px',
                                    paddingLeft: '6px',
                                    // marginLeft: '17px',
                                    // marginTop: '12px',
                                  }}>
                                  15 Days Remaining
                                </span>
                                <Button
                                  style={{
                                    // color: '#FFF',
                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    margin: '7px',

                                    // paddingTop: '9px',
                                    // paddingLeft: '19px',
                                  }}>
                                  Redeem
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
                setCurrentComponent={setCurrentComponent}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Reward;
