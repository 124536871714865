import React from 'react';
import {
  Form,
  Dropdown,
} from 'react-bootstrap';


import { useState} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {RoiLogo, Templates} from '../../Constants/Images';

const CreateForm = props => {
  const [type, setType] = useState('1');
  const [title, setTitle] = useState('');
  const [introStatement, setIntroStatement] = useState('');
  const [statement1, setStatement1] = useState({
    value1 : '',
    value2: ''
  });

  const dropdownMenu = [
    {id: '1', name: 'Training'},
    {
      id: '2',
      name: 'Training',
    },
    {
      id: '3',
      name: 'Training',
    },
  ];

  const statement1Dropdown = [
    {id: '1', name: 'Dropdown'},
    {
      id: '2',
      name: 'Training',
    },
    {
      id: '3',
      name: 'Training',
    },
  ];
  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderHeader}
                      showBlackHeader={true}
                      headerPadding={'24px 20px'}
                      lastDivStyle={{
                        borderRadius: '35px',
                        background: '#f7f6f2',
                      }}
                      firstDivStyle={{
                        padding: 0,
                        background:
                          'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
                      }}
        >
        <div className="serach-filter-wrapper create-sessionEvents">
          <Scrollbars
          renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
          // Hide the vertical scrollbar
          renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}
          >
            <div className="create-ticket-form pb-3 fixed-width-inputs black-head-form-pad-left createForm_wrapper">
              <div className="create-form-page">
                <div className="create-form-page-top">
                  <h1>Form</h1>

                  <img src={Templates} alt="" />
                </div>

                <div className="create-form-page-content">
                  <div className="">
                    <div className="roi-form-dropdown mb-3">
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setType(eventKey);
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {type
                              ? dropdownMenu.find(item => item.id === type).name
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {dropdownMenu.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === type}>
                                {item.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="roi-form-input">
                      <Form.Control
                        type="text"
                        value={title}
                        pattern="^\S.*$"
                        required
                        placeholder="Title"
                        onChange={e => {
                          setTitle(e.target.value)
                          
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Title is required.
                      </Form.Control.Feedback>
                    </div>

                    <div className="roi-form-input">
                      <Form.Control
                        type="text"
                        value={introStatement}
                        pattern="^\S.*$"
                        required
                        placeholder="Intro Statement"
                        onChange={e => {
                          setIntroStatement(e.target.value)
                       
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Intro Statement is required.
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className="statement-1">
                    <div className="form-black-tag">Question/statement 1</div>

                    <div className="mt-3">
                      <div className="roi-form-dropdown mb-3">
                        <div className="select-wrapper">
                          <Dropdown
                            onSelect={(eventKey, event) => {
                              setType(eventKey);
                            }}
                            drop="down">
                            <Dropdown.Toggle variant="primary">
                              {type
                                ? statement1Dropdown.find(
                                    item => item.id === type,
                                  ).name
                                : 'Select Role'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {statement1Dropdown.map((item, index) => (
                                <Dropdown.Item
                                  key={`st-${index}`}
                                  eventKey={item.id.toString()}
                                  active={item.id === type}>
                                  {item.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      <div className="roi-form-input">
                        <Form.Control
                          type="text"
                          value={statement1.value1}
                          pattern="^\S.*$"
                          required
                          placeholder="Enter Value"
                          onChange={e => {
                            setStatement1({
                              ...statement1,
                              value1: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Title is required.
                        </Form.Control.Feedback>
                      </div>

                      <div className="roi-form-input">
                        <Form.Control
                          type="text"
                          value={statement1.value2}
                          pattern="^\S.*$"
                          required
                          placeholder="Enter Value"
                          onChange={e => {
                            setStatement1({
                              ...statement1,
                              value2: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Intro Statement is required.
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="statement-btns">
                      <span>Add Value</span>

                      <span>More Options</span>
                    </div>
                  </div>

                  <div className="statement-2">
                    <div className="form-black-tag mb-3">
                      Question/statement 2
                    </div>
                    <div className="statement-btns">
                      <span>Add Input Request</span>

                      <span>More Options</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>
    </>
  );
};

export default CreateForm;
