import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { errorMessage } from '../Utils/ToastMessages';

const NewMultiDropdown = ({
  setDropdownIds,
  dropdownIds,
  DropDownValues,
  customFunction,
  itemName,
  ErrorMessage,
  shadow,
}) => {
  const getItemName = (item, keys) => {
    for (let key of keys) {
      if (item[key]) {
        return item[key];
      }
    }
    return 'Unknown';
  };

  useEffect(() => {
    setDropdownIds(DropDownValues.map(item => item.id));
  }, [DropDownValues, setDropdownIds]);

  const handleSelection = eventKey => {
    const groupIds = [...dropdownIds];

    if (eventKey === 'all') {
      if (dropdownIds.length === DropDownValues.length) {
        setDropdownIds([]); // Deselect all if all are selected
      } else {
        setDropdownIds(DropDownValues.map(item => item.id)); // Select all
      }
    } else {
      if (groupIds.includes(eventKey)) {
        const newIds = groupIds.filter(id => id !== eventKey);

        if (newIds.length === 0) {
          errorMessage(ErrorMessage);
          return;
        }
        setDropdownIds(newIds);
      } else {
        setDropdownIds([...groupIds, eventKey]);
      }
    }
  };

  const displayText = () => {
    if (dropdownIds.length === DropDownValues.length) {
      return 'All';
    }
    const text =  dropdownIds.length > 0 && dropdownIds.map(id => {
      const item = DropDownValues.find(item => item.id === id);
      return item ? (customFunction ? customFunction(item) : getItemName(item, itemName)) : '';
    }).join(', ');

    return text.length > 20 ? `${text.slice(0, 20)}...` : text || 'Select Options';
  };

  return (
    <div className={`multiselection_dropdown ${shadow ? 'shadow-none' : ''}`}>
      <Dropdown autoClose="outside" onSelect={handleSelection} drop="down">
        <Dropdown.Toggle variant="primary" id="dropdown-autoclose-outside">
          {displayText()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            eventKey="all"
            active={dropdownIds.length === DropDownValues.length}
          >
            All
          </Dropdown.Item>
          {DropDownValues.map((item, index) => (
            <Dropdown.Item
              key={`dropdown-item-${index}`}
              eventKey={item.id}
              active={dropdownIds.includes(item.id)}
            >
              {customFunction ? customFunction(item) : getItemName(item, itemName)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default NewMultiDropdown;
