import { useState,useEffect } from 'react';
import {Button} from 'react-bootstrap';
import {registerStarter, RoiLogo} from '../../Constants/Images';

const RegisterPhone = props => {
  const [responsive, setResponsive] = useState(window.innerWidth <= 650);
  // const [selectedSchedulerTypeItems, setSelectedSchedulerTypeItems] = useState([]);

  useEffect(() => {
   
    const checkWindowWidth = () => {
      setResponsive(window.innerWidth <= 650);
     
    };

    
    window.addEventListener('resize', checkWindowWidth);

    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);
  

  return (
    <>
      <div className="slider-component register-phone account_type">
        <div
          className="slider-component-img"
          style={
            {
              // backgroundImage: `url(${registerStarter})`,
              // backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
              // backgroundPosition: 'center',
            }
          }>
          <div className="roi-logo as-link" style={{zIndex:2}}>
            <a href="/" >
            <img   src={RoiLogo} alt="" />
            </a>
            
          </div>

          <div className="register-page1-text profile_selection">
            <div className="slider-component-img-text">
              Streamlined Experiences
            </div>

            <p className="bottom-text">
              Spend your time on things that matter the most
            </p>
          </div>

          <div className="starter-img">
            <img src={registerStarter} alt="" />
          </div>
        </div>

        <div className="slider-component-content">
          <div className="slider-content">
            <h1 className="mb-2 font_18">Let’s get started</h1>

            <div className="slider-content-inputs" style={{marginTop:'35px'}}>
             {!responsive && (
              <div className="input-group user-type">
                <h3 className="mb-3">Which are you?</h3>
                {/* <Form.Label controlid="validationCustom01">Which are you?</Form.Label> */}
                <Button
                  className={
                    props.button === 'business'
                      ? 'new-btn_login  btn-white mt-3'
                      : 'new-btn_customer btn-white mt-3'
                  }
                  onClick={() => {
                    props.setButton('business');
                  }}>
                  Business
                </Button>
                <Button
                  className={
                    props.button === 'customer'
                      ? 'new-btn_login  btn-white mt-3'
                      : 'new-btn_customer btn-white mt-3'
                  }
                  onClick={() => {
                    props.setButton('customer');
                  }}>
                  Customer
                </Button>
              </div>
             )} 

{responsive && (
              <div className="input-group user-type">
                <h3 className="mb-3">Which are you?</h3>
                {/* <Form.Label controlid="validationCustom01">Which are you?</Form.Label> */}
                <Button
                  className={
                    props.button === 'business'
                      ? 'new-btn_customer  btn-white mt-3'
                      : 'new-btn_login btn-white mt-3'
                  }
                  onClick={() => {
                    props.setButton('business');
                  }}>
                  Business
                </Button>
                <Button
                  className={
                    props.button === 'customer'
                      ? 'new-btn_customer  btn-white mt-3'
                      : 'new-btn_login btn-white mt-3'
                  }
                  onClick={() => {
                    props.setButton('customer');
                  }}>
                  Customer
                </Button>
              </div>
             )} 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPhone;
