"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The VideoPresentation model includes data related to video presentations such as which [participant](#owner) started the conference,
 * the [ID](#ownerid) of this participant, the [URL of the video presentation](#url), and the video presentation [timestamp](#timestamp).
 */
class VideoPresentation {
    /** @ignore */
    constructor(ownerId, url, timestamp) {
        this.ownerId = ownerId;
        this.url = url;
        this.timestamp = timestamp;
    }
}
exports.default = VideoPresentation;
