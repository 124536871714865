import React, {useEffect, useState} from 'react';


const MineModel = ({children, onClose, position, showCloseIcon, size = 'full', style}) => {
  //const [showContent, toggleShowContent] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const contentPos = position ? position : '';

  const toggleContent = () => {
    if (!animationClass) {
      //toggleShowContent(true);
      setAnimationClass('animate__fadeInUp show');
    } else {
      //toggleShowContent(false);
      // setAnimationClass('animate__fadeOutDown');
      setTimeout(() => {
        onClose();
      }, 320);
    }
  };

  useEffect(() => {
    toggleContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`custom-modal_mine ${size} ${contentPos}`} style={{ top: '36% !important' }}>
        {showCloseIcon === "yes" && 
      <span className="close as-link" onClick={() => toggleContent()}>
        <i className="fas fa-times" />
      </span>}

      <div
        className={`custom-modal-content animate__animated ${animationClass}`}
        style={{...style}}>
        {children}
      </div>
    </div>
  );
};

export default MineModel;
