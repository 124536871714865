import React, {useState, useEffect} from 'react';
import {selected_lang} from '../../Utils/env';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';
import {TagsInput} from 'react-tag-input-component';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {ShareYellowIcon} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';
import CustomSlidingButtons from '../CustomSlidingButtons';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import Actions from '../Actions/Actions';
import moment from 'moment';
import CreateAction from '../Snapshot/CreateAction';
import ActionDetail from '../Actions/ActionDetail';
import ReferOpportunity from '../Referral/ReferOpportunity';
import CustomModal from '../CustomModal';
import {FormattedText} from '../../Utils/Helpers';
import CropImage from '../Cropper/CropImage';
import {
  allowedImageTypes,
  allowedVideoTypes,
  FieldsNoRecordFound,
  formatDateTimeTodayPre,
} from '../../Utils/commonUtils';
import ShareIconSvg from '../../Svg/ShareIconSvg';
import Fields from './Fields';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import NorecordFound from '../NoRecord/NorecordFound';
import {RoiLogo} from '../../Constants/Images';
import {newSingleChatroom, chatroomInfo} from '../../Utils/commonUtils';
import SmartChatPage from '../Common/SmartChatPage';
import CustomTabs from '../CustomTabs';
import TransparentButton from '../TransparentButton/TransparentButton';

const ProfileNotes = props => {
  const [showLoader, setshowLoader] = useState(true);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState(
    props.type === 'follow-up' ? 'actions' : 'notes',
  );
  const [threeDots, setThreeDots] = useState(
    props.type === 'follow-up' ? true : false,
  );
  const [actionType, setActionType] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [relationid, setRelationid] = useState('');
  const [stageid, setStageid] = useState('');
  // const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);
  const [writtenMsg, setWrittenMsg] = useState('');
  const [notesData, setNotesData] = useState([]);
  const [itemOfActionData, setItemOfActionData] = useState([]);
  const [hitApi, setHitApi] = useState(false);
  const [referitem, setReferitem] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [checkFilterOption, setCheckFilterOption] = useState(['all']);
  const [filterOptionValue, setFilterOptionValue] = useState(['all']);
  const [futureDate, setFutureDate] = useState('');
  const [followUpType, setFollowUpType] = useState('');
  const [followUp, setFollowUp] = useState([]);
  const [showSendNotes, setShowSendNotes] = useState(false);

  const [ticketImg, setTicketImg] = useState('');
  const [ticketVideo, setTicketVideo] = useState('');
  const [mainTab, setMainTab] = useState(
    labels.sessions_type_general[selected_lang],
  );

  const [sliderImage, setSliderImage] = useState([]);
  const [file, setFile] = useState(null);
  const [showModel, setShowModel] = useState('');

  const [selectType, setSelectType] = useState('');
  const [slectedNote, setSlectedNote] = useState('');
  const [FieldListing, setFieldListing] = useState([]);
  const [editData, setEditData] = useState([]);

  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');

  const filterOption = [
    {id: 1, title: 'Me'},
    {id: 2, title: 'Other'},
    // {id: 3, title: 'Expire'},
  ];

  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  useEffect(() => {
    get_notes();
    show_user_field();
    // followUpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // get_notes();
    followUpData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);

  // const handleDelete = i => {
  //   const updatedTags = tags.filter((_, index) => index !== i);
  //   setTags(updatedTags);
  // };

  const bottom10={bottom:'10px'}

  const ActionCategories = [
    {title: 'Custom'},
    {title: 'Tomorrow'},
    {title: '7 days'},
    {title: '15 days'},
    {title: '21 days'},
    {title: '3 month'},
    // {title: 'Circle 4'},
  ];
  const RelationshipArray = [
    {title: 'Lead'},
    {title: 'Prospect'},
    {title: 'Customer'},
    {title: 'Partner'},
    {title: 'Colleague'},
    // {title: 'Circle 4'},
  ];
  const StageArray = [
    {title: 'Nurturing'},
    {title: 'Awaiting contract'},
    {title: 'Sent contract'},
    {title: 'Pending signature'},
    {title: 'Contract signed'},
    // {title: 'Circle 4'},
  ];
  // const tagArray = [
  //   {title: 'Multiple add'},
  //   {title: 'Awaiting contract,'},
  //   {title: 'Sent contract'},
  //   {title: 'Pending signature'},
  //   {title: 'Contract signed'},
  //   // {title: 'Circle 4'},
  // ];

  const create_notes = () => {
    let data = {
      type: 'other',
      associated_id: props.userData.id,
      description: writtenMsg,
      image: ticketImg,
      video: ticketVideo,
    };
    commonApi
      .create_notes(data)
      .then(res => {
        if (res.status) {
          setSearchQry('');
          setWrittenMsg('');
          successMessage(res.message);
          get_notes();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_notes = q => {
    let data = {
      type: 'other',
      type_id: props.userData.id,
      data_type: 'all',
      filter: filterOptionValue.toString(','),
      search_query: q,
    };
    commonApi
      .get_notes(data)
      .then(res => {
        if (res.status) {
          // successMessage(res.message)
          setNotesData(res.notes);
          setTimeout(() => {
            setshowLoader(false);
          }, 1000);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const show_user_field = q => {
    let data = {
      user_id: props.userData.id,
      search_query: q,
    };
    commonApi
      .show_user_field(data)
      .then(res => {
        if (res.status) {
          // successMessage(res.message)
          setFieldListing(res.user_fields);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const hanldeComponent = (item, type) => {
    setReferitem(item);
    setShowComponent(type);
  };

  const handleSearchQry = q => {
    if (tabType === 'fields') {
      show_user_field(q);
    } else {
      get_notes(q);
    }
  };
  const handleClickList = (id, value) => {
    // const {id, value} = e.target

    setCheckFilterOption([id]);
    setFilterOptionValue([
      value === 'Me' ? 'me' : value === 'Other' ? 'other' : 'all',
    ]);
  };

  const handleSelectAll = id => {
    setCheckFilterOption([id]);
    setFilterOptionValue([id]);
  };

  const allNotesLines =
    Object.entries(notesData).length > 0
      ? Object.entries(notesData).map(([key, item]) =>
          item.description.split('\n'),
        )
      : [];

  const NoteItem = ({index, item, allNotesLines}) => {
    const [showMore, setShowMore] = useState(false);
    const showMoreClass = showMore ? 'show-all' : '';

    return (
      <div>
        <FormattedText
          className={`profile-description message-adjust-profile-desc${showMoreClass}`}
          content={item.description}
        />

        {((item.description && item.description.length > 250) ||
          allNotesLines[index].length > 3) && (
          <>
            {' '}
            <span
              className="show-more-link"
              onClick={() => setShowMore(!showMore)}>
              {!showMore
                ? labels.read_more[selected_lang]
                : labels.read_less[selected_lang]}
            </span>
          </>
        )}
      </div>
    );
  };

  const handleGetData = e => {
    const {value} = e.target;
    const date = getFutureDateByCategory(value);

    if (date) {
      setFutureDate(date);
    }
    setFollowUpType('follow_up');

    setShowComponent('addAction');
  };
  const getFutureDateByCategory = categoryTitle => {
    if (categoryTitle === 'Custom') {
      return null; // Handle 'Custom' category or other cases
    }

    let unit, amount;

    if (categoryTitle === 'Tomorrow') {
      unit = 'day';
      amount = 1;
    } else if (categoryTitle === '7 days') {
      unit = 'day';
      amount = 7;
    } else if (categoryTitle === '15 days') {
      unit = 'day';
      amount = 15;
    } else if (categoryTitle === '21 days') {
      unit = 'day';
      amount = 21;
    } else if (categoryTitle === '3 month') {
      unit = 'month';
      amount = 3;
    }

    if (unit && amount) {
      return calculateFutureDate(unit, amount);
    }

    return null; // Handle invalid category titles
  };
  const calculateFutureDate = (unit, amount) => {
    const currentDate = new Date();

    if (unit === 'day') {
      currentDate.setDate(currentDate.getDate() + amount);
    } else if (unit === 'month') {
      currentDate.setMonth(currentDate.getMonth() + amount);
    }

    return currentDate;
  };

  const followUpData = () => {
    // setshowLoader(true);
    var payLoad = {
      user_id: props.userData ? props.userData.id : localStorage.getItem('id'),
      search_query: '',
    };

    commonApi
      .get_follow_ups(payLoad)
      .then(res => {
        if (res.status === 200) {
          setFollowUp(res.all_actions);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const delete_user_field = fieldId => {
    // setshowLoader(true);
    var payLoad = {
      id: fieldId,
    };

    commonApi
      .delete_user_field(payLoad)
      .then(res => {
        if (res.status === 200) {
          const newData = FieldListing.filter(fields => fields.id !== fieldId);
          setFieldListing(newData);
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const followUpView = (item, index) => {
  //   <div key={index}>
  //     <h3>{item.title}</h3>
  //     <h3>{item.due_date}</h3>
  //   </div>;
  // };

  console.log(props.userData, 'props.userData');

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);
      // setCropImg(true);
      setSelectType('img');
      setShowModel('crop_modal');

      e.target.value = null;

      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {

      //   uploadMedia(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };
  const changeTicketVideo = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no video selected, return
    if (!/^video\//.test(file.type)) {
      errorMessage('File is not a video.');
      return false;
    } else if (allowedVideoTypes.includes(file.type)) {
      // Convert the file to a base64 string
      setSelectType('video');
      setShowModel('');
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        uploadNotesImages(base64String); // Replace with the appropriate upload function for videos
      };
      reader.onerror = error => {
        errorMessage('Error reading video file.');
        console.log('Error: ', error);
      };

      // setSelectType('video');
      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };
  const deleteUpdatedImage = img => {
    var payload = {image: img};

    commonApi
      .delete_offering_image(payload)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const uploadNotesImages = base64String => {
    setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'media',
      base64String.replace(/^data:video\/[a-z]+;base64,/, ''),
    );
    bodyFormData.append('type', 'video');

    commonApi
      .upload_note_media(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          setTicketVideo(res.file_url);
        }
        setTimeout(() => {
          setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleDecession = (item, type) => {
    if (type === 'delete') {
      delete_user_field(item.id);
    } else {
      // alert('2');
      setEditData(item);
      setShowComponent('show_fields');
    }
  };

  const getOtherUserSetting = id => {
    let payLoad = {
      type: 'message',
      user_id: id,
    };
    commonApi
      .get_other_user_setting(payLoad)
      .then(response => {
        if (response.success) {
          checkChatRoom(id);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);
            setShowComponent('user_chat');
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([props.userData]);
            setChatRoomName('');
            setChatRoomId('');
            setShowComponent('user_chat');
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  console.log(props.userData, 'props.userData------->');
  return (
    <div className="page-content-wrapper profileNotesSheet profileNotesSheetholder">
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderHeader}
        showBlackHeader={true}
        // headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        closeIconRight={props.closeIconRight ? props.closeIconRight : false}
        marginTop={props.marginTop ? props.marginTop : false}>
        <div className="serach-filter-wrapper w-100 profile-notes-screen profile-notes-new-screen">
          <div className="fix-width-forms  fixed-width-inputs P_0  primary_panel_content">
            <div>
              {props.userData &&
                props.userData.id !== localStorage.getItem('id') &&
                props.type === 'follow-up' && (
                  <>
                    {/* <div className="invite-persons-heading">
                      <span className="followUp">
                        <i className="fa-solid fa-bell"></i>
                        <p className="p-reg m-0">Follow-up</p>
                      </span>
                    </div> */}
                    {tabType === 'actions' && (
                      <div className="category-btns-wrapper fixed_padding category-btns-wrapper-slider mt-0 mb-4">
                        <CustomSlidingButtons
                          items={ActionCategories}
                          activeButtons={actionType}
                          setActionType={setActionType}
                          stageid={stageid}
                          relationid={relationid}
                          onItemClick={e => {
                            handleGetData(e);
                          }}
                          showItems={3}
                        />
                      </div>
                    )}
                  </>
                )}
            </div>

            {props.type === 'follow-up' && (
              <div className=" custom_tabs d-flex align-items-center justify-content-center w-100 pb-1">
                <div className="create-ticket-tabs fixed_padding m-0">
                  <div>
                    <CustomTabs
                      darkmode
                      active={mainTab}
                      onSelect={item => {
                        setMainTab(item);
                      }}
                      tabs={[
                        labels.sessions_type_general[selected_lang],
                        labels.follow_up[selected_lang],
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* {mainTab === labels.sessions_type_general[selected_lang] && props.type === 'follow-up' &&(   <div className=' payment-search-wrapper messages-users-wrapper'>
        <div className="search-form d-flex search-tabs-icons">
            <Form.Group className="search-input roi-form-input">
              <Form.Control
                type="text"
                placeholder="Search task"
                value={searchQry}
                onChange={e => {
                  setSearchQry(e.target.value);
                  // get_expenses_for_invoices(e.target.value);
                }}
              />
            </Form.Group>
            <span
              className={`ml-0 mt-0 ${
                showComponent === 'createExpense' ? 'icon active' : 'icon'
              }`}>
              <i
                className="icon-plus"
                onClick={() => {
                  setShowComponent('createExpense');
                }}
              />
            </span>
          </div>

        </div>
     )} */}

            {/* <div className="primary_panel_content"> */}

            {tabType === 'actions' &&
              props.type === 'follow-up' &&
              mainTab === labels.follow_up[selected_lang] && (
                <div className="refferal-list-wrapper mt-4">
                  <Scrollbars>
                    <div className="fixed_padding">
                      {tabType === 'actions' && followUp.length > 0 ? (
                        followUp.map((item, index) => (
                          <div
                            className="action-listing followed-up as-link"
                            key={index}
                            onClick={() => {
                              setItemOfActionData(item);
                              setShowComponent('actionDetails');
                            }}>
                            <p className="p-bold mb-2 single-line-text">
                              {item.title}
                            </p>
                            <div className="d-flex align-items-center justify-content-between followed-date">
                              <p className="p-reg m-0">
                                {moment(
                                  item.due_date,
                                  'YYYY-MM-DD HH:mm',
                                ).format('DD/MM/YY hh:mmA')}
                              </p>
                              <span
                                className="form-icon send-btn"
                                onClick={e => {
                                  e.stopPropagation();
                                  getOtherUserSetting(props.userData.id);
                                }}>
                                <i className="icon-plane"></i>
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-center mt-5">
                          <div className="mb-2">No Record Found</div>
                          <div className="btn-wrapper mt-3">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                // props.handleCommand(
                                //   {followUpType: 'follow_up', date: ''},
                                //   'create_followUp',
                                // );

                                setFollowUpType('follow_up');

                                setShowComponent('addAction');
                              }}>
                              Create Follow Up
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Scrollbars>
                </div>
              )}

            {tabType === 'actions' &&
              props.type === 'follow-up' &&
              mainTab === labels.sessions_type_general[selected_lang] && (
                // <Scrollbars>
                <div className="fixed_padding">
                  <Actions
                    threeDots={threeDots}
                    setShowComponent={setShowComponent}
                    showComponent={showComponent}
                    setDataOfAction={setItemOfActionData}
                    hitApi={hitApi}
                    type={'profile'}
                    component={'follow-up'}
                    user_id={
                      props.userData
                        ? props.userData.id
                        : localStorage.getItem('id')
                    }
                  />
                </div>
                // </Scrollbars>
              )}
            {/* </div> */}

            {props.type !== 'follow-up' && (
              <div className="messages-header" 
              style={{
                paddingLeft:'22px'
              }}>
                {/* <div className="event-tabs dark-tabs"> */}
                <div className="event-tabs tabs-style-2 mb-0">
                  {' '}
                  {/* Merging */}
                  <ul className="LabelTabs">
                    <li>
                      <Button
                        className={tabType === 'notes' ? 'active' : ''}
                        onClick={() => {
                          setTabType('notes');
                        }}>
                        {labels.NOTES[selected_lang]}
                      </Button>
                    </li>

                    <li className="ml-20 d-flex align-items-center">
                      <Button
                        className={tabType === 'actions' ? 'active' : ''}
                        onClick={() => {
                          setTabType('actions');
                        }}>
                        {labels.ACTIONS[selected_lang]}
                      </Button>
                    </li>
                    <li>
                      <Button
                        className={tabType === 'fields' ? 'active' : ''}
                        onClick={() => {
                          setTabType('fields');
                        }}>
                        {labels.FIELDS[selected_lang]}
                      </Button>
                    </li>
                  </ul>
                </div>

                {/* <span className="add-icon">
                          <i
                            className="icon-plus"
                            onClick={() => setSecondComponent("group-component")}
                          />
                        </span>
                        <span className="add-icon">
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </span> */}

                <div className="search-tabs-icons">
                  {(tabType === 'actions' || tabType === 'fields') && (
                    <span
                      className={
                        showComponent === 'addAction' ? 'icon active' : 'icon'
                      }>
                      <i
                        className="icon-plus"
                        onClick={() => {
                          if (tabType === 'fields') {
                            setShowComponent('show_fields');
                          } else {
                            setShowComponent('addAction');
                          }
                        }}
                      />
                    </span>
                  )}

                  <span
                    onClick={() => {
                      setThreeDots(!threeDots);
                    }}
                    className={threeDots ? 'icon active' : 'icon'}>
                    <i className="fas fa-ellipsis-vertical" />
                  </span>
                </div>
              </div>
            )}

            {threeDots && tabType !== 'actions' && (
              <div className="person-search-wrapper mb-0 messages-users-wrapper pb-3">
                <div className="search-form d-flex">
                  <Form.Group className="search-input">
                    <Form.Control
                      type="text"
                      placeholder={
                        tabType === 'notes'
                          ? 'Search for notes'
                          : tabType === 'fields'
                          ? 'Search for fields'
                          : 'Search for actions'
                      }
                      value={searchQry}
                      onChange={e => {
                        setSearchQry(e.target.value);
                        handleSearchQry(e.target.value);
                      }}
                    />
                  </Form.Group>
                  {tabType !== 'fields' && (
                    <Button>
                      <i
                        className="icon-filter-icon"
                        onClick={() => setSearchFilterModal(true)}
                      />
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          {props.type !== 'follow-up' && (
            <>
              {showLoader && <LoaderSpinner />}

              {tabType === 'notes' && (
                <Scrollbars>
                  <div className="fix-width-forms fixed_width_page fixed_padding">
                    <div className="mt-4">
                      <div className="mt-4 profile-notes-listing-container">
                        {/* <div className="message-day-saparator">
                    Today February 2, 2024
                  </div> */}
                        {notesData && Object.entries(notesData).length ? (
                          notesData.map((item, index) => {
                            return (
                              <div className="card-container messages-card-note-container">
                                <div
                                  className="padd-with-borderContaier as-link message-adjust-img-container"
                                  onClick={() => {
                                    if (item.image || item.video) {
                                      setSlectedNote(item);
                                      setShowModel('previewImgVideo');
                                    }
                                  }}>
                                  {item.image && (
                                    <img src={item.image} alt="" />
                                  )}
                                  {item.video && (
                                    <video src={item.video} alt="" />
                                  )}
                                  <div className="d-flex align-items-center page-snapshots mt-2">
                                    <p className="p-reg m-0 chatMsg">
                                      {/* {item.description} */}
                                      <NoteItem
                                        index={index}
                                        item={item}
                                        allNotesLines={allNotesLines}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
                                  <span className="msg-date">
                                    {formatDateTimeTodayPre(item.updated_at)}
                                  </span>
                                  <div
                                    className="d-flex align-items-center"
                                    onClick={() => {
                                      hanldeComponent(item, 'refer_notes');
                                    }}>
                                    {/* <img
                                className="ScanQr as-link"
                                src={ShareYellowIcon}
                                alt="scanQr"
                              /> */}
                                    <span className="as-link">
                                      <ShareIconSvg fill={'#52bd41'} />
                                    </span>

                                    {/* <img
                                className="sharableYellow as-link"
                                src={ShareablesYellowIcon}
                                alt="shareable"
                              /> */}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="no-results-wrapper">
                            <div className="img-bg-wrapper">
                              <img
                                src={require('../../Assets/images/norecord/no-notes.png')}
                                alt=""
                              />
                            </div>

                            <div className="no-results-content">
                              <h2>No Shared Notes</h2>
                              <p>
                                Share notes with your network to stay on the
                                same page
                              </p>
                              {notesData.length === 0 && !showSendNotes && (
                                <div className="btn-wrapper mt-3">
                                  <Button
                                    className="btn btn-dark"
                                    onClick={() => {
                                      setShowSendNotes(true);
                                    }}>
                                    Add Note
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Scrollbars>
              )}

              {tabType === 'actions' && (
                <Scrollbars>
                  <div className="fix-width-forms fixed_width_page fixed_padding">
                    <div className="mt-4">
                      <div className="mt-4">
                        <Actions
                          threeDots={threeDots}
                          setShowComponent={setShowComponent}
                          showComponent={showComponent}
                          setDataOfAction={setItemOfActionData}
                          hitApi={hitApi}
                          type={'profile'}
                          user_id={
                            props.userData
                              ? props.userData.id
                              : localStorage.getItem('id')
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Scrollbars>
              )}

              {tabType === 'fields' && (
                <>
                  <Scrollbars>
                    <div className="fix-width-forms fixed_width_page fixed_padding">
                      <div className="membership-profile-notes-block">
                        {FieldListing.length > 0 ? (
                          FieldListing.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="card"
                                style={{marginBottom: '7px'}}>
                                <div className="session-defination-list-holder">
                                  {item.email && (
                                    <div className="input-label">
                                      <strong>Email:</strong>
                                      <span>{item.email}</span>
                                    </div>
                                  )}
                                  {item.phone && (
                                    <div className="input-label userCount">
                                      <strong>Phone number:</strong>
                                      <span>{item.phone}</span>
                                    </div>
                                  )}
                                  {item.relations && (
                                    <div className="input-label userCount">
                                      <strong>Relationship:</strong>
                                      <span>{item.relations}</span>
                                    </div>
                                  )}
                                  {item.stage && (
                                    <div className="input-label userCount">
                                      <strong>Stage:</strong>
                                      <span>{item.stage}</span>
                                    </div>
                                  )}
                                  {item.tag?.length > 0 && (
                                    <div className="input-label userCount">
                                      <strong>Tags:</strong>
                                      <span>{item.tag.toString(', ')}</span>
                                    </div>
                                  )}
                                </div>
                                <div className="session-address-wrapper">
                                  <span
                                    className="as-link"
                                    onClick={() => {
                                      handleDecession(item, 'edit');
                                      // editMembershipLevel(item);
                                    }}>
                                    <EditPencilSvg fill={'#52bd41'} />
                                  </span>
                                  <span
                                    className="poc_delete as-link"
                                    onClick={() => {
                                      handleDecession(item, 'delete');
                                      // deleteMembershipLevel(item);
                                    }}>
                                    <i className="fa-solid fa-trash-can" />
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <NorecordFound
                            noRecordData={FieldsNoRecordFound}
                            buttonPermesion={true}
                            ButtonAction={() => {
                              setShowComponent('show_fields');
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Scrollbars>
                </>
              )}
            </>
          )}

          {props.userData &&
            props.userData.id &&
            tabType === 'notes' &&
            (notesData.length > 0 || showSendNotes) && (
              <div className="fixed_padding">
                <div className="message-form fix-width-forms  fixed_width_page  message-profile-notes-form">
                  {ticketImg && selectType === 'img' ? (
                    <span className="msg-img as-link profile-notes-cross-icon-holder">
                      <img
                        src={ticketImg}
                        alt=""
                        onClick={() => {
                          setShowModel('imgPrewiew');
                        }}
                      />
                      <i
                        className="fas fa-times"
                        onClick={() => {
                          deleteUpdatedImage(ticketImg);
                          setTicketImg(null);
                        }}
                      />
                    </span>
                  ) : selectType === 'video' && ticketVideo ? (
                    <span className="msg-img as-link">
                      <video
                        style={{width: '50px'}}
                        src={ticketVideo}
                        onClick={() => {
                          setShowModel('videoPrewiew');
                        }}
                      />
                      <i
                        className="fas fa-times"
                        onClick={() => {
                          deleteUpdatedImage(ticketVideo);
                          setTicketVideo(null);
                        }}
                      />
                    </span>
                  ) : (
                    <span className="as-link profile-notes-ellipse-iconn">
                      <i
                        className="fas fa-ellipsis-vertical"
                        onClick={() => {
                          setShowModel('upload');
                        }}
                      />
                    </span>
                  )}

                  <Form.Group className="form-input-text">
                    <Form.Control
                      name=""
                      id="text_field"
                      as="textarea"
                      rows={3.5}
                      value={writtenMsg}
                      placeholder="Type what you are thinking"
                      onKeyDown={e => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          if (
                            writtenMsg.trim() === '' &&
                            !ticketImg &&
                            !ticketVideo
                          ) {
                            errorMessage("Can't send empty messsage.");
                          } else {
                            create_notes();
                          }
                          // create_notes();
                        }
                      }}
                      onChange={e => {
                        setWrittenMsg(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <span
                    className="form-icon send-btn"
                    onClick={() => {
                      if (
                        writtenMsg.trim() === '' &&
                        !ticketImg &&
                        !ticketVideo
                      ) {
                        errorMessage("Can't send empty messsage.");
                      } else {
                        create_notes();
                      }
                      // create_notes();
                    }}>
                    <i className="icon-play-icon" />
                  </span>
                </div>
              </div>
            )}
          {/* {tabType !== 'fields' && (
            <div className="message-form fix-width-forms">
              <span>
                <i className="fas fa-ellipsis-vertical" />
              </span>

              <Form.Group className="form-input">
                <Form.Control
                  name=""
                  id="text_field"
                  as="textarea"
                  rows={6}
                  placeholder="Type what you are thinking"
                  // onKeyDown={e => {
                  //   if (e.key === 'Enter') {
                  //     saveMessage();
                  //     setTicketImg(null);
                  //     setSelectedImage(null);
                  //   }
                  // }}
                />
              </Form.Group>
              <span className="form-icon send-btn">
                <i className="icon-play-icon" />
              </span>
            </div>
          )} */}
        </div>
      </SlidingPanel>
      {showComponent === 'show_fields' && (
        <SlidingPanel
          hidePanel={() => {
            setShowComponent('');
            setEditData([]);
          }}
          renderData={renderHeader}
          showBlackHeader={true}
          // headerPadding={'24px 20px'}
          lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
          firstDivStyle={{
            padding: 0,
            background:
              'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
          }}
          closeIconRight={props.closeIconRight ? props.closeIconRight : false}>
          <Fields
            userId={props.userData.id}
            editData={editData}
            onClose={() => {
              setShowComponent('');
              setEditData([]);
            }}
            FieldListing={FieldListing}
            setFieldListing={setFieldListing}
          />
        </SlidingPanel>
      )}
      {showComponent === 'addAction' && (
        <CreateAction
          userData={props.userData}
          onClose={() => {
            setShowComponent('');
          }}
          setHitApi={setHitApi}
          followUpType={followUpType}
          futureDate={futureDate}
        />
      )}

      {showComponent === 'actionDetails' && (
        <ActionDetail
          dataOfAction={itemOfActionData}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'refer_notes' && (
        <ReferOpportunity
          onClose={() => {
            setShowComponent('');
          }}
          userData={referitem}
          type={'share_notes_with_others'}
        />
      )}
      {searchFilter && (
        <CustomModal
          className="shareables-workspace-modal"
          size="small"
          onClose={() => setSearchFilterModal(false)}
          heading={'Filter Notes'}
          iconStyle={{top: '25px'}}
          >
          <div className="filter-wrapper">
            <div className="filter-category-list profile-filter-category-list">
              <ul>
                <li
                  className={
                    checkFilterOption.includes('all') ||
                    filterOptionValue.includes('all')
                      ? 'active'
                      : ''
                  }>
                  <Form.Label>
                    All
                    <input
                      type="checkbox"
                      id="All"
                      value="All"
                      onChange={() => {
                        handleSelectAll('all');
                      }}
                      checked={
                        checkFilterOption.includes('all') ||
                        filterOptionValue.includes('all')
                      }
                    />
                    <span />
                  </Form.Label>
                </li>

                {filterOption.map((item, index) => (
                  <li
                    key={`sessionC-${index}`}
                    className={
                      checkFilterOption.includes(item.id) ||
                      filterOptionValue.includes(item.title)
                        ? 'active'
                        : ''
                    }>
                    <Form.Label>
                      {item.title}
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.title}
                        onChange={e => {
                          handleClickList(item.id, e.target.value);
                        }}
                        checked={
                          checkFilterOption.includes(item.id) ||
                          filterOptionValue.includes(item.title)
                        }
                      />
                      <span />
                    </Form.Label>
                  </li>
                ))}
              </ul>
              <TransparentButton
              onClick_button={() => {
                get_notes();
                  setSearchFilterModal(false);
              }}
              buttonName={'Select'}
              disable={false}
              styleForButton={
                bottom10
              }
              className={'filter-btn-wrapper pt-1 pb-1 create-session-btn fixed-width-inputs  fix-width-forms mx-0 bg_transparent'}
            />
              {/* <Button
                type="submit"
                className=""
                onClick={() => {
                 
                }}>
               
              </Button> */}
            </div>
          </div>
        </CustomModal>
      )}

      {showModel === 'crop_modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="notes"
            ratio={429 / 187}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            setImgUrl={setTicketImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setshowLoader}
            // actionId={dataOfAction.id}
          />
        </CustomModal>
      )}
      {showModel === 'upload' && (
        <CustomModal position="bottom" onClose={() => setShowModel('')}>
          <div className="camera-options-list w-100 chatActionSheet">
            <ul>
              <li className=" image-list image_input">
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={e => changeTicketImg(e)}
                />
                <i className="fas fa-image as-link" />{' '}
                <span>{labels.ADD_IMAGE[selected_lang]}</span>
              </li>
              <li className="image-list image_input">
                <input
                  type="file"
                  accept="video/mp4, video/webm, video/ogg"
                  onChange={e => changeTicketVideo(e)}
                />
                <i className="fa-solid fa-video as-link " />{' '}
                <span> {labels.ADD_VIDEO[selected_lang]}</span>
              </li>
              {/* <li className="as-link video-list">
                <i class="fa-solid fa-video" />{' '}
                {labels.ADD_VIDEO[selected_lang]}
              </li> */}
            </ul>
          </div>
        </CustomModal>
      )}
      {showModel === 'imgPrewiew' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModel('');
          }}>
          <div className="chat-img-preview">
            <img src={ticketImg} alt="" />
          </div>
        </CustomModal>
      )}
      {showModel === 'videoPrewiew' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModel('');
          }}>
          <div className="chat-img-preview">
            <video src={ticketVideo} controls autoPlay loop alt="" />
          </div>
        </CustomModal>
      )}

      {showModel === 'previewImgVideo' && slectedNote && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModel('');
          }}>
          <div className="chat-img-preview">
            {slectedNote.image && <img src={slectedNote.image} alt="" />}
            {slectedNote.video && (
              <video src={slectedNote.video} controls autoPlay loop alt="" />
            )}
          </div>
        </CustomModal>
      )}

      {showComponent === 'user_chat' && (
        <SmartChatPage
          allmsg={true}
          type={chatType}
          item={[]}
          chatroomInfo={
            chatDetails.length > 0
              ? chatroomInfo(chatDetails)
              : newSingleChatroom(props.userData)
          }
          oppoDetails={chatDetails}
          chatDetails={chatDetails}
          allChatUsers={allChatUsers}
          chatRoomName={chatRoomName}
          chatRoomId={chatRoomId}
          onClose={() => {
            setShowComponent('');
          }}
          userData={props.userData}
        />
      )}
    </div>
  );
};

export default ProfileNotes;
