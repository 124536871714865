import React, {useState, useEffect} from 'react';
import commonApi from '../../Services/CommonService';
import {getItem} from '../../Utils/LocalStorage';
import {Button, Form} from 'react-bootstrap';
import {errorMessage} from '../../Utils/ToastMessages';
import { DEFAULT_IMAGE } from '../../Utils/env';

const UpdateProfile = () => {
  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
  });
  const [profileImg, setProfileImg] = useState('');

  const [validated, setValidated] = useState(false);

  const handleCustomerValidation = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      event.preventDefault();
      event.stopPropagation();
      UpdateUserProfile();
      if(customerData.profile_img){
        uploadProfilePicture(customerData.profile_img)
      }
      
    }
  };


  const uploadProfilePicture = img => {
   
    var bodyFormData = new FormData();
    bodyFormData.append(
      'picture',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_profile_picture(bodyFormData)
      .then(res => {
        if (res.status === 200) {
            if (res.status === 200) {
                localStorage.setItem(
                  'profilePic',
                  res.user.profile_img
                    ? res.user.profile_img
                    : DEFAULT_IMAGE,
                );

            }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const UpdateUserProfile = () => {
    const payload = {
      bio: '',
      firstName: customerData.firstName,
      instagram: '',
      lastName: customerData.lastName,
      linkedin: '',
      twitter: '',
      web: '',
      youtube: '',
    };

    commonApi
      .update_profile(payload)
      .then(response => {
        localStorage.setItem('firstName', response.user.first_name);

        localStorage.setItem('userName', response.user.unique_user_name);
        localStorage.setItem('lastName', response.user.last_name);
        updateInviationStatus();
        // setTimeout(() => {
        //   window.location.href = '/';
        // }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateInviationStatus = () => {
    var payload = {
      number: getItem('loginNum'),
    };

    commonApi
      .update_invitaion_status(payload)
      .then(response => {
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateImage = e => {
    // setProfileImg();
    const imgData = e.target.files[0];
    if (!/^image\//.test(imgData.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else {
      const objectUrl = URL.createObjectURL(imgData);

      let reader = new FileReader();
      reader.readAsDataURL(imgData);
      reader.onload = e => {
        const tempData = {
          ...customerData,
          profile_img: e.target.result,
        };
        setCustomerData(tempData);
      };
      //
      setProfileImg(objectUrl);
    }
  };

  return (
    <div
      className="user-detail"
      style={{
        textAlign: 'center',
       height: '100vh',
       paddingTop:'10vh'
      }}>
      <div className="input-container1 text-left">
        <Form
          noValidate
          validated={validated}
          onSubmit={handleCustomerValidation}>
          <div className="onboarding-profile create-profile">
            <div className="profile-img">
              <input
                type="file"
                accept="image/*"
                name="profile_img"
                onChange={e => updateImage(e)}
              />
              {profileImg && <img src={profileImg} alt="" />}
              {!profileImg && <i className="fas fa-camera" />}
            </div>
            <h3>What’s your name?</h3>
            <p>Yes, we use real names.</p>

            <Form.Group className="fieldset mt-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="fname"
                pattern="^\S.*$"
                value={customerData.firstName}
                onChange={e =>
                  setCustomerData({
                    ...customerData,
                    firstName: e.target.value,
                  })
                }
                placeholder="First Name"
                required
              />
              <Form.Control.Feedback type="invalid">
                First Name is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="fieldset">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                pattern="^\S.*$"
                name="lname"
                value={customerData.lastName}
                onChange={e =>
                  setCustomerData({...customerData, lastName: e.target.value})
                }
                placeholder="Last Name"
                required
              />
              <Form.Control.Feedback type="invalid">
                Last Name is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="new-btn btn-green" type="submit">
              Next
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateProfile;
