import React, {useState, } from 'react';
import {Button,  Row} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {Pannels} from '../../Components';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  bagIcon,
  heartIcon,
  timeIcon,
} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';

const Schedule = props => {
  const [currentComponent, setCurrentComponent] = useState('news');
  const [showProperComponet, setProperComponent] = useState('');

  const [workSpaceItem, setWorkSpaceItem] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);



  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setProperComponent}
          showComponent={showProperComponet}
        />
        <div className="page-content-wrapper">
          {/* {showLoader && <LoaderSpinner />} */}
          <div className="sessions-list-wrapper">
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="search-tabs-wrapper">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="event-tabs tabs-style-2 mb-0">
                        <ul>
                          <li>
                            <Button
                              className={
                                currentComponent === 'news' ? 'active' : ''
                              }>
                              Upcoming
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={
                                currentComponent === '1122' ? 'active' : ''
                              }>
                              Past
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="search-tabs-icons">
                        <span
                          className={
                            // secondComponent === 'group-component'
                            //   ? 'icon active'
                            //   :
                            'icon'
                          }>
                          <i
                            className="icon-plus"
                            // onClick={() =>
                            //   setSecondComponent('group-component')
                            // }
                          />
                        </span>

                        <span
                          //   onClick={() => {
                          //     setThreeDots(!threeDots);
                          //   }}
                          className={
                            // threeDots
                            //   ? 'icon active threeDots'
                            //   :
                            'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="refferal-list-wrapper">
                    <div className="payment-card qa-list-item">
                      <div class="message-user-item no-link">
                        <div class="user-img as-link">
                          <img
                            src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                            alt=""
                          />
                        </div>
                        <div class="user-content as-link">
                          <h2 class="single-line-text">Samantha Ellington</h2>
                          <p class="single-line-text">McLean, VA</p>
                          <div class="message-date-info">
                            <span class="message-date">
                              <time datetime="1699009603000">
                                Prospect (5 services)
                              </time>
                            </span>
                          </div>
                        </div>
                        <div class="message-other-users"></div>
                      </div>
                      <div className="appointment_icon">
                        <Row>
                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={WhitePenIcon}
                              alt=""
                            />
                          </div>

                          <div className="appointment_icon2">
                            {' '}
                            <img
                              className="appointment_icon1_image_time"
                              src={timeIcon}
                              alt=""
                            />
                            <span>5D</span>
                          </div>
                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={FireIcon}
                              alt=""
                            />
                          </div>
                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={MachineIcon}
                              alt=""
                            />
                          </div>
                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={ArrowIcon}
                              alt=""
                            />
                          </div>

                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={heartIcon}
                              alt=""
                            />
                          </div>

                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={bagIcon}
                              alt=""
                            />
                          </div>
                        </Row>
                      </div>
                      <div className="">
                        <div className="contact-card-subCard">
                          <div className="contacts-item-icon no-link">
                            <div className="contacts-icon as-link">
                              <i class="fa-solid fa-phone"></i>
                            </div>

                            <div className="contacts-icon as-link">
                              <i className="fa-solid icon-plane"></i>
                            </div>
                          </div>

                          <div className="buy-btn-wrapper">
                            <span
                              //  variant="primary"
                              className="btn-pending-signature as-link mt-3-bold"
                              onClick={() => {}}>
                              Pending Signature
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />
            {showProperComponet === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setProperComponent('');
                }}
                setCurrentComponent={setCurrentComponent}
              />
              
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;
