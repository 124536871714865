import {useState} from 'react';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';

const ShowMore = ({limit, children}) => {
  const text = children;

  const [isReadMoreShown, setReadMoreShown] = useState(false);
  const toggleBtn = () => {
    setReadMoreShown(prevState => !prevState);
  };


  return (
    <div className="read-more-read-less">
      {isReadMoreShown ? text : text.substr(0, limit)}
      {text.substr(limit) && (
        <button className="btn" onClick={toggleBtn}>
          {' '}
          {isReadMoreShown
            ? labels.read_less[selected_lang]
            : labels.read_more[selected_lang]}
        </button>
      )}
    </div>
  );
};
export default ShowMore;
