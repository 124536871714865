// import React from 'react';
import React, {useState} from 'react';
import {getTimeFormatForChat} from '../../Services/AppServices';
import {
  arrayColumn,
  filteringUsers,
  fixName,
  formatString,
} from '../../Utils/commonUtils';
import {DEFAULT_IMAGE, S3_EMOJI_DEV} from '../../Utils/env';
import OtherUserImages from './OtherUserImages';
import ShreAblesEvent from '../ShareAbles/ShreAblesEvent';
import ShareAblesSessions from '../ShareAbles/ShareAblesSessions';
import ShareAableOpportunity from '../ShareAbles/ShareAableOpportunity';
import Moment from 'react-moment';

const ReplyBox = ({
  type,
  msg,
  i,
  found,
  setShowUserProfile,
  handleChatBoxSignal,
  setReplyIndex,
  toggleReplyOptions,
  ShowSelectedComponent,
  handleOpenChatRoom,
  setShowReplyEmojiModal,
}) => {
  const [chatData, setChatData] = useState([]);
  // const componentDecesion = () => {};
  return (
    <div key={`msg-${i}`} className="message-user-item">
      <div
        className={`user-img ${type !== 'liveChat' ? 'as-link' : ''}`}
        onClick={() => {
          if (type !== 'liveChat') {
            setShowUserProfile(found.id);
          }
        }}>
        <img
          src={found?.profile_img ? found.profile_img : DEFAULT_IMAGE}
          alt=""
        />
      </div>
      <div className="user-content">
        <div className="d-flex align-items-center justify-content-between">
          {/* <h2>{found.first_name + ' ' + found.last_name}</h2> */}
          <div className="reply_date_info d-flex gap-3">
            <h2>{fixName(found.first_name, found.last_name)}</h2>
            <span className="message-date">
              {getTimeFormatForChat(msg.send_date)}
            </span>
          </div>

          <div className="search-tabs-icons">
            {/* <span
              className="icon as-link"
              onClick={() => {
                // handleSelectReply('emoji', allChat, '', '');
                // handleChatBoxSignal('add-reply-emoji', allChat, '', '');
                toggleReplyOptions(true);
                setReplyIndex(i);
              }}>
              {' '}
              <i className="fas fa-ellipsis-vertical"></i>
            </span> */}
            {msg.isCorrect && (
              <span className="icon">
                {' '}
                <i className="fa-solid fa-circle-check"></i>
              </span>
            )}
          </div>
        </div>

        <p>{msg.type === 'poll' ? msg.message.question : msg.message}</p>
        <div className="emoji-icons-wrapper">
          <div className="emoji-icons d-flex align-items-center">
            {msg.type === 'emoji' && (
              <div className="emoji-icons-item">
                <img
                  src={
                    S3_EMOJI_DEV +
                    msg.emoji.skinTone +
                    '/' +
                    msg.emoji.emojiName +
                    '.svg'
                  }
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
        {msg.type === 'accomplishment' && (
          <div
            className="container-fromChat-Shareables shareables-snapshots"
            onClick={() => {
              ShowSelectedComponent(msg.accomplishment_data, msg.type);
            }}>
            <div className="as-link">
              <div className=" d-flex align-items-center mb-3">
                <div className="snapshotsImage as-link">
                  <img
                    src={
                      msg.accomplishment_data &&
                      msg.accomplishment_data.images[0]
                    }
                    alt=""
                  />
                </div>
                <div
                  className="snapshots-content as-link"
                  onClick={() => {
                    // setSnapShotId(item);
                    // setShowComponent('SnapShotDetail');
                  }}>
                  <h2 className="single-line-text">
                    {msg.accomplishment_data && msg.accomplishment_data.title}
                  </h2>
                  {msg.category_name && (
                    <p className="p-reg single-line-text category-name">
                      {msg.accomplishment_data &&
                        msg.accomplishment_data.category_name}
                    </p>
                  )}
                </div>
              </div>
              {msg.accomplishment_data && (
                <p className="p-reg truncate_2_lines">
                  {msg.accomplishment_data.description}
                </p>
              )}
            </div>
            {/* {ElementDesign('snapShot', item)} */}
          </div>
        )}
        {msg.type === 'network' && msg.network_data && (
          <div
            className="as-link card-container"
            onClick={() => {
              ShowSelectedComponent(msg.network_data, msg.type);
            }}>
            <div className="padd-with-borderContaier">
              <div className="profile-img-wrapper align-items-center justify-content-start">
                <div className="profile-img">
                  <img
                    src={
                      msg.network_data.profile_img
                        ? msg.network_data.profile_img
                        : DEFAULT_IMAGE
                    }
                    alt=""
                  />
                </div>

                <div className="profile-name flex-0">
                  <div className="profile-name-headings">
                    <div className="">
                      <h2 className="m-0 mb-1 p-bold">
                        {msg.network_data && msg.network_data.first_name}
                        {msg.network_data && msg.network_data.last_name}
                      </h2>
                      {msg.network_data.on_boarding_areas &&
                        msg.network_data.on_boarding_areas.length > 0 && (
                          <h3 className="p-reg m-0">
                            {msg.network_data.on_boarding_areas
                              .map(area => area.title)
                              .join(', ')}
                          </h3>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {ElementDesign('profile', item)} */}
          </div>
        )}
        {msg.type === 'opportunity' && msg.opportunity_data && (
          <ShareAableOpportunity
            // key={`opportunity-item-${index}`}
            setShowButton={''}
            onShare={() => {
              // setProperComponent('refer-opportunity');
            }}
            handleItem={ShowSelectedComponent}
            setChatData={setChatData}
            chatData={chatData}
            //  handleItem={''}
            item={msg.opportunity_data}
            //  ElementDesign={''}
            typeMesages={true}
          />
        )}
        {msg.type === 'session' && msg.session_data && (
          <>
            <ShareAblesSessions
              item={msg.session_data}
              heart={''}
              componentDecesion={ShowSelectedComponent}
              //  ElementDesign={}
              typeMesages={true}
            />
            {/* <p>Daniyal</p> */}
          </>
        )}
        {msg.type === 'event' && msg.event_data && (
          <ShreAblesEvent
            //  key={`event-item-${index}`}
            item={msg.event_data}
            //  index={index}
            // eventDetails={eventDetails}
            componentDecesion={ShowSelectedComponent}
            //  ElementDesign={ElementDesign}
            typeMesages={true}
          />
        )}
        {msg.type === 'group_chat' && msg.group_chat_data && (
          <div
            className="messages-users-list card-container"
            onClick={() => {
              handleOpenChatRoom(msg.group_chat_data);
            }}>
            <div
              className={
                'message-user-item no-link padd-with-borderContaier m-0'
              }>
              <div
                className="user-img as-link"
                // onClick={() => {
                //   amplitudeEvent('OPEN_CHAT_ROOM');
                //   handleOpenChatRoom(item);
                // }}
              >
                <img
                  src={
                    msg.group_chat_data.group_image !== ''
                      ? msg.group_chat_data.group_image
                      : msg.group_chat_data[
                          parseInt(msg.group_chat_data.creator_id) ===
                          parseInt(localStorage.getItem('user'))
                            ? 'user_detail'
                            : msg.group_chat_data.hasOwnProperty(
                                'creator_details',
                              )
                            ? 'creator_details'
                            : 'creator_detail'
                        ]['profile_img']
                      ? msg.group_chat_data[
                          parseInt(msg.group_chat_data.creator_id) ===
                          parseInt(localStorage.getItem('user'))
                            ? 'user_detail'
                            : msg.group_chat_data.hasOwnProperty(
                                'creator_details',
                              )
                            ? 'creator_details'
                            : 'creator_detail'
                        ]['profile_img']
                      : DEFAULT_IMAGE
                  }
                  alt=""
                />
                {msg.group_chat_data.chat_type === 'question' && (
                  <span>
                    <i className="fa-solid fa-q"></i>
                  </span>
                )}
                {msg.group_chat_data.chat_type === 'opportunity' && (
                  <span className="icon icon-posts"></span>
                )}
                {msg.group_chat_data.chat_type === 'event' && (
                  <span className="icon-tickets"></span>
                )}
                {msg.group_chat_data.chat_type === 'session' && (
                  <span className="icon-video-camera-2"></span>
                )}
                {/* <span className="fa-solid fa-shop"></span> */}
              </div>
              <div
                className="user-content as-link"
                onClick={() => {
                  // handleOpenChatRoom(item);
                }}>
                <h2 className="single-line-text">
                  {/* {tabType === 'subInvites' &&
                                             !item.hasOwnProperty('read') */}
                  {msg.group_chat_data.group_name !== ''
                    ? msg.group_chat_data.group_name
                    : fixName(
                        msg.group_chat_data[
                          parseInt(msg.group_chat_data.creator_id) ===
                          parseInt(localStorage.getItem('user'))
                            ? 'user_detail'
                            : msg.group_chat_data.hasOwnProperty(
                                'creator_details',
                              )
                            ? 'creator_details'
                            : 'creator_detail'
                        ]['first_name'],
                        msg.group_chat_data[
                          parseInt(msg.group_chat_data.creator_id) ===
                          parseInt(localStorage.getItem('user'))
                            ? 'user_detail'
                            : msg.group_chat_data.hasOwnProperty(
                                'creator_details',
                              )
                            ? 'creator_details'
                            : 'creator_detail'
                        ]['last_name'],
                      )}
                </h2>

                {formatString(msg.group_chat_data.last_message)}

                <div className="message-date-info">
                  <span className="message-date">
                    <Moment format="MM/DD/YY HH:mm A">
                      {msg.group_chat_data.last_message_time
                        ? msg.group_chat_data.last_message_time
                        : msg.group_chat_data.created_at}
                    </Moment>
                  </span>
                  {/* {renderMessageUsers(item.moreUsers)} */}
                </div>
              </div>

              {/* {!msg.group_chat_data.hasOwnProperty('invitation_type') &&
                                             msg.group_chat_data.hasOwnProperty('group_detail') && (
                                              //  <OtherUserImages
                                              //    otherUsers={filteringUsers(
                                              //      msg.group_chat_data.audience_detail.concat(
                                              //        msg.group_chat_data.moderator_detail,
                                              //        msg.group_chat_data.creator_detail
                                              //          ? [msg.group_chat_data.creator_detail]
                                              //          : [msg.group_chat_data.creator_details],
                                              //      ),
                                              //      arrayColumn(
                                              //        msg.group_chat_data.group_detail,
                                              //        'id',
                                              //      ),
                                              //    )}
                                              //  />
                                             )} */}
              {!msg.group_chat_data.hasOwnProperty('invitation_type') &&
                !msg.group_chat_data.hasOwnProperty(
                  'opportunity_invitaion_status',
                ) &&
                msg.group_chat_data.hasOwnProperty('opportunity_id') &&
                msg.group_chat_data.chat_type === 'opportunity' && (
                  <OtherUserImages
                    otherUsers={filteringUsers(
                      msg.group_chat_data.other_persons_opportunity_chat_room.concat(
                        msg.group_chat_data.team_member_details,
                        msg.group_chat_data.creator_detail
                          ? [msg.group_chat_data.creator_detail]
                          : [msg.group_chat_data.creator_details],
                      ),
                      arrayColumn(
                        [
                          ...msg.group_chat_data
                            .other_persons_opportunity_chat_room,
                          ...msg.group_chat_data.team_member_details,
                          msg.group_chat_data.creator_details,
                        ],
                        'id',
                      ),
                    )}
                  />
                )}
            </div>
            {/* {PageDesign()} */}
          </div>
        )}
        {msg.type === 'appointment' && msg.appointment_data && (
          <div
            className="refferals-list as-link card-container"
            onClick={() => {
              ShowSelectedComponent(msg.appointment_data, msg.type);
            }}>
            <div className="padd-with-borderContaier">
              <div className="refferals-list-item new-refferal-list mb-0">
                <div className="refferal-content">
                  <h2>
                    {msg.appointment_data.creator_details &&
                      fixName(
                        msg.appointment_data.creator_details.first_name,
                        msg.appointment_data.creator_details.last_name,
                      )}
                  </h2>
                  <span className="refferal-auther">
                    {msg.appointment_data.appointment_type_title}
                  </span>
                  {/* <p>Think there may be some synergy</p> */}
                  <span className="refferal-date">
                    {/* {moment(item.start_date).format(
                                                'MM/DD/YYYY',
                                              )}{' '} */}
                    {msg.appointment_data.duration + 'min'}
                  </span>
                </div>
                <div className="refferal-img">
                  <img
                    src={
                      msg.appointment_data.creator_details &&
                      msg.appointment_data.creator_details.profile_img
                        ? msg.appointment_data.creator_details.profile_img
                        : DEFAULT_IMAGE
                    }
                    alt=""
                  />
                  <p className="refferal-info-que text-end">
                    {msg.appointment_data.cost
                      ? '$' + msg.appointment_data.cost
                      : 'Free'}
                  </p>
                </div>
              </div>
            </div>
            {/* {ElementDesign('appointment', item)} */}
          </div>
        )}
        {(msg.type === 'image' ||
          (msg.type === 'snapshot' &&
            Object.entries(msg.picture_url).length > 0) ||
          msg.type === 'video' ||
          msg.type === 'document') && (
          <div className="quoted-msg mt-2">
            <div className="msg-container">
              {msg.type === 'image' && (
                <div
                  className="msg-img"
                  onClick={() => {
                    handleChatBoxSignal('show-image', msg.picture_url);
                  }}>
                  <img
                    className="as-link"
                    src={msg.picture_url}
                    style={{width: '50px'}}
                    alt=""
                  />
                </div>
              )}
              {msg.type === 'snapshot' && (
                <div className="d-flex align-items-center chatSnapshots">
                  {Object.entries(msg.picture_url).length &&
                    msg.picture_url.slice(0, 4).map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="msg-img as-link snapShotsImg"
                          onClick={() => {
                            if (index === 3) {
                              handleChatBoxSignal(
                                'show-snapshot-detail',
                                msg.picture_url,
                              );
                            } else {
                              handleChatBoxSignal(
                                'show-snapshot-detail',
                                msg.picture_url,
                                '',
                                '',
                                item.id,
                              );
                            }
                          }}>
                          {/* <p className='text-center'>kkkkkkkkkkkkkkkkkkk</p> */}
                          <img
                            className="as-link"
                            src={item.images[0]}
                            // style={{width: '50px'}}
                            alt=""
                          />
                          {msg.picture_url.length > 4 && index === 3 && (
                            <span
                              className="overlay"
                              onClick={() => {
                                handleChatBoxSignal(
                                  'show-snapshot-detail',
                                  msg.picture_url,
                                );
                                // getSpecificAccomplishment(item.id);
                              }}>
                              {`+${msg.picture_url.length - 4}`}
                            </span>
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
              {msg.type === 'video' && (
                <div
                  className="msg-img"
                  onClick={() => {
                    handleChatBoxSignal('show-video', msg.video_url);
                  }}>
                  <video
                    className="as-link"
                    src={msg.video_url}
                    style={{width: '50px'}}
                  />
                </div>
              )}
              {msg.type === 'document' && (
                <span className="action-icon file-icon">
                  <i className="fas fa-file-lines" />
                  {/* </a> */}
                </span>
              )}
            </div>
          </div>
        )}

        <div className="message-date-info reply_options reply-msg ">
          {/* <span className="message-date">
            {getTimeFormatForChat(msg.send_date)}
          </span> */}
          <div className="d-flex gap-2 align-items-center justify-content-center">
            {msg.hasOwnProperty('emojis') && msg.emojis.length > 0 && (
              <div
                className="users-imgs as-link d-flex align-items-center reply_reactions"
                onClick={() => {
                  handleChatBoxSignal('show-emojis', msg.emojis);
                }}>
                {msg.emojis.slice(-3).map((emojiObj, index) => (
                  <img
                    src={
                      S3_EMOJI_DEV +
                      emojiObj.skinTone +
                      '/' +
                      emojiObj.emojiName +
                      '.svg'
                    }
                    key={`${emojiObj.emojiName}-${index}`}
                    alt=""
                  />
                ))}
                <span className="reaction-count">
                  {msg.emojis.length > 3 ? `${msg.emojis.length - 3}+` : ''}
                </span>
              </div>
            )}

            <span
              class="emoji-icons-item add-new-reaction reply_emoji_icon"
              onClick={() => {
                setReplyIndex(i);
                setShowReplyEmojiModal(true);
              }}>
              <i className="icon-smiley-plus"></i>
            </span>
          </div>

          <span
            className="icon as-link"
            onClick={() => {
              // handleSelectReply('emoji', allChat, '', '');
              // handleChatBoxSignal('add-reply-emoji', allChat, '', '');
              toggleReplyOptions(true);
              setReplyIndex(i);
            }}>
            {' '}
            <i className="fas fa-ellipsis-vertical"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReplyBox;
