"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.filter = exports.transformMap = exports.searchMap = exports.DeviceInformation = exports.HardwareInformation = exports.SdkPlatform = exports.UserMetric = void 0;
const Bowser = __importStar(require("bowser"));
const Statistics_1 = require("./Statistics");
/**
 * @ignore
 */
const browser = Bowser.getParser(window.navigator.userAgent);
/**
 * @ignore
 */
class UserMetric {
    constructor() {
        this.version = '1.0';
        this.userId = '';
        this.timestamp = 0;
    }
}
exports.UserMetric = UserMetric;
/**
 * @ignore
 */
var SdkPlatform;
(function (SdkPlatform) {
    SdkPlatform["Browser"] = "browser";
})(SdkPlatform = exports.SdkPlatform || (exports.SdkPlatform = {}));
/**
 * @ignore
 */
class HardwareInformation {
    constructor() {
        this.cpu = '';
        this.ram = 0;
        this.screen = '';
    }
}
exports.HardwareInformation = HardwareInformation;
/**
 * @ignore
 */
class DeviceInformation {
    constructor() {
        this.name = '';
        this.os = '';
        this.timeZone = '';
        this.sdkPlatform = SdkPlatform.Browser;
        this.sdkVersion = __VERSION__;
        this.uxkitVersion = '';
    }
}
exports.DeviceInformation = DeviceInformation;
/**
 * @ignore
 */
exports.searchMap = new Map([
    [
        'RTCCandidatePairStats',
        (s) => (s.type === 'candidate-pair' && !browser.is('firefox') && s.nominated === true) || (browser.is('firefox') && s.selected === true),
    ],
    ['RTCOutboundRtpAudioStreamStats', (s) => s.type === 'outbound-rtp' && s.mediaType === 'audio'],
    ['RTCOutboundRtpVideoStreamStats', (s) => s.type === 'outbound-rtp' && s.mediaType === 'video'],
    ['RTCRemoteInboundRTPStreamStats', (s) => s.type === 'remote-inbound-rtp'],
    ['RTCInboundRTPAudioStreamStats', (s) => s.type === 'inbound-rtp' && s.mediaType === 'audio'],
    ['RTCInboundRTPVideoStreamStats', (s) => s.type === 'inbound-rtp' && s.mediaType === 'video'],
    ['RTCMediaStreamAudioTrack', (s) => s.type === 'track' && s.kind === 'audio'],
    ['RTCMediaStreamVideoTrack', (s) => s.type === 'track' && s.kind === 'video'],
    ['RTCStreamDescription', (s) => s.type === 'stream'],
]);
/**
 * @ignore
 */
exports.transformMap = {
    RTCCandidatePairStats: Statistics_1.RTCCandidatePairStats,
    RTCOutboundRtpAudioStreamStats: Statistics_1.RTCOutboundRtpAudioStreamStats,
    RTCOutboundRtpVideoStreamStats: Statistics_1.RTCOutboundRtpVideoStreamStats,
    RTCRemoteInboundRTPStreamStats: Statistics_1.RTCRemoteInboundRTPStreamStats,
    RTCInboundRTPAudioStreamStats: Statistics_1.RTCInboundRTPAudioStreamStats,
    RTCInboundRTPVideoStreamStats: Statistics_1.RTCInboundRTPVideoStreamStats,
    RTCMediaStreamAudioTrack: Statistics_1.RTCMediaStreamAudioTrack,
    RTCMediaStreamVideoTrack: Statistics_1.RTCMediaStreamVideoTrack,
    RTCStreamDescription: Statistics_1.RTCStreamDescription,
};
/**
 * @ignore
 */
function filter(source, c) {
    let obj = new c();
    Object.keys(obj).forEach((key) => {
        obj[key] = source[key];
    });
    return obj;
}
exports.filter = filter;
