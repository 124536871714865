import React from 'react'

const PollIconSvg = () => {
  return (
    

<svg width="20.877" height="19.997" viewBox="0 0 128 130" fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M31.83 64.76H3.78C1.69236 64.76 0 66.4524 0 68.54V125.74C0 127.828 1.69236 129.52 3.78 129.52H31.83C33.9176 129.52 35.61 127.828 35.61 125.74V68.54C35.61 66.4524 33.9176 64.76 31.83 64.76Z"
    fill="#ffffff" />
  <path
    d="M77.5995 0H49.5495C47.4619 0 45.7695 1.69236 45.7695 3.78V125.74C45.7695 127.828 47.4619 129.52 49.5495 129.52H77.5995C79.6872 129.52 81.3795 127.828 81.3795 125.74V3.78C81.3795 1.69236 79.6872 0 77.5995 0Z"
    fill="#ffffff" />
  <path
    d="M123.369 36.41H95.3191C93.2314 36.41 91.5391 38.1024 91.5391 40.19V125.74C91.5391 127.828 93.2314 129.52 95.3191 129.52H123.369C125.457 129.52 127.149 127.828 127.149 125.74V40.19C127.149 38.1024 125.457 36.41 123.369 36.41Z"
    fill="#ffffff" />
</svg>
  )
}

export default PollIconSvg
