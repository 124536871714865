import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import commonApi from '../../Services/CommonService';
import Moment from 'moment';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';

import 'react-datepicker/dist/react-datepicker.css';
import {DEFAULT_IMAGE, ENV_HOST} from '../../Utils/env';
import moment from 'moment/moment';
import {
  convertTime12To24,
  convertTimeIntoDateTime,
  fixName,
} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import CustomModal from '../CustomModal';
import {getItem} from '../../Utils/LocalStorage';
import {connect} from 'react-redux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Appointments from '../../Pages/LandingPage/Appointments';
import DiscoverBuilders from '../SessionList/DiscoverBuilders';
import AllBuilders from '../../Pages/Front/StaticPages/AllBuilders';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import CreateSession from '../SessionDetails/CreateSession';
import EditAppointment from './EditAppointment';
import {SwiperSlide} from 'swiper/react';
import CalanderModel from '../Modals/CalanderModel';
import CustomDropdown from '../CustomDropdown';
import { RoiLogo } from '../../Constants/Images';
import Calender from '../FeedBack/Calender';
// import {getItem} from '../../Utils/LocalStorage';

const ScheduleAppointmemt = props => {
  const [showLoader, setshowLoader] = useState(false);
  const [builders, setBuilders] = useState([]);

  const [appointmentDisable, setAppointmentDisable] = useState([]);
  const [typeId, setTypeId] = useState([]);

  const [location, setLocation] = useState('');
  const [payLoadIs, setPayLoadIs] = useState('');
  const [events, setevents] = useState('');
  const [dataOfSlectedSlot, setDataOfSlectedSlot] = useState('');
  const [ShowModel, setShowModel] = useState('');

  const [showComponent, setShowComponent] = useState('');
  const [appointmentType, setAppointmentType] = useState([]);
  const [scheduleId, setScheduleId] = useState('');
  const [scheduleTitle, setScheduleTitle] = useState('');
  const [allSlots, setAllSlots] = useState('');

  const [allBuilders, setAllBuilders] = useState([]);
  const [contact, setContact] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [calendarData, setCalendarData] = useState([]);

  // const [serverTime, setServerTime] = useState(new Date());

  // const [showModel, setShowModel] = useState('');

  const [appointmentDetail, setAppointmentDetail] = useState({
    appointment_type: '',
    cost: '',
    day: '',
    description: '',
    duration: '',
    id: '',
    qr_code: '',
    title: '',
    user_id: '',
    session_type: '',
  });
  const [showError, setShowError] = useState(false);
  const [topIcons, setTopIcons] = useState({
    settings: false,
    location: false,
    shareable: false,
    personal: false,
  });

  const [serverTime, setServerTime] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = e => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };
  const handleClick = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  let jugar =
    typeof location === 'object'
      ? location.label
      : typeof location === 'string' && location !== ''
      ? location
      : 'Type city and state';

  useEffect(() => {
    // getAppointmentType();
    getAppointments('');
    setshowLoader(true);

    // addCard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appointmentData]);

  const get_google_calenders = () => {
    commonApi
      .get_all_calendars({token: localStorage.getItem('token')})
      .then(response => {
        if (response.all_calendars.length > 0) {
          setCalendarData(response.all_calendars);
          setShowModel('calneder');
        } else {
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const buildersHandle = (type, item) => {
    if (type === 'profileComponent' && !builders.includes(item.id)) {
      setBuilders(previous => [...previous, item.id]);
      setAllBuilders(previous => [...previous, item]);
      setOriginalData(previous => [...previous, item]);
    } else {
      if (type === 'profileComponent' && builders.includes(item.id)) {
        setBuilders(previous =>
          previous.filter(miniItem => miniItem !== item.id),
        );
        setAllBuilders(previous =>
          previous.filter(miniItem => miniItem.id !== item.id),
        );
        setOriginalData(previous =>
          previous.filter(miniItem => miniItem.id !== item.id),
        );
      } else {
        setShowComponent(type);
      }
    }
  };
  useEffect(() => {
    getAppointmentSlots(scheduleId, startDate);
    setPayLoadIs('');
    setevents('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId, startDate]);
  useEffect(() => {
    // getAppointmentType();
    appointmentType.forEach((miniItem, index) => {
      if (miniItem.id === scheduleId) {
        setScheduleTitle(miniItem.title);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId]);
  useEffect(() => {
    getAppointmentSlots(scheduleId, startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builders]);

  const getAppointmentSlots = (sId, sDate) => {
    const date = new Date(sDate);   
    const paylaod = {
      creator_id: localStorage.getItem('id'),
      date: sDate,
      day: Moment(date).format('dddd').toString().toLowerCase(),
      schedule_id: sId,
      user_ids: builders,
      // schedule_id: getItem('consult') ? getItem('consult') : sId,
    };
    // setItem('consult', '');
    commonApi
      .get_appointment_slots(paylaod)
      .then(response => {
        if (response.success === true) {
          // setshowLoader(false);
          setAppointmentDetail({
            appointment_type: response?.schedules?.appointment_type,
            cost: response?.schedules?.cost,
            day: response?.schedules?.day,
            description: response?.schedules?.description,
            duration: response?.schedules?.duration,
            id: response?.schedules?.id,
            qr_code: response?.schedules?.qr_code,
            title: response?.schedules?.title,
            session_type: response?.schedules?.session_type,
            user_id: response?.schedules?.user_id,
          });

          setAllSlots(response.data);
          setAppointmentDisable([]);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAppointments = () => {
    // setshowLoader(true);
    const paylaod = {
      user_id: localStorage.getItem('id'),
      appointment_type_id: '',
    };
    commonApi
      .get_appointment_type(paylaod)
      .then(response => {
        if (response.success === true) {
          setshowLoader(false);
          setAppointmentType(response.data);
          if (props.appointmentData) {
            setScheduleId(props.appointmentData.id);

            // setScheduleTitle(props.appointmentData.title);
          } else {
            setScheduleId(response.data[0].id);
            // setScheduleTitle(response.data[0].title);
          }

          setAppointmentDetail({
            appointment_type: response.data[0].appointment_type,
            cost: response.data[0].cost,
            day: response.data[0].day,
            description: response.data[0].description,
            duration: response.data[0].duration,
            id: response.data[0].id,
            qr_code: response.data[0].qr_code,
            title: response.data[0].title,
            session_type: response.data[0].session_type,
            user_id: response.data[0].user_id,
          });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const getAppointmentType = () => {
  //   // setshowLoader(true);
  //   const paylaod = {
  //     user_id: localStorage.getItem('id'),
  //     appointment_type_id: '',
  //   };

  //   commonApi
  //     .get_appointment_type(paylaod)
  //     .then(response => {
  //       if (response.success === true) {
  //         response.data.forEach((miniItem, index) => {
  //           if (miniItem.id === scheduleId) {
  //             setScheduleTitle(miniItem.title);
  //           }
  //         });
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (!builders.includes(miniItem.id)) {
          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalData(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const createAppointmentPayload = data => {
    setDataOfSlectedSlot(data);
    const date = new Date(data.new_start_time);
    setevents({
      summary: scheduleTitle,
      location: '',
      start: {
        dateTime: convertTimeIntoDateTime(
          startDate,
          data.start_time,
        ).toISOString(),
        timeZone: 'America/New_York',
      },
      end: {
        dateTime: convertTimeIntoDateTime(
          startDate,
          data.end_time,
        ).toISOString(),
        timeZone: 'America/New_York',
      },
      visibility: 'public',
      recurrence: [],
      attendees: [],
      reminders: {
        useDefault: false,
        overrides: [
          {method: 'email', minutes: 24 * 60},
          {method: 'popup', minutes: 10},
        ],
      },
    });
    setPayLoadIs({
      complete_time: data.new_start_time.replaceAll('/', '-'),
      date: Moment(date).format('ll').toString(),
      day: Moment(date).format('dddd').toString(),
      mentor_id: data.creator_id,
      slot_id: data.id,
      start_time: data.start_time,
      user_id: builders.toString(','),
      is_paid: data.cost === '' ? 'no' : 'yes',
      payment_link:
        ' https://' +
        ENV_HOST +
        'roiforpros.com/' +
        getItem('userName') +
        '/consult?id=',
    });

    // setShowForm(true);
  };

  const onSubmit = () => {
    if (builders.length === 0) {
      errorMessage('Select user');
    } else if (!payLoadIs) {
      errorMessage('Select slote');
    } else if (location === '' && topIcons.location) {
      setShowError(true);
    } else {
      if (builders.length > 1 && topIcons.location) {
        setShowModel('create-session');
      } else if (builders.length > 1 && !topIcons.location) {
        setShowModel('create-session');
      } else {
        createAppointment();
      }
    }
  };

  const getfolowers = qry => {
    // alert('@@@@@')
    var payLoad = {
      search_query: qry,
    };
    commonApi
      .excluded_followers_list(payLoad)
      .then(res => {
        // setshowLoader(false);

        if (res.status === 200) {
          // setFollowers(res.data);

          if (contact.length > 0) {
            const filterData = res.data.filter(
              item =>
                item.exist_in_build === 'no' &&
                contact.some(
                  dataItem =>
                    dataItem.fname === item.first_name &&
                    dataItem.lname === item.last_name &&
                    dataItem.contact === item.phone,
                ),
            );

            if (filterData.length > 0) {
              filterData.forEach(miniItem => {
                setBuilders(previous => [...previous, miniItem.id]);
                setAllBuilders(previous => [...previous, miniItem]);

                // setSelectedModData(updatedData)
              });
            }
          }
          if (contact) {
            setContact([]);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    // getAppointmentType();
    setTimeout(() => {
      getfolowers();
    }, 1500);
    getAppointments();
    // addCard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);

  useEffect(() => {
    commonApi.check_server_time().then(res => {
      setServerTime(new Date(res.server_time));
    });
    const interval = setInterval(() => {
      commonApi.check_server_time().then(res => {
        setServerTime(new Date(res.server_time));
      });
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const createAppointment = () => {
    const payload = {
      complete_time: payLoadIs.complete_time,
      date: payLoadIs.date,
      day: payLoadIs.day,
      mentor_id: payLoadIs.mentor_id,
      slot_id: payLoadIs.slot_id,
      start_time: payLoadIs.start_time,
      user_id: builders.toString(','),
      is_paid: payLoadIs.is_paid,
      payment_link:
        ' https://' +
        ENV_HOST +
        'roiforpros.com/' +
        getItem('userName') +
        '/consult?id=',
    };

    commonApi
      .create_appointment_with_user(payload)
      .then(response => {
        // amplitude('BOOK_APPOINTMENT');
        // setshowLoader(false);
        if (response.success === true) {
          setTypeId(response.slot_id);
          setAppointmentDisable(arr => [...arr, payLoadIs.slot_id]);
          successMessage(response.message);
          setPayLoadIs({});
          // props.onClose();
          get_google_calenders();
        } else {
          if (response.occupied) {
            setAppointmentDisable(arr => [...arr, payLoadIs.slot_id]);
            setPayLoadIs({});
          }
          errorMessage(response.message);
        }
        if (props.subTabType !== 'history') {
          props.getMyAppointmentsByType();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const areAllUsersAvailable = appointment => {
    return appointment.check_availability.every(user => user.available_status);
  };

  // Determine the class for each appointment
  const getClassForAppointment = appointment => {
    return areAllUsersAvailable(appointment)
      ? 'border-success'
      : 'border-warning';
  };


  const renderHeader = () => (
    <>
       <div className="" onClick={()=>{
        setShowComponent('appointment');
       }}>
           
                <i className="fa-sharp fa-solid fa-gear as-link" />
         
          </div>
    </>
  );

  return (
    <div>
      <SlidingPanel hidePanel={() => props.onClose()}
        renderData={renderHeader}
        showBlackHeader={true}
        // headerPadding={'24px 20px'}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#f7f6f2',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        <div className="serach-filter-wrapper ScheduleScreen create-schedule">
          {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
          {/* <div className="ticket-icons-options a">
            <ul>
              <li
                className={topIcons.settings ? 'active' : ''}
                onClick={() => {
                  setShowComponent('appointment');
                }}>
                
                <i className="fa-sharp fa-solid fa-gear" />
              </li>

              <li className={topIcons.shareable ? 'active' : ''}>
                <i className="icon fa-solid fa-arrow-up-from-bracket" />
              </li>
              <li
                className={topIcons.location ? 'active' : ''}
                onClick={() => {
                  setTopIcons({
                    ...topIcons,
                    location: !topIcons.location,
                  });
                }}>
                <i className="fas fa-map-marker-alt" />
              </li>
            </ul>
          </div> */}
          <div className="black-head-form-pad-left">
            <div
              className={`sidebar-heading-wrapper mt-0 mb-4 ${
                props.windowWidth < 1300 ? 'with-panel' : ''
              }`}>
              <SwiperSlide>
                <DiscoverBuilders
                  buildersHandle={buildersHandle}
                  type={'appointment'}
                  builders={builders}
                  setshowLoader={setshowLoader}
                  contact={contact}
                />
              </SwiperSlide>
            </div>
          </div>

          <Scrollbars>
            <div className="fixed-width-inputs black-head-form-pad-left pb-5">
              <>
                {/* <div className="search-form">
                  <div className="select-wrapper fieldset">
                    <select
                      disabled={props.appointmentData ? true : false}
                      className="form-control"
                      value={scheduleId}
                      onChange={e => {
                        setScheduleId(e.target.value);
                        // setScheduleTitle(e.target.id);
                      }}>
                      {Object.entries(appointmentType).length > 0 &&
                        appointmentType.map((item, index) => (
                          <option
                            id={item.id}
                            value={item.id}
                            key={`ap-type-${item.id}`}>
                            {item.title}
                          </option>
                        ))}

                    </select>

                    

                  </div>
                </div> */}
                {Object.entries(appointmentType).length > 0 && (
                  <CustomDropdown
                    disabled={props.appointmentData ? true : false}
                    setDropdownId={setScheduleId}
                    dropdownId={scheduleId}
                    DropDownValues={appointmentType}
                    itemName={['title']}
                    className={'recruitment_dropdown'}
                  />
                )}

                <div className="choose-appointment-wrapper schedule-screen">
                  <p>{appointmentDetail.description}</p>

                  <div className="appointment-cost-wrapper d-flex align-items-center justify-content-between my-3">
                    <div className="duration-cost d-flex align-items-center">
                      <label className="p-bold">
                        Duration{' '}
                        <span className="p-reg m-0">
                          {appointmentDetail.duration} min
                        </span>
                      </label>
                    </div>
                    <div className="duration-cost d-flex align-items-center">
                      <label className="p-bold">
                        Cost{' '}
                        <span className="p-reg m-0">
                          {appointmentDetail.cost
                            ? `$${appointmentDetail.cost}`
                            : 'Free'}
                        </span>
                      </label>
                    </div>
                    {/* <div className="duration-cost">
                      <label>Call Options</label>

                      <div className="icons-wrapper">
                        <span className="icon">
                          <i className="fa-solid fa-video"></i>
                        </span>
                        <span className="icon">
                          <i className="fa-solid fa-phone"></i>
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    {allBuilders.length === 0 && (
                      <div
                        className="stage-list-item as-link text-left d-flex align-items-center"
                        onClick={() => {
                          setShowComponent('inviteUser');
                        }}>
                        <div className="stage-person-img addcontact-image">
                          <img alt="" src={DEFAULT_IMAGE} />
                        </div>
                        <p className="p-reg text-gey">Add contact</p>
                      </div>
                    )}
                    {allBuilders.length > 0 && (
                      <div
                        className="stage-list-item as-link text-left d-flex align-items-center"
                        onClick={() => {
                          setShowComponent('AllBuilders');
                        }}>
                        <div className="stage-person-img addcontact-image">
                          <img
                            alt=""
                            src={
                              allBuilders[0].profile_img
                                ? allBuilders[0].profile_img
                                : DEFAULT_IMAGE
                            }
                          />
                        </div>
                        <div className="stage-person-name">
                          <h3 className="p-reg text-gey">
                            {fixName(
                              allBuilders[0].first_name,
                              allBuilders[0].last_name,
                            )}{' '}
                            {allBuilders.length > 1 &&
                              `  + ${allBuilders.length - 1} `}
                          </h3>
                        </div>
                      </div>
                    )}
                    <div className="invite-persons-heading text-right">
                      <span
                        className="circle-link-icon"
                        onClick={() => {
                          setShowComponent('CircleList');
                        }}>
                        {/* Circles  */}
                        <i className="icon-circles" />
                      </span>
                    </div>
                  </div>

                  <div className="appointment-date-wrapper">
                    <div className="date-wrapper">
                      <span
                        onClick={() => {
                          setStartDate(
                            new Date(Moment(startDate).add(-1, 'days')),
                          );
                        }}
                        className="navs fas fa-chevron-left"
                      />
                      <span onClick={handleClick} className="selected-date">
                        {Moment(startDate).format('ll').toString()}
                      </span>
                      <span
                        onClick={() => {
                          setStartDate(
                            new Date(Moment(startDate).add(1, 'days')),
                          );
                        }}
                        className="navs fas fa-chevron-right"
                      />
                    </div>
                    {isOpen && (
                      <div className="calender-wrapper">
                        {/* <DatePicker
                          selected={startDate}
                          onChange={handleChange}
                          inline
                        /> */}

                    <Calender
                      setSelectedDate={handleChange}
                      selectedDate={startDate  ? new Date(startDate) : ''} 
                      showDate={true} 
                      showTime={false}
                      type='appointment'
                      otherType='schedule_appointment'
                      isOpenCalendar={isOpen}
                      setIsOpenCalendar={setIsOpen}
                      />
                      </div>
                    )}
                    {/* <Form.Control type="date" name="date" value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value); }} /> */}

                    <div className="available-hours justify-content-center my-0">
                      {Object.entries(allSlots).length > 0 ? (
                        Object.entries(allSlots).length > 0 &&
                        allSlots.map((item, index) => (
                          <Button
                            onClick={() => {
                              createAppointmentPayload(item);
                              // setAppointmentPayload(item);
                            }}
                            disabled={
                              item.slot_availability === 'occupied' ||
                              appointmentDisable.includes(item.id) ||
                              new Date(
                                moment(serverTime).format('MMMM D, YYYY'),
                              ) > startDate ||
                              (convertTime12To24(
                                moment(serverTime).format('hh:mm A'),
                              ) > convertTime12To24(item.start_time) &&
                                moment(serverTime).format('l') ===
                                  moment(startDate).format('l'))
                                ? true
                                : false
                            }
                            key={`allSlots-${index}`}
                            className={
                              builders.length > 0 && item.check_availability
                                ? getClassForAppointment(item)
                                : payLoadIs && payLoadIs.slot_id === item.id
                                ? 'selected-slot'
                                : 'cate-btn'
                            }
                            value={item.id}>
                            {item.start_time}
                          </Button>
                        ))
                      ) : (
                        <div className="no-results-wrapper no-appointments mt-5">
                          <div className="img-bg-wrapper">
                            <img
                              src={require('../../Assets/images/norecord/no-appointments.png')}
                              alt=""
                            />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-desc">
                              you dont have any appointment slots available
                            </p>

                            <div className="btn-wrapper mt-3">
                              <Button
                                className={'btn btn-dark w-auto'}
                                onClick={() => {
                                  setShowComponent('addAppointment');
                                }}>
                                Manage Appointment Type
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
              {/* {topIcons.location && ( */}
                <div className="fieldset">
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label>Location</Form.Label>
                  </div>
                  <div className="location-field form-control schedule_location">
                    <GooglePlacesAutocomplete
                      location
                      selectProps={{
                        location,
                        onChange: setLocation,
                        name: 'city',
                        placeholder: jugar,
                        noOptionsMessage: () => null,
                      }}
                    />
                  </div>
                  {showError === true && location === '' ? (
                    <div className="text-danger textforerror">
                      Location is required.
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              {/* )} */}
            </div>
          </Scrollbars>
          {allSlots.length > 0 && (
            <div className="filter-btn-wrapper pt-1 create-session-btn mx-0 fixed-width-inputs bg_transparent bottom-0">
              <div className="invite-btn w-100 text-center mt-1 mb-2">
                <Button
                  className="btn-dark m-0"
                  type="submit"
                  onClick={() => {
                    onSubmit();
                    // setTimeout(() => {

                    // }, 1000);
                  }}>
                  {builders.length === 0 || builders.length === 1
                    ? ' Schedule Appointment'
                    : builders.length > 1 && !topIcons.location
                    ? 'Continue as Session'
                    : builders.length > 1 &&
                      topIcons.location &&
                      'Continue as Event'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>
      {ShowModel === 'create-session' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModel('');
          }}>
          <div className="new-session-modal-wrapper text-center createSessionModal">
            <div className="img-bg-wrapper">
              <img
                src={
                  topIcons.location
                    ? require('../../Assets/images/norecord/event.png')
                    : require('../../Assets/images/norecord/session.png')
                }
                alt="no-session"
              />
            </div>
            <div className="new-session-modal">
              <h3 className="p-reg mb-2">Almost Done</h3>
              {/* <p className="p-bold mb-2">
                {sessionDolby && sessionDolby.session_title}
              </p> */}
              <p className="p-reg">
                {`   Based on your selections, this is ${
                  topIcons.location ? 'an event.' : 'a session.'
                } To get the most out  of your meetup, we are forwarding you with your data to finish`}
                .
              </p>
              <Button
                className="btn-dark my-2"
                onClick={() => {
                  setShowComponent('createSession');
                  setTimeout(() => {
                    setShowModel('');
                  }, 1000);
                  
                }}>
                Ok
              </Button>

              <div
                className="copy-link-text d-flex align-items-center justify-content-center m-0"
                onClick={() => {
                  setShowModel('');
                }}>
                {/* <i className="icon-link" /> */}
                Cancel
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'AllBuilders' && (
        <AllBuilders
          type={'appointment'}
          builders={builders}
          setBuilders={setBuilders}
          setAllBuilders={setAllBuilders}
          allBuilders={allBuilders}
          originalData={originalData}
          setOriginalData={setOriginalData}
          contact={contact}
          setContact={setContact}
          onClose={() => {
            setShowComponent('');
            // toggleHiddenPanel(!showHiddenPanel);
          }}
        />
      )}

      {showComponent === 'createSession' && (
        <CreateSession
          onClose={() => {
            // props.onClose();
            setShowComponent('');
            // props.onClose();
          }}
          onCloseHitApi={
            ()=>{
              props.onClose();
            }
          }
          appointment={dataOfSlectedSlot}
          appointmentDetail={appointmentDetail}
          builders={allBuilders}
          showCloseIcon={true}
          location={location}
        />
      )}
      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          type={'schedule'}
          contact={contact}
          setContact={setContact}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'addAppointment' && (
        <EditAppointment onClose={() => setShowComponent('')} />
      )}

      {showComponent === 'appointment' && (
        <Appointments
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {ShowModel === 'calneder' && calendarData.length > 0 && (
        <CalanderModel
          calendarData={calendarData}
          typeId={typeId}
          event={events}
          setShowModel={setShowModel}
          type={'appointment'}
          onClose={() => {
            props.onClose();
          }}
        />
      )}
    </div>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(ScheduleAppointmemt);
