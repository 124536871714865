import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {DEFAULT_IMAGE, selected_lang} from '../../Utils/env';
import Slider from 'react-slick';
import {goToZoom, parseUrl} from '../../Utils/commonUtils';

import {useHistory} from 'react-router-dom';
import {
  getUserSessionList,
  getUserEventList,
  getCompanyMeetUpList,
} from '../../Store/Actions/SessionAction';
import {useSelector, useDispatch} from 'react-redux';
import SessionSearchFilter from './SessionSearchFilter';
import {getItem} from '../../Utils/LocalStorage';
import {errorMessage} from '../../Utils/ToastMessages';
import API from '../../Services/SessionServices';
import CustomModal from '../CustomModal';
import moment from 'moment';
import {callDeepLink} from '../../Store/Actions/DeepLinkAction';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';

// const EventsItem = ({item, setShowLogin}) => {
//   const history = useHistory();
//   const {host, pathname, search} = window.location;
//   const urlParts = pathname.split('/').filter(Boolean);

//   const _renderUserImg = person => {
//     return (
//       <div className="img-item">
//         {person.owner && (
//           <span className="owner-icon">
//             <i className="fas fa-crown" />
//           </span>
//         )}
//         {!person.owner && person.isOnline && (
//           <span className="online-icon"></span>
//         )}
//         {person.speaker && (
//           <span className="speaker-icon">
//             <i className="fas fa-microphone" />
//           </span>
//         )}
//         <img src={person.image} alt="" />
//       </div>
//     );
//   };

//   return (
//     <div
//       key={item.id + '-ses'}
//       className="events-item as-link"
//       onClick={() => {
//         const searchParams = new URLSearchParams();
//         searchParams.append('id', item.id);
//         const url = `/${urlParts[0]}/event/invite?${searchParams.toString()}`;
//         if (getItem('id')) {
//           // history.push('/events/event_invite/' + item.id);
//           // setShowLogin('/events/event_invite/' + item.id);
//           history.push(url);
//         } else {
//           // successMessage('Please login first');
//           // history.push('/login/event_invite/' + item.id);
//           // setShowLogin('/login/event_invite/' + item.id);
//           setShowLogin(url);
//         }
//       }}>
//       <div
//         className="event-img"
//         onClick={() => {
//           // props.eventDetails(props.index);
//         }}>
//         <Link to={`#`}>
//           <img src={item.image} alt="" />
//         </Link>
//       </div>
//       <div className="session-heading">
//         <div
//           className="event-titles"
//           onClick={() => {
//             // props.eventDetails(props.index);
//           }}>
//           <h2>{item.title}</h2>
//           <div className="event-date">
//             {moment(item.date).format('MMMM Do YYYY, h:mmA')}
//           </div>
//           <div className="event-users-cate">
//             <span className="users-text">
//               <i className="far fa-circle-user" /> {item.users}
//             </span>
//             <span className="category-text">{item.category}</span>
//           </div>
//         </div>
//         {/* <div className="event-share">
//           <i className="fas fa-share" />
//         </div> */}
//       </div>
//       <div className="persons-img-list">
//         {Object.entries(item.persons).length > 0 &&
//           item.persons.map((person, index) => _renderUserImg(person))}
//       </div>
//       <div className="events-place-like">
//         <span className="address">
//           <i className="fas fa-location-dot" /> {item.address}
//         </span>
//         {item.price && (
//           <span className="event-amount">${item.price ? item.price : 0}</span>
//         )}
//         {/* <span className="event-heart">
//           {item.isLiked && <i className="fas fa-heart" />}
//           {!item.isLiked && <i className="far fa-heart" />}
//         </span> */}
//       </div>
//     </div>
//   );
// };

const RenderSessions = ({setShowModal,options, user,setDataofSession}) => {
  // const [tabs, setTabs] = useState([]);
  const [sessionType, setGalleryType] = useState('happening');
  // const [mainTab, setMainTab] = useState('Sessions');
  // const [threeDots, setThreeDots] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  // const [searchQry, setSearchQry] = useState('');
  // const [mapButton, setMapButton] = useState(false);
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [showLogin, setShowLogin] = useState(null);
  // const [hasRendered, setHasRendered] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  const urlString = String(window.location);
  // const {type, id} = useParams();
  const {pathname, search} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get('type');
  const [dataItem, setDataItem] = useState({});
  // const urlData = useSelector(state => state.companyUrl);
  const urlData = parseUrl(urlString);
  const dispatch = useDispatch();
  // const sessionData = useSelector(state => state.userSessionListing);
  // const eventData = useSelector(state => state.userEventListing);
  const meetupData = useSelector(state => state.companyMeetupListing);
  const history = useHistory();
  const eventSlider = {
    // className: "center",
    // centerMode: true,
    dots: true,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  // const _renderUserImg = person => {
  //   return (
  //     <div className="img-item" key={'per' + person.id}>
  //       {person.owner && (
  //         <span className="owner-icon">
  //           <i className="fas fa-crown" />
  //         </span>
  //       )}
  //       {!person.owner && person.isOnline && (
  //         <span className="online-icon"></span>
  //       )}
  //       {person.speaker && (
  //         <span className="speaker-icon">
  //           <i className="fas fa-microphone" />
  //         </span>
  //       )}
  //       <img src={person.image} alt="" />
  //     </div>
  //   );
  // };

  // const updateTabs = () => {
  //   const tempTabs = [];
  //   if (options.showSessions) {
  //     if (!tempTabs.includes('Sessions')) {
  //       tempTabs.push('Sessions');
  //     }
  //   } else {
  //     if (tempTabs.includes('Sessions')) {
  //       tempTabs.pop('Sessions');
  //     }
  //   }

  //   if (options.showEvents) {
  //     if (!tempTabs.includes('Events')) {
  //       tempTabs.push('Events');
  //     }
  //   } else {
  //     if (tempTabs.includes('Events')) {
  //       tempTabs.pop('Events');
  //     }
  //   }

  //   if (!options.showEvents && options.showSessions) {
  //     setMainTab('Sessions');
  //   }
  //   if (options.showEvents && !options.showSessions) {
  //     setMainTab('Events');
  //   }

  //   setTabs(tempTabs);
  // };

  const getApiSessionList = (
    sesType = 'happening',
    qry = '',
    eventIs = false,
    page = 1,
    filter = [],
  ) => {
    // setGalleryType(sesType);

    const data = {
      user_id: user.id,
      category_id: filter,
      events: eventIs,
      focus: 'no',
      gender: '',
      otherIdentifires: '',
      ethnicities: '',
      city_states: '',
      page: page,
      search_query: qry,
      sessions: !eventIs,
      type: sesType,
    };

    if (eventIs) {
      dispatch(getUserEventList(data));
    } else {
      dispatch(getUserSessionList(data));
    }
  };

  const getMeetupData = urldata => {
    // setGalleryType(sesType);

    // const data = {
    //   company_name: urldata.companyName
    //     ? urldata.companyName.replace('-', ' ')
    //     : '',
    //   user_id: urldata.userId ? urldata.userId : '',
    //   search_query: '',
    //   category_id: urldata.type === 'meetup' ? urldata.typeId : '',
    // };

    const data = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id: urlParts[0] && !urlParts[1] ? '' : urlData.userId,
      search_query: '',
      category_id: urldata.type === 'meetup' ? urldata.typeId : '',
    };

    dispatch(getCompanyMeetUpList(data));
  };

  const getApiEventList = (
    sesType = 'happening',
    qry = '',
    eventIs = true,
    page = 1,
  ) => {
    // setGalleryType(sesType);

    const data = {
      user_id: user.id,
      category_id: '',
      events: eventIs,
      focus: 'no',
      gender: '',
      otherIdentifires: '',
      ethnicities: '',
      city_states: '',
      page: page,
      search_query: qry,
      sessions: !eventIs,
      type: sesType,
    };

    dispatch(getUserEventList(data));
  };

  // const makeUsers = item => {
  //   const chatUsers = [
  //     {
  //       id: item.creator_id,
  //       name: item.creator_name,
  //       image: item.creator_img,
  //       owner: true,
  //     },
  //   ];
  //   if (item.moderators_list && item.moderators_list.length > 0) {
  //     item.moderators_list.map(item => {
  //       item.image = item.profile_img;
  //       item.speaker = true;
  //       chatUsers.push(item);
  //     });
  //   }
  //   if (item.audience_list && item.audience_list.length > 0) {
  //     item.audience_list.map(item => {
  //       item.image = item.profile_img;
  //       chatUsers.push(item);
  //     });
  //   }
  //   return chatUsers;
  // };

  // const makeSessionItem = item => {
  //   return {
  //     id: item.id,
  //     title: item.session_title,
  //     description: item.session_description,
  //     users: `${item.total_members + 1}/${item.total_moderators + 1}`,
  //     category: item.category_name,
  //     persons: makeUsers(item),
  //     address: item.address,
  //     like: item.favourite,
  //     price: item.price,
  //   };
  // };

  // const makeEventItem = item => {
  //   return {
  //     id: item.id,
  //     image: item.image.slider_images,
  //     date: item.session_start_time,
  //     title: item.session_title,
  //     description: item.session_description,
  //     users: `${item.total_members + 1}/${item.total_moderators + 1}`,
  //     category: item.category_name,
  //     persons: makeUsers(item),
  //     address: item.address,
  //     isLiked: item.favourite,
  //     price: item.price,
  //   };
  // };

  const joinTheSession = async id => {
    let payload = {conference_id: id};
    const response = await API.is_session_started(payload);

    if (response.started) {
      if (response.session_id) {
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', id);
        // history.push('/session-details');
        goToZoom(response.session_id);
      } else {
      }
    } else {
      errorMessage(response.message);
      history.push('/');
      // window.location.reload();
    }
  };

  // const hitWhenFilter = () => {
  //   // getApiSessionList(sessionType, searchQry, isEvent, 1, isCheck);
  //   if (hasRendered) {
  //     getApiSessionList(sessionType, searchQry, isEvent, 1, isCheck);
  //   } else {
  //     setHasRendered(true);
  //   }
  // };

  // useEffect(() => {
  //   if (searchFilter) {
  //   } else {
  //     hitWhenFilter();
  //   }
  // }, [searchFilter]);

  useEffect(() => {
    if (meetupData.length === 0) {
      setshowLoader(true);
    }

    setTimeout(() => {
      setshowLoader(false);
    }, 1500);
    // updateTabs();

    if (['session', 'sessions', 'event', 'events'].includes(urlParts[1])) {
      if (id) {
        setIsCheck([id]);
        if (urlParts[1] === 'event') {
          setIsEvent(true);
          getApiSessionList('happening', '', true, 1, [id]);
        } else if (urlParts[1] === 'session') {
          setIsEvent(false);
          getApiSessionList('happening', '', false, 1, [id]);
        }
      } else {
        if (urlParts[1] === 'events') {
          setIsEvent(true);
          getApiEventList();
        } else {
          getApiSessionList();
        }
      }

      // if (urlParts[1] === 'events' || urlParts[1] === 'event') {
      //   setTimeout(() => {
      //     setMainTab('Events');
      //   }, 100);
      // }
    } else {
      // getApiSessionList();
      // getApiEventList();
      // getMeetupData(urlData);
      // getRecruitementData(urlData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    // updateTabs();
  }, [options]);

  useEffect(() => {
    dispatch(callDeepLink());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLogin]);

  const AddUserInCompany = (url,item) => {
    const payload = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id: localStorage.getItem('id'),
    };
    commonApi
      .add_user_in_company(payload)
      .then(res => {
        console.log(item,'item')
        history.push(url, { typeIs: item.event == 'no' ? 'virtual' : 'in-person', meetup_item:item });
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <div className="">
      <div className="refferal-list-wrapper">
        {showLoader && <LoaderSpinner />}
        {/* {Object.entries(tabs).length > 1 && (
          <div className="text-center mt-1">
            <CustomTabs
              tabs={tabs}
              active={mainTab}
              onSelect={tab => setMainTab(tab)}
            />
          </div>
        )} */}

        <div className="profile-meetups-tab profile_page_meetup_recruitement">
          {meetupData && meetupData.length > 0 ? (
            <>
              <div className=" mt-4">
                <Slider {...eventSlider}>
                  {meetupData &&
                    meetupData.length > 0 &&
                    meetupData.slice(0,7).map((item, index) => (
                      <div>
                        <div
                          className="event-card as-link"
                          onClick={() => {
                            const searchParams = new URLSearchParams();
                            searchParams.append('id', item.id);

                            if (getItem('id')) {
                              if (!item.upcoming) {
                                joinTheSession(item.id);
                              } else {
                                const url = `/${
                                  urlParts[0] + '/' + urlParts[1]
                                }/meetup/invite?${searchParams.toString()}`;
                               
                                AddUserInCompany(url,item);
                              }
                            } else {
                              setDataItem(item)
                              const url = `/${
                                urlParts[0] + '/' + urlParts[1]
                              }/meetup/invite?${searchParams.toString()}`;
                              setShowLogin(url);
                            }
                          }}>
                          {moment(
                            item.start_time,
                            'YYYY-MM-DD HH:mm:ss',
                            true,
                          ).isValid() && (
                            <div className="event-date">
                              {' '}
                              {moment(item.start_time).format(
                                'ddd, MMM D @ h:mm A',
                              )}
                            </div>
                          )}

                          <h1> {item.session_title}</h1>
                          <div className="event-slider-image ">
                            <img
                              src={
                                item.image?.slider_images
                                  ? item.image.slider_images
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>

                          {/* <div className="event-type">
                            {' '}
                            <img
                              className="d-inline mr-1"
                              src={profileIcon}
                              alt=""
                            />
                            165 Networking and Referrals
                          </div> */}

                          <div className="event-location">
                            {item.complete_address &&
                              item.complete_address !== '' && (
                                <span>
                                  <i
                                    style={{
                                      color: '#9B9787',
                                      marginRight: '5px',
                                    }}
                                    className="fas fa-location-dot"></i>
                                  {item.complete_address}
                                </span>
                              )}

                            {
                              item?.max_cost >= 1 &&
                              
                              item?.min_cost >= 1  && (
                                <span>
                                  {' '}
                                  {item.max_cost === item.min_cost &&
                                    item.max_cost !== 0 &&
                                    '$' + item.max_cost}
                                  {item.max_cost !== item.min_cost &&
                                    item.max_cost !== 0 &&
                                    `$${item.min_cost} - $${item.max_cost}`}
                                </span>
                               )} 
                          </div>

                          {item.min_cost >= 1 && (
                            <div className="card-button">
                              <Button
                                className="new-btn btn-green"
                                onClick={() => {}}>
                                Purchase Ticket
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
              {meetupData.length > 7 && 
              <div className="show-more-btn" onClick={()=>{
                       setShowModal('sessionList');
                       setDataofSession(meetupData)
                      }}>show more</div>}
            </>
          ) : (
            <div className="profile_page_meetup_recruitement d-flex align-items-center justify-content-center ">
              {labels.no_record_found[selected_lang]}
            </div>
          )}
        </div>

        {/* <div className="search-tabs-wrapper">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="event-tabs tabs-style-2 mb-0">
              <ul>
                <li>
                  <Button
                    className={mainTab === 'Sessions' ? 'active' : ''}
                    onClick={() => {
                      setGalleryType('happening');
                      setIsEvent(false);
                      setSearchQry('');
                      setMainTab('Sessions');
                      getApiSessionList('happening', '', false, 1, isCheck);
                      setMapButton(false);
                    }}>
                    {labels.sessions_tab_text[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={mainTab === 'Events' ? 'active' : ''}
                    onClick={() => {
                      setGalleryType('happening');
                      setIsEvent(true);
                      setSearchQry('');
                      setMainTab('Events');
                      getApiSessionList('happening', '', true, 1, isCheck);
                    }}>
                    {labels.events_tab_text[selected_lang]}
                  </Button>
                </li>
              </ul>
            </div>

            <div className="search-tabs-icons">
              <span
                onClick={() => {
                  if (sessionType === 'happening') {
                    setGalleryType('upcoming');
                    setSearchQry('');
                    getApiSessionList('upcoming', '', isEvent, 1, isCheck);
                  } else {
                    setGalleryType('happening');
                    setSearchQry('');
                    getApiSessionList('happening', '', isEvent, 1, isCheck);
                  }
                }}
                className={sessionType === 'upcoming' ? 'icon active' : 'icon'}>
                <i className="fa-solid fa-calendar-days"></i>
              </span>

              <span
                onClick={() => {
                  setThreeDots(!threeDots);
                }}
                className={threeDots ? 'icon active' : 'icon'}>
                <i className="fas fa-ellipsis-vertical" />
              </span>
            </div>
          </div>

          {threeDots && (
            <div className="search-form d-flex">
              <Form.Group className="search-input">
                <Form.Control
                  onChange={e => {
                    getApiSessionList(
                      sessionType,
                      e.target.value,
                      isEvent,
                      1,
                      isCheck,
                    );
                    setSearchQry(e.target.value);
                  }}
                  value={searchQry}
                  type="text"
                  placeholder={
                    isEvent === true
                      ? 'Search for an event'
                      : 'Search for a session'
                  }
                />
              </Form.Group>
              {mainTab === 'Events' && (
                <Button
                  style={{
                    color: mapButton ? '#A4641A' : '',
                  }}
                  onClick={() => {
                    // handClick();
                    // getLocation(lug,latitute);
                    setPlusButton(true);
                    setMapButton(!mapButton);
                    setShowComponent('');
                  }}>
                  <i className="icon-map-pin" />
                </Button>
              )}
              <Button>
                <i
                  className="icon-filter-icon"
                  onClick={() => setSearchFilterModal(true)}
                />
              </Button>
            </div>
          )}
        </div> */}

        {/* {mainTab === 'Sessions' && (
          <>
            <Scrollbars className="sessions-widget-scroll">
              <div style={{padding: '7px 10px'}}>
                {Object.entries(sessionData.session).length > 0
                  ? sessionData.session.map(obj => {
                      const item = makeSessionItem(obj);
                      const heartClass = item.like ? 'fas' : 'far';
                      return (
                        <div
                          className="opportunity-item session-item card as-link"
                          key={`session-item-${item.id}`}
                          onClick={() => {
                            const searchParams = new URLSearchParams();
                            searchParams.append('id', item.id);

                            if (getItem('id')) {
                             
                              if (sessionType === 'happening') {
                                joinTheSession(item.id);
                              } else {
                              
                                const url = `/${
                                  urlParts[0]
                                }/session/invite?${searchParams.toString()}`;
                                history.push(url);
                              }
                            } else {
                              
                              const url = `/${
                                urlParts[0]
                              }/session/invite?${searchParams.toString()}`;
                              setShowLogin(url);
                            }
                          }}>
                          <div className="session-heading">
                            <h2>{item.title}</h2>
                            <span className="users-text">
                              <i className="fas fa-circle-user" /> {item.users}
                            </span>
                            <span className="category-text">
                              {item.category}
                            </span>
                          </div>

                          <div className="persons-img-list">
                            {Object.entries(item.persons).length > 0 &&
                              item.persons.map(person =>
                                _renderUserImg(person),
                              )}
                          </div>

                          <div className="persons-name-list">
                            <ul>
                              {Object.entries(item.persons).length > 0 &&
                                item.persons.map((person, index) => (
                                  <li key={`person-name-${person.id}`}>
                                    {person.owner && (
                                      <span className="owner-icon">
                                        <i className="fas fa-crown" />
                                      </span>
                                    )}
                                    {!person.owner && person.speaker && (
                                      <span className="speaker-icon">
                                        <i className="fas fa-microphone" />
                                      </span>
                                    )}
                                    
                                    {fixName(
                                      person.name.split(' ')[0],
                                      person.name.split(' ')[1],
                                    )}
                                  </li>
                                ))}
                            </ul>
                          </div>

                          
                          <div className="oppo-address mt-3">
                            {item.address && (
                              <span className="address">
                                <i className="icon-map-pin" /> {item.address}
                              </span>
                            )}
                            {item.price && (
                              <span className="event-amount">
                                ${item.price ? item.price : 0}
                              </span>
                            )}
                           
                          </div>
                         
                        </div>
                      );
                    })
                  : labels.no_record_found[selected_lang]}
              </div>
            </Scrollbars>
          </>
        )} */}

        {/* {mainTab === 'Events' && Object.entries(eventItems).length > 0 && (
          <>
            <Scrollbars className="sessions-widget-scroll">
              <div style={{padding: '7px 10px'}}>
                {Object.entries(eventData.session).length > 0
                  ? eventData.session.map((item, index) => (
                      <EventsItem
                        key={`event-item-${index}`}
                        item={makeEventItem(item)}
                        setShowLogin={setShowLogin}
                      />
                    ))
                  : labels.no_record_found[selected_lang]}
              </div>
            </Scrollbars>
          </>
        )} */}

        {searchFilter && (
          <SessionSearchFilter
            show={searchFilter}
            onClose={() => setSearchFilterModal(false)}
            sessionType={sessionType}
            setGalleryType={setGalleryType}
            // isEvent={isEvent}
            isCheckAll={isCheckAll}
            setIsCheckAll={setIsCheckAll}
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            //Temporary State for remove warning
            isEvent={isEvent}
          />
        )}

        {showLogin && (
          <CustomModal
            // position="bottom"
            size="small"
            onClose={() => setShowLogin(null)}>
            <div className="leave-session-content">
              <h3>Login</h3>
              <p>For more information please login.</p>

              <div className="text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    // history.push('/login/session_invite/' + showLogin);
                    // history.push(showLogin);
                    AddUserInCompany(showLogin,dataItem)
                    setShowLogin(null);
                  }}>
                  Login
                </Button>
                <Button
                  className="btn-dark"
                  onClick={() => {
                    setShowLogin(null);
                  }}>
                  No
                </Button>
              </div>
            </div>
          </CustomModal>
        )}
      </div>
    </div>
  );
};

export default RenderSessions;
