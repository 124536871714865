"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * To share a file during a conference, the [FilePresentationService](/developers/interactivity-apis/client-sdk/reference-javascript/filepresentationservice)
 * converts the user-provided file into multiple pages (images). The FileConverted model represents the converted files.
 * It includes the file [ID](#id), [name](#optional-name), [size](#optional-size), [type](#optional-type), [URL](#optional-url),
 * [number of images](#imagecount) inside the file, and the [ID of the file owner](#optional-owner).
 */
class FileConverted {
    constructor(id, imageCount) {
        this.id = id;
        this.imageCount = imageCount;
    }
}
exports.default = FileConverted;
