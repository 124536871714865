export const sessionListing = (state = {session: [], upcoming: []}, action) => {
  let sessions;
  let upcomings;
  switch (action.type) {
    case 'GET_DATA':
      return {
        session: action.payload.meetup,
        // upcoming: action.payload.meetup,
        payload: action.prevPayload,
      };
    case 'GET_DATA_PAGE':
      return {
        session: [...state.session, ...action.payload.meetup],
        // upcoming: state.meetup,
        payload: state.payload,
      };
    case 'MANAGE_FAVOURITE':
      sessions = state.session.map(ses => {
        if (ses.id === action.payload)
          return {...ses, favourite: !ses.favourite};
        return ses;
      });
      upcomings = state.session.map(up => {
        if (up.id === action.payload) return {...up, favourite: !up.favourite};
        return up;
      });
      return {
        session: sessions,
        upcoming: upcomings,
        payload: state.payload,
      };
    default:
      return state;
  }
};

export const sessionCategory = (state = [], action) => {
  switch (action.type) {
    case 'GET_DATA_CAT':
      return action.payload;
    default:
      return state;
  }
};

export const builderList = (state = [], action) => {
  switch (action.type) {
    case 'BUILDER/GET_DATA':
      return action.payload;
    default:
      return state;
  }
};

export const AllBuilderList = (state = [], action) => {
  switch (action.type) {
    case 'BUILDER/GET_DATA':
      return action.payload;
    default:
      return state;
  }
};

export const AllWatchesList = (state = [], action) => {
  switch (action.type) {
    case 'WATCHES/GET_DATA':
      return action.payload;
    default:
      return state;
  }
};

export const AllActionsList = (state = [], action) => {
  switch (action.type) {
    case 'ACTIONS/GET_DATA':
      return action.payload;
    default:
      return state;
  }
};

export const userSessionListing = (
  state = {session: [], upcoming: []},
  action,
) => {
  switch (action.type) {
    case 'GET_USER_SESSION_LIST':
      return {
        session: action.payload.session,
        upcoming: action.payload.upcoming,
      };
    // case 'GET_USER_SESSION_LIST':
    //   return {
    //     session: [...state.session, ...action.payload.session],
    //     upcoming: state.upcoming,
    //   };
    default:
      return state;
  }
};

export const userEventListing = (
  state = {session: [], upcoming: []},
  action,
) => {
  switch (action.type) {
    case 'GET_USER_EVENT_LIST':
      return {
        session: action.payload.session,
        upcoming: action.payload.upcoming,
      };
    default:
      return state;
  }
};

export const companyMeetupListing = (
  state = [],
  action,
) => {
  switch (action.type) {
    case 'GET_COMPANY_MEETUP_LIST':
      return action.payload;
    default:
      return state;
  }
};
export const hitWorkspace = (
  state = {data: []},
  action,
) => {
  switch (action.type) {
    case 'List_Workspace':
      return {
        data: action.payload.data,
        
      };
    default:
      return state;
  }
};
export const hitAssociateWorkSpace = (
  state = {data: []},
  action,
) => {
  switch (action.type) {
    case 'List_Associate_Workspaces':
      return {
        data: action.payload.data,
        personal: action.payload.personal,
        
      };
    default:
      return state;
  }
};
