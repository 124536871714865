import React,{useState} from 'react';
import {fixName, formatAmount} from '../../Utils/commonUtils';
import {UserGroupMini} from '../../Constants/Images';
import {DEFAULT_IMAGE} from '../../Utils/env';

const SessionBusinessProfile = props => {
  const {item} = props;
  let totalMem = item.total_members + 1;
  let totalMode = item.total_moderators + 1;
  const [marketToggle, setMarketToggle] = useState(item.show_in_market_b);
  //   const history = useHistory();
  //   const [favourite, setFavourite] = useState(props.item.favourite);
  // //   const newFavourite = useSelector(state => state.favouriteSession);
  //   const heartClass = favourite === true ? 'fas' : 'far';

  // useEffect(() => {
  //   item.favourite = favourite;
  // }, [favourite]);

  const _renderCreatorImg = person => {
    return (
      <div className="img-item">
        {
          <span className="owner-icon">
            <i className="fas fa-crown" />
          </span>
        }
        {/* {!person.owner && person.isOnline && <span className="online-icon"></span>} */}
        {/* {person.speaker && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        <img
          src={person.creator_img === '' ? DEFAULT_IMAGE : person.creator_img}
          alt=""
        />
      </div>
    );
  };
  const _renderAudiImg = (person, speaking) => {
    return (
      <div key={`p-id-${person.id}`} className="img-item">
        {/* {!person.owner && person.isOnline && <span className="online-icon"></span>} */}
        {/* {person.speaker && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        {/* {speaking && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };

  return (
    <div className="session-item as-link card" key={props.key}>
      <div
        className="session-heading"
        // onClick={() => {
        //   if (
        //     props.serverTime &&
        //     new Date(props.serverTime) < new Date(item.start_time)
        //   ) {
        //     props.componentDecesion(item, 'session');
        //     //
        //   } else {
        //     joinTheSession();

        //   }
        // }}
      >
        <h2>{item.session_title}</h2>
        <div className="events-place-like align-items-start">
          <div className="w-100">
            <span className="users-text usercount-type">
              <img src={UserGroupMini} height={16} width={16} alt="" />
              <span className="userCount">{`${totalMem}/${totalMode}`}</span>
              <span className="category-text">{item.category_name}</span>
            </span>
          </div>
          {/* {props.heart === 'upcoming' && (
            <div className="session-share-favs">
              <span
                className="event-heart as-link"
                onClick={e => {
                  e.stopPropagation();
                  props.componentDecesion(item, 'Refer');
                }}
                >
                <i className="fas fa-share" />
              </span>
            </div>
          )} */}
        </div>
      </div>
      <div
        className="persons-img-list"
        // onClick={() => {
        //   if (
        //     props.serverTime &&
        //     new Date(props.serverTime) < new Date(item.start_time)
        //   ) {
        //     props.componentDecesion(item, 'session');
        //     //
        //   } else {
        //     joinTheSession();
        //     //
        //   }
        // }}
      >
        {/* {Object.entries(item.persons).length > 0 && item.persons.map((person, index) => _renderUserImg(person))} */}
        {_renderCreatorImg(item)}
        {!(item.moderator_ids === '') &&
          item.moderators_list &&
          item.moderators_list.length > 0 &&
          Object.entries(item.moderators_list).length > 0 &&
          item.moderators_list.slice(0, 4).map(person => {
            // if (index < 7) {
            return _renderAudiImg(person, true);
            // }
          })}
        {!(item.audience_ids === '') &&
          item.audience_list &&
          item.audience_list.length > 0 &&
          Object.entries(item.audience_list).length > 0 &&
          item.audience_list.slice(0, 3).map(person => {
            // if (index < 7) {
            return _renderAudiImg(person);
            // }
          })}
      </div>

      <div
        className="persons-name-list"
        // onClick={() => {
        //   if (
        //     props.serverTime &&
        //     new Date(props.serverTime) < new Date(item.start_time)
        //   ) {
        //     props.componentDecesion(item, 'session');
        //     //
        //   } else {
        //     joinTheSession();
        //     //
        //   }
        // }}
      >
        <ul>
          <li key={`person-name-creator`}>
            <span className="owner-icon">
              <i className="fas fa-crown" />
            </span>
            {fixName(item.creator_first_name, item.creator_last_name)}
          </li>
          {!(item.moderator_ids === '') &&
            item.moderators_list &&
            item.moderators_list.length > 0 &&
            Object.entries(item.moderators_list).length > 0 &&
            item.moderators_list.slice(0, 4).map(person => (
              <li key={`person-mod-${person.id}`}>
                {fixName(person.first_name, person.last_name)}
                <span className="speaker-icon">
                  <i className="fas fa-microphone" />
                </span>
              </li>
            ))}
          {!(item.audience_ids === '') &&
            item.audience_list &&
            item.audience_list.length > 0 &&
            Object.entries(item.audience_list).length > 0 &&
            item.audience_list.slice(0, 3).map(person => (
              // index < 5 &&
              <li key={`person-aud-${person.id}`}>
                {/* {person.owner && (
                                <span className="owner-icon">
                                    <i className="fas fa-crown" />
                                </span>
                            )}
                            {!person.owner && person.speaker && (
                                <span className="speaker-icon">
                                    <i className="fas fa-microphone" />
                                </span>
                            )} */}
                {fixName(person.first_name, person.last_name)}
              </li>
            ))}
        </ul>
      </div>
      {item.price ||
        (item.address && (
          <div className="events-place-like session-address-wrapper mt-2">
            {item.address && (
              <span
                className="address as-link"
                // onClick={() => {
                //   if (
                //     props.serverTime &&
                //     new Date(props.serverTime) < new Date(item.start_time)
                //   ) {
                //     props.componentDecesion(item, 'session');
                //     //
                //   } else {
                //     joinTheSession();
                //     //
                //   }
                // }}
              >
                <i className="fas fa-location-dot"></i> {item.address}
              </span>
            )}

            {item.price && (
              <span>{formatAmount(item.price ? item.price : '')}</span>
            )}

            {/* {item.upcoming  && (
          <div className="session-share-favs">
            <span
              className="event-heart as-link"
              onClick={() => {
                props.componentDecesion(item, 'Refer');
              }}
              >
              <i className="fas fa-share" />
            </span>
            <span
              className="event-heart as-link"
              onClick={() => {
                favouriteOpportunity();
              }}>
              <i className={`${heartClass} fa-heart`} />
            </span>
          </div>
        )} */}
          </div>
        ))}
      {props.PageDesign('meetups', item,marketToggle,setMarketToggle)}
    </div>
  );
};

export default SessionBusinessProfile;
