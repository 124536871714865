import React, {useState} from 'react';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Sidebar from '../../Pages/Sidebar';
import {CustomSlidingButtons, Pannels} from '../../Components';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import {
  ShareablesBlackIcon,
  // rewardStar,
} from '../../Constants/Images';
import DoubleCam from '../../Svg/DoubleCam';

export default function MyNotes(props) {
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('my_notes');
  // const [analycticsSlider, setAnalycticsSlider] = useState(false);

 



  const dynamicStyle = {
    padding: '0px',
  };


  // const rewardSlider = {
  //   // className: "center",
  //   // centerMode: true,
  //   dots: false,
  //   arrows: false,
  //   infinite: false,
  //   // centerPadding : "20px",
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   // adaptiveHeight: true,
  // };
  // const rewardSliderData = [
  //   {
  //     name: 'Task Completion',
  //     money: '$2.4k',
  //     time: 'versus last month',
  //     svgIcon: rewardStar,
  //   },

  //   {
  //     name: 'Ratings',
  //     money: '85%',
  //     time: 'versus last month',
  //     svgIcon: rewardStar,
  //     // icon: 'fa-solid fa-star',
  //   },

  //   {
  //     name: 'Close Time',
  //     money: '45%',
  //     time: 'versus last month',
  //     svgIcon: rewardStar,
  //   },
  //   {
  //     name: 'Rating',
  //     money: '4.8',
  //     time: 'versus last month',
  //     svgIcon: rewardStar,
  //   },
  // ];


  const listForDropDown = [
    {title: 'Recent', id: '1'},
    {title: 'Customers', id: '2'},
    {title: 'Prospects', id: '3'},
    {title: 'Leads', id: '4'},
    {title: 'Team', id: '5'},
    {title: 'Colleagues', id: '6'},
    {title: 'Other contact types', id: '7'},
  ];

  const dropDownSelection = e => {
    
    const {id} = e.target;

    setAreas_Filter(id);
    // setdropdownValue([innerText]);
  };
  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);
  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
        showComponent={showComponent}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          {/* <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span
                            style={{
                              color: '#000',

                              fontFamily: 'Inter',
                              // fontSize: '10.833px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                            }}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              color: '#000',

                              fontFamily: 'Inter',
                              fontSize: '17px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: 'normal',
                            }}>
                            {item.money}
                            {item.name === 'Rating' && (
                              <img src={rewardStar} alt="" />
                            )}
                            <i
                              class="fa-solid fa-arrow-up"
                              style={{color: '#52bd41'}}></i>
                          </span>
                          <div
                            class="message-date-info"
                            style={
                              {
                                // marginLeft: '13px',
                              }
                            }>
                            <span
                              class="message-date"
                              style={{
                                color: '#333',
                                fontFamily: 'Helvetica',
                                // fontSize: '9px',
                                fontStyle: 'normal',
                                fontWeight: '300',
                                lineHeight: 'normal',
                              }}>
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div> */}
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            customCss={dynamicStyle}
            primary={
              <>
                <div className="agreements-proposals-page primary_panel_wraper primary_panel_content">
                  <div className="messages-header mb-2">
                    <div className="event-tabs tabs-style-2 mb-2">
                      {' '}
                      <ul>
                        <li>
                          <Button
                            className={tabType === 'my_notes' ? 'active' : ''}
                            onClick={() => {
                              setTabType('my_notes');
                            }}>
                            My Notes
                          </Button>
                        </li>

                        <li>
                          <Button
                            className={tabType === 'shared' ? 'active' : ''}
                            onClick={() => {
                              setTabType('shared');
                            }}>
                            Shared
                          </Button>
                        </li>
                      </ul>
                    </div>

                    <div className="search-tabs-icons">
                      {/* <span
                            className={
                              // currentComponent === 'create-form'
                              //   ? 'icon active'
                              // :
                              'icon'
                            }>
                            <i
                              className="icon-plus"
                              // onClick={() => setCurrentComponent('create-form')}
                            />
                          </span> */}
                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={
                          threeDots ? 'icon active threeDots' : 'icon threeDots'
                        }>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>

                  {threeDots && (
                    <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                      <div className="search-form d-flex">
                        <Form.Group className="search-input">
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            // value={chatSearchQry}
                            // onChange={e => {
                            //   setChatSearchQry(e.target.value);
                            // }}
                          />
                        </Form.Group>
                        <Button>
                          <i
                            className="icon-filter-icon"
                            // onClick={() => setSearchFilterModal(true)}
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                    <CustomSlidingButtons
                      items={listForDropDown}
                      activeButtons={areas_Filter}
                      onItemClick={e => {
                        dropDownSelection(e);
                      }}
                      showItems={2}
                    />
                  </div>

                  {/* <div
                    style={{
                      borderRadius: '5px',
                      background: '#F5F9F0',
                      width: '380px',
                      height: '66px',
                      marginBottom: '14px',
                    }}>
                    <h1
                      style={{
                        color: '#262626',
                        fontfamily: 'Inter',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        paddingTop: '8px',
                        marginLeft: '13px',
                      }}>
                      {' '}
                      Next Payment
                    </h1>
                    <div className="d-flex justify-content-between mt-1">
                      <span
                        style={{
                          marginLeft: '13px',
                          color: '#262626',
                          fontfamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '24px',
                        }}>
                        Friday, March 3, 2024
                      </span>
                      <span
                        style={{
                          marginRight: '10px',
                          color: '#262626',
                          fontfamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '24px',
                        }}>
                        $2,450
                      </span>
                    </div>
                  </div> */}

                  <div className="refferal-list-wrapper">
                    <Scrollbars autoHide>
                      <div className="agreements-proposals-content">
                        <div className="agreement-card-main">
                          <div className="agrement-card">
                            <h1>Person or Company Name</h1>
                            <span
                              style={{
                                color: '#262626',
                                fontfamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '18.15px',
                              }}>
                              Note
                            </span>
                          </div>

                          <div
                            className="agreement-card-bottom"
                            style={{height: '55px', background: '#F9F8F5'}}>
                            <span
                              style={{
                                color: '#262626',

                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                              }}>
                              {' '}
                              10/05/24 5:42 PM
                            </span>

                            <div>
                              <span
                                style={{
                                  filter: 'brightness(4)',
                                }}>
                                <img
                                  className="sharableYellow as-link"
                                  src={ShareablesBlackIcon}
                                  alt="shareable"
                                  // onClick={() => {

                                  // }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="agreements-proposals-content">
                        <div className="agreement-card-main">
                          <div className="agrement-card">
                            <h1>Person or Company Name</h1>
                            <span
                              style={{
                                color: '#262626',
                                fontfamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '18.15px',
                              }}>
                              Note
                            </span>
                          </div>

                          <div className="agreement-card-bottom" style={{height: '55px', background: '#F9F8F5',  alignItems: 'center'}}>
    <div>
        <span style={{color: '#262626', fontFamily: 'Inter', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'}}>
            {' '}
            10/05/24 5:42 PM - Shonda S.
        </span>
    </div>

    <div style={{flex: '1', display: 'flex', justifyContent: 'center'}}>
        <span>
            <DoubleCam fill={'#989898'} />
        </span>
    </div>

    <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
        <span style={{filter: 'brightness(4)'}}>
            <span>
                <img className="sharableYellow as-link" src={ShareablesBlackIcon} alt="shareable" />
            </span>
        </span>
    </div>
</div>

                        </div>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </>
            }
            secondary={<></>}
          />
          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
