module.exports = {
    // when user click on leave, close the connection and redirect to this page
    // leaveURI: '//www.voxeet.com/web-thank-you',
    leaveURI: '//www.voxeet.com/web-thank-you',
    wsSessionURI: __WS_HOST__,
    dolbyVoice: __DOLBYVOICE__,
    ackTimeout: 30000,
    keepAlive: 30000,
    // used to instanciate a new RTCPeerConnection
    peerConnection: {
        // RTCConfiguration - https://developer.mozilla.org/en-US/docs/Web/API/RTCConfiguration
        config: {
            bundlePolicy: 'max-bundle',
            iceServers: [
                {
                    // provided freely by Google - https://code.google.com/p/natvpn/source/browse/trunk/stun_server_list
                    urls: 'stun:stun.l.google.com:19302',
                },
            ],
        },
        constraints: {
            mandatory: {},
            optional: [
                // to make Chrome and Firefox interoperate
                { DtlsSrtpKeyAgreement: true },
            ],
        },
    },
    // used by getUserMedia
    media: {
        constraints: {
            audio: {
                autoGainControl: false,
                googAutoGainControl: false,
            },
            video: {},
        },
        mobile: {
            constraints: {
                audio: {
                    autoGainControl: false,
                    googAutoGainControl: false,
                },
                video: {},
            },
        },
    },
    audioSettings: {
        chromeCutOffVolume: 0.06,
        distanceModel: 'inverse',
        refDistance: 0.3,
        maxDistance: 15,
        rollOffFactor: 4,
        gain: 1.0,
    },
    dialInTexts: {
        title: 'Bad connection?',
        description: "It looks like you have a low quality IP connection. We'd suggest rejoining this conference from your phone – simply dial in using the Voxeet dial-in number and PIN below.\nTo find an alternative local number, verify your current location.",
        phone_label: 'Your dial-in number:',
        pin_label: 'Your PIN:',
        qr_label: 'You can also scan the QR code with your phone to autodial.',
    },
    // don't attempt to connect to servers, used to debug only UI things
    offLine: false,
    videoSettings: {
        desktop: [
            {
                range: { min: 0, max: 384 },
                resolution: 240,
                fps: 20,
                dimensions: { width: 426, height: 240 },
            },
            {
                range: { min: 384, max: 900 },
                resolution: 360,
                fps: 25,
                dimensions: { width: 640, height: 360 },
            },
            {
                range: { min: 900, max: Infinity },
                resolution: 720,
                fps: 25,
                dimensions: { width: 1280, height: 720 },
            },
        ],
        mobile: [
            {
                range: { min: 0, max: 384 },
                resolution: 240,
                fps: 20,
                dimensions: { width: 426, height: 240 },
            },
            {
                range: { min: 384, max: 900 },
                resolution: 360,
                fps: 25,
                dimensions: { width: 640, height: 360 },
            },
            {
                range: { min: 900, max: Infinity },
                resolution: 480,
                fps: 25,
                dimensions: { width: 854, height: 480 },
            },
        ],
    },
    pluginDefs: {
        voxeetPlugin: {
            name: 'Voxeet Plugin',
            mimeType: 'application/x-voxeetplugin',
            activeXName: 'VoxeetInc.VoxeetPlugin',
            guid: '987ae2fb-3c9c-5787-bf68-b66d84219b95',
            version: '1.1.0.0',
            installURL: {
                win: 'http://app.voxeet.com/plugins/VoxeetPluginV1.1.exe',
                mac: 'http://app.voxeet.com/plugins/VoxeetPluginV1.1.dmg',
            },
        },
    },
};
