import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import CustomModal from '../CustomModal';
import {errorMessage} from '../../Utils/ToastMessages';
import {
  addEventToCalendar,
  getAccessTokenFromRefreshToken,
  getGoogleLatestToken,
} from '../../Utils/commonUtils';
import commonApi from '../../Services/CommonService';
import {
  addEventToOutlookCalendar,
  getOutlookRefreshAccessToken,
} from '../../Utils/OutlookUtils';
import CryptoJS from 'crypto-js';
import {ENCRYPTION_KEY} from '../../Utils/env';
import {
  getCalendarsByType,
  getLatestTokenForEmail,
  isTokenAndEmailUnique,
} from '../../Utils/commonUtils';
import {successMessage} from '../../Utils/ToastMessages';
import axios from 'axios';
import LoaderSpinner from './LoaderSpinner';
import { labels } from '../../Constants/Translations';
import { selected_lang } from '../../Utils/env';
// import {async} from 'rxjs';

const CalanderModel = props => {
  const [modelId, setModelId] = useState([]);
  const [modelIdItem, setModelItem] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  let EventId = [];
  let googleItems = [];

  const hanldeCalenderlist = item => {
    if (!modelId.includes(item.id)) {
      setModelId(prev => [...prev, item.id]);
      setModelItem(prev => [...prev, item]);
    } else {
      setModelId(previous => previous.filter(id => id !== item.id));
      setModelItem(previous =>
        previous.filter(miniItem => miniItem.id !== item.id),
      );
    }
  };

  // const getRefreshToken = async (googleCalendars) => {
  //   var payload = {
  //     type: 'google',
  //   };
  //   commonApi.get_refresh_token(payload).then(response => {
  //     for (const item of googleCalendars) {
  //       const {email} = item;

  //       // if (email) {
  //         // Find the matching token for the current calendar email

  //         const matchingToken = response.refresh_token.find(
  //           token => token.email === email,
  //         );

  //         let filterToken ;
  //         let token;
  //         if(!matchingToken){
  //            filterToken = getGoogleLatestToken(response.refresh_token)
  //         }

  //         token = matchingToken.refresh_token ? matchingToken : filterToken

  //         if (token && token.refresh_token) {
  //           const accessToken = getAccessTokenFromRefreshToken(
  //             token.refresh_token,
  //             item.google_calendar_id,
  //           );

  //           console.log(accessToken,'------->accessToken')
  //           // You can use the accessToken as needed
  //         } else {
  //         }
  //       }
  //     // }
  //     // getAccessTokenFromRefreshToken(response.refresh_token[0].refresh_token);
  //   });
  // };

  //  const getAccessTokenFromRefreshToken = async refreshToken => {
  //   const decrypted = CryptoJS.AES.decrypt(refreshToken, ENCRYPTION_KEY).toString(
  //     CryptoJS.enc.Utf8,
  //   );

  //   const requestBody = {
  //     grant_type: 'refresh_token',
  //     client_id:
  //       '556170493308-2o3iobkftj6d5ppk7uh1vtc8c3fn84g4.apps.googleusercontent.com',
  //     client_secret: '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',
  //     refresh_token: decrypted,
  //   };

  //   try {
  //     const response = await axios.post(
  //       'https://oauth2.googleapis.com/token',
  //       requestBody,
  //     );
  //     return response.data;
  //   } catch (error) {
  //     return error;
  //   }
  // };

  const getRefreshToken = async googleCalendars => {
    const payload = {
      type: 'google',
    };

    try {
      // Fetch the refresh tokens
      const response = await commonApi.get_refresh_token(payload);

      // Process each calendar item
      for (const item of googleCalendars) {
        const {email} = item;

        // if (email) {
        try {
          // Find the matching token for the current calendar email
          const matchingToken = response.refresh_token.find(
            token => token.email === email,
          );

          let filterToken;
          let token;
          if (!matchingToken) {
            // Get the latest token if no matching token found
            filterToken = getGoogleLatestToken(response.refresh_token);
          }

          // Decide which token to use
          token = matchingToken ? matchingToken : filterToken;

          if (token && token.refresh_token) {
            try {
              // Get the access token from the refresh token
              const response = await getAccessTokenFromRefreshToken(
                token.refresh_token,
                item.google_calendar_id,
              );
              const eventData = props.event;
              const res = await addEventToCalendar(
                response.access_token,
                item.google_calendar_id,
                eventData,
              );
              console.log(res, '--------google res');
              googleItems.push({
                calendar_id: item.google_calendar_id,
                calendar_event_id: res.data.id,
                type: 'google',
              });

              if (googleItems.length === googleCalendars.length) {
                add_calendar_ids_in_confer_appoint(googleItems);
              }
              // console.log(accessToken, '------->accessToken');
              // You can use the accessToken as needed
            } catch (accessTokenError) {
              console.error(
                'Error getting access token:',
                accessTokenError.message,
              );
              // Handle errors related to getting the access token
            }
          } else {
            console.warn('No valid token found for email:', email);
          }
        } catch (tokenProcessingError) {
          console.error(
            'Error processing token for email:',
            email,
            tokenProcessingError.message,
          );
        }
        // }
      }
    } catch (refreshTokenError) {
      console.error(
        'Error fetching refresh tokens:',
        refreshTokenError.message,
      );
      // Handle errors related to fetching refresh tokens
    }
  };

  // const getOutlookRefreshToken = async outlookCalendars => {
  //   var payload = {
  //     type: 'outlook',
  //   };

  //   let outlookEmails = [];
  //   let emailWithToken = [];
  //   commonApi.get_refresh_token(payload).then(async response => {
  //     for (const item of outlookCalendars) {
  //       const {email} = item;

  //       if (email) {
  //         // const matchingToken = response.refresh_token.find(
  //         //   token => token.email === email,
  //         // );

  //         const matchingToken = getLatestTokenForEmail(
  //           response.refresh_token,
  //           email,
  //         );
  //         console.log(matchingToken, 'matchingTokenmatchingToken');

  //         if (matchingToken && matchingToken.refresh_token) {
  //           var tokenData;
  //           if (!outlookEmails.includes(email)) {
  //             tokenData = await getOutlookRefreshAccessToken(
  //               matchingToken.refresh_token,
  //               item.google_calendar_id,
  //             );
  //             if (tokenData.access_token) {
  //               outlookEmails.push(email);
  //               emailWithToken.push({
  //                 email: email,
  //                 accessToken: tokenData.access_token,
  //               });
  //             }
  //             console.log(tokenData, '--------------->accessTokenaccessToken');
  //             const newToken = isTokenAndEmailUnique(
  //               response.refresh_token,
  //               matchingToken.email,
  //               tokenData.refresh_token,
  //             );
  //             const ciphertext = CryptoJS.AES.encrypt(
  //               tokenData.refresh_token,
  //               ENCRYPTION_KEY,
  //             ).toString();

  //             console.log(newToken, '-------->newToken');
  //             if (newToken) {
  //               const body = {
  //                 email: matchingToken.email,
  //                 type: 'outlook',
  //                 refresh_token: ciphertext,
  //               };
  //               await commonApi.add_refresh_token(body);
  //             }
  //           }

  //           const filteredToken = emailWithToken.filter(
  //             item => item.email === email,
  //           );

  //           const accessToken = tokenData.access_token
  //             ? tokenData.access_token
  //             : filteredToken[0].accessToken;

  //           const eventData = props.event;
  //           const res = await addEventToOutlookCalendar(
  //             accessToken,
  //             item.google_calendar_id,
  //             eventData,
  //           );
  //           EventId.push({
  //             calendar_id: item.google_calendar_id,
  //             calendar_event_id: res.id,
  //             type: 'outlook',
  //           });

  //           if (EventId.length === outlookCalendars.length) {
  //             add_calendar_ids_in_confer_appoint(EventId);
  //           }

  //           console.log(res, '----->resresfrom ');

  //           // You can use the accessToken as needed
  //         } else {
  //         }
  //       }
  //     }
  //   });
  // };

  //

  // const getRefreshToken = async () => {
  //   try {
  //     const response = await commonApi.get_refresh_token();

  //     // Create an array of promises to fetch access tokens
  //     const accessTokensPromises = modelIdItem.map(async (item) => {
  //       const { email } = item;
  //       if (email) {
  //         const matchingToken = response.refresh_token.find((token) => token.email === email);
  //         if (matchingToken && matchingToken.refresh_token) {
  //           const accessToken = await getAccessTokenFromRefreshToken(matchingToken.refresh_token, item.google_calendar_id);
  //
  //         } else {
  //
  //         }
  //       }
  //     });

  //     // Wait for all access token promises to resolve
  //     await Promise.all(accessTokensPromises);

  //     // After all access tokens are fetched, call add_google_calendars_ids_in_confer_appoint
  //     await add_google_calendars_ids_in_confer_appoint();
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  // Rest of your code remains the same


  const getOutlookRefreshToken = async (outlookCalendars) => {
    const payload = {
      type: 'outlook',
    };
  
    let outlookEmails = [];
    let emailWithToken = [];
  
    try {
     
      const response = await commonApi.get_refresh_token(payload);
  
      for (const item of outlookCalendars) {
        try {
          const { email } = item;
  
          if (email) {
            const matchingToken = getLatestTokenForEmail(response.refresh_token, email);
            console.log(matchingToken, 'matchingTokenmatchingToken');
  
            if (matchingToken && matchingToken.refresh_token) {
              let tokenData;
  
              if (!outlookEmails.includes(email)) {
                try {
                 
                  tokenData = await getOutlookRefreshAccessToken(matchingToken.refresh_token, item.google_calendar_id);
                  
                  if (tokenData.access_token) {
                    outlookEmails.push(email);
                    emailWithToken.push({
                      email: email,
                      accessToken: tokenData.access_token,
                    });
                  }
                  console.log(tokenData, '--------------->accessTokenaccessToken');
  
                 
                  const newToken = isTokenAndEmailUnique(response.refresh_token, matchingToken.email, tokenData.refresh_token);
                  const ciphertext = CryptoJS.AES.encrypt(tokenData.refresh_token, ENCRYPTION_KEY).toString();
                  console.log(newToken, '-------->newToken');
  
                  if (newToken) {
                    const body = {
                      email: matchingToken.email,
                      type: 'outlook',
                      refresh_token: ciphertext,
                    };
                    await commonApi.add_refresh_token(body);
                  }
                } catch (tokenError) {
                  console.error('Error fetching or processing token:', tokenError.message);
                  
                }
              }
  
              
              const filteredToken = emailWithToken.find(item => item.email === email);
              const accessToken = tokenData?.access_token || filteredToken?.accessToken;
  
              if (accessToken) {
                try {
                  
                  const eventData = props.event;
                  const res = await addEventToOutlookCalendar(accessToken, item.google_calendar_id, eventData);
  
                  EventId.push({
                    calendar_id: item.google_calendar_id,
                    calendar_event_id: res.id,
                    type: 'outlook',
                  });
  
                  if (EventId.length === outlookCalendars.length) {
                    await add_calendar_ids_in_confer_appoint(EventId);
                  }
  
                  console.log(res, '----->resresfrom ');
                } catch (calendarError) {
                  console.error('Error adding event to Outlook calendar:', calendarError.message);
                }
              } else {
                console.error('No access token available for email:', email);
              }
            } else {
              console.error('No matching token found or invalid refresh token for email:', email);
            }
          }
        } catch (itemError) {
          console.error('Error processing calendar item:', itemError.message);
        }
      }
    } catch (responseError) {
      console.error('Error fetching refresh tokens:', responseError.message);
    }
  };
  
  const addEventToCalendar = async (token, calendarId, event) => {
    // iteration++;

    const headers = {
      Authorization: `Bearer ${token}`,
      authorization: `Bearer ${token}`,
    };

    try {
      const config = {
        headers,
        data: event,
        method: 'POST',
        url: `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
      };
      const response = await axios(config);

      return response;

      // return response.data;
    } catch (error) {
      return error;
    }
  };
  const add_calendar_ids_in_confer_appoint = async MiniItems => {
    const payload = {
      type: props.type,
      type_id: props.typeId,
      items: MiniItems,
    };

    commonApi.add_calendar_ids_in_confer_appoint(payload).then(response => {
      if (EventId.length + googleItems.length === modelIdItem.length) {
        props.setShowModel('');
        // successMessage('Add Successfully');
        successMessage(labels.event_calendar_success[selected_lang]);
        props.onClose();
      }
    });
  };

  const handle = () => {
    setShowLoader(true)
    const {outlookCalendars, googleCalendars} = getCalendarsByType(modelIdItem);

    if (outlookCalendars.length > 0) {
      getOutlookRefreshToken(outlookCalendars);
    }
    if (googleCalendars.length > 0) {
      getRefreshToken(googleCalendars);
    }
  };

  console.log(props.event, '--------->event');
  return (
    <>
      <CustomModal
        style={{height: '100%'}}
        onClose={() => {
          props.setShowModel('');
          props.onClose();
        }}
        paddingBottomZero={true}>
        {/* {showLoader && <LoaderModal show={showLoader} />} */}
        {showLoader && <LoaderSpinner />}
        <div className="serach-filter-wrapper">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="headings m-0">
              <h2 className="m-0">Select Calenders</h2>
            </div>
          </div>

          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(props.calendarData).length > 0 &&
                  props.calendarData.map((item, index) => (
                    <li
                      key={`sessionC-${index}`}
                      //    className={
                      //      props.isCheck.includes(item.id) ? 'active' : ''
                      //    }
                    >
                      <Form.Label>
                        {item.summary}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          onChange={() => {
                            hanldeCalenderlist(item);
                          }}
                          checked={modelId.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {/* </Scrollbars> */}
          <div className="filter-btn-wrapper text-center pt-3">
            <Button
              className="btn-dark"
              type="submit"
              disabled={isButtonDisabled}
              onClick={() => {
                if (modelIdItem.length > 0) {
                  handle();
                } else {
                  errorMessage(labels.select_calendar_error[selected_lang]);
                }
              }}>
              Select
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
export default CalanderModel;
