import React, {useState, useEffect} from 'react';
import {format, addMonths, subMonths,  isBefore, isAfter} from 'date-fns';
import moment from 'moment';
import { formatDates, formatTime } from '../../Utils/commonUtils';
import { errorMessage } from '../../Utils/ToastMessages';

const Calender = ({filterDate, getCurrentYear,ModelStyle, highlightDates, otherType, setIsOpenCalendar, isOpenCalendar, selectMultipleDates, setSelectMultipleDates, checkTime, serverTimeS, setSelectedDate, selectedDate, type, setStartDaysTime, startDaysTime, closeDaysTime, setCloseDaysTime, showDate = true, showTime = true, onConfirm, minDate, maxDate,
  typeOfModelShow,
  setFromCalendar,
  setToCalendar,
  fromCalendar,
  toCalendar,
  showCalendar
}) => {
  const [isOpen, setIsOpen] = useState((type === 'invoice' || type === 'appointment') ? isOpenCalendar : false);
  
  const [currentDate, setCurrentDate] = useState((type === 'appointment') && selectedDate && selectedDate !== '' ? new Date(selectedDate) : new Date());  
  const [selectedHour, setSelectedHour] = useState(12);    
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [amPm, setAmPm] = useState('AM');


  useEffect(() => {
    let formattedTime;

    if (!setCloseDaysTime && startDaysTime) {
      formattedTime = formatTime(startDaysTime);
    } else if (setCloseDaysTime && closeDaysTime) {
      formattedTime = formatTime(closeDaysTime);
    } else if ((type === 'invoice' || type === 'appointment' || type === 'ticket') && selectedDate) {
      formattedTime = formatTime(selectedDate);
    } else {
      formattedTime = { hour: 12, minute: 0, ampm: 'AM' }; // Default values
    }

    setSelectedHour(formattedTime.hour);
    setSelectedMinute(formattedTime.minute);
    setAmPm(formattedTime.ampm);
  }, [startDaysTime, closeDaysTime, selectedDate, type]);
  
  useEffect(()=>{
    if(typeOfModelShow){
      const selectDate = selectedDate ? new Date(selectedDate) : new Date()
      setCurrentDate(selectDate)
    }
  },[typeOfModelShow])
  
  
  const [view, setView] = useState('date'); // 'date' or 'time'

  console.log(currentDate, 'currentDatecurrentDate')
  console.log(selectedDate, 'selectedDateselectedDate')
  console.log(selectMultipleDates, 'selectMultipleDatesselectMultipleDates')
  console.log(minDate, 'minDateminDate')
  console.log(maxDate, 'maxDatemaxDate')
  console.log(serverTimeS, 'serverTimeSserverTimeS')
  console.log(selectedHour, '===>selectedHour')

  const multipleDatesHandle = (datesArray) => {
    if (!datesArray.length) {
      return "Error: Array is empty.";
    }
  
    const uniqueDates = [...new Set(datesArray)];
  
    // const sortedDates = uniqueDates.sort((a, b) => new Date(a) - new Date(b));
  
    setSelectMultipleDates(uniqueDates); // Ensure you update the state with sorted dates
  };


  useEffect(() => {
    if(showDate){
    const formatDate = formatDates(minDate, maxDate)

    if (isBefore(currentDate, formatDate.formattedMinDate)) {
      setCurrentDate(minDate); // Adjust currentDate to minDate if it's before minDate
    }
    if (isAfter(currentDate, formatDate.formattedMaxDate)) {
      setCurrentDate(maxDate); // Adjust currentDate to maxDate if it's after maxDate
    }
  }
  }, [minDate, maxDate]);


  const selectDate = (day) => {
  
    if (showDate && showTime){
      const selectTime = moment(
        `${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
        "hh:mm A"
      ).format("hh:mm A"); 
      const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);

      const datePart = moment(newDate).format("YYYY-MM-DD");
      const combinedDateTime = moment(`${datePart} ${selectTime}`, "YYYY-MM-DD HH:mm");

      setSelectedDate(combinedDateTime); 
      toggleContent()

    }else if(showDate && !showTime){
      if(otherType ==='schedule_appointment'){

        const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        const formattedDate = newDate; 
        setSelectedDate(formattedDate); 

      }else{  
      const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      const year = newDate.getFullYear();
      const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
      const daysss = String(newDate.getDate()).padStart(2, '0'); // Add leading zero for day
      const formattedDate = `${year}-${month}-${daysss}`; // Format as YYYY-MM-DD
      setSelectedDate(formattedDate);
      toggleContent();
      }

    }
  
  }
  
  
  useEffect(() => {
    if (showDate) {
      setView('date');
    } else {
      setView('time');
    }
  }, []);

  const clearSelection = () => {
    if(showDate){
    setCurrentDate(new Date()); // Reset to today's date
    if(setSelectedDate){
    setSelectedDate('')
    }
    if(setSelectMultipleDates){
    setSelectMultipleDates([])
    }
    }else if(showTime){
    if(setStartDaysTime){
   
    setStartDaysTime('')
    }else if(setCloseDaysTime){
   
      setCloseDaysTime('')
    }
  }
  toggleContent();
  };

// Month Navigation with range checking

  const prevMonth = () => {
    const newDate = subMonths(currentDate, 1);
      setCurrentDate(newDate);
      
      if(getCurrentYear){
    const getYear = newDate.getFullYear()
    const currenYear = currentDate.getFullYear()
    if (currenYear !== getYear) {
      getCurrentYear(getYear);
    }
  }
  };

  const nextMonth = () => {
    const newDate = addMonths(currentDate, 1);
      setCurrentDate(newDate);

      if(getCurrentYear){
      const getYear = newDate.getFullYear()    
      const currenYear = currentDate.getFullYear()
      if (currenYear !== getYear) {
        getCurrentYear(getYear);
      }
    }
  };

  const handleHourChange = hour => {
    setSelectedHour(hour);
  };

  const handleMinuteChange = minute => {
    setSelectedMinute(minute);
  };

  const toggleAmPm = () => {
    setAmPm(prev => (prev === 'AM' ? 'PM' : 'AM'));
  };

  console.log(startDaysTime, 'startDaysTimeddddddddddddddddstartDaysTimestartDaysTime')

  // New function for handling OK button
  const handleOkClick = async () => {
    if(showTime){
    if (type === 'singleTime') {
      const selectTime = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A").format("hh:mm A");
  
      if (!checkTime) {
        const serverDate = moment(serverTimeS).format('YYYY-MM-DD');
        const selectedDateFormatted = moment(selectedDate).format('YYYY-MM-DD');
  
        const serverTimeMoment = moment(serverTimeS, 'YYYY-MM-DD HH:mm A');
        const selectedTimeMoment = moment(
          `${selectedDateFormatted} ${selectedHour.toString().padStart(2, "0")}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
          'YYYY-MM-DD HH:mm A'
        );
        const isSameDate = serverDate === selectedDateFormatted;
        const isBeforeServerTime = selectedTimeMoment.isBefore(serverTimeMoment);

        const selectedTimesss = moment(
          `${selectedHour.toString().padStart(2, "0")}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
          'HH:mm A'
        );
        
        const closeDaysTimeMoment = moment(closeDaysTime, 'hh:mm A');
        console.log(closeDaysTimeMoment, 'closeDaysTimeMomentcloseDaysTimeMoment')
        console.log(selectedTimesss, 'selectedTimesssselectedTimesss')
        const isValidTime = closeDaysTimeMoment.isBefore(selectedTimesss) || closeDaysTimeMoment.isSame(selectedTimesss);
  
        console.log(isValidTime, 'isValidTimeisValidTimeisValidTime')
        if (serverTimeS && isSameDate && isBeforeServerTime) {

          errorMessage('Choose correct time');
        } else {
          if(closeDaysTime && isValidTime){
            errorMessage('Choose correct time');
          }else{
          await setStartDaysTime(selectTime); // Await state update
          toggleContent(); // Close modal after state is set
          }
        }
      } else if (checkTime) {
     
  
        const timeStartMoment = moment(startDaysTime, 'hh:mm A');
        const timeEndMoment = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A");
  
        const matchTime = timeStartMoment.isSame(timeEndMoment) || timeStartMoment.isAfter(timeEndMoment);
  
        if (matchTime === false) {
          await setCloseDaysTime(selectTime); // Await state update
          toggleContent(); // Close modal after state is set
        } else {
          errorMessage('Choose correct time');
        }
      } else {
        await setStartDaysTime(selectTime); // Await state update
        toggleContent(); // Close modal after state is set
      }
    }else if(showTime && showDate){
      
      const selectTime = moment(
        `${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
        "hh:mm A"
      ).format("HH:mm A"); 

      const datePart = selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : moment(currentDate).format("YYYY-MM-DD");
      const combinedDateTime = moment(`${datePart} ${selectTime}`, "YYYY-MM-DD HH:mm");

      setSelectedDate(combinedDateTime); 
      toggleContent()
    }else{
      if(type === 'multipleTimes'){
       const selectTime = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A").format("hh:mm A");
       await setStartDaysTime(selectTime); // Await state update
       toggleContent();
     }else if(type === 'time'){
      const selectTime = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A").format("hh:mm A");
      setStartDaysTime(selectTime)
       toggleContent();
     }
   }
  }else{
      toggleContent();
  }
  };
  

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const daysInMonth = Array.from(
    {
      length: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
      ).getDate(),
    },
    (_, i) => i + 1,
  );


  const hours = Array.from({length: 12}, (_, i) => i + 1);
  const minutes = Array.from({length: 60}, (_, i) => i);
  
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const [animationClass, setAnimationClass] = useState(isOpen ? 'animate__fadeInUp show' : '');

  const toggleContent = () => {
    if(typeOfModelShow){
      if(showCalendar === 'toCalendar'){
        if(toCalendar){
          setToCalendar(false) 
        }else{
        setToCalendar(true)
        setFromCalendar(false)
        }
       }else if(showCalendar === 'fromCalendar'){
        if(fromCalendar){
          setFromCalendar(false)
        }else{
        setToCalendar(false)
        setFromCalendar(true)
        }
       }
    }else{
    if (!isOpen) {
      setAnimationClass('animate__fadeInUp show');
      if(type === 'invoice' || type === 'appointment'){
          setIsOpenCalendar(true);
      }else{
        setIsOpen(true);
      }
      
    } else {
      setAnimationClass('animate__fadeOutDown');

      if(type === 'invoice' || type === 'appointment'){
        setTimeout(() => {
          setIsOpenCalendar(false);
        })
      }else{
      setTimeout(() => {
        setIsOpen(false);
        setAnimationClass(''); 
      }, 320); 
    }
    }
  }
  };

  console.log(animationClass, 'animationClassanimationClassanimationClass')
  console.log(startDaysTime, 'startDaysTimestartDaysTimestartDaysTime')
  console.log(closeDaysTime, 'closeDaysTimecloseDaysTimecloseDaysTime')

  const getDisplayValue = ()=> {

    if (showDate && type !== 'multiple' && type !== 'ticket') {
      return selectedDate ? format(selectedDate, 'MM/dd/yyyy') : 'Select date';
  }

  if (showDate && showTime && type === 'ticket') {
    return selectedDate ? moment(selectedDate).format('MM/DD/YYYY hh:mm A') : 'Select end date';
  }
      
      if (showDate && type === 'multiple') {
          if (selectMultipleDates.length > 0) {
              return selectMultipleDates.length === 1 
                  ? selectMultipleDates[0] 
                  : selectMultipleDates.join(', ');
          } else {
              return "Select unavailable dates";
          }
      }
  
      if (showTime && type === 'time') {
          return startDaysTime && startDaysTime !== '' 
              ? moment(startDaysTime).format('hh:mm A') 
              : 'Select time';
      }
  
      if (showTime && type === 'singleTime') {
          if (setCloseDaysTime) {
              return closeDaysTime ? closeDaysTime.format("hh:mm A") : 'Select time';
          } else {
              return startDaysTime ? startDaysTime.format("hh:mm A") : 'Select time';
          }
      }
  
      if (showTime && type === 'multipleTimes') {
          return startDaysTime || 'Select time';
      }
  
      return null;
  }
  
  const openModalCalendar = ()=>{
    return (

    <div className="calendar-custom-relative-holder">
      

    <div className="relative">
      <button 
      // ref={calendarRef}
      type='button'
      style={{maxWidth: type === 'multiple' && '100%', display: isOpenCalendar && 'none'}}
      onClick={()=>{
          if(type !== 'singleTime'){
          toggleContent()
        }else if(type === 'singleTime' && selectedDate){
          toggleContent()
        }
      }}
        className="flex items-center justify-between w-full  transition duration-300 ease-in-out
        border border-gray-300 rounded-lg  bg-white text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400">
        <span className="clnd-date-time-txt">
          {/* {showDate && type !== 'multiple' && (selectedDate ? format(selectedDate, 'MM/dd/yyyy') : 'Select date')}
          {showTime && type === 'time' && ((startDaysTime && startDaysTime !== '' )? moment(startDaysTime).format('hh:mm A') : 'Select time')}
          {showTime && type === 'singleTime' && (
        setCloseDaysTime 
          ? (closeDaysTime ? closeDaysTime.format("hh:mm A") : 'Select time') 
          : (startDaysTime ? startDaysTime.format("hh:mm A") : 'Select time') 
      )}

          {showTime && type === 'multipleTimes' && (startDaysTime ? startDaysTime : 'Select time')
          }
        {showDate && type === 'multiple' && selectMultipleDates.length > 0 ? (
          selectMultipleDates.length === 1 
            ? selectMultipleDates[0] 
            : selectMultipleDates.join(', ')
        ) : showDate && type === 'multiple' && (
          "Select unavailable dates"
        )
      } */}

      {getDisplayValue()}

          
        </span>
        {(showDate || (showTime && showDate)) && 
        <svg
          className={`w-5 h-5 transition-transform duration-300 ease-in-out ${
            isOpen ? 'rotate-0' : ''
          }`}
          fill="none"
          stroke="#52bd41"
          viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 7V3m8 4V3m-7 8h6m-6 4h6m-8 6h10a2 2 0 002-2V7a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
}
          {showTime && !showDate &&
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
        <path d="M12.9713 6.22032C12.92 5.37564 12.6723 4.57939 12.3196 3.8261C11.9138 2.95871 11.3582 2.21335 10.6648 1.59113C9.77977 0.797069 8.76953 0.288948 7.63529 0.06567C7.41499 0.0224372 7.19165 0.0317405 6.97136 0H5.9573C5.81705 0.0292779 5.67349 0.0161439 5.53247 0.0377602C4.81519 0.146663 4.13982 0.390736 3.49572 0.748091C2.99669 1.025 2.53908 1.3632 2.12594 1.76926C1.43787 2.44511 0.891334 3.23343 0.516811 4.15609C0.278732 4.74247 0.108241 5.34937 0.0307444 5.98445C0.0134666 6.12701 0.02363 6.27258 0 6.41514V6.57904C0.020581 6.63376 0.020581 6.68821 0 6.74294V7.23437C0.020581 7.28909 0.020581 7.34355 0 7.39827V7.56217C0.02363 7.70473 0.0137206 7.85057 0.0307444 7.99313C0.119929 8.7404 0.334123 9.44827 0.645379 10.1197C1.04226 10.9765 1.58702 11.7117 2.26238 12.3358C3.02261 13.0388 3.89158 13.5116 4.85533 13.797C5.62928 14.0263 6.41847 14.0249 7.20613 13.9683C7.98592 13.9122 8.72201 13.6498 9.41795 13.2722C10.2234 12.8352 10.915 12.2371 11.4956 11.4928C12.1191 10.6933 12.5452 9.78373 12.8057 8.77844C13.0237 7.93622 13.0234 7.07786 12.9713 6.22032ZM9.6352 10.5203C9.44057 10.8194 9.0569 10.8566 8.80916 10.5939C8.44201 10.2046 8.07968 9.81 7.71532 9.4179C7.19572 8.85833 6.67789 8.29686 6.15523 7.74058C6.01777 7.59446 5.94993 7.43083 5.95197 7.22041C5.95781 6.6064 5.954 5.99239 5.95425 5.37865C5.95425 4.75095 5.95298 4.12353 5.95501 3.49583C5.95603 3.1995 6.15676 2.95187 6.41415 2.92067C6.69466 2.88647 6.93198 3.05913 7.00947 3.34917C7.02878 3.42141 7.02446 3.49337 7.02446 3.56533C7.02523 4.66585 7.027 5.76665 7.02218 6.86716C7.02167 6.99221 7.05775 7.07539 7.13804 7.16131C7.94121 8.02022 8.74082 8.88269 9.54042 9.74515C9.76021 9.98211 9.79451 10.2757 9.6352 10.5203Z" fill="#5ABD42"/>
      </svg>
          }

      </button>

      
      
          {isOpen &&
      <>
      
    <div 
    style={{backgroundColor: '#ededede6',zIndex:100, borderRadius: '25px'}}
    className={`animate__animated ${animationClass} fixed inset-0 -top-12 bg-opacity-100 flex items-center justify-center`} >
      <span
        className={`calendar_close 
        }`}
        style={ModelStyle || {}}
        onClick={() => { 
          
          toggleContent()
          
          }}>
        <i className="fas fa-times" />
      </span>
      <div className="w-full max-w-md calendar-custom-modal-holder">
        <div className="flex justify-center items-center mb-1">
            <h2 className="text-lg text-center font-bold">Select {view === 'date' ? 'Date' : 'Time'}</h2>

          </div>
      <div
        className={`relative fixed top-0 left-0 rounded-lg z-10 transform transition-transform duration-300 ease-in-out tail-calnd-dropdown ${
          isOpen
            ? 'translate-y-0 opacity-100'
            : '-translate-y-full opacity-0 pointer-events-none'
        }`} style={{ zIndex: 999 }}>
        {/* Header */}

        {/* {(showDate || showTime) && type !== 'multiple' && */}
        {((type !== 'singleTime' && type !== 'multiple' && (showTime || showDate)) || (type === 'singleTime' && selectedDate)) &&

        <div className="text-white rounded-t-lg p-2 date-time-pannel-head">
          <div className="text-center text-sm uppercase">
            {/* Select {showDate && 'Date'}
            {showTime && showDate && ' and '}
            {showTime && 'Time'} */}
          </div>
          <div className="text-center font-semibold">
          {showDate && (selectedDate ? format(selectedDate, 'MM/dd/yyyy') :  format(currentDate, 'MM/dd/yyyy'))}
          {showTime &&
              ` ${selectedHour}:${selectedMinute
                .toString()
                .padStart(2, '0')} ${amPm}`}
          </div>
        </div>
}

        {showDate && showTime && (
          <div className="flex justify-center items-center p-2 bg-white border-b date-time-combn-section">
            <button
            type='button'
              onClick={() => setView('date')}
              className={`p-2 w-1/2 text-center font-semibold ${
                view === 'date'
                  ? 'roi-bg-green font-semibold'
                  : 'hover:roi-bg-green'
              }`}>
              Date
            </button>
            <button
            type='button'
              onClick={() => setView('time')}
              className={`p-2 w-1/2 text-center font-semibold time-btn ${
                view === 'time'
                  ? 'roi-bg-green font-semibold'
                  : 'hover:roi-bg-green'
              }`} >
              Time
            </button>
          </div>
        )}

        {view === 'date' && showDate && (
          <>
            {/* Calendar Controls */}
            <div className="flex justify-between items-center bg-white p-2 border-b full-date-section">
              <button
              type='button'
                className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                onClick={prevMonth}>
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="#9b9787"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <div className="text-lg font-medium exact-date-holder">
              {currentDate && format(currentDate, 'MMMM yyyy')}        
              </div>
              <button
              type='button'
                className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                onClick={nextMonth}>
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="#9b9787"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-7 text-center text-gray-500 bg-white py-2 day-name-section">
{daysOfWeek.map(day => (
  <div key={day} className="text-sm font-medium day-name-color">
    {day}
  </div>
))}
</div>

<div className="grid grid-cols-7 text-center bg-white py-0 modal-custom-calendar-days">
{Array.from({ length: firstDayOfMonth }).map((_, index) => (
  <div key={`empty-${index}`} className="py-0"></div>
))}

{daysInMonth.map(day => {
const currentDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
const formatDate = formatDates(minDate, maxDate);
const isDisabled = currentDayDate < formatDate.formattedMinDate || currentDayDate > formatDate.formattedMaxDate;
const isSelectedDay = selectedDate && selectedDate.getDate() === day;
const isUnavailable = selectMultipleDates && selectMultipleDates.length > 0 && selectMultipleDates.includes(format(currentDayDate, 'yyyy-MM-dd'));
const todayDate = currentDate.getDate()
const filterDatess = filterDate && filterDate.length > 0 && filterDate(currentDayDate);

const classNames = [
"round-custom-time transition duration-300 ease-in-out",
isSelectedDay && !isDisabled ? "roi-bg-green text-white " : "",
todayDate === day && !selectedDate ? "roi-bg-green text-white " : "",
isUnavailable ? "roi-bg-green text-white cursor-pointer" : "",
isDisabled ? "text-gray-400 cursor-not-allowed" : "hover:roi-bg-green ",
filterDate && !filterDatess ? "text-gray-400 cursor-not-allowed" : "",
].filter(Boolean).join(" ");

  return (
    <div
      key={day}
      className={`${classNames} ${classNames.includes('cursor-not-allowed') ? '' : 'as-link'}`}
      style={{backgroundColor: (type === 'multiple' && isUnavailable) && '#429734', position: 'relative'}}
      onClick={() => {
        if (!isDisabled && !filterDate) {
          if (type !== 'multiple') {
            selectDate(day);
          } else {
            const formattedDate = format(currentDayDate, 'yyyy-MM-dd'); // Updated format
            if (isUnavailable) {
              setSelectMultipleDates(prev => prev.filter(date => date !== formattedDate));
            } else {
              multipleDatesHandle([...selectMultipleDates, formattedDate]);
            }
          }
        }else if(filterDatess){
          selectDate(day);
        }
      }}
    >
      {day}

      {highlightDates && highlightDates.length > 0 && highlightDates.includes(format(currentDayDate, 'yyyy-MM-dd')) && (
  <span
    style={{
      width: '3px',
      height: '3px',
      borderRadius: '50%',
      backgroundColor: 'black', // Color of the dot
      position: 'absolute',
      bottom: '5px', // Adjust the position as needed
      left: '50%',
      transform: 'translateX(-50%)',
    }}
  />
)}
    </div>
  );
})}
</div>

          </>
        )}
        {view === 'time' && ((type !== 'singleTime' && showTime) || (type === 'singleTime' && selectedDate)) && (

          <div className="flex justify-center items-center p-4 border-t time-corner-date-time-calander">
            <div className="flex flex-col items-center">
              <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">Hour</div>
              <div
                className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto overflow-x-hidden snap-y"
                style={{scrollBehavior: 'smooth'}}>
                {hours.map(hour => (
                  <div
                    key={hour}
                    className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out snap-start ${
                        selectedHour === hour 
                        ? 'roi-bg-green text-white'
                        : ''
                    }`}  
                    onClick={() => {
                    handleHourChange(hour)
                    }
                    }>
                    {hour}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col items-center mx-4">
              <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">Minute</div>
              <div
                className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto snap-y overflow-x-hidden"
                style={{scrollBehavior: 'smooth'}}>
                {minutes.map(minute => (
                  <div
                    key={minute}
                    className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out rounded-full snap-start ${
                      selectedMinute === minute 
                      ? 'roi-bg-green text-white'
                      : ''
                    }`}
                    onClick={() => {
                    handleMinuteChange(minute)
                    }
                  }>
                    {minute.toString().padStart(2, '0')}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">AM/PM</div>
              <div
                className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto snap-y overflow-x-hidden"
                style={{scrollBehavior: 'smooth'}}>
                {['AM', 'PM'].map(period => (
                  <div
                    key={period}
                    className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out rounded-full snap-start ${
                      amPm === period
                        ? 'roi-bg-green text-white'
                        : ''
                    }`}
                    onClick={() => {
                      setAmPm(period)
                    }
                    }>
                    {period}
                  </div>
                ))}
              </div>
            </div>


          </div>
          
        )}
      
      {((type !== 'singleTime' && (showTime || showDate)) || (type === 'singleTime' && selectedDate)) &&

        <div className="flex justify-between p-1 bg-gray-50 rounded-b-lg ok-clear-btn-section">
         {type !== 'appointment' &&
          <button
          type='button'
            onClick={clearSelection}
            className="px-4 py-2 text-sm text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
            Clear
          </button>
}
          <button
          type='button'
            onClick={handleOkClick}
            className="px-4 py-2 text-sm text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
            Okay
          </button>
        </div>
}
      </div>
      </div>
      </div>
      </>
}
    </div>
  </div>
    )
  }
  
  const openWithoutModalCalendar = ()=>{
    return (
    <div className="calendar-custom-relative-holder">
    <div className="relative">
      <button 
      type='button'
      style={{maxWidth: type === 'multiple' && '100%', display: isOpenCalendar && 'none'}}
      onClick={()=>{
         toggleContent()
      }}
        className="flex items-center justify-between w-full  transition duration-300 ease-in-out
        border border-gray-300 rounded-lg  bg-white text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400">
        <span className="clnd-date-time-txt">
      {getDisplayValue()}  
        </span>
        {(showDate) && 
        <svg
          className={`w-5 h-5 transition-transform duration-300 ease-in-out ${
            isOpen ? 'rotate-0' : ''
          }`}
          fill="none"
          stroke="#52bd41"
          viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 7V3m8 4V3m-7 8h6m-6 4h6m-8 6h10a2 2 0 002-2V7a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
}

      </button>      
         
    </div>
     {(toCalendar || fromCalendar) &&
      <>
      <div
        className={`adjustCalendar rounded-lg z-10 transform transition-transform duration-300 ease-in-out tail-calnd-dropdown ${
          (toCalendar || fromCalendar)
            ? 'translate-y-0 opacity-100'
            : '-translate-y-full opacity-0 pointer-events-none'
        }`} style={{ zIndex: 999 }}>

        {/* {((type !== 'singleTime' && type !== 'multiple' && (showTime || showDate)) || (type === 'singleTime' && selectedDate)) &&

        <div className="text-white rounded-t-lg p-2 date-time-pannel-head">
          <div className="text-center text-sm uppercase">
          </div>
          <div className="text-center font-semibold">
          {showDate && (selectedDate ? format(selectedDate, 'MM/dd/yyyy') :  format(currentDate, 'MM/dd/yyyy'))}
          </div>
        </div>
} */}

        {view === 'date' && showDate && (
          <>
            <div className="flex justify-between items-center bg-white p-1 border-b full-date-section">
              <button
              type='button'
                className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                onClick={prevMonth}>
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="#9b9787"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <div className="text-sm font-medium exact-date-holder">
              {currentDate && format(currentDate, 'MMMM yyyy')}        
              </div>
              <button
              type='button'
                className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                onClick={nextMonth}>
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="#9b9787"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-7 text-center text-gray-500 bg-white py-1 day-name-section">
{daysOfWeek.map(day => (
  <div key={day} className="text-xs font-medium day-name-color">
    {day}
  </div>
))}
</div>

<div className="grid grid-cols-7 text-center bg-white modal-custom-calendar-days">
{Array.from({ length: firstDayOfMonth }).map((_, index) => (
  <div key={`empty-${index}`} className="py-0"></div>
))}

{daysInMonth.map(day => {
const currentDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
const formatDate = formatDates(minDate, maxDate);
const isDisabled = currentDayDate < formatDate.formattedMinDate || currentDayDate > formatDate.formattedMaxDate;
const isSelectedDay = selectedDate && selectedDate.getDate() === day;
const isUnavailable = selectMultipleDates && selectMultipleDates.length > 0 && selectMultipleDates.includes(format(currentDayDate, 'yyyy-MM-dd'));
const todayDate = currentDate.getDate()
const filterDatess = filterDate && filterDate.length > 0 && filterDate(currentDayDate);

const classNames = [
"round-custom-time text-xs transition duration-300 ease-in-out",
isSelectedDay && !isDisabled ? "roi-bg-green text-white " : "",
todayDate === day && !selectedDate ? "roi-bg-green text-white " : "",
isUnavailable ? "roi-bg-green text-white cursor-pointer" : "",
isDisabled ? "text-gray-400 cursor-not-allowed" : "hover:roi-bg-green ",
filterDate && !filterDatess ? "text-gray-400 cursor-not-allowed" : "",
].filter(Boolean).join(" ");

  return (
    <div
      key={day}
      className={`${classNames} ${classNames.includes('cursor-not-allowed') ? '' : 'as-link'}`}
      style={{backgroundColor: (type === 'multiple' && isUnavailable) && '#429734', position: 'relative', marginBottom: '6px'}}
      onClick={() => {
        if (!isDisabled && !filterDate) {
          if (type !== 'multiple') {
            selectDate(day);
          } else {
            const formattedDate = format(currentDayDate, 'yyyy-MM-dd'); // Updated format
            if (isUnavailable) {
              setSelectMultipleDates(prev => prev.filter(date => date !== formattedDate));
            } else {
              multipleDatesHandle([...selectMultipleDates, formattedDate]);
            }
          }
        }else if(filterDatess){
          selectDate(day);
        }
      }}
    >
      {day}
    </div>
  );
})}
</div>

          </>
        )}

        <div className="flex justify-between bg-gray-50 rounded-b-lg ok-clear-btn-section">
          <button
          type='button'
            onClick={clearSelection}
            className="px-4 py-2 text-xs text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
            Clear
          </button>
          <button
          type='button'
            onClick={handleOkClick}
            className="px-4 py-2 text-xs text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
            Okay
          </button>
        </div>

      </div>
    
   
      </>
}
  </div>
    )
  }

  return (
   
   <>
       {!typeOfModelShow  &&
    openModalCalendar()
       }
    {typeOfModelShow === 'something' &&
    openWithoutModalCalendar()
}
    </>
  );
};

export default Calender;
