import React, { useRef, useState, useEffect } from 'react';

const AudioPlayer = ({ src, index }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // Play/Pause toggle
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Update progress bar as audio plays
  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentProgress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(currentProgress);
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  // Set duration when metadata is loaded
  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  // Reset on end
  const handleEnded = () => {
    setIsPlaying(false);
    // setProgress(0); // Reset progress when audio ends
  };

  // Handle click on progress bar to jump to that point
  const handleProgressBarClick = (e) => {
    const progressBar = e.target;
    const clickPosition = (e.nativeEvent.offsetX / progressBar.offsetWidth) * 100; // Calculate click position percentage
    if (audioRef.current) {
      audioRef.current.currentTime = (clickPosition / 100) * audioRef.current.duration; // Set audio time to clicked position
    }
    setProgress(clickPosition); // Update progress state
  };

  // Format time to mm:ss
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600); // 3600 seconds in an hour
    const minutes = Math.floor((time % 3600) / 60); // Remaining minutes after removing hours
    const seconds = Math.floor(time % 60); // Remaining seconds after removing minutes
  
    if (hours > 0) {
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    } else {
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
  };
  

  return (
    <div className="audio-player" style={{ display: 'flex', alignItems: 'center' }}>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleEnded}
        onLoadedMetadata={handleLoadedMetadata} // Set duration once metadata is loaded
        preload="auto"
      />
      {!isPlaying ? (
        <svg
          fill="currentColor"
          viewBox="0 0 16 16"
          height="25px"
          width="25px"
          style={{ marginRight: '6px', cursor: 'pointer' }}
          onClick={togglePlayPause}
        >
          <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 010 1.393z" />
        </svg>
      ) : (
        <svg
          fill="none"
          viewBox="0 0 24 24"
          height="25px"
          width="25px"
          style={{ marginRight: '6px', cursor: 'pointer' }}
          onClick={togglePlayPause}
        >
          <path fill="currentColor" d="M11 7H8v10h3V7zM13 17h3V7h-3v10z" />
        </svg>
      )}

      <div style={{ display: 'flex', alignItems: 'center', width: '100%'}}>
        <span style={{ fontSize: '12px', marginRight: '15px' }}>
          {formatTime(currentTime)}
          </span>        
        <div
          className="progress-bar-container"
          style={{ position: 'relative', flexGrow: 1, backgroundColor: '#e0e0e0', height: '5px', borderRadius: '5px' }}
          onClick={handleProgressBarClick}
        >
          <div
            className="progress-bar"
            style={{
              width: `${progress}%`,
              backgroundColor: 'gray',
              height: '5px',
              borderRadius: '5px',
              position: 'relative',
            }}
          ></div>
          <div
            className="thumb"
            style={{
              position: 'absolute',
              top: '-3px',
              left: `${progress}%`,
              transform: 'translateX(-50%)',
              width: '18px',
              height: '18px',
              backgroundColor: 'white',
              borderRadius: '50%',
              boxShadow: '0 0 6px rgba(0, 0, 0, 0.3)'  // Adding a soft shadow
            }}
          ></div>
        </div>
        
        <span style={{ fontSize: '12px', marginLeft: '15px' }}>
          {formatTime(duration)}
        </span>
      </div>
    </div>
  );
};

export default AudioPlayer;
