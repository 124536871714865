import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {ProgressBar} from 'react-bootstrap';

const SmartChatPoll = ({chat, handlePoll, chatStatusOfOpportunity}) => {
  const [count, setCount] = useState(0);
  const [participants, setParticipants] = useState(0);
  const [pollData, setPollData] = useState([
    {option: 'option1', label: '', userIds: [], users: 0, percentage: 0},
    {option: 'option2', label: '', userIds: [], users: 0, percentage: 0},
    {option: 'option3', label: '', userIds: [], users: 0, percentage: 0},
    {option: 'option4', label: '', userIds: [], users: 0, percentage: 0},
    {option: 'option5', label: '', userIds: [], users: 0, percentage: 0},
  ]);
  const currentUrl = window.location.href;
  const lastSlashIndex = currentUrl.lastIndexOf('/');
  const newSubstring = currentUrl.substring(lastSlashIndex + 1);

  const countUsers = (array, column) => {
    let count = 0;
    // let count = [];
    for (let x in array) {
      if (array[x].hasOwnProperty(column)) {
        count += array[x][column].length;
      }
      // count = count.concat(array[x][column]);
    }
    return count;
    // return array.map(item => item[column]);
  };

  const arrayObj = (array, count) => {
    let retArr = [];
    for (let x in array) {
      const optUser = array[x]['userIds'] ? array[x]['userIds'].length : 0;
      retArr.push({
        option: x,
        label: array[x]['option'],
        userIds: array[x]['userIds'] ? array[x]['userIds'] : [],
        users: optUser,
        percentage: count ? ((optUser / count) * 100).toFixed(0) : 0,
      });
    }
    return retArr;
  };

  const initialFun = () => {
    // const chating = JSON.parse(chat.message.options); // [0]
    const chating = chat.message.options;
    const totalUsers = countUsers(chating, 'userIds');
    setParticipants(totalUsers);
    setPollData(arrayObj(chating, totalUsers));
  };

  useEffect(() => {
    initialFun();

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, chat]);

  return (
    <div
      className={`msg-polls-wrapper ${
        chat.message.status !== 'open' ||
        newSubstring === 'history' ||
        chatStatusOfOpportunity === 'match' ||
        chatStatusOfOpportunity === 'decline' ||
        chatStatusOfOpportunity === 'left'
          ? 'poll-closed'
          : ''
      }`}>
      <h5>{chat.message.question}</h5>
      <span
        className="participates-count as-link gold-color"
        onClick={() => {
          handlePoll('show-participants');
        }}>
        {`${participants} Participants`}
      </span>
      {Object.entries(pollData).length > 0 &&
        pollData.map(item => {
          return (
            item.label && (
              <div className="poll-item" key={item.option}>
                <div className="poll-left">
                  <span className="poll-label">
                    {chat.message.dateAndTime
                      ? moment(item.label).format('ddd, MMM DD @ h A')
                      : item.label}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <ProgressBar
                    className="w-100"
                    animated
                    now={item.percentage}
                    label={`${item.percentage}%`}
                  />
                  <div
                    className="poll-right"
                    onClick={() => {
                      if (
                        chat.message.status === 'open' &&
                        newSubstring !== 'history' &&
                        chatStatusOfOpportunity !== 'match' &&
                        chatStatusOfOpportunity !== 'decline' &&
                        chatStatusOfOpportunity !== 'left'
                      ) {
                        localStorage.setItem('optionNo', item.option);
                        handlePoll('update-poll');
                        setCount(count + 1);
                      }
                    }}>
                    <span
                      // className="poll-vote"
                      className={`poll-vote ${
                        chat.message.status === 'open' &&
                        newSubstring !== 'history' &&
                        chatStatusOfOpportunity !== 'match' &&
                        chatStatusOfOpportunity !== 'decline' &&
                        chatStatusOfOpportunity !== 'left'
                          ? 'as-link'
                          : ''
                      }
                  `}>
                      <i
                        className={
                          item.userIds.includes(localStorage.getItem('id'))
                            ? 'fas fa-check-circle'
                            : 'far fa-circle'
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            )
          );
        })}
    </div>
  );
};

export default SmartChatPoll;
