import {
    GET_CATEGORIES,
    ADD_CATEGORY,
    UPDATE_CATEGORY,
    REMOVE_CATEGORY,
    GET_REGIONS,
    ADD_REGION,
    UPDATE_REGION,
    REMOVE_REGION,
    GET_ADMIN_CAUSES,
    ADD_ADMIN_CAUSE,
    UPDATE_ADMIN_CAUSE,
    REMOVE_ADMIN_CAUSE,
} from "../../Constants/Types";

export const causeCategory = (state = [], action = {}) => {
    let categories;
    switch (action.type) {
        case GET_CATEGORIES:
            return action.categories;
        case ADD_CATEGORY:
            categories = state;
            if (Object.entries(categories).length > 0) {
                categories.unshift(action.category);
            } else {
                categories = categories.push(action.category);
            }
            return categories;
        case UPDATE_CATEGORY:
            categories = state.map((category) => {
                if (category.id === action.category.id) return { ...category, ...action.category };
                return category;
            });
            return categories;
        case REMOVE_CATEGORY:
            categories = state.filter((category) => {
                return category.id !== action.category.id;
            });
            return categories;
        default:
            return state;
    }
};

export const causeRegions = (state = [], action = {}) => {
    let regions;
    switch (action.type) {
        case GET_REGIONS:
            return action.regions;
        case ADD_REGION:
            regions = state;
            if (Object.entries(regions).length > 0) {
                regions.unshift(action.region);
            } else {
                regions = regions.push(action.region);
            }
            return regions;
        case UPDATE_REGION:
            regions = state.map((region) => {
          
                if (region.id === action.region.id) return { ...region, ...action.region };
                return region;
            });
            return regions;
        case REMOVE_REGION:
            regions = state.filter((region) => {
                return region.id !== action.region.id;
            });
            return regions;
        default:
            return state;
    }
};

export const adminCauses = (state = [], action = {}) => {
    let causes;
    switch (action.type) {
        case GET_ADMIN_CAUSES:
            return action.causes;
        case ADD_ADMIN_CAUSE:
            causes = state;
            if (Object.entries(causes).length > 0) {
                causes.unshift(action.cause);
            } else {
                causes = causes.push(action.cause);
            }
            return causes;
        case UPDATE_ADMIN_CAUSE:
            causes = state.map((cause) => {
                if (cause.id === action.cause.id) return { ...cause, ...action.cause };
                return cause;
            });
            return causes;
        case REMOVE_ADMIN_CAUSE:
            causes = state.filter((cause) => {
                return cause.id !== action.cause.id;
            });
            return causes;
        default:
            return state;
    }
};
