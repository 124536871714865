import '@voxeet/voxeet-web-sdk';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import {App_key_Dolby, App_Secret_key_Dolby} from './env';
import {errorMessage} from './ToastMessages';

// This is due to a breaking change in version 3.2.0,
// in which these functions are expected to be invoked as methods
// on the SDK object, like VoxeetSDK.initialize().
const initialize = VoxeetSDK.initialize.bind(VoxeetSDK);
const session = VoxeetSDK.session;
const mediaDevice = VoxeetSDK.mediaDevice;
const conference = VoxeetSDK.conference;

const consumerKey = App_key_Dolby;
const consumerSecret = App_Secret_key_Dolby;

// const mediaDeviceService = mediaDevice();
export const initializeVoxeet = () => {
  initialize(consumerKey, consumerSecret);
};

/**
 * This function either creates a new session if there isn't anyone in one with that alias
 * or finds the conference if there already is.
 * It returns an object that can be passed into joinConference below();
 * @param {*} alias
 * @returns conference
 */

const generateConfId = () => {
  // let accessToken = '';

  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/x-www-form-urlencoded',
      authorization:
        'Basic cUpMajlVUkJhYmdtT28ydmcyN08zdz09OjFDQjVLcGVmRWhMV1JaMVF2a1ljUUhzM0dQN3RtUk16XzdCTTVESGlnU2c9',
    },
    body: new URLSearchParams({
      grant_type: 'client_credentials',
      expires_in: 86400,
    }),
  };

  return fetch('https://api.dolby.io/v1/auth/token', options)
    .then(response => response.json())
    .then(response => {
      return response.access_token;
    })
    .catch(err => console.error(err));

  // return accessToken;
  // const options = {
  //   method: 'POST',
  //   headers: {
  //     accept: 'application/json',
  //     'Cache-Control': 'no-cache',
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     authorization: 'Basic cUpMajlVUkJhYmdtT28ydmcyN08zdz09OjFDQjVLcGVmRWhMV1JaMVF2a1ljUUhzM0dQN3RtUk16XzdCTTVESGlnU2c9'
  //   },
  //   body: new URLSearchParams({grant_type: 'client_credentials'})
  // };

  // fetch('https://session.voxeet.com/v1/oauth2/token', options)
  //   .then(response => response.json())
  //   .then(response =>
  //   .catch(err => console.error(err));
};

const createConf = token => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      ownerExternalId: localStorage.getItem('id'),
      alias: 'conference_id_' + (Math.random() + 1).toString(36).substring(3),
    }),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + token,
    },
  };

  return fetch('https://comms.api.dolby.io/v2/conferences/create', options)
    .then(response => response.json())
    .then(response => response.conferenceId)
    .catch(err => console.error(err));
};

const createConference = alias => {
  return new Promise((resolve, reject) => {
    conference
      .create({alias})
      .then(cellConference => {
        resolve(cellConference);
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
};

// conference in/out
const joinConference = conf => {
  conference.join(conf, {});
};

const leaveConference = () => {
  conference.leave();
};

// video
const startVideo = () => {
  conference
    .startVideo(session.participant)
    .then(() => {})
    .catch(err => {
      console.error(err);
    });
};

const stopVideo = () => {
  conference
    .stopVideo(session.participant)
    .then(() => {})
    .catch(err => {
      console.error(err);
    });
};

// screen share
const startScreen = () => {
  return conference
    .startScreenShare()
    .then(() => {
      return true;
    })
    .catch(err => {
      errorMessage('Screen share already in progress');
      console.error(err);
    });
};

const stopScreen = () => {
  return conference
    .stopScreenShare()
    .then(() => {
      return true;
    })
    .catch(err => {
      console.error(err);
    });
};

// audio
const startAudio = () => {
  conference
    .startAudio(session.participant)
    .then(() => {})
    .catch(err => {
      console.error(err);
    });
};

const stopAudio = () => {
  conference
    .stopAudio(session.participant)
    .then(() => {})
    .catch(err => {
      console.error(err);
    });
};

// media devices
const getAudioDevices = () => {
  return new Promise((resolve, reject) => {
    mediaDevice
      .enumerateAudioDevices()
      .then(value => {
        resolve(value);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getVideoDevices = () => {
  return new Promise((resolve, reject) => {
    mediaDevice
      .enumerateVideoDevices()
      .then(value => {
        resolve(value);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const changeAudioDevice = deviceId => {
  mediaDevice
    .selectAudioInput(deviceId)
    .then(() => {})
    .catch(err => console.error);
};

const changeVideoDevice = deviceId => {
  mediaDevice
    .selectVideoInput(deviceId)
    .then(() => {})
    .catch(err => console.error);
};

// session related
const isSessionOpen = () => {
  return session.isOpen();
};

const closeSession = () => {
  session.close();
};

export {
  createConference,
  joinConference,
  leaveConference,
  startVideo,
  stopVideo,
  startAudio,
  stopAudio,
  getAudioDevices,
  getVideoDevices,
  changeAudioDevice,
  changeVideoDevice,
  generateConfId,
  createConf,
  startScreen,
  stopScreen,
  isSessionOpen,
  closeSession,
};
