import {FormattedText} from '../../Utils/Helpers';
import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';

import {
  CheckmarkFillGrey,
  VideoGreyIcon,
  CheckmarkFillYellow,
  UserGroupMini,
} from '../../Constants/Images';
import {RoiLogo} from '../../Constants/Images';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {
  capitalizeFirst,
  fixName,
  getRelativeDate,
} from '../../Utils/commonUtils';
import CreateAction from '../Snapshot/CreateAction';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import CropImage from '../Cropper/CropImage';
import {errorMessage} from '../../Utils/ToastMessages';
import CustomModal from '../CustomModal';
import LoaderSpinner from '../Modals/LoaderSpinner';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import {useDispatch} from 'react-redux';
import {getAllActions} from '../../Store/Actions/SessionAction';
import Slider from 'react-slick';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import ImagePreview from '../Modals/ImagePreview';
import {getDispatch} from '../../Store/Actions/SessionAction';
import EditPencilSvg from '../../Svg/EditPencilSvg';
const ActionDetail = props => {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState('');
  const userId = localStorage.getItem('id');
  const [showModel, setShowModel] = useState('');
  const [status, setStatus] = useState(props.dataOfAction.status);
  const [ticketImg, setTicketImg] = useState(
    props.dataOfAction.images !== ''
      ? props.dataOfAction.images.split(',')
      : [],
  );
  const [showLoader, setshowLoader] = useState(false);
  const [sliderImage, setSliderImage] = useState([]);
  const [checkSatusId, setCheckSatusId] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [file, setFile] = useState(null);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const [discription, setDiscription] = useState('');
  const [wacherId, setWacherId] = useState('');
  const [showNotes, setShowNotes] = useState(false);
  const [modelImg, setModelImg] = useState('');
  const [notes, setNotes] = useState([]);
  // const imgs =  props.dataOfAction.images != '' ? props.dataOfAction.images.split(',') :[],
  let sliderElements = [];
  let imgs = [];
  if (props.dataOfAction.images !== '') {
    imgs = props.dataOfAction.images.split(',');
  } else {
    imgs = [];
  }
  // let updateStatus = false
  const [showMore, setShowMore] = useState(false);
  const [showMoreFirstnote, setShowMoreFirstnote] = useState(false);
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const showMoreClassDescription = showMoreDescription ? 'show-all' : '';

  const prevDataOfActionRef = useRef(props.dataOfAction);
  const showMoreClassFirstnote = showMoreFirstnote ? 'show-all' : '';

  const [design, setDesign] = useState(false);
  const AttachmentsPreview = {
    dots: true,
    infinite: false, // Disable infinite looping
    arrows: false,
    speed: 500,
    slidesToShow: 3, // Number of previews
    slidesToScroll: 1,
  };
  const WatchersPreviews = {
    dots: false,
    infinite: false, // Disable infinite looping
    arrows: true,
    speed: 500,
    slidesToShow: 12, // Number of previews
    slidesToScroll: 1,
  };

  const createdBy = props.dataOfAction.created_by;
  const watchers = props.dataOfAction.watchers;
  const assigne = props.dataOfAction.assigne;

  // Combine the IDs into a single array
  const shareIds = [createdBy, ...watchers.split(','), assigne];

  if (ticketImg.length > 0) {
    sliderElements = [
      // Input and icon elements as the first item
      <div className="ticket-img-wrapper m-0" key="input">
        <input
          type="file"
          accept="image/*"
          onChange={e => changeTicketImg(e)}
        />
        <i className="icon-camera" />
      </div>,
      // Image elements for each URL in imageUrlArray
      ...ticketImg.map((imageUrl, index) => (
        <div className="ticket-img-wrapper" key={index}>
          <img
            src={imageUrl}
            alt=""
            onClick={() => {
              setModelImg(imageUrl);
              setShowModel('image_pre');
            }}
          />
        </div>
      )),
    ];
    // Input and icon elements as the first item
  } else {
    sliderElements = [
      // Input and icon elements as the first item
      <div className="ticket-img-wrapper m-0" key="input">
        <input
          type="file"
          accept="image/*"
          onChange={e => changeTicketImg(e)}
        />
        <i className="icon-camera" />
      </div>,
    ];
  }

  // const [checkLength, setCheckLength] = useState(ticketImg.length);

  useEffect(() => {
    // alert(props.dataOfAction.images != '' ? props.dataOfAction.images.split(',') :[])

    const prevDataOfAction = prevDataOfActionRef.current;

    setTicketImg(
      props.dataOfAction.images !== ''
        ? props.dataOfAction.images.split(',')
        : [],
    );

    setStatus(props.dataOfAction.status);
    setShowComponent('');

    if (!updateStatus) {
      getNotes();
    }

    if (props.dataOfAction.watchers_detail.length > 8) {
      setDesign(true);
    } else {
      setDesign(false);
    }
    setUpdateStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataOfAction]);

  useEffect(() => {
    if (
      props.dataOfAction.check_list_details &&
      props.dataOfAction.check_list_details.length > 0
    ) {
      const completedIds = props.dataOfAction.check_list_details
        .filter(item => item.status === 'complete')
        .map(item => item.id);

      setCheckSatusId(completedIds);
    }
  }, [props.dataOfAction]);

  const changeActionStatus = id => {
    var Payload = {
      action_id: id,
      status: 'completed',
    };
    // setStatus('completed')
    // props.dataOfAction.status = "completed";
    commonApi
      .change_action_status(Payload)
      .then(res => {
        if (res.status === 200 && !res.success) {
          // setActionsData(res.all_actions);
          setStatus('completed');
          props.dataOfAction.status = 'completed';
          successMessage(res.message);
          getActions();
        } else if (res.success === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getActions = () => {
    const Payload = JSON.parse(localStorage.getItem('action_paylaod'));

    dispatch(getAllActions(Payload));
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);
      // setCropImg(true);
      setShowModel('crop_modal');

      e.target.value = null;

      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {

      //   uploadMedia(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };

  useEffect(() => {
    if (imgs && imgs.length !== ticketImg.length) {
      uploadActionImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketImg]);

  const uploadActionImages = () => {
    var Payload = {
      action_id: props.dataOfAction.id,
      images: ticketImg.toString(','),
    };

    commonApi
      .add_images_in_action(Payload)
      .then(res => {
        if (res.status === 200) {
          props.setHitApi(true);

          // successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const addNotes = () => {
    const filteredShareIds = shareIds.filter(
      id => id !== localStorage.getItem('id'),
    );
    //     type(action,other,chat,opportunity_chat),
    // associated_id
    // description

    var Payload = {
      type: 'action',
      associated_id: props.dataOfAction.id,
      description: discription,
      share_with: filteredShareIds.toString(','),
    };

    commonApi
      .create_notes(Payload)
      .then(res => {
        if (res.status === 200) {
          // const filter_array = res.all_actions
          getNotes();
          setDiscription('');
          setshowLoader(false);
          successMessage('Note added successfully');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotes = () => {
    setshowLoader(true);
    //     get_notes
    // token
    // parameters =>
    // type
    // type_id
    // data_type(all,shared,my)
    // filter(all,me,other)
    var Payload = {
      type: 'action',
      type_id: props.dataOfAction.id,
      data_type: 'all',
      filter: 'all',
    };

    commonApi
      .get_notes(Payload)
      .then(res => {
        if (res.status === 200) {
          // const filter_array = res.all_actions
          setNotes(res.notes);
          setshowLoader(false);
          // successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const viewAllNotes = () => {
    const allNotesLines =
      Object.entries(notes).length > 1
        ? Object.entries(notes).map(([key, item]) =>
            item.description.split('\n'),
          )
        : [];

    return (
      <div className="message-other-users mb-2">
        <div className="watchers-imgs">
          {showNotes &&
            Object.entries(notes).length > 0 &&
            notes
              .slice(1)
              .map((item, index) => (
                <NoteItem
                  index={index}
                  item={item}
                  allNotesLines={allNotesLines}
                />
              ))}
        </div>
      </div>
    );
  };

  const NoteItem = ({index, item, allNotesLines}) => {
    // const [showMore, setShowMore] = useState(false);
    // const showMoreClass = showMore ? 'show-all' : '';
    const showMoreClass = '';

    return (
      <div className="flex align-items-center notes-view mt-2">
        <img
          src={item.creator_detail.profile_img}
          alt={`i ${index}`}
          onClick={() => {
            openComponent('user_profile', item.creator_detail.id);
          }}
        />
        <div>
          <FormattedText
            className={`profile-description ${showMoreClass}`}
            content={item.description}
          />

          {((item.description && item.description.length > 250) ||
            allNotesLines[index].length > 3) && (
            <>
              {' '}
              <span
                className="show-more-link"
                onClick={() => setShowMore(!showMore)}>
                {!showMore
                  ? labels.read_more[selected_lang]
                  : labels.read_less[selected_lang]}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  const previewNotes = () => {
    setShowNotes(!showNotes);
  };
  //

  //
  //

  const openComponent = (type, id) => {
    setWacherId(id);
    setShowComponent(type);
  };

  const assigneDetail = item => {
    const userId = localStorage.getItem('id');
    const check = item.watchers_detail.some(obj => obj.id === userId);

    return item.created_by === userId && item.assigne !== '0' ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        From you to{' '}
        {fixName(item.assigne_detail.first_name, item.assigne_detail.last_name)}
      </p>
    ) : item.assigne === userId || check ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        For you from{' '}
        {fixName(item.creator_detail.first_name, item.creator_detail.last_name)}
      </p>
    ) : (
      <></>
    );
  };

  const lines = props.dataOfAction.description.split('\n');

  const firstNote = notes.length > 0 ? notes[0].description.split('\n') : '';

  const handleFeedbackChange = e => {
    const value = e.target.value;

    // Remove leading spaces
    if (value.startsWith(' ')) {
      setDiscription(value.trimStart());
    } else {
      setDiscription(value);
    }
  };

  console.log(checkSatusId, 'checkSatusIdcheckSatusId');

  const change_checklist_status = id => {
    if (!checkSatusId.includes(id)) {
      setCheckSatusId(previous => [...previous, id]);
    } else {
      setCheckSatusId(previous => previous.filter(miniitem => miniitem !== id));
    }
    // setCheckSatusId(prevStatusIds => {
    //   if (item.list_status === 'complete') {
    //     return prevStatusIds.includes(item.id)
    //       ? prevStatusIds
    //       : [...prevStatusIds, item.id];
    //   } else if (res.list_status === 'pending') {
    //     return prevStatusIds.filter(statusId => statusId !== item.id);
    //   }
    //   return prevStatusIds;
    // });
    var Payload = {
      check_list_id: id,
    };

    commonApi
      .manage_check_list(Payload)
      .then(res => {
        if (res.status === 200 && res.success === true) {
          // setCheckSatusId(prevStatusIds => {
          //   if (res.list_status === 'complete') {
          //     // Add ID if it's not already in the array
          //     return prevStatusIds.includes(id)
          //       ? prevStatusIds
          //       : [...prevStatusIds, id];
          //   } else if (res.list_status === 'pending') {
          //     // Remove ID if it's in the array
          //     return prevStatusIds.filter(statusId => statusId !== id);
          //   }
          //   return prevStatusIds; // Return unchanged if status is neither 'complete' nor 'pending'
          // });

          // updateChecklistItemStatus(id);

          successMessage(res.message);
        } else if (res.success === false) {
          if (!checkSatusId.includes(id)) {
            setCheckSatusId(previous => [...previous, id]);
          } else {
            setCheckSatusId(previous =>
              previous.filter(miniitem => miniitem !== id),
            );
          }
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateChecklistItemStatus = checklistId => {
    const updatedTasks = props.actions.map(task => {
      if (task.id === props.dataOfAction.id) {
        return {
          ...task,
          check_list_details: task.check_list_details.map(checklistItem => {
            if (checklistItem.id === checklistId) {
              return {
                ...checklistItem,
                status:
                  checklistItem.status === 'pending' ? 'complete' : 'pending',
              };
            }
            return checklistItem;
          }),
        };
      }
      return task;
    });

    setUpdateStatus(true);
    const updatedTask = updatedTasks.find(
      task => task.id === props.dataOfAction.id,
    );
    props.setDataOfAction(updatedTask);
    dispatch(getDispatch('ACTIONS/GET_DATA', updatedTasks));
  };

  const handleRefresh = () => {
    console.log(imgs.length, 'imgs.lengthimgs.length');
    console.log(ticketImg, 'ticketImgticketImg');
    console.log(props.dataOfAction?.status, 'props.dataOfAction?.status');
    console.log(status, 'status');
    console.log(
      props.dataOfAction?.check_list_details,
      'props.dataOfAction?.check_list_details',
    );
    console.log(checkSatusId, 'checkSatusIdcheckSatusId');

    const completedIds = prevDataOfActionRef.current.check_list_details
      .filter(item => item.status === 'complete')
      .map(item => item.id);
    console.log(completedIds, 'completedIdscompletedIdscompletedIds');
    if (
      imgs.length !== ticketImg.length ||
      props.dataOfAction?.status !== status ||
      (props.dataOfAction?.status !== 'completed' &&
        props.dataOfAction?.check_list_details &&
        props.dataOfAction?.check_list_details.length > 0 &&
        (checkSatusId.length > completedIds.length ||
          checkSatusId.length < completedIds.length))
    ) {
      props.onCloseHitApi && props.onCloseHitApi();
    }
  };

  console.log(props.actions, 'props.actionsprops.actions');
  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {props.dataOfAction.created_by === localStorage.getItem('id') ? (
          <span
            className="as-link"
            onClick={() => {
              setShowComponent('edit_Action');
            }}>
            <EditPencilSvg fill={'white'} h={20} w={20} />
          </span>
        ) : (
          <img src={RoiLogo} alt="" />
        )}
      </>
    );
  };

  console.log(imgs, 'imgs--------->');
  console.log(ticketImg, 'ticketImg--------->');
  // console.log(props.dataOfAction,'props.dataOfActionprops.dataOfAction')
  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          handleRefresh();
          // if (imgs.length !== ticketImg.length) {
          //   uploadActionImages();
          // }

          props.onClose();
        }}>
        <div className="serach-filter-wrapper edit_Action fixed-width-inputs pt-3">
          {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}

          <Scrollbars autoHide="scroll">
            <div className="black-head-form-pad-left black-head-form-pad-right">
              <div className="mt-2">
                {/* {Object.entries(actionsData).length > 0 ? actionsData.map((item, index) =>  ( */}
                <div className="refferals-list action-listing bg-transparent action-detail-refferal-listing">
                  <h2 className="actions-title mb-2 truncate_2_lines">
                    {/* truncate_2_lines */}

                    {props.dataOfAction.title}

                    {/* <FormattedText
                      className={`profile-description ${showMoreClassTitle}`}
                      content={props.dataOfAction.title}
                    />
                    {(props.dataOfAction.title.length > 250 ||
                      titleLines.length > 3) && (
                      <>
                        {' '}
                        <span
                          className="show-more-link"
                          onClick={() => setShowMoreTitle(!showMoreTitle)}>
                          {!showMoreTitle
                            ? labels.read_more[selected_lang]
                            : labels.read_less[selected_lang]}
                        </span>
                      </>
                    )} */}

                    {/* order for Supplies */}
                  </h2>
                  <div className="d-flex align-items-center session-cam mb-2">
                    {props.dataOfAction?.watchers_detail.length > 0 ? (
                      <img src={UserGroupMini} height={16} width={16} alt="" />
                    ) : (
                      <span className="task_assigne_icon">
                        <i className="fas fa-user" />{' '}
                      </span>
                    )}

                    {/* <img src={VideoGreyIcon} alt="video-cam" /> */}
                    <p className="p-reg m-0 single-line-text">
                      {/* {props.dataOfAction.association_name} */}
                      {fixName(
                        props.dataOfAction?.assigne_detail?.first_name,
                        props.dataOfAction?.assigne_detail?.last_name,
                      )}
                    </p>
                  </div>

                  {props.dataOfAction.due_date !== '0000-00-00 00:00:00' &&
                    props.dataOfAction.priority !== '' && (
                      <p className="p-reg m-0 mb-2">
                        {'Due ' + getRelativeDate(props.dataOfAction.due_date)}
                        {
                          <span>
                            ({capitalizeFirst(props.dataOfAction.priority)}{' '}
                            Priority)
                          </span>
                        }
                      </p>
                    )}
                  {/* <p className="p-reg m-0 mb-2">
                    { 'Due '+getRelativeDate(props.dataOfAction.due_date)}
                    { <span>({props.dataOfAction.priority} Priority)</span> }
                  </p> */}
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{alignItems: 'center'}}>
                    <div className="d-flex align-items-center">
                      {/* <p className="p-reg m-0 actionsCategories single-line-text">
                        For you from{' '}
                        {fixName(
                          props.dataOfAction.creator_detail.first_name,
                          props.dataOfAction.creator_detail.last_name,
                        )}
                      
                      </p> */}
                      {/* <span className="redDot"></span> */}
                      {assigneDetail(props.dataOfAction)}
                    </div>
                    {props.dataOfAction.status === 'pending' &&
                    status === 'pending' ? (
                      <img
                        height={20}
                        width={20}
                        src={CheckmarkFillGrey}
                        className="as-link"
                        alt="checkmark"
                        onClick={() => {
                          if (
                            // props.dataOfAction.assigne == userId &&
                            props.dataOfAction.status === 'pending' &&
                            status === 'pending'
                          ) {
                            changeActionStatus(props.dataOfAction.id);
                          }
                        }}
                      />
                    ) : (
                      <img
                        height={20}
                        width={20}
                        src={CheckmarkFillYellow}
                        alt="checkmark"
                        onClick={() => {
                          if (props.dataOfAction.assigne === userId) {
                            changeActionStatus(props.dataOfAction.id);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <FormattedText
                // className={`profile-description ${showMoreClass}`}
                // content={profile.data.bio}

                // className={'profile-description my-3'}
                className={`profile-description ${showMoreClassDescription}`}
                content={props.dataOfAction.description}
              />

              {(props.dataOfAction.description.length > 250 ||
                lines.length > 3) && (
                <>
                  {' '}
                  <span
                    className="show-more-link"
                    onClick={() =>
                      setShowMoreDescription(!showMoreDescription)
                    }>
                    {!showMore
                      ? labels.read_more[selected_lang]
                      : labels.read_less[selected_lang]}
                  </span>
                </>
              )}
              <div className="mt-2 mb-2 custom-radio-action-detail-holder">
                {props.dataOfAction.check_list_details &&
                  props.dataOfAction.check_list_details.length > 0 && (
                    <h2 className="mb-2">Check List</h2>
                  )}
                {props.dataOfAction.check_list_details &&
                  props.dataOfAction.check_list_details.length > 0 &&
                  props.dataOfAction.check_list_details.map((task, index) => (
                    <div
                      key={task.id + index}
                      className="task mt-1 d-flex align-items-center justify-content-between">
                      <div>
                        <p className="mb-0">{task.title}</p>
                      </div>
                      <div className="me-2">
                        <input
                          className="form-check-input rounded-circle as-link"
                          disabled={
                            props.dataOfAction.status === 'completed' &&
                            status === 'completed'
                          }
                          type="checkbox"
                          onChange={() => {
                            change_checklist_status(task.id);
                          }}
                          checked={checkSatusId.includes(task.id)}
                          id={`flexCheck${task.id}`}
                        />
                      </div>
                    </div>
                  ))}
              </div>

              <div className="fieldset">
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label className="form-label-title f_600 ">
                    Note
                  </Form.Label>
                </div>
                {/* || props.eventData */}
                <Form.Control
                  // required={topIcons.focus}
                  value={discription}
                  as="textarea"
                  rows={6}
                  type="text"
                  placeholder="Write note... "
                  onChange={e => {
                    handleFeedbackChange(e);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Description is required.
                </Form.Control.Feedback>
              </div>

              <div className="filter-btn-wrapper pt-2">
                <div className="invite-btn text-center">
                  <Button
                    disabled={discription === ''}
                    className="btn-dark"
                    type="submit"
                    onClick={() => {
                      addNotes();
                    }}>
                    Add Note
                  </Button>
                </div>

                <div className="fieldset mt-2 fieldset-invite-wrap">
                  {notes && notes.length > 0 && (
                    <Form.Label
                      className="form-label-title m-0"
                      controlid="validationCustom01"
                      onClick={previewNotes}>
                      Previous Notes
                    </Form.Label>
                  )}

                  {notes && notes.length > 0 && (
                    <div className="message-other-users mb-0">
                      <div className="watchers-imgs">
                        <div className="flex align-items-center notes-view mt-2">
                          <img
                            alt="h"
                            src={notes[0].creator_detail.profile_img}
                            // alt={`Image ${index}`}
                            onClick={() => {
                              openComponent(
                                'user_profile',
                                notes[0].creator_detail.id,
                              );
                            }}
                          />

                          <div>
                            <FormattedText
                              className={`profile-description ${showMoreClassFirstnote}`}
                              content={notes[0].description}
                            />

                            {(notes[0].description.length > 250 ||
                              firstNote.length > 3) && (
                              <>
                                {' '}
                                <span
                                  className="show-more-link"
                                  onClick={() =>
                                    setShowMoreFirstnote(!showMoreFirstnote)
                                  }>
                                  {!showMoreFirstnote
                                    ? labels.read_more[selected_lang]
                                    : labels.read_less[selected_lang]}
                                  {/* {profile.data.bio.length} */}
                                </span>
                              </>
                            )}
                          </div>
                          {/* <p className="p-reg m-0">{notes[0].description}</p> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="fieldset">
                  {notes.length > 1 && <div>{viewAllNotes()}</div>}
                  {notes.length > 1 && (
                    <Form.Label
                      className={` as-link m-0 ${
                        !showNotes ? 'text_green' : ''
                      }`}
                      controlid="validationCustom01"
                      onClick={previewNotes}>
                      View More Notes ({notes.length - 1})
                    </Form.Label>
                  )}
                </div>

                <div className="">
                  <Form.Label
                    className="form-label-title f_600"
                    controlid="validationCustom01">
                    Attachments
                  </Form.Label>

                  {/* <Form.Control.Feedback type="invalid">
                           Description is required.
                         </Form.Control.Feedback> */}
                  <div className=" mb-5">
                    <div className="">
                      <Slider
                        {...AttachmentsPreview}
                        className="action-sliders snapshot-slider">
                        {sliderElements}
                      </Slider>
                    </div>
                  </div>
                </div>

                {props.dataOfAction &&
                  props.dataOfAction.watchers_detail &&
                  props.dataOfAction.watchers_detail.length > 0 && (
                    <div className="fieldset">
                      <Form.Label
                        className="form-label-title"
                        controlid="validationCustom01">
                        Watchers
                      </Form.Label>

                      <div className="message-other-users mb-4">
                        <div className="watchers-imgs w-100">
                          <Slider
                            {...WatchersPreviews}
                            className={`watchers-slider w-100 ${
                              design ? 'addwidth' : ''
                            }`}>
                            {props.dataOfAction &&
                              props.dataOfAction.watchers_detail.map(
                                (item, index) => {
                                  return (
                                    <img
                                      src={item.profile_img}
                                      alt={`a ${index}`}
                                      onClick={() => {
                                        openComponent('user_profile', item.id);
                                      }}
                                    />
                                  );
                                },
                              )}
                          </Slider>

                          {/* <span className="reaction-count">10+</span> */}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>
      {showComponent === 'edit_Action' && (
        <CreateAction
          editAction={props.dataOfAction}
          onClose={() => {
            setShowComponent('');
          }}
          closeActionDeatil={props.onClose}
          type={true}
          onCloseHitApi={() => {
            props.onCloseHitApi && props.onCloseHitApi();
          }}
        />
      )}
      {showModel === 'image_pre' && modelImg && (
        <ImagePreview
          modelImg={modelImg}
          onClose={() => {
            setShowModel('');
          }}
        />
      )}

      {showModel === 'crop_modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="action"
            component="actiondetail"
            ratio={429 / 187}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            setImgUrl={setTicketImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setshowLoader}
            // actionId={dataOfAction.id}
          />
        </CustomModal>
      )}

      {showModel === 'image_preview' && (
        <SlidingPanel hidePanel={() => setShowModel('')}>
          <div className="serach-filter-wrapper">
            <div className="permission-modal-content text-left">
              <h3>Images</h3>
            </div>

            <Scrollbars>
              <div className="snapshot-preview-wrapper fix-width-forms">
                <Row>
                  {Object.entries(sliderImage).length > 0 ? (
                    Object.entries(sliderImage).length > 0 &&
                    sliderImage.map((item, index) => {
                      return (
                        <Col md={4} sm={4} key={`image-${index}`}>
                          <div className="snapshot-item">
                            <span
                              className="remove-icon"
                              onClick={() => {
                                //
                                setSliderImage(prevImgs => prevImgs.slice(1));
                                // setImgUrl('');
                                setTicketImg('');
                              }}>
                              <i className="fas fa-times" />
                            </span>

                            <img src={item} alt="" />
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <p className="text-center">No Images Found</p>
                  )}
                </Row>
              </div>
            </Scrollbars>
          </div>
        </SlidingPanel>
      )}
      {showComponent === 'user_profile' && wacherId && (
        <ProfileComponent
          userId={wacherId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
    </>
  );
};

export default ActionDetail;
