import React, {useState,} from 'react';

import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';

import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';

const Template = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showComponent, setShowComponent] = useState(false);
 
  const [workspaceData, setWorkspaceData] = useState([]);




  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
        <div className="">
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
              <div className="flex items-center justify-center h-screen bg-white">
                <div className="text-center">
                  <svg
                    className="w-16 h-16 mx-auto mb-4 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"></path>
                  </svg>
                  <h1 className="text-2xl font-semibold text-gray-800">
                    No Records Found
                  </h1>
                  <p className="text-gray-600">
                    Sorry, we couldn't find any records against this page.
                  </p>
                </div>
              </div>
              </>
            }
            secondary={<></>}
          />
            {showComponent === 'WorkSpace' && (
                <CreateWorkSpace
                  onClose={() => {
                    setShowComponent('');
                  }}
                />
              )}
        </div>
      </div>
      </div>
    </>
  );
};
export default Template;
