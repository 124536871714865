"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const VideoPresentationEvents = __importStar(require("./videopresentation/index"));
const FilePresentationEvents = __importStar(require("./filepresentation/index"));
const ConferenceEvents = __importStar(require("./conference/index"));
const RecordingEvents = __importStar(require("./recording/index"));
const CommandEvents = __importStar(require("./command/index"));
const NotificationEvents = __importStar(require("./notification/index"));
const SessionEvents = __importStar(require("./session/index"));
/**
 * @ignore
 */
const events = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, ConferenceEvents), RecordingEvents), FilePresentationEvents), VideoPresentationEvents), CommandEvents), NotificationEvents), SessionEvents);
/**
 * @ignore
 */
class EventFactory {
    constructor() { }
    static fromData(data) {
        if (data && data.type) {
            const type = data.type
                .replace(/(^[a-z])/, (p) => p.toUpperCase())
                .replace(/(\_\w)/g, (m) => m[1].toUpperCase())
                .replace(/Push/g, (m) => '')
                .replace(/Event/g, (m) => '');
            if (typeof events[type] === 'function') {
                if (typeof events[type]['fromData'] === 'function') {
                    return events[type].fromData(data);
                }
                else {
                    return new events[type](Object.assign({}, data));
                }
            }
        }
        return undefined;
    }
}
/**
 * @ignore
 */
exports.default = EventFactory;
