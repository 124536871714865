import React, {useRef, useEffect, useState} from 'react';
import {GoogleMap,  Marker} from '@react-google-maps/api';
import commonApi from '../../Services/CommonService';
import Slider from 'react-slick';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {Button, Form} from 'react-bootstrap';
import {getItem, setItem} from '../../Utils/LocalStorage';
import EventDetail from '../Referral/EventDetail';
import PurchaseTicket from '../Referral/PurchaseTicket';
import SessionSearchFilter from '../Modals/SessionSearchFilter';
import {DEFAULT_LAT, DEFAULT_LNG} from '../../Utils/env';
import {distanceInMiles} from '../../Utils/commonUtils';
import { RoiLogo } from '../../Constants/Images';

// const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: 500,
};

const center = {
  lat: DEFAULT_LAT,
  lng: DEFAULT_LNG,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const Map = props => {
  // onMapChange, markers, handleMarkerClick
  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [centerLocation, setCenterLocation] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [allMarkers, setAllMarkers] = useState(null);
  const [allData, setAllData] = useState([]);
  const [searchBar, setSearchBar] = useState('');
  const [showComponent, setShowComponent] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [gender, setGender] = useState([]);
  const [focusLocations, setFocusLocations] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [active, setActive] = useState(false);

  const [appointmentId, setAppointmentId] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [persnalTypesId, setPersnalTypesId] = useState([]);
  // const {isLoaded, loadError} = useLoadScript({
  //   googleMapsApiKey: 'AIzaSyAUwMSFtdT541UlTa2ucZ1EORtWqwz0R7s',
  //   libraries,
  // });
  // const mapRef = useRef();
  const sliderRef = useRef(null);

  const handle = item => {
    
    // const currentSlideItem = sliderRef.current.innerSlider.state.currentSlide;
    setItem('onlyZoom', 'yes');
    allData?.forEach((element, index) => {
      if (item === index) {
        setAllMarkers([
          {
            id: element.id,
            latitude: parseFloat(element.latitude),
            longitude: parseFloat(element.longitude),
          },
        ]);
        map.panTo({
          lat: parseFloat(element.latitude),
          lng: parseFloat(element.longitude),
        });
        map.setZoom(15);
      }
    });

    // // setOppr(item)
  };

  const settingsWeb = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    afterChange: handle,
  };

  // const PersonWebItem = ({item}) => {
  //   // let distance = item.distance?.slice(0, 4);
  //   return (
  //     <div className="person-web-item">
  //       {item.image && (
  //         <div className="person-img">
  //           <img
  //             src={item.image.slider_images}
  //             alt=""
  //             onClick={() => {
  //               handleEvent(item);
  //             }}
  //           />
  //         </div>
  //       )}
  //       <br />
  //       <div
  //         className="person-web-link"
  //         onClick={() => {
  //           handleEvent(item);
  //         }}>
  //           {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
  //         <a rel="noopener noreferrer">{item.session_title}</a>
  //         <br />
  //         <span>{item.category_name}</span>
  //         <br />
  //         <EventDistance item={item} />
  //       </div>
  //     </div>
  //   );
  // };
  const PersonWebItem = ({item}) => {
    // let distance = item.distance?.slice(0, 4);
    return (
      <div className='map_slider_card_main'>

    
      <div className="map_slider_card">
        {item.image && (
          <div className="person-img">
            <img
              src={item.image.slider_images}
              alt=""
              onClick={() => {
                handleEvent(item);
              }}
            />
          </div>
        )}
        <br />
        <div
          className="person-web-link"
          onClick={() => {
            handleEvent(item);
          }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a rel="noopener noreferrer">{item.session_title}</a>
          <br />
          <span>{item.category_name}</span>
          <br />
          <EventDistance item={item} />
        </div>
      </div>
         
      </div>
    );
  };

  const handleEvent = item => {
    
    setEventData(item);
    setShowComponent('event');
  };

  const apiResult = marks => {
    let markerArr = [];
    setAllData(marks);

    if (marks.length > 0) {
      setItem('onlyZoom', 'yes');
      for (let index = 0; index < marks.length; index++) {
        const element = marks[index];
        markerArr = [
          ...markerArr,
          {
            id: element.id,
            latitude: parseFloat(element.latitude),
            longitude: parseFloat(element.longitude),
          },
        ];
      }
      map.panTo({
        lat: parseFloat(marks[0].latitude),
        lng: parseFloat(marks[0].longitude),
      });
      map.setZoom(15);
    }

    setMarkers(markerArr);
    // setshowLoader(false);
  };

  const markupClick = (event, item) => {
    // setSelectedMarker(i);
    // 
    // const currentSlideItem = sliderRef.current.innerSlider.state.currentSlide;
    if (allMarkers && allMarkers.length > 1) {
      setItem('onlyZoom', 'yes');
      if (item.id === 'mine') {
      } else {
        allData?.forEach((element, index) => {
          if (item.id === element.id) {
            sliderRef.current.slickGoTo(index);
          }
        });
      }
      map.panTo({lat: item.latitude, lng: item.longitude});
      map.setZoom(15);
    } else if (allMarkers && allMarkers.length === 1 && item.id === 'mine') {
      map.panTo({lat: item.latitude, lng: item.longitude});
      map.setZoom(15);
    }
  };
  const eventListLocation = (
    lng = DEFAULT_LNG,
    latt = DEFAULT_LAT,
    qry = '',
  ) => {
    // var work = {
    //   category_id: '',
    //   events: true,
    //   focus: 'no',
    //   latitude: latt,
    //   longitude: lng,
    //   page: 1,
    //   radius: 100,
    //   search_query: qry,
    //   type: 'happening',
    // };

    console.log(isCheck,'==========>in func')

    var work = {
      category_id: isCheck,
      events: true,
      focus: active ? 'yes' : 'no',
      // gender: gender.toString(),
      gender: '',

      otherIdentifires: identiferId.toString(),
      // ethnicities: ethnicityId.toString(),
      ethnicities: '',
      city_states: focusLocations,

      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId,
      on_boarding_categories: categoriesId,

      latitude: qry ? '' : latt,
      longitude: qry ? '' : lng,
      page: 1,
      radius: 100,
      search_query: qry,
      // type: props.sessionType,
    };

    commonApi
      .event_list_location(work)
      .then(res => {
        
        if (res.events) {
          setAllData(res.events);
          let markerArr = [];
          for (let index = 0; index < res.events.length; index++) {
            const element = res.events[index];
            markerArr = [
              ...markerArr,
              {
                id: element.id,
                latitude: parseFloat(element.latitude),
                longitude: parseFloat(element.longitude),
              },
            ];
          }
          setMarkers(markerArr);
          if (qry) {
            map.panTo({
              lat: parseFloat(res.events[0].latitude),
              lng: parseFloat(res.events[0].longitude),
            });
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const onMapChange = ({center, zoom}) => {
    // setMapData({
    //   center,
    //   zoom,
    // });
    setCenterLocation({
      id: 'center',
      latitude: center.lat,
      longitude: center.lng,
    });
   
    // Send center and zoom values to API
    // ...
    if (getItem('onlyZoom') && getItem('onlyZoom') === 'yes') {
      setItem('onlyZoom', 'no');
    } else {
      eventListLocation(center.lng, center.lat);
    }
  };

  useEffect(() => {
    setItem('onlyZoom', 'no');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setCurrentLocation({
            id: 'mine',
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setCenterLocation({
            id: 'center',
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        error => {
          setCenterLocation({
            id: 'center',
            latitude: DEFAULT_LAT,
            longitude: DEFAULT_LNG,
          });
          console.log(error);
        },
      );
    } else {
      setCenterLocation({
        id: 'center',
        latitude: DEFAULT_LAT,
        longitude: DEFAULT_LNG,
      });
      
    }
    eventListLocation();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map) {
      const listener = map.addListener('center_changed', () => {
        onMapChange({
          center: {
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
          },
          zoom: map.getZoom(),
        });
      });
      return () => new window.google.maps.event.removeListener(listener);
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map,isCheck]);

  useEffect(() => {
    
    if (currentLocation && markers) {
      setAllMarkers([currentLocation, ...markers]);
    } else if (currentLocation) {
      setAllMarkers([currentLocation]);
    } else if (markers) {
      setAllMarkers(markers);
    }
  }, [markers, currentLocation]);

  const onMapLoad = map => {
    // mapRef.current = map;
    setMap(map);
  };

  const EventDistance = ({item}) => {
    // console.log(item,'========>item')
    let distance = item.distance?.slice(0, 4);
    // if (
    //   item?.latitude &&
    //   item?.longitude &&
    //   currentLocation?.latitude &&
    //   currentLocation?.longitude
    // ) {
    //   return (
    //     <span>
    //       {distanceInMiles(
    //         parseFloat(item?.latitude),
    //         parseFloat(item?.longitude),
    //         parseFloat(currentLocation?.latitude),
    //         parseFloat(currentLocation?.longitude),
    //       )}
    //       mi away
    //     </span>
    //   );
    // } else {
      return item.distance ? (
        <span>{distance}mi away</span>
      ) : (
        <span></span>
      );
    // }
  };

  console.log(isCheck,'===>isCheckisCheck')
  // if (loadError) return 'Error loading maps';
  // if (!isLoaded) return 'Loading Maps...';
  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
       
          <img src={RoiLogo} alt="" />
      
      </>
    );
  };

  return (
    <>
      <SlidingPanel
      renderData={renderProfileTagsButtons}
      showBlackHeader={true}
      lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
      firstDivStyle={{
        padding: 0,
        background:
          'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
      }} 
      hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper">
          {/* <div className="headings fix-width-forms">
            <h2>&nbsp;</h2>
          </div> */}
          <div className="search-tabs-wrapper fixed-width-inputs fix-width-forms pt-4 black-head-form-pad-left black-head-form-pad-right">
            <div className="search-form d-flex mt-0 text-center">
              <Form.Group className="search-input">
                <Form.Control
                  onChange={e => {
                    setSearchBar(e.target.value);
                    eventListLocation(
                      centerLocation.longitude,
                      centerLocation.latitude,
                      e.target.value,
                    );
                  }}
                  value={searchBar}
                  type="text"
                  placeholder="Search for an event"
                />
              </Form.Group>
              <Button>
                <i
                  className="icon-filter-icon"
                  onClick={() => setSearchFilterModal(true)}
                />
              </Button>
            </div>
          </div>
          <Scrollbars>
            <div className="pb-4 fixed-width-inputs fix-width-forms black-head-form-pad-left black-head-form-pad-right">
              {Object.entries(allData).length > 0 ? (
                Object.entries(allData).length > 0 && (
                  <div className="session-website-slider recruitment_slider event_map_slider as-link ">
                    <Slider {...settingsWeb} ref={sliderRef}>
                      {allData.map((item, index) => (
                        <PersonWebItem
                          key={`website-adasd-${index}`}
                          item={item}
                        />
                      ))}
                    </Slider>
                  </div>
                )
              ) : (
                <p className="text-center slider_no_record">No record found</p>
              )}

              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={center}
                options={options}
                onLoad={onMapLoad}
                onUnmount={() => setMap(null)}>
                {allMarkers &&
                  Object.entries(allMarkers).length > 0 &&
                  allMarkers.map((marker, index) => (
                    <Marker
                      key={index}
                      position={{lat: marker.latitude, lng: marker.longitude}}
                      icon={{
                        url:
                          marker.id === 'mine'
                            ? 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
                            : 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                      }}
                      onClick={event => markupClick(event, marker)}
                    />
                  ))}
              </GoogleMap>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showComponent === 'event' &&
        (eventData.creator_id === localStorage.getItem('id') ||
          eventData.moderator_ids.includes(localStorage.getItem('id'))) && (
          <EventDetail
            onClose={() => {
              setShowComponent('');
            }}
            getApiSessionList={props.getApiSessionList}
            // getApiSessionList(sessionType, "", !typeIs, pageNo);
            sessionType={props.sessionType}
            typeIs={props.typeIs}
            item={eventData}
          />
        )}

      {showComponent === 'event' &&
        eventData.creator_id !== localStorage.getItem('id') &&
        !eventData.moderator_ids.includes(localStorage.getItem('id')) && (
          <PurchaseTicket
            onClose={() => {
              setShowComponent('');
            }}
            item={eventData}
            // type={"refer"}
            getApiSessionList={props.getApiSessionList}
            sessionType={props.sessionType}
            typeIs={props.typeIs}
            // item={userData}
          />
        )}

      {searchFilter && (
        <SessionSearchFilter
          show={searchFilter}
          onClose={() => {
            setSearchFilterModal(false);
          }}
          sessionType={props.sessionType}
          typeIs={props.typeIs}
          fun={apiResult}
          isCheckAll={isCheckAll}
          setIsCheckAll={setIsCheckAll}
          isCheck={isCheck}
          setIsCheck={setIsCheck}
          list={list}
          setList={setList}
          type={'event'}
          latitute={centerLocation.latitude}
          lug={centerLocation.longitude}
          active={active}
          setActive={setActive}
          setFocusLocations={setFocusLocations}
          setIdentiferId={setIdentiferId}
          setEthnicityId={setEthnicityId}
          setGender={setGender}
          gender={gender}
          focusLocations={focusLocations}
          ethnicityId={ethnicityId}
          identiferId={identiferId}
          setPersnalTypesId={setPersnalTypesId}
          setCategoriesId={setCategoriesId}
          setAppointmentId={setAppointmentId}
          persnalTypesId={persnalTypesId}
          appointmentId={appointmentId}
          categoriesId={categoriesId}
        />
      )}
    </>
  );
};

export default Map;
