import React from 'react';
import {DEFAULT_IMAGE} from '../../Utils/env';

const OtherUserImages = ({otherUsers}) => {
  const currentUrl = window.location.href;
  const lastSlashIndex = currentUrl.lastIndexOf('/');
  const newSubstring = currentUrl.substring(lastSlashIndex + 1);
  // Structure must be...
  //     id : item.creator_details.id,
  //     first_name : item.creator_details.first_name,
  //     last_name : item.creator_details.last_name,
  //     profile_img : item.creator_details.profile_img
  const maxImages = 3;
  const otherUserz = otherUsers.filter(user => {
    if (newSubstring === 'history') {
      if (
        !user.status ||
        (user.status && !['rejected', 'pending'].includes(user.status))
      ) {
        //  user.status != 'pending'
        return true;
      } else {
        return false;
      }
    } else {
      if (
        !user.status ||
        (user.status && !['rejected', 'pending', 'left'].includes(user.status))
      ) {
        //  user.status != 'pending'
        return true;
      } else {
        return false;
      }
    }
  });

  return otherUserz ? (
    <div className="message-other-users">
      <div className="users-imgs">
        {otherUserz.slice(0, 3).map((userItem, index) => (
          <img
            key={`other-users-${userItem.id}-${index}`}
            src={userItem.profile_img ? userItem.profile_img : DEFAULT_IMAGE}
            alt=""
          />
        ))}
      </div>
      {otherUserz.length > maxImages && (
        <span className="users-count">+{otherUserz.length - maxImages}</span>
      )}
    </div>
  ) : (
    <></>
  );
};

export default OtherUserImages;
