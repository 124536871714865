import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {S3_EMOJI_DEV} from '../../Utils/env';
import {emojisData} from '../../Constants/emojis';
import CustomModal from '../CustomModal';
import Scrollbars from 'react-custom-scrollbars';

const EmojiModal = props => {
  // const emojisDataArray = props.emojisDataArray ? props.emojisDataArray : [];

  return (
    <CustomModal
      className="emoji-modal-wrapper"
      position="bottom"
      onClose={() => props.onClose()}>
      <div className="emoji-modal-container">
        <div className="sidebar-heading-wrapper">
          <h2>All Emoji's</h2>
        </div>
        <Scrollbars>
          <div className="emoji-list-wrapper">
            <Row className="w-100">
              {Object.entries(emojisData).length > 0 ? (
                Object.entries(emojisData).length &&
                emojisData.map((item, index) => {
                  return (
                    <Col key={`emo-${index}`} md={2} className="text-center">
                      <div className="emoji-item">
                        <img
                          onClick={() =>
                            props.sendEmoji(
                              S3_EMOJI_DEV +
                                localStorage.getItem('skinToneId') +
                                '/' +
                                item.name,
                            )
                          }
                          src={
                            S3_EMOJI_DEV +
                            localStorage.getItem('skinToneId') +
                            '/' +
                            item.name
                          }
                          alt=""
                        />
                      </div>
                    </Col>
                  );
                })
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Scrollbars>
      </div>
    </CustomModal>
  );
};

export default EmojiModal;
