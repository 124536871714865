"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const events_1 = require("events");
const debug_1 = require("debug");
/**
 *
 * @name EventBus
 * @description Really simple event bus base on EventEmitter
 * @ignore
 */
class EventBus extends events_1.EventEmitter {
    constructor() {
        super();
    }
    /**
     * @ignore
     */
    register(object, events) {
        events.map((type) => {
            if (object.__proto__['on' + type]) {
                this.on(type, object.__proto__['on' + type].bind(object));
            }
            else if (object['on' + type]) {
                this.on(type, object['on' + type].bind(object));
            }
            else {
                this.on(type, (e) => object.emit(type.toString(), e));
            }
        });
    }
    send(event) {
        !event && debug_1.debug('vxt:events')('Received undefined event');
        event && debug_1.debug('vxt:events')('EventBus: ' + event.type);
        event && this.emit(event.type, event);
    }
}
exports.default = EventBus;
