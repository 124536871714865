import React, {useState, useEffect} from 'react';
// import {download} from '../../Constants/Images';
// import {addUser} from '../../Constants/Images';
import {Button, Col, Container, Row, Dropdown, Form} from 'react-bootstrap';
import CustomAccordion from '../../CustomAccordion';

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {errorMessage} from '../../../Utils/ToastMessages';
import {flushSync} from 'react-dom';

const Menus = props => {
  const [type, setType] = useState('2');
  const [roleName, setRoleName] = useState('Owner');
  const [selectedRolesAlias, setSelecedRolesAlias] = useState(
    props.companyRolesMenus.map(({role_alias}) => role_alias),
  );

  const [selectedRolesId, setSelecedRolesId] = useState(
    props.companyRolesMenus.map(({role_type_id}) => role_type_id),
  );

  const [menuType, setMenuType] = useState('1');

  const [checked, setCheked] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState([]);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const [aliasRolesMenus, setAliasRolesMenus] = useState([]);
  const [aliasType, setAliasType] = useState('all');
  const [rolesDropdown, setRolesDropdown] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(true);

  const options = [
    {id: 1, label: 'Option 1'},
    {id: 2, label: 'Option 2'},
    {id: 3, label: 'Option 3'},
    {id: 4, label: 'Option 4'},
    {id: 5, label: 'Option 5'},
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setSelectedOptions(rolesDropdown.map(item => item.alias));
    if (isCheckAll) {
      setSelectedOptions([]);
    }
  };

  const handleOptionChange = event => {
    const optionId = event.target.value;
    const isChecked = event.target.checked;

    setAliasType(event.target.value);
    if (isChecked) {
      setSelectedOptions([...selectedOptions, optionId]);
    } else {
      setSelectedOptions(selectedOptions.filter(id => id !== optionId));
    }
  };

  useEffect(() => {
    if (selectedOptions.length > 0) {
      if (selectedOptions.length === rolesDropdown.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    } else {
      setIsCheckAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    const filteredRoles = props.systemRoles.filter(item => item.id === type);
    // const rolesWithAll = [ { alias: 'all'}, ...filteredRoles];
    setSelectedOptions(filteredRoles.map(item => item.alias));
    setRolesDropdown(filteredRoles);
    // setAliasType('all');
    
    // useEffect Dependency
  }, [props.hitApiData]);

  useEffect(() => {
    console.log(props.companyRolesMenus, 'props.companyRolesMenus-line-262');
    
      if(aliasType !=='all'){
        const filterItem = props.companyRolesMenus.filter(
          item =>
            item.role_alias === aliasType &&
            item.role_type_id === type,
        );
  
        console.log(filterItem, 'filterItem');
        if (
          !selectedRolesAlias.includes(aliasType) &&
          !selectedRolesId.includes(
            filterItem.length > 0 && filterItem[0].role_type_id,
          )
        ) {
          setSelectedMenuId([]);
          setSelectedMenuItems([]);
        } else {
          const filteredarray = props.companyRolesMenus.filter(
            item =>
              item.role_alias === aliasType &&
              item.role_type_id === filterItem[0]?.role_type_id,
          );
          console.log(
            filteredarray,
            'filteredarray',
            props.companyRolesMenus,
            aliasType,
            filterItem[0]?.role_type_id,
          );
  
         
          let menusArray = filteredarray[0]?.menu_ids.split(',');
          console.log(menusArray, 'menusarray');
  
          
  
          if (menusArray?.length > 0) {
            setSelectedMenuId(menusArray);
            setSelectedMenuItems(filteredarray[0]?.permissions);
          }
        }
      }else{
        const permissionsArray = props.companyRolesMenus.filter(
          item => item.role_type_id === type,
        );
        console.log(permissionsArray, 'permissionsArray');
        setAliasRolesMenus(permissionsArray);
      }
      
    // }

    // setRoleName('Owner')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hitApiData]);

  useEffect(() => {
    setSelecedRolesAlias(
      props.companyRolesMenus.map(({role_alias}) => role_alias),
    );
    setSelecedRolesId(
      props.companyRolesMenus.map(({role_type_id}) => role_type_id),
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.companyRolesMenus]);

  const handleRoleMenus = value => {
    setType(value);
    setMenuType('1');
    console.log(value, 'value');
    setAliasType('all');
    const filteredRoles = props.systemRoles.filter(item => item.id === value);
    // const rolesWithAll = [ { alias: 'all'}, ...filteredRoles];
    setSelectedOptions(filteredRoles.map(item => item.alias));
    setRolesDropdown(filteredRoles);
    setRoleName(props.rolesTypes.find(item => item.id === value).role_name);

    const permissionsArray = props.companyRolesMenus.filter(
      item => item.role_type_id === value,
    );
    console.log(permissionsArray, 'permissionsArray');

    const aliasRoles = filteredRoles.map(({alias}) => alias);

    const orginialAlias = permissionsArray.map(({role_alias}) => role_alias);

    const isSubset = aliasRoles.every(role => orginialAlias.includes(role));

    const newAlias = aliasRoles.filter(role => !orginialAlias.includes(role));
    console.log(newAlias, 'newAlias');

    if (!isSubset) {
      const newArray = [...props.companyRolesMenus];

      newAlias.forEach((role, index) => {
        newArray.push({
          role_type_id: value,
          role_alias: role,
          menu_ids: '',
          permissions: [],
        });
      });

      props.setCompanyRolesMenus(newArray);
      // newArray.filter(item => item.role_type_id === value);
      setAliasRolesMenus(newArray.filter(item => item.role_type_id === value));
    } else {
      setAliasRolesMenus(permissionsArray);
    }

    setSelectedMenuId([]);
    setSelectedMenuItems([]);
  };

  const handleSubRolesMenus = MiniItem => {
    console.log(MiniItem, 'itemhandleSubRolesMenus');
    setAliasType(MiniItem.alias ? MiniItem.alias : 'all');

    if (MiniItem === 'all') {
      setAliasRolesMenus(
        props.companyRolesMenus.filter(
          MiniItem => MiniItem.role_type_id === type,
        ),
      );
    } else {
      const filterItem = props.companyRolesMenus.filter(
        item =>
          item.role_alias === MiniItem.alias &&
          item.role_type_id === MiniItem.id,
      );

      console.log(filterItem, 'filterItem');
      if (
        !selectedRolesAlias.includes(MiniItem.alias) &&
        !selectedRolesId.includes(
          filterItem.length > 0 && filterItem[0].role_type_id,
        )
      ) {
        setSelectedMenuId([]);
        setSelectedMenuItems([]);
      } else {
        const filteredarray = props.companyRolesMenus.filter(
          item =>
            item.role_alias === MiniItem.alias &&
            item.role_type_id === filterItem[0]?.role_type_id,
        );
        console.log(
          filteredarray,
          'filteredarray',
          props.companyRolesMenus,
          MiniItem.alias,
          filterItem[0]?.role_type_id,
        );

        // setRoleName(filteredarray[0].role_name);
        let menusArray = filteredarray[0]?.menu_ids.split(',');
        console.log(menusArray, 'menusarray');

        // const filterData = props.menuItems.filter(item =>
        //   menusArray.some(dataItem => {
        //     return dataItem === item.id;
        //   }),
        // );

        // const sortedData = menusArray
        //   .map(id => filterData.find(item => item.id === id))
        //   .filter(Boolean);
        // const filterData =
        //   props.dataForpermessions?.length > 0 &&
        //   props.dataForpermessions.filter(item =>
        //     item.permissions.some(permission =>
        //       menusArray.includes(permission.menu_id),
        //     ),
        //   );
        // const sortedData =
        //   filterData?.length > 0 &&
        //   filterData
        //     .flatMap(item =>
        //       item.permissions.find(permission =>
        //         menusArray.includes(permission.menu_id),
        //       ),
        //     )
        //     .filter(Boolean);

        if (menusArray?.length > 0) {
          setSelectedMenuId(menusArray);
          setSelectedMenuItems(filteredarray[0]?.permissions);
        }
      }
    }
  };

  const handleAlias = value => {
    setAliasType(value);
  };

  const handleMenus = (menuId, rollId, roleAlias) => {
    if (rolesDropdown.length > 0) {
      if (aliasType === 'all') {
        const menuIdsArrays = aliasRolesMenus.map(obj =>
          obj.menu_ids.split(','),
        );

        const commonMenuIds = menuIdsArrays.reduce((acc, current) => {
          return acc.filter(id => current.includes(id));
        });

        const filteredArray = props.menuItems.filter(
          item => item.id === menuId,
        );
        const item = filteredArray.length > 0 ? filteredArray[0] : null;

        if (!commonMenuIds.includes(menuId)) {
          const updatedRolesTypes = props.companyRolesMenus.map(role => {
            if (role.role_type_id === rollId) {
              const selectedMenuId = role.menu_ids.split(',');

              if (item && !selectedMenuId.includes(item.id)) {
                const updatedMenuItems = [
                  ...role.permissions,
                  {
                    ...item,
                    menu_id: menuId,
                    menu_permissions: {
                      create: true,
                      customer_row: true,
                      analytics_row: true,
                      edit_company: true,
                      live_chat:true,
                      add_poc : true,
                    },
                    role_alias: role.role_alias,
                    role_id: rollId,
                  },
                ];

                const existingMenusArray = role.menu_ids
                  ? role.menu_ids.split(',')
                  : [];
                const existingMenusSet = new Set(existingMenusArray);
                existingMenusSet.add(menuId);
                const updatedMenus = Array.from(existingMenusSet).join(',');

                return {
                  ...role,
                  menu_ids: updatedMenus,
                  permissions: updatedMenuItems,
                };
              }
            }
            return role;
          });

          setAliasRolesMenus(
            updatedRolesTypes.filter(
              MiniItem => MiniItem.role_type_id === rollId,
            ),
          );
          props.setCompanyRolesMenus(updatedRolesTypes);
        } else {
          errorMessage('You have already added this Menu');
        }
      } else {
        if (
          !selectedRolesAlias.includes(roleAlias) ||
          !selectedRolesId.includes(rollId)
        ) {
          setSelecedRolesId(previous => [...previous, rollId]);
          setSelecedRolesAlias(previous => [...previous, roleAlias]);

          const filteredarray = props.menuItems.filter(
            item => item.id === menuId,
          );
          let item = filteredarray[0];

          console.log(item, 'item');

          if (!selectedMenuId.includes(item.id)) {
            setSelectedMenuId(previous => [...previous, item.id]);

            const updatedMenuItems = [
              ...selectedMenuItems,
              {
                ...item,
                menu_id: menuId,
                menu_permissions: {
                  create: true,
                  customer_row: true,
                  analytics_row: true,
                  edit_company: true,
                  live_chat:true,
                  add_poc : true,
                },
                role_alias: aliasType,
                role_id: rollId,
              },
            ];

            setSelectedMenuItems(updatedMenuItems);

            props.setCompanyRolesMenus(previous => [
              ...previous,
              {
                role_type_id: rollId,
                role_alias: aliasType,
                // role_alias: aliasType === 'all'? '' : aliasType,
                menu_ids: menuId,
                permissions: updatedMenuItems,
              },
            ]);
          } else {
            errorMessage('You have already added this Menu');
          }
        } else {
          const filteredarray = props.menuItems.filter(
            item => item.id === menuId,
          );
          let item = filteredarray[0];

          console.log(item, 'item');

          if (!selectedMenuId.includes(item.id)) {
            setSelectedMenuId(previous => [...previous, item.id]);

            const updatedMenuItems = [
              ...selectedMenuItems,
              {
                ...item,
                menu_id: menuId,
                menu_permissions: {
                  create: true,
                  customer_row: true,
                  analytics_row: true,
                  edit_company: true,
                  live_chat:true,
                  add_poc : true,
                },
                role_alias: aliasType,
                role_id: rollId,
              },
            ];

            setSelectedMenuItems(updatedMenuItems);

            const updatedRolesTypes = props.companyRolesMenus.map(role => {
              if (role.role_alias === roleAlias) {
                const existingMenusArray = role.menu_ids
                  ? role.menu_ids.split(',')
                  : [];
                const existingMenusSet = new Set(existingMenusArray);
                existingMenusSet.add(menuId);
                const updatedMenus = Array.from(existingMenusSet).join(',');
                return {
                  ...role,
                  menu_ids: updatedMenus,
                  permissions: updatedMenuItems,
                };
              }
              return role;
            });

            props.setCompanyRolesMenus(updatedRolesTypes);
          } else {
            errorMessage('You have already added this Menu');
          }
        }
      }
    } else {
      errorMessage('Please Add Role');
    }
  };

  const removeMenus = item => {
    console.log('comming item', item);

    if (roleName === 'Owner' && aliasType === 'co-founder') {
      
      if (selectedMenuItems.length > 1) {
        const updatedMenuIds = selectedMenuId.filter(id => id !== item.menu_id);
        const updatedMenuItems = selectedMenuItems.filter(
          MiniItem => MiniItem.menu_id !== item.menu_id,
        );
        setSelectedMenuItems(updatedMenuItems);
        console.log(updatedMenuIds, 'updatedMenuIds');
        setSelectedMenuId(updatedMenuIds);
        // Remove the item from props.menuPermesions

        const updatedRolesTypes = props.companyRolesMenus.map(role => {
          if (role.role_alias === item.role_alias) {
            // const existingMenusArray = role.menu_ids
            //   ? role.menu_ids.split(',')
            //   : [];
            // const updatedMenus = existingMenusArray.filter(
            //   MiniItem => MiniItem !== item.id,
            // );
            return {
              ...role,
              menu_ids: updatedMenuIds.toString(),
              permissions: updatedMenuItems,
            };
          }
          return role;
        });

        props.setCompanyRolesMenus(updatedRolesTypes);
      } else {
        errorMessage('At least one menu required for owner');
      }
    } else {
      const filteredItem = props.companyRolesMenus.filter(
        Minitem =>
          Minitem.role_alias === item.role_alias &&
          Minitem.role_type_id === item.role_id,
      );

      const matchedItem = filteredItem[0];
      console.log(filteredItem, 'filteredItem');
        console.log(matchedItem, 'matchedItem');

      if (
        aliasType === 'all' &&
        // roleName === 'Owner' &&
        matchedItem.role_alias === 'co-founder'
        &&  matchedItem.role_name === 'Owner'
      ) {

       
        if (matchedItem.permissions.length > 1) {
          const updatedMenuIds = matchedItem.menu_ids
            .split(',')
            .filter(id => id !== item.menu_id);
          const updatedMenuItems = matchedItem.permissions.filter(
            MiniItem => MiniItem.menu_id !== item.menu_id,
          );

          console.log(updatedMenuIds, 'updatedMenuIds');
          console.log(updatedMenuItems, 'updatedMenuItems');

          const updatedRolesTypes = props.companyRolesMenus.map(role => {
            if (role.role_alias === item.role_alias) {
              return {
                ...role,
                menu_ids: updatedMenuIds.toString(),
                permissions: updatedMenuItems,
              };
            }
            return role;
          });

          console.log(updatedRolesTypes, 'updatedRolesTypes');

          setAliasRolesMenus(
            updatedRolesTypes.filter(
              MiniItem => MiniItem.role_type_id === item.role_id,
            ),
          );
          props.setCompanyRolesMenus(updatedRolesTypes);
        } else {
          errorMessage('At least one menu required for owner');
        }
      } else if (
        aliasType === 'all' &&
        matchedItem.role_alias !== 'co-founder'
      ) {

      
        const updatedMenuIds = matchedItem.menu_ids
          .split(',')
          .filter(id => id !== item.menu_id);
        const updatedMenuItems = matchedItem.permissions.filter(
          MiniItem => MiniItem.menu_id !== item.menu_id,
        );

        console.log(updatedMenuIds, 'updatedMenuIds');
        console.log(updatedMenuItems, 'updatedMenuItems');

        const updatedRolesTypes = props.companyRolesMenus.map(role => {
          if (role.role_alias === item.role_alias) {
            return {
              ...role,
              menu_ids: updatedMenuIds.toString(),
              permissions: updatedMenuItems,
            };
          }
          return role;
        });

        console.log(updatedRolesTypes, 'updatedRolesTypes');

        setAliasRolesMenus(
          updatedRolesTypes.filter(
            MiniItem => MiniItem.role_type_id === item.role_id,
          ),
        );
        props.setCompanyRolesMenus(updatedRolesTypes);
      } else {
        
       if(selectedMenuItems.length > 0){

        const updatedMenuIds = selectedMenuId.filter(id => id !== item.menu_id);
        const updatedMenuItems = selectedMenuItems.filter(
          MiniItem => MiniItem.menu_id !== item.menu_id,
        );
        setSelectedMenuItems(updatedMenuItems);
        console.log(updatedMenuIds, 'updatedMenuIds');
        setSelectedMenuId(updatedMenuIds);
        // Remove the item from props.menuPermesions

        const updatedRolesTypes = props.companyRolesMenus.map(role => {
          if (role.role_alias === item.role_alias) {
            // const existingMenusArray = role.menu_ids
            //   ? role.menu_ids.split(',')
            //   : [];
            // const updatedMenus = existingMenusArray.filter(
            //   MiniItem => MiniItem !== item.id,
            // );
            return {
              ...role,
              menu_ids: updatedMenuIds.toString(),
              permissions: updatedMenuItems,
            };
          }
          return role;
        });

        props.setCompanyRolesMenus(updatedRolesTypes);
       }
        
      }
    }
  };

  useEffect(() => {
    if (aliasRolesMenus.length > 0) {
      setAllPermissions(aliasRolesMenus.flatMap(role => role.permissions));
    }
  }, [aliasRolesMenus]);

  // console.log(props.menuPermesions, 'permision data');

  console.log(rolesDropdown, 'rolesDropdown');
  console.log(allPermissions, 'setAllPermissions');

  useEffect(() => {
    // alert('')

    // console.log(props.companyRolesMenus, 'props.companyRolesMenus-line-262');
    // setType('2');
    // setMenuType('1');
    // const filteredarray = props.companyRolesMenus.filter(
    //   item => item.role_type_id === '2',
    // );

    // let menusArray =
    //   filteredarray.length > 0 ? filteredarray[0].menu_ids.split(',') : [];
    // console.log(menusArray, 'menusarray');

    // const filterData = Array_fOR_tEST.filter(item =>
    //   menusArray.some(dataItem => {
    //     return dataItem === item.id;
    //   }),
    // );

    // const sortedData = menusArray
    //   .map(id => filterData.find(item => item.id === id))
    //   .filter(Boolean);

    const filteredarray = props.companyRolesMenus.filter(
      item => item.role_type_id === '2',
    );

    let menusArray =
      filteredarray.length > 0
        ? filteredarray.map(item => item.menu_ids.split(','))
        : [];
    menusArray = menusArray.flat(); // Flatten the array
    console.log(menusArray, 'menusarray');

    // const filterData =
    //   props.dataForpermessions?.length > 0 &&
    //   props.dataForpermessions.filter(item =>
    //     item.permissions.some(permission =>
    //       menusArray.includes(permission.menu_id),
    //     ),
    //   );
    // const filterData = Array_fOR_tEST.filter(item =>
    //   menusArray.some(dataItem => {
    //     return dataItem === item.menu_id;
    //   }),
    // );
    // const sortedData =
    //   filterData?.length > 0 &&
    //   filterData
    //     .flatMap(item =>
    //       item.permissions.find(permission =>
    //         menusArray.includes(permission.menu_id),
    //       ),
    //     )
    //     .filter(Boolean);

    //     const filterData = Array_fOR_tEST.filter(item =>
    //   menusArray.some(dataItem => {
    //     return dataItem === item.id;
    //   }),
    // );

    // const sortedData = menusArray
    //   .map(id => filterData.find(item => item.menu_id === id))
    //   .filter(Boolean);

    // console.log(sortedData, 'sortedData');
    // console.log(menusArray, 'menusArray');

    // setSelectedMenuId(menusArray);
    // setSelectedMenuItems([]);
    // setSelectedMenuItems(sortedData.length > 0 ? sortedData : []);
    // setSelecedRolesId(
    //   props.companyRolesMenus.map(({role_type_id}) => role_type_id),
    // );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hitApiData]);

  const onDragEnd = result => {
    if (!result.destination) return;

    console.log(result, 'resultonfrag');
    if (aliasType === 'all') {
      const filteredItem = aliasRolesMenus.filter(
        Minitem => Minitem.role_alias === result.source.droppableId,
      );
      const matchedItem = filteredItem[0];
      console.log(matchedItem, 'matchedItem');

      const updatedItems = matchedItem.permissions;
      const [movedItem] = updatedItems.splice(result.source.index, 1);
      console.log(movedItem, 'movedItem');
      updatedItems.splice(result.destination.index, 0, movedItem);
      console.log(updatedItems, 'updatedItems');

      const menusIds = updatedItems.map(item => item.menu_id);

      console.log(menusIds, 'menusIds');
      const updatedRolesTypes = props.companyRolesMenus.map(role => {
        if (role.role_alias === movedItem.role_alias) {
          return {
            ...role,
            menu_ids: menusIds.toString(),
            permissions: updatedItems,
          };
        }
        return role;
      });

      setAliasRolesMenus(
        updatedRolesTypes.filter(
          MiniItem => MiniItem.role_type_id === movedItem.role_id,
        ),
      );

      props.setCompanyRolesMenus(updatedRolesTypes);
    } else {
      const updatedItems = [...selectedMenuItems];
      const [movedItem] = updatedItems.splice(result.source.index, 1);
      console.log(movedItem, 'movedItem');
      updatedItems.splice(result.destination.index, 0, movedItem);
      console.log(updatedItems, 'updatedItems');
      setSelectedMenuItems(updatedItems);
      const menusIds = updatedItems.map(item => item.menu_id);

      console.log(menusIds, 'menusIds');
      const updatedRolesTypes = props.companyRolesMenus.map(role => {
        if (role.role_alias === movedItem.role_alias) {
          return {
            ...role,
            menu_ids: menusIds.toString(),
            permissions: updatedItems,
          };
        }
        return role;
      });

      props.setCompanyRolesMenus(updatedRolesTypes);
    }
  };
  console.log(props.companyRolesMenus, 'rolesMenusList');
  console.log(selectedMenuItems, 'selectedMenuItems');
  console.log(selectedRolesAlias, 'selectedRolesAlias');
  console.log(selectedRolesId, 'selectedRolesId');

  const handleEditPermisions = (menuId, type, item) => {

    
    if (aliasType === 'all') {
      const filteredItem = props.companyRolesMenus.filter(
        Minitem =>
          Minitem.role_alias === item.role_alias &&
          Minitem.role_type_id === item.role_id,
      );

      const matchedItem = filteredItem[0];

      console.log(matchedItem, 'matchedItem');

      const updatedMenuItems = matchedItem.permissions.map(menuItem => {
        if (menuItem.menu_id === menuId) {
          
          let updatedPermission = {...menuItem.menu_permissions};

           if (type === 'add_poc_for') {
            updatedPermission.add_poc = !updatedPermission.add_poc;
          }
          else if (type === 'customer_row') {
            updatedPermission.customer_row = !updatedPermission.customer_row;
          } else if (type === 'analytics_row') {
            updatedPermission.analytics_row = !updatedPermission.analytics_row;
          } else if (type === 'create') {
            updatedPermission.create = !updatedPermission.create;
          } else if (type === 'edit_company') {
            updatedPermission.edit_company = !updatedPermission.edit_company;
          } 
          else if (type === 'live_chat') {
            updatedPermission.live_chat = !updatedPermission.live_chat;
          }

          return {
            ...menuItem,
            menu_permissions: updatedPermission,
            // Update timestamp as needed
          };
        }
        return menuItem;
      });

      const updatedRolesTypes = props.companyRolesMenus.map(role => {
        if (role.role_alias === item.role_alias) {
          return {...role, permissions: updatedMenuItems};
        }
        return role;
      });

      setAliasRolesMenus(
        updatedRolesTypes.filter(
          MiniItem => MiniItem.role_type_id === item.role_id,
        ),
      );
      props.setCompanyRolesMenus(updatedRolesTypes);
    } else {
      const updatedMenuItems = selectedMenuItems.map(menuItem => {
        if (menuItem.menu_id === menuId) {

          console.log(menuId,'menuId')
          let updatedPermission = {...menuItem.menu_permissions};

          if (type === 'customer_row') {
            updatedPermission.customer_row = !updatedPermission.customer_row;
          } else if (type === 'analytics_row') {
            updatedPermission.analytics_row = !updatedPermission.analytics_row;
          } else if (type === 'create') {
            updatedPermission.create = !updatedPermission.create;
          } else if (type === 'edit_company') {
            updatedPermission.edit_company = !updatedPermission.edit_company;
          }  else if (type === 'live_chat') {
            updatedPermission.live_chat = !updatedPermission.live_chat;
          } else if (type === 'add_poc_for') {
           updatedPermission.add_poc = !updatedPermission.add_poc;
         }

          return {
            ...menuItem,
            menu_permissions: updatedPermission,
            // Update timestamp as needed
          };
        }
        return menuItem;
      });

      setSelectedMenuItems(updatedMenuItems);

      const updatedRolesTypes = props.companyRolesMenus.map(role => {
        if (role.role_alias === item.role_alias) {
          return {...role, permissions: updatedMenuItems};
        }
        return role;
      });

      props.setCompanyRolesMenus(updatedRolesTypes);
    }
  };

  console.log(props.rolesTypes, 'props.rolesTypes');
  console.log(aliasType, 'aliasType');
  console.log(roleName, 'roleName');
  console.log(aliasRolesMenus, 'aliasRolesMenus');

  return (
    <>
      <div className="company-info-screen template_menus_screen">
        <div>
          <div className="slider3-container">
            <div className="slider3-Top-container">
              <label>
                What does each role of your company care about the most?
              </label>
            </div>

            <div>
              <label className="role-label">
                Based on your selections and ordering, your team will see only
                what’s necessary
              </label>
              <div className="slider2-inner-container customized-slider2-inner-container">
                <div
                  className="menus-dropdowns roles_dropdown"
                  style={{
                    paddingRight: '24px',
                  }}>
                  <Dropdown
                    className="select-wrapper"
                    onSelect={(eventKey, event) => {
                      handleRoleMenus(eventKey);
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary">
                    <div className="text-container">
                      {type
                        ? props.rolesTypes.find(item => item.id === type)
                            ?.role_name
                        : 'Select Role'}
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {props.rolesTypes.map((item, index) => (
                        <Dropdown.Item
                          key={`sessionCategories-${index}`}
                          eventKey={item.id}
                          active={item.id === type}>
                          {item.role_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {rolesDropdown.length > 0 && (
                  <div className="menus-dropdowns roles_dropdown">
                    <Dropdown
                      className="select-wrapper"
                      onSelect={(eventKey, event) => {
                        const data =
                          eventKey === 'all' ? 'all' : JSON.parse(eventKey);
                        handleSubRolesMenus(data);
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                      <div class="text-container">
                        {type && aliasType
                          ? aliasType === 'all'
                            ? 'All'
                            : rolesDropdown.find(
                                item => item.alias === aliasType,
                              )?.alias
                          : 'Select Role'}
                          </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          key="all-option"
                          eventKey="all"
                          active={aliasType === 'all'}>
                          All
                        </Dropdown.Item>

                        {rolesDropdown.map((item, index) => (
                          <Dropdown.Item
                            key={`sessionCategorieswww-${index}`}
                            eventKey={JSON.stringify(item)}
                            active={item.alias === aliasType}>
                            {item.alias}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

                <span> Menu Options</span>

                <div className="menus-dropdowns roles_dropdown">
                  <Dropdown
                    className="select-wrapper"
                    onSelect={(eventKey, event) => {
                      setMenuType(eventKey);
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary">
                    <div class="text-container">{type
                        ? props.menuItems.find(item => item.id === menuType)
                            ?.menu_name
                        : 'No Menu Against Role'}</div>
                      {/* {type
                        ? props.menuItems.find(item => item.id === menuType)
                            ?.menu_name
                        : 'No Menu Against Role'} */}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="scrollable-dropdown">
                      {props.menuItems.map((item, index) => (
                        <Dropdown.Item
                          key={`sessionCategories-${index}`}
                          eventKey={item.id.toString()}
                          active={item.id === menuType}>
                          {item.menu_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="btn-add-type">
                  <Button
                    className="new-btn btn-green btn_padding"
                    onClick={() => {
                      handleMenus(menuType, type, aliasType);
                    }}>
                    Add Menus
                  </Button>
                </div>
              </div>
            </div>
            {aliasType !== 'all' && selectedMenuItems?.length > 0 && (
              <div className="slider-3-roles-conatiner">
                <label>
                  <b>{roleName} Menus</b>
                </label>

                <label>
                  Drag the menus up and down to determine menu and tab order
                </label>

                <div className="mt-3">
                  <div className="system-role-list">
                    <Container className="mb-3">
                      <Row>
                        <Col md={4}>
                          <b>Available Menus</b>
                        </Col>
                      </Row>
                    </Container>

                    <div className="menus-accordian">
                      <>
                        <Container className="my-3">
                          {Object.entries(selectedMenuItems).length > 0 && (
                            <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId="item-list">
                                {provided => (
                                  <ul
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}>
                                    {selectedMenuItems.map((item, index) => (
                                      <Draggable
                                        key={`${item.menu_id}-${index}`}
                                        draggableId={item.menu_id}
                                        index={index}
                                        roleAlias={item.role_alias}>
                                        {provided => (
                                          <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}>
                                            <CustomAccordion
                                              item={item}
                                              removeMenus={removeMenus}
                                              className="dragable"
                                              btn={'btn'}
                                              checked={checked}
                                              setCheked={setCheked}
                                              handleEditPermisions={
                                                handleEditPermisions
                                              }
                                            />
                                          </li>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </ul>
                                )}
                              </Droppable>
                            </DragDropContext>
                          )}
                        </Container>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {aliasType === 'all' &&
              aliasRolesMenus?.length > 0 &&
              allPermissions.length > 0 && (
                <div className="slider-3-roles-conatiner">
                  <label>
                    <b>{roleName} Menus</b>
                  </label>

                  <label>
                    Drag the menus up and down to determine menu and tab order
                  </label>

                  <div className="mt-3">
                    <div className="system-role-list">
                      <Container className="mb-3">
                        <Row>
                          <Col md={4}>
                            <b>Available Menus</b>
                          </Col>
                        </Row>
                      </Container>

                      {aliasRolesMenus.length > 0 &&
                        aliasRolesMenus.map((roleItem, index) => (
                          <div
                            className="menus-accordian all-menus-accordian"
                            key={index}
                            style={{marginBottom: '20px'}}>
                            <>
                              <Container className="my-3">
                                {roleItem.permissions.length > 0 && (
                                  <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable
                                      droppableId={roleItem.role_alias}>
                                      {provided => (
                                        <ul
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}>
                                          {roleItem.permissions.map(
                                            (item, index) => (
                                              <Draggable
                                                key={`${item.menu_id}-${index}`}
                                                draggableId={item.menu_id}
                                                index={index}>
                                                {provided => (
                                                  <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <CustomAccordion
                                                      item={item}
                                                      removeMenus={removeMenus}
                                                      className="dragable"
                                                      btn={'btn'}
                                                      checked={checked}
                                                      setCheked={setCheked}
                                                      handleEditPermisions={
                                                        handleEditPermisions
                                                      }
                                                      type={'allMenus'}
                                                    />
                                                  </li>
                                                )}
                                              </Draggable>
                                            ),
                                          )}
                                          {provided.placeholder}
                                        </ul>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                )}
                              </Container>
                            </>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Menus;
