import React,{useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {useState} from 'react';
import {Pannels} from '../../Components';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Scrollbars from 'react-custom-scrollbars';
import CreateForm from '../../Components/ROIForm/CreateForm';
import Slider from 'react-slick';
import {rewardStar} from '../../Constants/Images';
import CreateDeal from '../../Components/Deals/CreateDeal';

const FormPage = props => {
  const [threeDots, setThreeDots] = useState(false);
  const [chatSearchQry, setChatSearchQry] = useState('');
  const [tabType, setTabType] = useState('pending');
  const [showComponent, setShowComponent] = useState('');
  const [currentComponent, setCurrentComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'On-Time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Duration',
      money: '5m 20s',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Completion',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 15px',
  };

  useEffect(() => {
    if (currentComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [currentComponent]);

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
          workspaceData={workspaceData}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>

            <Pannels
              isFloated={isFloated}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              toggleFloatedPanel={toggleFloatedPanel}
              primary={
                <>
                  <div className="form-page primary_panel_wraper primary_panel_content">
                    <div className="messages-header mb-2">
                      <div className="event-tabs tabs-style-2 mb-2">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'pending' ? 'active' : ''}
                              onClick={() => {
                                setTabType('pending');
                              }}>
                              Pending
                            </Button>
                          </li>

                          <li>
                            <Button
                              className={tabType === 'complete' ? 'active' : ''}
                              onClick={() => {
                                setTabType('complete');
                              }}>
                              Complete
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        <span
                          className={
                            showComponent === 'create-form'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => setCurrentComponent('create-form')}
                          />
                        </span>
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    {threeDots && (
                      <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                        <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Who are you looking for?"
                              value={chatSearchQry}
                              onChange={e => {
                                setChatSearchQry(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Button>
                            <i
                              className="icon-filter-icon"
                              // onClick={() => setSearchFilterModal(true)}
                            />
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="refferal-list-wrapper">
                      <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide>
                        <div className="form-page-content">
                          <div className="form-card-main">
                            <div className="form-listing-card">
                              <div className="line-bar"></div>
                              <h1> Form Title</h1>

                              <div>Category</div>
                              <p>This is a description about this form...</p>
                            </div>

                            <div className="form-card-bottom">
                              <span> Required</span>

                              <span> Tue, December 5 2024</span>
                            </div>
                          </div>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={
                <>
                  {currentComponent === 'create-form' && (
                    <CreateForm
                      onClose={() => {
                        setCurrentComponent('');
                      }}
                    />
                  )}
                  {currentComponent === 'create-deal' && (
                    <CreateDeal
                      onClose={() => {
                        setCurrentComponent('');
                      }}
                    />
                  )}
                </>
              }
            />

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default FormPage;
