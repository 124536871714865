/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { RoiLogo, RoiArrowLogo} from '../Constants/Images';
import {getItem} from '../Utils/LocalStorage';
import {labels} from '../Constants/Translations';
import {selected_lang} from '../Utils/env';
// import {useSelector} from 'react-redux';


const Footer = () => {
  // const currentUserInfo = useSelector(state => state.currentUserData);
  return (
    <div className="footer-wrapper">
      <Container>
        <Row>
          <Col>
            {/* <div className="footer-text">
              Follow{' '}
              {currentUserInfo && currentUserInfo.first_name && fixName(currentUserInfo.first_name, currentUserInfo.last_name)}{' '}
              on BUILD
            </div> */}

            <div className="footer-text">
            
            <div className='footer-content'>
            <img src={RoiArrowLogo} alt="" />
           Add My Company
            </div>
            </div>
          </Col>
          <Col>
            <div className="footer-logo text-center">
              <a href="/">
                <img className="m-auto" src={RoiLogo} alt="" />
              </a>
            </div>
          </Col>
          <Col>
            <div className="footer-links">
              {getItem('id') ? (
                <div
                  onClick={() => {
                    localStorage.clear();
                  }}>
                  <a href="/login">{labels.logout[selected_lang]}</a>
                </div>
              ) : (
                <a href="/login">{labels.login[selected_lang]}</a>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
