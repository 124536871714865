// import React from 'react'
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import React, {useState,} from 'react';
import Sidebar from '../../Pages/Sidebar';
import Pannels from '../Pannels';
import DiscoverBuilders from '../SessionList/DiscoverBuilders';
import {Button, Form, Row} from 'react-bootstrap';
import {
  ArrowIcon,
  Bandage,
  FireIcon,
  MachineIcon,
  ShareablesBlackIcon,
  WhitePenIcon,
  bagIcon,
  heartIcon,
  timeIcon,
} from '../../Constants/Images';

import Slider from 'react-slick';

export default function HomeMap(props) {
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('shifts');

  const screenName = [
    'HomeMap',
    'MapDetail',
    'HomeCart',
    'BuyCart',
    'Donate',
    'QR',
    'Tachn',
    'LeaderShip_Detail',
    'Shift_Breaks',
  ];

  const handleCheckboxChange = screen => {
    setShowComponent(screen);
  };

  const imgSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const snapshotSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };

  const images = [
    'https://s3.amazonaws.com/build-dev/profile_img/1696311960976_thumb.jpg',
    'https://s3.amazonaws.com/build-dev/profile_img/1696311960976_thumb.jpg',
    'https://s3.amazonaws.com/build-dev/profile_img/1696311960976_thumb.jpg',
  ];
  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
        showComponent={showComponent}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper">
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
                {showComponent === '' &&
                  screenName.map((screen, index) => (
                    <div key={index} className="fieldset">
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          id={`modals_${index}`}
                          className="form-check-input"
                          //   checked={checkboxState[screen]}
                          onChange={() => handleCheckboxChange(screen)}
                        />
                        <label
                          title=""
                          htmlFor={`modals_${index}`}
                          className="form-check-label">
                          Show {screen}
                        </label>
                      </div>
                    </div>
                  ))}

                {showComponent === 'HomeMap' && (
                  <>
                    <div className="sidebar-modal gg-map-wrapper">
                      <span
                        className="close as-link"
                        onClick={() => setShowComponent('')}>
                        <i className="fas fa-times" />
                      </span>
                      <div className="gg-map">
                        <iframe
                          style={{height: '350px'}}
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.92329959299!2d-77.04004218434312!3d38.90286925422597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b7bec1bdc7c9%3A0x111b4f4a48b1e3ad!2s1629%20K%20St%20NW%20%23300%2C%20Washington%2C%20DC%2020006%2C%20USA!5e0!3m2!1sen!2sin!4v1663160441909!5m2!1sen!2sin"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                          title="BB"></iframe>
                      </div>
                    </div>
                    <div style={{paddingTop: '348px'}}>
                      {/* <div className=""> */}
                      <DiscoverBuilders />
                      {/* </div> */}
                    </div>
                    <div style={{position: 'relative'}}>
                      <h3>Hey Jeff</h3>
                    </div>
                    <div style={{position: 'relative'}} className="mt-2">
                      <span>Where would you like Jack to take you?</span>
                    </div>
                    <div>
                      <div className="search-tabs-wrapper  mt-0">
                        <div className="search-form d-flex mt-0">
                          <Form.Group className="search-input-location">
                            <Form.Control
                              onChange={e => {}}
                              type="text"
                              placeholder="Enter location"
                            />
                          </Form.Group>
                          {/* <Button> */}
                          <div
                            style={{
                              paddingLeft: '66px',
                              paddingTop: '10px',
                            }}>
                            <i className="fa-solid fa-calendar-days" /> Now
                          </div>

                          {/* </Button> */}
                        </div>
                      </div>
                    </div>
                    <div style={{position: 'relative'}} className="mt-2">
                      <span className="clear-btn">Schedule Round Trip</span>
                    </div>
                  </>
                )}
                {showComponent === 'MapDetail' && (
                  <>
                    <span
                      className="close as-link"
                      onClick={() => setShowComponent('')}>
                      <i className="fas fa-times" />
                    </span>

                    <div>
                      {/* <div className=""> */}
                      <DiscoverBuilders />
                      {/* </div> */}
                    </div>
                    <div style={{position: 'relative'}}>
                      <Form.Label>
                        Start{' '}
                        <span>
                          <i
                            class="fa-solid fa-location-dot"
                            style={{color: '#52BD41'}}></i>
                        </span>
                        <span className="text">Schedule Round Trip</span>
                      </Form.Label>
                    </div>

                    <div>
                      <div className="search-tabs-wrapper  mt-0">
                        <div className="search-form d-flex mt-0">
                          <Form.Group className="search-input-location">
                            <Form.Control
                              onChange={e => {}}
                              type="text"
                              placeholder="Pickup location"
                            />
                          </Form.Group>
                          {/* <Button> */}
                          <div
                            style={{
                              paddingLeft: '66px',
                              paddingTop: '10px',
                            }}>
                            <i className="fa-solid fa-calendar-days" /> Now
                          </div>

                          {/* </Button> */}
                        </div>
                      </div>
                      <div className="search-tabs-wrapper  mt-0">
                        <div className="search-form d-flex mt-0">
                          <Form.Group className="search-input-location">
                            <Form.Control
                              onChange={e => {}}
                              type="text"
                              placeholder="Destination"
                            />
                          </Form.Group>
                          {/* <Button> */}

                          {/* </Button> */}
                        </div>
                      </div>
                    </div>
                    <div style={{position: 'relative'}} className="mt-">
                      <span className="clear-btn">
                        Add Additional Destination
                      </span>
                    </div>
                    <div className="mt-3">
                      <h1>Quick Destinations</h1>
                    </div>
                    <div className="mt-3">
                      <span>
                        <i className="fa-solid fa-house"></i>
                      </span>
                      <span className="pl-2">Home</span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <i className="icon-store"></i>
                      </span>
                      <span className="pl-2">Work</span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <i className="fa-solid fa-location-dot"></i>
                      </span>
                      <span className="pl-2">Fairmont Austin</span>
                      <div>
                        <span className="pl-4">101 Red River St, Austin</span>
                      </div>
                    </div>
                    <div>
                      <Button
                        type="button"
                        className="new-btn btn-green  btn btn-primary  text-center mt-4">
                        Submit
                      </Button>
                    </div>
                  </>
                )}
                {showComponent === 'HomeCart' && (
                  <>
                    <span
                      className="close as-link"
                      onClick={() => setShowComponent('')}>
                      <i className="fas fa-times" />
                    </span>
                    <div className="mt-3">
                      <h3 className="text-2xl ">Hi, Jeff</h3>
                    </div>
                    <div className="mt-2 mb-3">
                      <span className="">Have fun shopping!</span>
                    </div>
                    <div className="serach-filter-wrapper">
                      {/* Search Wrapper */}
                      <div className="search-tabs-wrapper">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="event-tabs tabs-style-2 mb-0">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    //   currentComponent === 'catalog' ? 'active' :
                                    'active'
                                  }
                                  onClick={() => {
                                    // setCurrentComponent('catalog')
                                  }}>
                                  Shop
                                </Button>
                              </li>
                              <li>
                                <Button
                                  className={
                                    //   currentComponent === 'sold' ? 'active' :
                                    ''
                                  }
                                  onClick={() => {
                                    // setCurrentComponent('sold')
                                  }}>
                                  Save
                                </Button>
                              </li>

                              <li>
                                <Button
                                  className={
                                    //   currentComponent === 'inventory'
                                    //     ? 'active' :
                                    ''
                                  }
                                  onClick={() => {
                                    //   setCurrentComponent('inventory');
                                  }}>
                                  Cart
                                </Button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="refferal-list-wrapper">
                        <div className="payment-card qa-list-item">
                          <div class="payment-user-item no-link">
                            <div className="cart-img as-link"></div>
                            <div class="user-content as-link">
                              <h2 class="single-line-text">Catalog Item</h2>
                              <p class="single-line-text">In-Stock</p>
                              <div class="message-date-info">
                                <span class="message-date">
                                  <time datetime="1699009603000">Category</time>
                                </span>
                              </div>
                            </div>
                            <div class="message-other-users"></div>
                          </div>

                          <div className="">
                            <div className="payment-cart-subCard">
                              <h3>$456</h3>

                              <div className="buy-btn-wrapper">
                                <span>
                                  <img
                                    className="sharableYellow as-link"
                                    src={ShareablesBlackIcon}
                                    alt="shareable"
                                    // onClick={() => {

                                    // }}
                                  />
                                </span>
                                <span
                                  className="pl-2"
                                  style={{colour: '#68b245'}}>
                                  <i className="fa-solid fa-cart-shopping"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="refferal-list-wrapper">
                        <div className="payment-card qa-list-item">
                          <div class="payment-user-item no-link">
                            <div className="cart-img as-link"></div>
                            <div class="user-content as-link">
                              <h2 class="single-line-text">Catalog Item</h2>
                              <p class="single-line-text">In-Stock</p>
                              <div class="message-date-info">
                                <span class="message-date">
                                  <time datetime="1699009603000">Category</time>
                                </span>
                              </div>
                            </div>
                            <div class="message-other-users"></div>
                          </div>

                          <div className="">
                            <div className="payment-cart-subCard">
                              <span className="font-bold ">$456</span>
                              <span className="font-bold pl-12">
                                Added to Card
                              </span>
                              <div className="buy-btn-wrapper">
                                <span>
                                  <img
                                    className="sharableYellow as-link"
                                    src={ShareablesBlackIcon}
                                    alt="shareable"
                                    // onClick={() => {

                                    // }}
                                  />
                                </span>
                                <span className="pl-2" style={{colour: 'red'}}>
                                  <i className="fa-solid fa-xmark"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {showComponent === 'BuyCart' && (
                  <>
                    <span
                      className="close as-link"
                      onClick={() => setShowComponent('')}>
                      <i className="fas fa-times" />
                    </span>
                    <Slider {...imgSliderSettings} className="snapshot-slider">
                      {Object.entries(images).length > 0 &&
                        images.map((item, index) => (
                          <div
                            className="events-wrapper mt-3 fix-width-forms"
                            key={'hehe-Boy' + index}>
                            <div className="events-img-slider">
                              <Slider {...snapshotSlider}>
                                <img src={item} alt="" key={index} />
                              </Slider>
                            </div>
                          </div>
                        ))}
                    </Slider>

                    <div className="mt-4">
                      <h1 className="font-bold">Catalog Item</h1>
                    </div>
                    <div className="mt-3">
                      <span>Category</span>
                    </div>
                    <div className="mt-5">
                      <span>Select Size</span>
                    </div>
                    <div className="mt-3">
                      <button class="bg-white-200 text-black rounded-md px-4 py-2 mx-2 border border-black">
                        Small
                      </button>
                      <button class="bg-white-200 text-black rounded-md px-4 py-2 mx-2 border border-black">
                        Large
                      </button>
                      <button class="bg-white-200 text-black rounded-md px-4 py-2 mx-2 border border-black">
                        XL
                      </button>
                    </div>
                    <div className="pt-8">
                      <span className="font-bold ">$85</span>
                      <span
                        className="cart_icon
                    "
                        style={{colour: '#68b245'}}>
                        <i
                          className="fa-solid fa-cart-shopping"
                          style={{paddingLeft: '332px'}}></i>
                      </span>
                    </div>
                    <div className="pt-8">
                      <span className="font-bold">Details</span>

                      <span>
                        <img
                          style={{paddingLeft: '332px'}}
                          src={ShareablesBlackIcon}
                          alt="shareable"
                          // onClick={() => {

                          // }}
                        />
                      </span>
                    </div>
                    <div className="text-center mt-3">
                      <button className="btn-success ">Buy Now</button>
                    </div>
                  </>
                )}
                {showComponent === 'Donate' && (
                  <>
                    <span
                      className="close as-link"
                      onClick={() => setShowComponent('')}>
                      <i className="fas fa-times" />
                    </span>
                    <div className="mt-3">
                      <h3 className="text-2xl">Hi, Jeff</h3>
                    </div>
                    <div className="mt-2 ">
                      <span className="">Have fun shopping!</span>
                    </div>
                    <div>
                      <div className="search-tabs-wrapper  mt-0">
                        <div className="search-form d-flex mt-0">
                          <Form.Group className="search-input-location">
                            <Form.Control
                              onChange={e => {}}
                              type="text"
                              placeholder="Enter amount"
                            />
                          </Form.Group>
                          {/* <Button> */}
                          <div
                            style={{
                              paddingLeft: '66px',
                              paddingTop: '10px',
                            }}>
                            <i className="fa-solid fa-calendar-days" /> Now
                          </div>

                          {/* </Button> */}
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <button className="btn-success ">Donate</button>
                    </div>
                    <div
                      style={{position: 'relative'}}
                      className="mt-4 text-center font-bold">
                      <span className="clear-btn">Schedule Round Trip</span>
                    </div>
                    <div className="pt-3">
                      <h1 className="text-2xl">Recent News</h1>
                      <div className="pt-3">
                        <img
                          style={{width: '280px'}}
                          src="https://s3.amazonaws.com/build-dev/profile_img/1696311960976_thumb.jpg"
                          alt=""
                        />
                        <div className="pt-3">
                          <h1>We just painted a school</h1>
                        </div>
                        <div className="pt-3">
                          <span>
                            Thanks to our donors we are now able to help Bethel
                            Middle School.
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {showComponent === 'QR' && (
                  <>
                    <span
                      className="close as-link"
                      onClick={() => setShowComponent('')}>
                      <i className="fas fa-times" />
                    </span>
                    <div className="appointment_milestone mt-3">
                      <h1 className="text-center mt-2">Your Balance</h1>
                      <p className="text-center mt-2">250 points</p>
                      <p className="text-center mt-3">
                        30 points away from your next milesotne
                      </p>
                    </div>
                    <div className="messages-user-header mb-4 text-center mt-3">
                      <div className="user-img">
                        <img
                          style={{width: ' 123px', height: '122px'}}
                          src="https://s3.amazonaws.com/build-dev/profile_img/1696311960976_thumb.jpg"
                          alt=""
                        />
                      </div>
                      <div class="user-details">
                        <h2>first last name’s QR Code</h2>
                      </div>
                    </div>
                    <div className="qr-img text-center">
                      <img
                        className="m-auto"
                        src={
                          'https://s3.amazonaws.com/build-dev/consult_qrcode/1700477956981.png'
                        }
                        alt=""
                      />
                    </div>
                  </>
                )}
                {showComponent === 'Tachn' && (
                  <div className="refferal-list-wrapper">
                    <div className="payment-card qa-list-item">
                      <div class="message-user-item no-link">
                        <div class="user-img as-link">
                          <img
                            src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                            alt=""
                          />
                        </div>
                        <div class="user-content as-link">
                          <h2 class="single-line-text">Samantha Ellington</h2>
                          <p class="single-line-text">McLean, VA</p>
                          <div class="message-date-info">
                            <span class="message-date">
                              <time datetime="1699009603000">
                                Prospect (5 services)
                              </time>
                            </span>
                          </div>
                        </div>
                        <div class="message-other-users"></div>
                      </div>
                      <div className="appointment_icon">
                        <Row>
                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={WhitePenIcon}
                              alt=""
                            />
                          </div>

                          <div className="appointment_icon2">
                            {' '}
                            <img
                              className="appointment_icon1_image_time"
                              src={timeIcon}
                              alt=""
                            />
                            <span>5D</span>
                          </div>
                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={FireIcon}
                              alt=""
                            />
                          </div>
                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={MachineIcon}
                              alt=""
                            />
                          </div>
                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={ArrowIcon}
                              alt=""
                            />
                          </div>

                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={heartIcon}
                              alt=""
                            />
                          </div>

                          <div className="appointment_icon1">
                            <img
                              className="appointment_icon1_image"
                              src={bagIcon}
                              alt=""
                            />
                          </div>
                        </Row>
                      </div>
                      <div className="">
                        <div className="contact-card-subCard">
                          <div className="contacts-item-icon no-link">
                            <div className="contacts-icon as-link">
                              <i class="fa-solid fa-phone"></i>
                            </div>

                            <div className="contacts-icon as-link">
                              <i className="fa-solid icon-plane"></i>
                            </div>
                          </div>

                          <div className="buy-btn-wrapper">
                            <span
                              //  variant="primary"
                              className="btn-pending-signature as-link mt-3-bold"
                              onClick={() => {}}>
                              Pending Signature
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showComponent === 'LeaderShip_Detail' && (
                  <>
                    {' '}
                    <span
                      className="close as-link"
                      onClick={() => setShowComponent('')}>
                      <i className="fas fa-times" />
                    </span>
                    <div className="refferal-list-wrapper">
                      <div
                        style={{
                          background: '#383838',
                          width: '337px',
                          height: '43px',
                          color: 'white',
                          borderRadius: '3px 3px 0px 0px',
                        }}>
                        <span style={{marginLeft: '4px', marginTop: '4px'}}>
                          Good
                        </span>
                        <span style={{marginLeft: '245px', marginTop: '4px'}}>
                          $3500
                        </span>
                      </div>
                      <div className="mt-2">
                        <p>House refresh basic</p>
                        <span style={{marginLeft: '4px'}}>Was</span>
                        <span style={{marginLeft: '4px'}}>$6000</span>
                        <span style={{marginLeft: '4px'}}>Now</span>
                        <span style={{marginLeft: '4px'}}>$6000</span>
                        <p>Included</p>
                      </div>
                      <div className="container_listing">
                        <li>Ozone cleaning</li>
                        <li>Ozone cleaning</li>
                      </div>
                    </div>
                  </>
                )}
                {showComponent === 'Shift_Breaks' && (
                  <>
                    <span
                      className="close as-link"
                      onClick={() => setShowComponent('')}>
                      <i className="fas fa-times" />
                    </span>
                    <div className="agreements-proposals-page">
                      <div className="messages-header mb-2">
                        <div className="event-tabs tabs-style-2 mb-2">
                          {' '}
                          <ul>
                            <li>
                              <Button
                                className={tabType === 'shifts' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('shifts');
                                }}>
                                Shifts
                              </Button>
                            </li>

                            <li>
                              <Button
                                className={tabType === 'breaks' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('breaks');
                                }}>
                                Breaks
                              </Button>
                            </li>
                          </ul>
                        </div>

                        <div className="search-tabs-icons">
                          {/* <span
                            className={
                              // currentComponent === 'create-form'
                              //   ? 'icon active'
                              // :
                              'icon'
                            }>
                            <i
                              className="icon-plus"
                              // onClick={() => setCurrentComponent('create-form')}
                            />
                          </span> */}
                          <span
                            onClick={() => {
                              setThreeDots(!threeDots);
                            }}
                            className={
                              threeDots
                                ? 'icon active threeDots'
                                : 'icon threeDots'
                            }>
                            <i className="fas fa-ellipsis-vertical" />
                          </span>
                        </div>
                      </div>

                      {threeDots && (
                        <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Who are you looking for?"
                                // value={chatSearchQry}
                                // onChange={e => {
                                //   setChatSearchQry(e.target.value);
                                // }}
                              />
                            </Form.Group>
                            <Button>
                              <i
                                className="icon-filter-icon"
                                // onClick={() => setSearchFilterModal(true)}
                              />
                            </Button>
                          </div>
                        </div>
                      )}

                      <div className="agreements-proposals-content">
                        <div
                          style={{
                            borderRadius: '5px',
                            background: '#F5F9F0',
                            width: '380px',
                            height: '66px',
                            marginBottom: '14px',
                          }}>
                          <h1
                            style={{
                              color: '#262626',
                              fontfamily: 'Inter',
                              fontSize: '15px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: '24px',
                              paddingTop: '8px',
                              marginLeft: '13px',
                            }}>
                            {' '}
                            Next Payment
                          </h1>
                          <div className="d-flex justify-content-between mt-1">
                            <span
                              style={{
                                marginLeft: '13px',
                                color: '#262626',
                                fontfamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '24px',
                              }}>
                              Friday, March 3, 2024
                            </span>
                            <span
                              style={{
                                marginRight: '10px',
                                color: '#262626',
                                fontfamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '24px',
                              }}>
                              $2,450
                            </span>
                          </div>
                        </div>
                        <div className="agreement-card-main">
                          <div className="agrement-card">
                            <h1>Thursday, March 2, 2023</h1>
                            <span style={{
                               color: '#262626',
                               fontfamily: 'Inter',
                               fontSize: '15px',
                               fontStyle: 'normal',
                               fontWeight: '500',
                               lineHeight: '24px',
                            }}>
                              High productivity
                            </span>
                            <div className="service-title mt-1" >5 customers</div>

                            <div className="roi-service-icons">
                              <div className="service-icon">
                                <img src={WhitePenIcon} alt="" />
                              </div>

                              <div className="service-icon-time">
                                <img className="" src={timeIcon} alt="" />
                                <span>5D</span>
                              </div>

                              <div className="service-icon w-25">
                                <img src={FireIcon} alt="" />
                              </div>

                              <div className="service-icon w-29">
                                <img src={MachineIcon} alt="" />
                              </div>

                              <div className="service-icon w-29">
                                <img src={ArrowIcon} alt="" />
                              </div>

                              <div className="service-icon w-29">
                                <img src={Bandage} alt="" />
                              </div>

                              <div className="service-icon w-28">
                                <img src={heartIcon} alt="" />
                              </div>

                              <div className="service-icon w-29">
                                <img src={bagIcon} alt="" />
                              </div>
                              <div className="w-29">
                                <i
                                  className="fa-solid fa-ellipsis"
                                  style={{
                                    color: '#0a0a0a',
                                    paddingTop: '15px',
                                  }}></i>
                              </div>
                            </div>
                          </div>

                          <div
                            className="agreement-card-bottom"
                            style={{height: '55px'}}>
                            <span
                              style={{
                                color: '#262626',

                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                              }}>
                              {' '}
                              5:05AM - 2:15PM
                            </span>

                            <div>
                              <span
                                style={{
                                  color: '#262626',

                                  fontFamily: 'Inter',
                                  fontSize: '15px',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  lineHeight: 'normal',
                                }}>
                                $575
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {showComponent === 'WorkSpace' && (
                  <CreateWorkSpace
                    onClose={() => {
                      setShowComponent('');
                    }}
                  />
                )}
              </>
            }
            secondary={<></>}
          />
        </div>
      </div>
    </div>
  );
}
