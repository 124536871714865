"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Data which has to be provided to the backend for thee video forwarding strategy
 * @ignore
 */
class VideoForwarding {
    constructor(userId, streamIndex) {
        this.userId = userId;
        this.streamIndex = streamIndex;
    }
}
exports.default = VideoForwarding;
