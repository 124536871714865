"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscribeParticipantLeft = exports.SubscribeParticipantJoined = exports.SubscribeConferenceEnded = exports.SubscribeConferenceCreated = exports.SubscribeInvitation = exports.SubscriptionType = void 0;
/**
 * The SubscriptionType model includes types of subscription.
 */
var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["Invitation"] = "Invitation";
    SubscriptionType["ConferenceCreated"] = "Conference.Created";
    SubscriptionType["ConferenceEnded"] = "Conference.Ended";
    SubscriptionType["ParticipantJoined"] = "Participant.Joined";
    SubscriptionType["ParticipantLeft"] = "Participant.Left";
})(SubscriptionType = exports.SubscriptionType || (exports.SubscriptionType = {}));
/**
 * The SubscribeInvitation model includes parameters related to the subscription of invitations.
 */
class SubscribeInvitation {
    constructor() {
        /** The subscription type */
        this.type = SubscriptionType.Invitation;
    }
}
exports.SubscribeInvitation = SubscribeInvitation;
/**
 * The SubscribeConferenceCreated model includes parameters related to the subscription of the created conferences.
 */
class SubscribeConferenceCreated {
    constructor(conferenceAlias) {
        /** The subscription type */
        this.type = SubscriptionType.ConferenceCreated;
        this.conferenceAlias = conferenceAlias;
    }
}
exports.SubscribeConferenceCreated = SubscribeConferenceCreated;
/**
 * The SubscribeConferenceEnded model includes parameters related to the subscription of the ended conferences.
 */
class SubscribeConferenceEnded {
    constructor(conferenceAlias) {
        /** The subscription type */
        this.type = SubscriptionType.ConferenceEnded;
        this.conferenceAlias = conferenceAlias;
    }
}
exports.SubscribeConferenceEnded = SubscribeConferenceEnded;
/**
 * The SubscribeParticipantJoined model includes parameters related to the subscription of participants joining conferences.
 */
class SubscribeParticipantJoined {
    constructor(conferenceAlias) {
        /** The subscription type */
        this.type = SubscriptionType.ParticipantJoined;
        this.conferenceAlias = conferenceAlias;
    }
}
exports.SubscribeParticipantJoined = SubscribeParticipantJoined;
/**
 * The SubscribeParticipantLeft model includes parameters related to the subscription of participants leaving conferences.
 */
class SubscribeParticipantLeft {
    constructor(conferenceAlias) {
        /** The subscription type */
        this.type = SubscriptionType.ParticipantLeft;
        this.conferenceAlias = conferenceAlias;
    }
}
exports.SubscribeParticipantLeft = SubscribeParticipantLeft;
