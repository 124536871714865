// import React from 'react'
// import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import React, {useState} from 'react';
// import Sidebar from '../../Pages/Sidebar';
// import Pannels from '../Pannels';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Sidebar from '../../Pages/Sidebar';
import {CustomSlidingButtons, Pannels} from '../../Components';
import DoubleCam from '../../Svg/DoubleCam';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';

import {cupcake} from '../../Constants/Images';

export default function ShowCase(props) {
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);

  const dynamicStyle = {
    padding: '0px',
    // Add other styles as needed
  };

  const listForDropDown = [
    // {title: 'All', id: '1'},
    {title: 'Recent', id: '1'},
    {title: 'Customers', id: '2'},
    {title: 'Prospects', id: '3'},
    {title: 'Leads', id: '4'},
    {title: 'Team', id: '5'},
    {title: 'Colleagues', id: '6'},
    {title: 'Other contact types', id: '7'},
  ];

  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);
    // setdropdownValue([innerText]);
  };
  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);
  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        showComponent={showComponent}
        workspaceData={workspaceData}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          {/* <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span
                            style={{
                              color: '#000',

                              fontFamily: 'Inter',
                              // fontSize: '10.833px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                            }}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              color: '#000',

                              fontFamily: 'Inter',
                              fontSize: '17px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: 'normal',
                            }}>
                            {item.money}
                            {item.name === 'Rating' && (
                              <img src={rewardStar} alt="" />
                            )}
                            <i
                              class="fa-solid fa-arrow-up"
                              style={{color: '#52bd41'}}></i>
                          </span>
                          <div
                            class="message-date-info"
                            style={
                              {
                                // marginLeft: '13px',
                              }
                            }>
                            <span
                              class="message-date"
                              style={{
                                color: '#333',
                                fontFamily: 'Helvetica',
                                // fontSize: '9px',
                                fontStyle: 'normal',
                                fontWeight: '300',
                                lineHeight: 'normal',
                              }}>
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div> */}
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            customCss={dynamicStyle}
            primary={
              <>
                <div className="agreements-proposals-page primary_panel_wraper primary_panel_content">
                  <div className="messages-header mb-2">
                    <div className="event-tabs tabs-style-2 mb-2">
                      {' '}
                      <ul>
                        <li>
                          <Button className="active" style={{color: 'black'}}>
                            Showcase
                          </Button>
                        </li>
                      </ul>
                    </div>

                    <div className="search-tabs-icons">
                      {/* <span
                            className={
                              // currentComponent === 'create-form'
                              //   ? 'icon active'
                              // :
                              'icon'
                            }>
                            <i
                              className="icon-plus"
                              // onClick={() => setCurrentComponent('create-form')}
                            />
                          </span> */}
                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={
                          threeDots ? 'icon active threeDots' : 'icon threeDots'
                        }>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>

                  {threeDots && (
                    <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                      <div className="search-form d-flex">
                        <Form.Group className="search-input">
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            // value={chatSearchQry}
                            // onChange={e => {
                            //   setChatSearchQry(e.target.value);
                            // }}
                          />
                        </Form.Group>
                        <Button>
                          <i
                            className="icon-filter-icon"
                            // onClick={() => setSearchFilterModal(true)}
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                    <CustomSlidingButtons
                      items={listForDropDown}
                      activeButtons={areas_Filter}
                      onItemClick={e => {
                        dropDownSelection(e);
                      }}
                      showItems={2}
                    />
                  </div>

                  <div className="refferal-list-wrapper">
                    <Scrollbars autoHide>
                      <>
                        <div className="Business_profile_page">
                          <div className="business_profile_content mt-3">
                            <div className="before_after_content mr-3">
                              {/* <div className="mb-2">Before</div> */}
                              <div className="before_after_img">
                                <img src={cupcake} alt="" />
                              </div>
                            </div>
                            <div
                              className="before_after_content"
                              style={{position: 'relative'}}>
                              {/* <div className="mb-2">After</div> */}
                              <div
                                style={{
                                  position: 'absolute',
                                  bottom: '12px',
                                  right: '18px',
                                }}>
                                <DoubleCam fill={'#52BD41'} />
                              </div>
                              <div className="before_after_img">
                                <img src={cupcake} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </Scrollbars>
                  </div>
                </div>
              </>
            }
            secondary={<></>}
          />
          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
