import React, {useState,useEffect} from 'react';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import Actions from '../Actions/Actions';
import ActionDetail from '../Actions/ActionDetail';
import {getAllActions} from '../../Store/Actions/SessionAction';
import {useDispatch, useSelector} from 'react-redux';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import CreateAction from '../Snapshot/CreateAction';

const MessagesAction = props => {
  const dispatch = useDispatch();
  // const [showLoader, setshowLoader] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('mine');
  const [dataOfAction, setDataOfAction] = useState('');
  const [title, setTitle] = useState('');
  const actions = useSelector(state => state.AllActionsList);
  const [mineActionLength, setMineActionLength] = useState([]);
  

  useEffect(() => {
   if (tabType === 'mine' && !props.serachForNotesAction) {
    setMineActionLength(actions)
   }
  }, [actions])
  

  // const ActionCategories = [
  //   {title: 'All'},
  //   {title: 'Urgent'},
  //   {title: 'Pending'},
  //   {title: 'Pending again'},
  // ];

  const createAction = () => {
    // setshowLoader(true);
    var payLoad = {
      title: title,
      // priority: piorityListid,
      // due_date: date,
      // due_time: time,
      // description: discription,
      // assigne: assigneIds.toString(','),
      // watchers: watchesIds.toString(','),
      workspace_ids: localStorage.getItem('activeIds'),
      // images: sliderImage.toString(','),
      // association_name: localStorage.getItem('id'),
      association_type:
        props.chatType === 'personal'
          ? 'group_chat'
          : props.chatType === 'opportunity'
          ? 'opportunity'
          : props.chatType === 'session'
          ? 'session_chat'
          : props.chatType === 'event'
          ? 'event_chat'
          : 'profile',
      association_id: props.chatType
        ? props.chatId
        : props.userData
        ? props.userData.id
        : localStorage.getItem('id'),
    };
    
    commonApi
      .create_action(payLoad)
      .then(res => {
        if (res.status === 200) {
          setTitle('');
          successMessage(res.message);
          getActions();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getActions = () => {
    const Payload = JSON.parse(localStorage.getItem('action_paylaod'));
    

    dispatch(getAllActions(Payload));
  };
  const handleFeedbackChange = (e) => {
    const value = e.target.value;
    
    // Remove leading spaces
    if (value.startsWith(' ')) {
      setTitle(value.trimStart());
    } else {
      setTitle(value);
    }
  };
  

  return (
    <>
      <div className="page-content-wrapper profileNotesSheet MessagesNoteSheet  MessagesNewNoteSheet">
        <div className="serach-filter-wrapper w-100">
          <div className="">
            <div className="messages-header mb-3">
              {/* <div className="event-tabs dark-tabs"> */}
              <div className="event-tabs tabs-style-2 mb-0">
                {' '}
                {/* Merging */}
                <ul className="LabelTabs">
                  <li className="ml-20 d-flex align-items-center">
                    <Button
                      className={tabType === 'mine' ? 'active' : ''}
                      onClick={() => {
                        setTabType('mine');
                      }}>
                      {labels.Mine[selected_lang]}
                    </Button>
                    {mineActionLength && mineActionLength.length > 0 && (
                      <span className="actionCount">
                        {mineActionLength.length > 0 ? mineActionLength.length : ''}
                      </span>
                    )}
                  </li>

                  <li>
                    <Button
                      className={tabType === 'watching' ? 'active' : ''}
                      onClick={() => {
                        setTabType('watching');
                      }}>
                      {labels.WATCHING[selected_lang]}
                    </Button>
                  </li>
                </ul>
              </div>
              <div className="search-tabs-icons">
                <span
                  className={'icon'}
                  onClick={() => {
                    props.setShowComponent('create-action');
                  }}>
                  <i className="icon-plus" />
                </span>
              </div>
            </div>
          </div>
          <Scrollbars
          renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
          // Hide the vertical scrollbar
          renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}
          >
            <div className="" style={{ paddingRight: '0px'}}>
              {/* {showLoader && <LoaderSpinner />} */}
              <div className="fieldset">
                <Form.Label controlid="validationCustom01">Title</Form.Label>
                <Form.Control
                  name="title"
                  value={title}
                  pattern="^\S.*$"
                  required
                  placeholder="Add action title"
                  onChange={e => {

                    handleFeedbackChange(e)

                    // setTitle(e.target.value);
                  }}

                  onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey && title!=='') {
                      e.preventDefault();
                      createAction();
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Convo name is required.
                </Form.Control.Feedback>
              </div>
              <div className="filter-btn-wrapper">
                <div className="invite-btn text-center">
                  <Button
                    className="btn-dark"
                    onClick={createAction}
                    disabled={title === '' || !title ? true : false}>
                    Quick Add
                  </Button>
                </div>
              </div>

              {/* <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                <CustomSlidingButtons
                  items={ActionCategories}
                  activeButtons={actionType}
                  onItemClick={e => {
                    // handleClick(e);
                  }}
                  showItems={3}
                />
              </div> */}

              {tabType === 'mine' && (
                <div className="mt-4">
                  <Actions
                    threeDots={props.threeDots}
                    setShowComponent={setShowComponent}
                    setDataOfAction={setDataOfAction}
                    showComponent={props.showComponentAction}
                    handleChildComponent={props.handleChildComponent}
                    // showComponentAction={props.showComponentAction}
                    setShowComponentAction={props.setShowComponent}
                    type={'Messages'}
                    setSerachForNotesAction={props.setSerachForNotesAction}
                    serachForNotesAction={props.serachForNotesAction}
                  />
                </div>
              )}

              {tabType === 'watching' && (
                <div className="mt-4">
                  <Actions
                    threeDots={props.threeDots}
                    setShowComponentAction={props.setShowComponent}
                    handleChildComponent={props.handleChildComponent}
                    setDataOfAction={setDataOfAction}
                    showComponent={props.showComponentAction}
                    // showComponentAction={props.showComponentAction}
                    tabType={tabType}
                    type={'MessagesWatching'}
                    setSerachForNotesAction={props.setSerachForNotesAction}
                    serachForNotesAction={props.serachForNotesAction}
                  />
                </div>
              )}
            </div>
          </Scrollbars>
          {/* <div className="message-form">
          <span>
            <i className="fas fa-ellipsis-vertical" />
          </span>

          <Form.Group className="form-input">
            <Form.Control
              name=""
              id="text_field"
              as="textarea"
              rows={6}
              placeholder="Type what you are thinking"
              // onKeyDown={e => {
              //   if (e.key === 'Enter') {
              //     saveMessage();
              //     setTicketImg(null);
              //     setSelectedImage(null);
              //   }
              // }}
            />
          </Form.Group>
          <span className="form-icon send-btn">
            <i className="icon-play-icon" />
          </span>
        </div> */}
        </div>
      </div>
      {showComponent === 'actionDetails' && (
        <ActionDetail
          dataOfAction={dataOfAction}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

{/* 
{showComponent === 'addAction' && (
         <CreateAction
        
         onClose={() => {
           setShowComponent('')
         }}
        
       />
      )} */}

    </>
  );
};

export default MessagesAction;
