import React, {useState} from 'react';
import { Modal} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
function LoaderModal(props) {
  const [show, setShow] = useState(true);
  const closeModal = () => {
    // props.closeModal();
  };

  return (
    <Modal className="medium" show={show} setShow={setShow} onHide={() => closeModal()}>
      <Modal.Body>
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoaderModal;
