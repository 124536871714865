import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SlidingPanel from '../SlidingPanel';
import {fixName} from '../../Utils/commonUtils';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import SelectedReferUser from './SelectedReferUser';
import Invite from '../SingleComponent/Invite';

const SendTicket = props => {
  const [following, setFollowing] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [allBuilders, setAllBuilders] = useState([]);
  // const [newFollowers, setNewFollowers] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [availabletickets, setAvailabletickets] = useState([]);
  const [outSideBuilder, setOutSideBuilder] = useState([]);

  const [contact, setContact] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);

  //for Invites
  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [folowersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const submit = () => {
    if (builders.length + outSideBuilder.length === 0) {
      errorMessage('Select user');
    } else if (
      availabletickets.length > builders.length + outSideBuilder.length ||
      availabletickets.length === builders.length + outSideBuilder.length
    ) {
      sendTicket();
    } else {
      errorMessage('Not enough tickets');
    }
  };

  const sendTicket = () => {
    var payload = {
      ticket_id: props.allData,
      user_ids: builders,
      contacts: outSideBuilder,
    };

    commonApi
      .send_ticket(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          props.onClose();
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const followersList = qry => {
    var payloadFollowersList = {
      user_id: localStorage.getItem('user'),
      search_query: qry,
      event_id: props.eventId ? props.eventId : '',
    };
    commonApi
      .excluded_followers_list(payloadFollowersList)
      .then(res => {
        if (res.status === 200) {
          setshowLoader(false);
          setFollowing(res.data.filter(item => !builders.includes(item.id)));
          // setNewFollowers(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // useEffect(() => {

  //   setFollowing(newFollowers.filter(item => !builders.includes(item.id)));
  //   // setAllBuilders(newFollowers.filter(item => builders.includes(item.id)));
  // }, [builders]);

  //

  useEffect(() => {
    if (searchQuery === '') {
      followersList();

      setshowLoader(true);
    } else {
      followersList();
      setshowLoader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.tickets.length > 0) {
      props.tickets.forEach((miniItem, index) => {
        if (miniItem.share === 'no' && miniItem.user_status !== 'joined') {
          setAvailabletickets(previous => [...previous, miniItem.id]);
        }
        // setAvailabletickets(previous => [...previous, miniItem.id]);
        // setSelectedModData(previous => [...previous, miniItem]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedFollower = item => {
    if (
      !builders.includes(item.id) &&
      builders.length + outSideBuilder.length < availabletickets.length
    ) {
      setBuilders(previous => [...previous, item.id]);
      setAllBuilders(previous => [...previous, item]);
    } else {
      errorMessage('Not enough tickets');
    }
  };
  const CircleUserListData = item => {
    console.log(item, '=====>item');
    let error = false;
    let count = 0;

    if (item.length > 0 && availabletickets.length > builders.length) {
      item.forEach((miniItem, index) => {
        if (!builders.includes(miniItem.id)) {
          if (count < availabletickets.length) {
            setBuilders(previous => [...previous, miniItem.id]);
            setAllBuilders(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
            count++;
          } else {
            error = true;
          }
        }
      });

      if (error) {
        errorMessage(`${availabletickets.length}  ${labels.Tickets_available_only[selected_lang]}`);
      }
    } else {
      errorMessage('Not enough tickets');
    }
  };
  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  console.log(availabletickets,'===========availabletickets')
  console.log(builders,'===========builders')
  return (
    <>
      {inviteLoader && <LoaderSpinner />}

      {/* {showComponent === '' && ( */}
      <SlidingPanel hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper">
          <div className="sidebar-heading-wrapper mt-0 fix-width-forms">
            <span
              className="icon as-link"
              onClick={() => {
                setShowComponent('sendInvitation');
              }}>
              <span className="icon-tickets" /> Send Externally
            </span>
            {/* <span className="close as-link" onClick={() => props.onClose()}>
                  <i className="fas fa-times" />
                </span> */}
          </div>
          <Scrollbars>
            <div className="fix-width-forms pb-4">
              <div className="intrested-for-wrapper">
                <Form.Group className="">
                  <Form.Label>
                    Who would you like to send your Free tickets for{' '}
                    {props.userData.session_title}?
                  </Form.Label>
                </Form.Group>
              </div>

              <Invite
                type={'refer'}
                component={'sendTicket'}
                moderator={builders}
                setModerator={setBuilders}
                selectedModData={allBuilders}
                setSelectedModData={setAllBuilders}
                contact={contact}
                setContact={setContact}
                originalDataOfMod={originalDataOfMod}
                setOriginalDataOfMod={setOriginalDataOfMod}
                forApiHit={showComponent}
                handleCircle={handleCircle}
                onClick={() => {
                  setShowComponent('sendInvitation');
                  setTimeout(() => {
                    if (contact.length === 0 && inviteQry !== '') {
                      setInviteFollowers(folowersList);
                      setInviteQry('');
                    }
                  }, 1000);
                }}
                showLoader={inviteLoader}
                setshowLoader={setInviteLoader}
                followers={inviteFollowers}
                setFollowers={setInviteFollowers}
                setFollowersList={setFollowersList}
                followersList={folowersList}
                setSearchQry={setInviteQry}
                searchQry={inviteQry}
                availabletickets={availabletickets}
              />

              {/* <div className="invite-page-search">
                  <div className="search-form">
                    <Form.Group className="search-input">
                      <Form.Control
                        type="text"
                        placeholder="Search for contacts"
                        onChange={e => {
                          setSearchQuery(e.target.value);
                          followersList(e.target.value);
                        }}
                        value={searchQuery}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="invite-persons-wrapper">
                  <div className="invite-persons-heading">
                    <span className="builder-text">
                      My Contacts (tap to select)
                    </span>
                    <span
                      className="circle-link-icon"
                      onClick={() => {
                        setShowComponent('CircleList');
                      }}>
                     <i className="icon-circles" />
                    </span>
                  </div>
                  <div className="invite-persons-heading mb-4">
                    <span
                      className="builder-text as-link"
                      onClick={() => {
                        if (builders.length+outSideBuilder.length > 0) {
                          setShowComponent('SelectedUsers');
                        }
                      }}>
                      {outSideBuilder.length + builders.length} of {availabletickets.length} Builders
                      Added
                    </span>
                  </div>

                  <div className="invite-persons-list">
                    <Row>
                      {Object.entries(following).length > 0 ? (
                        Object.entries(following).length > 0 &&
                        following.map((item, index) => 
                        !builders.includes(item.id) &&(
                          <Col md={4} xl={3} key={`f-item-${index}`}>
                            <div
                              className="stage-list-item"
                              id={item.id}
                              onClick={() => {
                                handleSelectedFollower(item);
                              }}>
                              <div className="stage-person-img">
                                <img
                                  src={
                                    item.profile_img === ''
                                      ? DEFAULT_IMAGE
                                      : item.profile_img
                                  }
                                  alt=""
                                  id={item.id}
                                />
                              </div>
                              <div className="stage-person-name">
                                <h3>
                                  {fixName(item.first_name, item.last_name)}
                                </h3>
                              </div>
                            </div>
                          </Col>
                        ))
                      ) : !showLoader ? (
                        <div className="text-center">{labels.no_record_found[selected_lang]}</div>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </div>
                </div> */}
            </div>
          </Scrollbars>
          {inviteFollowers.length > 0 && (
            <div className="filter-btn-wrapper pt-2">
              <div className="invite-btns fix-width-forms text-center bg_transparent pb-2 bottom-0 pt-2">
                <Button className="btn-dark m-0" onClick={submit}>
                  Send Ticket
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>
      {/* )} */}

      {showComponent === 'sendInvitation' && (
        <SendInvitation
          setContact={setOutSideBuilder}
          id={true}
          onClose={() => setShowComponent('')}
        />
      )}
      {/* {showComponent === 'SelectedUsers' && (
        <SelectedUsers
          users={builders}
          setUsers={setBuilders}
          selectedAllUser={allBuilders}
          setSelectedAllUser={setAllBuilders}
          onClose={() => setShowComponent('')}
          alreadyAddedUser={[]}
        />
      )} */}
      {showComponent === 'SelectedUsers' && (
        <SelectedReferUser
          users={builders}
          setUsers={setBuilders}
          selectedAllUser={allBuilders}
          setSelectedAllUser={setAllBuilders}
          onClose={() => setShowComponent('')}
          alreadyAddedUser={[]}
          referOutSide={outSideBuilder}
          setReferOutSide={setOutSideBuilder}
        />
      )}

      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
          eventId={props.eventId}
          creatorId={props.creatorId}
        />
      )}
    </>
  );
};
export default SendTicket;
