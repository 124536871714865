import React, {useEffect, useState} from 'react';
import {DEFAULT_IMAGE} from '../../Utils/env';

import commonApi from '../../Services/CommonService';
import {Button, Form} from 'react-bootstrap';
import CustomModal from '../CustomModal';
import ProfileComponent from '../ProfilePage/ProfileComponent';
// import LoaderModal from '../Modals/LoaderModal';
import LoaderSpinner from '../Modals/LoaderSpinner';
import moment from 'moment';
import CreateSession from '../SessionDetails/CreateSession';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import SlidingPanel from '../SlidingPanel';
import {Scrollbars} from 'react-custom-scrollbars';
import {chatroomInfo, collectingUsers, fixName} from '../../Utils/commonUtils';
import SmartChatPage from '../Common/SmartChatPage';
import API from '../../Services/SessionServices';
import {connect} from 'react-redux';
import CropImage from '../Cropper/CropImage';
import SessionDetail from '../SessionDetails/SessionDetail';
import EditPencilSvg from '../../Svg/EditPencilSvg';

const EventDetail = props => {
  const [chatType, setChatType] = useState('Attendees');
  const [searchQry, setSearchQry] = useState([]);
  const [searchPenMod, setSearchPenMod] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [eventAud, setEventAud] = useState([]);
  const [rsvp, setRsvp] = useState([]);
  const [penMod, setPenMod] = useState([]);
  const [userId, setUserId] = useState([]);
  const [profile, setProfile] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [showSession, setShowSession] = useState(false);
  const [modAud, setModAud] = useState([]);
  const [createEvent, setCreateEvent] = useState(false);
  const [hideEvent, setHideEvent] = useState(true);
  const [modDetail, setModDetail] = useState([]);
  const [typeModAud, setTypeModAud] = useState(false);
  const [searchEventAud, setSearchEventAud] = useState(false);
  const [serachEventData, setSerachEventData] = useState([]);
  const [searchModDetail, setSearchModDetail] = useState([]);
  // const [chatButton, setChatButton] = useState(false);
  const [mediaImg, setMediaImg] = useState([]);
  const [showPrew, setShowPrew] = useState(false);
  const [modelImg, setModelImg] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [serverTime, setServerTime] = useState('');
  // const [ticketImg, setTicketImg] = useState('');
  const [chatDetail, setChatDetail] = useState({});
  const [audienceIds, setAudienceIds] = useState([]);
  const [modratorIds, setModratorIds] = useState([]);
  const [initialLoader, setInitailLoader] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [showModel, setShowModel] = useState(false);
  const [file, setFile] = useState(null);
  const [sliderImage, setSliderImage] = useState([]);
  const [eventImages, setEventImages] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [destination, setDestination] = useState(props.item.address);

  const endTime = moment(props.item.start_time).format('MMMM Do YYYY, h:mmA');
  const startTime = moment(props.item.end_time).format('h:mmA');
  //

  const submit = () => {
    if (props.historyValue === false) {
      joinEventHistory();
    } else {
      joinEvent();
    }
  };

  const serverTimeCheck = () => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .check_server_time()
      .then(res => {
        setServerTime(res.server_time);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const imgHandle = item => {
    setModelImg(item);
    setShowPrew(true);
  };
  const joinEventHistory = () => {
    var payLoad = {
      event_id: props.item.id,
      user_id: userId,
    };
    commonApi
      .join_event(payLoad)
      .then(response => {
        if (response.status === true) {
          successMessage(response.message);
          eventDetailHistory();
          setProfile(false);
          setShowComponent('');
        }
      })
      .catch(err => {
        setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const joinEvent = () => {
    var payLoad = {
      event_id: sessionData.id,
      user_id: userId,
    };
    commonApi
      .join_event(payLoad)
      .then(response => {
        if (response.status === true) {
          successMessage(response.message);
          eventDetail();
          setProfile(false);
          setShowComponent('');
        } else if (response.status === false) {
          errorMessage(response.message);
        }
      })
      .catch(err => {
        setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const eventDetail = () => {
    var payLoad = {
      event_id:
        props.type === 'refer' ? props.item.refer_type_id : props.item.id,
    };
    commonApi
      .event_detail(payLoad)
      .then(response => {
        if (response.status === 200) {
          setEventData(response.audience_details);
          const filteredData = response.pending_audience.filter(
            item => !response.going.some(miniItem => miniItem.id === item.id),
          );
          setEventAud(filteredData);
          setSearchEventAud(response.pending_audience);
          setSerachEventData(response.audience_details);
          setSearchModDetail(response.moderators_details);
          setRsvp(response.rsvp);
          setSearchQry(response.rsvp);
          setPenMod(response.pending_moderator);
          setSearchPenMod(response.pending_moderator);
          setSessionData(response.event_detail);
          setModAud(response);
          setModDetail(response.moderators_details);
          setMediaImg(response.event_moment_media);
          setEventImages(response.event_slider_images);
          if (
            response.group_chat_det && response.group_chat_det.audience_ids &&
            response.group_chat_det.audience_ids.length > 0
          ) {
            setAudienceIds(response.group_chat_det.audience_ids);
          }
          if (
            response.group_chat_det && response.group_chat_det.moderator_ids &&
            response.group_chat_det.moderator_ids.length > 0
          ) {
            setModratorIds(response.group_chat_det.moderator_ids);
          }
          setShowLoader(false)
          // setTimeout(() => {
          //   setInitailLoader(false);
          // }, 1000);
        }
      })
      .catch(err => {
        setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const eventDetailHistory = () => {
    var payLoad = {
      event_id: props.item.id,
    };
    commonApi
      .event_detail(payLoad)
      .then(response => {
        if (response.status === 200) {
          setEventData(response.audience_details);
          setSerachEventData(response.audience_details);
          setSearchEventAud(response.pending_audience);
          setEventAud(response.pending_audience);
          setRsvp(response.rsvp);
          setSearchQry(response.rsvp);
          setPenMod(response.pending_moderator);
          setSearchPenMod(response.pending_moderator);
          setSessionData(response.event_detail);
          setModAud(response);
          setModDetail(response.moderators_details);
          setSearchModDetail(response.moderators_details);
          setMediaImg(response.event_moment_media);
          setEventImages(response.event_slider_images);
          if (
            response.group_chat_det.audience_ids &&
            response.group_chat_det.audience_ids.length > 0
          ) {
            setAudienceIds(response.group_chat_det.audience_ids);
          }
          if (
            response.group_chat_det.moderator_ids &&
            response.group_chat_det.moderator_ids.length > 0
          ) {
            setModratorIds(response.group_chat_det.moderator_ids);
          }
          setShowLoader(false)
          // setTimeout(() => {
          //   setInitailLoader(false);
          // }, 1000);
        }
      })
      .catch(err => {
        setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleModeratorSearch = (array, query) => {
    setRsvp(
      array.filter(el => {
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleSearchPenMod = (array, arr, query) => {
    setPenMod(
      array.filter(el => {
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
    setEventAud(
      arr.filter(el => {
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleAttendeesSearch = (array, arr, query) => {
    //
    setEventData(
      arr.filter(el => {
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
    setModDetail(
      array.filter(el => {
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };

  // const popover = (
  //   <>
  //     <Tooltip>
  //       {sessionData.session_description === ''
  //         ? 'No description to show'
  //         : sessionData.session_description}
  //     </Tooltip>
  //     {/* // <Popover id="popover-basic">
  //   //   <Popover.Header as="h3">Popover right</Popover.Header>
  //   //   <Popover.Body>
  //   //     {sessionData.session_description === ''
  //   //       ? 'No description to show'
  //   //       : sessionData.session_description}
  //   //   </Popover.Body>
  //   // </Popover> */}
  //   </>
  // );
  const handleNavigate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const origin = `${position.coords.latitude},${position.coords.longitude}`;
          const destinationFormatted = destination.replace(' ', '+');
          const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destinationFormatted}&travelmode=driving`;
          window.open(url, '_blank');
          // history.push('/directions'); // navigate to new URL that will display directions
        },
        error => {
          console.error('Error getting user location', error);
        },
      );
    } else {
      console.error('Geolocation not supported');
    }
  };

  useEffect(() => {
    if (props.historyValue === false) {
      eventDetailHistory();
    } else {
      eventDetail();
      if (props.type === 'refer') {
        props.getReferrals('', '', props.arrowButton, 1);
      } else if (!props.type) {
        props.getApiSessionList(props.sessionType, '', !props.typeIs);
      }
    }
    serverTimeCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatType, createEvent, props.item]);
  //
  //
  const chatHandle = () => {
    // if (props.item.group_chat_detail) {
    //   setShowComponent('CommonChatPage');
    // } else {
    //   errorMessage('No chat exists ');
    // }
    showMessagesAll();
  };

  const showMessagesAll = () => {
    const chatroome =
      'web_chatroom_event_' + (Math.random() + 1).toString(36).substring(3);

    let modratorIdsEvent = modratorIds;
    if (
      modratorIdsEvent &&
      modratorIdsEvent.length > 0 &&
      modratorIdsEvent.includes(localStorage.getItem('id'))
    ) {
    } else if (sessionData.created_by === localStorage.getItem('id')) {
    } else {
      modratorIdsEvent.push(localStorage.getItem('id'));
    }

    let data = {
      audience_ids: audienceIds.length > 0 ? audienceIds.toString() : '',
      chat_room_name: chatroome,
      creator_id: props.item.creator_id,
      group_image: props.item.image.slider_images,
      group_name: props.item.session_title,
      moderator_ids:
        modratorIdsEvent && modratorIdsEvent.length > 0
          ? modratorIdsEvent.toString()
          : '',
      type: 'event',

      type_id:
        props.type === 'refer' ? props.item.refer_type_id : props.item.id,
      left_removed_users: '',
    };
    API.create_session_group_chat_dolby(data)
      .then(res => {
        if (res) {
          setChatDetail(res.group_chat_detail);
          setTimeout(() => {
            setShowLoader(false);
            setShowComponent('CommonChatPage');
          }, 1000);
        }
      })
      .catch(err => {
        setShowLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];
    // let fileExtension = file.name.split('.').pop().toLowerCase();

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else {
      setFile(file);
      setShowModel('modal');

      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {
      //

      //   uploadMedia(e.target.result);
      // };

      // setTicketImg(objectUrl);
      //
      //
    }
    // const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  };

  const addMomentMedia = img => {
    var payload = {
      event_id: props.item.id,
      moment_images: [img],
    };

    // setshowLoader(true);
    commonApi
      .add_moment_media(payload)
      .then(res => {
        setShowLoader(false);

        if (res.status === 200) {
          setMediaImg(prev => [...prev, img]);
          // setTicketImg('');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const uploadMedia = img => {
  //   setShowLoader(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'moment_images',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   // setshowLoader(true);
  //   commonApi
  //     .upload_event_media(bodyFormData)
  //     .then(res => {
  //       // setshowLoader(false);

  //       if (res.status === 200) {
  //         addMomentMedia(res.moment_image_path);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const handleCommonChat = (data, type) => {};

  const renderEventButtons = profile => (
    <>
      <div
        className="event_detail user-profile-img as-link"
        onClick={() => {
          setShowComponent('profileComponeent');
          setUserId(sessionData.creator_id);
        }}>
        <img
          src={modAud.creator_image ? modAud.creator_image : DEFAULT_IMAGE}
          alt=""
        />
      </div>

      <span
        // className='icon icon-meetups'
        onClick={() => {
          setShowSession(true);
          setShowComponent('session');
        }}>
        <i className="icon icon-meetups as-link" />
      </span>

      <span
        className={''}
        onClick={() => {
          setShowLoader(true);
          chatHandle();
        }}>
        <i className="fa-solid fa-paper-plane as-link"></i>
      </span>

      {new Date(serverTime) < new Date(sessionData.session_start_time) &&
        !props.history &&
        props.item.creator_id === localStorage.getItem('id') && (
          <span
            className=" as-link"
            onClick={() => {
              setCreateEvent(true);
              setShowComponent('editEvent');
            }}>
            <EditPencilSvg fill={'white'} h={20} w={20} />
          </span>
        )}

      {/* <i className="fa-solid fa-bell as-link" onClick={() => {}}></i>
        <i className="fas fa-heart as-link" onClick={() => {}}></i> */}
    </>
  );

  return (
    <>
      {/* {initialLoader && <LoaderSpinner />} */}

      <SlidingPanel
        hidePanel={() => props.onClose()}
        header={true}
        showBlackHeader={true}
        headerPadding={'20px 20px'}
        firstDivStyle={{
          padding: 0,
          background:
            
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
        }}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#F7F6F2',
          // padding: '0px 15px 0px 25px',
          // marginBottom: '20px',
        }}
        // typeOfComponent={'messges'}
        renderData={renderEventButtons}>
        <div className="serach-filter-wrapper  w-100">
          <div className="in_person_detail fix-width-forms fixed-width-inputs">
            <div className=" black-head-form-pad-left events-icons-wrapper mb-2">
              {/* <div className="left-icons">
                      <span
                        className={''}
                        onClick={() => {
                          setShowLoader(true);
                          chatHandle();
                        }}>
                        <i className="fa-solid fa-paper-plane as-link"></i>
                      </span>
                      <span
                        onClick={() => {
                          setShowSession(true);
                          setShowComponent('session');
                        }}>
                        <i className="fa-solid fa-user-group as-link" />
                      </span>
                    </div> */}
              <div className="search-tabs-icons gap-2">
                <span
                  className="icon info-icons event_detail_icon as-link ml-0"
                  onClick={() => {
                    setShowComponent('event_Deatil');
                  }}>
                  <span className="icon-info-down  ml-0">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </span>
                </span>

                <span className="icon event_detail_icon">
                  <i
                    className="fa-sharp fa-solid fa-location-dot"
                    onClick={handleNavigate}></i>
                </span>
              </div>
            </div>

            <div
              className={`black-head-form-pad-left sidebar-heading-wrapper m-0 mb-2 sidebar-event-detail-holder ${
                props.windowWidth <= 1295 ? 'with-panel' : ''
              }`}>
              <div className="heading ">
                <h2>{sessionData.session_title}</h2>
              </div>

              {/* <div className="with-user-img">
                  <div
                    className="user-profile-img no-margin as-link"
                    onClick={() => {
                      setShowComponent('profileComponeent');
                      setUserId(sessionData.creator_id);
                    }}>
                    <img src={modAud.creator_image} alt="" />
                  </div>
                </div> */}
            </div>
          </div>

          <Scrollbars>
            <div className="in_person_detail fix-width-forms fixed-width-inputs">
              <div className=' black-head-form-pad-left'>

              
              <div className="events-wrapper">
                <div
                  className={`${
                    sessionData.event_slider_images ? 'events-img-slider' : ''
                  }`}>
                  {sessionData.event_slider_images &&
                    sessionData.event_slider_images.map((item, index) => (
                      <img src={item} alt="" key={index} />
                    ))}
                </div>
                {/* <div className="events-icons-wrapper">
                    <div className="left-icons">
                      <span
                        className={''}
                        onClick={() => {
                          setShowLoader(true);
                          chatHandle();
                        }}>
                        <i className="fa-solid fa-paper-plane as-link"></i>
                      </span>
                      <span
                        onClick={() => {
                          setShowSession(true);
                          setShowComponent('session');
                        }}>
                        <i className="fa-solid fa-user-group as-link" />
                      </span>
                    </div>
                    <div className="search-tabs-icons">
                      <span
                        className="icon info-icons as-link"
                        onClick={() => {
                          setShowComponent('event_Deatil');
                        }}>
                        <span className="icon-info-down">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </span>
                      </span>

                      <span className="icon">
                        <i
                          className="fa-sharp fa-solid fa-location-dot"
                          onClick={handleNavigate}></i>
                      </span>
                    </div>
                  </div> */}

                <div className="events-headings mb-2">
                  <span className="category-name">
                    Moderator :{' '}
                    {fixName(
                      props.item?.creator_first_name,
                      props.item?.creator_last_name,
                    )}
                  </span>
                </div>
                <div className="events-headings">
                  {/* <h2>{props.item.creator_name}</h2> */}
                  <div className="d-flex align-item-center justify-content-between events-desc-wrapper m-0">
                    {/* <span className="category-name">
                        {sessionData.category_name}
                      </span> */}
                    <span className="category-name">
                      {endTime} - {startTime}
                    </span>
                    <span
                      className="event-address m-0"
                      // onClick={() => {
                      //   setShowSession(true);
                      //   setShowComponent('session');
                      // }}
                    >
                      <i className="icon icon-meetups"></i>{' '}
                      {modAud.total_members + 1}/{modAud.total_moderators + 1}
                    </span>
                  </div>
                </div>
                {/* <div className="events-desc-wrapper">
                    <p>{sessionData.session_description}</p>
                    <span
                      className="event-address"
                      // onClick={() => {
                      //   setShowSession(true);
                      //   setShowComponent('session');
                      // }}
                    >
                      <i className="fas fa-circle-user"></i>{' '}
                      {modAud.total_members + 1}/{modAud.total_moderators + 1}
                    </span>
                    <span className="event-address">
                      {sessionData &&
                        sessionData.complete_address &&
                        sessionData.complete_address}
                    </span>
                  </div> */}
              </div>

              <div className="event-tabs tabs-style-2 mt-4">
                <ul>
                  <li>
                    <Button
                      className={chatType === 'Attendees' ? 'active' : ''}
                      onClick={() => {
                        setShowLoader(true);
                        setTimeout(() => {
                          setShowLoader(false);
                        }, 1000);
                        setChatType('Attendees');
                      }}>
                      Attendees
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={chatType === 'Pending' ? 'active' : ''}
                      onClick={() => {
                        setShowLoader(true);
                        setTimeout(() => {
                          setShowLoader(false);
                        }, 1000);
                        setChatType('Pending');
                      }}>
                      Pending
                    </Button>
                  </li>
                  {props.item.paid_status === 'yes' && (
                    <li>
                      <Button
                        className={chatType === 'RSVP' ? 'active' : ''}
                        onClick={() => {
                          setShowLoader(true);
                          setTimeout(() => {
                            setShowLoader(false);
                          }, 1000);
                          setChatType('RSVP');
                        }}>
                        RSVP
                      </Button>
                    </li>
                  )}
                  <li>
                    <Button
                      className={chatType === 'Media' ? 'active' : ''}
                      onClick={() => {
                        setShowLoader(true);
                        setTimeout(() => {
                          setShowLoader(false);
                        }, 1000);
                        setChatType('Media');
                      }}>
                      Media
                      <i className="fa-solid fa-camera-viewfinder"></i>
                    </Button>
                  </li>
                </ul>
              </div>

              <div className="person-search-wrapper messages-users-wrapper mt-0">
                <div className="search-form d-flex">
                  <Form.Group className="search-input">
                    {chatType === 'Attendees' && (
                      <Form.Control
                        type="text"
                        placeholder="Who are you looking for?"
                        onChange={e => {
                          handleAttendeesSearch(
                            searchModDetail,
                            serachEventData,
                            e.target.value,
                          );
                        }}
                      />
                    )}
                    {chatType === 'RSVP' && (
                      <Form.Control
                        type="text"
                        placeholder="Who are you looking for?"
                        onChange={e => {
                          handleModeratorSearch(searchQry, e.target.value);
                        }}
                      />
                    )}
                    {chatType === 'Pending' && (
                      <Form.Control
                        type="text"
                        placeholder="Who are you looking for?"
                        onChange={e => {
                          handleSearchPenMod(
                            searchPenMod,
                            searchEventAud,
                            e.target.value,
                          );
                        }}
                      />
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="loader-container">
                {' '}
                {showLoader && <LoaderSpinner />}
                {chatType === 'Attendees' ? (
                  <div className="stage-list-item mt-3">
                    {modDetail.length > 0 || eventData.length > 0 ? (
                      <>
                        {modDetail.length > 0 && (
                          <div className="stage-header">
                            <h3>Moderator</h3>
                          </div>
                        )}
                        <div className="event-persons-list">
                          {Object.entries(modDetail).length > 0 ? (
                            modDetail.map(item => {
                              return (
                                <div
                                  key={`adcdefgg` + item.id}
                                  className="stage-person-img as-link"
                                  onClick={() => {
                                    setUserId(item.id);
                                    setTypeModAud(true);
                                    setShowComponent('profile');
                                  }}>
                                  <div className="user-img as-link">
                                    <img
                                      src={
                                        item.profile_img === ''
                                          ? DEFAULT_IMAGE
                                          : item.profile_img
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="stage-person-name">
                                    <h3>
                                      {fixName(item.first_name, item.last_name)}
                                    </h3>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>

                        {eventData.length > 0 && (
                          <div className="stage-header mt-4">
                            <h3>Audience</h3>
                          </div>
                        )}
                        <div className="event-persons-list">
                          {Object.entries(eventData).length > 0 ? (
                            eventData.map(item => {
                              return (
                                <div
                                  className="stage-person-img as-link"
                                  key={`oooooooooo-2233` + item.id}
                                  onClick={() => {
                                    setUserId(item.id);
                                    setTypeModAud(true);
                                    setShowComponent('profile');
                                  }}>
                                  <div className="user-img as-link">
                                    <img
                                      src={
                                        item.profile_img === ''
                                          ? DEFAULT_IMAGE
                                          : item.profile_img
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="stage-person-name">
                                    <h3>
                                      {fixName(item.first_name, item.last_name)}
                                    </h3>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <p className="text-center">No records found</p>
                    )}
                  </div>
                ) : chatType === 'Pending' ? (
                  <>
                    <div className="stage-list-item mt-3">
                      {penMod.length > 0 || eventAud.length > 0 ? (
                        <>
                          {penMod.length > 0 && (
                            <div className="messages-users-list">
                              <div className="stage-header">
                                <h3>Moderators</h3>
                              </div>

                              <div className="event-persons-list">
                                {Object.entries(penMod).length > 0 ? (
                                  penMod?.map((item, index) => {
                                    return (
                                      <div
                                        key={'penMod-' + item.id}
                                        className={'stage-person-img as-link'}
                                        onClick={() => {
                                          setUserId(item.id);
                                          setProfile(true);
                                          setShowComponent('AtProfile');
                                        }}>
                                        <div className="user-img as-link">
                                          <img src={item.profile_img} alt="" />
                                        </div>
                                        <div className="stage-person-name">
                                          <h3>
                                            {fixName(
                                              item.first_name,
                                              item.last_name,
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          )}

                          {eventAud.length > 0 && (
                            <div className="stage-header mt-4">
                              <h3>Audience</h3>
                            </div>
                          )}
                          <div className="event-persons-list">
                            {Object.entries(eventAud).length > 0 ? (
                              eventAud?.map((item, index) => {
                                return (
                                  <div
                                    key={'audi-' + item.id}
                                    className={'stage-person-img as-link'}
                                    // onClick={() =>{}}
                                  >
                                    <div
                                      className="user-img as-link"
                                      onClick={() => {
                                        setUserId(item.id);
                                        setProfile(true);
                                        setShowComponent('AtProfile');
                                      }}>
                                      <img
                                        src={
                                          item.profile_img === ''
                                            ? DEFAULT_IMAGE
                                            : item.profile_img
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="stage-person-name">
                                      <h3>
                                        {fixName(
                                          item.first_name,
                                          item.last_name,
                                        )}
                                      </h3>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                              // <p className="text-center">No Record Found</p>
                            )}
                          </div>
                        </>
                      ) : (
                        <p className="text-center">No records found</p>
                      )}
                    </div>
                  </>
                ) : chatType === 'RSVP' ? (
                  <div className="stage-list-item">
                    <div className="event-persons-list">
                      {Object.entries(rsvp).length > 0 ? (
                        rsvp?.map((item, index) => {
                          return (
                            <div
                              key={'rsvp-' + item.id}
                              className={'stage-person-img as-link'}
                              // onClick={() =>{}}
                            >
                              <div
                                className="user-img as-link"
                                onClick={() => {
                                  setUserId(item.id);
                                  setProfile(true);
                                  setShowComponent('AtProfile');
                                }}>
                                <img
                                  src={
                                    item.profile_img === ''
                                      ? DEFAULT_IMAGE
                                      : item.profile_img
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="stage-person-name">
                                <h3>
                                  {fixName(item.first_name, item.last_name)}
                                </h3>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-center">No records found</p>
                      )}
                    </div>
                  </div>
                ) : chatType === 'Media' ? (
                  <div className="events-wrapper">
                    <div
                      className={`sidebar-heading-wrapper mt-0 no-margin ${
                        props.windowWidth <= 1295 ? 'with-panel' : ''
                      }`}>
                      {mediaImg.length === 0 && (
                        <>
                          <h2 className="m-0">
                            <i className="fa-solid fa-camera"></i> Capture This
                            Moment
                          </h2>
                        </>
                      )}
                    </div>
                    <div className="images-list large-size mt-3 event-detail-img-list">
                      <div className="images-list-item">
                        {new Date(serverTime) >
                          new Date(sessionData.session_start_time) &&
                          new Date(serverTime) <
                            new Date(sessionData.end_time) &&
                          !props.history && (
                            <div className="camera-icon">
                              <i className="icon-camera-outline" />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={e => changeTicketImg(e)}
                              />
                            </div>
                          )}
                      </div>
                      {Object.entries(mediaImg).length > 0 ? (
                        mediaImg?.map((item, index) => {
                          return (
                            <div
                              className="images-list-item"
                              key={'mediaImger-' + index}
                              onClick={() => {
                                imgHandle(item);
                              }}>
                              <img src={item} alt="" />
                            </div>
                          );
                        })
                      ) : (
                        <div className="events-desc-wrapper pb-3">
                          <p>
                            No moments have been captured at this event yet. Be
                            the first!
                          </p>
                          {/* <span
                       
                        >
                          {new Date(serverTime) >
                            new Date(sessionData.session_start_time) && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={e => changeTicketImg(e)}
                            />
                          )}
                          <i className="fa-solid fa-camera"></i>
                        </span> */}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              </div>
            </div>
          </Scrollbars>

          {/* <div className="filter-btn-wrapper">
              {new Date(serverTime) <
                new Date(sessionData.session_start_time) &&
                !props.history &&
                props.item.creator_id === localStorage.getItem('id') && (
                  <Button
                    className="btn-dark"
                    onClick={() => {
                      setCreateEvent(true);
                      setShowComponent('editEvent');
                    }}>
                    Edit
                  </Button>
                )}
            </div> */}
        </div>
      </SlidingPanel>

      {/* {showComponent} */}
      {showComponent === 'CommonChatPage' && (
        <SmartChatPage
          allmsg={true}
          type={'event-chats'}
          item={[]}
          chatroomInfo={chatroomInfo(chatDetail)}
          oppoDetails={chatDetail}
          chatDetails={chatDetail}
          allChatUsers={collectingUsers(chatDetail)}
          chatRoomName={chatDetail.chat_room_name}
          chatRoomId={chatDetail.id}
          onClose={() => {
            // setChatButton(false);
            setShowComponent('');
          }}
          handleCommonChat={(data, type) => {
            handleCommonChat(data, type);
          }}
        />

        // <SlidingPanel
        //   hidePanel={() => {
        //     setChatButton(false);
        //     setShowComponent('');
        //   }}>
        /* <SmartChatPage
            type={'personal-group'}
            chatDetails={props.item.group_chat_detail}
            oppoDetails={props.item.group_chat_detail}
            allChatUsers={props.item.group_chat_detail.group_detail}
            chatRoomName={props.item.group_chat_detail.chat_room_name}
            chatRoomId={props.item.group_chat_detail.id}
            chatRoomStatus={props.item.group_chat_detail.chat_room_status}
            onClose={() => {
              setChatButton(false);
              setShowComponent('');
            }}
          /> */
        // </SlidingPanel>
      )}
      {userId && typeModAud === true && showComponent === 'profile' && (
        <>
          <ProfileComponent
            userId={userId}
            show={false}
            closeModal={() => {
              setTypeModAud(false);
              setShowComponent('');
            }}
            userData={[]}
            moderator={false}
            // setParticipantType={setParticipantType}
            onClose={() => {
              setProfile(false);
              setShowComponent('');
            }}
            VoxeetSDK={[]}
            conferenceInfo={[]}
            jdSpecialM={[]}
            jdSpecialA={[]}
            serverTime={serverTime}
            sessionData={sessionData}
          />
        </>
      )}
      {showComponent === 'profileComponeent' && (
        <>
          <ProfileComponent
            userId={userId}
            show={false}
            closeModal={() => {
              setTypeModAud(false);
              setShowComponent('');
            }}
            userData={[]}
            moderator={false}
            // setParticipantType={setParticipantType}
            onClose={() => {
              setProfile(false);
              setShowComponent('');
            }}
            VoxeetSDK={[]}
            conferenceInfo={[]}
            jdSpecialM={[]}
            jdSpecialA={[]}
            // serverTime={serverTime}
            // sessionData={sessionData}
          />
        </>
      )}
      {createEvent === true && showComponent === 'editEvent' && (
        // <SlidingPanel
        //   hidePanel={() => {
        //     setCreateEvent(false);
        //     setShowComponent('');
        //   }}>
        //   <Scrollbars>
        <CreateSession
          onClose={() => {
            setCreateEvent(false);
            setShowComponent('');
          }}
          editEvent={props.item}
          getApiSessionList={props.getApiSessionList}
          // getApiSessionList(sessionType, "", !typeIs, pageNo);
          sessionType={props.sessionType}
          typeIs={props.typeIs}
          typeOfReferEvent={props.type === 'refer' ? true : false}
          createEvent={createEvent}
          Close={() => props.onClose()}
        />
        //   </Scrollbars>
        // </SlidingPanel>
      )}
      {showSession === true && showComponent === 'session' && (
        // <SlidingPanel
        //   hidePanel={() => {
        //     setShowSession(false);
        //     setShowComponent('');
        //   }}>
        //   <Scrollbars>
        <CreateSession
          onClose={() => {
            setShowSession(false);
            setShowComponent('');
          }}
          createSesion={props.item}
          hideEvent={hideEvent}
          setHideEvent={setHideEvent}
        />
        //   </Scrollbars>
        // </SlidingPanel>
      )}
      {userId && profile === true && showComponent === 'AtProfile' && (
        <>
          {/* <SlidingPanel
            hidePanel={() => {
              setShowComponent('');
              setProfile(false);
              // setShowComponent('');
            }}>
            <Scrollbars> */}
          {/* {typeModAud !== true && (
                <div className="invite-btns">
                  <Button type="submit" onClick={submit}>
                    MARK ATTENDANCE
                  </Button>
                </div>
              )} */}

          <ProfileComponent
            userId={userId}
            show={false}
            closeModal={() => {
              setProfile(false);
              setShowComponent('');
            }}
            userData={[]}
            moderator={false}
            // setParticipantType={setParticipantType}
            onClose={() => {
              setProfile(false);
              setShowComponent('');
            }}
            VoxeetSDK={[]}
            conferenceInfo={[]}
            jdSpecialM={[]}
            jdSpecialA={[]}
            submit={submit}
            typeModAud={typeModAud}
            showButton={true}
            serverTime={serverTime}
            sessionData={sessionData}
          />
          {/* </Scrollbars>
          </SlidingPanel> */}
        </>
      )}

      {/* {showComponent === '' && ( */}

      {/* )} */}
      {showPrew && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowPrew(false);
          }}>
          <div className="chat-img-preview">
            <img src={modelImg} alt="" />
          </div>
        </CustomModal>
      )}

      {showModel === 'modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            ratio={92 / 89}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setShowLoader}
            addMomentMedia={addMomentMedia}
          />
        </CustomModal>
      )}
      {showComponent === 'event_Deatil' && (
        <SessionDetail
          item={props.item}
          eventImages={eventImages}
          rsvp={rsvp}
          onClose={() => setShowComponent('')}
          //Temporary state for remove warning
          setDestination={setDestination}
        />
      )}
      {/* <Modal
        show={showPrew}
        onHide={() => {
          setShowPrew(false);
        }}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Footer>
          <Col>
            <div className="text-center">
              <img src={modelImg} alt="" />
            </div>
          </Col>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(EventDetail);
