export const companyOferringsList = (
    state = [],
    action,
  ) => {
    switch (action.type) {
      case 'GET_COMPANY_OFFERINGS':
        return action.payload ? action.payload : [];
      default:
        return state;
    }
  };