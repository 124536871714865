import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import commonApi from '../../Services/CommonService';
import { successMessage} from '../../Utils/ToastMessages';
import {DEFAULT_IMAGE} from '../../Utils/env';
import moment from 'moment/moment';
import {UserGroupMini} from '../../Constants/Images';

const ShreAblesEvent = props => {
  const {item} = props;
  const [favourite, setFavourite] = useState(item.favourite);
  var totalMem = item.total_members + 1;
  var totalMode = item.total_moderators + 1;

  useEffect(() => {
    
  }, []);

  const manageFavourite = () => {
    var payloadManageFavourite = {
      conference_id: item.id,
    };
    commonApi
      .manage_favourite(payloadManageFavourite)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            setFavourite(true);
            item.favourite = true;
          } else {
            setFavourite(false);
            item.favourite = false;
          }
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    item.favourite = favourite;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourite]);

  // const _renderUserImg = (person) => {
  //     return (
  //         <div className="img-item">
  //             {person.owner && (
  //                 <span className="owner-icon">
  //                     <i className="fas fa-crown" />
  //                 </span>
  //             )}
  //             {!person.owner && person.isOnline && <span className="online-icon"></span>}
  //             {person.speaker && (
  //                 <span className="speaker-icon">
  //                     <i className="fas fa-microphone" />
  //                 </span>
  //             )}
  //             <img src={person.image} alt="" />
  //         </div>
  //     );
  // };

  const _renderCreatorImg = person => {
    return (
      <div className="img-item">
        {
          <span className="owner-icon">
            <i className="fas fa-crown" />
          </span>
        }
        <img
          src={person.creator_img === '' ? DEFAULT_IMAGE : person.creator_img}
          alt=""
        />
      </div>
    );
  };

  const _renderAudiImg = person => {
    return (
      <div key={`p-id-${person.id}`} className="img-item">
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };

  return (
    <div
      className={
        !props.typeMesages
          ? 'as-link card-container'
          : 'as-link container-fromChat-Shareables'
      }>
      <div className={!props.typeMesages ? 'padd-with-borderContaier' : ''}>
        <div
          className="event-img"
          onClick={() => {
            if (!props.typeForNpClick) {
              props.componentDecesion(item, 'event');
            }
           
          }}>
          <Link to={`#`}>
            <img
              src={
                item.image
                  ? item.image.slider_images
                  : 'https://build-dev.s3.us-east-1.amazonaws.com/no-shop.png'
              }
              alt=""
            />
          </Link>
        </div>
        <div className="session-heading d-flex justify-content-between align-items-center">
          <div
            className="event-titles as-link"
            onClick={() => {
              props.componentDecesion(item, 'event');
            }}>
            <h2>{item.session_title}</h2>
            {item.start_time === '0000-00-00 00:00:00' ? (
              <></>
            ) : (
              <div className="event-date">
                {moment(item.start_time).format('MMMM Do YYYY, h:mmA')}
              </div>
            )}
            <div className="event-users-cate">
              <span className="users-text usercount-type as-link">
                <img src={UserGroupMini} height={16} width={16} alt="" />
                <span className="userCount">{`${totalMem}/${totalMode}`}</span>
                <span className="category-text as-link">
                  {item.category_name}
                </span>
              </span>
            </div>
          </div>
          {!props.typeMesages && (
            <div className="event-share">
              {/* <i
                className="fas fa-share as-link"
                onClick={() => {
                  props.componentDecesion(item, 'Refer');
                }}
              /> */}
            </div>
          )}
        </div>
        <div
          className="persons-img-list as-link"
          onClick={() => {
            props.componentDecesion(item, 'event');
          }}>
          {_renderCreatorImg(item)}

          {item.moderator_ids &&
            Object.entries(item.moderators_list).length > 0 &&
            item.moderators_list
              .slice(0, 2)
              .map((person, index) => _renderAudiImg(person))}

          {item.audience_ids &&
            Object.entries(item.audience_list).length > 0 &&
            item.audience_list
              .slice(0, 3)
              .map((person, index) => _renderAudiImg(person))}

          {/* {Object.entries(item.persons).length > 0 && item.persons.map((person, index) => _renderUserImg(person))} */}
        </div>
        <div className="events-place-like">
          <span
            className="address as-link"
            onClick={() => {
              props.componentDecesion(item, 'event');
            }}>
            <i className="fas fa-location-dot" /> {item.address}
          </span>
          <span
            className="event-amount as-link"
            onClick={() => {
              props.componentDecesion(item, 'event');
            }}>
            {item.max_cost === item.min_cost &&
              item.max_cost !== 0 &&
              '$' + item.max_cost}
            {item.max_cost !== item.min_cost &&
              item.max_cost !== 0 &&
              `$${item.min_cost} - $${item.max_cost}`}
            {/* ${item.price ? item.price : 0} */}
          </span>
          {!props.typeMesages && (
            <span
              className="event-heart as-link"
              onClick={() => {
                if (props.filterFavroiuteList) {
                  props.filterFavroiuteList(item.id);
                }
                manageFavourite();
              }}>
              {item.favourite && <i className="fas fa-heart" />}
              {!item.favourite && <i className="far fa-heart" />}
            </span>
          )}
        </div>
      </div>
      {!props.typeMesages && !props.type && props.ElementDesign('event', props.item)}
    </div>
  );
};

export default ShreAblesEvent;
