import React from 'react'; // For React components
import CustomModal from '../CustomModal';
import Button from 'react-bootstrap/Button';

const AlertModel = props => {
  // Component logic and rendering here

  return (
    <CustomModal onClose={() => props.onClose()}>
      <div className="remove-user-text">
        <b>Remove {props.type === 'recursion' ? 'Date' : 'Image'}!! </b>
        <div>
          <p className="mt-2">
            Are you sure you want to remove this{' '}
            {props.type === 'recursion' ? 'date?' : 'image?'}
          </p>
        </div>
        <Button
          className="btn-dark"
          onClick={() => {
            props.removeImage();
            props.onClose();
          }}>
          Yes
        </Button>
        <Button
          className="btn-dark"
          onClick={() => {
            props.onClose();
          }}>
          No
        </Button>
      </div>
    </CustomModal>
  );
};
export default AlertModel;
