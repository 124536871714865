"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrowserEventService = void 0;
const Service_1 = require("../Service");
/**
 * A service to handle browser window event
 * @ignore
 * @noInheritDoc
 */
class BrowserEventService extends Service_1.BaseConferenceService {
    /**
     * @ignore
     * @param sdk
     */
    constructor(sdk, conferenceService) {
        super(sdk);
        this._conferenceService = conferenceService;
        window.onbeforeunload = (event) => {
            const activeElement = event.target.activeElement;
            const isMailto = activeElement && activeElement.href && activeElement.href.startsWith('mailto:');
            const isTel = activeElement && activeElement.href && activeElement.href.startsWith('tel:');
            if (!isMailto && !isTel && this._conferenceService.current) {
                this.leaveAfterDelay(1500);
            }
        };
    }
    /**
     * leave the conference after a delay
     */
    leaveAfterDelay(delay) {
        this._conferenceService.leave({ keepAlive: true, reason: 'tabClosure' });
        this.sleep(delay);
    }
    /**
     * Blocks execution during delay ms
     */
    sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay)
            ;
    }
}
__decorate([
    Service_1.ConferenceNotNull()
], BrowserEventService.prototype, "leaveAfterDelay", null);
exports.BrowserEventService = BrowserEventService;
