import React,{useState,useEffect} from 'react';
import CustomModal from '../CustomModal';
import {Button,Dropdown} from 'react-bootstrap';

export default function ConfirmationModel({
  closeModel,
  discription,
  buttonOne,
  buttonTwo,
  ButtonOneAction,
  dropdownMenu,
  setShiftPhaseId
}) {



  const [type, setType] = useState(dropdownMenu ? dropdownMenu[0].id :'');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    dropdownMenu && setShiftPhaseId(dropdownMenu ? dropdownMenu[0].id : '')
  }, [])
  

  return (
    <CustomModal onClose={() => closeModel()}>
      <div className="remove-user-text">
        <p>
          {discription}
          <b></b>
        </p>

        {dropdownMenu && (
          <div className="roi-form-dropdown  mb-3">
              <div className="select-wrapper">
                <Dropdown
                  onSelect={(eventKey, event) => {
                    setType(eventKey);
                    setShiftPhaseId(eventKey)
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type
                      ? dropdownMenu.find(item => item.id === type)?.phase
                      : ' '}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {dropdownMenu.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item.id}
                        active={item.id === type}>
                        {item.phase}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

                  )}

        <div>
          {buttonOne && (
            <Button
              className="btn-dark m-0"
              disabled={isButtonDisabled}
              onClick={() => {
                ButtonOneAction()
                setIsButtonDisabled(true)
                
              }}>
              {buttonOne}
            </Button>
          )}
          {/* <Button
        className="btn-dark"
        onClick={() => {
          setShowModel(false);
        }}>
        No Thanks!
      </Button> */}
          {buttonTwo && (
            <p
              className="text-yellow mt-3 mb-0 as-link"
              style={{fontSize: 14}}
              onClick={() => {
                closeModel();
              }}>
              {buttonTwo}
            </p>
          )}
        </div>
      </div>
    </CustomModal>
  );
}
