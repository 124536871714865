const emojisData = [
    { name: 'angry-face.svg' },
    { name: 'anxious-face-with-sweat.svg'},
    { name: 'astonished-face.svg'},
    { name: 'call-me-hand-sign.svg'},
    { name: 'clapping-hands.svg'},
    { name: 'closed-fist.svg'},
    { name: 'drooling-face.svg'},
    { name: 'face-blowing-a-kiss.svg'},
    { name: 'face-with-medical-mask.svg'},
    { name: 'face-with-rolling-eyes.svg'},
    { name: 'face-with-stuck-out-tongue-and-squinting-eyes.svg'},
    { name: 'face-with-tears-of-joy.svg'},
    { name: 'fingers-crossed.svg'},
    { name: 'flexed-bicep.svg'},
    { name: 'grimacing-face.svg'},
    { name: 'grinning-face-with-squinting-eyes.svg'},
    { name: 'grinning-face-with-sweat.svg'},
    { name: 'grinning-face.svg'},
    { name: 'hands-pressed-togather.svg'},
    { name: 'loudly-crying-face.svg'},
    { name: 'money-mouth-face.svg'},
    { name: 'neutral-face.svg'},
    { name: 'ok-hand-sign.svg'},
    { name: 'palms-togather-face-up.svg'},
    { name: 'pleading-face.svg'},
    { name: 'raised-fist.svg'},
    { name: 'sleeping-face.svg'},
    { name: 'smiling-face-with-halo.svg'},
    { name: 'smiling-face-with-heart-eyes.svg'},
    { name: 'smiling-face-with-smiling-eyes.svg'},
    { name: 'smilling-face-with-horns.svg'},
    { name: 'surrprised-face-with-open-mouth.svg'},
    { name: 'thumbs-down.svg'},
    { name: 'thumbs-up.svg'},
    { name: 'vomiting-face.svg'},
    { name: 'waving-hand.svg'},
    { name: 'winking-face.svg'},
    { name: 'yawning-face.svg'}
]

export {
    emojisData
};
