export const apointSlots = (state = [], action) => {
  switch (action.type) {
    case 'APPOINTMENT/SLOTS':
      return action.payload;
    default:
      return state;
  }
};

export const apointCurrentDate = (state = '', action) => {
  switch (action.type) {
    case 'APPOINTMENT/DATE':
      return action.payload;
    default:
      return state;
  }
};


export const appointmentList = (state = [], action) => {
  switch (action.type) {
    case 'APPOINTMENT/DATA':
      return action.payload;
    default:
      return state;
  }
};


export const userProfileAppointmentList = (state = [], action) => {
  switch (action.type) {
    case 'APPOINTMENT/USER_DATA':
      return action.payload;
    default:
      return state;
  }
};


