import React, {useState, useEffect} from 'react';
// import { connect } from "react-redux";
import {Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {useDispatch,useSelector} from 'react-redux';
// import {callChatApi} from '../Store/Actions/ChatAction';
import {callDeepLink} from '../Store/Actions/DeepLinkAction';
import {callOnBoardingApi} from '../Store/Actions/OpportunityAction';
import {appendFormData, isValidNumber} from '../Utils/Helpers';
import {getItem, setItem} from '../Utils/LocalStorage';
import {labels} from '../Constants/Translations';
import {selected_lang} from '../Utils/env';
import {useHistory} from 'react-router-dom';
import {DEFAULT_IMAGE} from '../Utils/env';
// import API from '../Services/SessionServices';
import commonApi from '../Services/CommonService';
// import {errorMessage} from '../Utils/ToastMessages';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import {getActiveCompanyUserMenus, getUserMenuPermesionList} from '../Store/Actions/MenuAction';
import { checkMenuExists } from '../Utils/commonUtils';
import { errorMessage } from '../Utils/ToastMessages';
import { registerRoute } from '../Store/Actions/CommanAction';
const VerifyPhone = ({
  otp,
  newUser,
  phone,
  showAlert,
  updatePhone,
  resetForm,
  verifyOtp,
  inviName,
  inviImg,
  inviDate,
  requestForOtp,
  button,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [num1, setNumOne] = useState('');
  const [num2, setNumTwo] = useState('');
  const [num3, setNumThree] = useState('');
  const [num4, setNumFour] = useState('');
  const [num5, setNumFive] = useState('');
  const [num6, setNumSix] = useState('');
  const [loginData, setLoginData] = useState({status: 500});
  // const [onBoardingModal, setonBoardingModal] = useState(false);
  // const [onBoardingModalData, setonBoardingModalData] = useState([]);
  const [seconds, setSeconds] = useState(60);

  const history = useHistory();

  const location = useLocation();
  // const id = new URLSearchParams(location.search).get('id');

  const companyMenuList = useSelector(state => state.menuActiveList);

  const validateData = () => {
    const tempErr = {};
    const tempOtp = `${num1}${num2}${num3}${num4}${num5}${num6}`;

    if (tempOtp === '' || tempOtp.length < 6) {
      tempErr.otp = 'Please enter valid OTP.';
    }

    return tempErr;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const submitForm = e => {
    e.preventDefault();
    const errs = validateData();
    setItem('skinToneId', 1);

    if (!loading && Object.entries(errs).length === 0) {
      setLoading(true);

      // let formData = '';
      var data = '';
      const tempOtp = `${num1}${num2}${num3}${num4}${num5}${num6}`;

      if (newUser && Object.entries(newUser).length > 0) {
        newUser.is_new_user = 'Yes';
        newUser.otp = tempOtp;
        appendFormData(newUser);
      } else {
        data = {
          phoneNumber: '+' + phone,
          code: JSON.stringify(parseInt(tempOtp)),
        };
      }

      // API CALL

      try {
        verifyOtp(data)
          .then(res => {
            let response = res;
            const type = res.type === false ? 'danger' : 'success';
            if (res.exist && !res.delete) {
              // setItem('userRole', res.invited ? 'user' : 'guest');
              // dispatch(getActiveCompanyUserMenus());
              dispatch(getUserMenuPermesionList());
              
              setItem('userRole', 'user');
              setLoginData(res);
              showAlert(type, res.message);
              // setLoading(false);
              // updatePhone();
              // resetForm();
              if (res.type === true) {
                commonApi
                  .get_preferences()
                  .then(res => {
                    if (res) {
                      localStorage.setItem(
                        'skinToneId',
                        res.data.id ? res.data.id : 1,
                      );
                      setLoading(false);

                      dispatch(callDeepLink());

                      // console.log(response.invitation_status,'response.invitation_status')

                     

                      var urlUser = window.location.pathname;
                      urlUser = urlUser.slice(1);
                      var url = urlUser.split('/').filter(Boolean);

                      
                      

                      // if (url && url[1] == 'session_invite') {
                      //   joinTheSession(url[2]);
                      // }
                      // if (url && url[1] == 'opportunity_invite') {
                      //   history.push(
                      //     '/opportunities/opportunity_invite/' + url[2],
                      //   );
                      //   // window.location.reload();
                      // }
                      // if (url && url[1] == 'event_invite') {
                      //   history.push('/events/event_invite/' + url[2]);
                      //   // window.location.reload();
                      // }
                      // if (url && url[1] == 'profile_invite') {
                      //   history.push('/events/profile_invite/' + url[2]);
                      //   // window.location.reload();
                      // }
                      // if (
                      //   url &&
                      //   url[1] != 'opportunity_invite' &&
                      //   url[1] != 'session_invite' &&
                      //   url[1] != 'event_invite' &&
                      //   url[1] != 'profile_invite'
                      // ) {
                      //   // history.push('/');
                      const redirectToUrl =
                        location.state && location.state.redirectTo;

                        if(response.invitation_status && response.invitation_status ==='pending'){
                        
                          history.push('/updateProfile');
                        }
                      
                     else if (redirectToUrl) {
                        localStorage.setItem('slot_id', location.state.slot_id);

                        // history.push('/appointmentpage', {slot_id: location.state.id_slot});
                        const slotId = location.state.id_slot;
                        history.push({
                          pathname: '/appointmentpage',
                          state: {
                            slot_id: slotId,
                            hehe: 'Daniyal',
                          },
                        });
                      } else if (url && url[2] === 'consult') {
                        const queryParams = new URLSearchParams(
                          window.location.search,
                        );
                        // const search = window.location.search;
                        if (url[0] === getItem('userName')) {
                          history.push('/');
                        } else {
                          // alert('')
                          history.push(
                            '/' +
                              url[0] +
                              '/' +
                              url[1] +
                              '?consult=' +
                              queryParams.get('type'),
                          );
                        }
                      } 
                      
                      else if (url && (url[1] === 'offerings' || url[1] === 'recruitment')) {
                        if(url[1] === 'offerings'){
                          AddOutsideUserInCompany(window.location.href,'business_offerings', url[0] )
                        }
                        // else if(url[1] === 'recruitment'){
                        //   AddOutsideUserInCompany(window.location.href,'recruitment', url[0] )
                        // }
                        
                      }
                      
                      else if (
                        url &&
                        [
                          'session',
                          'sessions',
                          'meetup',
                          'recruitment',
                          'profile',
                          'event',
                          'events',
                          'opportunity',
                          'opportunities',
                          'consult',
                          'consults',
                          'question',
                          'questions',
                          'virtual',
                          'in-person',
                          'schedule',
                          'group-chat',
                          'snapshot',
                          'profile',
                          
                        ].includes(url[2])
                      ) {
                      } else {
                        history.push('/');
                      }
                    }
                  })
                  .catch(err => {
                    console.log('Err', err);
                    return {type: 'error', message: err.message};
                  });
              } else {
                setLoading(false);
              }
            } else if (res.type === false) {
              showAlert(type, res.message);
              setLoading(false);
            } else {
              commonApi
                .get_on_boarding_details()
                .then(response => {
                  if (response) {
                    // onboarding modal
                    // setonBoardingModalData(res);
                    // setonBoardingModal(true);
                    setLoading(false);
                    // var urlUser = window.location.pathname;
                    // urlUser = urlUser.slice(1);
                    // var url = urlUser.split(/[.\-/]/);
                    // if (url && url[1]) {
                    //   var urlMade = {type: url[1], id: url[2]};
                    //   localStorage.setItem(
                    //     'invitedLink',
                    //     JSON.stringify(urlMade),
                    //   );
                    // }
                    const {pathname, search} = window.location;
                    if (pathname.includes('login')) {
                    } else {
                      localStorage.setItem('invitedLink', pathname + search);
                    }

                    // onboarding page
                    dispatch(callOnBoardingApi(response));
                    setTimeout(() => {
                      // history.push('/on-boarding');
                      localStorage.setItem('signup', true);
                      dispatch(registerRoute(true));
                      history.push({
                        pathname: '/registercompany',
                        // state: {
                        //   button :button,
                        //   from: 'login',
                        //   // typeIs: 'chats',
                        //   res,
                        // },
                      });
                    }, 1000);
                  }
                })
                .catch(err => {
                  console.log('Err', err);
                  return {type: 'error', message: err.message};
                });
            }
          })
          .catch(err => {
            showAlert('danger', err.message);
            setLoading(false);
          });
      } catch (err) {
        showAlert('danger', err.message);
      }
    } else {
      showAlert('danger', labels.enterValidOtp[selected_lang]);
      // setErrors(errs);
    }

    return false;
  };


  const  AddOutsideUserInCompany = async (href, type,company) =>{
  
    
   
    
    const url = new URL(href);
    const pathname = url.pathname; 
    const id = url.searchParams.get('id');
    
    console.log(type,'type')
    const newPath = `${pathname}?id=${id}`;

    console.log(newPath,'newPath')
    
    try {
      const payload = {
          company_name: company ? company.replace('-', ' ') : '',
          user_id: localStorage.getItem('id'),
      };
    
      const res = await commonApi.add_user_in_company(payload);
      const activeMenusResponse = await commonApi.get_active_company_user_menus();
    
      console.log(activeMenusResponse,'activeMenusResponse')
      if (res && activeMenusResponse) {
        let result;
        if(type === 'offerings'){
           result = await checkMenuExists('offerings', activeMenusResponse);
        }
        else if(type === 'recruitment'){
          result = await checkMenuExists('recruitment', activeMenusResponse);
        }
          
          console.log(result, 'resultresult');
    
          if (result) {
            // alert('')
            //   history.push(newPath, { typeIs: type });
          } else {
              errorMessage('This Menu is not available to you, Please contact your Administrator');
              history.push(activeMenusResponse.menus.menu_details[0].link);
          }
      }
    } catch (err) {
      console.log('Err', err);
      return { type: 'error', message: err.message };
    }
    
    
    
    
    }

    const handleResetOtp = () =>{
      setNumOne('');
      setNumTwo('');
      setNumThree('');
      setNumFour('');
      setNumFive('');
      setNumSix('');
    }

  // const joinTheSession = async id => {
  //   let payload = {conference_id: id};
  //   const response = await API.is_session_started(payload);

  //   if (response.started) {
  //     if (response.session_id) {
  //       localStorage.setItem('conferenceId', response.session_id);
  //       localStorage.setItem('conf_id', id);
  //       history.push('/session-details');
  //       window.location.reload();
  //     } else {
  //     }
  //   } else {
  //     errorMessage(response.message);
  //     history.push('/');
  //     window.location.reload();
  //   }
  // };
  const updateNumber = (e, target) => {
    const number = e.target.value;

    if (!isValidNumber(number)) {
      switch (target) {
        case 'one':
          setNumOne(number);
          if (number) {
            document.getElementById('num-2').focus();
          }
          break;
        case 'two':
          setNumTwo(number);
          if (number) {
            document.getElementById('num-3').focus();
          }
          break;
        case 'three':
          setNumThree(number);
          if (number) {
            document.getElementById('num-4').focus();
          }
          break;
        case 'four':
          setNumFour(number);
          if (number) {
            document.getElementById('num-5').focus();
          }
          break;
        case 'five':
          setNumFive(number);
          if (number) {
            document.getElementById('num-6').focus();
          }
          break;
        default:
          setNumSix(number);
          break;
      }
    }
  };

  useEffect(() => {
    if (otp) {
      const number = `${otp}`;
      setNumOne(number.charAt(0));
      setNumTwo(number.charAt(1));
      setNumThree(number.charAt(2));
      setNumFour(number.charAt(3));
      setNumFive(number.charAt(4));
      setNumSix(number.charAt(5));
    }
  }, [otp]);
  // useEffect(() => {
  //   var urlUser = window.location.pathname;
  //   urlUser = urlUser.slice(1);
  // });
  useEffect(() => {
    if (num1 && num2 && num3 && num4 && num5 && num6) {
      setErrors({otp: ''});
    }
  }, [num1, num2, num3, num4, num5, num6]);

  useEffect(() => {
    if (loginData.status === 200 && loginData.type === true) {
      setItem('allUserData', JSON.stringify(loginData.user));
      setItem('user', loginData.user.id);
      setItem('id', loginData.user.id);
      setItem('chatUserId', loginData.user.id);
      setItem(
        'profilePic',
        loginData.user.profile_img ? loginData.user.profile_img : DEFAULT_IMAGE,
      );
      setItem('bio', loginData.user.bio);
      setItem('currentLocation', loginData.user.current_location);
      setItem(
        'currentLocationLatitude',
        loginData.user.current_location_latitude,
      );
      setItem(
        'currentLocationLongitude',
        loginData.user.current_location_longitude,
      );
      setItem('email', loginData.user.email);
      setItem('fcmToken', loginData.user.firebase_token);
      setItem('firstName', loginData.user.first_name);
      setItem('homeLocation', loginData.user.home_location);
      setItem('homeLocationLatitude', loginData.user.home_location_latitude);
      setItem('homeLocationLongitude', loginData.user.home_location_longitude);
      setItem('instagram', loginData.user.instagram);
      // setItem("id", loginData.user.invitation_id);
      setItem('lastName', loginData.user.last_name);
      setItem('linkedin', loginData.user.linkedin);
      setItem('number', loginData.user.phone);
      setItem('qrCode', loginData.user.qr_code);
      // setItem("id", loginData.user.role_id);
      // setItem("id", loginData.user.unique_user_name);
      // setItem("id", loginData.user.verification_status);
      setItem('web', loginData.user.web);
      setItem('youtube', loginData.user.youtube);
      setItem('twitter', loginData.user.twitter);
      setItem('otherProfile', loginData.user.other_profile);
      setItem('userName', loginData.user.unique_user_name);
      setItem('primaryLanguageId', loginData.user.primary_language_id);
    }
  }, [loginData]);

  return (
    <div className="verify-phone-wrapper">
      {inviImg && inviName && inviDate ? (
        <div className="nominated-by">
          <div className="nominated-by-img">
            <img src={inviImg} alt="" />
          </div>
          <div className="nominated-by-content">
            <h3>
              {labels.nominatedBy[selected_lang]} {inviName}
            </h3>
            <span className="nomination-date">{inviDate}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Form onSubmit={submitForm}>
        <Form.Label>{labels.enterCode[selected_lang]}</Form.Label>
        <div className="otp-wrapper">
          <Form.Group className="form-group">
            <Form.Control
              autoFocus
              type="tel"
              required
              id="num-1"
              maxLength={1}
              value={num1}
              onChange={e => updateNumber(e, 'one')}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Control
              type="tel"
              required
              id="num-2"
              maxLength={1}
              value={num2}
              onChange={e => updateNumber(e, 'two')}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Control
              id="num-3"
              type="tel"
              required
              maxLength={1}
              value={num3}
              onChange={e => updateNumber(e, 'three')}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Control
              type="tel"
              required
              id="num-4"
              maxLength={1}
              value={num4}
              onChange={e => updateNumber(e, 'four')}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Control
              type="tel"
              id="num-5"
              maxLength={1}
              value={num5}
              onChange={e => updateNumber(e, 'five')}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Control
              type="tel"
              id="num-6"
              maxLength={1}
              value={num6}
              onChange={e => updateNumber(e, 'six')}
            />
          </Form.Group>
        </div>
        {errors.otp && <span className="info-text error">{errors.otp}</span>}

        <Form.Group className="form-group mb-0 mt-2">
          <div className="d-flex align-items-center justify-content-between">
            <button
              className="btn btn-small btn-green mt-0"
              onClick={submitForm}>
              {!loading && 'Verify Now'}
              {loading && (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              )}
            </button>

            <span className="resend-timing-seonds">
              {seconds > 0 &&
                `Code can be resend in 00:${formattedSeconds} seconds`}
            </span>
          </div>

          {/* <span className="update-number" onClick={() => updatePhone()}>
            {labels.didntReceiveCode[selected_lang]}
          </span> */}
          {seconds <= 0 && (
            <span
              className="update-number"
              onClick={() => {
                requestForOtp({number: getItem('loginNum')});
                setSeconds(60);
                handleResetOtp()
              }}>
              {labels.didntReceiveCode[selected_lang]}
            </span>
          )}
        </Form.Group>
      </Form>
    </div>
  );
};

export default VerifyPhone;
