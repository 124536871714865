import React, {useState, useEffect} from 'react';

import {db} from '../../Firebase/firebase';
import {ref, onValue} from 'firebase/database';
import commonApi from '../../Services/CommonService';

const SessionPopup = props => {
  const userId = localStorage.getItem('id');
  const [newSessionCount, setNewSessionCount] = useState(0);

  useEffect(() => {
    newSessionPopup();
    // dispatch(getNewSessions('reset'))

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {

  // alert('')
  //     setTimeout(() => {
  //       setTrigerRerender(false)
  //     }, 1000);
  //   }, [trigerRerender]);

  const newSessionPopup = () => {
    const databasePopup = ref(db, 'new_session/');

    onValue(databasePopup, async snapshot => {
      const newSessionsCount = JSON.parse(
        localStorage.getItem('newSessionsCount'),
      );

      if (snapshot.val()) {
        const triggerData = snapshot.val();

        if (newSessionsCount.checked_at !== triggerData.time) {
          const body = {session_id: triggerData.session_id};
          const response = await commonApi.check_this_session_show(body);

          if (response.show === 'yes' && userId !== response.creator_id) {
            const newCount = newSessionsCount.count + 1;

            if (newSessionsCount.count >= 1) {
              let counting = newCount - 1;

              setNewSessionCount(counting);
              props.setPopup(true);
              // setTrigerRerender(true)
            }

            const data = {count: newCount, checked_at: triggerData.time};

            dispatchNewSessionsCount(data);
          }
        }
      }
    });
  };
  const dispatchNewSessionsCount = data => {
    // dispatch(getNewSessions(data))
    localStorage.setItem('newSessionsCount', JSON.stringify(data));
  };

  return (
    <>
      {props.popup && (
        <div className="d-flex align-items-center justify-content-center w-100">
          <button
            className="newSessionEventCallBtn"
            onClick={() => {
              props.getApiSessionList('happening', '', false, 1);
              props.setShowSpinner(true);
              props.setPopup(false);
              const data = {count: 1, checked_at: 0};
              localStorage.setItem('newSessionsCount', JSON.stringify(data));
            }}>
            <span> {newSessionCount} Meetup Added</span>
          </button>
        </div>
      )}
    </>
  );
};

export default SessionPopup;
