export const callChatApi = () => {
  return {
    type: 'CHAT/API',
  };
};

export const updatePersonalChat = () => {
  return {
    type: 'CHAT/PERSONAL',
  };
};
