import React from 'react';
import {Button,  Form} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import {useEffect, useState} from 'react';
import Moment from 'moment';
import { Pannels} from '../../Components';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';


const ProjectManagement = props => {

  const [startDate, setStartDate] = useState(new Date());
  const [showComponent, setShowComponent] = useState('');
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [chatSearchQry, setChatSearchQry] = useState('');







  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  let divStyle = {
    color: '#1A1818',
  };
  



  return (
    <>
      <Sidebar
        location={props.location}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
        showComponent={showComponent}
      />
      {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

      <div className="page-content-wrapper">
        <Pannels
          isFloated={isFloated}
          toggleFloatedPanel={toggleFloatedPanel}
          showHiddenPanel={showHiddenPanel}
          updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
          primary={
            <>
              {/* {showComponent === 'PendingPayment' && (
                    <SlidingPanel hidePanel={() => setShowComponent('')}>
                      <PendingPayment
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        pending={pending}
                        getPaymentDetail={getPaymentDetail}
                        startDate={firstDate}
                        endDate={lastDate}
                        isCheck={isCheck}
                        subTabType={subTabType}
                        onClose={() => {
                          setShowComponent('');
                        }}
                      />
                    </SlidingPanel>
                  )} */}

              {/* {showComponent === 'AddCard' && (
                    <SlidingPanel hidePanel={() => setShowComponent('')}>
                      <AddCard
                        onClose={() => {
                          // setShowComponent('');
                        }}
                      />
                    </SlidingPanel>
                  )} */}
              {/* 
                  {showComponent === 'DirectDeposite' && (
                    <SlidingPanel hidePanel={() => setShowComponent('')}>
                      <DirectDeposite
                        onClose={() => {
                          // setShowComponent('');
                        }}
                      />
                    </SlidingPanel>
                  )} */}
                  
              <div className="project_management">
                <div className="messages-header mb-2">
                  {/* <div className="event-tabs dark-tabs"> */}
                  <div className="event-tabs tabs-style-2">
                    {' '}
                    {/* Merging */}
                    <div className="appointment-date-wrapper ">
                      <div className="date-wrapper">
                        <span
                          onClick={() => {
                            setStartDate(
                              new Date(Moment(startDate).add(-1, 'days')),
                            );
                          }}
                          className="navs fas fa-chevron-left"
                          style={divStyle}
                        />
                        <div className="selected-date">
                          <span className=" as-link" onClick={()=>{}}>
                            {Moment(startDate)
                              .format('dddd, MMMM D, YYYY')
                              .toString()}
                          </span>{' '}
                        </div>

                        <span
                          className="navs fas fa-chevron-right"
                          onClick={() => {
                            setStartDate(
                              new Date(Moment(startDate).add(1, 'days')),
                            );
                          }}
                          style={divStyle}
                        />
                      </div>
                     
                    </div>
                  </div>

                  <div className="search-tabs-icons">
                    <span
                      onClick={() => {
                        setThreeDots(!threeDots);
                      }}
                      className={
                        threeDots ? 'icon active threeDots' : 'icon threeDots'
                      }>
                      <i className="fas fa-ellipsis-vertical" />
                    </span>
                  </div>
                </div>
                {threeDots && (
                  <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                    <div className="search-form d-flex">
                      <Form.Group className="search-input">
                        <Form.Control
                          type="text"
                          placeholder="Who are you looking for?"
                          value={chatSearchQry}
                          onChange={e => {
                            setChatSearchQry(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Button>
                        <i
                          className="icon-filter-icon"
                          // onClick={() => setSearchFilterModal(true)}
                        />
                      </Button>
                    </div>
                  </div>
                )}

                <div className="time mt-5">
                  <div>On Time</div>

                  <div className="milestones mt-5">
                    <div className="">
                      {/* <h1 style={{flex: '1'}}>Milestone Name</h1>
                      <div
                        className="line_bar"
                        style={{flex: '1', backgroundColor: '#52BD41'}}></div> */}

                      {Array.from({length: 3}, (_, index) => (
                        <div key={index} className="milestone_tasks">
                          <h1 style={{flex: index > 0 ? 4 : 1}}>
                            {index === 0 ? 'Milestone Name' : 'Task'}
                          </h1>
                          <div
                            className="line_bar"
                            style={{
                              flex: 1,
                              marginRight: index === 1 ? '20px' : 0,
                              backgroundColor:
                                index > 0 ? '#AEABA0' : '#52BD41',
                            }}></div>
                        </div>
                      ))}

                      {Array.from({length: 3}, (_, index) => (
                        <div key={index} className="milestone_tasks">
                          <h1 style={{flex: index > 0 ? 4 : 1}}>
                            {index === 0 ? 'Milestone Name' : 'Task'}
                          </h1>
                          <div
                            className="line_bar"
                            style={{
                              flex: 1,
                              marginRight: index === 1 ? '20px' : 0,
                              backgroundColor:
                                index > 0 ? '#AEABA0' : '#52BD41',
                            }}></div>
                        </div>
                      ))}

                      {/* {Array.from({length: 3}, (_, index) => (
                        <div key={index} className="milestone_tasks" style={{marginRight: `${40 - 20 * index}px`}}>
                          <h1 style={{flex: 3 - index}}>
                            {index === 0 ? 'Milestone Name' : 'Task'}
                          </h1>
                          <div
                            className="line_bar"
                            style={{
                              flex: 1,
                              // marginRight: `${40 * (index + 1)}px`,
                              backgroundColor: '#AEABA0',
                            }}></div>
                        </div>
                      ))} */}
                    </div>

                    {/* {Array.from({length: 3}, (_, index) => (
                      <div
                        key={`form-dot-${index}`}
                        className={`form-dot ${
                          index + 1 === currentStep ? 'active-page' : ''
                        }`}></div>
                    ))} */}
                  </div>
                </div>
              </div>
              {showComponent === 'WorkSpace' && (
                <CreateWorkSpace
                  onClose={() => {
                    setShowComponent('');
                  }}
                />
              )}
            </>
          }
          secondary={
            <>
            
            </>
          }
        />
      </div>
    </>
  );
};
export default ProjectManagement;
