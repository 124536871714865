import React from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  Bandage,
} from '../../Constants/Images';
import { RoiLogo } from '../../Constants/Images';

const InvoiceTemplates = props => {
  console.log(props.templatesList, 'props.templatesList');

  function getProductTitles(products) {
    return products.map(product => product.title).join(', ');
  }

  function getInvoicesTitles(products) {
    return products.map(product => product.group_name).join(', ');
  }

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };

  return (
    <SlidingPanel
      hidePanel={() => {
        props.onClose();
      }}
      renderData={renderHeader}
      showBlackHeader={true}
      headerPadding={'24px 20px'}
      lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
      firstDivStyle={{
        padding: 0,
        background:
          'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
      }}
      
      >
      <div className="serach-filter-wrapper ">
        <div className='fixed-width-inputs black-head-form-pad-left pt-3'>


      
        <h1 className="mb-2" style={{fontSize: '16px'}}>
          {props.type === 'comparison'
            ? 'Comparison Templates'
            : props.type === 'standard'
            ? 'Standard Templates'
            : 'Invoice Templates'}
        </h1>
        </div>
        <Scrollbars>
          

          {!props.type && (
            <div className="create-ticket-form fix-width-forms fixed-width-inputs black-head-form-pad-left mb-1">
              {Object.entries(props.templatesList).length > 0 ? (
                props.templatesList.map((item, index) => (
                  <div className="payment-card-main">
                    <div
                      className="as-link"
                      onClick={() => {
                        props.setTemplateItem(item);
                       props.setShowInvoice('showInvoiceTemplate')
                      }}>
                      <div className="payment-content-card">
                        <div className="task-content">
                          <h1
                            style={{
                              color: '#000',
                              fontFamily: 'Inter',
                              fontSize: '15px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                            }}>
                            {item.invoice_title ? item.invoice_title : ''}
                          </h1>

                          <span
                            className="project-title"
                            style={{
                              color: '#707070',
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: 'normal',
                            }}>
                            {getProductTitles(item.catalog_details)}
                          </span>
                        </div>
                      </div>

                      <div className="payment-service-icons">
                        <div className="roi-service-icons">
                          <div className="service-icon">
                            <img src={WhitePenIcon} alt="" />
                          </div>

                          <div className="service-icon-time">
                            <img className="" src={timeIcon} alt="" />
                            <span>5D</span>
                          </div>

                          <div className="service-icon w-25">
                            <img src={FireIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={MachineIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={ArrowIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={Bandage} alt="" />
                          </div>

                          <div className="service-icon w-28">
                            <img src={heartIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={bagIcon} alt="" />
                          </div>
                          {/* <div className="w-29">
                            <i
                              className="fa-solid fa-ellipsis"
                              style={{
                                color: '#0a0a0a',
                                paddingTop: '15px',
                              }}></i>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="payment-card-bottom">
                      <div
                        className="payment-card-icons"
                        style={{
                          color: '#262626',

                          fontFamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: 'normal',
                          paddingTop: '11px',
                        }}>
                        {moment(item?.due_date).format('MM/DD/YY').toString()}
                      </div>

                      <div className="pay-btn">
                        <span
                          style={{
                            color: '#262626',
                            fontFamily: 'Inter',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                          }}>
                          {' '}
                          {item.total ? '$' + item.total : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{height: '30vh'}}>
                    <span className="">No Templates found</span>
                  </div>
                </>
              )}
            </div>
          )}

          {(props.type === 'comparison' || props.type === 'standard') && (
            <div className="create-ticket-form fix-width-forms fixed-width-inputs black-head-form-pad-left mb-1 ">
              {Object.entries(props.templatesList).length > 0 ? (
                props.templatesList.map((item, index) => (
                  <div className="payment-card-main" key={index + 'estimate'}>
                    <div
                      className="as-link"
                      onClick={() => {
                        props.handleItem(item);
                        // props.onClose();
                      }}>
                      <div className="payment-content-card">
                        <div className="task-content">
                          <h1
                            style={{
                              color: '#000',
                              fontFamily: 'Inter',
                              fontSize: '15px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                            }}>
                            {item.title ? item.title : ''}
                          </h1>

                          <span
                            className="project-title"
                            style={{
                              color: '#707070',
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: 'normal',
                            }}>
                            {getInvoicesTitles(item.invoices)}
                          </span>
                        </div>
                      </div>

                      <div className="payment-service-icons">
                        <div className="roi-service-icons">
                          <div className="service-icon">
                            <img src={WhitePenIcon} alt="" />
                          </div>

                          <div className="service-icon-time">
                            <img className="" src={timeIcon} alt="" />
                            <span>5D</span>
                          </div>

                          <div className="service-icon w-25">
                            <img src={FireIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={MachineIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={ArrowIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={Bandage} alt="" />
                          </div>

                          <div className="service-icon w-28">
                            <img src={heartIcon} alt="" />
                          </div>

                          <div className="service-icon w-29">
                            <img src={bagIcon} alt="" />
                          </div>
                          {/* <div className="w-29">
                            <i
                              className="fa-solid fa-ellipsis"
                              style={{
                                color: '#0a0a0a',
                                paddingTop: '15px',
                              }}></i>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="payment-card-bottom">
                      <div
                        className="payment-card-icons"
                        style={{
                          color: '#262626',

                          fontFamily: 'Inter',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: 'normal',
                          paddingTop: '11px',
                        }}>
                        Due {moment(item.due_date).format('MM/DD/YYYY')}{' '}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{height: '30vh'}}>
                    <span className="">No Templates found</span>
                  </div>
                </>
              )}
            </div>
          )}
        </Scrollbars>
      </div>
    </SlidingPanel>
  );
};

export default InvoiceTemplates;
