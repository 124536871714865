import axios from 'axios';
import {BACKEND_URL} from '../Utils/env';

const apiHit = (url, data, type) => {
  return axios({
    method: type,
    url: url,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
    .then(res => res.data)
    .catch(err => console.log('Error', err));
};
const API = {
  session_data: data =>
    apiHit(`${BACKEND_URL}session_data_dolby`, data, 'POST'),
  allmicRequests: data =>
    apiHit(`${BACKEND_URL}mic_request_listing_dolby`, data, 'POST'),
  update_mic_status: data =>
    apiHit(`${BACKEND_URL}update_mic_status_dolby`, data, 'POST'),
  switch_creator: data =>
    apiHit(`${BACKEND_URL}switch_creator_dolby`, data, 'POST'),
  manage_user: data => apiHit(`${BACKEND_URL}manage_user_dolby`, data, 'POST'),
  sessions_list: data =>
    apiHit(`${BACKEND_URL}get_meetup`, data, 'POST'),
  create_session: data =>
    apiHit(`${BACKEND_URL}create_session_dolby`, data, 'POST'),
  all_users: data => apiHit(`${BACKEND_URL}all_users_dolby`, data, 'POST'),
  users_not_in_conference: data =>
    apiHit(`${BACKEND_URL}users_not_in_conference_dolby`, data, 'POST'),
  add_user_in_conference: data =>
    apiHit(`${BACKEND_URL}add_user_in_conference_dolby`, data, 'POST'),
  is_session_started: data =>
    apiHit(`${BACKEND_URL}is_session_started_dolby`, data, 'POST'),
  is_session_joined: data =>
    apiHit(`${BACKEND_URL}is_session_joined`, data, 'POST'),
  add_request: data =>
    apiHit(`${BACKEND_URL}manage_mic_request_dolby`, data, 'POST'),
  leave: data => apiHit(`${BACKEND_URL}user_left_session_dolby`, data, 'POST'),
  ban: data => apiHit(`${BACKEND_URL}ban_user_dolby`, data, 'POST'),
  create_session_group_chat_dolby: data =>
    apiHit(`${BACKEND_URL}create_session_group_chat`, data, 'POST'),
  update_session_group_chat_dolby: data =>
    apiHit(`${BACKEND_URL}update_session_group_chat`, data, 'POST'),
  manage_favourite_dolby: data =>
    apiHit(`${BACKEND_URL}manage_favourite_dolby`, data, 'POST'),
  check_favourite_dolby: data =>
    apiHit(`${BACKEND_URL}check_favourite_dolby`, data, 'POST'),
  save_conference_id: data =>
    apiHit(`${BACKEND_URL}save_conference_id`, data, 'POST'),
  haha_user: data =>
    apiHit(`${BACKEND_URL}api/Circles/haha_user`, data, 'POST'),
  get_session_patterns: data =>
    apiHit(`${BACKEND_URL}get_session_patterns`, data, 'GET'),
    user_left_session_by_user_id: data =>
    apiHit(`${BACKEND_URL}user_left_session_by_user_id`, data, 'POST'),
    get_company_alias: data =>
    apiHit(`${BACKEND_URL}get_company_alias`,data, 'GET'),
    
};
export default API;
