"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * To share a file during a conference, [FilePresentationService](/developers/interactivity-apis/client-sdk/reference-javascript/filepresentationservice)
 * converts the user-provided files into multiple pages (images). The FileConversionProgress model represents the process of converting files into images.
 * The model includes the [current step](#currentstep) in the process of converting a file, [ID of the converted file](#fileid), [conversion status](#progress), [number of steps required to convert a file](#stepcount), and [ID of the user who started the file presentation](#userid).
 */
class FileConversionProgress {
    /**
     * @ignore
     */
    constructor(userId, fileId, currentStep, stepCount, progress) {
        this.userId = userId;
        this.fileId = fileId;
        this.currentStep = currentStep;
        this.stepCount = stepCount;
        this.progress = progress;
    }
}
exports.default = FileConversionProgress;
