import React, {useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';

import CustomModal from '../CustomModal';
import {allowedFileTypes} from '../../Utils/commonUtils';

const UploadFileFilter = props => {
  //   const [show, setShow] = useState(props.show);
  const closeModal = () => {
    // setShow(false);
    props.onClose();
  };

  const handleSelectAll = e => {
    props.setIsCheckAll(!props.isCheckAll);
    props.setIsCheck(allowedFileTypes.map(item => item.id));
    if (props.isCheckAll) {
      props.setIsCheck([]);
    }
  };

  const handleClick = e => {
    const {id, checked} = e.target;
    props.setIsCheck([...props.isCheck, id]);
    if (!checked) {
      props.setIsCheck(props.isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    if (allowedFileTypes.length > 0) {
      if (props.isCheck.length === allowedFileTypes.length) {
        props.setIsCheckAll(true);
      } else {
        props.setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCheck]);

  return (
    <>
      <CustomModal
        className={'sessioneventFilter'}
        style={{height: '100%'}}
        onClose={() => closeModal()}>
        <div className="serach-filter-wrapper">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="headings m-0">
              <h2 className="m-0">File Types</h2>
            </div>

            <div className="sidebar-heading-wrapper mt-0 text-right"></div>
          </div>

          {/* <Scrollbars> */}
          <div className="filter-wrapper pb-1">
            <div className="filter-category-list">
              <ul>
                <li className={props.isCheckAll ? 'active' : ''}>
                  <Form.Label>
                    All{' '}
                    <input
                      type="checkbox"
                      id="ALL"
                      name="ALL"
                      value="ALL"
                      onChange={handleSelectAll}
                      checked={props.isCheckAll}
                    />{' '}
                    <span />
                  </Form.Label>
                </li>
                {Object.entries(allowedFileTypes).length > 0 &&
                  allowedFileTypes.map((item, index) => (
                    <li
                      key={`sessionC-${index}`}
                      className={
                        props.isCheck.includes(item.id) ? 'active' : ''
                      }>
                      <Form.Label>
                        {item.name}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          onChange={handleClick}
                          checked={props.isCheck.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {/* </Scrollbars> */}
              <div className="filter-btn-wrapper pt-2 text-center bg_transparent bottom-0  border-radius-25">
            
            <Button
              className="btn-dark"
              type="submit"
              onClick={() => {
                props.hitFuctionAfterFilter();
                props.onClose();
              }}>
              Filter Categories
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default UploadFileFilter;
