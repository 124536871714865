import React from 'react';

const PersnalSvg = ({ fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.113" height="21.493" viewBox="0 0 24.113 21.493">
    <g id="Group_4725" data-name="Group 4725" transform="translate(-930.323 -258.77)">
      <path id="Path_2901" data-name="Path 2901" d="M1159.417,261.85l-10.8,10.8a8.194,8.194,0,0,1-1.714-9.162.211.211,0,0,0-.052-.286c-.251-.241-.5-.484-.734-.743a2.2,2.2,0,0,1,3.107-3.113c.259.232.5.482.742.735a.219.219,0,0,0,.3.051,8.085,8.085,0,0,1,9.127,1.692A.125.125,0,0,1,1159.417,261.85Z" transform="translate(-204.981)" fill={fill}/>
      <path id="Path_2902" data-name="Path 2902" d="M1019.8,671.937a7.142,7.142,0,0,1,.006-.871,1.2,1.2,0,0,1,1.189-1.051q4.059-.009,8.118,0a1.215,1.215,0,0,1,1.205,1.192c.009.237,0,.474,0,.729Z" transform="translate(-85.207 -391.674)" fill={fill}/>
      <path id="Path_2903" data-name="Path 2903" d="M932.86,426.659a2.576,2.576,0,1,1,2.605-2.5A2.568,2.568,0,0,1,932.86,426.659Zm.031-1.447a1.13,1.13,0,1,0-1.131-1.132A1.135,1.135,0,0,0,932.891,425.212Z" transform="translate(0 -154.994)" fill={fill}/>
      <path id="Path_2904" data-name="Path 2904" d="M1023.182,318.919a3.192,3.192,0,0,0,1.091,1.646l-5.422,4.592a3.249,3.249,0,0,0-1.3-1.471Z" transform="translate(-83.08 -57.287)" fill={fill}/>
      <path id="Path_2905" data-name="Path 2905" d="M995.937,531.631c-.6,0-1.149-.032-1.689.01a.735.735,0,0,1-.812-.479c-.863-1.644-1.765-3.268-2.652-4.9-.037-.067-.069-.137-.1-.2a8.35,8.35,0,0,0,.932-.286,8.763,8.763,0,0,0,.868-.494Z" transform="translate(-57.488 -253.831)" fill={fill}/>
      <path id="Path_2906" data-name="Path 2906" d="M1300.741,410.552a2.474,2.474,0,0,1-3.4,3.4Z" transform="translate(-349.553 -144.56)" fill={fill}/>
      <path id="Path_2907" data-name="Path 2907" d="M1150.144,639.376h-2.939c0-.213-.012-.409,0-.6a.339.339,0,0,1,.358-.339c.742-.007,1.484-.009,2.226,0a.335.335,0,0,1,.351.367C1150.146,638.989,1150.144,639.177,1150.144,639.376Z" transform="translate(-206.559 -361.593)" fill={fill}/>
    </g>
  </svg>
  );
};

export default PersnalSvg;