import React, { useState} from 'react';
// import commonApi from '../Services/CommonService';
// import {successMessage} from '../Utils/ToastMessages';
import moment from 'moment/moment';

const EventBriteItems = props => {
  const {item} = props;
  // const [favourite, setFavourite] = useState(item.favourite);
  // var totalMem = item.total_members + 1;
  // var totalMode = item.total_moderators + 1;
  const [selectedItem, setSelectedItem] = useState([]);



 

  // const manageFavourite = () => {
  //   var payloadManageFavourite = {
  //     conference_id: item.id,
  //   };
  //   commonApi
  //     .manage_favourite(payloadManageFavourite)
  //     .then(res => {
  //       if (res.status === 200) {
  //         if (res.success) {
  //           setFavourite(true);
  //           item.favourite = true;
  //         } else {
  //           setFavourite(false);
  //           item.favourite = false;
  //         }
  //         successMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // useEffect(() => {
  //   item.favourite = favourite;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [favourite]);

  // const _renderUserImg = (person) => {
  //     return (
  //         <div className="img-item">
  //             {person.owner && (
  //                 <span className="owner-icon">
  //                     <i className="fas fa-crown" />
  //                 </span>
  //             )}
  //             {!person.owner && person.isOnline && <span className="online-icon"></span>}
  //             {person.speaker && (
  //                 <span className="speaker-icon">
  //                     <i className="fas fa-microphone" />
  //                 </span>
  //             )}
  //             <img src={person.image} alt="" />
  //         </div>
  //     );
  // };



  const selectItem = item => {
    if (!selectedItem.includes(item.id)) {
      setSelectedItem(previous => [...previous, item.id]);
      props.setEvenBriteEvents(previous => [...previous, item]);
    } else {
      setSelectedItem(previous =>
        previous.filter(itemId => itemId !== item.id),
      );
      props.setEvenBriteEvents(previous =>
        previous.filter(miniItem => miniItem.id !== item.id),
      );
    }
  };

  return (
    <div
      className={`events-item ${
        selectedItem.includes(item.id) ? 'selercted-user' : ''
      }`}
      onClick={() => {
        if (props.type) {
          selectItem(item);
        }
      }}>
     { item.logo && item.logo != null && <div
        className="event-img"
        //  onClick={() => {
        //    props.componentDecesion(item, 'event');
        //  }}
      >
        {/* <Link to={`#`}> */}
        <img
          src={
            item.logo.url
              ? item.logo.url
              : 'https://build-dev.s3.us-east-1.amazonaws.com/no-shop.png'
          }
          alt=""
        />
        {/* </Link> */}
      </div>
}
      <div className="session-heading">
        <div
          className="event-titles as-link"
          onClick={() => {
            props.componentDecesion(item, 'event');
          }}>
          <h2>{item.summary}</h2>
          {item.start.local === '0000-00-00 00:00:00' ? (
            <></>
          ) : (
            <div className="event-date">
              {moment(item.start.local).format('MMMM Do YYYY, h:mmA')}
            </div>
          )}
          {/* <div className="event-users-cate">
            <span className="users-text usercount-type as-link">
              <img src={UserGroupMini} height={16} width={16} alt="" />
              <span className="userCount">{`${totalMem}/${totalMode}`}</span>
              <span className="category-text as-link">
                {item.category_name}
              </span>
            </span>
          </div> */}
        </div>
        {!props.type && (
          <div className="event-share">
            <i
              className="fas fa-share as-link"
              onClick={() => {
                props.componentDecesion(item, 'Refer');
              }}
            />
          </div>
        )}
      </div>
      {/* <div
        className="persons-img-list as-link"
        onClick={() => {
          props.componentDecesion(item, 'event');
        }}>
        {_renderCreatorImg(item)}

        {item.moderator_ids &&
          Object.entries(item.moderators_list).length > 0 &&
          item.moderators_list
            .slice(0, 2)
            .map((person, index) => _renderAudiImg(person))}

        {item.audience_ids &&
          Object.entries(item.audience_list).length > 0 &&
          item.audience_list
            .slice(0, 3)
            .map((person, index) => _renderAudiImg(person))}

        
      </div> */}
      <div className="events-place-like">
        {item.venues && typeof item.venues == 'object' &&
          item.venues.address &&
          item.venues.address.localized_area_display && (
            <span
              className="address as-link"
              // onClick={() => {
              //   props.componentDecesion(item, 'event');
              // }}
            >
              <i className="fas fa-location-dot" />{' '}
              {item.venues.address.localized_area_display}
            </span>
          )}
        {/* <span
          className="event-amount as-link"
          onClick={() => {
            props.componentDecesion(item, 'event');
          }}>
          {item.max_cost === item.min_cost &&
            item.max_cost !== 0 &&
            '$' + item.max_cost}
          {item.max_cost !== item.min_cost &&
            item.max_cost !== 0 &&
            '$' + item.min_cost + '-' + '$' + item.max_cost}
         
        </span> */}
        {/* <span
          className="event-heart as-link"
          onClick={() => {
            if (props.filterFavroiuteList) {
              props.filterFavroiuteList(item.id);
            }
            manageFavourite();
          }}>
          {item.favourite && !props.type && <i className="fas fa-heart" />}
          {!item.favourite && !props.type && <i className="far fa-heart" />}
        </span> */}
      </div>
      {/* {props.ElementDesign()} */}
    </div>
  );
};

export default EventBriteItems;
