import React, {useState, } from 'react';
import {Button, Col,  Form, Row} from 'react-bootstrap';
import SlidingPanel from '../SlidingPanel';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import Scrollbars from 'react-custom-scrollbars';

const CreateNews = props => {
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [validated, setValidated] = useState(false);
  const [newsImage, setNewsImage] = useState([]);
  const [ticketImg, setTicketImg] = useState('');
  const [showModel, setShowModel] = useState('');
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();

      setValidated(true);
    } else if (newsImage.length === 0) {
      event.preventDefault();
      errorMessage('Please select image');
    } else {
      event.preventDefault();
      addNews();
      props.onClose();

      setValidated(false);
    }
  };

  const addNews = () => {
    let Paylaod = {
      url: url,
      description: description,
      image: newsImage.toLocaleString(),
    };
    
    commonApi
      .add_news(Paylaod)
      .then(res => {
        if (res.success === true) {
          successMessage(res.message)
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];
    // let fileExtension = file.name.split('.').pop().toLowerCase();

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      const filePath = e.target.files[0];
      const objectUrl = URL.createObjectURL(filePath);
      let reader = new FileReader();
      reader.readAsDataURL(filePath);
      reader.onload = e => {
        // 

        uploadMedia(e.target.result);
      };

      setTicketImg(objectUrl);
      
      // 
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
   
  };

  const uploadMedia = img => {
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    // setshowLoader(true);
    commonApi
      .upload_news_picture(bodyFormData)
      .then(res => {
        // setshowLoader(false);

        if (res.status === 200) {
          setNewsImage([res.image]);
          // setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
        <div className="create-ticket-wrapper">
          {/* bs */}
          <div>
            <span className="text-center">Create News</span>
          </div>
          <>
            {/* <div className="create-ticket-tabs">
              <CustomTabs
                darkmode
                active={tabType}
                onSelect={selected => setTabType(selected)}
                tabs={[
                  labels.sessions_type_general[selected_lang],
                  labels.sessions_type_invites[selected_lang],
                ]}
              />
            </div> */}

            <div className="create-ticket-form">
              <Form noValidate validated={validated} onSubmit={handle}>
                <div className="fieldset">
                  <Form.Label controlid="validationCustom01">Url</Form.Label>
                  <Form.Control
                    className="as-link"
                    value={url}
                    pattern="https://.*"
                    required
                    placeholder="Url"
                    onChange={e => {
                      setUrl(e.target.value);
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    {url ? 'Url is invalid.' : 'Url is required.'}
                  </Form.Control.Feedback>
                </div>
                <div className="fieldset">
                  <Form.Label controlid="validationCustom01">
                    Description
                  </Form.Label>
                  <Form.Control
                    name="title"
                    value={description}
                    as="textarea"
                    rows={6}
                    required
                    placeholder="Enter your description"
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Description is required.
                  </Form.Control.Feedback>
                </div>

                <Col sm={2}>
                  <div className="ticket-img-wrapper mb-3">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={e => {
                        changeTicketImg(e);
                      }}
                    />
                    {newsImage.length > 0 && <img src={ticketImg} alt="" />}
                    <i className="icon-camera" />
                  </div>
                </Col>
                {newsImage.length > 0 > 0 && (
                  <span
                    className="as-link info-text mb-3 mt-0"
                    onClick={() => {
                      setShowModel('image');
                    }}>
                    Preview Images
                  </span>
                )}

                <div className="filter-btn-wrapper">
                  <div className="invite-btns text-left">
                    <Button type="submit">Submit</Button>
                  </div>
                </div>
              </Form>
            </div>
          </>
        </div>
      </SlidingPanel>
      {showModel === 'image' && (
        <SlidingPanel hidePanel={() => setShowModel('')}>
          <div className="serach-filter-wrapper">
            <div className="permission-modal-content text-left">
              <h3>Images</h3>
            </div>

            <Scrollbars>
              <div className="snapshot-preview-wrapper fix-width-forms">
                <Row>
                  {newsImage.length > 0 ? (
                    newsImage.map((item, index) => {
                      return (
                        <Col md={4} sm={4} key={`image-${index}`}>
                          <div className="snapshot-item">
                            <span
                              className="remove-icon"
                              onClick={() => {
                                // 
                                setNewsImage([]);
                                setTicketImg('');
                              }}>
                              <i className="fas fa-times" />
                            </span>

                            <img src={item} alt="" />
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <p className="text-center">No Images Found</p>
                  )}
                </Row>
              </div>
            </Scrollbars>
          </div>
        </SlidingPanel>
      )}
    </>
  );
};
export default CreateNews;
