import React from 'react'

const CopySvg = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.756 17.8689C16.6031 18.7672 16.2062 19.5305 15.5134 20.1251C14.8271 20.7143 14.0258 20.9978 13.1215 20.9973C9.97324 20.9963 6.82545 21.0062 3.67717 20.9934C1.81187 20.986 0.378004 19.7637 0.0456715 17.931C0.00671855 17.7151 0.00326703 17.4902 0.00326703 17.2694C0.000801651 14.1543 -0.00264987 11.0392 0.00326703 7.92408C0.00671855 6.10177 1.13635 4.66157 2.87494 4.28537C3.29405 4.19465 3.73535 4.20697 4.20476 4.16901C4.20476 4.02405 4.20476 3.85592 4.20476 3.6873C4.20574 1.83244 5.44632 0.356745 7.27859 0.0421787C7.49505 0.00519997 7.7194 0.00273472 7.94029 0.00273472C11.0472 0.000269471 14.1545 -0.00170273 17.2614 0.00224167C19.0581 0.00421387 20.4846 1.09583 20.8924 2.79192C20.9683 3.10649 20.9935 3.44028 20.994 3.7652C21.0009 7.20817 21.0018 10.6511 20.9969 14.0941C20.9945 16.017 19.8027 17.4646 17.9359 17.7989C17.5602 17.8659 17.1697 17.8472 16.756 17.8689ZM6.32942 4.20402C6.43247 4.20402 6.52862 4.20402 6.62477 4.20402C8.78888 4.20402 10.953 4.20352 13.1166 4.20402C15.2112 4.205 16.7944 5.79262 16.7954 7.89203C16.7964 10.4086 16.7954 12.9246 16.7954 15.4411C16.7954 15.5353 16.7954 15.6295 16.7954 15.7419C16.9542 15.7419 17.076 15.7419 17.1978 15.7419C18.2776 15.7384 18.888 15.131 18.8905 14.0527C18.892 13.4788 18.8905 12.9054 18.8905 12.3315C18.8905 9.47078 18.893 6.6101 18.889 3.74942C18.8875 2.7579 18.2865 2.11545 17.322 2.1125C14.1742 2.10214 11.0269 2.10855 7.87915 2.10855C7.45166 2.10855 7.07741 2.24168 6.7648 2.54244C6.28701 3.00196 6.27272 3.57932 6.32843 4.20352L6.32942 4.20402Z" fill="#9B9787"/>
</svg>

  )
}

export default CopySvg