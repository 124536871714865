import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import {labels} from '../../Constants/Translations';
import {DEFAULT_IMAGE, selected_lang} from '../../Utils/env';
import {RenderHTMLBlock} from '../../Utils/Helpers';
import ReferOpportunity from '../Referral/ReferOpportunity';
import {successMessage} from '../../Utils/ToastMessages';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SmartChatPage from '../Common/SmartChatPage';
import {chatroomInfoItem, fixName} from '../../Utils/commonUtils';
import ProfileComponent from '../ProfilePage/ProfileComponent';

import {useSelector, useDispatch, connect} from 'react-redux';
import {
  getOpportunityListing,
  getOpportunityListingProfile,
} from '../../Store/Actions/OpportunityAction';
import SlidingPanel from '../SlidingPanel';
import {newFavouriteRecruitment} from '../../Store/Reducers/DeepLinkReducer';
import {manageFavRecruitment} from '../../Store/Actions/DeepLinkAction';
import {RoiLogo} from '../../Constants/Images';
const OpportunityDetail = ({
  item,
  userData,
  onClose,
  withHeadingSpace,
  windowWidth,
  setProfileImgScrollBar,
}) => {
  const [showMore, toggleShowMore] = useState(false);
  const [showMoreSkillset, toggleShowMoreSkillset] = useState(false);
  const [heartClass, setHeartClass] = useState(item.like);
  const [shareButton, setShareButton] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';
  const showMoreClassSkill = showMoreSkillset ? 'show-all' : '';
  const [showLoader, setshowLoader] = useState(false);
  const [respondBtn, setRespondBtn] = useState(false);

  const [showComponent, setShowComponent] = useState('');
  const [profileId, setProfileId] = useState('');

  const dispatch = useDispatch();
  const opportunityData = useSelector(state => state.opportunityListing);
  const opportunityDataProfile = useSelector(
    state => state.opportunityListingProfile,
  );

  const newFavouriteRecruitment = useSelector(
    state => state.newFavouriteRecruitment,
  );

  const handle = () => {
    shareButton ? setShareButton(false) : setShareButton(true);
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const favouriteOpportunity = id => {
    // setshowLoader(true);

    dispatch(manageFavRecruitment(item.id));

    var data = {opportunity_id: id};
    commonApi
      .manage_favourite_opportunity(data)
      .then(res => {
        if (res) {
          //
          setshowLoader(false);
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const collectingUsersOtheroPP = item => {
    const creator = item.hasOwnProperty('creator_details')
      ? 'creator_details'
      : 'creator_detail';
    const moderator = 'team_member_details';
    const audience = 'other_persons_opportunity_chat_room';

    let chatUsers = [
      {
        id: item[creator]['id'],
        first_name: item[creator]['first_name'],
        last_name: item[creator]['last_name'],
        profile_img: item[creator]['profile_img'],
        type: 'creator',
      },
    ];
    if (item[audience] && item[audience].length > 0) {
      item[audience].forEach(item => {
        item.type = 'audience';
        chatUsers.push(item);
      });
    } else {
      const audienceObj = {
        id: localStorage.getItem('id'),
        first_name: localStorage.getItem('firstName'),
        last_name: localStorage.getItem('lastName'),
        profile_img: localStorage.getItem('profilePic')
          ? localStorage.getItem('profilePic')
          : DEFAULT_IMAGE,
        type: 'audience',
      };
      chatUsers.push(audienceObj);
    }
    if (item[moderator] && item[moderator].length > 0) {
      item[moderator].forEach(item => {
        item.type = 'moderator';
        chatUsers.push(item);
      });
    }
    return chatUsers;
  };

  useEffect(() => {
    if (item.id === newFavouriteRecruitment.id) {
      setHeartClass(prev => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFavouriteRecruitment]);

  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  return (
    <>
      {!(shareButton || respondBtn || showComponent) ? (
        <SlidingPanel
          hidePanel={() => onClose()}
          renderData={renderHeader}
          showBlackHeader={true}
          headerPadding={'24px 20px'}
          lastDivStyle={{
            borderRadius: '35px',
            background: '#f7f6f2',
            // marginTop : props.marginTop ? '12px' : ''
          }}
          firstDivStyle={{
            padding: 0,
            background:
              'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
          }}>
          <div className="serach-filter-wrapper">
            {/* <div className="fix-width-forms fixed-width-inputs black-head-form-pad-left">
              <div
                className={`sidebar-heading-wrapper mt-0 hi ${
                  withHeadingSpace || windowWidth <= 1295 ? 'with-panel' : ''
                }`}>
                <div className="heading">
                  <h2>{fixName(userData.first_name, userData.last_name)}</h2>
                </div>
                

                <div className="with-user-img">
                  <div
                    className="user-profile-img no-margin as-link"
                    onClick={() => {
                      setShowComponent('profileComponent');
                      setProfileId(userData.id);
                    }}>
                    <img
                      src={
                        userData.profile_img
                          ? userData.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt=""
                    />
                  </div>
                  <span
                    className="close as-link"
                    onClick={() => {
                      dispatch(getOpportunityListing(opportunityData.payload));
                      onClose();
                    }}>
                    <i className="fas fa-times" />
                  </span>
                </div>
              </div>
            </div> */}
            <Scrollbars className="custom-scroll ">
              <div className="fix-width-forms  fixed-width-inputs black-head-form-pad-left opportunity-respond pt-3">
                {showLoader && <LoaderSpinner />}

                <div className="events-wrapper">
                  <span className="d-block mb-2">
                    <b> {item.opportunity_type_name}</b>
                  </span>
                  <div className="events-headings">
                    <h2>{item.title}</h2>

                    <span className="category-name">{item.business_name}</span>
                  </div>

                  <span class="creator_name">Hello W.</span>

                  <div className="events-icons-wrapper">
                    <div className="profile-img-wrapper recruitment_user_detail d-flex align-items-center">
                      <div className="profile_img position-relative ">
                        <img
                          src={
                            userData && userData.profile_img
                              ? userData.profile_img
                              : DEFAULT_IMAGE
                          }
                          alt=""
                        />
                      </div>

                      <div className="profile-name pt-0">
                        <div
                          className={`profile-name-headings
                
                `}>
                          <div className="profile_name">
                            <h2>
                              {fixName(userData.first_name, userData.last_name)}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    {item.url && (
                      <div className="left-icons">
                        <span
                          className="icon-globe as-link"
                          onClick={() => openInNewTab(item.url)}></span>
                      </div>
                    )}
                    <div className="right-icons">
                      <span
                        onClick={() => {
                          favouriteOpportunity(item.id);
                          setHeartClass(!heartClass);
                        }}
                        className={
                          heartClass
                            ? 'fas fa-heart as-link'
                            : 'far fa-heart as-link'
                        }></span>
                      <span
                        className="icon-share-option as-link"
                        onClick={() => {
                          handle();
                        }}></span>
                    </div>
                  </div>

                  <div className="events-desc-wrapper opportunies-content-wrapper padding_b">
                    <div className="content-wraps">
                      <div className="heading">
                        <span className="d-block mb-2">
                          <b>
                            Pitch
                            <br />
                            {item.amount && '$' + item.amount}
                          </b>
                        </span>

                        <RenderHTMLBlock
                          className={`profile-description  ${showMoreClass}`}
                          container={'p'}
                          content={item.description}
                        />

                        {item.description.length > 250 && (
                          <span
                            className="show-more"
                            onClick={() => toggleShowMore(!showMore)}>
                            {!showMore
                              ? labels.read_more[selected_lang]
                              : labels.read_less[selected_lang]}
                          </span>
                        )}
                      </div>

                      {item.skill_set && (
                        <div className="skillset-desired-bg-wrapper">
                          <span className="d-block mb-2">
                            <b>Skillset Summary</b>
                          </span>
                          <RenderHTMLBlock
                            className={`profile-description ${showMoreClassSkill}`}
                            container={'p'}
                            content={item.skill_set}
                          />

                          {item.skill_set > 250 && (
                            <span
                              className="show-more"
                              onClick={() =>
                                toggleShowMoreSkillset(!showMoreSkillset)
                              }>
                              {!showMoreSkillset
                                ? labels.read_more[selected_lang]
                                : labels.read_less[selected_lang]}
                            </span>
                          )}
                        </div>
                      )}

                      {item.background_detail &&
                        item.background_detail.length > 0 && (
                          <div className="skillset-desired-bg-wrapper">
                            <span className="d-block mb-2">
                              <b>Background</b>
                            </span>
                            {item.background_detail &&
                              Object.entries(item.background_detail).length >
                                0 &&
                              item.background_detail.map((miniItem, index) => (
                                <p key={'mnititem_' + index}>
                                  {miniItem.title}
                                </p>
                              ))}
                          </div>
                        )}

                      <div className="d-flex align-items-center justify-content-between">
                        {item.address && (
                          <span className="event-address mt-0">
                            <i className="icon-map-pin" />
                            {item.address}
                          </span>
                        )}
                        {item.vaccinate === 'yes' && (
                          <span className="injection-icon-wrapper">
                            <i className="icon-injection" />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>

            <div className="event-btn-wrapper m-0 text-center bg_transparent fix-width-forms fixed-width-inputs black-head-form-pad-left bottom-0 pb-2">
              <Button
                disabled={item.status === 'finish'}
                onClick={() => {
                  setRespondBtn(true);
                }}>
                Respond
              </Button>
            </div>
          </div>
        </SlidingPanel>
      ) : shareButton ? (
        <ReferOpportunity
          onClose={() => {
            setShareButton(false);
          }}
          userData={item}
          type={'recruitment'}
        />
      ) : showComponent === 'profileComponent' && profileId ? (
        <ProfileComponent
          type="opportunityDetail"
          userId={profileId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={userData}
          setProfileImgScrollBar={setProfileImgScrollBar}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      ) : (
        <>
          {/* <OpportunityRespond item={item} userData={userData} onClose={() => { setRespondBtn(false); }} /> */}
          <SmartChatPage
            type={
              item.chat_detail && item.chat_detail.length > 0
                ? 'other-opportunity'
                : 'new-opportunity'
            }
            item={[]}
            allmsg={true}
            oppoDetails={item}
            chatDetails={item.chat_detail ? item.chat_detail : ''}
            chatroomInfo={chatroomInfoItem(item, 'opportunity')}
            // allChatUsers={
            //   item.hasOwnProperty('creator_details')
            //     ? [item.creator_details]
            //     : [item.creator_detail]
            // }
            allChatUsers={collectingUsersOtheroPP(item)}
            chatRoomName={
              item.chat_detail && item.chat_detail.length > 0
                ? item.chat_detail.chat_room_name
                : ''
            }
            chatRoomId={
              item.chat_detail && item.chat_detail.length > 0
                ? item.chat_detail.id
                : ''
            }
            onClose={extra => {
              if (extra) {
                dispatch(getOpportunityListing(opportunityData.payload));
                dispatch(
                  getOpportunityListingProfile(opportunityDataProfile.payload),
                );
                onClose();
              }
              setRespondBtn(false);
            }}
            userData={userData}
            paddingBottom={true}
            // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
          />
        </>
      )}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(OpportunityDetail);
