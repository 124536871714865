import React, {useState, useEffect, useRef} from 'react';
import {Button, Form} from 'react-bootstrap';

import API from '../../Services/SessionServices';
// import { vinceProfile } from "../../Constants/Images";
import ProfileBlock from './ProfileBlock';
import ProfileGallery from './ProfileGallery';
import messageService from '../../Services/MessageService';
import commonApi from '../../Services/CommonService';
import SnapshotDetail from '../Snapshot/SnapshotDetail';
import FollowersList from '../SessionDetails/FollowersList';
import OpportunityDetail from '../OpportunitiesList/OpportunityDetail';
import SessionDetail from '../SessionDetails/SessionDetail';
import AppointmentList from '../AppointmentPage/AppointmentList';
import PraiseCoution from '../SessionDetails/PraiseCoution';
import CircleList from './CircleList';
import ReferProfile from '../Referral/ReferProfile';

import ReferOpportunity from '../Referral/ReferOpportunity';

import CommonChatPage from '../Common/CommonChatPage';
import UserAppointments from '../AppointmentPage/UserAppointments';

import Scrollbars from 'react-custom-scrollbars';
import SlidingPanel from '../SlidingPanel';
import {getItem} from '../../Utils/LocalStorage';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {useLocation} from 'react-router-dom';
import SmartChatPage from '../Common/SmartChatPage';
import {
  arrayColumn,
  chatroomInfo,
  list,
  newSingleChatroom,
} from '../../Utils/commonUtils';
import {connect} from 'react-redux';
import ProfileNotes from './ProfileNotes';

import PersonalModal from '../Modals/PersonalModal';
import TransformDetail from '../Transformation/TransformDetail';
import CreateTransform from '../Transformation/CreateTransform';
import {useSelector, useDispatch} from 'react-redux';
import CustomModal from '../CustomModal';
import AddNewSnapShot from '../Messages/AddNewSnapShot';
import {checkPermissions} from '../../Utils/commonUtils';

import CheckoutForm from '../SessionDetails/CheckoutForm';
import {loadStripe} from '@stripe/stripe-js';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {EditPencilYellow} from '../../Constants/Images';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import CreateSession from '../SessionDetails/CreateSession';
import AppointmentCallDetail from '../AppointmentPage/AppointmentCallDetail';
import {successMessage} from '../../Utils/ToastMessages';
import {getUserProfileAppointments} from '../../Store/Actions/AppointmentAction';
import ActionDetail from '../Actions/ActionDetail';
import CreateAction from '../Snapshot/CreateAction';
import CreatePipeLine from '../PipeLine/CreatePipeLine';
import CreateDeal from '../Deals/CreateDeal';
import PipelineDetail from '../PipeLine/PipelineDetail';
import ProfileMap from './ProfileMap';
import CreateInvoice from '../InvoiceEstimate/CreateInvoice';
import EditOpportunities from '../OpportunitiesList/EditOpportunities';
import CreateTag from '../Tags/CreateTag';
import ProfileTags from './ProfileTags';
import InvoiceDeatil from '../InvoiceEstimate/InvoiceDeatil';
function ProfileComponent(props) {
  const [showComponent, setShowComponent] = useState({
    followers: false,
    following: false,
    snapshot: false,
    opportunity: false,
    session: false,
    appointment: false,
    mineAppointment: false,
    praise: false,
    circle: false,
    share: false,
    referOpportunity: false,
    chat: false,
    profilenotes: false,
    personal: false,
    Profile_view: false,
    transformation: false,
    createTransform: false,
    snapshot_filter: false,
    addNewSnapshot: false,
    stripe_modal: false,
    follow_up: false,
    other_user_chat: false,
    appointment_detail: false,
    follow_up_detail: false,
    create_task: false,
    pipeline: false,
    create_deal: false,
    pipeline_detail: false,
    current_map: false,
    home_map: false,
    payment_filter: false,
    create_invoice: false,
    task_filter: false,
    appointment_filter: false,
    create_session: false,
    session_filter: false,
    create_recruitment: false,
    recruitment_filter: false,
    create_tag: false,
    view_tag: false,
    invoice_detail: false,
  });
  const [profileId, setProfileId] = useState({});
  // const [profileType, setProfileType] = useState('');
  const [snapshotId, setSnapshotId] = useState({});
  const [opportunityData, setOpportunityItemData] = useState({});
  const [sessionData, setSessionData] = useState({});
  // const [show, setShow] = useState(props.show);
  const [average, setAverage] = useState('');

  const [appointmentData, setAppointmentData] = useState(null);
  const [changedStatusAppointment, setChangedStatusAppointment] = useState({});
  //
  //
  var dataOfPersons = props.userData;
  const [userInfo, setUserInfo] = useState([]);

  const [otherUserInfo, setOtherUserInfo] = useState([]);
  const [conferenceInfo, setconferenceInfo] = useState([]);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [UserisModerator, setUserisModerator] = useState(false);
  const [audio, setaudio] = useState(true);

  const [userSnapshots, setSnapshotData] = useState([]);
  const [showSnapshots, setShowSnapshot] = useState(false);

  const [userUpcomming, setUpcommingData] = useState([]);
  const [showUpcomming, setShowUpcomming] = useState(false);

  const [userOpportunity, setOpportunityData] = useState([]);
  const [showOpportunity, setShowOpportunity] = useState(false);
  // const [showLoader, setshowLoader] = useState(false);
  const [checkAppoinmentData, setCheckAppoinmentData] = useState([]);

  const [chatType, setChatType] = useState('');
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [userApStatus, setUserApStatus] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [dataofTransFormation, setDataofTransFormation] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [filterCat, setFilterCat] = useState([]);
  const [itemOfActionData, setItemOfActionData] = useState([]);
  const [invoiceItem, setInvoiceItem] = useState({});
  const locationWeb = useLocation();
  const menuPermesions = useSelector(state => state.menuPermesionList);

  const dispatch = useDispatch();
  const [checkFilterOption, setCheckFilterOption] = useState(['1']);
  const [filterOptionValue, setFilterOptionValue] = useState([]);
  const ActionCategories = [
    {id: '1', title: 'All'},
    {id: '2', title: 'Me'},
    {id: '3', title: 'Watching'},
  ];

  const [selectedFilterValue, setSelectedFilterValue] = useState('to_me');
  const [value_filter, setValue_filter] = useState('to_me');

  const BusinnesProfilePermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Business Profile');

  const snapshotCategories = useSelector(
    state => state.accomplishmentCategories,
  );

  const Appointment_Status_List = [
    {id: '1', title: 'Running Late'},
    {id: '2', title: 'On my way'},
    {id: '3', title: "I'm outside"},
    {id: '4', title: "I'm Done"},
  ];

  const [payment_filter, setPayment_filter] = useState('to_me');
  const [selectedPaymentFilter, setSelectedPaymentFilter] = useState('to_me');
  const [sessionFilterValue, setSessionFilterValue] = useState('all');
  const [session_filter, setSession_filter] = useState('all');

  const filterListArray = [
    {id: '1', value: 'from_me', title: 'From me'},
    {id: '2', value: 'to_me', title: 'To me'},
  ];

  const sessionFilterArray = [
    {id: '1', value: 'all', title: 'All'},
    {id: '2', value: 'mine', title: 'Mine'},
    {id: '3', value: 'others', title: 'Others'},
  ];

  const hitApiRef = useRef();
  const [selectedPrice, setSelectedPrice] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const stripePromise = loadStripe(
    'pk_test_51PEB5a2K8W7Cs25henyw4Ahf3eGMQgtDgRztlGV4POXFghfMEH5DDg5PtNpT916JChWA9ELeby2Znh5mu2IjXyuY00TWUVZN1V',
  );

  const handleFilterOption = alphabet => {
    setPayment_filter(alphabet);
  };

  const handleAppointmentFilter = alphabet => {
    setValue_filter(alphabet);
  };

  const handleSessionFilter = alphabet => {
    setSession_filter(alphabet);
  };

  const handleSnapshotSelection = event => {
    const {value, checked} = event.target;
    const itemObj = JSON.parse(value);

    if (itemObj.id === 'All') {
      //   setCategoriesName(['All']);
      if (!checked) {
        setCategoriesArr([]);
      } else {
        let categories = arrayColumn(snapshotCategories, 'id');
        setCategoriesArr(categories);
      }
    } else {
      if (checked) {
        // setCategoriesName(prev => [...prev, itemObj.title]);
        setCategoriesArr(prev => [...prev, itemObj.id]);
      } else {
        // setCategoriesName(prev =>
        //   prev.filter(title => title !== itemObj.title),
        // );
        setCategoriesArr(prev => prev.filter(id => id !== itemObj.id));
      }
    }
  };
  const closeModal = () => {
    // setShow(false);
    props.closeModal();
  };
  const sendMessage = type => {
    // send message to subscribers via observable subject
    messageService.sendMessage('update_api');
  };
  const mute = () => {
    var data = {
      user_id: props.userId,
      conference_id: conferenceInfo.id,
      mic: 'off',
    };
    API.update_mic_status(data)
      .then(res => {
        if (res) {
          props.VoxeetSDK.command
            .send({dataPayload: {id: props.userId, name: 'moderator_mute'}})
            .then(succ => {
              dataOfPersons.audio = false;
              setaudio(false);
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const unmute = stage => {
    // var data = { conference_id: props.conferenceInfo.id };
    var data = {
      user_id: props.userData.info.externalId,
      conference_id: conferenceInfo.id,
      mic: 'off',
    };
    if (stage) {
      data.mic_status = 'approved';
    }
    API.update_mic_status(data)
      .then(res => {
        if (res) {
          if (stage) {
            closeModal();
          }
          props.VoxeetSDK.command
            .send({
              dataPayload: {id: props.userId, name: 'moderator_unmute'},
            })
            .then(succ => {
              dataOfPersons.audio = true;
              setaudio(true);
              sendMessage();
              props.VoxeetSDK.command
                .send({
                  dataPayload: {id: props.userId, name: 'request_accepted'},
                })
                .then(succ => {})
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getUserData = () => {
    var data = {user_id: props.userId};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserInfo(res);
          setShowUserInfo(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getOtherUserData = userId => {
    var data = {user_id: userId};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setOtherUserInfo(res);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const switchCreator = () => {
    var data = {
      user_id: props.userId,
      conference_id: props.conferenceInfo.id,
    };
    API.switch_creator(data)
      .then(res => {
        if (res) {
          sendMessage('update_api');
          props.VoxeetSDK.command
            .send({dataPayload: {id: props.userId, name: 'update_api'}})
            .then(succ => {
              closeModal();
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const Ban = () => {
    var data = {
      conference_id: props.conferenceInfo.id,
      user_id: props.userId,
    };
    API.ban(data)
      .then(res => {
        if (res) {
          messageService.sendMessage({
            user_id: props.userId,
            name: 'ban_user',
            login_id: localStorage.getItem('user'),
          });
          closeModal();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAppointmentType = () => {
    const paylaod = {
      user_id: props.userId,
    };
    commonApi
      .get_appointment_type(paylaod)
      .then(res => {
        setCheckAppoinmentData(res.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  //

  const manageUser = type => {
    // var typeTf = (type === 'audience') ? false : true;
    // props.setParticipantType(typeTf);
    if (type === 'audience') {
      mute();
    }
    var data = {
      user_id: props.userId,
      conference_id: props.conferenceInfo.id,
      user_type: type,
    };
    API.manage_user(data)
      .then(res => {
        if (res) {
          sendMessage(type);
          props.VoxeetSDK.command
            .send({
              dataPayload: {
                id: props.userId,
                name: 'update_api',
                type: type,
                moderator: UserisModerator,
              },
            })
            .then(succ => {
              closeModal();
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getSnapshotData = () => {
    var data = {
      user_id: props.userId,
      categories: '',
    };
    commonApi
      .get_accomplishment(data)
      .then(res => {
        if (res) {
          setSnapshotData(res.accomplishment);
          setShowSnapshot(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getUpcommingData = () => {
    var data = {
      user_id: props.userId,
      type: 'mine',
    };
    commonApi
      .get_user_sessions(data)
      .then(res => {
        if (res.status === 200) {
          setUpcommingData(res.user_session);
          setShowUpcomming(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getOpportunityData = () => {
    // setshowLoader(true);
    var data = {
      user_id: props.userId,
      type: 'mine',
    };
    commonApi
      .get_user_available_opportunities(data)
      .then(res => {
        // setshowLoader(false);
        if (res) {
          setOpportunityData(res.user_available_opportunity);
          setShowOpportunity(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUserSchedule = () => {
    var data = {
      user_id: props.userId,
    };
    commonApi
      .get_user_schedule(data)
      .then(res => {
        if (res.success === true) {
          setAverage(res.average);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const showSnapshotDetail = ssId => {
    var payLoad = {
      accomplishment_id: ssId,
    };
    commonApi
      .detail_accomplishment(payLoad)
      .then(response => {
        if (response.status === 200) {
          setSnapshotId(response.accomplishment_detail);
          setShowComponent({...showComponent, snapshot: true});
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };

  const getOtherUserChatSetting = id => {
    let payLoad = {
      type: 'message',
      user_id: id,
    };
    commonApi
      .get_other_user_setting(payLoad)
      .then(response => {
        if (response.success) {
          setTimeout(() => {
            checkUserChatRoom(id);
          }, 500);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const checkUserChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);
            setShowComponent({...showComponent, other_user_chat: true});
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([otherUserInfo.data]);
            setChatRoomName('');
            setChatRoomId('');
            setShowComponent({...showComponent, other_user_chat: true});
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const getOtherUserSetting = id => {
    let payLoad = {
      type: 'message',
      user_id: id,
    };
    commonApi
      .get_other_user_setting(payLoad)
      .then(response => {
        if (response.success) {
          checkChatRoom(id);
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);
            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);
            setShowComponent({...showComponent, chat: true});
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([userInfo.data]);
            setChatRoomName('');
            setChatRoomId('');
            setShowComponent({...showComponent, chat: true});
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const handleSnapshotId = (id, type) => {
    // setSnapshotId(id);
    if (type === 'snapshot') {
      showSnapshotDetail(id);
    } else if (type === 'opportunity') {
      setOpportunityItemData(id);
      setShowComponent({...showComponent, opportunity: true});
    } else if (type === 'session') {
      setSessionData(id);
      setShowComponent({...showComponent, session: true});
    } else if (type === 'referOpportunity') {
      // setOpportunityItemData(id);

      setShowComponent({...showComponent, referOpportunity: true});
      //
    } else if (type === 'transformationDetail') {
      setDataofTransFormation(id);
      setShowComponent({...showComponent, transformation: true});
    } else if (type === 'snapshot_filter') {
      setShowComponent({...showComponent, snapshot_filter: true});
    } else if (type === 'addNewSnapshot') {
      setShowComponent({...showComponent, addNewSnapshot: true});
    } else if (type === 'createTransform') {
      setShowComponent({...showComponent, createTransform: true});
    } else if (type === 'stripeModal') {
      setSelectedPrice(id.price);
      setPaymentId(id.paymentId);
      setShowComponent({...showComponent, stripe_modal: true});
    } else if (type === 'appointmentDetail') {
      setAppointmentData(id);
      setShowComponent({...showComponent, appointment_detail: true});
    } else if (type === 'appointmentStatus') {
      statusOfUserAppointment(
        id,
        localStorage.getItem('id') === id.creator_details.id
          ? 'to_me'
          : 'from_me',
      );
    } else if (type === 'followUp') {
      setItemOfActionData(id);
      setShowComponent({...showComponent, follow_up_detail: true});
    } else if (type === 'create_task') {
      setShowComponent({...showComponent, create_task: true});
    } else if (type === 'pipeline') {
      setShowComponent({...showComponent, pipeline: true});
    } else if (type === 'createDeal') {
      setShowComponent({...showComponent, create_deal: true});
    } else if (type === 'pipeLineDetail') {
      setItemOfActionData(id);
      setShowComponent({...showComponent, pipeline_detail: true});
    } else if (type === 'current_map') {
      // setItemOfActionData(id)
      setShowComponent({...showComponent, current_map: true});
    } else if (type === 'home_map') {
      // setItemOfActionData(id)
      setShowComponent({...showComponent, home_map: true});
    } else if (type === 'payment_filter') {
      setShowComponent({...showComponent, payment_filter: true});
    } else if (type === 'create_invoice') {
      setShowComponent({...showComponent, create_invoice: true});
    } else if (type === 'task_filter') {
      setShowComponent({...showComponent, task_filter: true});
    } else if (type === 'appointment_filter') {
      setShowComponent({...showComponent, appointment_filter: true});
    } else if (type === 'create_session') {
      setShowComponent({...showComponent, create_session: true});
    } else if (type === 'session_filter') {
      setShowComponent({...showComponent, session_filter: true});
    } else if (type === 'create_recruitment') {
      setShowComponent({...showComponent, create_recruitment: true});
    } else if (type === 'recruitment_filter') {
      setShowComponent({...showComponent, recruitment_filter: true});
    } else if (type === 'create_tag') {
      setShowComponent({...showComponent, create_tag: true});
    }else if (type === 'invoiceDetail') {
      setInvoiceItem(id);
      setShowComponent({...showComponent, invoice_detail: true});
    }
  };

  console.log(checkFilterOption, 'filterOptionValuefilterOptionValue');
  // const handleBlock = (id, type) => {
  //   setProfileId(id);
  //   setProfileType(type);
  //   if (type === 'followers') {
  //     setShowComponent({...showComponent, ['followers']: true});
  //   } else if (type === 'appointment') {
  //     if (parseInt(id.id) === parseInt(localStorage.getItem('id'))) {
  //       setShowComponent({...showComponent, ['mineAppointment']: true});
  //     } else {
  //       setShowComponent({...showComponent, ['appointment']: true});
  //     }
  //   } else if (type === 'praise') {
  //     setShowComponent({...showComponent, ['praise']: true});
  //   } else if (type === 'circle') {
  //     setShowComponent({...showComponent, ['circle']: true});
  //   } else if (type === 'share') {
  //     setShowComponent({...showComponent, ['share']: true});
  //   } else if (type === 'chat') {
  //     setShowComponent({...showComponent, ['chat']: true});
  //     getOtherUserSetting(id.id);
  //   } else if (type === 'profile-notes') {
  //     setShowComponent({...showComponent, ['profilenotes']: true});
  //   } else if (type === 'work-space') {
  //     setShowComponent({...showComponent, ['personal']: true});
  //   } else if (type === 'show-gallery') {
  //     props.toggleHiddenPanel(true);
  //   } else if (type === 'Profile_view') {
  //     // props.setNowShow(false)
  //     setShowComponent({...showComponent, ['Profile_view']: true});
  //   }
  // };
  const handleBlock = (id, type) => {
    if (type === 'otherUserCHat') {
      getOtherUserChatSetting(id.id);
      getOtherUserData(id.id);
    }

    setProfileId(id);
    // setProfileType(type);
    setShowComponent(prevState => {
      switch (type) {
        case 'followers':
          return {...prevState, followers: true};
        case 'appointment':
          if (parseInt(id.id) === parseInt(localStorage.getItem('id'))) {
            return {...prevState, mineAppointment: true};
          } else {
            return {...prevState, appointment: true};
          }
        case 'praise':
          return {...prevState, praise: true};
        case 'circle':
          return {...prevState, circle: true};
        case 'share':
          return {...prevState, share: true};
        case 'chat':
          getOtherUserSetting(id.id);
          return {...prevState, chat: true};
        case 'profile-notes':
          return {...prevState, profilenotes: true};
        case 'work-space':
          return {...prevState, personal: true};
        case 'show-gallery':
          props.toggleHiddenPanel(true);
          return prevState;
        case 'Profile_view':
          return {...prevState, Profile_view: true};
        case 'follow-up':
          return {...prevState, follow_up: true};
        case 'home-map':
          return {...prevState, home_map: true};
        case 'current-map':
          return {...prevState, current_map: true};
        case 'view_tag':
          return {...prevState, view_tag: true};
        default:
          return prevState;
      }
    });
  };

  // const handleNotificationDirection = () => {
  //   const {pathname, state} = locationWeb;
  //   if (state && state.typeIs === 'scheduleAppointment') {
  //     setShowComponent({...showComponent, ['mineAppointment']: true});
  //     setTimeout(() => {
  //       setShowComponent({...showComponent, ['mineAppointment']: true});
  //     }, 1000);
  //     // history.replace({pathname: pathname, key: Date.now()});
  //   }
  // };
  const handleNotificationDirection = () => {
    const {state} = locationWeb;
    if (state && state.typeIs === 'scheduleAppointment') {
      setShowComponent(prevState => ({
        ...prevState,
        mineAppointment: true,
      }));
      setTimeout(() => {
        setShowComponent(prevState => ({
          ...prevState,
          mineAppointment: true,
        }));
      }, 1000);
      // history.replace({pathname: pathname, key: Date.now()});
    }
  };

  useEffect(() => {
    if (props.userData.audio) {
      setaudio(true);
    } else {
      setaudio(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, UserisModerator]);

  useEffect(() => {
    props.jdSpecialM.includes(props.userId) && setUserisModerator(true);
    props.jdSpecialA.includes(props.userId) && setUserisModerator(false);

    handleNotificationDirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //
    setconferenceInfo(props.conferenceInfo);
    getUserData();
    // getUserSchedule();
    // getSnapshotData();
    // getOpportunityData();
    // getUpcommingData();
    // setTimeout(() => {
    //   getAppointmentType();
    // }, 1000);

    setShowComponent({
      followers: false,
      following: false,
      snapshot: false,
      opportunity: false,
      session: false,
      mineAppointment: false,
      appointment: false,
      praise: false,
      circle: false,
      share: false,
      referOpportunity: false,
      chat: false,
      profilenotes: false,
      transformation: false,
      createTransform: false,
      follow_up: false,
      other_user_chat: false,
      appointment_detail: false,
      appointment_meeting_status: false,
    });

    if (props.userId === getItem('id')) {
      amplitudeEvent('VISIT_OWN_PROFILE');
    } else {
      amplitudeEvent('VISIT_OTHER_PROFILE');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  const handleApStatus = alphabet => {
    // getMyAppointmentsByType(alphabet);
    // setSelectedFilterValue(alphabet);
    setUserApStatus(alphabet);
  };

  const changeUserMeetingStatus = status => {
    const data = {meeting_id: changedStatusAppointment.id, user_status: status};

    commonApi
      .change_button_status(data)
      .then(res => {
        refreshUserAppointmentList();

        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const refreshUserAppointmentList = () => {
    var Payload = {
      user_id: props.userId,
    };

    dispatch(getUserProfileAppointments(Payload));
  };
  const statusOfUserAppointment = (item, type) => {
    if (type === 'to_me') {
      setUserApStatus(item.creator_status);
    } else if (type === 'from_me') {
      setUserApStatus(item.user_status);
    }

    setChangedStatusAppointment(item);
    setShowComponent({...showComponent, appointment_meeting_status: true});
  };

  const handleOverViewAppointment = (item, value) => {
    if (userInfo.appointment_detail.id === item.id) {
      if (localStorage.getItem('id') === item.creator_details.id) {
        userInfo.appointment_detail.creator_status = value;
      } else {
        userInfo.appointment_detail.user_status = value;
      }
    }
  };

  const handleTaskFilter = (id, value) => {
    setCheckFilterOption([id]);
  };

  return (
    <>
      {showUserInfo && (
        <div className="primary_panel_content user_profile " style={{background: 'rgb(66, 66, 66)'}}>
          <div>
            <div
              className="black-header"
              style={{
                backgroundColor: 'rgb(66, 66, 66)',
                // paddingTop:'60px'
              }}>
              <div
                className="adjust_icon "
                style={{
                  fontSize: '22px',
                  padding: '24px',
                  color: ' white',
                  display: 'flex',
                  justifyContent: 'left',
                  gap: '20px',
                }}>
                <span
                  className=" as-link"
                  onClick={() => {
                    props.setEditProfile && props.setEditProfile('editProf');
                  }}>
                  <EditPencilSvg fill={'white'} h={20} w={20} />
                </span>

                {/* <i
                  className="icon-circles as-link"
                  style={{
                    color: ' white',
                  }}
                  onClick={() => {
                    // handleBlock(profile.data, 'circle');
                  }}
                /> */}

                <i
                  className="fas fa-share  as-link"
                  onClick={() => {
                    handleBlock(userInfo.data, 'share');
                  }}
                />
                {/* <i
                  className="icon-plane  as-link"
                  onClick={() => {
                    // handleBlock(profile.data, 'chat');
                  }}
                /> */}
                <i
                  className="fa-solid fa-bell as-link"
                  onClick={() => {
                    handleBlock(userInfo.data, 'follow-up');
                  }}></i>
                <i
                  className="fas fa-heart as-link"
                  onClick={() => {
                    if (props.setEditProfile) {
                      props.setEditProfile('Favr');
                    }
                  }}></i>
              </div>
            </div>
          </div>
          <div
            className="refferal-list-wrapper primary_panel_wraper"
            style={{
              background: 'rgb(255, 255, 255)',
              borderRadius: '35px 35px 0 0',
              paddingTop:'0px',
              overflow:'hidden'
            }}>
            <Scrollbars 
            renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
            // Hide the vertical scrollbar
            renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
              <div className='pt-2'>
                {/* {showLoader && <LoaderSpinner />} */}

                {props.moderator &&
                  props.conferenceInfo.creator_id !== props.userId && (
                    <div className="sidebar-heading-wrapper ">
                      <div className="modal-header-icons">
                        <span>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                        {audio ? (
                          <span onClick={() => mute()}>
                            <i className="fas fa-microphone-slash" />
                          </span>
                        ) : (
                          <span onClick={() => unmute(false)}>
                            <i className="fas fa-microphone" />
                          </span>
                        )}
                        <span onClick={() => switchCreator()}>
                          <i className="far fa-star" />
                        </span>
                      </div>

                      <div className="btns-wrapper">
                        {props.moderator &&
                          props.conferenceInfo.creator_id !== props.userId &&
                          (UserisModerator ? (
                            <>
                              {props.jdSpecialS.includes(props.userId) && (
                                <Button
                                  className="btn-dark"
                                  onClick={() => manageUser('moderator')}>
                                  Make Moderator
                                </Button>
                              )}
                              <Button
                                className="btn-dark"
                                onClick={() => manageUser('audience')}>
                                Send To Audience
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                className="btn-dark"
                                onClick={() => manageUser('moderator')}>
                                Make Moderator
                              </Button>
                              <Button onClick={() => unmute(true)}>
                                Send To Stage
                              </Button>
                            </>
                          ))}

                        {props.conferenceInfo.creator_id ===
                          localStorage.getItem('user') && (
                          <Button className="btn-dark" onClick={() => Ban()}>
                            Ban
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                {/* { props.noshow === true || props.hideCloseButton  ?<></>:
        <span className="close as-link " onClick={() => closeModal()}>
          <i className="fas fa-times " />
        </span>} */}

                <div className="session-profile-wrapper profile-modal">
                  {showUserInfo && (
                    <>
                      {/* <SlidingPanel hidePanel={() => props.onClose()}> */}
                      {/* <Scrollbars> */}
                      <div className="fix-width-forms fix-width-padd-remove">
                        <ProfileBlock
                          average={average}
                          profile={userInfo}
                          handleBlock={handleBlock}
                          checkAppoinmentData={checkAppoinmentData}
                          noshow={props.noshow}
                          setEditProfile={props.setEditProfile}
                          editProfile={props.editProfile}
                          submit={props.submit}
                          typeModAud={props.typeModAud}
                          showButton={props.showButton}
                          serverTime={props.serverTime}
                          sessionData={props.sessionData}
                          tagsListing={userInfo}
                        />
                        {/* {userInfo &&
                userInfo.data &&
                userInfo.data.invited_by &&
                userInfo.data.invited_by.first_name && (
                  <div className="invited-by-wrapper">
                    <div className="user-img">
                      <img
                        src={
                          userInfo.data.invited_by.profile_img
                            ? userInfo.data.invited_by.profile_img
                            : DEFAULT_IMAGE
                        }
                        alt=""
                      />
                    </div>
                    <div className="user-info">
                      <span className="info-name">
                        Invited by{' '}
                        <b>
                          {userInfo.data.invited_by.first_name +
                            ' ' +
                            userInfo.data.invited_by.last_name}
                        </b>
                      </span>
                      <span className="info-date">
                        {moment(
                          userInfo.data.invited_by.invitation_date,
                        ).format('MM/DD/YY')}
                      </span>
                    </div>
                  </div>
                )} */}
                        {!props.noshow && (
                          <div className="mt-2">
                            <ProfileGallery
                              userId={props.userId}
                              gallery={userSnapshots}
                              isNewSession={false}
                              sesList={userUpcomming}
                              oppos={userOpportunity}
                              handleCommand={handleSnapshotId}
                              setShowComponent={setShowComponent}
                              showComponent={showComponent}
                              filterCat={filterCat}
                              permission={BusinnesProfilePermessions}
                              hitApiRef={hitApiRef}
                              userData={userInfo}
                              handleBlock={handleBlock}
                              selectedPaymentFilter={selectedPaymentFilter}
                              filterOptionValue={filterOptionValue}
                              selectedFilterValue={selectedFilterValue}
                              sessionFilterValue={sessionFilterValue}
                              setSessionFilterValue={setSessionFilterValue}
                              setshowLoader={props.setShowSpinner}
                              // fromPage={'userProfile'}
                            />
                          </div>
                        )}
                        {/* {props.button &&
            <ProfileGallery
              userId={props.userId}
              gallery={userSnapshots}
              isNewSession={false}
              sesList={userUpcomming}
              oppos={userOpportunity}
              handleCommand={handleSnapshotId}
            />} */}
                        {/* {props.glryButton && 
            <ProfileGallery
              userId={props.userId}
              gallery={userSnapshots}
              isNewSession={false}
              sesList={userUpcomming}
              oppos={userOpportunity}
              handleCommand={handleSnapshotId}
            />} */}
                      </div>
                      {/* </Scrollbars> */}
                      {/* </SlidingPanel> */}
                    </>
                  )}
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      )}
      {showComponent.snapshot ? (
        <SnapshotDetail
          snapshotId={snapshotId}
          userData={userInfo.data}
          onClose={() => setShowComponent({...showComponent, snapshot: false})}
          showRefer={true}
          noshow={props.noshow}
        />
      ) : showComponent.opportunity ? (
        <>
          {opportunityData &&
          opportunityData.chat_detail &&
          opportunityData.chat_detail.hasOwnProperty('id') ? (
            // <OpportunityRespond
            //   item={opportunityData}
            //   userData={userInfo.data}
            //   onClose={() =>
            //     setShowComponent({ ...showComponent, ["opportunity"]: false })
            //   }
            // />
            <CommonChatPage
              type={'new-opportunity'}
              item={[]}
              oppoDetails={opportunityData}
              chatDetails={opportunityData.chat_detail}
              allChatUsers={
                opportunityData.hasOwnProperty('creator_details')
                  ? [opportunityData.creator_details]
                  : [opportunityData.creator_detail]
              }
              chatRoomName={
                opportunityData.chat_detail.hasOwnProperty('chat_room_name')
                  ? opportunityData.chat_detail.chat_room_name
                  : ''
              }
              chatRoomId={
                opportunityData.chat_detail.hasOwnProperty('id')
                  ? opportunityData.chat_detail.id
                  : ''
              }
              onClose={() => {
                setShowComponent({...showComponent, opportunity: false});
              }}
              userData={userInfo.data}
              // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
            />
          ) : (
            <OpportunityDetail
              item={opportunityData}
              userData={userInfo.data}
              onClose={() =>
                setShowComponent({...showComponent, opportunity: false})
              }
            />
          )}
        </>
      ) : showComponent.followers ? (
        <>
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['followers']: false })}>
                    Back
                </span> */}
          <FollowersList
            setShowComponent={setShowComponent}
            userId={profileId}
          />
        </>
      ) : showComponent.praise ? (
        <>
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['praise']: false })}>
                    Back
                </span> */}
          <PraiseCoution
            setShowComponent={setShowComponent}
            userData={profileId}
            marginTop={true}
          />
        </>
      ) : showComponent.referOpportunity ? (
        // <div className="content-sidebar">
        <ReferOpportunity
          onClose={() =>
            setShowComponent({...showComponent, referOpportunity: false})
          }
          type={'opportunity'}
          userData={profileId}
        />
      ) : // </div>
      showComponent.session ? (
        <>
          {sessionData.creator_id === localStorage.getItem('id') ? (
            <CreateSession
              onClose={() =>
                setShowComponent({...showComponent, session: false})
              }
              editSession={sessionData}
              type={true}
              item={sessionData}
            />
          ) : (
            <div>
              <SessionDetail
                item={sessionData}
                onClose={() =>
                  setShowComponent({...showComponent, session: false})
                }
              />
            </div>
          )}
        </>
      ) : showComponent.appointment ? (
        <div>
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['appointment']: false })}>
                    Back
                </span> */}
          <AppointmentList
            setShowComponent={setShowComponent}
            userData={profileId}
            windowWidth={props.windowWidth}
          />
        </div>
      ) : showComponent.mineAppointment ? (
        <div style={{height: '100%'}}>
          <UserAppointments
            onClose={() =>
              setShowComponent({...showComponent, mineAppointment: false})
            }
            // userData={profileId}
          />
        </div>
      ) : showComponent.circle ? (
        <>
          {/* <div className="content-sidebar"> */}
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['circle']: false })}>
                    Back
                </span> */}
          <CircleList
            setShowComponent={setShowComponent}
            checkAppoinmentData={checkAppoinmentData}
            userData={profileId}
            onClose={() => setShowComponent({...showComponent, circle: false})}
          />
          {/* </div> */}
        </>
      ) : showComponent.share ? (
        <>
          {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['share']: false })}>
                    Back
                </span> */}
          <ReferProfile
            userData={profileId}
            onClose={() => setShowComponent({...showComponent, share: false})}
            marginTop={true}
          />
        </>
      ) : showComponent.chat ? (
        <>
          <SmartChatPage
            allmsg={true}
            type={chatType}
            item={[]}
            chatroomInfo={
              chatDetails.length > 0
                ? chatroomInfo(chatDetails)
                : newSingleChatroom(userInfo.data)
            }
            oppoDetails={chatDetails}
            chatDetails={chatDetails}
            allChatUsers={allChatUsers}
            chatRoomName={chatRoomName}
            chatRoomId={chatRoomId}
            onClose={() => {
              setShowComponent({...showComponent, chat: false});
            }}
            userData={userInfo.data}
            // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
          />
        </>
      ) : showComponent.profilenotes ? (
        <ProfileNotes
          onClose={() =>
            setShowComponent({...showComponent, profilenotes: false})
          }
          marginTop={true}
          userData={profileId}
        />
      ) : showComponent.personal ? (
        <PersonalModal
          list={list}
          onClose={() => setShowComponent({...showComponent, personal: false})}
        />
      ) : showComponent.Profile_view ? (
        <div className="profile-view-parent">
          <SlidingPanel
            hidePanel={() =>
              setShowComponent({...showComponent, Profile_view: false})
            }
            type={'remove_class'}>
            <ProfileComponent
              userId={profileId}
              show={false}
              closeModal={() => {
                setShowComponent({...showComponent, Profile_view: false});
              }}
              userData={[]}
              moderator={false}
              VoxeetSDK={[]}
              conferenceInfo={[]}
              jdSpecialM={[]}
              jdSpecialA={[]}
              onClose={() => {
                setShowComponent({...showComponent, Profile_view: false});
              }}
            />
          </SlidingPanel>
        </div>
      ) : showComponent.transformation ? (
        <TransformDetail
          item={dataofTransFormation}
          onClose={() => {
            setShowComponent('');
          }}
        />
      ) : showComponent.createTransform ? (
        <CreateTransform
          onClose={() => {
            setShowComponent({...showComponent, createTransform: false});
          }}
        />
      ) : showComponent.snapshot_filter ? (
        <CustomModal
          size="medium"
          className={'filter-modal from-otherProfile-Snapshot'}
          style={{height: '85%'}}
          onClose={() => {
            setShowComponent({...showComponent, snapshot_filter: false});
          }}>
          <div className="filter-wrapper custom-modal-filter mb-0">
            <div className="filter-headings text-center">
              <h2>Filter By Category</h2>
            </div>
            <Scrollbars className="custom-scroll"
            renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
            // Hide the vertical scrollbar
            renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
              <div className="filter-category-list">
                <ul>
                  <li
                    key={`sessionC-100`}
                    className={categoriesArr.length === 8 ? 'active' : ''}>
                    <Form.Label>
                      {'All'}
                      <input
                        type="checkbox"
                        value={'{"id":"All", "title":"All"}'}
                        onChange={handleSnapshotSelection}
                        checked={categoriesArr.length === 8}
                      />
                      <span />
                    </Form.Label>
                  </li>
                  {Object.entries(snapshotCategories).length > 0 &&
                    snapshotCategories.map((item, index) => (
                      <li
                        key={`sessionC-${index}`}
                        className={
                          categoriesArr.includes(item.id) ? 'active' : ''
                        }>
                        <Form.Label>
                          {item.category_name}
                          <input
                            type="checkbox"
                            value={
                              '{"id":"' +
                              item.id +
                              '", "title":"' +
                              item.category_name +
                              '"}'
                            }
                            onChange={handleSnapshotSelection}
                            checked={categoriesArr.includes(item.id)}
                          />
                          <span />
                        </Form.Label>
                      </li>
                    ))}
                </ul>
              </div>
            </Scrollbars>
            <div
              className="filter-category-list text-center bg_transparent"
              style={{borderTopRightRadius: '0px', borderTopLeftRadius: '0px'}}>
              <Button
                className="btn-dark"
                onClick={() => {
                  setFilterCat(categoriesArr);
                  setShowComponent({
                    ...showComponent,
                    snapshot_filter: false,
                  });
                }}>
                Filter
              </Button>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.addNewSnapshot ? (
        <AddNewSnapShot
          onClose={() => {
            setShowComponent({...showComponent, addNewSnapshot: false});
          }}
        />
      ) : showComponent.stripe_modal ? (
        <CustomModal
          size="small"
          onClose={() => {
            setShowComponent({...showComponent, stripe_modal: false});
            setSelectedPrice('');
            setPaymentId('');
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'payment',
                  amount: 10 * 100,
                  currency: 'usd',
                  paymentMethodTypes: ['card'],
                  payment_method_types: ['card'],
                }}>
                <CheckoutForm
                  price={selectedPrice}
                  paymentId={paymentId}
                  transactionType={'invoice'}
                  onClose={() => {
                    setShowComponent({...showComponent, stripe_modal: false});
                    setSelectedPrice('');
                    setPaymentId('');
                    hitApiRef.current.getInvoice();
                  }}
                />
              </Elements>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.follow_up ? (
        <ProfileNotes
          onClose={() => setShowComponent({...showComponent, follow_up: false})}
          type={'follow-up'}
          marginTop={true}
          userData={profileId}
        />
      ) : showComponent.other_user_chat && otherUserInfo ? (
        <>
          <SmartChatPage
            allmsg={true}
            type={chatType}
            item={[]}
            chatroomInfo={
              chatDetails.length > 0
                ? chatroomInfo(chatDetails)
                : newSingleChatroom(otherUserInfo.data)
            }
            oppoDetails={chatDetails}
            chatDetails={chatDetails}
            allChatUsers={allChatUsers}
            chatRoomName={chatRoomName}
            chatRoomId={chatRoomId}
            onClose={() => {
              setShowComponent({...showComponent, other_user_chat: false});
            }}
            userData={otherUserInfo.data}
            // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
          />
        </>
      ) : showComponent.appointment_detail ? (
        <AppointmentCallDetail
          type={'userProfile'}
          appointmentData={appointmentData}
          subTabType={
            localStorage.getItem('id') === appointmentData.creator_details.id
              ? 'to_me'
              : 'from_me'
          }
          onClose={() => {
            setShowComponent({...showComponent, appointment_detail: false});
          }}
          changeMeetingStatusForOverView={(item, status) => {
            handleOverViewAppointment(item, status);
          }}
          setUserApStatus={setUserApStatus}
          refreshUserAppointmentList={refreshUserAppointmentList}
        />
      ) : showComponent.appointment_meeting_status ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              appointment_meeting_status: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(Appointment_Status_List).length > 0 &&
                  Appointment_Status_List.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.title}
                        <input
                          type="checkbox"
                          id={item.title}
                          value={item.title}
                          checked={userApStatus === item.title}
                          onChange={() => handleApStatus(item.title)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  type="submit"
                  className="btn-dark"
                  onClick={() => {
                    changeUserMeetingStatus(userApStatus);
                    handleOverViewAppointment(
                      changedStatusAppointment,
                      userApStatus,
                    );
                    setShowComponent({
                      ...showComponent,
                      appointment_meeting_status: false,
                    });
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.follow_up_detail ? (
        <ActionDetail
          dataOfAction={itemOfActionData}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              follow_up_detail: false,
            });
            setItemOfActionData({});
          }}
        />
      ) : showComponent.create_task ? (
        <CreateAction
          component={'userProfile'}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              create_task: false,
            });
          }}
          refreshUserDataList={() => {
            hitApiRef.current.getTasks();
          }}
          type={'userProfile'}
          primary={true}
        />
      ) : showComponent.pipeline ? (
        <CreatePipeLine
          onClose={() => {
            setShowComponent({
              ...showComponent,
              pipeline: false,
            });
            // setItemOfActionData({});
          }}
        />
      ) : showComponent.create_deal ? (
        <CreateDeal
          onClose={() => {
            setShowComponent({
              ...showComponent,
              create_deal: false,
            });
          }}
        />
      ) : showComponent.pipeline_detail ? (
        <PipelineDetail
          itemOfPipeline={itemOfActionData}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              pipeline_detail: false,
            });
          }}
          pipeLinePermissions={userInfo.pipeline_permission}
        />
      ) : showComponent.current_map ? (
        <ProfileMap
          profile={userInfo}
          home={false}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              current_map: false,
            });
          }}
        />
      ) : showComponent.home_map ? (
        <ProfileMap
          profile={userInfo}
          home={true}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              home_map: false,
            });
          }}
        />
      ) : showComponent.payment_filter ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              payment_filter: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(filterListArray).length > 0 &&
                  filterListArray.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.title}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.value}
                          checked={payment_filter === item.value}
                          onChange={() => handleFilterOption(item.value)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    setSelectedPaymentFilter(payment_filter);
                    setShowComponent({
                      ...showComponent,
                      payment_filter: false,
                    });
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.create_invoice ? (
        <CreateInvoice
          type={'profile'}
          onClose={() => {
            setShowComponent({
              ...showComponent,
              create_invoice: false,
            });
          }}
          hitApiRefresh={() => {
            hitApiRef.current.getInvoice();
          }}
          marginTop={true}
        />
      ) : showComponent.task_filter ? (
        <CustomModal
          className="shareables-workspace-modal"
          size="small"
          onClose={() =>
            setShowComponent({
              ...showComponent,
              task_filter: false,
            })
          }>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {/* <li
                  className={
                    checkFilterOption.includes('all') ||
                    filterOptionValue.includes('all')
                      ? 'active'
                      : ''
                  }>
                  <Form.Label>
                    All
                    <input
                      type="checkbox"
                      id="All"
                      value="All"
                      onChange={() => {
                        handleSelectAll('all');
                      }}
                      checked={
                        checkFilterOption.includes('all') ||
                        filterOptionValue.includes('all')
                      }
                    />
                    <span />
                  </Form.Label>
                </li> */}

                {ActionCategories.map((item, index) => (
                  <li
                    key={`sessionC-${index}`}
                    className={
                      checkFilterOption.includes(item.id) ? 'active' : ''
                    }>
                    <Form.Label>
                      {item.title}
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.title}
                        onChange={e => {
                          handleTaskFilter(item.id, e.target.value);
                        }}
                        checked={checkFilterOption.includes(item.id)}
                      />
                      <span />
                    </Form.Label>
                  </li>
                ))}
              </ul>

              <div className="text-center">
                <Button
                  type="submit"
                  className="btn-dark "
                  onClick={() => {
                    const item = ActionCategories.filter(
                      item => item.id === checkFilterOption[0],
                    );
                    console.log(item, 'itemfromfilter');
                    setFilterOptionValue([item[0].title]);
                    setShowComponent({
                      ...showComponent,
                      task_filter: false,
                    });
                  }}>
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.appointment_filter ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              appointment_filter: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(filterListArray).length > 0 &&
                  filterListArray.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.title}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.value}
                          checked={value_filter === item.value}
                          onChange={() => handleAppointmentFilter(item.value)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  type="submit"
                  className="btn-dark"
                  onClick={() => {
                    // onSubmitFilter(value_filter);
                    setSelectedFilterValue(value_filter);
                    setShowComponent({
                      ...showComponent,
                      appointment_filter: false,
                    });
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.create_session ? (
        <CreateSession
          onClose={() =>
            setShowComponent({...showComponent, create_session: false})
          }
          // editSession={sessionData}
          // type={true}
          // item={sessionData}
        />
      ) : showComponent.session_filter ? (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent({
              ...showComponent,
              session_filter: false,
            });
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {Object.entries(sessionFilterArray).length > 0 &&
                  sessionFilterArray.map((item, index) => (
                    <li key={`sessionC-${index}`}>
                      <Form.Label>
                        {item.title}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.value}
                          checked={session_filter === item.value}
                          onChange={() => handleSessionFilter(item.value)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  type="submit"
                  className="btn-dark"
                  onClick={() => {
                    // onSubmitFilter(value_filter);
                    setSessionFilterValue(session_filter);
                    setShowComponent({
                      ...showComponent,
                      session_filter: false,
                    });
                  }}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : showComponent.create_recruitment ? (
        <EditOpportunities
          onClose={() =>
            setShowComponent({...showComponent, create_recruitment: false})
          }
          item={[]}
          // editSession={sessionData}
          // type={true}
          // item={sessionData}
        />
      ) : showComponent.create_tag ? (
        <CreateTag
          onClose={() =>
            setShowComponent({...showComponent, create_tag: false})
          }
        />
      )  : showComponent.invoice_detail ? (
        <InvoiceDeatil
        invoiceItem={invoiceItem}
          handleStates={() => {
            hitApiRef.current.getInvoice();
          }}
          onClose={() => {
            setShowComponent({...showComponent, invoice_detail: false});
            setInvoiceItem({});
          }}
        />
      )  : (
        showComponent.view_tag && (
          <ProfileTags
            handleCommand={handleSnapshotId}
            onClose={() =>
              setShowComponent({...showComponent, view_tag: false})
            }
            user_id={localStorage.getItem('id')}
            userTagList={userInfo?.tags_details}
            permession={userInfo?.tag_permission?.create_tag}
          />
        )
      )}
    </>
  );
}

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(ProfileComponent);
