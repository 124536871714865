import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import Calendar from 'react-calendar';
import Slider from 'react-slick';

import 'react-calendar/dist/Calendar.css';
import {monthsText, weekDay} from '../../Constants';
import {useSelector, useDispatch} from 'react-redux';
import {getApointCurrentDate} from '../../Store/Actions/AppointmentAction';
import moment from 'moment/moment';
import {convertTime12To24} from '../../Utils/commonUtils';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import commonApi from '../../Services/CommonService';
import {getItem} from '../../Utils/LocalStorage';
import $ from 'jquery';

const TimingSlider = ({
  updateTime,
  data,
  removeTime,
  setShowLogin,
  setShowFormP,
  setPayLoadIsP,
  setShowModelP,
  appointmentDetailP,
  cardDeatilP,
  appointmentDisableP,
  windowWidth,
  setHideSlotsInMobileView,
}) => {
  const [appointmentDisable, setAppointmentDisable] = useState([]);
  const [payLoadIs, setPayLoadIs] = useState({});
  const {pathname} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const sliderRef = useRef(null);

  const handleTransformClass = () => {
    $('.timing-slider')
      .find('.slick-track')
      .removeClass('slider_transform_class');
    if (data.time !== '') {
      setTimeout(() => {
        $('.timing-slider')
          .find('.slick-prev')
          .addClass('timing_slider_prev_btn');
      }, 0);
    }
  };
  const handleSliderButtonClass = () => {
    // if(data.time !==''){
    //   $('.timing-slider').find('.slick-prev').addClass('timing_slider_prev_btn');
    // }
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    vertical: true,
    beforeChange: handleTransformClass,
    afterChange: handleSliderButtonClass,
  };

  const apointTimes = useSelector(state => state.apointSlots);
  const startDate = useSelector(state => state.apointCurrentDate);
  const serverTime = useSelector(state => state.serverTime);

  const bookAppointment = slot => {
    const date = new Date(slot.new_start_time);
    const appointmentPayload = {
      complete_time: slot.new_start_time.replaceAll('/', '-'),
      date: moment(date).format('ll').toString(),
      day: moment(date).format('dddd').toString(),
      mentor_id: slot.creator_id,
      slot_id: slot.id,
      start_time: slot.start_time,
    };
    createAppointment(appointmentPayload);
    // setAppointmentDisable([...appointmentDisable, slot.id]);
    removeTime();
  };

  const createAppointmentPayload = data => {
    const date = new Date(data.new_start_time);
    setPayLoadIs({
      complete_time: data.new_start_time.replaceAll('/', '-'),
      date: moment(date).format('ll').toString(),
      day: moment(date).format('dddd').toString(),
      mentor_id: data.creator_id,
      slot_id: data.id,
      start_time: data.start_time,
    });
    setPayLoadIsP({
      complete_time: data.new_start_time.replaceAll('/', '-'),
      date: moment(date).format('ll').toString(),
      day: moment(date).format('dddd').toString(),
      mentor_id: data.creator_id,
      slot_id: data.id,
      start_time: data.start_time,
    });
  };

  //   const stripePayment = data => {
  //     setshowLoader(true);
  //     commonApi
  //       .stripe_payment(data)
  //       .then(response => {
  //         if (response.stripe === true) {
  //           createAppointment();
  //           addCard();
  //         }
  //       })
  //       .catch(err => {
  //
  //         return {type: 'error', message: err.message};
  //       });
  //   };

  const createAppointment = item => {
    commonApi
      .create_appointment(
        Object.keys(payLoadIs).length !== 0 ? payLoadIs : item,
      )
      .then(response => {
        amplitudeEvent('BOOK_APPOINTMENT');
        if (response.success === true) {
          setAppointmentDisable(arr => [...arr, payLoadIs.slot_id]);
          successMessage(response.message);
          setPayLoadIs({});
          setPayLoadIsP({});
        } else {
          if (response.occupied) {
            setAppointmentDisable(arr => [...arr, payLoadIs.slot_id]);
            setPayLoadIs({});
            setPayLoadIsP({});
          }
          errorMessage(response.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    setAppointmentDisable([]);
  }, [startDate]);

  useEffect(() => {
    removeTime();
    setAppointmentDisable(arr => [...arr, ...appointmentDisableP]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDisableP]);
  useEffect(() => {
    if (apointTimes.length > 0) {
      $('.timing-slider')
        .find('.slick-track')
        .addClass('slider_transform_class');
      $('.timing-slider').find('.slick-list').addClass('adjust_slick_height');
    }
    if (apointTimes.length === 0) {
      $('.timing-slider').find('.slick-track').addClass('height_0');
    }

    setTimeout(() => {
      $('.timing-slider')
        .find('.slick-list')
        .removeClass('adjust_slick_height');
    }, 200);

    if (data.date !== '' && windowWidth < 768) {
      $('body').addClass('over_flow_hidden');
    } else {
      $('body').removeClass('over_flow_hidden');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apointTimes]);

  const adjustSlider = type => {
    // alert('')

    setTimeout(() => {
      if (type === 'add') {
        $('.timing-slider')
          .find('.slick-prev')
          .addClass('timing_slider_prev_btn');
        $('.timing-slider')
          .find('.slick-next')
          .addClass('timing_slider_next_btn');
      } else if (type === 'remove') {
        $('.timing-slider')
          .find('.slick-prev')
          .removeClass('timing_slider_prev_btn');
        $('.timing-slider')
          .find('.slick-next')
          .removeClass('timing_slider_next_btn');
      }
    }, 50);
  };

  return (
    <div className="timing-slider">
      <Slider {...settings} ref={sliderRef}>
        {Object.entries(apointTimes).length > 0 ? (
          apointTimes.map((item, index) => {
            const isActive = item.start_time === data.time ? 'active' : '';

            if (item.start_time === data.time) {
              return (
                <div
                  className="d-flex appointmentConsult"
                  key={`times_${index}`}>
                  <span
                    className={`time-item time-slots ${isActive}`}
                    // key={`times_${index}`}
                    // onClick={() => confirmTime()}
                    onClick={() => {
                      const searchParams = new URLSearchParams();
                      searchParams.append('type', item.schedule_id);
                      const url = `/${
                        urlParts[0] + '/' + urlParts[1]
                      }/consult/invite?${searchParams.toString()}`;
                      if (getItem('id') || getItem('token')) {
                        // bookAppointment(item);

                        if (
                          typeof cardDeatilP === 'object' &&
                          !Array.isArray(cardDeatilP) &&
                          appointmentDetailP.cost
                        ) {
                          // show modal
                          setShowModelP(true);
                        } else {
                          if (appointmentDetailP.cost) {
                            setShowFormP(true);
                          } else {
                            // createAppointment();
                            bookAppointment(item);
                            adjustSlider('remove');
                          }
                        }
                      } else {
                        // successMessage('Please login first');
                        // setShowLogin(item.id);
                        setShowLogin(url);
                      }
                      setHideSlotsInMobileView(false);
                    }}>
                    Confirm
                  </span>
                  <span
                    className={`time-item close-item consult-appointment-cross`}
                    // key={`times_${index}`}
                    onClick={() => {
                      removeTime();
                      adjustSlider('remove');
                    }}>
                    <i className="fas fa-times" />
                  </span>
                </div>
              );
            } else {
              return (
                <span
                  className={`time-item ${isActive} ${
                    item.slot_availability === 'occupied' ||
                    appointmentDisable.includes(item.id) ||
                    new Date(moment(serverTime).format('MMMM D, YYYY')) >
                      startDate ||
                    (convertTime12To24(moment(serverTime).format('hh:mm A')) >
                      convertTime12To24(item.start_time) &&
                      moment(serverTime).format('l') ===
                        moment(startDate).format('l'))
                      ? 'disabled-class'
                      : ''
                  }`}
                  key={`times_${index}`}
                  onClick={() => {
                    if (
                      item.slot_availability === 'occupied' ||
                      appointmentDisable.includes(item.id) ||
                      new Date(moment(serverTime).format('MMMM D, YYYY')) >
                        startDate ||
                      (convertTime12To24(moment(serverTime).format('hh:mm A')) >
                        convertTime12To24(item.start_time) &&
                        moment(serverTime).format('l') ===
                          moment(startDate).format('l'))
                    ) {
                    } else {
                      adjustSlider('add');
                      updateTime(item.start_time);
                      createAppointmentPayload(item);
                    }
                  }}>
                  {item.start_time} - {item.end_time}
                </span>
              );
            }
          })
        ) : (
          <div className="no-results-wrapper no-appointments my-5 no_slots_found">
            <div className="no-results-content">
              <p className={'noRecord-desc'}>No Slots found</p>
            </div>
          </div>
        )}
      </Slider>
    </div>
  );
};

const AppointmentCalender = props => {
  const [data, setData] = useState(props.data);
  // const [currentTime, setCurrentTime] = useState(''); // new Date()
  // const [type, setType] = useState(1);

  const dispatch = useDispatch();

  const today = new Date();
  const todayFormatted = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
  );

  //   useEffect(() => {
  //     const tempData = props.data;
  //     // const newDate = new Date();
  //     setData(tempData);
  //     // setCurrentTime(canadaTime); // newDate
  //   }, []);

  useEffect(() => {
    const tempData = props.data;
    // const newDate = new Date();
    setData(tempData);
    // setCurrentTime(canadaTime); // newDate
    // updateCurrentTime();
  }, [props.data]);

  const changeDate = value => {

    // danoiyal
    if (props.appointmentSubType && props.appointmentSubType.length > 0) {
      const tempData = {
        ...data,
        date: value,
        time: '',
      };
      dispatch(getApointCurrentDate(new Date(value)));
      props.updateData(tempData);
      props.setHideSlotsInMobileView(true);
    }
  };

  const updateTime = value => {
    const tempData = {
      ...data,
      time: value,
    };
    props.updateData(tempData);
  };
  const removeData = value => {
    $('body').removeClass('over_flow_hidden');
    const tempData = {
      ...data,
      date: value,
    };
    props.updateData(tempData);
  };

  // const onChange = e => {
  //   setData({
  //     ...data,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const updateCurrentTime = () => {
  //   const now = new Date();
  //   const options = {
  //     timeZone: 'Canada/Eastern',
  //     hour12: false,
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     //   second: 'numeric',
  //   };
  //   const canadaTime = now.toLocaleString('en-US', options);
  //   setCurrentTime(canadaTime);
  // };

  // const updateCurrentTime = () => {
  //   const now = new Date();
  //   const options = {
  //     timeZone: 'Canada/Eastern',
  //     hour12: true,
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     // timeZoneName: 'short',
  //   };
  //   const canadaTime = now.toLocaleString('en-US', options);
  //   setCurrentTime(canadaTime);
  // };

  const formatShortWeekday = (locale, date) => {
    return date.toLocaleDateString(locale, {weekday: 'short'}).charAt(0);
  };

  const tileClassName = ({date}) => {
    if (
      props.avaiabaleDates?.length > 0 &&
      props.avaiabaleDates.includes(date.toISOString().split('T')[0])
    ) {
      return 'highlighted'; // Apply custom class for highlighted dates
    }
    return null; // Return null for non-highlighted dates
  };

  const isMobileView = window.innerWidth <= 375; // Adjust this value as per your mobile breakpoint

  const customFormatMonthYear = (locale, date) => {
    if (isMobileView) {
      const month = date.toLocaleString(locale, {month: 'short'});
      const year = date.getFullYear();
      return `${month} ${year}`;
    } else {
      return date.toLocaleString(locale, {month: 'long', year: 'numeric'});
    }
  };

  return (
    <div className="calender-wrapper">
      <div className="profile-calendar-main">
        <Row>
          <Col className="calendar_column appointment_cal_holder">
            <div className="calender-wrapper profile-calendar">
              <Calendar
                calendarType={'US'}
                formatMonthYear={customFormatMonthYear}
                onChange={changeDate}
                value={data.date}
                formatShortWeekday={formatShortWeekday}
                tileClassName={tileClassName}
                minDate={todayFormatted}
              />
            </div>
          </Col>
          {data.date !== '' && props.hideSlotsInMobileView && (
            <Col md={4} sm={4} xs={12} className="timing_cloumn">
              <div className="timing-wrapper">
                {props.windowWidth < 768 && (
                  <div
                    className="remove-time"
                    onClick={() => {
                      removeData('');
                      props.setHideSlotsInMobileView(false);
                    }}>
                    <i className="fas fa-times" />
                  </div>
                )}
                <div className="selected-date">
                  {`${weekDay[data.date.getDay()]}, ${
                    monthsText[data.date.getMonth()]
                  } ${data.date.getDate()}`}
                  {data.time !== '' && <span>{data.time}</span>}
                </div>

                <TimingSlider
                  data={data}
                  updateTime={time => updateTime(time)}
                  removeTime={() => updateTime('')}
                  confirmTime={() => props.showProfileField()}
                  setShowLogin={e => props.setShowLogin(e)}
                  setHideSlotsInMobileView={props.setHideSlotsInMobileView}
                  setShowFormP={props.setShowForm}
                  setPayLoadIsP={props.setPayLoadIs}
                  setShowModelP={props.setShowModel}
                  setCardDeatilP={props.setCardDeatil}
                  appointmentDetailP={props.appointmentDetail}
                  cardDeatilP={props.cardDeatil}
                  appointmentDisableP={props.appointmentDisable}
                  windowWidth={props.windowWidth}
                />
              </div>
            </Col>
          )}
        </Row>
        {/* <span className="current-time">
        Eastern Time - US & Canada (
        {currentTime})
      </span> */}
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(AppointmentCalender);
