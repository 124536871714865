export const deepLinking = (state = 1, action) => {
  switch (action.type) {
    case 'DEEPLINK':
      return state + 1;
    default:
      return state;
  }
};

export const notification = (state = {type: ''}, action) => {
  switch (action.type) {
    case 'NOTIFICATION':
      return action.payload;
    default:
      return state;
  }
};

export const serverTime = (state = new Date(), action) => {
  switch (action.type) {
    case 'SERVER_TIME':
      return action.payload;
    default:
      return state;
  }
};

export const currentUserData = (state = {}, action) => {
  switch (action.type) {
    case 'GET_CURRENT_USER':
      return action.payload;
    default:
      return state;
  }
};

export const userData = (state = {}, action) => {
  switch (action.type) {
    case 'GET_USER_DATA':
      return action.payload;
    default:
      return state;
  }
}; 
// export const userCompanyData = (state = {}, action) => {
//   switch (action.type) {
//     case 'GET_USER_COMPANY_DATA':
//       return action.payload;
//     default:
//       return state;
//   }
// };

export const userCompanyData = (
  state = {companyDetail: {}, userDetail: {}},
  action,
) => {
  switch (action.type) {
    case 'GET_USER_COMPANY_DATA':
      return {
        companyDetail: action.payload.company_detail ? action.payload.company_detail : {},
        userDetail: action.payload.user_detail ? action.payload.user_detail : {},
      };
    default:
      return state;
  }
};

export const companyUrl = (state = {}, action) => {
  switch (action.type) {
    case 'GET_URL_DATA':
      return action.payload;
    default:
      return state;
  }
};

export const newFavourite = (state = {count: 0, id: 0}, action) => {
  switch (action.type) {
    case 'FAVOURITE_CHANGE':
      return {count: state.count + 1, id: action.payload};
    default:
      return state;
  }
};


export const favouriteSession = (state = {id: 0}, action) => {
  switch (action.type) {
    case 'FAVOURITE_CHANGE':
      return { id: action.payload};
    default:
      return state;
  }
};

export const favouriteRecruitment = (state = {id: 0}, action) => {
  switch (action.type) {
    case 'FAVOURITE_RECRUITMENT_CHANGE':
      return { id: action.payload};
    default:
      return state;
  }
};


export const newFavouriteRecruitment = (state = {id: 0}, action) => {
  switch (action.type) {
    case 'FAVOURITE_CHANGE':
      return { id: action.payload};
    default:
      return state;
  }
};