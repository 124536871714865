import React, {useState, useRef, useEffect} from 'react';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from 'react-image-crop';
import {useDebounceEffect} from './useDebounceEffect';
import {canvasPreview} from './canvasPreview';
import commonApi from '../../Services/CommonService';

import {Button} from 'react-bootstrap';
import LoaderSpinner from '../Modals/LoaderSpinner';
import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const CropImage = props => {
  // const [sliderImage, setSliderImage] = useState([]);
  // const [showLoader, setshowLoader] = useState(false);
  //   const [imgUrl, setImgUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(props.ratio);
  const [showLoader, setShowLoader] = useState(false);

  const [crope, setCrope] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 10,
    y: 10,
    width: 80,
    height: 80,
  });

  const handleImg = () => {
    const canvas = previewCanvasRef.current;
    const base64Data = canvas.toDataURL('image/jpeg');

    if (props.type === 'profile') {
      uploadProfilePicture(base64Data);
    } else if (props.type === 'snapshot') {
      uploadAccomplishmentImage(base64Data);
    } else if (props.type === 'group') {
      uploadGroupPicture(base64Data);
    } else if (props.type === 'action') {
      uploadActionImage(base64Data);
    }  else if (props.type === 'tags') {
      uploadTagsImage(base64Data);
    }  else if (props.type === 'company') {
      uploadCompanyImg(base64Data);
    } else if (props.type === 'businessProfile') {
      uploadBanner(base64Data);
    } else if (props.type === 'transformImages') {
      upload_transformation_images(base64Data);
    } else if (props.type === 'templateCatalog') {
      uploadCatalogImages(base64Data);
    } else if (props.type === 'notes') {
      uploadNotesImages(base64Data);
    } else {
      uploadMedia(base64Data);
    }
  };

  const upload_transformation_images = img => {
    // props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_transformation_images(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          if (props.tabType === 'Before') {
            props.setBeforeImages(prevState => [res.image, ...prevState]);
          } else {
            props.setAfterImages(prevState => [res.image, ...prevState]);
          }
        }
        // setTimeout(() => {
        //   props.setshowLoader(false);
        // }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const uploadCompanyImg = img => {
    props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_company_image(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          props.setImgUrl(res.image);
        }
        setTimeout(() => {
          props.setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const uploadCatalogImages = img => {
    props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_catalog_images(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          props.setImgUrl(res.image);
        }
        setTimeout(() => {
          props.setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const uploadNotesImages = img => {
    props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'media',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );
    bodyFormData.append('type', 'image');

    commonApi
      .upload_note_media(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          props.setImgUrl(res.file_url);
        }
        setTimeout(() => {
          props.setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const uploadBanner = img => {
    props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_multiple_profile_image(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          props.setImgUrl(res.image);
          setTimeout(() => {
            if (props?.component && props?.component === 'branding') {
              if (props.banner) {
                props?.updateBusinessProfile('', '', '', res.image);
              } else {
                props?.updateBusinessProfile(res.image, '', '', '');
              }
            } else {
              props?.updateBusinessProfile('img', res.image);
            }
          }, 500);
        }

        setTimeout(() => {
          props.setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const uploadTagsImage = img => {


    console.log(img,'imgimgimg')
    props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    console.log(bodyFormData,'bodyFormDatabodyFormDatabodyFormData')

    commonApi
      .upload_company_tag_icon(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          props.setImgUrl([res.image]);
          props.setSliderImage([res.image]);
        }
        setTimeout(() => {
          props.setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };


  const uploadActionImage = img => {
    props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'action_images',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_action_image(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          if(props.component && props.component === 'actiondetail'){
            props.setImgUrl([...props.imgUrl, res.action_image_path]);
            props.setSliderImage([...props.sliderImage, res.action_image_path]);
          }else{
            props.setImgUrl([res.action_image_path]);
            props.setSliderImage([res.action_image_path]);
          }
          
         
        }
        setTimeout(() => {
          props.setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const uploadActionImage = img => {
  //   props.setshowLoader(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'images',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .add_images_in_action(bodyFormData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         props.setImgUrl([...props.imgUrl,res.image]);
  //       }
  //       setTimeout(() => {
  //         props.setshowLoader(false);
  //       }, 1000);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const uploadAccomplishmentImage = img => {
    props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'images',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_accomplishment_image(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          props.setImgUrl(res.image);
        }
        setTimeout(() => {
          props.setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const uploadGroupPicture = img => {
    // setshowLoader(true);
    props.setshowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_group_picture(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          props.setImgUrl(res.image);
          props.setImageCreate(res.image);
          setTimeout(() => {
            // setshowLoader(false);
            props.setshowLoader(false);
          }, 2000);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const uploadMedia = img => {
    var bodyFormData = new FormData();

    if (props.type === 'event') {
      bodyFormData.append(
        'slider_images',
        img.replace(/^data:image\/[a-z]+;base64,/, ''),
      );
    } else {
      bodyFormData.append(
        'moment_images',
        img.replace(/^data:image\/[a-z]+;base64,/, ''),
      );
    }

    props.setshowLoader(true);
    commonApi
      .upload_event_media(bodyFormData)
      .then(res => {
        // console.log('fffffffffff', res);
        if (res.status === 200) {
          //
          if (props.type === 'event') {
            props.setImgUrl([res.slider_image_path]);
            props.setSliderImage([res.slider_image_path]);
          } else {
            props.addMomentMedia(res.moment_image_path);
          }
        }
        setTimeout(() => {
          props.setshowLoader(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const uploadProfilePicture = img => {
    if(props.component !=='registerUser'){
      props.setshowLoader(true);
    }
    
    var bodyFormData = new FormData();
    bodyFormData.append(
      'picture',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    if(props.component ==='registerUser'){
      props.setImgUrl(img)
      return
    }

    commonApi
      .upload_profile_picture(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          if (props.component === 'profileComponent') {
            props.getUserData();
          }
          props.setImgUrl(res.user.profile_img);
          localStorage.setItem('profilePic', res.user.profile_img);
        }
        props.setshowLoader(false);
        props?.setProfileImgScrollBar(res.user.profile_img);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.

        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop],
  );

  useEffect(() => {
    setShowLoader(true);

    setCrop(undefined); // Makes crop preview update between images.
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setImgSrc(reader.result?.toString() || ''),
    );
    reader.readAsDataURL(props.file);

    setTimeout(() => {
      setShowLoader(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onImageLoad(e) {
    const {width, height} = e.currentTarget;
    if (aspect) {
      const {width, height} = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    } else {
      setCompletedCrop(convertToPixelCrop(crope, width, height));
    }
  }

  return (
    <>
      {/* <div className="ticket-img-wrapper mb-3">
        <input
          type="file"
          accept="image/*"
          onClick={e => setCropImg(true)}
        />
        {props.imgUrl && <img src={props.imgUrl} alt="" />}
        <i className="icon-camera" />
      </div> */}

      <div className="new-session-modal-wrapper CropImageModal">
        {showLoader && <LoaderSpinner />}

        <div className="new-session-modal">
          {props.type !== 'group' && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={aspect}
              // minWidth={400}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}

          {props.type === 'group' && (
            <ReactCrop
              crop={crope}
              onChange={(_, percentCrop) => setCrope(percentCrop)}
              onComplete={c => setCompletedCrop(c)}>
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
              setAspect={setAspect}
              setRotate={setRotate}
              setScale={setScale}
            </ReactCrop>
          )}

          {completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    display: 'none',
                  }}
                />
              </div>
            </>
          )}
          <Button
            className="btn-dark mb-0 custom-crop-modal-btn"
            onClick={() => {
              handleImg();
              props.setCropImg('');
              setImgSrc();
              props.setFile();

              // props.setImgUrl();
              // props.setSliderImage([])
            }}>
            Crop Image
          </Button>
        </div>
      </div>
    </>
  );
};

export default CropImage;
