import React, {useState, useEffect} from 'react';
import {Button,  Form} from 'react-bootstrap';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import commonApi from '../../Services/CommonService';
// import LoaderModal from '../../Components/Modals/LoaderModal';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {getLatLng, geocodeByPlaceId} from 'react-google-places-autocomplete';
import { successMessage} from '../../Utils/ToastMessages';
import CircleNotifications from '../../Components/Preferences/CirlceNotifications';
import SessionFocus from './StaticPages/SessionFocus';
import {generalArray} from '../../Constants/PushNotification';
import $ from 'jquery';
import {
  CustomSlidingTabs,
  CustomTabs,
  SlidingPanel,
} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';
import PrefListItems from '../../Components/Preferences/PrefListItems';
import GenderListItems from '../../Components/Preferences/GenderListItems';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {connect} from 'react-redux';
const Preferences = props => {
  const [subTabType, setSubTabsType] = useState('Recruitment');
  const [tabType, setTabType] = useState(labels.aboutme[selected_lang]);
  const [tabButton, setTabButton] = useState('Experience');
  const [boarding, setBoarding] = useState('');
  const [catgories, setCatgories] = useState('');
  const [gender, setGender] = useState('');
  const [identifer, setIdentifer] = useState('');
  const [tone, setTone] = useState('');
  const [option, setOption] = useState('');
  // const [showLoader, setshowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPreSpinner, setShowPreSpinner] = useState(true);
  const [frequency, setFrequency] = useState('');
  const [opportunityType, setOpportunityType] = useState([]);
  const [sessionCategories, setSessionCategories] = useState([]);
  const [notificationButtuns, setnotificationButtuns] = useState('general');
  // const [OpportunityTypeSub, setOpportunityTypeSub] = useState([]);
  // const [OpportunitySubTypeId, setOpportunitySubTypeId] = useState([]);
  const [apiResponse, setApiResponse] = useState({});
  const [pageValue, setPageValue] = useState(false);
  const [locationShow, setLocationShow] = useState('');
  const [showComponent, setShowComponent] = useState('');
  

  // const [pushNotifications, setPushNotifications] = useState({
  //   e_Change: false,
  //   e_FavouriteTopicStart: false,
  //   e_FollowingRSVP: false,
  //   e_MomentAchievement: false,
  //   e_MomentAdd: false,
  //   e_Referral: false,
  //   e_Reminder: false,
  //   e_UserAdded: false,
  //   e_UserAddedRSVP: false,
  //   e_circle_ids: '',
  //   gen_AddCaution: false,
  //   gen_AddPriase: false,
  //   gen_CallReminder: false,
  //   gen_FollowingAchivement: false,
  //   gen_PeopleFollowTravel: false,
  //   gen_ScheduleCall: false,
  //   gen_SendMeRefferal: false,
  //   gen_circle_ids: '',
  //   o_BestMatch: false,
  //   o_Call: false,
  //   o_Decision: false,
  //   o_Message: false,
  //   o_Referral: false,
  //   o_Save: false,
  //   o_Session: false,
  //   o_circle_ids: '',
  //   s_Change: false,
  //   s_FavouriteTopicStart: false,
  //   s_FollowingStartSession: false,
  //   s_Invite: false,
  //   s_Link: false,
  //   s_RSVP: false,
  //   s_Referral: false,
  //   s_Reminder: false,
  //   s_UserAdded: false,
  //   s_circle_ids: '',
  // });

  const [location, setLocation] = useState('');
  const [vaccination, setVaccination] = useState(false);
  const [genderId, setGenderId] = useState('');
  const [toneId, setToneId] = useState('');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  // const [eventId, setEventId] = useState([]);
  // const [buttonDiscription, setButtonDiscription] = useState(false);
  const [catgoriesId, setCatgoriesId] = useState([]);
  const [mineCatgoriesId, setMineCatgoriesId] = useState([]);
  const [identiferId, setIdentiferId] = useState([]);
  const [optionalId, setOptionalId] = useState([]);
  const [frequencyId, setFrequencyId] = useState([]);
  const [isCheckButton, setIsCheckButton] = useState([]);
  const [isButton, setisButton] = useState([]);
  const [opportunity, setOpportunity] = useState([]);
  // const [ids, setId] = useState([]);
  // const [oppNotificationId, setOppNotificationId] = useState([]);

  // const [sessionId, setSessionId] = useState([]);
  // const [show, setShow] = useState(false);
  // const [subOppId, setSubOppId] = useState([]);
  // const [idModel, setIdModel] = useState('');
  // const [oppId, setOppId] = useState('');
  const [array, setArray] = useState([]);
  const [focus, setFocus] = useState(false);

  const [notiArray, setNotiArray] = useState(generalArray);
  const [sessionNoti, setSessionNoti] = useState(generalArray.slice(7, 14));
  const [eventNoti, setEventNoti] = useState(generalArray.slice(14, 22));
  const [oppNoti, setOppNoti] = useState(generalArray.slice(22, 28));
  const [latitude, setLatitude] = useState('');
  const [magnitude, setMagnitude] = useState('');


  // const handleClose = subId => {
  //   setShow(false);
  //   if (subId) {
  //     const xyzArr = array;
  //     const lastIndex = array.length - 1;
  //     xyzArr[lastIndex]['sub_opportunity_ids'] = subId;

  //     setArray(xyzArr);
  //   }
  // };
 
  // const handleShow = () => setShow(true);

  // data of notification from response
  // let xyz = response.data;
  // d
  // setAllNotifications(response.data)
  
  // const decision = () => {
  //   buttonDiscription === true
  //     ? setButtonDiscription(false)
  //     : setButtonDiscription(true);
  // };

  // const othersButton = id => {
  //   let tempList = [...isButton];

  //   if (tempList.includes(id)) {
  //     tempList = tempList.filter(item => item !== id);
  //   } else {
  //     tempList.push(id);
  //   }

  //   setisButton(tempList);
  // };

  // const tryTo =(e)=>{
  //   setSubOppId(e.target.value);
  //   console.log("huva set ya ni",)
  // }

  // useEffect(() => {}, [isCheckButton, isButton]);
 

  
  // const eventNotification = e => {
  //   const {id, checked} = e.target;
  //   setPushNotifications({
  //     ...pushNotifications,
  //   });
  //   setEventId([...eventId, id]);

  //   if (!checked) {
  //     setEventId(eventId.filter(item => item !== id));
  //   }
  // };

 
  // const sessionNotification = e => {
  //   const {id, checked} = e.target;
  //   setPushNotifications({
  //     ...pushNotifications,
  //   });
  //   setSessionId([...sessionId, id]);

  //   if (!checked) {
  //     setSessionId(sessionId.filter(item => item !== id));
  //   }
  // };
 
  const generalNotifications = id => {
    let tempRes = {...apiResponse};

    

    if (tempRes[id] === true || tempRes[id] === 'true') {
      tempRes[id] = false;
    } else {
      tempRes[id] = true;
    }

    setApiResponse(tempRes);

    // const {id, checked} = e.target;
    // if (!checked) {
    //   setApiResponse({
    //     ...apiResponse,
    //     [id]: false,
    //   });
    // } else {
    //   setApiResponse({
    //     ...apiResponse,
    //     [id]: true,
    //   });
    // }
  };
  // const handleNotificationSearch = (array, query) => {
  //   set(array.filter((el) => {
  //     return el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 || el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  //   }));
  // }

  const handle = () => {
    // setshowLoader(true);
    setShowSpinner(true);
    // createOnBoarding();
    manageNotification();
    // getBoarding();
    amplitudeEvent('UPDATE_PREFERENCES');
    successMessage('You have set your preferences successfully');
    var place_id = !location ? '' : location.value.place_id;

    if (place_id) {
      geocodeByPlaceId(place_id)
        .then(results => getLatLng(results[0]))
        .then(({lat, lng}) => {
          // 
          createOnBoarding(lat, lng);
        });
    } else {
      createOnBoarding('', '');
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list?.map(item => item.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  useEffect(() => {
    if (location !== '') {
      selectedLocationbug();
    }

    // $(". css-1wa3eu0-placeholder").css("color", "black");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const frequencyIdvalue = id => {
    let tempList = [...frequencyId];

    if (tempList.includes(id)) {
      tempList = tempList.filter(item => item !== id);
    } else {
      tempList.push(id);
    }
    setFrequencyId(tempList);
  };

  const opportunityvalue = (oppoId, oppoSubId) => {
    
    
    
    if (oppoSubId) {
      setArray(current => {
        return current.map(obj => {
          if (obj.opportunity_id === oppoId) {
            return {
              ...obj,
              sub_opportunity_ids: oppoSubId,
            };
          }
          return obj;
        });
      });
    } else {
      const found = array.find(objs => objs.opportunity_id === oppoId);
      if (typeof found === 'object') {
        setArray(prev => prev.filter(item => item.opportunity_id !== oppoId));
        setOpportunity(prev => prev.filter(item => item !== oppoId));
      } else {
        setArray([
          ...array,
          {opportunity_id: oppoId, sub_opportunity_ids: oppoSubId},
        ]);
        setOpportunity([...opportunity, oppoId]);
      }
    }
  };
  // const opportunityvalue = (e, item) => {
  //   const {id, checked} = e.target;
  //   console.log(checked, 'wwwwwwwwwwww', id);

  //   setOpportunity([...opportunity, id]);
  //   setIdModel(item.id);
  //   setOpportunityTypeSub(item.sub_oppo_type);
  //   setArray([...array, {opportunity_id: id, sub_opportunity_ids: ''}]);

  //   setOppId('');
  //   // setSubOppId([]);

  //   if (checked) {
  //     const found = array.find(objs => objs.opportunity_id === id);
  //     if (typeof found === 'object') {
  //       const idToRemove = found.sub_opportunity_ids;
  //       setSubOppId(subOppId.filter(item => item != idToRemove));
  //     }
  //     setOpportunity(opportunity.filter(item => item !== id));
  //     setArray(array.filter(item => item.opportunity_id !== id));
  //   }
  //   if (['1', '2', '5', 1, 2, 5].includes(id) && !checked) {
  //     handleShow();
  //   }
  // };
  // 
  // 
  // 

  const handleClick = id => {
    let tempList = [...isCheck];

    if (tempList.includes(id)) {
      tempList = tempList.filter(item => item !== id);
    } else {
      tempList.push(id);
    }
    setIsCheck(tempList);
  };

  useEffect(() => {
    if (list.length > 0) {
      if (isCheck.length === list.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const createOnBoarding = (lat, lung) => {
    // var adress = location ? location.label : "";
    // var creatPaylaod = {
    //   accredited: "no",
    //   creation_category_tag_ids: [],
    //   gender_id: genderId,
    //   identifier_ids: identiferId,
    //   latitude: lat,
    //   location: adress,
    //   longitude: lung,
    //   mine_areas: isCheckButton,
    //   mine_categories: mineCatgoriesId,
    //   opportunity_amount_type_value: [],
    //   opportunity_frequency_ids: frequencyId,
    //   opportunity_type_ids: array,
    //   option_id: optionalId,

    //   other_areas: isButton,
    //   other_categories: catgoriesId,
    //   session_categories: isCheck,
    //   shop_category_tag_ids: [],
    //   tone_id: toneId,
    //   vaccination: vaccination ? "yes" : "no",
    // };
    // setPageValue(true);

    var adress = location ? location.label : '';
    var creatPaylaod = {
      accredited: 'no',
      creation_category_tag_ids: [],
      gender_id: genderId.toString(),
      identifier_ids: identiferId,
      latitude: lat === '' ? latitude : lat,
      location: adress === '' ? locationShow : adress,
      longitude: lung === '' ? magnitude : lung,
      mine_areas: isCheckButton,
      mine_categories: mineCatgoriesId,
      opportunity_amount_type_value: [],
      opportunity_frequency_ids: frequencyId,
      opportunity_type_ids: array,
      option_id: optionalId,

      other_areas: isButton,
      other_categories: catgoriesId,
      session_categories: isCheck,
      shop_category_tag_ids: [],
      tone_id: toneId.toString(),
      vaccination: vaccination ? 'yes' : 'no',
    };
    
    commonApi
      .create_on_boarding_details(creatPaylaod)
      .then(res => {
        // setshowLoader(false);
        setShowSpinner(false);
        if (res.status === 200) {
          setPageValue(true);
          // localStorage["skinToneId"] = toneId.toString();
          localStorage.setItem('skinToneId', toneId.toString());

          // resetproblemStates()
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const manageNotification = () => {
    var notificationsPayload = {
      e_Change: apiResponse.e_Change,
      e_FavouriteTopicStart: apiResponse.e_FavouriteTopicStart,
      e_FollowingRSVP: apiResponse.e_FollowingRSVP,
      e_MomentAchievement: apiResponse.e_MomentAchievement,
      e_MomentAdd: apiResponse.e_MomentAdd,
      e_Referral: apiResponse.e_Referral,
      e_Reminder: apiResponse.e_Reminder,
      e_UserAdded: apiResponse.e_UserAdded,
      e_UserAddedRSVP: apiResponse.e_UserAddedRSVP,

      gen_AddCaution: apiResponse.gen_AddCaution,
      gen_AddPriase: apiResponse.gen_AddPriase,
      gen_CallReminder: apiResponse.gen_CallReminder,
      gen_FollowingAchivement: apiResponse.gen_FollowingAchivement,
      gen_PeopleFollowTravel: apiResponse.gen_PeopleFollowTravel,
      gen_ScheduleCall: apiResponse.gen_ScheduleCall,
      gen_SendMeRefferal: apiResponse.gen_SendMeRefferal,

      o_BestMatch: apiResponse.o_BestMatch,
      o_Call: apiResponse.o_Call,
      o_Decision: apiResponse.o_Decision,
      o_Message: apiResponse.o_Message,
      o_Referral: apiResponse.o_Referral,
      o_Save: apiResponse.o_Save,
      o_Session: apiResponse.o_Session,

      s_Change: apiResponse.s_Change,
      s_FavouriteTopicStart: apiResponse.s_FavouriteTopicStart,
      s_FollowingStartSession: apiResponse.s_FollowingStartSession,
      s_Invite: apiResponse.s_Invite,
      s_Link: apiResponse.s_Link,
      s_RSVP: apiResponse.s_RSVP,
      s_Referral: apiResponse.s_Referral,
      s_Reminder: apiResponse.s_Reminder,
      s_UserAdded: apiResponse.s_UserAdded,
    };
    commonApi
      .manage_push_notification(notificationsPayload)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getNotification = () => {
    commonApi
      .get_push_notification()
      .then(res => {
        if (res.status === 200) {
          // setPushNotifications(res.data);
          setApiResponse(res.data);
        
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const makeReact = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };

  // const subOppType = arr => {
  //   let oppoTypeSubArr = [];
  //   let returnArr = [];
  //   arr.map(item => {
  //     if (item['selected'] === true || item['selected'] === 'true') {
  //       if (item.sub_oppo_type.length > 0) {
  //         item.sub_oppo_type.map(miniItem => {
  //           if (
  //             miniItem['selected'] === true ||
  //             miniItem['selected'] === 'true'
  //           ) {
  //             oppoTypeSubArr.push({
  //               opportunity_id: item.id,
  //               sub_opportunity_ids: miniItem.id,
  //             });
  //             returnArr.push(miniItem['id']);
  //           }
  //         });
  //       } else {
  //         oppoTypeSubArr.push({
  //           opportunity_id: item.id,
  //           sub_opportunity_ids: '',
  //         });
  //       }
  //     }
  //   });

  //   setArray(oppoTypeSubArr);
  //   return returnArr;
  // };

  // const subOppType = arr => {
  //   let oppoTypeSubArr = [];
  //   let returnArr = [];
  //   arr.map(item => {
  //     if (item['selected'] === true || item['selected'] === 'true') {
  //       if (item.sub_oppo_type.length > 0) {
  //         let flag = true;
  //         item.sub_oppo_type.map(miniItem => {
  //           if (
  //             miniItem['selected'] === true ||
  //             miniItem['selected'] === 'true'
  //           ) {
  //             flag = false;
  //             oppoTypeSubArr.push({
  //               opportunity_id: item.id,
  //               sub_opportunity_ids: miniItem.id,
  //             });
  //             returnArr.push(miniItem['id']);
  //           }
  //         });
  //         if (flag) {
  //           oppoTypeSubArr.push({
  //             opportunity_id: item.id,
  //             sub_opportunity_ids: '',
  //           });
  //         }
  //       } else {
  //         oppoTypeSubArr.push({
  //           opportunity_id: item.id,
  //           sub_opportunity_ids: '',
  //         });
  //       }
  //     }
  //   });
    

  //   setArray(oppoTypeSubArr);
  //   return returnArr;
  // };


  const idnValue = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };

  // const makeArray = (arr, idColName, selectColName) => {
  //   const returnArr = [];
  //   arr.map(item => {
  //     if (item[selectColName] === true || item[selectColName] === 'true') {
  //       returnArr.push(item[idColName]);
  //     }
  //   });
  //   return returnArr;
  // };


  const toneSelect = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };
  const genderVI = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };

  const clearAll = () => {
    // input.value = "";
    // google.maps.event.removeListener(autocompleteListener);
    // initAutocomplete();
    setLocationShow('');
    setMagnitude('');
    setLatitude('');
    setLocation('');
    
    $('.css-1uccc91-singleValue').html('Type city and state');
    //$("#googlecomplete").html("Hello <b>world</b>!");
  };

  const getBoarding = () => {
    commonApi
      .get_on_boarding_details()
      .then(res => {
        setBoarding(res.boarding_areas);
        setCatgories(res.boarding_catgories);
        setGender(res.gender);
        setIdentifer(res.identifier);
        setTone(res.tones);
        setOption(res.options);
        setFrequency(res.opportunity_frequency);
        setOpportunityType(res.opportunity_types);
        // setOpportunityTypeSub(res.opportunity_types.sub_oppo_type);
        setSessionCategories(res.session_categories);
        setList(res.session_categories);
        setShowPreSpinner(false);
        if (res.status === 200) {
          // alert("222222222222")
          //console.log("daniyao",res);

          // setArray(arrayHeavyFunction(res.opportunity_types));
          // subOppType(res.opportunity_types); // neeed to uncomment

          setLocationShow(res.user_opportunity_location.location);

          setMagnitude(res.user_opportunity_location.longitude);
          setLatitude(res.user_opportunity_location.latitude);
          // setLocation(res.user_opportunity_location.location);
          setIdentiferId(idnValue(res.identifier, 'id', 'selected'));
          setIsCheckButton(res.boarding_areas_mine);
          setMineCatgoriesId(res.boarding_categories_mine);
          setOptionalId(makeReact(res.options, 'id', 'selected'));
          setIsCheck(makeReact(res.session_categories, 'id', 'selected'));
          setGenderId(genderVI(res.gender, 'id', 'selected'));
          setisButton(res.boarding_areas_other);
          setCatgoriesId(res.boarding_categories_other);
          setOpportunity(makeReact(res.opportunity_types, 'id', 'selected'));
          setFrequencyId(
            makeReact(res.opportunity_frequency, 'id', 'selected'),
          );
          // setSubOppId(subOppType(res.opportunity_types));
          setVaccination(res.user_opportunity_location.vaccination === 'yes' ? true : false);
          // setOpportunity(makeArray(res.opportunity_types, "id", "selected"));
          setToneId(toneSelect(res.tones, 'id', 'selected'));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    // setShowPreSpinner(true);
    getNotification();
    getBoarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (pageValue === true) {
      getNotification();
      getBoarding();
      setPageValue(false);
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValue]);
  useEffect(() => {
    if (opportunity.includes('2') || opportunity.includes('5')) {
    } else {
      setFrequencyId([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity]);

  const renderColorList = () => {
    return (
      <div className="color-list">
        <ul>
          {Object.entries(tone).length &&
            tone.map((item, index) => {
              // console.log(selectedColor, item.class);

              return (
                <li key={`color-item-${item.id}`}>
                  <span
                    className={
                      toneId.includes(item.id)
                        ? 'color-item active'
                        : 'color-item'
                    }
                    style={{backgroundColor: item.tone_code}}
                    onClick={() => {
                      setToneId(item.id);
                    }}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    );
  };
  const resetSearch = () => {
    setNotiArray(generalArray);
    setSessionNoti(generalArray.slice(7, 14));
    setEventNoti(generalArray.slice(14, 22));
    setOppNoti(generalArray.slice(22, 28));
  };

  const handleGeneralSearch = (array, query) => {
    setNotiArray(
      array.slice(0, 7).filter(el => {
        return (
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handlesessionsSearch = (array, query) => {
    setSessionNoti(
      array.filter(el => {
        return (
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleEventSearch = (array, query) => {
    setEventNoti(
      array.filter(el => {
        return (
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleOppSearch = (array, query) => {
    setOppNoti(
      array.filter(el => {
        return (
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };

  
  let jugar = location || locationShow ? locationShow : 'Type city and state';
  // setInterval($(".css-1wa3eu0-placeholder").css("color", "#212529"), 1000);
  const selectedLocationbug = () => {
    setLocationShow(location.label);
  };

  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      {/* {showPreSpinner && <LoaderSpinner />} */}
      {showComponent === '' && showPreSpinner === false && (
        <SlidingPanel hidePanel={() => props.onClose()} hideCloseicon={props.hidethings}>
          <div className="serach-filter-wrapper">
            <div className="sessions-content-wrapper d-flex">
              <div className="serach-filter-wrapper pt-0">
                <div
                  className={`sidebar-heading-wrapper fix-width-forms ${
                    props.windowWidth < 1300 ? 'with-panel' : ''
                  }`}>
                  <h2>Preferences</h2>
                  {/* <div className="search-tabs-icons">
                    <span
                      className="icon"
                      onClick={() => {
                        setShowComponent('focus');
                      }}>
                      <i className="icon-bullseye" />
                    </span>
                  </div> */}
                </div>

               

                <div className="fix-width-forms">
                  <div className="container">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="event-tabs tabs-style-2 mb-0">
                                      <ul>
                                        <li>
                                          <Button
                                            className={
                                              showComponent === 'Recruitment'
                                                ? 'active'
                                                : ''
                                            }
                                            onClick={() => {
                                              setSubTabsType('Recruitment')
                                            }}>
                                            
                                            Recruitment
                                          </Button>
                                        </li>
                                        <li>
                                          <Button
                                            className={
                                              showComponent === 'notifications'
                                                ? 'active'
                                                : ''
                                            }
                                            onClick={() => {
                                            
                                              setSubTabsType('notifications')
                                            }}>
                                            
                                            Notifications
                                          </Button>
                                        </li>
                                      </ul>
                                     
                                    </div>

                                   
                                  </div>
                    {/* <div className="event-tabs sliding-tabs mb-4">
                      <CustomSlidingTabs
                        items={[
                          // {key: 'background', label: 'Background'},
                          {
                            key: 'Recruitment',
                            label: 'Recruitment',
                          },
                          // {key: 'topics', label: 'Topics'},
                          {
                            key: 'notifications',
                            label: 'Notifications',
                          },
                        ]}
                        showItems={3}
                        activeTab={subTabType}
                        onItemClick={item => setSubTabsType(item)}
                      />
                    </div> */}
                  </div>
                </div>

                <div className="preferences-forms" style={{flex: 1, position: 'relative'}}>
                   {showSpinner && <LoaderSpinner />}
                  <Scrollbars
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}>
                    {!showPreSpinner && (
                      <div className="fix-width-forms">
                       

                        <div className="prefs-content-wrapper prefs-form-content-wrapper">
                          {subTabType === 'background' && (
                            <>
                              <div className="pref-tabs-wrapper">
                                <CustomTabs
                                  darkmode
                                  tabs={[
                                    labels.aboutme[selected_lang],
                                    labels.others[selected_lang],
                                  ]}
                                  active={tabType}
                                  onSelect={selected => setTabType(selected)}
                                />
                              </div>

                              {tabType === labels.aboutme[selected_lang] && (
                                <>
                                  <div className="event-tabs sliding-tabs">
                                    <CustomSlidingTabs
                                      items={[
                                        {
                                          key: 'Experience',
                                          label: 'Experience',
                                        },
                                        {
                                          key: 'Identifiers',
                                          label: 'Identifiers',
                                        },
                                        {
                                          key: 'Ethnicity',
                                          label: 'Ethnicity',
                                        },
                                        // {
                                        //   key: 'notifications',
                                        //   label: 'Notifications',
                                        // },
                                      ]}
                                      showItems={3}
                                      activeTab={tabButton}
                                      onItemClick={item => setTabButton(item)}
                                    />
                                  </div>

                                  {tabButton === 'Experience' && (
                                    <>
                                      <div className="prefs-inner-content-wrapper">
                                        <h2>
                                          Which of the following best describe
                                          you?
                                        </h2>

                                        <div className="prefs-inner-content-btns">
                                          <div className="checkbox-list">
                                            <PrefListItems
                                              items={boarding}
                                              active={isCheckButton}
                                              updateActive={newActive =>
                                                setIsCheckButton(newActive)
                                              }
                                              maxItem={2}
                                              showTooltip
                                            />
                                          </div>
                                        </div>

                                        <div className="checkbox-list">
                                          <h2>
                                            Which of the following best
                                            represents your background?
                                          </h2>

                                          <PrefListItems
                                            items={catgories}
                                            active={mineCatgoriesId}
                                            updateActive={newActive =>
                                              setMineCatgoriesId(newActive)
                                            }
                                            maxItem={5}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {tabButton === 'Identifiers' && (
                                    <>
                                      <div className="prefs-inner-content-wrapper">
                                        <h2>Gender</h2>

                                        <div className="prefs-inner-content-btns">
                                          <div className="checkbox-list">
                                            <GenderListItems
                                              items={gender}
                                              active={genderId}
                                              updateActive={newActive =>
                                                setGenderId(newActive)
                                              }
                                              maxItem={1}
                                            />
                                          </div>
                                        </div>

                                        <div className="checkbox-list mt-4">
                                          <h2>Other Identifiers</h2>

                                          <PrefListItems
                                            items={identifer}
                                            active={identiferId}
                                            updateActive={newActive =>
                                              setIdentiferId(newActive)
                                            }
                                          />
                                        </div>
                                        <div className="color-pallet">
                                          <div className="tabs-wrapper">
                                            <p>
                                              Which shade best represents your
                                              beautiful melanin? (This is for
                                              your emojis)
                                            </p>
                                            <div className="color-list-wrapper mt-3 text-left">
                                              {renderColorList()}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {tabButton === 'Ethnicity' && (
                                    <div className="prefs-inner-content-wrapper">
                                      <div className="checkbox-list">
                                        <h2>
                                          Which group do you identify with the
                                          most?
                                        </h2>

                                        <PrefListItems
                                          items={option}
                                          active={optionalId}
                                          updateActive={newActive =>
                                            setOptionalId(newActive)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {tabType === labels.others[selected_lang] && (
                                <div className="prefs-inner-content-wrapper mt-4">
                                  <h2>
                                    Which of builders are you looking for?
                                  </h2>

                                  <div className="prefs-inner-content-btns">
                                    <div className="checkbox-list">
                                      <PrefListItems
                                        items={boarding}
                                        active={isButton}
                                        updateActive={newActive =>
                                          setisButton(newActive)
                                        }
                                        showTooltip
                                      />
                                    </div>
                                  </div>

                                  <div className="checkbox-list">
                                    <h2>
                                      Which background best complement what
                                      you're building?
                                    </h2>

                                    <PrefListItems
                                      items={catgories}
                                      active={catgoriesId}
                                      updateActive={newActive =>
                                        setCatgoriesId(newActive)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          {subTabType === 'Recruitment' && (
                            <div className="prefs-inner-content-wrapper">
                              <div className="checkbox-list">
                                <ul>
                                  {Object.entries(opportunityType).length > 0 &&
                                    opportunityType.map((item, index) => {
                                      // const isActive = opportunity.includes(
                                      //   item.id,
                                      // )
                                      //   ? 'active'
                                      //   : '';
                                      const isActive =
                                        typeof array.find(
                                          objs =>
                                            objs.opportunity_id === item.id,
                                        ) === 'object'
                                          ? 'active'
                                          : '';
                                      return (
                                        <li
                                          className={isActive}
                                          key={`opportunityType-${index}`}
                                          onClick={e => {
                                            opportunityvalue(item.id, '');
                                          }}
                                          // id={item.id}
                                          // checked={opportunity.includes(
                                          //   item.id,
                                          // )}
                                          checked={
                                            typeof array.find(
                                              objs =>
                                                objs.opportunity_id === item.id,
                                            ) === 'object'
                                          }>
                                          {item.type_name}
                                          {item.sub_oppo_type.length > 0 && (
                                            <div className="checkbox-sub-wrapper">
                                              <ul>
                                                {item.sub_oppo_type.forEach(
                                                  miniItem => {
                                                    const isActiveSub =
                                                      array.find(
                                                        objs =>
                                                          objs.sub_opportunity_ids ===
                                                          miniItem.id,
                                                      )
                                                        ? 'active'
                                                        : '';
                                                    if (
                                                      array.find(
                                                        objs =>
                                                          objs.opportunity_id ===
                                                          item.id,
                                                      )
                                                    ) {
                                                      return (
                                                        <li
                                                          className={
                                                            isActiveSub
                                                          }
                                                          key={`${miniItem.title}-${index}`}
                                                          onClick={e => {
                                                            e.stopPropagation();
                                                            opportunityvalue(
                                                              item.id,
                                                              miniItem.id,
                                                            );
                                                          }}
                                                          checked={
                                                            array.find(
                                                              objs =>
                                                                objs.sub_opportunity_ids ===
                                                                miniItem.id,
                                                            )
                                                              ? true
                                                              : false
                                                          }>
                                                          {miniItem.title}

                                                          {array.find(
                                                            objs =>
                                                              objs.sub_opportunity_ids ===
                                                              miniItem.id,
                                                          ) && (
                                                            <span className="check-icon">
                                                              <i className="fas fa-check" />
                                                            </span>
                                                          )}
                                                        </li>
                                                      );
                                                    }
                                                  },
                                                )}
                                              </ul>
                                            </div>
                                          )}

                                          {isActive === 'active' && (
                                            <span className="check-icon">
                                              <i className="fas fa-check" />
                                            </span>
                                          )}
                                        </li>
                                      );
                                    })}
                                </ul>

                                <div className="location-box">
                                  <div className="location-heading">
                                    <h2>
                                      Location{' '}
                                      <i className="fas fa-location-arrow" />
                                    </h2>

                                    <div className="d-flex align-items-center">
                                      <Form.Check
                                        type="switch"
                                        id="location-virtually"
                                        label="Virtual Only"
                                        // value={locationShow !== "" || location !== ""  ? "yes" : "no"}
                                        // //onClick={() => setVirtual(!virtual)}
                                        defaultChecked={
                                          location === '' ? false : true
                                        }
                                      />
                                      {(location !== '' ||
                                        locationShow !== '') && (
                                        <span
                                          className="clear-btn"
                                          onClick={() => {
                                            clearAll();
                                          }}>
                                          Clear
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="location-field form-control">
                                    <GooglePlacesAutocomplete
                                      location
                                      selectProps={{
                                        location,
                                        onChange: setLocation,
                                        name: 'city',
                                        placeholder: jugar,
                                        id: 'googlecomplete',
                                      }}
                                    />
                                  </div>
                                </div>

                                <h2>Employment type</h2>

                                <div className="checkbox-list">
                                  <ul>
                                    {Object.entries(frequency).length > 0 &&
                                      frequency.map((item, index) => {
                                        const isActive = frequencyId.includes(
                                          item.id,
                                        )
                                          ? 'active'
                                          : '';
                                        const isDisable =
                                          opportunity.includes('2') ||
                                          opportunity.includes('5')
                                            ? false
                                            : true;
                                        return (
                                          <li
                                            onClick={() => {
                                              if (
                                                opportunity.includes('2') ||
                                                opportunity.includes('5')
                                              ) {
                                                frequencyIdvalue(item.id);
                                              }
                                            }}
                                            className={`${isActive} ${
                                              isDisable ? 'disable' : ''
                                            }`}
                                            key={`emp-type-${item.id}-${index}`}>
                                            {item.title}

                                            {isActive === 'active' && (
                                              <span className="check-icon">
                                                <i className="fas fa-check" />
                                              </span>
                                            )}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </div>

                              <div className="text-left as-link">
                                <Form.Check
                                  type="switch"
                                  id="vaccination"
                                  label="COVID-19 Vaccination Required"
                                  value={vaccination ? 'yes' : 'no'}
                                  // vaccination={vaccination === "yes" ? true : false}
                                  onClick={() => {setVaccination(!vaccination)}}
                                  defaultChecked={
                                    vaccination ? true : false
                                  }
                                />
                              </div>
                            </div>
                          )}

                          {subTabType === 'topics' && (
                            <div className="prefs-inner-content-wrapper">
                              <div className="checkbox-list">
                                <h2>
                                  Which topics or activities interest you the
                                  most?
                                </h2>
                                <Form
                                  className="mt-2"
                                  //onSubmit={handleSubmit}
                                >
                                  <ul>
                                    <li
                                      onClick={() => handleSelectAll()}
                                      className={isCheckAll ? 'active' : ''}>
                                      All
                                      {isCheckAll && (
                                        <span className="check-icon">
                                          <i className="fas fa-check" />
                                        </span>
                                      )}
                                    </li>

                                    {Object.entries(sessionCategories).length >
                                      0 &&
                                      sessionCategories.map((item, index) => {
                                        const isActive = isCheck.includes(
                                          item.id,
                                        )
                                          ? 'active'
                                          : '';
                                        return (
                                          <li
                                            className={isActive}
                                            key={`sessionCategories-${index}`}
                                            onClick={() =>
                                              handleClick(item.id)
                                            }>
                                            {item.ses_category_name}

                                            {isActive === 'active' && (
                                              <span className="check-icon">
                                                <i className="fas fa-check" />
                                              </span>
                                            )}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </Form>
                              </div>
                            </div>
                          )}

                          {subTabType === 'notifications' && (
                            <>
                              <div className="event-tabs sliding-tabs  preferences_custom_events_tabs_slider">
                                <CustomSlidingTabs
                                  items={[
                                    {key: 'general', label: 'General'},
                                    {
                                      key: 'sessions',
                                      label: 'Virtual',
                                    },
                                    {key: 'events', label: 'In-Person'},
                                    {
                                      key: 'oppor',
                                      label: 'Recruitment',
                                    },
                                   
                                  ]}
                                  showItems={3}
                                  activeTab={notificationButtuns}
                                  onItemClick={item => {
                                    setnotificationButtuns(item);
                                    resetSearch();
                                  }}
                                />
                              </div>

                              <div className="person-search-wrapper messages-users-wrapper">
                                <div className="search-form">
                                  {notificationButtuns === 'general' && (
                                    <div className="search-form d-flex">
                                      <Form.Group className="search-input">
                                        <Form.Control
                                          onChange={e => {
                                            handleGeneralSearch(
                                              generalArray,
                                              e.target.value,
                                            );
                                          }}
                                          type="text"
                                          placeholder="Search for notification"
                                        />
                                      </Form.Group>
                                      <Button>
                                        <i
                                          className="icon-circles"
                                          onClick={() => {
                                            setShowComponent('circleNoti');
                                          }}
                                        />
                                      </Button>
                                    </div>
                                  )}
                                  {notificationButtuns === 'sessions' && (
                                    <div className="search-form d-flex">
                                      <Form.Group className="search-input">
                                        <Form.Control
                                          onChange={e => {
                                            handlesessionsSearch(
                                              generalArray.slice(7, 14),
                                              e.target.value,
                                            );
                                          }}
                                          type="text"
                                          placeholder="Search for notification"
                                        />
                                      </Form.Group>
                                      <Button>
                                        <i
                                          className="icon-circles"
                                          onClick={() => {
                                            setShowComponent('circleNoti');
                                          }}
                                        />
                                      </Button>
                                    </div>
                                  )}
                                  {notificationButtuns === 'events' && (
                                    <div className="search-form d-flex">
                                      <Form.Group className="search-input">
                                        <Form.Control
                                          onChange={e => {
                                            handleEventSearch(
                                              generalArray.slice(14, 22),
                                              e.target.value,
                                            );
                                          }}
                                          type="text"
                                          placeholder="Search for notification"
                                        />
                                      </Form.Group>
                                      <Button>
                                        <i
                                          className="icon-circles"
                                          onClick={() => {
                                            setShowComponent('circleNoti');
                                          }}
                                        />
                                      </Button>
                                    </div>
                                  )}
                                  {notificationButtuns === 'oppor' && (
                                    <div className="search-form d-flex">
                                      <Form.Group className="search-input">
                                        <Form.Control
                                          onChange={e => {
                                            handleOppSearch(
                                              generalArray.slice(22, 28),
                                              e.target.value,
                                            );
                                          }}
                                          type="text"
                                          placeholder="Search for notification"
                                        />
                                      </Form.Group>
                                      <Button>
                                        <i
                                          className="icon-circles"
                                          onClick={() => {
                                            setShowComponent('circleNoti');
                                          }}
                                        />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {notificationButtuns === 'general' && (
                                <div className="prefs-inner-content-wrapper mt-4">
                                  <div className="filter-wrapper" id="1">
                                    <h2>
                                      Which topics or activities interest you
                                      the most?
                                    </h2>
                                    <div className="checkbox-list">
                                      <ul>
                                        <li>Notify me when...</li>
                                       
                                        {Object.entries(notiArray).length >
                                        0 ? (
                                          Object.entries(notiArray).length >
                                            0 &&
                                          notiArray
                                            .slice(0, 7)
                                            .map((item, index) => {
                                              const isActive =
                                                apiResponse[item.name] ===
                                                  true ||
                                                apiResponse[item.name] ===
                                                  'true'
                                                  ? 'active'
                                                  : '';
                                              return (
                                                <li
                                                  onClick={() =>
                                                    generalNotifications(
                                                      item.name,
                                                    )
                                                  }
                                                  key={'abcd' + item.id}
                                                  className={isActive}>
                                                  {item.description}

                                                  {isActive === 'active' && (
                                                    <span className="check-icon">
                                                      <i className="fas fa-check" />
                                                    </span>
                                                  )}
                                                </li>
                                              );
                                            })
                                        ) : (
                                          <p className="text-center mt-3">
                                            No records found
                                          </p>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {notificationButtuns === 'sessions' && (
                                <div className="prefs-inner-content-wrapper mt-4">
                                  <div className="filter-wrapper">
                                    <div className="checkbox-list">
                                      <Form
                                      //onSubmit={handleSubmit}
                                      >
                                        <ul>
                                          <li>Notify me when...</li>
                                          {Object.entries(sessionNoti).length >
                                          0 ? (
                                            Object.entries(sessionNoti).length >
                                              0 &&
                                            sessionNoti.map((item, index) => {
                                              const isActive =
                                                apiResponse[item.name] ===
                                                  true ||
                                                apiResponse[item.name] ===
                                                  'true'
                                                  ? 'active'
                                                  : '';
                                              return (
                                                <li
                                                  key={
                                                    'sessionsArray' + item.id
                                                  }
                                                  onClick={() =>
                                                    generalNotifications(
                                                      item.name,
                                                    )
                                                  }
                                                  className={isActive}>
                                                  {item.description}

                                                  {isActive === 'active' && (
                                                    <span className="check-icon">
                                                      <i className="fas fa-check" />
                                                    </span>
                                                  )}
                                                </li>
                                              );
                                            })
                                          ) : (
                                            <p className="text-center mt-3">
                                              No records found
                                            </p>
                                          )}
                                        </ul>
                                      </Form>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {notificationButtuns === 'events' && (
                                <div className="prefs-inner-content-wrapper mt-4">
                                  <div className="filter-wrapper">
                                    <div className="checkbox-list">
                                      <Form
                                      //onSubmit={handleSubmit}
                                      >
                                        <ul>
                                          <li>Notify me when...</li>
                                          {Object.entries(eventNoti).length >
                                          0 ? (
                                            Object.entries(eventNoti).length >
                                              0 &&
                                            eventNoti.map((item, index) => {
                                              const isActive =
                                                apiResponse[item.name] ===
                                                  true ||
                                                apiResponse[item.name] ===
                                                  'true'
                                                  ? 'active'
                                                  : '';
                                              return (
                                                <li
                                                  key={'eventArray' + item.id}
                                                  onClick={() =>
                                                    generalNotifications(
                                                      item.name,
                                                    )
                                                  }
                                                  className={isActive}>
                                                  {item.description}

                                                  {isActive === 'active' && (
                                                    <span className="check-icon">
                                                      <i className="fas fa-check" />
                                                    </span>
                                                  )}
                                                </li>
                                              );
                                            })
                                          ) : (
                                            <p className="text-center mt-3">
                                              No records found
                                            </p>
                                          )}
                                        </ul>
                                      </Form>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {notificationButtuns === 'oppor' && (
                                <div className="prefs-inner-content-wrapper mt-4">
                                  <div className="filter-wrapper">
                                    <div className="checkbox-list">
                                      <Form
                                      //onSubmit={handleSubmit}
                                      >
                                        <ul>
                                          <li>Notify me when...</li>

                                          {Object.entries(oppNoti).length >
                                          0 ? (
                                            Object.entries(oppNoti).length >
                                              0 &&
                                            oppNoti.map((item, index) => {
                                              const isActive =
                                                apiResponse[item.name] ===
                                                  true ||
                                                apiResponse[item.name] ===
                                                  'true'
                                                  ? 'active'
                                                  : '';
                                              return (
                                                <li
                                                  key={'oppArray' + item.id}
                                                  onClick={() =>
                                                    generalNotifications(
                                                      item.name,
                                                    )
                                                  }
                                                  className={isActive}>
                                                  {item.description}

                                                  {isActive === 'active' && (
                                                    <span className="check-icon">
                                                      <i className="fas fa-check" />
                                                    </span>
                                                  )}
                                                </li>
                                              );
                                            })
                                          ) : (
                                            <p className="text-center mt-3">
                                              No records found
                                            </p>
                                          )}
                                        </ul>
                                      </Form>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </Scrollbars>
                </div>

                <div className="filter-btn-wrapper text-center pt-2 pb-2 fix-width-forms bg_transparent bottom-0">
                  <Button className="btn-dark mb-0" type="submit" onClick={handle}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SlidingPanel>
      )}

      {showComponent === 'circleNoti' && (
        <CircleNotifications
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'focus' && (
        <SessionFocus
          focus={focus}
          mineCatgoriesId={mineCatgoriesId}
          onClose={() => {
            setShowComponent('');
          }}
          setFocus={setFocus}
          type={'show'}
        />
      )}

      {/* {OpportunityTypeSub.length > 0 && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {idModel === '1' && (
              <>
                <Modal.Title>
                  Are you an accredited Investor? We ask this question to keep
                  BUILD community asfe Misrepresenting youself may lead to
                  temporary or permanent suspension from our community
                </Modal.Title>
              </>
            )}
            {idModel === '2' && (
              <Modal.Title>Do you have a security clearance</Modal.Title>
            )}
            {idModel === '5' && (
              <Modal.Title>Which best describe you</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="gallery-imgs">
                <Row>
                  {Object.entries(OpportunityTypeSub).length > 0 ? (
                    Object.entries(OpportunityTypeSub).length > 0 &&
                    OpportunityTypeSub.map((item, index) => {
                      return (
                        <Col md={4} sm={4} key={`opportunityTypeSub-${index}`}>
                          <li
                            onClick={e => {
                              setSubOppId(current => [...current, item.id]);
                              handleClose(item.id);
                            }}
                            value={item.id}>
                            {item.title}
                          </li>
                        </Col>
                      );
                    })
                  ) : (
                    <div>{labels.no_record_found[selected_lang]}</div>
                  )}
                  {idModel === '2' && (
                    <div
                      onClick={() => {
                        handleClose();
                      }}>
                      NO CLEARANCE
                    </div>
                  )}
                  {idModel === '5' && (
                    <div
                      onClick={() => {
                        handleClose();
                      }}>
                      NOT YET
                    </div>
                  )}
                  {idModel === '1' && (
                    <div
                      onClick={() => {
                        handleClose();
                      }}>
                      NOT YET
                    </div>
                  )}
                </Row>
              </div>
            </>
          </Modal.Body>
        </Modal>
      )} */}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(Preferences);
