"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilePresentation = void 0;
/**
 * To share a file during a conference, the [FilePresentationService](/developers/interactivity-apis/client-sdk/reference-javascript/filepresentationservice)
 * converts the user-provided file into multiple pages (images). The FilePresentation model contains information about the file presentation,
 * such as file [ID](#id), [owner](#owner), [number of pages](#optional-imagecount), and [current position](#optional-position) that informs
 * which image is currently presented.
 */
class FilePresentation {
    /** @ignore */
    constructor(id) {
        /**
         * The current position in the presentation.
         */
        this.position = 0;
        this.id = id;
    }
}
exports.FilePresentation = FilePresentation;
