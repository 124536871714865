import commonApi from "../../Services/CommonService";

const getDispatch = (type, data) => ({
  type: type,
  payload: data,
});


export const getAllAppointments = (data) =>{
  return async dispatch => {
    if(data.type ==='UPDATE'){
      dispatch(getDispatch('APPOINTMENT/DATA', data.appointmentList));
    }else{
      const response = await commonApi.get_my_appointments_by_type(data);
      dispatch(getDispatch('APPOINTMENT/DATA', response.appointments));
      return response;
    }
   
  };
}


export const getUserProfileAppointments = (data) =>{
  return async dispatch => {
    const response = await commonApi.get_my_appointments_by_type(data);
    dispatch(getDispatch('APPOINTMENT/USER_DATA', response.appointments));
    return response;
  };
}


export const getApointSlots = data => {
  return getDispatch('APPOINTMENT/SLOTS', data);
};

export const getApointCurrentDate = data => {
  return getDispatch('APPOINTMENT/DATE', data);
};

// export const getAccomplishmentCategory = () => {
//   return {
//     type: 'FILTER/API',
//   };
// };
