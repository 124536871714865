import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import {Pannels} from '../../Components';
import {customerQr} from '../../Constants/Images';

const CustomerScanQr = props => {

  const [showComponent, setshowComponent] = useState('');
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [wrkSpaceItem, setWorkSpaceItem] = useState([]);


  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        setShowComponent={setshowComponent}
        showComponent={showComponent}
        setWorkSpaceItem={setWorkSpaceItem}
        wrkSpaceItem={wrkSpaceItem}
      />
      <div className="page-content-wrapper">
        <Pannels
          isFloated={isFloated}
          toggleFloatedPanel={toggleFloatedPanel}
          showHiddenPanel={showHiddenPanel}
          updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
          primary={
            <>
              <div className="cutomer-scan-qr-page">
                <div className="scan-content">
                  <div className="customer-qr-img">
                    <img src={customerQr} alt="" />
                  </div>

                  <p>To get started, scan a business or request an invite</p>

                  <Button className="new-btn btn-green  " onClick={() => {}}>
                    Scan Qr
                  </Button>

                  <button
                    type="submit"
                    style={{
                      color: '#68b245',
                    }}
                    className="as-link">
                    Search for business
                  </button>
                </div>
              </div>
            </>
          }
          secondary={<></>}
        />
      </div>
    </div>
  );
};

export default CustomerScanQr;
