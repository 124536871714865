import React from 'react';

const EditPencilSvg = ({ fill , h , w }) => {
  return (
    <React.Fragment>
      <svg
        id="Group_2662"
        data-name="Group 2662"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={w ? w : "18.238"}
        height={h ? h : "18.248"}
        viewBox="0 0 18.248 18.238"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_1077"
              data-name="Rectangle 1077"
              width="18.248"
              height="18.238"
              fill={fill}
            />
          </clipPath>
        </defs>
        <g id="Group_2660" data-name="Group 2660" clipPath="url(#clip-path)">
          <path
            id="Path_2010"
            data-name="Path 2010"
            d="M.284,101.334c-.283-.17-.329-.276-.248-.6q.535-2.155,1.075-4.309a.618.618,0,0,1,.158-.27q4.954-4.963,9.914-9.92c.025-.025.052-.048.065-.059l3.94,3.94a.925.925,0,0,0-.156.108q-4.879,4.874-9.753,9.753a1.049,1.049,0,0,1-.515.29c-1.38.339-2.758.685-4.137,1.029a.6.6,0,0,0-.093.041Z"
            transform="translate(0 -83.096)"
            fill={fill}
          />
          <path
            id="Path_2011"
            data-name="Path 2011"
            d="M353.416,3.376a1.511,1.511,0,0,1-.076.25,2.9,2.9,0,0,1-.385.712c-.478.511-1,.983-1.485,1.457L347.581,1.9l.582-.576c.27-.268.531-.545.811-.8a2,2,0,0,1,2.7,0c.416.389.813.8,1.209,1.21a1.838,1.838,0,0,1,.5.987.389.389,0,0,0,.033.08Z"
            transform="translate(-335.168 0)"
            fill={fill}
          />
        </g>
      </svg>
    </React.Fragment>
  );
};

export default EditPencilSvg;
