"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endpoints = void 0;
/**
 * @ignore
 */
exports.endpoints = {
    version: '2.0',
    init: {
        register: { method: 'POST', uri: '/v1/oauth2/token' },
        refresh: { method: 'POST', uri: '/v1/oauth2/refresh' },
        invalidate: { method: 'POST', uri: '/v1/oauth2/invalidate' },
        identify: { method: 'POST', uri: '/v2/users/identify' },
        logout: { method: 'POST', uri: '/v1/users/logout' },
    },
    file: {
        convert: { method: 'POST', uri: '/v1/files/convert' },
        urlConvert: { method: 'POST', uri: '/v1/files/urlConvert' },
        getImage: { method: 'GET', uri: '/v1/files/{fileId}/converted/{page}' },
        getThumbnail: { method: 'GET', uri: '/v1/files/{fileId}/converted/{page}/thumbnail' },
    },
    conferences: {
        updatePermissions: { method: 'POST', uri: '/v1/conferences/{conferenceId}/permissions' },
        kick: { method: 'POST', uri: '/v1/conferences/{conferenceId}/kick/{peerId}' },
        invite: { method: 'POST', uri: '/v1/conferences/{conferenceId}/invite' },
        answer: { method: 'POST', uri: '/v1/conferences/{conferenceId}/answer/{peerId}' },
        candidates: { method: 'POST', uri: '/v1/conferences/{conferenceId}/candidates/{peerId}' },
        broadcast: { method: 'POST', uri: '/v1/conferences/{conferenceId}/send' },
        create: { method: 'POST', uri: '/v1/conferences/create' },
        getDemo: { method: 'GET', uri: '/v1/conferences/demo' },
        joinRoom: { method: 'POST', uri: '/v1/conferences/{conferenceId}/join' },
        leave: { method: 'POST', uri: '/v1/conferences/{conferenceId}/leave' },
        videoForwarding: { method: 'POST', uri: '/v1/conferences/{conferenceId}/videoforwarding' },
        stopVideo: { method: 'GET', uri: '/v1/conferences/{conferenceId}/video/{peerId}/stop' },
        startVideo: { method: 'GET', uri: '/v1/conferences/{conferenceId}/video/{peerId}/start' },
        stopMedia: { method: 'POST', uri: '/v1/conferences/{conferenceId}/media/{peerId}/stop' },
        startMedia: { method: 'POST', uri: '/v1/conferences/{conferenceId}/media/{peerId}/start' },
        subscribe: { method: 'GET', uri: '/v1/conferences/{conferenceId}/subscribe' },
        unsubscribe: { method: 'DELETE', uri: '/v1/conferences/{conferenceId}/subscribe' },
        status: { method: 'POST', uri: '/v1/conferences/{conferenceId}/status' },
        renegociate: { method: 'GET', uri: '/v1/conferences/{conferenceId}/renegociate' },
        startRecording: { method: 'POST', uri: '/v1/conferences/{conferenceId}/recording/start' },
        stopRecording: { method: 'GET', uri: '/v1/conferences/{conferenceId}/recording/stop' },
        replay: { method: 'POST', uri: '/v1/conferences/{conferenceId}/replay' },
        reserve: { method: 'POST', uri: '/v1/conferences/{conferenceId}/reserve' },
        setMode: { method: 'POST', uri: '/v1/conferences/{conferenceId}/mode' },
        mix: { method: 'POST', uri: '/v1/conferences/{conferenceId}/mix' },
        download: { method: 'GET', uri: '/v1/conferences/{conferenceId}/download' },
        mixUrl: { method: 'GET', uri: '/v1/conferences/{conferenceId}/url' },
        wait: { method: 'GET', uri: '/v1/conferences/{conferenceId}/wait' },
        getStats: { method: 'GET', uri: '/v1/conferences/{conferenceId}/stats' },
        simulcast: { method: 'POST', uri: '/v1/conferences/{conferenceId}/simulcast' },
        error: { method: 'POST', uri: '/v1/conferences/{conferenceId}/error' },
        audioProcessing: { method: 'POST', uri: '/v1/conferences/{conferenceId}/audio/{peerId}/processing' },
    },
    screen: {
        start: { method: 'GET', uri: '/v1/conferences/{conferenceId}/sharing/start' },
        stop: { method: 'GET', uri: '/v1/conferences/{conferenceId}/sharing/stop' },
    },
    fileshare: {
        start: { method: 'POST', uri: '/v1/conferences/{conferenceId}/filepresentation/start' },
        update: { method: 'POST', uri: '/v1/conferences/{conferenceId}/filepresentation/update' },
        stop: { method: 'POST', uri: '/v1/conferences/{conferenceId}/filepresentation/stop' },
    },
    externallive: {
        start: { method: 'POST', uri: '/v1/conferences/mix/{conferenceId}/live/start' },
        stop: { method: 'POST', uri: '/v1/conferences/mix/{conferenceId}/live/stop' },
    },
    hls: {
        start: { method: 'POST', uri: '/v1/conferences/mix/{conferenceId}/hls/start' },
        stop: { method: 'POST', uri: '/v1/conferences/mix/{conferenceId}/hls/stop' },
    },
    videoshare: {
        start: { method: 'POST', uri: '/v1/conferences/{conferenceId}/videopresentation/start' },
        stop: { method: 'POST', uri: '/v1/conferences/{conferenceId}/videopresentation/stop' },
        play: { method: 'POST', uri: '/v1/conferences/{conferenceId}/videopresentation/play' },
        pause: { method: 'POST', uri: '/v1/conferences/{conferenceId}/videopresentation/pause' },
        seek: { method: 'POST', uri: '/v1/conferences/{conferenceId}/videopresentation/seek' },
    },
    tyw: {
        speaker: { method: 'GET', uri: '/v1/conferences/{conferenceId}/speaker' },
        setMetadata: { method: 'POST', uri: '/v1/conferences/{conferenceId}/metadata' },
    },
    subscription: {
        post: { method: 'POST', uri: '/v1/subscriptions' },
        delete: { method: 'DELETE', uri: '/v1/subscriptions' },
    },
};
