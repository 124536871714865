import React from 'react';

import ShareAblesSessions from '../ShareAbles/ShareAblesSessions';
import ShreAblesEvent from '../ShareAbles/ShreAblesEvent';
import ShareAableOpportunity from '../ShareAbles/ShareAableOpportunity';
import {
  arrayColumn,
  filteringUsers,
  fixName,
  formatString,
} from '../../Utils/commonUtils';
import OtherUserImages from './OtherUserImages';
import Moment from 'react-moment';
import {DEFAULT_IMAGE} from '../../Utils/env';

const ChatReplyBox = ({reply, user}) => {
  return (
    <div className="quoted-msg">
      <div className="msg-container" style={{color: '#000000'}}>
        <p className="p-reg text-yellow">You</p>
        {reply.type === 'poll' ? reply.message.question : reply.message}
        {reply.type === 'image' && (
          <div className="msg-img">
            <img src={reply.picture_url} style={{width: '50px'}} alt="" />
          </div>
        )}
        <div className="d-flex align-items-center snapshot-imgs-wrapper chatSnapshots">
          {reply.type === 'snapshot' &&
            Object.entries(reply.picture_url).length &&
            reply.picture_url.slice(0, 4).map((item, index) => {
              return (
                <div key={index} className="msg-img snapShotsImg">
                  <img src={item.images[0]} style={{width: '50px'}}  alt="" />
                  {reply.picture_url
                .length > 4 &&
                index === 3 && (
                  <span
                    className="overlay"
                    
                      // getSpecificAccomplishment(item.id);
                    >
                    {`+${
                     reply.picture_url
                        .length - 4
                    }`}
                  </span>
                )}
                </div>
              );
            })}
        </div>
        {reply.type === 'accomplishment' && (
          <div className="container-fromChat-Shareables shareables-snapshots">
            <div className="as-link">
              <div className=" d-flex align-items-center mb-3">
                <div className="snapshotsImage as-link">
                  <img
                    src={
                      reply.accomplishment_data &&
                      reply.accomplishment_data.images[0]
                    }
                    alt=""
                  />
                </div>
                <div
                  className="snapshots-content as-link"
                  onClick={() => {
                    // setSnapShotId(item);
                    // setShowComponent('SnapShotDetail');
                  }}>
                  <h2 className="single-line-text">
                    {reply.accomplishment_data &&
                      reply.accomplishment_data.title}
                  </h2>
                  {reply.accomplishment_data.category_name && (
                    <p className="p-reg single-line-text category-name">
                      {reply.accomplishment_data &&
                        reply.accomplishment_data.category_name}
                    </p>
                  )}
                </div>
              </div>
              {reply.accomplishment_data && (
                <p className="p-reg truncate_2_lines">
                  {reply.accomplishment_data.description}
                </p>
              )}
            </div>
            {/* {ElementDesign('snapShot', item)} */}
          </div>
        )}

        {reply.type === 'session' && reply.session_data && (
          <ShareAblesSessions
            item={reply.session_data}
            heart={''}
            ElementDesign={''}
            ttypeMesages={true}
            typeForNpClick={true}
          />
        )}
        {reply.type === 'event' && reply.event_data && (
          <ShreAblesEvent
            //  key={`event-item-${index}`}
            item={reply.event_data}
            //  index={index}
            // eventDetails={eventDetails}
            // componentDecesion={
            //   ShowSelectedComponent
            // }
            //  ElementDesign={ElementDesign}
            typeMesages={true}
            typeForNpClick={true}
          />
        )}
        {reply.type === 'opportunity' && reply.opportunity_data && (
          <ShareAableOpportunity
            // key={`opportunity-item-${index}`}
            setShowButton={''}
            onShare={() => {
              // setProperComponent('refer-opportunity');
            }}
            // handleItem={ShowSelectedComponent}
            setChatData={''}
            //  handleItem={''}
            item={reply.opportunity_data}
            //  ElementDesign={''}
            typeMesages={true}
            typeForNpClick={true}
          />
        )}
        {reply.type === 'group_chat' && reply.group_chat_data && (
          <div className="messages-users-list as-link container-fromChat-Shareables group-chat">
            <div className={'message-user-item no-link m-0 p-0'}>
              <div
                className="user-img as-link"
                // onClick={() => {
                //   amplitudeEvent('OPEN_CHAT_ROOM');
                //   handleOpenChatRoom(item);
                // }}
              >
                <img
                  src={
                    reply.group_chat_data.group_image !== ''
                      ? reply.group_chat_data.group_image
                      : reply.group_chat_data[
                          parseInt(reply.group_chat_data.creator_id) ===
                          parseInt(localStorage.getItem('user'))
                            ? 'user_detail'
                            : reply.group_chat_data.hasOwnProperty(
                                'creator_details',
                              )
                            ? 'creator_details'
                            : 'creator_detail'
                        ]['profile_img']
                      ? reply.group_chat_data[
                          parseInt(reply.group_chat_data.creator_id) ===
                          parseInt(localStorage.getItem('user'))
                            ? 'user_detail'
                            : reply.group_chat_data.hasOwnProperty(
                                'creator_details',
                              )
                            ? 'creator_details'
                            : 'creator_detail'
                        ]['profile_img']
                      : DEFAULT_IMAGE
                  }
                  alt=""
                />
                {reply.group_chat_data.chat_type === 'question' && (
                  <span>
                    <i className="fa-solid fa-q"></i>
                  </span>
                )}
                {reply.group_chat_data.chat_type === 'opportunity' && (
                  <span className="icon icon-posts"></span>
                )}
                {reply.group_chat_data.chat_type === 'event' && (
                  <span className="icon-tickets"></span>
                )}
                {reply.group_chat_data.chat_type === 'session' && (
                  <span className="icon-video-camera-2"></span>
                )}
                {/* <span className="fa-solid fa-shop"></span> */}
              </div>
              <div
                className="user-content as-link"
                onClick={() => {
                  // handleOpenChatRoom(item);
                }}>
                <h2 className="single-line-text">
                  {/* {tabType === 'subInvites' &&
                                             !item.hasOwnProperty('read') */}
                  {reply.group_chat_data.group_name !== ''
                    ? reply.group_chat_data.group_name
                    : fixName(
                        reply.group_chat_data[
                          parseInt(reply.group_chat_data.creator_id) ===
                          parseInt(localStorage.getItem('user'))
                            ? 'user_detail'
                            : reply.group_chat_data.hasOwnProperty(
                                'creator_details',
                              )
                            ? 'creator_details'
                            : 'creator_detail'
                        ]['first_name'],
                        reply.group_chat_data[
                          parseInt(reply.group_chat_data.creator_id) ===
                          parseInt(localStorage.getItem('user'))
                            ? 'user_detail'
                            : reply.group_chat_data.hasOwnProperty(
                                'creator_details',
                              )
                            ? 'creator_details'
                            : 'creator_detail'
                        ]['last_name'],
                      )}
                </h2>

                {formatString(reply.group_chat_data.last_message)}

                <div className="message-date-info">
                  <span className="message-date">
                    <Moment format="MM/DD/YY HH:mm A">
                      {reply.group_chat_data.last_message_time
                        ? reply.group_chat_data.last_message_time
                        : reply.group_chat_data.created_at}
                    </Moment>
                  </span>
                  {/* {renderMessageUsers(item.moreUsers)} */}
                </div>
              </div>

              {/* {!reply.group_chat_data.hasOwnProperty('invitation_type') &&
                                             reply.group_chat_data.hasOwnProperty('group_detail') && (
                                              //  <OtherUserImages
                                              //    otherUsers={filteringUsers(
                                              //      reply.group_chat_data.audience_detail.concat(
                                              //        reply.group_chat_data.moderator_detail,
                                              //        reply.group_chat_data.creator_detail
                                              //          ? [reply.group_chat_data.creator_detail]
                                              //          : [reply.group_chat_data.creator_details],
                                              //      ),
                                              //      arrayColumn(
                                              //        reply.group_chat_data.group_detail,
                                              //        'id',
                                              //      ),
                                              //    )}
                                              //  />
                                             )} */}
              {!reply.group_chat_data.hasOwnProperty('invitation_type') &&
                !reply.group_chat_data.hasOwnProperty(
                  'opportunity_invitaion_status',
                ) &&
                reply.group_chat_data.hasOwnProperty('opportunity_id') &&
                reply.group_chat_data.chat_type === 'opportunity' && (
                  <OtherUserImages
                    otherUsers={filteringUsers(
                      reply.group_chat_data.other_persons_opportunity_chat_room.concat(
                        reply.group_chat_data.team_member_details,
                        reply.group_chat_data.creator_detail
                          ? [reply.group_chat_data.creator_detail]
                          : [reply.group_chat_data.creator_details],
                      ),
                      arrayColumn(
                        [
                          ...reply.group_chat_data
                            .other_persons_opportunity_chat_room,
                          ...reply.group_chat_data.team_member_details,
                          reply.group_chat_data.creator_details,
                        ],
                        'id',
                      ),
                    )}
                  />
                )}
            </div>
            {/* {PageDesign()} */}
          </div>
        )}
        {reply.type === 'appointment' && reply.appointment_data && (
          <div
            className="refferals-list as-link container-fromChat-Shareables"
            // onClick={() => {
            //   ShowSelectedComponent(
            //     reply.appointment_data,
            //     reply.type,
            //   );
            // }}
          >
            <div className="">
              <div className="refferals-list-item new-refferal-list mb-0">
                <div className="refferal-content">
                  <h2>
                    {reply.appointment_data.creator_details &&
                      fixName(
                        reply.appointment_data.creator_details.first_name,
                        reply.appointment_data.creator_details.last_name,
                      )}
                  </h2>
                  <span className="refferal-auther">
                    {reply.appointment_data.appointment_type_title}
                  </span>
                  {/* <p>Think there may be some synergy</p> */}
                  <span className="refferal-date">
                    {/* {moment(item.start_date).format(
                                                'MM/DD/YYYY',
                                              )}{' '} */}
                    {reply.appointment_data.duration + 'min'}
                  </span>
                </div>
                <div className="refferal-img">
                  <img
                    src={
                      reply.appointment_data.creator_details &&
                      reply.appointment_data.creator_details.profile_img
                        ? reply.appointment_data.creator_details.profile_img
                        : DEFAULT_IMAGE
                    }
                    alt=""
                  />
                  <p className="refferal-info-que text-end">
                    {reply.appointment_data.cost
                      ? '$' + reply.appointment_data.cost
                      : 'Free'}
                  </p>
                </div>
              </div>
            </div>
            {/* {ElementDesign('appointment', item)} */}
          </div>
        )}

        {reply.type === 'network' && reply.network_data && (
          <div
            className="as-link container-fromChat-Shareables network"
            // onClick={() => {
            //   ShowSelectedComponent(
            //     reply.network_data,
            //     reply.type,
            //   );
            // }}
          >
            <div className="">
              <div className="profile-img-wrapper align-items-center justify-content-start">
                <div className="profile-img">
                  <img
                    src={
                      reply.network_data.profile_img
                        ? reply.network_data.profile_img
                        : DEFAULT_IMAGE
                    }
                    alt=""
                  />
                </div>

                <div className="profile-name flex-0">
                  <div className="profile-name-headings">
                    <div className="">
                      <h2 className="m-0 mb-1 p-bold">
                        {reply.network_data && reply.network_data.first_name}
                        {reply.network_data && reply.network_data.last_name}
                      </h2>
                      {reply.network_data.on_boarding_areas &&
                        reply.network_data.on_boarding_areas.length > 0 && (
                          <h3 className="p-reg m-0">
                            {reply.network_data.on_boarding_areas
                              .map(area => area.title)
                              .join(', ')}
                          </h3>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {ElementDesign('profile', item)} */}
          </div>
        )}
        {/* {reply.video_url && ( */}
        {reply.type === 'video' && (
          <div className="msg-img">
            <video src={reply.video_url} style={{width: '50px'}} />
          </div>
        )}
        {/* {reply.document_url && ( */}
        {reply.type === 'document' && (
          <span className="action-icon file-icon">
            {/* <a href={reply.document_url} target="_blank" style={{color : 'white'}} download> */}
            <i className="fas fa-file-lines" />
            {/* </a> */}
          </span>
        )}
      </div>
      {/* <div className="msg-usr">
        <div className="msg-usr-details">
          <span className="msg-date">
            {getTimeFormatForChat(reply.send_date)}
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default ChatReplyBox;
