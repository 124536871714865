import {animateScroll as scroll} from 'react-scroll';
import React from 'react';

const appendFormData = data => {
  const formData = new FormData();

  if (Object.entries(data).length !== 0) {
    for (const [key, value] of Object.entries(data)) {
      // console.log("Form Data :", key, value);
      formData.append(key, value);
    }
  }

  return formData;
};

const validateEmail = email => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  return pattern.test(email);
};
const isValidNumber = number => {
  var pattern = new RegExp('[^0-9]', 'g');
  return pattern.test(number);
};

const checkDifferent = timeStamp => {
  const current = Date.now();
  const difference = timeStamp > current ? timeStamp - current : 0;

  return current + difference;
};

const updateUrl = (newUrl, history) => {
  history.push({pathname: newUrl});
};

const convertNumber = num => {
  let nf = new Intl.NumberFormat('en-US');

  const amount = nf.format(parseFloat(num).toFixed(2));

  return amount;
};

const RenderHTMLBlock = ({container, content, className}) => {
  const Component = container ? container : 'div';
  return (
    <Component
      className={className}
      dangerouslySetInnerHTML={{__html: content}}
    />
  );
};

const FormattedText = props => {
  const {content, className, style} = props;
  console.log(className);
  const lines = content.split('\n');
  return (
    <div className={className} style={{margin:  style &&'14px 0 5px' }}>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

const scrollTo = id => {
  const target = id && id !== '' ? document.getElementById(id) : null;

  const headerElement = document.getElementsByClassName('header-wrapper');
  const headerHeight = headerElement[0].clientHeight + 20;
  const topPos = id && id !== '' ? target.offsetTop - headerHeight : 0;

  scroll.scrollTo(topPos);

  return false;
};

const checkScrollPosition = () => {
  var scrollPos = window.scrollY;
  var screenHeight = window.outerHeight / 4;

  const header = document.getElementsByClassName('header-wrapper');
  if (header && header[0]) {
    if (scrollPos > 50) {
      header[0].classList.add('fixed');
    } else {
      header[0].classList.remove('fixed');
    }
  }

  const backToTop = document.getElementsByClassName('backToTop');
  if (backToTop && backToTop[0]) {
    if (scrollPos > screenHeight) {
      backToTop[0].classList.add('show');
    } else {
      backToTop[0].classList.remove('show');
    }
  }
};

export const getMinutes = ms => {
  return Number(ms / 60000).toFixed(2);
};

export const copy_url = copyUrl => {
  const textField = document.createElement('textarea');
  textField.innerText = copyUrl;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

const generateId = () => Math.random().toString(36).substring(2, 7);

window.addEventListener('scroll', checkScrollPosition);

export {
  validateEmail,
  isValidNumber,
  appendFormData,
  checkDifferent,
  checkScrollPosition,
  updateUrl,
  convertNumber,
  RenderHTMLBlock,
  FormattedText,
  generateId,
  scrollTo,
};
