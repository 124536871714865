"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FakeMediaStreamAudioTrack = exports.DvcsMediaStream = exports.CustomStreamMinIndex = exports.MediaStreamIndex = exports.MediaStreamType = void 0;
/**
 * The MediaStreamType model represents the [default](#camera) and [screen share](#screenshare) media stream types.
 */
var MediaStreamType;
(function (MediaStreamType) {
    /**
     * The default Media stream type.
     */
    MediaStreamType["Camera"] = "Camera";
    /**
     * The ScreenShare media stream type.
     */
    MediaStreamType["ScreenShare"] = "ScreenShare";
    /**
     * User added custom stream.
     * @ignore
     */
    MediaStreamType["Custom"] = "Custom";
})(MediaStreamType = exports.MediaStreamType || (exports.MediaStreamType = {}));
/**
 * @ignore
 */
var MediaStreamIndex;
(function (MediaStreamIndex) {
    MediaStreamIndex[MediaStreamIndex["Camera"] = 0] = "Camera";
    MediaStreamIndex[MediaStreamIndex["ScreenShare"] = 1] = "ScreenShare";
    MediaStreamIndex[MediaStreamIndex["Custom"] = 2] = "Custom";
})(MediaStreamIndex = exports.MediaStreamIndex || (exports.MediaStreamIndex = {}));
/**
 * @ignore
 */
exports.CustomStreamMinIndex = 2;
/**
 * @ignore
 */
class DvcsMediaStream extends MediaStream {
    constructor(stream) {
        super(stream);
        this.type = MediaStreamType.Camera;
        this._audioTracks = new Array();
        this._audioTracks.push(new FakeMediaStreamAudioTrack());
    }
    addTrack(track) {
        if (track instanceof MediaStreamTrack) {
            super.addTrack(track);
        }
        else {
            this._audioTracks.push(track);
        }
    }
    removeTrack(track) {
        if (track instanceof MediaStreamTrack) {
            super.removeTrack(track);
        }
        else {
            this._audioTracks.shift();
        }
    }
    getAudioTracks() {
        return this._audioTracks;
    }
}
exports.DvcsMediaStream = DvcsMediaStream;
/**
 * @ignore
 */
class FakeMediaStreamAudioTrack {
    constructor() {
        this.id = 'fakeAudioTrack';
        this.kind = 'audio';
        this.enabled = true;
    }
    applyConstraints(constraints) {
        return Promise.resolve();
    }
    clone() {
        return this;
    }
    getCapabilities() {
        return null;
    }
    getConstraints() {
        return null;
    }
    getSettings() {
        return null;
    }
    stop() { }
    addEventListener() { }
    removeEventListener() { }
    dispatchEvent(event) {
        return true;
    }
}
exports.FakeMediaStreamAudioTrack = FakeMediaStreamAudioTrack;
