import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import LoaderModal from './LoaderModal';
import FocusModel from './FocusModel';
import {useSelector, useDispatch} from 'react-redux';
import {getSessionListing} from '../../Store/Actions/SessionAction';
import CustomModal from '../CustomModal';
import { errorMessage } from '../../Utils/ToastMessages';

const CompanyFilterModal = (props) => {
 
  const closeModal = () => {
    props.onClose();
  };
  const [showLoader, setShowLoader] = useState(false);
  const [searchFilter, setSearchFilter] = useState(false);
  const [companies, setCompanies] = useState([]);
 


  useEffect(() => {

    setCompanies(props.list.length > 0 ? props.list:[] )
    // getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  const getCompanies = () => {
    commonApi
      .get_company()
      .then(res => {
        setShowLoader(false);
        if (res.status === 200) {
          props.setList(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const handleSelectAll = e => {
    props.setIsCheckAll(!props.isCheckAll);
    props.setIsCheck(props.list.map(item => item.id));
    if (props.isCheckAll) {
      props.setIsCheck([]);
    }
  };



  const handleClick = e => {
    const {id, checked} = e.target;
    props.setIsCheck([...props.isCheck, id]);
    if (!checked) {
      props.setIsCheck(props.isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    if (props.list.length > 0) {
      if (props.isCheck.length === props.list.length) {
        props.setIsCheckAll(true);
      } else {
        props.setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCheck]);


  const handleFilter = () => {
    if(props.isCheck.length >0){
        props.handleFilter && props.handleFilter()
        props.onClose();
    }else{
        errorMessage('Please Select Company')
    }
   

  };

  return (
    <>
      <CustomModal size="medium" className={'sessioneventFilter'} style={{height: '100%'}} onClose={() => closeModal()}>
        {showLoader && <LoaderModal show={showLoader} />}

        <div className="serach-filter-wrapper">
          <div className="align-items-center justify-content-between mb-3">
            <div className="headings m-0">
              <h2 className="m-0 text-center">Companies</h2>
            </div>

            <div className="sidebar-heading-wrapper mt-0 text-right">
              {/* <div className="ticket-icons-options">
              <ul>
                <li
                  className={props.active ? 'active' : 'target-icon'}
                  onClick={() => {
                    setSearchFilter(true);
                  }}>
                  <i className="icon-bullseye" />
                </li>
              </ul>
            </div> */}
            </div>
          </div>

          {/* <Scrollbars> */}
          <div className="filter-wrapper filter-pad-bottom-wrap">
            <div className="filter-category-list">
              <ul>
                <li className={props.isCheckAll ? 'active' : ''}>
                  <Form.Label>
                    All{' '}
                    <input
                      type="checkbox"
                      id="ALL"
                      name="ALL"
                      value="ALL"
                      onChange={handleSelectAll}
                      checked={props.isCheckAll}
                    />{' '}
                    <span />
                  </Form.Label>
                </li>
                {Object.entries(companies).length > 0 &&
                  companies.map((item, index) => (
                    <li
                      key={`sessionC-${index}`}
                      className={
                        props.isCheck.includes(item.id) ? 'active' : ''
                      }>
                      <Form.Label>
                        {item.company_name}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          onChange={handleClick}
                          checked={props.isCheck.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {/* </Scrollbars> */}
          <div className="filter-btn-wrapper pt-3 text-center bg_transparent bottom-0  border-radius-25">
            <Button
              className="btn-dark"
              type="submit"
              onClick={() => {
                
               handleFilter()
                

                
              }}>
              Filter Companies
            </Button>
          </div>
        </div>
      </CustomModal>
      
    </>
  );
};




export default CompanyFilterModal