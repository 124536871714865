import ReactDOM from 'react-dom/client';

import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'animate.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-lightbox-pack/dist/index.css';

import './Assets/fonts/style.css';

import './Assets/css/admin/style.css';
import './Assets/css/admin/responsive.css';

import './Assets/css/landing.style.css';
import './Assets/css/style.css';

import './Assets/css/theme.scss';
import './Assets/css/dev.css';
import './Assets/css/roi.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import 'react-phone-input-2/lib/style.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Assets/css/responsive.css';


import 'rc-time-picker/assets/index.css';
// import PasteLinks from './CheckPasteLinks';
import {GoogleOAuthProvider} from '@react-oauth/google';
import store from './Store';
import App from './Routes';
const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <BrowserRouter>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="556170493308-2o3iobkftj6d5ppk7uh1vtc8c3fn84g4.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
      <ToastContainer />
    
    </Provider>
  </BrowserRouter>,
);
