import API from '../../Utils/API';

import {setItem, getItem} from '../../Utils/LocalStorage';
import {loginDispatch} from './AuthActions';
import {
  PAGE_LOADED,
  SIDEBAR_MENU_STATE,
  UPADATE_WINDOW_WIDTH,
  GET_SETTINGS,
  GET_STATES,
  GET_MY_CARD,
  ADD_MY_CARD,
  REMOVE_MY_CARD,
  GET_NOTIFICATIONS,
  SECONDARY_PANEL_STATE,
  SUB_MENU_STATE,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../../Constants/Types';
import {addTestimonialDispatch} from './AdminAction';

/**
 *
 */



const getDispatch = (type, data) => ({
  type: type,
  payload: data,
});

export const updatePageWidthDispatch = width => ({
  type: UPADATE_WINDOW_WIDTH,
  width,
});

export const updatePageLoadedDispatch = status => ({
  type: PAGE_LOADED,
  status,
});

export const setMenuStateDispatch = status => ({
  type: SIDEBAR_MENU_STATE,
  status,
});

export const setMenuState = status => dispatch => {
  setItem('showSidebar', status);
  dispatch(setMenuStateDispatch(status));
};

export const setSubMenuStateDispatch = status => ({
  type: SUB_MENU_STATE,
  status,
});

export const setSubMenuState = status => dispatch => {
  setItem('showSubMenu', status);
  dispatch(setSubMenuStateDispatch(status));
};

export const setSeconaryPannelStateDispatch = status => ({
  type: SECONDARY_PANEL_STATE,
  status,
});

export const toggleSecondaryPannel = status => dispatch => {
  setItem('Secondary Panel State', status);
  dispatch(setSeconaryPannelStateDispatch(status));
};

export const dispatchStates = states => ({
  type: GET_STATES,
  states,
});

export const getAllStates = () => dispatch =>
  API.getAllStates().then(res => {
    if (res.type === 'success') {
      const {states} = res;
      dispatch(dispatchStates(states));
    }
  });

/**
 * Settings
 **/

export const setSaveSettingsDispatch = settings => ({
  type: GET_SETTINGS,
  settings,
});

export const saveSettings = data => dispatch =>
  API.saveSettings(data).then(res => {
    const {settings} = res;
    if (res.type === 'success') {
      dispatch(setSaveSettingsDispatch(settings));
    }
    return res;
  });

export const getSettings = data => dispatch =>
  API.getSettings(data).then(res => {
    const {settings} = res;
    if (res.type === 'success') {
      dispatch(setSaveSettingsDispatch(settings));
    }
    return res;
  });

export const saveRatting =
  (data, isAdmin = 'No') =>
  dispatch =>
    API.saveRatting(data).then(res => {
      const {testimonial} = res;
     
      if (res.type === 'success') {
        // dispatch(setSaveSettingsDispatch(settings));
      }

      if (isAdmin === 'Yes' && res.type === 'success') {
        dispatch(addTestimonialDispatch(testimonial));
      }

      return res;
    });

/**
 * Cards Actions
 */
export const getMyCardDispatch = cards => ({
  type: GET_MY_CARD,
  cards,
});
export const addMyCardDispatch = card => ({
  type: ADD_MY_CARD,
  card,
});
export const removeMyCardDispatch = card => ({
  type: REMOVE_MY_CARD,
  card,
});

export const getMyCard = () => dispatch =>
  API.getMyCard().then(res => {
    if (res.type === 'success') {
      const {cards} = res;
      dispatch(getMyCardDispatch(cards));
    }
    return res;
  });

export const saveCard = data => dispatch =>
  API.saveCard(data).then(res => {
    const authToken = getItem('bf_auth_token');
    if (res.type === 'success') {
      const {card, user} = res;
      user.authToken = authToken;

      dispatch(addMyCardDispatch(card));
      dispatch(loginDispatch(user));
    }

    return res;
  });

export const assignPrimary = id => dispatch =>
  API.assignPrimary(id).then(res => {
    const authToken = getItem('bf_auth_token');
    if (res.type === 'success') {
      const {cards, user} = res;
      user.authToken = authToken;
      dispatch(getMyCardDispatch(cards));
      dispatch(loginDispatch(user));
    }
    return res;
  });

export const deleteCard = id => dispatch =>
  API.deleteCard(id).then(res => {
    if (res.type === 'success') {
      const {card} = res;
      dispatch(removeMyCardDispatch(card));
    }
    return res;
  });

export const getStripeKeys = () => dispatch => API.getStripeKeys();

/**
 * Youtube Authentication
 */

export const createAuthClient = redirect => dispatch =>
  API.createAuthClient(redirect);

export const verifyAuthCode = data => dispatch =>
  API.verifyAuthCode(data).then(res => {
    const {type, settings} = res;

    if (settings && type === 'success') {
      dispatch(setSaveSettingsDispatch(settings));
    }
    return res;
  });

/**
 * Save & Retrieve Notification Mails
 */
export const getNotificationsDispatch = notifications => ({
  type: GET_NOTIFICATIONS,
  notifications,
});

export const saveNotifications = data => dispatch =>
  API.saveNotifications(data).then(res => {
    const {type, notifications} = res;

    if (notifications && type === 'success') {
      dispatch(getNotificationsDispatch(notifications));
    }

    return res;
  });

export const getNotifications = () => dispatch =>
  API.getNotifications().then(res => {
    const {type, notifications} = res;

    if (notifications && type === 'success') {
      dispatch(getNotificationsDispatch(notifications));
    }

    return res;
  });

/**
 * ADD & REMOVE Notifications
 */

const addNotificationDispatch = notification => ({
  type: ADD_NOTIFICATION,
  notification,
});

export const addNotification = item => dispatch => {
  if (item.title) {
    item.id = parseInt(Math.random(5) * 1000);
    dispatch(addNotificationDispatch(item));
  }
};
const removeNotificationDispatch = notification => ({
  type: REMOVE_NOTIFICATION,
  notification,
});

export const removeNotification = item => dispatch => {
  if (item.id) {
    dispatch(removeNotificationDispatch(item));
  }
};



export const getUpdateDataOfCompany = (value) =>{
  return async dispatch => {
    
    dispatch(getDispatch('UPDATE/COMPANYNAME', value));
   
  };
}


export const companyChangeLoader = (value) =>{
  return async dispatch => {
    
    dispatch(getDispatch('COMPANY/CHANGE', value));
   
  };
}

export const companyMenu = (value) =>{
  return async dispatch => {
    
    dispatch(getDispatch('MENU/OPEN', value));
   
  };
}

export const registerRoute = (value) =>{
  return async dispatch => {
    
    dispatch(getDispatch('ROUTE/AVAILABLE', value));
   
  };
}