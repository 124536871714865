import React, {useState} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import CatalogDetail from './CatalogDetail.js';
import Slider from 'react-slick';
import Sidebar from '../../Pages/Sidebar.js';
import {ShareablesBlackIcon, Cart, rewardStar} from '../../Constants/Images';
import {Pannels} from '../../Components';
import {Button} from 'react-bootstrap';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';

const RetailHome = props => {
  const [refresh, setRefresh] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [threeDots, setThreeDots] = useState(false);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Revenue',
      money: '12',
      time: 'versus last month',
      svgIcon: '',
    },

    {
      name: 'Returns ',
      money: '22$',
      time: 'versus last month',
      svgIcon: '',
      icon: 'fa-solid fa-star',
    },

    {
      name: 'Margins ',
      money: '22%',
      time: 'versus last month',
      svgIcon: '',
    },
    {
      name: 'Abandoned ',
      money: '42%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Abandoned',
      money: '42$',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Upsells ',
      money: '42%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Ratings',
      money: '42%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  return (
    <div className="page-wrapper">
      {!props.eventButton && (
        <Sidebar
          // setWorkSpaceItem={setWorkSpaceItem}
          setWorkSpaceItem={setRefresh}
          refresh={refresh}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
      )}

      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          <div className="Reward_main analytics_slider_bg">
            <span
              className="analytics_slider_heading "
              style={{color: analycticsSlider ? '' : '#999999'}}
              onClick={() => {
                setAnalycticsSlider(!analycticsSlider);
              }}>
              Analytics Row
            </span>

            {analycticsSlider && (
              <div className="reward-top mt-3 mb-2">
                <Slider {...rewardSlider}>
                  {rewardSliderData.length > 0 &&
                    rewardSliderData.map((item, index) => (
                      <div className="reward-main">
                        <div className="reward-card">
                          <span style={{fontWeight: 700, fontSize: '13px'}}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <span style={{fontSize: '17px'}}>{item.money}</span>
                            {item.name === 'Ratings' && (
                              <img src={item.svgIcon} alt="" />
                            )}
                            {item.name === 'Conversion' ? (
                              <i
                                className="fa-solid fa-arrow-down"
                                style={{color: '#BD4141'}}></i>
                            ) : (
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            )}
                          </span>
                          <div class="message-date-info">
                            <span style={{color: '#333'}} class="message-date">
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="add_border"></div>

          <>
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  {/* {showComponent === 'firstVist' && (
                    <OpportunityScreen
                      onClose={() => {
                        setShowComponent('');
                      }}
                      type={'session_event'}
                      setButton={setButton}
                      setPlusButton={setPlusButton}
                    />
                  )} */}
                  {showComponent !== 'firstVist' && (
                    <div className="serach-filter-wrapper">
                      <>
                        <div className="mt-3">
                          <h3
                            style={{
                              color: '#000',
                              fontFamily: 'Inter',
                              fontSize: '21.062px',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: 'normal',
                            }}>
                            Hi, Jeff
                          </h3>
                        </div>
                        <div className="mt-2 mb-3">
                          <span
                            style={{
                              color: '#726F6F',
                              fontFamily: 'Inter',
                              fontSize: '16.047px',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              lineHeight: 'normal',
                            }}>
                            Have fun shopping!
                          </span>
                        </div>
                        <div className="serach-filter-wrapper">
                          {/* Search Wrapper */}
                          <div className="search-tabs-wrapper">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <div className="event-tabs tabs-style-2 mb-0">
                                <ul>
                                  <li>
                                    <Button
                                      className={
                                        //   currentComponent === 'catalog' ? 'active' :
                                        'active'
                                      }
                                      onClick={() => {
                                        // setCurrentComponent('catalog')
                                      }}>
                                      Shop
                                    </Button>
                                  </li>
                                  <li>
                                    <Button
                                      className={
                                        //   currentComponent === 'sold' ? 'active' :
                                        ''
                                      }
                                      onClick={() => {
                                        // setCurrentComponent('sold')
                                      }}>
                                      Save
                                    </Button>
                                  </li>

                                  <li className="cart_count_main">
                                    <Button
                                      className={
                                        //   currentComponent === 'inventory'
                                        //     ? 'active' :
                                        ''
                                      }
                                      onClick={() => {
                                        //   setCurrentComponent('inventory');
                                      }}>
                                      Cart
                                    </Button>

                                    <div className="cart_count">5</div>
                                  </li>
                                </ul>
                              </div>

                              <div className="search-tabs-icons">
                                <span
                                  onClick={() => {}}
                                  className={
                                    // button === 'sessions' &&
                                    // plusButton === false
                                    //   ? 'icon active'
                                    //   :
                                    'icon'
                                  }>
                                  <i className="icon-plus"></i>
                                </span>

                                <span
                                  onClick={() => {
                                    setThreeDots(!threeDots);
                                  }}
                                  className={
                                    threeDots ? 'icon active' : 'icon'
                                  }>
                                  <i className="fas fa-ellipsis-vertical" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="refferal-list-wrapper">
                            <Scrollbars autoHide>
                              <div
                                className="retail_catalog_item"
                                onClick={() => {
                                  setShowComponent('retailDetail');
                                }}>
                                <div className="payment-card qa-list-item">
                                  <div className="payment-user-item no-link">
                                    <div className="cart-img as-link"></div>
                                    <div className="user-content as-link">
                                      <h2
                                        style={{
                                          color: '#000',
                                          fontFamily: 'Inter',
                                          fontSize: '15.044px',
                                          fontStyle: 'normal',
                                          fontWeight: '700',
                                          lineHeight: 'normal',
                                        }}>
                                        Catalog Item
                                      </h2>
                                      <p
                                        style={{
                                          fontWeight: '500',
                                          color: '#000',
                                          fontFamily: 'Inter',
                                          fontSize: '13.038px',
                                          fontStyle: 'normal',

                                          lineHeight: 'normal',
                                        }}>
                                        In-Stock
                                      </p>
                                      <div className="message-date-info">
                                        <span className="message-date">
                                          <time datetime="1699009603000">
                                            Category
                                          </time>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="message-other-users"></div>
                                  </div>

                                  <div className="">
                                    <div className="payment-cart-subCard">
                                      <h3>$456</h3>

                                      <div className="buy-btn-wrapper">
                                        <span>
                                          <i
                                            className="fa-regular fa-heart"
                                            style={{
                                              // paddingLeft: '17px',
                                              fontSize: '21px',
                                            }}></i>
                                        </span>
                                        <span
                                          style={{
                                            paddingLeft: '17px',
                                          }}>
                                          <img
                                            className="sharableYellow as-link"
                                            src={ShareablesBlackIcon}
                                            alt="shareable"
                                            // onClick={() => {

                                            // }}
                                          />
                                        </span>

                                        <span
                                          // className="pl-2"
                                          style={{
                                            paddingLeft: '17px',
                                            paddingRight: '2px',
                                          }}>
                                          <img alt="" src={Cart} />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="retail_catalog_item"
                                onClick={() => {
                                  setShowComponent('retailDetail');
                                }}>
                                <div className="payment-card qa-list-item">
                                  <div className="payment-user-item no-link">
                                    <div className="cart-img as-link"></div>
                                    <div className="user-content as-link">
                                      <h2
                                        style={{
                                          color: '#000',
                                          fontFamily: 'Inter',
                                          fontSize: '15.044px',
                                          fontStyle: 'normal',
                                          fontWeight: '700',
                                          lineHeight: 'normal',
                                        }}>
                                        Catalog Item
                                      </h2>
                                      <p
                                        style={{
                                          fontWeight: '500 ',
                                          color: '#000',
                                          fontFamily: 'Inter',
                                          fontSize: '13.038px',
                                          fontStyle: 'normal',
                                          lineHeight: 'normal',
                                        }}>
                                        In-Stock
                                      </p>
                                      <div className="message-date-info">
                                        <span className="message-date">
                                          <time datetime="1699009603000">
                                            Category
                                          </time>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="message-other-users"></div>
                                  </div>

                                  <div className="">
                                    <div className="payment-cart-subCard">
                                      <span className="font-bold ">$456</span>
                                      <span className="font-bold pl-12">
                                        Added to Card
                                      </span>
                                      <div className="buy-btn-wrapper">
                                        <span>
                                          <i
                                            className="fa-regular fa-heart"
                                            style={{
                                              // paddingLeft: '17px',
                                              fontSize: '21px',
                                            }}></i>
                                        </span>
                                        <span
                                          style={{
                                            paddingLeft: '17px',
                                          }}>
                                          <img
                                            className="sharableYellow as-link"
                                            src={ShareablesBlackIcon}
                                            alt="shareable"
                                            // onClick={() => {

                                            // }}
                                          />
                                        </span>

                                        <span
                                          // className="pl-2"
                                          style={{
                                            paddingLeft: '17px',
                                            paddingRight: '2px',
                                          }}>
                                          <i
                                            className="fa-solid fa-xmark"
                                            style={{
                                              color: '#D71616',
                                              fontSize: '21px',
                                            }}></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Scrollbars>
                          </div>
                        </div>
                      </>
                    </div>
                  )}
                </>
              }
              secondary={
                <>
                  {showComponent === 'retailDetail' && (
                    <CatalogDetail
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default RetailHome;
