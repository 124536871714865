import React, {useState, useEffect, useRef} from 'react';
import Moment from 'react-moment';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {fixName, formatTimeee, splitStringToArray} from '../../Utils/commonUtils';
import AudioPlayer from './AudioPlayer';

const CallRecordings = props => {
  const [callRecordingList, setCallRecordingList] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);

  const get_twilio_call = () => {
    commonApi
      .get_twilio_call()
      .then(res => {
        if (res.status === 200 && res.call_details.length > 0) {
            const filteredData = res.call_details.filter(item => item.recording_urls !== "");
          setCallRecordingList(filteredData);
          setShowSpinner(false);
        }
      })
      .catch(err => console.error('Error fetching Twilio token:', err));
  };

  useEffect(() => {
    get_twilio_call();
  }, []);

  return (
    <>
      {showSpinner && <LoaderSpinner />}

      <div className="session-wrapper history-sessions">
        {Object.entries(callRecordingList).length > 0 ? (
          Object.entries(callRecordingList).length > 0 &&
          callRecordingList.map((item, index) => (
            <div
              key={index + 'ASDSAD' + item.id}
              className="events-item as-link"
              style={{padding: '11px', marginBottom: '10px'}}>
              <div className="session-heading" style={{alignItems: 'unset'}}>
                <div className="persons-img-list mb-0 d-flex align-items-center">
                  <div className="img-item mb-0">
                    <img
                      src={
                        item.receiver_detail?.profile_img
                          ? item.receiver_detail?.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt=""
                      style={{width: '60px', height: '60px'}}
                    />
                  </div>
                  <div className="row">
                    <span className="m-1">
                      {fixName(
                        item?.receiver_detail?.first_name,
                        item?.receiver_detail?.last_name,
                      )}
                    </span>
                    {item?.receiver_phone && (
                      <span className="m-1">{item?.receiver_phone}</span>
                    )}
                  </div>
                </div>
                <div className="row event-titles">
                  <div className="event-date">{item?.status}</div>
                </div>
              </div>
              {item?.recording_urls && splitStringToArray(item?.recording_urls).map((url, index) => (
      <div>
        <AudioPlayer src={url} index={index}/>
      </div>
    ))}
              <div className="session-heading">
                <div className="event-titles">
                </div>
                <div className="event-date fs-8">
                  <Moment format="MM/DD/YY H:mmA">{item.created_at}</Moment>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-results-wrapper">
            <div className="img-bg-wrapper">
              {/* <img
                                src={require('../../Assets/images/norecord/session.png')}
                                alt="no-session"
                              /> */}
            </div>

            <div className="no-results-content">
              <h2>No recorde found.</h2>
              {/* <p>
                                We can explore existing group sessions or
                                Startyour own. audio,video and screen
                                sharing are at your fingertips.
                              </p> */}

              {/* <div className="btn-wrapper mt-3">
                                <Button
                                  className={
                                    getItem('userRole') === 'guest'
                                      ? 'grey-class-btn'
                                      : 'btn btn-dark'
                                  }>
                                  <Link
                                    className="text-light"
                                    onClick={e => {
                                      loginUserRole(e);
                                    }}
                                    style={{
                                      textDecoration: 'none',
                                    }}
                                    to="/">
                                    Explore Meetups
                                  </Link>
                                </Button>
                              </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CallRecordings;
