import React from 'react';
import Sidebar from '../../Pages/Sidebar';
import {Button, Col, Dropdown, Form, Row} from 'react-bootstrap';

import {useEffect, useState} from 'react';
import {rewardStar, taskCheckMark} from '../../Constants/Images';

import {Pannels} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';

import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  dispatch,
  ProfileNotesIcon,
  Bandage,
} from '../../Constants/Images';

import Slider from 'react-slick';
import {getPipelineData} from '../../Store/Actions/ProfileAction';
import {useSelector, useDispatch} from 'react-redux';

import commonApi from '../../Services/CommonService';
import {
  DraggableDealsNoRecord,
  PipelineNoRecordFound,
} from '../../Utils/NoRecordConstant';
import NorecordFound from '../../Components/NoRecord/NorecordFound';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import PipelineDetail from '../../Components/PipeLine/PipelineDetail';
import CreatePipeLine from '../../Components/PipeLine/CreatePipeLine';

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {
  DealsNoRecordFound,
  checkPipelinesDeals,
  checkPermissions,
  truncateTitle,
} from '../../Utils/commonUtils';
import CreateDeal from '../../Components/Deals/CreateDeal';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import {
  newSingleChatroom,
  chatroomInfo,
  fixName,
} from '../../Utils/commonUtils';
import ProfileNotes from '../../Components/ProfilePage/ProfileNotes';
import DealDetail from '../../Components/Deals/DealDetail';
import {errorMessage} from '../../Utils/ToastMessages';

const getItems = (count, offset = 0) =>
  Array.from({length: count}, (v, k) => k).map(k => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`,
  }));

const Pipeline = props => {
  const [showComponent, setShowComponent] = useState('');
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('list');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [showLoader, setshowLoader] = useState(true);
  const [searchQry, setSearchQry] = useState('');

  const [phaseId, setPhaseId] = useState('');
  const dispatch = useDispatch();
  const pipelineList = useSelector(state => state.pipelineList);
  const updatePipelineData = useSelector(state => state.updatePipelineData);

  const [seconadaryComponent, setSecondaryComponent] = useState('');
  const [primaryComponent, setPrimaryComponent] = useState('');
  const [pipeLineItem, setPipelineItem] = useState({});
  const [pipelinePhases, setPipelinePhases] = useState([]);
  const [pipelinePermissionItem, setPipelinePermissionItem] = useState({});
  const [movedDeal, setMovedDeal] = useState({});

  const [dealItem, setDealItem] = useState({});

  // const [chatState, setChatState] = useState({
  //   chatType: '',
  //   chatDetails: [],
  //   allChatUsers: [],
  //   chatRoomName: '',
  //   chatRoomId: '',
  // });

  const [chatType, setChatType] = useState([]);
  const [chatDetails, setChatDetails] = useState([]);
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');

  const [customerId, setCustomerId] = useState('');

  const [pipelinePhases2, setPipelinePhases2] = useState([]);
  const [pieplineId, setPieplineId] = useState('');

  const menuPermesions = useSelector(state => state.menuPermesionList);
  const taskSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices (e.g., tablets)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // This is the breakpoint for smaller mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // adaptiveHeight: true,
  };

  const taskSliderData = [
    {
      taskStatus: 'Prospects',
    },

    {
      taskStatus: 'Pending Follow-up',
    },
    {
      taskStatus: 'Pending Agreement',
    },
    {
      taskStatus: 'Complete',
    },
  ];

  useEffect(() => {
    if (seconadaryComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [seconadaryComponent]);

  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);
      
      if (
        Object.keys(workspaceData).length > 0 &&
        urlPart.length === 1 &&
        showComponent !== ''
      ) {
        setShowComponent('');
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'Leads',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Conversion',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Close Time',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];
  const dynamicStyle = {
    padding: '0px 0px 15px',
    // Add other styles as needed
  };

  const getPipelines = value => {
    const res = dispatch(
      getPipelineData({
        search: value,
      }),
    );

    // if (res) {
    //   setTimeout(() => {
    //     setshowLoader(false);
    //   }, 500);
    // }
    // commonApi
    //   .get_pipeline_details()
    //   .then(res => {
    //     if (res.status === 200) {
    //       setPipelineList(res.data);
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  const getUserData = () => {
    var data = {
      user_id: localStorage.getItem('user') ? localStorage.getItem('user') : 1,
    };
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserInfo(res);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    getPipelines();

    getUserData();
  }, []);

  useEffect(() => {
    if (Object.keys(pipeLineItem).length > 0 && updatePipelineData) {
      pipelineList.forEach(pipeline => {
        if (pipeline.id === pipeLineItem.id) {
          setPipelineItem(pipeline);
          setSecondaryComponent('pipelineDetail');
        }
      });
    }
  }, [pipelineList]);

  useEffect(() => {
    if (pipelineList.length > 0) {
      if (pieplineId !== '') {
        setPieplineId(pieplineId);
      } else {
        setPieplineId(pipelineList.length > 0 ? pipelineList[0].id : '');
      }

      // setPipelinePhases(pipelineList.length > 0 ? pipelineList[0].phases : []);
    }
  }, [pipelineList]);

  useEffect(() => {
    if (pipelineList.length > 0) {
      const filterData = pipelineList.filter(item => item.id === pieplineId);
      setPipelinePermissionItem(filterData.length > 0 ? filterData[0] : {});

      console.log(filterData, 'filterDatafilterDatafilterData');
      setPipelinePhases(
        filterData.length > 0 &&
          filterData[0].phases &&
          filterData[0].phases.length > 0
          ? filterData[0].phases
          : [],
      );

      setPipelinePhases2(
        filterData.length > 0 &&
          filterData[0].phases &&
          filterData[0].phases.length > 0
          ? filterData[0].phases
          : [],
      );

      // const arrayOfArrays = filterData.length > 0 && filterData[0].phases.map(phase => phase.deals);

      // console.log(arrayOfArrays,'arrayOfArraysarrayOfArraysarrayOfArrays')
      // setPipelinePhases2(arrayOfArrays)
    }
  }, [pieplineId, pipelineList]);

  // function onDragEnd(result) {
  //   const { source, destination } = result;

  //   // dropped outside the list
  //   if (!destination) {
  //     return;
  //   }

  //   const sInd = +source.droppableId;
  //   const dInd = +destination.droppableId;
  //   console.log(sInd,'sIndsIndsInd')
  //   console.log(dInd,'dInddInddInd')

  //   if (sInd === dInd) {
  //     const items = reorder(pipelinePhases2.deals[sInd], source.index, destination.index);
  //     const newState = [...pipelinePhases2];
  //     newState.deals[sInd] = items;
  //     setPipelinePhases2(newState);
  //   } else {
  //     const result = move(pipelinePhases2.deals[sInd], pipelinePhases2.deals[dInd], source, destination);
  //     const newState = [...pipelinePhases2];
  //     newState.deals[sInd] = result[sInd];
  //     newState.deals[dInd] = result[dInd];

  //     setPipelinePhases2(newState);
  //   }
  // }

  const onDragEnd = result => {
    const {source, destination} = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    console.log(source, 'sourcesourcesource');
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    const updatePermission = pipelinePhases[sInd].update_permission;

    if (!updatePermission) {
      errorMessage("You don't have Permission");
      return;
    }

    // If dragging within the same phase
    if (sInd === dInd) {
      const phaseIndex = sInd;
      const items = reorder(
        pipelinePhases[phaseIndex].deals,
        source.index,
        destination.index,
      );

      const newState = [...pipelinePhases];
      newState[phaseIndex] = {
        ...newState[phaseIndex],
        deals: items,
      };

      setPipelinePhases(newState);
    } else {
      // Moving to a different phase
      const sourcePhaseIndex = sInd;
      const destPhaseIndex = dInd;
      const {result, removed} = move(
        pipelinePhases[sourcePhaseIndex].deals,
        pipelinePhases[destPhaseIndex].deals,
        source,
        destination,
        pipelinePhases[destPhaseIndex].deals,
      );

      const newState = [...pipelinePhases];
      newState[sourcePhaseIndex] = {
        ...newState[sourcePhaseIndex],
        deals: result[sourcePhaseIndex],
      };
      newState[destPhaseIndex] = {
        ...newState[destPhaseIndex],
        deals: result[destPhaseIndex],
      };

      updateDealPhase(removed.id, pipelinePhases[destPhaseIndex].id);

      // console.log(destination,'destinationdestinationdestination')
      // console.log( pipelinePhases2[sourcePhaseIndex],' pipelinePhases2[sourcePhaseIndex]')

      // console.log(pipelinePhases2[sourcePhaseIndex].deals,'pipelinePhases2[sourcePhaseIndex].deals')
      // console.log(pipelinePhases2[destPhaseIndex],'pipelinePhases2[destPhaseIndex]')

      setPipelinePhases(newState);
    }
  };

  // console.log(pipelinePhases2, 'pipelinePhases2');
  // console.log(pipelinePhases, 'pipelinePhases2');

  const dealsEmpty = checkPipelinesDeals(pipelinePhases);

  const updateDealPhase = (dealId, phaseId) => {
    const payload = {
      deal_id: dealId,
      phase_status_id: phaseId,
    };

    console.log('payload', payload);
    commonApi
      .create_deal(payload)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    movedPhase,
  ) => {
    console.log(source, 'source');
    console.log(destination, 'destination');
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    // updateDealPhase(removed.id,movedPhase.id)

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return {result, removed};
  };
  const grid = 8;

  const getListStyle = isDraggingOver => ({
    padding: grid,
    width: 349,
  });

  const handleComponents = () => {};

  const checkChatRoom = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);

            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);

            // setChatType('personal-single');
            // setChatDetails(response.chat_detail);
            // setAllChatUsers(allChatUsers);
            // setChatRoomName(response.chat_room_name);
            // setChatRoomId(response.chat_room_id);
            setSecondaryComponent('single_chat');
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([customerData]);
            setChatRoomName('');
            setChatRoomId('');

            setSecondaryComponent('single_chat');

            // setChatType('new-personal-single');
            // setChatDetails([]);
            // setAllChatUsers([userInfo]);
            // setChatRoomName('');
            // setChatRoomId('');
            // setShowComponent('single_chat');
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const checkChatRoomForBoard = id => {
    let payLoad = {
      user_id: id,
    };
    commonApi
      .check_chat_room(payLoad)
      .then(response => {
        if (response.status === 200) {
          if (response.exist) {
            const allChatUsers = [];
            allChatUsers.push(response.chat_detail.creator_detail);
            allChatUsers.push(response.chat_detail.user_detail);

            setChatType('personal-single');
            setChatDetails(response.chat_detail);
            setAllChatUsers(allChatUsers);
            setChatRoomName(response.chat_room_name);
            setChatRoomId(response.chat_room_id);

            setShowComponent('single_chat');
          } else {
            setChatType('new-personal-single');
            setChatDetails([]);
            setAllChatUsers([customerData]);
            setChatRoomName('');
            setChatRoomId('');
            setShowComponent('single_chat');
          }
        }
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const getCustomerData = id => {
    var data = {
      user_id: id ? id : '',
    };
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setCustomerData(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const Permissions =
    menuPermesions && checkPermissions(menuPermesions, 'Funnel');

  const PipelinePermission =
    Permissions &&
    (Permissions === 'allow' || Permissions?.create_pipeline === true)
      ? true
      : false;

  console.log(Permissions, 'PipelinePermissionPipelinePermission');

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main funnel_page">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>

            {tabType === 'list' && (
              <Pannels
                isFloated={isFloated}
                toggleFloatedPanel={toggleFloatedPanel}
                showHiddenPanel={showHiddenPanel}
                updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
                customCss={dynamicStyle}
                primary={
                  <>
                    <div className="primary_panel_wraper primary_panel_content">
                      {showLoader && <LoaderSpinner />}
                      <div className="messages-header pb-1">
                        <div className="event-tabs tabs-style-2 mb-0">
                          {' '}
                          <ul className="LabelTabs">
                            <li className="ml-20 d-flex align-items-center">
                              <Button
                                className={tabType === 'list' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('list');
                                }}>
                                List
                              </Button>
                            </li>

                            <li className="ml-20 d-flex align-items-center">
                              <Button
                                className={tabType === 'board' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('board');
                                }}>
                                Board
                              </Button>
                            </li>
                          </ul>
                        </div>

                        <div className="search-tabs-icons">
                          {PipelinePermission && (
                            <span
                              className={`mt-0
                              ${
                                seconadaryComponent === 'createPipeline'
                                  ? 'icon active'
                                  : 'icon'
                              }`}>
                              <i
                                className="icon-plus"
                                onClick={() => {
                                  if (
                                    seconadaryComponent === 'createPipeline'
                                  ) {
                                    setSecondaryComponent('');
                                  } else {
                                    setSecondaryComponent('createPipeline');
                                  }
                                }}
                              />
                            </span>
                          )}

                          <span
                            onClick={() => {
                              setThreeDots(!threeDots);
                            }}
                            className={` mt-0 ${
                              threeDots ? 'icon active' : 'icon'
                            }`}>
                            <i className="fas fa-ellipsis-vertical" />
                          </span>
                        </div>
                      </div>

                      {/* {threeDots && (
                        <div className="person-search-wrapper mb-1 mt-3 messages-users-wrapper pr-2">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQry}
                                onChange={e => {
                                  setSearchQry(e.target.value);
                                  getPipelines(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Button>
                                  <i
                                    className="icon-filter-icon"
                                    onClick={() => setShowFilter(true)}
                                  />
                                </Button>
                          </div>
                        </div>
                      )} */}

                      {threeDots && pipelineList.length > 0 && (
                        <div className="roi-form-dropdown fix-width-forms mt-2 mb-1">
                          <div className="select-wrapper">
                            <Dropdown
                              onSelect={(eventKey, event) => {
                                setPieplineId(eventKey);
                                setPhaseId('');
                              }}
                              drop="down">
                              <Dropdown.Toggle variant="primary">
                                {pieplineId
                                  ? pipelineList.find(
                                      item => item.id === pieplineId,
                                    )?.title
                                  : ' '}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {pipelineList.map((item, index) => (
                                  <Dropdown.Item
                                    key={`st-${index}`}
                                    eventKey={item.id}
                                    active={item.id === pieplineId}>
                                    {item.title}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      )}
                      <div className="refferal-list-wrapper">
                        <Scrollbars autoHide
                        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                        // Hide the vertical scrollbar
                        renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
                          <div className="funnel-page-content mt-4">
                            {Object.entries(pipelinePhases).length > 0 &&
                            !showLoader ? (
                              pipelinePhases.map((phaseItem, index) => (
                                <>
                                  <h1>{phaseItem.phase}</h1>

                                  {Object.entries(phaseItem.deals).length > 0
                                    ? phaseItem.deals.map((item, index) => (
                                        <div className="payment-card-main mb-3">
                                          <div
                                            className="payment-content-card as-link"
                                            onClick={() => {
                                              setDealItem(item);
                                              setCustomerData({
                                                id: item.user_id,

                                                profile_img: item.profile_img,
                                                first_name: item.first_name,
                                                last_name: item.last_name,
                                              });

                                              setSecondaryComponent(
                                                'dealDetail',
                                              );
                                            }}>
                                            <div className="task-profile-img">
                                              <img
                                                src={
                                                  item.profile_img
                                                    ? item.profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                            </div>

                                            <div className="task-content ">
                                              <h1
                                                className="truncate_2_lines"
                                                style={{
                                                  color: '#000',
                                                  fontFamily: 'Inter',
                                                  fontSize: '16px',
                                                  fontStyle: 'normal',
                                                  fontWeight: '700',
                                                  lineHeight: 'normal',
                                                }}>
                                                {fixName(
                                                  item.first_name,
                                                  item.last_name,
                                                )}
                                              </h1>

                                              {/* <span
                                                    className="p-reg-truncate"
                                                    style={{
                                                      color: '#000',
                                                      fontFamily: 'Inter',
                                                      fontSize: '15px',
                                                      fontStyle: 'normal',
                                                      fontWeight: '400',
                                                      lineHeight: 'normal',
                                                    }}>
                                                    {item.description}
                                                  </span> */}
                                              <span
                                                className="project-title"
                                                style={{
                                                  color: '#000',
                                                  fontFamily: 'Inter',
                                                  fontSize: '14px',
                                                  fontStyle: 'normal',
                                                  fontWeight: '400',
                                                  lineHeight: 'normal',
                                                }}>
                                                {item.deal_name}
                                              </span>
                                            </div>
                                          </div>

                                          <div className="roi-service-icons">
                                            {item?.tags &&
                                              Object.entries(item.tags).length >
                                                0 &&
                                              item.tags
                                                .slice(0, 6)
                                                .map((miniItem, index) => (
                                                  <div
                                                    className="service-icon-time"
                                                    key={index}>
                                                    {miniItem.icon && (
                                                      <img
                                                        className="tags_img"
                                                        src={miniItem.icon}
                                                        alt=""
                                                      />
                                                    )}
                                                    {miniItem.title && (
                                                      <span className="tags_title">
                                                        {truncateTitle(
                                                          miniItem.title,
                                                          4,
                                                        )}
                                                      </span>
                                                    )}
                                                  </div>
                                                ))}
                                            {item?.tags?.length > 6 && (
                                              <div className="w-29">
                                                <i
                                                  className="fa-solid fa-ellipsis"
                                                  style={{
                                                    color: 'rgb(10, 10, 10)',
                                                    paddingTop: '15px',
                                                  }}></i>
                                              </div>
                                            )}
                                          </div>

                                          <div className="payment-card-bottom">
                                            <div className="payment-card-icons">
                                              <div className="payment-icon as-link">
                                                <i class="fa-solid fa-phone"></i>
                                              </div>

                                              <div
                                                className="payment-icon as-link"
                                                onClick={() => {
                                                  getCustomerData(item.user_id);
                                                  // setCustomerData({
                                                  //   user_id: item.user_id,

                                                  //   profile_img:
                                                  //     item.profile_img,
                                                  //   first_name:
                                                  //     item.first_name,
                                                  //   last_name:
                                                  //     item.last_name,
                                                  // });
                                                  checkChatRoom(item.user_id);
                                                }}>
                                                <i className="fa-solid icon-plane"></i>
                                              </div>
                                            </div>

                                            <div className="funnel-card-icons">
                                              <div
                                                className="p-bottom-icon as-link"
                                                onClick={() => {
                                                  setCustomerData({
                                                    id: item.user_id,

                                                    profile_img:
                                                      item.profile_img,
                                                    first_name: item.first_name,
                                                    last_name: item.last_name,
                                                  });

                                                  setSecondaryComponent(
                                                    'followUp',
                                                  );
                                                }}>
                                                <i
                                                  class="fa-solid fa-clock"
                                                  style={{
                                                    color: '#262626',
                                                  }}></i>
                                              </div>

                                              <div
                                                className="p-bottom-icon as-link"
                                                onClick={() => {
                                                  setCustomerData({
                                                    id: item.user_id,

                                                    profile_img:
                                                      item.profile_img,
                                                    first_name: item.first_name,
                                                    last_name: item.last_name,
                                                  });
                                                  setSecondaryComponent(
                                                    'profileNotes',
                                                  );
                                                }}>
                                                <img
                                                  src={ProfileNotesIcon}
                                                  alt=""
                                                />
                                              </div>

                                              {/* <div className="p-bottom-icon">
                                                    <img
                                                      src={dispatch}
                                                      alt=""
                                                    />
                                                  </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    : !showLoader && (
                                        <NorecordFound
                                          noRecordData={DraggableDealsNoRecord}
                                          buttonPermesion={
                                            Boolean(
                                              pipelinePermissionItem?.create_permission,
                                            )
                                              ? true
                                              : false
                                          }
                                          ButtonAction={() => {
                                            setSecondaryComponent('createDeal');
                                            setPhaseId(phaseItem.id);
                                          }}
                                         
                                        />
                                      )}
                                </>
                              ))
                            ) : (
                              <NorecordFound
                                noRecordData={PipelineNoRecordFound}
                                buttonPermesion={
                                  PipelinePermission ? true : false
                                }
                                ButtonAction={() => {
                                  if (
                                    seconadaryComponent === 'createPipeline'
                                  ) {
                                    setSecondaryComponent('');
                                  } else {
                                    setSecondaryComponent('createPipeline');
                                  }
                                  // setSecondaryComponent('createDeal');
                                  // setPhaseId(phaseItem.id);
                                }}
                              />
                            )}
                          </div>
                        </Scrollbars>
                      </div>
                    </div>
                  </>
                }
                secondary={
                  <>
                    {seconadaryComponent === 'createPipeline' && (
                      <CreatePipeLine
                        onClose={() => {
                          setSecondaryComponent('');
                        }}
                      />
                    )}

                    {seconadaryComponent === 'pipelineDetail' && (
                      <PipelineDetail
                        itemOfPipeline={pipeLineItem}
                        onClose={() => {
                          setSecondaryComponent('');
                          setPipelineItem({});
                        }}
                        pipeLinePermissions={userInfo.pipeline_permission}
                      />
                    )}

                    {seconadaryComponent === 'createDeal' && (
                      <CreateDeal
                        type={'pipeline'}
                        PieplineId={pieplineId}
                        phaseId={phaseId}
                        onClose={() => {
                          setSecondaryComponent('');
                        }}
                      />
                    )}

                    {seconadaryComponent === 'single_chat' && (
                      <SmartChatPage
                        allmsg={true}
                        type={chatType}
                        item={[]}
                        chatroomInfo={
                          chatDetails.length > 0
                            ? chatroomInfo(chatDetails)
                            : newSingleChatroom(userInfo)
                        }
                        oppoDetails={chatDetails}
                        chatDetails={chatDetails}
                        allChatUsers={allChatUsers}
                        chatRoomName={chatRoomName}
                        chatRoomId={chatRoomId}
                        onClose={() => {
                          setSecondaryComponent('');
                        }}
                        userData={customerData}
                        // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                      />
                    )}
                    {seconadaryComponent === 'profileNotes' && (
                      <ProfileNotes
                        onClose={() => setSecondaryComponent('')}
                        userData={customerData}
                      />
                    )}

                    {seconadaryComponent === 'followUp' && (
                      <ProfileNotes
                        onClose={() => setSecondaryComponent('')}
                        userData={customerData}
                        type={'follow-up'}
                      />
                    )}

                    {seconadaryComponent === 'dealDetail' && (
                      <DealDetail
                        type={'pipeline'}
                        dealItem={dealItem}
                        onClose={() => {
                          setSecondaryComponent('');
                          setDealItem({});
                        }}
                        userData={{data: customerData}}
                      />
                    )}
                  </>
                }
              />
            )}

            {tabType === 'board' && (
              <div className="task-page" style={{display: 'flex', flex: 1}}>
                <div
                  className="slider-screen-main funnel_board_view primary_panel_content"
                  style={{padding: '20px 20px 15px'}}>
                  <div className="messages-header pb-1">
                    <div className="event-tabs tabs-style-2 mb-0">
                      {' '}
                      {/* Merging */}
                      <ul className="LabelTabs">
                        <li className="ml-20 d-flex align-items-center">
                          <Button
                            className={tabType === 'list' ? 'active' : ''}
                            onClick={() => {
                              setTabType('list');
                            }}>
                            List
                          </Button>
                        </li>

                        <li className="ml-20 d-flex align-items-center">
                          <Button
                            className={tabType === 'board' ? 'active' : ''}
                            onClick={() => {
                              setTabType('board');
                            }}>
                            Board
                          </Button>
                        </li>
                      </ul>
                    </div>

                    {/* <span className="add-icon">
            <i
              className="icon-plus"
              onClick={() => setSecondComponent("group-component")}
            />
          </span>
          <span className="add-icon">
            <i
              className="icon-filter-icon"
              onClick={() => setSearchFilterModal(true)}
            />
          </span> */}

                    <div className="search-tabs-icons">
                      {PipelinePermission && (
                        <span
                          className={`mt-0 ${
                            showComponent === 'addAction'
                              ? 'icon active'
                              : 'icon'
                          }`}>
                          <i
                            className="icon-plus"
                            onClick={() => {
                              if (showComponent === 'createPipeline') {
                                setShowComponent('');
                              } else {
                                setShowComponent('createPipeline');
                              }
                            }}
                          />
                        </span>
                      )}

                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={`mt-0 ${
                          threeDots ? 'icon active' : 'icon'
                        }`}>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>

                  {threeDots && pipelineList.length > 0 && (
                    <div
                      className="roi-form-dropdown pipeline_dropdown fix-width-forms mt-2 mb-1"
                      style={{width: '379px'}}>
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setPieplineId(eventKey);
                            setPhaseId('');
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {pieplineId
                              ? pipelineList.find(
                                  item => item.id === pieplineId,
                                )?.title
                              : ' '}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {pipelineList.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id}
                                active={item.id === pieplineId}>
                                {item.title}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}

                  <div className="refferal-list-wrapper">
                    <Scrollbars autoHide renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                        // Hide the vertical scrollbar
                        renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
                      <div className="board-list-wrapper d-flex mt-4">
                        <DragDropContext onDragEnd={onDragEnd}>
                          {pipelinePhases.length > 0 ? (
                            pipelinePhases.map((el, ind) => (
                              <Droppable key={ind} droppableId={`${ind}`}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    style={getListStyle(
                                      snapshot.isDraggingOver,
                                    )}
                                    {...provided.droppableProps}>
                                    <div
                                      className={`text-center mb-3 ${
                                        Boolean(
                                          pipelinePermissionItem?.create_permission,
                                        )
                                          ? ''
                                          : 'mb-5'
                                      }`}>
                                      <h1>{el.phase}</h1>
                                    </div>

                                    {Boolean(
                                      pipelinePermissionItem?.create_permission,
                                    ) && (
                                      <div className="d-flex justify-content-center align-items-center pb-3">
                                        <div
                                          className=" add-task-btn as-link"
                                          onClick={() => {
                                            setShowComponent('createDeal');
                                            setPhaseId(el.id);
                                          }}>
                                          Add Deal
                                        </div>
                                      </div>
                                    )}

                                    {el.deals.length > 0 ? (
                                      el.deals.map((item, index) => (
                                        <Draggable
                                          key={item.id}
                                          draggableId={item.id}
                                          index={index}>
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              // style={getItemStyle(
                                              //   snapshot.isDragging,
                                              //   provided.draggableProps.style
                                              // )}
                                            >
                                              <div className="payment-card-main mb-3">
                                                <div
                                                  className="payment-content-card as-link"
                                                  onClick={() => {
                                                    // setPipelineItem(item)
                                                    // setSecondaryComponent('pipelineDetail')

                                                    setDealItem(item);
                                                    setCustomerData({
                                                      id: item.user_id,

                                                      profile_img:
                                                        item.profile_img,
                                                      first_name:
                                                        item.first_name,
                                                      last_name: item.last_name,
                                                    });

                                                    setShowComponent(
                                                      'dealDetail',
                                                    );
                                                  }}>
                                                  <div className="task-profile-img">
                                                    <img
                                                      src={
                                                        item.profile_img
                                                          ? item.profile_img
                                                          : DEFAULT_IMAGE
                                                      }
                                                      alt=""
                                                    />
                                                  </div>

                                                  <div className="task-content ">
                                                    <h1
                                                      className="truncate_2_lines"
                                                      style={{
                                                        color: '#000',
                                                        fontFamily: 'Inter',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: 'normal',
                                                      }}>
                                                      {fixName(
                                                        item.first_name,
                                                        item.last_name,
                                                      )}
                                                    </h1>

                                                    {/* <span
                                                    className="p-reg-truncate"
                                                    style={{
                                                      color: '#000',
                                                      fontFamily: 'Inter',
                                                      fontSize: '15px',
                                                      fontStyle: 'normal',
                                                      fontWeight: '400',
                                                      lineHeight: 'normal',
                                                    }}>
                                                    {item.description}
                                                  </span> */}
                                                    <span
                                                      className="project-title"
                                                      style={{
                                                        color: '#000',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                      }}>
                                                      {item.deal_name}
                                                    </span>
                                                  </div>
                                                </div>

                                                <div className="roi-service-icons">
                                                  {item?.tags &&
                                                    Object.entries(item.tags)
                                                      .length > 0 &&
                                                    item.tags
                                                      .slice(0, 6)
                                                      .map(
                                                        (miniItem, index) => (
                                                          <div
                                                            className="service-icon-time"
                                                            key={index}>
                                                            {miniItem.icon && (
                                                              <img
                                                                className="tags_img"
                                                                src={
                                                                  miniItem.icon
                                                                }
                                                                alt=""
                                                              />
                                                            )}
                                                            {miniItem.title && (
                                                              <span className="tags_title">
                                                                {truncateTitle(
                                                                  miniItem.title,
                                                                  4,
                                                                )}
                                                              </span>
                                                            )}
                                                          </div>
                                                        ),
                                                      )}
                                                  {item?.tags?.length > 6 && (
                                                    <div className="w-29">
                                                      <i
                                                        className="fa-solid fa-ellipsis"
                                                        style={{
                                                          color:
                                                            'rgb(10, 10, 10)',
                                                          paddingTop: '15px',
                                                        }}></i>
                                                    </div>
                                                  )}
                                                </div>

                                                <div className="payment-card-bottom">
                                                  <div className="payment-card-icons">
                                                    <div className="payment-icon as-link">
                                                      <i class="fa-solid fa-phone"></i>
                                                    </div>

                                                    <div className="payment-icon as-link">
                                                      <i
                                                        className="fa-solid icon-plane"
                                                        onClick={() => {
                                                          getCustomerData(
                                                            item.user_id,
                                                          );
                                                          // setCustomerData({
                                                          //   user_id: item.user_id,

                                                          //   profile_img:
                                                          //     item.profile_img,
                                                          //   first_name:
                                                          //     item.first_name,
                                                          //   last_name:
                                                          //     item.last_name,
                                                          // });
                                                          checkChatRoomForBoard(
                                                            item.user_id,
                                                          );
                                                        }}></i>
                                                    </div>
                                                  </div>

                                                  <div className="funnel-card-icons">
                                                    <div
                                                      className="p-bottom-icon as-link"
                                                      onClick={() => {
                                                        setCustomerData({
                                                          id: item.user_id,

                                                          profile_img:
                                                            item.profile_img,
                                                          first_name:
                                                            item.first_name,
                                                          last_name:
                                                            item.last_name,
                                                        });

                                                        setShowComponent(
                                                          'followUp',
                                                        );
                                                      }}>
                                                      <i
                                                        class="fa-solid fa-clock"
                                                        style={{
                                                          color: '#262626',
                                                        }}></i>
                                                    </div>

                                                    <div
                                                      className="p-bottom-icon as-link"
                                                      onClick={() => {
                                                        setCustomerData({
                                                          id: item.user_id,

                                                          profile_img:
                                                            item.profile_img,
                                                          first_name:
                                                            item.first_name,
                                                          last_name:
                                                            item.last_name,
                                                        });
                                                        setShowComponent(
                                                          'profileNotes',
                                                        );
                                                      }}>
                                                      <img
                                                        src={ProfileNotesIcon}
                                                        alt=""
                                                      />
                                                    </div>

                                                    {/* <div className="p-bottom-icon">
                                                    <img
                                                      src={dispatch}
                                                      alt=""
                                                    />
                                                  </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))
                                    ) : (
                                      <NorecordFound
                                        className="draggableWidth"
                                        noRecordData={DraggableDealsNoRecord}
                                        buttonPermesion={
                                          Boolean(
                                            pipelinePermissionItem?.create_permission,
                                          )
                                            ? true
                                            : false
                                        }
                                        ButtonAction={() => {
                                          setShowComponent('createDeal');
                                          setPhaseId(el.id);
                                        }}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            ))
                          ) : (
                            <NorecordFound
                              noRecordData={PipelineNoRecordFound}
                              buttonPermesion={
                                PipelinePermission ? true : false
                              }
                              ButtonAction={() => {
                                if (showComponent === 'createPipeline') {
                                  setShowComponent('');
                                } else {
                                  setShowComponent('createPipeline');
                                }
                                
                                // setSecondaryComponent('createDeal');
                                // setPhaseId(phaseItem.id);
                              }}
                              firstDivStyle={{width:'371px'}}
                            />
                          )}
                        </DragDropContext>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            )}
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}

            {showComponent === 'createPipeline' && (
              <CreatePipeLine
                onClose={() => {
                  setShowComponent('');
                }}
                closeIconRight={true}
              />
            )}

            {showComponent === 'createDeal' && (
              <CreateDeal
                type={'pipeline'}
                PieplineId={pieplineId}
                onClose={() => {
                  setShowComponent('');
                }}
                phaseId={phaseId}
                closeIconRight={true}
              />
            )}

            {showComponent === 'single_chat' && (
              <SmartChatPage
                allmsg={true}
                type={chatType}
                item={[]}
                chatroomInfo={
                  chatDetails.length > 0
                    ? chatroomInfo(chatDetails)
                    : newSingleChatroom(userInfo)
                }
                oppoDetails={chatDetails}
                chatDetails={chatDetails}
                allChatUsers={allChatUsers}
                chatRoomName={chatRoomName}
                chatRoomId={chatRoomId}
                onClose={() => {
                  setShowComponent('');
                }}
                userData={customerData}
                closeIconRight={true}
                // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
              />
            )}
            {showComponent === 'profileNotes' && (
              <ProfileNotes
                onClose={() => setSecondaryComponent('')}
                userData={customerData}
                closeIconRight={true}
              />
            )}

            {showComponent === 'followUp' && (
              <ProfileNotes
                onClose={() => setSecondaryComponent('')}
                userData={customerData}
                type={'follow-up'}
                closeIconRight={true}
              />
            )}

            {showComponent === 'dealDetail' && (
              <DealDetail
                type={'pipeline'}
                dealItem={dealItem}
                onClose={() => {
                  setShowComponent('');
                  setDealItem({});
                }}
                userData={{data: customerData}}
                closeIconRight={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pipeline;
