import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import LoaderSpinner from '../Modals/LoaderSpinner';
import NorecordFound from '../NoRecord/NorecordFound';
import { DealsNoRecordFound } from '../../Utils/commonUtils';
import { getDealsData } from '../../Store/Actions/ProfileAction';


const ProfileDeals = props => {



  const [dealItem, setDealItem] = useState({});
  const [showLoader, setshowLoader] = useState(true);
  const dealsList = useSelector(state => state.dealList);
  const updateData = useSelector(state => state.updateDealData);
  const dispatch = useDispatch();

  const getDeals = () => {

    var payload = {
      user_id : props.userData.data.id
    }
    const res = dispatch(getDealsData(payload));

    if (res) {
      setTimeout(() => {
        setshowLoader(false);
      }, 500);
    }
    
  };

  useEffect(() => {
    getDeals();
  }, [props.userData]);

  useEffect(() => {
    if (Object.keys(dealItem).length > 0 && updateData) {
      dealsList.forEach(deal => {
        if (deal.id === dealItem.id) {
          setDealItem(deal);
          props.handleCommand(deal, 'dealDeatil');
        }
      });
    }
  }, [dealsList]);

  // useEffect(() => {
    
  // }, []);

  // console.log(dealsList,'dealsList')
  // console.log(updateData,'updateData')
  return (
    <div className="refferal-list-wrapper mt-4">
    {showLoader && (
      <div className="loader_hieght">{showLoader && <LoaderSpinner />}</div>
    )}

    {Object.entries(dealsList).length > 0 && !showLoader 
      ? dealsList.map((item, index) => (
          <div
            key={`pipeline_${index}`}
            className="refferals-list action-listing as-link"
            style={{border: '1px solid #f1eded'}}
            onClick={() => {
              props.handleCommand(item, 'dealDeatil');
              setDealItem(item);
            }}>
            <div className="task-item">
              <div className="d-flex justify-content-between">
                <h2 className="truncate_2_lines actions-title mb-2">
                  {item.deal_name}
                </h2>

                
              </div>

              <p className="p-reg m-0 mb-2 p-reg-truncate">{item.description}</p>
              <div
                className="d-flex align-items-center justify-content-between"
                style={{alignItems: 'center'}}>
                <div className="d-flex align-items-center">
                  <p className="p-reg m-0 actionsCategories single-line-text">
                    Catalogs: {item.catalog_details.map(catalog => catalog.title).join(', ')}
                  </p>
                </div>

                <div className="arrow-up"></div>
              </div>
            </div>
          </div>
        ))
      : !showLoader && (
          <NorecordFound
            noRecordData={DealsNoRecordFound}
            buttonPermesion={
              // props.userData.pipeline_permission.create_pipeline
              //   ? true
              //   : false
              true
            }
            ButtonAction={() => {
              props.handleCommand('', 'createDeal');
            }}
          />

          // <div className="no-results-wrapper payment-no-results no-record-notification">
          //   {/* <div className="img-bg-wrapper">
          //                     <img
          //                       src={require('../../Assets/images/norecord/no-actions.png')}
          //                       alt=""
          //                     />
          //                   </div> */}

          //   <div className="no-results-content">
          //     <p>No PipeLines found</p>
          //     <div className="btn-wrapper mt-3">
          //       <Button
          //         className="btn btn-dark"
          //         onClick={() => {
          //           props.handleCommand('', 'pipeline');
          //         }}>
          //         Create Pipeline
          //       </Button>
          //     </div>
          //   </div>
          // </div>
        )}
  </div>
  );
};

export default ProfileDeals;
