
import commonApi from '../../Services/CommonService';

export const getDispatch = (type, data) => ({
    type: type,
    payload: data,
  });

export const getCompanyTransformationList = data => {
    return async dispatch => {
      const response = await  commonApi
      .get_company_transformation(data);
      dispatch(getDispatch('GET_COMPANY_TRANSFORMATION_LIST', response.transformation ));
      return response;
    };
  };