import React, {useState} from 'react';
// import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Slider from 'react-slick';

// import { TicketDateOption } from "../../Components";
// import {
//     eventImg,
//     avtarImg,
//     sessionUser1,
//     sessionUser2,
//     sessionUser3,
//     sessionUser4,
//     sessionUser5,
//     sessionUser6,
//     sessionUser7,
//     sessionUser8,
//     sessionUser9,
//     sessionUser10,
//     sessionUser11,
//     sessionUser12,
// } from "../../Constants/Images";
// import { labels } from "../../Constants/Translations";
// import { selected_lang } from "../../Utils/env";

const ShowEventsDetails = props => {
  const [showmore, setShowMore] = useState(false);
  // const [repeateOption, setRepeatOption] = useState(false);
  // const [chooseCircle, setChooseCircle] = useState(false);
  // const [tabType, setTabType] = useState("general");
  // const [hostImg, setHostImg] = useState("");
  // const [ticketImg, setTicketImg] = useState("");
  // const [subTabs, setSubTabs] = useState("moderator");

  const imgSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const timeSliderSettings = {
  //     dots: false,
  //     arrows: false,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  // };

  // const changeTicketImg = (e) => {
  //     const filePath = e.target.files[0];
  //     const objectUrl = URL.createObjectURL(filePath);

  //     const imgTypes = ["image/gif", "image/jpeg", "image/png"];

  //     if (imgTypes.includes(filePath.type)) {
  //         if (filePath.size <= 1000000) {
  //             setTicketImg(objectUrl);
  //         } else {
  //             // Error :  Image size must be less then 1MB.
  //         }
  //     } else {
  //         // Error :  Invalid Image Type.
  //     }
  // };
  // const changeHostImg = (e) => {
  //     const filePath = e.target.files[0];
  //     const objectUrl = URL.createObjectURL(filePath);

  //     const imgTypes = ["image/gif", "image/jpeg", "image/png"];

  //     if (imgTypes.includes(filePath.type)) {
  //         if (filePath.size <= 1000000) {
  //             setHostImg(objectUrl);
  //         } else {
  //             // Error :  Image size must be less then 1MB.
  //         }
  //     } else {
  //         // Error :  Invalid Image Type.
  //     }
  // };

  return (
    <div className="content-sidebar">
      <div className="events-wrapper">
        <div className="events-img-slider">
          <Slider {...imgSliderSettings}>
            <img src={props.showDetails.image.slider_images} alt="" />
          </Slider>
        </div>

        <div className="events-icons-wrapper">
          <div className="left-icons">
            <span className="icon-globe"></span>
            <span className="icon-car"></span>
          </div>
          <div className="right-icons">
            <span
              className={
                props.showDetails.favourite ? 'fas fa-heart' : 'far fa-heart'
              }></span>
            <span className="icon-share-option"></span>
          </div>
        </div>

        <div className="events-headings">
          <h2>{props.showDetails.session_title}</h2>
          <span className="category-name">
            {props.showDetails.category_name}
          </span>
        </div>

        {/* <div className="events-time-wrapper">
                    <Slider {...timeSliderSettings}>
                        <div className="events-time-slide">
                            <div className="events-time-item">
                                <span className="day">Friday</span>
                                <span className="date">1</span>
                                <span className="month-year">Aug '22</span>
                                <span className="time">10 PM - 3 AM</span>
                            </div>
                        </div>
                        <div className="events-time-slide">
                            <div className="events-time-item selected">
                                <span className="day">Friday</span>
                                <span className="date">1</span>
                                <span className="month-year">Aug '22</span>
                                <span className="time">10 PM - 3 AM</span>
                            </div>
                        </div>
                        <div className="events-time-slide">
                            <div className="events-time-item">
                                <span className="day">Friday</span>
                                <span className="date">1</span>
                                <span className="month-year">Aug '22</span>
                                <span className="time">10 PM - 3 AM</span>
                            </div>
                        </div>
                        <div className="events-time-slide">
                            <div className="events-time-item">
                                <span className="day">Friday</span>
                                <span className="date">1</span>
                                <span className="month-year">Aug '22</span>
                                <span className="time">10 PM - 3 AM</span>
                            </div>
                        </div>
                    </Slider>
                </div> */}

        <div className="events-desc-wrapper">
          <p>{props.showDetails.session_description}</p>
          <span className="show-more" onClick={() => setShowMore(!showmore)}>
            {showmore ? 'Show Less' : 'Show More'}
          </span>

          <span className="event-address">{props.showDetails.address}</span>

          {/* <div className="message-other-users">
                        <span className="icon-plane" />

                        <div className="users-imgs">
                            <img src={sessionUser1} alt="" />
                            <img src={sessionUser2} alt="" />
                            <img src={sessionUser3} alt="" />
                        </div>
                    </div> */}

          {/* <div className="event-happening">
                    <span>Going(20)</span>
                    <span>
                        <i className="icon-tickets" /> MY TICKET(S)
                    </span>
                </div>
                <div className="event-btn-wrapper">
                    <span>Free - $45</span>

                    <select>
                        <option>$1-$10</option>
                        <option>$1-$45</option>
                    </select>

                    <Button>Choose Ticket</Button>
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default ShowEventsDetails;
