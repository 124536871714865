import React, {useEffect, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const GenderListItems = ({items, active, showTooltip, updateActive}) => {
  const [activeItems, setactiveItems] = useState([]);

  useEffect(() => {
    setactiveItems(active);
  }, [active]);

  return (
    <ul>
      {items.length > 0 &&
        items.map((item, index) => {
          const isActive = activeItems.includes(item.id) ? 'active' : '';
          return (
            <li
              className={isActive}
              key={`boarding-item-${item.id}-${index}`}
              onClick={() => updateActive(item.id)}>
              {showTooltip && item.description !== '' && (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{item.description}</Tooltip>}>
                  <span>{item.name}</span>
                </OverlayTrigger>
              )}
              {!showTooltip && item.name}

              {activeItems.includes(item.id) && (
                <span className="check-icon">
                  <i className="fas fa-check" />
                </span>
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default GenderListItems;
