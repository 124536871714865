import React from 'react';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';


//import { Breadcrumb, Header } from "../Components";
import Sidebar from './Sidebar';

const Home = ({location, menuState}) => {

  return (
    <div className={`page-wrapper menu-open`}>
      <Sidebar location={location} />

      <div className="page-content-wrapper auth-page-wrapper">
        <div className="page-not-found-content">
          <h2>404</h2>
          <h3>Opps! Page Not Found</h3>
          <p>The page you are looking for is not available.</p>
          <Link to="/">
            <Button className="btn-dark">Visit Home</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
});

export default connect(mapStateProps, {})(Home);




