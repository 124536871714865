import React from 'react';
import {useState, useEffect} from 'react';

import img from '../../Assets/images/slider-img.jpeg';
import Slider from 'react-slick';
import {parseUrl} from '../../Utils/commonUtils';
import { useSelector } from 'react-redux';
// import commonApi from '../../Services/CommonService';



const RenderTransformations = (props) => {
  // const [transformationList, setTransformationList] = useState([]);
  const transformationList = useSelector(state => state.companyTransformationList);
  const {pathname, search} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const urlString = String(window.location);
  const urlData = parseUrl(urlString);
  

  const allProductSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
 
  };



  useEffect(() => {
    get_company_transformation(urlData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_company_transformation = (urlData) => {
    // const filteredArray =
    //   transformation_Filter.length > 0 &&
    //   transformation_Filter.filter(item => item !== 'All');

    const payload = {
      user_id:  /^\d+$/.test(urlParts[0]) && !urlParts[1] ? urlParts[0] : /^\d+$/.test(urlData.userId) ? urlData.userId : '' ,
      category_ids: [],
      company_name: !/^\d+$/.test(urlParts[0]) ? urlParts[0].replace('-', ' ') : '',
    };
    // commonApi
    //   .get_company_transformation(payload)
    //   .then(res => {
    //     if (res.status === 200) {
    //       setTransformationList(res.transformation);
    //       props.setDataofTransFormation(res.transformation)
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };




  return (
    <div className="mt-4">
      {/* <Slider {...allProductSlider}>
        {transformationList.length > 0 &&
          transformationList.map((item, index) => (
            <div className="slider-image mr-1" key={`f-item-${index}`}>
              <div className='d-flex'>
              <img src={item.before_images[0]} alt="" />
              <img src={item.after_images[0]} alt="" />

              </div>
            </div>
          ))}
      </Slider> */}


       <div className="mt-4 profile_tranformation_slider" style={{height : transformationList.length > 0 ? '170px' : ''}}>
              {transformationList.length > 0 ? (  <Slider {...allProductSlider}>
                  {transformationList.length > 0 &&
                    transformationList.slice(0,7).map((item, index) => (
                      <div className="" onClick={()=>{
                       
                        props.setShowModal('tranformationDetail')
                        props.setDataofTransFormation(item)

                      }}>
                        <div className='d-flex justify-content-between slider-image '>
                        <img src={item.before_images[0]} alt="" />
                        <img src={item.after_images[0]} alt="" />
                        </div>
                        
                      </div>
                    ))}
                </Slider>) : (
                 <div className="no-results-wrapper no-appointments my-5">
                 <div className="no-results-content">
                   <p className={'noRecord-desc'}>No record found</p>
                 </div>
               </div>
                )}
              </div>
    </div>
  );
};

export default RenderTransformations;
