import React, {useState, useEffect, useRef} from 'react';
import {
  Button,
  Form,
  OverlayTrigger,
  Popover,
  Modal,
  Tooltip,
} from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

import {ref, push, onValue, update} from 'firebase/database';
import {db, storage, storageRef} from '../../Firebase/firebase';
import {uploadBytes, getDownloadURL} from 'firebase/storage';
import {
  getDateFormatForChat,
} from '../../Services/AppServices';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import LoaderModal from '../Modals/LoaderModal';
import SnapshotList from '../Snapshot/SnapshotList';
import SnapshotDetail from '../Snapshot/SnapshotDetail';
import {DEFAULT_IMAGE, S3_EMOJI_DEV} from '../../Utils/env';
import ListUsers from './ListUsers';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import EmojiModal from '../SessionDetails/EmojiModal';
import {emojisArr} from '../../Constants/IonicEmojis';
import CreateGroup from '../Messages/CreateGroup';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import ImageLightbox from '../ImageLightbox';
import OpportunityCall from '../OpportunitiesList/OpportunityCall';
import SmartChatBox from './SmartChatBox';
// import { allUsers } from "../../Constants/allUsers";
// import ReplyList from "./ReplyList";
import SmartReplyList from './SmartReplyList';
import CreatePoll from '../Questions/CreatePoll';
import {arrayColumnMerge, filteringUsers} from '../../Utils/commonUtils';
import CustomModal from '../CustomModal';
import SlidingPanel from '../SlidingPanel';
import {fixName} from '../../Utils/commonUtils';
import {connect} from 'react-redux';

const CommonChatPage = props => {
  const [userData, setUserData] = useState({});

  const [otherMsg, setOtherMsg] = useState('');
  const [msgChildKey, setMsgChildKey] = useState('');
  const [msgParentKey, setMsgParentKey] = useState('');
  const [emojiToMsg, setEmojiToMsg] = useState({});
  const [msgForReply, setMsgForReply] = useState({});

  const [showEmojis, setShowEmojis] = useState(false);
  const [emojiList, setEmojiList] = useState([]);

  const [showLoader, setshowLoader] = useState(false);
  const [addSnapshot, setAddSnapshot] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState('');
  const [snapshotIds, setSnapshotIds] = useState([]);
  const [snapshotImages, setSnapshotImages] = useState([]);
  const [showSnapshotDetail, setShowSnapshotDetail] = useState(false);
  const [snapshotData, setSnapshotData] = useState({});
  const [showEmojiModal, setshowEmojiModal] = useState(false);

  const [allChat, setallChat] = useState([]);
  const [chatId, setchatId] = useState(props.chatRoomId);
  const [isNewChatroom, setNewChatroom] = useState(props.chatRoomName);
  const [chatRoomStatus, setChatroomStatus] = useState(
    props.hasOwnProperty('chatRoomStatus')
      ? props.chatRoomStatus
      : props.chatDetails.hasOwnProperty('chat_room_status')
      ? props.chatDetails.chat_room_status
      : props.chatDetails.hasOwnProperty('status')
      ? props.chatDetails.status
      : 'open',
  );

  const [selectedImage, setSelectedImage] = useState(null);
  const [showUsers, setShowUsers] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [pollParticipants, setPollParticipants] = useState([]);

  const [imageSrc, setImageSrc] = useState(null);
  const [webcamStream, setWebcamStream] = useState(null);

  const [createPoll, setCreatePoll] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [oppoCall, setOppoCall] = useState(false);
  const [oppoGroupChat, setOppoGroupChat] = useState(false);
  const [modalEndChat, setModalEndChat] = useState(false);
  const [writtenMsg, setWrittenMsg] = useState('');
  const [isReply, setIsReply] = useState(false);
  const [isReplies, setIsReplies] = useState(false);
  const [showPrivate, setPrivateUser] = useState(false);
  const [messageBodyNew, setMessageBodyNew] = useState({});

  const [marksBest, setMarksBest] = useState(false);
  const [replyOptionObj, setReplyOptionObj] = useState(null);
  const [popupImages, setPopupImages] = useState([]);
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(0);

  const [chatIsEnable, setChatEnable] = useState(true);
  const [chatEnableStatus, setChatEnableStatus] = useState(
    props.chatRoomStatus,
  );

  const [isExtraClose, setExtraClose] = useState(false);
  const [cameraOptions, toggleCameraOptions] = useState(false);
  const [replyOptions, toggleReplyOptions] = useState(false);

  const [handleData, setHandleData] = useState({
    key: '',
    item: {},
    cKey: '',
    pKey: '',
  });

  const messagesEndRef = useRef(null);
  const webcamRef = useRef(null);

  const [messageBody, setMessageBody] = useState({
    message: '',
    picture_url: '',
    document_url: '',
    video_url: '',
    type: 'message',
    isPrivate: false,
    privateChatData: {
      first_name: '',
      last_name: '',
      profile_img: '',
      sent_to: '',
    },
    sent_by: localStorage.getItem('user'),
    send_date: Date.now(),
  });

  // const [xchatStatusIn, setxChatStatusIn] = useState(["accepted", "open", "join"]);
  const [xfbFolder, setxFbFolder] = useState(
    props.type.toLowerCase().indexOf('oppo') !== -1 ? 'opportunities' : 'chats',
  );
  const [xchatDetail, setxChatDetail] = useState(props.chatDetails);
  // const [xchatRoomStatus, setxChatRoomStatus] = useState();
  const [xotherUser, setxOtherUser] = useState(
    props.chatDetails.creator_id === localStorage.getItem('user')
      ? 'user_detail'
      : props.chatDetails.hasOwnProperty('creator_details')
      ? 'creator_details'
      : 'creator_detail',
  );

  const [xchatType, setxChatType] = useState(props.type);
  const [chatRoomName, setChatRoomName] = useState(
    props.chatRoomName
      ? props.chatRoomName
      : 'chatroom_' + (Math.random() + 1).toString(36).substring(3),
  );

  const inputRef = useRef(null);

  const chatStatusIn = ['accepted', 'open', 'join', 'pending', 'started'];
  // const fbFolder = (props.type === 'new-opportunity') ? "opportunities" : ((props.chatDetails.length > 0 && !(props.chatDetails.hasOwnProperty("chat_type"))) || props.chatDetails.chat_type === "opportunity") ? "opportunities" : "chats";
  // const creatorDetail = props.chatDetails.hasOwnProperty('creator_details')
  //   ? 'creator_details'
  //   : 'creator_detail';
  // const chatRoomStatus = (props.chatDetails.chat_type === "opportunity") ? props.oppoDetails.other_persons_opportunity_chat_room[0].status : props.chatDetails.hasOwnProperty('chat_room_status') ? props.chatDetails.chat_room_status : props.chatDetails.status;
  // const chatRoomStatus = props.hasOwnProperty("chatRoomStatus")
  //   ? props.chatRoomStatus
  //   : props.chatDetails.hasOwnProperty("chat_room_status")
  //   ? props.chatDetails.chat_room_status
  //   : props.chatDetails.hasOwnProperty("status")
  //   ? props.chatDetails.status
  //   : "open";
  // const otherUser = (props.chatDetails.creator_id == localStorage.getItem("user")) ? "user_detail" : props.chatDetails.hasOwnProperty('creator_details') ? 'creator_details' : 'creator_detail';
  // const chatRoomName = (props.chatRoomName) ? props.chatRoomName : ("chatroom_" + (Math.random() + 1).toString(36).substring(3));
  const opportunityId = props.oppoDetails.hasOwnProperty('opportunity_id')
    ? props.oppoDetails.opportunity_id
    : props.oppoDetails.id;
  const oppoCreatorDetail = props.oppoDetails.hasOwnProperty('creator_details')
    ? 'creator_details'
    : 'creator_detail';
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const startWebCam = () => {
    // request access to the user's webcam
    navigator.mediaDevices.getUserMedia({video: true}).then(stream => {
      // save the webcam stream to state
      setWebcamStream(stream);
      // navigator.attachMediaStream(webcamRef.current, stream);
      setTimeout(() => {
        webcamRef.current.srcObject = stream;
      }, 1000);

      
    });
  };

  const captureImage = () => {
    // create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // set the canvas size to the size of the video
    canvas.width = webcamStream.getTracks()[0].getSettings().width;
    canvas.height = webcamStream.getTracks()[0].getSettings().height;

    // draw the video on the canvas
    context.drawImage(webcamRef.current, 0, 0);

    // convert the canvas to an image
    const img = new Image();
    img.src = canvas.toDataURL();
    setImageSrc(img.src);

    canvas.toBlob(blob => {
      makeFile(blob);
    });
  };

  const handleReplyOptions = (option, signalData, childKey, parentKey) => {
    toggleReplyOptions(false);
    
    if (option === 'add-replies') {
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setMsgForReply(signalData);
      setIsReplies(true);
    } else if (option === 'add-emoji') {
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setEmojiToMsg(signalData);
      setshowEmojiModal(true);
    } else if (option === 'best-answer') {
      markAsBest(parentKey, childKey, {isCorrect: true}, signalData);
    }
  };

  const handleChatBoxSignal = (signalType, signalData, childKey, parentKey) => {
    if (signalType === 'replyOptions') {
      
      setMarksBest(!signalData.isCorrect);
      setReplyOptionObj({
        signalType: signalType,
        signalData: signalData,
        childKey: childKey,
        parentKey: parentKey,
      });
      toggleReplyOptions(!replyOptions);
    } else if (signalType === 'update-poll') {
      // setMsgChildKey(childKey);
      // setMsgParentKey(parentKey);
      // setPollToMsg(signalData);
      handlePoll(signalData, childKey, parentKey);
      // setEmojiToMsg(signalData);
      // setshowEmojiModal(true);
    } else if (signalType === 'add-replies') {
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setMsgForReply(signalData);
      setIsReplies(true);
    } else if (signalType === 'add-reply') {
      setMessageBody({...messageBody, reply: signalData});
      setIsReply(true);
    } else if (signalType === 'show-snapshot-detail') {
      getSpecificAccomplishment(signalData);
    } else if (signalType === 'add-emoji') {
      
      setMsgChildKey(childKey);
      setMsgParentKey(parentKey);
      setEmojiToMsg(signalData);
      setshowEmojiModal(true);
    } else if (signalType === 'show-emojis') {
      setEmojiList(signalData);
      setShowEmojis(true);
    } else if (signalType === 'mention-user') {
      handleMentionUsers(signalData);
    } else if (signalType === 'remove-reply') {
      setMsgChildKey('');
      setMsgParentKey('');
      setMsgForReply({});
      setIsReplies(false);
    } else if (signalType === 'show-replies') {
      // setMsgChildKey(childKey);
      // setMsgParentKey(parentKey);
      // setMsgForReply(signalData);
      setHandleData({
        key: '',
        item: signalData,
        cKey: childKey,
        pKey: parentKey,
      });
      setShowReplies(true);
    } else if (signalType === 'best-answer') {
      markAsBest(parentKey, childKey, {isCorrect: true}, signalData);
    } else if (signalType === 'show-participants') {
      const chatData = signalData.message.options;
      const participantIds = arrayColumnMerge(chatData, 'userIds');
      setPollParticipants(filteringUsers(props.allChatUsers, participantIds));
      setShowParticipants(true);
    } else if (signalType === 'show-profile') {
      setShowUserProfile(signalData.id);
    }
  };

  const handleCreatePoll = msg => {
    setMessageBody({...messageBody, message: msg, type: 'poll'});
    setMessageBodyNew({...messageBody, message: msg, type: 'poll'});
  };

  const showLightbox = (imagez, current) => {
    // 

    const tempImgs = [{id: 0, image: URL.createObjectURL(selectedImage)}];

    // for (let i = 0; i < imagez.length; i++) {
    //     tempImgs.push({
    //         id: i,
    //         image: imagez[i].images[0],
    //     });
    // }

    setShow(true);
    setPopupImages(tempImgs);
    // setCurrent(current);
  };

  const handleSelect = e => {
    
    if (e === '2') {
      inputRef.current.click();
    } else if (e === '3') {
      setOtherMsg('Snapshot');
      setAddSnapshot(true);
    } else if (e === '1') {
      startWebCam();
    }
  };

  const makeFile = data => {
    const type = data.type.toString();
    if (type.search('image') === 0) {
      if (data) {
        setSelectedImage(data);
        setOtherMsg('Image');
      }
    }
    if (type.search('video') === 0) {
      if (data) {
        setSelectedImage(data);
        setOtherMsg('Video');
      }
    }
    if (
      type.search('document') === 0 ||
      type.search('pdf') === 0 ||
      type.search('application') === 0
    ) {
      if (data) {
        setSelectedImage(data);
        setOtherMsg('Document');
      }
    }
  };

  const clearData = () => {
    setSelectedImage(null);
    setMessageBody({
      message: '',
      picture_url: '',
      document_url: '',
      video_url: '',
      type: 'message',
      isPrivate: false,
      privateChatData: {
        first_name: '',
        last_name: '',
        profile_img: '',
        sent_to: '',
      },
      sent_by: localStorage.getItem('user'),
      send_date: Date.now(),
    });
    setSnapshotIds([]);
    setSnapshotImages([]);
    setPrivateUser(false);
  };

  const uploadFile = () => {
    var type;
    if (selectedImage) {
      type = selectedImage.type.toString();
      
    }
    if (type.search('video') === 0) {
      if (selectedImage) {
        const fileName =
          'video_' +
          Math.floor(Date.now() / 1000).toString() +
          selectedImage.name;
        const fileRef = storageRef(storage, 'videos/' + fileName);
        uploadBytes(fileRef, selectedImage).then(res => {
          
          getDownloadURL(res.ref).then(downloadURL => {
            
            
            setMessageBody({
              ...messageBody,
              type: 'video',
              video_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'video',
              video_url: downloadURL,
            });
          });
        });
      }
    }
    if (type.search('image') === 0) {
      if (selectedImage) {
        const fileName =
          'image_' + Math.floor(Date.now() / 1000).toString() + '.png';
        const fileRef = storageRef(storage, 'images/' + fileName);
        uploadBytes(fileRef, selectedImage).then(res => {
          getDownloadURL(res.ref).then(downloadURL => {
            setMessageBody({
              ...messageBody,
              type: 'image',
              picture_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'image',
              picture_url: downloadURL,
            });
          });
        });
      }
    }
    if (
      type.search('document') === 0 ||
      type.search('pdf') === 0 ||
      type.search('application') === 0
    ) {
      if (selectedImage) {
        const fileName = 'document_' + Math.floor(Date.now() / 1000).toString();
        const fileRef = storageRef(storage, 'documents/' + fileName);
        
        uploadBytes(fileRef, selectedImage).then(res => {
          
          getDownloadURL(res.ref).then(downloadURL => {
            
            
            setMessageBody({
              ...messageBody,
              type: 'document',
              document_url: downloadURL,
            });
            setMessageBodyNew({
              ...messageBody,
              type: 'document',
              document_url: downloadURL,
            });
          });
        });
      }
    }
  };

  const sendSnapshot = () => {
    let images = [];
    snapshotIds.forEach((item, index) => {
      images.push({
        id: item,
        images: [snapshotImages[index]],
      });
    });
    setMessageBody({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
    setMessageBodyNew({
      ...messageBody,
      type: 'snapshot',
      picture_url: images,
    });
  };

  const saveMessage = () => {
    if (selectedImage) {
      setshowLoader(true);
      uploadFile();
      setWrittenMsg('');
      setIsReply(false);
    } else if (snapshotIds.length > 0) {
      sendSnapshot();
      setWrittenMsg('');
      setIsReply(false);
    } else if (writtenMsg.trim().length !== 0) {
      sendMessage();
      setWrittenMsg('');
      setIsReply(false);
    } else {
      // 
      setWrittenMsg('');
      setMessageBody({...messageBody, message: ''});
      errorMessage("Can't send empty messsage.");
    }
  };

  const sendEmoji = emojiMessage => {
    // let msgBody = emojiToMsg;
    // msgBody.emoji = "ja raha ha";
    const databaseRef = ref(
      db,
      'chatrooms/' +
        xfbFolder +
        '/' +
        chatRoomName +
        '/' +
        msgParentKey +
        '/' +
        msgChildKey,
    );
    update(databaseRef, emojiMessage)
      .then(() => {
        openChatroom(chatRoomName);
        setMsgChildKey('');
        setMsgParentKey('');
        setEmojiToMsg({});
        setshowEmojiModal(false);
        // setshowLoader(false);
      })
      .then(() => {
        // clearData();
      });
  };

  const markAsBest = (pKey, cKey, msg, markMsg) => {
    let payload = {
      best_ans: markMsg.type,
      best_ans_user: markMsg.sent_by,
      question_id: props.item.id,
    };
    if (markMsg) {
      if (markMsg.type === 'message') {
        payload.best_ans = markMsg.message;
      }

      commonApi.mark_as_best(payload);
    }

    // remove old mark
    if (localStorage.getItem('isCorrectPath')) {
      const databaseRefOld = ref(
        db,
        'chatrooms/' +
          xfbFolder +
          '/' +
          chatRoomName +
          '/' +
          localStorage.getItem('isCorrectPath'),
      );
      update(databaseRefOld, {isCorrect: false});
    }

    // add new mark
    const databaseRef = ref(
      db,
      'chatrooms/' + xfbFolder + '/' + chatRoomName + '/' + pKey + '/' + cKey,
    );
    update(databaseRef, msg).then(() => {
      openChatroom(chatRoomName);
    });
  };

  const handleAddEmoji = icon => {
    

    const myArray = icon.split('/').slice(-2);
    const skinTone = myArray[0];
    const emojiName = myArray[1].split('.', 1).toString();
    const found = emojisArr.find(objs => objs.name === emojiName);
    const data = {
      emojiID: found.id,
      emojiName: emojiName,
      send_date: Date.now(),
      sent_by: localStorage.getItem('id'),
      skinTone: skinTone,
      type: 'emoji',
    };

    if (emojiToMsg.hasOwnProperty('emojis') && emojiToMsg.emojis.length > 0) {
      let prevEmojis = emojiToMsg.emojis;
      prevEmojis.push(data);
      sendEmoji({emojis: prevEmojis});
    } else {
      sendEmoji({emojis: [data]});
    }
  };

  const handlePoll = (msg, cKey, pKey) => {
    const newOpt = localStorage.getItem('optionNo');
    const localUser = localStorage.getItem('id').toString();
    // const chating = JSON.parse(msg.message.options)[0];
    const chating = msg.message.options;

    const data = {
      dateAndTime: msg.message.dateAndTime,
      options: msg.message.options,
      pollId: msg.message.pollId,
      question: msg.message.question,
      status: msg.message.status,
    };

    for (let x in chating) {
      if (x === newOpt) {
        if (chating[x].hasOwnProperty('userIds')) {
          if (chating[x]['userIds'].includes(localUser)) {
            chating[x]['userIds'] = chating[x]['userIds'].filter(
              item => item !== localUser,
            );
          } else {
            chating[x]['userIds'].push(localUser);
          }
        } else {
          chating[x]['userIds'] = [localUser];
        }
      } else {
        if (chating[x].hasOwnProperty('userIds')) {
          chating[x]['userIds'] = chating[x]['userIds'].filter(
            item => item !== localUser,
          );
        }
      }
    }

    // data.options = JSON.stringify([chating]);
    data.options = chating;

    const databaseRef = ref(
      db,
      'chatrooms/' + xfbFolder + '/' + chatRoomName + '/' + pKey + '/' + cKey,
    );
    update(databaseRef, {message: data})
      .then(() => {
        
       
        
      })
      .then(() => {
        // clearData();
      });

    // if (emojiToMsg.hasOwnProperty("emojis") && emojiToMsg.emojis.length > 0) {
    //     let prevEmojis = emojiToMsg.emojis;
    //     prevEmojis.push(data);
    //     sendEmoji({emojis: prevEmojis});
    // } else {
    // sendEmoji({message: data});
    // }

    // dateAndTime: true,
    // options: "[{\"option1\":{\"option\":\"2022-12-20T13:00:04.812-05:00\",\"userIds\":[]},\"option2\":{\"option\":\"2022-12-21T07:59:04.812-05:00\",\"userIds\":[]},\"option3\":{\"option\":\"2022-12-22T12:30:04.812-05:00\",\"userIds\":[\"71\"]},\"option4\":{\"option\":\"2022-12-20T17:45:04.812-05:00\",\"userIds\":[\"545\"]},\"option5\":{\"option\":\"\",\"userIds\":[]}}]",
    // pollId: 33,
    // question: "Di Ci no I fr see Dr hi hi hi hi Dr Dr Dr F ghh",
    // status: "open"
  };

  // const getOpportunityData = () => {
  //     var data = {
  //         user_id: props.userData.id,
  //         type: "mine",
  //     };
  //     commonApi
  //         .get_user_available_opportunities(data)
  //         .then((res) => {
  //             if (res) {
  //                 
  //                 res.user_available_opportunity.map((item) => {
  //                     if (item.id === opportunityId) {
  //                         if (item.chat_detail.hasOwnProperty("chat_room_name")) {
  //                             setChatroomName(item.chat_detail.chat_room_name);
  //                             setchatId(item.chat_detail.id);
  //                             if (item.chat_detail.status === "close") {
  //                                 setChatroomStatus(false);
  //                             }
  //                         } else {
  //                             setNewChatroom(true);
  //                             setChatroomName("chatroom_" + (Math.random() + 1).toString(36).substring(7));
  //                             setshowLoader(false);
  //                         }
  //                     }
  //                 });
  //             }
  //         })
  //         .catch((err) => {
  //             console.log("Err", err);
  //             return { type: "error", message: err.message };
  //         });
  // };

  const createOpportunityChatroom = () => {
    var data = {
      chat_room_name: chatRoomName,
      creator_id: props.oppoDetails.created_by,
      opportunity_id: opportunityId,
      user_id: '',
    };
    return commonApi
      .create_opportunity_chat_room(data)
      .then(res => {
        if (res) {
          setExtraClose(true);
          setxChatDetail(res.chat_detail);
          setchatId(res.opportunity_chat_room_id);
          setNewChatroom(res.opportunity_chat_room_name);
          // saveOppotunityLastMessage(res.opportunity_chat_room_id, );
          return res.opportunity_chat_room_id;
        } else {
          return false;
        }
      })
      .catch(err => {
        // console.log("Err", err);
        return {type: 'error', message: err.message};
      });
  };

  const createChatroom = () => {
    var data = {
      chat_room_name: chatRoomName,
      chat_room_type: 'single',
      type: 'personal',
      user_id: props.userData.hasOwnProperty('user_id')
        ? props.userData.User_id
        : props.userData.id,
    };
    return commonApi
      .create_chat_room(data)
      .then(res => {
        if (res) {
          setxChatDetail(res.chat_detail);
          setchatId(res.chat_room_id);
          setNewChatroom(res.chat_room_name);
          return res.chat_room_id;
        } else {
          return false;
        }
      })
      .catch(err => {
        // console.log("Err", err);
        return {type: 'error', message: err.message};
      });
  };

  const leavePolitely = () => {
    var data = {
      chat_room_id: chatId,
      opportunity_id: opportunityId,
    };
    commonApi
      .politely_leave_opportunity(data)
      .then(res => {
        if (res.status === 200) {
          // setChatroomStatus(false);
          successMessage(res.message);
          if (isExtraClose) {
            props.onClose(isExtraClose);
          } else {
            props.onClose();
          }
        }
        setshowLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const manageOpportunityStatus = status => {
    var data = {
      chat_id: chatId,
      opportunity_id: opportunityId,
      status: status,
      user_id: xchatDetail.user_id,
    };
    commonApi
      .manage_opportunity_status(data)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            successMessage(res.message);
          } else {
            errorMessage(res.message);
          }
          // props.chatRoomStatus = status;
          setChatEnable(false);
          setChatEnableStatus(status);
          setChatroomStatus(status);
          // setChatroomStatus(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const changeOpportunityStatus = () => {
    var data = {
      chat_room_id: chatId,
      opportunity_id: opportunityId,
      user_id: xchatDetail.user_id,
    };
    commonApi
      .change_opportunity_status(data)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            successMessage(res.message);
          } else {
            errorMessage(res.message);
          }
          setChatEnable(true);
          setChatEnableStatus('pending');
          setChatroomStatus('pending');
          // setChatroomStatus(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUserData = id => {
    var data = {user_id: id};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserData(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getSpecificAccomplishment = id => {
    var data = {
      accomplishment_ids: [id],
    };
    commonApi
      .get_specific_accomplishment(data)
      .then(res => {
        if (res.status === 200) {
          getUserData(res.accomplishment_detail[0].created_by);
          setSnapshotData(res.accomplishment_detail[0]);
          setShowSnapshotDetail(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const saveOppotunityLastMessage = (chat, message) => {
    var data = {opportunity_chat_room_id: chat, last_message: message};
    commonApi
      .add_opportunity_last_message(data)
      .then(res => {
        if (res) {
          
          
          clearData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const saveLastMessage = (chat, message) => {
    var data = {chat_room_id: chat, last_message: message};
    commonApi
      .save_last_message(data)
      .then(res => {
        if (res) {
          
          
          clearData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const saveQuestionLastMessage = (chat, message, id) => {
    var data = {chat_id: chat, last_message: message, question_id: id};
    commonApi
      .add_last_message_in_question(data)
      .then(res => {
        if (res) {
          
          
          clearData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const sendMessage = async () => {
    if (
      messageBody.message !== '' ||
      messageBody.picture_url !== '' ||
      messageBody.document_url !== '' ||
      messageBody.video_url !== ''
    ) {
      let chatIdNew = '';
      if (isNewChatroom) {
      } else {
        // createChatroom();
        if (xchatType === 'new-opportunity') {
          chatIdNew = await createOpportunityChatroom();
        } else if (xchatType === 'new-personal-single') {
          chatIdNew = await createChatroom();
        }
      }

      if (isReplies) {
        let replyIs = messageBody;
        let updatedMsg = {};

        replyIs.first_name = localStorage.getItem('firstName');
        replyIs.last_name = localStorage.getItem('lastName');
        replyIs.profile_img = localStorage.getItem('profilePic');

        if (
          msgForReply.hasOwnProperty('replies') &&
          msgForReply.replies.length > 0
        ) {
          let prevReplies = msgForReply.replies;
          prevReplies.push(replyIs);
          updatedMsg = {replies: prevReplies};
        } else {
          updatedMsg = {replies: [replyIs]};
        }

        setIsReplies(false);
        const databaseRef = ref(
          db,
          'chatrooms/' +
            xfbFolder +
            '/' +
            chatRoomName +
            '/' +
            msgParentKey +
            '/' +
            msgChildKey,
        );
        update(databaseRef, updatedMsg)
          .then(() => {
            setMsgChildKey('');
            setMsgParentKey('');
            let lastMsg = messageBody.message ? messageBody.message : otherMsg;
            openChatroom(chatRoomName);
            if (messageBody.isPrivate) {
              clearData();
            } else {
              if (xchatType === 'qa-chats') {
                lastMsg = messageBody.type === 'poll' ? 'Poll' : lastMsg;
                saveQuestionLastMessage(chatId, lastMsg, props.item.id);
              } else {
                if (xfbFolder === 'chats') {
                  saveLastMessage(chatId, lastMsg);
                } else {
                  saveOppotunityLastMessage(chatId, lastMsg);
                }
              }
            }
            setshowLoader(false);
          })
          .then(() => {
            // clearData();
          });
      } else {
        const databaseRef = ref(
          db,
          'chatrooms/' +
            xfbFolder +
            '/' +
            chatRoomName +
            '/' +
            getDateFormatForChat(Date.now()),
        );
        push(databaseRef, messageBody)
          .then(() => {
            let lastMsg = messageBody.message ? messageBody.message : otherMsg;
            openChatroom(chatRoomName);
            if (messageBody.isPrivate) {
              clearData();
            } else {
              if (xchatType === 'qa-chats') {
                lastMsg = messageBody.type === 'poll' ? 'Poll' : lastMsg;
                saveQuestionLastMessage(chatId, lastMsg, props.item.id);
              } else {
                if (xfbFolder === 'chats') {
                  if (chatIdNew) {
                    saveLastMessage(chatIdNew, lastMsg);
                  } else {
                    saveLastMessage(chatId, lastMsg);
                  }
                } else {
                  if (chatIdNew) {
                    saveOppotunityLastMessage(chatIdNew, lastMsg);
                  } else {
                    saveOppotunityLastMessage(chatId, lastMsg);
                  }
                }
              }
            }
            setshowLoader(false);
          })
          .then(() => {
            // clearData();
          });
      }
    }
  };

  const closeChatRoom = () => {
    var payLoad = {
      chat_room_id: chatId,
    };
    commonApi
      .close_chat_room(payLoad)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const openChatroom = chatRoomName => {
    // localStorage.setItem('isCorrectPath', null);
    localStorage.removeItem('isCorrectPath');
    
    localStorage.setItem(
      'chatPath',
      'chatrooms/' + xfbFolder + '/' + chatRoomName,
    );
    const database = ref(db, 'chatrooms/' + xfbFolder + '/' + chatRoomName);
    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        setallChat(snapshot.val());
        
      } else if (snapshot.val() === null) {
        setallChat([]);
      }
    });
  };

  //   const onReplyCrossClick = () => {
  //     if (messageToReply && messageToReply.key != "") {
  //       messageToReply = {
  //         key: "",
  //         date: "",
  //         name: "",
  //         emojiId: "",
  //         mesasge: {
  //           message: "",
  //           picture_url: [],
  //           video_url: "",
  //           type: "",
  //           sent_by: "",
  //           send_date: "",
  //           isPrivate: false,
  //           privateChatData: {
  //             sent_to: "",
  //             first_name: "",
  //             last_name: "",
  //             profile_img: "",
  //           },
  //         },
  //       };
  //     }
  //   };

  const handleRemoveFile = () => {
    setOtherMsg(null);
    setSelectedImage(null);
    inputRef.current.value = null;
  };

  const handlePrivate = () => {
    setMessageBody({...messageBody, isPrivate: true});
    setPrivateUser(false);
  };

  const handleMentionUsers = item => {
    
    const privateUserObj = {
      sent_to: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      profile_img: item.profile_img,
    };
    setMessageBody({...messageBody, privateChatData: privateUserObj});
    setWrittenMsg('@' + item.first_name + ' ' + item.last_name);
    setPrivateUser(true);
  };

  const allMembers = () => {
    let memberNames = '';

    props.allChatUsers.slice(0, 2).forEach((item, index) => {
      memberNames += item.first_name + ' ' + item.last_name;
      memberNames += index < 1 ? ', ' : ' ';
    });

    return memberNames;
  };

  const createOpportunityCall = async () => {
    let payLoad = {
      audience_ids: [props.chatDetails.user_id],
      opportunity_id: props.chatDetails.opportunity_id,
      chat_room_id: props.chatDetails.id,
    };

    const response = await commonApi.create_opportunity_call_dolby(payLoad);

    if (response.session_id) {
      // 
      // return true;
      setOppoCall(response);
    } else {
      errorMessage(response.message);
    }
  };

  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
      <Popover.Body>
        And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    
    
    
    
    if (chatRoomName && isNewChatroom) {
      openChatroom(chatRoomName);
    }
    setTimeout(() => {
      scrollToBottom();
    }, 100);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomName]);

  useEffect(() => {
    if (props.chatRoomName) {
      setChatRoomName(props.chatRoomName);
    } else {
      setChatRoomName(
        'chatroom_' + (Math.random() + 1).toString(36).substring(3),
      );
    }
    setNewChatroom(props.chatRoomName);
    clearData();
    setWrittenMsg('');
    toggleCameraOptions(false);
    toggleReplyOptions(false);
  }, [props.chatRoomName]);

  useEffect(() => {
    setchatId(props.chatRoomId);
  }, [props.chatRoomId]);

  useEffect(() => {
    setxChatType(props.type);
    setxFbFolder(
      props.type.toLowerCase().indexOf('oppo') !== -1
        ? 'opportunities'
        : 'chats',
    );
  }, [props.type]);

  useEffect(() => {
    setxChatDetail(props.chatDetails);
    setxOtherUser(
      props.chatDetails.creator_id === localStorage.getItem('user')
        ? 'user_detail'
        : props.chatDetails.hasOwnProperty('creator_details')
        ? 'creator_details'
        : 'creator_detail',
    );
  }, [props.chatDetails]);

  useEffect(() => {
    sendMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageBodyNew]);

  useEffect(() => {
    if (
      messageBody.isPrivate &&
      (writtenMsg.length === 0 ||
        (writtenMsg.length > 0 && writtenMsg[0] !== '@'))
    ) {
      setMessageBody({
        ...messageBody,
        isPrivate: true,
        privateChatData: {
          first_name: '',
          last_name: '',
          profile_img: '',
          sent_to: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writtenMsg]);

  return (
    <>
      {/* Default view of chat */}
      {!addSnapshot &&
        !showUsers &&
        !showParticipants &&
        !showUserProfile &&
        !showEmojiModal &&
        !oppoGroupChat &&
        !oppoCall &&
        !showReplies &&
        !createPoll && (
          <SlidingPanel hidePanel={() => props.onClose()}>
            <div className="messages-wrapper fix-width-forms">
              {/* Show Loader */}
              {showLoader && <LoaderModal />}
              {/* Chat Room Info & extra features */}
              {xchatType === 'mine-opportunity' && (
                <>
                  <div className="sidebar-heading-wrapper with-panel">
                    <div className="leave-btn-wrapper">
                      {/* {chatroomStatus && */}
                      {/* xchatDetail.length === 0 || chatStatusIn.includes(props.oppoDetails.other_persons_opportunity_chat_room[0].status) ? */}
                      {chatEnableStatus === 'pending' && (
                        <>
                          <Button
                            onClick={() => {
                              manageOpportunityStatus('match');
                            }}
                            disabled={
                              props.oppoDetails.status === 'finish'
                                ? true
                                : false
                            }>
                            SUCCESSFUL MATCH
                          </Button>
                          <Button
                            onClick={() => {
                              manageOpportunityStatus('decline');
                            }}
                            disabled={
                              props.oppoDetails.status === 'finish'
                                ? true
                                : false
                            }>
                            DECLINE
                          </Button>
                        </>
                      )}
                      {(chatEnableStatus === 'match' ||
                        chatEnableStatus === 'decline') && (
                        <Button
                          onClick={() => {
                            changeOpportunityStatus();
                          }}
                          disabled={
                            props.oppoDetails.status === 'finish' ? true : false
                          }>
                          ENABLE
                        </Button>
                      )}
                      {/* } */}
                      <span className="info-icons">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip placement="bottom">Info Text</Tooltip>
                          }>
                          <span className="icon-info-down">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </span>
                        </OverlayTrigger>
                      </span>
                      {/* <span onClick={() => props.onClose()}>
                      <i className="fas fa-times" />
                    </span> */}
                    </div>
                  </div>

                  <div className="sidebar-heading-wrapper">
                    <div className="user-profile-img">
                      <img
                        src={
                          Object.entries(xchatDetail).length > 0 &&
                          xchatDetail.group_image !== ''
                            ? xchatDetail.group_image
                            : props.oppoDetails.profile_img
                            ? props.oppoDetails.profile_img
                            : DEFAULT_IMAGE
                        }
                        alt=""
                      />
                    </div>
                    <div className="middle-heading">
                      <h2>
                        {Object.entries(xchatDetail).length > 0 &&
                        xchatDetail.group_image !== ''
                          ? xchatDetail.group_name
                          : props.oppoDetails.first_name +
                            ' ' +
                            props.oppoDetails.last_name}
                      </h2>
                      {Object.entries(xchatDetail).length > 0 &&
                        xchatDetail.group_image !== '' && (
                          <p
                            onClick={() => {
                              setShowUsers(true);
                            }}>
                            {allMembers()} +{props.allChatUsers.length - 2}
                          </p>
                        )}
                    </div>
                    <div className="search-tabs-icons">
                      {/* <div className="user-profile-img">
                        <img src={sessionUser16} alt="" />
                    </div> */}
                      {/* <span className="close as-link" onClick={() => props.onClose()}>
                        <i className="fas fa-times" />
                    </span> */}
                      <span
                        className="icon as-link"
                        onClick={() => {
                          createOpportunityCall();
                        }}>
                        <i className="fas fa-video"></i>
                      </span>
                      {/* <span onClick={() => {setOppoGroupChat(true)}}>
                        <i className="fa fa-plus"></i>
                    </span> */}
                    </div>
                  </div>
                </>
              )}
              {xchatType === 'other-opportunity' && (
                <>
                  <div
                    className={`messages-profile-heading ${
                      props.withHeadingSpace || props.windowWidth < 1300
                        ? 'with-panel'
                        : ''
                    }`}>
                    <div className="profile-img as-link">
                      <img
                        src={
                          xchatDetail.group_image !== ''
                            ? xchatDetail.group_image
                            : props.oppoDetails[oppoCreatorDetail].profile_img
                            ? props.oppoDetails[oppoCreatorDetail].profile_img
                            : DEFAULT_IMAGE
                        }
                        alt=""
                      />
                    </div>

                    <div className="profile-content">
                      <div className="user-name">
                        <h2>
                          {xchatDetail.group_image !== ''
                            ? xchatDetail.group_name
                            : props.oppoDetails[oppoCreatorDetail].first_name +
                              ' ' +
                              props.oppoDetails[oppoCreatorDetail].last_name}
                        </h2>

                        <div className="messages-header-icons">
                          <span
                            className="as-link"
                            onClick={() => {
                              createOpportunityCall();
                            }}>
                            <i className="fas fa-video"></i>
                          </span>
                        </div>
                      </div>
                      <div className="profile-links mt-1">
                        {xchatDetail.group_image !== '' && (
                          <span>
                            {allMembers()} +{props.allChatUsers.length - 2}
                          </span>
                        )}
                        {xchatDetail.group_image === '' && <span />}
                        <Button
                          onClick={() => {
                            setshowLoader(true);
                            leavePolitely();
                          }}>
                          Politely Leave Opportunity
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {xchatType === 'new-opportunity' && (
                <>
                  {/* <div className="leave-btn-wrapper">
                    {chatStatusIn.includes(chatRoomStatus) && chatIsEnable && (
                      <Button
                        onClick={() => {
                          setshowLoader(true);
                          leavePolitely();
                        }}>
                        Politely Leave Opportunity
                      </Button>
                    )}
                    <span className="info-icons">
                      <OverlayTrigger placement="top" overlay={popover}>
                        <span className="icon-info-down">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </div> */}

                  <div
                    className={`messages-profile-heading ${
                      props.withHeadingSpace || props.windowWidth < 1300
                        ? 'with-panel'
                        : ''
                    }`}>
                    <div className="profile-img">
                      <img
                        src={
                          props.userData.profile_img
                            ? props.userData.profile_img
                            : DEFAULT_IMAGE
                        }
                        alt=""
                      />
                    </div>
                    <div className="profile-content">
                      <div className="user-name">
                        <h2>
                          {props.userData.first_name +
                            ' ' +
                            props.userData.last_name}
                        </h2>
                        <div className="messages-header-icons as-link">
                          <span className="info-icons">
                            <OverlayTrigger placement="top" overlay={popover}>
                              <span className="icon-info-down">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                              </span>
                            </OverlayTrigger>
                          </span>
                        </div>
                      </div>
                      <div className="profile-links mt-1">
                        <span />
                        {chatStatusIn.includes(chatRoomStatus) &&
                          chatIsEnable && (
                            <div className="profile-links-btns">
                              <Button
                                onClick={() => {
                                  setshowLoader(true);
                                  leavePolitely();
                                }}>
                                Politely Leave Opportunity
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="with-user-img">
                      {/* <div className="user-profile-img">
                        <img src={sessionUser16} alt="" />
                    </div> */}
                      {/* <span className="close as-link" onClick={() => props.onClose()}>
                        <i className="fas fa-times" />
                    </span> */}
                    </div>
                  </div>
                </>
              )}
              {xchatType === 'new-personal-single' && ( //otherUser
                <>
                  <div
                    className="messages-user-header"
                    onClick={() => {
                      setShowUserProfile(
                        props.userData.hasOwnProperty('user_id')
                          ? props.userData['User_id']
                          : props.userData['id'],
                      );
                    }}>
                    <div className="user-img">
                      <img
                        src={
                          props.userData['profile_img']
                            ? props.userData['profile_img']
                            : DEFAULT_IMAGE
                        }
                        alt=""
                      />
                    </div>
                    <div className="user-details">
                      <h2>
                        {props.userData['first_name'] +
                          ' ' +
                          props.userData['last_name']}
                      </h2>
                    </div>
                  </div>
                  {/*(xchatDetail.private_group === "no" || xchatDetail.creator_id == localStorage.getItem("id")) && <>
                    <div className="text-center">
                        <span><i className="fa-solid fa-plus" onClick={() => { props.handleCommonChat(xchatDetail, "edit-group-component"); }}></i>
                            invite
                        </span>
                    </div>
                    <div className="text-center">
                        <span><i className="fa-sharp fa-solid fa-circle-xmark" onClick={() => { setModalEndChat(true); }}></i></span>
                    </div>
                    </>*/}
                  {/*xchatDetail.creator_id == localStorage.getItem("id") &&
                    <div className="create-session-links" onClick={() => { props.handleCommonChat(xchatDetail, "create-session-component"); }}>
                        Create Session or Event
                    </div>*/}
                </>
              )}
              {xchatType === 'personal-single' && ( //otherUser
                <>
                  <div
                    className="messages-user-header"
                    onClick={() => {
                      setShowUserProfile(xchatDetail[xotherUser]['id']);
                    }}>
                    <div className="user-img">
                      <img
                        src={
                          xchatDetail[xotherUser]['profile_img']
                            ? xchatDetail[xotherUser]['profile_img']
                            : DEFAULT_IMAGE
                        }
                        alt=""
                      />
                    </div>
                    <div className="user-details">
                      <h2>
                        {xchatDetail[xotherUser]['first_name'] +
                          ' ' +
                          xchatDetail[xotherUser]['last_name']}
                      </h2>
                    </div>
                    {/* <span
                    className="close as-link"
                    onClick={() => props.onClose()}>
                    <i className="fas fa-times" />
                  </span> */}
                  </div>
                  {(xchatDetail.private_group === 'no' ||
                    xchatDetail.creator_id === localStorage.getItem('id')) && (
                    <>
                      <div className="text-center">
                        <span>
                          <i
                            className="fa-solid fa-plus"
                            onClick={() => {
                              props.handleCommonChat(
                                xchatDetail,
                                'edit-group-component',
                              );
                            }}></i>
                          invite
                        </span>
                      </div>
                      <div className="text-center">
                        <span>
                          <i
                            className="fa-sharp fa-solid fa-circle-xmark"
                            onClick={() => {
                              setModalEndChat(true);
                            }}></i>
                        </span>
                      </div>
                    </>
                  )}
                  {xchatDetail.creator_id === localStorage.getItem('id') && (
                    <div
                      className="create-session-links"
                      onClick={() => {
                        props.handleCommonChat(
                          xchatDetail,
                          'create-session-component',
                        );
                      }}>
                      Create Session or Event
                    </div>
                  )}
                </>
              )}
              {xchatType === 'personal-group' && (
                <>
                  <div
                    className={`messages-profile-heading ${
                      props.withHeadingSpace || props.windowWidth < 1300
                        ? 'with-panel'
                        : ''
                    }`}>
                    {' '}
                    {/*messages-user-header*/}
                    <div className="profile-img">
                      {' '}
                      {/*user-img*/}
                      <img src={xchatDetail.group_image} alt="" />
                    </div>
                    <div className="profile-content">
                      <div className="user-name">
                        <h2>{xchatDetail.group_name}</h2>
                        {/* <Button>Clear Screen</Button> */}
                      </div>
                      <div
                        className="profile-links"
                        onClick={() => {
                          setShowUsers(true);
                        }}>
                        <span>
                          <i className="far fa-user" />{' '}
                          {` ${xchatDetail.count + 1}`}
                        </span>
                        {/* <Button>Create Session or Event</Button> */}
                      </div>
                    </div>
                    {/* <div className="user-details">
                    <h2>{xchatDetail.group_name}</h2>
                    {
                      <div
                        className="user-friends"
                        onClick={() => {
                          setShowUsers(true);
                        }}>
                        <i className="far fa-user-circle" />{' '}
                        {xchatDetail.count + 1}
                      </div>
                    }
                  </div> */}
                    {(xchatDetail.private_group === 'no' ||
                      xchatDetail.creator_id === localStorage.getItem('id')) && (
                      <div className="messages-header-icons">
                        <span>
                          <i
                            className="fa-solid fa-plus"
                            onClick={() => {
                              props.handleCommonChat(
                                xchatDetail,
                                'edit-group-component',
                              );
                            }}></i>
                          {'  '}
                          invite
                        </span>

                        <span>
                          <i
                            className="fas fa-times"
                            onClick={() => {
                              setModalEndChat(true);
                            }}></i>
                        </span>
                      </div>
                    )}
                  </div>

                  {xchatDetail.creator_id === localStorage.getItem('id') && (
                    <div
                      className="create-session-links"
                      onClick={() => {
                        props.handleCommonChat(
                          xchatDetail,
                          'create-session-component',
                        );
                      }}>
                      Create Session or Event
                    </div>
                  )}
                </>
              )}
              {xchatType === 'session-group' && (
                <div className="sidebar-heading-wrapper">
                  <div className="heading">
                    <h2>Messages</h2>
                  </div>
                  {/* <span className="close as-link" onClick={() => props.onClose()}>
                  <i className="fas fa-times" />
                </span> */}
                </div>
              )}
              {xchatType === 'qa-chats' && (
                <div
                  className={`messages-profile-heading ${
                    props.withHeadingSpace || props.windowWidth < 1300
                      ? 'with-panel'
                      : ''
                  }`}
                  onClick={() => {}}>
                  <div className="profile-img">
                    <img src={xchatDetail.group_image} alt="" />
                  </div>
                  <div className="profile-content">
                    <div className="user-name">
                      <h2>{xchatDetail.group_name}</h2>

                      <div className="messages-header-icons">
                        <span
                          onClick={() => {
                            setCreatePoll(true);
                          }}>
                          <i className="fa-solid fa-square-poll-horizontal"></i>
                        </span>
                        {/* <span
                      className="close as-link"
                      onClick={() => props.onClose()}>
                      <i className="fas fa-times" />
                    </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="messages-wrapper"> */}
              {/* Show Messages */}
              {Object.entries(allChat).length > 0 && (
                <Scrollbars style={{width: '100%', flex: 1}}>
                  {/* <CommonChatBox
                    allChatUsers={props.allChatUsers}
                    allChat={Object.entries(allChat)}
                    handleChatBoxSignal={handleChatBoxSignal}
                /> */}
                  <SmartChatBox
                    allChatUsers={props.allChatUsers}
                    allChat={Object.entries(allChat)}
                    handleChatBoxSignal={handleChatBoxSignal}
                    chatStatus={
                      chatStatusIn.includes(chatRoomStatus) && chatIsEnable
                    }
                    chatModule={xchatType}
                  />
                  <div ref={messagesEndRef} />
                </Scrollbars>
              )}

              {/* Send Message */}

              {chatStatusIn.includes(chatRoomStatus) && chatIsEnable && (
                <div className="message-form aa">
                  <input
                    style={{display: 'none'}}
                    ref={inputRef}
                    type="file"
                    name="myImage"
                    onChange={event => {
                      makeFile(event.target.files[0]);
                    }}
                  />
                  {/* Show user list make private */}
                  {writtenMsg === '@' && (
                    // writtenMsg
                    <div className="reply-wrapper user-name-list">
                      {props.allChatUsers.map((item, index) => {
                        return (
                          item.id !== localStorage.getItem('id') && (
                            <div
                              className="user-item "
                              onClick={() => {
                                handleMentionUsers(item);
                              }}
                              key={`${item.id}-${index}`}>
                              @{fixName(item.first_name, item.last_name)}
                            </div>
                          )
                        );
                      })}
                    </div>
                  )}
                  {/* Show message reply container */}
                  {isReply && messageBody.hasOwnProperty('reply') && (
                    <div className="reply-wrapper">
                      <p>{messageBody.reply.message}</p>
                      {messageBody.reply.type === 'image' && (
                        <div className="msg-img">
                          <img
                            src={messageBody.reply.picture_url}
                            style={{width: '50px'}}
                            alt=""
                          />
                        </div>
                      )}
                      {messageBody.reply.type === 'snapshot' &&
                        Object.entries(messageBody.reply.picture_url).length &&
                        messageBody.reply.picture_url.map((item, index) => {
                          return (
                            <div
                              // onClick={() => {
                              //     getSpecificAccomplishment(item.id);
                              // }}
                              key={index}
                              className="msg-img">
                              <img
                                src={item.images[0]}
                                style={{width: '50px'}}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      {messageBody.reply.video_url && (
                        <div className="msg-img">
                          <video
                            src={messageBody.reply.video_url}
                            style={{width: '50px'}}
                            playsInline
                            autoPlay
                            controls
                          />
                        </div>
                      )}
                      <span
                        className="close as-link"
                        onClick={() => {
                          setIsReplies(false);
                          setIsReply(false);
                          setMessageBody(current => {
                            const {reply, ...rest} = current;
                            return rest;
                          });
                        }}>
                        <i className="fas fa-times" />
                      </span>
                      {/* <span>{getTimeFormatForChat(messageBody.reply.send_date)}</span> */}
                    </div>
                  )}
                  {isReplies &&
                    msgForReply.hasOwnProperty('message') &&
                    typeof msgForReply.message !== 'object' && (
                      <div className="reply-wrapper">
                        <p>{msgForReply.message}</p>
                        {/* {msgForReply.type === 'image' && (
                      <div className="msg-img">
                        <img
                          src={msgForReply.picture_url}
                          style={{width: '50px'}}
                        />
                      </div>
                    )}
                    {msgForReply.type === 'snapshot' &&
                      Object.entries(msgForReply.picture_url).length &&
                      msgForReply.picture_url.map((item, index) => {
                        return (
                          <div
                            // onClick={() => {
                            //     getSpecificAccomplishment(item.id);
                            // }}
                            key={index}
                            className="msg-img">
                            <img src={item.images[0]} style={{width: '50px'}} />
                          </div>
                        );
                      })}
                    {msgForReply.video_url && (
                      <div className="msg-img">
                        <video
                          src={msgForReply.video_url}
                          style={{width: '50px'}}
                          playsInline
                          autoPlay
                          controls
                        />
                      </div>
                    )} */}
                        <span
                          className="close as-link"
                          onClick={() => {
                            setIsReplies(false);
                            // setIsReply(false);
                            // setMessageBody(current => {
                            //   const {reply, ...rest} = current;
                            //   return rest;
                            // });
                          }}>
                          <i className="fas fa-times" />
                        </span>
                        {/* <span>{getTimeFormatForChat(messageBody.reply.send_date)}</span> */}
                      </div>
                    )}
                  {otherMsg === 'Image' && selectedImage ? (
                    <span className="msg-img">
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt=""
                        onClick={() => {
                          showLightbox();
                        }}
                        style={{width: '50px'}}
                      />
                      <i className="fas fa-times" onClick={handleRemoveFile} />
                    </span>
                  ) : (
                    <>
                      <span
                        onClick={() => toggleCameraOptions(!cameraOptions)}
                        className="form-icon">
                        <i className="icon-camera" />
                      </span>
                      {/* <DropdownButton
                      // className="form-icon"
                      // align="up"
                      title={<i className="fas fa-ellipsis-v" />}
                      id="dropdown-menu-align-end"
                      onSelect={handleSelect}>
                      <Dropdown.Item as={'span'} eventKey="1">
                        Camera
                      </Dropdown.Item>
                      <Dropdown.Item as={'span'} eventKey="2">
                        Media
                      </Dropdown.Item>
                      <Dropdown.Item as={'span'} eventKey="3">
                        Snapshot
                      </Dropdown.Item>
                    </DropdownButton> */}
                    </>
                  )}
                  <Form.Group className="form-input">
                    <TextareaAutosize
                      maxRows={4}
                      minRows={1}
                      className="form-control"
                      name="message"
                      value={writtenMsg}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          saveMessage();
                        }
                      }}
                      onChange={e => {
                        setMessageBody({
                          ...messageBody,
                          message: e.target.value,
                        });
                        setWrittenMsg(e.target.value);
                      }}
                      placeholder="Type your message here"
                    />
                    {/* <Form.Control
                      autoComplete="off"
                      as="textarea"
                      rows={1}
                      name="message"
                      value={writtenMsg}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          saveMessage();
                        }
                      }}
                      onChange={e => {
                        setMessageBody({
                          ...messageBody,
                          ['message']: e.target.value,
                        });
                        setWrittenMsg(e.target.value);
                      }}
                      placeholder="Type your message here"
                    /> */}
                  </Form.Group>
                  <span
                    onClick={() => {
                      saveMessage();
                    }}
                    className="form-icon send-btn">
                    <i className="icon-play-icon" />
                  </span>
                </div>
              )}
              {/* </div> */}
            </div>
          </SlidingPanel>
        )}

      {/* When user add snapshot show snapshot list view */}
      {addSnapshot && (
        <SnapshotList
          show={{sendButton: true}}
          withHeadingSpace={true}
          userData={props.allChatUsers.find(
            objs =>
              parseInt(objs.id) === parseInt(localStorage.getItem('user')),
          )}
          snapshotIds={snapshotIds}
          setSnapshotIds={setSnapshotIds}
          setSnapshotImages={setSnapshotImages}
          onClose={() => {
            setAddSnapshot(false);
          }}
          onSend={() => {
            sendSnapshot();
          }}
        />
      )}

      {/* When user user open snapshot in chat snapshot detail view */}
      {showSnapshotDetail &&
        snapshotData &&
        snapshotData.hasOwnProperty('images') &&
        userData.hasOwnProperty('first_name') && (
          <SnapshotDetail
            snapshotId={snapshotData}
            userData={userData}
            onClose={() => {
              setShowSnapshotDetail(false);
            }}
            showRefer={true}
            withHeadingSpace={true}
          />
        )}

      {/* Show group users in modal */}
      {showUsers && (
        <ListUsers
          allUsers={props.allChatUsers}
          onClose={() => {
            setShowUsers(false);
          }}
          type={true}
        />
      )}

      {/* Show poll participants in modal */}
      {showParticipants && (
        <ListUsers
          allUsers={pollParticipants}
          onClose={() => {
            setShowParticipants(false);
          }}
          type={false}
        />
      )}

      {/* Show user profile */}
      {showUserProfile && (
        <ProfileComponent
          userId={showUserProfile}
          show={false}
          closeModal={() => {
            setShowUserProfile('');
          }}
          userData={[]}
          moderator={false}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}

      {oppoCall && Object.entries(oppoCall).length > 0 && (
        <OpportunityCall
          confData={oppoCall}
          onClose={() => {
            setOppoCall(false);
          }}
        />
      )}

      {oppoGroupChat && (
        <CreateGroup
          groupChat={xchatDetail}
          onClose={() => {
            setOppoGroupChat(false);
          }}
          chatGroupType={'opportunity'}
        />
      )}

      {showEmojiModal && (
        <EmojiModal
          onClose={() => setshowEmojiModal(false)}
          sendEmoji={icon => {
            handleAddEmoji(icon);
          }}
        />
      )}

      {showReplies && (
        // <ReplyList
        //     onClose={()=>{setShowReplies(false)}}
        //     chatx={handleData.item}
        //     allChatUsers={props.allChatUsers}
        //     // handlePoll={(type)=>{
        //     //     handleSelectReply(type, handleData.item, handleData.cKey, handleData.pKey);
        //     // }}
        //     handleChatBoxSignal={handleChatBoxSignal}
        //     messagePath={handleData}
        // />
        <SmartReplyList
          onClose={() => {
            setShowReplies(false);
          }}
          chatx={handleData.item}
          allChatUsers={props.allChatUsers}
          folderName={xfbFolder}
          messagePath={
            'chatrooms/' +
            xfbFolder +
            '/' +
            chatRoomName +
            '/' +
            handleData.pKey +
            '/' +
            handleData.cKey
          }
          chatId={chatId}
        />
      )}

      {createPoll && (
        <CreatePoll
          onClose={() => {
            setCreatePoll(false);
          }}
          chatId={chatId}
          handleCreatePoll={handleCreatePoll}
        />
      )}

      {cameraOptions && (
        <CustomModal
          position="bottom"
          onClose={() => toggleCameraOptions(!cameraOptions)}>
          <div className="camera-options-list">
            <ul>
              <li
                onClick={() => {
                  toggleCameraOptions(false);
                  handleSelect('1');
                }}>
                <i className="icon-camera" /> Camera
              </li>
              <li
                onClick={() => {
                  toggleCameraOptions(false);
                  handleSelect('2');
                }}>
                <i className="icon-gallery-img" /> Media
              </li>
              <li
                onClick={() => {
                  toggleCameraOptions(false);
                  handleSelect('3');
                }}>
                <i className="icon-snapshot" /> Snapshot
              </li>
            </ul>
          </div>
        </CustomModal>
      )}

      {replyOptions && replyOptionObj && (
        <CustomModal
          position="bottom"
          onClose={() => toggleReplyOptions(!replyOptions)}>
          <div className="camera-options-list">
            <div className="emoji-list">
              <span className="add-emoji">
                <i className="" />
              </span>
            </div>
            <ul>
              {/* <li onClick={() => {
                  toggleReplyOptions(false);
                  handleSelect('3');
                }} >
                <i className="icon-scan-icon" /> Share Snapshot
              </li>
              <li onClick={() => {
                  toggleReplyOptions(false);
                  handleSelect('2');
                }} >
                <i className="icon-camera" /> Add Media
              </li> */}
              {marksBest &&
                props.chatDetails.type === 'question' &&
                props.chatDetails.creator_id === localStorage.getItem('user') &&
                replyOptionObj.signalData &&
                replyOptionObj.signalData.type !== 'poll' && (
                  <li
                    onClick={() => {
                      // handleReplyOptions(replyOptionObj);
                      handleReplyOptions(
                        'best-answer',
                        replyOptionObj.signalData,
                        replyOptionObj.childKey,
                        replyOptionObj.parentKey,
                      );
                    }}>
                    <i className="fa-solid fa-circle-check" /> Mark as best
                    answer
                  </li>
                )}
              <li
                onClick={() => {
                  // handleReplyOptions(replyOptionObj);
                  handleReplyOptions(
                    'add-replies',
                    replyOptionObj.signalData,
                    replyOptionObj.childKey,
                    replyOptionObj.parentKey,
                  );
                }}>
                <i className="fas fa-comment" /> Reply
              </li>
              <li
                onClick={() => {
                  handleReplyOptions(
                    'add-emoji',
                    replyOptionObj.signalData,
                    replyOptionObj.childKey,
                    replyOptionObj.parentKey,
                  );
                }}>
                <i className="fas fa-smile" /> Emoji
              </li>
              {/* <li
                onClick={() => {
                  setWrittenMsg('@');
                }}>
                <i className="fas fa-comment-xmark" /> Private Reply
              </li> */}
            </ul>
          </div>
        </CustomModal>
      )}

      <Modal
        show={modalEndChat}
        onHide={() => {
          setModalEndChat(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>End Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to end the chat ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              closeChatRoom();
              setModalEndChat(false);
            }}>
            Yes
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalEndChat(false);
            }}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEmojis}
        onHide={() => {
          setShowEmojis(false);
        }}>
        <Modal.Body>
          {Object.entries(emojiList).length > 0 &&
            emojiList.map((item, index) => {
              const found = props.allChatUsers.find(
                objs => objs.id === item.sent_by,
              );
              return (
                typeof found === 'object' && (
                  <div key={`emoji-${item.emojiID}-${index}`}>
                    <img
                      onClick={() => {
                        setShowUserProfile(found.id);
                        setShowEmojis(false);
                      }}
                      src={
                        found.profile_img ? found.profile_img : DEFAULT_IMAGE
                      }
                      alt=""
                      style={{width: '50px', borderRadius: '30px'}}
                    />
                    <span
                      onClick={() => {
                        setShowUserProfile(found.id);
                        setShowEmojis(false);
                      }}>
                      {found.first_name + ' ' + found.last_name}
                    </span>
                    <span style={{float: 'right'}}>
                      <img
                        style={{
                          border: '1px solid golden',
                          borderRadius: '30px',
                          width: '25px',
                        }}
                        src={
                          S3_EMOJI_DEV +
                          item.skinTone +
                          '/' +
                          item.emojiName +
                          '.svg'
                        }
                        alt=""
                      />
                    </span>
                  </div>
                )
              );
            })}
        </Modal.Body>
      </Modal>

      <Modal
        show={showPrivate}
        onHide={() => {
          setPrivateUser(false);
        }}>
        <Modal.Body>
          {
            <div>
              <img
                src={
                  messageBody.privateChatData.profile_img
                    ? messageBody.privateChatData.profile_img
                    : DEFAULT_IMAGE
                }
                alt=""
                style={{width: '50px', borderRadius: '30px'}}
              />
              <>
                {messageBody.privateChatData.first_name +
                  ' ' +
                  messageBody.privateChatData.last_name}
              </>
              <span style={{float: 'right'}}>
                <Button
                  onClick={() => {
                    handlePrivate();
                  }}>
                  Make Private
                </Button>
              </span>
            </div>
          }
        </Modal.Body>
      </Modal>

      {webcamStream &&
        webcamStream.getTracks() &&
        webcamStream.getTracks().length > 0 && (
          <CustomModal
            size="medium"
            onClose={() => {
              webcamStream.getTracks().forEach(track => track.stop());
              setWebcamStream(null);
            }}>
            <div className="capture-with-camera-wrapper">
              <div className="capture-with-camera-imgs">
                <div className="video">
                  <video
                    ref={webcamRef}
                    autoPlay={true}
                    stream={webcamStream}
                  />
                </div>
                {imageSrc && (
                  <div className="video-img">
                    <img src={imageSrc} alt="captured" />
                  </div>
                )}
              </div>
             <div className='text-center'>
              <button className="btn btn-dark mt-3" onClick={captureImage}>
                Take Photo
              </button>
              </div>
            </div>
          </CustomModal>
        )}

      {show && (
        <ImageLightbox
          show={show}
          current={current}
          setCurrent={setCurrent}
          images={popupImages}
          onClose={() => {
            setShow(false);
            // setCurrent(0);
          }}
        />
      )}
    </>
  );
};

const mapStatProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStatProps, {})(CommonChatPage);
