import React, {useState, useEffect} from 'react';
import CustomModal from '../CustomModal';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../SessionDetails/CheckoutForm';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const PaymentModal = ({price, transactionId,transactionType,onClose,onCloseHitApi}) => {
  const [showLoader, setShowLoader] = useState(false);

  const stripePromise = loadStripe(
    'pk_test_51PEB5a2K8W7Cs25henyw4Ahf3eGMQgtDgRztlGV4POXFghfMEH5DDg5PtNpT916JChWA9ELeby2Znh5mu2IjXyuY00TWUVZN1V',
  );

  return (
    <CustomModal
      size="small"
      onClose={() => {
        onClose();
      }}
      className="availability-modal">
      <div
        className="new-session-modal-wrapper text-center  "
        style={{width: '-webkit-fill-available'}}>
        <div className="new-session-modal estimate_name_modal">
          <Elements
            stripe={stripePromise}
            options={{
              mode: 'payment',
              amount: 10 * 100,
              currency: 'usd',
              paymentMethodTypes: ['card'],
              payment_method_types: ['card'],
            }}>
            <CheckoutForm
              price={price}
              paymentId={transactionId}
              transactionType={transactionType}
              onClose={() => {
                onClose();
                onCloseHitApi && onCloseHitApi()
                //   setShowStripeModal(false);
                //   onClose();
                //   setShowLoader(true);
                //   handleRefresh()
              }}
            />
          </Elements>
        </div>
      </div>
    </CustomModal>
  );
};

export default PaymentModal;
