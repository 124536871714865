import React from 'react'

const ShareIconSvg = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.349" height="17.276" viewBox="0 0 19.349 17.276">
  <path id="Path_1109" data-name="Path 1109"
    d="M19.144,33.141l-5.528-5.528a.691.691,0,0,0-1.177.486v2.764H10.02q-7.7,0-9.448,4.351A9.833,9.833,0,0,0,0,38.81a12.966,12.966,0,0,0,1.371,4.87q.032.076.113.259t.146.324a1.227,1.227,0,0,0,.14.237.369.369,0,0,0,.3.184.315.315,0,0,0,.254-.108.4.4,0,0,0,.092-.27,2.2,2.2,0,0,0-.027-.286,2.234,2.234,0,0,1-.027-.254q-.054-.734-.054-1.328A9.191,9.191,0,0,1,2.5,40.484a5.868,5.868,0,0,1,.524-1.5,3.756,3.756,0,0,1,.864-1.09,5.069,5.069,0,0,1,1.139-.75,6.176,6.176,0,0,1,1.436-.459,13.692,13.692,0,0,1,1.663-.232q.837-.065,1.9-.065h2.419v2.764a.687.687,0,0,0,1.177.486l5.528-5.528a.678.678,0,0,0,0-.972Z"
    transform="translate(0 -27.408)" fill={fill} />
</svg>
  )
}

export default ShareIconSvg