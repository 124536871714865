import axios from 'axios';
import {API_URL, BACKEND_URL_Api_Of_ROI} from './env';

const postData = (url, data) => {
  const expiresInMilliseconds = 3600000; // 1 hour

// Calculate the expiration date
const expirationDate = new Date(Date.now() + expiresInMilliseconds);
  return axios({
    method: 'post',
    url: url,
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Expires': expirationDate.toUTCString()
      },
    },
  }).then(res => res.data);
};

const getData = url => axios(url).then(res => res.data);

const API = {
  // Auth
  requestOtp: data => postData(`${BACKEND_URL_Api_Of_ROI}send_verification_code`, data),
  registerOtpRequest: data => postData(`${API_URL}/register-otp-request`, data),
  verifyOtp: data => postData(`${BACKEND_URL_Api_Of_ROI}verify_code`, data),
  getUserProfile: id => getData(`${API_URL}/get-user-profile?userId=${id}`),
  saveUserProfile: data => postData(`${API_URL}/save-user-profile`, data),
  checkApi: data =>
    postData(
      `https://us-central1-the-shootinggallery.cloudfunctions.net/storeTransaction`,
      data,
    ),

  authoriseSpotifyuser: code =>
    getData(`${API_URL}/authorise-spotify?code=${code}`),
  refreshUserToken: token =>
    getData(`${API_URL}/refresh-spotify-token?token=${token}`),
};

export default API;
