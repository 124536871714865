import React, {useRef, useEffect} from 'react';


const ParticipantStream = props => {
  const ref = useRef();

  useEffect(() => {
    updateStream(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStream = prop => {
    const {stream} = prop;
    navigator.attachMediaStream(ref.current, stream);
  };

  return (
    <video
      className={
        props.participant.info.externalId === localStorage.getItem('id') &&
        props.stream.type === 'Camera'
          ? 'participant-grid-item__video'
          : 'participant-grid-item__video'
      }
      id={'video-p-' + props.participant.id}
      ref={ref}
      participant={props.participant}
      stream={props.stream}
      autoPlay={true}
      controls={false}
      muted
      // poster={DEFAULT_IMAGE}
    />
  );
};

export default ParticipantStream;
