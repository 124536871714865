import React, {useState,useRef,useEffect} from 'react';
import moment from 'moment';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {FireIcon, MachineIcon, layers} from '../../Constants/Images';
import {Row} from 'react-bootstrap';

const DateGraph = ({
  projectListingBoardData,
  selectMileStoneId,
  startFrom,
  startEnd,
}) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showSelectedEvent, setShowSelectedEvent] = useState(false);


  const milestoneRef = useRef(null);
  const [milestoneHeight, setMilestoneHeight] = useState(0);

  useEffect(() => {
   
    // Check if milestoneRef is defined, then set the height
    setTimeout(() => {
      if (milestoneRef.current) {
        // Get the height and add marginTop and marginBottom if applicable
        const rect = milestoneRef.current.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(milestoneRef.current);
        
        const totalHeight =
          rect.height +
          parseFloat(computedStyle.marginTop) +
          parseFloat(computedStyle.marginBottom);
  
        setMilestoneHeight(totalHeight);
      }
    }, 1500);
   
  }, [projectListingBoardData,startFrom,startEnd]);

  const onBarClick = (event, offset, top) => {
    if (selectedEvent && selectedEvent?.id === event.id) {
      setSelectedEvent(null);
      setShowSelectedEvent(false);
    } else {
      setSelectedEvent({...event, top: top + 25, left: offset});
      setShowSelectedEvent(true);
    }
  };

  const calculateTotalDays = (startDate, endDate) => {
    const totalMilliseconds = endDate - startDate;
    return Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));
  };
  let topOffset = 35;

  // Initialize the top offset globally to manage stacking

  console.log('showSelectedEvent', selectedEvent);
  const renderEvents = (event, i, test) => {
    // Calculate the total number of days in the range
    const totalDays = Math.ceil(
      (new Date(startEnd) - new Date(startFrom)) / (1000 * 60 * 60 * 24),
    );

    // Calculate pixel width per day dynamically based on the timeline width (each slot is 133px for 7 days)
    const pxPerDay = 126 / 7; // You can adjust this or make it dynamic if needed

    const firstSlotDate = new Date(startFrom); // Start of timeline
    const endSlotDate = new Date(startEnd); // End of timeline

    const startDate = new Date(event.delivery_start_date);
    const endDate = new Date(event.delivery_end_date);

    // Check if the event falls within the date range
    if (startDate < firstSlotDate || endDate > endSlotDate) {
      return null; // Skip rendering if outside the range
    }

    // Calculate the day difference from `firstSlotDate` for offset
    const daysFromStart = Math.floor(
      (startDate - firstSlotDate) / (1000 * 60 * 60 * 24),
    );
    const offset = daysFromStart * pxPerDay; // Dynamic left offset in pixels

    // Calculate event duration in days for width
    const eventDurationDays = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24),
    );
    const width = eventDurationDays * pxPerDay; // Dynamic width in pixels

    // Track vertical stacking for each deliverable
    const currentTop = topOffset;
    topOffset += 50; // Increment for each deliverable to stack vertically

    return (
      <div
        key={`event-${event.id}-${i}`}
        className="project-line-bar as-link"
        onClick={() => onBarClick(event, offset, currentTop)}
        style={{
          left: `${offset}px`, // Offset in pixels from start
          width: `${width}px`, // Width in pixels for event duration
          height: '20px',
          backgroundColor:
            selectedEvent?.id === event.id ? '#68C559' : '#AEABA0',
          top: `${currentTop}px`, // Vertical position
        }}></div>
    );
  };

  const handleCalculateHeight = (deliverableArray, type) => {
    // Start of timeline
    const firstSlotDate = new Date(startFrom); // Start of timeline
    const endSlotDate = new Date(startEnd);

    let count = 0; // Initialize count to 0

    deliverableArray.forEach(event => {
      const startDate = new Date(event.delivery_start_date);
      const endDate = new Date(event.delivery_end_date);
      // Check if the event falls within the date range
      if (startDate >= firstSlotDate && endDate <= endSlotDate) {
        count++; // Increment count if condition is met
      }
    });

    // Return the total count after loop completes
    return count;

    // Loop through the array of deliverables
  };

  const generateDateSlots = () => {
    const start = new Date(startFrom); // Start from today
    const end = new Date(startEnd); // End date as specified

    const lines = [];
    const days = calculateTotalDays(start, end); // Total days from today to the end date
    const interval = 7; // Interval in days

    for (let i = 0; i <= days; i += interval) {
      // Create a new date incremented by `i` days from the start
      const currentDate = new Date(start);
      currentDate.setDate(start.getDate() + i);

      // Format the date
      const data = moment(currentDate).format('D MMM YY').toString();

      // Push date slot to lines array
      lines.push(
        <div className="tasks-listing" key={`slot-${i}`}>
          <div className="date">{data}</div>
          <div className="activity-line"></div>
        </div>,
      );
    }

    return lines;
  };
  const ShowMilestoneBar = milestone => {
    const firstSlotDate = new Date(startFrom); // Start of timeline
    const endSlotDate = new Date(startEnd); // End of timeline

    // Check if any deliverable matches the date range
    const decision = milestone.deliverables.some(event => {
      const startDate = new Date(event.delivery_start_date);
      const endDate = new Date(event.delivery_end_date);

      // Check if the event falls within the date range
      return startDate >= firstSlotDate && endDate <= endSlotDate;
    });

    if (decision) {
      return (
        <div
          key={milestone.id} // You can use a unique key like milestone.id
          className="rounded shadow font-bold text-lg"
          style={{
            width: '2px',
            height: `${handleCalculateHeight(milestone.deliverables) * 45}px`,
            padding: '5px',
            marginTop: '10px',
            backgroundColor: '#C945DF',
          }}></div>
      );
    } else {
      return null;
    }
  };

  return (
    <div style={{ maxHeight: '100vh', width: '100vw', overflow: 'hidden' }}>
      <div
        className="flex flex-row data-graph-vertical-scroll-div"
        style={{ overflowY: 'auto', maxHeight: '100vh' }}
        
      >
        <div
          className="milestone-container"
          style={{
            marginTop: '43px',
            paddingRight: '6px',
          }}
          id="milestoneHeight"
        ref={milestoneRef}
        >
          {projectListingBoardData?.milestones?.length > 0 &&
            projectListingBoardData.milestones.map((milestone) => {
              if (
                selectMileStoneId.includes(milestone.id) &&
                milestone.deliverables
              ) {
                return (
                  <React.Fragment key={milestone.id}>
                    {ShowMilestoneBar(milestone)}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
        </div>

        <div style={{ overflowX: 'auto', width: '100%', overflowY: 'hidden', height:`${'900'}px` }}>
          <div className="mt-4 project-graph" style={{ width: '100%' }}>
            <div className="flex">
              {generateDateSlots()}
              {projectListingBoardData?.milestones?.length > 0 &&
                projectListingBoardData.milestones.map((milestone) => {
                  if (selectMileStoneId.includes(milestone.id)) {
                    return milestone.deliverables.map((event, i) =>
                      renderEvents(event, i, milestone.deliverables)
                    );
                  }
                  return null;
                })}

              {showSelectedEvent && (
                <div
                  style={{
                    top: selectedEvent.top,
                    left: selectedEvent.left,
                  }}
                  className="project-task"
                >
                  <div className="duration">
                    <span>
                      {moment(selectedEvent.delivery_start_date).format(
                        'DD MMM YY'
                      )}
                    </span>
                    <span>
                      {moment(selectedEvent.delivery_end_date).format(
                        'DD MMM YY'
                      )}
                    </span>
                  </div>

                  <div className="project-task-inner-content">
                    <div className="bar-icon">
                      <div className="bar-main">
                        <ProgressBar
                          className="task-bar"
                          variant="warning"
                          now={75}
                        />
                      </div>
                      <div className="task-layer-icon">
                        <img src={layers} alt="Layers icon" />
                      </div>
                    </div>

                    <div className="task-detail">
                      <span>{selectedEvent.title}</span>
                      <span>Quick summary</span>
                    </div>

                    <div className="bottom-image-icon">
                      <div className="task-images">
                        {Array(3)
                          .fill(
                            'https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg'
                          )
                          .map((src, idx) => (
                            <div className="img-consulting" key={idx}>
                              <img src={src} alt="Consulting example" />
                            </div>
                          ))}
                      </div>

                      <div className="task-icons">
                        <Row>
                          {[FireIcon, MachineIcon].map((icon, idx) => (
                            <div className="appointment_icon1" key={idx}>
                              <img
                                className="appointment_icon1_image"
                                src={icon}
                                alt={`Icon ${idx + 1}`}
                              />
                            </div>
                          ))}
                          <div className="dots">...</div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateGraph;
