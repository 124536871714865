const emojisArr = [
    { id: 8, name: 'angry-face' },
    { id: 23, name: 'anxious-face-with-sweat'},
    { id: 13, name: 'astonished-face'},
    { id: 35, name: 'call-me-hand-sign'},
    { id: 34, name: 'clapping-hands'},
    { id: 33, name: 'closed-fist'},
    { id: 10, name: 'drooling-face'},
    { id: 22, name: 'face-blowing-a-kiss'},
    { id: 17, name: 'face-with-medical-mask'},
    { id: 2, name: 'face-with-rolling-eyes'},
    { id: 20, name: 'face-with-stuck-out-tongue-and-squinting-eyes'},
    { id: 15, name: 'face-with-tears-of-joy'},
    { id: 38, name: 'fingers-crossed'},
    { id: 28, name: 'flexed-bicep'},
    { id: 26, name: 'grimacing-face'},
    { id: 3, name: 'grinning-face-with-squinting-eyes'},
    { id: 6, name: 'grinning-face-with-sweat'},
    { id: 21, name: 'grinning-face'},
    { id: 36, name: 'hands-pressed-togather'},
    { id: 5, name: 'loudly-crying-face'},
    { id: 19, name: 'money-mouth-face'},
    { id: 16, name: 'neutral-face'},
    { id: 31, name: 'ok-hand-sign'},
    { id: 27, name: 'palms-togather-face-up'},
    { id: 11, name: 'pleading-face'},
    { id: 32, name: 'raised-fist'},
    { id: 14, name: 'sleeping-face'},
    { id: 24, name: 'smiling-face-with-halo'},
    { id: 4, name: 'smiling-face-with-heart-eyes'},
    { id: 18, name: 'smiling-face-with-smiling-eyes'},
    { id: 9, name: 'smilling-face-with-horns'},
    { id: 1, name: 'surrprised-face-with-open-mouth'},
    { id: 30, name: 'thumbs-down'},
    { id: 29, name: 'thumbs-up'},
    { id: 12, name: 'vomiting-face'},
    { id: 37, name: 'waving-hand'},
    { id: 7, name: 'winking-face'},
    { id: 25, name: 'yawning-face'}
]

export {
    emojisArr
};
