/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect,} from 'react';
import {Button, Col, Form, Row, Container} from 'react-bootstrap';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {fixName} from '../../Utils/commonUtils';
import {DEFAULT_IMAGE} from '../../Utils/env';
import ProfileComponent from '../ProfilePage/ProfileComponent';

const UserListOfSnapshot = props => {
  const [showComponent, setShowComponent] = useState('');
  const [userId, setUserId] = useState('');
  useEffect(() => {
    if (props.invitedUser.sorted_invited_users) {
      if (props.invitedUser.sorted_invited_users.Leader) {
        setSubTabs('Leadership');
      } else if (props.invitedUser.sorted_invited_users.Member) {
        setSubTabs('Members');
      } else if (props.invitedUser.sorted_invited_users.Partner) {
        setSubTabs('Partners');
      } else {
        setSubTabs('Pending');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.invitedUser]);

  const handleProfileComponent = userId => {
    setUserId(userId);
    setShowComponent('profile');
  };

  const [subTabs, setSubTabs] = useState('Leadership');
  
  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper">
          <h2>All Members</h2>
          <Scrollbars>
            <div className="create-ticket-wrapper fix-width-forms">
              <div className="create-ticket-form">
                <Form noValidate>
                  <div className="">
                    <div className="event-tabs tabs-style-2 mb-0 mt-3">
                      <ul>
                        {props.invitedUser &&
                          props.invitedUser.sorted_invited_users &&
                          props.invitedUser.sorted_invited_users.Leader && (
                            <li>
                              <Button
                                className={
                                  subTabs === 'Leadership' ? 'active' : ''
                                }
                                onClick={() => {
                                  setSubTabs('Leadership');
                                }}>
                                Leadership
                              </Button>
                            </li>
                          )}
                        {props.invitedUser &&
                          props.invitedUser.sorted_invited_users &&
                          props.invitedUser.sorted_invited_users.Member && (
                            <li>
                              <Button
                                className={
                                  subTabs === 'Members' ? 'active' : ''
                                }
                                onClick={() => {
                                  setSubTabs('Members');
                                }}>
                                Members
                              </Button>
                            </li>
                          )}
                        {props.invitedUser &&
                          props.invitedUser.sorted_invited_users &&
                          props.invitedUser.sorted_invited_users.Partner && (
                            <li>
                              <Button
                                className={
                                  subTabs === 'Partners' ? 'active' : ''
                                }
                                onClick={() => {
                                  setSubTabs('Partners');
                                }}>
                                Partners
                              </Button>
                            </li>
                          )}
                        {props.invitedUser &&
                          props.invitedUser.sorted_invited_users &&
                          props.invitedUser.sorted_invited_users.pending && (
                            <li>
                              <Button
                                className={
                                  subTabs === 'Pending' ? 'active' : ''
                                }
                                onClick={() => {
                                  setSubTabs('Pending');
                                }}>
                                Pending
                              </Button>
                            </li>
                          )}
                      </ul>
                    </div>

                    <div className="invite-persons-wrapper">
                      <div className="invite-persons-list">
                        <Container>
                          {subTabs === 'Pending' && (
                            <Row>
                              {props.invitedUser &&
                              props.invitedUser.sorted_invited_users &&
                              props.invitedUser.sorted_invited_users.pending &&
                              Object.entries(
                                props.invitedUser.sorted_invited_users.pending,
                              ).length > 0 ? (
                                Object.entries(
                                  props.invitedUser.sorted_invited_users
                                    .pending,
                                ).length > 0 &&
                                props.invitedUser.sorted_invited_users.pending.map(
                                  (item, index) => (
                                    <Col key={`f-item-${index}`} md={4} xl={3}>
                                      <div
                                        className="stage-list-item "
                                        key={`Pending-${index}`}>
                                        <div
                                          className="stage-person-img as-link"
                                          onClick={() => {
                                            handleProfileComponent(item.id);
                                          }}>
                                          <img
                                            src={
                                              item.profile_img === ''
                                                ? DEFAULT_IMAGE
                                                : item.profile_img
                                            }
                                            alt=""
                                            id={item.id}
                                          />
                                        </div>
                                        <div
                                          className="stage-person-name as-link"
                                          onClick={() => {
                                            handleProfileComponent(item.id);
                                          }}>
                                          <h3>
                                            {fixName(
                                              item.first_name,
                                              item.last_name,
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  ),
                                )
                              ) : (
                                <p className="text-center">No user found.</p>
                              )}
                            </Row>
                          )}

                          {subTabs === 'Leadership' && (
                            <Row>
                              {props.invitedUser &&
                              props.invitedUser.sorted_invited_users &&
                              props.invitedUser.sorted_invited_users.Leader &&
                              Object.entries(
                                props.invitedUser.sorted_invited_users.Leader,
                              ).length > 0 ? (
                                Object.entries(
                                  props.invitedUser.sorted_invited_users.Leader,
                                ).length > 0 &&
                                props.invitedUser.sorted_invited_users.Leader.map(
                                  (item, index) => (
                                    <Col key={`f-item-${index}`} md={4} xl={3}>
                                      <div
                                        className="stage-list-item "
                                        key={`folowers-${index}`}>
                                        <div
                                          className="stage-person-img as-link"
                                          type="checkbox"
                                          onClick={() => {
                                            handleProfileComponent(item.id);
                                          }}>
                                          <img
                                            src={
                                              item.profile_img === ''
                                                ? DEFAULT_IMAGE
                                                : item.profile_img
                                            }
                                            alt=""
                                            id={item.id}
                                          />
                                        </div>
                                        <div
                                          className="stage-person-name as-link"
                                          onClick={() => {
                                            handleProfileComponent(item.id);
                                          }}>
                                          <h3>
                                            {fixName(
                                              item.first_name,
                                              item.last_name,
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  ),
                                )
                              ) : (
                                <p className="text-center">No user found.</p>
                              )}
                            </Row>
                          )}
                          {subTabs === 'Partners' && (
                            <Row>
                              {props.invitedUser &&
                              props.invitedUser.sorted_invited_users &&
                              props.invitedUser.sorted_invited_users.Partner &&
                              Object.entries(
                                props.invitedUser.sorted_invited_users.Partner,
                              ).length > 0 ? (
                                Object.entries(
                                  props.invitedUser.sorted_invited_users
                                    .Partner,
                                ).length > 0 &&
                                props.invitedUser.sorted_invited_users.Partner.map(
                                  (item, index) => (
                                    <Col key={`f-again-${index}`} md={4} xl={3}>
                                      <div
                                        className="stage-list-item"
                                        key={`folow-${index}`}>
                                        <div
                                          className="stage-person-img as-link"
                                          onClick={() => {
                                            handleProfileComponent(item.id);
                                          }}>
                                          <img
                                            src={
                                              item.profile_img === ''
                                                ? DEFAULT_IMAGE
                                                : item.profile_img
                                            }
                                            alt=""
                                            id={item.id}
                                          />
                                        </div>
                                        <div
                                          className="stage-person-name as-link"
                                          id={item.id}
                                          value={item.id}
                                          onClick={() => {
                                            handleProfileComponent(item.id);
                                          }}>
                                          <h3>
                                            {fixName(
                                              item.first_name,
                                              item.last_name,
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  ),
                                )
                              ) : (
                                <p className="text-center">No user found.</p>
                              )}
                            </Row>
                          )}
                          {subTabs === 'Members' && (
                            <Row>
                              {props.invitedUser &&
                              props.invitedUser.sorted_invited_users &&
                              props.invitedUser.sorted_invited_users.Member &&
                              Object.entries(
                                props.invitedUser.sorted_invited_users.Member,
                              ).length > 0 ? (
                                Object.entries(
                                  props.invitedUser.sorted_invited_users.Member,
                                ).length > 0 &&
                                props.invitedUser.sorted_invited_users.Member.map(
                                  (item, index) => (
                                    <Col key={`f-again-${index}`} md={4} xl={3}>
                                      <div
                                        className="stage-list-item"
                                        key={`folow-${index}`}>
                                        <div
                                          className="stage-person-img as-link"
                                          onClick={() => {
                                            handleProfileComponent(item.id);
                                          }}>
                                          <img
                                            src={
                                              item.profile_img === ''
                                                ? DEFAULT_IMAGE
                                                : item.profile_img
                                            }
                                            alt=""
                                            id={item.id}
                                          />
                                        </div>
                                        <div
                                          className="stage-person-name as-link"
                                          id={item.id}
                                          value={item.id}
                                          onClick={() => {
                                            handleProfileComponent(item.id);
                                          }}>
                                          <h3>
                                            {fixName(
                                              item.first_name,
                                              item.last_name,
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  ),
                                )
                              ) : (
                                <p className="text-center">No user found.</p>
                              )}
                            </Row>
                          )}
                        </Container>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>
      {showComponent === 'profile' && userId && (
        <ProfileComponent
          userId={userId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
    </>
  );
};

export default UserListOfSnapshot;
