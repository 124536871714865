import React, {useState, useRef} from 'react';
import {connect,useDispatch} from 'react-redux';
import {Alert, Button, Form} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import Moment from 'moment';
import {DEFAULT_IMAGE} from '../Utils/env';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {ALLOWED_COUNTRY, selected_lang} from '../Utils/env';
import {requestOtp, verifyOtp} from '../Store/Actions/AuthActions';
import VerifyPhone from './VerifyPhone';
import {labels} from '../Constants/Translations';
import {fixName} from '../Utils/commonUtils';
import {useHistory} from 'react-router-dom';
import commonApi from '../Services/CommonService';
import { registerRoute } from '../Store/Actions/CommanAction';

import {RoiLogo, registerStarter} from '../Constants/Images';

const LoginForm = ({requestOtp, verifyOtp, ref}) => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const button2Ref = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [messageType, setMessageType] = useState('');
  const [message, setMessage] = useState('');
  const [otpState, changeOtpState] = useState(false);
  const [verifyPhoneNo, setVerifyPhoneNo] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const [otp, setOtp] = useState('');
  const [inviName, setInviName] = useState('');
  const [inviImg, setInviImg] = useState('');
  const [inviDate, setInviDate] = useState('');
  const [button, setButton] = useState('business');
  const history = useHistory();
  const dispatch = useDispatch();
  const displayAlert = (type, message) => {
    setShowAlert(true);
    setMessageType(type);
    setMessage(message);
  };

  const handleClickComponent1Button = () => {
 
    // Trigger the second component's button click
    if (button2Ref.current) {
      button2Ref.current.click();
    }
  };

  const submitLogin = event => {
    event.preventDefault();
    // setLoading(true);

    if (phone.length > 9) {
      localStorage.setItem('loginNum', '+' + phone);

      let data = {
        number: '+' + phone,
      };
      const payData = {
        number: '+' + phone,
        token: localStorage.getItem('newFbToken'),
      };

      // changeOtpState(true);

      // API CALL

      commonApi
        .check_invited_by(data)
        .then(res => {
          if (res.status === 200) {
            setInviName(fixName(res.user.first_name, res.user.last_name));
            setInviImg(
              res.user.profile_img === ''
                ? DEFAULT_IMAGE
                : res.user.profile_img,
            );
            setInviDate(
              Moment(res.invitation_details.created_on)
                .format('MM/DD/YY')
                .toString(),
            );
          }
        })
        .catch(err => {
          setLoading(false);
          // displayAlert("danger", err.message);
        });

      requestForOtp(payData);
    } else {
      displayAlert('danger', 'Please enter a valid phone number');
    }
    return false;
  };

  const phoneVerification = event => {
    event.preventDefault();
    if (phone.length > 9) {
      // localStorage.setItem('loginNum', '+' + phone);
      // let data = {
      //   number: '+' + phone,
      // };

      if (phone === '923012345670') {
        setVerifyPhoneNo(false);
      } else {
        // history.push('/registercompany');

        // history.push({
        //   pathname: '/registercompany',
        //   state: {
        //     button: button
            
        //   },
        // });
      }
    } else {
      displayAlert('danger', 'Please enter a valid phone number');
    }
  };

  const requestForOtp = num => {
    requestOtp(num)
      .then(res => {
        if (!res.twilio) {
          displayAlert('danger', res.message);
          return false;
        }
        changeOtpState(true);
        if (res.status === 200) {
          changeOtpState(true);
          const networkOtp = res.otp ? res.otp : '';
          setOtp(networkOtp);

          const userState = res.new_user === 'true' ? true : false;
          setNewUser(userState);
        }

        // const type = res.type === "error" ? "danger" : res.type;
        const type = res.twilio ? 'success' : 'danger';
        displayAlert(type, res.message);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        displayAlert('danger', err.message);
      });
  };

  const updatePhone = () => {
    setShowAlert(false);
    setPhone('');
    setOtp('');
    setNewUser(false);
    changeOtpState(false);
  };

 
  return (
    <>
      <div className=" verify-roi-phone slider-component  register-phone">
        <div
          className="slider-component-img"
          style={
            {
              // backgroundImage: `url(${registerStarter})`,
              // backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
              // backgroundPosition: 'center',
            }
          }>
          <div className="roi-logo as-link">
          <a href="/">
            <img src={RoiLogo} alt="" />
            </a>
          </div>

          {otpState && !verifyPhoneNo && (
            <div className="back-btn mobile_back_btn">
            <Button
              className="new-btn btn-green "
              onClick={() => {
                setShowAlert(false)
                // setPhone('');
                changeOtpState(false)
                setInviDate('')
                setInviImg('')
                setInviName('')
              }}>
              Back
            </Button>
          </div>
          )}


          <div className="register-page1-text">
            <div className="slider-component-img-text">
              Streamlined Experiences
            </div>

            <p className="bottom-text">
              Spend your time on things that matter the most
            </p>
          </div>

          <div className="starter-img">
            <img src={registerStarter} alt="" />
          </div>
        </div>

        <div className="slider-component-content register-component2">

          {otpState && !verifyPhoneNo && (
            <div className="back-btn desktop_back_btn">
            <Button
              className="new-btn btn-green "
              onClick={() => {
                setShowAlert(false)
                // setPhone('');
                changeOtpState(false)
                setInviDate('')
                setInviImg('')
                setInviName('')
              }}>
              Back
            </Button>
          </div>
          )}


          {!otpState && (
            <div className="next-btn">
              <Button
                className="new-btn btn-green "
                onClick={() => {
                  handleClickComponent1Button();
                }}>
                Next
              </Button>
            </div>
          )}

          <div className="slider-content">
            <h1 className="mb-2 font_18" >Let’s get started</h1>

            <div className="slider-content-inputs" style={{marginTop:'35px'}}>
              <div className="auth-form auth-login-custom-flag-form">
                {showAlert && (
                  <Alert
                    variant={messageType}
                    onClose={() => setShowAlert(false)}
                    dismissible>
                    <p>{message}</p>
                  </Alert>
                )}

                {!otpState && !verifyPhoneNo && (
                  <Form onSubmit={submitLogin}>
                    <div className="auth-logo">
                      <h3 className="placeholder_N" style={{fontSize:'15px'}}>Phone Number</h3>
                    </div>
                    <Form.Group className="form-group">
                      <PhoneInput
                        onlyCountries={ALLOWED_COUNTRY}
                        country={'us'}
                        value={phone}
                        onChange={phone => setPhone(phone)}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            submitLogin(e);
                          }
                        }}
                      />

                      {/* <RenderHTMLBlock
              container="span"
              className="info-text"
              content={labels.loginInfoText[selected_lang]}
            /> */}
                    </Form.Group>
                    {/* <div className="auth-logo">
            <h3 className="placeholder_N">Which are you?</h3>
          </div>
          <Button
            className="new-btn_login btn-white"
            // onClick={() => {
            //   props.slider?.current?.slickNext();
            // }}
            type="submit">
            Business
          </Button>
          <Button
            className="new-btn_customer btn-white mt-3"
            // onClick={() => {
            //   props.slider?.current?.slickNext();
            // }}
            type="submit">
            Customer
          </Button> */}
                    <div>
                      <Button
                        style={{display: 'none'}}
                        className="next_button btn-green mt-3"
                        // onClick={() => {
                        //   props.slider?.current?.slickNext();
                        // }}
                        ref={button2Ref}
                        onClick={submitLogin}>
                        Next
                        {loading && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="fa-spin"
                          />
                        )}
                      </Button>
                    </div>
                    {/* <Form.Group className="form-group mb-0">
            <button className="btn btn-small btn-dark" onClick={submitLogin}>
              {!loading && labels.sendVerificationCode[selected_lang]}
              {loading && (
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
              )}
            </button>
          </Form.Group> */}

                    {/* <div className="signup-links">
                        Don't have account? <Link to="/register">Register Now</Link>
                    </div> */}
                  </Form>
                )}

                {verifyPhoneNo && (
                  <Form onSubmit={phoneVerification}>
                    <div className="auth-logo">
                      <h3>{labels.letsStartPhone[selected_lang]}</h3>
                    </div>
                    <Form.Group className="form-group">
                      <PhoneInput
                        onlyCountries={ALLOWED_COUNTRY}
                        country={'us'}
                        value={phone}
                        onChange={phone => setPhone(phone)}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            phoneVerification(e);
                          }
                        }}
                      />

                      {/* <RenderHTMLBlock
              container="span"
              className="info-text"
              content={labels.loginInfoText[selected_lang]}
            /> */}
                    </Form.Group>

                    <Form.Group className="form-group mb-0">
                      <button
                        className="btn btn-small btn-dark"
                        onClick={phoneVerification}>
                        {!loading && labels.verifyPhoneNo[selected_lang]}
                        {loading && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="fa-spin"
                          />
                        )}
                      </button>
                    </Form.Group>

                    {/* <div className="signup-links">
                        Don't have account? <Link to="/register">Register Now</Link>
                    </div> */}
                  </Form>
                )}

                {otpState && (
                  <VerifyPhone
                    otp={otp}
                    isNewUser={isNewUser}
                    phone={phone}
                    showAlert={(type, message) => displayAlert(type, message)}
                    updatePhone={() => updatePhone()}
                    verifyOtp={data => verifyOtp(data)}
                    inviName={inviName}
                    inviImg={inviImg}
                    inviDate={inviDate}
                    requestForOtp={num => requestForOtp(num)}
                    button={button}
                    setButton={setButton}
                  />
                )}
              </div>

              {/* <div className="input-group mb-4">
                <Form.Label className="mb-2" controlid="validationCustom01">
                  Phone Number
                </Form.Label>

                <Form.Control
                  type="number"
                  value={phoneNumber}
                  pattern="^\S.*$"
                  required
                  placeholder=""
                  onChange={e => {
                      setPhoneNumber(e.target.value)
                  //   setCompanyData({...companyData, contact: e.target.value});
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Phone Number is required.
                </Form.Control.Feedback>
              </div> */}
              {/* {!otpState && (
                <div className="input-group user-type mt-3">
                  <h3 className="mb-3">Which are you?</h3>

                  <Button
                    // className="new-btn_login "
                    className={`   ${
                      button == 'business'
                        ? 'new-btn_login'
                        : button == ''
                        ? 'background-black'
                        : 'new-btn_customer'
                    }`}
                    onClick={() => {
                      setButton('business');
                    }}>
                    Business
                  </Button>
                  <Button
                    className={`mt-3  ${
                      button == 'customer'
                        ? 'new-btn_login'
                        : 'new-btn_customer'
                    }`}
                    onClick={() => {
                      setButton('customer');
                    }}>
                    Customer
                  </Button>
                </div>
              )} */}
            </div>
          </div>
        </div>

        {!otpState && (
            <div className="responsive_next_btn login_next_btn text-center">
              <Button
                className="new-btn btn-green "
                onClick={() => {
                  handleClickComponent1Button();
                }}>
                Next
              </Button>

            
            </div>
          )}
      </div>
    </>
  );
};

export default connect(null, {requestOtp, verifyOtp})(LoginForm);
