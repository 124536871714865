import {Form} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import {Car, HomeServices} from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';

import React, {useState, useEffect} from 'react';
import {
  Logo,
  hurricane,
  drive,
  driveThru,
  cruise,
  UrgentPlumber,
  cookies,
  strideretail,
  shoppingRetail,
  RetailImg1,
  RetailImg2,
  RetailImg3,
} from '../../Constants/Images';

import {CustomSlidingTabs, Pannels} from '../../Components';

import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import SvgIconComponent from '../../Constants/SvgIconComponent';

const MarketHome = props => {
  const [subTabType, setSubTabsType] = useState('home services');

  const [showComponent, setShowComponent] = useState('');

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const userProfileSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  const reatilImagesSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const images_array = [Logo, UrgentPlumber, hurricane, Logo, UrgentPlumber];
  const ride_images = [drive, driveThru, cruise, drive, driveThru];
  const retail_images = [
    shoppingRetail,
    cookies,
    strideretail,
    shoppingRetail,
    cookies,
  ];

  const retailProductimages = [
    RetailImg1,
    RetailImg2,
    RetailImg3,
    RetailImg1,
    RetailImg2,
  ];

  //

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          workspaceData={workspaceData}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper">
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="market-home-page">
                    <div className="event-tabs sliding-tabs mb-3 customer-product-page">
                      <CustomSlidingTabs
                        items={[
                          {
                            key: 'home services',
                            label: 'Home Services',
                            icon: '',
                            img: (
                              <SvgIconComponent
                                icon={'home_services'}
                                fill={
                                  subTabType === 'home services'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'25'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'retail',
                            label: 'Retail',
                            icon: 'fa-solid fa-cart-shopping',
                            img: '',
                          },
                          {
                            key: 'consulting',
                            label: 'Consulting',
                            img: (
                              <SvgIconComponent
                                icon={'consulting'}
                                fill={
                                  subTabType === 'consulting'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'23'}
                                w={'30'}
                              />
                            ),
                          },
                          // {
                          //   key: 'consults',
                          //   label: 'Consults',
                          // },
                          {
                            key: 'rideshare',
                            label: 'Rideshare',
                            img: (
                              <SvgIconComponent
                                icon={'rideshare'}
                                fill={
                                  subTabType === 'rideshare'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'25'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'pickup drop-off',
                            label: 'Pickup Drop-off',
                            img: (
                              <SvgIconComponent
                                icon={'pickup_drop_off'}
                                fill={
                                  subTabType === 'pickup drop-off'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'25'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'nonprofit',
                            label: 'Nonprofit',
                            img: (
                              <SvgIconComponent
                                icon={'nonprofit'}
                                fill={
                                  subTabType === 'nonprofit'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'23'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'dining',
                            label: 'Dining',
                            img: (
                              <SvgIconComponent
                                icon={'dining'}
                                fill={
                                  subTabType === 'dining' ? 'white' : '#262626'
                                }
                                h={'23'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'event management',
                            label: 'Event Management',
                            img: (
                              <SvgIconComponent
                                icon={'event_management'}
                                fill={
                                  subTabType === 'event management'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'25'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'rentals',
                            label: 'Rentals',
                            img: (
                              <SvgIconComponent
                                icon={'rentals'}
                                fill={
                                  subTabType === 'rentals' ? 'white' : '#262626'
                                }
                                h={'25'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'real_estate',
                            label: 'Real Estate',
                            img: (
                              <SvgIconComponent
                                icon={'real_estate'}
                                fill={
                                  subTabType === 'real_estate'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'25'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'co-working space',
                            label: 'Co-Working Space',
                            img: (
                              <SvgIconComponent
                                icon={'cp_worker'}
                                fill={
                                  subTabType === 'co-working space'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'25'}
                                w={'30'}
                              />
                            ),
                          },
                          {
                            key: 'trade shops',
                            label: 'Trade Shops',
                            img: (
                              <SvgIconComponent
                                icon={'trade_shops'}
                                fill={
                                  subTabType === 'trade shops'
                                    ? 'white'
                                    : '#262626'
                                }
                                h={'23'}
                                w={'30'}
                              />
                            ),
                          },
                        ]}
                        showItems={5}
                        activeTab={subTabType}
                        onItemClick={item => {
                          // setMustApiHit(false)
                          // setHasMoreData(true)
                          setSubTabsType(item);
                          // setshowLoader(true);
                          // handleApiSelector(item);
                        }}
                        component={'productComponent'}
                      />
                    </div>
                    <div className="market-page-wrapper">
                      <Scrollbars autoHide>
                        <div className="home_services">
                          {subTabType === 'home services' && (
                            <div className="home-tab">
                              <div className="home-top">
                                <div className="home-title-img">
                                  <img src={HomeServices} alt="" />
                                  <h1>Home Services</h1>
                                </div>

                                <div className="service-count">10</div>
                              </div>

                              <div className="company-logo-slider">
                                <Slider {...userProfileSlider}>
                                  {images_array.length > 0 &&
                                    images_array.map((miniItem, index) => (
                                      <div
                                        className=" company-logo-card"
                                        key={index + 'eee'}>
                                        <div className="  mr-5">
                                          <img
                                            src={miniItem}
                                            alt=""
                                            className=""
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </Slider>
                              </div>
                            </div>
                          )}

                          {(subTabType === 'home services' ||
                            subTabType === 'rideshare') && (
                            <div className="home-tab">
                              <div className="home-top">
                                <div className="home-title-img">
                                  <img src={Car} alt="" />
                                  <h1>Rideshare</h1>
                                </div>

                                <div className="service-count">5</div>
                              </div>

                              <div className="ride-distance">
                                Closest driver is approx 15 min away
                              </div>
                              <div className="company-logo-slider">
                                <Slider {...userProfileSlider}>
                                  {ride_images.length > 0 &&
                                    ride_images.map((miniItem, index) => (
                                      <div
                                        className=" company-logo-card"
                                        key={index + 'eee'}>
                                        <div className="  mr-5">
                                          <img
                                            src={miniItem}
                                            alt=""
                                            className=""
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </Slider>
                              </div>

                              <div className="enter-destination-input">
                                <Form.Control
                                  type="text"
                                  value={''}
                                  pattern="^\S.*$"
                                  required
                                  placeholder="Enter destination"
                                  onChange={e => {}}
                                />
                                <div className="destination-btn">
                                  <i className="fa-solid fa-calendar-days" />{' '}
                                  Now
                                </div>
                              </div>
                            </div>
                          )}

                          {(subTabType === 'home services' ||
                            subTabType === 'retail') && (
                            <div className="home-tab retail-tab mt-4">
                              <div className="home-top">
                                <div className="home-title-img">
                                  <i
                                    style={{
                                      marginRight: '10px',
                                      fontSize: '19px',
                                    }}
                                    class="fa-solid fa-cart-shopping"></i>
                                  <h1>Retail</h1>
                                </div>

                                <div className="service-count ">
                                  <div>Shop as One</div>
                                  <div>5</div>
                                </div>
                              </div>

                              <div className="company-logo-slider">
                                <Slider {...userProfileSlider}>
                                  {retail_images.length > 0 &&
                                    retail_images.map((miniItem, index) => (
                                      <div
                                        className=" company-logo-card"
                                        key={index + 'eee'}>
                                        <div className="  mr-5">
                                          <img
                                            src={miniItem}
                                            alt=""
                                            className=""
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </Slider>
                              </div>

                              <div className="retail-product-slider">
                                <Slider {...reatilImagesSlider}>
                                  {retailProductimages.length > 0 &&
                                    retailProductimages.map((item, index) => (
                                      <div className="retail-product-img">
                                        <img src={item} alt="" />
                                      </div>
                                    ))}
                                </Slider>
                              </div>
                            </div>
                          )}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketHome;
