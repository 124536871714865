"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.senderVideoScore = exports.videoScore = exports.audioScore = void 0;
/**
 * @ignore
 */
const MIN_VIDEO_BITRATE = 30000;
/**
 * @ignore
 * @param {*} fractionLost
 * @param {*} rtt
 * @param {*} jitter
 */
const audioScore = (fractionLost, rtt, jitter) => {
    const latencyImpact = 2;
    const compTime = 20; // computation time
    const factorLatencyBased = 4;
    const aggressiveFactorLatencyBased = 10;
    const lossImpact = 2.5;
    const effectiveLatency = rtt + jitter * latencyImpact + compTime;
    let R = 93.2 - (effectiveLatency < 160 ? effectiveLatency / factorLatencyBased : effectiveLatency - 120.0) / aggressiveFactorLatencyBased;
    R -= fractionLost * lossImpact;
    R = Math.max(0, R);
    return ((1 + 0.035 * R + 0.000007 * R * (R - 60) * (100 - R)) * 100.0) / 100.0;
};
exports.audioScore = audioScore;
/**
 * @ignore
 */
const bitrateForPixelCount = (pixelCount) => {
    // Framerate ignored
    return Math.pow(10, 2.069924867 * Math.pow(Math.log10(pixelCount), 0.6250223771));
};
/**
 * @ignore
 * @param {*} lastStats
 * @param {*} currentStats
 * @param dvcs
 */
const videoScore = (lastStats, currentStats, dvcs = true) => {
    let score = 0;
    if (lastStats) {
        const lastInboundRtp = lastStats.filter((s) => s.type === 'inbound-rtp' && s.kind === 'video');
        const currentInboundRtp = currentStats.filter((s) => s.type === 'inbound-rtp' && s.kind === 'video');
        const filterFn = dvcs ? (s) => s.type === 'track' : (s) => s.type === 'track' && s.kind === 'video';
        const videoTrack = currentStats.filter(filterFn);
        if (lastInboundRtp.length > 0 && currentInboundRtp.length > 0 && videoTrack.length > 0) {
            const totalPackets = parseInt(currentInboundRtp[0].packetsLost) +
                parseInt(currentInboundRtp[0].packetsReceived) -
                (parseInt(lastInboundRtp[0].packetsLost) + parseInt(lastInboundRtp[0].packetsReceived));
            const packetLoss = (parseInt(currentInboundRtp[0].packetsLost) - parseInt(lastInboundRtp[0].packetsLost)) / totalPackets;
            const interval = parseInt(currentInboundRtp[0].timestamp) - parseInt(lastInboundRtp[0].timestamp);
            let bitrate = (8 * (parseInt(currentInboundRtp[0].bytesReceived) - parseInt(lastInboundRtp[0].bytesReceived))) / (interval / 1000);
            const targetBitrate = bitrateForPixelCount(parseInt(videoTrack[0].frameHeight) * parseInt(videoTrack[0].frameWidth));
            if (bitrate > 30000) {
                bitrate = Math.min(bitrate, targetBitrate);
                score = (Math.log(bitrate / MIN_VIDEO_BITRATE) / Math.log(targetBitrate / MIN_VIDEO_BITRATE)) * 4 + 1;
            }
        }
    }
    return score;
};
exports.videoScore = videoScore;
/**
 * @ignore
 */
const senderVideoScore = (lastStats, currentStats, videoTracks) => {
    let score = 0;
    if (lastStats) {
        const lastOutboundRtp = lastStats.filter((s) => s.type === 'outbound-rtp' && s.kind === 'video');
        const currentOutboundRtp = currentStats.filter((s) => s.type === 'outbound-rtp' && s.kind === 'video');
        if (lastOutboundRtp.length > 0 && currentOutboundRtp.length > 0 && videoTracks.length > 0) {
            const interval = parseInt(currentOutboundRtp[0].timestamp) - parseInt(lastOutboundRtp[0].timestamp);
            let bitrate = (8 * (parseInt(currentOutboundRtp[0].bytesSent) - parseInt(lastOutboundRtp[0].bytesSent))) / (interval / 1000);
            const targetBitrate = bitrateForPixelCount(parseInt(videoTracks[0].getSettings().height) * parseInt(videoTracks[0].getSettings().width));
            if (bitrate > 30000) {
                bitrate = Math.min(bitrate, targetBitrate);
                score = (Math.log(bitrate / MIN_VIDEO_BITRATE) / Math.log(targetBitrate / MIN_VIDEO_BITRATE)) * 4 + 1;
            }
        }
    }
    return score;
};
exports.senderVideoScore = senderVideoScore;
