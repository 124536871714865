import React, {useState, useEffect} from 'react';
import AppointmentCall from '../../Components/AppointmentPage/AppointmentCall';
import Pannels from '../Pannels';
import Sidebar from '../../Pages/Sidebar';
import {useHistory, useLocation} from 'react-router-dom';
import {amplitudeEvent} from '../../Services/AmplitudeServices';

const AppointmentCallPage = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [confData, setConfData] = useState(null);

  const locationWeb = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (
      locationWeb &&
      locationWeb.state &&
      locationWeb.state.hasOwnProperty('callData')
    ) {
      setConfData(locationWeb.state.callData);
      amplitudeEvent('JOIN_APPOINTMENT_CALL');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <div className="page-wrapper">
      <Sidebar location={props.location} />
      <div className="page-content-wrapper">
        <Pannels
          isFloated={isFloated}
          toggleFloatedPanel={toggleFloatedPanel}
          showHiddenPanel={showHiddenPanel}
          updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
          primary={
            confData && (
              <AppointmentCall
              type={'sidebar'}
                onClose={() => {
                  
                  history.goBack();
                }}
                confData={confData}
              />
            )
          }
          secondary={<></>}
        />
      </div>
    </div>
  );
};

export default AppointmentCallPage;
