import API from '../../Services/SessionServices';
import commonApi from '../../Services/CommonService';

export const getDispatch = (type, data) => ({
  type: type,
  payload: data,
});

export const getDispatchPayload = (type, data, payload) => ({
  type: type,
  payload: data,
  prevPayload: payload,
});

export const getSessionListing = data => {
  return async dispatch => {
    if (typeof data === 'object') {
      const response = await API.sessions_list(data);
      if (data.page > 1) {
        dispatch(getDispatch('GET_DATA_PAGE', response));
      } else {
        dispatch(getDispatchPayload('GET_DATA', response, data));
      }
      return response;
    } else {
      dispatch(getDispatch('MANAGE_FAVOURITE', data));
    }
  };
};


export const getAllBuilderList = (data) =>{
  return async dispatch => {
    const response = await commonApi.followers_list(data);
    dispatch(getDispatch('BUILDER/GET_DATA', response.data));
    return response;
  };
}


export const getAllWatchesList = (data) =>{
  return async dispatch => {
    const response = await commonApi.followers_list(data);
    dispatch(getDispatch('WATCHES/GET_DATA', response.data));
    return response;
  };
}

export const getAllActions = (data) =>{
  return async dispatch => {
    const response = await commonApi.get_actions(data);
    dispatch(getDispatch('ACTIONS/GET_DATA', response.all_actions));
    return response;
  };
}

export const getSessionCategory = () => {
  return async dispatch => {
    const response = await commonApi.session_categories();
    dispatch(getDispatch('GET_DATA_CAT', response.data));
    return response;
  };
};

export const getBuilderList = () => {
  return async dispatch => {
    const response = await commonApi.get_random_builder();
    dispatch(getDispatch('BUILDER/GET_DATA', response.all_other_builder));
    return response;
  };
};

export const getUserSessionList = data => {
  return async dispatch => {
    const response = await commonApi.sessions_list_by_id(data);
    dispatch(getDispatch('GET_USER_SESSION_LIST', response));
    return response;
  };
};

export const getUserEventList = data => {
  return async dispatch => {
    const response = await commonApi.sessions_list_by_id(data);
    dispatch(getDispatch('GET_USER_EVENT_LIST', response));
    return response;
  };
};


export const getCompanyMeetUpList = data => {
  return async dispatch => {
    const response = await commonApi.get_companyuser_session(data);
    dispatch(getDispatch('GET_COMPANY_MEETUP_LIST', response.company_detail.meetups ? response.company_detail.meetups : []));
    return response;
  };
};


export const getHitWorkspace = data => {
  return async dispatch => {
    const response = await commonApi.get_my_workspaces(data);
    dispatch(getDispatch('List_Workspace', response));
    return response;
  };
};
export const get_my_all_associate_workspaces = data => {
  return async dispatch => {
    const response = await commonApi.get_my_all_associate_workspaces(data);
    dispatch(getDispatch('List_Associate_Workspaces', response));
    return response;
  };
};
