import React, {useState} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import Slider from 'react-slick';
import Sidebar from '../../Pages/Sidebar.js';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  rewardStar,
  Bandage,
} from '../../Constants/Images';
import {Button, Form} from 'react-bootstrap';
import {Pannels} from '../../Components';

import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';

const Order = props => {
  const [showComponent, setShowComponent] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);

  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [threeDots, setThreeDots] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [tabType, setTabType] = useState('invoices');
  const [currentComponent, setCurrentComponent] = useState('');
  const [chatSearchQry, setChatSearchQry] = useState('');

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Sales',
      money: '$2.4k',
      time: 'versus last month',
    },

    {
      name: 'Paid On-Time',
      money: '85%',
      time: 'versus last month',
    },

    {
      name: 'Conversion',
      money: '25%',
      time: 'versus last month',
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
    },
  ];

  const dynamicStyle = {
    padding: 0,
  };
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceItem={workspaceData}
        />

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span
                              style={{
                                color: '#000',

                                fontFamily: 'Inter',
                                // fontSize: '10.833px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                              }}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                color: '#000',

                                fontFamily: 'Inter',
                                fontSize: '17px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                              }}>
                              {item.money}
                              {item.name === 'Rating' && (
                                <img src={rewardStar} alt="" />
                              )}
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            </span>
                            <div
                              class="message-date-info"
                              style={
                                {
                                  // marginLeft: '13px',
                                }
                              }>
                              <span
                                class="message-date"
                                style={{
                                  color: '#333',
                                  fontFamily: 'Helvetica',
                                  // fontSize: '9px',
                                  fontStyle: 'normal',
                                  fontWeight: '300',
                                  lineHeight: 'normal',
                                }}>
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>

            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="primary_panel_wraper primary_panel_content payment_invoice">
                    <div className="messages-header mb-2">
                      <div className="event-tabs tabs-style-2 mb-2">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'invoices' ? 'active' : ''}
                              onClick={() => {
                                setTabType('invoices');
                              }}>
                              Pending
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={
                                tabType === 'estimates' ? 'active' : ''
                              }
                              onClick={() => {
                                setTabType('estimates');
                              }}>
                              Ordered
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={tabType === 'paid' ? 'active' : ''}
                              onClick={() => {
                                setTabType('paid');
                              }}>
                              Arrived
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        <span
                          className={
                            currentComponent === 'createInvoice'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => setCurrentComponent('createInvoice')}
                          />
                        </span>
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    {threeDots && (
                      <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                        <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Who are you looking for?"
                              value={chatSearchQry}
                              onChange={e => {
                                setChatSearchQry(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Button>
                            <i
                              className="icon-filter-icon"
                              // onClick={() => setSearchFilterModal(true)}
                            />
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="refferal-list-wrapper">
                      <Scrollbars autoHide>
                        {tabType === 'invoices' && (
                          <div className="mt-2">
                            <div
                              className="payment-card-main"
                              onClick={() =>
                                setCurrentComponent('detailInvoice')
                              }>
                              <div className="payment-content-card">
                                <div className="task-profile-img">
                                  <img
                                    src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                    alt=""
                                  />
                                </div>

                                <div className="task-content">
                                  <h1
                                    style={{
                                      color: '#000',
                                      fontFamily: 'Inter',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '700',
                                      lineHeight: 'normal',
                                    }}>
                                    Samantha Ellington
                                  </h1>

                                  <span
                                    style={{
                                      color: '#000',
                                      fontFamily: 'Inter',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: 'normal',
                                    }}>
                                    Invoice Title
                                  </span>
                                  <span
                                    className="project-title"
                                    style={{
                                      color: '#707070',
                                      fontFamily: 'Inter',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: 'normal',
                                    }}>
                                    Project
                                  </span>
                                </div>
                              </div>

                              <div className="payment-service-icons">
                                <div className="roi-service-icons">
                                  <div className="service-icon">
                                    <img src={WhitePenIcon} alt="" />
                                  </div>

                                  <div className="service-icon-time">
                                    <img className="" src={timeIcon} alt="" />
                                    <span>5D</span>
                                  </div>

                                  <div className="service-icon w-25">
                                    <img src={FireIcon} alt="" />
                                  </div>

                                  <div className="service-icon w-29">
                                    <img src={MachineIcon} alt="" />
                                  </div>

                                  <div className="service-icon w-29">
                                    <img src={ArrowIcon} alt="" />
                                  </div>

                                  <div className="service-icon w-29">
                                    <img src={Bandage} alt="" />
                                  </div>

                                  <div className="service-icon w-28">
                                    <img src={heartIcon} alt="" />
                                  </div>

                                  <div className="service-icon w-29">
                                    <img src={bagIcon} alt="" />
                                  </div>
                                  <div className="w-29">
                                    <i
                                      className="fa-solid fa-ellipsis"
                                      style={{
                                        color: '#0a0a0a',
                                        paddingTop: '15px',
                                      }}></i>
                                  </div>
                                </div>
                              </div>

                              <div className="payment-card-bottom">
                                <div
                                  className="payment-card-icons"
                                  style={{
                                    color: '#262626',

                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    paddingTop: '11px',
                                  }}>
                                  Order by 01/15/24
                                </div>

                                <div className="pay-btn">
                                  <span
                                    style={{
                                      color: '#262626',
                                      fontFamily: 'Inter',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '600',
                                      lineHeight: 'normal',
                                    }}>
                                    {' '}
                                    $575
                                  </span>

                                  <Button
                                    className="new-btn btn-green ml-3 "
                                    onClick={() => {}}>
                                    order
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {tabType === 'estimates' && (
                          <div className="mt-2">
                            <div
                              className="payment-card-main"
                              onClick={() =>
                                setCurrentComponent('leaderBoard-detail')
                              }>
                              <div className="payment-content-card">
                                {/* <div className="task-profile-img">
                        <img
                          src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                          alt=""
                        />
                      </div> */}

                                <div className="task-content">
                                  <h1
                                    style={{
                                      color: '#000',
                                      fontFamily: 'Inter',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '700',
                                      lineHeight: 'normal',
                                    }}>
                                    Estimate Title
                                  </h1>
                                  <h1
                                    style={{
                                      color: '#000',
                                      fontFamily: 'Inter',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '700',
                                      lineHeight: 'normal',
                                    }}>
                                    Samantha Ellington
                                  </h1>

                                  <span
                                    style={{
                                      color: '#000',
                                      fontFamily: 'Inter',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: 'normal',
                                    }}>
                                    Invoice Title
                                  </span>
                                  <span
                                    className="project-title"
                                    style={{
                                      color: '#707070',
                                      fontFamily: 'Inter',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: 'normal',
                                    }}>
                                    Project
                                  </span>
                                </div>
                              </div>

                              <div className="payment-service-icons">
                                <div className="roi-service-icons">
                                  <div className="service-icon">
                                    <img src={WhitePenIcon} alt="" />
                                  </div>

                                  <div className="service-icon-time">
                                    <img className="" src={timeIcon} alt="" />
                                    <span>5D</span>
                                  </div>

                                  <div className="service-icon w-25">
                                    <img src={FireIcon} alt="" />
                                  </div>

                                  <div className="service-icon w-29">
                                    <img src={MachineIcon} alt="" />
                                  </div>

                                  <div className="service-icon w-29">
                                    <img src={ArrowIcon} alt="" />
                                  </div>

                                  <div className="service-icon w-29">
                                    <img src={Bandage} alt="" />
                                  </div>

                                  <div className="service-icon w-28">
                                    <img src={heartIcon} alt="" />
                                  </div>

                                  <div className="service-icon w-29">
                                    <img src={bagIcon} alt="" />
                                  </div>
                                  <div className="w-29">
                                    <i
                                      className="fa-solid fa-ellipsis"
                                      style={{
                                        color: '#0a0a0a',
                                        paddingTop: '15px',
                                      }}></i>
                                  </div>
                                </div>
                              </div>

                              <div className="payment-card-bottom">
                                <div
                                  className="payment-card-icons"
                                  style={{
                                    color: '#262626',

                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    paddingTop: '11px',
                                  }}>
                                  Due 01/15/24
                                  {/* <div className="payment-icon">
                          <i class="fa-solid fa-phone"></i>
                        </div>

                        <div className="payment-icon">
                          <i className="fa-solid icon-plane"></i>
                        </div> */}
                                </div>

                                <div className="pay-btn">
                                  <span
                                    style={{
                                      color: '#262626',
                                      fontFamily: 'Inter',
                                      fontSize: '15px',
                                      fontStyle: 'normal',
                                      fontWeight: '600',
                                      lineHeight: 'normal',
                                    }}>
                                    {' '}
                                    $575
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={<></>}
            />

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
