import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../Sidebar";

class OpportunityDetails extends Component {
    render() {
        return (
            <div className="page-wrapper">
                <Sidebar location={this.props.location} />
                <div className="page-content-wrapper">
                    <Container>
                        <Row>
                            <Col md={5} sm={6} xs={12} className="content-sidebar">
                                Test
                            </Col>
                            <Col md={7} sm={6} xs={12}>
                                Content
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default OpportunityDetails;
