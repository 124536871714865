const { host } = window.location;
export const appName = 'BUILD APP MVP';
const environment = 'Live'; // Local | Dev | Live
const environment_api = host.includes('localhost')
  ? 'Local'
  : host.includes('dev')
    ? 'Dev'
    : 'Live'; // Local | Dev | Live
export const ENV_HOST =
  host.includes('localhost') || host.includes('dev.roilevelup')
    ? 'dev.'
    : host.includes('staging.roilevelup')
      ? 'staging'
      : '';
     
var BACKEND_URL_Api_ROI = 'https://liveapi.roilevelup.com/';



if (host.includes('localhost') || host.includes('dev')) {


  BACKEND_URL_Api_ROI = 'https://devapi.roilevelup.com/'
} else if (host.includes('staging')) {


  BACKEND_URL_Api_ROI = 'https://stagingapi.roilevelup.com/';
  
} else {


  BACKEND_URL_Api_ROI = 'https://liveapi.roilevelup.com/'
}
const URLs = {
  Local: 'https://build-foundation.org/api',
  Dev: 'https://build-foundation.bleedingbulbtest.com/api',
  Live: 'https://build-foundation.org/api',
};

const site_url = {
  Local: `http://localhost:3000`,
  Dev: 'https://www.dev.roilevelup.com',
  // Live: "https://build-foundation.org/",
  Live: 'https://www.roilevelup.com',
};
const site_url_api = {
  Local: `http://localhost:3000`,
  Dev: 'https://www.dev.roilevelup.com',
  // Live: "https://build-foundation.org/",
  Live: 'https://www.roilevelup.com',
};
const site_url_api_cal = {
  Local: `http://localhost:3000`,
  Dev: 'https://www.dev.roilevelup.com',
  // Live: "https://build-foundation.org/",
  Live: 'https://www.roiforpros.com',
};
export const BACKEND_URL = BACKEND_URL_Api_ROI;
export const BACKEND_URL_Api_Of_ROI = BACKEND_URL_Api_ROI
export const SITE_URL = site_url[environment];
export const SITE_URL_FOR_API = site_url_api[environment_api];
export const SITE_URL_FOR_API_Calendar = site_url_api_cal[environment_api];
export const BASE_URL = URLs[environment];
export const API_URL = `${BASE_URL}`;
export const selected_lang = `en`;
export const S3_EMOJI_DEV =
  'https://build-dev.s3.us-east-1.amazonaws.com/emojis/';
export const DEFAULT_IMAGE =
  'https://build-dev.s3.us-east-1.amazonaws.com/build-default-profile.jpg';

export const CARDS_LIMIT = 5;
export const ALLOWED_COUNTRY = ['pk', 'in', 'us'];
export const DONATION_AMOUNT = 5;

export const SPOTIFY_CLIENT_ID = '8eed1e0204354497b6384b64d13a7024';
export const SPOTIFY_CLIENT_SECRET = 'da4e0da906774a38bec5ca685ab20779';
// export const SPOTIFY_CLIENT_ID = "a3be009827fc4948bacb2a4094531ea8";
// export const SPOTIFY_CLIENT_SECRET = "a2cf1ec0d8574d578c0a894b09429b7a";

export const GOOGLE_AUTH_TOKEN_ENDPOINT = 'https://oauth2.googleapis.com/token';
export const ENCRYPTION_KEY = 'jd_0320loveyou';

// Frenk Godman keys of Doilby
// export const App_key_Dolby = 'wWG5eswCePrmOsahtnIXsw==';
// export const App_Secret_key_Dolby =
//   'kL_8_M5fk-lLmfV6nOU3UA4KOygOXlgXwWCcVm3rAw8=';

export const App_key_Dolby = 'wWG5eswCePrmOsahtnIXsw==';
export const App_Secret_key_Dolby = 'kL_8_M5fk-lLmfV6nOU3UA4KOygOXlgXwWCcVm3rAw8=';

export const DEFAULT_LAT = 31.4699555;
export const DEFAULT_LNG = 74.309585;
