import Store from '../Store';
import {
  updatePageLoadedDispatch,
  updatePageWidthDispatch,
} from '../Store/Actions/CommanAction';

window.onload = () => {
  Store.dispatch(updatePageLoadedDispatch({status: true}));

 

  // fadeOutEffect();
};

window.onresize = event => {
  const windowWidth = window.innerWidth;
  Store.dispatch(updatePageWidthDispatch(windowWidth));
 
};


