import React from 'react'

const ChatIcon = () => {
  return (
    <svg width="45" height="35" viewBox="0 0 336 270" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M82.2688 196.882C67.1585 196.882 54.9351 184.521 54.9351 169.256V120H19.195C9.07753 120 0.855469 128.288 0.855469 138.541V227.509C0.855469 237.735 9.05998 246.041 19.195 246.041H28.5139V258.499C28.5139 268.415 38.5787 273.666 44.9141 267.061L65.0437 246.067H161.445C171.562 246.067 179.784 237.78 179.784 227.535V196.944H82.2864L82.26 196.891L82.2688 196.882Z" fill="white"/>
<path d="M307.913 0L96.6527 27.1425C81.6012 27.1425 69.4258 39.2666 69.4258 54.2497V152.937C69.4258 167.92 81.6012 180.044 96.6527 180.044H225.623L274.616 225.32C280.924 231.823 290.956 226.652 290.956 216.919V180.053H307.913C322.965 180.053 335.14 167.929 335.14 152.946V27.1072C335.14 12.1241 322.965 0 307.913 0ZM133.735 122.812C120.836 122.812 110.39 112.408 110.39 99.5695C110.39 86.7307 120.836 76.3272 133.735 76.3272C146.634 76.3272 157.08 86.7307 157.08 99.5695C157.08 112.408 146.634 122.812 133.735 122.812ZM202.279 122.812C189.38 122.812 178.934 112.408 178.934 99.5695C178.934 86.7307 189.38 76.3272 202.279 76.3272C215.177 76.3272 225.623 86.7307 225.623 99.5695C225.623 112.408 215.177 122.812 202.279 122.812ZM270.822 122.812C257.924 122.812 247.477 112.408 247.477 99.5695C247.477 86.7307 257.924 76.3272 270.822 76.3272C283.721 76.3272 294.167 86.7307 294.167 99.5695C294.167 112.408 283.721 122.812 270.822 122.812Z" fill="#7AC25A"/>
</svg>

  )
}

export default ChatIcon