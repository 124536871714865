import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {Pannels} from '../../Components';
import {rewardStar} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';

import CustomSlidingButtons from '../../Components/CustomSlidingButtons';
import Slider from 'react-slick';
import LeaderBoardDeatil from '../../Components/LeaderBoard/LeaderBoardDeatil';
import Scrollbars from 'react-custom-scrollbars';

const LeaderBoard = props => {
  const [currentComponent, setCurrentComponent] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('chats');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [workSpaceItem, setWorkSpaceItem] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );

  const listForDropDown = [
    // {title: 'All', id: '1'},
    {title: 'Overall', id: '1'},
    {title: 'Sales', id: '2'},
    {title: 'Customer Service', id: '3'},
    {title: 'Anything', id: '4'},
    {title: 'Anything', id: '5'},
    {title: 'Anything', id: '6'},
    {title: 'Anything', id: '7'},
  ];

  const dynamicStyle = {
    padding: 0,
    // Add other styles as needed
  };

  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);

  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);
    // setdropdownValue([innerText]);
  };

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,

    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Level Up',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Training',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="leaderboard-page primary_panel_wraper primary_panel_content">
                    <div className="messages-header">
                      {/* <div className="event-tabs dark-tabs"> */}
                      <div className="event-tabs tabs-style-2 mb-0">
                        {' '}
                        {/* Merging */}
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'chats' ? 'active' : ''}
                              onClick={() => {
                                setTabType('chats');
                              }}>
                              LeaderBoard
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    {threeDots && (
                      <div className="person-search-wrapper mb-0 messages-users-wrapper">
                        <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Who are you looking for?"
                              value={''}
                              onChange={e => {
                                // setChatSearchQry(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Button>
                            <i
                              className="icon-filter-icon"
                              onClick={() => {}}
                            />
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                      <CustomSlidingButtons
                        items={listForDropDown}
                        activeButtons={areas_Filter}
                        onItemClick={e => {
                          dropDownSelection(e);
                        }}
                        showItems={2}
                      />
                    </div>
                    <div className="refferal-list-wrapper">
                      <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide>
                        <div className="leaderBoard-content">
                          <div className="refferal-list-wrapper">
                            <div
                              className="payment-card mb-3 qa-list-item"
                              onClick={() => {
                                setCurrentComponent('leaderBoard-detail');
                              }}>
                              <div className="money-detail">
                                <span> $8,700</span>

                                <div>
                                  <i
                                    className="fa-solid fa-star"
                                    style={{color: '#FFEC42'}}
                                  />{' '}
                                  <span>#1</span>
                                </div>
                              </div>

                              <div class="message-user-item no-link">
                                <div class="user-img as-link">
                                  <img
                                    src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                    alt=""
                                  />
                                </div>
                                <div class="user-content as-link">
                                  <h2 class="single-line-text">
                                    Samantha Ellington
                                  </h2>
                                  <p class="single-line-text">Plumbing</p>
                                  <div className="average-sale">
                                    <div>Average Sale</div>

                                    <span>$2,500</span>
                                  </div>
                                  <div className="leaderboard-subCard">
                                    <span>
                                      {' '}
                                      <b>Close</b> 61%
                                    </span>
                                    <span>
                                      <b>Leads</b> 80
                                    </span>

                                    <span>
                                      {' '}
                                      <b>Closed</b> 50
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="bottom-img-card">
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                  className="payment-cart-subCard">
                                  <span>Consider partnering with</span>
                                  <div
                                    style={{
                                      marginLeft: '25px',
                                      display: 'flex',
                                    }}>
                                    <div className="img-consulting">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="img-consulting">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="users-number">
                                      <span>+ 3</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="payment-card leaderboard-card-black qa-list-item mb-3">
                              <div className="money-detail">
                                <span> $8,700</span>

                                <div>
                                  <i
                                    className="fa-solid fa-star"
                                    style={{color: '#FFEC42'}}
                                  />{' '}
                                  <span>#1</span>
                                </div>
                              </div>

                              <div class="message-user-item no-link">
                                <div class="user-img as-link">
                                  <img
                                    src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                    alt=""
                                  />
                                </div>
                                <div class="user-content as-link">
                                  <h2 class="single-line-text">
                                    Samantha Ellington
                                  </h2>
                                  <p class="single-line-text">Plumbing</p>
                                  <div className="average-sale">
                                    <div>Average Sale</div>

                                    <span>$2,500</span>
                                  </div>
                                  <div className="leaderboard-subCard">
                                    <span>
                                      {' '}
                                      <b>Close</b> 61%
                                    </span>
                                    <span>
                                      <b>Leads</b> 80
                                    </span>

                                    <span>
                                      {' '}
                                      <b>Closed</b> 50
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="bottom-img-card">
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                  className="payment-cart-subCard">
                                  <span>Consider partnering with</span>
                                  <div
                                    style={{
                                      marginLeft: '25px',
                                      display: 'flex',
                                    }}>
                                    <div className="img-consulting">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="img-consulting">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="users-number">
                                      <span>+ 3</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={
                <>
                  {/* {currentComponent === 'leaderBoard-detail' && (
                    <LeaderBoardDeatil
                      onClose={() => {
                        setCurrentComponent('');
                      }}
                    />
                  )} */}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderBoard;
