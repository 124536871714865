import {labels} from '../../Constants/Translations';
import {formatAmount} from '../../Utils/commonUtils';
import {selected_lang} from '../../Utils/env';
import {RenderHTMLBlock} from '../../Utils/Helpers';
import React, {useState,} from 'react';

const RecruitmentBusinessProfile = ({item,PageDesign}) => {
  // const [showButton, setShowButton] = useState(false);
  // const [newUserId, setNewUserId] = useState('');


  const [showMore, toggleShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';
  const [marketToggle, setMarketToggle] = useState(item.show_in_market_b);

  return (
    <>
      <div className="opportunity-item card">
        <h2>{item.title}</h2>
        <div className="oppo-share-wrapper as-link ">
          <span className="category as-link ">{item.business_name}</span>
        </div>

        <div className="oppo-desc as-link mb-1">
          <RenderHTMLBlock
            className={`oppo-desc-content ${showMoreClass}`}
            content={item.description}
          />
        </div>

        {item.description.length > 100 && (
          <div className="oppo-desc as-link ">
            <span
              className="read-more"
              onClick={() => toggleShowMore(!showMore)}>
              {!showMore
                ? labels.read_more[selected_lang]
                : labels.read_less[selected_lang]}
            </span>
          </div>
        )}

        <div className="oppo-address as-link ">
          {item.address && (
            <span className="address">
              <i className="icon-map-pin" /> {item.address}
            </span>
          )}
          <span className="injeaction-text">
            {item.vaccinate === 'yes' && <i className="icon-injection" />}{' '}
            {item.opportunity_type_name}
            <b>
              {item.amount &&
                formatAmount(item.amount ? item.amount : '') + '/hr'}
            </b>
          </span>
        </div>
        {PageDesign('opportunity', item,marketToggle,setMarketToggle)}
      </div>
      {/* {showButton === true && <OpportunityDetail  item={item} userData={item.creator_details
} onClose={()=>{setShowButton(false);} }/>} */}
    </>
  );
};

export default RecruitmentBusinessProfile;
