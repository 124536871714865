import React, {useState, useEffect} from 'react';
import {RenderHTMLBlock} from '../../Utils/Helpers';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {successMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import {formatAmount} from '../../Utils/commonUtils';

const OpportunityList = props => {
  const heartClass = props.item.like ? 'fas' : 'far';

  const [favourite, setFavourite] = useState(props.item.like);
  // const heartClass = item.like ? "fas" : "far";

  const [showMore, toggleShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';
  useEffect(() => {
    props.item.like = favourite;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourite]);

  const favouriteOpportunity = () => {
    let payload = {
      opportunity_id: props.item.id,
    };
    commonApi
      .manage_favourite_opportunity(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            props.item.like = true;
            setFavourite(true);
          } else {
            props.item.like = false;
            setFavourite(false);
          }
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  

  return (
    <div className="opportunity-item card as-link change">
      <h2
        onClick={() => {
          props.handleCommand(props.item, 'opportunity');
        }}>
        {props.item.title}
      </h2>
      <div className="oppo-share-wrapper">
        <span
          className="category"
          onClick={() => {
            props.handleCommand(props.item, 'opportunity');
          }}>
          {props.item.opportunity_type_name}
        </span>
        <span
          onClick={() => {
            props.handleCommand(props.item, 'referOpportunity');
          }}
          className="oppo-share-icon">
          <i className="fas fa-share" />
        </span>
      </div>
      <div className="oppo-desc as-link mb-1" onClick={() => { props.handleCommand(props.item, 'opportunity'); }}>
        <RenderHTMLBlock
          className={`oppo-desc-content ${showMoreClass}`}
          content={props.item.description}
        />

        {/* {props.item.description && (
          <ShowMore limit={150}>{props.item.description}</ShowMore>
        )} */}
      </div>
      {props.item.description.length > 110 && (
        <div className="oppo-desc as-link">
          <span className="read-more" onClick={() => toggleShowMore(!showMore)}>
            {!showMore
              ? labels.read_more[selected_lang]
              : labels.read_less[selected_lang]}
          </span>
        </div>
      )}

      <div className="oppo-address" onClick={() => { props.handleCommand(props.item, 'opportunity'); }}>
        {props.item.address && (
          <span
            className="address m-0"
            onClick={() => {
              props.handleCommand(props.item, 'opportunity');
            }}>
            <i className="icon-map-pin" /> {props.item.address}
          </span>
        )}
        <span
          className="injeaction-text"
          onClick={() => {
            props.handleCommand(props.item, 'opportunity');
          }}>
          {props.item.vaccinate === 'yes' && <i className="icon-injection" />}{' '}
          {props.item.opportunity_type_name}
          {/* <b>{formatAmount(props.item.amount ? props.item.amount : '')}</b> */}
          <b>{props.item.amount  && formatAmount(props.item.amount ? props.item.amount : '')+'/hr' }</b>
        </span>
      </div>
      <span
        className="hearticon maxWidth-maxContent m-0"
        onClick={() => {
          favouriteOpportunity();
        }}>
        <i className={`${heartClass} fa-heart as-link `} />
      </span>
    </div>
  );
};
export default OpportunityList;
