import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import LoaderModal from './LoaderModal';
import CustomModal from '../CustomModal';


const MarketFilterModal = props => {
  // const [show, setShow] = useState(props.show);
  const closeModal = () => {
    // setShow(false);
    props.onClose();
  };
  const [showLoader, setShowLoader] = useState(false);






 
  const handleSelectAll = e => {
    props.setIsCheckAll(!props.isCheckAll);
    props.setIsCheck(props.list.map(item => item.id));
    if (props.isCheckAll) {
      props.setIsCheck([]);
    }
  };






  const handleClick = e => {
    const {id, checked} = e.target;
    props.setIsCheck([...props.isCheck, id]);
    if (!checked) {
      props.setIsCheck(props.isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    if (props.list.length > 0) {
      if (props.isCheck.length === props.list.length) {
        props.setIsCheckAll(true);
      } else {
        props.setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCheck]);

  const handlevalue = () => {
    props.setSessionFilter(props.isCheck)
    props.onClose();
  };

  return (
    <>
      <CustomModal  size="small" onClose={() => closeModal()}>
        {showLoader && <LoaderModal show={showLoader} />}

        <div className="serach-filter-wrapper">
          <div className="align-items-center justify-content-between mb-3">
            <div className="headings m-0">
              <h2 className="m-0 text-center">Categories</h2>
            </div>

            <div className="sidebar-heading-wrapper mt-0 text-right">
              {/* <div className="ticket-icons-options">
              <ul>
                <li
                  className={props.active ? 'active' : 'target-icon'}
                  onClick={() => {
                    setSearchFilter(true);
                  }}>
                  <i className="icon-bullseye" />
                </li>
              </ul>
            </div> */}
            </div>
          </div>

          {/* <Scrollbars> */}
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                <li className={props.isCheckAll ? 'active' : ''}>
                  <Form.Label>
                    All{' '}
                    <input
                      type="checkbox"
                      id="ALL"
                      name="ALL"
                      value="ALL"
                      onChange={handleSelectAll}
                      checked={props.isCheckAll}
                    />{' '}
                    <span />
                  </Form.Label>
                </li>
                {Object.entries(props.categoryData).length > 0 &&
                  props.categoryData.map((item, index) => (
                    <li
                      key={`sessionC-${index}`}
                      className={
                        props.isCheck.includes(item.id) ? 'active' : ''
                      }>
                      <Form.Label>
                        {item.category_name}
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          onChange={handleClick}
                          checked={props.isCheck.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {/* </Scrollbars> */}
          <div className="filter-btn-wrapper pt-3 text-center">
            <Button
              className="btn-dark"
              type="submit"
              onClick={() => {
                
                  
                
                props.setCategoriesIds(props.list.length === props.isCheck.length ? '' :  props.isCheck )
                props.onClose();
              }}>
              Filter Categories
            </Button>
          </div>
        </div>
      </CustomModal>
      
    </>
  );
};

export default MarketFilterModal;
