import React, {useState, useRef, useEffect} from 'react';
import {Button, Col, Dropdown, Form, Row} from 'react-bootstrap';
import {
    catalogsTotalCost,
    formatPaymentDescription,
  } from '../../Utils/commonUtils';

const MembershipDealItem = ({
    item,
    ItemIndex,
    membershipList,
    membershipList2,
    setMembershipList,
    setMembershipList2,
  }) => {
    const [levels, setLevels] = useState([]);

    const [durations, setDuartions] = useState([]);
    const [levelType, setLevelType] = useState('');
    const [durationType, setDurationType] = useState('');
    const [durationItem, setDurationItem] = useState({});
    const [levelItem, setLevelItem] = useState({});
    const [itemCost, setItemCost] = useState(item.cost ? item.cost : '');

    const handleAmount = item => {
      setDurationItem(item ? item : {});
      if (item.payment_amount && item.payment_amount !== '') {
        setItemCost(item.payment_amount);
      } else {
        setItemCost('0.00');
      }
    };

    useEffect(() => {
      if (item.catalog_type === 'membership') {
        console.log(
          item.membership_levels,
          'item.membership_levelsitem.membership_levels',
        );
        const filterLevel = item.membership_levels.filter(
          item => item.selected === true,
        );
        if (filterLevel.length > 0) {
          console.log(filterLevel, '=====>filterLevelfilterLevel');
          const selectedLevel = filterLevel.length > 0 ? filterLevel[0] : {};
          setLevelItem(selectedLevel);
          setLevels(
            item.membership_levels && item.membership_levels.length > 0
              ? item.membership_levels
              : [],
          );
          setLevelType(selectedLevel.id);
        } else {
          setLevelItem(
            item.membership_levels && item.membership_levels.length > 0
              ? item.membership_levels[0]
              : [],
          );
          setLevels(
            item.membership_levels && item.membership_levels.length > 0
              ? item.membership_levels
              : [],
          );
          setLevelType(
            item.membership_levels && item.membership_levels.length > 0
              ? item.membership_levels[0].id
              : '',
          );
        }
      }
    }, []);

    useEffect(() => {
      if (item.catalog_type === 'membership' && levelType !== '') {
        const selectedLevel = levels.filter(item => item.id === levelType);
        console.log(selectedLevel, 'selectedLevelselectedLevel');
        setDuartions(
          item.membership_levels.length > 0 &&
            selectedLevel[0].frequencies.length > 0
            ? selectedLevel[0].frequencies
            : [],
        );

        if (selectedLevel.length > 0 && selectedLevel[0].selected) {
          // setDurationType(item?.level?.frequency?.id)
          const filterFrequency =
            selectedLevel[0].frequencies.length > 0 &&
            selectedLevel[0].frequencies.filter(item => item.selected === true);
          setDurationType(filterFrequency.length > 0 && filterFrequency[0].id);
          handleAmount(filterFrequency[0]);
        } else {
          setDurationType(
            item.membership_levels.length > 0 &&
              selectedLevel[0].frequencies.length > 0
              ? selectedLevel[0].frequencies[0].id
              : '',
          );
          handleAmount(selectedLevel[0].frequencies[0]);
        }

        let durationId =
          item.membership_levels.length > 0 &&
          selectedLevel[0].frequencies.length > 0
            ? selectedLevel[0].frequencies[0].id
            : '';

            let duration =
            item.membership_levels.length > 0 &&
            selectedLevel[0].frequencies.length > 0
              ? selectedLevel[0].frequencies[0].duration
              : '';

           let cost = item.membership_levels.length > 0 &&
           selectedLevel[0].frequencies.length > 0
             ? selectedLevel[0].frequencies[0].payment_amount
             : '';

             console.log(cost , '====> cost')

        handleSelectionChange(levelType, durationId,cost,duration);
      }
    }, [levelType]);

    const handleSelectionChange = (levelId, durationId,cost,duration) => {
      const updatedGroup = membershipList.map((item, index) => {
        if (index === ItemIndex) {
            
          return {
            ...item,
            level:{
                level_id: levelId,
                frequency_id: durationId,
                duration:duration
            },

            cost : cost
           
          };
        }
        return item;
      });
      setMembershipList(updatedGroup);
    };

    
 
   

    return (
      <div>
        <h1 className="text-left mb-2">{item.title}</h1>

        <div className="mb-3">
          <Form.Label controlid="validationCustom01">Select Level </Form.Label>
          <Dropdown
            className="select-wrapper"
            onSelect={(eventKey, event) => {
              var item = JSON.parse(eventKey);
              setLevelItem(item);
              setLevelType(item.id);
            }}
            drop="down">
            <Dropdown.Toggle variant="primary">
              {levelType
                ? levels.find(item => item.id === levelType).level_name
                : 'Select Role'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {levels.map((item, index) => (
                <Dropdown.Item
                  key={`st-${index}`}
                  eventKey={JSON.stringify(item)}
                  active={item.id === levelType}>
                  {item.level_name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="mb-3">
          <Form.Label controlid="validationCustom01">
            Select Duration{' '}
          </Form.Label>
          <Dropdown
            className="select-wrapper"
            onSelect={(eventKey, event) => {
              var item = JSON.parse(eventKey);
              handleAmount(item);
              setDurationType(item.id);
              handleSelectionChange(levelType, item.id,item.payment_amount,item?.duration);
            }}
            drop="down">
            <Dropdown.Toggle variant="primary">
              {formatPaymentDescription(
                durationType
                  ? durations.find(item => item.id === durationType)
                  : 'Select Role',
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {durations.map((item, index) => (
                <Dropdown.Item
                  key={`st-${index}`}
                  eventKey={JSON.stringify(item)}
                  // eventKey={item.id.toString()}
                  active={item.id === durationType}>
                  {formatPaymentDescription(item)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
}

export default MembershipDealItem