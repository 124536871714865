import React from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {useState, useEffect, useRef} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {
  calculateItemSubtotal,
  calculateSubtotal,
  checkPayableAmount,
  formatMinutesTime,
  formatSelectedDuration,
} from '../../Utils/commonUtils';
import moment from 'moment';
import {
  checkSubscriptionType,
  formatPaymentDescription,
  getLabelFromSubscriptionMonth,
} from '../../Utils/commonUtils';
import {FormattedText} from '../../Utils/Helpers';

import {loadStripe} from '@stripe/stripe-js';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import CheckoutForm from '../SessionDetails/CheckoutForm';
import CustomModal from '../CustomModal';
import {getItem} from '../../Utils/LocalStorage';
import { RoiLogo } from '../../Constants/Images';
const InvoiceDeatil = ({
  invoiceItem,
  selectedFilterValue,
  type,
  getUserInvoices,
  setShowLoader,
  onClose,
  handleStates,
  userId =''
}) => {
  const [tabType, setTabType] = useState('invoices');

  const stripePromise = loadStripe(
    'pk_test_51PEB5a2K8W7Cs25henyw4Ahf3eGMQgtDgRztlGV4POXFghfMEH5DDg5PtNpT916JChWA9ELeby2Znh5mu2IjXyuY00TWUVZN1V',
  );

  function formatNumberWithTwoDecimals(number) {
    const roundedNumber = Number(number).toFixed(2);

    return roundedNumber.toString();
  }
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [showMoreFirstnote, setShowMoreFirstnote] = useState(false);
  const showMoreClassFirstnote = showMoreFirstnote ? 'show-all' : '';
  const [payablePayment, setPayablePayment] = useState(false);

  console.log(invoiceItem, 'item');

  const firstNote = invoiceItem.customer_note
    ? invoiceItem.customer_note.split('\n')
    : '';

  const host = window.location.origin;

  const paymentMilstone = [
    {
      id: '13',
      catalog_id: '690',
      date: '2024-09-24 00:00:00',
      amount: '34',
      created_at: '2024-09-03 01:49:26',
    },
    {
      id: '14',
      catalog_id: '690',
      date: '2024-09-23 00:00:00',
      amount: '33',
      created_at: '2024-09-03 01:49:26',
    },
    {
      id: '15',
      catalog_id: '690',
      date: '2024-09-24 00:00:00',
      amount: '344',
      created_at: '2024-09-03 01:49:26',
    },
  ];

  const ItemDetail = ({item, index}) => {
    const [moreOptions, setMoreOptions] = useState(false);

    const [levels, setLevels] = useState([]);

    const [durations, setDuartions] = useState([]);
    const [levelType, setLevelType] = useState('');
    const [durationType, setDurationType] = useState('');
    const [durationItem, setDurationItem] = useState({});
    const [levelItem, setLevelItem] = useState({});

    const handleAmount = item => {
      setDurationItem(item ? item : {});
    };

    useEffect(() => {
      if (item.catalog_type === 'membership') {
        if (item.level) {
          setLevelItem(item.level);
          setLevels([item.level]);
          setLevelType(item.level.id);
        }
      }

      const checkPayable = checkPayableAmount(invoiceItem.catalog_details);
      setPayablePayment(checkPayable);
    }, []);

    useEffect(() => {
      if (item.catalog_type === 'membership' && levelType !== '') {
        setDuartions([item.level.frequency]);

        setDurationType(item.level.frequency.id);
        handleAmount(item.level.frequency);
      }
    }, [levelType]);

    let dropdownWidth = {
      width: '181.55px',
    };

    

    console.log(payablePayment, 'payablePaymentpayablePayment');
    return (
      <div key={`catalog_details${index}`}>
        <h1 className="mb-2 text-break">{item.title}</h1>
        <div className="template-flex ">
          <span className="template-text f_700">
            {item.duration ? formatMinutesTime(item.duration) : ''}
          </span>

          {item.catalog_type === 'membership' && (
            <div
              className={`d-flex gap-2 ${
                item.catalog_type === 'membership' ? 'flex-1' : ''
              }`}>
              {item.catalog_type === 'membership' && levels.length > 0 && (
                <div className="d-flex gap-2">
                  <div className="f_700">Level:</div>
                  <div
                    className="multi-line-ellipsis"
                    style={{maxWidth: '380px'}}>
                    {levelType
                      ? levels.find(item => item.id === levelType).level_name
                      : ''}{' '}
                    (
                    {formatSelectedDuration(
                      durationType
                        ? durations.find(item => item.id === durationType)
                        : '',
                    )}
                    )
                  </div>
                </div>
                // <div className="mb-3" style={dropdownWidth}>
                //   <Form.Label controlid="validationCustom01">
                //     Level{' '}
                //   </Form.Label>
                //   <Dropdown
                //     className="select-wrapper"
                //     onSelect={(eventKey, event) => {
                //       var item = JSON.parse(eventKey);
                //       setLevelItem(item);
                //       setLevelType(item.id);
                //     }}
                //     drop="down">
                //     <Dropdown.Toggle variant="primary" disabled={true}>
                //       {levelType
                //         ? levels.find(item => item.id === levelType).level_name
                //         : 'Select Role'}
                //     </Dropdown.Toggle>

                //     <Dropdown.Menu>
                //       {levels.map((item, index) => (
                //         <Dropdown.Item
                //           key={`st-${index}`}
                //           eventKey={JSON.stringify(item)}
                //           active={item.id === levelType}>
                //           {item.level_name}
                //         </Dropdown.Item>
                //       ))}
                //     </Dropdown.Menu>
                //   </Dropdown>
                // </div>
              )}

              {/* {item.catalog_type === 'membership' &&
                levels.length > 0 &&
                durations.length > 0 && (

                  <div className='d-flex gap-2'>
                    <div className='f_700'>
                      Duration:
                    </div>
                    <div>
                      {formatPaymentDescription(
                        durationType
                          ? durations.find(item => item.id === durationType)
                          : '',
                      )}
                    </div>
                  </div>
                  // <div className="mb-3" style={dropdownWidth}>
                  //   <Form.Label controlid="validationCustom01">
                  //     Duration{' '}
                  //   </Form.Label>
                  //   <Dropdown
                  //     className="select-wrapper "
                  //     onSelect={(eventKey, event) => {
                  //       var item = JSON.parse(eventKey);
                  //       handleAmount(item);
                  //       setDurationType(item.id);
                  //     }}
                  //     drop="down">
                  //     <Dropdown.Toggle variant="primary" disabled={true}>
                  //       {formatPaymentDescription(
                  //         durationType
                  //           ? durations.find(item => item.id === durationType)
                  //           : 'Select Role',
                  //       )}
                  //     </Dropdown.Toggle>

                  //     <Dropdown.Menu>
                  //       {durations.map((item, index) => (
                  //         <Dropdown.Item
                  //           key={`st-${index}`}
                  //           eventKey={JSON.stringify(item)}
                  //           // eventKey={item.id.toString()}
                  //           active={item.id === durationType}>
                  //           {formatPaymentDescription(item)}
                  //         </Dropdown.Item>
                  //       ))}
                  //     </Dropdown.Menu>
                  //   </Dropdown>
                  // </div>
                )} */}
            </div>
          )}

          <span>${formatNumberWithTwoDecimals(item.cost)}</span>
        </div>

        {!moreOptions && (
          <div
            className="more-option detail_btn as-link fit_content mb-3"
            onClick={() => {
              setMoreOptions(!moreOptions);
            }}>
            View More
          </div>
        )}
        {moreOptions && (
          <>
            {item.created_by === getItem('id') &&
              item.expense_ids &&
              item.expense_ids.length > 0 &&
              item.expense_deatils &&
              item.expense_deatils.length > 0 && (
                <div className="payment_milestone_flex">
                  <div className="f_700 mb-2">Expenses</div>

                  {item.expense_deatils.map((expense, index) => (
                    <div key={`estimateInvoices-${index}`} className="mb-2">
                      <div className="d-flex gap-2">
                        <span className=" f_700">Title:</span>
                        <span className="text-break"> {expense.title}</span>
                      </div>

                      {expense.description ? (
                        <div className="d-flex gap-2">
                          <span className="text-break f_700">Description:</span>
                          <span className="text-break">
                            {expense.description}
                          </span>
                        </div>
                      ) : null}

                      <div className="d-flex gap-2">
                        <span className="f_700">Cost :</span>
                        <span className="text-break"> ${expense.cost}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

            {item.selected_payment_type === 'subscription' &&
              item.subscription_month &&
              item.subscription_month !== '' && (
                <div className="template-flex">
                  <span className="f_400">Subscription</span>

                  <span className="template-text f_600">
                    {getLabelFromSubscriptionMonth(item.subscription_month)}
                  </span>
                </div>
              )}
            {item.selected_payment_type === 'milestone' &&
              item.payment_milestone &&
              item.payment_milestone.length > 0 && (
                <div className="payment_milestone_flex">
                  <div className="f_400 mb-2">Payment Milestones</div>
                  <div className="payment_milestone_list">
                    <ul>
                      {item.payment_milestone.length > 0 &&
                        item.payment_milestone.map((milestone, index) => (
                          <li key={`estimateInvoices-${index}`}>
                            <span className="text-break">
                              {moment(milestone.date).format('YYYY-MM-DD')} - ($
                              {milestone.amount})
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            <div className="template-flex">
              <span className="f_400">Taxes and Fees</span>

              <span className="template-text f_600">
                ${formatNumberWithTwoDecimals(item.taxes_fee)}
              </span>
            </div>

            <div className="template-flex">
              <span className="f_400">Discount</span>

              <span className="template-text f_600">
                ${formatNumberWithTwoDecimals(item.discount)}
              </span>
            </div>

            {item.catalog_type === 'product' && (
              <div className="template-flex">
                <span className="f_400">Shipping & handling</span>

                <span className="template-text f_600">
                  ${formatNumberWithTwoDecimals(item.shipping_handling)}
                </span>
              </div>
            )}

            {(item.catalog_type === 'service' ||
              item.catalog_type === 'membership') &&
              (item.late_fee_status === 'true' || !item.late_fee_status) && (
                <div className="template-flex">
                  <span className="f_400">Late Fee</span>

                  <span className="template-text f_600">
                    ${formatNumberWithTwoDecimals(item.late_fee)}
                  </span>
                </div>
              )}

            <div className="template-flex">
              <span className="f_400">Subtotal</span>

              <span className="f_600">${calculateItemSubtotal(item)}</span>
            </div>
          </>
        )}

        {moreOptions && (
          <div
            className="more-option detail_btn as-link fit_content mb-3"
            onClick={() => {
              setMoreOptions(!moreOptions);
            }}>
            View Less
          </div>
        )}
      </div>
    );
  };

  const handleRefresh = () =>{
    handleStates && handleStates()
    setTimeout(() => {
      getUserInvoices('invoice',1);
    }, 300);
   
  
  }

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };

  // console.log(userId, 'userId -------->');
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          onClose();
        }}
        renderData={renderHeader}
    showBlackHeader={true}
    headerPadding={'24px 20px'}
    lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
    firstDivStyle={{
      padding: 0,
      background:
        'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
    }}>
        <div className="serach-filter-wrapper create-sessionEvents invoice_detail_page">
          <Scrollbars>
            <div className="create-ticket-form fixed-width-inputs black-head-form-pad-left fix-width-forms ">
              <div className="create-template invoice_detail">
                <div className="messages-header mb-2 pt-3">
                  {/* <div className="event-tabs dark-tabs"> */}
                  <div className="event-tabs tabs-style-2 mb-2">
                    {' '}
                    {/* Merging */}
                    <ul>
                      <li>
                        <Button
                          className={tabType === 'invoices' ? 'active' : ''}
                          style={{color: '#272727'}}
                          onClick={() => {
                            // setGalleryType('chats');
                            setTabType('invoices');
                            // setShowSpinner(true);
                          }}>
                          {labels.technician_payment_invoices[selected_lang]}
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="refferal-list-wrapper">
                  <div className="create-template-content ">
                    <div className="invoice">
                      <div className="template-flex-total invoice_due">
                        <span>
                          Due{' '}
                          {moment(invoiceItem?.due_date)
                            .format('MM/DD/YY')
                            .toString()}
                        </span>
                        <span className="f_600">
                          ${formatNumberWithTwoDecimals(invoiceItem.total)}
                        </span>
                      </div>

                      {invoiceItem.catalog_details.length > 0 &&
                        invoiceItem.catalog_details.map((item, index) => (
                          //                   <div key={`catalog_details${index}`}>
                          //                     <h1 className="mb-2">{item.title}</h1>
                          //                     <div className="template-flex ">
                          //                       <span className="template-text f_700">
                          //                         {item.duration
                          //                           ? formatMinutesTime(item.duration)
                          //                           : ''}
                          //                       </span>

                          //                       <span>
                          //                         ${formatNumberWithTwoDecimals(item.cost)}
                          //                       </span>
                          //                     </div>

                          //                     <>
                          //                 {/* <div className="template-flex">
                          //                   <span className="f_400">Subtotal</span>

                          //                   <span className="f_600">
                          //                     $
                          //                     {formatNumberWithTwoDecimals(invoiceItem.sub_total)}
                          //                   </span>
                          //                 </div> */}

                          //                 <div className="template-flex">
                          //                   <span className="f_400">Taxes and Fees</span>

                          //                   <span className="template-text f_600">
                          //                     $
                          //                     {formatNumberWithTwoDecimals(item.taxes_fee)}
                          //                   </span>
                          //                 </div>

                          //                 <div className="template-flex">
                          //                   <span className="f_400">Discount</span>

                          //                   <span className="template-text f_600">
                          //                     ${formatNumberWithTwoDecimals(item.discount)}
                          //                   </span>
                          //                 </div>

                          //                {item.catalog_type ==='product' && (
                          //                 <div className="template-flex">
                          //                   <span className="f_400">Shipping & handling</span>

                          //                   <span className="template-text f_600">
                          //                     $
                          //                     {formatNumberWithTwoDecimals(
                          //                       item.shipping_handling,
                          //                     )}
                          //                   </span>
                          //                 </div>
                          //                )}

                          //  {(item.catalog_type === 'service'  ||  item.catalog_type === 'membership') && (
                          //   <div className="template-flex">
                          //                   <span className="f_400">Late Fee</span>

                          //                   <span className="template-text f_600">
                          //                     $
                          //                     {formatNumberWithTwoDecimals(
                          //                       item.late_fee,
                          //                     )}
                          //                   </span>
                          //                 </div>
                          //  )}

                          //               </>
                          //                   </div>

                          <ItemDetail item={item} index={index} />
                        ))}

                      {invoiceItem.customer_note !== '' && (
                        <>
                          <h1>Note :</h1>
                          <div className="message-other-users">
                            <div className="watchers-imgs">
                              <div className="flex align-items-center notes-view mt-2">
                                <div>
                                  <FormattedText
                                    className={`profile-description ${showMoreClassFirstnote}`}
                                    content={invoiceItem.customer_note}
                                  />

                                  {(invoiceItem.customer_note.length > 250 ||
                                    firstNote.length > 3) && (
                                    <>
                                      {' '}
                                      <span
                                        className="show-more-link"
                                        onClick={() =>
                                          setShowMoreFirstnote(
                                            !showMoreFirstnote,
                                          )
                                        }>
                                        {!showMoreFirstnote
                                          ? labels.read_more[selected_lang]
                                          : labels.read_less[selected_lang]}
                                        {/* {profile.data.bio.length} */}
                                      </span>
                                    </>
                                  )}
                                </div>

                                {/* <p className="p-reg m-0">{notes[0].description}</p> */}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="template-flex-total mb-0">
                        <span className="template-text">Total</span>

                        <span className="f_700">
                          ${formatNumberWithTwoDecimals(invoiceItem.total)}
                        </span>
                      </div>

                      {invoiceItem.payable_amount && (
                        <div className="template-flex-total">
                          <span
                            className="template-text"
                            style={{fontWeight: 500}}>
                            Payable Amount
                          </span>

                          <span className="f_700">
                            $
                            {formatNumberWithTwoDecimals(
                              invoiceItem.payable_amount,
                            )}
                          </span>
                        </div>
                      )}

                      <div className="template-flex payment-schedule">
                        <span>Payment Schedule</span>

                        <span className="f_600">
                          {invoiceItem.catalog_details.length > 0 &&
                          checkSubscriptionType(invoiceItem.catalog_details) ===
                            'monthly'
                            ? 'Recurring Monthly'
                            : checkSubscriptionType(
                                invoiceItem.catalog_details,
                              )}
                        </span>
                      </div>

                      {selectedFilterValue !== 'from_me' && type !== 'paid' && (
                        <div className="template-btns invoice_pay_btn">
                          <div>
                            <Button
                              className="new-btn btn-green "
                              onClick={() => {
                                setShowStripeModal(true);
                              }}>
                              Pay
                            </Button>
                          </div>
                        </div>
                      )}

{(userId && userId === localStorage.getItem('id')) &&  (
                        <div className="template-btns invoice_pay_btn">
                          <div>
                            <Button
                              className="new-btn btn-green "
                              onClick={() => {
                                setShowStripeModal(true);
                              }}>
                              Pay
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showStripeModal && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowStripeModal(false);
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'payment',
                  amount: 10 * 100,
                  currency: 'usd',
                  paymentMethodTypes: ['card'],
                  payment_method_types: ['card'],
                }}>
                <CheckoutForm
                  price={
                    payablePayment && invoiceItem.payable_amount
                      ? invoiceItem.payable_amount
                      : invoiceItem.total
                  }
                  paymentId={invoiceItem.payment_id}
                  transactionType={'invoice'}
                  onClose={() => {
                    setShowStripeModal(false);
                    onClose();
                    setShowLoader(true);
                    handleRefresh()
                  }}
                />
              </Elements>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default InvoiceDeatil;
