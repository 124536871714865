import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import Scrollbars from 'react-custom-scrollbars';
import {DEFAULT_IMAGE} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CreatCircleList from '../Circle/CreatCircleList';

const CircleList = props => {
  const [circles, setcircles] = useState([]);
  const [circleUser, setcircleUser] = useState([]);
  const [currentCircle, setCurrentCircle] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [showComponent, setShowComponent] = useState('');

  const getCircles = () => {
    setshowLoader(true);
    commonApi
      .get_circles()
      .then(response => {
        if (response) {
          setshowLoader(false);
          if (response.data.length > 0) {
            setcircles(response.data);
            if (currentCircle) {
              getCirclesUsers(currentCircle);
            } else {
              getCirclesUsers(response.data[0].circle_id);
              
            }
          } else {
            errorMessage('Please select the circle first');
            props.onClose();
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getCirclesUsers = id => {
    setshowLoader(true);
    setCurrentCircle(id);
    var data = {circle_id: id};
    commonApi
      .get_circle_users(data)
      .then(response => {
        if (response) {
          setshowLoader(false);
          setcircleUser(response.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const addUserInCircle = () => {
    var data = {
      circle_id: currentCircle,
      circle_name: '',
      user_ids: [props.userData.id],
      workspace_ids:localStorage.getItem('workspaceIds'),
    };
    commonApi
      .create_circle(data)
      .then(res => {
        if (res.success === true) {
          getCircles();
          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // 
  useEffect(() => {
    getCircles();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showLoader && <LoaderSpinner  />}

      {showComponent === 'createCircle' && (
        <CreatCircleList
          onClose={type => {
            if (type) {
              getCircles();
            }
            setShowComponent('');
          }}
        />
      )}
      {showComponent === "" && 
      <SlidingPanel hidePanel={() => props.onClose()}>
        <Scrollbars>
          <div className="fix-width-forms">
            <div className="sidebar-heading-wrapper mt-">
              <div className="heading">
                <h2>
                  <i className="icon-circles primary-color" /> Circles
                </h2>
              </div>

              <div className="d-flex align-items-center search-tabs-icons">
                <span className='as-link' onClick={()=>{setShowComponent('createCircle')}}>
                  <i className="fas fa-plus" /> Add New Circle
                </span>

                {/* <span className="close as-link">
                                <i className="fas fa-times"  onClick={()=>{props.onClose();}}/>
                            </span> */}
              </div>
            </div>

            <div className="invite-page-search ">
              {/* <p>
                <i className="icon-circles primary-color" /> Circles
              </p> */}
              <div className="search-form">
                <Form.Group>
                  <div className="select-wrapper">
                    <select
                      className="form-control mb-3"
                      onChange={e => {
                        getCirclesUsers(e.target.value);
                      }}
                      disabled={circles.length > 0 ? false : true}>
                      {Object.entries(circles).length > 0 &&
                        circles.map((item, index) => (
                          <option value={item.circle_id}>
                            {item.circle_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </Form.Group>
              </div>

              {/* <div className="user-profile-wrapper">
                <div className="user-img">
                  <img
                    src={
                      props.userData.profile_img
                        ? props.userData.profile_img
                        : DEFAULT_IMAGE
                    }
                    alt=""
                  />
                </div>
                <div className="user-content">
                  <h2>Analyzer, Supporter</h2>
                  <h3>
                    {props.userData.first_name + ' ' + props.userData.last_name}
                  </h3>
                </div>
              </div> */}
            </div>

            <div className="invite-persons-wrapper">
              <div className="invite-persons-heading">
                <span className="builder-text">Existing Circle Members</span>
              </div>
              <div className="invite-persons-list">
                <Row>
                  {Object.entries(circleUser).length > 0 ? (
                    Object.entries(circleUser).length > 0 &&
                    circleUser.map((item, index) => (
                      <Col md={4} xl={3}>
                        <div
                          className="stage-list-item"
                          // onClick={() => { getProfileData(item.id) }}
                        >
                          <div className="stage-person-img">
                            <img
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>
                          <div className="stage-person-name">
                            <h3>
                              {item.first_name} {item.last_name}
                            </h3>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : !showLoader ? (
                    <div className="text-center">No record found</div>
                  ) : (
                    <></>
                  )}
                </Row>
              </div>
            </div>

            <div className="filter-btn-wrapper invite-btns  text-left">
              <Button
                onClick={() => {
                  addUserInCircle();
                }}>
                Add To Circle
              </Button>
            </div>
          </div>
        </Scrollbars>
      </SlidingPanel>}
    </>
  );
};

export default CircleList;
