import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {Button,} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import Moment from 'react-moment';
import Sidebar from '../../Pages/Sidebar';
import OpportunityMessageList from '../OpportunitiesList/OpportunityMessageList';
import {RenderHTMLBlock} from '../../Utils/Helpers';
import SessionNotes from './SessionNotes';
import EventDetail from '../Referral/EventDetail';
import {DEFAULT_IMAGE} from '../../Utils/env';
import { RoiLogo, UserGroupMini, consults} from '../../Constants/Images';
import {Link} from 'react-router-dom';
import EditAppointment from '../AppointmentPage/EditAppointment';
import OtherUserImages from '../Common/OtherUserImages';
import Scrollbars from 'react-custom-scrollbars';
import CustomSlidingTabs from '../CustomSlidingTabs';
import {collectingUsers} from '../../Utils/commonUtils';
// import {ref, onValue} from 'firebase/database';
// import {db} from '../../Firebase/firebase';
import Pannels from '../Pannels';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SmartChatPage from '../Common/SmartChatPage';
import {chatroomInfo} from '../../Utils/commonUtils';
import {fixName} from '../../Utils/commonUtils';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {CountSessionHistoryUsers} from '../../Utils/commonUtils';
import ShowMore from '../Common/ShowMore';
import {getItem} from '../../Utils/LocalStorage';
import {loginUserRole} from '../../Utils/commonUtils';
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import ScrollLoader from '../Modals/ScrollLoader';
import {useHistory} from 'react-router-dom';

const History = props => {
  const [subTabType, setSubTabsType] = useState('opportunities');
  const [showLoader, setshowLoader] = useState(false);
  const [oppData, setOppData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [oppoDetails, setOppoDetails] = useState([]);
  const [allInfoOpp, setAllInfoOpp] = useState([]);
  const [sessionNoteData, setSessionNoteData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [eventItem, setEventItem] = useState([]);
  const [historyValue, setHistoryValue] = useState(false);
  const [consult, setConsult] = useState([]);
  const [chatRoomStatus, setChatRoomStatus] = useState('pending');
  const [convosData, setConvosData] = useState([]);
  const [page, setPage] = useState(1);
  const [chatDetails, setChatDetails] = useState([]);
  const [chatPropType, setChatPropType] = useState('');

  // const [chatItem, setChatItem] = useState({});
  // const [chatPath, setChatPath] = useState({});
  // const [chatHeader, setChatHeader] = useState({});

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true); // Flag to indicate more data is available
  const [isFetching, setIsFetching] = useState(false); // Flag to prevent multiple requests
  // const [mustApiHit, setMustApiHit] = useState(false);
  const history = useHistory();
  const mustApiHit = useRef(false);

  const get_consult_history = pageN => {
    if ((!hasMoreData || isFetching) && mustApiHit.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }

    setIsFetching(true);

    var data = {
      page: pageN,
    };
    commonApi
      .get_consult_history(data)
      .then(res => {
        if (res) {
          setshowLoader(false);
          if (pageN > 1) {
            if (res.history.length > 0) {
              setPage(pageN);
              setConsult([...consult, ...res.history]);
            } else {
              setHasMoreData(false);
              mustApiHit.current = !mustApiHit.current;
            }
          } else {
            setPage(1);
            setConsult(res.history);
          }

          setIsFetching(false);
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const userEventsList = pageN => {
    if ((!hasMoreData || isFetching) && mustApiHit.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }

    setIsFetching(true);
    var data = {
      page: pageN,
    };
    commonApi
      .user_events_list(data)
      .then(res => {
        if (res) {
          if (pageN > 1) {
            if (res.user_events_list.length > 0) {
              setPage(pageN);
              setEventData([...eventData, ...res.user_events_list]);
            } else {
              setHasMoreData(false);
              mustApiHit.current = !mustApiHit.current;
            }
          } else {
            setPage(1);
            setEventData(res.user_events_list);
          }
          setIsFetching(false);
          setIsLoading(false);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const user_session_list = pageN => {
    
    // 
    if ((!hasMoreData || isFetching) && mustApiHit.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }

    setIsFetching(true);

    var data = {
      page: pageN,
    };
    commonApi
      .user_session_list(data)
      .then(res => {
        if (res) {
          setshowLoader(false);
          if (pageN > 1) {
            if (res.data.length > 0) {
              setPage(pageN);
              setSessionData([...sessionData, ...res.data]);
            } else {
              setHasMoreData(false);
              mustApiHit.current = !mustApiHit.current;
            }
          } else {
            setPage(1);
            setSessionData(res.data);
          }

          setIsFetching(false);
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const userOpportunitesList = pageN => {
    // setshowLoader(true);

    if ((!hasMoreData || isFetching) && mustApiHit.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }

    setIsFetching(true);

    var data = {
      page: pageN,
    };
    commonApi
      .user_opportunites_list(data)
      .then(res => {
        if (res) {
          setshowLoader(false);
          if (pageN > 1) {
            if (res.user_opportunites_list.length > 0) {
              setPage(pageN);
              setOppData([...oppData, ...res.user_opportunites_list]);
            } else {
              setHasMoreData(false);
              // setMustApiHit(true)
              mustApiHit.current = !mustApiHit.current;
            }
          } else {
            setPage(1);
            setOppData(res.user_opportunites_list);
          }
          setIsFetching(false);
          setIsLoading(false);
        }
        amplitudeEvent('VISIT_HISTORY_OPPORTUNITY');
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const handleConvos = item => {
  //   let path = '';
  //   setChatItem(item);
  //   if (item.chat_type === 'opportunity') {
  //     path = 'chatrooms/opportunities/' + item.chat_room_name;
  //   } else {
  //     path = 'chatrooms/chats/' + item.chat_room_name;
  //   }
  //   const database = ref(db, path);
  //   onValue(database, snapshot => {
  //     if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
  //       setChatPath(snapshot.val());
  //     }
  //   });

  //   const userIs =
  //     parseInt(item.creator_id) === parseInt(localStorage.getItem('user'))
  //       ? 'user_detail'
  //       : item.hasOwnProperty('creator_details')
  //       ? 'creator_details'
  //       : 'creator_detail';
  //   setChatHeader({
  //     image:
  //       item.group_image !== ''
  //         ? item.group_image
  //         : item[userIs]['profile_img']
  //         ? item[userIs]['profile_img']
  //         : DEFAULT_IMAGE,
  //     name:
  //       item.group_name !== ''
  //         ? item.group_name
  //         : item[userIs]['first_name'] + ' ' + item[userIs]['last_name'],
  //   });
  // };

  // const handleChatBoxSignal = (signalType, signalData, childKey, parentKey) => {
  //   if (signalType === 'show-replies') {
  //     // setHandleData({
  //     //   key: "",
  //     //   item: signalData,
  //     //   cKey: childKey,
  //     //   pKey: parentKey,
  //     // });
  //     // setShowReplies(true);
  //   }
  // };

  useEffect(() => {
    setshowLoader(true);
    userOpportunitesList(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {

  //   setHasMoreData(true)
  //   setTimeout(() => {
  //     setshowLoader(false)
  //   }, 500);

  // }, [subTabType])

  
  //   

  const getConservation = pageN => {
    if ((!hasMoreData || isFetching) && mustApiHit.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }

    setIsFetching(true);

    var data = {
      filter: {
        event: true,
        opportunity: true,
        personal: true,
        session: true,
      },
      page: pageN,
      search_query: '',
      status: 'history',
    };
    commonApi.get_conversation(data).then(res => {
      if (res) {
        if (pageN > 1) {
          if (res.all_data.length > 0) {
            setPage(pageN);
            setConvosData([...convosData, ...res.all_data]);
          } else {
            setHasMoreData(false);
            mustApiHit.current = !mustApiHit.current;
          }
        } else {
          setPage(1);
          setConvosData(res.all_data);
        }
        setshowLoader(false);
        setIsFetching(false);
        setIsLoading(false);
      }
    });
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + scrollTop >= scrollHeight) {
      // Increment the page number and call the API
      if (subTabType === 'sessions') {
        user_session_list(page + 1);
      }
      if (subTabType === 'opportunities') {
        userOpportunitesList(page + 1);
      }
      if (subTabType === 'consults') {
        get_consult_history(page + 1);
      }
      if (subTabType === 'events') {
        userEventsList(page + 1);
      }
      if (subTabType === 'Convos') {
        getConservation(page + 1);
      }
    }
  };

  const handleOpenChatRoom = chatDetail => {
    setChatDetails(chatDetail);
    setAllChatUsers(collectingUsers(chatDetail));
    setChatRoomName(chatDetail.chat_room_name);
    setChatRoomId(chatDetail.chat_id);

    if (chatDetail.chat_type === 'opportunity') {
      if (chatDetail.creator_id === localStorage.getItem('id')) {
        setChatPropType('mine-opportunity');
      } else if (chatDetail.team_member_ids) {
        const myArray = chatDetail.team_member_ids.split(',');
        if (myArray.includes(localStorage.getItem('id'))) {
          setChatPropType('mine-opportunity');
        } else {
          setChatPropType('other-opportunity');
        }
      } else {
        setChatPropType('other-opportunity');
      }
    } else {
      if (chatDetail.chat_room_type === 'group') {
        setChatPropType('personal-group');
      } else {
        setChatPropType('personal-single');
      }
    }

    setShowComponent('chat-component');
  };

  const handleApiSelector = item => {
    // setMustApiHit(false)
    if (mustApiHit.current) {
      mustApiHit.current = !mustApiHit.current;
    }
    

    if (item === 'opportunities') {
      userOpportunitesList(1);
    }
    if (item === 'sessions') {
      user_session_list(1);

      amplitudeEvent('VISIT_HISTORY_SESSION');
    }
    if (item === 'consults') {
      get_consult_history(1);
      amplitudeEvent('VISIT_HISTORY_CONSULT');
    }
    if (item === 'events') {
      userEventsList(1);
      amplitudeEvent('VISIT_HISTORY_EVENT');
    }

    if (item === 'Convos') {
      getConservation(1);
      amplitudeEvent('VISIT_HISTORY_CONVOS');
    }
  };

  const handleCommonChat = (data, type) => {
    
  };

  const usersGroup = item => {
    if (item.chat_room_type === 'single') {
      return [];
    }
    if (item.hasOwnProperty('group_detail')) {
      return item.group_detail;
    } else if (item.hasOwnProperty('creator_details')) {
      const arr1 = [item.creator_details];
      const arr2 = item.other_persons_opportunity_chat_room
        ? item.other_persons_opportunity_chat_room
        : [];
      const arr3 = item.team_member_details ? item.team_member_details : [];
      const children = arr1.concat(arr2, arr3);
      return children;
    } else {
      return [];
    }
  };

  
  
  
  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper">
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="sessions-content-wrapper HistoryPage">
                    <div className="profile-referral-wrapper"></div>
                    <div className="event-tabs sliding-tabs mb-3">
                      <CustomSlidingTabs
                        items={[
                          {
                            key: 'opportunities',
                            label: 'Recruitment',
                          },
                          {
                            key: 'sessions',
                            label: 'Virtual',
                          },
                          // {
                          //   key: 'consults',
                          //   label: 'Consults',
                          // },
                          {key: 'events', label: 'In-person'},
                          {key: 'Convos', label: 'Convos'},
                        ]}
                        showItems={3}
                        activeTab={subTabType}
                        onItemClick={item => {
                          // setMustApiHit(false)
                          // setHasMoreData(true)
                          setSubTabsType(item);
                          setshowLoader(true);
                          handleApiSelector(item);
                        }}
                      />
                    </div>
                    {subTabType === 'opportunities' && (
                      <>
                        <div className="refferal-list-wrapper">
                          {showLoader && <LoaderSpinner />}

                          <Scrollbars
                            autoHide
                            onScroll={handleScroll}
                            style={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <div className="event-tabs tabs-style-2 mt-3 mb-0" />
                            {Object.entries(oppData).length > 0 ? (
                              Object.entries(oppData).length > 0 &&
                              oppData.map((item, index) => (
                                <div
                                  key={item.id + 'yoooooo'}
                                  className="opportunity-item card"
                                  onClick={() => {
                                    setAllInfoOpp(item);
                                    setShowComponent('opp');
                                  }}>
                                  <h2 className="as-link">{item.title}</h2>
                                  <div className="oppo-share-wrapper as-link">
                                    <span className="category">
                                      {item.business_name}
                                    </span>
                                  </div>
                                  <div className="as-link">
                                    <RenderHTMLBlock
                                      className="oppo-desc"
                                      content={item.description}
                                    />
                                  </div>

                                  <div className="oppo-address as-link">
                                    {item.address !== '' && (
                                      <span className="address">
                                        <i className="icon-map-pin" />{' '}
                                        {item.address === ' '
                                          ? ''
                                          : item.address}
                                      </span>
                                    )}
                                    <span className="injeaction-text">
                                      {item.opportunity_type_name}
                                      <b>
                                        {item.amount
                                          ? '$' + item.amount + '/hr'
                                          : ''}
                                      </b>
                                    </span>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="no-results-wrapper">
                                <div className="img-bg-wrapper">
                                  <img
                                    src={require('../../Assets/images/norecord/session.png')}
                                    alt="no-session"
                                  />
                                </div>

                                <div className="no-results-content">
                                  <h2>Ask For What You Need</h2>
                                  <p>
                                    Don't hesitate to let people know you are
                                    looking for or providing an opportunity to
                                    the BUILD community. We all have to play to
                                    truly accelerate growth.
                                  </p>

                                  <div className="btn-wrapper mt-3">
                                    <Button
                                      className={
                                        getItem('userRole') === 'guest'
                                          ? 'grey-class-btn'
                                          : 'btn btn-dark'
                                      }
                                      onClick={(e) => {

                                        loginUserRole(e);
                                        if (
                                          localStorage.getItem('userRole') !==
                                          'guest'
                                        ) {
                                          history.push({
                                            pathname: '/recruitment',
                                            state: {
                                              typeIs: 'historyOpportunity',
                                            },
                                          });
                                        }
                                      }}>
                                      {/* <Link
                                        className="text-light"
                                        onClick={e => {
                                          loginUserRole(e);
                                        }}
                                        style={{
                                          textDecoration: 'none',
                                        }}
                                        // to="/opportunities"
                                      > */}
                                        Explore Recruitment
                                      {/* </Link> */}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Scrollbars>
                        </div>
                        {isLoading && <ScrollLoader />}
                      </>
                    )}
                    {subTabType === 'sessions' && (
                      <>
                        <div className="refferal-list-wrapper">
                          {showLoader && <LoaderSpinner />}
                          <Scrollbars
                            autoHide
                            onScroll={handleScroll}
                            style={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <div className="session-wrapper history-sessions">
                              {Object.entries(sessionData).length > 0 ? (
                                Object.entries(sessionData).length > 0 &&
                                sessionData.map((item, index) => (
                                  <div
                                    key={index + 'ASDSAD' + item.id}
                                    className="events-item as-link"
                                    onClick={() => {
                                      setSessionNoteData(item);
                                      setShowComponent('sesionNote');
                                      
                                    }}>
                                    <div className="session-heading">
                                      <div className="event-titles">
                                        <h2 className="as-link">
                                          {item.session_title}
                                        </h2>
                                        <div className="event-date">
                                          {item.total_user_session_time} -{' '}
                                          <Moment format="MM/DD/YY H:mmA">
                                            {item.session_start_time}
                                          </Moment>
                                        </div>
                                        <div className="event-users-cate">
                                          <span className="users-text usercount-type as-link">
                                            <img
                                              src={UserGroupMini}
                                              height={16}
                                              width={16}
                                              alt=""
                                            />{' '}
                                            <span className="userCount">
                                              {CountSessionHistoryUsers(
                                                item.all_join_user_in_session,
                                              )}
                                            </span>
                                            <span className="category-text as-link">
                                              {item.session_category}
                                              {item.session_notes.length >
                                                0 && (
                                                <i className="fa-sharp fa-solid fa-book"></i>
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="persons-img-list mb-0 d-flex align-items-center">
                                      <div className="img-item mb-0">
                                        <img src={item.creator_img} alt="" />
                                      </div>
                                      <span className="mr-3">
                                        {` `} Moderator - {` `}
                                        {fixName(
                                          item.creator_first_name,
                                          item.creator_last_name,
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="no-results-wrapper">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={require('../../Assets/images/norecord/session.png')}
                                      alt="no-session"
                                    />
                                  </div>

                                  <div className="no-results-content">
                                    <h2>Start Expanding Your Network </h2>
                                    <p>
                                      We can explore existing group sessions or
                                      Startyour own. audio,video and screen
                                      sharing are at your fingertips.
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className={
                                          getItem('userRole') === 'guest'
                                            ? 'grey-class-btn'
                                            : 'btn btn-dark'
                                        }>
                                        <Link
                                          className="text-light"
                                          onClick={e => {
                                            loginUserRole(e);
                                          }}
                                          style={{
                                            textDecoration: 'none',
                                          }}
                                          to="/">
                                          Explore Meetups
                                        </Link>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Scrollbars>
                        </div>
                        {isLoading && <ScrollLoader />}
                      </>
                    )}
                    {subTabType === 'consults' && (
                      <div className="refferal-list-wrapper">
                        {showLoader && <LoaderSpinner />}
                        <Scrollbars
                          autoHide
                          onScroll={handleScroll}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}>
                          <div className="event-tabs tabs-style-2 mt-3 mb-0" />
                          {Object.entries(consult).length > 0 ? (
                            Object.entries(consult).length > 0 &&
                            consult.map((item, index) => (
                              <div
                                key={index + 'aty' + item.id}
                                className="opportunity-item card history-consults">
                                <h2 className="">{item.title}</h2>
                                <div className="oppo-share-wrapper">
                                  <span>
                                    <ShowMore limit={120}>
                                      {item.description}
                                    </ShowMore>
                                  </span>
                                </div>

                                <div className="oppo-address">
                                  <span className="injeaction-text">
                                    <div className="persons-img-list ">
                                      <div className="img-item ">
                                        <img
                                          src={item.creator_details.profile_img}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <Moment format="MM/DD/YY h:mmA">
                                      {item.start_date}
                                    </Moment>
                                  </span>
                                  <div className="video-details">
                                    <i className="fa-solid fa-video" />{' '}
                                    <div className="text-left">
                                      <span className="">
                                        {item.duration} Minutes
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="no-results-wrapper">
                              <div className="img-bg-wrapper">
                                <img src={consults} alt="" />
                              </div>

                              <div className="no-results-content">
                                <h2>
                                  Leverage your Experiences to Help Others
                                </h2>
                                <p>
                                  No matter if you want to mentor for free or
                                  charge a consulting fee. you can start hosting
                                  1-on-1 video meetings based on your
                                  availability.
                                </p>

                                <div className="btn-wrapper">
                                  <Button
                                    className="btn btn-dark"
                                    onClick={() => {
                                      setShowComponent('appoinment');
                                    }}>
                                    Create Appointment Type
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Scrollbars>
                      </div>
                    )}
                    {subTabType === 'events' && (
                      <>
                        <div className="refferal-list-wrapper">
                          {showLoader && <LoaderSpinner />}
                          <Scrollbars
                            autoHide
                            onScroll={handleScroll}
                            style={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <div className="event-tabs tabs-style-2 mt-3 mb-0" />
                            {Object.entries(eventData).length > 0 ? (
                              Object.entries(eventData).length > 0 &&
                              eventData.map((item, index) => (
                                <div
                                  key={index + 'eventData' + item.id}
                                  className="session-item card history-events"
                                  onClick={() => {
                                    setEventItem(item);
                                    setShowComponent('event');
                                  }}>
                                  <div className="session-heading as-link mb-0">
                                    <h2>{item.session_title}</h2>
                                    <p className="refferal-date mb-1">
                                      <Moment format="MM/D/YY h:mmA">
                                        {item.start_time}
                                      </Moment>
                                    </p>
                                    <span className="users-text usercount-type as-link mb-2">
                                      <img
                                        src={UserGroupMini}
                                        height={16}
                                        width={16}
                                        alt=""
                                      />{' '}
                                      <span className="userCount">
                                        {CountSessionHistoryUsers(
                                          item.all_join_user_in_session,
                                        )}
                                      </span>
                                      <span className="category-text">
                                        {item.ses_category_namep}
                                      </span>
                                    </span>
                                  </div>

                                  <div className="persons-img-list mb-0 d-flex align-items-end justify-content-between">
                                    {Object.entries(item.moderators_list)
                                      .length > 0 ? (
                                      Object.entries(item.moderators_list)
                                        .length > 0 &&
                                      item.moderators_list.map(
                                        (item, index) => (
                                          <div className="img-item m-0">
                                            <img
                                              key={`ref-wwwertyuw-${index}`}
                                              src={
                                                item.profile_img === ''
                                                  ? DEFAULT_IMAGE
                                                  : item.profile_img
                                              }
                                              alt=""
                                            />
                                          </div>
                                        ),
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    {/* <br /> */}
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mt-1">
                                    {item.address !== '' && (
                                      <span className="address as-link d-block truncate_2_lines">
                                        <i className="icon-map-pin" />{' '}
                                        {item.address === ' '
                                          ? ''
                                          : item.address}
                                      </span>
                                    )}
                                    <div className="text-right as-link">
                                      <i className="icon-tickets" />
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="no-results-wrapper">
                                <div className="img-bg-wrapper">
                                  <img
                                    src={require('../../Assets/images/norecord/session.png')}
                                    alt="no-session"
                                  />
                                </div>

                                <div className="no-results-content">
                                  <h2>Let's Meet In Person </h2>
                                  <p>
                                    Virtual is convenient but something we need
                                    to feel each others vibes in person.Lets
                                    enjoy each other while we grow.
                                  </p>

                                  <div className="btn-wrapper mt-3">
                                    <Button
                                      className={
                                        getItem('userRole') === 'guest'
                                          ? 'grey-class-btn'
                                          : 'btn btn-dark'
                                      }>
                                      <Link
                                        className="text-light"
                                        onClick={e => {
                                          loginUserRole(e);
                                        }}
                                        style={{
                                          textDecoration: 'none',
                                        }}
                                        to={{
                                          pathname: '/',
                                          state: {
                                            typeIs: 'events',
                                          },
                                        }}>
                                        Explore Meetups
                                      </Link>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Scrollbars>
                        </div>
                        {isLoading && <ScrollLoader />}
                      </>
                    )}
                    {subTabType === 'Convos' && (
                      <>
                        <div className="refferal-list-wrapper">
                          {showLoader && <LoaderSpinner />}
                          <Scrollbars
                            autoHide
                            onScroll={handleScroll}
                            style={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <div className="event-tabs tabs-style-2 mt-3 mb-0" />
                            <div className="messages-users-list">
                              {Object.entries(convosData).length > 0 ? (
                                Object.entries(convosData).length > 0 &&
                                convosData.map((item, index) => {
                                  // const newMessage = item.read
                                  //   ? ''
                                  //   : 'new-message';
                                  const userIs =
                                    parseInt(item.creator_id) ===
                                    parseInt(localStorage.getItem('user'))
                                      ? 'user_detail'
                                      : item.hasOwnProperty('creator_details')
                                      ? 'creator_details'
                                      : 'creator_detail';
                                  return (
                                    <div
                                      // onClick={() => {
                                      //   // getUserData(item.user_id);
                                      //   // checkNewUsersInGroup(item.id);
                                      //   handleOpenChatRoom(item);
                                      // }}
                                      onClick={() => {
                                        // handleConvos(item);
                                        handleOpenChatRoom(item);
                                      }}
                                      key={`msg--${index}`}
                                      className={`message-user-item history-convos`}>
                                      <div className="user-img as-link">
                                        <img
                                          src={
                                            item.group_image !== ''
                                              ? item.group_image
                                              : item[userIs]['profile_img']
                                              ? item[userIs]['profile_img']
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                        {item.chat_type === 'question' && (
                                          <span>
                                            <i className="fa-solid fa-q"></i>
                                          </span>
                                        )}
                                        {item.chat_type === 'opportunity' && (
                                          <span className="icon icon-posts"></span>
                                        )}
                                        {item.chat_type === 'event' && (
                                          <span className="icon-tickets"></span>
                                        )}
                                        {item.chat_type === 'session' && (
                                          <span className="icon-video-camera-2"></span>
                                        )}
                                      </div>
                                      <div className="user-content as-link">
                                        <h2>
                                          {item.group_name !== ''
                                            ? item.group_name
                                            : fixName(
                                                item[userIs]['first_name'],
                                                item[userIs]['last_name'],
                                              )}
                                        </h2>
                                        <p className="as-link single-line-text">
                                          {item.chat_room_type === 'single'
                                            ? item.last_message
                                            : 'You are no longer a member'}
                                        </p>
                                        <div className="message-date-info">
                                          <span className="message-date as-link">
                                            <Moment format="MM/DD/YY HH:mm A">
                                              {item.chat_room_type === 'single'
                                                ? item.last_message_time
                                                  ? item.last_message_time
                                                  : item.created_at
                                                : item.invitaion_status
                                                ? item.invitaion_status
                                                    .updated_at
                                                : item.last_message_time}
                                            </Moment>
                                          </span>
                                          {/* {renderMessageUsers(item.moreUsers)} */}
                                        </div>
                                      </div>

                                      {/* {item.hasOwnProperty('group_detail') && ( */}
                                      <OtherUserImages
                                        otherUsers={usersGroup(item)}
                                      />
                                      {/* )} */}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="no-results-wrapper mt-5">
                                   <div className="img-bg-wrapper">
                                                    <img
                                                      src={require('../../Assets/images/norecord/no-convos.webp')}
                                                      alt=""
                                                    />
                                                  </div>
                                  <div className="no-results-content">
                                    <p>
                                      Seems you don't have any closed conversations.
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className={
                                          getItem('userRole') === 'guest'
                                            ? 'grey-class-btn'
                                            : 'btn btn-dark'
                                        }>
                                        <Link
                                          className="text-light"
                                          style={{
                                            textDecoration: 'none',
                                          }}
                                          onClick={e => {
                                            loginUserRole(e);
                                          }}
                                          to={{
                                            pathname: '/circle',
                                            state: {
                                              typeIs: 'search',
                                            },
                                          }}>
                                          Explore Builders
                                        </Link>
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Scrollbars>
                        </div>
                        {isLoading && <ScrollLoader />}
                      </>
                    )}
                  </div>
                </>
              }
              secondary={
                <>
                  {/* {Object.entries(chatItem).length > 0 &&
                  Object.entries(chatPath).length > 0 && (
                    <SlidingPanel hidePanel={() => props.onClose()}>
                      <div className="messages-wrapper pb-3">
                        {Object.entries(chatHeader).length > 0 && (
                          <div
                            className="messages-user-header fix-width-forms"
                            // onClick={() => {
                            //   
                            // }}
                          >
                            <div className="user-img">
                              <img src={chatHeader.image} alt="" />
                            </div>
                            <div className="user-details">
                              <h2>{chatHeader.name}</h2>
                            </div>
                            {/* <span
                    className="close as-link"
                    onClick={() => props.onClose()}
                  >
                    <i className="fas fa-times" />
                  </span> /}
                          </div>
                        )}
                        <Scrollbars>
                          <div className="fix-width-forms">
                            {/* {convosData ==}  /}
                            {Object.entries(chatItem).length > 0 &&
                              Object.entries(chatPath).length > 0 && (
                                <SmartChatBox
                                  allChatUsers={collectingUsers(chatItem)}
                                  allChat={Object.entries(chatPath)}
                                  // handleChatBoxSignal={handleChatBoxSignal}
                                  handleChatBoxSignal={() => {
                                    
                                  }}
                                  chatStatus={false}
                                  chatModule={'history'} // temperary fix for MARK AS BEST ANSWER
                                />
                              )}
                          </div>
                        </Scrollbars>
                      </div>
                    </SlidingPanel>
                  )} */}

                  {showComponent === 'chat-component' && (
                    <SmartChatPage
                      allmsg={true}
                      type={chatPropType}
                      item={[]}
                      chatroomInfo={chatroomInfo(chatDetails)}
                      oppoDetails={chatDetails}
                      chatDetails={chatDetails}
                      allChatUsers={allChatUsers}
                      chatRoomName={chatRoomName}
                      chatRoomId={chatRoomId}
                      onClose={() => {
                        setShowComponent('');
                      }}
                      handleCommonChat={(data, type) => {
                        handleCommonChat(data, type);
                      }}
                    />
                  )}

                  {showComponent === 'appoinment' && (
                    <EditAppointment
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}

                  {showComponent === 'event' && (
                    <EventDetail
                      onClose={() => {
                        setShowComponent('');
                      }}
                      item={eventItem}
                      historyValue={historyValue}
                      setHistoryValue={setHistoryValue}
                      history={true}
                    />
                  )}

                  {showComponent === 'sesionNote' && (
                    <SlidingPanel hidePanel={() => setShowComponent('')}
                    renderData={renderHeader}
                      showBlackHeader={true}
                      headerPadding={'24px 20px'}
                      lastDivStyle={{
                        borderRadius: '35px',
                        background: '#f7f6f2',
                      }}
                      firstDivStyle={{
                        padding: 0,
                        background:
                          'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
                      }}
                    >
                      <SessionNotes
                        item={sessionNoteData}
                        onClose={() => {
                          setShowComponent('');
                        }}
                      />
                    </SlidingPanel>
                  )}

                  {/* {showComponent === 'oppoChat' &&
                  // oppoDetails &&
                  // Object.entries(oppoDetails).length > 0 &&
                  allInfoOpp.created_by == localStorage.getItem('id') && (
                    // <SlidingPanel hidePanel={() => setChatRoomName('')}>
                    <SmartChatPage
                      // type={'mine-opportunity'}
                      // chatDetails={allInfoOpp}
                      // oppoDetails={oppoDetails}
                      // allChatUsers={allChatUsers}
                      // chatRoomName={chatRoomName}
                      // chatRoomId={chatRoomId}
                      // chatRoomStatus={chatRoomStatus}
                      allmsg={true}
                      type={'history-opportunity'}
                      item={[]}
                      // chatroomInfo={chatroomInfo(oppoDetails)}
                      chatroomInfo={chatroomInfoOppoMsgList(
                        oppoDetails,
                        'opportunity',
                      )}
                      oppoDetails={oppoDetails}
                      chatDetails={oppoDetails}
                      allChatUsers={collectingOppoUsers(oppoDetails)}
                      chatRoomName={chatRoomName}
                      chatRoomId={chatRoomId}
                      onClose={() => {
                        setChatRoomName('');
                        setShowComponent('opp');
                      }}
                      handleCommonChat={(data, type) => {
                        handleCommonChat(data, type);
                      }}
                    />
                    // </SlidingPanel>
                  )} */}

                  {showComponent === 'opp' &&
                    allInfoOpp.created_by === localStorage.getItem('id') && (
                      // <SlidingPanel hidePanel={() => showComponent('')}>
                      <OpportunityMessageList
                        item={allInfoOpp}
                        setOppoDetails={setOppoDetails}
                        oppoDetails={oppoDetails}
                        setAllChatUsers={setAllChatUsers}
                        setChatRoomId={setChatRoomId}
                        setChatRoomName={setChatRoomName}
                        setChatRoomStatus={setChatRoomStatus}
                        chatRoomStatus={chatRoomStatus}
                        type={'history'}
                        onClose={() => {
                          // setShowButton(false);
                          setShowComponent('');
                        }}
                        onShowChat={() => {
                          setShowComponent('oppoChat');
                        }}
                      />
                      //{' '}
                      // </SlidingPanel>
                    )}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
