import React from 'react';
import {Button,  Form} from 'react-bootstrap';
import {useState} from 'react';
// import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import { arrayColumn } from '../../Utils/commonUtils';
import CustomModal from '../CustomModal';
import Scrollbars from 'react-custom-scrollbars';


// import OpportunityItem from "../OpportunitiesList/OppotunityItem";
const SnapShotFilter = props => {
    const [categoriesArr, setCategoriesArr] = useState([])
    // const [categoriesName, setCategoriesName] = useState(['All']);
  const snapshotCategories = useSelector(
    state => state.accomplishmentCategories,
  );

  const handleSnapshotSelection = event => {
    const {value, checked} = event.target;
    const itemObj = JSON.parse(value);

    if (itemObj.id === 'All') {
      //   setCategoriesName(['All']);
      if (!checked) {
        setCategoriesArr([]);
      } else {
        let categories = arrayColumn(snapshotCategories, 'id');
        setCategoriesArr(categories);
      }
    } else {
      if (checked) {
        // setCategoriesName(prev => [...prev, itemObj.title]);
        setCategoriesArr(prev => [...prev, itemObj.id]);
      } else {
        // setCategoriesName(prev =>
        //   prev.filter(title => title !== itemObj.title),
        // );
        setCategoriesArr(prev => prev.filter(id => id !== itemObj.id));
      }
    }
  };

  // useEffect(() => {
  //   if (
  //     categoriesArr.length === 0 ||
  //     categoriesArr.length === snapshotCategories.length
  //   ) {
  //     setCategoriesName(['All']);
  //   } else {
  //     const filterData = snapshotCategories.filter(item =>
  //       categoriesArr.includes(item.id),
  //     );
  //     setCategoriesName(arrayColumn(filterData, 'category_name'));
  //   }
  // }, [categoriesArr]);

  return (
    <div className="profile-gallery-wrapper">
      <CustomModal
        size="medium"
        style={{height: '85%'}}
        onClose={() =>{props.onClose()}}>
        <div className="filter-wrapper custom-modal-filter mb-0">
          <div className="filter-headings">
            <h2 style={{
              textAlign:'center'
            }}>Filter By Category</h2>
          </div>
          <Scrollbars>
            <div className="filter-category-list">
              <ul>
                <li
                  key={`sessionC-100`}
                  className={categoriesArr.length === 8 ? 'active' : ''}>
                  <Form.Label>
                    {'All'}
                    <input
                      type="checkbox"
                      value={'{"id":"All", "title":"All"}'}
                      onChange={handleSnapshotSelection}
                      checked={categoriesArr.length === 8}
                    />
                    <span />
                  </Form.Label>
                </li>
                {Object.entries(snapshotCategories).length > 0 &&
                  snapshotCategories.map((item, index) => (
                    <li
                      key={`sessionC-${index}`}
                      className={
                        categoriesArr.includes(item.id) ? 'active' : ''
                      }>
                      <Form.Label>
                        {item.category_name}
                        <input
                          type="checkbox"
                          value={
                            '{"id":"' +
                            item.id +
                            '", "title":"' +
                            item.category_name +
                            '"}'
                          }
                          onChange={handleSnapshotSelection}
                          checked={categoriesArr.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </Scrollbars>
          <div className="filter-category-list">
            <Button
              className="btn-dark"
              onClick={() => {
                props.setSnapshotFilter(categoriesArr)
                props.onClose();
                
              }}>
              Filter
            </Button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SnapShotFilter;
