import React from 'react';
import SlidingPanel from '../SlidingPanel';
import { DEFAULT_IMAGE_COMPANYLOGO } from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';

const CompanyList = props => {
  
    const cardStyle = {
        display: 'flex',
        width: '376px',
        height: '122px',

        borderRadius: '5.015px',
        border: ' 1.003px solid #F6F5F5',
        background: '#FFF',
        marginTop: '14px',
    };

    const imageStyle = {
        width: '94.276px',
        height: '94.276px',
        borderRadius: '5.015px',
        margin: '12px',
        objectFit: 'cover',
    };

    const contentStyle = {
        flex: 1,
        margin: ' 1px',
        marginTop: '14px',
    };
    return (
        // <SlidingPanel hidePanel={() => props.onClose()}>

        //     <Scrollbars>
                <div className="">
                    {props.companies.length > 0 &&
                        props.companies.map((item, index) => (
                            <div style={cardStyle} className='as-link' key={index} onClick={()=>{
                                props.handleCompanyProfile(item)
                            }}>
                                <img
                                    src={item.company_logo? item.company_logo : item.image ? item.image : DEFAULT_IMAGE_COMPANYLOGO}
                                    alt="Card"
                                    style={imageStyle}
                                />
                                <div style={contentStyle}>
                                    <h3
                                        style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            paddingBottom: '2px',
                                        }}>
                                        {item.company_name}
                                    </h3>
                                    <p
                                        style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            paddingBottom: '2px',
                                        }}>
                                        Naan Haven
                                    </p>
                                    <p
                                        style={{
                                            color: '#7E7B70',
                                            fontFamily: 'Inter',
                                            fontSize: '13px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            paddingTop: '7px',
                                        }}>
                                        Catering
                                    </p>
                                    <div>
                                        <div
                                            className="d-flex justify-content-between"
                                            style={{
                                                marginTop: '10px',
                                                marginRight: '18px',
                                            }}>
                                            <span
                                                style={{
                                                    color: '#000',
                                                    fontFamily: 'Inter',
                                                    fontSize: '15px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                }}>
                                                {' '}
                                                2 weeks needed in advance
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
        //     </Scrollbars>

        // </SlidingPanel>
    );
};

export default CompanyList;
