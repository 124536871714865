import React, {useState, useEffect} from 'react';
import Sidebar from '../../Pages/Sidebar';
import {selected_lang} from '../../Utils/env';
import Pannels from '../Pannels';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';

import {errorMessage, successMessage} from '../../Utils/ToastMessages';

import SnapshotDetail from '../Snapshot/SnapshotDetail';
import CreateAction from '../Snapshot/CreateAction';

import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';

import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import CustomModal from '../CustomModal';

import Actions from '../Actions/Actions';
import ActionDetail from '../Actions/ActionDetail';
import {useSelector, useDispatch} from 'react-redux';
import {getAllActions} from '../../Store/Actions/SessionAction';

const TechnicianTasks = props => {
  const dispatch = useDispatch();
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [userListing, setUserListing] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [snapShotId, setSnapShotId] = useState('');
  const [tabType, setTabType] = useState('incomplete');
  const [threeDots, setThreeDots] = useState('');
  // const [actionType, setActionType] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);
  // const [actionDetail, setActionDetail] = useState(false);
  const [checkFilterOption, setCheckFilterOption] = useState([]);
  const [filterOptionValue, setFilterOptionValue] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(true);



  const [dataOfAction, setDataOfAction] = useState([]);
  const [hitApi, setHitApi] = useState(false);

  const [workspaceData, setWorkspaceData] = useState([]);
  const actions = useSelector(state => state.AllActionsList);
  const filterOption = [
    {id: 1, title: 'Pending'},
    {id: 2, title: 'Completed'},
    {id: 3, title: 'Expire'},
  ];

  useEffect(() => {
    if (workspaceData.length > 0) {
      setTimeout(() => {
        getData();
      }, 500);
    }
  }, [workspaceData]);

  const getData = () => {
    setshowLoader(true);

    commonApi
      .get_accomplishment_invitation_by_login_id()
      .then(res => {
        if (res.status === 200) {
          setUserListing(res.accomplishment_invitations);
        }
        setshowLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const manageAccomplishmentInvitation = (status, id) => {
    let payload = {
      status: status,
      id: id,
    };
    commonApi
      .manage_accomplishment_invitation(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          getData();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const handleClickList = (id, value) => {
    // const {id, value} = e.target

    if (!checkFilterOption.includes(id)) {
      setCheckFilterOption([...checkFilterOption, id]);
      setFilterOptionValue([...filterOptionValue, value]);
    } else {
      if (checkFilterOption.length === 1) {
        errorMessage('One workspace should be selected.');
      } else {
        setCheckFilterOption(checkFilterOption.filter(itemId => itemId !== id));
        setFilterOptionValue(
          filterOptionValue.filter(itemValue => itemValue !== value),
        );
      }
    }
  };

  const handleSelectAll = () => {
    if (!isCheckAll) {
      setCheckFilterOption(filterOption.map(item => item.id));
      setFilterOptionValue(filterOption.map(item => item.title));
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
      setCheckFilterOption([]);
      setFilterOptionValue([]);
    }
  };

  useEffect(() => {
    if (filterOptionValue.length > 0) {
      if (filterOptionValue.length === filterOption.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFilterOption]);

  const getActions = () => {
   

    const options = filterOptionValue.map(element => {
      return element.toLowerCase();
    });

    var Payload = {
      user_id: localStorage.getItem('id'),
      type: ['all'],
      search_query: searchQry,
      filter: isCheckAll ? ['all'] : options,
    };

    dispatch(getAllActions(Payload));
  };

  useEffect(() => {
    if (isCheckAll) {
      setCheckFilterOption(filterOption.map(item => item.id));
      setFilterOptionValue(filterOption.map(item => item.title));
    }
    getActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        showComponent={showComponent}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
      />
      <div className="page-content-wrapper">
        <Pannels
          isFloated={isFloated}
          toggleFloatedPanel={toggleFloatedPanel}
          showHiddenPanel={showHiddenPanel}
          updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
          primary={
            <>
              <div
                className="custom-scrollbar pt-0 "
                style={{overflowX: 'hidden'}}>
                <div className="messages-header">
                  {/* <div className="event-tabs dark-tabs"> */}
                  <div className="event-tabs tabs-style-2 mb-0">
                    {' '}
                    {/* Merging */}
                    <ul className="LabelTabs">
                      <li className="ml-20 d-flex align-items-center">
                        <Button
                          className={tabType === 'incomplete' ? 'active' : ''}
                          onClick={() => {
                            setTabType('incomplete');
                          }}>
                          {labels.technician_incomplete_tasks[selected_lang]}
                        </Button>
                        <span className="actionCount">
                          {actions && actions.length}
                        </span>
                      </li>
                      <li>
                        <Button
                          className={tabType === 'complete' ? 'active' : ''}
                          onClick={() => {
                            setTabType('complete');
                          }}>
                          {labels.technician_complete_tasks[selected_lang]}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  {/* <span className="add-icon">
                            <i
                              className="icon-plus"
                              onClick={() => setSecondComponent("group-component")}
                            />
                          </span>
                          <span className="add-icon">
                            <i
                              className="icon-filter-icon"
                              onClick={() => setSearchFilterModal(true)}
                            />
                          </span> */}

                  <div className="search-tabs-icons">
                    {tabType === 'incomplete' && (
                      <span
                        className={
                          showComponent === 'addAction' ? 'icon active' : 'icon'
                        }>
                        <i
                          className="icon-plus"
                          onClick={() => {
                            if (showComponent === 'addAction') {
                              setShowComponent('');
                            } else {
                              setShowComponent('addAction');
                            }
                          }}
                        />
                      </span>
                    )}

                    <span
                      onClick={() => {
                        setThreeDots(!threeDots);
                      }}
                      className={threeDots ? 'icon active' : 'icon'}>
                      <i className="fas fa-ellipsis-vertical" />
                    </span>
                  </div>
                </div>

                {threeDots && tabType !== 'incomplete' && (
                  <div className="person-search-wrapper mb-0 messages-users-wrapper">
                    <div className="search-form d-flex">
                      <Form.Group className="search-input">
                        <Form.Control
                          type="text"
                          placeholder={
                            tabType === 'actions'
                              ? 'Search for action'
                              : 'Search for notification'
                          }
                          value={searchQry}
                          onChange={e => {
                            setSearchQry(e.target.value);
                          }}
                        />
                      </Form.Group>
                      {tabType === 'incomplete' && (
                        <Button>
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </Button>
                      )}
                    </div>
                  </div>
                )}
                {showLoader && <LoaderSpinner />}

                {tabType === 'complete' && (
                  <div className="mt-4">
                    {Object.entries(userListing).length > 0 ? (
                      userListing.map((userListing, index) => (
                        <div
                          className="refferals-list notification-listing"
                          key={index + 'userListing'}>
                          <div className="refferals-list-item card as-link">
                            <div
                              className="refferal-img as-link"
                              onClick={() => {
                                setSnapShotId(userListing);
                                setShowComponent('SnapShotDetail');
                              }}>
                              <img
                                src={userListing.accomplishment_images}
                                alt=""
                              />
                            </div>
                            <div className="as-link forwidth">
                              <div
                                className="refferal-content as-link mb-2"
                                onClick={() => {
                                  setSnapShotId(userListing);
                                  setShowComponent('SnapShotDetail');
                                }}>
                                <h2 className="single-line-text">
                                  {userListing.accomplishment_title}
                                </h2>
                                {userListing.accomplishment_sub_category_name && (
                                  <span className="refferal-auther single-line-text">
                                    {
                                      userListing.accomplishment_sub_category_name
                                    }
                                  </span>
                                )}

                                {userListing.accomplishment_role_name && (
                                  <span className="refferal-info-que single-line-text">
                                    {userListing.accomplishment_role_name}
                                  </span>
                                )}
                              </div>
                              <div className="refferal-like-unlike">
                                <span
                                  className="refferal-icons as-link"
                                  onClick={() => {
                                    manageAccomplishmentInvitation(
                                      'accepted',
                                      userListing.id,
                                    );
                                  }}>
                                  <i className="icon-like-icon active" />
                                </span>
                                <span
                                  className="refferal-icons as-link"
                                  onClick={() => {
                                    manageAccomplishmentInvitation(
                                      'rejected',
                                      userListing.id,
                                    );
                                  }}>
                                  <i className="icon-unlike" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-results-wrapper payment-no-results no-record-notification">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/no-notifications.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          <h2>{labels.Notifications_Pending[selected_lang]}</h2>
                          <p>{labels.NoAction_Desc[selected_lang]}</p>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {tabType === 'incomplete' && (
                  <div className="notify-actions">
                    <Actions
                      threeDots={threeDots}
                      setShowComponent={setShowComponent}
                      setDataOfAction={setDataOfAction}
                      hitApi={hitApi}
                      showComponent={showComponent}
                    />
                  </div>
                )}
              </div>

              {showComponent === 'WorkSpace' && (
                <CreateWorkSpace
                  onClose={() => {
                    setShowComponent('');
                  }}
                />
              )}

              {searchFilter && tabType === 'actions' && (
                <CustomModal
                  className="shareables-workspace-modal"
                  size="small"
                  onClose={() => setSearchFilterModal(false)}>
                  <div className="filter-wrapper">
                    <div className="filter-category-list">
                      <ul>
                        <li className={isCheckAll ? 'active' : ''}>
                          <Form.Label>
                            All
                            <input
                              type="checkbox"
                              id="All"
                              value="All"
                              onChange={() => {
                                handleSelectAll();
                              }}
                              checked={isCheckAll}
                            />
                            <span />
                          </Form.Label>
                        </li>

                        {filterOption.map((item, index) => (
                          <li
                            key={`sessionC-${index}`}
                            className={
                              checkFilterOption.includes(item.id) ||
                              filterOptionValue.includes(item.title)
                                ? 'active'
                                : ''
                            }>
                            <Form.Label>
                              {item.title}
                              <input
                                type="checkbox"
                                id={item.id}
                                value={item.title}
                                onChange={e => {
                                  handleClickList(item.id, e.target.value);
                                }}
                                checked={
                                  checkFilterOption.includes(item.id) ||
                                  filterOptionValue.includes(item.title)
                                }
                              />
                              <span />
                            </Form.Label>
                          </li>
                        ))}
                      </ul>

                      <Button
                        type="submit"
                        className="btn-dark"
                        onClick={() => {
                          getActions();
                          setSearchFilterModal(false);
                        }}>
                        Select
                      </Button>
                    </div>
                  </div>
                </CustomModal>
              )}
            </>
          }
          secondary={
            <>
              {showComponent === 'SnapShotDetail' && (
                <SnapshotDetail
                  snapshotId={snapShotId.accomplishment_id}
                  userData={snapShotId.creator_detail}
                  onClose={() => {
                    setShowComponent('');
                  }}
                  showRefer={false}
                />
              )}
              {showComponent === 'addAction' && (
                <CreateAction
                  onClose={() => {
                    setShowComponent('');
                  }}
                  setHitApi={setHitApi}
                />
              )}

              {showComponent === 'actionDetails' && (
                <ActionDetail
                  dataOfAction={dataOfAction}
                  onClose={() => {
                    setShowComponent('');
                  }}
                  setHitApi={setHitApi}
                />
              )}
            </>
          }
        />
      </div>
    </div>
  );
};

export default TechnicianTasks;
